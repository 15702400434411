<button [attr.id]="'btnDialogClose'" mat-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title>{{'intervention-instance-reminder.reminder_information' | translate}}</h1>
<div mat-dialog-content class="mat-typography">
  <div [ngSwitch]="displaySubject | async">
    <div *ngSwitchCase="'DEFAULT'">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="row mb-2">
            <div class="col-sm-3">
              <strong>{{'intervention-instance-reminder.form_create_intervention_instance_reminder_starts_at' | translate}}:</strong>
            </div>
            <div class="col-sm-9">{{helper.localizeDateTimestamp(reminder.attributes.start)}}</div>
          </div>

          <div class="row mb-2">
            <div class="col-sm-3"><strong>{{'intervention-instance-reminder.rhythm' | translate}}:</strong></div>
            <div class="col-sm-9">
              {{'intervention-instance-reminder.reminder_rhythm_1' | translate}} {{reminder.attributes.configuration.pause}}
              {{('intervention-instance-reminder.' + reminder.attributes.configuration.unit) |
              translate}}{{'intervention-instance-reminder.reminder_rhythm_2' | translate}}
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-sm-3"><strong>{{'intervention-instance-reminder.performed' | translate}}:</strong></div>
            <div class="col-sm-9">
              {{reminder.attributes.performed ? reminder.attributes.performed : 0}} / {{reminder.attributes.maximum_reminders}}
            </div>
          </div>

          <p><strong>{{'intervention-instance-reminder.message_preview'| translate}}:</strong></p>
          <p>{{getReminderGreeting(reminder.attributes.content?.locale ? reminder.attributes.content?.locale : 'de')}}</p>
          <p>
            <ng-template *ngIf="reminder.attributes.content?.text; then thenShowCustomMessage else elsShowDefaultMessage"></ng-template>
            <ng-template #thenShowCustomMessage>{{reminder.attributes.content?.text}}</ng-template>
            <ng-template #elsShowDefaultMessage>
              {{getReminderMessage(reminder.attributes.content?.locale ? reminder.attributes.content?.locale : 'de')}}</ng-template
            >
          </p>
          <p>{{getReminderEndingHead(reminder.attributes.content?.locale ? reminder.attributes.content?.locale : 'de')}}</p>
          <p>
            <ng-template *ngIf="reminder.attributes.content?.ending; then thenShowCustomEnding else elsShowDefaultEnding"></ng-template>
            <ng-template #thenShowCustomEnding>{{reminder.attributes.content?.ending}}</ng-template>
            <ng-template #elsShowDefaultEnding>
              {{getReminderEnding(reminder.attributes.content?.locale ? reminder.attributes.content?.locale : 'de')}}</ng-template
            >
          </p>
        </div>

        <div class="col-12 col-md-6">
          <div class="mb-4 text-start">
            <div class="card fixHeight">
              <div *ngIf="(pagedReminderLog$ | async)?.length === 0" class="card-body">
                <p class="centering">{{'intervention-instance-reminder.no_reminder_logs_found' | translate}}</p>
              </div>
              <div *ngIf="(pagedReminderLog$ | async).length !== 0" class="list-group overflowList">
                <div
                  [attr.id]="'reminder_log_' + reminderLog.id"
                  *ngFor="let reminderLog of pagedReminderLog$ | async"
                  class="list-group-item list-group-item-action flex-column align-items-start"
                >
                  <div class="row justify-content-around mb-3">
                    <div class="col-12 col-lg-8 mb-1">
                      <p>{{getReminderGreeting(reminderLog.attributes.content?.locale ? reminderLog.attributes.content?.locale : 'de')}}</p>
                      <p>
                        <ng-template
                          *ngIf="reminderLog.attributes.content?.text; then thenShowCustomMessageLog else elsShowDefaultMessageLog"
                        ></ng-template>
                        <ng-template #thenShowCustomMessageLog>{{reminderLog.attributes.content?.text}}</ng-template>
                        <ng-template #elsShowDefaultMessageLog
                          >{{getReminderMessage(reminderLog.attributes.content?.locale ? reminderLog.attributes.content?.locale :
                          'de')}}</ng-template
                        >
                      </p>
                      <p>
                        {{getReminderEndingHead(reminderLog.attributes.content?.locale ? reminderLog.attributes.content?.locale : 'de')}}
                      </p>
                      <p>
                        <ng-template
                          *ngIf="reminderLog.attributes.content?.ending; then thenShowCustomEndingLog else elsShowDefaultEndingLog"
                        ></ng-template>
                        <ng-template #thenShowCustomEndingLog>{{reminderLog.attributes.content?.ending}}</ng-template>
                        <ng-template #elsShowDefaultEndingLog
                          >{{getReminderEnding(reminderLog.attributes.content?.locale ? reminderLog.attributes.content?.locale :
                          'de')}}</ng-template
                        >
                      </p>
                    </div>
                    <div class="col-12 col-lg-4 mb-1">
                      <p><small>{{helper.localizeDateTimestamp(reminderLog.attributes.created_at)}}</small></p>

                      <p>{{ 'intervention-instance-reminder.incomplete_questionnaires' | translate}}:</p>
                      <p *ngFor="let id of reminderLog.attributes.questionnaires_ids">
                        <span class="text-info"
                          ><small> {{helper.findArrObjById(id, questionnaires).attributes.title}} (ID {{id}})</small></span
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-footer">
              <app-pagination-list
                #paginator
                (emitPageChange)="updatePagedReminderLogs($event)"
                [_isLoading]="isLoading$ | async"
                [_items]="reminderLogs$ | async"
              ></app-pagination-list>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="'UPDATE'">
      <div [formGroup]="reminderForm">
        <!-- Starts at -->
        <div class="col-12">
          <div class="col-form-label col-12 pt-0">
            <strong>{{'intervention-instance-reminder.form_create_intervention_instance_reminder_starts_at' | translate}}:</strong>
          </div>
          <div class="row">
            <div class="col-md-6 col-12">
              <mat-form-field class="full-width" appearance="outline">
                <input
                  [attr.id]="'starts_at'"
                  matInput
                  [ngxMatDatetimePicker]="picker"
                  placeholder="Choose a date"
                  formControlName="starts_at"
                  [disabled]="false"
                  readonly
                />
                <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker
                  #picker
                  [attr.id]="'picker'"
                  [showSpinners]="showSpinners"
                  [showSeconds]="showSeconds"
                  [stepHour]="stepHour"
                  [stepMinute]="stepMinute"
                  [stepSecond]="stepSecond"
                  [touchUi]="touchUi"
                  [color]="color"
                  [enableMeridian]="enableMeridian"
                >
                </ngx-mat-datetime-picker>
              </mat-form-field>
            </div>
            <div class="col-md-6 col-12">
              <!-- Max_Times -->
              <mat-form-field [style.width.%]="100" appearance="outline">
                <mat-label>{{'intervention-instance-reminder.max_number_of_repetitions' | translate}}</mat-label>
                <input [attr.id]="'max_times'" autocomplete="off" formControlName="max_times" matInput required type="number" min="0" />
                <mat-error
                  ><span
                    *ngIf="((isSubmitted$ | async) && f.get('max_times').invalid && f.get('max_times').errors?.required) || (f.get('max_times').touched && f.get('max_times').invalid)"
                    >{{'intervention-instance-reminder.valid_max_number_of_repetitions_required' | translate}}</span
                  >
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 col-12">
            <!-- Time interval -->
            <mat-form-field [style.width.%]="100" appearance="outline">
              <mat-label>{{'intervention-instance-reminder.time_interval' | translate}}</mat-label>
              <input
                [attr.id]="'time_interval'"
                type="number"
                [attr.min]="reminder.attributes.performed"
                autocomplete="off"
                formControlName="time_interval"
                matInput
              />
              <mat-error
                *ngIf="((isSubmitted$ | async) && f.get('time_interval').invalid && f.get('time_interval').errors?.required) || (f.get('time_interval').touched && f.get('time_interval').invalid)"
                >{{'intervention-instance-reminder.time_interval_required' | translate}}</mat-error
              >
            </mat-form-field>
          </div>
          <div class="col-md-6 col-12">
            <!-- Time interval unit -->
            <mat-form-field appearance="outline">
              <mat-label>{{'intervention-instance-reminder.time_interval_unit' | translate }}</mat-label>
              <mat-select [attr.id]="'time_interval_unit'" formControlName="time_interval_unit" name="time_interval_unit">
                <mat-option [attr.id]="'option_' + unit" *ngFor="let unit of unitList" [value]="unit">
                  {{('intervention-instance-reminder.' + unit) | translate}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <mat-divider class="mb-3"></mat-divider>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label>{{'intervention-instance-reminder.select_language' | translate}}</mat-label>
          <mat-select [(value)]="selectedLanguage">
            <mat-option [value]="option" *ngFor="let option of languages"
              >{{'intervention-instance-reminder.' + option | translate }}</mat-option
            >
          </mat-select>
        </mat-form-field>

        <p>{{getReminderGreeting()}}</p>

        <!-- Content text -->
        <mat-form-field [style.width.%]="100" appearance="outline" floatLabel="always">
          <mat-label>{{'intervention-instance-reminder.content_text' | translate}}</mat-label>
          <textarea
            [attr.id]="'content_text'"
            [attr.placeholder]="getReminderMessage()"
            autocomplete="off"
            formControlName="content_text"
            cdkTextareaAutosize
            cdkAutosizeMinRows="5"
            matInput
          ></textarea>
        </mat-form-field>

        <p>{{getReminderEndingHead()}}</p>

        <mat-form-field [style.width.%]="100" appearance="outline" floatLabel="always">
          <mat-label>{{'intervention-instance-reminder.content_ending' | translate}}</mat-label>
          <textarea
            [attr.id]="'content_ending'"
            [attr.placeholder]="getReminderEnding()"
            autocomplete="off"
            formControlName="content_ending"
            matInput
          ></textarea>
        </mat-form-field>
      </div>
    </div>
    <div *ngSwitchCase="'DELETE'">{{'intervention-instance-reminder.delete_reminder_info' | translate }}</div>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button [attr.id]="'btnCancel'" mat-button mat-dialog-close>{{'intervention-instance-reminder.cancel' | translate}}</button>
  <div [ngSwitch]="displaySubject | async">
    <div *ngSwitchCase="'DEFAULT'">
      <button [attr.id]="'btnToggleUpdate'" mat-button [disabled]="isDisabled()" (click)="setDisplay('UPDATE')">
        {{'intervention-instance-reminder.toggle_update' | translate}}
      </button>
      <button [attr.id]="'btnToggleDelete'" mat-button [disabled]="isDisabled()" (click)="setDisplay('DELETE')">
        {{'intervention-instance-reminder.toggle_delete' | translate}}
      </button>
    </div>
    <div *ngSwitchCase="'UPDATE'">
      <button [attr.id]="'btnToggleDetail'" mat-button [disabled]="isDisabled()" (click)="setDisplay('DEFAULT')">
        {{'intervention-instance-reminder.toggle_detail' | translate}}
      </button>
      <app-button-feedback
        [_setId]="'btnUpdateInterventionInstanceReminder'"
        (click)="updateInterventionInstanceReminder()"
        [_defaultButtonText]="'intervention-instance-reminder.reminder_confirm'"
        [_responseText]="updateInterventionInstanceReminder$ | async"
      ></app-button-feedback>
    </div>
    <div *ngSwitchCase="'DELETE'">
      <button [attr.id]="'btnSetDisplay'" mat-button [disabled]="isDisabled()" (click)="setDisplay('DEFAULT')">
        {{'intervention-instance-reminder.toggle_detail' | translate}}
      </button>
      <app-button-feedback
        [_setId]="'btnDeleteInterventionInstanceReminder'"
        (click)="deleteInterventionInstanceReminder()"
        [_defaultButtonText]="'intervention-instance-reminder.reminder_delete'"
        [_responseText]="deleteInterventionInstanceReminder$ | async"
      ></app-button-feedback>
    </div>
  </div>
</div>
