<button [attr.id]="'btnDialogClose'" mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon>close</mat-icon>
</button>

<h2 mat-dialog-title>{{'codebook.title' | translate}}</h2>

<div class="content-height" mat-dialog-content>
  <p>{{'codebook.overview_codebook' | translate}}</p>

  <app-codebook-overview [_study]="study"></app-codebook-overview>
</div>
