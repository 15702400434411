import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { catchError, EMPTY, mergeMap, Observable, tap, throwError } from 'rxjs';
import { MyProfileService } from '../../../services/api/my-profile/my-profile.service';
import { Store } from '@ngrx/store';
import { updateProfileStore } from '../profile.action';

export interface ProfileState {
  updateProfileResponse: any;
  updatePasswordResponse: any;
}

@Injectable()
export class ProfileStore extends ComponentStore<ProfileState> {
  readonly updateProfileResponse$: Observable<any> = this.select(state => state.updateProfileResponse, {
    debounce: true
  });
  readonly updatePasswordResponse$: Observable<any> = this.select(state => state.updatePasswordResponse, {
    debounce: true
  });

  readonly updateProfileState = this.updater(
    (
      state,
      payload: {
        updateProfileResponse?: any;
        updatePasswordResponse?: any;
      }
    ) => ({
      updateProfileResponse: payload.updateProfileResponse ? payload.updateProfileResponse : state.updateProfileResponse,
      updatePasswordResponse: payload.updatePasswordResponse ? payload.updatePasswordResponse : state.updatePasswordResponse
    })
  );

  readonly addUpdatePasswordResponse = this.updater((state, updatePasswordResponse: any) => ({
    updateProfileResponse: state.updateProfileResponse,
    updatePasswordResponse
  }));

  readonly updateProfile = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) => {
        this.updateProfileState({ updateProfileResponse: null });
        return this.myProfileService
          .updateProfile(payload.settings, payload.name, payload.firstname, payload.lastname, payload.sex, payload.picture)
          .pipe(
            tap({
              next: (result: any) => {
                this.store.dispatch(updateProfileStore({ myProfile: result.body.data }));
                this.updateProfileState({ updateProfileResponse: result });
              },
              error: e => throwError(e)
            }),
            catchError(error => {
              this.updateProfileState({ updateProfileResponse: error });
              return EMPTY;
            })
          );
      })
    )
  );

  readonly updatePassword = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) => {
        this.updateProfileState({ updatePasswordResponse: null });
        return this.myProfileService.updatePassword(payload.currentPassword, payload.password, payload.passwordConfirmation).pipe(
          tap({
            next: (result: any) => this.updateProfileState({ updatePasswordResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateProfileState({ updatePasswordResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  constructor(private myProfileService: MyProfileService, private store: Store) {
    super({
      updateProfileResponse: null,
      updatePasswordResponse: null
    });
  }
}
