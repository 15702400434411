import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { RequestBodyData } from '../../../models/request-body.data';
import { Observable } from 'rxjs';
import { HelperService } from '../../helper/helper.service';

/**
 * Service:
 * Lesson/Questionnaire API service that retrieves questionnaires
 */

@Injectable({
  providedIn: 'root'
})
export class LessonQuestionnaireService {
  public header: HttpHeaders = new HttpHeaders();
  private backendUrl: string = environment.backendURL;
  private payload: RequestBodyData;

  constructor(private http: HttpClient, private helperService: HelperService) {
    this.header = this.header.set('Content-Type', 'application/json');
    this.header = this.helperService.setLocaleFromStorage(this.header);
  }

  /**
   * AAS2 API User - All Questionnaires
   * This function retrieves all questionnaires.
   *
   * @params number is_active - A number to filter activated questionnaires
   * @return Observable<any> - An observable for any response.
   */
  public getAllQuestionnaires(isActive?: number): Observable<any> {
    this.header = this.helperService.setLocaleFromStorage(this.header);
    let params = new HttpParams();
    if (isActive !== undefined) {
      params = params.set('is_active', isActive.toString());
    } else {
      params = params.set('is_active', '1');
    }
    return this.http.get<any>(`${this.backendUrl}/api/v1/questionnaires?limit=0`, {
      headers: this.header,
      observe: 'response',
      params
    });
  }

  /**
   * AAS2 API User - Questionnaire Details
   * This function retrieves details of a questionnaire.
   *
   * @params number questionnaireId - ID of the questionnaire.
   * @return Observable<any> - An observable for any response.
   */
  public getQuestionnaireDetails(questionnaireId: number): Observable<any> {
    this.header = this.helperService.setLocaleFromStorage(this.header);
    return this.http.get<any>(`${this.backendUrl}/api/v1/questionnaires/${questionnaireId}`, { headers: this.header, observe: 'response' });
  }

  /**
   * AAS2 API User - Questionnaire Structure
   * This function retrieves the structure elements of a questionnaire.
   *
   * @params number questionnaireId - ID of the questionnaire.
   * @return Observable<any> - An observable for any response.
   */
  public getQuestionnaireStructure(questionnaireId: number): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/api/v1/questionnaires/${questionnaireId}/structure`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API User - Questionnaires of Study
   * This function returns all questionnaires of the study (study).
   *
   * @params number studyId - A number of the study (study) id.
   *         number is_active - A number to filter activated questionnaires
   * @return Observable<any> - An observable for any response.
   */
  public getQuestionnairesStudy(studyId: number, active?: boolean): Observable<any> {
    let params = new HttpParams();
    if (active !== undefined) {
      params = params.set('active', active.toString());
    }
    this.header = this.helperService.setLocaleFromStorage(this.header);
    return this.http.get<any>(`${this.backendUrl}/api/v1/studies/${studyId}/questionnaires?limit=0`, {
      headers: this.header,
      observe: 'response',
      params
    });
  }

  /**
   * AAS2 API User - Get Elements
   * This function returns all elements of the questionnaire.
   *
   * @params number questionnaireId - ID of the questionnaire.
   * @return Observable<any> - An observable for any response.
   */
  public getElementsOfQuestionnaire(questionnaireId: number): Observable<any> {
    this.header = this.helperService.setLocaleFromStorage(this.header);
    return this.http.get<any>(`${this.backendUrl}/api/v1/questionnaires/${questionnaireId}/elements`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API User - Upload Media
   * This function uploads a media file - For more information check the project documentation.
   * TODO: Add payload and check for media upload
   *
   * @return Observable<any> - An observable for any response.
   */
  public uploadMedia(): Observable<any> {
    return this.http.post<any>(`${this.backendUrl}/api/v1/media`, this.payload, { observe: 'response' });
  }

  /**
   * AAS2 API User - All Questionnaires of an Intervention
   * This function returns all questionnaires of an intervention.
   *
   * @params number interventionId - ID of the intervention.
   *         number is_active - A number to filter activated questionnaires
   * @return Observable<any> - An observable for any response.
   */
  public getAllQuestionnairesOfIntervention(interventionId: number, isActive?: number): Observable<any> {
    this.header = this.helperService.setLocaleFromStorage(this.header);
    let params = new HttpParams();
    if (isActive !== undefined) {
      params = params.set('is_active', isActive.toString());
    } else {
      params = params.set('is_active', '1');
    }
    return this.http.get<any>(`${this.backendUrl}/api/v1/interventions/${interventionId}/questionnaires?limit=0`, {
      headers: this.header,
      observe: 'response',
      params
    });
  }
}
