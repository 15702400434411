<button [attr.id]="'btnDialogClose'" mat-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title>{{'group-buddy.modal_detail_instance_title' | translate}}</h1>

<div mat-dialog-content>
  <div class="container text-center">
    <mat-card appearance="outlined" class="mb-3">
      <mat-card-header class="card-header-center">
        <mat-card-title>{{'group-buddy.buddy_instance_id' | translate}} #{{(buddyInstance.id)}}</mat-card-title>
        <mat-card-subtitle>
          <p class="my-1">
            {{'group-buddy.guiding_ecoach' | translate}} :<span *ngFor="let ecoach_id of buddyInstance.attributes.ecoach_id">
              {{helper.findArrObjById(ecoach_id, studyCollaborators)?.attributes.email}} ({{helper.findArrObjById(ecoach_id,
              studyCollaborators)?.attributes.name}})</span
            >
          </p>
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div class="row">
          <div *ngFor="let user_id of buddyInstance.attributes.buddies_ids" class="col-12 col-sm-6">
            <p>
              {{helper.findArrObjById(user_id, studyMembers)?.attributes.email}}
              <span *ngIf="helper.getStudyCode(studyId, helper.findArrObjById(user_id, studyMembers))"
                >({{helper.getStudyCode(studyId, helper.findArrObjById(user_id, studyMembers))}})</span
              >
            </p>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <mat-divider></mat-divider>

  <div class="mb-4 text-start">
    <p class="my-3"><strong>{{'group-buddy.buddy_activities' | translate}}</strong></p>

    <!-- Activity content -->
    <div *ngIf="(isLoadingActivities$ | async); then thenIsLoading else elseShowActivities"></div>
    <ng-template #thenIsLoading>
      <app-loader></app-loader>
    </ng-template>
    <ng-template #elseShowActivities>
      <div class="card">
        <div *ngIf="(pagedActivity$ | async)?.length === 0" class="card-body">
          <p class="centering">{{'group-buddy.no_activities_found' | translate}}</p>
        </div>
        <div *ngIf="(pagedActivity$ | async).length !== 0" class="list-group">
          <div
            *ngFor="let activityPair of pagedActivity$ | async"
            class="list-group-item list-group-item-action flex-column align-items-start"
          >
            <div class="row justify-content-around mb-3">
              <div class="col-sm-8">
                <p class="mb-1"><strong>{{'group-buddy.buddy_daily_task_title' | translate}} {{activityPair.localizedDate}}</strong></p>
                <p
                  *ngIf="getBuddyState(activityPair) === 'no_daily_task_completed' || getBuddyState(activityPair) === 'both_daily_task_completed' || getBuddyState(activityPair) === 'buddy_daily_only_one_daily_task_completed'"
                ></p>
                <p
                  [ngClass]="{'text-danger': getBuddyState(activityPair) === 'no_daily_task_completed', 'text-info': getBuddyState(activityPair) === 'only_one_daily_task_completed', 'text-success': getBuddyState(activityPair) === 'both_daily_task_completed'}"
                >
                  {{'group-buddy.buddy_daily_' + getBuddyState(activityPair) | translate}}
                </p>

                <div>
                  <div
                    [ngClass]="{'text-danger': getBuddySingleState(activityPair?.pair1) === 'no_daily_task_completed', ' text-info': getBuddySingleState(activityPair?.pair1) === 'one_daily_task_completed_with_reminder' || getBuddySingleState(activityPair?.pair1) === 'one_daily_task_completed', 'text-success': getBuddySingleState(activityPair?.pair1) === 'both_daily_task_completed'}"
                    *ngIf="activityPair?.pair1; else elseShowNothing1"
                  >
                    <p>
                      <strong>
                        {{'group-buddy.buddy_daily_' + getBuddySingleState(activityPair?.pair1) |
                        translate:getTranslation(activityPair?.pair1?.attributes.user_id)}}</strong
                      >
                    </p>
                    <p *ngIf="activityPair?.pair1 && activityPair?.pair1.attributes.daily_task_timestamps">
                      <fa-icon [icon]="faClock"></fa-icon>
                      {{helper.localizeDateTimestamp(activityPair?.pair1.attributes.daily_task_timestamps)}}
                    </p>
                  </div>
                  <ng-template #elseShowNothing1>
                    <p class="text-danger">
                      <strong
                        >{{'group-buddy.buddy_daily_task_incomplete' |
                        translate:getTranslation(activityPair?.pair1?.attributes.user_id)}}</strong
                      >
                    </p>
                  </ng-template>
                </div>

                <div>
                  <div
                    [ngClass]="{'text-danger': getBuddySingleState(activityPair?.pair2) === 'no_daily_task_completed', ' text-info': getBuddySingleState(activityPair?.pair2) === 'one_daily_task_completed_with_reminder' || getBuddySingleState(activityPair?.pair2) === 'one_daily_task_completed', 'text-success': getBuddySingleState(activityPair?.pair2) === 'both_daily_task_completed'}"
                    *ngIf="activityPair?.pair2; else elseShowNothing2"
                  >
                    <p>
                      <strong>
                        {{'group-buddy.buddy_daily_' + getBuddySingleState(activityPair?.pair2) |
                        translate:getTranslation(activityPair?.pair2?.attributes.user_id)}}</strong
                      >
                    </p>
                    <p *ngIf="activityPair?.pair2 && activityPair?.pair2.attributes.daily_task_timestamps">
                      <fa-icon [icon]="faClock"></fa-icon>
                      {{helper.localizeDateTimestamp(activityPair?.pair2.attributes.daily_task_timestamps)}}
                    </p>
                  </div>
                  <ng-template #elseShowNothing2>
                    <p class="text-danger">
                      <strong
                        >{{'group-buddy.buddy_daily_task_incomplete' |
                        translate:getTranslation(activityPair?.pair2?.attributes.user_id)}}</strong
                      >
                    </p>
                  </ng-template>
                </div>

                <div *ngIf="hasSentReminder(activityPair); then thenShowReminder"></div>
                <ng-template #thenShowReminder>
                  <hr />
                  <p *ngIf="activityPair?.pair1 && activityPair?.pair1.attributes.daily_reminder_timestamps">
                    <fa-icon [icon]="faBell"></fa-icon>
                    <strong>
                      {{helper.findArrObjById(activityPair?.pair1.attributes.user_id, studyMembers) !== null ?
                      helper.getCodeNameEmail(activityPair?.pair1.attributes.user_id, studyMembers, studyId) : ('SYSTEM' |
                      translate)}}</strong
                    >
                    {{'group-buddy.buddy_daily_reminder_has_sent' | translate}}
                    {{helper.localizeDateTimestamp(activityPair?.pair1.attributes.daily_reminder_timestamps)}}
                  </p>

                  <p *ngIf="activityPair?.pair2 && activityPair?.pair2.attributes.daily_reminder_timestamps">
                    <fa-icon [icon]="faBell"></fa-icon>
                    <strong>
                      {{helper.findArrObjById(activityPair?.pair2.attributes.user_id, studyMembers) !== null ?
                      helper.getCodeNameEmail(activityPair?.pair2.attributes.user_id, studyMembers, studyId) : ('SYSTEM' |
                      translate)}}</strong
                    >
                    {{'group-buddy.buddy_daily_reminder_has_sent' | translate}}
                    {{helper.localizeDateTimestamp(activityPair?.pair2.attributes.daily_reminder_timestamps)}}
                  </p>
                </ng-template>
              </div>

              <div class="col-sm-4"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="card-footer">
        <app-pagination-list
          (emitPageChange)="updatePagedActivities($event)"
          [_isLoading]="isLoadingActivities$ | async"
          [_items]="buddyActivitiesPair"
        ></app-pagination-list>
      </div>
    </ng-template>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button [attr.id]="'btnCancel'" mat-button mat-dialog-close="true">{{'dialog.intervention-detail.dialog_close' | translate}}</button>
</div>
