<div class="container-fluid text-center">
  <div class="text-start">
    <h1>{{ 'faq.faq_title' | translate }}</h1>
    <hr />
  </div>
  <h4>{{ 'faq.overview_of_faq' | translate }}</h4>

  <section class="row justify-content-center">
    <h4 class="text-danger text-center col-12">
      <strong><u>{{'faq.section1_account_creation_verification_invitation' | translate}}</u></strong>
    </h4>
    <mat-accordion class="col-12 col-lg-8">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="text-info">{{'faq.section1_question1' | translate}}</span>
          </mat-panel-title>
          <mat-panel-description> </mat-panel-description>
        </mat-expansion-panel-header>
        <span class="text-start" [innerHTML]="'faq.section1_answer1' | translate"></span>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="text-info">{{'faq.section1_question2' | translate}}</span>
          </mat-panel-title>
          <mat-panel-description> </mat-panel-description>
        </mat-expansion-panel-header>
        <span class="text-start" [innerHTML]="'faq.section1_answer2' | translate"></span>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="text-info">{{'faq.section1_question3' | translate}}</span>
          </mat-panel-title>
          <mat-panel-description> </mat-panel-description>
        </mat-expansion-panel-header>
        <span class="text-start" [innerHTML]="'faq.section1_answer3' | translate"></span>
      </mat-expansion-panel>
    </mat-accordion>
  </section>

  <section class="row justify-content-center mt-3">
    <h4 class="text-danger text-center col-12">
      <strong><u>{{'faq.section2_assign_lessons' | translate}}</u></strong>
    </h4>
    <mat-accordion class="col-12 col-lg-8">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="text-info">{{'faq.section2_question1' | translate}}</span>
          </mat-panel-title>
          <mat-panel-description> </mat-panel-description>
        </mat-expansion-panel-header>
        <p class="text-start" [innerHTML]="'faq.section2_answer1' | translate"></p>
        <ul class="text-start">
          <li [innerHTML]="'faq.section2_answer1_option1' | translate"></li>
          <li [innerHTML]="'faq.section2_answer1_option2' | translate"></li>
          <li [innerHTML]="'faq.section2_answer1_option3' | translate"></li>
          <li [innerHTML]="'faq.section2_answer1_option4' | translate"></li>
        </ul>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="text-info">{{'faq.section2_question2' | translate}}</span>
          </mat-panel-title>
          <mat-panel-description> </mat-panel-description>
        </mat-expansion-panel-header>
        <span class="text-start" [innerHTML]="'faq.section2_answer2' | translate"></span>
      </mat-expansion-panel>
    </mat-accordion>
  </section>

  <section class="row justify-content-center mt-3">
    <h4 class="text-danger text-center col-12">
      <strong><u>{{'faq.section3_configure_assigned_intervention' | translate}}</u></strong>
    </h4>
    <mat-accordion class="col-12 col-lg-8">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="text-info">{{'faq.section3_question1' | translate}}</span>
          </mat-panel-title>
          <mat-panel-description> </mat-panel-description>
        </mat-expansion-panel-header>
        <span class="text-start" [innerHTML]="'faq.section3_answer1' | translate"></span>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="text-info">{{'faq.section3_question2' | translate}}</span>
          </mat-panel-title>
          <mat-panel-description> </mat-panel-description>
        </mat-expansion-panel-header>
        <span class="text-start" [innerHTML]="'faq.section3_answer2' | translate"></span>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="text-info">{{'faq.section3_question3' | translate}}</span>
          </mat-panel-title>
          <mat-panel-description> </mat-panel-description>
        </mat-expansion-panel-header>
        <span class="text-start" [innerHTML]="'faq.section3_answer3' | translate"></span>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="text-info">{{'faq.section3_question4' | translate}}</span>
          </mat-panel-title>
          <mat-panel-description> </mat-panel-description>
        </mat-expansion-panel-header>
        <span class="text-start" [innerHTML]="'faq.section3_answer4' | translate"></span>
      </mat-expansion-panel>
    </mat-accordion>
  </section>
</div>
