/* eslint-disable @typescript-eslint/naming-convention */
import { AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { BehaviorSubject, iif, Observable, of, Subscription, throwError } from 'rxjs';
import { distinctUntilChanged, map, mergeMap, skip, switchMap, take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import { HelperService } from '../../../services/helper/helper.service';
import { UserInterface } from '../../../models/interface/user.interface';
import { StudyInterface } from '../../../models/interface/study/study.interface';
import { PatientSharedService } from '../../../services/shared/patient-shared/patient-shared.service';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { StudyActionTypes } from '../../../store/study/study.action';
import { UserActionTypes } from '../../../store/user/user.action';
import { UserStore } from '../../../store/user/component-store/user.store';
import { TranslateService } from '@ngx-translate/core';

/**
 * Component:
 * Patient details page displaying the selected patient;
 * Can be found: {uri}/patients/{{user_id}}
 */

@Component({
  selector: 'app-patient-detail',
  templateUrl: './patient-detail.component.html',
  styleUrls: ['./patient-detail.component.scss'],
  providers: [UserStore]
})
export class PatientDetailComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren('matTabGroup') matTabGroup: QueryList<MatTabGroup>;

  // Icons
  faCheckCircle = faCheckCircle;
  faTimesCircle = faTimesCircle;

  public isLoading$: Observable<boolean>;

  public myPatients: Array<UserInterface> = [];
  // Data provided by UserService
  public user: UserInterface;

  // Data provided by UserService
  public initialStudies: Array<StudyInterface>;

  public activeChildrouteSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private translationSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});

  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private collaboratingStudies: Array<StudyInterface> = [];

  private patientId: number;

  private myMembers$: Observable<Array<UserInterface>>;
  private memberStudies$: Observable<Array<StudyInterface>>;
  private myRegisteredUsers$: Observable<Array<UserInterface>>;

  private subscriptions: Subscription[] = [];

  constructor(
    private helperService: HelperService,
    private translateService: TranslateService,
    private actRoute: ActivatedRoute,
    private router: Router,
    private actions$: Actions,
    private userStore: UserStore,
    private store: Store<{ myMembers: Array<UserInterface>; myRegisteredUsers: Array<UserInterface> }>,
    private sharedService: PatientSharedService
  ) {
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.myMembers$ = this.store.select('myMembers');
    this.memberStudies$ = this.userStore.memberStudies$;
    this.myRegisteredUsers$ = this.store.select('myRegisteredUsers');

    this.subscriptions.push(
      this.translateService
        .getTranslation(localStorage.getItem('language') !== null ? localStorage.getItem('language') : 'de')
        .subscribe(translations => {
          this.translationSubject.next(translations);
        })
    );
  }

  public get helper() {
    return this.helperService;
  }

  ngOnInit(): void {
    this.isLoadingSubject.next(true);

    this.subscriptions.push(
      this.actRoute.params
        .pipe(
          map(v => v.id),
          mergeMap(id => {
            this.patientId = id;
            this.sharedService.pushId(this.patientId);
            this.store.dispatch({
              type: UserActionTypes.getUsersRegisteredByEcoachType,
              payload: {}
            });
            return this.myRegisteredUsers$.pipe(skip(1), take(1));
          }),
          mergeMap(result => {
            this.myPatients = result;
            this.store.dispatch({
              type: UserActionTypes.getMyMembersType,
              payload: {}
            });
            return this.myMembers$.pipe(skip(1), take(1));
          })
        )
        .subscribe(
          result => {
            this.myPatients = this.helper.setUniqueMembers(this.myPatients, result);
            const found = this.helper.findArrObjById(this.patientId, this.myPatients);
            if (found) {
              this.sharedService.pushPatient(found);
              this.user = found;
              this.isLoadingSubject.next(false);
            } else {
              this.router.navigateByUrl('/patients');
            }
          },
          error => {
            throwError(error);
            this.router.navigateByUrl('/patients');
          }
        )
    );

    this.store.dispatch({ type: StudyActionTypes.getCollaboratingStudiesType, payload: {} });
    this.subscriptions.push(
      this.actions$
        .pipe(
          ofType(StudyActionTypes.getCollaboratingStudiesSuccessType, StudyActionTypes.getCollaboratingStudiesErrorType),
          take(1),
          mergeMap((result: any) => {
            if (result.type === StudyActionTypes.getCollaboratingStudiesSuccessType) {
              this.collaboratingStudies = result['response']['body']['data'];
              this.userStore.getMemberStudies({ userId: this.patientId });
              return this.memberStudies$.pipe(skip(1), take(1));
            } else {
              return throwError(result);
            }
          })
        )
        .subscribe(result => {
          this.initialStudies = result.filter((study: StudyInterface) =>
            this.collaboratingStudies.map(val => val.id.toString()).includes(study.id.toString())
          );
        })
    );

    setTimeout(() => {
      // Nav active
      this.sharedService.activeChildroute$.subscribe(value => {
        this.activeChildrouteSubject.next(value);
        if (this.matTabGroup) {
          this.helper.setMatTabActive(this.matTabGroup, this.router.url, this.translationSubject, this.translateService, 'patient-detail');
        }
      });
    });
  }

  ngAfterViewInit(): void {
    const url = this.router.url;
    this.subscriptions.push(
      this.matTabGroup.changes.pipe().subscribe((comps: QueryList<MatTabGroup>) => {
        this.helper.setMatTabActive(this.matTabGroup, this.router.url, this.translationSubject, this.translateService, 'patient-detail');
      })
    );
  }

  // Display and navigate to child component relative to this path
  public showChildComponent(child: string): void {
    if (this.activeChildrouteSubject.value !== child) {
      // Excluded configuration
      if (child.match(/^(studies|instances|diaries)$/)) {
        this.router.navigate([child], { relativeTo: this.actRoute });
      }
    }
  }

  // Listens to tab change
  public onChangeTab(event): void {
    const tab: MatTab = event.tab;
    this.showChildComponent(this.evaluateTab(tab.textLabel, this.translationSubject.value));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private evaluateTab(label, translate: any): string {
    if (translate) {
      if (translate['patient-detail']['studies'] === label) {
        return 'studies';
      }
      if (translate['patient-detail']['instances'] === label) {
        return 'instances';
      }
      if (translate['patient-detail']['diaries'] === label) {
        return 'diaries';
      }
    }
    return '';
  }
}
