import { createReducer, on } from '@ngrx/store';
import { AnswersheetAnswerInterface } from 'src/app/models/interface/answersheet-answer.interface';
import { addDynamicAnswersStore, removeDynamicAnswersStore, updateDynamicAnswersStore } from './dynamic-answers.action';

export const initialState: Array<AnswersheetAnswerInterface> = [];

export const dynamicAnswersReducer = createReducer(
  initialState,
  on(addDynamicAnswersStore, (state, { dynamicAnswers }) => dynamicAnswers),
  on(removeDynamicAnswersStore, (state, {}) => [])
);
