import { AfterViewInit, Component, EventEmitter, Inject, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StudyMediaInterface } from '../../../models/interface/study/study-media.interface';
import { MediaGalleryComponent } from '../../media/media-gallery/media-gallery.component';

@Component({
  selector: 'app-dialog-study-media-gallery',
  templateUrl: './dialog-study-media-gallery.component.html',
  styleUrls: ['./dialog-study-media-gallery.component.scss']
})
export class DialogStudyMediaGalleryComponent implements OnInit, AfterViewInit {
  // For assign intervention modal
  @ViewChild('selectMediaFromGallery', { read: ViewContainerRef }) selectMediaContainer: ViewContainerRef;

  public mediaGalleryComponentClass = MediaGalleryComponent;
  public selectedPicture: EventEmitter<StudyMediaInterface> = null;

  public studyId: number;
  public studyMedia: Array<StudyMediaInterface> = [];

  private components: Array<any> = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data, private dialogRef: MatDialogRef<DialogStudyMediaGalleryComponent>) {}

  ngOnInit(): void {
    this.studyMedia = this.data.studyMedia;
    this.studyId = this.data.studyId;
    this.components = [];
  }

  ngAfterViewInit(): void {
    if (this.selectMediaContainer) {
      this.selectMediaContainer.clear();
      const component = this.selectMediaContainer.createComponent(MediaGalleryComponent).instance;
      this.components = [...this.components, component];

      const foundMediaGallery: MediaGalleryComponent = this.components.find(c => c instanceof MediaGalleryComponent);
      foundMediaGallery.studyId = this.studyId;
      foundMediaGallery.studyMedia = this.studyMedia;
      foundMediaGallery.selectedMedia.subscribe((selectedMedia: StudyMediaInterface) => {
        this.dialogRef.close(selectedMedia);
      });
    }
  }

  public selectImage(): void {
    const foundMediaGallery: MediaGalleryComponent = this.components.find(c => c instanceof MediaGalleryComponent);
    if (foundMediaGallery) {
      foundMediaGallery.selectMedia();
      foundMediaGallery.selectedMedia.subscribe((selectedMedia: StudyMediaInterface) => {
        this.dialogRef.close(selectedMedia);
      });
    }
  }
}
