import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WithCredentialsInterceptorService implements HttpInterceptor {
  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      !(
        req.url.includes('/auth/login') ||
        (req.url.includes('/auth/register') && !req.url.includes('/ecoach/auth/register')) ||
        req.url.includes('/auth/verify/resend') ||
        req.url.includes('/auth/password/reset/instructions') ||
        req.url.includes('/auth/password/reset') ||
        req.url.includes('/assets/i18n/')
      )
    ) {
      req = req.clone({
        withCredentials: true
      });
    }
    return next.handle(req);
  }
}
