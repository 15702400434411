import { Action, createAction, props } from '@ngrx/store';
import { AnswersheetInterface } from '../../models/interface/answersheet.interface';

export enum AnswersheetActionTypes {
  addAnswersheetsOfInterventionInstancesStoreType = 'Add Answersheets of Intervention Instances Store',
  updateAnswersheetsOfInterventionInstancesStoreType = 'Update Answersheets of Intervention Instances Store',
  deleteAnswersheetsOfInterventionInstancesStoreType = 'Delete Answersheets of Intervention Instances Store',

  getAnswersheetsOfAllInterventionInstancesType = '[Answersheet API] Get all Answersheets of all intervention instances',
  getAnswersheetsOfAllInterventionInstancesSuccessType = '[Success] Get all Answersheets of all intervention instances',
  getAnswersheetsOfAllInterventionInstancesErrorType = '[Error] Get all Answersheets of all intervention instances'
}

export const addAnswersheetsOfInterventionInstancesStore = createAction(
  AnswersheetActionTypes.addAnswersheetsOfInterventionInstancesStoreType,
  props<{ answersheetsOfInterventionInstances: Array<AnswersheetInterface> }>()
);
export const updateAnswersheetsOfInterventionInstancesStore = createAction(
  AnswersheetActionTypes.updateAnswersheetsOfInterventionInstancesStoreType,
  props<{ answersheetsOfInterventionInstances: Array<AnswersheetInterface> }>()
);
export const removeAnswersheetsOfInterventionInstancesStore = createAction(
  AnswersheetActionTypes.deleteAnswersheetsOfInterventionInstancesStoreType
);

export class GetAnswersheetsOfInterventionInstances implements Action {
  readonly type: string = AnswersheetActionTypes.getAnswersheetsOfAllInterventionInstancesType;
  constructor() {}
}
