import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { HelperService } from 'src/app/services/helper/helper.service';

@Component({
  selector: 'app-pagination-list',
  templateUrl: './pagination-list.component.html',
  styleUrls: ['./pagination-list.component.scss']
})
export class PaginationListComponent implements OnInit, OnDestroy {
  @Output()
  public emitPageChange: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('paginator') paginator: MatPaginator;

  public isLoading$: Observable<boolean>;

  public items$: Observable<Array<any>>;

  public pagedItems$: Observable<Array<any>>;

  public pageIndex = 0;
  public pageSize = 20;

  public showEmptySubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private itemsSubject: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  private pagedItemsSubject: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>([]);

  private subscriptions: Subscription[] = [];

  constructor(private helperService: HelperService) {
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.items$ = this.itemsSubject.asObservable();
    this.pagedItems$ = this.pagedItemsSubject.asObservable();
  }

  // Helper function
  public get helper() {
    return this.helperService;
  }

  @Input()
  set _showEmpty(_showEmpty: boolean) {
    if (_showEmpty !== undefined) {
      this.showEmptySubject.next(_showEmpty);
    }
  }

  @Input()
  set _isLoading(_isLoading: boolean) {
    if (_isLoading !== undefined) {
      this.isLoadingSubject.next(_isLoading);
    }
  }

  @Input()
  set _items(_items: Array<any>) {
    if (_items) {
      this.itemsSubject.next(_items);
      if (this.paginator) {
        this.paginator.firstPage();
        this.pageIndex = this.paginator ? this.paginator.pageIndex : 0;
        this.pageSize = this.paginator ? this.paginator.pageSize : 20;
        this.helper.setPagination(this.paginator, this.itemsSubject, this.pagedItemsSubject, true, this.pageIndex, this.pageSize);
      }
    }
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.isLoading$.subscribe((loading: boolean) => {
        if (this.paginator) {
          this.paginator.firstPage();
          this.pageIndex = this.paginator ? this.paginator.pageIndex : 0;
          this.pageSize = this.paginator ? this.paginator.pageSize : 20;
          this.helper.setPagination(this.paginator, this.itemsSubject, this.pagedItemsSubject, true, this.pageIndex, this.pageSize);
        }
      })
    );
  }

  public onPageChange(event: PageEvent): void {
    const startIndex = event.pageIndex * event.pageSize;
    let endIndex = startIndex + event.pageSize;
    if (endIndex > this.itemsSubject.value.length) {
      endIndex = this.itemsSubject.value.length;
    }
    this.pagedItemsSubject.next(this.itemsSubject.value.slice(startIndex, endIndex));
    this.emitPageChange.emit(this.itemsSubject.value.slice(startIndex, endIndex));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
