import { Injectable } from '@angular/core';
import { InterventionInstanceInterface } from '../../../models/interface/intervention-instances/intervention-instance.interface';
import { InterventionInstanceProgressFeedbackInterface } from '../../../models/interface/intervention-instances/intervention-instance.progress.feedback.interface';
import { MessageThreadsInterface } from '../../../models/interface/message_threads.interface';

@Injectable({
  providedIn: 'root'
})
export class HelperFeedbackService {
  constructor() {}

  public isFeedbackRequired(answersheetId: number, instance: InterventionInstanceInterface) {
    if (instance !== null) {
      const found = instance.attributes.progress.feedback_provided.find((value: InterventionInstanceProgressFeedbackInterface) => {
        if (Array.isArray(value.answersheet_id)) {
          return value.answersheet_id.includes(parseInt(answersheetId.toString(), 10));
        } else {
          return answersheetId.toString() === value.answersheet_id.toString();
        }
      });
      return found !== undefined;
    } else {
      return false;
    }
  }

  /*
  public isRead(answersheet_id: number, instance: InterventionInstanceInterface, thread_id: number) {
    if (this.isFeedbackRequired(answersheet_id, instance)) {
      const feedback_found: InterventionInstanceProgressFeedbackInterface = instance.attributes.progress.feedback_provided.find(
        (value: InterventionInstanceProgressFeedbackInterface) =>
          (value.thread_id !== null ? value.thread_id : '').toString() === thread_id.toString());
      if (feedback_found !== undefined) {
        return feedback_found.read;
      }
    } else {
      return false;
    }
  }
   */

  public isRead(answersheetId: number, instance: InterventionInstanceInterface, thread: MessageThreadsInterface) {
    if (!thread) {
      return false;
    }
    if (this.isFeedbackRequired(answersheetId, instance)) {
      const feedbackFound: InterventionInstanceProgressFeedbackInterface = instance.attributes.progress.feedback_provided.find(
        (value: InterventionInstanceProgressFeedbackInterface) => {
          if (Array.isArray(value.thread_id) && Array.isArray(value.answersheet_id)) {
            const found = value.thread_id.find((threadId: number) => threadId.toString() === thread.id.toString());
            return !!found;
          } else {
            return (value.thread_id !== null ? value.thread_id : '').toString() === thread.id.toString();
          }
        }
      );
      if (feedbackFound) {
        return feedbackFound.read;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public isUnread(answersheetId: number, instance: InterventionInstanceInterface, thread: MessageThreadsInterface) {
    if (!thread) {
      return false;
    }
    if (this.isFeedbackRequired(answersheetId, instance)) {
      const feedbackFound: InterventionInstanceProgressFeedbackInterface = instance.attributes.progress.feedback_provided.find(
        (value: InterventionInstanceProgressFeedbackInterface) => {
          if (Array.isArray(value.thread_id) && Array.isArray(value.answersheet_id)) {
            const found = value.thread_id.find((threadId: number) => threadId.toString() === thread.id.toString());
            return !!found;
          } else {
            return (value.thread_id !== null ? value.thread_id : '').toString() === thread.id.toString();
          }
        }
      );
      if (feedbackFound) {
        return !feedbackFound.read;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public hasUnreadFeedback(instance: InterventionInstanceInterface): boolean {
    const feedbackProvided: Array<InterventionInstanceProgressFeedbackInterface> = instance.attributes.progress.feedback_provided.filter(
      (value: InterventionInstanceProgressFeedbackInterface) => value.read === false
    );
    return feedbackProvided.length > 0;
  }

  public hasPendingAnswersheets(instance: InterventionInstanceInterface) {
    const feedbackProvided: Array<InterventionInstanceProgressFeedbackInterface> = instance.attributes.progress.feedback_provided.filter(
      (value: InterventionInstanceProgressFeedbackInterface) => {
        if (Array.isArray(value.thread_id) && Array.isArray(value.answersheet_id)) {
          return value.answersheet_id.length > value.thread_id.length;
        } else {
          return value.thread_id === null;
        }
      }
    );
    return feedbackProvided.length > 0;
  }

  public hasFeedback(answersheetId: number, threads: Array<MessageThreadsInterface>): boolean {
    if (answersheetId !== null && threads) {
      const found = threads.find(
        (thread: MessageThreadsInterface) => thread.attributes.answersheet_id.toString() === answersheetId.toString()
      );
      return found !== undefined;
    }
    return false;
  }

  public isAllRead(instance: InterventionInstanceInterface) {
    return instance.attributes.progress.feedback_provided.every(
      (progressFeedback: InterventionInstanceProgressFeedbackInterface) => progressFeedback.read
    );
  }

  public hasCreatedFeedback(alreadyCreatedThreads: Array<MessageThreadsInterface>, answersheetId?: number) {
    if (answersheetId) {
      return !!alreadyCreatedThreads.find(
        (thread: MessageThreadsInterface) => thread.attributes.answersheet_id.toString() === answersheetId.toString()
      );
    } else {
      return alreadyCreatedThreads.length > 0;
    }
  }

  public hasSubmittedFeedback(answersheetId: number, instance: InterventionInstanceInterface) {
    if (!!instance) {
      const found: InterventionInstanceProgressFeedbackInterface = instance.attributes.progress.feedback_provided.find(
        (value: InterventionInstanceProgressFeedbackInterface) => {
          if (Array.isArray(value.answersheet_id)) {
            return value.answersheet_id.includes(parseInt(answersheetId.toString(), 10));
          } else {
            return answersheetId.toString() === value.answersheet_id.toString();
          }
        }
      );
      return found.thread_id !== null;
    } else {
      return false;
    }
  }
}
