import { PayloadInterface } from './interface/payload.interface';

export class RequestBodyData implements PayloadInterface {
  data: {
    type: string;
    attributes: any;
  };

  constructor(setType: string, setAttr: any) {
    this.data = {
      type: setType,
      attributes: setAttr
    };
  }
}
