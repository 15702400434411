<div class="container-fluid text-center">
  <div class="col-sm-8 text-start">
    <h2>{{ 'patient-study.studies' | translate }}</h2>
    <hr />
  </div>

  <div class="row justify-content-center">
    <div class="col-12 col-sm-6 mb-2">
      <mat-form-field [style.width.%]="100" appearance="outline">
        <mat-label>{{'patient-study.search' | translate}}</mat-label>
        <input
          [attr.id]="'search'"
          (keyup.enter)="applyStudyFilter()"
          [(ngModel)]="filter['searchFilter']"
          aria-describedby="groupSearch"
          aria-label="Search"
          matInput
          type="text"
        />
        <button [attr.id]="'btnSearchStudy'" (click)="applyStudyFilter()" aria-label="Search" mat-button mat-button matSuffix>
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>

  <app-pagination-list
    #paginator
    (emitPageChange)="updatePagedStudies($event)"
    [_isLoading]="isLoading$ | async"
    [_items]="studies$ | async"
  ></app-pagination-list>

  <div class="row">
    <div *ngIf="(isLoading$ | async); else elseStudyBlock" class="col-sm">
      <app-loader></app-loader>
    </div>

    <ng-template #elseStudyBlock>
      <div class="col-sm">
        <div *ngFor="let group of pagedStudies$ | async; let i = index; trackBy: helper.trackByElement">
          <app-card-study
            (emitUpdatePerformed)="updateList()"
            [_study]="group"
            [_organisations]="organisations"
            [_patient]="patient"
          ></app-card-study>
        </div>
      </div>
    </ng-template>
  </div>
</div>
