<button [attr.id]="'btnDialogClose'" mat-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title>{{'intervention.delete_intervention_modal_title' | translate}}</h1>
<div mat-dialog-content class="mat-typography">
  <div *ngIf="intervention.attributes?.is_test === 0; then thenInactiveInterventionBody else elseTestInterventionBody"></div>
  <ng-template #thenInactiveInterventionBody>{{'intervention.delete_intervention_unpublished_modal_body' | translate}}</ng-template>
  <ng-template #elseTestInterventionBody>{{'intervention.delete_intervention_test_modal_body' | translate}}</ng-template>

  <mat-card appearance="outlined" class="card mx-auto text-center my-3 mat-elevation-z0">
    <mat-card-header class="card-header-center">
      <mat-card-title
        >{{((intervention.attributes.title) ? intervention.attributes.title : ('intervention.no_intervention_title' |
        translate))}}</mat-card-title
      >
      <mat-card-subtitle>
        Name: {{((intervention.attributes.name) ? intervention.attributes.name : ('intervention.no_intervention_name' | translate))}} (ID -
        {{intervention.id}})
      </mat-card-subtitle>
    </mat-card-header>
    <div class="row justify-content-center">
      <img
        [src]="helper.getAllMediaSupportPath(intervention.attributes.picture, intervention.attributes.study_id)"
        (error)="helper.getFallbackImage($event)"
        alt="No image"
        class="img-fluid card-img-top col-6"
        mat-card-image
      />
    </div>
    <mat-card-content>
      <p>
        {{((intervention.attributes.description) ? intervention.attributes.description : ('intervention.no_intervention_description' |
        translate))}}
      </p>

      <div class="row">
        <div class="col-12 col-sm-6 text-end">{{'intervention.available_languages' | translate}}:</div>
        <div class="col-12 col-sm-6 text-start">
          <div *ngFor="let code of intervention.attributes.locales">{{helper.parseLanguage(code) | translate}}</div>
        </div>
      </div>

      <ng-template #elseNoLanguageBlock>
        <p>{{'intervention.no_language_available' | translate}}</p>
      </ng-template>

      <div *ngIf="(intervention.attributes.unlock_diaries !== null) && (intervention.attributes.unlock_diaries.length === 1)">
        <p>
          <fa-icon [icon]="faBook"></fa-icon>
          {{'intervention.intervention_unlocks_diary' | translate}}
        </p>
      </div>
      <div *ngIf="(intervention.attributes.unlock_diaries !== null) && (intervention.attributes.unlock_diaries.length > 1)">
        <p>
          <fa-icon [icon]="faBook"></fa-icon>
          {{'intervention.intervention_unlocks_diaries' | translate}}
        </p>
      </div>

      <div class="row justify-content-center mt-2">
        <!-- Unguided/Guided/No information -->
        <div [ngSwitch]="intervention.attributes.intervention_type" class="text-padding col-sm-2">
          <span *ngSwitchCase="'unaccompanied'" class="text-info">
            <fa-icon [icon]="faHands"></fa-icon>
            {{'intervention.unguided' | translate}}
          </span>
          <span *ngSwitchCase="'accompanied'" class="text-info">
            <fa-icon [icon]="faHandsHelping"></fa-icon>
            {{'intervention.guided' | translate}}
          </span>
          <span *ngSwitchDefault class="text-muted">{{'intervention.no_information' | translate}}</span>
        </div>

        <!-- Inactive/Active -->
        <div class="text-padding col-sm-2">
          <span *ngIf="(intervention.attributes.is_active === 0); else activeBlock" class="text-danger">
            {{'intervention.inactive' | translate}}
          </span>
          <ng-template #activeBlock>
            <span class="text-success">{{'intervention.active' | translate}}</span>
          </ng-template>
        </div>

        <div class="text-padding col-sm-2">
          <span *ngIf="(intervention.attributes.is_running === 0); else runningBlock" class="text-danger">
            {{'intervention.not_running' | translate}}
          </span>
          <ng-template #runningBlock>
            <span class="text-success">{{'intervention.running' | translate}}</span>
          </ng-template>
        </div>

        <div class="text-padding col-sm-2">
          <span *ngIf="(intervention.attributes.is_test === 0); else isTestBlock" class="text-info">
            {{'intervention.is_not_test' | translate}}
          </span>
          <ng-template #isTestBlock>
            <span class="text-success">{{'intervention.is_test' | translate}}</span>
          </ng-template>
        </div>

        <div class="text-padding col-sm-2">
          <span class="text-info">{{'intervention.' + intervention.attributes.publishing_state | translate}}</span>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <p *ngIf="intervention.attributes?.is_test === 1"><strong>{{'intervention.delete_intervention_test_modal_note' | translate}}</strong></p>
</div>
<div mat-dialog-actions align="end">
  <button [attr.id]="'btnCancel'" mat-button mat-dialog-close>{{'intervention.cancel' | translate}}</button>
  <app-button-feedback
    [_setId]="'btnDeleteIntervention_' + intervention.id"
    (click)="deleteIntervention(intervention)"
    [_defaultButtonText]="intervention.attributes?.is_test === 0 ? 'intervention.delete_intervention_unpublished' : 'intervention.delete_intervention_test'"
    [_responseText]="deleteInterventionResponse | async"
  ></app-button-feedback>
</div>
