import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { HelperService } from '../../../services/helper/helper.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { filter, take } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { InstanceUpdateInformationComponent } from '../../intervention-instance/instance-update-information/instance-update-information.component';
import { InstanceUpdateConfirmationComponent } from '../../intervention-instance/instance-update-confirmation/instance-update-confirmation.component';
import { InterventionInstanceInterface } from '../../../models/interface/intervention-instances/intervention-instance.interface';
import { InstanceUpdateFormComponent } from '../../intervention-instance/instance-update-form/instance-update-form.component';
import { InterventionInstanceStore } from '../../../store/intervention-instance/component-store/intervention-instance.store';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-dialog-intervention-instance-update',
  templateUrl: './dialog-intervention-instance-update.component.html',
  styleUrls: ['./dialog-intervention-instance-update.component.scss'],
  providers: [InterventionInstanceStore]
})
export class DialogInterventionInstanceUpdateComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('instanceUpdateInformationContainer', { read: ViewContainerRef }) instanceUpdateInformationContainer: ViewContainerRef;
  @ViewChild('instanceUpdateContainer', { read: ViewContainerRef }) instanceUpdateContainer: ViewContainerRef;
  @ViewChild('instanceUpdateConfirmationContainer', { read: ViewContainerRef }) instanceUpdateConfirmationContainer: ViewContainerRef;
  public components;

  public instanceUpdateInformationComponentClass = InstanceUpdateInformationComponent;
  public instanceUpdateFormComponentClass = InstanceUpdateFormComponent;
  public instanceUpdateConfirmationComponentClass = InstanceUpdateConfirmationComponent;

  public progressSubject: BehaviorSubject<string | 'INFORMATION' | 'CONFIGURATION' | 'CONFIRMATION'> = new BehaviorSubject<string>(
    'INFORMATION'
  );

  public updateInstanceResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');

  public instance: InterventionInstanceInterface;

  public param;

  private isManagerSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private updateInstanceStateResponse$: Observable<any>;

  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private interventionInstanceStore: InterventionInstanceStore,
    private helperService: HelperService,
    private dialogRef: MatDialogRef<DialogInterventionInstanceUpdateComponent>
  ) {
    this.updateInstanceStateResponse$ = this.interventionInstanceStore.updateInstanceStateResponse$;
  }

  public get helper() {
    return this.helperService;
  }

  ngOnInit(): void {
    this.param = this.data.param;
    this.instance = this.data.instance;
    this.isManagerSubject.next(!!this.data.isManager);
    this.progressSubject.next('INFORMATION');
    this.components = [];
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.instanceUpdateInformationContainer.clear(); // Create the initial component dynamically inside the ng-template
      const component = this.instanceUpdateInformationContainer.createComponent<InstanceUpdateInformationComponent>(
        this.instanceUpdateInformationComponentClass
      ).instance;
      this.components = [...this.components, component];
    }, 0);
  }

  public pauseAndContinue(): void {
    const found = this.foundUpdateComponent();
    if (!found) {
      if (this.instanceUpdateContainer) {
        this.instanceUpdateContainer.clear();
        const component = this.instanceUpdateContainer.createComponent<InstanceUpdateFormComponent>(
          this.instanceUpdateFormComponentClass
        ).instance;

        component._collaborators = this.data.collaborators;
        component._instance = this.data.instance;
        component._intervention = this.data.intervention;
        component._members = this.data.members;
        component._questionnaires = this.data.questionnaires;
        component._id = this.data.id;
        component._myUserId = this.data.myUserId;

        this.components = [...this.components, component];
      }
    }

    if (!this.helper.isState(this.instance, 'canceled') && !this.helper.isState(this.instance, 'paused')) {
      if (this.isManagerSubject.value) {
        this.interventionInstanceStore.updateInstanceStateEM({ instanceId: this.instance.id, state: 'paused' });
      } else {
        this.interventionInstanceStore.updateInstanceState({ instanceId: this.instance.id, state: 'paused' });
      }

      this.subscriptions.push(
        this.updateInstanceStateResponse$
          .pipe(
            filter(value => !!value),
            take(1)
          )
          .subscribe((value: any) => {
            if (value instanceof HttpResponse) {
              this.progressSubject.next('CONFIGURATION');
            } else {
              this.progressSubject.next('INFORMATION');
            }
          })
      );
    } else {
      if (this.helper.isState(this.instance, 'paused')) {
        this.progressSubject.next('CONFIGURATION');
      }
    }
  }

  // Confirm instance creation settings
  public confirmUpdateSettings(): void {
    const found = this.foundUpdateComponent();
    if (found.confirmInstanceSettings()) {
      this.progressSubject.next('CONFIRMATION');
      this.initializeInstanceUpdateConfirmationComponent();
    }
  }

  public foundUpdateComponent(): InstanceUpdateFormComponent {
    return this.components.find(c => c instanceof InstanceUpdateFormComponent);
  }

  public foundUpdateConfirmationComponent(): InstanceUpdateConfirmationComponent {
    return this.components.find(c => c instanceof InstanceUpdateConfirmationComponent);
  }

  public initializeInstanceUpdateConfirmationComponent(): void {
    const found: InstanceUpdateFormComponent = this.foundUpdateComponent();
    const foundConfirmation: InstanceUpdateConfirmationComponent = this.foundUpdateConfirmationComponent();

    if (!foundConfirmation) {
      this.instanceUpdateConfirmationContainer.clear();
      // Create the initial component dynamically inside the ng-template
      const component = this.instanceUpdateConfirmationContainer.createComponent<InstanceUpdateConfirmationComponent>(
        this.instanceUpdateConfirmationComponentClass
      ).instance;

      component._collaborators = this.data.collaborators;
      component._instance = this.data.instance;
      component._intervention = this.data.intervention;
      component._members = this.data.members;
      component._questionnaires = this.data.questionnaires;
      component._id = this.data.id;
      component._myUserId = this.data.myUserId;
      component._payload = found.payload;

      this.components = [...this.components, component];
    }
  }

  // Return to instance update modal
  public goBackUpdate(state: string): void {
    this.progressSubject.next(state);
  }

  // Update an intervention instance
  public updateInterventionInstance(): void {
    if (this.updateInstanceResponse.value === 'DEFAULT') {
      this.updateInstanceResponse.next('LOADING');
      const found: InstanceUpdateConfirmationComponent = this.foundUpdateConfirmationComponent();
      this.subscriptions.push(
        found.updateInterventionInstance(found.instance.id).subscribe(
          (result: any) => {
            if (result instanceof HttpResponse) {
              found.unconfirmedSubject.next(false);
              found.alert.success('FORM_UPDATE_INSTANCE_SUCCEEDED');
              this.updateInstanceResponse.next('SUCCESS');
              setTimeout(() => {
                this.updateInstanceResponse.next('DEFAULT');
                this.dialogRef.close('SUCCESS');
              }, 2500);
            } else {
              this.updateInstanceResponse.next('FAILURE');
              setTimeout(() => {
                this.updateInstanceResponse.next('DEFAULT');
              }, 2500);
            }
          },
          () => {
            this.updateInstanceResponse.next('FAILURE');
            setTimeout(() => {
              this.updateInstanceResponse.next('DEFAULT');
            }, 2500);
          },
          () => {}
        )
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
