/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, OnInit } from '@angular/core';
import { FormGroupDirective, NgForm, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, forkJoin, iif, Observable, of, throwError } from 'rxjs';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { mergeMap, skip, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { faStar } from '@fortawesome/free-regular-svg-icons/faStar';
import { RequestBodyData } from '../../../models/request-body.data';
import { HelperService } from '../../../services/helper/helper.service';
import { UserInterface } from '../../../models/interface/user.interface';
import { InvitationInterface } from '../../../models/interface/invitation.interface';
import { AlertService } from '../../../services/alert/alert.service';
import { StudyInvitationInterface } from '../../../models/interface/study-invitation.interface';
import { StudyInterface } from '../../../models/interface/study/study.interface';
import { ProfileInterface } from '../../../models/interface/profile.interface';
import { StudyActionTypes } from '../../../store/study/study.action';
import { PayloadInterface } from '../../../models/interface/payload.interface';
import { environment } from '../../../../environments/environment';
import { UserActionTypes } from '../../../store/user/user.action';
import { StudyStore } from '../../../store/study/component-store/study.store';
import { UserStore } from '../../../store/user/component-store/user.store';
import { ErrorStateMatcher } from '@angular/material/core';

/**
 * Component:
 * Patient invitation study component displaying a form to send study invitations and assign new interventions;
 */

class CodeUsedMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && (control.dirty || control.touched));
  }
}

@Component({
  selector: 'app-patient-invitation-study',
  templateUrl: './patient-invitation-study.component.html',
  styleUrls: ['./patient-invitation-study.component.scss'],
  providers: [StudyStore, UserStore]
})
export class PatientInvitationStudyComponent implements OnInit {
  // Icons
  faStar = faStar;

  public isLoading$: Observable<boolean>;

  public patient: UserInterface;
  public memberInStudy: Array<StudyInterface> = [];

  // Form
  public studyForm: UntypedFormGroup;
  public studyList: Array<StudyInterface> = [];
  public submitted: boolean;

  public matcher = new CodeUsedMatcher();

  public invitationStudy: Array<StudyInvitationInterface> = [];

  public codeTaken$;

  public isHiddenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isHidden$: Observable<boolean>;

  public isShowingInvitationsSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isShowingInvitations$: Observable<boolean>;

  public profile$: Observable<ProfileInterface>;

  public selectedLanguage = localStorage.getItem('language');

  private addMembersMultipleStudiesResponse$: Observable<Array<any>>;

  private memberStudies$: Observable<Array<StudyInterface>>;

  private allInvitations$: Observable<Array<InvitationInterface>>;

  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private collabStudies: Array<StudyInterface> = [];
  private nonCollabStudies: Array<StudyInterface> = [];
  private pendingInvitation: Array<InvitationInterface> = [];
  private diff: Array<StudyInterface> = [];
  private codeTakenSubject = new BehaviorSubject(false);
  private studyCode: Array<{ study_id: number; codeTaken: boolean }> = [];

  constructor(
    private helperService: HelperService,
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private studyStore: StudyStore,
    private userStore: UserStore,
    private actions$: Actions,
    private store: Store<{ myProfile: ProfileInterface; allInvitations: Array<InvitationInterface> }>
  ) {
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.codeTaken$ = this.codeTakenSubject.asObservable();
    this.isHidden$ = this.isHiddenSubject.asObservable();
    this.isShowingInvitations$ = this.isShowingInvitationsSubject.asObservable();
    this.profile$ = store.select('myProfile');
    this.studyForm = this.formBuilder.group({
      studyList: new UntypedFormArray([])
    });
    this.addMembersMultipleStudiesResponse$ = this.studyStore.addMembersMultipleStudiesResponse$;
    this.memberStudies$ = this.userStore.memberStudies$;
    this.allInvitations$ = this.store.select('allInvitations');
  }

  public get fStudyList() {
    return this.studyForm.get('studyList') as UntypedFormArray;
  }

  public get helper() {
    return this.helperService;
  }

  @Input() set _patient(_patient: UserInterface) {
    if (_patient !== undefined) {
      this.patient = _patient;
    }
  }

  // Set study and its configuration from parent
  @Input() hide(_hide: boolean) {
    if (_hide !== undefined) {
      this.isHiddenSubject.next(_hide);
    }
  }

  ngOnInit(): void {
    const reqs: Array<Observable<any>> = [];
    this.store.dispatch({
      type: UserActionTypes.getAllInvitationsOfEcoachMembersType,
      payload: {}
    });
    reqs.push(this.allInvitations$.pipe(skip(1), take(1)));

    this.userStore.getMemberStudies({ userId: this.patient.id });
    reqs.push(this.memberStudies$.pipe(skip(1), take(1)));
    this.store.dispatch({
      type: StudyActionTypes.getCollaboratingStudiesType,
      payload: { type: 'study', include: 'roles', role: 'study.owner,study.ecoachmanager,study.ecoach' }
    });
    reqs.push(
      this.actions$.pipe(
        ofType(StudyActionTypes.getCollaboratingStudiesSuccessType, StudyActionTypes.getCollaboratingStudiesErrorType),
        take(1),
        mergeMap((result: any) =>
          iif(() => result.type === StudyActionTypes.getCollaboratingStudiesSuccessType, of(result), throwError(result))
        )
      )
    );
    this.store.dispatch({ type: StudyActionTypes.getNonCollaboratingStudiesType, payload: { type: 'study' } });
    reqs.push(
      this.actions$.pipe(
        ofType(StudyActionTypes.getNonCollaboratingStudiesSuccessType, StudyActionTypes.getNonCollaboratingStudiesErrorType),
        take(1),
        mergeMap((result: any) =>
          iif(() => result.type === StudyActionTypes.getNonCollaboratingStudiesSuccessType, of(result), throwError(result))
        )
      )
    );

    forkJoin(reqs).subscribe(
      (results: Array<any>) => {
        this.pendingInvitation = results[0].filter(
          (invitation: InvitationInterface) => invitation.attributes.email === this.patient.attributes.email
        );
        this.memberInStudy = results[1];
        this.collabStudies = results[2]['response']['body']['data'];
        this.collabStudies = this.collabStudies.filter((study: StudyInterface) => study.relationships.roles.data.length > 0);

        this.memberInStudy = this.memberInStudy.filter((study: StudyInterface) =>
          this.collabStudies.map(val => val.id.toString()).includes(study.id.toString())
        );

        this.nonCollabStudies = results[3]['response']['body']['data'];

        this.invitationStudy = [];
        this.pendingInvitation.forEach(pending => {
          const found = this.getStudyById(pending.attributes.model_id);
          const pair: StudyInvitationInterface = {
            invitation: pending,
            study: found
          };
          this.invitationStudy.push(pair);
        });
        const studyIds: Array<number> = this.memberInStudy.map(study => study.id);

        this.diff = this.collabStudies.filter(study => !studyIds.includes(study.id));

        // Checkbox
        this.studyList = this.diff;
        this.addCheckboxes();
        this.isLoadingSubject.next(false);
      },
      error => {
        console.error(error);
        this.isLoadingSubject.next(false);
      }
    );
  }

  public sendInvitation(patientId: number): Observable<any> {
    this.submitted = false;
    this.alertService.reset();
    this.showErrorMessage();

    this.codeTakenSubject.next(false);

    // Exclude disabled studies
    let studies = this.studyList;
    studies = studies.filter(
      (value, i: number) => (this.studyForm.controls.studyList as UntypedFormArray).controls[i].status !== 'DISABLED'
    );
    const selectedStudyIdsAndCodes = this.studyForm.value.studyList
      .map((v, i) => {
        if (v.checked) {
          if (v.code !== '' || v.code != null) {
            return { id: studies[i].id, code: v.code };
          } else {
            return { id: studies[i].id };
          }
        } else {
          return null;
        }
      })
      .filter(v => v !== null)
      .map(list => list);

    if (selectedStudyIdsAndCodes.length === 0) {
      return throwError('No studies selected');
    }

    const payload: Array<{ studyId: number; code: string; payload: PayloadInterface }> = selectedStudyIdsAndCodes
      .filter(v => !!v)
      .map((selected: { id: number; code: string }) => {
        if (!(selected.code === null || selected.code === '')) {
          return {
            studyId: selected.id,
            code: selected.code,
            payload: new RequestBodyData('users', {
              users: [{ id: patientId, code: selected.code }]
            }),
            language: this.selectedLanguage
          };
        } else {
          return {
            studyId: selected.id,
            code: selected.code,
            payload: new RequestBodyData('users', { users: [{ id: patientId }] }),
            language: this.selectedLanguage
          };
        }
      });

    this.studyStore.addMembersMultipleStudies(payload);
    return this.addMembersMultipleStudiesResponse$.pipe(
      skip(1),
      take(1),
      mergeMap((results: any) => {
        const codeUsedArray: Array<{ id: number; codeTaken: boolean; code: string }> = [];

        if (Array.isArray(results)) {
          results.forEach((res: HttpResponse<any> | HttpErrorResponse) => {
            if (res instanceof HttpErrorResponse) {
              if (res.status === 403) {
                if (
                  res.statusText === 'Forbidden' ||
                  (res.error.errors[0] && res.error.errors[0].title === 'Invalid Codes') ||
                  res.error.errors[0].code.toString() === '1500'
                ) {
                  const errorStudyCode = res.error.errors[0].detail.replace('The following codes are used already: ', '').trim();
                  const id = parseInt(
                    res.url.replace(environment.backendURL.toString() + '/api/v1/ecoach/studies/', '').replace(/\D+/g, ''),
                    10
                  );
                  codeUsedArray.push({ id, codeTaken: true, code: errorStudyCode });

                  this.codeTakenSubject.next(true);
                  const codes = res.error.errors[0].detail
                    .replace('The following codes are used already: ', '')
                    .replace(/\s/g, '')
                    .split(',');
                  const intersection = selectedStudyIdsAndCodes.filter(obj => codes.includes(obj.code));
                  const userIdsWithTakenCodes = intersection.map(x => x.id);
                  this.studyCode = this.studyCode.map((obj: { study_id: number; codeTaken: boolean }) => {
                    if (userIdsWithTakenCodes.includes(obj.study_id)) {
                      return { study_id: obj.study_id, codeTaken: true };
                    } else {
                      return { study_id: obj.study_id, codeTaken: false };
                    }
                  });
                  this.submitted = true;
                }
              }
            }
          });
          if (results.filter(res => res instanceof HttpErrorResponse).length > 0) {
            return throwError(results);
          }
        } else {
          if (results instanceof HttpErrorResponse) {
            if (results.status === 403 && results.statusText === 'Forbidden') {
              const errorStudyCode = results.error.errors[0].detail.replace('The following codes are used already: ', '').trim();
              const id = parseInt(
                results.url.replace(environment.backendURL.toString() + '/api/v1/ecoach/studies/', '').replace(/\D+/g, ''),
                10
              );
              codeUsedArray.push({ id, codeTaken: true, code: errorStudyCode });

              this.codeTakenSubject.next(true);
              const codes = results.error.errors[0].detail
                .replace('The following codes are used already: ', '')
                .replace(/\s/g, '')
                .split(',');
              const intersection = selectedStudyIdsAndCodes.filter(obj => codes.includes(obj.code));
              const userIdsWithTakenCodes = intersection.map(x => x.id);
              this.studyCode = this.studyCode.map((obj: { study_id: number; codeTaken: boolean }) => {
                if (userIdsWithTakenCodes.includes(obj.study_id)) {
                  return { study_id: obj.study_id, codeTaken: true };
                } else {
                  return { study_id: obj.study_id, codeTaken: false };
                }
              });
              this.submitted = true;
            }
            return throwError(results);
          }
        }
        return of('SUCCESS');
        /*
        if (!results.every(result => result instanceof HttpResponse)) {
          results.forEach((res: HttpResponse<any> | HttpErrorResponse) => {
            if (res instanceof HttpErrorResponse) {
              if (res.status === 403) {
                if (
                  res.statusText === 'OK' ||
                  (res.error.errors[0] && res.error.errors[0].title === 'Invalid Codes') ||
                  res.error.errors[0].code.toString() === '1500'
                ) {
                  const errorStudyCode = res.error.errors[0].detail.replace('The following codes are used already: ', '').trim();
                  const id = parseInt(
                    res.url.replace(environment.backendURL.toString() + '/api/v1/ecoach/studies/', '').replace(/\D+/g, ''),
                    10
                  );
                  codeUsedArray.push({ id, codeTaken: true, code: errorStudyCode });

                  this.codeTakenSubject.next(true);
                  const codes = res.error.errors[0].detail
                    .replace('The following codes are used already: ', '')
                    .replace(/\s/g, '')
                    .split(',');
                  const intersection = selectedStudyIdsAndCodes.filter(obj => codes.includes(obj.code));
                  const userIdsWithTakenCodes = intersection.map(x => x.id);
                  this.studyCode = this.studyCode.map((obj: { study_id: number; codeTaken: boolean }) => {
                    if (userIdsWithTakenCodes.includes(obj.study_id)) {
                      return { study_id: obj.study_id, codeTaken: true };
                    } else {
                      return { study_id: obj.study_id, codeTaken: false };
                    }
                  });
                  this.submitted = true;
                }
              }
            }
          });
          return throwError(results);
        } else {
          return of('SUCCESS');
        }
        */
      })
    );
  }

  public checked(): boolean {
    const arr = this.studyForm.value.studyList;
    return arr.length === 0 ? false : this.studyForm.value.studyList.reduce((a, b) => a || b);
  }

  public codeUsed(studyId: number): boolean {
    const found = this.studyCode.find(
      (taken: { study_id: number; codeTaken: boolean }) => taken.study_id.toString() === studyId.toString() && taken.codeTaken
    );
    return found === undefined ? false : found.codeTaken;
  }

  public hasPendingInvitation(study: StudyInterface): boolean {
    const found: StudyInvitationInterface = this.invitationStudy.find(
      (value: StudyInvitationInterface) => value.study.id.toString() === study.id.toString()
    );
    return found !== undefined;
  }

  public getStudyById(studyId: number): StudyInterface {
    const found = this.helper.findArrObjById(studyId, this.collabStudies);
    if (found === null) {
      return this.helper.findArrObjById(studyId, this.nonCollabStudies);
    } else {
      return found;
    }
  }

  public showErrorMessage(): void {
    this.submitted = false;
    this.alertService.reset();
    const isCheckedArr = this.studyForm.get('studyList').value.map(val => (val.checked === null ? false : val.checked));
    if (isCheckedArr.length === 0) {
      this.submitted = true;
      this.alertService.error('SELECTION_REQUIRED_STUDY');
      return;
    }
    const noneIsSelected = !(isCheckedArr.reduce((val, curr) => val || curr) && this.studyForm.get('studyList').value.length > 0);
    if (this.studyForm.invalid || this.studyForm.get('studyList').value === undefined || noneIsSelected) {
      this.submitted = true;
      this.alertService.error('SELECTION_REQUIRED_STUDY');
      return;
    }
  }

  public hideInvitation(): void {
    this.isShowingInvitationsSubject.next(false);
  }

  public getIncludedRolesOfStudy(studyId: number): any {
    const found: StudyInterface = this.helper.findArrObjById(studyId, this.collabStudies);
    return found.relationships?.roles?.data;
  }

  public setSelectedLanguage(event$): void {
    this.selectedLanguage = event$;
  }

  // Adding checkboxes on studyForm control
  private addCheckboxes(): void {
    this.studyForm = this.formBuilder.group({
      studyList: new UntypedFormArray([])
    });

    this.studyList.map((study: StudyInterface) => {
      // Disable those with pending invitations
      const found: StudyInvitationInterface = this.invitationStudy.find(
        (value: StudyInvitationInterface) => value.study.id.toString() === study.id.toString()
      );

      (this.studyForm.controls.studyList as UntypedFormArray).push(
        this.formBuilder.group({
          checked: [{ value: false, disabled: found !== undefined }],
          code: [{ value: null, disabled: found !== undefined }]
        })
      );
      this.studyCode.push({ study_id: study.id, codeTaken: false });
    });
  }
}
