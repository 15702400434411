import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SanitizeHtmlPipe } from '../../helpers/pipes/sanitizeHtml/sanitizeHtmlPipe';

@NgModule({
  declarations: [SanitizeHtmlPipe],
  imports: [CommonModule],
  exports: [SanitizeHtmlPipe]
})
export class PipeModule {}
