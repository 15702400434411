<div class="card-header" #topPage id="toppage">
  <mat-form-field appearance="outline" class="full-width">
    <mat-label>{{'answersheet-detail.select_language' | translate}}</mat-label>
    <mat-select [(value)]="selectedLocale" (closed)="onChange($event)">
      <mat-option *ngFor="let v of locales; index as i" [value]="v">{{helper.parseLanguage(v) | translate}}</mat-option>
    </mat-select>
  </mat-form-field>

  <button
    [attr.id]="'btnToggleCollapse'"
    (click)="helper.toggleSubject(isCollapseSubject)"
    color="primary"
    data-html="true"
    data-toggle="tooltip"
    mat-button
    title="{{'answersheet-detail.answersheet_show_and_hide' | translate}}"
    type="button"
  >
    <fa-icon *ngIf="(isCollapse$ | async); else elseDetailsBlock" [icon]="faChevronUp"></fa-icon>
    <ng-template #elseDetailsBlock>
      <fa-icon [icon]="faChevronDown"></fa-icon>
    </ng-template>
    {{'answersheet-detail.lesson_elements' | translate}}
  </button>
  <button (click)="toggleSmallLayout()" mat-button *ngIf="intervention?.attributes?.small_layout === 1">
    <div *ngIf="(isSmallLayout$ | async) === false; then thenMobileView else elseNormalView"></div>
    <ng-template #thenMobileView>
      <fa-icon *ngIf="(isSmallLayout$ | async) === false; else elseNormalView" [icon]="faEye"></fa-icon>
      {{'answersheet-detail.toggle_small_layout' | translate}}</ng-template
    >

    <ng-template #elseNormalView>{{'answersheet-detail.toggle_normal_layout' | translate}}</ng-template>
  </button>
</div>
<div [hidden]="isCollapse$ | async">
  <div [ngStyle]="getBackgroundStyle()" [ngClass]="{'smallLayout': (isSmallLayout$ | async)}" class="mx-auto card-body">
    <div class="mb-3">
      <ng-template #progressBarContainer></ng-template>
    </div>

    <ng-template #elementsContainer> </ng-template>
    <ion-button
      [attr.id]="'btnPreviousTopPage'"
      (click)="showTopPage(currentPageNumber - 1)"
      *ngIf="(currentPageNumber !== 0) && (pageIteration.length > 0) && hasEvaluatedPage('previous')"
      class="page-buttons"
      slot="start"
    >
      <ion-icon name="arrow-back"></ion-icon>
      {{ "lesson-preview.button_previous_page" | translate}}
    </ion-button>

    <ion-button
      [attr.id]="'btnNextTopPage'"
      (click)="showTopPage(currentPageNumber + 1)"
      *ngIf="(currentPageNumber < pageIteration.length) && (pageIteration.length > 0) && hasEvaluatedPage('next')"
      class="page-buttons"
      slot="end"
    >
      {{ "lesson-preview.button_next_page" | translate}}
      <ion-icon name="arrow-forward"></ion-icon>
    </ion-button>

    <div class="align-self-center">
      <div *ngIf="pageIteration.length > 0">
        <small>{{'lesson-preview.lesson_page' | translate}} - {{getPageNumber()}}</small>
      </div>
    </div>
  </div>
</div>
