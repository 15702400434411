<button [attr.id]="'btnDialogClose'" mat-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title>{{'admin-dashboard.create_organisation' | translate}}</h1>
<div mat-dialog-content class="mat-typography">
  <p>{{'admin-dashboard.create_organisation_description' | translate}}:</p>

  <div class="container text-start mt-3">
    <form [formGroup]="groupFormType">
      <div class="row justify-content-center">
        <mat-form-field appearance="outline" class="col-8 full-width">
          <mat-label>{{'group-creation.form_create_group_name' | translate}}</mat-label>
          <input [attr.id]="'name'" matInput formControlName="name" />
          <div *ngIf="submitted && f.name.invalid">
            <mat-error *ngIf="f.name.errors?.required">{{'group-creation.error_is_required' | translate}}</mat-error>
            <mat-error *ngIf="f.name.errors?.maxlength">{{'group-creation.error_max_255_characters' | translate}}</mat-error>
          </div>
        </mat-form-field>
      </div>

      <hr />

      <div>
        <div class="form-group">
          <strong>{{'group-creation.form_create_organisation_owner_description' | translate}}</strong>
        </div>
        <div class="form-group row justify-content-start">
          <div class="col-8">
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>{{'group-creation.form_create_group_owner' | translate}}</mat-label>
              <mat-select [attr.id]="'selectOwnerId'" (click)="resetFilter()" formControlName="owner_id" required>
                <mat-form-field class="full-width" appearance="fill">
                  <mat-label>{{'group-creation.filter_selection' | translate}}</mat-label>
                  <input
                    [attr.id]="'search'"
                    autocomplete="off"
                    matInput
                    (keyup)="onKeyFilter()"
                    [(ngModel)]="filter['userSelection']"
                    [ngModelOptions]="{standalone: true}"
                  />
                </mat-form-field>
                <mat-option [attr.id]="'option_' + owner.id" *ngFor="let owner of (allECoachesAsAdmin$ | async)" [value]="owner.id"
                  >{{owner?.attributes.email | translate}}</mat-option
                >
                <mat-option *ngIf="(allECoachesAsAdmin$ | async)?.length < 1"
                  >{{'group-creation.no_ecoach_available' | translate}}</mat-option
                >
              </mat-select>
              <mat-error *ngIf="submitted && (f.owner_id.value === -1)">{{'group-creation.error_selection_needed' | translate}}</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button [attr.id]="'btnCancel'" mat-button mat-dialog-close>{{'admin-dashboard.cancel' | translate}}</button>
  <app-button-feedback
    [_setId]="'btnCreateOrganisation'"
    (click)="createOrganisation()"
    [_isDisabled]="(f.owner_id.value === -1)"
    [_defaultButtonText]="'admin-dashboard.create_new_organisation'"
    [_responseText]="createOrganisationResponse | async"
  ></app-button-feedback>
</div>
