import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faSave } from '@fortawesome/free-regular-svg-icons/faSave';
import { faEraser } from '@fortawesome/free-solid-svg-icons/faEraser';
import { faRedo } from '@fortawesome/free-solid-svg-icons/faRedo';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { BehaviorSubject, mergeMap, Observable, of, skip, take, throwError } from 'rxjs';
import { InvitationInterface } from '../../../models/interface/invitation.interface';
import { HelperService } from '../../../services/helper/helper.service';
import { StudyInterface } from '../../../models/interface/study/study.interface';
import { StudyStore } from '../../../store/study/component-store/study.store';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-card-pending-invitation',
  templateUrl: './card-pending-invitation.component.html',
  styleUrls: ['./card-pending-invitation.component.css'],
  providers: [StudyStore]
})
export class CardPendingInvitationComponent implements OnInit {
  @Output()
  public emitDelete: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public emitUpdate: EventEmitter<any> = new EventEmitter<any>();

  // Icons
  faRedo = faRedo;
  faTrash = faTrash;
  faEraser = faEraser;
  faSave = faSave;

  public invitation: InvitationInterface;
  public studyCode: string;
  public isManager: boolean;
  public isECoach: boolean;
  public studies: Array<StudyInterface> = [];

  public currentDate = Math.round(Date.now() / 1000);

  // Response
  public addUpdateStudyCodeResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');
  public deleteStudyCodeResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');
  public resendStudyInvitationResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');
  public deleteStudyInvitationResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');

  private addMembersResponse$: Observable<any>;

  private updateMemberCodeResponse$: Observable<any>;
  private deleteMemberCodeResponse$: Observable<any>;
  private deleteStudyInvitationResponse$: Observable<any>;

  constructor(private helperService: HelperService, private studyStore: StudyStore) {
    this.addMembersResponse$ = this.studyStore.addMembersResponse$;
    this.updateMemberCodeResponse$ = this.studyStore.updateMemberCodeResponse$;
    this.deleteMemberCodeResponse$ = this.studyStore.deleteMemberCodeResponse$;
    this.deleteStudyInvitationResponse$ = this.studyStore.deleteStudyInvitationResponse$;
  }

  public get helper() {
    return this.helperService;
  }

  @Input()
  set _invitation(_invitation: InvitationInterface) {
    if (_invitation) {
      this.invitation = _invitation;
    }
  }

  @Input()
  set _studyCode(_studyCode: string) {
    if (_studyCode) {
      this.studyCode = _studyCode;
    } else {
      this.studyCode = '';
    }
  }

  @Input()
  set _isManager(_isManager: boolean) {
    this.isManager = !!_isManager;
  }

  @Input()
  set _isECoach(_isEcoach: boolean) {
    this.isECoach = !!_isEcoach;
  }

  @Input()
  set _setStudies(_setStudies: Array<StudyInterface>) {
    if (this.studies) {
      this.studies = _setStudies;
    } else {
      this.studies = [];
    }
  }

  ngOnInit(): void {
    this.isExpired();
  }

  public addUpdateStudyCode(studyId: number, email: string, invitationId: number, codeParam?): void {
    this.addUpdateStudyCodeResponse.next('LOADING');
    if (this.isManager) {
      this.studyStore.addUpdateMemberCodeEM({ invitationId, codeParam });
    } else {
      this.studyStore.addUpdateMemberCode({ invitationId, codeParam });
    }
    this.updateMemberCodeResponse$
      .pipe(
        skip(1),
        take(1),
        mergeMap((result: any) => {
          if (result instanceof HttpResponse) {
            return of(result);
          } else {
            return throwError(result);
          }
        })
      )
      .subscribe(
        () => {
          this.addUpdateStudyCodeResponse.next('SUCCESS');
        },
        () => {
          this.addUpdateStudyCodeResponse.next('FAILURE');
          setTimeout(() => {
            this.addUpdateStudyCodeResponse.next('DEFAULT');
          }, 2500);
        },
        () => {
          setTimeout(() => {
            this.addUpdateStudyCodeResponse.next('DEFAULT');
          }, 2500);
        }
      );
  }

  public deleteStudyCode(studyId: number, email: string, invitationId: number): void {
    this.deleteStudyCodeResponse.next('LOADING');
    if (this.isManager) {
      this.studyStore.deleteCodeOfStudyInvitationEM({ invitationId });
    } else {
      this.studyStore.deleteCodeOfStudyInvitation({ invitationId });
    }
    this.deleteMemberCodeResponse$
      .pipe(
        skip(1),
        take(1),
        mergeMap((result: any) => {
          if (result instanceof HttpResponse) {
            return of(result);
          } else {
            return throwError(result);
          }
        })
      )
      .subscribe(
        () => {
          this.deleteStudyCodeResponse.next('SUCCESS');
          this.studyCode = '';
          setTimeout(() => {
            this.deleteStudyCodeResponse.next('DEFAULT');
          }, 2500);
        },
        () => {
          this.deleteStudyCodeResponse.next('FAILURE');
          setTimeout(() => {
            this.deleteStudyCodeResponse.next('DEFAULT');
          }, 2500);
        }
      );
  }

  public resendStudyInvitation(studyId: number, email: string, invitation: InvitationInterface): void {
    const payloadStudy = {
      data: {
        type: 'users',
        attributes: {
          users: [{ email }]
        }
      }
    };
    this.resendStudyInvitationResponse.next('LOADING');
    if (this.isManager) {
      this.studyStore.resendStudyInvitationEM({ studyId, invitationsParam: [invitation.id] });
    } else {
      this.studyStore.addMembers({ studyId, payload: payloadStudy });
    }
    this.addMembersResponse$.pipe(skip(1), take(1)).subscribe(
      result => {
        if (result instanceof HttpResponse) {
          this.resendStudyInvitationResponse.next('SUCCESS');
          this.emitUpdate.emit();
          setTimeout(() => {
            this.resendStudyInvitationResponse.next('DEFAULT');
          }, 2500);
        } else {
          this.resendStudyInvitationResponse.next('FAILURE');
          setTimeout(() => {
            this.resendStudyInvitationResponse.next('DEFAULT');
          }, 2500);
        }
      },
      () => {
        this.resendStudyInvitationResponse.next('FAILURE');
        setTimeout(() => {
          this.resendStudyInvitationResponse.next('DEFAULT');
        }, 2500);
      }
    );
  }

  public deleteStudyInvitation(studyId: number, email: string, invitationId: number): void {
    this.deleteStudyInvitationResponse.next('LOADING');
    if (this.isManager) {
      this.studyStore.deleteStudyInvitationEM({ invitationId });
    } else {
      this.studyStore.deleteCodeOfStudyInvitation({ invitationId });
    }
    this.deleteStudyInvitationResponse$.pipe(skip(1), take(1)).subscribe(
      result => {
        if (result instanceof HttpResponse) {
          this.deleteStudyInvitationResponse.next('SUCCESS');
          this.emitDelete.emit();
        } else {
          this.deleteStudyInvitationResponse.next('FAILURE');
        }
        setTimeout(() => {
          this.deleteStudyInvitationResponse.next('DEFAULT');
        }, 2500);
      },
      () => {
        this.deleteStudyInvitationResponse.next('FAILURE');
        setTimeout(() => {
          this.deleteStudyInvitationResponse.next('DEFAULT');
        }, 2500);
      }
    );
  }

  public getStudyById(studyId: number): StudyInterface {
    return this.helper.findArrObjById(studyId, this.studies);
  }

  public isExpired(): boolean {
    return this.invitation.attributes.expires_at < this.currentDate;
  }

  public subscribeToObservable(responseSubject: BehaviorSubject<string>, observe: Observable<any>, eventEmitter: EventEmitter<any>) {
    observe.subscribe(
      () => {
        responseSubject.next('SUCCESS');
        eventEmitter.emit();
      },
      () => {
        responseSubject.next('FAILURE');
        setTimeout(() => {
          responseSubject.next('DEFAULT');
        }, 2500);
      },
      () => {
        setTimeout(() => {
          responseSubject.next('DEFAULT');
        }, 2500);
      }
    );
  }
}
