import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { catchError, EMPTY, forkJoin, mergeMap, Observable, tap, throwError } from 'rxjs';
import { UserInterface } from '../../../models/interface/user.interface';
import { StudyInterface } from '../../../models/interface/study/study.interface';
import { StudyService } from '../../../services/api/study/study.service';
import { StudyMediaInterface } from '../../../models/interface/study/study-media.interface';
import { StudyDetailsTranslationsInterface } from 'src/app/models/interface/study/study-details_translations.interface';

export interface StudiesState {
  allStudies: StudyInterface[];
  allStudiesOwners: UserInterface[];
  studyDetail: StudyInterface | StudyDetailsTranslationsInterface | null;
  createStudyResponse: any;
  updateStudyResponse: any;
  addCollaboratorsResponse: any;
  deleteCollaboratorsResponse: any;
  studyMembers: UserInterface[];
  addMembersResponse: any;
  addMembersMultipleStudiesResponse: any;
  deleteMembersResponse: any;
  updateMemberCodeResponse: any;
  deleteMemberCodeResponse: any;
  studyInvitationsECoach: any;
  uploadStudyMediaResponse: any;
  studyMedia: Array<StudyMediaInterface>;
  deleteStudyInvitationResponse: any;
  assignStudyToOrganisationResponse: any;
  getStudyCSVReportQueueResponse: any;
  deleteStudyAdminResponse: any;
  addCollaboratorToAllInterventionInstancesOfECoachResponse: any;
}

@Injectable()
export class StudyStore extends ComponentStore<StudiesState> {
  readonly allStudies$: Observable<StudyInterface[]> = this.select(state => state.allStudies, {
    debounce: true
  });

  readonly allStudiesOwners$: Observable<UserInterface[]> = this.select(state => state.allStudiesOwners, {
    debounce: true
  });

  readonly studyDetail$: Observable<StudyInterface | StudyDetailsTranslationsInterface> = this.select(state => state.studyDetail, {
    debounce: true
  });

  readonly createStudyResponse$: Observable<any> = this.select(state => state.createStudyResponse, {
    debounce: true
  });

  readonly updateStudyResponse$: Observable<any> = this.select(state => state.updateStudyResponse, {
    debounce: true
  });

  readonly addCollaboratorsResponse$: Observable<any> = this.select(state => state.addCollaboratorsResponse, {
    debounce: true
  });

  readonly deleteCollaboratorsResponse$: Observable<any> = this.select(state => state.deleteCollaboratorsResponse, {
    debounce: true
  });

  readonly studyMembers$: Observable<any> = this.select(state => state.studyMembers, {
    debounce: true
  });

  readonly addMembersResponse$: Observable<any> = this.select(state => state.addMembersResponse, {
    debounce: true
  });

  readonly addMembersMultipleStudiesResponse$: Observable<any> = this.select(state => state.addMembersMultipleStudiesResponse, {
    debounce: true
  });

  readonly deleteMembersResponse$: Observable<any> = this.select(state => state.deleteMembersResponse, {
    debounce: true
  });

  readonly updateMemberCodeResponse$: Observable<any> = this.select(state => state.updateMemberCodeResponse, {
    debounce: true
  });

  readonly deleteMemberCodeResponse$: Observable<any> = this.select(state => state.deleteMemberCodeResponse, {
    debounce: true
  });

  readonly studyInvitationsECoach$: Observable<any> = this.select(state => state.studyInvitationsECoach, {
    debounce: true
  });

  readonly uploadStudyMediaResponse$: Observable<any> = this.select(state => state.uploadStudyMediaResponse, {
    debounce: true
  });

  readonly studyMedia$: Observable<any> = this.select(state => state.studyMedia, {
    debounce: true
  });

  readonly deleteStudyInvitationResponse$: Observable<any> = this.select(state => state.deleteStudyInvitationResponse, {
    debounce: true
  });

  readonly assignStudyToOrganisationResponse$: Observable<any> = this.select(state => state.assignStudyToOrganisationResponse, {
    debounce: true
  });

  readonly getStudyCSVReportQueueResponse$: Observable<any> = this.select(state => state.getStudyCSVReportQueueResponse, {
    debounce: true
  });

  readonly deleteStudyAdminResponse$: Observable<any> = this.select(state => state.deleteStudyAdminResponse, {
    debounce: true
  });

  readonly addCollaboratorToAllInterventionInstancesOfECoachResponse$: Observable<any> = this.select(
    state => state.addCollaboratorToAllInterventionInstancesOfECoachResponse,
    {
      debounce: true
    }
  );

  readonly updateStudyState = this.updater(
    (
      state,
      payload: {
        allStudies?: StudyInterface[];
        allStudiesOwners?: UserInterface[];
        studyDetail?: StudyInterface;
        createStudyResponse?: any;
        updateStudyResponse?: any;
        addCollaboratorsResponse?: any;
        deleteCollaboratorsResponse?: any;
        studyMembers?: any;
        addMembersResponse?: any;
        addMembersMultipleStudiesResponse?: any;
        deleteMembersResponse?: any;
        updateMemberCodeResponse?: any;
        deleteMemberCodeResponse?: any;
        studyInvitationsECoach?: any;
        uploadStudyMediaResponse?: any;
        studyMedia?: any;
        deleteStudyInvitationResponse?: any;
        assignStudyToOrganisationResponse?: any;
        getStudyCSVReportQueueResponse?: any;
        deleteStudyAdminResponse?: any;
        addCollaboratorToAllInterventionInstancesOfECoachResponse?: any;
      }
    ) => ({
      allStudies: payload.allStudies ? payload.allStudies : state.allStudies,
      allStudiesOwners: payload.allStudiesOwners ? payload.allStudiesOwners : state.allStudiesOwners,
      studyDetail: payload.studyDetail ? payload.studyDetail : state.studyDetail,
      createStudyResponse: payload.createStudyResponse ? payload.createStudyResponse : state.createStudyResponse,
      updateStudyResponse: payload.updateStudyResponse ? payload.updateStudyResponse : state.updateStudyResponse,
      addCollaboratorsResponse: payload.addCollaboratorsResponse ? payload.addCollaboratorsResponse : state.addCollaboratorsResponse,
      deleteCollaboratorsResponse: payload.deleteCollaboratorsResponse
        ? payload.deleteCollaboratorsResponse
        : state.deleteCollaboratorsResponse,
      studyMembers: payload.studyMembers ? payload.studyMembers : state.studyMembers,
      addMembersResponse: payload.addMembersResponse ? payload.addMembersResponse : state.addMembersResponse,
      addMembersMultipleStudiesResponse: payload.addMembersMultipleStudiesResponse
        ? payload.addMembersMultipleStudiesResponse
        : state.addMembersMultipleStudiesResponse,
      deleteMembersResponse: payload.deleteMembersResponse ? payload.deleteMembersResponse : state.deleteMembersResponse,
      updateMemberCodeResponse: payload.updateMemberCodeResponse ? payload.updateMemberCodeResponse : state.updateMemberCodeResponse,
      deleteMemberCodeResponse: payload.deleteMemberCodeResponse ? payload.deleteMemberCodeResponse : state.deleteMemberCodeResponse,
      studyInvitationsECoach: payload.studyInvitationsECoach ? payload.studyInvitationsECoach : state.studyInvitationsECoach,
      uploadStudyMediaResponse: payload.uploadStudyMediaResponse ? payload.uploadStudyMediaResponse : state.uploadStudyMediaResponse,
      studyMedia: payload.studyMedia ? payload.studyMedia : state.studyMedia,
      deleteStudyInvitationResponse: payload.deleteStudyInvitationResponse
        ? payload.deleteStudyInvitationResponse
        : state.deleteStudyInvitationResponse,
      assignStudyToOrganisationResponse: payload.assignStudyToOrganisationResponse
        ? payload.assignStudyToOrganisationResponse
        : state.assignStudyToOrganisationResponse,
      getStudyCSVReportQueueResponse: payload.getStudyCSVReportQueueResponse
        ? payload.getStudyCSVReportQueueResponse
        : state.getStudyCSVReportQueueResponse,
      deleteStudyAdminResponse: payload.deleteStudyAdminResponse ? payload.deleteStudyAdminResponse : state.deleteStudyAdminResponse,
      addCollaboratorToAllInterventionInstancesOfECoachResponse: payload.addCollaboratorToAllInterventionInstancesOfECoachResponse
        ? payload.addCollaboratorToAllInterventionInstancesOfECoachResponse
        : state.addCollaboratorToAllInterventionInstancesOfECoachResponse
    })
  );

  readonly getAllStudies = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) =>
        this.studyService
          .getAllStudies(
            payload.type,
            payload.interventionType,
            payload.isShareable,
            payload.isSubscribable,
            payload.isPrivate,
            payload.isActive,
            payload.include,
            payload.organisation_id
          )
          .pipe(
            tap({
              next: (result: any) => {
                this.updateStudyState({ allStudies: result.body.data });
              },
              error: e => throwError(e)
            }),
            catchError(error => EMPTY)
          )
      )
    )
  );

  readonly getStudyDetail = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) =>
        this.studyService.getStudyDetail(payload.studyId, payload.include).pipe(
          tap({
            next: (result: any) => {
              this.updateStudyState({ studyDetail: result.body.data });
            },
            error: e => throwError(e)
          }),
          catchError(error => EMPTY)
        )
      )
    )
  );

  readonly createStudy = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) => {
        this.updateStudyState({ createStudyResponse: null });
        return this.studyService.createStudy(payload.name, payload.type, payload.ownerId, payload.organisationId).pipe(
          tap({
            next: (result: any) => this.updateStudyState({ createStudyResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateStudyState({ createStudyResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly createStudyAdmin = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) => {
        this.updateStudyState({ createStudyResponse: null });
        return this.studyService.createStudyAdmin(payload.name, payload.type, payload.ownerId, payload.organisationId).pipe(
          tap({
            next: (result: any) => this.updateStudyState({ createStudyResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateStudyState({ createStudyResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly updateStudy = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) => {
        this.updateStudyState({ updateStudyResponse: null });
        return this.studyService
          .updateStudy(
            payload.studyId,
            payload.name,
            payload.languages,
            payload.translationContent,
            payload.accessType,
            payload.password,
            payload.isPrivate,
            payload.startsAt,
            payload.endsAt,
            payload.isShareable,
            payload.isUnsubscribable,
            payload.isRunning,
            payload.isActive,
            payload.picture
          )
          .pipe(
            tap({
              next: (result: any) => this.updateStudyState({ updateStudyResponse: result }),
              error: e => throwError(e)
            }),
            catchError(error => {
              this.updateStudyState({ updateStudyResponse: error });
              return EMPTY;
            })
          );
      })
    )
  );

  readonly addCollaborators = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) => {
        this.updateStudyState({ addCollaboratorsResponse: null });
        return this.studyService.addCollaborators(payload.studyId, payload.payload).pipe(
          tap({
            next: (result: any) => this.updateStudyState({ addCollaboratorsResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateStudyState({ addCollaboratorsResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly deleteCollaborators = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) => {
        this.updateStudyState({ deleteCollaboratorsResponse: null });
        return this.studyService.deleteCollaborators(payload.studyId, payload.payload).pipe(
          tap({
            next: (result: any) => this.updateStudyState({ deleteCollaboratorsResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateStudyState({ deleteCollaboratorsResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly getMembers = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) =>
        this.studyService.getMembers(payload.studyId, payload.include).pipe(
          tap({
            next: (result: any) => {
              this.updateStudyState({ studyMembers: result.body.data });
            },
            error: e => throwError(e)
          }),
          catchError(error => EMPTY)
        )
      )
    )
  );

  readonly getECoachMembers = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) =>
        this.studyService.getEcoachMembers(payload.studyId).pipe(
          tap({
            next: (result: any) => {
              this.updateStudyState({ studyMembers: result.body.data });
            },
            error: e => throwError(e)
          }),
          catchError(error => EMPTY)
        )
      )
    )
  );

  readonly addMembers = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) => {
        this.updateStudyState({ addMembersResponse: null });
        return this.studyService.addMembers(payload.studyId, payload.payload, payload.language).pipe(
          tap({
            next: (result: any) => this.updateStudyState({ addMembersResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateStudyState({ addMembersResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly addMembersMultipleStudies = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payloads: Array<any>) => {
        this.updateStudyState({ addMembersMultipleStudiesResponse: null });
        const reqs: Array<Observable<any>> = [];
        payloads.forEach(req => {
          reqs.push(this.studyService.addMembers(req.studyId, req.payload, req.language));
        });
        return forkJoin(reqs).pipe(
          tap({
            next: (results: any) => this.updateStudyState({ addMembersMultipleStudiesResponse: results }),
            error: e => throwError(e)
          }),
          catchError(errors => {
            this.updateStudyState({ addMembersMultipleStudiesResponse: errors });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly deleteMembers = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) => {
        this.updateStudyState({ deleteMembersResponse: null });
        return this.studyService.deleteMembers(payload.studyId, payload.payload).pipe(
          tap({
            next: (result: any) => this.updateStudyState({ deleteMembersResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateStudyState({ deleteMembersResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly updateMemberCode = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) => {
        this.updateStudyState({ updateMemberCodeResponse: null });
        return this.studyService.updateMemberCode(payload.studyId, payload.payload).pipe(
          tap({
            next: (result: any) => this.updateStudyState({ updateMemberCodeResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateStudyState({ updateMemberCodeResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly addUpdateMemberCode = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) => {
        this.updateStudyState({ updateMemberCodeResponse: null });
        return this.studyService.addUpdateMemberCode(payload.invitationId, payload.codeParam).pipe(
          tap({
            next: (result: any) => this.updateStudyState({ updateMemberCodeResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateStudyState({ updateMemberCodeResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly addUpdateMemberCodeEM = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) => {
        this.updateStudyState({ updateMemberCodeResponse: null });
        return this.studyService.addUpdateMemberCodeEM(payload.invitationId, payload.codeParam).pipe(
          tap({
            next: (result: any) => this.updateStudyState({ updateMemberCodeResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateStudyState({ updateMemberCodeResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly deleteMemberCodeOfPendingInvitation = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) => {
        this.updateStudyState({ deleteMemberCodeResponse: null });
        return this.studyService.deleteMemberCodeOfPendingInvitation(payload.studyId, payload.userId).pipe(
          tap({
            next: (result: any) => this.updateStudyState({ deleteMemberCodeResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateStudyState({ deleteMemberCodeResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly deleteCodeOfStudyInvitation = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) => {
        this.updateStudyState({ deleteMemberCodeResponse: null });
        return this.studyService.deleteCodeOfStudyInvitation(payload.invitationId).pipe(
          tap({
            next: (result: any) => this.updateStudyState({ deleteMemberCodeResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateStudyState({ deleteMemberCodeResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly deleteCodeOfStudyInvitationEM = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) => {
        this.updateStudyState({ deleteMemberCodeResponse: null });
        return this.studyService.deleteCodeOfStudyInvitationEM(payload.invitationId).pipe(
          tap({
            next: (result: any) => this.updateStudyState({ deleteMemberCodeResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateStudyState({ deleteMemberCodeResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly getEcoachStudyInvitations = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) =>
        this.studyService.getEcoachStudyInvitations(payload.studyId).pipe(
          tap({
            next: (result: any) => this.updateStudyState({ studyInvitationsECoach: result.body.data }),
            error: e => throwError(e)
          }),
          catchError(error => EMPTY)
        )
      )
    )
  );

  readonly getAllPendingStudyInvitations = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) =>
        this.studyService.getAllPendingStudyInvitations(payload.studyId).pipe(
          tap({
            next: (result: any) => this.updateStudyState({ studyInvitationsECoach: result.body.data }),
            error: e => throwError(e)
          }),
          catchError(error => EMPTY)
        )
      )
    )
  );

  readonly getStudyDetailAndTranslation = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) => {
        this.updateStudyState({ studyDetail: null });
        return this.studyService.getStudyDetailAndTranslation(payload.studyId, payload.include).pipe(
          tap({
            next: (result: any) => {
              this.updateStudyState({ studyDetail: result.body.data });
            },
            error: e => throwError(e)
          }),
          catchError(error => EMPTY)
        );
      })
    )
  );

  readonly uploadStudyMedia = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) => {
        this.updateStudyState({ uploadStudyMediaResponse: null });
        return this.studyService.uploadStudyMedia(payload.studyId, payload.fileToUpload).pipe(
          tap({
            next: (result: any) => {
              this.updateStudyState({ uploadStudyMediaResponse: result });
            },
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateStudyState({ uploadStudyMediaResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly getStudyMedia = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) =>
        this.studyService.getStudyMedia(payload.studyId).pipe(
          tap({
            next: (result: any) => this.updateStudyState({ studyMedia: result.body.data }),
            error: e => throwError(e)
          }),
          catchError(error => EMPTY)
        )
      )
    )
  );

  readonly resendStudyInvitationEM = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) => {
        this.updateStudyState({ addMembersResponse: null });
        return this.studyService.resendStudyInvitationEM(payload.studyId, payload.invitationsParam).pipe(
          tap({
            next: (result: any) => this.updateStudyState({ addMembersResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateStudyState({ addMembersResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly deleteStudyInvitation = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) => {
        this.updateStudyState({ deleteStudyInvitationResponse: null });
        return this.studyService.deleteStudyInvitation(payload.invitationId).pipe(
          tap({
            next: (result: any) => this.updateStudyState({ deleteStudyInvitationResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateStudyState({ deleteStudyInvitationResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly deleteStudyInvitationEM = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) => {
        this.updateStudyState({ deleteStudyInvitationResponse: null });
        return this.studyService.deleteStudyInvitationEM(payload.invitationId).pipe(
          tap({
            next: (result: any) => this.updateStudyState({ deleteStudyInvitationResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateStudyState({ deleteStudyInvitationResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly assignStudyToOrganisation = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) => {
        this.updateStudyState({ assignStudyToOrganisationResponse: null });
        return this.studyService.assignStudyToOrganisation(payload.studyId, payload.organisationId).pipe(
          tap({
            next: (result: any) => this.updateStudyState({ assignStudyToOrganisationResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateStudyState({ assignStudyToOrganisationResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly getStudyCSVReportQueue = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) => {
        this.updateStudyState({ getStudyCSVReportQueueResponse: null });
        return this.studyService.getStudyCSVReportQueue(payload.studyId, payload.meta, payload.diaryInstances).pipe(
          tap({
            next: (result: any) => this.updateStudyState({ getStudyCSVReportQueueResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateStudyState({ getStudyCSVReportQueueResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly deleteStudyAdmin = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) => {
        this.updateStudyState({ deleteStudyAdminResponse: null });
        return this.studyService.deleteStudyAdmin(payload.studyId).pipe(
          tap({
            next: (result: any) => this.updateStudyState({ deleteStudyAdminResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateStudyState({ deleteStudyAdminResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly addCollaboratorToAllInterventionInstancesOfECoach = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) => {
        this.updateStudyState({ addCollaboratorToAllInterventionInstancesOfECoachResponse: null });
        return this.studyService
          .addCollaboratorToAllInterventionInstancesOfECoach(payload.newECoach, payload.currentECoach, payload.studyId)
          .pipe(
            tap({
              next: (result: any) => this.updateStudyState({ addCollaboratorToAllInterventionInstancesOfECoachResponse: result }),
              error: e => throwError(e)
            }),
            catchError(error => {
              this.updateStudyState({ addCollaboratorToAllInterventionInstancesOfECoachResponse: error });
              return EMPTY;
            })
          );
      })
    )
  );

  constructor(private studyService: StudyService) {
    super({
      allStudies: [],
      allStudiesOwners: [],
      studyDetail: null,
      createStudyResponse: null,
      updateStudyResponse: null,
      addCollaboratorsResponse: null,
      deleteCollaboratorsResponse: null,
      studyMembers: [],
      addMembersResponse: null,
      addMembersMultipleStudiesResponse: null,
      deleteMembersResponse: null,
      updateMemberCodeResponse: null,
      deleteMemberCodeResponse: null,
      studyInvitationsECoach: [],
      uploadStudyMediaResponse: null,
      studyMedia: [],
      deleteStudyInvitationResponse: null,
      assignStudyToOrganisationResponse: null,
      getStudyCSVReportQueueResponse: null,
      deleteStudyAdminResponse: null,
      addCollaboratorToAllInterventionInstancesOfECoachResponse: null
    });
  }
}
