import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';

@Injectable({
  providedIn: 'root'
})
export class FcmMessageService {
  public tokenInstance = null;
  currentMessage = new BehaviorSubject(null);

  constructor(private angularFireMessaging: AngularFireMessaging) {
    this.angularFireMessaging.messages.subscribe(_messaging => {
      console.log('messages', _messaging);
    });
  }
  requestPermission() {
    this.angularFireMessaging.getToken.subscribe(
      token => {
        console.log(token);
        this.tokenInstance = token;
      },
      err => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(payload => {
      console.log('New message received.');
      this.currentMessage.next(payload);
    });
  }

  /*
  public messaging: firebase.messaging.Messaging = null;
  public token_instance = null;
  currentMessageReceived$ = new BehaviorSubject(null);
  messageQueue = [];

  constructor() {

    if (firebase.messaging.isSupported()) {
      console.log('Browser supports FCM...');

      // If firebase has been initialized
      if (!firebase.apps.length) {
        firebase.initializeApp(environment.firebaseConfig);
      }
      this.messaging = firebase.messaging();

      this.messaging.getToken().then((currentToken) => {
        this.token_instance = currentToken;
      });

      this.messaging.onMessage((payload) => {
        console.log('Message received. ', payload);
        this.currentMessageReceived$.next(payload);
        this.messageQueue.push(payload);
      });
    }

  }
  */
}
