import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { catchError, EMPTY, forkJoin, Observable, switchMap, tap, throwError } from 'rxjs';
import { InterventionInstanceInterface } from '../../../models/interface/intervention-instances/intervention-instance.interface';
import { ActivityInterface } from '../../../models/interface/activity.interface';
import { LessonInterface } from '../../../models/interface/lesson.interface';
import { InterventionInstanceService } from '../../../services/api/intervention-instance/intervention-instance.service';
import { HttpResponse } from '@angular/common/http';
import { InterventionInstanceLogInterface } from 'src/app/models/interface/intervention-instances/intervention-instance.log.interface';

export interface InterventionInstancesState {
  instancesOfIntervention: InterventionInstanceInterface[];
  instance: InterventionInstanceInterface | null;
  allInstancesOfInterventionForECoach: InterventionInstanceInterface[];
  allInstancesOfInterventionOfPatient: InterventionInstanceInterface[];
  updateInstanceStateResponse: any;
  submitFeedbackAsECoachResponse: any;
  createInstanceAsECoachResponse: any;
  createMultipleInstancesAsECoachResponse: any;
  allMyInstancesOfStudy: InterventionInstanceInterface[];
  updateInstanceResponse: any;
  activitiesOfInstance: Array<ActivityInterface>;
  activitiesOfMultipleInstances: { activities: Array<ActivityInterface>; questionnaires: Array<LessonInterface> };
  unlockQuestionnaireAsECoachResponse: any;
  unpauseInstanceResponse: any;
  getInstancePDFReportQueueResponse: any;
  unlockMyInstancesAsEcoachResponse: any;
  addECoachToECoachInterventionInstancesOfStudyResponse: any;
  logsOfInterventionInstance: Array<InterventionInstanceLogInterface>;
  allInterventionInstances: Array<InterventionInstanceInterface>;
}

@Injectable()
export class InterventionInstanceStore extends ComponentStore<InterventionInstancesState> {
  readonly instancesOfIntervention$: Observable<InterventionInstanceInterface[]> = this.select(state => state.instancesOfIntervention, {
    debounce: true
  });
  readonly instance$: Observable<InterventionInstanceInterface | null> = this.select(state => state.instance, {
    debounce: true
  });
  readonly allInstancesOfInterventionForECoach$: Observable<InterventionInstanceInterface[]> = this.select(
    state => state.allInstancesOfInterventionForECoach,
    {
      debounce: true
    }
  );
  readonly allInstancesOfInterventionOfPatient$: Observable<InterventionInstanceInterface[]> = this.select(
    state => state.allInstancesOfInterventionOfPatient,
    {
      debounce: true
    }
  );
  readonly updateInstanceStateResponse$: Observable<any> = this.select(state => state.updateInstanceStateResponse, { debounce: true });
  readonly submitFeedbackAsECoachResponse$: Observable<any> = this.select(state => state.submitFeedbackAsECoachResponse, {
    debounce: true
  });
  readonly createInstanceAsECoachResponse$: Observable<any> = this.select(state => state.createInstanceAsECoachResponse, {
    debounce: true
  });
  readonly createMultipleInstancesAsECoachResponse$: Observable<any> = this.select(state => state.createMultipleInstancesAsECoachResponse, {
    debounce: true
  });
  readonly allMyInstancesOfStudy$: Observable<InterventionInstanceInterface[]> = this.select(state => state.allMyInstancesOfStudy, {
    debounce: true
  });
  readonly updateInstanceResponse$: Observable<any> = this.select(state => state.updateInstanceResponse, { debounce: true });

  readonly activitiesOfInstance$: Observable<any> = this.select(state => state.activitiesOfInstance, { debounce: true });

  readonly activitiesOfMultipleInstances$: Observable<any> = this.select(state => state.activitiesOfMultipleInstances, { debounce: true });

  readonly unlockQuestionnaireAsECoachResponse$: Observable<any> = this.select(state => state.unlockQuestionnaireAsECoachResponse, {
    debounce: true
  });

  readonly unpauseInstanceResponse$: Observable<any> = this.select(state => state.unpauseInstanceResponse, { debounce: true });

  readonly getInstancePDFReportQueueResponse$: Observable<any> = this.select(state => state.getInstancePDFReportQueueResponse, {
    debounce: true
  });

  readonly unlockMyInstancesAsEcoachResponse$: Observable<any> = this.select(state => state.unlockMyInstancesAsEcoachResponse, {
    debounce: true
  });

  readonly addECoachToECoachInterventionInstancesOfStudyResponse$: Observable<any> = this.select(
    state => state.addECoachToECoachInterventionInstancesOfStudyResponse,
    {
      debounce: true
    }
  );
  readonly logsOfInterventionInstance$: Observable<any> = this.select(state => state.logsOfInterventionInstance, {
    debounce: true
  });
  readonly allInterventionInstances$: Observable<any> = this.select(state => state.allInterventionInstances, {
    debounce: true
  });

  readonly updateInterventionInstanceState = this.updater(
    (
      state,
      payload: {
        instancesOfIntervention?: InterventionInstanceInterface[];
        instance?: InterventionInstanceInterface | null;
        allInstancesOfInterventionForECoach?: InterventionInstanceInterface[];
        allInstancesOfInterventionOfPatient?: InterventionInstanceInterface[];
        updateInstanceStateResponse?: any;
        submitFeedbackAsECoachResponse?: any;
        createInstanceAsECoachResponse?: any;
        createMultipleInstancesAsECoachResponse?: any;
        allMyInstancesOfStudy?: InterventionInstanceInterface[];
        updateInstanceResponse?: any;
        activitiesOfInstance?: Array<ActivityInterface>;
        activitiesOfMultipleInstances?: { activities: Array<ActivityInterface>; questionnaires: Array<LessonInterface> };
        unlockQuestionnaireAsECoachResponse?: any;
        unpauseInstanceResponse?: any;
        getInstancePDFReportQueueResponse?: any;
        unlockMyInstancesAsEcoachResponse?: any;
        addECoachToECoachInterventionInstancesOfStudyResponse?: any;
        logsOfInterventionInstance?: Array<InterventionInstanceLogInterface>;
        allInterventionInstances?: Array<InterventionInstanceInterface>;
      }
    ) => ({
      instancesOfIntervention: payload.instancesOfIntervention ? payload.instancesOfIntervention : state.instancesOfIntervention,
      instance: payload.instance ? payload.instance : state.instance,
      allInstancesOfInterventionForECoach: payload.allInstancesOfInterventionForECoach
        ? payload.allInstancesOfInterventionForECoach
        : state.allInstancesOfInterventionForECoach,
      allInstancesOfInterventionOfPatient: payload.allInstancesOfInterventionOfPatient
        ? payload.allInstancesOfInterventionOfPatient
        : state.allInstancesOfInterventionOfPatient,
      updateInstanceStateResponse: payload.updateInstanceStateResponse
        ? payload.updateInstanceStateResponse
        : state.updateInstanceStateResponse,
      submitFeedbackAsECoachResponse: payload.submitFeedbackAsECoachResponse
        ? payload.submitFeedbackAsECoachResponse
        : state.submitFeedbackAsECoachResponse,
      createInstanceAsECoachResponse: payload.createInstanceAsECoachResponse
        ? payload.createInstanceAsECoachResponse
        : state.createInstanceAsECoachResponse,
      createMultipleInstancesAsECoachResponse: payload.createMultipleInstancesAsECoachResponse
        ? payload.createMultipleInstancesAsECoachResponse
        : state.createMultipleInstancesAsECoachResponse,
      allMyInstancesOfStudy: payload.allMyInstancesOfStudy ? payload.allMyInstancesOfStudy : state.allMyInstancesOfStudy,
      updateInstanceResponse: payload.updateInstanceResponse ? payload.updateInstanceResponse : state.updateInstanceResponse,
      activitiesOfInstance: payload.activitiesOfInstance ? payload.activitiesOfInstance : state.activitiesOfInstance,
      activitiesOfMultipleInstances: payload.activitiesOfMultipleInstances
        ? payload.activitiesOfMultipleInstances
        : state.activitiesOfMultipleInstances,
      unlockQuestionnaireAsECoachResponse: payload.unlockQuestionnaireAsECoachResponse
        ? payload.unlockQuestionnaireAsECoachResponse
        : state.unlockQuestionnaireAsECoachResponse,
      unpauseInstanceResponse: payload.unpauseInstanceResponse ? payload.unpauseInstanceResponse : state.unpauseInstanceResponse,
      getInstancePDFReportQueueResponse: payload.getInstancePDFReportQueueResponse
        ? payload.getInstancePDFReportQueueResponse
        : state.getInstancePDFReportQueueResponse,
      unlockMyInstancesAsEcoachResponse: payload.unlockMyInstancesAsEcoachResponse
        ? payload.unlockMyInstancesAsEcoachResponse
        : state.unlockMyInstancesAsEcoachResponse,
      addECoachToECoachInterventionInstancesOfStudyResponse: payload.addECoachToECoachInterventionInstancesOfStudyResponse
        ? payload.addECoachToECoachInterventionInstancesOfStudyResponse
        : state.addECoachToECoachInterventionInstancesOfStudyResponse,
      logsOfInterventionInstance: payload.logsOfInterventionInstance
        ? payload.logsOfInterventionInstance
        : state.logsOfInterventionInstance,
      allInterventionInstances: payload.allInterventionInstances ? payload.allInterventionInstances : state.allInterventionInstances
    })
  );

  readonly getAllInstancesOfIntervention = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) =>
        this.interventionInstanceService
          .getAllInstancesOfIntervention(
            payload.interventionId,
            payload.ecoach_id,
            payload.patient_id,
            payload.intervention_type,
            payload.pendingInvitation
          )
          .pipe(
            tap({
              next: (result: any) => this.updateInterventionInstanceState({ instancesOfIntervention: result.body.data }),
              error: e => throwError(e)
            }),
            catchError(error => EMPTY)
          )
      )
    )
  );

  readonly getInstance = this.effect((instanceId$: Observable<number>) =>
    instanceId$.pipe(
      switchMap((instanceId: number) => {
        this.updateInterventionInstanceState({ instance: null });
        return this.interventionInstanceService.getInstance(instanceId).pipe(
          tap({
            next: (result: any) => this.updateInterventionInstanceState({ instance: result.body.data }),
            error: e => throwError(e)
          }),
          catchError(error => EMPTY)
        );
      })
    )
  );

  readonly getAllInstancesOfInterventionForECoach = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) =>
        this.interventionInstanceService
          .getAllInstancesOfInterventionForECoach(
            payload.interventionId,
            payload.patientId,
            payload.interventionType,
            payload.pendingInvitation
          )
          .pipe(
            tap({
              next: (result: any) => this.updateInterventionInstanceState({ allInstancesOfInterventionForECoach: result.body.data }),
              error: e => throwError(e)
            }),
            catchError(error => EMPTY)
          )
      )
    )
  );

  readonly getAllInstancesOfInterventionOfPatient = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) =>
        this.interventionInstanceService
          .getAllInstancesOfInterventionOfPatient(
            payload.userId,
            payload.ecoachId,
            payload.interventionId,
            payload.interventionType,
            payload.pendingInvitation
          )
          .pipe(
            tap({
              next: (result: any) => this.updateInterventionInstanceState({ allInstancesOfInterventionOfPatient: result.body.data }),
              error: e => throwError(e)
            }),
            catchError(error => EMPTY)
          )
      )
    )
  );

  readonly updateInstanceState = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) => {
        this.updateInterventionInstanceState({ updateInstanceStateResponse: null });
        return this.interventionInstanceService.updateInstanceState(payload.instanceId, payload.state).pipe(
          tap({
            next: (result: any) => this.updateInterventionInstanceState({ updateInstanceStateResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateInterventionInstanceState({ updateInstanceStateResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly updateInstanceStateEM = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) => {
        this.updateInterventionInstanceState({ updateInstanceStateResponse: null });
        return this.interventionInstanceService.updateInstanceStateEM(payload.instanceId, payload.state).pipe(
          tap({
            next: (result: any) => this.updateInterventionInstanceState({ updateInstanceStateResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateInterventionInstanceState({ updateInstanceStateResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly submitFeedbackAsECoach = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) => {
        this.updateInterventionInstanceState({ submitFeedbackAsECoachResponse: null });
        return this.interventionInstanceService
          .submitFeedbackAsECoach(
            payload.instanceId,
            payload.subject,
            payload.message,
            payload.answersheetId,
            payload.questionnaireId,
            payload.activityId
          )
          .pipe(
            tap({
              next: (result: any) => this.updateInterventionInstanceState({ submitFeedbackAsECoachResponse: result }),
              error: e => throwError(e)
            }),
            catchError(error => {
              this.updateInterventionInstanceState({ submitFeedbackAsECoachResponse: error });
              return EMPTY;
            })
          );
      })
    )
  );

  readonly createInstanceAsECoach = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) => {
        this.updateInterventionInstanceState({ createInstanceAsECoachResponse: null });
        return this.interventionInstanceService.createInstanceAsECoach(payload.payload).pipe(
          tap({
            next: (result: any) => this.updateInterventionInstanceState({ createInstanceAsECoachResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateInterventionInstanceState({ createInstanceAsECoachResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly createMultipleInstancesAsECoach = this.effect((payloads$: Observable<Array<any>>) =>
    payloads$.pipe(
      switchMap((payloads: any) => {
        this.updateInterventionInstanceState({ createMultipleInstancesAsECoachResponse: null });
        const reqs: Array<Observable<any>> = [];
        payloads.forEach(payload => {
          reqs.push(this.interventionInstanceService.createInstanceAsECoach(payload));
        });
        return forkJoin(reqs).pipe(
          tap({
            next: (results: any) => this.updateInterventionInstanceState({ createMultipleInstancesAsECoachResponse: results }),
            error: e => throwError(e)
          }),
          catchError(errors => {
            this.updateInterventionInstanceState({ createMultipleInstancesAsECoachResponse: errors });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly getMyInstancesOfStudy = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) =>
        this.interventionInstanceService.getMyInstancesOfStudy(payload.studyId, payload.pendingInvitation).pipe(
          tap({
            next: (result: any) => this.updateInterventionInstanceState({ allMyInstancesOfStudy: result.body.data }),
            error: e => throwError(e)
          }),
          catchError(error => EMPTY)
        )
      )
    )
  );

  readonly getAllInstancesOfStudy = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) =>
        this.interventionInstanceService.getAllInstancesOfStudy(payload.studyId, payload.pendingInvitation).pipe(
          tap({
            next: (result: any) => this.updateInterventionInstanceState({ allMyInstancesOfStudy: result.body.data }),
            error: e => throwError(e)
          }),
          catchError(error => EMPTY)
        )
      )
    )
  );

  readonly updateInstanceAsECoach = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) => {
        this.updateInterventionInstanceState({ updateInstanceResponse: null });
        return this.interventionInstanceService.updateInstanceAsECoach(payload.payload, payload.instanceId).pipe(
          tap({
            next: (result: any) => this.updateInterventionInstanceState({ updateInstanceResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateInterventionInstanceState({ updateInstanceResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly updateInstanceAsECoachManager = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) => {
        this.updateInterventionInstanceState({ updateInstanceResponse: null });
        return this.interventionInstanceService.updateInstanceAsECoachManager(payload.payload, payload.instanceId).pipe(
          tap({
            next: (result: any) => this.updateInterventionInstanceState({ updateInstanceResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateInterventionInstanceState({ updateInstanceResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly getActivitiesOfInterventionInstance = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) =>
        this.interventionInstanceService
          .getActivitiesOfInterventionInstance(payload.instanceId, payload.action, payload.finished, payload.include)
          .pipe(
            tap({
              next: (result: any) => this.updateInterventionInstanceState({ activitiesOfInstance: result.body.data }),
              error: e => throwError(e)
            }),
            catchError(error => EMPTY)
          )
      )
    )
  );

  readonly getActivitiesOfInterventionInstanceEM = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) =>
        this.interventionInstanceService
          .getActivitiesOfInterventionInstanceEM(payload.instanceId, payload.action, payload.finished, payload.include)
          .pipe(
            tap({
              next: (result: any) => this.updateInterventionInstanceState({ activitiesOfInstance: result.body.data }),
              error: e => throwError(e)
            }),
            catchError(error => EMPTY)
          )
      )
    )
  );

  readonly getAllActivitiesOfInstance = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) =>
        this.interventionInstanceService
          .getActivitiesOfInterventionInstance(payload.instanceId, payload.action, payload.finished, payload.include)
          .pipe(
            tap(result => this.updateInterventionInstanceState({ activitiesOfInstance: result.body.data })),
            catchError(err =>
              this.interventionInstanceService
                .getActivitiesOfInterventionInstanceEM(payload.instanceId, payload.action, payload.finished, payload.include)
                .pipe(
                  tap({
                    next: result => this.updateInterventionInstanceState({ activitiesOfInstance: result.body.data }),
                    error: e => throwError(e)
                  }),
                  catchError(error => EMPTY)
                )
            )
          )
      )
    )
  );

  readonly getActivitiesOfMultipleInstances = this.effect((payloads$: Observable<Array<any>>) =>
    payloads$.pipe(
      switchMap((payloads: Array<any>) => {
        const reqs: Array<Observable<any>> = [];
        payloads.forEach(req => {
          reqs.push(
            this.interventionInstanceService.getActivitiesOfInterventionInstance(req.instanceId, req.action, req.finished, req.include)
          );
        });
        return forkJoin(reqs).pipe(
          tap(results => {
            let tempActivities = [];
            const responses: Array<HttpResponse<any>> = results;
            responses.forEach((response: HttpResponse<any>) => {
              tempActivities = tempActivities.concat(response.body.data);
            });
            this.updateInterventionInstanceState({ activitiesOfMultipleInstances: { activities: tempActivities, questionnaires: [] } });
          }),
          catchError(error => EMPTY)
        );
      })
    )
  );

  readonly unlockQuestionnaireAsECoach = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) => {
        this.updateInterventionInstanceState({ unlockQuestionnaireAsECoachResponse: null });
        return this.interventionInstanceService
          .unlockQuestionnaireAsECoach(payload.instance, payload.unlockedQuestionnaires, payload.activityId)
          .pipe(
            tap({
              next: (result: any) => this.updateInterventionInstanceState({ unlockQuestionnaireAsECoachResponse: result }),
              error: e => throwError(e)
            }),
            catchError(error => {
              this.updateInterventionInstanceState({ unlockQuestionnaireAsECoachResponse: error });
              return EMPTY;
            })
          );
      })
    )
  );

  readonly unpauseInstance = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) => {
        this.updateInterventionInstanceState({ unpauseInstanceResponse: null });
        return this.interventionInstanceService.unpauseInstance(payload.instanceId).pipe(
          tap({
            next: (result: any) => this.updateInterventionInstanceState({ unpauseInstanceResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateInterventionInstanceState({ unpauseInstanceResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly unpauseInstanceECoachManager = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) => {
        this.updateInterventionInstanceState({ unpauseInstanceResponse: null });
        return this.interventionInstanceService.unpauseInstanceECoachManager(payload.instanceId).pipe(
          tap({
            next: (result: any) => this.updateInterventionInstanceState({ unpauseInstanceResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateInterventionInstanceState({ unpauseInstanceResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly getInstancePDFReportQueue = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) => {
        this.updateInterventionInstanceState({ getInstancePDFReportQueueResponse: null });
        return this.interventionInstanceService
          .getInstancePDFReportQueue(
            payload.instanceId,
            payload.unfinishedQuestionnaires,
            payload.activities,
            payload.diaries,
            payload.feedback
          )
          .pipe(
            tap({
              next: (result: any) => {
                this.updateInterventionInstanceState({ getInstancePDFReportQueueResponse: result });
              },
              error: e => throwError(e)
            }),
            catchError(error => {
              this.updateInterventionInstanceState({ getInstancePDFReportQueueResponse: error });
              return EMPTY;
            })
          );
      })
    )
  );

  readonly unlockMyInstancesAsEcoach = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) => {
        this.updateInterventionInstanceState({ unlockMyInstancesAsEcoachResponse: null });
        return this.interventionInstanceService.unlockMyInstancesAsEcoach();
      }),
      tap({
        next: (result: any) => this.updateInterventionInstanceState({ unlockMyInstancesAsEcoachResponse: result }),
        error: e => throwError(e)
      }),
      catchError(error => {
        this.updateInterventionInstanceState({ unlockMyInstancesAsEcoachResponse: error });
        return EMPTY;
      })
    )
  );

  readonly addECoachToECoachInterventionInstancesOfStudy = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) => {
        this.updateInterventionInstanceState({ addECoachToECoachInterventionInstancesOfStudyResponse: null });
        return this.interventionInstanceService
          .addECoachToECoachInterventionInstancesOfStudy(payload.studyId, payload.currentECoach, payload.newECoach)
          .pipe(
            tap({
              next: (result: any) =>
                this.updateInterventionInstanceState({ addECoachToECoachInterventionInstancesOfStudyResponse: result }),
              error: e => throwError(e)
            }),
            catchError(error => {
              this.updateInterventionInstanceState({ addECoachToECoachInterventionInstancesOfStudyResponse: error });
              return EMPTY;
            })
          );
      })
    )
  );

  readonly getInterventionInstanceLogs = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) =>
        this.interventionInstanceService.getInterventionInstanceLogs(payload.instanceId).pipe(
          tap({
            next: (result: any) => this.updateInterventionInstanceState({ logsOfInterventionInstance: result.body.data }),
            error: e => throwError(e)
          }),
          catchError(error => EMPTY)
        )
      )
    )
  );

  readonly getAllInterventionInstances = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) =>
        this.interventionInstanceService
          .getAllInterventionInstances(
            payload.ecoachId,
            payload.patientId,
            payload.interventionId,
            payload.interventionType,
            payload.pendingInvitation
          )
          .pipe(
            tap({
              next: (result: any) => this.updateInterventionInstanceState({ allInterventionInstances: result.body.data }),
              error: e => throwError(e)
            }),
            catchError(error => EMPTY)
          )
      )
    )
  );

  constructor(private readonly interventionInstanceService: InterventionInstanceService) {
    super({
      instancesOfIntervention: [],
      instance: null,
      allInstancesOfInterventionForECoach: [],
      allInstancesOfInterventionOfPatient: [],
      updateInstanceStateResponse: null,
      submitFeedbackAsECoachResponse: null,
      createInstanceAsECoachResponse: null,
      createMultipleInstancesAsECoachResponse: null,
      allMyInstancesOfStudy: [],
      updateInstanceResponse: null,
      activitiesOfInstance: [],
      activitiesOfMultipleInstances: { activities: [], questionnaires: [] },
      unlockQuestionnaireAsECoachResponse: null,
      unpauseInstanceResponse: null,
      getInstancePDFReportQueueResponse: null,
      unlockMyInstancesAsEcoachResponse: null,
      addECoachToECoachInterventionInstancesOfStudyResponse: null,
      logsOfInterventionInstance: [],
      allInterventionInstances: []
    });
  }
}
