<div [hidden]="isHidden$ | async">
  <div *ngIf="(isModalLoading$ | async); else elseShowInterventions">
    <app-loader></app-loader>
  </div>

  <ng-template #elseShowInterventions>
    <div [hidden]="isHidden$ | async" class="container text-start">
      <form [formGroup]="interventionsForm">
        <div formArrayName="interventionList">
          <div *ngIf="(fInterventionList.controls).length > 0; then showStudies else showNoStudies"></div>
          <ng-template #showStudies>
            <div *ngFor="let study of fInterventionList.controls; let i = index" class="card outline text-start mb-2">
              <div [attr.id]="'study' + fStudyId(i)" [formGroupName]="i" class="card-body">
                <div *appVariable="helper.findArrObjById(fStudyId(i).value, studies) as study" class="row mx-2 mb-2">
                  <img
                    class="col-4"
                    [src]="helper.getAllMediaSupportPath(study?.attributes.picture, study?.id)"
                    (error)="helper.getFallbackImage($event)"
                    alt="No image"
                    class="image-parent img-fluid"
                  />
                  <div class="col-8">
                    <p>
                      <strong>
                        {{(study?.attributes.title) ? study.attributes.title : 'patient-invitation-study.no_group_title' |translate}}
                      </strong>
                    </p>
                    <p><small class="text-muted">({{study.attributes.name}}) (ID - {{study.id}})</small></p>
                  </div>
                </div>
                <div *ngIf="submitted && !checkIfOneSelected(fGroup(i))">
                  <div class="alert alert-danger" role="alert">
                    {{'patient-creation-intervention-selection.selection_required_intervention' | translate}}
                  </div>
                </div>

                <div *ngIf="(fGroup(i).study_interventions).length > 0; then showInterventions else showNoInterventions"></div>
                <ng-template #showInterventions>
                  <div formArrayName="study_interventions">
                    <div *ngFor="let intervention of fStudyInterventions(i).controls; let l = index">
                      <mat-checkbox
                        (change)="onCheckboxChange()"
                        [attr.id]="getIDCheckbox(i, l) | async"
                        [attr.value]="getSelectedIntervention(i, l) | async"
                        [formControlName]="l"
                        matTooltip="{{'patient-creation-intervention-selection.tooltip_pending_intervention_instance' | translate}}"
                        [matTooltipDisabled]="!hasPendingInstances(i, l)"
                        matTooltipClass="tooltip-red"
                        [disabled]="isInitializing || hasPendingInstances(i, l)"
                      >
                        <span [ngClass]="{'text-danger' : hasPendingInstances(i, l)}">
                          <fa-icon *ngIf="hasPendingInstances(i, l)" [icon]="faExclamationCircle"></fa-icon>
                          {{(getSelectedIntervention(i, l) | async)?.attributes.name}} - {{(getSelectedIntervention(i, l) |
                          async)?.attributes.title}} (ID {{(getSelectedIntervention(i, l) | async)?.id}})
                          <ng-template
                            *ngIf="(getSelectedIntervention(i, l) | async)?.attributes.intervention_type === 'accompanied'; then thenAccompanied else elseUnaccompanied"
                          ></ng-template>
                          <ng-template #thenAccompanied>
                            <p>
                              <fa-icon [icon]="faHandsHelping"></fa-icon>
                              {{'patient-creation-intervention-selection.guided' | translate}}
                            </p>
                          </ng-template>
                          <ng-template #elseUnaccompanied>
                            <p>
                              <fa-icon [icon]="faHands"></fa-icon>
                              {{'patient-creation-intervention-selection.unguided' | translate}}
                            </p>
                          </ng-template>
                        </span>
                      </mat-checkbox>
                    </div>
                  </div>
                </ng-template>
                <ng-template #showNoInterventions>
                  <p class="alert alert-warning">
                    {{'patient-creation-intervention-selection.form_create_patient_no_intervention_in_study' | translate}}
                  </p>
                </ng-template>
              </div>
            </div>
          </ng-template>
          <ng-template #showNoStudies>
            <p>{{'patient-creation-intervention-selection.form_create_patient_no_common_study' | translate}}</p>
          </ng-template>
        </div>
      </form>
    </div>
  </ng-template>
</div>
