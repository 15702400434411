<button [attr.id]="'btnDialogClose'" mat-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<div mat-dialog-content>
  <div *ngIf="submittedSubject | async; else elseShowMessage">
    <app-loader></app-loader>
  </div>
  <ng-template #elseShowMessage>
    <app-alert></app-alert>
  </ng-template>
</div>
