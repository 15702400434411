import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { BuddyService } from '../../../services/api/buddy/buddy.service';
import { catchError, EMPTY, forkJoin, Observable, switchMap, tap, throwError } from 'rxjs';
import { BuddyInstanceInterface } from '../../../models/interface/buddy/buddy-instance.interface';
import { BuddyActivityInterface } from '../../../models/interface/buddy/buddy-activity.interface';
import { BuddyLastActivityInterface } from '../../../models/interface/buddy/buddy-last-activity.interface';
import { ActivityInterface } from '../../../models/interface/activity.interface';

export interface BuddyState {
  connectBuddiesResponse: any;
  deleteBuddyResponse: any;
  updateBuddyResponse: any;
  allBuddiesOfStudy: Array<BuddyInstanceInterface>;
  allBuddyActivitiesOfBuddy: Array<BuddyActivityInterface>;
  swapBuddyResponse: any;
  allInactiveBuddyLastActivities: Array<BuddyLastActivityInterface>;
  allActiveBuddyLastActivities: Array<BuddyLastActivityInterface>;
  getCSVBuddyByLastActivityResponse: any;
  getCSVBuddyActivitiesByBuddyIdResponse: any;
  getCSVBuddyActivitiesByStudyIdResponse: any;
  allBuddyActivitiesOfStudy: Array<ActivityInterface>;
}

@Injectable()
export class BuddyStore extends ComponentStore<BuddyState> {
  readonly connectBuddiesResponse$: Observable<any> = this.select(state => state.connectBuddiesResponse, { debounce: true });
  readonly deleteBuddyResponse$: Observable<any> = this.select(state => state.deleteBuddyResponse, { debounce: true });
  readonly updateBuddyResponse$: Observable<any> = this.select(state => state.updateBuddyResponse, { debounce: true });
  readonly allBuddiesOfStudy$: Observable<Array<BuddyInstanceInterface>> = this.select(state => state.allBuddiesOfStudy, {
    debounce: true
  });
  readonly allBuddyActivitiesOfBuddy$: Observable<Array<BuddyActivityInterface>> = this.select(state => state.allBuddyActivitiesOfBuddy, {
    debounce: true
  });
  readonly swapBuddyResponse$: Observable<any> = this.select(state => state.swapBuddyResponse, {
    debounce: true
  });
  readonly allInactiveBuddyLastActivities$: Observable<Array<BuddyLastActivityInterface>> = this.select(
    state => state.allInactiveBuddyLastActivities,
    {
      debounce: true
    }
  );
  readonly allActiveBuddyLastActivities$: Observable<Array<BuddyLastActivityInterface>> = this.select(
    state => state.allActiveBuddyLastActivities,
    {
      debounce: true
    }
  );
  readonly getCSVBuddyByLastActivityResponse$: Observable<any> = this.select(state => state.getCSVBuddyByLastActivityResponse, {
    debounce: true
  });
  readonly getCSVBuddyActivitiesByBuddyIdResponse$: Observable<any> = this.select(state => state.getCSVBuddyActivitiesByBuddyIdResponse, {
    debounce: true
  });
  readonly getCSVBuddyActivitiesByStudyIdResponse$: Observable<any> = this.select(state => state.getCSVBuddyActivitiesByStudyIdResponse, {
    debounce: true
  });
  readonly allBuddyActivitiesOfStudy$: Observable<Array<ActivityInterface>> = this.select(state => state.allBuddyActivitiesOfStudy, {
    debounce: true
  });

  readonly updateBuddyState = this.updater(
    (
      state,
      payload: {
        connectBuddiesResponse?: any;
        deleteBuddyResponse?: any;
        updateBuddyResponse?: any;
        allBuddiesOfStudy?: Array<BuddyInstanceInterface>;
        allBuddyActivitiesOfBuddy?: Array<BuddyActivityInterface>;
        swapBuddyResponse?: any;
        allInactiveBuddyLastActivities?: Array<BuddyLastActivityInterface>;
        allActiveBuddyLastActivities?: Array<BuddyLastActivityInterface>;
        getCSVBuddyByLastActivityResponse?: any;
        getCSVBuddyActivitiesByBuddyIdResponse?: any;
        getCSVBuddyActivitiesByStudyIdResponse?: any;
        allBuddyActivitiesOfStudy?: Array<ActivityInterface>;
      }
    ) => ({
      connectBuddiesResponse: payload.connectBuddiesResponse ? payload.connectBuddiesResponse : state.connectBuddiesResponse,
      deleteBuddyResponse: payload.deleteBuddyResponse ? payload.deleteBuddyResponse : state.deleteBuddyResponse,
      updateBuddyResponse: payload.updateBuddyResponse ? payload.updateBuddyResponse : state.updateBuddyResponse,
      allBuddiesOfStudy: payload.allBuddiesOfStudy ? payload.allBuddiesOfStudy : state.allBuddiesOfStudy,
      allBuddyActivitiesOfBuddy: payload.allBuddyActivitiesOfBuddy ? payload.allBuddyActivitiesOfBuddy : state.allBuddyActivitiesOfBuddy,
      swapBuddyResponse: payload.swapBuddyResponse ? payload.swapBuddyResponse : state.swapBuddyResponse,
      allInactiveBuddyLastActivities: payload.allInactiveBuddyLastActivities
        ? payload.allInactiveBuddyLastActivities
        : state.allInactiveBuddyLastActivities,
      allActiveBuddyLastActivities: payload.allActiveBuddyLastActivities
        ? payload.allActiveBuddyLastActivities
        : state.allActiveBuddyLastActivities,
      getCSVBuddyByLastActivityResponse: payload.getCSVBuddyByLastActivityResponse
        ? payload.getCSVBuddyByLastActivityResponse
        : state.getCSVBuddyByLastActivityResponse,
      getCSVBuddyActivitiesByBuddyIdResponse: payload.getCSVBuddyActivitiesByBuddyIdResponse
        ? payload.getCSVBuddyActivitiesByBuddyIdResponse
        : state.getCSVBuddyActivitiesByBuddyIdResponse,
      getCSVBuddyActivitiesByStudyIdResponse: payload.getCSVBuddyActivitiesByStudyIdResponse
        ? payload.getCSVBuddyActivitiesByStudyIdResponse
        : state.getCSVBuddyActivitiesByStudyIdResponse,
      allBuddyActivitiesOfStudy: payload.allBuddyActivitiesOfStudy ? payload.allBuddyActivitiesOfStudy : state.allBuddyActivitiesOfStudy
    })
  );

  // API
  readonly connectBuddies = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) => {
        this.updateBuddyState({ connectBuddiesResponse: null });
        return this.buddyService
          .connectBuddies(
            payload.buddyId1,
            payload.interventionInstanceId1,
            payload.diaryInstanceId1,
            payload.buddyId2,
            payload.interventionInstanceId2,
            payload.diaryInstanceId2,
            payload.studyId,
            payload.language
          )
          .pipe(
            tap({
              next: (result: any) => this.updateBuddyState({ connectBuddiesResponse: result }),
              error: e => throwError(e)
            }),
            catchError(error => {
              this.updateBuddyState({ connectBuddiesResponse: error });
              return EMPTY;
            })
          );
      })
    )
  );

  readonly deleteBuddy = this.effect((buddyId$: Observable<number>) =>
    buddyId$.pipe(
      switchMap((buddyId: number) => {
        this.updateBuddyState({ deleteBuddyResponse: null });
        return this.buddyService.deleteBuddy(buddyId).pipe(
          tap({
            next: (result: any) => this.updateBuddyState({ deleteBuddyResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateBuddyState({ deleteBuddyResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly updateBuddies = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) => {
        this.updateBuddyState({ updateBuddyResponse: null });
        return this.buddyService
          .updateBuddy(
            payload.buddyId1,
            payload.interventionInstanceId1,
            payload.diaryInstanceId1,
            payload.buddyId2,
            payload.interventionInstanceId2,
            payload.diaryInstanceId2,
            payload.buddyConnectionId
          )
          .pipe(
            tap({
              next: (result: any) => this.updateBuddyState({ updateBuddyResponse: result }),
              error: e => throwError(e)
            }),
            catchError(error => {
              this.updateBuddyState({ updateBuddyResponse: error });
              return EMPTY;
            })
          );
      })
    )
  );

  readonly getAllBuddiesOfStudy = this.effect((studyId$: Observable<number>) =>
    studyId$.pipe(
      switchMap((studyId: number) =>
        this.buddyService.getAllBuddiesOfStudy(studyId).pipe(
          tap({
            next: (result: any) => this.updateBuddyState({ allBuddiesOfStudy: result.body.data }),
            error: e => throwError(e)
          }),
          catchError(error => EMPTY)
        )
      )
    )
  );

  readonly getAllBuddiesOfStudyEM = this.effect((studyId$: Observable<number>) =>
    studyId$.pipe(
      switchMap((studyId: number) =>
        this.buddyService.getAllBuddiesOfStudyEM(studyId).pipe(
          tap({
            next: (result: any) => this.updateBuddyState({ allBuddiesOfStudy: result.body.data }),
            error: e => throwError(e)
          }),
          catchError(error => EMPTY)
        )
      )
    )
  );

  readonly getBuddyActivities = this.effect((buddyInstanceId$: Observable<number>) =>
    buddyInstanceId$.pipe(
      switchMap((buddyInstanceId: number) =>
        this.buddyService.getBuddyActivities(buddyInstanceId).pipe(
          tap({
            next: (result: any) => this.updateBuddyState({ allBuddyActivitiesOfBuddy: result.body.data }),
            error: e => throwError(e)
          }),
          catchError(error => EMPTY)
        )
      )
    )
  );

  readonly getBuddyActivitiesEM = this.effect((buddyInstanceId$: Observable<number>) =>
    buddyInstanceId$.pipe(
      switchMap((buddyInstanceId: number) =>
        this.buddyService.getBuddyActivitiesEM(buddyInstanceId).pipe(
          tap({
            next: (result: any) => this.updateBuddyState({ allBuddyActivitiesOfBuddy: result.body.data }),
            error: e => throwError(e)
          }),
          catchError(error => EMPTY)
        )
      )
    )
  );

  readonly swapBuddies = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) => {
        this.updateBuddyState({ swapBuddyResponse: null });
        return this.buddyService
          .swapBuddy(
            payload.buddyId1,
            payload.active_user_id1,
            payload.inactive_user_id1,
            payload.buddyId2,
            payload.active_user_id2,
            payload.inactive_user_id2
          )
          .pipe(
            tap({
              next: (result: any) => this.updateBuddyState({ swapBuddyResponse: result }),
              error: e => throwError(e)
            }),
            catchError(error => {
              this.updateBuddyState({ swapBuddyResponse: error });
              return EMPTY;
            })
          );
      })
    )
  );

  readonly getBuddyLastActivities = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) =>
        forkJoin([
          this.buddyService.getBuddyByLastActivity(payload.studyId, false, payload.days),
          this.buddyService.getBuddyByLastActivity(payload.studyId, true, payload.days)
        ]).pipe(
          tap({
            next: (results: any) => {
              this.updateBuddyState({
                allInactiveBuddyLastActivities: Array.isArray(results[0].body.data) ? results[0].body.data : []
              });
              this.updateBuddyState({
                allActiveBuddyLastActivities: Array.isArray(results[1].body.data) ? results[1].body.data : []
              });
            },
            error: e => throwError(e)
          }),
          catchError(error => EMPTY)
        )
      )
    )
  );

  readonly getCSVBuddyByLastActivity = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) => {
        this.updateBuddyState({ getCSVBuddyByLastActivityResponse: null });
        return this.buddyService.getCSVBuddyByLastActivity(payload.studyId, payload.isActive, payload.days).pipe(
          tap({
            next: (result: any) => this.updateBuddyState({ getCSVBuddyByLastActivityResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateBuddyState({ getCSVBuddyByLastActivityResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly exportCSVBuddyActivitiesByBuddyId = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) => {
        this.updateBuddyState({ getCSVBuddyActivitiesByBuddyIdResponse: null });
        return this.buddyService.getCSVBuddyActivitiesByBuddyId(payload.buddyId, payload.userId, payload.startTime, payload.endTime).pipe(
          tap({
            next: (result: any) => this.updateBuddyState({ getCSVBuddyActivitiesByBuddyIdResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateBuddyState({ getCSVBuddyActivitiesByBuddyIdResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly exportCSVBuddyActivitiesByStudyId = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) => {
        this.updateBuddyState({ getCSVBuddyActivitiesByStudyIdResponse: null });
        return this.buddyService.getCSVBuddyActivitiesByStudyId(payload.studyId, payload.userId, payload.startTime, payload.endTime).pipe(
          tap({
            next: (result: any) => this.updateBuddyState({ getCSVBuddyActivitiesByStudyIdResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateBuddyState({ getCSVBuddyActivitiesByStudyIdResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly getAllBuddyActivitiesStudy = this.effect((studyId$: Observable<number>) =>
    studyId$.pipe(
      switchMap((studyId: number) =>
        this.buddyService.getAllBuddyActivitiesStudy(studyId).pipe(
          tap({
            next: (result: any) => this.updateBuddyState({ allBuddyActivitiesOfStudy: result.body.data }),
            error: e => throwError(e)
          }),
          catchError(error => EMPTY)
        )
      )
    )
  );

  readonly getAllBuddyActivitiesStudyEM = this.effect((studyId$: Observable<number>) =>
    studyId$.pipe(
      switchMap((studyId: number) =>
        this.buddyService.getAllBuddyActivitiesStudyEM(studyId).pipe(
          tap({
            next: (result: any) => this.updateBuddyState({ allBuddyActivitiesOfStudy: result.body.data }),
            error: e => throwError(e)
          }),
          catchError(error => EMPTY)
        )
      )
    )
  );

  constructor(private readonly buddyService: BuddyService) {
    super({
      connectBuddiesResponse: null,
      deleteBuddyResponse: null,
      updateBuddyResponse: null,
      allBuddiesOfStudy: [],
      allBuddyActivitiesOfBuddy: [],
      swapBuddyResponse: null,
      allInactiveBuddyLastActivities: [],
      allActiveBuddyLastActivities: [],
      getCSVBuddyByLastActivityResponse: null,
      getCSVBuddyActivitiesByBuddyIdResponse: null,
      getCSVBuddyActivitiesByStudyIdResponse: null,
      allBuddyActivitiesOfStudy: []
    });
  }
}
