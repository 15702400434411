import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { HttpErrorResponseMessage } from 'src/app/enum/http-error-response-message';
import { DiaryService } from '../../services/api/diary/diary.service';
import { ResponseError } from '../utils/response-error';
import { ResponseSuccess } from '../utils/response-success';
import { addAllDiariesStore, DiaryActionTypes, GetAllDiaries } from './diary.action';

@Injectable()
export class DiaryEffects {
  getAllDiaries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiaryActionTypes.getAllDiariesType),
      map((action: GetAllDiaries) => action.payload),
      switchMap((payload: any) =>
        this.diaryService
          .getAllDiaries(
            payload.isShareable,
            payload.isSubscribable,
            payload.isActive,
            payload.isPrivate,
            payload.studyId,
            payload.questionnaireId
          )
          .pipe(
            switchMap(result => {
              const actions = [];
              actions.push(addAllDiariesStore({ diaries: result.body.data }));
              actions.push(new ResponseSuccess(DiaryActionTypes.getAllDiariesSuccessType, result));
              return actions;
            }),
            catchError(err =>
              of(
                new ResponseError(DiaryActionTypes.getAllDiariesErrorType, {
                  message: HttpErrorResponseMessage.genericUnknownError,
                  content: err
                })
              )
            )
          )
      )
    )
  );

  constructor(private actions$: Actions, private diaryService: DiaryService) {}
}
