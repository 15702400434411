<div class="container-fluid text-center">
  <div class="col-sm-8 text-start mb-3">
    <h2>{{'intervention-activity.activities' | translate}}</h2>
    <hr />
  </div>

  <div class="mb-4">
    <div *ngIf="isLoading$ | async; else elseActivityBlock" class="spinnerPosition">
      <app-loader></app-loader>
    </div>

    <ng-template #elseActivityBlock>
      <div *ngIf="(activities$ | async)?.length === 0; else elseShowActivityBlock">
        <p class="centering">{{ 'activity.no_activities' | translate }}</p>
      </div>

      <!-- Table -->
      <ng-template #elseShowActivityBlock>
        <div class="mat-elevation-z0 text-start mb-3">
          <table (matSortChange)="onChangeSortTable($event)" [dataSource]="dataSource" mat-table matSort>
            <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->

            <!-- Activity_text Column -->
            <ng-container matColumnDef="activity_text">
              <th *matHeaderCellDef mat-header-cell mat-sort-header>{{'activity.activity_text' | translate}}</th>
              <td *matCellDef="let activity" mat-cell>
                {{helperActivity.getActivityText(activity) | async}}
                <span *ngIf="activity.attributes.action === 'ANSWERSHEET_SUBMITTED'"
                  >: {{helperActivity.getLessonNameOfActivity(activity, lessons)}}</span
                >
              </td>
            </ng-container>

            <!-- Performed_by Column -->
            <ng-container matColumnDef="performed_by">
              <th *matHeaderCellDef mat-header-cell mat-sort-header>{{'activity.performed_by' | translate}}</th>
              <td *matCellDef="let activity" mat-cell>
                {{helper.findArrObjById(activity.attributes.user, members) !== null ? helper.getCodeNameEmail(activity.attributes.user,
                members, studyId) : ('activity.system' | translate)}}
              </td>
            </ng-container>

            <!-- Created_at Column -->
            <ng-container matColumnDef="created_at">
              <th *matHeaderCellDef mat-header-cell mat-sort-header>{{'activity.created_at' | translate}}</th>
              <td *matCellDef="let activity" mat-cell>{{helper.localizeDateString(activity.attributes.created_at)}}</td>
            </ng-container>

            <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
            <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
          </table>
          <mat-paginator
            (page)="onPageChangeActivity($event)"
            [length]="(activities$ | async)?.length"
            [showFirstLastButtons]="true"
            [pageSizeOptions]="[10, 20, 50]"
            [pageSize]="20"
          ></mat-paginator>
        </div>
      </ng-template>
    </ng-template>
  </div>
</div>
