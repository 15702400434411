/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { PayloadInterface } from '../../../models/interface/payload.interface';
import { InterventionInstanceProgressInterface } from '../../../models/interface/intervention-instances/intervention-instance.progress.interface';
import { RequestBodyData } from '../../../models/request-body.data';
import { InterventionInstanceInterface } from '../../../models/interface/intervention-instances/intervention-instance.interface';
import { download } from '../../../helpers/download/download';
import { HelperService } from '../../helper/helper.service';

/**
 * Service:
 * Intervention instance API service that handles operations related to intervention instances
 */

@Injectable({
  providedIn: 'root'
})
export class InterventionInstanceService {
  public header: HttpHeaders = new HttpHeaders();
  private backendUrl: string = environment.backendURL;

  constructor(private http: HttpClient, private helperService: HelperService) {
    this.header = this.header.set('Content-Type', 'application/json');
    this.header = this.helperService.setLocaleFromStorage(this.header);
  }

  /**
   * AAS2 API User - All Interventions Instances
   * @deprecated This function retrieves all intervention instances.
   *
   * @params number ecoach_id - ID of the user - preferably ecoach.
   *         number patient_id - ID of the patient - preferably patient.
   *         number intervention_id - ID of the intervention.
   *         string intervention_type - A string that determines whether the inssance is guided or unguided.
   *           - typically 'guided' or 'unguided'
   *         boolean pendingInvitation - A boolean value that filters instances of users according to the state of the study invitation of
   *           the intervention instance
   * @return Observable<any> - An observable for any response.
   */
  public getAllInterventionInstances(
    ecoachId?: number,
    patientId?: number,
    interventionId?: number,
    interventionType?: string,
    pendingInvitation?: boolean
  ): Observable<any> {
    const params = this.setHttpParams(ecoachId, patientId, interventionId, interventionType, pendingInvitation);
    return this.http.get<any>(`${this.backendUrl}/api/v1/interventions/instances?limit=0`, {
      headers: this.header,
      observe: 'response',
      params
    });
  }

  /**
   * AAS2 API User - Get All Instances of a specific Intervention
   * This function retrieves all intervention instances of a specific intervention.
   *
   * @params number interventionId - ID of the intervention.
   *         number ecoach_id - ID of the user - preferably ecoach.
   *         number patient_id - ID of the user - preferably patient.
   *         string intervention_type - A string that determines whether the instance is guided or unguided.
   *           - typically 'guided' or 'unguided'
   *         boolean pendingInvitation - A boolean value that filters instances of users according to the state of the study invitation of
   *           the intervention instance
   * @return Observable<any> - An observable for any response.
   */
  public getAllInstancesOfIntervention(
    interventionId: number,
    ecoach_id?: number,
    patient_id?: number,
    intervention_type?: string,
    pendingInvitation?: boolean
  ): Observable<any> {
    const params = this.setHttpParams(ecoach_id, patient_id, undefined, intervention_type, pendingInvitation);
    return this.http.get<any>(`${this.backendUrl}/api/v1/interventions/${interventionId}/instances?limit=0`, {
      headers: this.header,
      observe: 'response',
      params
    });
  }

  /**
   * AAS2 API User - Get an Intervention Instance
   * This function returns an intervention instance.
   *
   * @params number instanceId - ID of the intervention instance.
   * @return Observable<any> - An observable for any response.
   */
  public getInstance(instanceId: number): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/api/v1/interventions/instances/${instanceId}`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API User - Delete an Intervention Instance
   * This function deletes an intervention instance.
   *
   * @params number instanceId - ID of the intervention instance.
   * @return Observable<any> - An observable for any response.
   */
  public deleteInstance(instanceId: number): Observable<any> {
    return this.http.delete<any>(`${this.backendUrl}/api/v1/interventions/instances/${instanceId}`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API ECoach - Delete an Intervention Instance as an eCoach
   * This function deletes an intervention instance as an eCoach.
   *
   * @params number instanceId - ID of the intervention instance.
   * @return Observable<any> - An observable for any response.
   */
  public deleteInstanceAsECoach(instanceId: number): Observable<any> {
    return this.http.delete<any>(`${this.backendUrl}/api/v1/ecoach/interventions/instances/${instanceId}`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API User - Update an Intervention Instance
   * This function updates an intervention instance.
   *
   * @params number instanceId - ID of the intervention instance.
   *         number ecoach_id - ID of the user - preferably ecoach.
   *         number patient_id - ID of the user - preferably patient.
   *         number intervention_id - A number of the intervention id.
   *         string intervention_type - A string that determines whether the instance is guided or unguided.
   *           - typically 'guided' or 'unguided'
   *         any f_questionnaire_configuration - A list of questionnaire configuration elements
   *         Array<number> custom_order - A sequential list of questionnaire identifiers.
   *         any starting_at - A date determines the start of the instance.
   *         InterventionInstanceProgressInterface progress - An object that contains progress-related metadata.
   * @return Observable<any> - An observable for any response.
   */
  public updateInstance(
    instanceId: number,
    ecoachId: number,
    patientId: number,
    interventionId: number,
    interventionType: string,
    questionnaireConfiguration: Array<any>,
    customOrder: Array<number>,
    startingAt: any,
    progress: InterventionInstanceProgressInterface
  ): Observable<any> {
    const payload: PayloadInterface = {
      data: {
        type: 'intervention_instance',
        attributes: {
          ecoach_id: ecoachId,
          patient_id: patientId,
          intervention_id: interventionId,
          intervention_type: interventionType,
          configuration: {
            questionnaire_configuration: questionnaireConfiguration,
            custom_order: customOrder,
            starting_at: startingAt
          },
          progress
        }
      }
    };
    return this.http.patch<any>(`${this.backendUrl}/api/v1/interventions/instances/${instanceId}`, payload, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API ECoach - All Intervention Instances for a specific ecoach
   * This function retrieves all intervention instances for ecoach.
   *
   * @params number patient_id - ID of the user - preferably patient.
   *         number intervention_id - ID of the intervention.
   *         string intervention_type - A string that determines whether the instance is guided or unguided.
   *           - typically 'guided' or 'unguided'
   *         boolean pendingInvitation - A boolean value that filters instances of users according to the state of the study invitation of
   *           the intervention instance
   * @return Observable<any> - An observable for any response.
   */
  public getAllInstancesForECoach(
    patientId?: number,
    interventionId?: number,
    interventionType?: string,
    pendingInvitation?: boolean
  ): Observable<any> {
    const params = this.setHttpParams(undefined, patientId, interventionId, interventionType, pendingInvitation);
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoach/interventions/my/instances?limit=0`, {
      headers: this.header,
      observe: 'response',
      params
    });
  }

  /**
   * AAS2 API ECoach - All Intervention instances of a certain eCoach for a specific Intervention
   * This function retrieves intervention instances of a specific intervention of a certain eCoach.
   *
   * @params number interventionId - ID of the intervention.
   *         number patient_id - ID of the user - preferably patient.
   *         string intervention_type - A string that determines whether the instance is guided or unguided.
   *           - typically 'guided' or 'unguided'
   *         boolean pendingInvitation - A boolean value that filters instances of users according to the state of the study invitation of
   *           the intervention instance
   * @return Observable<any> - An observable for any response.
   */
  public getAllInstancesOfInterventionForECoach(
    interventionId: number,
    patientId?: number,
    interventionType?: string,
    pendingInvitation?: boolean
  ): Observable<any> {
    let params = new HttpParams();
    if (patientId !== undefined) {
      params = params.set('patient_id', patientId.toString());
    }
    if (interventionType !== undefined) {
      params = params.set('intervention_type', interventionType);
    }
    if (pendingInvitation !== undefined) {
      params = params.set('pendingInvitation', pendingInvitation.toString());
    }
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoach/interventions/${interventionId}/instances?limit=0`, {
      headers: this.header,
      observe: 'response',
      params
    });
  }

  /**
   * AAS2 API ECoach - Get all Intervention instances of a patient
   * This function retrieves all intervention instances of a patient.
   *
   * @params number userId - ID of the user - preferably patient.
   *         number @DEPRECATED ecoach_id - ID of the user - preferably ecoach.
   *         number intervention_id - ID of the intervention.
   *         string intervention_type - A string that determines whether the inssance is guided or unguided.
   *           - typically 'guided' or 'unguided'
   *         boolean @DEPRECATED pendingInvitation - A boolean value that filters instances of users according to the state of the study invitation of
   *           the intervention instance
   * @return Observable<any> - An observable for any response.
   */
  public getAllInstancesOfInterventionOfPatient(
    userId: number,
    ecoachId?: number,
    interventionId?: number,
    interventionType?: string,
    pendingInvitation?: boolean
  ): Observable<any> {
    const params = this.setHttpParams(ecoachId, undefined, interventionId, interventionType, pendingInvitation);
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoach/members/${userId}/interventions/instances?limit=0`, {
      headers: this.header,
      observe: 'response',
      params
    });
  }

  /**
   * AAS2 API ECoach - Update Intervention Instance State
   * This function updates an intervention instance's state. Only works if "paused" or "feedback_provided"
   *
   * @params number instanceId - ID of the intervention instance.
   *         string state - Contains a string of the state.
   * @return Observable<any> - An observable for any response.
   */
  public updateInstanceState(instanceId: number, state: string): Observable<any> {
    const payload: PayloadInterface = new RequestBodyData('intervention_instance', { state });
    return this.http.patch<any>(`${this.backendUrl}/api/v1/ecoach/interventions/instances/${instanceId}/state`, payload, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API eCoach Manager - Update Intervention Instance State as eCoach manager
   * This function updates an intervention instance's state. Only works if "paused" or "feedback_provided"
   *
   * @params number instanceId - ID of the intervention instance.
   *         string state - Contains a string of the state.
   * @return Observable<any> - An observable for any response.
   */
  public updateInstanceStateEM(instanceId: number, state: string): Observable<any> {
    const payload: PayloadInterface = new RequestBodyData('intervention_instance', { state });
    return this.http.patch<any>(`${this.backendUrl}/api/v1/ecoachmanager/interventions/instances/${instanceId}/state`, payload, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API User - Progress Update
   * This function updates an intervention instance's progress.
   *
   * @params number instanceId - ID of the intervention instance.
   *         Array<number> unlocked_questionnaires - A list of questionnaire ids.
   *         Array<number> finished_questionnaires - A list of questionnaire ids.
   * @return Observable<any> - An observable for any response.
   */
  public updateProgress(instanceId: number, payload: PayloadInterface): Observable<any> {
    return this.http.patch<any>(`${this.backendUrl}/api/v1/interventions/instances/${instanceId}/progress`, payload, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API ECoach - Progress Update as eCoach
   * This function updates an intervention instance's progress.
   *
   * @params number instanceId - ID of the intervention instance.
   *         Array<number> unlocked_questionnaires - A list of questionnaire ids.
   *         Array<number> finished_questionnaires - A list of questionnaire ids.
   * @return Observable<any> - An observable for any response.
   */
  public updateProgressAsEcoach(instanceId: number, payload: PayloadInterface): Observable<any> {
    return this.http.patch<any>(`${this.backendUrl}/api/v1/ecoach/interventions/instances/${instanceId}/progress`, payload, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API User - Get State of an Intervention Instance
   * This function returns an instance's state
   *
   * @params number instanceId - ID of the intervention instance.
   * @return Observable<any> - An observable for any response.
   */
  public getStateOfInstance(instanceId: number): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/api/v1/interventions/instances/${instanceId}/state`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API ECoach - Create an Instance as Ecoach
   * This function creates an intervention instance.
   *
   * @params PayloadInterface payload contains:
   *         number ecoach_id - ID of the user - preferably ecoach.
   *         number patient_id - ID of the user - preferably patient.
   *         number intervention_id - ID of the intervention.
   *         string intervention_type - A string that determines whether the instance is guided or unguided.
   *           - typically 'guided' or 'unguided'
   *         any f_questionnaire_configuration - A list of questionnaire configuration elements
   *         Array<number> custom_order - A sequential list of questionnaire identifiers.
   *         any starting_at - A date determines the start of the instance.
   *         InterventionInstanceProgressInterface progress - An object that contains progress-related metadata.
   * @return Observable<any> - An observable for any response.
   */
  public createInstanceAsECoach(payload: PayloadInterface): Observable<any> {
    return this.http.post<any>(`${this.backendUrl}/api/v1/ecoach/interventions/instances`, payload, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API ECoach - Get all my intervention instances of study
   * This function returns all the ecoach's intervention instances of a study.
   *
   * @params number studyId - ID of the user - preferably patient.
   *         boolean pendingInvitation - A boolean value that filters instances of users according to the state of the study invitation of
   *           the intervention instance
   * @return Observable<any> - An observable for any response.
   */
  public getMyInstancesOfStudy(studyId: number, pendingInvitation?: boolean): Observable<any> {
    let params = new HttpParams();
    if (pendingInvitation !== undefined) {
      params = params.set('pendingInvitation', pendingInvitation.toString());
    }
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoach/studies/${studyId}/instances?limit=0`, {
      headers: this.header,
      observe: 'response',
      params
    });
  }

  /**
   * AAS2 API ECoach - Get all intervention instances of a study
   * This function returns all instances of a study.
   *
   * @params number studyId - ID of the study.
   *         boolean pendingInvitation - A boolean value that filters instances of users according to the state of the study invitation of
   *           the intervention instance
   * @return Observable<any> - An observable for any response.
   */
  public getAllInstancesOfStudy(studyId: number, pendingInvitation?: boolean): Observable<any> {
    let params = new HttpParams();
    if (pendingInvitation !== undefined) {
      params = params.set('pendingInvitation', pendingInvitation.toString());
    }
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoach/studies/${studyId}/all/instances?limit=0`, {
      headers: this.header,
      observe: 'response',
      params
    });
  }

  /**
   * AAS2 API ECoach - Update an Instance as Ecoach
   * This function updates an intervention instance.
   *
   * @params PayloadInterface payload - An interface type that contains the intervention instance.
   *         number instance_id - ID of the intervention instance.
   * @return Observable<any> - An observable for any response.
   */
  public updateInstanceAsECoach(payload: PayloadInterface, instanceId: number): Observable<any> {
    return this.http.patch<any>(`${this.backendUrl}/api/v1/ecoach/interventions/instances/${instanceId}`, payload, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API ECoach Manager - Update an Instance as Ecoach Manager
   * This function updates an intervention instance.
   *
   * @params PayloadInterface payload - An interface type that contains the intervention instance.
   *         number instance_id - ID of the intervention instance.
   * @return Observable<any> - An observable for any response.
   */
  public updateInstanceAsECoachManager(payload: PayloadInterface, instanceId: number): Observable<any> {
    return this.http.patch<any>(`${this.backendUrl}/api/v1/ecoachmanager/interventions/instances/${instanceId}`, payload, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API ECoach - Get Activities of Intervention Instance
   * This function returns all activities of the intervention instance.
   *
   * @params number instance_id - ID of the intervention instance.
   *         string action - A string of activity's action.
   *         number finished - A number to filter for finished/non-finished activities
   *         string include - A string including additional information.
   *           - typically 'questionnaires'
   * @return Observable<any> - An observable for any response.
   */
  public getActivitiesOfInterventionInstance(
    instanceId: number,
    action?: string,
    finished?: number | boolean,
    include?: string
  ): Observable<any> {
    this.header = this.helperService.setLocaleFromStorage(this.header);
    const params = this.setHttpParams(undefined, undefined, undefined, undefined, undefined, action, finished, include);
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoach/interventions/instances/${instanceId}/activities?limit=0`, {
      headers: this.header,
      observe: 'response',
      params
    });
  }

  /**
   * AAS2 API eCoach Manager - Get Activities of Intervention Instance
   * This function returns all activities of the intervention instance.
   *
   * @params number instance_id - ID of the intervention instance.
   *         string action - A string of activity's action.
   *         number finished - A number to filter for finished/non-finished activities
   *         string include - A string including additional information.
   *           - typically 'questionnaires'
   * @return Observable<any> - An observable for any response.
   */
  public getActivitiesOfInterventionInstanceEM(
    instanceId: number,
    action?: string,
    finished?: number | boolean,
    include?: string
  ): Observable<any> {
    this.header = this.helperService.setLocaleFromStorage(this.header);
    const params = this.setHttpParams(undefined, undefined, undefined, undefined, undefined, action, finished, include);
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoachmanager/interventions/instances/${instanceId}/activities?limit=0`, {
      headers: this.header,
      observe: 'response',
      params
    });
  }

  /**
   * AAS2 API eCoach - Get Logs of Intervention Instance
   * This function returns all logs of the intervention instance.
   *
   * @params number instance_id - ID of the intervention instance.
   * @return Observable<any> - An observable for any response.
   */
  public getInterventionInstanceLogs(instanceId: number): Observable<any> {
    this.header = this.helperService.setLocaleFromStorage(this.header);
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoach/interventions/instances/${instanceId}/logs?limit=0`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API ECoach - Update unlocked_questionnaires
   * Unlocks a questionnaire that is unlock_type=manually|at_date.
   * to repeat questionnaire -> update questionnaire configuration to unlock_type manually and then use this route; also more than one
   * questionnaire can be set unlocked; current_questionnaire_id refers to the first questionnaire to be processed from the custom_order
   * array, since it must always contains all the questionnaires that are available in questionnaire_configuration
   * Not usable in feedback_required but can be used in feedback_required => automatically updates the state after it has been performed
   *
   * @params InterventionInstanceInterface instance - Intervention instance to unlock the questionnaires.
   *         Array<number> unlocked_questionnaire - An array of number - questionnaire_id to unlocked the questionnaire.
   *         number activity_id - ID of the activity that sets a flag to completed - specifically 'intervention_instance_can_unlock'.
   * @return Observable<any> - An observable for any response.
   */
  public unlockQuestionnaireAsECoach(instance: InterventionInstanceInterface, unlockedQuestionnaires: Array<number>, activityId?: number) {
    const requestBody = new RequestBodyData('intervention_instance', {
      configuration: {
        custom_order: instance.attributes.configuration.custom_order
      },
      progress: {
        unlocked_questionnaires: unlockedQuestionnaires
      }
    });
    if (activityId !== undefined) {
      requestBody.data.attributes.activity_id = activityId;
    }
    return this.http.patch<any>(`${this.backendUrl}/api/v1/ecoach/interventions/instances/${instance.id}/unlock`, requestBody, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API ECoach - Unpause intervention instance
   * Sets intervention instance state back to its previous state
   *
   * @params number instance_id - ID of the intervention instance.
   * @return Observable<any> - An observable for any response.
   */
  public unpauseInstance(instanceId: number) {
    return this.http.patch<any>(`${this.backendUrl}/api/v1/ecoach/interventions/instances/${instanceId}/unpause`, null, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API ECoach Manager - Unpause intervention instance as eCoach manager
   * Sets intervention instance state back to its previous state if the intervention instance is part of the study.
   *
   * @params number instance_id - ID of the intervention instance.
   * @return Observable<any> - An observable for any response.
   */
  public unpauseInstanceECoachManager(instanceId: number) {
    return this.http.patch<any>(`${this.backendUrl}/api/v1/ecoachmanager/interventions/instances/${instanceId}/unpause`, null, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API ECoach - Get Instance Report Queue
   * This function returns a report of an intervention instance in PDF. Given query parameters can be interpreted as booleans,
   * so 0 = FALSE and 1 = TRUE. If set to true, the data will be included in the report
   *
   * @params number instanceId - ID of the intervention instance.
   *         number unfinishedQuestionnaires - Includes unfinished questionnaires in report.
   *         number activities - Includes activities in report.
   *         number diaries - Includes related diaries to intervention instance in report.
   * @return Observable<any> - An observable for any response.
   */
  public getInstancePDFReportQueue(
    instanceId: number,
    unfinishedQuestionnaires?: number,
    activities?: number,
    diaries?: number,
    feedback?: number
  ): Observable<any> {
    let params = new HttpParams();
    if (unfinishedQuestionnaires !== undefined) {
      params = params.set('unfinishedQuestionnaires', unfinishedQuestionnaires.toString());
    }
    if (activities !== undefined) {
      params = params.set('activities', activities.toString());
    }
    if (diaries !== undefined) {
      params = params.set('diaries', diaries.toString());
    }
    if (feedback !== undefined) {
      params = params.set('feedback', feedback.toString());
    }
    const timezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (timezone !== undefined) {
      params = params.set('timezone', timezone);
    }
    this.header = this.helperService.setLocaleFromStorage(this.header);
    return this.http.get(`${this.backendUrl}/api/v1/ecoach/queue/interventions/instances/${instanceId}/pdf/report`, {
      headers: this.header,
      reportProgress: true,
      observe: 'events',
      params
    });
  }

  public getInstanceReport(
    instanceId: number,
    unfinishedQuestionnaires?: number,
    activities?: number,
    diaries?: number,
    feedback?: number
  ): Observable<any> {
    this.header = this.header.set('Content-Type', '*/*');
    this.header = this.header.set('Accept', '*/*');
    let params = new HttpParams();
    if (unfinishedQuestionnaires !== undefined) {
      params = params.set('unfinishedQuestionnaires', unfinishedQuestionnaires.toString());
    }
    if (activities !== undefined) {
      params = params.set('activities', activities.toString());
    }
    if (diaries !== undefined) {
      params = params.set('diaries', diaries.toString());
    }
    if (feedback !== undefined) {
      params = params.set('feedback', feedback.toString());
    }
    let downloaded = false;
    return this.http
      .get(`${this.backendUrl}/api/v1/ecoach/interventions/instances/${instanceId}/pdf/report`, {
        headers: this.header,
        reportProgress: true,
        observe: 'events',
        params,
        responseType: 'blob' as 'json'
      })
      .pipe(
        download(jsonResponse => {
          const getFileName = (response: HttpResponse<any>) => {
            let fileName: string;
            try {
              const contentDisposition: string = response.headers.get('content-disposition');
              const r = /filename="(.+)"/;
              fileName = r.exec(contentDisposition)[1];
            } catch (error) {
              fileName = `report-${new Date().toISOString()}.pdf`;
            }
            return fileName;
          };

          const filename = `report-${new Date().toISOString()}.pdf`;
          const binaryData = [];
          binaryData.push(jsonResponse);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/pdf' }));
          downloadLink.setAttribute('download', filename);
          document.body.appendChild(downloadLink);
          if (!downloaded) {
            downloadLink.click();
            URL.revokeObjectURL(downloadLink.href);
            downloaded = true;
          }
        })
      );
  }

  /**
   * AAS2 API ECoach - Submits a feedback as Ecoach
   * This function creates an intervention instance.
   *
   * @params PayloadInterface payload contains:
   *         string subject - feedback (thread) subject.
   *         string message - first message of the feedback/thread.
   *         number questionnaire_id - ID of the questionnaire.
   *         number answersheet_id - ID of the answersheet.
   * @return Observable<any> - An observable for any response.
   */
  public submitFeedbackAsECoach(
    instanceId: number,
    subject: string,
    message: string,
    answersheetId: number,
    questionnaireId: number,
    activityId: number
  ): Observable<any> {
    const feedback = {
      thread: {
        subject
      },
      message: {
        body: message
      },
      questionnaire_id: questionnaireId,
      answersheet_id: answersheetId,
      activity_id: activityId
    };
    const payload: PayloadInterface = new RequestBodyData('intervention_instance', feedback);
    return this.http.patch<any>(`${this.backendUrl}/api/v1/ecoach/interventions/instances/${instanceId}/feedback`, payload, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API User - Checks and updated unlocked_questionnaires of all user's instances
   * Unlocks questionnaires that is unlock_type=at_date.
   * Unlocks questionnaires that should be unlocked after 'starts_at' has passed.
   *
   * @return Observable<any> - An observable for any response.
   */
  public unlockMyInstances(): Observable<any> {
    return this.http.patch<any>(
      `${this.backendUrl}/api/v1/my/interventions/instances/unlock`,
      {},
      { headers: this.header, observe: 'response' }
    );
  }

  /**
   * AAS2 API ECoach - Check and update unlocked_questionnaires of all eCoach instances
   * Unlocks questionnaires that is unlock_type=at_date.
   * Unlocks questionnaires that should be unlocked after 'starts_at' has passed.
   *
   * @return Observable<any> - An observable for any response.
   */
  public unlockMyInstancesAsEcoach(): Observable<any> {
    return this.http.patch<any>(
      `${this.backendUrl}/api/v1/ecoach/interventions/instances/unlock`,
      {},
      { headers: this.header, observe: 'response' }
    );
  }

  /**
   * AAS2 API ECoach Manager - Add collaborator to all intervention instances of the eCoach within the study
   * Adds a study collaborator to all intervention instances of the target eCoach of a respective study.
   * The newly added study collaborator (study.ecoach, study.ecoachmanager, ecoach.owner) has access to all data of the intervention
   * instance.
   * Only study.ecoachmanager & study.owner may execute this request.
   *
   * @params number studyId - ID of the study.
   *         number currentECoach - ID of the target user
   *         number newECoach - ID of the user to be added to the intervention instances.
   * @return Observable<any> - An observable for any response.
   */
  public addECoachToECoachInterventionInstancesOfStudy(studyId: number, currentECoach: number, newECoach: number) {
    const requestBody = new RequestBodyData('intervention_instance', {
      study_id: studyId,
      current_ecoach: currentECoach,
      new_ecoach: newECoach
    });
    return this.http.patch<any>(`${this.backendUrl}/api/v1/ecoachmanager/interventions/instances/ecoach`, requestBody, {
      headers: this.header,
      observe: 'response'
    });
  }

  public setHttpParams(
    ecoachId?: number,
    patientId?: number,
    interventionId?: number,
    interventionType?: string,
    pendingInvitation?: boolean,
    action?: string,
    finished?: number | boolean,
    include?: string
  ): HttpParams {
    let params = new HttpParams();
    if (ecoachId !== undefined) {
      params = params.set('ecoach_id', ecoachId.toString());
    }
    if (patientId !== undefined) {
      params = params.set('patient_id', patientId.toString());
    }
    if (interventionId !== undefined) {
      params = params.set('intervention_id', interventionId.toString());
    }
    if (interventionType !== undefined) {
      params = params.set('intervention_type', interventionType);
    }
    if (pendingInvitation !== undefined) {
      params = params.set('pendingInvitation', pendingInvitation.toString());
    }
    if (action !== undefined) {
      params = params.set('action', action);
    }
    if (finished !== undefined) {
      params = params.set('finished', finished.toString());
    }
    if (include !== undefined) {
      params = params.set('include', include.toString());
    }
    return params;
  }
}
