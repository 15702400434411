import { Action, createAction, props } from '@ngrx/store';
import { LessonInterface } from '../../models/interface/lesson.interface';

export enum LessonQuestionnaireActionTypes {
  addAllQuestionnairesStoreType = 'Add All Questionnaires Store',
  updateAllQuestionnairesStoreTyp = 'Update All Questionnaires Store',
  deleteAllQuestionnairesStoreTyp = 'Delete All Questionnaires Store',

  getAllQuestionnairesType = '[Lesson Questionnaire API] Get All Questionnaires',
  getAllQuestionnairesSuccessType = '[Success] Get All Questionnaires',
  getAllQuestionnairesErrorType = '[Error] Get All Questionnaires'
}

export const addAllQuestionnairesStore = createAction(
  LessonQuestionnaireActionTypes.addAllQuestionnairesStoreType,
  props<{ allQuestionnaires: Array<LessonInterface> }>()
);
export const updateAllQuestionnairesStore = createAction(
  LessonQuestionnaireActionTypes.updateAllQuestionnairesStoreTyp,
  props<{ allQuestionnaires: Array<LessonInterface> }>()
);
export const removeAllQuestionnairesStore = createAction(LessonQuestionnaireActionTypes.deleteAllQuestionnairesStoreTyp);

export class GetAllQuestionnaires implements Action {
  readonly type: string = LessonQuestionnaireActionTypes.getAllQuestionnairesType;
  constructor(readonly payload: any) {}
}
