<button [attr.id]="'btnDialogClose'" mat-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title>{{'patient-diary.modal_diary_answersheet_preview_title' | translate}}</h1>
<div mat-dialog-content>
  <!-- ng-template to ensure that the generated components end up in this place -->
  <ng-template #answersheetsPreviewContainer> </ng-template>
</div>
<div mat-dialog-actions align="end">
  <button [attr.id]="'btnCancel'" mat-button mat-dialog-close mat-dialog-close="true">
    {{'dialog.intervention-detail.dialog_close' | translate}}
  </button>
</div>
