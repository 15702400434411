import { createReducer, on } from '@ngrx/store';
import { LessonInterface } from '../../models/interface/lesson.interface';
import { addAllQuestionnairesStore, updateAllQuestionnairesStore, removeAllQuestionnairesStore } from './lesson-questionnaire.action';

export const initialAllQuestionnaires: Array<LessonInterface> = [];
export const allQuestionnairesReducer = createReducer(
  initialAllQuestionnaires,
  on(addAllQuestionnairesStore, (state, { allQuestionnaires }) => allQuestionnaires),
  on(updateAllQuestionnairesStore, (state, { allQuestionnaires }) => allQuestionnaires),
  on(removeAllQuestionnairesStore, (state, {}) => [])
);
