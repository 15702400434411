<button [attr.id]="'btnDialogClose'" mat-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title>{{'admin-dashboard-organisation.organisation_detail_title' | translate:param}}</h1>

<div class="content-height" mat-dialog-content>
  <mat-tab-group mat-align-tabs="center">
    <mat-tab label="{{'admin-dashboard-organisation.studies' | translate}}">
      <div class="container text-start">
        <br />

        <div *ngIf="(studiesSubject | async).length > 0; then thenShowStudies else elseNoStudies"></div>
        <ng-template #thenShowStudies>
          <app-pagination-list
            #paginatorStudies
            (emitPageChange)="updatePagedStudies($event)"
            [_isLoading]="isLoading$ | async"
            [_items]="studiesContent$ | async"
          ></app-pagination-list>

          <ul *ngFor="let result of (pagedStudies$ | async); trackBy: trackByJobId" class="list-group">
            <li class="list-group-item mb-1">
              <div class="row">
                <div class="col-4 row">
                  <div class="col-12">{{result.attributes.name}}</div>
                  <div class="col-12"><span class="text-muted">{{result.attributes.title}} (ID - {{result.id}})</span></div>
                </div>
                <div class="col-8 row">
                  <div>{{result.attributes.description}}</div>
                </div>
              </div>
            </li>
          </ul>
        </ng-template>
        <ng-template #elseNoStudies>
          <p class="text-center">{{'admin-dashboard-organisation.no_studies' | translate}}</p>
        </ng-template>
      </div>
    </mat-tab>
    <mat-tab label="{{'admin-dashboard-organisation.collaborators' | translate}}">
      <div class="container text-start">
        <br />

        <div *ngIf="(pagedOrganisationCollaborators$ | async)?.length > 0; then thenShowCollaborators else elseNoCollaborators"></div>
        <ng-template #thenShowCollaborators>
          <app-pagination-list
            #paginatorStudies
            (emitPageChange)="updatePagedOrganisationCollaborators($event)"
            [_isLoading]="isLoading$ | async"
            [_items]="organisationCollaboratorsContent$ | async"
          ></app-pagination-list>

          <div *ngFor="let collaborator of pagedOrganisationCollaborators$ | async" class="mb-3">
            <mat-card appearance="outlined">
              <mat-card-header class="card-header-center">
                <mat-card-title>
                  <fa-icon [icon]="faEnvelope"></fa-icon>
                  {{((collaborator.attributes.email) ? collaborator.attributes.email : ('intervention-collaborator.no_email' | translate))}}
                </mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <p class="mb-1 text-center">
                  {{((collaborator.attributes.firstname && collaborator.attributes.lastname) ? collaborator.attributes.firstname + ' ' +
                  collaborator.attributes.lastname : ('organisation-collaborator.no_names' | translate))}}
                </p>
                <p class="mb-1 text-center">
                  {{((collaborator.attributes.name) ? collaborator.attributes.name : ('organisation-collaborator.no_username' |
                  translate))}}
                </p>
              </mat-card-content>
            </mat-card>
          </div>
        </ng-template>
        <ng-template #elseNoCollaborators>
          <p class="text-center">{{'admin-dashboard-organisation.no_collaborators' | translate}}</p>
        </ng-template>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
