import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { iif, Observable, of } from 'rxjs';
import { StudyInterface } from '../../models/interface/study/study.interface';
import { catchError, skip, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { StudyActionTypes } from '../../../app/store/study/study.action';
import { getCollabGroupById } from '../../../app/store/study/study.selector';

@Injectable({
  providedIn: 'root'
})
export class GroupGuard implements CanActivate {
  private group$: Observable<StudyInterface>;

  constructor(private router: Router, private store: Store<{ getCollabGroupById: StudyInterface }>) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const groupId = parseInt(state.url.replace(/\D/g, ''), 10);
    this.group$ = this.store.select(getCollabGroupById(groupId));
    this.store.dispatch({ type: StudyActionTypes.getCollaboratingStudiesType, payload: { include: `owners,roles` } });
    return this.group$.pipe(
      skip(1),
      take(1),
      switchMap((group: StudyInterface) => iif(() => group.attributes.type === 'study', of(true), of(this.router.parseUrl('/groups')))),
      catchError(() => of(this.router.parseUrl('/groups')))
    );
  }
}
