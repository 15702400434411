<button
  [attr.id]="btnId"
  [style.width.%]="width"
  [ngSwitch]="(resetResponseSubject | async)"
  color="primary"
  mat-button
  [ngClass]="{'mat-button': buttonType === 'mat-button', 'mat-raised-button': buttonType === 'mat-raised-button', 'mat-flat-button': buttonType === 'mat-flat-button', 'mat-stroked-button': buttonType === 'mat-stroked-button', 'mat-fab': buttonType === 'mat-fab', 'mat-mini-fab': buttonType === 'mat-mini-fab'}"
  [disabled]="isDisabled || ((resetResponseSubject | async) === 'LOADING') || (isLoadingReset$ | async)"
>
  <span id="textLoading" *ngSwitchCase="'LOADING'">
    <span aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span>
    {{'button-feedback.loading' | translate}}
  </span>
  <span id="textDefault" *ngSwitchCase="'DEFAULT'"> <fa-icon *ngIf="icon" [icon]="icon"></fa-icon> {{defaultButtonText | translate}} </span>
  <span id="textSuccess" *ngSwitchCase="'SUCCESS'">
    <fa-icon [icon]="faCheck"></fa-icon>
    {{successButtonText | translate}}
  </span>
  <span id="textFailure" *ngSwitchCase="'FAILURE'">
    <fa-icon [icon]="faTimes"></fa-icon>
    {{errorButtonText | translate}}
  </span>
</button>
