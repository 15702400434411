import { createReducer, on } from '@ngrx/store';
import { InterventionInterface } from 'src/app/models/interface/intervention.interface';
import {
  addCollabInterventionsStore,
  addInactiveCollabInterventionsStore,
  addNonCollabInterventionsStore,
  removeCollabInterventionsStore,
  removeInactiveCollabInterventionsStore,
  removeNonCollabInterventionsStore,
  updateCollabInterventionsStore,
  updateInactiveCollabInterventionsStore,
  updateNonCollabInterventionsStore
} from './intervention.action';

export const initialCollabInterventionsState: Array<InterventionInterface> = [];
export const collabInterventionsReducer = createReducer(
  initialCollabInterventionsState,
  on(addCollabInterventionsStore, (state, { collabInterventions }) => collabInterventions),
  on(updateCollabInterventionsStore, (state, { collabInterventions }) => collabInterventions),
  on(removeCollabInterventionsStore, (state, {}) => [])
);

export const initialInactiveCollabInterventionsState: Array<InterventionInterface> = [];
export const inactiveCollabInterventionsReducer = createReducer(
  initialInactiveCollabInterventionsState,
  on(addInactiveCollabInterventionsStore, (state, { inactiveCollabInterventions }) => inactiveCollabInterventions),
  on(updateInactiveCollabInterventionsStore, (state, { inactiveCollabInterventions }) => inactiveCollabInterventions),
  on(removeInactiveCollabInterventionsStore, (state, {}) => [])
);

export const initialNonCollabInterventionsState: Array<InterventionInterface> = [];
export const nonCollabInterventionsReducer = createReducer(
  initialNonCollabInterventionsState,
  on(addNonCollabInterventionsStore, (state, { nonCollabInterventions }) => nonCollabInterventions),
  on(updateNonCollabInterventionsStore, (state, { nonCollabInterventions }) => nonCollabInterventions),
  on(removeNonCollabInterventionsStore, (state, {}) => [])
);
