<button [attr.id]="'btnDialogClose'" mat-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title>{{'intervention-instance.modal_create_instance_title' | translate:param}}</h1>
<div mat-dialog-content [appCustomScroll]="(updateScroll | async)" #appScrollElement="appCustomScroll" #scroll>
  <div [hidden]="confirmedCreation">
    <div class="container-fluid">
      <!-- Create instance -->
      <div class="container text-start mb-3">
        <div class="form-group text-start">
          <strong>{{'group-instance.form_create_select_patient_description' | translate}}</strong>
        </div>
        <div class="form-group row">
          <div *ngIf="members.length > 0; then thenShowUsers else elseShowNoUsers"></div>
          <ng-template #thenShowUsers>
            <mat-form-field class="full-width center-mat-form-field">
              <mat-label>{{'group-instance.selected_user' | translate}}</mat-label>
              <mat-select
                [attr.id]="'selectionUser'"
                (click)="resetFilter()"
                (selectionChange)="showSelected(selectedUser)"
                [(ngModel)]="selectedUser"
              >
                <mat-form-field class="full-width" appearance="fill">
                  <mat-label>{{'group-instance.filter_selection' | translate}}</mat-label>
                  <input
                    [attr.id]="'userSelection'"
                    autocomplete="off"
                    matInput
                    (keyup)="helper.onKeyFilterUser(members, filter['userSelection'], membersSubject, selectedUser)"
                    [(ngModel)]="filter['userSelection']"
                  />
                </mat-form-field>
                <mat-option [attr.id]="'option_' + selection.id" *ngFor="let selection of (members$ | async)" [value]="selection"
                  >{{helper.getCodeNameEmail(selection.id, members)}}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <div class="col-sm-10 text-danger" *ngIf="hasPendingInstances()">
              <fa-icon [icon]="faExclamationCircle"></fa-icon>
              {{'patient-creation-intervention-selection.tooltip_pending_intervention_instance' | translate}}
            </div>
          </ng-template>
          <ng-template #elseShowNoUsers>
            <div class="col-sm-8 text-start">{{'group-instance.form_create_no_patient_recruit' | translate}}</div>
          </ng-template>
        </div>
      </div>
    </div>

    <app-instance-creation-form
      #createInstanceChild
      (confirmedForm)="onConfirmCreation($event)"
      [_collaborators]="helper.excludeUsersWithOnlyStudyAccess(collaborators)"
      [_intervention]="intervention"
      [_members]="[]"
      [_myUserId]="myUserId"
      [_questionnaires]="questionnaires"
    ></app-instance-creation-form>
  </div>

  <!-- Confirmation -->
  <div [hidden]="!confirmedCreation" [appCustomScroll]="(updateScroll | async)" #appScrollElement="appCustomScroll" #scroll>
    <ng-template #createPatientAccountContainer> </ng-template>
  </div>
</div>

<div mat-dialog-actions align="end">
  <button
    [attr.id]="'btnShowNextComponent'"
    (click)="showNextComponent()"
    *ngIf="!confirmedCreation"
    [disabled]="(members.length === 0 && !selectedUser) || hasPendingInstances() || appScrollElement.isScrollbarVisible()"
    mat-button
    matBadge="!"
    [matBadgePosition]="'above before'"
    [matBadgeHidden]="!appScrollElement.isScrollbarVisible()"
  >
    {{'intervention-instance.modal_instance_confirm' | translate}}
  </button>

  <!-- Postconfirmation -->
  <button [attr.id]="'btnShowPreviousComponent'" (click)="showPreviousComponent()" *ngIf="confirmedCreation" mat-button>
    {{'intervention-instance.modal_back' | translate}}
  </button>
  <app-button-feedback
    [_setId]="'btnCreateInstance'"
    *ngIf="confirmedCreation"
    (click)="createInstance()"
    [_defaultButtonText]="'intervention-instance.modal_create_instance'"
    [_responseText]="createInstanceResponse | async"
  ></app-button-feedback>
</div>
