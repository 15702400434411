import { Component, Input, OnInit } from '@angular/core';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { Observable, skip, take } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { JobResultInterface } from '../../../models/interface/jobresult.interface';
import { HelperService } from '../../../services/helper/helper.service';
import { QueueStore } from '../../../store/queue/component-store/queue.store';

@Component({
  selector: 'app-jobresult-download',
  templateUrl: './jobresult-download.component.html',
  styleUrls: ['./jobresult-download.component.scss'],
  providers: [QueueStore]
})
export class JobresultDownloadComponent implements OnInit {
  // Icons
  faDownload = faDownload;

  public result: JobResultInterface;

  // Response
  public fileDownloadResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');

  private downloadReportResponse$: Observable<any>;

  constructor(private queueStore: QueueStore, private helperService: HelperService) {
    this.downloadReportResponse$ = queueStore.downloadReportResponse$;
  }

  // Helper function
  public get helper() {
    return this.helperService;
  }

  @Input()
  set _result(_result: JobResultInterface) {
    if (_result) {
      this.result = _result;
    }
  }

  ngOnInit(): void {}

  public downloadFile(reportName: string): void {
    const resultId = this.result.attributes.result_id;
    let contentType = 'application/text';
    if (resultId.includes('.pdf')) {
      contentType = 'application/text';
    }
    if (resultId.includes('.pdf')) {
      contentType = 'text/csv';
    }

    this.fileDownloadResponse.next('LOADING');
    this.queueStore.downloadReport({ reportName });
    this.downloadReportResponse$.pipe(skip(1), take(1)).subscribe(
      (result: any) => {
        if (result instanceof Blob) {
          const newBlob = new Blob([result], { type: contentType });
          const data = window.URL.createObjectURL(newBlob);
          const link = document.createElement('a');
          link.href = data;
          link.download = reportName;
          link.click();
          this.fileDownloadResponse.next('SUCCESS');
        } else {
          this.fileDownloadResponse.next('FAILURE');
        }
      },
      () => {
        this.fileDownloadResponse.next('FAILURE');
        setTimeout(() => {
          this.fileDownloadResponse.next('DEFAULT');
        }, 2500);
      },
      () => {
        setTimeout(() => {
          this.fileDownloadResponse.next('DEFAULT');
        }, 2500);
      }
    );
  }
}
