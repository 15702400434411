/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, filter, mergeMap, skip, take } from 'rxjs/operators';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar';
import { Action, Store } from '@ngrx/store';
import { OrganisationCollaboratorInterface } from '../../../models/interface/organisation/organisation-collaborator.interface';
import { OrganisationSharedService } from '../../../services/shared/organisation-shared/organisation-shared.service';
import { HelperService } from '../../../services/helper/helper.service';
import { UserInterface } from '../../../models/interface/user.interface';
import { PermissionInterface } from '../../../models/interface/permission.interface';
import { RoleInterface } from '../../../models/interface/role.interface';
import { HttpResponse } from '@angular/common/http';
import { ProfileInterface } from '../../../models/interface/profile.interface';
import { Actions, ofType } from '@ngrx/effects';
import { GetOrganisationCollaboratorsSuccess, OrganisationActionTypes } from '../../../store/organisation/organisation.action';
import { HelperDialogService } from '../../../services/helper/helper-dialog/helper-dialog.service';
import { UserActionTypes } from '../../../store/user/user.action';
import { UserStore } from '../../../store/user/component-store/user.store';
import { OrganisationInterface } from 'src/app/models/interface/organisation/organisation.interface';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import { AuthenticationStore } from 'src/app/store/authentication/component-store/authentication.store';
import { AlertService } from 'src/app/services/alert/alert.service';

@Component({
  selector: 'app-organisation-collaborator',
  templateUrl: './organisation-collaborator.component.html',
  styleUrls: ['./organisation-collaborator.component.scss'],
  providers: [UserStore, AuthenticationStore]
})
export class OrganisationCollaboratorComponent implements OnInit, OnDestroy {
  @ViewChild('paginator') paginator;

  // Icons
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;
  faStar = faStar;
  faEnvelope = faEnvelope;
  faCheckCircle = faCheckCircle;
  faTimesCircle = faTimesCircle;

  public isLoading$: Observable<boolean>;

  public myUserId: number;

  // Data provided by OrganisationService
  public collaborators: Array<OrganisationCollaboratorInterface> = [];
  public eCoaches: Array<UserInterface>;

  // Filter
  public filter = { searchFilter: '' };
  public searchTextSubject = new BehaviorSubject<string>('');

  public pagedCollaborators: Observable<Array<OrganisationCollaboratorInterface>>;

  public isCollapse$: Observable<boolean>;

  public collaborators$: Observable<Array<UserInterface>>;

  // Check if logged in user is organisation manager
  public isManager$: Observable<boolean>;

  // Check if logged in user is organisation owner
  public isOwner$: Observable<boolean>;

  // Translation
  public param = { intervention_name: '...' };

  public profile$: Observable<ProfileInterface>;
  public profileRoles$: Observable<Array<RoleInterface>>;

  public resendVerificationResponse$: Observable<any>;

  public isCollapseSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private isModalLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private allECoaches$: Observable<Array<UserInterface>>;

  // Organisation ID and loading status for this child component
  private organisationId: number;
  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private pagedCollaboratorsSubject: BehaviorSubject<Array<OrganisationCollaboratorInterface>> = new BehaviorSubject<
    Array<OrganisationCollaboratorInterface>
  >([]);

  private collaboratorsSubject: BehaviorSubject<Array<OrganisationCollaboratorInterface>> = new BehaviorSubject([]);

  private isManagerSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isOwnerSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private allCollaborators$: Observable<Array<UserInterface>>;

  private subscriptions: Subscription[] = [];

  constructor(
    private sharedService: OrganisationSharedService,
    private helperService: HelperService,
    private helperDialogService: HelperDialogService,
    private alertService: AlertService,
    private userStore: UserStore,
    private authenticationStore: AuthenticationStore,
    private actions$: Actions,
    private store: Store<{ myProfile: ProfileInterface; myRoles: Array<RoleInterface>; allECoaches: Array<UserInterface> }>
  ) {
    this.profile$ = store.select('myProfile');
    this.profileRoles$ = store.select('myRoles');

    this.isLoading$ = this.isLoadingSubject.asObservable();

    this.pagedCollaborators = this.pagedCollaboratorsSubject.asObservable();
    this.isCollapse$ = this.isCollapseSubject.asObservable();
    this.collaborators$ = this.collaboratorsSubject.asObservable();
    this.isManager$ = this.isManagerSubject.asObservable();
    this.isOwner$ = this.isOwnerSubject.asObservable();
    this.allECoaches$ = this.store.select('allECoaches');
    this.allCollaborators$ = this.userStore.allCollaborators$;
    this.resendVerificationResponse$ = this.authenticationStore.resendVerificationResponse$;
  }

  public get helper() {
    return this.helperService;
  }

  public get helperDialog() {
    return this.helperDialogService;
  }

  ngOnInit(): void {
    // Sharing parent values: id and organisation name
    this.subscriptions.push(
      this.sharedService.sourceOrganisation$.subscribe((value: OrganisationInterface) => {
        if (value === null) {
          this.isLoadingSubject.next(true);
        } else {
          this.organisationId = value.id;
          this.param = { intervention_name: value.attributes.name };
          this.subscriptions.push(
            this.profile$
              .pipe(
                filter(user => user !== null),
                take(1)
              )
              .subscribe(
                (result: ProfileInterface) => {
                  this.myUserId = result.id;
                  this.applyCollaboratorFilter();
                },
                error => {
                  throwError('Loading error', error);
                }
              )
          );
        }
      })
    );
  }

  public applyCollaboratorFilter(): void {
    this.isLoadingSubject.next(true);
    this.subscriptions.push(
      this.reloadCollaboratorList(true).subscribe((result: any) => {
        this.isLoadingSubject.next(false);
      })
    );
  }

  public reloadCollaboratorList(setFirst?: boolean): Observable<boolean> {
    const pageIndex = this.paginator?.paginator ? this.paginator?.paginator.pageIndex : 0;
    const pageSize = this.paginator?.paginator ? this.paginator?.paginator.pageSize : 20;
    let foundAdmin;
    return this.profileRoles$.pipe(
      filter(user => user !== null),
      mergeMap((resp: Array<RoleInterface>) => {
        const roles: Array<RoleInterface> = resp;
        foundAdmin = this.helperService.hasRoles(roles, /admin$/);

        this.store.dispatch({
          type: OrganisationActionTypes.getOrganisationCollaboratorsType,
          payload: {
            organisationId: this.organisationId
          }
        });

        return this.actions$.pipe(
          ofType(
            OrganisationActionTypes.getOrganisationCollaboratorsSuccessType,
            OrganisationActionTypes.getOrganisationCollaboratorsErrorType
          ),
          take(1),
          mergeMap((action: Action) => {
            if (action.type === OrganisationActionTypes.getOrganisationCollaboratorsSuccessType) {
              const result: HttpResponse<any> = (action as GetOrganisationCollaboratorsSuccess).response;
              this.collaborators = result['body']['data'];
              // Determine my highest role
              const myself: OrganisationCollaboratorInterface = this.collaborators.find(user => user.id === this.myUserId);
              if (myself !== undefined) {
                const myroleSlug = this.helperService.getHighestRoleOfOrganisationCollaborator(myself);
                this.isManagerSubject.next(!!myroleSlug.match(/organisation\.(manager|owner)$/));
                this.isOwnerSubject.next(!!myroleSlug.match(/organisation\.owner$/));
              }
            }
            if (foundAdmin || this.isOwnerSubject.value) {
              this.store.dispatch({ type: UserActionTypes.getAllEcoachesType, payload: {} });
              return this.allECoaches$.pipe(skip(1), take(1));
            } else {
              this.userStore.getAllCollaboratorsOfUser({ roles: 'ecoach' });
              return this.allCollaborators$.pipe(skip(1), take(1));
            }
          }),
          catchError(() => {
            if (foundAdmin || this.isOwnerSubject.value) {
              this.store.dispatch({ type: UserActionTypes.getAllEcoachesType, payload: {} });
              return this.allECoaches$.pipe(skip(1), take(1));
            } else {
              this.userStore.getAllCollaboratorsOfUser({ roles: 'ecoach' });
              return this.allCollaborators$.pipe(skip(1), take(1));
            }
          })
        );
      }),
      mergeMap((result: any) => {
        // All user with role editor
        this.eCoaches = this.getCollaboratorsNotInOrganisation(result, this.collaborators);

        // If user is not manager - they can only see organisation.manager and organisation.owner
        if (!this.isManagerSubject.value) {
          this.collaborators = this.collaborators.filter((user: OrganisationCollaboratorInterface) => {
            const includedRoles: Array<PermissionInterface> = user.attributes.roles;
            const foundManager = includedRoles.find(role => role.slug === 'organisation.manager' || role.slug === 'organisation.owner');
            return foundManager !== undefined;
          });
        }
        return this.search(this.filter['searchFilter']).pipe(
          mergeMap(() =>
            this.helper.setPagedContent(this.collaboratorsSubject, this.pagedCollaboratorsSubject, setFirst, pageIndex, pageSize)
          )
        );
      }),
      catchError(error => throwError('Loading error', error))
    );
  }

  public updatePagedCollaborators(event: any) {
    if (event) {
      console.log(this.pagedCollaboratorsSubject.value);
    }
  }

  public openDialogOrganisationCollaboratorAdd(): void {
    this.helperDialog
      .openDialogOrganisationCollaboratorAdd(this.param, this.organisationId, this.collaborators, this.eCoaches)
      .afterClosed()
      .pipe(mergeMap((value: any) => this.reloadCollaboratorList()))
      .subscribe(() => {});
  }

  public openDialogOrganisationCollaboratorEdit(): void {
    this.helperDialog
      .openDialogOrganisationCollaboratorEdit(this.param, this.organisationId, this.collaborators, this.eCoaches)
      .afterClosed()
      .pipe(mergeMap((value: any) => this.reloadCollaboratorList()))
      .subscribe(() => {});
  }

  public openDialogOrganisationCollaboratorRemove(user: UserInterface): void {
    this.helperDialog
      .openDialogOrganisationCollaboratorRemove(this.param, this.organisationId, this.collaborators, this.eCoaches, user)
      .afterClosed()
      .pipe(mergeMap((value: any) => this.reloadCollaboratorList()))
      .subscribe(() => {});
  }

  public openDialogOrganisationAccountCreation(): void {
    this.helperDialog
      .openDialogOrganisationAccountCreation([this.organisationId])
      .afterClosed()
      .pipe(mergeMap((value: any) => this.reloadCollaboratorList()))
      .subscribe(() => {});
  }

  public openDialogDeleteUnverified(user: UserInterface): void {
    this.helperDialog
      .openDialogUnverifiedOrganisationAccountDelete(this.organisationId, user, this.myUserId)
      .afterClosed()
      .pipe(mergeMap((value: any) => this.reloadCollaboratorList()))
      .subscribe(() => {});
  }

  public openDialogPatientResendVerification(): void {
    const dialogRef = this.helperDialog.openDialogPatientResendVerification(this.isModalLoadingSubject);
    const loading = this.isModalLoadingSubject.asObservable().subscribe(value => {
      if (dialogRef && dialogRef.componentInstance) {
        dialogRef.componentInstance.data = { submitted: value };
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      loading.unsubscribe();
    });
  }

  public resendVerificationEmail(email: string): void {
    this.isModalLoadingSubject.next(true);

    this.authenticationStore.resendVerification(email);
    this.resendVerificationResponse$
      .pipe(
        filter(value => !!value),
        take(1)
      )
      .subscribe((value: any) => {
        if (value instanceof HttpResponse) {
          this.alertService.success('ACCOUNT_RESEND_SUCCEEDED');
        } else {
          this.alertService.error('ACCOUNT_RESEND_FAILED');
        }
        this.isModalLoadingSubject.next(false);
        this.openDialogPatientResendVerification();
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  // Reads search value and runs searchFilter
  private search(name: string): Observable<boolean> {
    this.searchTextSubject.next(name);
    return this.searchTextSubject.pipe(
      distinctUntilChanged(),
      mergeMap(() => {
        this.collaboratorsSubject.next(
          this.helper.filterOrganisationCollaboratorsBy(this.collaborators, this.filter['searchFilter'].toLowerCase().trim())
        );
        return of(true);
      })
    );
  }

  // Get all eCoaches who are not part of the organisation
  private getCollaboratorsNotInOrganisation(
    allEditors: Array<UserInterface>,
    myCollaborators: Array<OrganisationCollaboratorInterface>
  ): Array<UserInterface> {
    let notCollab: Array<UserInterface> = allEditors;
    myCollaborators.forEach(myCollab => {
      notCollab = notCollab.filter(editor => editor.id !== myCollab.id);
    });
    return notCollab;
  }
}
