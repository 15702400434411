/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of, Subscription, throwError } from 'rxjs';
import { ActivatedRoute, Router, RouterEvent } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { HelperActivityService } from '../../../services/helper/helper-activity/helper-activity.service';
import { ActivityInterface } from '../../../models/interface/activity.interface';
import { TableSortInterface } from '../../../models/interface/table/table_sort.interface';
import { HelperService } from '../../../services/helper/helper.service';
import { UserInterface } from '../../../models/interface/user.interface';
import { LessonInterface } from '../../../models/interface/lesson.interface';
import { Store } from '@ngrx/store';
import { UserActionTypes } from '../../../store/user/user.action';
import { UserStore } from '../../../store/user/component-store/user.store';

/**
 * Component:
 * Activity page displaying a list of activities of a group;
 * Can be found: {uri}/groups/{{group_id}}/activities
 */

@Component({
  selector: 'app-group-activity',
  templateUrl: './group-activity.component.html',
  styleUrls: ['./group-activity.component.scss'],
  providers: [UserStore]
})
export class GroupActivityComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public displayedColumns: string[] = ['activity_text', 'performed_by', 'created_at'];
  public dataSource;

  // Study ID and loading status for this child component
  public studyId: number;
  public isLoading$: Observable<boolean>;

  public activities: Array<ActivityInterface> = [];
  public activities$: Observable<Array<ActivityInterface>>;

  public isManager$: Observable<boolean>;

  // Data provided by UserService
  public members: Array<UserInterface> = [];
  public lessons: Array<LessonInterface> = [];

  public tableSort: TableSortInterface = {
    sortActivityId: null,
    sortAction: null,
    sortActionId: null,
    sortText: null,
    sortPerformedBy: null,
    sortCreatedAt: null
  };

  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private activitiesSubject: BehaviorSubject<Array<ActivityInterface>> = new BehaviorSubject<Array<ActivityInterface>>([]);
  private isManagerSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private myMembers$: Observable<Array<UserInterface>>;

  private studyActivities$: Observable<Array<ActivityInterface>>;

  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private actRoute: ActivatedRoute,
    private userStore: UserStore,
    private store: Store<{
      myMembers: Array<UserInterface>;
    }>,
    private helperService: HelperService,
    private helperActivityService: HelperActivityService
  ) {
    this.activities$ = this.activitiesSubject.asObservable();
    this.isManager$ = this.isManagerSubject.asObservable();
    this.router.events.subscribe((e: RouterEvent) => {
      this.subscriptions.forEach(s => s.unsubscribe());
    });
    this.myMembers$ = this.store.select('myMembers');
    this.studyActivities$ = this.userStore.studyActivities$;
    this.isLoading$ = this.isLoadingSubject.asObservable();
  }

  public get helper() {
    return this.helperService;
  }

  public get helperActivity() {
    return this.helperActivityService;
  }

  ngOnInit(): void {
    // Get loader
    this.isLoadingSubject.next(true);

    // Sharing parent values: id and study name
    this.subscriptions.push(
      this.actRoute.parent.params.subscribe((params: any) => {
        if (params.id === null) {
          this.isLoadingSubject.next(true);
        } else {
          this.studyId = parseInt(params.id, 10);
          this.store.dispatch({
            type: UserActionTypes.getMyMembersType,
            payload: {}
          });
        }
      })
    );
    this.myMembers$
      .pipe(
        switchMap(members => {
          this.members = members;
          if (this.studyId !== null) {
            this.userStore.getActivitiesStudy({ studyId: this.studyId, include: 'questionnaires' });
            return this.studyActivities$;
          } else {
            return of([]);
          }
        })
      )
      .subscribe(
        (result: any) => {
          this.activities = result
            .filter((value: ActivityInterface) => value.attributes.study_id?.toString() === this.studyId.toString())
            .filter((value: ActivityInterface) =>
              value.attributes.user !== null
                ? this.members.find(user => user.id.toString() === value.attributes.user.toString()) !== undefined
                : false
            );
          // Add lesson
          this.lessons = this.activities
            .filter((activity: ActivityInterface) => activity.attributes.action === 'ANSWERSHEET_SUBMITTED')
            .map((activity: ActivityInterface) => activity.relationships.questionnaires.data[0]);

          this.activities = this.helper.getActivityByNewest(this.activities);
          this.activitiesSubject.next(this.activities);
          this.dataSource = this.activitiesSubject.value;
          this.dataSource.sort = this.sort;
          const currentPageSize = this.paginator ? this.paginator.pageSize : 20;
          this.dataSource = this.activitiesSubject.value.slice(0, currentPageSize);
          if (this.paginator) {
            this.paginator.firstPage();
          }
          this.isLoadingSubject.next(false);
        },
        error => {
          throwError(error);
        }
      );
  }

  public onPageChangeActivity(event: PageEvent): void {
    const startIndex = event.pageIndex * event.pageSize;
    const endIndex =
      startIndex + event.pageSize > this.activitiesSubject.value.length ? this.activitiesSubject.value.length : startIndex + event.pageSize;
    if (this.sort.direction !== '') {
      this.sortData(this.sort, this.activitiesSubject.value.slice());
    }
    this.dataSource = this.activitiesSubject.value.slice(startIndex, endIndex);
    this.dataSource.sort = this.sort;
  }

  public onChangeSortTable(sort: Sort): void {
    const data: Array<ActivityInterface> = this.activitiesSubject.value.slice();
    const isDefault = sort.direction === '';
    const currentPageSize = this.paginator ? this.paginator.pageSize : 20;
    if (isDefault) {
      this.dataSource = data.slice(0, currentPageSize);
    } else {
      this.sortData(sort, data);
      this.dataSource = this.activitiesSubject.value.slice(0, currentPageSize);
    }
    this.paginator.firstPage();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private sortData(sort: Sort, data: Array<ActivityInterface>): void {
    const isDesc = sort.direction === 'desc';
    switch (sort.active) {
      case 'activity_text':
        this.activitiesSubject.next(
          data.sort((a, b) => {
            const behavior = new BehaviorSubject<number>(null);
            forkJoin([this.helperActivity.getActivityText(a), this.helperActivity.getActivityText(b)]).subscribe((activities: string[]) => {
              const act1 =
                a.attributes.action === 'ANSWERSHEET_SUBMITTED'
                  ? activities[0] + this.helperActivity.getLessonNameOfActivity(a, this.lessons)
                  : activities[0];

              const act2 =
                b.attributes.action === 'ANSWERSHEET_SUBMITTED'
                  ? activities[1] + this.helperActivity.getLessonNameOfActivity(b, this.lessons)
                  : activities[1];

              behavior.next(isDesc ? act2.localeCompare(act1) : act1.localeCompare(act2));
            });
            return behavior.value;
          })
        );
        this.dataSource = this.activitiesSubject.value;
        break;
      case 'performed_by':
        this.helperActivityService.toggleSortPerformedBy(data, isDesc, this.activitiesSubject, this.members, this.tableSort);
        this.dataSource = this.activitiesSubject.value;
        break;
      case 'created_at':
        this.helperActivityService.toggleSortCreated(data, isDesc, this.activitiesSubject, this.tableSort);
        this.dataSource = this.activitiesSubject.value;
        break;
    }
  }
}
