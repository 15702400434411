import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, distinctUntilChanged, Observable, Subscription } from 'rxjs';
import { AnswersheetAnswerInterface } from '../../../../models/interface/answersheet-answer.interface';
import { ConditionalblockInterface } from '../../../../models/interface/condition/conditionalblock.interface';
import { HeadlineInterface } from '../../../../models/interface/elements-question/question-headline/headline.interface';
import { ElementInterface } from '../../../../models/interface/elements/element.interface';
import { QuestionHeadlineInterface } from '../../../../models/interface/elements-question/question-headline/question-headline.interface';
import { Store } from '@ngrx/store';
import { EvaluationService } from '../../../../services/evaluation/evaluation.service';

/**
 * Component:
 * Represents headline element
 */

@Component({
  selector: 'app-question-headline',
  templateUrl: './question-headline.component.html',
  styleUrls: ['./question-headline.component.scss', '../../../../../assets/styles/aas2_app.scss']
})
export class QuestionHeadlineComponent implements OnInit, OnDestroy {
  public headline = '';
  public color = '#000000';

  public allElements = [];
  public element: QuestionHeadlineInterface | ElementInterface;
  public answers: Array<AnswersheetAnswerInterface> = [];
  public showHeadline: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public condition: Array<ConditionalblockInterface> = [];

  public evaluation$: Observable<boolean>;
  public hidden$: Observable<boolean>;

  public pageNumber: number;
  public currentPage: number;

  private content: HeadlineInterface;
  private dynamicAnswers: Array<AnswersheetAnswerInterface> = [];
  private dynamicAnswers$: Observable<Array<AnswersheetAnswerInterface>>;

  private lessonLocale$: Observable<any>;

  private expressionSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private evaluationSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private hiddenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private subscriptions: Subscription[] = [];

  constructor(
    private translateService: TranslateService,
    private evaluationService: EvaluationService,
    private store: Store<{
      dynamicAnswers: Array<AnswersheetAnswerInterface>;
      lessonLocale: any;
    }>
  ) {
    this.dynamicAnswers$ = store.select('dynamicAnswers');
    this.lessonLocale$ = store.select('lessonLocale');
    this.evaluation$ = this.evaluationSubject.asObservable();
    this.hidden$ = this.hiddenSubject.asObservable();
  }

  @Input()
  set _element(_element: QuestionHeadlineInterface | ElementInterface) {
    this.element = _element;
    this.color = this.element?.color ? this.element.color : '#000000';
  }

  @Input()
  set _condition(_condition: Array<ConditionalblockInterface>) {
    this.condition = _condition;
  }

  @Input()
  set _page(_page) {
    if (_page !== undefined) {
      this.pageNumber = _page;
      if (this.currentPage !== undefined) {
        this.hiddenSubject.next(this.currentPage.toString() !== this.pageNumber.toString());
      }
    }
  }

  @Input()
  set _currentPage(_currentPage) {
    if (_currentPage !== undefined) {
      this.currentPage = _currentPage;
      if (this.pageNumber !== undefined) {
        this.hiddenSubject.next(this.currentPage.toString() !== this.pageNumber.toString());
      }
    }
  }

  @Input()
  set _allElements(_allElements) {
    if (_allElements !== undefined) {
      this.allElements = _allElements;
    }
  }

  @Input()
  set _answers(_answers: Array<AnswersheetAnswerInterface>) {
    if (_answers !== undefined) {
      this.answers = _answers;
    }
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.dynamicAnswers$.pipe(distinctUntilChanged()).subscribe(dynamicAnswers => {
        this.dynamicAnswers = dynamicAnswers;
        this.showHeadline.next(
          this.evaluationService.replaceReference(
            this.headline,
            this.allElements,
            localStorage.getItem('lessonLocale'),
            this.answers,
            this.dynamicAnswers
          )
        );
        this.evaluationService.evaluateExpression(
          this.expressionSubject,
          this.evaluationSubject,
          this.condition,
          this.element.position,
          this.answers,
          this.dynamicAnswers
        );
      })
    );

    // Assign translation
    this.content = this.findTranslation();
    this.headline = this.content.headline;
    this.showHeadline.next(this.headline);
    this.subscriptions.push(
      this.translateService.onLangChange.subscribe(
        (event: LangChangeEvent) => {
          this.content = this.findTranslation();
          this.headline = this.content.headline;
          this.showHeadline.next(
            this.evaluationService.replaceReference(
              this.headline,
              this.allElements,
              localStorage.getItem('lessonLocale'),
              this.answers,
              this.dynamicAnswers
            )
          );
        },
        error => {
          console.error(error);
        }
      )
    );
    this.subscriptions.push(
      this.lessonLocale$.subscribe(() => {
        this.content = this.findTranslation();
        this.headline = this.content.headline;
        this.showHeadline.next(
          this.evaluationService.replaceReference(
            this.headline,
            this.allElements,
            localStorage.getItem('lessonLocale'),
            this.answers,
            this.dynamicAnswers
          )
        );
      })
    );
    this.showHeadline.next(
      this.evaluationService.replaceReference(
        this.headline,
        this.allElements,
        localStorage.getItem('lessonLocale'),
        this.answers,
        this.dynamicAnswers
      )
    );
    this.evaluationService.evaluateExpression(
      this.expressionSubject,
      this.evaluationSubject,
      this.condition,
      this.element.position,
      this.answers,
      this.dynamicAnswers
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  private findTranslation() {
    const _content = this.element.translations.find(translation => translation.locale === localStorage.getItem('lessonLocale'));
    return _content ? _content : this.element.translations[0];
  }
}
