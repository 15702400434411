<button [attr.id]="'btnDialogClose'" mat-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title>{{'patient.delete_my_unverified_patient_modal_title' | translate}}</h1>
<div mat-dialog-content class="mat-typography">
  {{'patient.delete_my_unverified_patient_modal_body' | translate}}
  <p>{{patient.attributes.email}}</p>
</div>
<div mat-dialog-actions align="end">
  <button [attr.id]="'btnCancel'" mat-button mat-dialog-close>{{'patient.cancel' | translate}}</button>
  <app-button-feedback
    [_setId]="'btnRemoveUnverifiedAccount'"
    (click)="removeUnverifiedAccount()"
    [_defaultButtonText]="'patient.delete_account'"
    [_responseText]="removeUnverifiedAccountResponse | async"
  ></app-button-feedback>
</div>
