/* eslint-disable @typescript-eslint/naming-convention */
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, RouterEvent } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription, of, throwError, iif } from 'rxjs';
import { filter, mergeMap, takeLast, catchError, take, takeWhile, skip } from 'rxjs/operators';
import { OrganisationInterface } from '../../../models/interface/organisation/organisation.interface';
import { ProfileInterface } from '../../../models/interface/profile.interface';
import { RoleInterface } from '../../../models/interface/role.interface';
import { StudyMediaInterface } from '../../../models/interface/study/study-media.interface';
import { UserInterface } from '../../../models/interface/user.interface';
import { AlertService } from '../../../services/alert/alert.service';
import { HelperService } from '../../../services/helper/helper.service';
import { OrganisationActionTypes } from '../../../store/organisation/organisation.action';
import { UserActionTypes } from '../../../store/user/user.action';
import { StudyStore } from '../../../store/study/component-store/study.store';

@Component({
  selector: 'app-dialog-admin-study-create',
  templateUrl: './dialog-admin-study-create.component.html',
  styleUrls: ['./dialog-admin-study-create.component.scss'],
  providers: [StudyStore]
})
export class DialogAdminStudyCreateComponent implements OnInit, OnDestroy {
  @Output() newGroupEvent = new EventEmitter<string>();

  @ViewChild('fileInputPicture') fileInputPicture: ElementRef;

  // Form submission
  public groupFormType: UntypedFormGroup;
  public accessType = ['PASSWORD', 'INVITATION'];

  // Image upload
  public language: string;
  public url = '';
  public fileData: File = null;
  public previewUrl: any = null;
  public fileUploadProgress: number = null;
  public uploadedFilePath: string = null;
  public files: Array<any> = null;

  public organisations: Array<OrganisationInterface> = [];
  public selectedOrganisation: OrganisationInterface;

  // Filter selection
  public filter = {
    userSelection: '',
    organisationSelection: ''
  };

  public organisations$: Observable<Array<OrganisationInterface>>;

  public createStudyResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');

  public allECoachesAsAdmin$: Observable<Array<UserInterface>>;

  public selectedIsPrivate$: Observable<string>;
  public selectedAccesstype$: Observable<string>;
  public isECoach$: Observable<boolean>;
  public globalRole$: Observable<string>;

  public profile$: Observable<UserInterface>;
  public profileRoles$: Observable<Array<RoleInterface>>;

  public organisationStore$: Observable<Array<OrganisationInterface>>;

  // Data from UserService
  public allECoachesAsAdmin: Array<UserInterface> = [];

  private submittedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isUploadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private profile: ProfileInterface;

  private locationUrl;

  private organisationsSubject: BehaviorSubject<Array<OrganisationInterface>> = new BehaviorSubject<Array<OrganisationInterface>>([]);
  private allECoachesAsAdminSubject: BehaviorSubject<Array<UserInterface>> = new BehaviorSubject<Array<UserInterface>>([]);
  private selectedIsPrivateSubject = new BehaviorSubject<string>('0');
  private selectedAccesstypeSubject = new BehaviorSubject<string>('PASSWORD');
  private globalRoleSubject: BehaviorSubject<string> = new BehaviorSubject<string>('ecoach');
  private isECoachSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private users$: Observable<Array<UserInterface>>;

  private createStudyResponse$: Observable<any>;
  private updateStudyResponse$: Observable<any>;
  private uploadStudyMediaResponse$: Observable<any>;

  private subscriptions: Subscription[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private helperService: HelperService,
    private router: Router,
    private alertService: AlertService,
    private dialog: MatDialog,
    private studyStore: StudyStore,
    private store: Store<{
      myProfile: ProfileInterface;
      myRoles: Array<RoleInterface>;
      organisations: Array<OrganisationInterface>;
      users: Array<UserInterface>;
    }>
  ) {
    router.events.subscribe((e: RouterEvent) => {
      this.dialog.closeAll();
    });
    this.profile$ = this.store.select('myProfile');
    this.profileRoles$ = this.store.select('myRoles');
    this.organisationStore$ = this.store.select('organisations');
    this.users$ = this.store.select('users');

    this.organisations$ = this.organisationsSubject.asObservable();
    this.allECoachesAsAdmin$ = this.allECoachesAsAdminSubject.asObservable();
    this.isECoach$ = this.isECoachSubject.asObservable();
    this.globalRole$ = this.globalRoleSubject.asObservable();

    // Form type contains searchFilter options
    this.groupFormType = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(255), Validators.minLength(1)]],
      is_private: ['0', Validators.required],
      accesstype: ['', Validators.required],
      password: ['', Validators.maxLength(255)],
      subscribable: ['2'],
      starts_at: [''],
      ends_at: [''],
      deTitle: ['', [Validators.required, Validators.maxLength(255)]],
      enTitle: ['', [Validators.required, Validators.maxLength(255)]],
      deDescription: ['', [Validators.required, Validators.maxLength(255)]],
      enDescription: ['', [Validators.required, Validators.maxLength(255)]],
      owner_id: [-1]
    });
    this.groupFormType.controls['accesstype'].setValue(this.accessType[0], { onlySelf: true });
    this.groupFormType.controls['owner_id'].setValue(-1, { onlySelf: true });

    this.selectedIsPrivate$ = this.selectedIsPrivateSubject.asObservable();
    this.selectedAccesstype$ = this.selectedAccesstypeSubject.asObservable();
    this.createStudyResponse$ = this.studyStore.createStudyResponse$;
    this.updateStudyResponse$ = this.studyStore.updateStudyResponse$;
    this.uploadStudyMediaResponse$ = this.studyStore.uploadStudyMediaResponse$;
  }

  get f() {
    return this.groupFormType.controls;
  }

  public get helper() {
    return this.helperService;
  }

  ngOnInit(): void {
    this.store.dispatch({ type: OrganisationActionTypes.getOrganisationsAdminType, payload: { include: 'owners,collaborators' } });
    this.subscriptions.push(
      this.organisationStore$.pipe(filter(organisations => organisations.length > 0)).subscribe((result: Array<OrganisationInterface>) => {
        this.organisations = result;
        this.organisationsSubject.next(this.organisations);
      })
    );

    this.subscriptions.push(
      this.profile$
        .pipe(
          filter(user => user !== null),
          mergeMap((result: ProfileInterface) => {
            this.profile = result;
            return this.profileRoles$.pipe(
              filter(user => user !== null),
              take(1)
            );
          }),
          mergeMap((roles: Array<RoleInterface>) => {
            // Check whether the user is eCoach or admin
            if (this.helper.hasRoles(roles, /admin$/)) {
              this.globalRoleSubject.next('admin');
            }
            if (this.helper.hasRoles(roles, /(ecoach|ecoachmanager)$/)) {
              this.isECoachSubject.next(true);
            }

            this.store.dispatch({ type: UserActionTypes.getAllUsersAdminType, payload: {} });
            return this.users$.pipe(skip(1), take(1));
          })
        )
        .subscribe((result: any) => {
          // Get all users in role eCoach as admin
          this.allECoachesAsAdmin = result;
          this.allECoachesAsAdminSubject.next(this.allECoachesAsAdmin);
          this.allECoachesAsAdmin = this.allECoachesAsAdmin.filter((user: UserInterface) =>
            user.attributes.roles.find((role: { slug: string; description: string }) => role.slug.toString() === 'ecoach')
          );
          if (this.allECoachesAsAdmin.length > 0) {
            this.groupFormType.controls['owner_id'].setValue(this.allECoachesAsAdmin[0].id, { onlySelf: true });
          }
        })
    );

    this.groupFormType.get('is_private').valueChanges.subscribe(val => {
      if (this.groupFormType.get('accesstype').value === 'PASSWORD' && val.toString() === '1') {
        this.groupFormType.controls['password'].setValidators([Validators.required, Validators.minLength(1), Validators.maxLength(255)]);
      } else {
        this.groupFormType.controls['password'].clearValidators();
      }
      this.groupFormType.controls['password'].updateValueAndValidity();
    });

    this.groupFormType.get('accesstype').valueChanges.subscribe(val => {
      if (val === 'PASSWORD') {
        this.groupFormType.controls['password'].setValidators([Validators.required, Validators.minLength(1), Validators.maxLength(255)]);
      } else {
        this.groupFormType.controls['password'].clearValidators();
      }
      this.groupFormType.controls['password'].updateValueAndValidity();
    });
  }

  // Group creation studyForm check is_private
  public showIsPrivate(event): void {
    this.selectedIsPrivateSubject.next(event.value.toString());
  }

  // Group creation studyForm check access type
  public showAccesstype(value: string): void {
    this.selectedAccesstypeSubject.next(value);
  }

  // Submit parameters required to create a group
  public createGroup(): void {
    let newStudyId = null;
    if (
      !this.isNameOrPasswordInvalid() &&
      (!this.isGroupFormInvalid() || !this.isOwnerAndECoach()) &&
      this.createStudyResponse.value === 'DEFAULT'
    ) {
      this.createStudyResponse.next('LOADING');

      const translations = [];

      const translationGerman = {
        locale: 'de',
        title: this.f.deTitle.value,
        description: this.f.deDescription.value
      };

      const translationEnglish = {
        locale: 'en',
        title: this.f.enTitle.value,
        description: this.f.enDescription.value
      };

      translations.push(translationGerman, translationEnglish);

      const formIsPrivate = isPrivate =>
        ({
          1: true,
          0: false
        }[isPrivate]);
      const isPrivateParam = formIsPrivate(this.f.is_private.value);

      const formAccesstype = accessType =>
        ({
          PASSWORD: 'password',
          INVITATION: 'invite'
        }[accessType]);
      const accesstypeParam = formAccesstype(this.f.accesstype.value);

      // User is eCoach and owner
      if (this.globalRoleSubject.value === 'ecoach' || this.isOwnerAndECoach()) {
        const selectedOrganisationId = this.selectedOrganisation ? parseInt(this.selectedOrganisation.id.toString(), 10) : undefined;
        this.studyStore.createStudy({
          name: this.f.name.value,
          type: 'study',
          ownerId: parseInt(this.f.owner_id.value.toString(), 10),
          organisationId: selectedOrganisationId
        });
        this.subscriptions.push(
          this.createStudyResponse$
            .pipe(
              skip(1),
              take(1),
              mergeMap((result: any) => {
                if (result instanceof HttpResponse) {
                  // Get location_url
                  const locationUrl = result['headers'].get('Location');
                  newStudyId = locationUrl.substring(locationUrl.lastIndexOf('/') + 1);
                  return of(newStudyId);
                } else {
                  return throwError(result);
                }
              }),
              mergeMap((studyId: string) => {
                const studyParams = {
                  translations,
                  isPrivateParam,
                  accesstypeParam: isPrivateParam ? accesstypeParam : undefined,
                  password: isPrivateParam && accesstypeParam === 'password' ? this.f.password.value : undefined
                };
                if (this.fileInputPicture.nativeElement.files.length > 0) {
                  this.studyStore.uploadStudyMedia({ studyId: parseInt(studyId, 10), fileToUpload: this.files[0] });
                  return this.uploadStudyMediaResponse$.pipe(
                    skip(1),
                    takeWhile(result => result.type === HttpEventType.UploadProgress, true),
                    mergeMap((result: any) => {
                      this.fileUploadProgress = Math.round((result['response']['loaded'] / result['response']['total']) * 100);
                      return of(result);
                    }),
                    takeLast(1),
                    mergeMap((result: any) => {
                      if (result instanceof HttpResponse) {
                        this.fileUploadProgress = 0;
                        this.isUploadingSubject.next(false);
                        return of(result);
                      } else {
                        return throwError(result);
                      }
                    }),
                    mergeMap(result => {
                      const studyMediaList: Array<StudyMediaInterface> = result.body.data;
                      this.studyStore.updateStudy({
                        studyId: parseInt(studyId, 10),
                        translationContent: studyParams.translations,
                        accesstypeParam: studyParams.accesstypeParam,
                        password: studyParams.password,
                        isPrivate: studyParams.isPrivateParam,
                        picture: `uploads/studies/${parseInt(studyId, 10)}/${studyMediaList[0].attributes.url}`
                      });
                      return this.updateStudyResponse$.pipe(
                        skip(1),
                        take(1),
                        mergeMap((res: any) => iif(() => res instanceof HttpResponse, of(res), throwError(res)))
                      );
                    })
                  );
                } else {
                  this.studyStore.updateStudy({
                    studyId: parseInt(studyId, 10),
                    translationContent: studyParams.translations,
                    accesstypeParam: studyParams.accesstypeParam,
                    password: studyParams.password,
                    isPrivate: studyParams.isPrivateParam
                  });
                  return this.updateStudyResponse$.pipe(
                    skip(1),
                    take(1),
                    mergeMap((res: any) => iif(() => res instanceof HttpResponse, of(res), throwError(res)))
                  );
                }
              }),
              catchError(error => throwError(error))
            )
            .subscribe(
              () => {
                if (this.globalRoleSubject.value === 'admin') {
                  this.store.dispatch({
                    type: OrganisationActionTypes.getOrganisationsAdminType,
                    payload: { include: 'owners,collaborators' }
                  });
                }
                this.subscriptions.push(
                  this.organisationStore$
                    .pipe(filter(organisations => organisations.length > 0))
                    .subscribe((result: Array<OrganisationInterface>) => {
                      this.organisations = result;
                      this.organisationsSubject.next(this.organisations);
                    })
                );

                this.alertService.success('group-creation.form_create_succeeded_redirection');
                this.submittedSubject.next(false);
                this.createStudyResponse.next('SUCCESS');
                setTimeout(() => {
                  this.createStudyResponse.next('DEFAULT');
                  this.dialog.closeAll();
                }, 2500);
              },
              () => {
                this.alertService.error('group-creation.form_create_failed');
                this.submittedSubject.next(false);
                this.createStudyResponse.next('FAILURE');
                setTimeout(() => {
                  this.createStudyResponse.next('DEFAULT');
                }, 2500);
              }
            )
        );
      } else {
        // If user create a group for another user
        if (this.f.owner_id.value.toString() !== '-1') {
          this.createStudyResponse.next('LOADING');
          const selectedOrganisationId = this.selectedOrganisation ? parseInt(this.selectedOrganisation.id.toString(), 10) : undefined;
          this.studyStore.createStudyAdmin({
            name: this.f.name.value,
            type: 'study',
            ownerId: parseInt(this.f.owner_id.value.toString(), 10),
            organisationId: selectedOrganisationId
          });

          this.subscriptions.push(
            this.createStudyResponse$.pipe(skip(1), take(1)).subscribe((result: any) => {
              if (result instanceof HttpResponse) {
                this.showGroupCreationSuccess(this.locationUrl);
              } else {
                this.showGroupCreationError();
              }
            })
          );
        }
      }
    }
  }

  public isOwnerAndECoach(): boolean {
    if (this.f.owner_id.value.toString() !== '-1') {
      return (
        this.f.owner_id.value.toString() === this.profile.id.toString() &&
        this.isECoachSubject.value &&
        this.globalRoleSubject.value === 'admin'
      );
    }
    return false;
  }

  public processFile(fileInput: any): void {
    this.fileData = fileInput.target.files[0] as File;
    this.uploadedFilePath = this.fileData.name;
    this.files = fileInput.target.files;
    this.displayImagePreview();
  }

  public displayImagePreview(): string {
    if (this.fileData) {
      const mimeType = this.fileData.type;
      if (mimeType.match(/image\/*/) == null) {
        return 'assets/pictures/default_no_image_picture.svg';
      }
      const reader = new FileReader();
      reader.readAsDataURL(this.fileData);
      reader.onload = _event => {
        this.previewUrl = reader.result;
      };
      return this.previewUrl;
    } else {
      return 'assets/pictures/default_no_image_picture.svg';
    }
  }

  public clearImage(): void {
    this.fileData = null;
    this.previewUrl = null;
    this.fileUploadProgress = null;
    this.uploadedFilePath = null;
    this.files = null;
    this.fileInputPicture.nativeElement.value = '';
  }

  public onKeyFilter(): void {
    const filterResults: Array<UserInterface> = this.allECoachesAsAdmin.filter(
      (user: UserInterface) =>
        user.attributes.name?.toLowerCase().includes(this.filter['userSelection'].toLowerCase()) ||
        user.attributes.email.toLowerCase().includes(this.filter['userSelection'].toLowerCase())
    );
    if (filterResults.length !== 0) {
      this.allECoachesAsAdminSubject.next(filterResults);
    } else {
      this.allECoachesAsAdminSubject.next([
        this.helper.findArrObjById(parseInt(this.f.owner_id.value.toString(), 10), this.allECoachesAsAdmin)
      ]);
    }
  }

  public onKeyFilterOrganisation(): void {
    const filterResults: Array<OrganisationInterface> = this.organisations.filter((organisation: OrganisationInterface) =>
      organisation.attributes?.name.toLowerCase().includes(this.filter['organisationSelection'].toLowerCase())
    );
    this.organisationsSubject.next(filterResults.length !== 0 ? filterResults : [this.selectedOrganisation]);
  }

  public resetFilter() {
    this.filter = {
      userSelection: '',
      organisationSelection: ''
    };
    this.allECoachesAsAdminSubject.next(this.allECoachesAsAdmin);
    this.organisationsSubject.next(this.organisations);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  private isGroupFormInvalid(): boolean {
    const translationInvalid =
      this.f.deTitle.invalid || this.f.deDescription.invalid || this.f.enTitle.invalid || this.f.enDescription.invalid;
    if (!this.f.name.invalid) {
      if (this.f.is_private.value === '0') {
        return translationInvalid;
      } else {
        if (this.f.accesstype.value === 'PASSWORD') {
          return !this.f.password.invalid ? translationInvalid : true;
        } else {
          return translationInvalid;
        }
      }
    }
    return true;
  }

  private isNameOrPasswordInvalid(): boolean {
    return this.f.name.invalid || this.f.password.invalid;
  }

  private showGroupCreationSuccess(locationUrl: string, message?: string) {
    this.alertService.success('group-creation.form_create_succeeded');
    this.submittedSubject.next(false);
    this.newGroupEvent.emit(locationUrl);
    this.createStudyResponse.next('SUCCESS');
    setTimeout(() => {
      this.createStudyResponse.next('DEFAULT');
      this.dialog.closeAll();
    }, 2500);
  }

  private showGroupCreationError(message?: string) {
    this.alertService.error('group-creation.form_create_failed');
    this.submittedSubject.next(false);
    this.createStudyResponse.next('FAILURE');
    setTimeout(() => {
      this.createStudyResponse.next('DEFAULT');
    }, 2500);
  }
}
