import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-dialog-group-creation',
  templateUrl: './dialog-group-creation.component.html',
  styleUrls: ['./dialog-group-creation.component.scss']
})
export class DialogGroupCreationComponent implements OnInit {
  public submittedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<DialogGroupCreationComponent>) {}

  ngOnInit(): void {
    this.submittedSubject.next(this.data.submitted);
  }
}
