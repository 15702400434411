/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { PayloadInterface } from '../../../models/interface/payload.interface';
import { RequestBodyData } from '../../../models/request-body.data';
import { download } from '../../../helpers/download/download';
import { HelperService } from '../../helper/helper.service';

/**
 * Service:
 * Intervention API service that handles intervention-related requests
 */

@Injectable({
  providedIn: 'root'
})
export class InterventionService {
  public header: HttpHeaders = new HttpHeaders();
  private backendUrl: string = environment.backendURL;

  constructor(private http: HttpClient, private helperService: HelperService) {
    this.header = this.header.set('Content-Type', 'application/json');
    this.header = this.helperService.setLocaleFromStorage(this.header);
  }

  /**
   * AAS2 API User - All Interventions
   * This function retrieves all interventions.
   *
   * @params string type  - A string determining the type of the study typically 'study'.
   *         string intervention_type - A string that determines whether the study is guided or unguided.
   *           - typically 'accompanied' or 'unaccompanied'
   *         number is_shareable - A number determines if study is shareable.
   *         number is_subscribable - A number determines if study is subscribable.
   *         number is_private - A number determines if study is private.
   *         number is_active - A number determines if study is active.
   *         number study_id - A number of the study id.
   *         string include - A string including additional information.
   *           - typically 'collaborators' or 'members'
   * @return Observable<any> - An observable for any response.
   */
  public getAllInterventions(
    type?: string,
    interventionType?: string,
    isShareable?: number,
    isSubscribable?: number,
    isPrivate?: number,
    isActive?: number,
    studyId?: number,
    include?: string
  ): Observable<any> {
    this.header = this.helperService.setLocaleFromStorage(this.header);
    const params = this.setHttpParams(type, interventionType, isShareable, isSubscribable, isPrivate, isActive, studyId, include);
    return this.http.get<any>(`${this.backendUrl}/api/v1/interventions?limit=0`, { headers: this.header, observe: 'response', params });
  }

  /**
   * AAS2 API User - Get An Intervention
   * This function returns an interventions.
   *
   * @params number interventionId - ID of the intervention.
   * @return Observable<any> - An observable for any response.
   */
  public getIntervention(interventionId: number): Observable<any> {
    this.header = this.helperService.setLocaleFromStorage(this.header);
    return this.http.get<any>(`${this.backendUrl}/api/v1/interventions/${interventionId}`, { headers: this.header, observe: 'response' });
  }

  /**
   * AAS2 API User - All Interventions of a Specific Study
   * This function returns all interventions of a specific study.
   *
   * @params number studyId - ID of the study.
   *         string type - A string determining the type of the study typically 'study'.
   *         string intervention_type - A string that determines whether the study is guided or unguided.
   *           - typically 'accompanied' or 'unaccompanied'
   *         number is_shareable - A number determines if study is shareable.
   *         number is_subscribable - A number determines if study is subscribable.
   *         number is_private - A number determines if study is private.
   *         number is_active - A number determines if study is active.
   *         string include - A string including additional information.
   *           - typically 'collaborators' or 'members'
   * @return Observable<any> - An observable for any response.
   */
  public getAllInterventionsOfSpecificStudy(
    studyId: number,
    type?: string,
    interventionType?: string,
    isShareable?: number,
    isSubscribable?: number,
    isPrivate?: number,
    isActive?: number,
    include?: string
  ): Observable<any> {
    this.header = this.helperService.setLocaleFromStorage(this.header);
    const params = this.setHttpParams(type, interventionType, isShareable, isSubscribable, isPrivate, isActive, undefined, include);
    return this.http.get<any>(`${this.backendUrl}/api/v1/studies/${studyId}/interventions?limit=0`, {
      headers: this.header,
      observe: 'response',
      params
    });
  }

  /**
   * AAS2 API User - Get all Interventions of the logged in user
   * This function returns all interventions of the logged in user.
   *
   * @params string type - A string determining the type of the study typically 'study'.
   *         string intervention_type - A string that determines whether the study is guided or unguided.
   *           - typically 'accompanied' or 'unaccompanied'
   *         number is_shareable - A number determines if study is shareable.
   *         number is_subscribable - A number determines if study is subscribable.
   *         number is_private - A number determines if study is private.
   *         number is_active - A number determines if study is active.
   *         number study_id - ID of the study.
   *         string include - A string including additional information.
   *           - typically 'collaborators' or 'members'
   * @return Observable<any> - An observable for any response.
   */
  public getAllInterventionsOfUser(
    type?: string,
    interventionType?: string,
    isShareable?: number,
    isSubscribable?: number,
    isPrivate?: number,
    isActive?: number,
    studyId?: number,
    include?: string
  ): Observable<any> {
    this.header = this.helperService.setLocaleFromStorage(this.header);
    const params = this.setHttpParams(type, interventionType, isShareable, isSubscribable, isPrivate, isActive, undefined, include);
    return this.http.get<any>(`${this.backendUrl}/api/v1/my/interventions?limit=0`, { headers: this.header, observe: 'response', params });
  }

  /**
   * AAS2 API ECoach - Copy an Intervention for Ecoach
   * This function copies an intervention from a study to a study as eCoach - automatically activates and sets to running.
   * User of target study has to be study.owner or study.ecoachmanager
   *
   * @params interventionId number - ID of the intervention.
   *         study_id number -ID of the study.
   * @return Observable<any> - An observable for any response.
   */
  public copyInterventionToStudyAsECoach(interventionId: number, studyId: number) {
    const reqBody: PayloadInterface = new RequestBodyData('interventions', {
      study_id: studyId
    });
    return this.http.post<any>(`${this.backendUrl}/api/v1/ecoach/interventions/${interventionId}/copy`, reqBody, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API ECoach - Interventions of collaborating studies
   * This function returns all interventions of the user's collaborating studies. [Works only for editor]
   *
   * @params string type - A string determining the type of the study typically 'study'.
   *         string intervention_type - A string that determines whether the study is guided or unguided.
   *           - typically 'accompanied' or 'unaccompanied'
   *         number is_shareable - A number determines if study is shareable.
   *         number is_subscribable - A number determines if study is subscribable.
   *         number is_private - A number determines if study is private.
   *         number is_active - A number determines if study is active.
   *         number study_id - ID of the study.
   *         string include - A string including additional information.
   *           - typically 'collaborators', 'owners', 'members' or 'roles'
   *         string typeOfParentStudy - Legacy value to filter interventions that belongs a specific type of the parent study
   *           - typically 'study'
   *         string role - Value to filter results by user's role in the parent study.
   * @return Observable<any> - An observable for any response.
   */
  public getInterventionsOfCollabStudy(
    type?: string,
    interventionType?: string,
    isShareable?: number,
    isSubscribable?: number,
    isPrivate?: number,
    isActive?: number,
    studyId?: number,
    include?: string,
    typeOfParentStudy?: string,
    role?: string
  ): Observable<any> {
    this.header = this.helperService.setLocaleFromStorage(this.header);
    const params = this.setHttpParams(
      type,
      interventionType,
      isShareable,
      isSubscribable,
      isPrivate,
      null,
      studyId,
      include,
      typeOfParentStudy,
      role
    );
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoach/interventions?limit=0`, {
      headers: this.header,
      observe: 'response',
      params
    });
  }

  /**
   * AAS2 API ECoach - Interventions of noncollaborating studies
   * This function returns all interventions of non-collaborating studies.
   *
   * @params string type - A string determining the type of the study typically 'study'.
   *         string intervention_type - A string that determines whether the study is guided or unguided.
   *           - typically 'accompanied' or 'unaccompanied'
   *         number is_shareable - A number determines if study is shareable.
   *         number is_subscribable - A number determines if study is subscribable.
   *         number is_private - A number determines if study is private.
   *         number is_active - A number determines if study is active.
   *         number study_id - ID of the study.
   *         string include - A string including additional information.
   *           - typically 'collaborators' or 'owners'
   *         string typeOfParentStudy - Legacy value to filter interventions that belongs a specific type of the parent study
   *           - typically 'study'
   * @return Observable<any> - An observable for any response.
   */
  public getInterventionsOfNonCollabStudy(
    type?: string,
    interventionType?: string,
    isShareable?: number,
    isSubscribable?: number,
    isPrivate?: number,
    isActive?: number,
    studyId?: number,
    include?: string,
    typeOfParentStudy?: string
  ): Observable<any> {
    this.header = this.helperService.setLocaleFromStorage(this.header);
    const params = this.setHttpParams(
      type,
      interventionType,
      isShareable,
      isSubscribable,
      isPrivate,
      isActive,
      studyId,
      include,
      typeOfParentStudy
    );
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoach/interventions/noncollaborating?limit=0`, {
      headers: this.header,
      observe: 'response',
      params
    });
  }

  /**
   * AAS2 API ECoach - (Un)Set Intervention as running
   * This function updates the running attribute in the intervention (only performed by eCoach manager).
   * It prevents patients in intervention from submitting answer sheets.
   *
   * @params number intervention_id - ID of the intervention.
   *         string is_running - A number to set is_running true or false.
   * @return Observable<any> - An observable for any response.
   */
  public updateIsRunningInIntervention(interventionId: number, isRunning: number): Observable<any> {
    const running: any = { running: isRunning };
    return this.http.post<any>(
      `${this.backendUrl}/api/v1/ecoach/interventions/${interventionId}/running`,
      new RequestBodyData('users', running),
      { headers: this.header, observe: 'response' }
    );
  }

  /**
   * AAS2 API ECoach - (Un)Set Intervention as active to publish/reject intervention
   * This function updates the is_active attribute in the intervention as well as the publishing_state attribute
   * (only performed by study.publisher).
   * It enables the intervention and allows eCoaches and others to assign interventions.
   *
   * @params number intervention_id - ID of the intervention.
   *         boolean is_active - A boolean to set is_running true or false.
   * @return Observable<any> - An observable for any response.
   */
  public setActiveInterventionAsPublisher(interventionId: number, isActive: boolean, deactivationText?: string): Observable<any> {
    const activate: any = { activate: isActive, deactivation_text: deactivationText };
    if (deactivationText === undefined) {
      delete activate.deactivation_text;
    }
    return this.http.patch<any>(
      `${this.backendUrl}/api/v1/ecoach/interventions/${interventionId}/activate`,
      new RequestBodyData('interventions', activate),
      { headers: this.header, observe: 'response' }
    );
  }

  /**
   * AAS2 API ECoach Manager - Confirm Intervention has been reviewed and update intervention
   * This function updates the is_active attribute in the intervention as well as the publishing_state attribute
   * (only performed by study.publisher).
   * It enables the intervention and allows eCoaches and others to assign interventions.
   *
   * @params number intervention_id - ID of the intervention.
   *         boolean is_active - A boolean to set is_running true or false.
   * @return Observable<any> - An observable for any response.
   */
  public confirmInterventionReviewAsECoachManager(interventionId: number): Observable<any> {
    return this.http.patch<any>(
      `${this.backendUrl}/api/v1/ecoach/interventions/${interventionId}/review`,
      {},
      { headers: this.header, observe: 'response' }
    );
  }

  /**
   * AAS2 API ECoach Manager - Delete an Intervention as ECoach Manager
   * This function deletes an intervention if either of the following conditions are fulfilled:
   * a) "is_active=0" and/or "publishing_state=declined"
   * b) "is_test=1", EVEN IF it has been already activated
   * As a result, all intervention instances, diary, diary instance, answer sheets and their threads will be deleted.
   *
   * @params number intervention_id - ID of the intervention.
   * @return Observable<any> - An observable for any response.
   */
  public deleteInterventionAsECoachManager(interventionId: number): Observable<any> {
    return this.http.delete<any>(`${this.backendUrl}/api/v1/ecoachmanager/interventions/${interventionId}`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API User - All pending Interventions
   * This function retrieves all pending interventions.
   *
   * @params string type  - A string determining the type of the study typically 'study'.
   *         string intervention_type - A string that determines whether the study is guided or unguided.
   *           - typically 'accompanied' or 'unaccompanied'
   *         number is_shareable - A number determines if study is shareable.
   *         number is_subscribable - A number determines if study is subscribable.
   *         number is_private - A number determines if study is private.
   *         number is_active - A number determines if study is active.
   *         number study_id - A number of the study id.
   *         string include - A string including additional information.
   *           - typically 'collaborators' or 'members'
   * @return Observable<any> - An observable for any response.
   */
  public getAllPendingReviewingInterventions(
    type?: string,
    interventionType?: string,
    isShareable?: number,
    isSubscribable?: number,
    isPrivate?: number,
    isActive?: number,
    studyId?: number
  ): Observable<any> {
    this.header = this.helperService.setLocaleFromStorage(this.header);
    const params = this.setHttpParams(type, interventionType, isShareable, isSubscribable, isPrivate, 0, studyId);
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoach/interventions/pending?limit=0`, {
      headers: this.header,
      observe: 'response',
      params
    });
  }

  /**
   * AAS2 API eCoach Manager - Export intervention codebook
   * This function returns a codebook of the intervention as csv.
   *
   * @params number interventionId  - An identifier of the intervention
   *         number questionnaireID - A number determines if study is shareable.
   * @return Observable<any> - An observable for any response.
   */
  public exportCSVInterventionCodebook(interventionId: number, questionnaireID?: number): Observable<any> {
    this.header = this.header.set('Content-Type', '*/*');
    this.header = this.header.set('Accept', '*/*');
    this.header = this.helperService.setLocaleFromStorage(this.header);
    let params = new HttpParams();
    if (questionnaireID !== undefined) {
      params = params.set('questionnaireID', questionnaireID);
    }
    let downloaded = false;
    return this.http
      .get<any>(`${this.backendUrl}/api/v1/ecoachmanager/interventions/${interventionId}/codebook`, {
        headers: this.header,
        reportProgress: true,
        observe: 'events',
        params,
        responseType: 'blob' as 'json'
      })
      .pipe(
        download(jsonResponse => {
          const getFileName = (response: HttpResponse<any>) => {
            let fileName: string;
            try {
              const contentDisposition: string = response.headers.get('content-disposition');
              const r = /filename="(.+)"/;
              fileName = r.exec(contentDisposition)[1];
            } catch (error) {
              fileName = `codebook_intervention_id${interventionId}-${new Date().toISOString()}.csv`;
            }
            return fileName;
          };

          const filename = `codebook_intervention_id-${interventionId}-${new Date().toISOString()}.csv`;
          const binaryData = [];
          binaryData.push(jsonResponse);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/csv' }));
          downloadLink.setAttribute('download', filename);
          document.body.appendChild(downloadLink);
          if (!downloaded) {
            downloadLink.click();
            URL.revokeObjectURL(downloadLink.href);
            downloaded = true;
          }
        })
      );
  }

  public setHttpParams(
    type?: string,
    interventionType?: string,
    isShareable?: number,
    isSubscribable?: number,
    isPrivate?: number,
    isActive?: number,
    studyId?: number,
    include?: string,
    typeOfParentStudy?: string,
    role?: string
  ): HttpParams {
    let params = new HttpParams();
    if (type !== undefined) {
      params = params.set('type', type);
    }
    if (interventionType !== undefined) {
      params = params.set('intervention_type', interventionType.toString());
    }
    if (isShareable !== undefined) {
      params = params.set('is_shareable', isShareable.toString());
    }
    if (isSubscribable !== undefined) {
      params = params.set('is_subscribable', isSubscribable.toString());
    }
    if (isPrivate !== undefined) {
      params = params.set('is_private', isPrivate.toString());
    }
    if (isActive !== undefined && isActive !== null) {
      params = params.set('is_active', isActive.toString());
    } else if (isActive === undefined) {
      params = params.set('is_active', '1');
    }
    if (studyId !== undefined) {
      params = params.set('study_Id', studyId.toString());
    }
    if (include !== undefined) {
      params = params.set('include', include);
    }
    if (typeOfParentStudy !== undefined) {
      params = params.set('typeOfParentStudy', typeOfParentStudy);
    }
    if (role !== undefined) {
      params = params.set('role', role);
    }
    return params;
  }
}
