<div class="container-fluid text-center">
  <div class="col-sm-8 text-start">
    <h1>{{ 'patient-overview.patients' | translate }}</h1>
  </div>
  <hr />
  <h4>
    {{'patient-overview.patients_and_create_patients' | translate}}
    <button [attr.id]="'btnToggleDescription'" mat-button aria-label="Information" (click)="helper.toggleSubject(showDescriptionSubject)">
      <fa-icon [icon]="faInfoCircle"></fa-icon>
    </button>
  </h4>
  <div *ngIf="showDescription$ | async" class="row justify-content-center">
    <div class="col-sm-9">
      <p class="text-justify">{{ 'patient-overview.description_patient_overview' | translate }}</p>
    </div>
  </div>

  <app-patient></app-patient>
</div>
