import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import jwt_decode from 'jwt-decode';
import { environment } from '../../../environments/environment';

export const JWT_COOKIE_NAME = 'aas2_token';

/**
 * Service:
 * Custom cookie service that stores JWT token
 */

@Injectable({
  providedIn: 'root'
})
export class CustomCookieService {
  constructor(private cookieService: CookieService) {}

  /**
   * This function returns the authentication check.
   *
   * @return string A JWT value of the cookie
   */
  getJWTCookie(): string {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${JWT_COOKIE_NAME}=`);

    let tokenString = '';
    if (parts.length === 2) {
      tokenString = parts.pop().split(';').shift();
    }
    return tokenString;
    // return this.cookieService.get(JWT_COOKIE_NAME);
  }

  /**
   * This function set a JWT cookie.
   * TODO Current version of Firefox and Google Chrome cause issues in ngx-cookie-service.
   *
   * @params string token - A JWT value
   *         number expires - Optional number/date of the expiration date
   *         string path - Optional cookie path
   *         string domain - Optional cookie domain
   *         boolean secure - Optional cookie secure flag
   *         Lax|None|Strict sameSite - Optional OWASP attribue
   * @return string A JWT value of the cookie
   */
  setJWTCookie(
    token: string,
    expires?: number | Date,
    path?: string,
    domain?: string,
    secure?: boolean,
    sameSite?: 'Lax' | 'None' | 'Strict'
  ): void {
    // TODO: Cookie is not set without explicit LAX samesite attribute (or Secure flag?) issue
    // this.cookieService.set(JWT_COOKIE_NAME, token, undefined, '/', undefined, undefined, 'Lax');

    // Delete cookie
    this.deleteJWTCookie();

    const date = new Date();
    const value = token;
    // Set it expire in 7 days
    date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);

    const expiration = this.getJWTExpiration(token);
    document.cookie = `${JWT_COOKIE_NAME}=${value};path=/`;

    /* Set it
    document.cookie = `${JWT_COOKIE_NAME}=${value};expires=${date.toUTCString()};path=/`;
    const environment_domain: string = environment.backendURL.replace(/(^\w+:|^)\/\//, '');
    if (environment_domain.includes('.aas2.klips.ifp.uni-ulm.de')) {
      document.cookie = `jwt=${value};domain=.aas2.klips.ifp.uni-ulm.de;path=/;samesite=none;secure=true;expires=${expiration}`;
    }
    if (environment_domain.includes('.esano-trainings.de')) {
      document.cookie = `jwt=${value};domain=.esano-trainings.de;path=/;samesite=none;secure=true;expires=${expiration}`;
    }
     */
  }

  /**
   * This function deletes the JWT cookie.
   * TODO Current version of Firefox and Google Chrome cause issues in ngx-cookie-service.
   */
  deleteJWTCookie(): void {
    const date = new Date();

    // Set it expire in -1 days
    date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000);

    document.cookie = `${JWT_COOKIE_NAME}=;path=/`;

    /* Set it
    document.cookie = `${JWT_COOKIE_NAME}=;expires=${date.toUTCString()};path=/`;
    const environment_domain: string = environment.backendURL.replace(/(^\w+:|^)\/\//, '');
    if (environment_domain.includes('.aas2.klips.ifp.uni-ulm.de')) {
      document.cookie = `jwt=;domain=.aas2.klips.ifp.uni-ulm.de;path=/`;
    }
    if (environment_domain.includes('.esano-trainings.de')) {
      document.cookie = `jwt=;domain=.esano-trainings.de;path=/`;
    }
     */
  }

  /**
   * This function checks if the JWT cookie exists.
   */
  checkJWTCookieExists(): boolean {
    return this.cookieService.check(JWT_COOKIE_NAME);
  }

  /**
   * This function returns a Date value of the cookie expiration date.
   *
   * @return Date A date value of the cookie
   */
  getJWTExpirationDate(): Date {
    const jwtToken = this.getJWTCookie();
    if (jwtToken) {
      const decoded = jwt_decode(jwtToken);

      if (decoded['exp'] === undefined) {
        return null;
      } else {
        const date = new Date(0);

        date.setUTCSeconds(decoded['exp']);

        return date;
      }
    } else {
      return null;
    }
  }

  /**
   * This function returns a Date value of the cookie expiration date.
   *
   * @return Date A date value of the cookie
   */
  getJWTExpiration(token): Date {
    if (token) {
      const decoded = jwt_decode(token);

      if (decoded['exp'] === undefined) {
        return null;
      } else {
        const date = new Date(0);

        date.setUTCSeconds(decoded['exp']);

        return date;
      }
    } else {
      return null;
    }
  }

  /**
   * This function checks if the cookie is expired.
   *
   * @return boolean A boolean value of the cookie
   */
  isJWTExpired(): boolean {
    const date = this.getJWTExpirationDate();
    if (date == null) {
      return true;
    }
    return !(date.valueOf() > new Date().valueOf());
  }

  public isCookieValid(): boolean {
    return this.checkJWTCookieExists() && !(this.getJWTCookie() === undefined || this.getJWTCookie() === '');
  }
}
