/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Inject, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { filter, mergeMap, skip, take } from 'rxjs/operators';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { InstanceDetailComponent } from '../../intervention-instance/instance-detail/instance-detail.component';
import { InterventionInstanceInterface } from '../../../models/interface/intervention-instances/intervention-instance.interface';
import { InterventionInterface } from '../../../models/interface/intervention.interface';
import { HelperService } from '../../../services/helper/helper.service';
import { UserInterface } from '../../../models/interface/user.interface';
import { LessonInterface } from '../../../models/interface/lesson.interface';
import { Store } from '@ngrx/store';
import { InterventionActionTypes } from '../../../store/intervention/intervention.action';
import { StudyActionTypes } from '../../../store/study/study.action';
import { getCollabInterventionById } from '../../../store/intervention/intervention.selector';
import { InterventionInstanceStore } from '../../../store/intervention-instance/component-store/intervention-instance.store';
import { QuestionnaireStore } from '../../../store/lesson-questionnaire/component-store/lesson-questionnaire.store';
import { getCollaboratorsByStudyId } from '../../../store/study/study.selector';
import { StudyStore } from '../../../store/study/component-store/study.store';

@Component({
  selector: 'app-dialog-intervention-detail',
  templateUrl: './dialog-intervention-instance-detail.component.html',
  styleUrls: ['./dialog-intervention-instance-detail.component.scss'],
  providers: [InterventionInstanceStore, QuestionnaireStore, StudyStore]
})
export class DialogInterventionInstanceDetailComponent implements OnInit, OnDestroy {
  @ViewChild('instanceDetailContainer', { read: ViewContainerRef }) instanceDetailContainer: ViewContainerRef;

  public components = [];

  public param = { intervention_name: '...' };

  public intervention$: Observable<InterventionInterface | undefined>;

  public instance$: Observable<InterventionInstanceInterface | null>;

  public questionnairesOfIntervention$: Observable<Array<LessonInterface>>;

  private collaboratorsOfStudy$: Observable<{ studyId: number; collaborators: UserInterface[] }>;

  private studyMembers$: Observable<Array<UserInterface>>;

  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private helperService: HelperService,
    private store: Store<{
      getCollabInterventionById: InterventionInterface;
      getCollaboratorsByStudyId: { studyId: number; collaborators: UserInterface[] };
    }>,
    private interventionInstanceStore: InterventionInstanceStore,
    private questionnaireStore: QuestionnaireStore,
    private studyStore: StudyStore
  ) {
    this.instance$ = this.interventionInstanceStore.instance$;
    this.questionnairesOfIntervention$ = this.questionnaireStore.questionnairesOfIntervention$;
    this.studyMembers$ = this.studyStore.studyMembers$;
  }

  public get helper() {
    return this.helperService;
  }

  ngOnInit(): void {
    this.components = [];
    if (this.instanceDetailContainer) {
      this.instanceDetailContainer.clear();
    }

    let instance: InterventionInstanceInterface;
    let intervention: InterventionInterface;
    let collaborators: Array<UserInterface>;
    let questionnaires: Array<LessonInterface>;
    let members: Array<UserInterface>;

    this.interventionInstanceStore.getInstance(this.data.instanceId);
    this.subscriptions.push(
      this.instance$
        .pipe(
          filter(value => !!value),
          take(1),
          mergeMap((result: InterventionInstanceInterface) => {
            instance = result;
            this.intervention$ = this.store.select(getCollabInterventionById(instance.attributes.intervention_id));
            this.store.dispatch({
              type: InterventionActionTypes.getInterventionsOfCollabStudyType,
              payload: { typeOfParentStudy: 'study' }
            });
            return this.intervention$.pipe(
              filter((interventionResp: InterventionInterface | undefined) => !!interventionResp),
              take(1),
              mergeMap((interventionResp: InterventionInterface) => {
                intervention = interventionResp;
                this.questionnaireStore.getQuestionnairesIntervention({ interventionId: instance.attributes.intervention_id });
                return this.questionnairesOfIntervention$.pipe(
                  skip(1),
                  take(1),
                  mergeMap((res: any) => {
                    questionnaires = res;
                    const reqs = [];
                    this.studyStore.getMembers({ studyId: intervention.attributes.study_id });
                    reqs.push(this.studyMembers$.pipe(skip(1), take(1)));
                    this.store.dispatch({
                      type: StudyActionTypes.getCollaboratorsType,
                      payload: { studyId: intervention.attributes.study_id, include: 'roles' }
                    });
                    this.collaboratorsOfStudy$ = this.store.select(getCollaboratorsByStudyId(intervention.attributes.study_id));
                    reqs.push(
                      this.collaboratorsOfStudy$.pipe(
                        filter(collaboratorsOfStudy => !!collaboratorsOfStudy),
                        take(1)
                      )
                    );
                    return forkJoin(reqs);
                  })
                );
              })
            );
          })
        )
        .subscribe((results: any) => {
          members = results[0];
          collaborators = results[1].collaborators;
          const component: InstanceDetailComponent = this.instanceDetailContainer.createComponent(InstanceDetailComponent).instance;
          component._instance = instance;
          component._intervention = intervention;
          component._questionnaires = questionnaires;
          component._collaborators = collaborators;
          component._members = members;
          component._initialChild = 'progress';
          component._selectedIndex = this.data.selectedIndex;

          this.components = [...this.components, component];
          this.param = { intervention_name: intervention.attributes.name };
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
