<button [attr.id]="'btnDialogClose'" mat-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title>{{'conversation.modal_create_new_conversation' | translate}}</h1>
<div mat-dialog-content class="mat-typography">
  <div class="form-group">
    <p><strong>{{'conversation.modal_selected_participants' | translate}}</strong></p>
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>{{'conversation.modal_patients' | translate}}</mat-label>
      <mat-select [attr.id]="'membersControl'" (selectionChange)="displaySelectedParticipants()" [formControl]="membersControl" multiple>
        <mat-select-trigger>
          {{membersControl.value?.[0]?.attributes.email || ''}}
          <span *ngIf="(membersControl.value?.length || 0) > 1">
            (+{{(membersControl.value?.length || 0) - 1}} {{(membersControl.value?.length === 2 ? 'conversation.other' :
            'conversation.others') | translate}})
          </span>
        </mat-select-trigger>
        <mat-option [attr.id]="'option_' + member.id" *ngFor="let member of members" [value]="member"
          >{{member.attributes.email}}</mat-option
        >
      </mat-select>
    </mat-form-field>

    <div class="row">
      <div class="col-12 col-md-6">
        <mat-form-field class="full-width">
          <mat-label>{{'conversation.study_selection' | translate}}</mat-label>
          <mat-select
            (selectionChange)="changeSelection()"
            [attr.id]="'selectionQuestionnaire'"
            (click)="resetFilter()"
            [(ngModel)]="selectedStudy"
          >
            <mat-form-field class="full-width" appearance="fill">
              <mat-label>{{'conversation.filter_selection' | translate}}</mat-label>
              <input [attr.id]="'studySelection'" autocomplete="off" matInput [(ngModel)]="filter['studySelection']" />
            </mat-form-field>
            <mat-option [attr.id]="'option_' + study?.id" *ngFor="let study of groups" [value]="study"
              >{{study?.attributes.name}} (ID {{study?.id}})</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-6">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>{{'conversation.modal_ecoaches' | translate}}</mat-label>
          <mat-select
            [attr.id]="'collaboratorsControl'"
            (selectionChange)="displaySelectedParticipants()"
            [formControl]="collaboratorsControl"
            multiple
          >
            <mat-select-trigger>
              {{collaboratorsControl.value?.[0]?.attributes.email || ''}}
              <span *ngIf="(collaboratorsControl.value?.length || 0) > 1">
                (+{{(collaboratorsControl.value?.length || 0) - 1}} {{(collaboratorsControl.value?.length === 2 ? 'conversation.other' :
                'conversation.others') | translate}})
              </span>
            </mat-select-trigger>
            <mat-option [attr.id]="'option_' + collaborator.id" *ngFor="let collaborator of collaborators" [value]="collaborator"
              >{{collaborator.attributes.email}}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button [attr.id]="'btnCancel'" mat-button mat-dialog-close (click)="removeSelectedParticipants()">
    {{'conversation.modal_close' | translate}}
  </button>
  <app-button-feedback
    [_setId]="'btnCreateAndDisplayThread'"
    (click)="createAndDisplayThread()"
    [_isDisabled]="(membersControl.value?.length === 0 && collaboratorsControl.value?.length === 0)"
    [_defaultButtonText]="'conversation.create_new_conversation'"
    [_responseText]="createThreadResponse | async"
  ></app-button-feedback>
</div>
