/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of, Subscription, throwError } from 'rxjs';
import { filter, mergeMap, skip, switchMap, take } from 'rxjs/operators';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { HelperActivityService } from '../../../services/helper/helper-activity/helper-activity.service';
import { InterventionSharedService } from '../../../services/shared/intervention-shared/intervention-shared.service';
import { InterventionInstanceInterface } from '../../../models/interface/intervention-instances/intervention-instance.interface';
import { ActivityInterface } from '../../../models/interface/activity.interface';
import { TableSortInterface } from '../../../models/interface/table/table_sort.interface';
import { InterventionInterface } from '../../../models/interface/intervention.interface';
import { HelperService } from '../../../services/helper/helper.service';
import { UserInterface } from '../../../models/interface/user.interface';
import { LessonInterface } from '../../../models/interface/lesson.interface';
import { Store } from '@ngrx/store';
import { UserActionTypes } from '../../../store/user/user.action';
import { InterventionInstanceStore } from '../../../store/intervention-instance/component-store/intervention-instance.store';
/**
 * Component:
 * Intervention activities page displaying all activities of an intervention;
 * Can be found: {uri}/interventions/{{intervention_id}}/activities
 */

@Component({
  selector: 'app-intervention-activity',
  templateUrl: './intervention-activity.component.html',
  styleUrls: ['./intervention-activity.component.scss'],
  providers: [InterventionInstanceStore]
})
export class InterventionActivityComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public displayedColumns: string[] = ['activity_text', 'performed_by', 'created_at'];
  public dataSource;

  public isLoading$: Observable<boolean>;
  public activities: Array<ActivityInterface> = [];

  // Data provided by UserService
  public members: Array<UserInterface> = [];
  public lessons: Array<LessonInterface> = [];
  public tableSort: TableSortInterface = {
    sortActivityId: null,
    sortAction: null,
    sortActionId: null,
    sortText: null,
    sortPerformedBy: null,
    sortCreatedAt: null
  };

  public studyId: number;

  public activities$: Observable<Array<ActivityInterface>>;

  private myMembers$: Observable<Array<UserInterface>>;

  private allInstancesOfInterventionForECoach$: Observable<Array<InterventionInstanceInterface>>;
  private activitiesOfMultipleInstances$: Observable<{ activities: Array<ActivityInterface>; questionnaires: Array<LessonInterface> }>;

  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private activitiesSubject: BehaviorSubject<Array<ActivityInterface>> = new BehaviorSubject<Array<ActivityInterface>>([]);

  private subscriptions: Subscription[] = [];

  constructor(
    private sharedService: InterventionSharedService,
    private store: Store<{ myMembers: Array<UserInterface> }>,
    private interventionInstanceStore: InterventionInstanceStore,
    private helperService: HelperService,
    private helperActivityService: HelperActivityService
  ) {
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.activities$ = this.activitiesSubject.asObservable();
    this.allInstancesOfInterventionForECoach$ = this.interventionInstanceStore.allInstancesOfInterventionForECoach$;
    this.activitiesOfMultipleInstances$ = this.interventionInstanceStore.activitiesOfMultipleInstances$;
    this.myMembers$ = this.store.select('myMembers');
  }

  // Helper function
  public get helper() {
    return this.helperService;
  }

  public get helperActivity() {
    return this.helperActivityService;
  }

  ngOnInit(): void {
    this.store.dispatch({
      type: UserActionTypes.getMyMembersType,
      payload: {}
    });
    this.subscriptions.push(
      this.myMembers$.subscribe(
        result => {
          this.members = result;
        },
        () => {
          this.members = [];
        }
      )
    );

    this.subscriptions.push(
      this.sharedService.intervention$
        .pipe(
          filter(intervention => !!intervention),
          mergeMap((intervention: InterventionInterface) => {
            this.isLoadingSubject.next(true);
            this.studyId = intervention.attributes.study_id;
            this.interventionInstanceStore.getAllInstancesOfInterventionForECoach({ interventionId: intervention.id });
            return this.allInstancesOfInterventionForECoach$.pipe(skip(1), take(1));
          }),
          mergeMap((result: Array<InterventionInstanceInterface>) => {
            const allInstancesOfECoach: Array<InterventionInstanceInterface> = result;
            const payload: Array<{ instanceId: number; include: string }> = allInstancesOfECoach.map(
              (value: InterventionInstanceInterface) => ({
                instanceId: parseInt(value.id.toString(), 10),
                include: 'questionnaires'
              })
            );
            if (payload.length > 0) {
              this.interventionInstanceStore.getActivitiesOfMultipleInstances(payload);
              return this.activitiesOfMultipleInstances$.pipe(
                skip(1),
                take(1),
                switchMap((res: { activities: Array<ActivityInterface>; questionnaires: Array<LessonInterface> }) => {
                  this.lessons = res.questionnaires;
                  return of(res.activities);
                })
              );
            } else {
              return throwError([]);
            }
          })
        )
        .subscribe(
          (results: Array<any>) => {
            this.activities = this.helper.getActivityByNewest(results);
            this.activitiesSubject.next(this.activities);
            this.dataSource = this.activitiesSubject.value;
            this.dataSource.sort = this.sort;
            const currentPageSize = this.paginator ? this.paginator.pageSize : 20;
            this.dataSource = this.activitiesSubject.value.slice(0, currentPageSize);
            if (this.paginator) {
              this.paginator.firstPage();
            }

            this.isLoadingSubject.next(false);
          },
          () => {
            this.activities = [];
            this.isLoadingSubject.next(false);
          }
        )
    );
  }

  public onPageChangeActivity(event: PageEvent): void {
    const startIndex = event.pageIndex * event.pageSize;
    const endIndex =
      startIndex + event.pageSize > this.activitiesSubject.value.length ? this.activitiesSubject.value.length : startIndex + event.pageSize;
    if (this.sort.direction !== '') {
      this.sortData(this.sort, this.activitiesSubject.value.slice());
    }
    this.dataSource = this.activitiesSubject.value.slice(startIndex, endIndex);
    this.dataSource.sort = this.sort;
  }

  public sortData(sort: Sort, data: Array<ActivityInterface>): void {
    const isDesc = sort.direction === 'desc';
    switch (sort.active) {
      case 'activity_text':
        this.activitiesSubject.next(
          data.sort((a, b) => {
            const behavior = new BehaviorSubject<number>(null);
            forkJoin([this.helperActivity.getActivityText(a), this.helperActivity.getActivityText(b)]).subscribe((activities: string[]) => {
              const act1 =
                a.attributes.action === 'ANSWERSHEET_SUBMITTED'
                  ? activities[0] + this.helperActivity.getLessonNameOfActivity(a, this.lessons)
                  : activities[0];
              const act2 =
                b.attributes.action === 'ANSWERSHEET_SUBMITTED'
                  ? activities[1] + this.helperActivity.getLessonNameOfActivity(b, this.lessons)
                  : activities[1];
              if (isDesc) {
                behavior.next(act2.localeCompare(act1));
              } else {
                behavior.next(act1.localeCompare(act2));
              }
            });
            return behavior.value;
          })
        );
        this.dataSource = this.activitiesSubject.value;
        break;
      case 'performed_by':
        this.helperActivityService.toggleSortPerformedBy(data, isDesc, this.activitiesSubject, this.members, this.tableSort);
        this.dataSource = this.activitiesSubject.value;
        break;
      case 'created_at':
        this.helperActivityService.toggleSortCreated(data, isDesc, this.activitiesSubject, this.tableSort);
        this.dataSource = this.activitiesSubject.value;
        break;
    }
  }

  public onChangeSortTable(sort: Sort): void {
    const data: Array<ActivityInterface> = this.activitiesSubject.value.slice();
    const isDefault = sort.direction === '';
    const currentPageSize = this.paginator ? this.paginator.pageSize : 20;
    if (isDefault) {
      this.dataSource = data.slice(0, currentPageSize);
    } else {
      this.sortData(sort, data);
      this.dataSource = this.activitiesSubject.value.slice(0, currentPageSize);
    }
    this.paginator.firstPage();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
