import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BuddyService } from '../../services/api/buddy/buddy.service';
import { BuddyActionTypes } from './buddy.action';
import { HttpErrorResponseMessage } from 'src/app/enum/http-error-response-message';

@Injectable()
export class BuddyEffects {
  constructor(private actions$: Actions, private buddyService: BuddyService) {}
}
