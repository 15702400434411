import { HttpResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable, Subscription, filter, take } from 'rxjs';
import { InterventionInterface } from 'src/app/models/interface/intervention.interface';
import { InterventionStore } from 'src/app/store/intervention/component-store/intervention.store';
import { DialogInterventionPublishOfficialComponent } from '../dialog-intervention-publish-official/dialog-intervention-publish-official.component';

@Component({
  selector: 'app-dialog-intervention-publish-review',
  templateUrl: './dialog-intervention-publish-review.component.html',
  styleUrls: ['./dialog-intervention-publish-review.component.scss'],
  providers: [InterventionStore]
})
export class DialogInterventionPublishReviewComponent implements OnInit, OnDestroy {
  public firstTerm = false;
  public secondTerm = false;
  public thirdTerm = false;
  public fourthTerm = false;
  public fifthTerm = false;
  public sixthTerm = false;
  public intervention: InterventionInterface;
  public isTest: boolean;
  public param = { intervention_name: '...' };

  public confirmInterventionReviewAsECoachManagerResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');

  public confirmInterventionReviewAsECoachManagerResponse$: Observable<any>;

  public deactivationText = '';

  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private interventionStore: InterventionStore,
    private dialogRef: MatDialogRef<DialogInterventionPublishOfficialComponent>
  ) {
    this.confirmInterventionReviewAsECoachManagerResponse$ = interventionStore.confirmInterventionReviewAsECoachManagerResponse$;
  }

  ngOnInit(): void {
    this.intervention = this.data.intervention;
    this.isTest = !!this.intervention.attributes.is_test;
    this.param = { intervention_name: this.intervention.attributes.name };
  }

  public confirmReviewedIntervention(): void {
    if (this.updateCondition()) {
      if (this.confirmInterventionReviewAsECoachManagerResponse.value === 'DEFAULT') {
        this.confirmInterventionReviewAsECoachManagerResponse.next('LOADING');
        this.interventionStore.confirmInterventionReviewAsECoachManager({
          id: this.intervention.id
        });
        this.subscriptions.push(
          this.confirmInterventionReviewAsECoachManagerResponse$
            .pipe(
              filter(value => !!value),
              take(1)
            )
            .subscribe((value: any) => {
              if (value instanceof HttpResponse) {
                this.confirmInterventionReviewAsECoachManagerResponse.next('SUCCESS');
                setTimeout(() => {
                  this.confirmInterventionReviewAsECoachManagerResponse.next('DEFAULT');
                  this.dialogRef.close('SUCCESS');
                }, 2500);
              } else {
                this.confirmInterventionReviewAsECoachManagerResponse.next('FAILURE');
                setTimeout(() => {
                  this.confirmInterventionReviewAsECoachManagerResponse.next('DEFAULT');
                }, 2500);
              }
            })
        );
      }
    }
  }

  public updateCondition(): boolean {
    if (this.isTest) {
      return this.firstTerm && this.secondTerm;
    } else {
      return this.firstTerm && this.secondTerm && this.thirdTerm && this.fourthTerm && this.fifthTerm && this.sixthTerm;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
