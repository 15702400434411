import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, distinctUntilChanged, Observable, Subscription } from 'rxjs';
import { QuestionSliderInterface } from '../../../../models/interface/elements-question/question-slider/question-slider.interface';
import { AnswersheetAnswerInterface } from '../../../../models/interface/answersheet-answer.interface';
import { SliderInterface } from '../../../../models/interface/elements-question/question-slider/slider.interface';
import { ConditionalblockInterface } from '../../../../models/interface/condition/conditionalblock.interface';
import { ElementInterface } from '../../../../models/interface/elements/element.interface';
import { EvaluationService } from '../../../../services/evaluation/evaluation.service';
import { Store } from '@ngrx/store';
import { DynamicAnswersActionTypes } from '../../../../store/dynamic-answers/dynamic-answers.action';

/**
 * Component:
 * Represents slider element
 */

@Component({
  selector: 'app-question-slider',
  templateUrl: './question-slider.component.html',
  styleUrls: ['./question-slider.component.scss', '../../../../../assets/styles/aas2_app.scss']
})
export class QuestionSliderComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('answerRequiredRef', { read: ElementRef, static: false }) answerRequiredRef: ElementRef;
  @ViewChild('slider', { read: ElementRef, static: false }) slider: ElementRef;

  public question = '';
  public labelLeft = '';
  public labelRight = '';
  public scaleRangeLeft = 0;
  public scaleRangeRight = 1;
  public stepWidth = 1;
  public answer;
  public required = false;
  public isDisabled = false;

  public manyPoints = false;

  public allElements = [];
  public element: QuestionSliderInterface | ElementInterface;
  public questionSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

  // Values to evaluate condition
  public condition: Array<ConditionalblockInterface> = [];

  public evaluation$: Observable<boolean>;
  public hidden$: Observable<boolean>;
  public noAnswerProvided$: Observable<boolean>;

  private dynamicAnswers: Array<AnswersheetAnswerInterface> = [];
  private dynamicAnswers$: Observable<Array<AnswersheetAnswerInterface>>;

  private lessonLocale$: Observable<any>;

  private iteration = 0;
  private nested = 0;

  private content: SliderInterface;
  private answers: Array<AnswersheetAnswerInterface> = [];

  private pageNumber: number;
  private currentPage: number;

  private expressionSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private evaluationSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private hiddenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private noAnswerProvidedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private subscriptions: Subscription[] = [];

  constructor(
    private translateService: TranslateService,
    private evaluationService: EvaluationService,
    private renderer: Renderer2,
    private store: Store<{ dynamicAnswers: Array<AnswersheetAnswerInterface>; lessonLocale: any }>
  ) {
    this.dynamicAnswers$ = store.select('dynamicAnswers');
    this.lessonLocale$ = store.select('lessonLocale');
    this.evaluation$ = this.evaluationSubject.asObservable();
    this.hidden$ = this.hiddenSubject.asObservable();
    this.noAnswerProvided$ = this.noAnswerProvidedSubject.asObservable();
  }

  @Input()
  set _element(_element: QuestionSliderInterface | ElementInterface) {
    this.element = _element;
    this.stepWidth = _element.values.step;
  }

  @Input()
  set _iteration(_iteration: number) {
    this.iteration = _iteration;
  }

  @Input()
  set _nested(_nested: number) {
    this.nested = _nested;
  }

  @Input()
  set _answers(_answers: Array<AnswersheetAnswerInterface>) {
    if (_answers) {
      this.answers = _answers;
      this.isDisabled = true;
    }
  }

  // Block condition
  @Input()
  set _condition(_condition: Array<ConditionalblockInterface>) {
    this.condition = _condition;
  }

  @Input()
  set _page(_page) {
    if (_page !== undefined) {
      this.pageNumber = _page;
      if (this.currentPage !== undefined) {
        this.hiddenSubject.next(this.currentPage.toString() !== this.pageNumber.toString());
      }
    }
  }

  @Input()
  set _currentPage(_currentPage) {
    if (_currentPage !== undefined) {
      this.currentPage = _currentPage;
      if (this.pageNumber !== undefined) {
        this.hiddenSubject.next(this.currentPage.toString() !== this.pageNumber.toString());
      }
    }
  }

  @Input()
  set _allElements(_allElements) {
    if (_allElements !== undefined) {
      this.allElements = _allElements;
    }
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.dynamicAnswers$.pipe(distinctUntilChanged()).subscribe(dynamicAnswers => {
        this.dynamicAnswers = dynamicAnswers;
        this.questionSubject.next(
          this.evaluationService.replaceReference(
            this.question,
            this.allElements,
            localStorage.getItem('lessonLocale'),
            this.answers,
            this.dynamicAnswers
          )
        );
        if (this.element.position) {
          this.setAnswersFromDynamicAnswers(dynamicAnswers);
        }
        this.evaluationService.evaluateExpression(
          this.expressionSubject,
          this.evaluationSubject,
          this.condition,
          this.element.position,
          this.answers,
          this.dynamicAnswers
        );
      })
    );

    // Assign translation
    this.content = this.findTranslation();
    this.setSlider(this.content);
    this.subscriptions.push(
      this.translateService.onLangChange.subscribe(
        (event: LangChangeEvent) => {
          this.content = this.findTranslation();
          this.setSlider(this.content);
        },
        error => {
          console.error(error);
        }
      )
    );

    this.subscriptions.push(
      this.lessonLocale$.subscribe(() => {
        this.content = this.findTranslation();
        this.setSlider(this.content);
      })
    );

    if (this.answers.length > 0) {
      this.setAnswersFromAnswersheet(this.answers);
    } else {
      this.setAnswersFromDynamicAnswers(this.dynamicAnswers);
    }
    this.evaluationService.evaluateExpression(
      this.expressionSubject,
      this.evaluationSubject,
      this.condition,
      this.element.position,
      this.answers,
      this.dynamicAnswers
    );
  }

  ngAfterViewInit(): void {
    this.injectStyle();
  }

  public injectStyle(): void {
    try {
      this.renderer.setStyle(this.answerRequiredRef.nativeElement.shadowRoot.childNodes[0], 'background-color', 'transparent');
    } catch (TypeError) {
      setTimeout(() => {
        this.injectStyle();
      }, 100);
    }
  }

  public setAnswersFromAnswersheet(answers: Array<AnswersheetAnswerInterface>): void {
    const element = answers.find(
      (answer: AnswersheetAnswerInterface) =>
        answer.position.toString() === this.element.position.toString() && answer.parentLoopIndex.toString() === this.iteration.toString()
    );
    if (element) {
      this.answer = element.value;
    } else {
      this.noAnswerProvidedSubject.next(true);
    }
  }

  public setAnswersFromDynamicAnswers(answers: Array<AnswersheetAnswerInterface>): void {
    const element = answers.find(
      (answer: AnswersheetAnswerInterface) =>
        answer.position.toString() === this.element.position.toString() && answer.parentLoopIndex.toString() === this.iteration.toString()
    );
    if (element) {
      this.answer = element.value;
    }
  }

  public onChange(event?): void {
    // Default looped answers set value to min
    if (isNaN(this.answer)) {
      this.answer = this.content.answers[0].value;
    }
    const submittedAnswer: AnswersheetAnswerInterface = {
      value: this.answer,
      position: this.element.position,
      loop: false,
      parentLoopIndex: this.iteration + this.nested
    };
    const findElementIndex = this.dynamicAnswers.findIndex(
      (value: AnswersheetAnswerInterface) =>
        value.position.toString() === submittedAnswer.position.toString() &&
        value.parentLoopIndex.toString() === submittedAnswer.parentLoopIndex.toString()
    );
    if (findElementIndex > -1) {
      const temp = [...this.dynamicAnswers];
      temp[findElementIndex] = submittedAnswer;
      this.dynamicAnswers = temp;
    } else {
      this.dynamicAnswers = [...this.dynamicAnswers, submittedAnswer];
    }
    this.store.dispatch({
      type: DynamicAnswersActionTypes.addDynamicAnswersType,
      payload: this.dynamicAnswers
    });
  }

  public onAddToRange(): void {
    this.answer++;
    this.onChange();
  }

  public onSubtractFromRange(): void {
    this.answer--;
    this.onChange();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  private findTranslation() {
    const _content = this.element.translations.find(translation => translation.locale === localStorage.getItem('lessonLocale'));
    return _content ? _content : this.element.translations[0];
  }

  private setSlider(content: SliderInterface): void {
    this.question = content.question;
    this.labelLeft = content.answers[0].label;
    this.labelRight = content.answers[1].label;
    this.scaleRangeLeft = content.answers[0].value;
    this.scaleRangeRight = content.answers[1].value;
    this.questionSubject.next(
      this.evaluationService.replaceReference(
        this.question,
        this.allElements,
        localStorage.getItem('lessonLocale'),
        this.answers,
        this.dynamicAnswers
      )
    );
    this.manyPoints = this.scaleRangeRight > 20;
  }
}
