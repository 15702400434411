<!-- Slider component for lection/question elements in ionic app -->
<div *ngIf="(evaluation$ | async)" [class.required]="required" [hidden]="hidden$ | async" class="ion-text-start question">
  <ion-label class="ion-text-wrap ion-text-center" color="page-font">{{questionSubject | async}}</ion-label>
  <div class="slider" fxLayout="column">
    <div class="mb-4 range-slider-labels">
      <div class="text-start">{{labelLeft}}</div>
      <h4>{{answer}}</h4>
      <div class="text-end">{{labelRight}}</div>
    </div>
    <mat-slider
      [disabled]="isDisabled"
      [max]="scaleRangeRight"
      [min]="scaleRangeLeft"
      [step]="stepWidth"
      [discrete]="true"
      [showTickMarks]="true"
      (change)="onChange($event)"
      color="primary"
    >
      <input [(ngModel)]="answer" matSliderThumb />
    </mat-slider>
    <!--
    <ion-item #slider class="ion-no-padding" lines="none">
      <ion-range
        (ionChange)="onChange($event)"
        [(ngModel)]="answer"
        max="{{scaleRangeRight}}"
        min="{{scaleRangeLeft}}"
        step="{{stepWidth}}"
        snaps="true"
        debounce="50"
        [class.disabled]="isDisabled"
        [class.notAnswered]="noAnswerProvided$ | async"
      >
        <ion-button slot="start" fill="clear" *ngIf="manyPoints" (click)="onSubtractFromRange()">
          <ion-icon name="remove-circle-outline"></ion-icon>
        </ion-button>
        <ion-button slot="end" fill="clear" *ngIf="manyPoints" (click)="onAddToRange()">
          <ion-icon name="add-circle-outline"></ion-icon>
        </ion-button>
      </ion-range>
    </ion-item>
    -->
    <div *ngIf="noAnswerProvided$ | async" class="text-danger mb-2">
      <small>{{'question-slider.no_answer_has_been_submitted' | translate}}</small>
    </div>
  </div>
</div>
