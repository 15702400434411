import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AsyncPipe, CommonModule } from '@angular/common';
import { ElementsBlockclosesComponent } from './components/elements-blockcloses/elements-blockcloses.component';
import { ElementsBlockopensComponent } from './components/elements-blockopens/elements-blockopens.component';
import { ElementsLinesComponent } from './components/elements-lines/elements-lines.component';
import { ElementsPageComponent } from './components/elements-page/elements-page.component';
import { ElementsSpacesComponent } from './components/elements-spaces/elements-spaces.component';
import { QuestionHeadlineComponent } from './components/question-headline/question-headline.component';
import { QuestionMediaComponent } from './components/question-media/question-media.component';
import { QuestionMultipleChoiceComponent } from './components/question-multiple-choice/question-multiple-choice.component';
import { QuestionSingleChoiceComponent } from './components/question-single-choice/question-single-choice.component';
import { QuestionSliderComponent } from './components/question-slider/question-slider.component';
import { QuestionTextComponent } from './components/question-text/question-text.component';
import { QuestionTextAreaComponent } from './components/question-text-area/question-text-area.component';
import { QuestionTextDateComponent } from './components/question-text-date/question-text-date.component';
import { QuestionTextStringComponent } from './components/question-text-string/question-text-string.component';
import { QuestionYesNoComponent } from './components/question-yes-no/question-yes-no.component';
import { QuestionTableComponent } from './components/question-table/question-table.component';
import { IonicModule } from '@ionic/angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PipeModule } from '../pipe/pipe.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexModule } from '@angular/flex-layout';
import { MaterialModule } from '../material/material.module';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { TextProgressBarComponent } from './text-progress-bar/text-progress-bar.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export const createTranslateLoader = (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json');

@NgModule({
  declarations: [
    ElementsBlockclosesComponent,
    ElementsBlockopensComponent,
    ElementsLinesComponent,
    ElementsPageComponent,
    ElementsSpacesComponent,
    QuestionHeadlineComponent,
    QuestionMediaComponent,
    QuestionMultipleChoiceComponent,
    QuestionSingleChoiceComponent,
    QuestionSliderComponent,
    QuestionTextComponent,
    QuestionTextAreaComponent,
    QuestionTextDateComponent,
    QuestionTextStringComponent,
    QuestionYesNoComponent,
    QuestionTableComponent,
    ProgressBarComponent,
    TextProgressBarComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    PipeModule,
    FlexModule,
    MaterialModule
  ],
  providers: [AsyncPipe],
  exports: [
    ElementsBlockclosesComponent,
    ElementsBlockopensComponent,
    ElementsLinesComponent,
    ElementsPageComponent,
    ElementsSpacesComponent,
    QuestionHeadlineComponent,
    QuestionMediaComponent,
    QuestionMultipleChoiceComponent,
    QuestionSingleChoiceComponent,
    QuestionSliderComponent,
    QuestionTextComponent,
    QuestionTextAreaComponent,
    QuestionTextDateComponent,
    QuestionTextStringComponent,
    QuestionYesNoComponent,
    QuestionTableComponent,
    ProgressBarComponent,
    TextProgressBarComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class QuestionnaireElementModule {}
