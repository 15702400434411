<div *ngIf="(isLoading$ | async); else elseDiaryAnswersheetBlock" class="col-sm">
  <app-loader></app-loader>
</div>

<ng-template #elseDiaryAnswersheetBlock>
  <div class="jumbotron text-center hoverable p-4">
    <!-- Card with detailed lesson content -->
    <div class="card">
      <app-questionnaire-preview
        [_background_color]="backgroundColor"
        [_elementsType]="'questionnaires'"
        [_lesson]="lesson"
        [_studyId]="studyId"
        [_answers]="answers"
        [_isDiary]="true"
      ></app-questionnaire-preview>
    </div>
  </div>
</ng-template>
