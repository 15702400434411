import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog-admin-organisation-update',
  templateUrl: './dialog-admin-organisation-update.component.html',
  styleUrls: ['./dialog-admin-organisation-update.component.scss']
})
export class DialogAdminOrganisationUpdateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
