import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { StudyMediaInterface } from '../../../models/interface/study/study-media.interface';
import { HelperService } from '../../../services/helper/helper.service';

/**
 * TODO: Add selection and removal of media files in gallery
 * Component:
 * Media gallery component displaying all media files of a study;
 */

@Component({
  selector: 'app-media-gallery',
  templateUrl: './media-gallery.component.html',
  styleUrls: ['./media-gallery.component.scss']
})
export class MediaGalleryComponent implements OnInit, AfterViewInit {
  @Output() selectedMedia = new EventEmitter<StudyMediaInterface>();

  public studyMedia: Array<StudyMediaInterface> = [];
  public studyId: number;

  public selectMediaForm: UntypedFormGroup;

  // Pagination
  public pagedMediaSubject: BehaviorSubject<Array<StudyMediaInterface>> = new BehaviorSubject<Array<StudyMediaInterface>>([]);
  public pagedMedia$: Observable<Array<StudyMediaInterface>> = this.pagedMediaSubject.asObservable();

  // Subscription handler
  private subscriptions: Subscription[] = [];

  constructor(private helperService: HelperService, private formBuilder: UntypedFormBuilder) {
    this.selectMediaForm = this.formBuilder.group({
      selected: [null, Validators.required]
    });
  }

  public get helper() {
    return this.helperService;
  }

  @Input() set _studyMedia(_studyMedia: Array<StudyMediaInterface>) {
    if (_studyMedia !== undefined) {
      this.studyMedia = _studyMedia.filter((value: StudyMediaInterface) => this.isImage(value));
    }
  }

  @Input() set _studyId(_studyId: number) {
    if (_studyId !== undefined) {
      this.studyId = _studyId;
    }
  }

  ngOnInit(): void {}

  isImage(media: StudyMediaInterface) {
    return media.attributes.mimetype.match(/image\/*/);
  }

  ngAfterViewInit(): void {}

  selectMedia() {
    this.selectedMedia.emit(this.selectMediaForm.get('selected').value);
  }
}
