import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { faTools } from '@fortawesome/free-solid-svg-icons/faTools';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';

/**
 * Component:
 * Shows footer element if user is authenticated;
 */

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  @Input()
  color;

  // Icons
  faTools = faTools;

  public isAuthenticated$: Observable<boolean>;

  constructor(private router: Router, private store: Store<{ isAuthenticated: boolean }>) {
    this.isAuthenticated$ = this.store.select('isAuthenticated');
  }

  ngOnInit(): void {}

  public redirectToImprint(): void {
    this.router.navigate([`/imprint`]);
  }

  public redirectToPrivacyECoach(): void {
    this.router.navigate([`/privacy-ecoach`]);
  }

  ngOnDestroy(): void {}
}
