import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { catchError, EMPTY, mergeMap, Observable, tap, throwError } from 'rxjs';
import { QueueService } from '../../../services/api/queue/queue.service';

export interface QueueState {
  downloadReportResponse: any;
}

@Injectable()
export class QueueStore extends ComponentStore<QueueState> {
  readonly downloadReportResponse$: Observable<any> = this.select(state => state.downloadReportResponse, {
    debounce: true
  });

  readonly updateQueueState = this.updater((state, payload: { downloadReportResponse?: any }) => ({
    downloadReportResponse: payload.downloadReportResponse ? payload.downloadReportResponse : state.downloadReportResponse
  }));

  readonly downloadReport = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) => {
        this.updateQueueState({ downloadReportResponse: null });
        return this.queueService.downloadReport(payload.reportName).pipe(
          tap({
            next: (result: any) => this.updateQueueState({ downloadReportResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateQueueState({ downloadReportResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  constructor(private queueService: QueueService) {
    super({
      downloadReportResponse: null
    });
  }
}
