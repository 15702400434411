import { Action, createAction, props } from '@ngrx/store';
import { StudyInterface } from 'src/app/models/interface/study/study.interface';
import { UserInterface } from '../../models/interface/user.interface';

export enum StudyActionTypes {
  addCollabGroupsStoreType = 'Add Collaborating Groups Store',
  updateCollabGroupsStoreType = 'Update Collaborating Groups Store',
  deleteCollabGroupsStoreType = 'Delete Collaborating Groups Store',

  addNonCollabGroupsStoreType = 'Add Non-Collaborating Groups Store',
  updateNonCollabGroupsStoreType = 'Update Non-Collaborating Groups Store',
  deleteNonCollabGroupsStoreType = 'Delete Non-Collaborating Groups Store',

  addCollaboratorsOfStudiesStoreType = 'Add Collaborators of Study Store',
  updateCollaboratorsOfStudiesStoreType = 'Update Collaborators of Study Store',
  removeCollaboratorsOfStudiesStoreType = 'Delete Collaborators of Study Store',

  getCollaboratingStudiesType = '[Study API] Get Collaborating Studies',
  getCollaboratingStudiesSuccessType = '[Success] Get Collaborating Studies',
  getCollaboratingStudiesErrorType = '[Error] Get Collaborating Studies',

  getNonCollaboratingStudiesType = '[Study API] Get Non-collaborating Studies',
  getNonCollaboratingStudiesSuccessType = '[Success] Get Non-collaborating Studies',
  getNonCollaboratingStudiesErrorType = '[Error] Get Non-collaborating Studies',

  getCollaboratorsType = '[Study API] Get Study Collaborators',
  getCollaboratorsSuccessType = '[Success] Get Study Collaborators',
  getCollaboratorsErrorType = '[Error] Get Study Collaborators',

  getCollaboratorsOfMultipleStudiesType = '[Study API] Get Study Collaborators of Multiple Studies',
  getCollaboratorsOfMultipleStudiesSuccessType = '[Success] Get Study Collaborators of Multiple Studies',
  getCollaboratorsOfMultipleStudiesErrorType = '[Error] Get Study Collaborators of Multiple Studies'
}

export const addCollabGroupsStore = createAction(
  StudyActionTypes.addCollabGroupsStoreType,
  props<{ collabGroups: Array<StudyInterface> }>()
);
export const updateCollabGroupsStore = createAction(
  StudyActionTypes.updateCollabGroupsStoreType,
  props<{ collabGroups: Array<StudyInterface> }>()
);
export const removeCollabGroupsStore = createAction(StudyActionTypes.deleteCollabGroupsStoreType);

export const addNonCollabGroupsStore = createAction(
  StudyActionTypes.addNonCollabGroupsStoreType,
  props<{ nonCollabGroups: Array<StudyInterface> }>()
);
export const updateNonCollabGroupsStore = createAction(
  StudyActionTypes.updateNonCollabGroupsStoreType,
  props<{ nonCollabGroups: Array<StudyInterface> }>()
);
export const removeNonCollabGroupsStore = createAction(StudyActionTypes.deleteNonCollabGroupsStoreType);

export const addCollaboratorsOfStudiesStore = createAction(
  StudyActionTypes.addCollaboratorsOfStudiesStoreType,
  props<{ collaboratorsOfStudy: { studyId: number; collaborators: UserInterface[] } }>()
);
export const updateCollaboratorsOfStudiesStore = createAction(
  StudyActionTypes.updateCollaboratorsOfStudiesStoreType,
  props<{ collaboratorsOfStudy: { studyId: number; collaborators: UserInterface[] } }>()
);
export const removeCollaboratorsOfStudiesStore = createAction(
  StudyActionTypes.removeCollaboratorsOfStudiesStoreType,
  props<{ studyId: number }>()
);

export class GetCollaboratingStudies implements Action {
  readonly type: string = StudyActionTypes.getCollaboratingStudiesType;
  constructor(readonly payload: any) {}
}

export class GetCollaboratingStudiesSuccess implements Action {
  readonly type: string = StudyActionTypes.getCollaboratingStudiesSuccessType;
  constructor(readonly response: any) {}
}

export class GetCollaboratingStudiesError implements Action {
  readonly type: string = StudyActionTypes.getCollaboratingStudiesErrorType;
  constructor(readonly response: any) {}
}

export class GetNonCollaboratingStudies implements Action {
  readonly type: string = StudyActionTypes.getNonCollaboratingStudiesType;
  constructor(readonly payload: any) {}
}

export class GetNonCollaboratingStudiesSuccess implements Action {
  readonly type: string = StudyActionTypes.getNonCollaboratingStudiesSuccessType;
  constructor(readonly response: any) {}
}

export class GetNonCollaboratingStudiesError implements Action {
  readonly type: string = StudyActionTypes.getNonCollaboratingStudiesErrorType;
  constructor(readonly response: any) {}
}

export class GetCollaborators implements Action {
  readonly type: string = StudyActionTypes.getCollaboratorsType;
  constructor(readonly payload: any) {}
}

export class GetCollaboratorsSuccess implements Action {
  readonly type: string = StudyActionTypes.getCollaboratorsSuccessType;
  constructor(readonly response: any) {}
}

export class GetCollaboratorsError implements Action {
  readonly type: string = StudyActionTypes.getCollaboratorsErrorType;
  constructor(readonly response: any) {}
}

export class GetCollaboratorsOfMultipleStudies implements Action {
  readonly type: string = StudyActionTypes.getCollaboratorsOfMultipleStudiesType;
  constructor(readonly payload: any) {}
}

export class GetCollaboratorsOfMultipleStudiesSuccess implements Action {
  readonly type: string = StudyActionTypes.getCollaboratorsOfMultipleStudiesSuccessType;
  constructor(readonly response: any) {}
}

export class GetCollaboratorsOfMultipleStudiesError implements Action {
  readonly type: string = StudyActionTypes.getCollaboratorsOfMultipleStudiesErrorType;
  constructor(readonly response: any) {}
}
