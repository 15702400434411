import { Action, createAction, props } from '@ngrx/store';
import { InterventionInstanceReminderInterface } from '../../models/interface/intervention-instance-reminder.interface';

export enum ReminderActionTypes {
  addAllInterventionInstanceRemindersStoreType = 'Add All Intervention Instance Reminders Store',
  updateAllInterventionInstanceRemindersStoreType = 'Update All Intervention Instance Reminders Store',
  deleteAllInterventionInstanceRemindersStoreType = 'Remove All Intervention Instance Reminders Store',

  getAllInterventionInstanceRemindersOfUserType = '[Queue API] Get All Intervention Instance Reminders of a User',
  getAllInterventionInstanceRemindersOfUserSuccessType = '[Success] Get All Intervention Instance Reminders of a User',
  getAllInterventionInstanceRemindersOfUserErrorType = '[Error] Get All Intervention Instance Reminders of a User'
}

export const addAllInterventionInstanceRemindersStore = createAction(
  ReminderActionTypes.addAllInterventionInstanceRemindersStoreType,
  props<{ allInterventionInstanceReminders: Array<InterventionInstanceReminderInterface> }>()
);

export const updateAllInterventionInstanceRemindersStore = createAction(
  ReminderActionTypes.updateAllInterventionInstanceRemindersStoreType,
  props<{ allInterventionInstanceReminders: Array<InterventionInstanceReminderInterface> }>()
);

export const removeAllInterventionInstanceRemindersStore = createAction(
  ReminderActionTypes.deleteAllInterventionInstanceRemindersStoreType
);

export class GetAllInterventionInstanceRemindersOfUser implements Action {
  readonly type: string = ReminderActionTypes.getAllInterventionInstanceRemindersOfUserType;
  constructor(readonly payload: any) {}
}

export class GetAllInterventionInstanceRemindersOfUserSuccess implements Action {
  readonly type: string = ReminderActionTypes.getAllInterventionInstanceRemindersOfUserSuccessType;
  constructor(readonly response: any) {}
}

export class GetAllInterventionInstanceRemindersOfUserError implements Action {
  readonly type: string = ReminderActionTypes.getAllInterventionInstanceRemindersOfUserErrorType;
  constructor(readonly response: any) {}
}
