/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { faExclamation } from '@fortawesome/free-solid-svg-icons/faExclamation';
import { faList } from '@fortawesome/free-solid-svg-icons/faList';
import { Router } from '@angular/router';
import { BehaviorSubject, iif, Observable, of, Subscription } from 'rxjs';
import { distinctUntilChanged, mergeMap, skip, switchMap, take } from 'rxjs/operators';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { InterventionInterface } from '../../../models/interface/intervention.interface';
import { HelperService } from '../../../services/helper/helper.service';
import { Store } from '@ngrx/store';
import { InterventionActionTypes } from '../../../store/intervention/intervention.action';
import { InterventionStore } from '../../../store/intervention/component-store/intervention.store';
import { Actions, ofType } from '@ngrx/effects';

/**
 * Component:
 * Intervention page displaying a list of interventions - child component of CatalogueComponent;
 */

@Component({
  selector: 'app-intervention',
  templateUrl: './intervention.component.html',
  styleUrls: ['./intervention.component.scss'],
  providers: [InterventionStore]
})
export class InterventionComponent implements OnInit, OnDestroy {
  @ViewChild('paginator') paginator;

  // Icons
  faList = faList;
  faExclamation = faExclamation;

  // Filter
  public filter = {
    searchFilter: '',
    isCollaboratingOnly: true,
    typeOfIntervention: '0'
  };

  public isLoading$: Observable<boolean>;

  public param = { result_value: 0 };
  public isCollapse$: Observable<boolean>;

  public interventions$: Observable<Array<InterventionInterface>>;
  public collabInterventions$: Observable<Array<InterventionInterface>>;
  public nonCollabInterventions$: Observable<Array<InterventionInterface>>;
  public pagedInterventions$: Observable<Array<InterventionInterface>>;

  public hasPendingReviewingInterventions = false;
  public pendingReviewingInterventions$: Observable<Array<InterventionInterface>>;

  // All isCollaboratingSubject intervention ids
  public collabInterventionIds: Array<number>;

  public isCollapseSubject = new BehaviorSubject<boolean>(true);

  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private searchTextSubject = new BehaviorSubject<string>('');

  private interventionsSubject: BehaviorSubject<Array<InterventionInterface>> = new BehaviorSubject([]);
  private pagedInterventionsSubject: BehaviorSubject<Array<InterventionInterface>> = new BehaviorSubject<Array<InterventionInterface>>([]);

  private interventions: Array<InterventionInterface> = [];

  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private helperService: HelperService,
    public interventionStore: InterventionStore,
    private store: Store<{
      collabInterventions: Array<InterventionInterface>;
      nonCollabInterventions: Array<InterventionInterface>;
    }>,
    private actions$: Actions
  ) {
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.isCollapse$ = this.isCollapseSubject.asObservable();
    this.interventions$ = this.interventionsSubject.asObservable();
    this.pagedInterventions$ = this.pagedInterventionsSubject.asObservable();
    this.collabInterventions$ = this.store.select('collabInterventions');
    this.nonCollabInterventions$ = this.store.select('nonCollabInterventions');
    this.pendingReviewingInterventions$ = this.interventionStore.pendingReviewingInterventions$;
  }

  // Helper function
  public get helper() {
    return this.helperService;
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
        this.applyInterventionFilter();
      })
    );

    this.interventionStore.getAllPendingReviewingInterventions({});

    this.subscriptions.push(
      this.pendingReviewingInterventions$.pipe(skip(1), take(1)).subscribe((value: any) => {
        this.hasPendingReviewingInterventions = value.length > 0;
      })
    );
    this.applyInterventionFilter();
  }

  public applyInterventionFilter(): void {
    this.isLoadingSubject.next(true);
    this.subscriptions.push(
      this.reloadInterventionList(true).subscribe((result: any) => {
        this.isLoadingSubject.next(false);
      })
    );
  }

  public reloadInterventionList(setFirst?: boolean): Observable<boolean> {
    const pageIndex = this.paginator?.paginator ? this.paginator?.paginator.pageIndex : 0;
    const pageSize = this.paginator?.paginator ? this.paginator?.paginator.pageSize : 20;
    const formGuidanceType = type =>
      ({
        2: 'accompanied',
        1: 'unaccompanied',
        0: undefined
      }[type]);
    const paramGuidanceType: string = formGuidanceType(this.filter['typeOfIntervention']);

    this.store.dispatch({
      type: InterventionActionTypes.getInterventionsOfCollabStudyType,
      payload: { type: 'intervention', interventionType: paramGuidanceType, include: 'roles', typeOfParentStudy: 'study' }
    });
    return this.actions$.pipe(
      ofType(
        InterventionActionTypes.getInterventionsOfCollabStudySuccessType,
        InterventionActionTypes.getInterventionsOfCollabStudyErrorType
      ),
      take(1),
      switchMap((result: any) =>
        iif(() => result.type === InterventionActionTypes.getInterventionsOfCollabStudySuccessType, this.collabInterventions$, of([]))
      ),
      switchMap((result: any) =>
        iif(
          () => this.filter['isCollaboratingOnly'],
          this.setInterventionCollabOnly(result, setFirst, pageIndex, pageSize),
          this.setInterventionNonCollabOnly(result, paramGuidanceType, setFirst, pageIndex, pageSize)
        )
      )
    );
  }

  public setInterventionCollabOnly(interventions: Array<InterventionInterface>, setFirst, pageIndex, pageSize): Observable<any> {
    this.interventions = interventions;
    this.collabInterventionIds = interventions.map(obj => obj.id);
    return this.search(this.filter['searchFilter']).pipe(
      switchMap(() => this.helper.setPagedContent(this.interventionsSubject, this.pagedInterventionsSubject, setFirst, pageIndex, pageSize))
    );
  }

  public setInterventionNonCollabOnly(
    interventions: Array<InterventionInterface>,
    paramGuidanceType,
    setFirst,
    pageIndex,
    pageSize
  ): Observable<any> {
    this.interventions = interventions;
    this.collabInterventionIds = interventions.map(obj => obj.id);
    this.store.dispatch({
      type: InterventionActionTypes.getInterventionsOfNonCollabStudyType,
      payload: { type: 'intervention', interventionType: paramGuidanceType, include: 'roles', typeOfParentStudy: 'study' }
    });
    return this.nonCollabInterventions$.pipe(
      switchMap(noncollab => {
        this.interventions = this.helper.uniqueById(
          this.interventions.concat(noncollab).sort((a, b) => parseInt(a.id.toString(), 10) - parseInt(b.id.toString(), 10))
        );
        return this.search(this.filter['searchFilter']).pipe(
          switchMap(() =>
            this.helper.setPagedContent(this.interventionsSubject, this.pagedInterventionsSubject, setFirst, pageIndex, pageSize)
          )
        );
      })
    );
  }

  public isCollabId(id: number): boolean {
    return this.collabInterventionIds !== undefined ? this.collabInterventionIds.includes(id) : false;
  }

  // Navigates to detailed interventions
  public getDetailedIntervention(id: number): void {
    this.router.navigateByUrl(`/interventions/${id}`);
  }

  // Get intervention instance details
  public getDetailedInterventionInstance(id: number): void {
    this.router.navigateByUrl(`interventions/${id}/instances`);
  }

  // Get study name
  public getDetailedStudy(id: number): void {
    this.router.navigateByUrl(`groups/${id}`);
  }

  public updatePagedInterventions(event: any) {
    if (event) {
      this.pagedInterventionsSubject.next(event);
    }
  }

  public trackByInterventionId(index: number, element: InterventionInterface): number {
    return element.id;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  // Reads search value and runs searchFilter
  private search(name: string): Observable<boolean> {
    this.searchTextSubject.next(name);
    const formGuidanceType = type =>
      ({
        2: 'accompanied',
        1: 'unaccompanied',
        0: undefined
      }[type]);
    const paramGuidanceType: string = formGuidanceType(this.filter['typeOfIntervention']);
    return this.searchTextSubject.pipe(
      distinctUntilChanged(),
      mergeMap(() => {
        this.interventionsSubject.next(
          this.helper
            .filterInterventionsBy(this.interventions, this.filter['searchFilter'].toLowerCase().trim())
            .filter(intervention => (paramGuidanceType ? intervention.attributes.intervention_type === paramGuidanceType : true))
        );
        return of(true);
      })
    );
  }
}
