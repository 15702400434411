/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { PayloadInterface } from '../../../models/interface/payload.interface';
import { HelperService } from '../../helper/helper.service';

/**
 * Service:
 * Profile API service that handles account-related requests
 */

@Injectable({
  providedIn: 'root'
})
export class MyProfileService {
  private backendUrl: string = environment.backendURL;
  private header: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(private http: HttpClient, private helperService: HelperService) {
    this.header = this.header.set('Content-Type', 'application/json');
    this.header = this.helperService.setLocaleFromStorage(this.header);
  }

  /**
   * AAS2 API User - My Profile
   * This function returns logged in user's profile.
   *
   * @return Observable<any> - An observable for any response.
   */
  public getMyProfile(): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/api/v1/my/profile`, { headers: this.header, observe: 'response' });
  }

  /**
   * AAS2 API User - My Roles
   * This function returns logged in user's roles.
   *
   * @return Observable<any> - An observable for any response.
   */
  public getMyRoles(): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/api/v1/my/roles`, { headers: this.header, observe: 'response' });
  }

  /**
   * AAS2 API User - My Notes
   * This function returns logged in user's notes.
   *
   * @return Observable<any> - An observable for any response.
   */
  public getMyNotes(): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/api/v1/my/notes`, { headers: this.header, observe: 'response' });
  }

  /**
   * AAS2 API User - My Studies
   * This function returns logged in user's studies.
   *
   * @return Observable<any> - An observable for any response.
   */
  public getMyStudies(): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/api/v1/my/studies`, { headers: this.header, observe: 'response' });
  }

  /**
   * AAS2 API User - My Pending Studies
   * This function returns logged in user's pending study requests.
   *
   * @return Observable<any> - An observable for any response.
   */
  public getMyPendingStudies(): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/api/v1/my/studies/pending`, { headers: this.header, observe: 'response' });
  }

  /**
   * AAS2 API User - Delete My Pending Studies
   * This function deletes the user's pending study request.
   *
   * @params number requestId - ID of the pending study request.
   * @return Observable<any> - An observable for any response.
   */
  public deleteMyPendingStudies(requestId: number): Observable<any> {
    return this.http.delete<any>(`${this.backendUrl}/api/v1/my/studies/pending${requestId}`, { headers: this.header, observe: 'response' });
  }

  /**
   * AAS2 API User - My Activities
   * This function get the user's activities.
   *
   * @params string action - Action of the activity
   *         boolean unlimited - Return all activities
   * @return Observable<any> - An observable for any response.
   */
  public getMyActivities(action?: string, unlimited: boolean = true): Observable<any> {
    this.header = this.helperService.setLocaleFromStorage(this.header);
    let params = new HttpParams();
    if (action !== undefined) {
      params = params.set('action', action);
    }
    if (unlimited) {
      params = params.set('limit', '0');
    }
    return this.http.get<any>(`${this.backendUrl}/api/v1/my/activities`, { headers: this.header, observe: 'response', params });
  }

  /**
   * AAS2 API User - My Invitations
   * This function get the user's invitations.
   *
   * @return Observable<any> - An observable for any response.
   */
  public getMyInvitations(): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/api/v1/my/invitations`, { headers: this.header, observe: 'response' });
  }

  /**
   * AAS2 API User - Update Profile
   * This function get the user's profile and settings.
   *
   * @params object settings - A key-value object that contains profile settings.
   *         string name - A string of the username.
   *         string firstname - A string of the first name.
   *         string lastname - A string of the last name.
   *         number sex - A number which determines the gender.
   *         any picture - A binary data of the picture.
   * @return Observable<any> - An observable for any response.
   */
  public updateProfile(settings?: any, name?: string, firstname?: string, lastname?: string, sex?: number, picture?: any): Observable<any> {
    const payload: PayloadInterface = {
      data: {
        type: 'users',
        attributes: {
          settings,
          name,
          firstname,
          lastname,
          sex,
          picture: picture ? picture : []
        }
      }
    };
    for (const prop in payload.data.attributes) {
      if (Object.prototype.hasOwnProperty.call(payload.data.attributes, prop)) {
        switch (prop) {
          case 'name': {
            if (name === undefined) {
              delete payload.data.attributes.name;
            }
            break;
          }
          default:
            break;
        }
      }
    }

    if (settings !== undefined) {
      payload.data.attributes.settings = settings;
    }
    return this.http.patch<any>(`${this.backendUrl}/api/v1/my/profile`, payload, { headers: this.header, observe: 'response' });
  }

  /**
   * AAS2 API User - Update Password
   * This function updates the user's password.
   *
   * @params string current_password - A string of the current password.
   *         string password - A string of the new password.
   *         string password_confirmation - A string confirming the new password.
   * @return Observable<any> - An observable for any response.
   */
  public updatePassword(currentPassword: string, password: string, passwordConfirmation: string): Observable<any> {
    const payload: PayloadInterface = {
      data: {
        type: 'users',
        attributes: {
          current_password: currentPassword,
          password,
          password_confirmation: passwordConfirmation
        }
      }
    };
    return this.http.patch<any>(`${this.backendUrl}/api/v1/my/profile/password`, payload, { headers: this.header, observe: 'response' });
  }

  /**
   * AAS2 API User - Create account deletion request
   * This function creates an account deletion request.
   *
   * @params number pseudonymized - flag whether all user data should be pseudonymized or deleted.
   * @return Observable<any> - An observable for any response.
   */
  public createAccountDeletionRequest(pseudonymized: number): Observable<any> {
    const payload: PayloadInterface = {
      data: {
        type: 'users',
        attributes: {
          pseudonymized: pseudonymized
        }
      }
    };
    return this.http.post<any>(`${this.backendUrl}/api/v1/my/account/delete/request`, payload, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API User - Delete account deletion request
   * This function deletes an account deletion request.
   *
   * @return Observable<any> - An observable for any response.
   */
  public deleteAccountDeletionRequest(): Observable<any> {
    return this.http.delete<any>(`${this.backendUrl}/api/v1/my/account/delete/request`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API User - Update account deletion request
   * This function updates an account deletion request.
   *
   * @params number pseudonymized - flag whether all user data should be pseudonymized or deleted.
   * @return Observable<any> - An observable for any response.
   */
  public updateAccountDeletionRequest(pseudonymized: number): Observable<any> {
    const payload: PayloadInterface = {
      data: {
        type: 'users',
        attributes: {
          pseudonymized: pseudonymized
        }
      }
    };
    return this.http.patch<any>(`${this.backendUrl}/api/v1/my/account/delete/request`, payload, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API User - Get account deletion request
   * This function returns an account deletion request.
   *
   * @return Observable<any> - An observable for any response.
   */
  public getAccountDeletionRequest(): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/api/v1/my/account/delete/request`, {
      headers: this.header,
      observe: 'response'
    });
  }
}
