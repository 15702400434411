/* eslint-disable max-len */
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { faBell } from '@fortawesome/free-solid-svg-icons/faBell';
import { faCalendar } from '@fortawesome/free-solid-svg-icons/faCalendar';
import { BehaviorSubject, distinctUntilChanged, filter, Observable, skip, Subscription, switchMap, take } from 'rxjs';
import { InterventionInstanceReminderLogsInterface } from 'src/app/models/interface/intervention-instance-reminder-logs.interface';
import { LessonInterface } from 'src/app/models/interface/lesson.interface';
import { HelperService } from 'src/app/services/helper/helper.service';
import { ReminderStore } from 'src/app/store/reminder/component-store/reminder.store';
import { InterventionInstanceReminderInterface } from '../../../models/interface/intervention-instance-reminder.interface';

@Component({
  selector: 'app-dialog-intervention-instance-reminder-detail',
  templateUrl: './dialog-intervention-instance-reminder-detail.component.html',
  styleUrls: ['./dialog-intervention-instance-reminder-detail.component.scss'],
  providers: [ReminderStore]
})
export class DialogInterventionInstanceReminderDetailComponent implements OnInit {
  // Icons
  faBell = faBell;
  faCalendar = faCalendar;

  public studyId: number;
  public questionnaires: Array<LessonInterface> = [];

  public languages = ['de', 'en'];
  public selectedLanguage;
  public selectedLanguageSubject: BehaviorSubject<any> = new BehaviorSubject<any>('');

  public defaultReminderText;

  // Reminder form submission
  public reminderForm: UntypedFormGroup;

  public updateInterventionInstanceReminderResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');
  public deleteInterventionInstanceReminderResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');

  public isSubmitted$: Observable<boolean>;

  public displaySubject: BehaviorSubject<'DEFAULT' | 'UPDATE' | 'DELETE'> = new BehaviorSubject<'DEFAULT' | 'UPDATE' | 'DELETE'>('DEFAULT');

  public updateInterventionInstanceReminderResponse$: Observable<any>;
  public updateInterventionInstanceReminder$: Observable<any>;
  public deleteInterventionInstanceReminderResponse$: Observable<any>;
  public deleteInterventionInstanceReminder$: Observable<any>;

  public allReminderLogsOfStudy$: Observable<Array<InterventionInstanceReminderLogsInterface>>;

  public reminderLogs$: Observable<Array<InterventionInstanceReminderLogsInterface>>;
  public reminderLogsSubject: BehaviorSubject<Array<InterventionInstanceReminderLogsInterface>> = new BehaviorSubject<
    Array<InterventionInstanceReminderLogsInterface>
  >([]);
  public pagedReminderLogSubject: BehaviorSubject<Array<InterventionInstanceReminderLogsInterface>> = new BehaviorSubject<
    Array<InterventionInstanceReminderLogsInterface>
  >([]);
  public pagedReminderLog$: Observable<Array<InterventionInstanceReminderLogsInterface>> = this.pagedReminderLogSubject.asObservable();

  public unitList = ['minute', 'hour', 'day'];

  // Ngx datetimepicker
  public showSpinners = false;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate = new Date();
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';

  public reminder: InterventionInstanceReminderInterface;

  public isLoading$: Observable<boolean>;

  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private isSubmittedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<DialogInterventionInstanceReminderDetailComponent>,
    private helperService: HelperService,
    private reminderStore: ReminderStore,
    private httpClient: HttpClient
  ) {
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.selectedLanguageSubject.next(this.languages[0]);
    this.selectedLanguageSubject
      .pipe(
        distinctUntilChanged(),
        switchMap(language => this.httpClient.get(`../../../../assets/i18n/${language}.json`))
      )
      .subscribe(translation => {
        this.defaultReminderText = translation;
      });
    this.isSubmitted$ = this.isSubmittedSubject.asObservable();
    this.reminderForm = new UntypedFormGroup({
      starts_at: new UntypedFormControl('', [Validators.required]),
      max_times: new UntypedFormControl('', [Validators.required]),
      time_interval: new UntypedFormControl('', [Validators.required]),
      time_interval_unit: new UntypedFormControl(this.unitList[0]),
      content_text: new UntypedFormControl(''),
      content_ending: new UntypedFormControl('')
    });
    this.reminderForm.controls['starts_at'].setValue(new Date());
    this.reminderLogs$ = this.reminderLogsSubject.asObservable();

    this.updateInterventionInstanceReminderResponse$ = this.reminderStore.updateInterventionInstanceReminderResponse$;
    this.deleteInterventionInstanceReminderResponse$ = this.reminderStore.deleteInterventionInstanceReminderResponse$;
    this.allReminderLogsOfStudy$ = this.reminderStore.allReminderLogsOfStudy$;
    this.updateInterventionInstanceReminder$ = this.updateInterventionInstanceReminderResponse.asObservable();
    this.deleteInterventionInstanceReminder$ = this.deleteInterventionInstanceReminderResponse.asObservable();
  }

  public get f() {
    return this.reminderForm;
  }

  public get helper() {
    return this.helperService;
  }

  ngOnInit(): void {
    this.questionnaires = this.data.questionnaires;
    this.studyId = this.data.studyId;
    if (this.studyId) {
      this.reminderStore.getallReminderLogsOfStudy({
        studyId: this.studyId
      });
      this.subscriptions.push(
        this.allReminderLogsOfStudy$.pipe(skip(1), take(1)).subscribe((res: any) => {
          this.reminderLogsSubject.next(
            res
              .sort((a, b) => b.attributes.created_at - a.attributes.created_at)
              .filter(
                (log: InterventionInstanceReminderLogsInterface) =>
                  log.attributes.intervention_instance_id.toString() === this.reminder.attributes.model_id.toString()
              )
          );
          this.pagedReminderLogSubject.next(this.reminderLogsSubject.value.slice(0, 20));
          this.isLoadingSubject.next(false);
        })
      );
    }

    this.reminder = this.data.reminder;
    if (this.reminder) {
      const reminderConfig = this.reminder.attributes;
      this.f.get('starts_at').patchValue(new Date(this.helper.convertUnixTimestampToDate(reminderConfig.start)));
      this.f.get('max_times').patchValue(reminderConfig.maximum_reminders ? reminderConfig.maximum_reminders : '');
      this.f.get('time_interval').patchValue(reminderConfig.configuration.pause ? reminderConfig.configuration.pause : '');
      this.f.get('time_interval_unit').patchValue(reminderConfig.configuration.unit ? reminderConfig.configuration.unit : '');
      this.f.get('content_text').patchValue(reminderConfig.content?.text ? reminderConfig.content.text : '');
      this.f.get('content_ending').patchValue(reminderConfig.content?.ending ? reminderConfig.content.ending : '');
      if (reminderConfig.content?.locale) {
        this.selectedLanguage = reminderConfig.content?.locale;
      }
    }
  }

  public getReminderGreeting(language?): string {
    if (language) {
      this.selectedLanguageSubject.next(language);
    }
    if (this.defaultReminderText) {
      return this.defaultReminderText['intervention-instance-reminder']['default_reminder_message_head'];
    } else {
      return '';
    }
  }

  public getReminderMessage(language?): string {
    if (language) {
      this.selectedLanguageSubject.next(language);
    }
    if (this.defaultReminderText) {
      return this.defaultReminderText['intervention-instance-reminder']['default_reminder_message'];
    } else {
      return '';
    }
  }

  public getReminderEndingHead(language?): string {
    if (language) {
      this.selectedLanguageSubject.next(language);
    }
    if (this.defaultReminderText) {
      return this.defaultReminderText['intervention-instance-reminder']['default_reminder_ending_head'];
    } else {
      return '';
    }
  }

  public getReminderEnding(language?): string {
    if (language) {
      this.selectedLanguageSubject.next(language);
    }
    if (this.defaultReminderText) {
      return this.defaultReminderText['intervention-instance-reminder']['default_reminder_ending'];
    } else {
      return '';
    }
  }

  public setDisplay(value: 'DEFAULT' | 'UPDATE' | 'DELETE'): void {
    this.displaySubject.next(value);
  }

  public updateInterventionInstanceReminder(): void {
    this.isSubmittedSubject.next(true);
    if (this.reminderForm.invalid) {
      return;
    }

    const startsAt = this.helperService.convertStringToUnixTimestamp(
      this.f.get('starts_at').value || this.f.get('starts_at').value === '' ? this.f.get('starts_at').value : undefined
    );
    const maxTimes = this.f.get('max_times').value || this.f.get('max_times').value === '' ? this.f.get('max_times').value : undefined;
    const timeInterval =
      this.f.get('time_interval').value || this.f.get('time_interval').value === '' ? this.f.get('time_interval').value : undefined;
    const timeIntervalUnit =
      this.f.get('time_interval_unit').value || this.f.get('time_interval_unit').value === ''
        ? this.f.get('time_interval_unit').value
        : undefined;
    const contentText =
      this.f.get('content_text').value || this.f.get('content_text').value === '' ? this.f.get('content_text').value : undefined;
    const contentEnding =
      this.f.get('content_ending').value || this.f.get('content_ending').value === '' ? this.f.get('content_ending').value : undefined;

    if (this.updateInterventionInstanceReminderResponse.value === 'DEFAULT') {
      this.updateInterventionInstanceReminderResponse.next('LOADING');

      this.reminderStore.updateInterventionInstanceReminder({
        reminderId: this.reminder.id,
        receiverId: this.reminder.attributes.receiver_id,
        maxTimes,
        start: startsAt,
        repeatTime: timeInterval,
        repeatUnit: timeIntervalUnit,
        contentText,
        contentEnding,
        locale: this.selectedLanguage
      });

      this.subscriptions.push(
        this.updateInterventionInstanceReminderResponse$
          .pipe(
            filter(value => !!value),
            take(1)
          )
          .subscribe((value: any) => {
            if (value instanceof HttpResponse) {
              this.updateInterventionInstanceReminderResponse.next('SUCCESS');
              setTimeout(() => {
                this.updateInterventionInstanceReminderResponse.next('DEFAULT');
                this.dialogRef.close(value);
              }, 2500);
            } else {
              this.updateInterventionInstanceReminderResponse.next('FAILURE');
              setTimeout(() => {
                this.updateInterventionInstanceReminderResponse.next('DEFAULT');
              }, 2500);
            }
          })
      );
    }
  }

  public deleteInterventionInstanceReminder(): void {
    if (this.deleteInterventionInstanceReminderResponse.value === 'DEFAULT') {
      this.deleteInterventionInstanceReminderResponse.next('LOADING');

      this.reminderStore.deleteInterventionInstanceReminder({
        reminderId: this.reminder.id
      });

      this.subscriptions.push(
        this.deleteInterventionInstanceReminderResponse$
          .pipe(
            filter(value => !!value),
            take(1)
          )
          .subscribe((value: any) => {
            if (value instanceof HttpResponse) {
              this.deleteInterventionInstanceReminderResponse.next('SUCCESS');
              setTimeout(() => {
                this.deleteInterventionInstanceReminderResponse.next('DEFAULT');
                this.dialogRef.close(value);
              }, 2500);
            } else {
              this.deleteInterventionInstanceReminderResponse.next('FAILURE');
              setTimeout(() => {
                this.deleteInterventionInstanceReminderResponse.next('DEFAULT');
              }, 2500);
            }
          })
      );
    }
  }

  public isDisabled(): boolean {
    return (
      this.deleteInterventionInstanceReminderResponse.value !== 'DEFAULT' ||
      this.updateInterventionInstanceReminderResponse.value !== 'DEFAULT'
    );
  }

  public updatePagedReminderLogs(event: any) {
    if (event) {
      this.pagedReminderLogSubject.next(event);
    }
  }
}
