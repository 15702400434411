import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-button-feedback',
  templateUrl: './button-feedback.component.html',
  styleUrls: ['./button-feedback.component.css']
})
export class ButtonFeedbackComponent implements OnInit, OnDestroy {
  // Icon
  faCheck = faCheck;
  faTimes = faTimes;
  public icon;

  public buttonType = 'mat-button';

  public btnId;
  public defaultButtonText = '';
  public successButtonText = 'button-feedback.succeeded';
  public errorButtonText = 'button-feedback.failed';

  public width;

  public isDisabled = false;

  public isLoadingReset$: Observable<boolean>;

  public resetResponseSubject: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');
  private isLoadingResetSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
    this.isLoadingReset$ = this.isLoadingResetSubject.asObservable();
  }

  @Input()
  set _setMatButtonType(_setMatButtonType) {
    if (_setMatButtonType !== undefined) {
      this.buttonType = _setMatButtonType;
    }
  }

  @Input()
  set _setIcon(_setIcon) {
    this.icon = _setIcon;
  }

  @Input()
  set _setId(_setId) {
    if (_setId !== undefined) {
      this.btnId = _setId;
    }
  }

  @Input()
  set _setWidth(_setWidth) {
    if (_setWidth !== undefined) {
      this.width = _setWidth;
    }
  }

  @Input()
  set _successButtonText(_successButtonText) {
    if (_successButtonText !== undefined) {
      this.successButtonText = _successButtonText;
    }
  }

  @Input()
  set _errorButtonText(_errorButtonText) {
    if (_errorButtonText !== undefined) {
      this.errorButtonText = _errorButtonText;
    }
  }

  @Input()
  set _isDisabled(_isDisabled: boolean) {
    if (_isDisabled !== undefined) {
      this.isDisabled = _isDisabled;
    }
  }

  @Input()
  set _defaultButtonText(_defaultButtonText: string) {
    if (_defaultButtonText) {
      this.defaultButtonText = _defaultButtonText;
    }
  }

  @Input()
  set _responseText(_responseText: string) {
    if (_responseText) {
      this.resetResponseSubject.next(_responseText);
    }
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
