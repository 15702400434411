<div class="container-fluid text-center">
  <div class="col-sm-8 text-start">
    <h2>{{ 'group-task.tasks' | translate }}</h2>
    <hr />
  </div>

  <!-- Spinner -->
  <div *ngIf="isLoading$ | async; else elseTaskBlock">
    <app-loader></app-loader>
  </div>

  <ng-template #elseTaskBlock>
    <div *ngIf="(tasks$ | async)?.length === 0; else elseShowTasksBlock">
      <p class="centering">{{ 'group-task.no_tasks_found' | translate }}</p>
    </div>

    <!-- Table -->
    <ng-template #elseShowTasksBlock>
      <div class="mat-elevation-z0 text-start mb-3">
        <table (matSortChange)="onChangeSortTable($event)" [dataSource]="dataSource" mat-table matSort>
          <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

          <!-- Activity_text Column -->
          <ng-container matColumnDef="activity_text">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>{{'group-task.task' | translate}}</th>
            <td *matCellDef="let activity" mat-cell>
              {{helperActivity.translateActivityToTask(activity, activity.relationships?.questionnaires.data) | async}}
              <span *ngIf="activity.attributes.action === 'ANSWERSHEET_SUBMITTED'"
                >: {{helperActivity.getLessonNameOfActivity(activity, activity.relationships?.questionnaires.data)}}</span
              >
            </td>
          </ng-container>

          <!-- Performed_by Column -->
          <ng-container matColumnDef="performed_by">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>{{'group-task.task_for' | translate}}</th>
            <td *matCellDef="let activity" mat-cell>
              {{helper.findArrObjById(activity.attributes.user, users) !== null ? helper.getCodeNameEmail(activity.attributes.user, users,
              studyId) : ('task.system' | translate)}}
            </td>
          </ng-container>

          <!-- Created_at Column -->
          <ng-container matColumnDef="created_at">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>{{'group-task.created_at' | translate}}</th>
            <td *matCellDef="let activity" mat-cell>{{helper.localizeDateString(activity.attributes.created_at)}}</td>
          </ng-container>

          <!-- Status Column -->
          <ng-container matColumnDef="status">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>{{'group-task.status' | translate}}</th>
            <td *matCellDef="let activity" mat-cell>
              <!-- Answersheet status -->
              <div *ngIf="activity.attributes.action === 'ANSWERSHEET_SUBMITTED'; then thenShowAnswersheetStatus"></div>
              <ng-template #thenShowAnswersheetStatus>
                <div class="my-3">
                  <div *ngIf="getStatus(activity) !== null; then thenEvaluateStatus else elseNoStatus"></div>
                  <!-- Evaluate answersheet status -->
                  <ng-template #thenEvaluateStatus>
                    <div *ngIf="getStatus(activity); then thenFeedbackSubmitted else elseFeedbackRequired"></div>
                    <ng-template #thenFeedbackSubmitted>
                      <p>
                        {{'group-task.task_complete' | translate}}
                        <fa-icon [icon]="faCheck" class="fa-fw green-icon"></fa-icon>
                      </p>
                      <button [attr.id]="'btnShowAnswersheet_' + activity.id" (click)="showAnswersheet(activity)" mat-button>
                        {{'group-task.to_feedback' | translate}}
                        <fa-icon [icon]="faComments"></fa-icon>
                      </button>
                    </ng-template>
                    <ng-template #elseFeedbackRequired>
                      <p>
                        {{'group-task.task_pending' | translate}}
                        <fa-icon [icon]="faTimes" class="fa-fw red-icon"></fa-icon>
                      </p>
                      <button [attr.id]="'btnShowAnswersheet2_' + activity.id" (click)="showAnswersheet(activity)" mat-button>
                        {{'group-task.task_provide_feedback' | translate}}
                        <fa-icon [icon]="faPlusCircle"></fa-icon>
                      </button>
                    </ng-template>
                  </ng-template>
                  <!-- No answersheet status available -->
                  <ng-template #elseNoStatus>
                    <p>
                      {{'task.deleted' | translate}}
                      <fa-icon [icon]="faMinusCircle"></fa-icon>
                    </p>
                  </ng-template>
                </div>
              </ng-template>

              <!-- Unlock questionnaire status -->
              <div *ngIf="activity.attributes.action === 'INTERVENTION_INSTANCE_CAN_UNLOCK'; then thenShowUnlockStatus"></div>
              <ng-template #thenShowUnlockStatus>
                <div class="my-3">
                  <div
                    *ngIf="(activity.attributes.finished.toString() === '1' || activity.attributes.finished.toString() === '' || activity.attributes.finished.toString() === 'completed'); then thenFinishedUnlock else elseUnfinishedUnlock"
                  ></div>
                  <!-- Evaluate finish unlock status -->
                  <ng-template #thenFinishedUnlock>
                    <p>
                      {{'group-task.task_complete' | translate}}
                      <fa-icon [icon]="faCheck" class="fa-fw green-icon"></fa-icon>
                    </p>
                    <button
                      [attr.id]="'btnopenDialogInterventionInstanceDetails_' + activity.id"
                      (click)="openDialogInterventionInstanceDetails(activity)"
                      mat-button
                    >
                      {{'group-task.task_show_instance_details' | translate}}
                      <fa-icon [icon]="faComments"></fa-icon>
                    </button>
                  </ng-template>
                  <ng-template #elseUnfinishedUnlock>
                    <p>
                      {{'group-task.task_pending' | translate}}
                      <fa-icon [icon]="faTimes" class="fa-fw red-icon"></fa-icon>
                    </p>
                    <button
                      [attr.id]="'btnopenDialogInterventionInstanceDetails2_' + activity.id"
                      (click)="openDialogInterventionInstanceDetails(activity)"
                      mat-button
                    >
                      {{'group-task.task_unlock_lesson' | translate}}
                      <fa-icon [icon]="faPlusCircle"></fa-icon>
                    </button>
                  </ng-template>
                </div>
              </ng-template>
            </td>
          </ng-container>

          <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
          <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
        </table>
        <mat-paginator
          (page)="onPageChangeActivity($event)"
          [length]="(tasks$ | async)?.length"
          [showFirstLastButtons]="true"
          [pageSizeOptions]="[10, 20, 50]"
          [pageSize]="20"
        ></mat-paginator>
      </div>
    </ng-template>
  </ng-template>
</div>
