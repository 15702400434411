<div class="container-fluid text-center">
  <div class="col-sm-8 text-start">
    <h2>{{ 'patient-diary.diaries' | translate }}</h2>
    <hr />
  </div>

  <app-pagination-list
    #paginator
    (emitPageChange)="updatePagedDiaries($event)"
    [_isLoading]="isLoading$ | async"
    [_items]="instanceDiaryPairs$ | async"
  ></app-pagination-list>

  <div class="row justify-content-center">
    <div *ngIf="(isLoading$ | async); else elseInterventionBlock" class="col-sm">
      <app-loader></app-loader>
    </div>

    <ng-template #elseInterventionBlock>
      <div class="row justify-content-center col-sm-8">
        <div *ngFor="let content of pagedInstanceDiaryPair$ | async; let i = index" class="col-12 col-lg-6">
          <app-card-diary [_diary]="content.diary" [_diaryInstance]="content.diary_instance"></app-card-diary>
        </div>
      </div>
    </ng-template>
  </div>
</div>
