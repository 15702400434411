<section class="hide_overflow">
  <div class="row mt-3 justify-content-center">
    <div class="col-12 col-sm-6 mb-2">
      <mat-form-field [style.width.%]="100" appearance="outline">
        <mat-label>{{'organisation.organisation_search' | translate}}</mat-label>
        <input
          (keyup.enter)="applyOrganisationFilter()"
          [(ngModel)]="filter['searchFilter']"
          aria-describedby="organisationSearch"
          aria-label="Search"
          matInput
          type="text"
        />
        <button [attr.id]="'btnSearchOrganisation'" (click)="applyOrganisationFilter()" aria-label="Search" mat-button mat-button matSuffix>
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>

  <app-pagination-list
    #paginator
    (emitPageChange)="updatePagedOrganisations($event)"
    [_isLoading]="isLoading$ | async"
    [_items]="organisationsSubject | async"
  ></app-pagination-list>

  <div class="row justify-content-center">
    <div *ngIf="(isLoading$ | async); else elseGroupsBlock" class="col-md">
      <app-loader></app-loader>
    </div>

    <ng-template #elseGroupsBlock>
      <div [ngClass]="{'col-12': (isCollapsed$ | async) === false, 'col-lg-9': (isCollapsed$ | async) === false}" class="row col-sm-10">
        <div
          *ngFor="let organisation of pagedOrganisations | async; let i = index; trackBy: helper.trackByElement"
          class="col-12 col-lg-4 justify-content-center"
        >
          <app-card-organisation
            [_organisation]="organisation"
            [_includedUsers]="(organisationsCollaborators$ | async)"
            [_isCollab]="isCollabId(organisation)"
            [_isAdmin]="false"
          ></app-card-organisation>
        </div>
      </div>
    </ng-template>
  </div>
</section>
