import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserInterface } from '../../../models/interface/user.interface';

@Injectable({
  providedIn: 'root'
})
export class PatientSharedService {
  public sourceId$: Observable<number>;
  public patient$: Observable<UserInterface>;
  public sourceName$: Observable<string>;
  public activeChildroute$: Observable<string>;

  public activeChildrouteSubject = new BehaviorSubject<string>(null);

  private sourceIdSubject = new BehaviorSubject<number>(null);
  private sourcePatientSubject = new BehaviorSubject<UserInterface>(null);
  private sourceNameSubject = new BehaviorSubject<string>(null);

  constructor() {
    this.sourceId$ = this.sourceIdSubject.asObservable();
    this.patient$ = this.sourcePatientSubject.asObservable();
    this.sourceName$ = this.sourceNameSubject.asObservable();
    this.activeChildroute$ = this.activeChildrouteSubject.asObservable();
  }

  pushId(id: number) {
    this.sourceIdSubject.next(id);
  }

  pushPatient(user: UserInterface) {
    this.sourcePatientSubject.next(user);
  }

  pushName(name: string) {
    this.sourceNameSubject.next(name);
  }

  pushActiveChild(name: string) {
    this.activeChildrouteSubject.next(name);
  }
}
