import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { AnnouncementInterface } from '../../../models/interface/announcement.interface';
import { AnnouncementActionTypes } from '../../../store/announcement/announcement.action';
import { getAnnouncementsByLatest } from '../../../store/announcement/announcement.selector';
import { Store } from '@ngrx/store';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit, OnChanges, OnDestroy {
  // Icons
  faArrowLeft = faArrowLeft;
  faArrowRight = faArrowRight;

  public show = false;
  public announcements: Array<AnnouncementInterface> = [];
  public selectedAnnouncement: AnnouncementInterface;

  public isAuthenticated$: Observable<boolean>;
  public announcements$: Observable<Array<AnnouncementInterface>>;

  private index = 0;

  private subscriptions: Subscription[] = [];

  constructor(
    private store: Store<{ isAuthenticated: boolean; getAnnouncementsByLatest: Array<AnnouncementInterface> }>,
    private translateService: TranslateService
  ) {
    this.isAuthenticated$ = this.store.select('isAuthenticated');
    this.announcements$ = this.store.select(getAnnouncementsByLatest);
  }

  @Input() set _showBanner(_showBanner) {
    if (_showBanner !== undefined) {
      if (_showBanner !== null) {
        localStorage.setItem('showBanner', 'true');
      }
      this.show = localStorage.getItem('showBanner') === 'true';
    }
  }

  ngOnInit(): void {
    const currentDate = Math.round(Date.now() / 1000);
    this.subscriptions.push(
      this.isAuthenticated$.subscribe(value => {
        this.show = localStorage.getItem('showBanner') === 'true';
        if (value) {
          this.store.dispatch({
            type: AnnouncementActionTypes.getAnnouncementsType,
            payload: { client: 'ecoach', operatorStart: '<', start: currentDate, operatorEnd: '>', end: currentDate }
          });
        }
      })
    );
    this.subscriptions.push(
      this.announcements$.subscribe((announcements: Array<AnnouncementInterface>) => {
        this.announcements = announcements;
        if (this.announcements.length > 0) {
          this.selectedAnnouncement = this.announcements[0];
        }
      })
    );

    this.subscriptions.push(
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
        this.store.dispatch({
          type: AnnouncementActionTypes.getAnnouncementsType,
          payload: { client: 'ecoach', operatorStart: '<', start: currentDate, operatorEnd: '>', end: currentDate }
        });
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    const currentItem: SimpleChange = changes._showBanner;
    if ((currentItem.previousValue === undefined && currentItem.currentValue) || currentItem.previousValue !== undefined) {
      const currentDate = Math.round(Date.now() / 1000);
      this.store.dispatch({
        type: AnnouncementActionTypes.getAnnouncementsType,
        payload: { client: 'ecoach', operatorStart: '<', start: currentDate, operatorEnd: '>', end: currentDate }
      });
    }
  }

  public onClick(pos: 'next' | 'previous'): void {
    if (pos === 'previous') {
      this.index = this.index > 0 ? this.index - 1 : this.announcements.length - 1;
    }
    if (pos === 'next') {
      this.index = this.index < this.announcements.length - 1 ? this.index + 1 : 0;
    }
    this.selectedAnnouncement = this.announcements[this.index];
  }

  public hideBanner(): void {
    localStorage.setItem('showBanner', 'false');
    this.show = localStorage.getItem('showBanner') === 'true';
  }

  public hasPreviousAnnouncement(): boolean {
    return this.index !== 0;
  }

  public hasNextAnnouncement(): boolean {
    return this.index !== this.announcements.length - 1;
  }

  public displayIndexAnnouncement(): number {
    return this.index;
  }

  sortByLatestStart(announcements: Array<AnnouncementInterface>): Array<AnnouncementInterface> {
    return announcements.sort((a, b) => parseInt(b.attributes.start.toString(), 10) - parseInt(a.attributes.start.toString(), 10));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
