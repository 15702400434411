<mat-card appearance="outlined" [attr.id]="'study_' + study.id" class="card mx-auto mb-3">
  <mat-card-header class="card-header-center">
    <mat-card-title>
      <span> {{((study.attributes.title) ? study.attributes.title : 'group.no_group_title') | translate}}</span>
    </mat-card-title>
    <mat-card-subtitle>
      {{((study.attributes.name) ? study.attributes.name : 'group.no_group_name') | translate}} (ID - {{study.id}})
    </mat-card-subtitle>
  </mat-card-header>

  <div *ngIf="patient" class="row justify-content-center mb-1">
    <p class="text-info">{{'group.assigned_study_code' | translate}}: <strong>{{getStudyCodeFromStudy()}}</strong></p>
  </div>

  <div class="row justify-content-center">
    <img
      [src]="helper.getAllMediaSupportPath(study.attributes.picture, study.id)"
      (error)="helper.getFallbackImage($event)"
      alt="No image"
      class="img-fluid card-img-top col-6"
      mat-card-image
    />
  </div>

  <mat-card-content>
    <p class="mb-2">{{((study.attributes.description) ? study.attributes.description : 'group.no_group_description') | translate}}</p>

    <p class="mb-2" *ngIf="study.attributes.organisation_id">
      <span></span>{{'group.assigned_to_organisation' | translate }}
      <strong
        >{{helper.findArrObjById(study.attributes.organisation_id, organisations)?.attributes.name}} (ID
        {{study.attributes.organisation_id}})</strong
      >
    </p>
    <p class="mb-2">
      <span *ngIf="(study.attributes.type === 'study')"><fa-icon [icon]="faUsers"></fa-icon> {{'group.study' | translate}}</span>
      <span *ngIf="(study.attributes.type === 'organisation')"
        ><fa-icon [icon]="faSitemap"></fa-icon> {{'group.organisation' | translate}}</span
      >
      <span *ngIf="(study.attributes.type === 'workgroup')"
        ><fa-icon [icon]="faBriefcase"></fa-icon> {{'group.workgroup' | translate}}</span
      >
      /
      <span *ngIf="(study.attributes.is_private === 1); else openBlock" class="text-padding">
        <span *ngIf="(study.attributes.accesstype === 'invite')">
          <fa-icon [icon]="faEnvelopeOpenText"></fa-icon>
          {{'group.invitations_only' | translate}}
        </span>
        <span *ngIf="(study.attributes.accesstype === 'password')">
          <fa-icon [icon]="faKey"></fa-icon>
          {{'group.password_only' | translate}}
        </span>
        <span *ngIf="(study.attributes.accesstype !== 'password') && (study.attributes.accesstype !== 'invite')"
          >{{'group.private' | translate}}
        </span>
      </span>
      <ng-template #openBlock> {{'group.public' | translate}} </ng-template>
    </p>

    <p class="mb-2">
      <span *ngIf="isCollabId(study.id)"> {{'group.joined' | translate}} <fa-icon [icon]="faCheckSquare"></fa-icon></span>
    </p>

    <p class="mb-2" *ngIf="study.relationships?.owners?.data[0]">
      <fa-icon [icon]="faStar"></fa-icon>
      {{'group.owner' | translate}}: {{study.relationships?.owners?.data[0]?.attributes?.email}}
    </p>
  </mat-card-content>
  <mat-divider *ngIf="isCollabId(study.id)"></mat-divider>
  <mat-card-actions [align]="'end'">
    <div *ngIf="(isCollabId(study.id)); then thenShowCollabButtons else elseShowDetailsButton"></div>
    <ng-template #thenShowCollabButtons>
      <button
        [attr.id]="'btnGetDetailedStudy' + study.id"
        *ngIf="(study.attributes.type === 'study')"
        (click)="getDetailedStudy(study.id)"
        class="spacing-right"
        mat-button
      >
        Details
      </button>
      <button
        [attr.id]="'btnGetPatientOverview' + study.id"
        (click)="getPatientOverview()"
        *ngIf="(study.attributes.type === 'study') && (this.helper.getHighestRoleOfStudy(study) !== 'study.access')"
        mat-button
        class="spacing-right"
      >
        <fa-icon [icon]="faPlus"></fa-icon>
        {{'group.invite_member' | translate}}
      </button>

      <button
        [attr.id]="'btnOpenDialogGroupCollaboratorOrganisationManager' + study.id"
        (click)="openDialogGroupCollaboratorOrganisationManager(study)"
        *ngIf="(study.attributes.type === 'study') && (isOrganisationCollaborator$ | async)"
        class="spacing-right"
        mat-button
      >
        {{'organisation-study.study-collaborator-management' | translate}}
      </button>
      <!---
      <button
        [attr.id]="'btnOpenDialogAssignStudy' + study.id"
        *ngIf="(study.attributes.type === 'organisation') && isCollabId(study.id) && (study.attributes.organisation_id === null) && (isAdmin$ | async)"
        (click)="openDialogAssignStudy(study)"
        class="spacing-right"
        mat-button
      >
    -->
      <button
        [attr.id]="'btnOpenDialogAssignStudy' + study.id"
        *ngIf="(study.attributes.type === 'study') && (study.attributes.organisation_id === null) && (isAdmin$ | async)"
        (click)="openDialogAssignStudy(study)"
        class="spacing-right"
        mat-button
      >
        {{'group.assign_study' | translate}}
      </button>
      <button
        [attr.id]="'btnGetDetailedOrganisation_' + study.id"
        *ngIf="study.attributes.organisation_id"
        (click)="getDetailedOrganisation(study.attributes.organisation_id)"
        class="spacing-right"
        mat-button
      >
        {{'group.to_organisation' | translate}}
      </button>
    </ng-template>
    <ng-template #elseShowDetailsButton>
      <button
        [attr.id]="'btnOpenDialogGroupCollaboratorOrganisationManager_' + study.id"
        (click)="openDialogGroupCollaboratorOrganisationManager(study)"
        *ngIf="(isOrganisationCollaborator$ | async)"
        class="spacing-right"
        mat-button
      >
        {{'organisation-study.study-collaborator-management' | translate}}
      </button>
    </ng-template>
    <button
      [attr.id]="'btnOpenDialogGroupUpdateAdmin_' + study.id"
      class="spacing-right"
      (click)="openDialogStudyUpdateAdmin(study)"
      mat-button
      *ngIf="(isAdmin$ | async) && showAdmin"
    >
      <fa-icon [icon]="faPen"></fa-icon> {{'group.edit' | translate}}
    </button>

    <button
      [attr.id]="'btnOpenDialogGroupDeleteAdmin_' + study.id"
      class="spacing-right"
      (click)="openDialogStudyDeleteAdmin(study)"
      mat-button
      *ngIf="(isAdmin$ | async) && showAdmin"
    >
      <fa-icon [icon]="faTrash"></fa-icon> {{'group.delete' | translate}}
    </button>
  </mat-card-actions>
</mat-card>
