<div class="card my-3">
  <div class="card-body">
    <div class="col-12 my-3">
      <app-patient-creation-overview (patientCreationEvent)="reloadPage($event)" #patientCreationOverview></app-patient-creation-overview>
    </div>

    <div class="row justify-content-center">
      <div class="col-12 col-sm-3 mb-2">
        <button (click)="helper.toggleSubject(isCollapsedSubject)" class="mt-1" mat-raised-button type="submit">
          <fa-icon [icon]="faList"></fa-icon>
          Filter
          <ng-container *ngIf="(isCollapsed$ | async) === false; then thenShowUp else elseShowDown"></ng-container>
          <ng-template #thenShowUp>
            <fa-icon [icon]="faChevronUp"></fa-icon>
          </ng-template>
          <ng-template #elseShowDown>
            <fa-icon [icon]="faChevronDown"></fa-icon>
          </ng-template>
        </button>
      </div>

      <div class="col-12 col-md-6 mb-2">
        <mat-form-field [style.width.%]="100" appearance="outline">
          <mat-label>{{'patient.search_user' | translate}}</mat-label>
          <input
            [attr.id]="'search'"
            (keyup.enter)="applyUserFilter()"
            [(ngModel)]="this.filter['searchFilter']"
            [disabled]="(isLoading$ | async)"
            aria-describedby="userSearch"
            aria-label="Search"
            matInput
            type="text"
          />
          <button [attr.id]="'btnSearchUser'" (keyup.enter)="applyUserFilter()" aria-label="Search" mat-button mat-button matSuffix>
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>

    <div *ngIf="(isCollapsed$ | async) === false">
      <mat-divider></mat-divider>
      <div class="row my-3">
        <div class="col-12 col-md-6">
          <mat-form-field appearance="outline">
            <mat-label>{{'patient.sort_by' | translate}}</mat-label>
            <mat-select (selectionChange)="applyUserFilter()" [(value)]="filter['selectedSort']" required>
              <mat-option>{{'patient.select_sorting' | translate}}</mat-option>
              <mat-option *ngFor="let type of sortSelection" [value]="type">{{('patient.' + type.toLowerCase()) | translate}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12 col-md-6">
          <mat-form-field appearance="outline">
            <mat-label>{{'patient.filter_verification_by' | translate}}</mat-label>
            <mat-select (selectionChange)="applyUserFilter()" [(value)]="filter['selectedVerified']" required>
              <mat-option *ngFor="let type of filterVerified" [value]="type">{{('patient.' + type.toLowerCase()) | translate}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12 col-md-6">
          <button mat-raised-button (click)="resetFilter()">{{'patient.filter_reset' | translate}}</button>
        </div>
      </div>

      <mat-divider></mat-divider>
    </div>

    <app-pagination-list
      #paginator
      (emitPageChange)="updatePagedPatients($event)"
      [_isLoading]="isLoading$ | async"
      [_items]="patients$ | async"
    ></app-pagination-list>

    <div *ngIf="(isLoading$ | async); else elsePatientsBlock">
      <app-loader></app-loader>
    </div>

    <ng-template #elsePatientsBlock>
      <div class="mt-1">
        <div class="row">
          <div *ngIf="(isLoading$ | async)" class="col-sm">
            <app-loader></app-loader>
          </div>

          <div *ngIf="(isLoading$ | async) === false" class="col-sm">
            <div class="row">
              <div *ngFor="let patient of pagedPatients$ | async; let i = index; trackBy: helper.trackByElement" class="col-12 mb-2">
                <app-card-user
                  [_user]="patient"
                  [_userAsPatient]="true"
                  (emitDelete)="updateList()"
                  (emitUpdate)="updateList()"
                  (emitReloadInvitations)="updateInvitations()"
                ></app-card-user>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
