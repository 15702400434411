import { Action, createAction, props } from '@ngrx/store';

export enum LessonLocaleActionTypes {
  updateLessonLocaleStoreType = 'Update LessonLocale Store',
  deleteIsAuthenticatedStoreType = 'Delete LessonLocale Store',
  setLessonLocaleType = 'Set LesonLocale'
}

export const updateLessonLocaleStore = createAction(LessonLocaleActionTypes.updateLessonLocaleStoreType, props<{ lessonLocale: any }>());
export const removeLessonLocaleStore = createAction(LessonLocaleActionTypes.deleteIsAuthenticatedStoreType);

export class SetLessonLocaleAction implements Action {
  readonly type: string = LessonLocaleActionTypes.setLessonLocaleType;
  constructor(readonly payload: any) {}
}
