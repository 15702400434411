<button [attr.id]="'btnDialogClose'" mat-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title>{{'admin-dashboard.update_account' | translate}}</h1>
<div mat-dialog-content class="mat-typography">
  <p>{{'admin-dashboard.update_account_roles' | translate}}: <strong>{{user.attributes.email}}</strong></p>

  <section class="checkbox-section">
    <mat-checkbox [attr.id]="'checkbox_admin'" class="checkbox-margin" [(ngModel)]="adminChecked"
      >{{'admin-dashboard.admin_only' | translate}}</mat-checkbox
    >
    <mat-checkbox [attr.id]="'checkbox_ecoach'" class="checkbox-margin" [(ngModel)]="ecoachChecked"
      >{{'admin-dashboard.ecoach_only' | translate}}</mat-checkbox
    >
    <mat-checkbox [attr.id]="'checkbox_editor'" class="checkbox-margin" [(ngModel)]="editorChecked"
      >{{'admin-dashboard.editor_only' | translate}}</mat-checkbox
    >
  </section>
</div>
<div mat-dialog-actions align="end">
  <button [attr.id]="'btnCancel'" mat-button mat-dialog-close>{{'admin-dashboard.cancel' | translate}}</button>
  <app-button-feedback
    [_setId]="'btnUpdateAccount'"
    (click)="updateAccount()"
    [_defaultButtonText]="'admin-dashboard.update_account_confirm'"
    [_responseText]="updateAccountResponse | async"
  ></app-button-feedback>
</div>
