import { createReducer, on } from '@ngrx/store';
import {
  addAnswersheetsOfInterventionInstancesStore,
  updateAnswersheetsOfInterventionInstancesStore,
  removeAnswersheetsOfInterventionInstancesStore
} from './answersheet.action';

import { AnswersheetInterface } from 'src/app/models/interface/answersheet.interface';

export const initialAnswersheetsOfInterventionInstancesState: Array<AnswersheetInterface> = [];

export const answersheetsOfInterventionInstancesReducer = createReducer(
  initialAnswersheetsOfInterventionInstancesState,
  on(addAnswersheetsOfInterventionInstancesStore, (state, { answersheetsOfInterventionInstances }) => answersheetsOfInterventionInstances),
  on(
    updateAnswersheetsOfInterventionInstancesStore,
    (state, { answersheetsOfInterventionInstances }) => answersheetsOfInterventionInstances
  ),
  on(removeAnswersheetsOfInterventionInstancesStore, (state, {}) => [])
);
