import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-patient-verification-resend',
  templateUrl: './dialog-patient-verification-resend.component.html',
  styleUrls: ['./dialog-patient-verification-resend.component.scss']
})
export class DialogPatientVerificationResendComponent implements OnInit {
  public submitted;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<DialogPatientVerificationResendComponent>) {}

  ngOnInit(): void {
    this.submitted = this.data.submitted;
  }
}
