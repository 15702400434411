<button [attr.id]="'btnDialogClose'" mat-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title>{{'group-member.modal_title_check_invitations' | translate}}</h1>
<div class="content-height" mat-dialog-content>
  <!-- Spinner -->
  <div *ngIf="(isLoading$ | async); else elseInvitationBlock">
    <app-loader></app-loader>
  </div>

  <ng-template #elseInvitationBlock>
    <app-pagination-list
      #paginator
      (emitPageChange)="updatePagedInvitations($event)"
      [_isLoading]="isLoading$ | async"
      [_items]="pendingInvitations$ | async"
    ></app-pagination-list>
    <div *ngIf="(pagedInvitationsSubject | async).length > 0; then thenShowInvitations else elseShowNoInvitations"></div>
    <ng-template #thenShowInvitations>
      <div *ngFor="let invitation of (pagedInvitationsSubject | async); let i = index">
        <app-card-pending-invitation
          [_invitation]="invitation"
          [_studyCode]="pendingInvitationCode[i]"
          [_isManager]="isManager"
          [_setStudies]="[study]"
          (emitDelete)="removeInvitation(i)"
          (emitUpdate)="updateInvitation(i, invitation)"
        ></app-card-pending-invitation>
      </div>
    </ng-template>
    <ng-template #elseShowNoInvitations>
      <p>{{'patient.no_pending_invitations' | translate}}</p>
    </ng-template>
  </ng-template>
</div>
