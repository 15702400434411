import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { HttpErrorResponseMessage } from 'src/app/enum/http-error-response-message';
import { AnswersheetService } from '../../services/api/answersheet/answersheet.service';
import { addAnswersheetsOfInterventionInstancesStore, AnswersheetActionTypes } from './answersheet.action';
import { ResponseSuccess } from '../utils/response-success';
import { ResponseError } from '../utils/response-error';

@Injectable()
export class AnswersheetEffects {
  getAnswersheetsOfAllInterventionInstances$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnswersheetActionTypes.getAnswersheetsOfAllInterventionInstancesType),
      switchMap(() =>
        this.answersheetService.getAllAnswersheetsOfAllEcoachInterventionInstances().pipe(
          switchMap(result => {
            const actions = [];
            actions.push(addAnswersheetsOfInterventionInstancesStore({ answersheetsOfInterventionInstances: result.body.data }));
            actions.push(new ResponseSuccess(AnswersheetActionTypes.getAnswersheetsOfAllInterventionInstancesSuccessType, result));
            return actions;
          }),
          catchError(err =>
            of(
              new ResponseError(AnswersheetActionTypes.getAnswersheetsOfAllInterventionInstancesErrorType, {
                message: HttpErrorResponseMessage.genericUnknownError,
                content: err
              })
            )
          )
        )
      )
    )
  );

  constructor(private actions$: Actions, private answersheetService: AnswersheetService) {}
}
