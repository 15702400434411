import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { catchError, EMPTY, mergeMap, Observable, switchMap, tap, throwError } from 'rxjs';
import { MessageThreadsInterface } from '../../../models/interface/message_threads.interface';
import { MessageService } from '../../../services/api/message/message.service';
import { UserInterface } from '../../../models/interface/user.interface';
import { MessageMessageInterface } from '../../../models/interface/message_message.interface';

export interface MessagesState {
  inboxFolder: MessageThreadsInterface[];
  inboxFolderAnswersheet: MessageThreadsInterface[];
  threadDetails: MessageThreadsInterface | null;
  getThreadDetailsResponse: any;
  startThreadResponse: any;
  sendMessageResponse: any;
  addParticipantResponse: any;
  updateMessageResponse: any;
  allThreadsOfInstance: MessageThreadsInterface[];
  threadOfInstanceEM: MessageThreadsInterface | null;
}

@Injectable()
export class MessageStore extends ComponentStore<MessagesState> {
  readonly inboxFolder$: Observable<MessageThreadsInterface[]> = this.select(state => state.inboxFolder, {
    debounce: true
  });
  readonly inboxFolderAnswersheet$: Observable<MessageThreadsInterface[]> = this.select(state => state.inboxFolderAnswersheet, {
    debounce: true
  });
  readonly threadDetails$: Observable<any> = this.select(state => state.threadDetails, { debounce: true });
  readonly getThreadDetailsResponse$: Observable<any> = this.select(state => state.getThreadDetailsResponse, { debounce: true });
  readonly startThreadResponse$: Observable<any> = this.select(state => state.startThreadResponse, { debounce: true });
  readonly sendMessageResponse$: Observable<any> = this.select(state => state.sendMessageResponse, { debounce: true });
  readonly addParticipantResponse$: Observable<any> = this.select(state => state.addParticipantResponse, { debounce: true });
  readonly updateMessageResponse$: Observable<any> = this.select(state => state.updateMessageResponse, { debounce: true });
  readonly allThreadsOfInstance$: Observable<any> = this.select(state => state.allThreadsOfInstance, { debounce: true });
  readonly threadOfInstanceEM$: Observable<any> = this.select(state => state.threadOfInstanceEM, {
    debounce: true
  });

  readonly updateMessageState = this.updater(
    (
      state,
      payload: {
        inboxFolder?: MessageThreadsInterface[];
        inboxFolderAnswersheet?: MessageThreadsInterface[];
        threadDetails?: MessageThreadsInterface | null;
        getThreadDetailsResponse?: any;
        startThreadResponse?: any;
        sendMessageResponse?: any;
        addParticipantResponse?: any;
        updateMessageResponse?: any;
        allThreadsOfInstance?: MessageThreadsInterface[];
        allThreadsOfInstanceMessages?: MessageMessageInterface[];
        allThreadsOfInstanceParticipants?: UserInterface[];
        threadOfInstanceEM?: MessageThreadsInterface | null;
      }
    ) => ({
      inboxFolder: payload.inboxFolder ? payload.inboxFolder : state.inboxFolder,
      inboxFolderAnswersheet: payload.inboxFolderAnswersheet ? payload.inboxFolderAnswersheet : state.inboxFolderAnswersheet,
      threadDetails: payload.threadDetails ? payload.threadDetails : state.threadDetails,
      getThreadDetailsResponse: payload.getThreadDetailsResponse ? payload.getThreadDetailsResponse : state.getThreadDetailsResponse,
      startThreadResponse: payload.startThreadResponse ? payload.startThreadResponse : state.startThreadResponse,
      sendMessageResponse: payload.sendMessageResponse ? payload.sendMessageResponse : state.sendMessageResponse,
      addParticipantResponse: payload.addParticipantResponse ? payload.addParticipantResponse : state.addParticipantResponse,
      updateMessageResponse: payload.updateMessageResponse ? payload.updateMessageResponse : state.updateMessageResponse,
      allThreadsOfInstance: payload.allThreadsOfInstance ? payload.allThreadsOfInstance : state.allThreadsOfInstance,
      threadOfInstanceEM: payload.threadOfInstanceEM ? payload.threadOfInstanceEM : state.threadOfInstanceEM
    })
  );

  readonly getInboxFolder = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) =>
        this.messageService
          .getInboxFolder(payload.operator, payload.answersheetId, payload.include, payload.subject, payload.answersheetIdFlag)
          .pipe(
            tap({
              next: (result: any) => {
                if (!!payload.answersheetIdFlag) {
                  this.updateMessageState({ inboxFolderAnswersheet: result.body.data });
                } else {
                  this.updateMessageState({ inboxFolder: result.body.data });
                }
              },
              error: e => throwError(e)
            }),
            catchError(error => EMPTY)
          )
      )
    )
  );

  readonly getThreadDetails = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) => {
        this.updateMessageState({ threadDetails: null });
        this.updateMessageState({ getThreadDetailsResponse: null });
        return this.messageService.getThreadDetails(payload.threadId, payload.include).pipe(
          tap({
            next: (result: any) => {
              this.updateMessageState({ threadDetails: result.body.data });
              this.updateMessageState({ getThreadDetailsResponse: result });
            },
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateMessageState({ threadDetails: null });
            this.updateMessageState({ getThreadDetailsResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly startThread = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) => {
        this.updateMessageState({ startThreadResponse: null });
        return this.messageService.startThread(payload.subject, payload.answersheetId, payload.interventionInstanceId).pipe(
          tap({
            next: (result: any) => {
              this.updateMessageState({ startThreadResponse: result });
            },
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateMessageState({ startThreadResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly startThreadAsECoach = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) => {
        this.updateMessageState({ startThreadResponse: null });
        return this.messageService.startThreadAsECoach(payload.subject, payload.answersheetId, payload.interventionInstanceId).pipe(
          tap({
            next: (result: any) => {
              this.updateMessageState({ startThreadResponse: result });
            },
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateMessageState({ startThreadResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly sendMessage = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) => {
        this.updateMessageState({ sendMessageResponse: null });
        return this.messageService.sendMessage(payload.threadId, payload.messageParam).pipe(
          tap({
            next: (result: any) => {
              this.updateMessageState({ sendMessageResponse: result });
            },
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateMessageState({ sendMessageResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly addParticipant = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) => {
        this.updateMessageState({ addParticipantResponse: null });
        return this.messageService.addParticipant(payload.threadId, payload.participantsParam).pipe(
          tap({
            next: (result: any) => {
              this.updateMessageState({ addParticipantResponse: result });
            },
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateMessageState({ addParticipantResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly updateMessage = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) => {
        this.updateMessageState({ updateMessageResponse: null });
        return this.messageService.updateMessage(payload.messageId, payload.message).pipe(
          tap({
            next: (result: any) => {
              this.updateMessageState({ updateMessageResponse: result });
            },
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateMessageState({ updateMessageResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly getAllThreadsOfInstance = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) =>
        this.messageService.getAllThreadsOfInstance(payload.instanceId, payload.answersheetId, payload.include, payload.subject).pipe(
          tap({
            next: (result: any) => {
              this.updateMessageState({ allThreadsOfInstance: result.body.data });
            },
            error: e => throwError(e)
          }),
          catchError(error => EMPTY)
        )
      )
    )
  );

  readonly getAllThreadsOfInstanceEM = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) =>
        this.messageService.getAllThreadsOfInstanceEM(payload.instanceId, payload.answersheetId, payload.include, payload.subject).pipe(
          tap({
            next: (result: any) => {
              this.updateMessageState({ allThreadsOfInstance: result.body.data });
            },
            error: e => throwError(e)
          }),
          catchError(error => EMPTY)
        )
      )
    )
  );

  readonly getThreadOfInstanceEM = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) =>
        this.messageService.getThreadOfInstanceEM(payload.threadId).pipe(
          tap({
            next: (result: any) => this.updateMessageState({ threadOfInstanceEM: result.body.data }),
            error: e => throwError(e)
          }),
          catchError(error => EMPTY)
        )
      )
    )
  );

  constructor(private readonly messageService: MessageService) {
    super({
      inboxFolder: [],
      inboxFolderAnswersheet: [],
      threadDetails: null,
      getThreadDetailsResponse: null,
      startThreadResponse: null,
      sendMessageResponse: null,
      addParticipantResponse: null,
      updateMessageResponse: null,
      allThreadsOfInstance: [],
      threadOfInstanceEM: null
    });
  }
}
