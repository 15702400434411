import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appVariable]'
})
export class VariableDirective {
  public context: any = {};

  constructor(private vcRef: ViewContainerRef, private templateRef: TemplateRef<any>) {}

  @Input()
  set appVariable(context: any) {
    this.context.$implicit = this.context.appVariable = context;
    this.updateView();
  }

  public updateView() {
    this.vcRef.clear();
    this.vcRef.createEmbeddedView(this.templateRef, this.context);
  }
}
