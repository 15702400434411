<div class="container-fluid text-center">
  <div class="col-sm-8 text-start">
    <h2>{{ 'organisation-collaborator.collaborators_from' | translate:param }}</h2>
  </div>

  <!-- Collaborator content -->
  <div>
    <!-- Management functions -->
    <div *ngIf="(isManager$ | async)">
      <button [attr.id]="'btnToggleCollapse'" (click)="helper.toggleSubject(isCollapseSubject)" mat-button>
        <span *ngIf="(isCollapse$ | async)"
          ><fa-icon [icon]="faChevronDown"></fa-icon> {{'organisation-collaborator.collaborators_management' | translate}}</span
        >
        <span *ngIf="(isCollapse$ | async) === false"
          ><fa-icon [icon]="faChevronUp"></fa-icon> {{'organisation-collaborator.collaborators_management' | translate}}</span
        >
      </button>
      <div *ngIf="(isCollapse$ | async)">
        <hr />

        <div class="row">
          <!--
          <div class="col-12 col-md-4">
            <p>{{'organisation-collaborator.collaborators_create_account_description' | translate}}</p>
            <button
              [attr.id]="'btnOpenDialogOrganisationAccountCreate'"
              class="largeButton"
              (click)="openDialogOrganisationAccountCreation()"
              mat-button
              color="primary"
            >
              {{'organisation-collaborator.create_account' | translate}}
            </button>
          </div>
          -->
          <div class="col-12 col-md-4">
            <p>{{'organisation-collaborator.collaborators_add_description' | translate}}</p>
            <button
              [attr.id]="'btnOpenDialogOrganisationCollaboratorAdd'"
              class="largeButton"
              (click)="openDialogOrganisationCollaboratorAdd()"
              mat-button
              color="primary"
            >
              {{'organisation-collaborator.collaborators_add' | translate}}
            </button>
          </div>
          <div class="col-12 col-md-4">
            <p>{{'organisation-collaborator.collaborators_options_description' | translate}}</p>
            <button
              [attr.id]="'btnOpenDialogOrganisationCollaboratorEdit'"
              class="largeButton"
              (click)="openDialogOrganisationCollaboratorEdit()"
              mat-button
              color="primary"
            >
              {{'organisation-collaborator.collaborators_options' | translate}}
            </button>
          </div>
        </div>
      </div>
    </div>

    <hr />

    <div class="row justify-content-center">
      <div class="col-12 col-sm-6 mb-2">
        <!-- Search bar -->
        <mat-form-field [style.width.%]="100" appearance="outline">
          <mat-label>{{'organisation-collaborator.search_user' | translate}}</mat-label>
          <input
            (keyup.enter)="applyCollaboratorFilter()"
            [(ngModel)]="filter['searchFilter']"
            [disabled]="(isLoading$  | async)"
            aria-describedby="collaboratorSearch"
            aria-label="Search"
            matInput
            type="text"
          />
          <button
            [attr.id]="'btnSearchCollaborator'"
            (keyup.enter)="applyCollaboratorFilter()"
            aria-label="Search"
            mat-button
            mat-button
            matSuffix
          >
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>

    <app-pagination-list
      #paginator
      (emitPageChange)="updatePagedCollaborators($event)"
      [_isLoading]="isLoading$ | async"
      [_items]="collaborators$ | async"
    ></app-pagination-list>

    <!-- List of user of the organisation -->
    <div *ngIf="(isLoading$  | async) === false" class="row">
      <div *ngFor="let collaborator of pagedCollaborators | async; trackBy: helper.trackByElement" class="col-sm-6 mb-3">
        <mat-card appearance="outlined" [attr.id]="'collaborator_' + collaborator.id">
          <mat-card-header class="card-header-center">
            <mat-card-title>
              <fa-icon [icon]="faEnvelope"></fa-icon>
              {{((collaborator.attributes.email) ? collaborator.attributes.email : ('intervention-collaborator.no_email' | translate))}}
            </mat-card-title>
            <mat-card-subtitle>
              <span class="spacing-right" *ngFor="let role of collaborator.attributes?.roles">
                <fa-icon *ngIf="role.slug === 'organisation.owner'" [icon]="faStar"></fa-icon>
                {{helper.parseRoleSlug(role.slug) | translate}}
              </span>
            </mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <p class="mb-1 text-center">
              {{((collaborator.attributes.firstname && collaborator.attributes.lastname) ? collaborator.attributes.firstname + ' ' +
              collaborator.attributes.lastname : ('organisation-collaborator.no_names' | translate))}}
            </p>
            <p class="mb-1 text-center">
              {{((collaborator.attributes.name) ? collaborator.attributes.name : ('organisation-collaborator.no_username' | translate))}}
            </p>

            <div *ngIf="(collaborator.attributes.is_verified === 1); then thenShowVerified else elseShowNotVerified"></div>
            <ng-template #thenShowVerified>
              <p class="mb-1 text-center text-success">
                <fa-icon [icon]="faCheckCircle"></fa-icon>
                {{'organisation-collaborator.is_verified' | translate}}
              </p>
            </ng-template>
            <ng-template #elseShowNotVerified>
              <p class="mb-1 text-center text-danger">
                <fa-icon [icon]="faTimesCircle"></fa-icon>
                {{'organisation-collaborator.is_not_verified' | translate}}
              </p>
            </ng-template>
          </mat-card-content>
          <mat-card-actions [align]="'end'">
            <button
              [attr.id]="'btnOpenDialogOrganisationCollaboratorResendVerification_' + collaborator.id"
              (click)="resendVerificationEmail(collaborator.attributes.email)"
              *ngIf="(isManager$ | async)  && (myUserId !== collaborator.id) && (helper.getHighestRoleOfOrganisationCollaborator(collaborator) !== 'organisation.owner') && (collaborator.attributes.is_verified === 0)"
              mat-button
            >
              {{'organisation-collaborator.resend_verification' | translate}}
            </button>
            <button
              [attr.id]="'btnOpenDialogOrganisationCollaboratorDeleteUnverfied_' + collaborator.id"
              (click)="openDialogDeleteUnverified(collaborator)"
              *ngIf="(isManager$ | async)  && (myUserId !== collaborator.id) && (helper.getHighestRoleOfOrganisationCollaborator(collaborator) !== 'organisation.owner') && (collaborator.attributes.is_verified === 0)"
              mat-button
            >
              {{'organisation-collaborator.delete_unverified_account' | translate}}
            </button>
            <button
              [attr.id]="'btnOpenDialogOrganisationCollaboratorRemove_' + collaborator.id"
              (click)="openDialogOrganisationCollaboratorRemove(collaborator)"
              *ngIf="(isManager$ | async)  && (myUserId !== collaborator.id) && (helper.getHighestRoleOfOrganisationCollaborator(collaborator) !== 'organisation.owner') && (collaborator.attributes.is_verified === 1)"
              mat-button
            >
              {{'organisation-collaborator.collaborators_remove' | translate}}
            </button>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  </div>

  <!-- Spinner -->
  <div *ngIf="(isLoading$  | async)">
    <app-loader></app-loader>
  </div>
</div>
