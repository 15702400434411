/* eslint-disable @typescript-eslint/naming-convention */
import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, forkJoin, iif, Observable, of, Subscription, throwError } from 'rxjs';
import { mergeMap, skip, take } from 'rxjs/operators';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { InstanceCreationConfirmationComponent } from '../../intervention-instance/instance-creation-confirmation/instance-creation-confirmation.component';
import { InstanceCreationFormComponent } from '../../intervention-instance/instance-creation-form/instance-creation-form.component';
import { PayloadInterface } from '../../../models/interface/payload.interface';
import { RequestBodyData } from '../../../models/request-body.data';
import { InterventionInstanceInterface } from '../../../models/interface/intervention-instances/intervention-instance.interface';
import { InterventionInterface } from '../../../models/interface/intervention.interface';
import { HelperService } from '../../../services/helper/helper.service';
import { UserInterface } from '../../../models/interface/user.interface';
import { LessonInterface } from '../../../models/interface/lesson.interface';
import { InterventionInstanceStore } from '../../../store/intervention-instance/component-store/intervention-instance.store';
import { HttpResponse } from '@angular/common/http';
import { UserStore } from '../../../store/user/component-store/user.store';
import { ReminderStore } from 'src/app/store/reminder/component-store/reminder.store';

@Component({
  selector: 'app-dialog-intervention-instance-create',
  templateUrl: './dialog-intervention-instance-create.component.html',
  styleUrls: ['./dialog-intervention-instance-create.component.scss'],
  providers: [InterventionInstanceStore, UserStore, ReminderStore]
})
export class DialogInterventionInstanceCreateComponent implements OnInit, OnDestroy {
  @ViewChild('scroll', { read: ElementRef }) public scrollRef: ElementRef;
  @ViewChild('createPatientAccountContainer', { read: ViewContainerRef }) createPatientAccountContainer: ViewContainerRef;
  @ViewChild('createInstanceChild') public createInstanceChild: InstanceCreationFormComponent;
  @ViewChild('confirmCreationChild') public confirmCreationChild: InstanceCreationConfirmationComponent;

  public updateScroll: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  // Icons
  faExclamationCircle = faExclamationCircle;

  public isLoading$: Observable<boolean>;

  // Confirm instance payload
  public payload: PayloadInterface = null;
  public confirmedCreation = false;

  public members: Array<UserInterface> = [];
  public collaborators: Array<UserInterface> = [];
  public intervention: InterventionInterface;
  public myUserId: number;
  public questionnaires: Array<LessonInterface> = [];

  public instances: Array<InterventionInstanceInterface> = [];

  public selectedUserSubject: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public selectedUser: UserInterface;

  // Filter
  public filter = {
    userSelection: ''
  };

  public membersSubject: BehaviorSubject<Array<UserInterface>> = new BehaviorSubject<Array<UserInterface>>([]);
  public members$: Observable<Array<UserInterface>> = this.membersSubject.asObservable();

  public createInstanceResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');

  public param;

  public allInstancesOfInterventionOfPatient$: Observable<Array<InterventionInstanceInterface>>;

  private addEcoachesToInterventionInstanceResponse$: Observable<any>;

  private createInterventionInstanceReminderResponse$: Observable<any>;

  // Tracks list of generated components
  private components: Array<any> = [];

  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  // Subscription Handler
  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private helperService: HelperService,
    private dialogRef: MatDialogRef<DialogInterventionInstanceCreateComponent>,
    private interventionInstanceStore: InterventionInstanceStore,
    private userStore: UserStore,
    private reminderStore: ReminderStore
  ) {
    this.dialogRef.disableClose = true;
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.allInstancesOfInterventionOfPatient$ = this.interventionInstanceStore.allInstancesOfInterventionOfPatient$;
    this.addEcoachesToInterventionInstanceResponse$ = this.userStore.addEcoachesToInterventionInstanceResponse$;
    this.createInterventionInstanceReminderResponse$ = this.reminderStore.createInterventionInstanceReminderResponse$;
  }

  // Helper function
  public get helper() {
    return this.helperService;
  }

  ngOnInit(): void {
    // Get child loader
    this.isLoadingSubject.next(true);

    this.param = this.data.param;

    this.members = this.data.members;
    if (this.members.length > 0) {
      this.membersSubject.next(this.members);
      this.selectedUser = this.members[0];
      this.selectedUserSubject.next(this.members[0].id);
      this.showSelected(this.selectedUser);
    } else {
      this.selectedUserSubject.next(null);
    }
    this.collaborators = this.data.collaborators;
    this.intervention = this.data.intervention;
    this.myUserId = this.data.myUserId;
    this.questionnaires = this.data.questionnaires;
  }

  public showSelected(user: UserInterface): void {
    this.selectedUserSubject.next(user.id);
    this.interventionInstanceStore.getAllInstancesOfInterventionOfPatient({ userId: user.id });
    this.subscriptions.push(
      this.allInstancesOfInterventionOfPatient$.pipe(skip(1), take(1)).subscribe((result: any) => {
        this.instances = result;
      })
    );
  }

  // Event on creation confirmation
  public onConfirmCreation(payload: PayloadInterface): void {
    this.payload = payload;
    this.payload.data.attributes.patient_id = this.selectedUserSubject.value;
  }

  // Confirm instance creation settings
  public confirmCreationSettings(): void {
    if (this.createInstanceChild.confirmInstanceSettings()) {
      this.confirmedCreation = true;
    }
  }

  // Return to instance creation modal
  public showPreviousComponent(): void {
    this.confirmedCreation = false;
  }

  // Create an intervention instance
  public createInstance(): void {
    if (!this.selectedUserSubject.value) {
      return;
    }
    if (this.createInstanceResponse.value === 'DEFAULT') {
      this.createInstanceResponse.next('LOADING');

      const foundConfirmation: InstanceCreationConfirmationComponent = this.components.find(
        c => c instanceof InstanceCreationConfirmationComponent
      );

      this.subscriptions.push(
        foundConfirmation
          .createInstance(this.selectedUserSubject.value)
          .pipe(
            mergeMap((result: any) => {
              if (result instanceof HttpResponse) {
                const data = result;
                const newInstanceId = parseInt(
                  data.headers.get('Location').substring(data.headers.get('Location').lastIndexOf('/') + 1),
                  10
                );
                // Ignore user id when user creates the intervention instance
                let ecoachIds: Array<number> = [];
                if (foundConfirmation.instancePayload.data.attributes.ecoach_ids) {
                  ecoachIds = foundConfirmation.instancePayload.data.attributes.ecoach_ids.filter(
                    (id: number) => id.toString() !== this.myUserId.toString()
                  );
                }
                const reqs: any = [of(newInstanceId)];

                const payloadMember: PayloadInterface = new RequestBodyData('intervention_instance', { ecoach_ids: ecoachIds });
                if (foundConfirmation.instancePayload.data.attributes.ecoach_id !== undefined) {
                  if (foundConfirmation.instancePayload.data.attributes.ecoach_id.length > 0) {
                    this.userStore.addEcoachesToInterventionInstance({ instanceId: newInstanceId, payload: payloadMember });
                    reqs.push(
                      this.addEcoachesToInterventionInstanceResponse$.pipe(
                        skip(1),
                        take(1),
                        mergeMap((res: any) => iif(() => res instanceof HttpResponse, of(newInstanceId), throwError(res)))
                      )
                    );
                  }
                }

                if (!!foundConfirmation.reminderConfig) {
                  this.reminderStore.createInterventionInstanceReminder({
                    instanceId: newInstanceId,
                    receiverId: this.selectedUserSubject.value,
                    maxTimes: foundConfirmation.reminderConfig.payload.data.attributes.configuration.max_times,
                    start: foundConfirmation.reminderConfig.payload.data.attributes.configuration.start,
                    repeatTime: foundConfirmation.reminderConfig.payload.data.attributes.configuration.pause,
                    repeatUnit: foundConfirmation.reminderConfig.payload.data.attributes.configuration.time_unit,
                    contentText: foundConfirmation.reminderConfig.payload.data.attributes.content.text,
                    contentEnding: foundConfirmation.reminderConfig.payload.data.attributes.content.ending,
                    locale: foundConfirmation.reminderConfig.payload.data.attributes.content.locale
                  });
                  reqs.push(this.createInterventionInstanceReminderResponse$.pipe(skip(1), take(1)));
                }

                return forkJoin(reqs);
              } else {
                return throwError(result);
              }
            })
          )
          .subscribe(
            () => {
              foundConfirmation.unconfirmedSubject.next(false);
              foundConfirmation.alert.success('FORM_CREATE_INSTANCE_SUCCEEDED');
              this.createInstanceResponse.next('SUCCESS');
            },
            error => {
              console.error(error);
              this.createInstanceResponse.next('FAILURE');
              setTimeout(() => {
                this.createInstanceResponse.next('DEFAULT');
              }, 2500);
            },
            () => {
              setTimeout(() => {
                this.createInstanceResponse.next('DEFAULT');
                this.dialogRef.close('SUCCESS');
              }, 2500);
            }
          )
      );
    }
  }

  public hasPendingInstances(): boolean {
    const nonPendingStates = ['canceled'];
    const instanceResult = this.instances.filter(
      (instance: InterventionInstanceInterface) =>
        nonPendingStates.indexOf(instance.attributes.progress.current_state.toLowerCase()) === -1 &&
        instance.attributes.intervention_id.toString() === this.intervention.id.toString()
    );
    return instanceResult.length > 0;
  }

  public resetFilter(): void {
    this.filter = {
      userSelection: ''
    };
    this.membersSubject.next(this.members);
  }

  public showNextComponent(): void {
    this.updateScroll.next(!this.updateScroll.value);
    const found = this.components.find(c => c instanceof InstanceCreationConfirmationComponent);
    let component;
    if (found) {
      component = found;
    } else {
      component = this.createPatientAccountContainer.createComponent<any>(InstanceCreationConfirmationComponent).instance;
      this.components = [...this.components, component];
    }
    this.createInstanceChild.confirmInstanceSettings();
    component._collaborators(this.collaborators);
    component._intervention(this.intervention);
    component._members(this.members);
    component._myUserId(this.myUserId);
    component._payload(this.payload);
    component._questionnaires(this.questionnaires);
    this.confirmedCreation = true;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
