import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, Observable, Subscription } from 'rxjs';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { ElementBlockInterface } from '../../../../models/interface/elements/element-block.interface';
import { AnswersheetAnswerInterface } from '../../../../models/interface/answersheet-answer.interface';
import { ConditionalblockInterface } from '../../../../models/interface/condition/conditionalblock.interface';
import { ElementInterface } from '../../../../models/interface/elements/element.interface';
import { EvaluationService } from '../../../../services/evaluation/evaluation.service';
import { ElementsLinesComponent } from '../elements-lines/elements-lines.component';
import { ElementsPageComponent } from '../elements-page/elements-page.component';
import { ElementsSpacesComponent } from '../elements-spaces/elements-spaces.component';
import { QuestionHeadlineComponent } from '../question-headline/question-headline.component';
import { QuestionMediaComponent } from '../question-media/question-media.component';
import { QuestionMultipleChoiceComponent } from '../question-multiple-choice/question-multiple-choice.component';
import { QuestionSingleChoiceComponent } from '../question-single-choice/question-single-choice.component';
import { QuestionSliderComponent } from '../question-slider/question-slider.component';
import { QuestionTableComponent } from '../question-table/question-table.component';
import { QuestionTextAreaComponent } from '../question-text-area/question-text-area.component';
import { QuestionTextDateComponent } from '../question-text-date/question-text-date.component';
import { QuestionTextStringComponent } from '../question-text-string/question-text-string.component';
import { QuestionTextComponent } from '../question-text/question-text.component';
import { QuestionYesNoComponent } from '../question-yes-no/question-yes-no.component';
import { Store } from '@ngrx/store';
import { PageIterationInterface } from '../../../../models/interface/elements/page-iteration.interface';

/**
 * Component:
 * Represents blockopens element
 */

@Component({
  selector: 'app-elements-blockopens',
  templateUrl: './elements-blockopens.component.html',
  styleUrls: ['./elements-blockopens.component.scss']
})
export class ElementsBlockopensComponent implements OnInit, OnDestroy {
  @ViewChild('elementsBlockContainer', { read: ViewContainerRef }) elementsContainer: ViewContainerRef;

  @Output()
  emitting = new EventEmitter<Array<AnswersheetAnswerInterface>>();

  // Icon
  faInfoCircle = faInfoCircle;
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;

  public element;
  public content: any;

  // Answers from answer sheet
  public answers: Array<AnswersheetAnswerInterface>;
  public color: string;

  // All questionnaire elements
  public elements = [];

  // Values to evaluate condition
  public condition: Array<ConditionalblockInterface> = [];

  // Readable condition in string
  public expression = null;

  public hidden$: Observable<boolean>;
  public toggle$: Observable<boolean>;

  // For looped elements
  public numberOfLoopsToObjectArray: Array<any> = [];
  public loopElements: Array<ElementInterface>;

  public iteration = 0;

  // Array<AnswerPositionInterface>
  public nested;

  // Page related
  public pageNumber;
  public currentPage;
  public pageIteration: Array<PageIterationInterface> = [];

  public evaluation$: Observable<boolean>;

  // Tracks list of generated components
  private components: Array<any> = [];

  private dynamicAnswers: Array<AnswersheetAnswerInterface> = [];
  private dynamicAnswers$: Observable<Array<AnswersheetAnswerInterface>>;

  private thenBlockRepeatData;
  private thenBlockRepeatQuestion;

  private expressionSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private evaluationSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private hiddenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private toggleSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private translations: any;

  private subscriptions: Subscription[] = [];

  constructor(
    private translateService: TranslateService,
    private evaluationService: EvaluationService,
    private store: Store<{
      dynamicAnswers: Array<AnswersheetAnswerInterface>;
    }>
  ) {
    this.dynamicAnswers$ = store.select('dynamicAnswers');
    this.hidden$ = this.hiddenSubject.asObservable();
    this.toggle$ = this.toggleSubject.asObservable();
    this.evaluation$ = this.evaluationSubject.asObservable();
  }

  @Input()
  set _element(_element: ElementBlockInterface | ElementInterface) {
    if (_element !== undefined) {
      this.element = _element;
      this.translations = _element.translations;
      this.thenBlockRepeatData = _element.condition.thenBlock.repeat.data;
      this.thenBlockRepeatQuestion = _element.condition.thenBlock.repeat.question;
    }
  }

  @Input()
  set _elements(_elements: any) {
    if (_elements !== undefined) {
      this.elements = _elements;
    }
  }

  @Input()
  set _answers(_answers: Array<AnswersheetAnswerInterface>) {
    if (_answers) {
      this.answers = _answers;
    }
  }

  // Block condition
  @Input()
  set _condition(_condition: Array<ConditionalblockInterface>) {
    this.condition = _condition;
  }

  @Input()
  set _iteration(_iteration) {
    if (_iteration !== undefined) {
      this.iteration = _iteration;
    }
  }

  @Input()
  set _nested(_nested) {
    if (_nested !== undefined) {
      this.nested = _nested;
    }
  }

  @Input()
  set _page(_page) {
    if (_page !== undefined) {
      this.pageNumber = _page;
      if (this.currentPage !== undefined) {
        this.hiddenSubject.next(!(this.currentPage + 1).toString() === this.pageNumber.toString());
      }
    }
  }

  @Input()
  set _pageIteration(_pageIteration) {
    if (_pageIteration !== undefined) {
      this.pageIteration = _pageIteration;
    }
  }

  @Input()
  set _currentPage(_currentPage) {
    if (_currentPage !== undefined) {
      this.currentPage = _currentPage;
      if (this.pageNumber !== undefined) {
        if (this.currentPage !== undefined) {
          this.hiddenSubject.next(!(this.currentPage + 1).toString() === this.pageNumber.toString());
        }
      }
      this.components.forEach(element => {
        element._currentPage = _currentPage;
      });
    }
  }

  @Input()
  set _color(_color) {
    if (_color !== undefined) {
      this.color = _color;
    }
  }

  ngOnInit(): void {
    if (this.answers) {
      this.evaluateLoop(this.answers);
      this.evaluateExpression(this.answers);
      this.createDynamicComponents();
    } else {
      this.subscriptions.push(
        this.dynamicAnswers$.pipe(distinctUntilChanged()).subscribe(dynamicAnswers => {
          this.dynamicAnswers = dynamicAnswers;
          this.evaluateLoop(this.dynamicAnswers);
          this.evaluateExpression(this.dynamicAnswers);
          this.createDynamicComponents();
        })
      );
    }

    if (this.element.type === 'details' || this.element.type === 'both') {
      this.content = this.findTranslation();
    }
    this.translateService.onLangChange.subscribe(
      (event: LangChangeEvent) => {
        if (this.element.type === 'details' || this.element.type === 'both') {
          this.content = this.findTranslation();
        }
      },
      error => {
        console.error(error);
      }
    );
  }

  public toggleShowing(): void {
    this.toggleSubject.next(!this.toggleSubject.value);
  }

  public getPageByElementPosition(iteration, position, answers: Array<AnswersheetAnswerInterface>): number {
    const nextPageElements: Array<PageIterationInterface> = this.pageIteration.filter(
      (obj: PageIterationInterface) => obj.position > position
    );
    const previousCurrentPageElements: Array<PageIterationInterface> = this.pageIteration.filter(
      (obj: PageIterationInterface) => obj.position < position
    );
    let nextPage: PageIterationInterface;
    if (nextPageElements.length > 0) {
      const nextPageElementPosition = nextPageElements.reduce(
        (minimum: number, obj: PageIterationInterface) => (obj.position < minimum ? obj.position : minimum),
        nextPageElements[0].position
      );
      const allArrMin = this.pageIteration.filter((obj: PageIterationInterface) => obj.position === nextPageElementPosition);
      nextPage = allArrMin.find(obj => iteration.toString() === obj.iteration.toString());
      if (nextPage === undefined) {
        nextPage = allArrMin.find(obj => obj.iteration.toString() === '0');
      }
    }

    let currentPage: PageIterationInterface;
    if (previousCurrentPageElements.length > 0) {
      const currentPageElementPosition = previousCurrentPageElements.reduce(
        (maximum: number, obj: PageIterationInterface) => (obj.position > maximum ? obj.position : maximum),
        previousCurrentPageElements[0].position
      );
      const allArrMax = this.pageIteration.filter((obj: PageIterationInterface) => obj.position === currentPageElementPosition);
      currentPage = allArrMax.find(obj => iteration.toString() === obj.iteration.toString());
      if (currentPage === undefined) {
        currentPage = allArrMax.reduce((maximum: PageIterationInterface, obj: PageIterationInterface) =>
          obj.iteration > maximum.iteration ? obj : maximum
        );
      }
    }
    let evaluationNextPage;
    if (currentPage !== undefined) {
      evaluationNextPage = this.evaluationService.evaluate(this.condition, currentPage.position, answers);
      // Add page condition
      if (evaluationNextPage && currentPage.evaluatedValue) {
        return currentPage.page;
      }
    }
    let evaluationCurrentPage;
    if (currentPage !== undefined) {
      evaluationCurrentPage = this.evaluationService.evaluate(this.condition, currentPage.position, answers);
      // add page condition
      if (evaluationCurrentPage && currentPage.evaluatedValue) {
        return currentPage.page - 1;
      }
    }
    return 0;
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  private findTranslation() {
    const _content = this.translations.find(translation => translation.locale === localStorage.getItem('lessonLocale'));
    return _content ? _content : this.translations[0];
  }

  private evaluateExpression(answers: Array<AnswersheetAnswerInterface>): void {
    // Get expression
    const conditionalString = this.evaluationService.getConditionalString(this.condition, this.element.position);
    this.evaluationService.displayConditionString(this.expressionSubject, conditionalString);
    const evaluation = this.evaluationService.evaluate(this.condition, this.element.position, answers);
    if (this.element.type !== 'details') {
      this.evaluationService.displayEvaluation(this.evaluationSubject, evaluation);
    } else {
      this.evaluationSubject.next(true);
    }
    this.loopElements = this.getNonEncapsulatedElementsInBlock();
  }

  private evaluateLoop(answers: Array<AnswersheetAnswerInterface>): void {
    this.numberOfLoopsToObjectArray = Array(0)
      .fill(0)
      .map((x, i) => i);
    if (this.element.type === 'none') {
      if (this.thenBlockRepeatQuestion) {
        const resultFromDefault = this.elements.find(element => element.position === this.thenBlockRepeatData);
        const resultFromAnswer: AnswersheetAnswerInterface = answers.find(answer => answer.position === this.thenBlockRepeatData);

        // If value for slider question is found
        if (resultFromAnswer) {
          // Choose loop from answersheet
          if (resultFromAnswer.value) {
            this.numberOfLoopsToObjectArray = Array(parseInt(resultFromAnswer.value, 10))
              .fill(0)
              .map((x, i) => i);
          }
        } else {
          // Choose loop from min default value
          this.numberOfLoopsToObjectArray = Array(resultFromDefault.values.min)
            .fill(0)
            .map((x, i) => i);
        }
      } else {
        // Constant value
        this.numberOfLoopsToObjectArray = Array(parseInt(this.thenBlockRepeatData, 10))
          .fill(0)
          .map((x, i) => i);
      }
    }
    if (this.element.type.match(/^(conditional|details|both)$/)) {
      if (this.thenBlockRepeatQuestion) {
        const resultFromDefault = this.elements.find(element => element.position === this.thenBlockRepeatData);
        const resultFromAnswer: AnswersheetAnswerInterface = answers.find(answer => answer.position === this.thenBlockRepeatData);
        // If value for slider question is found
        if (resultFromAnswer) {
          // Choose loop from answersheet
          if (resultFromAnswer.value) {
            this.numberOfLoopsToObjectArray = Array(parseInt(resultFromAnswer.value.toString(), 10))
              .fill(0)
              .map((x, i) => i);
          }
        } else {
          // Choose loop from min default value
          this.numberOfLoopsToObjectArray = Array(resultFromDefault.values.min)
            .fill(0)
            .map((x, i) => i);
        }
      } else {
        this.numberOfLoopsToObjectArray = Array(parseInt(this.thenBlockRepeatData, 10))
          .fill(0)
          .map((x, i) => i);
      }
    }
  }

  private getNonEncapsulatedElementsInBlock(): Array<ElementInterface> {
    // Get elements that belongs to the block
    const nestedElements = [];
    const result = this.condition.find((cond: ConditionalblockInterface) => cond.blockOpen === this.element.position);
    const positionResults = [];
    if (result !== undefined) {
      for (let i = result.blockOpen + 1; i < result.blockClose; i++) {
        positionResults.push(i);
      }
      positionResults.forEach(num => {
        const found = this.elements.find(element => element.position === num);
        if (result !== undefined) {
          nestedElements.push(found);
        }
      });
    }

    // Return non-encapsulated elements of this block
    const nonEncapElements = [];
    let skipToPos = 0;

    for (const nestedElement of nestedElements) {
      if (nestedElement.position > skipToPos) {
        if (nestedElement.elementtype === 'elements/blockopens') {
          const loopBlock = this.condition.find(cond => cond.blockOpen === nestedElement.position);
          skipToPos = loopBlock.blockClose;
        }
        nonEncapElements.push(nestedElement);
      }
    }
    return nonEncapElements;
  }

  private createDynamicComponents(): void {
    setTimeout(() => {
      this.components = [];
      if (this.elementsContainer) {
        this.elementsContainer.clear();
      }
      const _answers: Array<AnswersheetAnswerInterface> = this.answers ? this.answers : this.dynamicAnswers;
      this.numberOfLoopsToObjectArray.forEach((iteration, l) => {
        this.loopElements.forEach((element, i) => {
          let component;
          if (element.name === 'HEADLINE') {
            component = this.elementsContainer.createComponent<QuestionHeadlineComponent>(QuestionHeadlineComponent).instance;
            if (this.answers) {
              component._answers = _answers;
            }
            component._condition = this.condition;
            component._currentPage = this.currentPage;
            component._element = element;
            component._allElements = this.elements;
            component._page = this.getPageByElementPosition(l, element.position, _answers);
          }
          if (element.name === 'MEDIA') {
            component = this.elementsContainer.createComponent<QuestionMediaComponent>(QuestionMediaComponent).instance;
            if (this.answers) {
              component._answers = _answers;
            }
            component._condition = this.condition;
            component._currentPage = this.currentPage;
            component._element = element;
            component._allElements = this.elements;
            component._page = this.getPageByElementPosition(l, element.position, _answers);
          }
          if (element.elementtype === 'elements/questions' && element.questiontype === 'MultipleChoice') {
            component = this.elementsContainer.createComponent<QuestionMultipleChoiceComponent>(QuestionMultipleChoiceComponent).instance;
            if (this.answers) {
              component._answers = _answers;
            }
            // component._iteration = iteration;
            component._iteration = iteration + this.iteration * this.numberOfLoopsToObjectArray.length;
            component._nested = this.nested;
            component._condition = this.condition;
            component._currentPage = this.currentPage;
            component._element = element;
            component._allElements = this.elements;
            component._page = this.getPageByElementPosition(l, element.position, _answers);
          }
          if (element.elementtype === 'elements/questions' && element.questiontype === 'SingleChoice') {
            component = this.elementsContainer.createComponent<QuestionSingleChoiceComponent>(QuestionSingleChoiceComponent).instance;
            if (this.answers) {
              component._answers = _answers;
            }
            // component._iteration = iteration;
            component._iteration = iteration + this.iteration * this.numberOfLoopsToObjectArray.length;
            component._nested = this.nested;
            component._condition = this.condition;
            component._currentPage = this.currentPage;
            component._element = element;
            component._allElements = this.elements;
            component._page = this.getPageByElementPosition(l, element.position, _answers);
          }
          if (element.elementtype === 'elements/questions' && element.questiontype === 'Slider') {
            component = this.elementsContainer.createComponent<QuestionSliderComponent>(QuestionSliderComponent).instance;
            if (this.answers) {
              component._answers = _answers;
            }
            // component._iteration = iteration;
            component._iteration = iteration + this.iteration * this.numberOfLoopsToObjectArray.length;
            component._nested = this.nested;
            component._condition = this.condition;
            component._currentPage = this.currentPage;
            component._element = element;
            component._allElements = this.elements;
            component._page = this.getPageByElementPosition(l, element.position, _answers);
          }
          if (element.elementtype === 'elements/questions' && element.questiontype === 'QuestionTable') {
            component = this.elementsContainer.createComponent<QuestionTableComponent>(QuestionTableComponent).instance;
            if (this.answers) {
              component._answers = _answers;
            }
            // component._iteration = iteration;
            component._iteration = iteration + this.iteration * this.numberOfLoopsToObjectArray.length;
            component._nested = this.nested;
            component._condition = this.condition;
            component._currentPage = this.currentPage;
            component._element = element;
            component._allElements = this.elements;
            component._page = this.getPageByElementPosition(l, element.position, _answers);
          }
          if (element.elementtype === 'elements/texts') {
            component = this.elementsContainer.createComponent<QuestionTextComponent>(QuestionTextComponent).instance;
            if (this.answers) {
              component._answers = _answers;
            }
            component._condition = this.condition;
            component._currentPage = this.currentPage;
            component._element = element;
            component._allElements = this.elements;
            component._page = this.getPageByElementPosition(l, element.position, _answers);
          }
          if (element.elementtype === 'elements/questions' && element.questiontype === 'TextArea') {
            component = this.elementsContainer.createComponent<QuestionTextAreaComponent>(QuestionTextAreaComponent).instance;
            if (this.answers) {
              component._answers = _answers;
            }
            // component._iteration = iteration;
            component._iteration = iteration + this.iteration * this.numberOfLoopsToObjectArray.length;
            component._nested = this.nested;
            component._condition = this.condition;
            component._currentPage = this.currentPage;
            component._element = element;
            component._allElements = this.elements;
            component._page = this.getPageByElementPosition(l, element.position, _answers);
          }
          if (element.elementtype === 'elements/questions' && element.questiontype === 'TextDate') {
            component = this.elementsContainer.createComponent<QuestionTextDateComponent>(QuestionTextDateComponent).instance;
            if (this.answers) {
              component._answers = _answers;
            }
            // component._iteration = iteration;
            component._iteration = iteration + this.iteration * this.numberOfLoopsToObjectArray.length;
            component._nested = this.nested;
            component._condition = this.condition;
            component._currentPage = this.currentPage;
            component._element = element;
            component._allElements = this.elements;
            component._page = this.getPageByElementPosition(l, element.position, _answers);
          }
          if (element.elementtype === 'elements/questions' && element.questiontype === 'TextString') {
            component = this.elementsContainer.createComponent<QuestionTextStringComponent>(QuestionTextStringComponent).instance;
            if (this.answers) {
              component._answers = _answers;
            }
            // component._iteration = iteration;
            component._iteration = iteration + this.iteration * this.numberOfLoopsToObjectArray.length;
            component._nested = this.nested;
            component._condition = this.condition;
            component._currentPage = this.currentPage;
            component._element = element;
            component._allElements = this.elements;
            component._page = this.getPageByElementPosition(l, element.position, _answers);
          }
          if (element.elementtype === 'elements/questions' && element.questiontype === 'YesNoSwitch') {
            component = this.elementsContainer.createComponent<QuestionYesNoComponent>(QuestionYesNoComponent).instance;
            if (this.answers) {
              component._answers = _answers;
            }
            // component._iteration = iteration;
            component._iteration = iteration + this.iteration * this.numberOfLoopsToObjectArray.length;
            component._nested = this.nested;
            component._condition = this.condition;
            component._currentPage = this.currentPage;
            component._element = element;
            component._allElements = this.elements;
            component._page = this.getPageByElementPosition(l, element.position, _answers);
          }
          if (element.elementtype === 'elements/pages') {
            component = this.elementsContainer.createComponent<ElementsPageComponent>(ElementsPageComponent).instance;
            if (this.answers) {
              component._answers = _answers;
            }
            component._iteration = iteration;
            component._nested = this.nested + 1;
            component._condition = this.condition;
            component._element = element;
            component._elements = this.elements;
            component._page = this.pageNumber;
          }
          if (element.elementtype === 'elements/lines') {
            component = this.elementsContainer.createComponent<ElementsLinesComponent>(ElementsLinesComponent).instance;
            component._condition = this.condition;
            component._currentPage = this.currentPage;
            component._element = element;
            component._page = this.getPageByElementPosition(l, element.position, _answers);
          }
          if (element.elementtype === 'elements/spaces') {
            component = this.elementsContainer.createComponent<ElementsSpacesComponent>(ElementsSpacesComponent).instance;
            component._condition = this.condition;
            component._currentPage = this.currentPage;
            component._element = element;
            component._page = this.getPageByElementPosition(l, element.position, _answers);
          }
          if (element.elementtype === 'elements/blockopens') {
            component = this.elementsContainer.createComponent<ElementsBlockopensComponent>(ElementsBlockopensComponent).instance;
            component._elements = this.elements;
            if (this.answers) {
              component._answers = _answers;
            }
            component._condition = this.condition;
            component._currentPage = this.currentPage;
            component._element = element;
            component._iteration = iteration;
            component._nested = this.nested + 1;
            component._pageIteration = this.pageIteration;
            component._page = this.getPageByElementPosition(l, element.position, _answers) + 1;
            component._color = this.color;
          }
          if (component) {
            this.components = [...this.components, component];
          }
        });
      });
    });
  }
}
