<button [attr.id]="'btnDialogClose'" mat-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title>{{'intervention-instance.modal_cancel_instance_title' | translate:param}}</h1>

<div mat-dialog-content>
  <!-- ng-template to ensure that the generated components end up in this place -->
  <ng-template #instanceDeletionContainer></ng-template>
</div>
<div mat-dialog-actions align="end">
  <button [attr.id]="'btnCancel'" mat-button mat-dialog-close mat-dialog-close="true">
    {{'intervention-instance.modal_cancel' | translate}}
  </button>
  <app-button-feedback
    [_setId]="'btnSetCanceled'"
    (click)="setCanceled()"
    [_defaultButtonText]="'intervention-instance.modal_cancel_instance'"
    [_responseText]="setCanceledResponse | async"
  ></app-button-feedback>
</div>
