import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { OrganisationCollaboratorInterface } from '../../models/interface/organisation/organisation-collaborator.interface';
import { Action, Store } from '@ngrx/store';
import { HttpResponse } from '@angular/common/http';
import { ProfileInterface } from '../../../app/models/interface/profile.interface';
import { GetOrganisationCollaboratorsSuccess, OrganisationActionTypes } from '../../../app/store/organisation/organisation.action';
import { Actions, ofType } from '@ngrx/effects';
import { HelperService } from '../../services/helper/helper.service';

@Injectable({
  providedIn: 'root'
})
export class OrganisationManagerGuard implements CanActivateChild {
  public profile$: Observable<ProfileInterface> = this.store.select('myProfile');
  public profile: ProfileInterface;
  public organisationId: string;
  constructor(
    private router: Router,
    private helperService: HelperService,
    private actions$: Actions,
    private store: Store<{ myProfile: ProfileInterface }>
  ) {}

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (state.url.includes('studies') || state.url.includes('collaborators')) {
      return of(true);
    } else {
      return this.profile$.pipe(
        filter((user: ProfileInterface) => !!user),
        take(1),
        switchMap((user: ProfileInterface) => {
          this.profile = user;
          this.organisationId = state.url.replace(/\D/g, '');

          this.store.dispatch({
            type: OrganisationActionTypes.getOrganisationCollaboratorsType,
            payload: {
              organisationId: parseInt(this.organisationId, 10)
            }
          });

          return this.actions$.pipe(
            ofType(
              OrganisationActionTypes.getOrganisationCollaboratorsSuccessType,
              OrganisationActionTypes.getOrganisationCollaboratorsErrorType
            ),
            take(1)
          );
        }),
        switchMap((action: Action) => {
          if (action.type === OrganisationActionTypes.getOrganisationCollaboratorsSuccessType) {
            const resp: HttpResponse<any> = (action as GetOrganisationCollaboratorsSuccess).response;
            const collaborators: Array<OrganisationCollaboratorInterface> = resp['body']['data'];
            const myself: OrganisationCollaboratorInterface = collaborators.find(user => user.id === this.profile.id);
            if (myself !== undefined) {
              const myRoleSlug = this.helperService.getHighestRoleOfOrganisationCollaborator(myself);
              if (myRoleSlug.match(/organisation\.(manager|owner)$/)) {
                return of(true);
              }
            } else {
              return of(false);
            }
          }
          return of(this.router.parseUrl(`/organisations/${this.organisationId}`));
        }),
        catchError(() => of(this.router.parseUrl(`/organisations/${this.organisationId}`)))
      );
    }
  }
}
