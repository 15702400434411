/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { debounceTime, distinctUntilChanged, filter, mergeMap, skip, switchMap, take } from 'rxjs/operators';
import { BehaviorSubject, forkJoin, iif, Observable, of, Subscription } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { faClock } from '@fortawesome/free-regular-svg-icons/faClock';
import { faStar } from '@fortawesome/free-regular-svg-icons/faStar';
import { Store } from '@ngrx/store';
import { MessageMessageInterface } from '../../../models/interface/message_message.interface';
import { FcmMessageService } from '../../../services/fcm-message/fcm-message.service';
import { HelperService } from '../../../services/helper/helper.service';
import { MessageThreadsInterface } from '../../../models/interface/message_threads.interface';
import { UserInterface } from '../../../models/interface/user.interface';
import { ProfileInterface } from '../../../models/interface/profile.interface';
import { HelperDialogService } from '../../../services/helper/helper-dialog/helper-dialog.service';
import { UserActionTypes } from '../../../store/user/user.action';
import { MessageStore } from '../../../store/message/component-store/message.store';
import { HttpResponse } from '@angular/common/http';
import { UserStore } from '../../../store/user/component-store/user.store';

/**
 * Component:
 * Conversation page displaying a list of conversations;
 * Can be found: {uri}/conversations
 */

@Component({
  selector: 'app-conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.scss', '../../../../assets/styles/aas2_app.scss'],
  providers: [MessageStore, UserStore]
})
export class ConversationComponent implements OnInit, OnDestroy {
  @ViewChild('chat') chatchild;

  // Icons
  faEnvelope = faEnvelope;
  faPlus = faPlus;
  faStar = faStar;
  faClock = faClock;

  public isLoading$: Observable<boolean>;
  public profile$: Observable<UserInterface>;

  // Filter
  public filter = {
    searchFilter: ''
  };

  public commentForm: UntypedFormGroup;

  public selectedThreadsModel: Array<MessageThreadsInterface> = [];

  // Data provided by UserService
  public members: Array<UserInterface> = [];
  public threads: Array<MessageThreadsInterface> = [];
  public threadsSubject: BehaviorSubject<Array<MessageThreadsInterface>> = new BehaviorSubject<Array<MessageThreadsInterface>>([]);
  public threads$: Observable<Array<MessageThreadsInterface>> = this.threadsSubject.asObservable();

  public messagesFromDetails: Array<MessageMessageInterface> = [];
  public collaborators: Array<UserInterface> = [];

  public allECoaches: Array<UserInterface> = [];

  public submitCommentResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');

  public selectedThread: MessageThreadsInterface;

  private threadDetails$: Observable<any>;

  private inboxFolder$: Observable<Array<MessageThreadsInterface>>;

  private sendMessageResponse$: Observable<any>;

  private myRegisteredUsers$: Observable<Array<UserInterface>>;

  private isLoadingSubject = new BehaviorSubject<boolean>(true);
  private searchTextSubject = new BehaviorSubject<string>('');

  private myMembers$: Observable<Array<UserInterface>>;
  private allCollaborators$: Observable<Array<UserInterface>>;

  private subscriptions: Subscription[] = [];

  constructor(
    private helperService: HelperService,
    private helperDialogService: HelperDialogService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private fcmMessageService: FcmMessageService,
    private actRoute: ActivatedRoute,
    private store: Store<{ myProfile: ProfileInterface; myMembers: Array<UserInterface>; myRegisteredUsers: Array<UserInterface> }>,
    private messageStore: MessageStore,
    private userStore: UserStore
  ) {
    this.profile$ = this.store.select('myProfile');
    this.isLoading$ = this.isLoadingSubject.asObservable();
    router.events.pipe(filter(e => e instanceof NavigationEnd && e.url.includes('/conversations'))).subscribe(() => {
      this.dialog.closeAll();
    });

    this.commentForm = this.formBuilder.group({
      comment: ['', Validators.required]
    });
    this.inboxFolder$ = this.messageStore.inboxFolder$;
    this.threadDetails$ = this.messageStore.threadDetails$;
    this.sendMessageResponse$ = this.messageStore.sendMessageResponse$;
    this.myMembers$ = this.store.select('myMembers');
    this.myRegisteredUsers$ = this.store.select('myRegisteredUsers');
    this.allCollaborators$ = this.userStore.allCollaborators$;
  }

  get f() {
    return this.commentForm.controls;
  }

  public get helper() {
    return this.helperService;
  }

  public get helperDialog() {
    return this.helperDialogService;
  }

  ngOnInit(): void {
    this.messageStore.getInboxFolder({ operator: '<', answersheetId: null, include: 'participants' });
    this.subscriptions.push(
      this.inboxFolder$.pipe(skip(1), take(1)).subscribe(
        (result: any) => {
          this.threads = result;
          this.threadsSubject.next(this.helperService.getThreadByRecentUpdates(this.threads));

          if (this.actRoute.snapshot.queryParamMap.get('thread_id')) {
            const foundIndex = this.threads.findIndex(
              (thread: MessageThreadsInterface) => thread.id.toString() === this.actRoute.snapshot.queryParamMap.get('thread_id').toString()
            );
            const threadFromIndex = foundIndex > 0 ? [this.threads[foundIndex]] : [this.threads[0]];
            this.selectThread(threadFromIndex);
          } else {
            if (this.threads.length > 0) {
              this.selectThread([this.threads[0]]);
              this.router.navigate([], {
                relativeTo: this.actRoute,
                queryParams: { thread_id: this.threads[0].id }
              });
            }
          }

          this.store.dispatch({
            type: UserActionTypes.getMyMembersType,
            payload: {}
          });
          this.store.dispatch({
            type: UserActionTypes.getUsersRegisteredByEcoachType,
            payload: {}
          });
          this.userStore.getAllCollaboratorsOfUser({ roles: 'ecoach' });
          this.isLoadingSubject.next(false);
        },
        error => {
          console.error(error);
          this.isLoadingSubject.next(false);
        }
      )
    );

    this.subscriptions.push(
      this.myMembers$.subscribe((members: any) => {
        this.members = this.helper.setUniqueMembers(this.members, members);
      })
    );

    this.subscriptions.push(
      this.myRegisteredUsers$.subscribe((members: any) => {
        this.members = this.helper.setUniqueMembers(this.members, members);
      })
    );

    this.subscriptions.push(
      this.allCollaborators$.subscribe((collaborators: any) => {
        this.allECoaches = collaborators;
      })
    );

    this.subscriptions.push(
      this.fcmMessageService.currentMessage.subscribe((value: any) => {
        if (value) {
          const notificationJson = this.helperService.getJSONObject(value.notification.body);
          if (this.helperService.getJSONObject(value.notification.body)) {
            if (this.selectedThread.id.toString() === notificationJson.thread_id.toString()) {
              this.selectThread([this.selectedThread]);
            }
          }
        }
      })
    );

    this.subscriptions.push(
      this.threadDetails$.subscribe((messagesDetails: any) => {
        const messages = messagesDetails?.relationships?.messages?.data ? messagesDetails.relationships.messages.data : [];
        this.setUsersMessages(messages);
      })
    );
  }

  // Select thread and display user and messages
  public selectThread(thread: Array<MessageThreadsInterface>): void {
    this.selectedThread = thread[0];
    this.selectedThreadsModel = thread;
    this.messageStore.getThreadDetails({ threadId: this.selectedThread.id, include: 'messages' });
    this.router.navigate([], {
      relativeTo: this.actRoute,
      queryParams: { thread_id: this.selectedThread.id },
      queryParamsHandling: 'merge'
    });
  }

  public setUsersMessages(messages): void {
    this.messagesFromDetails = [];
    messages.forEach(element => {
      if (element.type === 'messages/messages') {
        this.messagesFromDetails = [...this.messagesFromDetails, element];
      }
    });
  }

  public isMyMessage(myUserId: number, authorId: number): boolean {
    return myUserId && authorId ? authorId === myUserId : false;
  }

  public submitComment(): void {
    if (this.submitCommentResponse.value === 'DEFAULT') {
      this.submitCommentResponse.next('LOADING');
      if (this.commentForm.invalid) {
        this.submitCommentResponse.next('DEFAULT');
        return;
      }
      this.messageStore.sendMessage({ threadId: this.selectedThread.id, messageParam: this.f.comment.value });
      this.subscriptions.push(
        this.sendMessageResponse$.pipe(skip(1), take(1)).subscribe(
          (result: any) => {
            if (result instanceof HttpResponse) {
              this.messageStore.getThreadDetails({ threadId: this.selectedThread.id, include: 'messages' });
            }
            this.submitCommentResponse.next('SUCCESS');
            this.commentForm.reset('');
          },
          () => {
            this.submitCommentResponse.next('FAILURE');
            setTimeout(() => {
              this.submitCommentResponse.next('DEFAULT');
            }, 2500);
          },
          () => {
            setTimeout(() => {
              this.submitCommentResponse.next('DEFAULT');
            }, 2500);
          }
        )
      );
    }
  }

  // Automatic scroll to bottom after ngFor's last iteration
  public scrollToBottom(): void {
    try {
      if (this.chatchild) {
        this.chatchild.nativeElement.scrollTop = this.chatchild.nativeElement.scrollHeight;
      }
    } catch (err) {
      console.error(err);
    }
  }

  public initializeChat(newThreadId?: number): void {
    this.messageStore.getInboxFolder({ operator: '<', answersheetId: null, include: 'participants' });
    this.subscriptions.push(
      this.inboxFolder$.pipe(skip(1), take(1)).subscribe((result: any) => {
        this.threads = this.helper.getInboxWithRecentUpdatedThreads(result);
        this.threadsSubject.next(this.helperService.getThreadByRecentUpdates(this.threads));
        if (this.threads.length !== 0) {
          if (newThreadId) {
            const newThreadPos = this.threads.findIndex(thread => thread.id.toString() === newThreadId.toString());
            if (newThreadPos > -1) {
              this.selectThread([this.threads[newThreadPos]]);
            }
          }
          this.selectThread([this.threads[0]]);
        }
      })
    );
  }

  public search(name: string): void {
    this.isLoadingSubject.next(true);

    // Filter interventions for email while ignoring casing
    const filterWithInput = (value: string, threads: Array<MessageThreadsInterface>): Array<MessageThreadsInterface> =>
      threads.filter(
        (element: MessageThreadsInterface) =>
          element.relationships.participants.data.filter(user => user.attributes.email.toLowerCase().includes(value)).length > 0
      );

    this.searchTextSubject.next(name);
    this.subscriptions.push(
      this.searchTextSubject.pipe(debounceTime(500), distinctUntilChanged()).subscribe(() => {
        this.threadsSubject.next(
          this.helperService.getThreadByRecentUpdates(filterWithInput(this.filter['searchFilter'].toLowerCase().trim(), this.threads))
        );
        this.isLoadingSubject.next(false);
      })
    );
  }

  public openDialogConversationCreate(): void {
    this.subscriptions.push(
      this.profile$
        .pipe(
          filter(user => user !== null),
          take(1),
          switchMap((user: ProfileInterface) =>
            iif(
              () => !user,
              of(null),
              this.helperDialog.openDialogConversationCreate([], user, this.members, this.allECoaches, this.collaborators).afterClosed()
            )
          )
        )
        .subscribe((result: any) => {
          this.initializeChat(result);
        })
    );
  }

  public isThreadOwner(userId: number, thread: MessageThreadsInterface): boolean {
    return !!thread.attributes.thread_owner.find(
      (owner: { id: number; name: string; email: string }) => owner.id.toString() === userId.toString()
    );
  }

  public trackByThreadId(index: number, element: MessageThreadsInterface): number {
    return element.id;
  }

  public showParticipants(thread: MessageThreadsInterface): string {
    let participants = '';
    thread.relationships.participants.data.forEach((user: UserInterface, index: number) => {
      participants = index === 0 ? participants.concat(user.attributes.email) : participants.concat(', ' + user.attributes.email);
    });
    return participants;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
