import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { catchError, EMPTY, mergeMap, Observable, tap, throwError } from 'rxjs';
import { ActivityInterface } from '../../../models/interface/activity.interface';
import { StudyInterface } from '../../../models/interface/study/study.interface';
import { LessonInterface } from '../../../models/interface/lesson.interface';
import { UserService } from '../../../services/api/user/user.service';
import { UserInterface } from '../../../models/interface/user.interface';
import { AccountDeletionRequestInterface } from '../../../models/interface/account-deletion-request.interface';
import { HttpResponse } from '@angular/common/http';

export interface UsersState {
  memberStudies: StudyInterface[];
  registerPatientAsECoachResponse: any;
  addEcoachesToInterventionInstanceResponse: any;
  deleteEcoachesToInterventionInstanceResponse: any;
  studyActivities: ActivityInterface[];
  sendReminderEmailResponse: any;
  deleteUnverifiedPatientAccountResponse: any;
  allCollaborators: UserInterface[];
  assignRolesToUserAdminResponse: any;
  removeRolesFromUserAdminResponse: any;
  accountDeletionRequests: AccountDeletionRequestInterface[];
  rejectAccountDeletionRequestResponse: any;
  acceptAccountDeletionRequestResponse: any;
}

@Injectable()
export class UserStore extends ComponentStore<UsersState> {
  readonly memberStudies$: Observable<StudyInterface[]> = this.select(state => state.memberStudies, {
    debounce: true
  });

  readonly registerPatientAsECoachResponse$: Observable<any> = this.select(state => state.registerPatientAsECoachResponse, {
    debounce: true
  });

  readonly addEcoachesToInterventionInstanceResponse$: Observable<any> = this.select(
    state => state.addEcoachesToInterventionInstanceResponse,
    {
      debounce: true
    }
  );

  readonly deleteEcoachesToInterventionInstanceResponse$: Observable<any> = this.select(
    state => state.deleteEcoachesToInterventionInstanceResponse,
    {
      debounce: true
    }
  );

  readonly studyActivities$: Observable<any> = this.select(state => state.studyActivities, {
    debounce: true
  });

  readonly sendReminderEmailResponse$: Observable<any> = this.select(state => state.sendReminderEmailResponse, {
    debounce: true
  });

  readonly deleteUnverifiedPatientAccountResponse$: Observable<any> = this.select(state => state.deleteUnverifiedPatientAccountResponse, {
    debounce: true
  });

  readonly allCollaborators$: Observable<any> = this.select(state => state.allCollaborators, {
    debounce: true
  });

  readonly assignRolesToUserAdminResponse$: Observable<any> = this.select(state => state.assignRolesToUserAdminResponse, {
    debounce: true
  });

  readonly removeRolesFromUserAdminResponse$: Observable<any> = this.select(state => state.removeRolesFromUserAdminResponse, {
    debounce: true
  });

  readonly accountDeletionRequests$: Observable<any> = this.select(state => state.accountDeletionRequests, {
    debounce: true
  });

  readonly rejectAccountDeletionRequestResponse$: Observable<any> = this.select(state => state.rejectAccountDeletionRequestResponse, {
    debounce: true
  });

  readonly acceptAccountDeletionRequestResponse$: Observable<any> = this.select(state => state.acceptAccountDeletionRequestResponse, {
    debounce: true
  });

  readonly updateUserState = this.updater(
    (
      state,
      payload: {
        memberStudies?: StudyInterface[];
        registerPatientAsECoachResponse?: any;
        addEcoachesToInterventionInstanceResponse?: any;
        deleteEcoachesToInterventionInstanceResponse?: any;
        studyActivities?: ActivityInterface[];
        studyActivitiesQuestionnaires?: LessonInterface[];
        sendReminderEmailResponse?: any;
        deleteUnverifiedPatientAccountResponse?: any;
        allCollaborators?: UserInterface[];
        assignRolesToUserAdminResponse?: any;
        removeRolesFromUserAdminResponse?: any;
        accountDeletionRequests?: AccountDeletionRequestInterface[];
        rejectAccountDeletionRequestResponse?: any;
        acceptAccountDeletionRequestResponse?: any;
      }
    ) => ({
      memberStudies: payload.memberStudies ? payload.memberStudies : state.memberStudies,
      registerPatientAsECoachResponse: payload.registerPatientAsECoachResponse
        ? payload.registerPatientAsECoachResponse
        : state.registerPatientAsECoachResponse,
      addEcoachesToInterventionInstanceResponse: payload.addEcoachesToInterventionInstanceResponse
        ? payload.addEcoachesToInterventionInstanceResponse
        : state.addEcoachesToInterventionInstanceResponse,
      deleteEcoachesToInterventionInstanceResponse: payload.deleteEcoachesToInterventionInstanceResponse
        ? payload.deleteEcoachesToInterventionInstanceResponse
        : state.deleteEcoachesToInterventionInstanceResponse,
      studyActivities: payload.studyActivities ? payload.studyActivities : state.studyActivities,
      sendReminderEmailResponse: payload.sendReminderEmailResponse ? payload.sendReminderEmailResponse : state.sendReminderEmailResponse,
      deleteUnverifiedPatientAccountResponse: payload.deleteUnverifiedPatientAccountResponse
        ? payload.deleteUnverifiedPatientAccountResponse
        : state.deleteUnverifiedPatientAccountResponse,
      allCollaborators: payload.allCollaborators ? payload.allCollaborators : state.allCollaborators,
      assignRolesToUserAdminResponse: payload.assignRolesToUserAdminResponse
        ? payload.assignRolesToUserAdminResponse
        : state.assignRolesToUserAdminResponse,
      removeRolesFromUserAdminResponse: payload.removeRolesFromUserAdminResponse
        ? payload.removeRolesFromUserAdminResponse
        : state.removeRolesFromUserAdminResponse,
      accountDeletionRequests: payload.accountDeletionRequests ? payload.accountDeletionRequests : state.accountDeletionRequests,
      rejectAccountDeletionRequestResponse: payload.rejectAccountDeletionRequestResponse
        ? payload.rejectAccountDeletionRequestResponse
        : state.rejectAccountDeletionRequestResponse,
      acceptAccountDeletionRequestResponse: payload.acceptAccountDeletionRequestResponse
        ? payload.acceptAccountDeletionRequestResponse
        : state.acceptAccountDeletionRequestResponse
    })
  );

  readonly getMemberStudies = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) =>
        this.userService.getMemberStudies(payload.userId, payload.include, payload.type).pipe(
          tap({
            next: (result: any) => {
              this.updateUserState({ memberStudies: result.body.data });
            },
            error: e => throwError(e)
          }),
          catchError(error => EMPTY)
        )
      )
    )
  );

  readonly registerPatientAsECoach = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) => {
        this.updateUserState({ registerPatientAsECoachResponse: null });
        return this.userService.registerPatientAsECoach(payload.payload, payload.language).pipe(
          tap({
            next: (result: any) => this.updateUserState({ registerPatientAsECoachResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateUserState({ registerPatientAsECoachResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly addEcoachesToInterventionInstance = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) => {
        this.updateUserState({ addEcoachesToInterventionInstanceResponse: null });
        return this.userService.addEcoachesToInterventionInstance(payload.instanceId, payload.payload).pipe(
          tap({
            next: (result: any) => this.updateUserState({ addEcoachesToInterventionInstanceResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateUserState({ addEcoachesToInterventionInstanceResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly deleteEcoachesToInterventionInstance = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) => {
        this.updateUserState({ deleteEcoachesToInterventionInstanceResponse: null });
        return this.userService.deleteEcoachesFromInterventionInstance(payload.instanceId, payload.payload).pipe(
          tap({
            next: (result: any) => this.updateUserState({ deleteEcoachesToInterventionInstanceResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateUserState({ deleteEcoachesToInterventionInstanceResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly getActivitiesStudy = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) =>
        this.userService.getActivitiesStudy(payload.studyId, payload.currentStudyOnly, payload.action, payload.include).pipe(
          tap({
            next: (result: any) => {
              this.updateUserState({ studyActivities: result.body.data });
            },
            error: e => throwError(e)
          }),
          catchError(error => EMPTY)
        )
      )
    )
  );

  readonly getActivitiesOfMembers = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) =>
        this.userService.getActivitiesOfMembers(payload.action, payload.include).pipe(
          tap({
            next: (result: any) => {
              this.updateUserState({ studyActivities: result.body.data });
            },
            error: e => throwError(e)
          }),
          catchError(error => EMPTY)
        )
      )
    )
  );

  readonly sendReminderEmail = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) => {
        this.updateUserState({ sendReminderEmailResponse: null });
        return this.userService
          .sendReminderEmail(payload.userId, payload.questionnaireId, payload.interventionInstanceId, payload.language)
          .pipe(
            tap({
              next: (result: any) => this.updateUserState({ sendReminderEmailResponse: result }),
              error: e => throwError(e)
            }),
            catchError(error => {
              this.updateUserState({ sendReminderEmailResponse: error });
              return EMPTY;
            })
          );
      })
    )
  );

  readonly sendReminderToUnreadFeedback = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) => {
        this.updateUserState({ sendReminderEmailResponse: null });
        return this.userService.sendReminderToUnreadFeedback(payload.userId, payload.answersheetId, payload.language).pipe(
          tap({
            next: (result: any) => this.updateUserState({ sendReminderEmailResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateUserState({ sendReminderEmailResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly deleteUnverifiedPatientAccount = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) => {
        this.updateUserState({ deleteUnverifiedPatientAccountResponse: null });
        return this.userService.deleteUnverifiedPatientAccount(payload.userIds).pipe(
          tap({
            next: (result: any) => this.updateUserState({ deleteUnverifiedPatientAccountResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateUserState({ deleteUnverifiedPatientAccountResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly getAllCollaboratorsOfUser = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) =>
        this.userService.getAllCollaboratorsOfUser(payload.roles).pipe(
          tap({
            next: (result: any) => {
              this.updateUserState({ allCollaborators: result.body.data });
            },
            error: e => throwError(e)
          }),
          catchError(error => EMPTY)
        )
      )
    )
  );

  readonly assignRolesToUserAdmin = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) => {
        this.updateUserState({ assignRolesToUserAdminResponse: null });
        return this.userService.assignRolesToUserAdmin(payload.userId, payload.roleSlugs).pipe(
          tap({
            next: (result: any) => {
              this.updateUserState({ assignRolesToUserAdminResponse: result });
            },
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateUserState({ assignRolesToUserAdminResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly removeRolesFromUserAdmin = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) => {
        this.updateUserState({ removeRolesFromUserAdminResponse: null });
        return this.userService.removeRolesFromUserAdmin(payload.userId, payload.roleSlugs).pipe(
          tap({
            next: (result: any) => {
              this.updateUserState({ removeRolesFromUserAdminResponse: result.body.data });
            },
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateUserState({ removeRolesFromUserAdminResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly getDeletionRequests = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) =>
        this.userService.getDeletionRequests().pipe(
          tap({
            next: (result: any) => {
              this.updateUserState({ accountDeletionRequests: result.body.data });
            },
            error: e => throwError(e)
          }),
          catchError(error => EMPTY)
        )
      )
    )
  );

  readonly rejectDeletionRequest = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) => {
        this.updateUserState({ rejectAccountDeletionRequestResponse: null });
        return this.userService.rejectDeletionRequest(payload.requestId).pipe(
          tap({
            next: (result: any) => {
              this.updateUserState({ rejectAccountDeletionRequestResponse: result });
            },
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateUserState({ rejectAccountDeletionRequestResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly acceptDeletionRequestPseudonymise = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) => {
        this.updateUserState({ acceptAccountDeletionRequestResponse: null });
        return this.userService.acceptDeletionRequestPseudonymise(payload.userId).pipe(
          tap({
            next: (result: any) => this.updateUserState({ acceptAccountDeletionRequestResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateUserState({ acceptAccountDeletionRequestResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly acceptDeletionRequestDelete = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) => {
        this.updateUserState({ acceptAccountDeletionRequestResponse: null });
        return this.userService.acceptDeletionRequestDelete(payload.userId).pipe(
          tap({
            next: (result: any) => this.updateUserState({ acceptAccountDeletionRequestResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateUserState({ acceptAccountDeletionRequestResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  constructor(private userService: UserService) {
    super({
      memberStudies: [],
      registerPatientAsECoachResponse: [],
      addEcoachesToInterventionInstanceResponse: null,
      deleteEcoachesToInterventionInstanceResponse: null,
      studyActivities: [],
      sendReminderEmailResponse: null,
      deleteUnverifiedPatientAccountResponse: null,
      allCollaborators: [],
      assignRolesToUserAdminResponse: null,
      removeRolesFromUserAdminResponse: null,
      accountDeletionRequests: [],
      rejectAccountDeletionRequestResponse: null,
      acceptAccountDeletionRequestResponse: null
    });
  }
}
