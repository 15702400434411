import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BuddyInstanceInterface } from '../../../models/interface/buddy/buddy-instance.interface';
import { UserInterface } from '../../../models/interface/user.interface';
import { InterventionInstanceInterface } from '../../../models/interface/intervention-instances/intervention-instance.interface';
import { DiaryInstanceInterface } from '../../../models/interface/diary-instance.interface';
import { DiaryInterface } from '../../../models/interface/diary.interface';
import { InterventionInterface } from '../../../models/interface/intervention.interface';
import { HelperService } from '../../../services/helper/helper.service';
import { BehaviorSubject, Observable, skip, Subscription, take } from 'rxjs';
import { HelperActivityService } from '../../../services/helper/helper-activity/helper-activity.service';
import { BuddyActivityInterface } from '../../../models/interface/buddy/buddy-activity.interface';
import { BuddyStore } from '../../../store/buddy/component-store/buddy.store';
import { faBell } from '@fortawesome/free-regular-svg-icons/faBell';
import { faClock } from '@fortawesome/free-regular-svg-icons/faClock';

@Component({
  selector: 'app-dialog-buddy-instance-detail',
  templateUrl: './dialog-buddy-instance-detail.component.html',
  styleUrls: ['./dialog-buddy-instance-detail.component.css'],
  providers: [BuddyStore]
})
export class DialogBuddyInstanceDetailComponent implements OnInit, OnDestroy {
  // Icons
  faBell = faBell;
  faClock = faClock;

  public buddyInstance: BuddyInstanceInterface;
  public studyMembers: Array<UserInterface> = [];
  public interventions: Array<InterventionInterface>;
  public interventionInstances: Array<InterventionInstanceInterface> = [];
  public diaryInstances: Array<DiaryInstanceInterface> = [];
  public diaries: Array<DiaryInterface> = [];
  public studyCollaborators: Array<UserInterface> = [];
  public isManager: boolean;

  public activities: Array<BuddyActivityInterface> = [];
  public pagedActivitySubject: BehaviorSubject<
    Array<{ pair1: BuddyActivityInterface; pair2: BuddyActivityInterface; localizedDate: string }>
  > = new BehaviorSubject<Array<{ pair1: BuddyActivityInterface; pair2: BuddyActivityInterface; localizedDate: string }>>([]);
  public pagedActivity$: Observable<Array<{ pair1: BuddyActivityInterface; pair2: BuddyActivityInterface; localizedDate: string }>> =
    this.pagedActivitySubject.asObservable();

  public buddyActivities$: Observable<Array<BuddyActivityInterface>>;

  public buddyActivitiesPair: Array<{ pair1: BuddyActivityInterface; pair2: BuddyActivityInterface; localizedDate: string }> = [];

  public isLoadingActivities$: Observable<boolean>;

  // Translation
  public param1 = { buddy: '' };
  public param2 = { buddy: '' };

  public studyId: number;

  private isLoadingActivitiesSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private helperService: HelperService,
    private buddyStore: BuddyStore,
    private helperActivityService: HelperActivityService
  ) {
    this.isLoadingActivities$ = this.isLoadingActivitiesSubject.asObservable();
    this.buddyActivities$ = this.buddyStore.allBuddyActivitiesOfBuddy$;
  }

  public get helper() {
    return this.helperService;
  }

  public get helperActivity() {
    return this.helperActivityService;
  }

  ngOnInit(): void {
    this.buddyInstance = this.data.buddyInstance;
    this.studyMembers = this.data.members;
    this.interventions = this.data.interventions;
    this.interventionInstances = this.data.interventionInstances;
    this.diaryInstances = this.data.diaryInstances;
    this.diaries = this.data.diaries;
    this.studyCollaborators = this.data.studyCollaborators;
    this.isManager = this.data.isManager;
    this.studyId = this.data.studyId;

    if (this.isManager) {
      this.buddyStore.getBuddyActivitiesEM(this.buddyInstance.id);
    } else {
      this.buddyStore.getBuddyActivities(this.buddyInstance.id);
    }

    this.subscriptions.push(
      this.buddyActivities$.pipe(skip(1), take(1)).subscribe(
        (result: any) => {
          this.activities = result;
          this.activities = this.helper.getBuddyActivityByNewest(this.activities);
          this.pairBuddy();
          this.pagedActivitySubject.next(this.buddyActivitiesPair.slice(0, 20));
          this.isLoadingActivitiesSubject.next(false);
        },
        () => {
          this.activities = [];
          this.pagedActivitySubject.next([]);
          this.isLoadingActivitiesSubject.next(false);
        }
      )
    );
  }

  public updatePagedActivities(event: any) {
    if (event) {
      this.pagedActivitySubject.next(event);
    }
  }

  public hasSentReminder(buddyActivityPair: {
    pair1: BuddyActivityInterface;
    pair2: BuddyActivityInterface;
    localizedDate: string;
  }): boolean {
    const reminder1 = buddyActivityPair?.pair1?.attributes.daily_reminder_timestamps;
    const reminder2 = buddyActivityPair?.pair2?.attributes.daily_reminder_timestamps;
    return (!!reminder1 || !!reminder2) && reminder1 !== 0 && reminder2 !== 0;
  }

  public getBuddyState(buddyActivityPair: { pair1: BuddyActivityInterface; pair2: BuddyActivityInterface; localizedDate: string }): string {
    const task1 = buddyActivityPair?.pair1?.attributes.daily_task_timestamps;
    const task2 = buddyActivityPair?.pair2?.attributes.daily_task_timestamps;
    if (!!task1 && !!task2) {
      return 'both_daily_task_completed';
    } else if (!!task1 || !!task2) {
      return 'only_one_daily_task_completed';
    } else {
      return 'no_daily_task_completed';
    }
  }

  public getBuddySingleState(pair: BuddyActivityInterface): string {
    const task = pair.attributes.daily_task_timestamps;
    const reminder = pair.attributes.daily_reminder_timestamps;
    if (!!task && !!reminder) {
      return 'one_daily_task_completed_with_reminder';
    } else if (!!task) {
      return 'one_daily_task_completed';
    } else {
      return 'no_daily_task_completed';
    }
  }

  public pairBuddy(): void {
    const buddyActivitiesPair1: Array<{ pair1: BuddyActivityInterface; pair2: BuddyActivityInterface; localizedDate: string }> = [];
    this.activities.forEach((activity: BuddyActivityInterface) => {
      const localizeDate = this.helper.localizeDate(activity.attributes.created_at);
      if (!buddyActivitiesPair1.find(pair => pair.localizedDate.toString() === localizeDate.toString())) {
        const buddyAct: BuddyActivityInterface = this.activities.find(
          (buddyActivity: BuddyActivityInterface) =>
            this.helper.localizeDate(buddyActivity.attributes.created_at) === localizeDate &&
            buddyActivity.attributes.user_id.toString() !== activity.attributes.user_id.toString()
        );
        buddyActivitiesPair1.push({ pair1: activity, pair2: buddyAct, localizedDate: localizeDate });
      }
    });
    this.buddyActivitiesPair = buddyActivitiesPair1;
  }

  public getTranslation(userId?: number): { buddy: string } {
    if (userId === undefined) {
      return { buddy: 'System' };
    }
    const name =
      this.helper.findArrObjById(userId, this.studyMembers) !== null ? this.helper.getCodeNameEmail(userId, this.studyMembers) : 'System';
    return { buddy: name };
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
