/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { distinctUntilChanged, mergeMap, skip, switchMap, take } from 'rxjs/operators';
import { InterventionSharedService } from '../../../services/shared/intervention-shared/intervention-shared.service';
import { InterventionInterface } from '../../../models/interface/intervention.interface';
import { HelperService } from '../../../services/helper/helper.service';
import { DiaryInterface } from '../../../models/interface/diary.interface';
import { LessonInterface } from '../../../models/interface/lesson.interface';
import { DiaryStore } from '../../../store/diary/component-store/diary.store';
import { QuestionnaireStore } from '../../../store/lesson-questionnaire/component-store/lesson-questionnaire.store';

/**
 * Component:
 * Diary page displaying a list of diaries of the intervention;
 * Can be found: {uri}/intervention/{{intervention_id}}/diaries
 */

@Component({
  selector: 'app-intervention-diary',
  templateUrl: './intervention-diary.component.html',
  styleUrls: ['./intervention-diary.component.scss'],
  providers: [DiaryStore, QuestionnaireStore]
})
export class InterventionDiaryComponent implements OnInit, OnDestroy {
  @ViewChild('paginator') paginator;

  public isLoading$: Observable<boolean>;

  public questionnaires: Array<LessonInterface> = [];

  public diaries: Array<DiaryInterface> = [];

  public diaries$: Observable<Array<DiaryInterface>>;
  public pagedDiaries$: Observable<Array<DiaryInterface>>;

  // Filter
  public filter = { searchFilter: '' };

  // Translation for result
  public param = { result_value: 0 };

  public diariesOfStudy$: Observable<Array<DiaryInterface>>;

  public questionnairesOfIntervention$: Observable<Array<LessonInterface>>;

  // Loading
  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private intervention: InterventionInterface = null;
  private diaryIdsOfIntervention: Array<number> = [];
  // Pagination
  private searchText$ = new BehaviorSubject<string>('');
  // Intervention subject
  private diariesSubject: BehaviorSubject<Array<DiaryInterface>> = new BehaviorSubject([]);

  // Paged diaries
  private pagedDiariesSubject: BehaviorSubject<Array<DiaryInterface>> = new BehaviorSubject<Array<DiaryInterface>>([]);
  // Subscription handler
  private subscriptions: Subscription[] = [];

  constructor(
    private sharedService: InterventionSharedService,
    private questionnaireStore: QuestionnaireStore,
    private diaryStore: DiaryStore,
    private helperService: HelperService
  ) {
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.diaries$ = this.diariesSubject.asObservable();
    this.pagedDiaries$ = this.pagedDiariesSubject.asObservable();

    this.diariesOfStudy$ = this.diaryStore.diariesOfStudy$;
    this.questionnairesOfIntervention$ = this.questionnaireStore.questionnairesOfIntervention$;
  }

  public get helper() {
    return this.helperService;
  }

  ngOnInit(): void {
    // Loading members of study
    this.subscriptions.push(
      this.sharedService.intervention$
        .pipe(
          switchMap((intervention: InterventionInterface) => {
            this.intervention = intervention;
            this.diaryStore.getDiariesOfStudy({ studyId: this.intervention.attributes.study_id });
            return this.diariesOfStudy$.pipe(skip(1), take(1));
          }),
          mergeMap((result: any) => {
            this.diaries = result;
            this.questionnaireStore.getQuestionnairesIntervention({ interventionId: this.intervention.id });
            return this.questionnairesOfIntervention$.pipe(skip(1), take(1));
          })
        )
        .subscribe((result: any) => {
          // Get diaries unlocked by questionnaires
          this.questionnaires = result;
          this.questionnaires.forEach((questionnaire: LessonInterface) => {
            if (questionnaire.attributes.unlock_diaries !== null) {
              this.diaryIdsOfIntervention = this.diaryIdsOfIntervention.concat(questionnaire.attributes.unlock_diaries);
            }
          });

          // Get diaries unlocked by interventions
          if (this.intervention.attributes.unlock_diaries !== null) {
            this.diaryIdsOfIntervention = this.diaryIdsOfIntervention.concat(
              this.intervention.attributes.unlock_diaries.map(id => parseInt(id.toString(), 10))
            );
          }
          this.diaryIdsOfIntervention = this.diaryIdsOfIntervention.filter((value, index, array) => array.indexOf(value) === index);
          this.diaries = this.diaries.filter((value: DiaryInterface) =>
            this.diaryIdsOfIntervention.includes(parseInt(value.id.toString(), 10))
          );
          this.applyDiaryFilter();
        })
    );
  }

  public applyDiaryFilter(): void {
    this.isLoadingSubject.next(true);
    this.subscriptions.push(
      this.reloadDiaryList(true).subscribe((result: any) => {
        this.isLoadingSubject.next(false);
      })
    );
  }

  public reloadDiaryList(setFirst?: boolean): Observable<boolean> {
    const pageIndex = this.paginator?.paginator ? this.paginator?.paginator.pageIndex : 0;
    const pageSize = this.paginator?.paginator ? this.paginator?.paginator.pageSize : 20;
    this.diaryStore.getDiariesOfStudy({ studyId: this.intervention.attributes.study_id });
    return this.diariesOfStudy$.pipe(
      mergeMap((result: any) => {
        this.diaries = result;
        return this.search(this.filter['searchFilter']).pipe(
          mergeMap(() => this.helper.setPagedContent(this.diariesSubject, this.pagedDiariesSubject, setFirst, pageIndex, pageSize))
        );
      })
    );
  }

  public updateList(): void {
    this.subscriptions.push(this.reloadDiaryList().subscribe());
  }

  public updatePagedDiaries(event: any) {
    if (event) {
      this.pagedDiariesSubject.next(event);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  // Reads search value and runs searchFilter
  private search(name: string): Observable<boolean> {
    this.searchText$.next(name);
    return this.searchText$.pipe(
      distinctUntilChanged(),
      mergeMap(() => {
        this.diariesSubject.next(this.helper.filterDiariesBy(this.diaries, this.filter['searchFilter'].toLowerCase().trim()));
        return of(true);
      })
    );
  }
}
