<div class="container-fluid">
  <div class="row my-3">
    <div class="col-6">
      <div class="fixHeight">
        <div *ngIf="interventions.length > 0; then thenShowInterventionsInStudy else elseShowNoInterventionsInStudy"></div>
        <ng-template #thenShowInterventionsInStudy>
          <p><strong>{{'codebook.available_interventions' | translate}}:</strong></p>
          <ul class="list-group">
            <li [attr.id]="'intervention_' + intervention.id" *ngFor="let intervention of interventions" class="list-group-item mb-1">
              <div class="row mx-2 mb-2">
                <img
                  class="col-4"
                  [src]="helper.getAllMediaSupportPath(intervention.attributes.picture, intervention.id)"
                  (error)="helper.getFallbackImage($event)"
                  alt="No image"
                  class="image-parent img-fluid"
                />
                <div class="col-6">
                  <p>
                    <strong> {{(intervention.attributes.title) ? intervention.attributes.title : 'codebook.no_title' |translate}} </strong>
                  </p>
                  <p><small class="text-muted">({{intervention.attributes.name}}) (ID - {{intervention.id}})</small></p>
                </div>
                <div class="col-12">
                  <button (click)="getQuestionnairesOfIntervention(intervention.id)" mat-button>
                    {{'codebook.show_codebook' | translate}}
                  </button>

                  <button (click)="exportInterventionCodebook(intervention.id)" mat-button>
                    {{'codebook.export_intervention_codebook' | translate}}
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </ng-template>
        <ng-template #elseShowNoInterventionsInStudy>
          <p><strong>{{'codebook.no_intervention_study' | translate}}</strong></p>
        </ng-template>
      </div>
    </div>

    <div class="col-6">
      <div class="fixHeight">
        <div *ngIf="diaries.length > 0; then thenShowDiariesInStudy else elseShowNoDiariesInStudy"></div>
        <ng-template #thenShowDiariesInStudy>
          <p><strong>{{'codebook.available_diaries' | translate}}:</strong></p>
          <ul class="list-group">
            <li [attr.id]="'diary_' + diary.id" *ngFor="let diary of diaries" class="list-group-item mb-1">
              <div class="row mx-2 mb-2">
                <div class="col-10">
                  <p>
                    <strong> {{(diary.attributes.title) ? diary.attributes.title : 'codebook.no_title' |translate}} </strong>
                  </p>
                  <p><small class="text-muted">({{diary.attributes.name}}) (ID - {{diary.id}})</small></p>
                </div>
                <div class="col-12">
                  <button (click)="getQuestionnaireOfDiary(diary.id)" mat-button>{{'codebook.show_codebook' | translate}}</button>
                  <button (click)="exportDiaryCodebook(diary.id)" mat-button>{{'codebook.export_diary_codebook' | translate}}</button>
                </div>
              </div>
            </li>
          </ul>
        </ng-template>
        <ng-template #elseShowNoDiariesInStudy>
          <p><strong>{{'codebook.no_diary_study' | translate}}</strong></p>
        </ng-template>
      </div>
    </div>
  </div>
  <!--
        (keyup)="helper.onKeyFilterUser(ecoaches, filter['questionnaireSelection'], ecoachesSubject, selectedCollaborator)" -->
  <div class="row">
    <div class="col-sm-8">
      <mat-form-field class="full-width">
        <mat-label>{{'codebook.selected_questionnaire' | translate}}</mat-label>
        <mat-select
          (selectionChange)="changeSelection()"
          [attr.id]="'selectionQuestionnaire'"
          (click)="resetFilter()"
          [(ngModel)]="selectedQuestionnaire"
        >
          <mat-form-field class="full-width" appearance="fill">
            <mat-label>{{'organisation-collaborator.filter_selection' | translate}}</mat-label>
            <input [attr.id]="'questionnaireSelection'" autocomplete="off" matInput [(ngModel)]="filter['questionnaireSelection']" />
          </mat-form-field>
          <mat-option [attr.id]="'option_' + questionnaire?.id" *ngFor="let questionnaire of questionnaires" [value]="questionnaire"
            >{{questionnaire?.attributes.name}} (ID {{questionnaire?.id}})</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-sm-4">
      <mat-form-field class="full-width">
        <mat-label>{{'codebook.selected_language' | translate}}</mat-label>
        <mat-select [attr.id]="'selectedLanguage'" [(ngModel)]="selectedLanguage">
          <mat-form-field class="full-width" appearance="fill">
            <mat-label>{{'organisation-collaborator.filter_selection' | translate}}</mat-label>
            <input [attr.id]="'languageSelection'" autocomplete="off" matInput [(ngModel)]="filter['questionnaireSelection']" />
          </mat-form-field>
          <mat-option [attr.id]="'option_' + language" *ngFor="let language of languages" [value]="language">{{language}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="mat-elevation-z0 text-start mb-3" *ngIf="questionnaireElements.length > 0">
      <table [dataSource]="dataSource" mat-table>
        <ng-container matColumnDef="position">
          <th *matHeaderCellDef mat-header-cell>{{'codebook.position' | translate}}</th>
          <td *matCellDef="let element" mat-cell>{{element.position}}</td>
        </ng-container>
        <ng-container matColumnDef="questionType">
          <th *matHeaderCellDef mat-header-cell>{{'codebook.questiontype' | translate}}</th>
          <td *matCellDef="let element" mat-cell>{{'codebook.' + element.questiontype.toLowerCase() | translate}}</td>
        </ng-container>
        <ng-container matColumnDef="questionLabel">
          <th *matHeaderCellDef mat-header-cell>{{'codebook.questionlabel' | translate}}</th>
          <td *matCellDef="let element" mat-cell>{{element.label.toLowerCase()}}</td>
        </ng-container>
        <ng-container matColumnDef="question">
          <th *matHeaderCellDef mat-header-cell>{{'codebook.question' | translate}}</th>
          <td *matCellDef="let element" mat-cell>{{getCodebookQuestion(element.translations)}}</td>
        </ng-container>
        <ng-container matColumnDef="answer">
          <th *matHeaderCellDef mat-header-cell>{{'codebook.answer' | translate}}</th>
          <td *matCellDef="let element" mat-cell>{{getCodebookAnswer(element) | async}}</td>
        </ng-container>
        <ng-container matColumnDef="raw">
          <th *matHeaderCellDef mat-header-cell>{{'codebook.raw_data' | translate}}</th>
          <td *matCellDef="let element" mat-cell>{{getCodebookRawAnswer(element)}}</td>
        </ng-container>
        <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
        <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
      </table>
    </div>
  </div>
</div>
