<button [attr.id]="'btnDialogClose'" mat-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title>{{'patient.resend_verification' | translate}}</h1>
<div mat-dialog-content>
  <!-- ng-template to ensure that the generated components end up in this place -->
  <div *ngIf="submitted; else elseShowMessageBlock">
    <app-loader></app-loader>
  </div>
  <ng-template #elseShowMessageBlock>
    <app-alert></app-alert>
  </ng-template>
</div>
<div mat-dialog-actions align="end">
  <button [attr.id]="'btnCancel'" mat-button mat-dialog-close mat-dialog-close="true">{{'patient.modal_close' | translate}}</button>
</div>
