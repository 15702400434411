import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { iif, Observable, of } from 'rxjs';
import { catchError, skip, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { StudyActionTypes } from '../../../app/store/study/study.action';
import { StudyInterface } from '../../models/interface/study/study.interface';
import { getCollabGroupById } from '../../store/study/study.selector';
import { RoleInterface } from 'src/app/models/interface/role.interface';
import { HelperService } from 'src/app/services/helper/helper.service';

@Injectable({
  providedIn: 'root'
})
export class GroupPublisherGuard implements CanActivate {
  private group$: Observable<StudyInterface>;

  constructor(private router: Router, private store: Store, private helperService: HelperService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const groupId = parseInt(state.url.replace(/\D/g, ''), 10);
    this.group$ = this.store.select(getCollabGroupById(groupId));
    this.store.dispatch({
      type: StudyActionTypes.getCollaboratingStudiesType,
      payload: { include: `owners,roles` }
    });
    return this.group$.pipe(
      skip(1),
      take(1),
      switchMap((group: StudyInterface) => {
        if (group.attributes.type !== 'study') {
          return of(this.router.parseUrl(`/groups`));
        } else {
          if (group.relationships?.roles?.data) {
            const includedRoles: Array<RoleInterface> = group.relationships.roles.data;
            return iif(
              () => this.helperService.hasRoles(includedRoles, /study\.(publisher|ecoachmanager|owner)$/),
              of(true),
              of(this.router.parseUrl(`/groups/${groupId}`))
            );
          } else {
            return of(this.router.parseUrl(`/groups`));
          }
        }
      }),
      catchError(() => of(this.router.parseUrl('/home')))
    );
  }
}
