<div class="row mt-3">
  <div class="col-12 col-sm-3 mb-2">
    <button [attr.id]="'btnToggleFilter'" (click)="helper.toggleSubject(isCollapseSubject)" class="mt-1" mat-raised-button type="submit">
      <fa-icon [icon]="faList"></fa-icon>
      Filter
    </button>
  </div>

  <div class="col-12 col-sm-6 mb-2">
    <mat-form-field [style.width.%]="100" appearance="outline">
      <mat-label>{{'group-intervention-review.search_intervention' | translate}}</mat-label>
      <input
        [attr.id]="'search'"
        (keyup.enter)="applyInterventionFilter()"
        [(ngModel)]="filter['searchFilter']"
        aria-describedby="interventionSearch"
        aria-label="Search"
        matInput
        type="text"
      />
      <button [attr.id]="'btnSearchIntervention'" (click)="applyInterventionFilter()" aria-label="Search" mat-button mat-button matSuffix>
        <mat-icon>search</mat-icon>
      </button>
    </mat-form-field>
  </div>
</div>

<app-pagination-list
  #paginator
  (emitPageChange)="updatePagedInterventions($event)"
  [_isLoading]="isLoading$ | async"
  [_items]="interventions$ | async"
></app-pagination-list>

<!-- Filter content, Loading and Intervention list -->
<div class="row justify-content-center">
  <!-- Content of searchFilter - Selections, radio buttons -->
  <div *ngIf="(isCollapse$ | async) === false" class="col-sm-4">
    <div>
      <label id="filter-radio-group-label">{{'group-intervention-review.type_intervention' | translate}}</label>
      <mat-radio-group
        [attr.id]="'radioTypeOfIntervention'"
        aria-label="select guidance type"
        aria-labelledby="filter-radio-group-label"
        class="filter-radio-group"
        [(ngModel)]="filter['typeOfIntervention']"
      >
        <mat-radio-button [attr.id]="'radioItem0'" (change)="applyInterventionFilter()" class="filter-radio-button" value="0"
          >{{'group-intervention-review.no_selection' | translate}}</mat-radio-button
        >
        <mat-radio-button [attr.id]="'radioItem1'" (change)="applyInterventionFilter()" class="filter-radio-button" value="1"
          >{{'group-intervention-review.guided' | translate}}</mat-radio-button
        >
        <mat-radio-button [attr.id]="'radioItem2'" (change)="applyInterventionFilter()" class="filter-radio-button" value="2"
          >{{'group-intervention-review.unguided' | translate}}</mat-radio-button
        >
      </mat-radio-group>
    </div>
  </div>

  <!-- Loader -->
  <div *ngIf="(isLoading$ | async); else elseInterventionBlock" class="col-sm">
    <app-loader></app-loader>
  </div>

  <ng-template #elseInterventionBlock>
    <div class="col-12 col-sm-8">
      <!-- List of interventions -->
      <div *ngFor="let intervention of pagedInterventions$ | async; let i = index; trackBy: helper.trackByElement">
        <app-card-intervention
          (emitPublishingPerformed)="updateList()"
          [_intervention]="intervention"
          [_isCollab]="true"
        ></app-card-intervention>
      </div>
    </div>
  </ng-template>
</div>
