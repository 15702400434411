/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { InterventionInterface } from '../../../models/interface/intervention.interface';
import { SkillInterface } from '../../../models/interface/skill.interface';
import { HelperService } from '../../../services/helper/helper.service';

@Component({
  selector: 'app-skill-preview',
  templateUrl: './skill-preview.component.html',
  styleUrls: ['./skill-preview.component.scss']
})
export class SkillPreviewComponent implements OnInit, OnDestroy {
  // Data provided by SkillService
  public skill: SkillInterface;

  public intervention: InterventionInterface;

  constructor(private helperService: HelperService) {}

  // Helper function
  public get helper() {
    return this.helperService;
  }

  @Input()
  set _skill(_skill: SkillInterface) {
    this.skill = _skill;
  }

  @Input()
  set _intervention(_intervention: InterventionInterface) {
    this.intervention = _intervention;
  }

  ngOnInit(): void {}

  public getSkillIcon() {
    return this.helper.getAllMediaSupportPath(`uploads/studies/${this.intervention.attributes.study_id}/` + this.skill?.attributes.icon);
  }

  ngOnDestroy(): void {}
}
