/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { mergeMap } from 'rxjs/operators';
import { faBook } from '@fortawesome/free-solid-svg-icons/faBook';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faSquare } from '@fortawesome/free-solid-svg-icons/faSquare';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ThemePalette } from '@angular/material/core';
import { InterventionInterface } from '../../../models/interface/intervention.interface';
import { InterventionConfigurationInterface } from '../../../models/interface/intervention-configuration.interface';
import { HelperService } from '../../../services/helper/helper.service';
import { DiaryInterface } from '../../../models/interface/diary.interface';
import { LessonInterface } from '../../../models/interface/lesson.interface';
import { SkillInterface } from '../../../models/interface/skill.interface';
import { HelperDialogService } from '../../../services/helper/helper-dialog/helper-dialog.service';
import { HelperSkillService } from '../../../services/helper/helper-skill/helper-skill.service';

@Component({
  selector: 'app-instance-questionnaire-configuration',
  templateUrl: './configuration-create.component.html',
  styleUrls: ['./configuration-create.component.scss']
})
export class ConfigurationCreateComponent implements OnInit, OnChanges {
  // Icons
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;
  faBook = faBook;
  faEye = faEye;
  faSquare = faSquare;

  public intervention: InterventionInterface;

  public selectedGuidanceType = 'UNGUIDED';
  public questionnaires: Array<LessonInterface> = [];

  public skills: Array<SkillInterface> = [];
  public diaries: Array<DiaryInterface> = [];

  public unlockType: Array<string> = ['ALWAYS', 'MANUALLY', 'AFTER_PREVIOUS', 'AT_DATE'];
  public unlockTypeNew: Array<string> = ['ALWAYS', 'MANUALLY', 'AFTER_PREVIOUS', 'AT_DATE', 'CONDITIONAL'];
  public unlockTypeFirst: Array<string> = ['ALWAYS', 'AT_DATE'];
  public unlockTypeFirstNew: Array<string> = ['ALWAYS', 'AT_DATE', 'CONDITIONAL'];

  // Instance creation instanceForm
  public instanceFormType: UntypedFormGroup;
  public currentDate = this.helperService.formatDateToYYYY_MM_DD_THH_MM(Date.now());

  public formCustomOrderSubject = new BehaviorSubject<Array<{ questionnaire: number; enabled: boolean }>>([]);
  public updateView: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public showMoveShortCutSubject: BehaviorSubject<Array<boolean>> = new BehaviorSubject<Array<boolean>>([]);

  // Ngx datetimepicker
  public showSpinners = false;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate = new Date();
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';

  private interventionReadOnly: InterventionInterface;
  private defaultConfiguration: {
    questionnaire_configuration: Array<InterventionConfigurationInterface>;
    custom_order: Array<number>;
  };
  private defaultConfigurationReadOnly: {
    questionnaire_configuration: Array<InterventionConfigurationInterface>;
    custom_order: Array<number>;
  };

  constructor(
    private rootFormGroup: FormGroupDirective,
    private helperService: HelperService,
    private helperDialogService: HelperDialogService,
    private helperSkillService: HelperSkillService
  ) {}

  public get f() {
    return this.instanceFormType.controls;
  }

  public get helper() {
    return this.helperService;
  }

  public get helperSkill() {
    return this.helperSkillService;
  }

  public get helperDialog() {
    return this.helperDialogService;
  }

  public get fQuestionnaireConfiguration() {
    return this.instanceFormType.get('questionnaire_configuration') as UntypedFormArray;
  }

  // Set study and its configuration from parent
  @Input()
  set _intervention(_intervention: InterventionInterface) {
    this.interventionReadOnly = JSON.parse(JSON.stringify(_intervention));
    this.defaultConfigurationReadOnly = JSON.parse(JSON.stringify(_intervention.attributes.default_configuration));

    this.intervention = JSON.parse(JSON.stringify(_intervention));
    this.defaultConfiguration = JSON.parse(JSON.stringify(_intervention.attributes.default_configuration));

    if (this.intervention.attributes.intervention_type === 'accompanied') {
      if (!this.instanceFormType) {
        this.instanceFormType = this.rootFormGroup.control;
      }
      this.instanceFormType.controls['intervention_type'].setValue('GUIDED', { onlySelf: true });
      this.selectedGuidanceType = 'GUIDED';
    }
  }

  @Input()
  set _questionnaires(_questionnaires: Array<LessonInterface>) {
    if (_questionnaires) {
      this.questionnaires = _questionnaires;
    }
  }

  @Input()
  set _skills(_skills: Array<SkillInterface>) {
    if (_skills) {
      this.skills = _skills;
    }
  }

  @Input()
  set _diaries(_diaries: Array<DiaryInterface>) {
    if (_diaries) {
      this.diaries = _diaries;
    }
  }

  @Input()
  set _form_custom_orderSubject(_formCustomOrderSubject: BehaviorSubject<Array<{ questionnaire: number; enabled: boolean }>>) {
    if (_formCustomOrderSubject) {
      this.formCustomOrderSubject = _formCustomOrderSubject;
    }
  }

  ngOnInit(): void {
    if (!this.instanceFormType) {
      this.instanceFormType = this.rootFormGroup.control;
    }
    this.instanceFormType.controls['starts_at'].valueChanges.subscribe(value => {
      const startsAt = this.helperService.convertStringToUnixTimestamp(this.helper.formatDateToYYYY_MM_DD_THH_MM(this.f.starts_at.value));
      this.fQuestionnaireConfiguration.controls.forEach(formcontrol => {
        const unlockDate = this.helperService.convertStringToUnixTimestamp(new Date(formcontrol.get('unlock_date').value).toUTCString());
        if (unlockDate < startsAt) {
          formcontrol.get('unlock_date').patchValue(this.helper.formatDateToYYYY_MM_DD_THH_MM(this.f.starts_at.value), { onlySelf: true });
        }
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {}

  public changePosition(questionnaire: LessonInterface, questionnaireIndex: number, pos: number): void {
    // Move value to position
    const currentpos = this.formCustomOrderSubject.value.map(x => x.questionnaire).indexOf(parseInt(questionnaire.id.toString(), 10));
    // const newpos = event.target.value;
    const newpos = currentpos + pos;
    const currentarray = this.formCustomOrderSubject.value.map(x => x.questionnaire);
    const arrTemp = this.formCustomOrderSubject.value;
    this.formCustomOrderSubject.next(
      this.moveToPosition(currentarray, currentpos, newpos).map(x => ({
        questionnaire: x,
        enabled: arrTemp.find(a => a.questionnaire.toString() === x.toString()).enabled
      }))
    );
    // For each formcontrol update position value
    this.fQuestionnaireConfiguration.controls.forEach((formControl: AbstractControl) => {
      // Get questionnaire_id
      const foundIndex = this.formCustomOrderSubject.value
        .map(x => x.questionnaire)
        .indexOf(parseInt(formControl.get('questionnaire_id').value.toString(), 10));
      formControl.get('current_position').patchValue(foundIndex.toString(), { onlySelf: true });

      if (formControl.get('current_position').value.toString() === '0') {
        formControl.get('unlock_type').patchValue('ALWAYS', { onlySelf: true });
      }
    });

    this.sortByCurrentPosition();
    this.updateView.next('changePosition');
  }

  // Replace invalid position with string
  public replaceCurrentPosition(questionnaireId: string): string {
    const foundIndex = this.formCustomOrderSubject.value
      .filter((x: { questionnaire: number; enabled: boolean }) => x.enabled)
      .map((x: { questionnaire: number; enabled: boolean }) => x.questionnaire)
      .findIndex((x: number) => x.toString() === questionnaireId);
    return foundIndex.toString() === '-1'
      ? 'instance-creation-form.form_create_instance_pos_not_selected'
      : 'Position ' + (foundIndex + 1).toString();
  }

  public sortByCurrentPosition(): void {
    this.fQuestionnaireConfiguration.controls.sort(
      (a, b) => a.get('current_position').value.toString() - b.get('current_position').value.toString()
    );
  }

  public movePosition(event, questionnaire: LessonInterface, i: number) {
    const currentpos = this.formCustomOrderSubject.value.map(x => x.questionnaire).indexOf(parseInt(questionnaire.id.toString(), 10));
    const newpos = event.value;
    const currentarray = this.formCustomOrderSubject.value.map(x => x.questionnaire);
    const arrTemp = this.formCustomOrderSubject.value;
    this.formCustomOrderSubject.next(
      this.moveToPosition(currentarray, currentpos, newpos).map(x => ({
        questionnaire: x,
        enabled: arrTemp.find(a => a.questionnaire.toString() === x.toString()).enabled
      }))
    );
    // For each formcontrol update position value
    this.fQuestionnaireConfiguration.controls.forEach((formControl: AbstractControl) => {
      // Get questionnaire_id
      const foundIndex = this.formCustomOrderSubject.value
        .map(x => x.questionnaire)
        .indexOf(parseInt(formControl.get('questionnaire_id').value.toString(), 10));
      formControl.get('current_position').patchValue(foundIndex.toString(), { onlySelf: true });

      if (formControl.get('current_position').value.toString() === '0') {
        formControl.get('unlock_type').patchValue('ALWAYS', { onlySelf: true });
      }
    });

    this.sortByCurrentPosition();
    this.updateView.next('changePosition');
  }

  public getIndexOfElement(arr: Array<any>): Array<number> {
    return arr.map((value, index) => index);
  }

  // Check if lesson is added or removed from checkbox
  public checkEnabled(event, questionnaire: LessonInterface, i: number): void {
    const arrTemp = this.formCustomOrderSubject.value;
    arrTemp.forEach((value: { questionnaire: number; enabled: boolean }) => {
      if (value.questionnaire.toString() === questionnaire.id.toString()) {
        value.enabled = event.checked;
      }
    });
    this.formCustomOrderSubject.next(arrTemp);
    this.fQuestionnaireConfiguration.controls.forEach((formControl: AbstractControl) => {
      // Get questionnaire_id
      const foundIndex = this.formCustomOrderSubject.value
        .map(x => x.questionnaire)
        .indexOf(parseInt(formControl.get('questionnaire_id').value.toString(), 10));
      const found = this.formCustomOrderSubject.value.find(
        (value: { questionnaire: number; enabled: boolean }) =>
          value.questionnaire.toString() === formControl.get('questionnaire_id').value.toString()
      );
      formControl.get('current_position').patchValue(foundIndex.toString(), { onlySelf: true });
      formControl.get('current_enabled').patchValue(found.enabled, { onlySelf: true });
    });

    let isFirst = false;
    this.fQuestionnaireConfiguration.controls.forEach(formControl => {
      // Get questionnaire_id
      const questionnaireId = formControl.get('questionnaire_id').value;
      const foundValue = this.formCustomOrderSubject.value.find(
        (value: { questionnaire: number; enabled: boolean }) => value.questionnaire.toString() === questionnaireId.toString()
      );
      const foundIndex = this.formCustomOrderSubject.value.findIndex(
        (value: { questionnaire: number; enabled: boolean }) => value.questionnaire.toString() === questionnaireId.toString()
      );
      if (foundValue) {
        formControl.get('current_position').patchValue(foundIndex.toString(), { onlySelf: true });
        if (foundValue.enabled && !isFirst) {
          isFirst = true;
          if (
            !formControl
              .get('unlock_type')
              .value.toString()
              .match(/^(ALWAYS|AT_DATE)$/)
          ) {
            formControl.get('unlock_type').patchValue('ALWAYS', { onlySelf: true });
          }
        }
      }
    });

    if (event.checked) {
      this.updateView.next('checkEnabled');
    } else {
      this.updateView.next('disabledEnabled');
    }
    this.getInterventionDefaultConfiguration();
    this.sortByCurrentPosition();
  }

  public isOnlySelected(questionnaireId) {
    const arr = this.formCustomOrderSubject.value.filter((x: { questionnaire: number; enabled: boolean }) => x.enabled);
    const found = arr.find((x: { questionnaire: number; enabled: boolean }) => x.questionnaire.toString() === questionnaireId.toString());
    return arr.length === 1 && found ? '' : null;
  }

  public isFirstSelectedQuestionnaire(questionnaireId: number): boolean {
    const arr = this.formCustomOrderSubject.value.filter(x => x.enabled).map(x => x.questionnaire);
    return arr.findIndex(val => val.toString() === questionnaireId.toString()).toString() === '0';
  }

  public showUnlockHint(questionnaireId: number): Observable<boolean> {
    return this.updateView.pipe(
      mergeMap(() => {
        const foundControl = this.fQuestionnaireConfiguration.controls.find(
          (formcontrol: AbstractControl) => formcontrol.get('questionnaire_id').value.toString() === questionnaireId.toString()
        );
        if (!foundControl) {
          return of(false);
        }
        // Previous position based on form_custom_order
        const currentPost = this.formCustomOrderSubject.value
          .filter((x: { questionnaire: number; enabled: boolean }) => x.enabled)
          .map(x => x.questionnaire)
          .findIndex(value => value.toString() === questionnaireId.toString());
        const previousPos = currentPost - 1;
        let foundPredecessor;
        if (previousPos >= 0) {
          foundPredecessor = this.fQuestionnaireConfiguration.controls.find(
            (formcontrol: AbstractControl) =>
              formcontrol.get('questionnaire_id').value.toString() ===
              this.formCustomOrderSubject.value
                .filter((x: { questionnaire: number; enabled: boolean }) => x.enabled)
                .map(x => x.questionnaire)
                [previousPos].toString()
          );
        }
        if (foundPredecessor) {
          if (foundControl.get('unlock_type').value === 'AFTER_PREVIOUS' && foundPredecessor.get('feedback_required').value) {
            return of(true);
          }
        }
        return of(false);
      })
    );
  }

  public setDate(questionnaireId, i: number): string | Date {
    return new Date(new Date(this.f.starts_at.value).getTime() + this.getUnlockDaysAfterStart(questionnaireId) * 24 * 60 * 60);
  }

  public openDialogInterventionInstanceDiaryConfiguration(): void {
    this.helperDialog
      .openDialogInterventionInstanceDiaryConfiguration()
      .afterClosed()
      .subscribe(() => {});
  }

  public openDialogDiaryPreview(questionnaireId: number, studyId: number, diaryPageColor: string) {
    this.helperDialog
      .openDialogDiaryPreview(questionnaireId, studyId, diaryPageColor)
      .afterClosed()
      .subscribe(result => {});
  }

  public openDialogInterventionLesson(questionnaireId: number, studyId: number) {
    this.helperDialog
      .openDialogInterventionLesson(questionnaireId, studyId)
      .afterClosed()
      .subscribe(result => {});
  }

  public hasDefaultConditional(questionnaire_id: number): boolean {
    const config: InterventionConfigurationInterface = this.getDefaultConfigurationOfQuestionnaire(questionnaire_id);
    return config ? config.unlock_type.toLowerCase() === 'conditional' : false;
  }

  public getCondition(questionnaireId: number): string {
    const foundConfig: InterventionConfigurationInterface = this.getDefaultConfigurationOfQuestionnaire(questionnaireId);
    if (foundConfig !== null && foundConfig.condition) {
      if (Array.isArray(foundConfig.condition)) {
        if (foundConfig.condition.length > 0) {
          return (foundConfig.condition as Array<any>).reduce((prev, curr) => prev + ' (' + curr.description + ')', '');
        }
        return null;
      } else {
        return foundConfig.condition.description ? foundConfig.condition.description : JSON.stringify(foundConfig.condition);
      }
    }
    return null;
  }

  public openDialogSkill(skillId: number): void {
    const dialogRef = this.helperDialog.openDialogSkill(skillId, this.skills, this.intervention);
    if (dialogRef) {
      dialogRef.afterClosed().subscribe(result => {});
    }
  }

  // Set default configuration
  private getInterventionDefaultConfiguration(): void {
    this.intervention = JSON.parse(JSON.stringify(this.interventionReadOnly));
    this.defaultConfiguration = JSON.parse(JSON.stringify(this.defaultConfigurationReadOnly));
  }

  // Returns default unlock days of a questionnaire
  private getUnlockDaysAfterStart(questionnaireId: number): number {
    const foundDefaultConfig: InterventionConfigurationInterface = this.getDefaultConfigurationOfQuestionnaire(questionnaireId);
    return !foundDefaultConfig && !foundDefaultConfig.unlock_days_after_start
      ? parseInt(foundDefaultConfig.unlock_days_after_start.toString(), 10)
      : 0;
  }

  // Returns default configuration of a questionnaire
  private getDefaultConfigurationOfQuestionnaire(questionnaireId: number): InterventionConfigurationInterface {
    this.getInterventionDefaultConfiguration();
    const found = this.defaultConfiguration.questionnaire_configuration.find(
      (questionnaire: InterventionConfigurationInterface) => questionnaire.id.toString() === questionnaireId.toString()
    );
    return found ? found : null;
  }

  private moveToPosition(currentArray, currentPos, newPos) {
    if (newPos >= currentArray.length) {
      let k = newPos - currentArray.length + 1;
      while (k--) {
        // currentArray.push(undefined);
      }
    }
    currentArray.splice(newPos, 0, currentArray.splice(currentPos, 1)[0]);
    return currentArray;
  }
}
