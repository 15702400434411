/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CredentialInterface } from '../../../models/interface/credential.interface';
import { PayloadInterface } from '../../../models/interface/payload.interface';
import { environment } from '../../../../environments/environment';
import { RequestBodyData } from '../../../models/request-body.data';
import { CustomCookieService } from '../../customcookie/custom-cookie.service';
import { HelperService } from '../../helper/helper.service';

/**
 * Service:
 * Authentication API service that handles login, logout, password reset and token refresh
 */

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private backendUrl: string = environment.backendURL;
  private header: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(private http: HttpClient, private customCookieService: CustomCookieService, private helperService: HelperService) {
    this.header = this.helperService.setLocaleFromStorage(this.header);
  }

  /**
   * AAS2 API User - Auth Login
   * This function is a login request with user credentials (+ device token for notification) and returns a session token.
   *
   * @params string email - An email address as string.
   *         string password - A password as string.
   * @return Observable<any> - An observable for any response.
   */
  public login(email: string, password: string): Observable<any> {
    const credential: CredentialInterface = {
      email,
      password
    };
    return this.http.post<any>(`${this.backendUrl}/api/v1/auth/login`, new RequestBodyData('users', credential), {
      headers: this.header,
      observe: 'response',
      withCredentials: true
    });
  }

  /**
   * AAS2 API User - Refresh Token
   * This function refreshes the session token stored in the cookie.
   *
   * @return Observable<any> - An observable for any response.
   */
  public refreshToken(): Observable<any> {
    return this.http.post<any>(`${this.backendUrl}/api/v1/auth/refresh`, null, { headers: this.header, observe: 'response' });
  }

  /**
   * AAS2 API User - Auth Logout
   * This function is a logout request and deletes the session token.
   *
   * @return Observable<any> - An observable for any response.
   */
  public logout(): Observable<any> {
    return this.http.delete(`${this.backendUrl}/api/v1/auth/logout`, { observe: 'response' });
  }

  /**
   * AAS2 API User - Register
   * This function registers user and sends an email.
   *
   * @params string email - An email address as string.
   *         string password - A password as string.
   *         string passwordConfirm - A password confirmation as string.
   *         string name - A user name as string.
   *         any settings - An optional value that is saved as account-based setting.
   * @return Observable<any> - An observable for any response.
   */
  public register(email: string, password: string, passwordConfirm: string, name: string, settings?: any): Observable<any> {
    const reqBody: PayloadInterface = new RequestBodyData('users', {
      email,
      password,
      password_confirmation: passwordConfirm,
      name
    });
    return this.http.post<any>(`${this.backendUrl}/api/v1/auth/register`, reqBody, { headers: this.header, observe: 'response' });
  }

  /**
   * AAS2 API User - Resend Verification
   * This function resends a registration confirmation email to the user.
   *
   * @params string email  - An email address as string.
   * @return Observable<any> - An observable for any response.
   */
  public resendVerification(email: string): Observable<any> {
    const reqBody: PayloadInterface = new RequestBodyData('users', {
      email
    });
    return this.http.post<any>(`${this.backendUrl}/api/v1/auth/verify/resend`, reqBody, { headers: this.header, observe: 'response' });
  }

  /**
   * AAS2 API Admin - Reactivate a user account
   * This function restores a deleted user by email.
   *
   * @params string email  - An email address as string.
   * @return Observable<any> - An observable for any response.
   */
  public reactivateUser(email: string): Observable<any> {
    const reqBody: PayloadInterface = new RequestBodyData('users', {
      email
    });
    return this.http.post<any>(`${this.backendUrl}/api/v1/admin/auth/reactivate`, reqBody, { headers: this.header, observe: 'response' });
  }

  /**
   * @deprecated
   * AAS2 API Admin - Deactivate a user account
   * This function deactivates a user by user id.
   *
   * @params string email  - An email string value.
   * @return Observable<any> - An observable for any response.
   */
  public deactivateUser(email: string): Observable<any> {
    const reqBody: PayloadInterface = new RequestBodyData('users', {
      email
    });
    return this.http.post<any>(`${this.backendUrl}/api/v1/admin/auth/deactivate`, reqBody, { headers: this.header, observe: 'response' });
  }

  /**
   * AAS2 API Admin - Deactivate a user account
   * This function deactivates a user by user id.
   *
   * @params number userId  - A user id.
   * @return Observable<any> - An observable for any response.
   */
  public deactivateUserAdmin(userId: number): Observable<any> {
    return this.http.delete<any>(`${this.backendUrl}/api/v1/admin/users/${userId}`, { headers: this.header, observe: 'response' });
  }

  /**
   * AAS2 API User - Verify Token
   * This function verifies the registration token from the email.
   *
   * @params string registerToken  - A registration token from email.
   * @return Observable<any> - An observable for any response.
   */
  public verifyToken(registerToken: string): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/api/v1/auth/verify/token/${registerToken}`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API User - Request PW Reset Instructions
   * This function requests password reset instructions email.
   *
   * @return Observable<any> - An observable for any response.
   */
  public requestPasswordReset(email: string): Observable<any> {
    this.header = this.helperService.setLocaleFromStorage(this.header);
    const reqBody: PayloadInterface = new RequestBodyData('users', {
      email
    });
    return this.http.post<any>(`${this.backendUrl}/api/v1/auth/password/reset/instructions`, reqBody, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API Admin User - Create new user with roles
   * This function creates a new user account with roles as an admin
   *
   * @return Observable<any> - An observable for any response.
   */
  public createAccountAdmin(
    email: string,
    roleSlugs: Array<string>,
    name?: string,
    firstname?: string,
    lastname?: string
  ): Observable<any> {
    this.header = this.helperService.setLocaleFromStorage(this.header);
    const reqBody: PayloadInterface = new RequestBodyData('users', {
      email,
      role_slugs: roleSlugs
    });
    if (name) {
      reqBody.data.attributes.name = name;
    }
    if (firstname) {
      reqBody.data.attributes.firstname = firstname;
    }
    if (lastname) {
      reqBody.data.attributes.lastname = lastname;
    }
    return this.http.post<any>(`${this.backendUrl}/api/v1/admin/auth/assign/register`, reqBody, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * This function deletes the session cookie value and set the authentication state to false.
   * In case the logout fails, this will be performed.
   */
  public forcedLogout(): void {
    this.customCookieService.deleteJWTCookie();
  }

  public isTokenExpired(): boolean {
    return this.customCookieService.isJWTExpired();
  }
}
