<button [attr.id]="'btnDialogClose'" mat-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title>{{'organisation-collaborator.collaborators_add_modal_title' | translate:param}}</h1>
<div class="content-height" mat-dialog-content>
  <mat-tab-group (selectedIndexChange)="setSelectedTabIndex($event)" [selectedIndex]="selectedTabIndex" mat-elevation-z4 mat-stretch-tabs>
    <mat-tab label="{{'organisation-collaborator.add_new_study_collaborator' | translate}}">
      <div class="mt-2">
        <mat-form-field class="full-width">
          <mat-label>{{'organisation-collaborator.my_collaborators' | translate}}</mat-label>
          <mat-select [attr.id]="'selectionCollaborator'" (click)="resetFilter()" [(ngModel)]="selectedCollaborator">
            <mat-form-field class="full-width" appearance="fill">
              <mat-label>{{'organisation-collaborator.filter_selection' | translate}}</mat-label>
              <input
                [attr.id]="'userSelection'"
                autocomplete="off"
                matInput
                (keyup)="onKeyFilterECoaches()"
                [(ngModel)]="filter['userSelection']"
              />
            </mat-form-field>
            <mat-option [attr.id]="'option_' + user.id" *ngFor="let user of (ecoaches$ | async)" [value]="user"
              >{{user?.attributes.email}}</mat-option
            >
          </mat-select>
        </mat-form-field>

        <p class="col">{{'organisation-collaborator.collaborators_select_study_role' | translate}}:</p>
        <div class="col text-start">
          <mat-radio-group
            [attr.id]="'radioSelectedRole'"
            [(ngModel)]="selectedRole"
            aria-labelledby="radio-group-label"
            class="roles-radio-group"
          >
            <mat-radio-button [attr.id]="'radioItem_' + roles" *ngFor="let role of roles" [value]="role" class="roles-radio-button">
              {{helper.parseRoleSlug(role) | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>

        <ul>
          <li>
            <mat-checkbox [attr.id]="'checkboxIsReviewer'" [(ngModel)]="isReviewer">
              {{'organisation-collaborator.collaborator_set_reviewer' | translate}}
            </mat-checkbox>
          </li>
          <li>
            <mat-checkbox [attr.id]="'checkboxIsOwner'" [(ngModel)]="isOwner">
              {{'organisation-collaborator.collaborator_set_owner' | translate}}
            </mat-checkbox>
          </li>
        </ul>
      </div>
    </mat-tab>
    <mat-tab label="{{'organisation-collaborator.manage_study_collaborator' | translate}}">
      <div class="mt-2">
        <mat-form-field class="full-width">
          <mat-label>{{'organisation-collaborator.my_collaborators' | translate}}</mat-label>
          <mat-select [attr.id]="'selectionCollaborator'" (ngModelChange)="onChangeCollaborator($event)" [(ngModel)]="selectedCollaborator">
            <mat-form-field class="full-width" appearance="fill">
              <mat-label>{{'organisation-collaborator.filter_selection' | translate}}</mat-label>
              <input
                [attr.id]="'userSelection'"
                autocomplete="off"
                matInput
                (keyup)="onKeyFilterCollaborators()"
                [(ngModel)]="filter['userSelection']"
              />
            </mat-form-field>
            <mat-option [attr.id]="'option_' + user.id" *ngFor="let user of collaborators" [value]="user"
              >{{user?.attributes.email}}</mat-option
            >
          </mat-select>
        </mat-form-field>

        <p class="col">{{'organisation-collaborator.collaborators_select_study_role' | translate}}:</p>
        <div class="col text-start">
          <mat-radio-group
            [attr.id]="'radioSelectedRole'"
            [(ngModel)]="selectedRole"
            aria-labelledby="radio-group-label"
            class="roles-radio-group"
          >
            <mat-radio-button [attr.id]="'radioItem_' + role" *ngFor="let role of roles" [value]="role" class="roles-radio-button">
              {{helper.parseRoleSlug(role) | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>

        <ul>
          <li>
            <mat-checkbox [attr.id]="'checkboxIsReviewerCollaborator'" [(ngModel)]="isReviewerCollaborator">
              {{'organisation-collaborator.collaborator_set_reviewer' | translate}}
            </mat-checkbox>
          </li>
          <li>
            <mat-checkbox [attr.id]="'checkboxIsOwnerCollaborator'" [(ngModel)]="isOwnerCollaborator">
              {{'organisation-collaborator.collaborator_set_owner' | translate}}
            </mat-checkbox>
          </li>
        </ul>

        <p *ngIf="isNotRemovableSubject | async" class="text-danger">
          {{'intervention-collaborator.cannot_remove_collaborator_role' | translate }}
        </p>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<div mat-dialog-actions [ngSwitch]="selectedTabIndex" align="end">
  <button [attr.id]="'btnCancel'" mat-button mat-dialog-close>{{'organisation-collaborator.cancel' | translate}}</button>
  <app-button-feedback
    [_setId]="'btnAddCollaborator'"
    *ngSwitchCase="0"
    (click)="addCollaborator()"
    [_defaultButtonText]="'organisation-collaborator.collaborators_add'"
    [_responseText]="addCollaboratorResponse | async"
  ></app-button-feedback>
  <app-button-feedback
    [_setId]="'btnRemoveCollaborator'"
    *ngSwitchCase="1"
    (click)="removeCollaborator()"
    [_defaultButtonText]="'organisation-collaborator.collaborators_remove'"
    [_responseText]="removeCollaboratorResponse | async"
  ></app-button-feedback>
  <app-button-feedback
    [_setId]="'btnRemoveCollaborator'"
    *ngSwitchCase="1"
    (click)="updateCollaborator()"
    [_defaultButtonText]="'organisation-collaborator.collaborators_update'"
    [_responseText]="updateCollaboratorResponse | async"
  ></app-button-feedback>
</div>
