<div class="container-fluid">
  <div *ngIf="(isLoading$ | async); then thenLoadingBlock else elseAnswersheetContent"></div>

  <ng-template #thenLoadingBlock>
    <app-loader></app-loader>
  </ng-template>

  <ng-template #elseAnswersheetContent>
    <div class="jumbotron text-center hoverable p-4 row justify-content-center">
      <div class="col-12 col-sm-10">
        <mat-card appearance="outlined" class="card mx-auto mb-3">
          <mat-card-header class="card-header-center">
            <mat-card-title>{{'answersheets.answersheet_of_lesson' | translate}} '{{lesson.attributes.name}}' </mat-card-title>
            <mat-card-subtitle>
              <div
                *ngIf="helperFeedback.isFeedbackRequired(answersheet.id, instance); then thenShowIsRequired else elseShowNotRequired"
              ></div>
              <ng-template #thenShowIsRequired>
                <span class="font-weight-bold text-danger">
                  <fa-icon [icon]="faComment"></fa-icon>
                  {{'answersheet-detail.answersheet_feedback_mandatory' | translate}}
                </span>
              </ng-template>
              <ng-template #elseShowNotRequired>
                <span class="font-weight-light text-info">
                  <fa-icon [icon]="faCommentSlash"></fa-icon>
                  {{'answersheet-detail.answersheet_feedback_optional' | translate}}
                </span>

                <span *ngIf="(isDiary$ | async) === false; then thenShowSupport"></span>
                <ng-template #thenShowSupport>
                  |
                  <span
                    *ngIf="instance.attributes.intervention_type.toString().toUpperCase() === 'GUIDED'; then thenShowGuidedIcon else elseShowUnguidedIcon"
                  ></span>
                  <ng-template #thenShowGuidedIcon>
                    <fa-icon [icon]="faHandsHelping"></fa-icon>
                  </ng-template>
                  <ng-template #elseShowUnguidedIcon>
                    <fa-icon [icon]="faHands"></fa-icon>
                  </ng-template>
                  {{('answersheet-detail.' + instance.attributes.intervention_type.toString().toLowerCase()) | translate}}
                </ng-template>
              </ng-template>
            </mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <div class="row text-start">
              <div class="col-12 col-sm-4"><strong>{{'answersheet-detail.title' | translate}}:</strong></div>
              <div class="col-12 col-sm-8"><p>{{lesson.attributes.title}}</p></div>

              <div class="col-12 col-sm-4"><strong>{{'answersheet-detail.description' | translate}}:</strong></div>
              <div class="col-12 col-sm-8"><p>{{lesson.attributes.description}}</p></div>
              <div class="my-2">
                <p class="text-success">
                  <strong>{{'answersheet-detail.answersheet_submitted_by_and_collected_on' | translate:param}}</strong>
                </p>
              </div>
            </div>
          </mat-card-content>
          <mat-card-actions [align]="'end'">
            <button
              [attr.id]="'btnGetDetailedIntervention_' + instance.attributes.intervention_id"
              (click)="getDetailedIntervention(instance.attributes.intervention_id)"
              class="mb-2"
              mat-button
            >
              <fa-icon [icon]="faFolder"></fa-icon>
              {{'intervention.to_intervention' | translate}}
            </button>
            <button
              [attr.id]="'btnOpenDialogInterventionIntDetails_' + answersheet.attributes.intervention_instance_id"
              (click)="openDialogInterventionInstanceDetails(answersheet.attributes.intervention_instance_id)"
              *ngIf="answersheet.attributes.intervention_instance_id !== null"
              class="mb-2"
              mat-button
              type="button"
            >
              <fa-icon [icon]="faInfoCircle"></fa-icon>
              {{'answersheet-detail.modal_detail_instance_details' | translate}}
            </button>
          </mat-card-actions>
        </mat-card>

        <div #topPage class="card">
          <div class="card-header">
            <button
              id="btnShowPreviousAnswersheet"
              *ngIf="hasPreviousAnswersheet(answersheet.id, answersheets)"
              (click)="getAnswersheet(answersheet.id, answersheets, 'previous')"
              color="primary"
              mat-button
            >
              <fa-icon [icon]="faArrowLeft"></fa-icon>
              {{'answersheet-detail.answersheet_show_previous' | translate}}
            </button>
            <button
              id="btnShowNextAnswersheet"
              *ngIf="hasNextAnswersheet(answersheet.id, answersheets)"
              (click)="getAnswersheet(answersheet.id, answersheets, 'next')"
              color="primary"
              mat-button
            >
              {{'answersheet-detail.answersheet_show_next' | translate}}
              <fa-icon [icon]="faArrowRight"></fa-icon>
            </button>
          </div>
          <!-- Lesson with answers -->
          <app-questionnaire-preview
            [_background_color]="backgroundColor"
            [_elementsType]="'questionnaires'"
            [_lesson]="lesson"
            [_studyId]="intervention?.attributes.study_id"
            [_answers]="answersheet?.attributes.answers"
          ></app-questionnaire-preview>
        </div>
      </div>
    </div>

    <app-answersheet-feedback
      [_answersheet]="answersheet"
      [_instance]="instance"
      [_lesson]="lesson"
      [_questionnaires]="questionnaires"
      [_intervention]="intervention"
    ></app-answersheet-feedback>
  </ng-template>
</div>
