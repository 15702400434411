import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, iif, mergeMap, Observable, of, skip, Subscription, take, throwError } from 'rxjs';
import { PayloadInterface } from '../../../models/interface/payload.interface';
import { RequestBodyData } from '../../../models/request-body.data';
import { UserInterface } from '../../../models/interface/user.interface';
import { StudyStore } from '../../../store/study/component-store/study.store';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-dialog-group-member-remove',
  templateUrl: './dialog-group-member-remove.component.html',
  styleUrls: ['./dialog-group-member-remove.component.scss'],
  providers: [StudyStore]
})
export class DialogGroupMemberRemoveComponent implements OnInit, OnDestroy {
  public member: UserInterface;
  public param;
  public studyId: number;
  public code: string;

  public removeMemberResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');

  private deleteMembersResponse$: Observable<any>;

  // Subscription Handler
  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private studyStore: StudyStore,
    private dialogRef: MatDialogRef<DialogGroupMemberRemoveComponent>
  ) {
    this.deleteMembersResponse$ = this.studyStore.deleteMembersResponse$;
  }

  ngOnInit(): void {
    this.member = this.data.member;
    this.param = this.data.param;
    this.studyId = this.data.studyId;
    this.code = this.data.code;
  }

  // Removing collaborator from study
  public removeMember(userId: number): void {
    if (this.removeMemberResponse.value === 'DEFAULT') {
      const payload: PayloadInterface = new RequestBodyData('users', { users: [{ id: userId }] });
      this.removeMemberResponse.next('LOADING');
      this.studyStore.deleteMembers({ studyId: this.studyId, payload });
      this.subscriptions.push(
        this.deleteMembersResponse$
          .pipe(
            skip(1),
            take(1),
            mergeMap((result: any) => iif(() => result instanceof HttpResponse, of(result), throwError(result)))
          )
          .subscribe(
            () => {
              this.removeMemberResponse.next('SUCCESS');
            },
            () => {
              this.removeMemberResponse.next('FAILURE');
              setTimeout(() => {
                this.removeMemberResponse.next('DEFAULT');
              }, 2500);
            },
            () => {
              setTimeout(() => {
                this.removeMemberResponse.next('DEFAULT');
                this.dialogRef.close('SUCCESS');
              }, 2500);
            }
          )
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
