import { Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, distinctUntilChanged, Observable, Subscription } from 'rxjs';
import { AnswersheetAnswerInterface } from '../../../../models/interface/answersheet-answer.interface';
import { ConditionalblockInterface } from '../../../../models/interface/condition/conditionalblock.interface';
import { ElementInterface } from '../../../../models/interface/elements/element.interface';
import { EvaluationService } from '../../../../services/evaluation/evaluation.service';
import { QuestionTextDateInterface } from '../../../../models/interface/elements-question/question-text-date/question-text-date.interface';
import { TextDateInterface } from '../../../../models/interface/elements-question/question-text-date/text-date.interface';
import { DateAdapter, ThemePalette } from '@angular/material/core';
import { de, enGB } from 'date-fns/locale';
import { Store } from '@ngrx/store';
import { DynamicAnswersActionTypes } from '../../../../store/dynamic-answers/dynamic-answers.action';
import { HelperService } from '../../../../services/helper/helper.service';

/**
 * Component:
 * Represents date element
 */

@Component({
  selector: 'app-question-text-date',
  templateUrl: './question-text-date.component.html',
  styleUrls: ['./question-text-date.component.scss', '../../../../../assets/styles/aas2_app.scss']
})
export class QuestionTextDateComponent implements OnInit, OnDestroy {
  @ViewChild('confirmBtnTmpl') public confirmBtnTmpl: TemplateRef<any>;
  @ViewChild('cancelBtnTmpl') public cancelBtnTmpl: TemplateRef<any>;
  @ViewChild('answerRequired', { read: ElementRef, static: false }) answerRequiredRef: ElementRef;

  public question = '';
  public answer;
  public required = false;
  public isDisabled = false;

  public type: string;

  public allElements = [];
  public element: QuestionTextDateInterface | ElementInterface;
  public questionSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

  // Fixes issues displaying ion-picker in ion-datetime
  customPickerOptions = {
    cssClass: 'custom-picker'
  };

  // Values to evaluate condition
  public condition: Array<ConditionalblockInterface> = [];

  public evaluation$: Observable<boolean>;
  public hidden$: Observable<boolean>;
  public noAnswerProvided$: Observable<boolean>;

  // Ngx datetimepicker
  public showSpinners = false;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate = new Date();
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';

  private answers: Array<AnswersheetAnswerInterface> = [];
  private content: TextDateInterface;

  private dynamicAnswers: Array<AnswersheetAnswerInterface> = [];
  private dynamicAnswers$: Observable<Array<AnswersheetAnswerInterface>>;

  private lessonLocale$: Observable<any>;

  private iteration = 0;
  private nested = 0;

  private expressionSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private evaluationSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private hiddenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private noAnswerProvidedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private pageNumber: number;
  private currentPage: number;

  private subscriptions: Subscription[] = [];

  constructor(
    private translateService: TranslateService,
    private evaluationService: EvaluationService,
    private helperService: HelperService,
    private renderer: Renderer2,
    private adapter: DateAdapter<any>,
    private store: Store<{ dynamicAnswers: Array<AnswersheetAnswerInterface>; lessonLocale: any }>
  ) {
    this.dynamicAnswers$ = store.select('dynamicAnswers');
    this.lessonLocale$ = store.select('lessonLocale');
    this.adapter.setLocale(de);
    this.evaluation$ = this.evaluationSubject.asObservable();
    this.hidden$ = this.hiddenSubject.asObservable();
    this.noAnswerProvided$ = this.noAnswerProvidedSubject.asObservable();
  }

  @Input()
  set _element(_element: QuestionTextDateInterface | ElementInterface) {
    this.element = _element;
    if ((_element.values as Array<string>).length > 0) {
      this.type = _element.values[0];
    } else {
      this.type = 'date';
    }
  }

  @Input()
  set _iteration(_iteration: number) {
    this.iteration = _iteration;
  }

  @Input()
  set _answers(_answers: Array<AnswersheetAnswerInterface>) {
    if (_answers) {
      this.answers = _answers;
      this.isDisabled = true;
    }
  }

  // Block condition
  @Input()
  set _condition(_condition: Array<ConditionalblockInterface>) {
    this.condition = _condition;
  }

  @Input()
  set _page(_page) {
    if (_page !== undefined) {
      this.pageNumber = _page;
      if (this.currentPage !== undefined) {
        this.hiddenSubject.next(this.currentPage.toString() !== this.pageNumber.toString());
      }
    }
  }

  @Input()
  set _currentPage(_currentPage) {
    if (_currentPage !== undefined) {
      this.currentPage = _currentPage;
      if (this.pageNumber !== undefined) {
        this.hiddenSubject.next(this.currentPage.toString() !== this.pageNumber.toString());
      }
    }
  }

  @Input()
  set _allElements(_allElements) {
    if (_allElements !== undefined) {
      this.allElements = _allElements;
    }
  }

  ngOnInit() {
    this.subscriptions.push(
      this.dynamicAnswers$.pipe(distinctUntilChanged()).subscribe(dynamicAnswers => {
        this.dynamicAnswers = dynamicAnswers;
        this.questionSubject.next(
          this.evaluationService.replaceReference(
            this.question,
            this.allElements,
            localStorage.getItem('lessonLocale'),
            this.answers,
            this.dynamicAnswers
          )
        );
        if (this.element.position) {
          this.setAnswersFromDynamicAnswers(dynamicAnswers);
        }
        this.evaluationService.evaluateExpression(
          this.expressionSubject,
          this.evaluationSubject,
          this.condition,
          this.element.position,
          this.answers,
          this.dynamicAnswers
        );
      })
    );

    // Assign translation
    this.content = this.findTranslation();
    this.setTextDate(this.content);
    this.setAnswersFromAnswersheet(this.answers);
    this.subscriptions.push(
      this.translateService.onLangChange.subscribe(
        (event: LangChangeEvent) => {
          if (event.lang === 'de') {
            this.adapter.setLocale(de);
          } else {
            this.adapter.setLocale(enGB);
          }
          this.content = this.findTranslation();
          this.setTextDate(this.content);
        },
        error => {
          console.error(error);
        }
      )
    );
    this.subscriptions.push(
      this.lessonLocale$.subscribe(() => {
        if (localStorage.getItem('lessonLocale') === 'de') {
          this.adapter.setLocale(de);
        } else {
          this.adapter.setLocale(enGB);
        }
        this.content = this.findTranslation();
        this.setTextDate(this.content);
      })
    );
    this.evaluationService.evaluateExpression(
      this.expressionSubject,
      this.evaluationSubject,
      this.condition,
      this.element.position,
      this.answers,
      this.dynamicAnswers
    );
  }

  public injectStyle(): void {
    try {
      this.renderer.setStyle(this.answerRequiredRef.nativeElement.shadowRoot.childNodes[0], 'background-color', 'transparent');
    } catch (TypeError) {
      setTimeout(() => {
        this.injectStyle();
      }, 100);
    }
  }

  public setAnswersFromAnswersheet(answers: Array<AnswersheetAnswerInterface>): void {
    const element = answers.find(
      (answer: AnswersheetAnswerInterface) =>
        answer.position.toString() === this.element.position.toString() && answer.parentLoopIndex.toString() === this.iteration.toString()
    );
    if (element) {
      this.answer = element.value;
      if (element.value !== null) {
        const tempAnswer = new Date(new Date(element.value).getTime() + new Date(element.value).getTimezoneOffset() * 60000);
        this.answer =
          this.type === 'time'
            ? new Date(tempAnswer).getHours().toString().concat(':', new Date(tempAnswer).getMinutes().toString())
            : tempAnswer;
      } else {
        this.noAnswerProvidedSubject.next(true);
      }
    }
  }

  public setAnswersFromDynamicAnswers(answers: Array<AnswersheetAnswerInterface>): void {
    const element = answers.find(
      (answer: AnswersheetAnswerInterface) =>
        answer.position.toString() === this.element.position.toString() && answer.parentLoopIndex.toString() === this.iteration.toString()
    );
    if (element) {
      this.answer =
        this.type === 'time'
          ? new Date(element.value).getHours().toString().concat(':', new Date(element.value).getMinutes().toString()).toString()
          : new Date(element.value);
    }
  }

  public onChange(event) {
    let date;
    if (this.type === 'time') {
      const hour = parseInt(this.answer.substring(0, 2), 10);
      const minutes = parseInt(this.answer.substring(this.answer.length - 2), 10);
      date = new Date().setHours(hour, minutes, 0);
      date = new Date(date);
    } else {
      date = this.answer;
    }
    const submittedAnswer: AnswersheetAnswerInterface = {
      value: this.helperService.formatDateToYYYY_MM_DD_THH_MM(date),
      position: this.element.position,
      loop: false,
      parentLoopIndex: this.iteration + this.nested
    };
    const findElementIndex = this.dynamicAnswers.findIndex(
      (value: AnswersheetAnswerInterface) =>
        value.position.toString() === submittedAnswer.position.toString() &&
        value.parentLoopIndex.toString() === submittedAnswer.parentLoopIndex.toString()
    );
    if (findElementIndex > -1) {
      const temp = [...this.dynamicAnswers];
      temp[findElementIndex] = submittedAnswer;
      this.dynamicAnswers = temp;
    } else {
      this.dynamicAnswers = [...this.dynamicAnswers, submittedAnswer];
    }
    this.store.dispatch({
      type: DynamicAnswersActionTypes.addDynamicAnswersType,
      payload: this.dynamicAnswers
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  private findTranslation() {
    const _content = this.element.translations.find(translation => translation.locale === localStorage.getItem('lessonLocale'));
    return _content ? _content : this.element.translations[0];
  }

  private setTextDate(content: TextDateInterface) {
    this.question = content.question;
    this.questionSubject.next(
      this.evaluationService.replaceReference(
        this.question,
        this.allElements,
        localStorage.getItem('lessonLocale'),
        this.answers,
        this.dynamicAnswers
      )
    );
  }
}
