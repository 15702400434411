import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { OrganisationInterface } from '../../../models/interface/organisation/organisation.interface';

@Injectable({
  providedIn: 'root'
})
export class OrganisationSharedService {
  public sourceOrganisation$: Observable<OrganisationInterface>;
  public isCollaborator$: Observable<boolean>;

  private sourceOrganisationSubject = new BehaviorSubject<OrganisationInterface>(null);
  private isCollaboratorSubject = new BehaviorSubject<boolean>(false);

  constructor() {
    this.sourceOrganisation$ = this.sourceOrganisationSubject.asObservable();
    this.isCollaborator$ = this.isCollaboratorSubject.asObservable();
  }

  pushIsCollaborator(value: boolean) {
    this.isCollaboratorSubject.next(value);
  }

  pushOrganisation(organisation: OrganisationInterface) {
    this.sourceOrganisationSubject.next(organisation);
  }
}
