import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstanceCreationConfirmationComponent } from './instance-creation-confirmation/instance-creation-confirmation.component';
import { InstanceCreationFormComponent } from './instance-creation-form/instance-creation-form.component';
import { InstanceDeletionComponent } from './instance-deletion/instance-deletion.component';
import { InstanceDetailComponent } from './instance-detail/instance-detail.component';
import { InstanceUpdateConfirmationComponent } from './instance-update-confirmation/instance-update-confirmation.component';
import { InstanceUpdateFormComponent } from './instance-update-form/instance-update-form.component';
import { InstanceUpdateInformationComponent } from './instance-update-information/instance-update-information.component';
import { MaterialModule } from '../material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { UtilsModule } from '../utils/utils.module';
import { InstanceCreationConfirmationDialogComponent } from './instance-creation-confirmation-dialog/instance-creation-confirmation-dialog.component';
import { DirectiveModule } from '../directive/directive.module';
import { EffectsModule } from '@ngrx/effects';
import { HydrationEffects } from '../../store/hydration/hydration.effects';
import { ConfigurationCreateComponent } from './instance-questionnaire-configuration-create/configuration-create.component';
import { ConfigurationUpdateComponent } from './instance-questionnaire-configuration-update/configuration-update.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export const createTranslateLoader = (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json');

@NgModule({
  declarations: [
    InstanceCreationConfirmationComponent,
    InstanceCreationFormComponent,
    InstanceDeletionComponent,
    InstanceDetailComponent,
    InstanceUpdateConfirmationComponent,
    InstanceUpdateFormComponent,
    InstanceUpdateInformationComponent,
    InstanceCreationConfirmationDialogComponent,
    ConfigurationCreateComponent,
    ConfigurationUpdateComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FontAwesomeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    UtilsModule,
    DirectiveModule,
    EffectsModule.forFeature([HydrationEffects])
  ],
  exports: [
    InstanceCreationConfirmationComponent,
    InstanceCreationFormComponent,
    InstanceDeletionComponent,
    InstanceDetailComponent,
    InstanceUpdateConfirmationComponent,
    InstanceUpdateFormComponent,
    InstanceUpdateInformationComponent,
    InstanceCreationConfirmationDialogComponent,
    ConfigurationCreateComponent,
    ConfigurationUpdateComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class InterventionInstanceModule {}
