import { createReducer, on } from '@ngrx/store';
import { MessageThreadsInterface } from '../../models/interface/message_threads.interface';
import { addAllMessageThreadsStore, updateAllMessageThreadsStore, removeAllMessageThreadsStore } from './message.action';

export const initialAllMessageThreads: Array<MessageThreadsInterface> = [];
export const allMessageThreadsReducer = createReducer(
  initialAllMessageThreads,
  on(addAllMessageThreadsStore, (state, { allMessageThreads }) => allMessageThreads),
  on(updateAllMessageThreadsStore, (state, { allMessageThreads }) => allMessageThreads),
  on(removeAllMessageThreadsStore, (state, {}) => [])
);
