import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { AlertService } from '../../../services/alert/alert.service';

/**
 * Component:
 * Alert component displaying a message
 * Can be found: {uri}/activities
 */

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit, OnDestroy {
  public message: { text: string; type: string };

  public showLoading$: Observable<boolean>;

  public param;

  private showLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private subscription: Subscription[] = [];

  constructor(private alertService: AlertService) {
    this.showLoading$ = this.showLoadingSubject.asObservable();
  }

  ngOnInit(): void {
    this.subscription.push(
      this.alertService.hasAlert$.subscribe((message: { text: string; type: string }) => {
        this.message = message;
      })
    );
    this.subscription.push(
      this.alertService.showLoading$.subscribe((value: boolean) => {
        this.showLoadingSubject.next(value);
      })
    );
    this.subscription.push(
      this.alertService.getParam$.subscribe(value => {
        this.param = value;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.forEach(sub => sub.unsubscribe());
  }
}
