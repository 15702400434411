/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, iif, Observable, of, Subscription, throwError } from 'rxjs';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { catchError, distinctUntilChanged, mergeMap, skip, switchMap, take } from 'rxjs/operators';
import { HelperService } from '../../../services/helper/helper.service';
import { StudyInterface } from '../../../models/interface/study/study.interface';
import { PatientSharedService } from '../../../services/shared/patient-shared/patient-shared.service';
import { UserInterface } from '../../../models/interface/user.interface';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { StudyActionTypes } from '../../../store/study/study.action';
import { UserStore } from '../../../store/user/component-store/user.store';
import { OrganisationInterface } from '../../../models/interface/organisation/organisation.interface';
import { OrganisationActionTypes } from '../../../store/organisation/organisation.action';

/**
 * Component:
 * Patient study page displaying all common studies with the ecoach;
 * Can be found: {uri}/patients/{{user_id}}/studies
 */

@Component({
  selector: 'app-patient-study',
  templateUrl: './patient-study.component.html',
  styleUrls: ['./patient-study.component.scss'],
  providers: [UserStore]
})
export class PatientStudyComponent implements OnInit, OnDestroy {
  @ViewChild('paginator') paginator;

  // Filter
  public filter = { searchFilter: '' };

  public isLoading$: Observable<boolean>;

  public searchText$ = new BehaviorSubject<string>('');

  public studies$: Observable<Array<StudyInterface>>;

  public pagedStudies$: Observable<Array<StudyInterface>>;

  // Form type
  public types = ['All', 'Study', 'Group'];

  public isManager$: Observable<boolean>;

  public patient: UserInterface;

  public organisations$: Observable<Array<OrganisationInterface>>;
  public organisations: Array<OrganisationInterface> = [];

  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  // Data provided by UserService
  private initialStudies: Array<StudyInterface>;

  private collaboratingStudies: Array<StudyInterface> = [];

  private studiesSubject: BehaviorSubject<Array<StudyInterface>> = new BehaviorSubject([]);
  private pagedStudiesSubject: BehaviorSubject<Array<StudyInterface>> = new BehaviorSubject<Array<StudyInterface>>([]);

  private isManagerSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private memberStudies$: Observable<Array<StudyInterface>>;

  // Subscription Handler
  private subscriptions: Subscription[] = [];

  constructor(
    private helperService: HelperService,
    private sharedService: PatientSharedService,
    private actions$: Actions,
    private userStore: UserStore,
    private store: Store<{ organisations: Array<OrganisationInterface> }>,
    private translateService: TranslateService
  ) {
    this.organisations$ = store.select('organisations');
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.studies$ = this.studiesSubject.asObservable();
    this.pagedStudies$ = this.pagedStudiesSubject.asObservable();
    this.isManager$ = this.isManagerSubject.asObservable();
    this.memberStudies$ = this.userStore.memberStudies$;
  }

  // Helper function
  public get helper() {
    return this.helperService;
  }

  ngOnInit(): void {
    this.isLoadingSubject.next(true);
    this.isManagerSubject.next(false);

    this.subscriptions.push(
      this.sharedService.patient$.subscribe((value: UserInterface) => {
        // Due to async service check whether value is null or valid id
        if (value === null || value === undefined) {
          this.isLoadingSubject.next(true);
        } else {
          this.patient = value;
          this.applyStudyFilter();
        }
      })
    );

    // Subscription to language change
    this.subscriptions.push(
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
        this.applyStudyFilter();
      })
    );

    // Active nav is collaborators
    this.sharedService.pushActiveChild('studies');
  }

  public applyStudyFilter(): void {
    this.isLoadingSubject.next(true);
    this.subscriptions.push(
      this.reloadOrganisationList()
        .pipe(switchMap(() => this.reloadStudyList(true)))
        .subscribe((result: any) => {
          this.isLoadingSubject.next(false);
        })
    );
  }

  public reloadStudyList(setFirst?: boolean): Observable<boolean> {
    const pageIndex = this.paginator?.paginator ? this.paginator?.paginator.pageIndex : 0;
    const pageSize = this.paginator?.paginator ? this.paginator?.paginator.pageSize : 20;
    this.store.dispatch({
      type: StudyActionTypes.getCollaboratingStudiesType,
      payload: { include: 'owners,roles' }
    });
    return this.actions$.pipe(
      ofType(StudyActionTypes.getCollaboratingStudiesSuccessType, StudyActionTypes.getCollaboratingStudiesErrorType),
      take(1),
      mergeMap((result: any) =>
        iif(() => result.type === StudyActionTypes.getCollaboratingStudiesSuccessType, of(result), throwError(result))
      ),
      mergeMap(result => {
        this.collaboratingStudies = result['response']['body']['data'];
        this.userStore.getMemberStudies({ userId: this.patient.id });
        return this.memberStudies$.pipe(skip(1), take(1));
      }),
      mergeMap(result => {
        this.initialStudies = result.filter((study: StudyInterface) =>
          this.collaboratingStudies.map(val => val.id.toString()).includes(study.id.toString())
        );
        return this.search(this.filter['searchFilter']).pipe(
          mergeMap(() => this.helper.setPagedContent(this.studiesSubject, this.pagedStudiesSubject, setFirst, pageIndex, pageSize))
        );
      })
    );
  }

  public reloadOrganisationList(): Observable<boolean> {
    this.store.dispatch({ type: OrganisationActionTypes.getOrganisationsType, payload: { include: 'owners,collaborators' } });
    return this.organisations$.pipe(
      skip(1),
      take(1),
      mergeMap(result => {
        this.organisations = result;
        return of(true);
      }),
      catchError(error => {
        this.organisations = [];
        return of(false);
      })
    );
  }

  public updateList(): void {
    this.subscriptions.push(this.reloadStudyList().subscribe());
  }

  public updatePagedStudies(event: any) {
    if (event) {
      this.pagedStudiesSubject.next(event);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  // Reads search value and runs searchFilter
  private search(name: string): Observable<boolean> {
    this.searchText$.next(name);
    return this.searchText$.pipe(
      distinctUntilChanged(),
      mergeMap(() => {
        this.studiesSubject.next(this.helper.filterStudiesBy(this.initialStudies, this.filter['searchFilter'].toLowerCase().trim()));
        return of(true);
      })
    );
  }
}
