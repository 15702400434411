/* eslint-disable @typescript-eslint/naming-convention */
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, iif, Observable, of, Subscription, throwError } from 'rxjs';
import { Router, RouterEvent } from '@angular/router';
import { filter, map, mergeMap, skip, take, takeLast, takeWhile, tap } from 'rxjs/operators';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { Action, Store } from '@ngrx/store';
import { DialogGroupCreationComponent } from '../../dialog/dialog-group-creation/dialog-group-creation.component';
import { StudyMediaInterface } from '../../../models/interface/study/study-media.interface';
import { OrganisationSharedService } from '../../../services/shared/organisation-shared/organisation-shared.service';
import { AlertService } from '../../../services/alert/alert.service';
import { HelperService } from '../../../services/helper/helper.service';
import { RoleInterface } from '../../../models/interface/role.interface';
import { UserInterface } from '../../../models/interface/user.interface';
import { ProfileInterface } from '../../../models/interface/profile.interface';
import { HelperDialogService } from '../../../services/helper/helper-dialog/helper-dialog.service';
import { UserActionTypes } from '../../../store/user/user.action';
import { StudyStore } from '../../../store/study/component-store/study.store';
import { UserStore } from '../../../store/user/component-store/user.store';
import { OrganisationInterface } from 'src/app/models/interface/organisation/organisation.interface';
import { Actions, ofType } from '@ngrx/effects';
import { GetOrganisationCollaboratorsSuccess, OrganisationActionTypes } from 'src/app/store/organisation/organisation.action';
import { OrganisationCollaboratorInterface } from 'src/app/models/interface/organisation/organisation-collaborator.interface';

@Component({
  selector: 'app-organisation-study-creation',
  templateUrl: './organisation-study-creation.component.html',
  styleUrls: ['./organisation-study-creation.component.scss'],
  providers: [StudyStore, UserStore]
})
export class OrganisationStudyCreationComponent implements OnInit, OnDestroy {
  @ViewChild('fileInputPicture') fileInputPicture: ElementRef;

  // Icons
  faInfoCircle = faInfoCircle;

  // Group creation studyForm
  public groupFormType: UntypedFormGroup;
  public accessType = ['PASSWORD', 'INVITATION'];

  // Image upload
  public language: string;
  public url = '';
  fileData: File = null;
  previewUrl: any = null;
  fileUploadProgress: number = null;
  uploadedFilePath: string = null;
  files: Array<any> = null;

  // Form submission
  public submitted = false;
  public disabled = false;

  // Data from UserService
  public allECoaches: Array<UserInterface> = [];

  // Filter selection
  public filter = { userSelection: '' };

  public ecoachesSubject: BehaviorSubject<Array<UserInterface>> = new BehaviorSubject<Array<UserInterface>>([]);
  public ecoaches$: Observable<Array<UserInterface>> = this.ecoachesSubject.asObservable();

  public selectedminDate$: Observable<string>;
  public selectedCurrentDate$: Observable<string>;
  public selectedIsPrivate$: Observable<string>;
  public selectedAccessType$: Observable<string>;

  public isECoach$: Observable<boolean>;
  public showDescription$: Observable<boolean>;

  public profile$: Observable<ProfileInterface>;
  public profileRoles$: Observable<Array<RoleInterface>>;

  public showDescriptionSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // Dialog
  private dialogRef: MatDialogRef<DialogGroupCreationComponent>;

  private organisationId: number;

  // Calculate dates
  private today = new Date();
  private tomorrow = this.today.setDate(this.today.getDate() + 1);

  private isUploadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private submittedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // Data from Profile Store
  private myProfile: ProfileInterface;

  // Subject and Observables
  private selectedMinDateSubject = new BehaviorSubject<string>(this.helperService.formatDateToYYYY_MM_DD(this.tomorrow));
  private selectedCurrentDateSubject = new BehaviorSubject<string>(this.helperService.formatDateToYYYY_MM_DD(this.tomorrow));
  private selectedIsPrivateSubject = new BehaviorSubject<string>('0');
  private selectedAccessTypeSubject = new BehaviorSubject<string>('PASSWORD');

  private isECoachSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private createStudyResponse$: Observable<any>;
  private updateStudyResponse$: Observable<any>;

  private uploadStudyMediaResponse$: Observable<any>;

  private allECoaches$: Observable<Array<UserInterface>>;

  private allCollaborators$: Observable<Array<UserInterface>>;

  // Subscription Handler
  private subscription: Subscription[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private helperService: HelperService,
    private helperDialogService: HelperDialogService,
    private router: Router,
    private alertService: AlertService,
    private dialog: MatDialog,
    private sharedService: OrganisationSharedService,
    private actions$: Actions,
    private studyStore: StudyStore,
    private userStore: UserStore,
    private store: Store<{ myProfile: ProfileInterface; myRoles: Array<RoleInterface>; allECoaches: Array<UserInterface> }>
  ) {
    this.showDescription$ = this.showDescriptionSubject.asObservable();
    this.profile$ = store.select('myProfile');
    this.profileRoles$ = store.select('myRoles');
    router.events.subscribe((e: RouterEvent) => {
      this.dialog.closeAll();
    });

    // Form type contains searchFilter options
    this.groupFormType = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(255), Validators.minLength(1)]],
      is_private: ['0', Validators.required],
      accesstype: ['', Validators.required],
      password: ['', Validators.maxLength(255)],
      subscribable: ['2'],
      starts_at: [''],
      ends_at: [''],
      deTitle: ['', [Validators.required, Validators.maxLength(255)]],
      enTitle: ['', [Validators.required, Validators.maxLength(255)]],
      deDescription: ['', [Validators.required, Validators.maxLength(255)]],
      enDescription: ['', [Validators.required, Validators.maxLength(255)]],
      owner_id: [-1]
    });
    this.groupFormType.controls['accesstype'].setValue(this.accessType[0], { onlySelf: true });
    this.groupFormType.controls['owner_id'].setValue(-1, { onlySelf: true });

    // Set values and date for group creation studyForm
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    this.groupFormType.controls['starts_at'].setValue(this.helperService.formatDateToYYYY_MM_DD(Date.now()));
    this.groupFormType.controls['ends_at'].setValue(this.helperService.formatDateToYYYY_MM_DD(this.tomorrow));
    this.selectedIsPrivate$ = this.selectedIsPrivateSubject.asObservable();
    this.selectedAccessType$ = this.selectedAccessTypeSubject.asObservable();
    this.selectedminDate$ = this.selectedMinDateSubject.asObservable();
    this.selectedCurrentDate$ = this.selectedCurrentDateSubject.asObservable();
    this.createStudyResponse$ = this.studyStore.createStudyResponse$;
    this.updateStudyResponse$ = this.studyStore.updateStudyResponse$;
    this.uploadStudyMediaResponse$ = this.studyStore.uploadStudyMediaResponse$;
    //this.allECoaches$ = this.store.select('allECoaches');
    this.allCollaborators$ = this.userStore.allCollaborators$;
  }

  get f() {
    return this.groupFormType.controls;
  }

  public get helper() {
    return this.helperService;
  }

  public get helperDialog() {
    return this.helperDialogService;
  }

  ngOnInit(): void {
    this.sharedService.sourceOrganisation$
      .pipe(
        mergeMap((value: OrganisationInterface) => {
          this.organisationId = value.id;
          this.store.dispatch({
            type: OrganisationActionTypes.getOrganisationCollaboratorsType,
            payload: {
              organisationId: this.organisationId
            }
          });
          return this.actions$.pipe(
            ofType(
              OrganisationActionTypes.getOrganisationCollaboratorsSuccessType,
              OrganisationActionTypes.getOrganisationCollaboratorsErrorType
            ),
            take(1)
          );
        })
      )
      .subscribe((action: Action) => {
        if (action.type === OrganisationActionTypes.getOrganisationCollaboratorsSuccessType) {
          const resp: HttpResponse<any> = (action as GetOrganisationCollaboratorsSuccess).response;
          const collaborators: Array<OrganisationCollaboratorInterface> = resp['body']['data'];
          this.allECoaches = this.allECoaches.concat(collaborators);
          if (this.allECoaches.length !== 0) {
            this.allECoaches = this.allECoaches.reduce(
              (unique: Array<UserInterface>, item: UserInterface) =>
                unique.find(user => user.id.toString() === item.id.toString()) ? unique : [...unique, item],
              []
            );
            this.ecoachesSubject.next(this.allECoaches);
          }
          if (this.allECoaches.length > 0) {
            this.groupFormType.controls['owner_id'].setValue(this.allECoaches[0].id, { onlySelf: true });
          }
        }
      });

    this.isECoach$ = this.isECoachSubject.asObservable();

    this.subscription.push(
      this.profile$
        .pipe(
          filter(user => user !== null),
          mergeMap((result: ProfileInterface) => {
            this.myProfile = result;
            return this.profileRoles$.pipe(
              filter(user => user !== null),
              take(1)
            );
          }),
          mergeMap((resp: Array<RoleInterface>) => {
            const roles: Array<RoleInterface> = resp;
            if (this.helperService.hasRoles(roles, /ecoach$/)) {
              this.isECoachSubject.next(true);
            }
            if (this.helperService.hasRoles(roles, /admin$/)) {
              this.store.dispatch({ type: UserActionTypes.getAllEcoachesType, payload: {} });
              return this.allECoaches$.pipe(skip(1), take(1));
            } else {
              this.userStore.getAllCollaboratorsOfUser({ roles: 'ecoach' });
              return this.allCollaborators$.pipe(skip(1), take(1));
            }
          })
        )
        .subscribe((resp: any) => {
          const collaborators = resp.filter((user: UserInterface) =>
            user.attributes.roles.find((role: { slug: string; description: string }) => role.slug.toString() === 'ecoach')
          );

          this.allECoaches = this.allECoaches.concat(collaborators);
          if (this.allECoaches.length !== 0) {
            this.allECoaches = this.allECoaches.reduce(
              (unique: Array<UserInterface>, item: UserInterface) =>
                unique.find(user => user.id.toString() === item.id.toString()) ? unique : [...unique, item],
              []
            );
            this.ecoachesSubject.next(this.allECoaches);
          }
          if (this.allECoaches.length > 0) {
            this.groupFormType.controls['owner_id'].setValue(this.allECoaches[0].id, { onlySelf: true });
          }
        })
    );
  }

  // Group creation studyForm check is_private
  public showIsPrivate(event): void {
    this.selectedIsPrivateSubject.next(event.value.toString());
  }

  // Group creation studyForm check is_private
  public showAccessType(value: string): void {
    this.selectedAccessTypeSubject.next(value);
  }

  // Submit parameters required to create a group

  public createGroup(): void {
    this.submitted = true;
    this.disabled = true;
    let newStudyId = null;

    if (!this.isGroupFormInvalid() || !this.f.name.invalid) {
      let accesstypeParam = null;
      let isPrivateParam = null;
      const translations = [];

      const translationGerman = {
        locale: 'de',
        title: this.f.deTitle.value,
        description: this.f.deDescription.value
      };

      const translationEnglish = {
        locale: 'en',
        title: this.f.enTitle.value,
        description: this.f.enDescription.value
      };

      translations.push(translationGerman, translationEnglish);

      const formIsPrivate = isPrivate =>
        ({
          1: true,
          0: false
        }[isPrivate]);
      isPrivateParam = formIsPrivate(this.f.is_private.value);

      const formAccessType = accessType =>
        ({
          PASSWORD: 'password',
          INVITATION: 'invite'
        }[accessType]);
      accesstypeParam = formAccessType(this.f.accesstype.value);

      const startsAt = this.helperService.convertStringToUnixTimestamp(this.f.starts_at.value);
      const endsAt = this.helperService.convertStringToUnixTimestamp(this.f.ends_at.value);

      const ownerId = this.f.owner_id.value.toString() !== '-1' ? parseInt(this.f.owner_id.value.toString(), 10) : undefined;

      if (this.isOwnerAndECoach()) {
        this.studyStore.createStudy({
          name: this.f.name.value,
          type: 'study',
          organisationId: this.organisationId
        });
        this.createStudyResponse$
          .pipe(
            skip(1),
            take(1),
            mergeMap((result: any) => {
              if (result instanceof HttpResponse) {
                // Get location_url
                const locationUrl = result['headers'].get('Location');
                newStudyId = locationUrl.substring(locationUrl.lastIndexOf('/') + 1);
                return of(newStudyId);
              } else {
                return throwError(result);
              }
            }),
            mergeMap((studyId: any) => {
              // If image is provided
              if (this.fileInputPicture.nativeElement.files.length > 0) {
                this.studyStore.uploadStudyMedia({ studyId: parseInt(studyId, 10), fileToUpload: this.files[0] });
                return this.uploadStudyMediaResponse$.pipe(
                  skip(1),
                  takeWhile(result => !(result.type === HttpEventType.Response), true),
                  mergeMap((result: any) => {
                    this.fileUploadProgress = Math.round((result['loaded'] / result['total']) * 100);
                    return of(result);
                  }),
                  takeLast(1),
                  mergeMap((result: any) => {
                    if (result instanceof HttpResponse) {
                      this.fileUploadProgress = 0;
                      this.isUploadingSubject.next(false);
                      return of(result);
                    } else {
                      return throwError(result);
                    }
                  }),
                  mergeMap((result: any) => {
                    const studyMediaList: Array<StudyMediaInterface> = result.body.data;
                    this.studyStore.updateStudy({
                      studyId: parseInt(studyId, 10),
                      translationContent: translations,
                      accesstypeParam: isPrivateParam ? accesstypeParam : undefined,
                      password: isPrivateParam && accesstypeParam === 'password' ? this.f.password.value : undefined,
                      isPrivate: isPrivateParam,
                      startsAt,
                      endsAt,
                      picture: `uploads/studies/${parseInt(studyId, 10)}/${studyMediaList[0].attributes.url}`
                    });
                    return this.updateStudyResponse$.pipe(
                      skip(1),
                      take(1),
                      mergeMap((res: any) => iif(() => res instanceof HttpResponse, of(res), throwError(res)))
                    );
                  })
                );
              } else {
                this.studyStore.updateStudy({
                  studyId: parseInt(studyId, 10),
                  translationContent: translations,
                  accesstypeParam: isPrivateParam ? accesstypeParam : undefined,
                  password: isPrivateParam && accesstypeParam === 'password' ? this.f.password.value : undefined,
                  isPrivate: isPrivateParam,
                  startsAt,
                  endsAt
                });
                return this.updateStudyResponse$.pipe(
                  skip(1),
                  take(1),
                  mergeMap((res: any) => iif(() => res instanceof HttpResponse, of(res), throwError(res)))
                );
              }
            })
          )
          .subscribe(
            () => {
              this.showCreationSuccess(newStudyId.toString(), 'group-creation.form_create_group_succeeded_redirection', true);
            },
            () => {
              this.showCreationError('group-creation.form_create_group_failed');
            }
          );
      } else {
        this.studyStore.createStudy({
          name: this.f.name.value,
          type: 'study',
          ownerId,
          organisationId: this.organisationId
        });
        this.createStudyResponse$
          .pipe(
            skip(1),
            take(1),
            mergeMap((result: any) => {
              if (result instanceof HttpResponse) {
                // Get location_url
                const locationUrl = result['headers'].get('Location');
                newStudyId = locationUrl.substring(locationUrl.lastIndexOf('/') + 1);
                return of(newStudyId);
              } else {
                return throwError(result);
              }
            })
          )
          .subscribe(
            () => {
              this.showCreationSuccess(newStudyId, 'group-creation.form_create_group_succeeded');
            },
            error => {
              this.showCreationError('group-creation.form_create_failed');
            }
          );
      }
    }
  }

  public isOwnerAndECoach(): boolean {
    if (this.f.owner_id.value.toString() === '-1') {
      return true;
    } else {
      return this.f.owner_id.value.toString() === this.myProfile.id.toString() && this.isECoachSubject.value;
    }
  }

  public processFile(fileInput: any): void {
    this.fileData = fileInput.target.files[0] as File;
    this.uploadedFilePath = this.fileData.name;
    this.files = fileInput.target.files;
    this.displayImagePreview();
  }

  public displayImagePreview(): string {
    if (this.fileData) {
      const mimeType = this.fileData.type;
      if (mimeType.match(/image\/*/) == null) {
        return 'assets/pictures/default_no_image_picture.svg';
      }
      const reader = new FileReader();
      reader.readAsDataURL(this.fileData);
      reader.onload = _event => {
        this.previewUrl = reader.result;
      };
      return this.previewUrl;
    } else {
      return 'assets/pictures/default_no_image_picture.svg';
    }
  }

  public clearImage(): void {
    this.fileData = null;
    this.previewUrl = null;
    this.fileUploadProgress = null;
    this.uploadedFilePath = null;
    this.files = null;
    this.fileInputPicture.nativeElement.value = '';
  }

  public openDialogGroupCreate(): MatDialogRef<DialogGroupCreationComponent> {
    this.dialogRef = this.helperDialog.openDialogGroupCreate(this.submitted);
    this.submittedSubject.asObservable().subscribe(value => {
      if (this.dialogRef && this.dialogRef.componentInstance) {
        this.dialogRef.componentInstance.data = { submitted: value };
      }
    });
    return this.dialogRef;
  }

  public onKeyFilter() {
    const filterResults: Array<UserInterface> = this.allECoaches.filter(
      (user: UserInterface) =>
        user.attributes.name?.toLowerCase().includes(this.filter['userSelection'].toLowerCase()) ||
        user.attributes.email.toLowerCase().includes(this.filter['userSelection'].toLowerCase())
    );
    if (filterResults.length !== 0) {
      this.ecoachesSubject.next(filterResults);
    } else {
      if (this.f.owner_id.value.toString() !== '-1') {
        this.ecoachesSubject.next([this.helper.findArrObjById(this.f.owner_id.value, this.allECoaches)]);
      }
    }
  }

  public resetFilter() {
    this.filter = { userSelection: '' };
    this.ecoachesSubject.next(this.allECoaches);
  }

  ngOnDestroy(): void {}

  private isGroupFormInvalid(): boolean {
    const translationInvalid =
      this.f.deTitle.invalid || this.f.deDescription.invalid || this.f.enTitle.invalid || this.f.enDescription.invalid;
    if (!this.f.name.invalid) {
      if (this.f.is_private.value === '0') {
        return translationInvalid;
      } else {
        if (this.f.accesstype.value === 'PASSWORD') {
          return !this.f.password.invalid ? translationInvalid : true;
        } else {
          return translationInvalid;
        }
      }
    } else {
      return true;
    }
  }

  private showCreationSuccess(locationUrl: string, message?: string, redirect?: boolean): void {
    if (message) {
      this.alertService.success(message);
    }
    this.submitted = false;
    this.submittedSubject.next(false);
    const dialogRef = this.openDialogGroupCreate();
    dialogRef.componentInstance.data = { submitted: false };
    this.disabled = false;
    setTimeout(() => {
      dialogRef.close();
    }, 5000);
    dialogRef.afterClosed().subscribe(() => {
      if (redirect) {
        this.router.navigateByUrl(`/groups/${locationUrl}`);
      }
    });
  }

  private showCreationError(message?: string): void {
    if (message) {
      this.alertService.error(message);
    }
    this.submitted = false;
    this.submittedSubject.next(false);
    const dialogRef = this.openDialogGroupCreate();
    setTimeout(() => {
      this.disabled = false;
      dialogRef.close();
    }, 5000);
  }
}
