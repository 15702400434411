/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, Subscription, throwError } from 'rxjs';
import { mergeMap, skip, take } from 'rxjs/operators';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faComments } from '@fortawesome/free-solid-svg-icons/faComments';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons/faMinusCircle';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { Router } from '@angular/router';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { HelperActivityService } from '../../../services/helper/helper-activity/helper-activity.service';
import { ActivityInterface } from '../../../models/interface/activity.interface';
import { TableSortInterface } from '../../../models/interface/table/table_sort.interface';
import { HelperService } from '../../../services/helper/helper.service';
import { UserInterface } from '../../../models/interface/user.interface';
import { MessageThreadsInterface } from '../../../models/interface/message_threads.interface';
import { GroupSharedService } from '../../../services/shared/group-shared/group-shared.service';
import { HelperDialogService } from '../../../services/helper/helper-dialog/helper-dialog.service';
import { Store } from '@ngrx/store';
import { MessageActionTypes } from '../../../store/message/message.action';
import { UserActionTypes } from '../../../store/user/user.action';
import { MessageStore } from '../../../store/message/component-store/message.store';
import { UserStore } from '../../../store/user/component-store/user.store';
import { StudyInterface } from 'src/app/models/interface/study/study.interface';

/**
 * Component:
 * Group task page displaying a list of all tasks of the group;
 * Can be found: {uri}/groups/{{group_id}}/tasks
 */

@Component({
  selector: 'app-group-task',
  templateUrl: './group-task.component.html',
  styleUrls: ['./group-task.component.scss'],
  providers: [MessageStore, UserStore]
})
export class GroupTaskComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild('instanceDetailContainer', { read: ViewContainerRef }) instanceDetailContainer: ViewContainerRef;

  public displayedColumns: string[] = ['activity_text', 'performed_by', 'created_at', 'status'];
  public dataSource;

  public components;

  // Icons
  faMinusCircle = faMinusCircle;
  faPlusCircle = faPlusCircle;
  faComments = faComments;
  faCheck = faCheck;
  faTimes = faTimes;

  // Study ID and loading status for this child component
  public studyId: number;
  public isLoading$: Observable<boolean>;

  // Data provided by UserService
  public users: Array<UserInterface> = [];

  public feedbackParticipants: Array<MessageThreadsInterface> = [];
  public feedbackAnswersheetId: Array<number> = [];

  public tasks$: Observable<Array<ActivityInterface>>;

  public isManager$: Observable<boolean>;

  // Table sort
  public tableSort: TableSortInterface = {
    sortActivityId: null,
    sortAction: null,
    sortActionId: null,
    sortText: null,
    sortPerformedBy: null,
    sortCreatedAt: null
  };

  // Translation for study name
  public param = { intervention_name: '...' };

  private myActivities: Array<ActivityInterface> = [];
  private myTasks: Array<ActivityInterface> = [];

  private threads: Array<MessageThreadsInterface> = [];

  private allMessageThreads$: Observable<Array<MessageThreadsInterface>>;

  private myMembers$: Observable<Array<UserInterface>>;

  private inboxFolderAnswersheet$: Observable<Array<MessageThreadsInterface>>;

  private studyActivities$: Observable<Array<ActivityInterface>>;

  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private isManagerSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private tableContentSubject: BehaviorSubject<Array<ActivityInterface>> = new BehaviorSubject([]);
  private tasksSubject: BehaviorSubject<Array<ActivityInterface>> = new BehaviorSubject([]);

  private subscriptions: Subscription[] = [];

  constructor(
    private sharedService: GroupSharedService,
    private helperService: HelperService,
    private router: Router,
    private helperActivityService: HelperActivityService,
    private helperDialogService: HelperDialogService,
    private dialog: MatDialog,
    private messageStore: MessageStore,
    private userStore: UserStore,
    private store: Store<{ allMessageThreads: Array<MessageThreadsInterface>; myMembers: Array<UserInterface> }>
  ) {
    router.events.subscribe(() => {
      this.dialog.closeAll();
    });
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.isManager$ = this.isManagerSubject.asObservable();
    this.tasks$ = this.tasksSubject.asObservable();
    this.allMessageThreads$ = this.store.select('allMessageThreads');
    this.inboxFolderAnswersheet$ = this.messageStore.inboxFolderAnswersheet$;
    this.myMembers$ = this.store.select('myMembers');
    this.studyActivities$ = this.userStore.studyActivities$;
  }

  public get helper() {
    return this.helperService;
  }

  public get helperActivity() {
    return this.helperActivityService;
  }

  public get helperDialog() {
    return this.helperDialogService;
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.sharedService.sourceGroup$
        .pipe(
          mergeMap((group: StudyInterface) => {
            if (group === null) {
              this.isLoadingSubject.next(true);
              return throwError('NO_STUDY_ID_FOUND');
            } else {
              this.studyId = group.id;
              this.param = { intervention_name: group.attributes.name };
              const reqs: Array<Observable<any>> = [];
              this.store.dispatch({
                type: UserActionTypes.getMyMembersType,
                payload: {}
              });
              reqs.push(this.myMembers$.pipe(skip(1), take(1)));
              this.userStore.getActivitiesStudy({ studyId: this.studyId, include: 'questionnaires' });
              reqs.push(this.studyActivities$.pipe(skip(1), take(1)));
              this.messageStore.getInboxFolder({ include: 'participants', answersheetIdFlag: true });
              reqs.push(this.inboxFolderAnswersheet$.pipe(skip(1), take(1)));
              this.store.dispatch({
                type: MessageActionTypes.getAllMessagesThreadsType,
                payload: { answersheetFlag: true, include: 'messages,participants' }
              });
              reqs.push(this.allMessageThreads$.pipe(take(1)));

              return forkJoin(reqs);
            }
          })
        )
        .subscribe(
          (results: any) => {
            this.users = results[0];

            this.myActivities = results[1].filter(
              (value: ActivityInterface) => value.attributes.study_id?.toString() === this.studyId.toString()
            );
            this.myTasks = this.helperActivity.getTasks(this.myActivities);

            this.feedbackParticipants = results[2].filter((thread: MessageThreadsInterface) => thread.attributes.answersheet_id !== null);
            this.feedbackAnswersheetId = this.feedbackParticipants.map(
              (thread: MessageThreadsInterface) => thread.attributes.answersheet_id
            );

            this.tasksSubject.next(
              this.helperService.getActivityByNewest(this.helperActivity.filterPendingTasks(this.myTasks, this.feedbackParticipants))
            );

            this.tableContentSubject.next(this.tasksSubject.value);
            this.dataSource = this.tableContentSubject.value;
            this.dataSource.sort = this.sort;
            const currentPageSize = this.paginator ? this.paginator.pageSize : 20;
            this.dataSource = this.tableContentSubject.value.slice(0, currentPageSize);
            if (this.paginator) {
              this.paginator.firstPage();
            }

            this.threads = results[3];

            this.isLoadingSubject.next(false);
          },
          () => {
            this.isLoadingSubject.next(false);
          }
        )
    );
  }

  public getStatus(activity): boolean {
    return this.helperActivity.isTaskCompleted(activity, this.threads);
  }

  public getAnswersheetId(activity: ActivityInterface): number {
    const path = activity.attributes.uri;
    if (path !== null) {
      return parseInt(path.substring(path.lastIndexOf('/') + 1), 10);
    } else {
      return null;
    }
  }

  // Navigate to answersheet
  public showAnswersheet(activity: ActivityInterface): void {
    this.router.navigateByUrl(`/feedback-overview/answersheets/${this.getAnswersheetId(activity)}`);
  }

  public onPageChangeActivity(event: PageEvent): void {
    const startIndex = event.pageIndex * event.pageSize;
    const endIndex =
      startIndex + event.pageSize > this.tasksSubject.value.length ? this.tasksSubject.value.length : startIndex + event.pageSize;
    if (this.sort.direction !== '') {
      this.sortData(this.sort, this.tasksSubject.value.slice());
      this.tableContentSubject.next(this.tableContentSubject.value.slice(startIndex, endIndex));
    } else {
      this.tableContentSubject.next(this.tasksSubject.value.slice(startIndex, endIndex));
    }
    this.dataSource = this.tableContentSubject.value;
    this.dataSource.sort = this.sort;
  }

  public sortData(sort: Sort, data: Array<ActivityInterface>): void {
    const isDesc = sort.direction === 'desc';
    switch (sort.active) {
      case 'activity_text':
        this.tableContentSubject.next(
          data.sort((a, b) => {
            const behavior = new BehaviorSubject<number>(null);
            this.subscriptions.push(
              forkJoin([this.helperActivity.getActivityText(a), this.helperActivity.getActivityText(b)]).subscribe(
                (activities: string[]) => {
                  const act1 =
                    a.attributes.action === 'ANSWERSHEET_SUBMITTED'
                      ? activities[0] + this.helperActivity.getLessonNameOfActivity(a, a.relationships?.questionnaires.data)
                      : activities[0];
                  const act2 =
                    b.attributes.action === 'ANSWERSHEET_SUBMITTED'
                      ? activities[1] + this.helperActivity.getLessonNameOfActivity(b, b.relationships?.questionnaires.data)
                      : activities[1];
                  if (isDesc) {
                    behavior.next(act2.localeCompare(act1));
                  } else {
                    behavior.next(act1.localeCompare(act2));
                  }
                }
              )
            );
            return behavior.value;
          })
        );
        this.dataSource = this.tableContentSubject.value;
        break;
      case 'performed_by':
        this.helperActivityService.toggleSortPerformedBy(data, isDesc, this.tableContentSubject, this.users, this.tableSort);
        this.dataSource = this.tableContentSubject.value;
        break;
      case 'created_at':
        this.helperActivityService.toggleSortCreated(data, isDesc, this.tableContentSubject, this.tableSort);
        this.dataSource = this.tableContentSubject.value;
        break;
    }
  }

  public onChangeSortTable(sort: Sort): void {
    const data: Array<ActivityInterface> = this.tableContentSubject.value.slice();
    const isDefault = sort.direction === '';
    const currentPageSize = this.paginator ? this.paginator.pageSize : 20;
    if (isDefault) {
      this.dataSource = data.slice(0, currentPageSize);
    } else {
      this.sortData(sort, data);
      this.dataSource = this.tableContentSubject.value.slice(0, currentPageSize);
    }
    this.paginator.firstPage();
  }

  public openDialogInterventionInstanceDetails(activity: ActivityInterface): void {
    const instanceId =
      activity.attributes.uri !== undefined ? this.helper.getLastIndexIDOfURI(activity.attributes.uri) : activity.attributes.entity_id;
    this.helperDialog
      .openDialogInterventionInstanceDetails(instanceId, this.param, 1)
      .afterClosed()
      .subscribe(() => {});
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
