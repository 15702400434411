<button [attr.id]="'btnDialogClose'" mat-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title>{{'group-buddy.delete_buddy_modal_title' | translate}}</h1>
<div mat-dialog-content class="mat-typography">
  <p>{{'group-buddy.delete_buddy_modal_body' | translate}}:</p>

  <mat-card appearance="outlined" class="card mx-auto my-3">
    <mat-card-header class="card-header-center">
      <mat-card-title>{{'group-buddy.buddy_instance_id' | translate}} #{{(buddyInstance.id)}}</mat-card-title>
      <mat-card-subtitle>
        <p class="mb-1 text-center">
          {{'group-buddy.guiding_ecoach' | translate}} :<span *ngFor="let ecoach_id of buddyInstance.attributes.ecoach_id">
            {{helper.findArrObjById(ecoach_id, studyCollaborators)?.attributes.email}} ({{helper.findArrObjById(ecoach_id,
            studyCollaborators)?.attributes.name}})</span
          >
        </p>
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div *ngFor="let user_id of buddyInstance.attributes.buddies_ids" class="col-12 col-sm-6">
          <div>
            <p>
              {{helper.findArrObjById(user_id, members)?.attributes.email}}
              <span *ngIf="helper.getStudyCode(studyId, helper.findArrObjById(user_id, members))"
                >({{helper.getStudyCode(studyId, helper.findArrObjById(user_id, members))}})</span
              >
            </p>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <div *ngIf="(isLoadingActivities$ | async); then thenIsLoading else elseShowActivities"></div>
  <ng-template #thenIsLoading>
    <app-loader></app-loader>
  </ng-template>
  <ng-template #elseShowActivities>
    <div class="card">
      <div *ngIf="(pagedActivity$ | async)?.length === 0" class="card-body">
        <p class="centering">{{'group-buddy.no_activities_found' | translate}}</p>
      </div>
      <div *ngIf="(pagedActivity$ | async).length !== 0" class="list-group">
        <div
          *ngFor="let activityPair of pagedActivity$ | async"
          class="list-group-item list-group-item-action flex-column align-items-start"
        >
          <div class="row justify-content-around mb-3">
            <div class="col-sm-8">
              <h3 class="mb-1">{{'group-buddy.buddy_daily_task_title' | translate}} {{activityPair.localizedDate}}</h3>

              <p
                [ngClass]="{'text-danger': getBuddyState(activityPair) === 'no_daily_task_completed', 'text-warning': getBuddyState(activityPair) === 'one_daily_task_completed', ' text-info': getBuddyState(activityPair) === 'one_daily_task_completed_with_reminder', 'text-success': getBuddyState(activityPair) === 'both_daily_task_completed'}"
              >
                {{'group-buddy.buddy_daily_' + getBuddyState(activityPair) | translate}}
              </p>

              <div>
                <div
                  *ngIf="activityPair?.pair1?.attributes?.daily_task_timestamps && activityPair?.pair2?.attributes?.daily_task_timestamps; then thenShowEntry else elseShowNothing"
                ></div>
                <ng-template #thenShowEntry>
                  <p *ngIf="activityPair?.pair1">
                    {{'group-buddy.performed_by' | translate}}
                    <strong
                      >{{helper.findArrObjById(activityPair?.pair1.attributes.user_id, members) !== null ?
                      helper.getCodeNameEmail(activityPair?.pair1.attributes.user_id, members, studyId) : ('SYSTEM' | translate)}}</strong
                    >
                    {{'group-buddy.buddy_daily_task_completed' | translate}}
                    <strong>{{helper.localizeDateTimestamp(activityPair?.pair1.attributes.daily_task_timestamps)}}</strong>
                  </p>
                  <p *ngIf="activityPair?.pair2">
                    {{'group-buddy.performed_by' | translate}}
                    <strong
                      >{{helper.findArrObjById(activityPair?.pair2.attributes.user_id, members) !== null ?
                      helper.getCodeNameEmail(activityPair?.pair2.attributes.user_id, members, studyId) : ('SYSTEM' | translate)}}</strong
                    >
                    {{'group-buddy.buddy_daily_task_completed' | translate}}
                    <strong>{{helper.localizeDateTimestamp(activityPair?.pair2.attributes.daily_task_timestamps)}}</strong>
                  </p>
                </ng-template>
                <ng-template #elseShowNothing>
                  <p>{{'group-buddy.buddy_daily_task_incomplete' | translate}}</p>
                </ng-template>
              </div>

              <div *ngIf="hasSentReminder(activityPair); then thenShowReminder"></div>
              <ng-template #thenShowReminder>
                <hr />
                <p *ngIf="activityPair?.pair1">
                  <strong
                    >{{helper.findArrObjById(activityPair?.pair1.attributes.user_id, members) !== null ?
                    helper.getCodeNameEmail(activityPair?.pair1.attributes.user_id, members, studyId) : ('SYSTEM' | translate)}}</strong
                  >
                  {{'group-buddy.buddy_daily_reminder_has_sent' | translate}}
                  {{helper.localizeDateTimestamp(activityPair?.pair1.attributes.daily_reminder_timestamps)}}
                </p>

                <p *ngIf="activityPair?.pair2">
                  <strong
                    >{{helper.findArrObjById(activityPair?.pair2.attributes.user_id, members) !== null ?
                    helper.getCodeNameEmail(activityPair?.pair2.attributes.user_id, members, studyId) : ('SYSTEM' | translate)}}</strong
                  >
                  {{'group-buddy.buddy_daily_reminder_has_sent' | translate}}
                  {{helper.localizeDateTimestamp(activityPair?.pair2.attributes.daily_reminder_timestamps)}}
                </p>
              </ng-template>
            </div>

            <div class="col-sm-4"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="card-footer">
      <app-pagination-list
        (emitPageChange)="updatePagedActivities($event)"
        [_isLoading]="isLoadingActivities$ | async"
        [_items]="buddyActivitiesPair"
      ></app-pagination-list>
    </div>
  </ng-template>
</div>
<div mat-dialog-actions align="end">
  <button [attr.id]="'btnCancel'" mat-button mat-dialog-close>{{'group-buddy.cancel' | translate}}</button>
  <app-button-feedback
    [_setId]="'btnDeleteBuddyInstance_' + buddyInstance.id"
    (click)="deleteBuddyInstance(buddyInstance)"
    [_defaultButtonText]="'group-buddy.delete_buddy'"
    [_responseText]="deleteBuddyResponse | async"
  ></app-button-feedback>
</div>
