<mat-card appearance="outlined" [attr.id]="'intervention_' + intervention.id" class="card mx-auto mb-3">
  <mat-card-header class="card-header-center">
    <mat-card-title
      >{{((intervention.attributes.title) ? intervention.attributes.title : ('intervention.no_intervention_title' |
      translate))}}</mat-card-title
    >
    <mat-card-subtitle>
      Name: {{((intervention.attributes.name) ? intervention.attributes.name : ('intervention.no_intervention_name' | translate))}} (ID -
      {{intervention.id}})
    </mat-card-subtitle>
  </mat-card-header>
  <div class="row justify-content-center mt-1">
    <img
      [src]="helper.getAllMediaSupportPath(intervention.attributes.picture, intervention.attributes.study_id)"
      (error)="helper.getFallbackImage($event)"
      alt="No image"
      class="img-fluid card-img-top col-6"
      mat-card-image
    />
  </div>
  <mat-card-content>
    <p>
      <strong>
        {{((intervention.attributes.description) ? intervention.attributes.description : ('intervention.no_intervention_description' |
        translate))}}
      </strong>
    </p>

    <div class="row justify-content-center my-2">
      <div class="text-padding col-12 col-sm-4 mb-1">
        <span *ngIf="(intervention.attributes.is_test === 0); else isTestBlock" class="text-success">
          {{'intervention.is_not_test' | translate}}
        </span>
        <ng-template #isTestBlock>
          <span class="text-info">{{'intervention.is_test' | translate}}</span>
        </ng-template>
      </div>

      <!-- Unguided/Guided/No information -->
      <div [ngSwitch]="intervention.attributes.intervention_type" class="text-padding col-12 col-sm-4 mb-1">
        <span *ngSwitchCase="'unaccompanied'" class="text-info">
          <fa-icon [icon]="faHands"></fa-icon>
          {{'intervention.unguided' | translate}}
        </span>
        <span *ngSwitchCase="'accompanied'" class="text-info">
          <fa-icon [icon]="faHandsHelping"></fa-icon>
          {{'intervention.guided' | translate}}
        </span>
        <span *ngSwitchDefault class="text-muted">{{'intervention.no_information' | translate}}</span>
      </div>

      <!-- Inactive/Active -->
      <div class="text-padding col-12 col-sm-4 mb-1">
        <span *ngIf="(intervention.attributes.is_active === 0); else activeBlock" class="text-danger">
          {{'intervention.inactive' | translate}}
        </span>
        <ng-template #activeBlock>
          <span class="text-success">{{'intervention.active' | translate}}</span>
        </ng-template>
        &
        <span *ngIf="(intervention.attributes.is_running === 0); else runningBlock" class="text-danger">
          {{'intervention.not_running' | translate}}
        </span>
        <ng-template #runningBlock>
          <span class="text-success">{{'intervention.running' | translate}}</span>
        </ng-template>
      </div>
    </div>

    <div [ngSwitch]="intervention.attributes.publishing_state" class="my-2">
      <p>
        <span *ngSwitchCase="'pending'" class="text-info">
          <fa-icon [icon]="faHourglassStart"></fa-icon>
          <span> {{'intervention.' + intervention.attributes.publishing_state | translate}}</span>
        </span>
        <span *ngSwitchCase="'reviewed'" class="text-info">
          <fa-icon [icon]="faHourglassHalf"></fa-icon>
          <span> {{'intervention.' + intervention.attributes.publishing_state | translate}}</span>
        </span>
        <span *ngSwitchCase="'declined'" class="text-danger">
          <fa-icon [icon]="faTimesCircle"></fa-icon>
          <span> {{'intervention.' + intervention.attributes.publishing_state | translate}}</span>
        </span>
        <span *ngSwitchCase="'accepted'" class="text-success">
          <fa-icon [icon]="faCheckCircle"></fa-icon>
          <span> {{'intervention.' + intervention.attributes.publishing_state | translate}}</span>
        </span>
      </p>
      <div *ngIf="((isManager$ | async) || (isPublisher$ | async)) && studyCollaborators.length > 0">
        <p
          *ngIf="intervention.attributes?.reviewed_by_user && intervention.attributes?.decided_by_user && intervention.attributes?.reviewed_by_user === intervention.attributes?.decided_by_user; else elseShowSingle"
        >
          <span
            ><small
              >{{'intervention.reviewed_and_decided_by' | translate}} {{helper.findArrObjById(intervention.attributes?.reviewed_by_user,
              studyCollaborators)?.attributes.email}}</small
            ></span
          >
        </p>
        <ng-template #elseShowSingle>
          <p *ngIf="intervention.attributes?.reviewed_by_user">
            <small
              >{{'intervention.reviewed_by' | translate}} {{helper.findArrObjById(intervention.attributes?.reviewed_by_user,
              studyCollaborators)?.attributes.email}}</small
            >
          </p>
          <p *ngIf="intervention.attributes?.decided_by_user">
            <small
              >{{'intervention.decided_by' | translate}} {{helper.findArrObjById(intervention.attributes?.decided_by_user,
              studyCollaborators)?.attributes.email}}</small
            >
          </p>
        </ng-template>
      </div>
    </div>

    <p class="mb-2" *ngIf="intervention.attributes.locales; else elseNoLanguageBlock">
      <fa-icon [icon]="faGlobe"></fa-icon> {{'intervention.available_languages' | translate}}:
      <span *ngFor="let code of intervention.attributes.locales; last as isLast"
        >{{helper.parseLanguage(code) | translate}}<span *ngIf="!isLast">, </span></span
      >
    </p>

    <ng-template #elseNoLanguageBlock>
      <fa-icon [icon]="faGlobe"></fa-icon>
      <p>{{'intervention.no_language_available' | translate}}</p>
    </ng-template>

    <div class="mb-2" *ngIf="(intervention.attributes.unlock_diaries !== null) && (intervention.attributes.unlock_diaries.length === 1)">
      <p>
        <fa-icon [icon]="faBook"></fa-icon>
        <span *ngIf="(intervention.attributes.unlock_diaries.length === 1); else elseMultipleDiaries">
          {{'intervention.intervention_unlocks_diary' | translate}}
        </span>
        <ng-template #elseMultipleDiaries><span>{{'intervention.intervention_unlocks_diaries' | translate}}</span></ng-template>
      </p>
    </div>

    <div class="mb-2" *ngIf="intervention.attributes?.deactivation_text" class="row justify-content-center mt-2">
      <p class="text-danger">{{intervention.attributes?.deactivation_text}}</p>
    </div>
  </mat-card-content>
  <mat-divider></mat-divider>
  <mat-card-actions [align]="'end'">
    <div *ngIf="isCollab" class="justify-content-center">
      <button
        [attr.id]="'btnGetDetailedIntervention_' + intervention.id"
        *ngIf="intervention.attributes.is_active === 1"
        (click)="getDetailedIntervention(intervention.id)"
        class="mb-2"
        color="primary"
        mat-button
      >
        <fa-icon [icon]="faFolder"></fa-icon>
        {{'intervention.to_intervention' | translate}}
      </button>
      <button
        [attr.id]="'btnGetUnpublishedIntervention_' + intervention.id"
        *ngIf="intervention.attributes.is_active === 0"
        (click)="getUnpublishedIntervention(intervention.id)"
        class="mb-2"
        color="primary"
        mat-button
      >
        <fa-icon [icon]="faFolder"></fa-icon>
        {{'intervention.to_intervention' | translate}}
      </button>
      <button
        [attr.id]="'btnGetDetailedStudy_' + intervention.id"
        *ngIf="showStudyButtonSubject | async"
        (click)="getDetailedStudy(intervention.attributes.study_id)"
        class="mb-2"
        color="primary"
        mat-button
      >
        <fa-icon [icon]="faUsers"></fa-icon>
        {{'intervention.to_study' | translate}}
      </button>
      <button
        [attr.id]="'btnGetAssignedIntervention_' + intervention.id"
        (click)="getDetailedInterventionInstance(intervention.id)"
        *ngIf="helper.getHighestRoleOfIntervention(intervention) !== 'study.access' && (intervention.attributes.publishing_state === 'accepted' || intervention.attributes.is_active === 1)"
        class="mb-2"
        color="primary"
        mat-button
      >
        <fa-icon [icon]="faPlus"></fa-icon>
        {{'intervention.assign_member_to_intervention' | translate}}
      </button>

      <button
        [attr.id]="'btnOpenDialogInterventionTestPublish_' + intervention.id"
        *ngIf="(isUnpublished() | async) && (intervention.attributes.is_test === 1)"
        (click)="openDialogInterventionTestPublish(intervention)"
        class="mb-2"
        color="primary"
        mat-button
      >
        <fa-icon [icon]="faPlus"></fa-icon>
        {{'group-intervention-review.button_publishing' | translate}}
      </button>
      <button
        [attr.id]="'btnOpenDialogInterventionOfficialPublish_' + intervention.id"
        *ngIf="(isUnpublished() | async) && (intervention.attributes.is_test === 0)"
        (click)="openDialogInterventionOfficialPublish(intervention)"
        class="mb-2"
        color="primary"
        mat-button
      >
        <fa-icon [icon]="faPlus"></fa-icon>
        {{'group-intervention-review.button_publishing' | translate}}
      </button>

      <button
        [attr.id]="'btnOpenDialogInterventionDelete_' + intervention.id"
        *ngIf="canBeDeleted() | async"
        (click)="openDialogInterventionDelete(intervention)"
        class="mb-2"
        color="primary"
        mat-button
      >
        <fa-icon [icon]="faTrash"></fa-icon>
        {{'group-intervention-review.button_delete_intervention' | translate}}
      </button>

      <button
        [attr.id]="'btnOpenDialogInterventionReview_' + intervention.id"
        *ngIf="canBeReviewed() | async"
        (click)="openDialogInterventionReview(intervention)"
        class="mb-2"
        color="primary"
        mat-button
      >
        {{'group-intervention-review.button_review_intervention' | translate}}
      </button>
    </div>
  </mat-card-actions>
</mat-card>
