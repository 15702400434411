import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { filter, take } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { InstanceDeletionComponent } from '../../intervention-instance/instance-deletion/instance-deletion.component';
import { InterventionInstanceStore } from '../../../store/intervention-instance/component-store/intervention-instance.store';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-dialog-intervention-instance-delete',
  templateUrl: './dialog-intervention-instance-delete.component.html',
  styleUrls: ['./dialog-intervention-instance-delete.component.scss'],
  providers: [InterventionInstanceStore]
})
export class DialogInterventionInstanceDeleteComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('instanceDeletionContainer', { read: ViewContainerRef }) instanceDeletionContainer!: ViewContainerRef;
  public components;

  public instanceDeletionComponentClass = InstanceDeletionComponent;

  // Form submission
  public submitted = false;
  public deleted = false;

  public isManager: boolean;

  public setCanceledResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');

  public param;

  private updateInstanceStateResponse$: Observable<any>;

  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private interventionInstanceStore: InterventionInstanceStore,
    private dialogRef: MatDialogRef<DialogInterventionInstanceDeleteComponent>
  ) {
    this.updateInstanceStateResponse$ = this.interventionInstanceStore.updateInstanceStateResponse$;
  }

  ngOnInit(): void {
    this.param = this.data.param;

    if (this.data.isManager) {
      this.isManager = this.data.isManager;
    }

    this.components = [];
  }

  ngAfterViewInit(): void {
    if (this.instanceDeletionContainer) {
      this.components = [];
      this.instanceDeletionContainer.clear();
      const component = this.instanceDeletionContainer.createComponent<InstanceDeletionComponent>(
        this.instanceDeletionComponentClass
      ).instance;

      component._collaborators = this.data.collaborators;
      component._instance = this.data.instance;
      component._intervention = this.data.intervention;
      component._members = this.data.members;

      this.components = [...this.components, component];
    }
  }

  public setCanceled(): void {
    const found: InstanceDeletionComponent = this.components.find(c => c instanceof InstanceDeletionComponent);
    if (found !== undefined) {
      this.deleted = true;
      if (this.setCanceledResponse.value === 'DEFAULT') {
        this.setCanceledResponse.next('LOADING');
        if (this.isManager) {
          this.interventionInstanceStore.updateInstanceStateEM({ instanceId: found.instance.id, state: 'canceled' });
        } else {
          this.interventionInstanceStore.updateInstanceState({ instanceId: found.instance.id, state: 'canceled' });
        }

        this.subscriptions.push(
          this.updateInstanceStateResponse$
            .pipe(
              filter(value => !!value),
              take(1)
            )
            .subscribe((value: any) => {
              if (value instanceof HttpResponse) {
                this.deleted = false;
                this.dialogRef.close(value);
                this.setCanceledResponse.next('SUCCESS');
                setTimeout(() => {
                  this.setCanceledResponse.next('DEFAULT');
                  this.dialogRef.close('SUCCESS');
                }, 2500);
              } else {
                this.deleted = false;
                this.setCanceledResponse.next('FAILURE');
                setTimeout(() => {
                  this.setCanceledResponse.next('DEFAULT');
                }, 2500);
              }
            })
        );
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
