<div class="container-fluid text-center">
  <div class="text-start">
    <h1>{{ 'catalogue.catalogue' | translate }}</h1>
    <hr />
  </div>
  <h4>
    {{ 'catalogue.overview_of_intervention' | translate }}
    <button [attr.id]="'btnToggleDescription'" (click)="helper.toggleSubject(showDescriptionSubject)" aria-label="Information" mat-button>
      <fa-icon [icon]="faInfoCircle"></fa-icon>
    </button>
  </h4>
  <div *ngIf="showDescription$ | async" class="row justify-content-center">
    <div class="col-sm-9">
      <p class="text-justify">{{ 'catalogue.description_catalogue' | translate }}</p>
    </div>
  </div>

  <div>
    <app-intervention></app-intervention>
  </div>
</div>
