import { Action, createAction, props } from '@ngrx/store';
import { InterventionInterface } from 'src/app/models/interface/intervention.interface';

export enum InterventionActionTypes {
  addCollabInterventionsStoreType = 'Add Collaborating Interventions Store',
  updateCollabInterventionsStoreType = 'Update Collaborating Interventions Store',
  deleteCollabInterventionsStoreType = 'Delete Collaborating Interventions Store',

  addInactiveCollabInterventionsStoreType = 'Add Inactive Collaborating Interventions Store',
  updateInactiveCollabInterventionsStoreType = 'Update Inactive Collaborating Interventions Store',
  deleteInactiveCollabInterventionsStoreType = 'Delete Inactive Collaborating Interventions Store',

  addNonCollabInterventionsStoreType = 'Add Non-Collaborating Interventions Store',
  updateNonCollabInterventionsStoreType = 'Update Non-Collaborating Interventions Store',
  deleteNonCollabInterventionsStoreType = 'Delete Non-Collaborating Interventions Store',

  getInterventionsOfCollabStudyType = '[Intervention API] Get Interventions of collaborating studies',
  getInterventionsOfCollabStudySuccessType = '[Success] Get Interventions of collaborating studies',
  getInterventionsOfCollabStudyErrorType = '[Error] Get Interventions of collaborating studies',

  getInterventionsOfNonCollabStudyType = '[Intervention API] Get Interventions of non-collaborating studies',
  getInterventionsOfNonCollabStudySuccessType = '[Success] Get Interventions of non-collaborating studies',
  getInterventionsOfNonCollabStudyErrorType = '[Error] Get Interventions of non-collaborating studies'
}

export const addCollabInterventionsStore = createAction(
  InterventionActionTypes.addCollabInterventionsStoreType,
  props<{ collabInterventions: Array<InterventionInterface> }>()
);
export const updateCollabInterventionsStore = createAction(
  InterventionActionTypes.updateCollabInterventionsStoreType,
  props<{ collabInterventions: Array<InterventionInterface> }>()
);
export const removeCollabInterventionsStore = createAction(InterventionActionTypes.deleteCollabInterventionsStoreType);

export const addInactiveCollabInterventionsStore = createAction(
  InterventionActionTypes.addInactiveCollabInterventionsStoreType,
  props<{ inactiveCollabInterventions: Array<InterventionInterface> }>()
);
export const updateInactiveCollabInterventionsStore = createAction(
  InterventionActionTypes.updateInactiveCollabInterventionsStoreType,
  props<{ inactiveCollabInterventions: Array<InterventionInterface> }>()
);
export const removeInactiveCollabInterventionsStore = createAction(InterventionActionTypes.deleteInactiveCollabInterventionsStoreType);

export const addNonCollabInterventionsStore = createAction(
  InterventionActionTypes.addNonCollabInterventionsStoreType,
  props<{ nonCollabInterventions: Array<InterventionInterface> }>()
);
export const updateNonCollabInterventionsStore = createAction(
  InterventionActionTypes.updateNonCollabInterventionsStoreType,
  props<{ nonCollabInterventions: Array<InterventionInterface> }>()
);
export const removeNonCollabInterventionsStore = createAction(InterventionActionTypes.deleteNonCollabInterventionsStoreType);

export class GetInterventionsOfCollabStudy implements Action {
  readonly type: string = InterventionActionTypes.getInterventionsOfCollabStudyType;
  constructor(readonly payload: any) {}
}

export class GetInterventionsOfNonCollabStudy implements Action {
  readonly type: string = InterventionActionTypes.getInterventionsOfNonCollabStudyType;
  constructor(readonly payload: any) {}
}
