import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { faBook } from '@fortawesome/free-solid-svg-icons/faBook';
import { faHands } from '@fortawesome/free-solid-svg-icons/faHands';
import { faHandsHelping } from '@fortawesome/free-solid-svg-icons/faHandsHelping';
import { BehaviorSubject, filter, Observable, Subscription, take } from 'rxjs';
import { InterventionInterface } from '../../../models/interface/intervention.interface';
import { HelperService } from '../../../services/helper/helper.service';
import { InterventionStore } from '../../../store/intervention/component-store/intervention.store';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-dialog-intervention-delete',
  templateUrl: './dialog-intervention-delete.component.html',
  styleUrls: ['./dialog-intervention-delete.component.scss'],
  providers: [InterventionStore]
})
export class DialogInterventionDeleteComponent implements OnInit, OnDestroy {
  // Icons
  faBook = faBook;
  faHands = faHands;
  faHandsHelping = faHandsHelping;

  public intervention: InterventionInterface;

  public deleteInterventionResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');

  public deleteInterventionAsECoachManagerResponse$: Observable<any>;

  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private interventionStore: InterventionStore,
    private dialogRef: MatDialogRef<DialogInterventionDeleteComponent>,
    private helperService: HelperService
  ) {
    this.deleteInterventionAsECoachManagerResponse$ = this.interventionStore.deleteInterventionAsECoachManagerResponse$;
  }

  public get helper() {
    return this.helperService;
  }

  ngOnInit(): void {
    this.intervention = this.data.intervention;
  }

  public deleteIntervention(intervention: InterventionInterface): void {
    if (this.deleteInterventionResponse.value === 'DEFAULT') {
      this.deleteInterventionResponse.next('LOADING');
      this.interventionStore.deleteInterventionAsECoachManager(this.intervention.id);

      this.subscriptions.push(
        this.deleteInterventionAsECoachManagerResponse$
          .pipe(
            filter(value => !!value),
            take(1)
          )
          .subscribe((value: any) => {
            if (value instanceof HttpResponse) {
              this.deleteInterventionResponse.next('SUCCESS');
              setTimeout(() => {
                this.deleteInterventionResponse.next('DEFAULT');
                this.dialogRef.close('SUCCESS');
              }, 2500);
            } else {
              this.deleteInterventionResponse.next('FAILURE');
              setTimeout(() => {
                this.deleteInterventionResponse.next('DEFAULT');
              }, 2500);
            }
          })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
