/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { RequestBodyData } from '../../../models/request-body.data';
import { PayloadInterface } from '../../../models/interface/payload.interface';
import { HelperService } from '../../helper/helper.service';

/**
 * Service:
 * Message/Thread API service that handles message and notification-related operations
 */

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  public header: HttpHeaders = new HttpHeaders();
  private backendUrl: string = environment.backendURL;

  constructor(private http: HttpClient, private helperService: HelperService) {
    this.header = this.header.set('Content-Type', 'application/json');
    this.header = this.helperService.setLocaleFromStorage(this.header);
  }

  /**
   * AAS2 API User - All Device Tokens
   * This function retrieves all device tokens.
   *
   * @return Observable<any> - An observable for any response.
   */
  public getAllDeviceTokens(): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/api/v1/messages/devicetokens`, { headers: this.header, observe: 'response' });
  }

  /**
   * AAS2 API User - Register Device Token
   * This function registers a device token.
   *
   * @params string firebaseToken - A string generated device token.
   * @return Observable<any> - An observable for any response.
   */
  public registerDeviceToken(firebaseToken: string): Observable<any> {
    const firebase = {
      firebase_token: firebaseToken
    };
    return this.http.post<any>(`${this.backendUrl}/api/v1/messages/devicetokens`, new RequestBodyData('messages/devicetokens', firebase), {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API User - Delete Device Token
   * This function deletes a device token.
   *
   * @params number firebaseTokenId - ID of the registered firebase token.
   * @return Observable<any> - An observable for any response.
   */
  public deleteDeviceToken(firebaseTokenId: number): Observable<any> {
    return this.http.request<any>(`delete`, `${this.backendUrl}/api/v1/messages/devicetokens/${firebaseTokenId}`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API User - All Messages
   * This function retrieves all messages.
   *
   * @params number answersheet_id - ID of the answersheet.
   *         - If 'null': Thread is not a feedback.
   *         - If <number>: Thread is a feedback.
   *         string include - A string including additional information.
   *           - typically 'messages' or 'participants'
   *         string subject - A string of the thread subject.
   * @return Observable<any> - An observable for any response.
   */
  public getAllMessages(answersheetId?: number, include?: string, subject?: string): Observable<any> {
    const params = this.setHttpParams(answersheetId, include, subject);
    this.header = this.helperService.setLocaleFromStorage(this.header);
    return this.http.get<any>(`${this.backendUrl}/api/v1/messages?limit=0`, { headers: this.header, observe: 'response', params });
  }

  /**
   * AAS2 API User - All threads of the user
   * This function retrieves all threads.
   *
   * @params string include - A string including additional information.
   *           - typically 'messages' or 'participants'
   * @return Observable<any> - An observable for any response.
   */
  public getAllMessagesThreads(answersheetIdFlag?: boolean, answersheetId?: number, include?: string): Observable<any> {
    const params = this.setHttpParams(null, include, undefined, undefined, undefined, answersheetIdFlag);
    this.header = this.helperService.setLocaleFromStorage(this.header);
    return this.http.get<any>(`${this.backendUrl}/api/v1/messages/threads?limit=0`, { headers: this.header, observe: 'response', params });
  }

  /**
   * AAS2 API User - Inbox Folder
   * This function retrieves all messages.
   *
   * @params '>'|'<'|'=' operator - Value allows to searchFilter or search for specific feedback threads
   *         number answersheet_id - ID of the answersheet.
   *         - If 'null': Thread is not a feedback.
   *         - If <number>: Thread is a feedback.
   *         string include - A string including additional information.
   *           - typically 'messages' or 'participants'
   *         string subject - A string of the thread subject.
   *         boolean answersheetID_flag - A boolean that indicates whether threads with or without answersheets are queried
   * @return Observable<any> - An observable for any response.
   */
  public getInboxFolder(
    operator?: '>' | '<' | '=',
    answersheetId?: number | string,
    include?: string,
    subject?: string,
    answersheetIdFlag?: boolean
  ): Observable<any> {
    const params = this.setHttpParams(-1, include, subject, answersheetId, operator, answersheetIdFlag);
    this.header = this.helperService.setLocaleFromStorage(this.header);
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoach/messages/folders/inbox?limit=0`, {
      headers: this.header,
      observe: 'response',
      params
    });
  }

  /**
   * AAS2 API User - Sent Folder
   * This function get all sent messages.
   *
   * @params '>'|'<'|'=' operator - Value allows to searchFilter or search for specific feedback threads
   *         number answersheet_id - ID of the answersheet.
   *         - If 'null': Thread is not a feedback.
   *         - If <number>: Thread is a feedback.
   *         string include - A string including additional information.
   *           - typically 'messages' or 'participants'
   *         string subject - A string of the thread subject.
   * @return Observable<any> - An observable for any response.
   */
  public getSentFolder(operator?: '>' | '<' | '=', answersheetId?: number, include?: string, subject?: string): Observable<any> {
    const params = this.setHttpParams(-1, include, subject, answersheetId, operator);
    this.header = this.helperService.setLocaleFromStorage(this.header);
    return this.http.get<any>(`${this.backendUrl}/api/v1/messages/folders/sent?limit=0`, { headers: this.header, observe: 'response' });
  }

  /**
   * AAS2 API User - Thread Details
   * This function returns details of a thread.
   *
   * @params number threadId - ID of the thread.
   *         string include - A string including additional information.
   *           - typically 'messages' or 'participants'
   * @return Observable<any> - An observable for any response.
   */
  public getThreadDetails(threadId: number, include?: string): Observable<any> {
    const params = this.setHttpParams(null, include);
    return this.http.get<any>(`${this.backendUrl}/api/v1/messages/threads/${threadId}`, {
      headers: this.header,
      observe: 'response',
      params
    });
  }

  /**
   * AAS2 API User - Delete Thread
   * This function deletes a thread.
   *
   * @params number threadId - ID of the thread.
   * @return Observable<any> - An observable for any response.
   */
  public deleteThread(threadId: number): Observable<any> {
    return this.http.delete<any>(`${this.backendUrl}/api/v1/messages/threads/${threadId}`, { headers: this.header, observe: 'response' });
  }

  /**
   * AAS2 API User - Start Thread
   * This function starts a thread/feedback if answersheet_id is set.
   *
   * @params string subject - A string of the thread subject.
   *         number answersheet_id - ID of the answersheet, only needed for feedback.
   * @return Observable<any> - An observable for any response.
   */
  public startThread(subject: string, answersheetId?: number, interventionInstanceId?: number): Observable<any> {
    const payload: PayloadInterface = this.createThreadBody(subject, answersheetId, interventionInstanceId);
    return this.http.post<any>(`${this.backendUrl}/api/v1/messages/threads`, payload, { headers: this.header, observe: 'response' });
  }

  /**
   * AAS2 API User - Send Message
   * This function sends/posts a message to a thread/feedback.
   *
   * @params number threadId - ID of the thread.
   *         string message_param - A string of the message content.
   * @return Observable<any> - An observable for any response.
   */
  public sendMessage(threadId: number, messageParam: string): Observable<any> {
    const message = {
      body: messageParam
    };
    return this.http.post<any>(
      `${this.backendUrl}/api/v1/messages/threads/${threadId}/messages`,
      new RequestBodyData('messages/messages', message),
      { headers: this.header, observe: 'response' }
    );
  }

  /**
   * AAS2 API User - Add Participants
   * This function adds participants to a thread.
   *
   * @params number threadId - ID of the thread.
   *         Array<number> participants_param - A list of user ids to invite to the thread.
   * @return Observable<any> - An observable for any response.
   */
  public addParticipant(threadId: number, participantsParam: Array<number>): Observable<any> {
    const participants = {
      participants: participantsParam
    };
    return this.http.post<any>(
      `${this.backendUrl}/api/v1/messages/threads/${threadId}/relationships/participants`,
      new RequestBodyData('users', participants),
      { headers: this.header, observe: 'response' }
    );
  }

  /**
   * AAS2 API User - Remove Participants
   * This function deletes participants from a thread.
   *
   * @params number threadId - ID of the thread.
   *         Array<number> participants_param - A list of user ids to invite to the thread.
   * @return Observable<any> - An observable for any response.
   */
  public deleteParticipant(threadId: number, participantsParam: Array<number>): Observable<any> {
    const participants = {
      participants: participantsParam
    };
    return this.http.request<any>(`delete`, `${this.backendUrl}/api/v1/messages/threads/${threadId}/relationships/participants`, {
      body: new RequestBodyData('users', participants),
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API User - Threads Get Participants
   * This function returns a specific thread of an intervention instance.
   *
   * @params number threadId - ID of the thread.
   * @return Observable<any> - An observable for any response.
   */
  public getParticipantsOfThread(threadId: number): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/api/v1/messages/threads/${threadId}/participants`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API ECoach - Threads All Threads of a specific Intervention Instance
   * This function returns all threads of an intervention instance.
   *
   * @params number instanceId - ID of the intervention instance.
   *         number answersheetId - ID of the answersheet.
   *         string include - A string including additional information.
   *           - typically 'messages' or 'participants'
   *         string subject - Subject of the thread
   * @return Observable<any> - An observable for any response.
   */
  public getAllThreadsOfInstance(instanceId: number, answersheetId?: number, include?: string, subject?: string): Observable<any> {
    const params = this.setHttpParams(answersheetId, include, subject);
    this.header = this.helperService.setLocaleFromStorage(this.header);
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoach/interventions/instances/${instanceId}/threads?limit=0`, {
      headers: this.header,
      observe: 'response',
      params
    });
  }

  /**
   * AAS2 API ECoach Manager - Threads All Threads of a specific Intervention Instance
   * This function returns all threads of an intervention instance.
   *
   * @params number instanceId - A number of the instance id.
   * @return Observable<any> - An observable for any response.
   */
  public getAllThreadsOfInstanceEM(instanceId: number, answersheetId?: number, include?: string, subject?: string): Observable<any> {
    const params = this.setHttpParams(answersheetId, include, subject);
    this.header = this.helperService.setLocaleFromStorage(this.header);
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoachmanager/interventions/instances/${instanceId}/threads?limit=0`, {
      headers: this.header,
      observe: 'response',
      params
    });
  }

  /**
   * AAS2 API ECoach Manager - Gets thread details
   * This function returns the thread details of intervention instances as ecoachmanager/owner of the study.
   *
   * @params number thread_id - ID of the thread.
   * @return Observable<any> - An observable for any response.
   */
  public getThreadOfInstanceEM(threadId: number): Observable<any> {
    this.header = this.helperService.setLocaleFromStorage(this.header);
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoachmanager/interventions/instances/threads/${threadId}`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API ECoach Manager - All Messages of a specific thread
   * This function returns all messages of a thread.
   *
   * @params number thread_id - ID of the thread.
   *         string include - A string that queries and includes [messages, participants]
   * @return Observable<any> - An observable for any response.
   */
  public getAllMessagesOfThreadEM(threadId: number, include?: string): Observable<any> {
    let params = new HttpParams();
    if (include !== undefined) {
      params = params.set('include', include);
    }
    this.header = this.helperService.setLocaleFromStorage(this.header);
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoachmanager/threads/${threadId}/messages`, {
      headers: this.header,
      observe: 'response',
      params
    });
  }

  /**
   * AAS2 API User - Threads Get a specific Thread
   * This function returns a specific thread of an intervention instance.
   *
   * @params number threadId - ID of the thread.
   * @return Observable<any> - An observable for any response.
   */
  public getThreadOfInstance(threadId: number): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/api/v1/interventions/instances/threads/${threadId}`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API User - Delete a Thread
   * This function deletes a thread of an intervention instance.
   *
   * @params number threadId - ID of the thread.
   * @return Observable<any> - An observable for any response.
   */
  public deleteThreadOfInstance(threadId: number): Observable<any> {
    return this.http.delete<any>(`${this.backendUrl}/api/v1/interventions/instances/threads/${threadId}`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API User - Create a Thread
   * This function starts a thread/feedback if answersheet_id is set.
   * TODO: Payload needs to be set
   *
   * @params string subject - A string of the thread subject.
   *         number answersheet_id - ID of the answersheet, only needed for feedback.
   * @return Observable<any> - An observable for any response.
   */
  public createThreadOfInstance(subject: string, answersheetId?: string): Observable<any> {
    let thread;
    if (answersheetId !== undefined) {
      thread = {
        subject,
        answersheet_id: answersheetId
      };
    } else {
      thread = {
        subject
      };
    }
    return this.http.post<any>(
      `${this.backendUrl}/api/v1/interventions/instances/threads`,
      new RequestBodyData('messages/threads', thread),
      { headers: this.header, observe: 'response' }
    );
  }

  /**
   * AAS2 API User - Update a Thread
   * This function updates a thread/feedback if answersheet_id is set.
   *
   * @params number instanceId - ID of the intervention instance.
   *         string subject - A string of the thread subject.
   *         number answersheet_id - ID of the answersheet, only needed for feedback.
   * @return Observable<any> - An observable for any response.
   */
  public updateThreadOfInstance(instanceId: number, subject: string, answersheetId?: string): Observable<any> {
    let thread;
    if (answersheetId !== undefined) {
      thread = {
        subject,
        answersheet_id: answersheetId
      };
    } else {
      thread = {
        subject
      };
    }
    return this.http.put<any>(
      `${this.backendUrl}/api/v1/interventions/instances/threads`,
      new RequestBodyData('messages/threads', thread),
      { headers: this.header, observe: 'response' }
    );
  }

  /**
   * AAS2 API User - Start a thread as eCoach
   * This function updates a thread/feedback if answersheet_id is set.
   *
   * @params number instanceId - ID of the intervention instance.
   *         string subject - A string of the thread subject.
   *         number answersheet_id - ID of the answersheet, only needed for feedback.
   * @return Observable<any> - An observable for any response.
   */
  public startThreadAsECoach(subject: string, answersheetId?: number, interventionInstanceId?: number): Observable<any> {
    const payload: PayloadInterface = this.createThreadBody(subject, answersheetId, interventionInstanceId);
    return this.http.post<any>(`${this.backendUrl}/api/v1/ecoach/messages/threads`, payload, { headers: this.header, observe: 'response' });
  }

  /**
   * AAS2 API User - Update my message in thread
   * Updates the user's own message of a thread.
   *
   * @params InterventionInstanceInterface instance - Intervention instance to unlock the questionnaires.
   *         Array<number> unlocked_questionnaire - An array of number - questionnaire_id to unlocked the questionnaire.
   * @return Observable<any> - An observable for any response.
   */
  public updateMessage(messageId: number, message: string) {
    const requestBody = new RequestBodyData('messages/messages', { body: message });
    return this.http.patch<any>(`${this.backendUrl}/api/v1/messages/${messageId}`, requestBody, {
      headers: this.header,
      observe: 'response'
    });
  }

  public setHttpParams(
    answersheetId?: number | null,
    include?: string,
    subject?: string,
    answersheetIdDetail?: number | string,
    operator?: '>' | '<' | '=',
    answersheetIdFlag?: boolean
  ): HttpParams {
    let params = new HttpParams();
    if (answersheetId === undefined) {
      params = params.set('answersheet_id>', '0');
    } else if (answersheetId !== -1 && answersheetId !== null) {
      params = params.set('answersheet_id', answersheetId.toString());
    }
    if (include !== undefined) {
      params = params.set('include', include);
    }
    if (subject !== undefined) {
      params = params.set('subject', subject);
    }
    if (answersheetIdDetail !== undefined && answersheetIdDetail !== null && operator !== undefined) {
      if (operator === '>') {
        params = params.set('answersheet_id>', answersheetIdDetail.toString());
      }
      if (operator === '=') {
        params = params.set('answersheet_id', answersheetIdDetail.toString());
      }
      if (operator === '<') {
        params = params.set('answersheet_id<', answersheetIdDetail.toString());
      }
    }
    if (answersheetIdFlag) {
      params = params.set('answersheetID', answersheetIdFlag.toString());
    }
    return params;
  }

  public createThreadBody(subject: string, answersheetId?: number, interventionInstanceId?: number): RequestBodyData {
    const thread: any = { subject, answersheet_id: null, intervention_instance_id: null };
    if (answersheetId !== undefined) {
      thread.answersheet_id = parseInt(answersheetId.toString(), 10);
    } else {
      thread.answersheet_id = null;
    }
    if (interventionInstanceId !== undefined) {
      thread.intervention_instance_id = parseInt(interventionInstanceId.toString(), 10);
    } else {
      thread.intervention_instance_id = null;
    }
    return new RequestBodyData('messages/threads', thread);
  }
}
