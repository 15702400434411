<div class="container">
  <div class="jumbotron text-center hoverable p-3">
    <div class="card mb-3">
      <div class="card-body">
        <div class="row">
          <img class="col-12 col-sm-4" [src]="getSkillIcon()" (error)="helper.getFallbackImage($event)" class="image-parent img-fluid" />
          <div class="col-12 col-sm-8">
            <h1><strong>{{skill?.attributes.title}}</strong></h1>
          </div>
        </div>
      </div>

      <app-questionnaire-preview
        [_skillId]="skill.id"
        [_elementsType]="'skills'"
        [_studyId]="intervention.attributes.study_id"
        [_isDiary]="false"
      ></app-questionnaire-preview>
    </div>
  </div>
</div>
