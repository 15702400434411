<div class="container-fluid">
  <div class="text-center" *ngIf="isLoading$ | async; else elseCreateInstance">
    <app-loader></app-loader>
  </div>

  <ng-template #elseCreateInstance>
    <div class="container text-start mb-3">
      <div class="form-group">
        <p class="text-info" [innerHTML]="'instance-creation-form.form_create_instance_description' | translate"></p>
      </div>

      <!-- InstanceFormType - Contains studyForm for instance creation -->
      <form [formGroup]="instanceFormType">
        <!-- Collaborator -->
        <div *ngIf="(fCollaboratorList.length > 0) && (isManager$ | async)" class="my-3">
          <div class="mb-2">
            {{'instance-creation-form.form_create_instance_ecoach' | translate}}:
            <strong>{{helper.findArrObjById(myUserId, studyCollaborator)?.attributes.email}}</strong>
          </div>
          <div class="form-group row">
            <label [attr.for]="'collaboratorSelectCreation' + intervention.id" class="col-12 col-md-6">
              <strong>{{'instance-creation-form.form_create_instance_additional_ecoaches' | translate}}</strong>
            </label>
            <div class="col-12 col-md-6">
              <div class="card outline fixHeight overflowList">
                <div class="card-body">
                  <div formArrayName="collaboratorList">
                    <div *ngFor="let collaborator of fCollaboratorList.controls; let i = index">
                      <mat-checkbox
                        [attr.id]="'checkboxCollaborator_' + collaboratorsWithoutUser[i].id"
                        [attr.value]="collaboratorsWithoutUser[i]"
                        [formControlName]="i"
                      >
                        {{collaboratorsWithoutUser[i]?.attributes?.email}} ({{collaboratorsWithoutUser[i]?.attributes?.name}})
                      </mat-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="studyMembers.length > 0; then thenListOfMembers"></div>
        <ng-template #thenListOfMembers>
          <mat-form-field class="full-width center-mat-form-field">
            <mat-label>{{'instance-creation-form.form_create_instance_patient' | translate}}</mat-label>
            <mat-select [attr.id]="'selectUser'" (click)="resetFilter()">
              <mat-form-field class="full-width" appearance="fill">
                <mat-label>{{'instance-creation-form.filter_selection' | translate}}</mat-label>
                <input
                  [attr.id]="'search'"
                  autocomplete="off"
                  matInput
                  (keyup)="onKeyFilter()"
                  [(ngModel)]="filter['userSelection']"
                  [ngModelOptions]="{standalone: true}"
                />,
              </mat-form-field>
              <mat-option [attr.id]="'member_' + member.id" *ngFor="let member of (members$ | async)" [value]="member"
                >{{helper.getCodeNameEmail(member.id, studyMembers)}}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </ng-template>

        <!-- Type and date settings for instance -->
        <div class="row mb-3">
          <!-- Guidance-->
          <div class="col-12">
            <div class="row">
              <p class="col-12 col-md-6">
                <strong>{{'instance-creation-form.form_create_instance_guidance_support'| translate}}:</strong>
              </p>
              <div class="col-12 col-md-6 text-start">
                <mat-radio-group
                  [attr.id]="'radioSelectedGuidanceType'"
                  (change)="showInterventionType(instanceFormType.get('intervention_type').value)"
                  [disabled]="isGuided()"
                  [(ngModel)]="selectedGuidanceType"
                  [ngModelOptions]="{standalone: true}"
                  aria-labelledby="radio-group-label"
                  class="roles-radio-group"
                >
                  <mat-radio-button
                    [attr.id]="'radioItem_' + type"
                    *ngFor="let type of guidanceType"
                    [value]="type"
                    class="types-radio-button"
                  >
                    {{'instance-creation-form.' + type.toLowerCase() | translate}}
                  </mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
          </div>
          <!-- Starts at -->
          <div class="col-12">
            <div class="row">
              <div class="col-form-label col-12 col-md-6 pt-0">
                <strong>{{'instance-creation-form.form_create_instance_starts_at' | translate}}:</strong>
              </div>
              <div class="col-12 col-md-6 text-center">
                <mat-form-field class="full-width" appearance="outline">
                  <input
                    [attr.id]="'starts_at'"
                    matInput
                    [ngxMatDatetimePicker]="picker"
                    placeholder="Choose a date"
                    formControlName="starts_at"
                    [min]="minDate"
                    [disabled]="false"
                    readonly
                  />
                  <!-- <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle> -->
                  <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
                  <ngx-mat-datetime-picker
                    #picker
                    [showSpinners]="showSpinners"
                    [showSeconds]="showSeconds"
                    [stepHour]="stepHour"
                    [stepMinute]="stepMinute"
                    [stepSecond]="stepSecond"
                    [touchUi]="touchUi"
                    [color]="color"
                    [enableMeridian]="enableMeridian"
                  >
                  </ngx-mat-datetime-picker>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <hr />

        <!-- Description -->
        <div class="form-group">
          <strong>{{'instance-creation-form.form_create_instance_customization_description' | translate}}</strong>
        </div>

        <div class="row my-3">
          <div class="col align-self-end">
            <button
              [attr.id]="'btnResetDefaultQuestionnaireConfig'"
              (click)="resetDefaultQuestionnaireConfig()"
              class="float-end"
              mat-button
            >
              {{'instance-creation-form.form_create_instance_reset_config' | translate}}
            </button>
          </div>
        </div>

        <!-- Lesson configuration -->
        <app-instance-questionnaire-configuration
          [_intervention]="intervention"
          [_questionnaires]="questionnaires"
          [_skills]="skills"
          [_diaries]="diaries"
          [_form_custom_orderSubject]="formCustomOrderSubject"
        ></app-instance-questionnaire-configuration>
      </form>
    </div>
  </ng-template>
</div>
