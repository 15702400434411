<button [attr.id]="'btnDialogClose'" mat-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title>{{'group-instance.add_ecoach_to_assigned_interventions' | translate}}</h1>
<div mat-dialog-content class="mat-typography">
  {{'group-instance.add_ecoach_to_assigned_interventions_description' | translate}}
  <section>
    <div class="row my-2">
      <div class="col-12 col-md-6">
        <mat-form-field class="full-width">
          <mat-label>{{'group-instance.my_new_collaborator' | translate}}</mat-label>
          <mat-select [attr.id]="'selectionNewECoach'" (click)="resetFilter()" [(ngModel)]="newECoach">
            <mat-form-field class="full-width" appearance="fill">
              <mat-label>{{'group-instance.filter_mycollaborator_selection' | translate}}</mat-label>
              <input
                [attr.id]="'userNewSelection'"
                autocomplete="off"
                matInput
                (keyup)="onKeyFilterECoaches(newECoachesSubject, newECoach, filter['userNewSelection'])"
                [(ngModel)]="filter['userNewSelection']"
              />
            </mat-form-field>
            <mat-option *ngFor="let user of (newECoachesSubject | async)" [value]="user">{{user?.attributes.email}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-6">
        <mat-form-field class="full-width">
          <mat-label>{{'group-instance.my_current_collaborator' | translate}}</mat-label>
          <mat-select [attr.id]="'selectionCurrentECoach'" (click)="resetFilter()" [(ngModel)]="currentECoach">
            <mat-form-field class="full-width" appearance="fill">
              <mat-label>{{'group-instance.filter_mycollaborator_selection' | translate}}</mat-label>
              <input
                [attr.id]="'userCurrentSelection'"
                autocomplete="off"
                matInput
                (keyup)="onKeyFilterECoaches(currentECoachesSubject, currentECoach, filter['userCurrentSelection'])"
                [(ngModel)]="filter['userCurrentSelection']"
              />
            </mat-form-field>
            <mat-option *ngFor="let user of (currentECoachesSubject | async)" [value]="user">{{user?.attributes.email}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </section>
  <p>{{'group-instance.add_ecoach_note' | translate}}</p>
</div>
<div mat-dialog-actions align="end">
  <button [attr.id]="'btnCancel'" mat-button mat-dialog-close>{{'group-instance.modal_cancel' | translate}}</button>
  <app-button-feedback
    [_setId]="'btnAddECoachToECoachesInstanceStudy'"
    (click)="addECoachToECoachesInstanceStudy()"
    [_defaultButtonText]="'group-instance.add_ecoach_to_ecoach_instances'"
    [_responseText]="insertECoachResponse | async"
  ></app-button-feedback>
</div>
