<div class="container-fluid" [hidden]="isHidden$ | async">
  <div *ngFor="let payload of payloads; let i = index">
    <div [hidden]="(page$ | async) !== i">
      <div class="container text-start">
        <div class="row mb-2">
          <div class="col-sm-3">{{'instance-creation-confirmation.instance_patient' | translate}}:</div>
          <div class="col-sm-9">
            <span *ngIf="(emailSubject | async) !== ''; then thenEmail else elsePatientId"> </span>
            <ng-template #thenEmail> {{emailSubject | async}} </ng-template>
            <ng-template #elsePatientId> {{helper.getCodeNameEmail(payloadAttributes[i].patient_id, studyMembers)}} </ng-template>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-3">{{'instance-creation-confirmation.instance_ecoach' | translate}}:</div>
          <div class="col-sm-9">
            {{helper.findArrObjById(myUserId, studyCollaborator)?.attributes.email}} ({{helper.findArrObjById(myUserId,
            studyCollaborator)?.attributes.name}})
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-3">{{'instance-creation-confirmation.instance_additional_ecoach' | translate}}:</div>
          <div *ngIf="payloadAttributes[i].ecoach_ids.length !== 0;" class="col-sm-9">
            <span *ngFor="let id of payloadAttributes[i].ecoach_ids; first as isFirst"
              ><span *ngIf="!isFirst"> | </span> {{helper.findArrObjById(id, studyCollaborator)?.attributes.email}}
              ({{helper.findArrObjById(id, studyCollaborator)?.attributes.name}})
            </span>
          </div>
          <div *ngIf="payloadAttributes[i].ecoach_ids.length === 0;" class="col-sm-9">-</div>
        </div>

        <div class="row mb-2">
          <div class="col-sm-3">{{'instance-creation-confirmation.instance_guidance_support' | translate}}:</div>
          <div class="col-sm-9">
            <div
              *ngIf="payloadAttributes[i].intervention_type.toString().toUpperCase() === 'GUIDED'; then thenShowGuidedIcon else elseShowUnguidedIcon"
            ></div>
            <ng-template #thenShowGuidedIcon>
              <fa-icon [icon]="faHandsHelping"></fa-icon>
            </ng-template>
            <ng-template #elseShowUnguidedIcon>
              <fa-icon [icon]="faHands"></fa-icon>
            </ng-template>
            {{('instance-creation-confirmation.' + payloadAttributes[i].intervention_type.toLowerCase()) | translate}}
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-sm-3">{{'instance-creation-confirmation.instance_starting_at' | translate}}:</div>
          <div class="col-sm-9">{{helper.localizeDateTimestamp(payloadAttributes[i].configuration.starting_at)}}</div>
        </div>
        <mat-divider class="my-2"></mat-divider>

        <!-- Disable reminder
        <div *ngIf="reminderConfigs[i]; then thenShowReminder else elseNoReminder"></div>

        <ng-template #thenShowReminder>
          <div class="row">
            <div class="col-sm-3">
              {{'intervention-instance-reminder.form_create_intervention_instance_reminder_starts_at' | translate}}:
            </div>
            <div class="col-sm-9">{{helper.localizeDateTimestamp(reminderConfigs[i].payload.data.attributes.configuration.start)}}</div>

            <div class="col-sm-3">{{'intervention-instance-reminder.max_number_of_repetitions' | translate}}:</div>
            <div class="col-sm-9">{{reminderConfigs[i].payload.data.attributes.configuration.max_times}}</div>

            <div class="col-sm-3">{{'intervention-instance-reminder.rhythm' | translate}}:</div>
            <div class="col-sm-9">
              {{'intervention-instance-reminder.reminder_rhythm_1' | translate}}
              {{reminderConfigs[i].payload.data.attributes.configuration.pause}} {{('intervention-instance-reminder.' +
              reminderConfigs[i].payload.data.attributes.configuration.time_unit) |
              translate}}{{'intervention-instance-reminder.reminder_rhythm_2' | translate}}
            </div>
          </div>

          <p><strong>{{'intervention-instance-reminder.message_preview'| translate}}:</strong></p>
          <p>{{ defaultReminderText[reminderConfigs[i].payload.data.attributes.content.locale]['greeting'] }}</p>
          <p>
            <ng-template
              *ngIf="reminderConfigs[i].payload.data.attributes.content.text; then thenShowCustomMessage else elsShowDefaultMessage"
            ></ng-template>
            <ng-template #thenShowCustomMessage>{{reminderConfigs[i].payload.data.attributes.content.text}}</ng-template>
            <ng-template #elsShowDefaultMessage
              >{{ defaultReminderText[reminderConfigs[i].payload.data.attributes.content.locale]['defaultText'] }}</ng-template
            >
          </p>
          <p>{{ defaultReminderText[reminderConfigs[i].payload.data.attributes.content.locale]['ending'] }}</p>
          <p>
            <ng-template
              *ngIf="reminderConfigs[i].payload.data.attributes.content.ending; then thenShowCustomEnding else elsShowDefaultEnding"
            ></ng-template>
            <ng-template #thenShowCustomEnding>{{reminderConfigs[i].payload.data.attributes.content.ending}}</ng-template>
            <ng-template #elsShowDefaultEnding
              >{{ defaultReminderText[reminderConfigs[i].payload.data.attributes.content.locale]['defaultEnding'] }}</ng-template
            >
          </p>
        </ng-template>
        <ng-template #elseNoReminder>
          <p class="text-center text-info my-2">{{'intervention-instance-reminder.no_reminder_found' | translate}}</p>
        </ng-template>

        <div class="row" align="end">
          <div class="col align-self-end">
            <button
              class="float-end"
              [attr.id]="'btnOpenDialogInterventionInstanceReminderCreation_' + i"
              mat-button
              (click)="openDialogInterventionInstanceReminderCreation(i, reminderConfigs[i])"
            >
              {{'instance-creation-confirmation.create_intervention_instance_reminder' | translate}}
            </button>
            <button class="float-end" [attr.id]="'btnRemoveReminderConfig_' + i" mat-button (click)="removeReminderConfig(i)">
              {{'instance-creation-confirmation.remove_intervention_instance_reminder' | translate}}
            </button>
          </div>
        </div>

        <mat-divider class="my-2"></mat-divider>
        -->
        <div>
          <p>
            <strong> {{'instance-creation-confirmation.form_create_instance_custom_order' | translate}}: </strong>
          </p>

          <ul *ngIf="payloadAttributes[i].configuration.custom_order.length > 0; else elseNoLessonSelected" class="list-group">
            <li
              *ngFor="let questionnaire_id of payloadAttributes[i].configuration.custom_order; let j = index"
              class="list-group-item flex-column align-items-start"
            >
              <div class="d-flex justify-content-between">
                <p class="mb-1">
                  {{j + 1}}. {{getQuestionnaireById(questionnaire_id).attributes.title}} -
                  {{getQuestionnaireById(questionnaire_id).attributes.name}}
                </p>
              </div>

              <div *ngIf="getConfigurationByQuestionnaireId(questionnaire_id, i) as config" class="row">
                <div class="col-sm-6">
                  <p *ngIf="config.feedback_required; else elseFeedbackNotRequired">
                    <span>
                      <fa-icon [icon]="faComment"></fa-icon>
                      {{'instance-creation-confirmation.feedback_required' | translate}}</span
                    >
                  </p>
                  <ng-template #elseFeedbackNotRequired>
                    <p>
                      <span>
                        <fa-icon [icon]="faCommentSlash"></fa-icon>
                        {{'instance-creation-confirmation.feedback_not_required' | translate}}</span
                      >
                    </p>
                  </ng-template>
                </div>
                <div class="col-sm-6">
                  <div *ngIf="config.unlock_type.toUpperCase()  === 'AT_DATE'; then thenAtDate else elseOtherTypes"></div>
                  <ng-template #thenAtDate>
                    <p>
                      {{'instance-creation-confirmation.modal_detail_instance_unlock_at' | translate}}
                      {{helper.localizeDateTimestamp(config.unlock_date)}}
                    </p>
                  </ng-template>
                  <ng-template #elseOtherTypes>
                    <p>{{('instance-creation-confirmation.' + config.unlock_type.toLowerCase()) | translate}}</p>
                  </ng-template>
                  <small *ngIf="(showUnlockHint(config.id, i))" class="form-text text-muted">
                    {{'instance-creation-confirmation.form_create_instance_unlocks_next' | translate}}
                  </small>
                </div>
              </div>

              <div *ngIf="helper.hasUnlockDiaries(questionnaire_id, questionnaires)">
                <mat-divider></mat-divider>
                <p class="py-2">{{'instance-creation-form.unlocks_diaries' | translate}}:</p>
                <div class="row">
                  <div *ngFor="let diary of helper.findArrObjById(questionnaire_id, questionnaires).attributes.unlock_diaries">
                    <div
                      *appVariable="helper.findArrObjById(diary, findDiaries(payloadAttributes[i].intervention_id)) as selectedDiary"
                      class="col-12"
                    >
                      <fa-icon [icon]="faBook"></fa-icon>
                      {{selectedDiary !== null ? selectedDiary.attributes.name : ('lesson.diary_not_found' | translate)}}

                      <div>
                        <!-- <button mat-button (click)="openDialog()">{{'instance-creation-form.show_diary_configuration' | translate}}</button> -->
                        <!--
                        <button
                          mat-button
                          [attr.id]="'btnOpenDialogDiaryPreview_' + selectedDiary.id"
                          (click)="openDialogDiaryPreview(selectedDiary.attributes.questionnaire_id, selectedDiary.attributes.study_id, selectedDiary.attributes.page_color)"
                        >
                          <fa-icon [icon]="faEye"></fa-icon> {{'instance-creation-form.show_diary_preview' | translate}}
                        </button>
                      --></div>
                    </div>
                  </div>
                </div>
              </div>
              <!--
              <div *appVariable="helper.findArrObjById(questionnaire_id, questionnaires) as selectedQuestionnaire">
                <div *ngIf="selectedQuestionnaire.attributes?.skills">
                  <div *ngIf="selectedQuestionnaire.attributes.skills.length > 0; then thenShowSkills"></div>
                  <ng-template #thenShowSkills>
                    <div class="button-row">
                      <button
                        [attr.id]="'btnOpenDialogSkill_' + skillId"
                        *ngFor="let skillId of selectedQuestionnaire.attributes.skills; last as isLast"
                        [ngClass]="{'spacing-right': !isLast}"
                        class="mb-2"
                        (click)="openDialogSkill(skillId, payloadAttributes[i].intervention_id)"
                        mat-button
                      >
                        <span
                          [ngStyle]="helperSkill.getColorStyle(skillId, this.findInterventionSet(payloadAttributes[i].intervention_id).skills)"
                          ><fa-icon [icon]="faSquare"></fa-icon> {{helper.findArrObjById(skillId,
                          findSkills(payloadAttributes[i].intervention_id)) !== null ? helper.findArrObjById(skillId,
                          findSkills(payloadAttributes[i].intervention_id)).attributes.title : ('lesson.skill_not_found' |
                          translate)}}</span
                        >
                      </button>
                    </div>
                  </ng-template>
                </div>
              </div>
            --></li>
          </ul>
          <ng-template #elseNoLessonSelected>
            <li class="list-group-item text-center">
              {{'instance-creation-confirmation.form_create_instance_custom_order_no_item' | translate}}
            </li>
          </ng-template>
        </div>
        <br />
      </div>
    </div>
  </div>
  <div class="container row">
    <div class="col-sm-1 vertical-center">
      <button
        [attr.id]="'btnShowPrevious'"
        (click)="showPrevious()"
        *ngIf="((page$ | async) !== 0) && (numbersOfPages > 1)"
        class="button-design"
      >
        <fa-icon [icon]="faArrowLeft"></fa-icon>
      </button>
    </div>
    <div class="col-sm-10 vertical-center">
      <div>
        {{'patient-creation-instance-creation.modal_patient_configure_instance_selected_intervention' | translate}} -
        <strong>Title</strong>
        - {{'patient-creation-instance-creation.modal_patient_configure_instance_page' | translate}} {{pageSubject.value + 1}}
        {{'patient-creation-instance-creation.modal_patient_configure_instance_page_of' | translate}} {{numbersOfPages}}
      </div>
    </div>
    <div class="col-sm-1 vertical-center">
      <button
        [attr.id]="'btnShowNext'"
        (click)="showNext()"
        *ngIf="((page$ | async) !== (numbersOfPages - 1)) && (numbersOfPages > 1)"
        class="button-design"
      >
        <fa-icon [icon]="faArrowRight"></fa-icon>
      </button>
    </div>
  </div>
</div>
