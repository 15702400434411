import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, BehaviorSubject, Subscription, filter, take, throwError, mergeMap, of, switchMap } from 'rxjs';
import { OrganisationStore } from 'src/app/store/organisation/component-store/organisation.store';

// eslint-disable-next-line @angular-eslint/component-class-suffix
class ValueUsedMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && (control.dirty || control.touched));
  }
}

@Component({
  selector: 'app-dialog-organisation-account-creation',
  templateUrl: './dialog-organisation-account-creation.component.html',
  styleUrls: ['./dialog-organisation-account-creation.component.scss'],
  providers: [OrganisationStore]
})
export class DialogOrganisationAccountCreationComponent implements OnInit, OnDestroy {
  readonly emailRegex =
    // eslint-disable-next-line max-len
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  // Account form submission
  public accountForm: UntypedFormGroup;

  public createNewAccountResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');

  public isNameAlreadyInUseSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isEmailAlreadyInUseSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public matcher = new ValueUsedMatcher();

  public isSubmitted$: Observable<boolean>;

  public createAccountOrganisationResponse$: Observable<any>;
  public addECoachAccountToOrganisationResponse$: Observable<any>;

  public organisations_ids: Array<number> = [];

  private isSubmittedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<DialogOrganisationAccountCreationComponent>,
    private organisationStore: OrganisationStore
  ) {
    this.isSubmitted$ = this.isSubmittedSubject.asObservable();
    this.accountForm = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required, Validators.pattern(this.emailRegex)]),
      name: new UntypedFormControl('', [Validators.required]),
      firstname: new UntypedFormControl(''),
      lastname: new UntypedFormControl(''),
      admin: new UntypedFormControl(false),
      ecoach: new UntypedFormControl(false),
      editor: new UntypedFormControl(false)
    });
    this.createAccountOrganisationResponse$ = this.organisationStore.createAccountOrganisationResponse$;
    this.addECoachAccountToOrganisationResponse$ = this.organisationStore.addECoachAccountToOrganisationResponse$;
  }

  public get f() {
    return this.accountForm;
  }

  ngOnInit(): void {
    this.organisations_ids = this.data.organisations_ids;
  }

  public resetError(): void {
    this.isNameAlreadyInUseSubject.next(false);
    this.isEmailAlreadyInUseSubject.next(false);
  }

  public registerNewAccount(): void {
    this.isSubmittedSubject.next(true);
    this.resetError();
    if (this.accountForm.invalid) {
      return;
    }
    const name = this.f.get('name').value || this.f.get('name').value === '' ? this.f.get('name').value : undefined;
    const firstname = this.f.get('firstname').value || this.f.get('firstname').value === '' ? this.f.get('firstname').value : undefined;
    const lastname = this.f.get('lastname').value || this.f.get('lastname').value === '' ? this.f.get('lastname').value : undefined;

    if (this.createNewAccountResponse.value === 'DEFAULT') {
      this.createNewAccountResponse.next('LOADING');

      this.organisationStore.createAccountOrganisationManager({
        email: this.f.get('email').value,
        organisations_ids: this.organisations_ids,
        name,
        firstname,
        lastname
      });

      this.subscriptions.push(
        this.createAccountOrganisationResponse$
          .pipe(
            filter(value => !!value),
            take(1),
            switchMap((value: any) => {
              if (value instanceof HttpResponse) {
                return of(value);
              } else {
                if (value instanceof HttpErrorResponse) {
                  const errorMessage = value.error.errors[0];
                  if (errorMessage.code.toString() === '32' && errorMessage.title === 'User Already Exists') {
                    if (errorMessage.detail === 'This username address already exists. Please choose another one.') {
                      this.isNameAlreadyInUseSubject.next(true);
                    }
                    if (errorMessage.detail === 'This email address already exists. Please choose another email address.') {
                      this.isEmailAlreadyInUseSubject.next(true);
                    }
                    this.organisationStore.addECoachAccountToOrganisation({
                      emails: [this.f.get('email').value],
                      organisations_ids: this.organisations_ids
                    });
                    return this.addECoachAccountToOrganisationResponse$.pipe(
                      filter(val => !!val),
                      take(1)
                    );
                  }
                }
                return throwError(value);
              }
            })
          )
          .subscribe(value => {
            if (value instanceof HttpResponse) {
              this.createNewAccountResponse.next('SUCCESS');
              setTimeout(() => {
                this.createNewAccountResponse.next('DEFAULT');
                this.dialogRef.close();
              }, 2500);
            } else {
              this.createNewAccountResponse.next('FAILURE');
              setTimeout(() => {
                this.createNewAccountResponse.next('DEFAULT');
              }, 2500);
            }
          })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
