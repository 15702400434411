import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PayloadInterface } from '../../../models/interface/payload.interface';
import { HelperService } from '../../helper/helper.service';

@Injectable({
  providedIn: 'root'
})
export class AnnouncementService {
  public header: HttpHeaders = new HttpHeaders();
  private backendUrl: string = environment.backendURL;

  constructor(private http: HttpClient, private helperService: HelperService) {
    this.header = this.header.set('Content-Type', 'application/json');
    this.header = this.helperService.setLocaleFromStorage(this.header);
  }

  /**
   * AAS2 API User - All Announcements
   * This function retrieves all announcements.
   *
   * @params
   * @return Observable<any> - An observable for any response.
   */
  public getAllAnnouncements(
    client?: string,
    operatorStart?: '>' | '<' | '=',
    start?: number,
    operatorEnd?: '>' | '<' | '=',
    end?: number
  ): Observable<any> {
    const params = this.setHttpParams(client, operatorStart, start, operatorEnd, end);
    this.header = this.helperService.setLocaleFromStorage(this.header);
    return this.http.get<any>(`${this.backendUrl}/api/v1/announcements?limit=0`, {
      headers: this.header,
      observe: 'response',
      params
    });
  }

  /**
   * AAS2 API User - Get an Announcement
   * This function retrieves a specific announcement.
   *
   * @params number announcementId - ID of the announcement.
   * @return Observable<any> - An observable for any response.
   */
  public getAnnouncement(announcementId: number): Observable<any> {
    this.header = this.helperService.setLocaleFromStorage(this.header);
    return this.http.get<any>(`${this.backendUrl}/api/v1/announcements/${announcementId}`, { headers: this.header, observe: 'response' });
  }

  /**
   * AAS2 API Admin - Create an Announcement as admin
   * This function creates an announcement as an administrator
   *
   * @params string title - title of the announcement.
   *         string message - message of the announcement.
   *         string type - type of the announcement.
   *         Array<'eCoach' | 'cms' | 'app'> - announcement for targeted clients.
   *         number - start date in unix timestamp.
   *         number - end date in unix timestamp.
   * @return Observable<any> - An observable for any response.
   */
  public createAnnouncement(
    title: string,
    message: string,
    type: string,
    client: Array<'eCoach' | 'cms' | 'app'>,
    start: number,
    end: number
  ): Observable<any> {
    const payload: PayloadInterface = {
      data: {
        type: 'announcement',
        attributes: {
          title,
          message,
          type,
          client,
          start,
          end
        }
      }
    };
    return this.http.post<any>(`${this.backendUrl}/api/v1/admin/announcements`, payload, { headers: this.header, observe: 'response' });
  }

  /**
   * AAS2 API Admin - Deletes a specific Announcement as an admin
   * This function deletes an announcement.
   *
   * @params number announcementId - ID of the announcement.
   * @return Observable<any> - An observable for any response.
   */
  public deleteAnnouncement(announcementId: number): Observable<any> {
    return this.http.delete<any>(`${this.backendUrl}/api/v1/admin/announcements/${announcementId}`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API Admin - Update an Announcement as admin
   * This function updates an announcement as an administrator
   *
   * @params number announcementId - ID of the announcement.
   *         string title - title of the announcement.
   *         string message - message of the announcement.
   *         string type - type of the announcement.
   *         Array<'eCoach' | 'cms' | 'app'> - announcement for targeted clients.
   *         number - start date in unix timestamp.
   *         number - end date in unix timestamp.
   * @return Observable<any> - An observable for any response.
   */
  public updateAnnouncement(
    announcementId: number,
    title: string,
    message: string,
    type: string,
    client: Array<'eCoach' | 'cms' | 'app'>,
    start: number,
    end: number
  ): Observable<any> {
    const payload: PayloadInterface = {
      data: {
        type: 'announcement',
        attributes: {
          title,
          message,
          type,
          client,
          start,
          end
        }
      }
    };
    return this.http.patch<any>(`${this.backendUrl}/api/v1/admin/announcements/${announcementId}`, payload, {
      headers: this.header,
      observe: 'response'
    });
  }

  public setHttpParams(
    client?: string,
    operatorStart?: '>' | '<' | '=',
    start?: number,
    operatorEnd?: '>' | '<' | '=',
    end?: number
  ): HttpParams {
    let params = new HttpParams();
    if (client !== undefined) {
      params = params.set('client', client);
    }
    if (operatorStart !== undefined && start !== undefined) {
      if (operatorStart === '>') {
        params = params.set('start>', start.toString());
      }
      if (operatorStart === '=') {
        params = params.set('start', start.toString());
      }
      if (operatorStart === '<') {
        params = params.set('start<', start.toString());
      }
    } else if (start !== undefined) {
      params = params.set('start', start.toString());
    }
    if (operatorEnd !== undefined && end !== undefined) {
      if (operatorEnd === '>') {
        params = params.set('end>', end.toString());
      }
      if (operatorEnd === '=') {
        params = params.set('end', end.toString());
      }
      if (operatorEnd === '<') {
        params = params.set('end<', end.toString());
      }
    } else if (end !== undefined) {
      params = params.set('end', end.toString());
    }
    return params;
  }
}
