import { Action, createAction, props } from '@ngrx/store';
import { RoleInterface } from '../../models/interface/role.interface';
import { ActivityInterface } from 'src/app/models/interface/activity.interface';
import { ProfileInterface } from 'src/app/models/interface/profile.interface';

export enum ProfileActionTypes {
  addProfileStoreType = 'Add Profile Store',
  updateProfileStoreType = 'Update Profile Store',
  deleteProfileStoreType = 'Delete Profile Store',
  addProfileRolesStoreType = 'Add Profile Roles Store',
  updateProfileRolesStoreType = 'Update Profile Roles Store',
  deleteProfileRolesStoreType = 'Delete Profile Roles Store',
  addProfileActivitiesStoreType = 'Add Profile Activities Store',
  updateProfileActivitiesStoreType = 'Update Profile Activities Store',
  deleteProfileActivitiesStoreType = 'Remove Profile Activities Store',

  getProfileType = '[Profile API] Get Profile',
  getProfileSuccessType = '[Success] Get Profile',
  getProfileErrorType = '[Error] Get Profile',

  deleteProfileType = '[Profile API] Delete Profile',
  deleteProfileSuccessType = '[Success] Delete Profile',
  deleteProfileErrorType = '[Error] Delete Profile',

  getProfileRolesType = '[Profile API] Get Profile Roles',
  getProfileRolesSuccessType = '[Success] Get Profile Roles',
  getProfileRolesErrorType = '[Error] Get Profile Roles',

  getProfileActivitiesType = '[Profile API] Get Profile Activities',
  getProfileActivitiesSuccessType = '[Success] Get Profile Activities',
  getProfileActivitiesErrorType = '[Error] Get Profile Activities'
}
export const addProfileStore = createAction(ProfileActionTypes.addProfileStoreType, props<{ myProfile: ProfileInterface }>());
export const addProfileRolesStore = createAction(ProfileActionTypes.addProfileRolesStoreType, props<{ myRoles: Array<RoleInterface> }>());
export const addProfileActivitiesStore = createAction(
  ProfileActionTypes.addProfileActivitiesStoreType,
  props<{ myActivities: Array<ActivityInterface> }>()
);
export const updateProfileStore = createAction(ProfileActionTypes.updateProfileStoreType, props<{ myProfile: ProfileInterface }>());
export const updateProfileRolesStore = createAction(
  ProfileActionTypes.updateProfileRolesStoreType,
  props<{ myRoles: Array<RoleInterface> }>()
);
export const updateProfileActivitiesStore = createAction(
  ProfileActionTypes.updateProfileActivitiesStoreType,
  props<{ myActivities: Array<ActivityInterface> }>()
);
export const removeProfileStore = createAction(ProfileActionTypes.deleteProfileStoreType);
export const removeProfileRolesStore = createAction(ProfileActionTypes.deleteProfileRolesStoreType);
export const removeProfileActivitiesStore = createAction(ProfileActionTypes.deleteProfileActivitiesStoreType);
export class GetProfileActivities implements Action {
  readonly type: string = ProfileActionTypes.getProfileActivitiesType;
  constructor(readonly payload: any) {}
}
