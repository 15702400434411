<button [attr.id]="'btnDialogClose'" mat-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title>{{'admin-account-deletion.deletion_request' | translate: param}}</h1>

<div class="content-height" mat-dialog-content>
  <ng-template *ngIf="selectedDeletionRequest?.attributes.pseudonymized === 0; then thenIsNotPseudonymized else elseIsPseudonymized">
  </ng-template>
  <ng-template #thenIsNotPseudonymized>
    <p [innerHtml]="'admin-account-deletion.deletion_request_is_not_pseudonymized' | translate"></p>
  </ng-template>
  <ng-template #elseIsPseudonymized>
    <p [innerHtml]="'admin-account-deletion.deletion_request_is_pseudonymized' | translate"></p>
  </ng-template>

  <p>{{'admin-account-deletion.deletion_request_details' | translate}}</p>
  <mat-card appearance="outlined" class="mb-2" *ngFor="let study of selectedStudies">
    <mat-card-content>
      <div class="row justify-content-center">
        <div class="col-12 col-lg-3 center">
          <p>{{((study.attributes.title) ? study.attributes.title : 'group.no_group_title') | translate}} (ID - {{study.id}})</p>
          <p><strong>{{((study.attributes.name) ? study.attributes.name : 'group.no_group_name') | translate}}</strong></p>
        </div>
        <div class="col-12 col-lg-2 center">
          <img
            class="mb-2"
            mat-card-sm-image
            [src]="helper.getAllMediaSupportPath(study.attributes.picture, study.id)"
            (error)="helper.getFallbackImage($event)"
          />
        </div>
        <div class="col-12 col-lg-4 center">
          <p>{{study.attributes.description}}</p>
        </div>
        <div class="col-12 col-lg-3 center">
          <p><fa-icon [icon]="faEnvelope"></fa-icon> {{'admin-account-deletion.contact_ecoach_manager' | translate}}:</p>
          <div class="row justify-content-left">
            <a class="col-12" *ngFor="let email of getECoachManager(study.id)" [attr.href]="'mailto:' + email" mat-button> {{email}} </a>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
<div mat-dialog-actions align="end">
  <button [attr.id]="'btnCancelDialog'" mat-button mat-dialog-close>{{'admin-dashboard.cancel' | translate}}</button>
  <app-button-feedback
    [_setId]="'btnRejectAccountDeletionRequest'"
    [_isDisabled]="(acceptAccountDeletionRequestResponse | async) !== 'DEFAULT'"
    (click)="rejectAccountDeletionRequest()"
    [_defaultButtonText]="'admin-dashboard.reject_deletion_request'"
    [_responseText]="rejectAccountDeletionRequestResponse | async"
  ></app-button-feedback>
  <app-button-feedback
    [_setId]="'btnAcceptAccountDeletionRequest'"
    [_isDisabled]="(rejectAccountDeletionRequestResponse | async) !== 'DEFAULT'"
    (click)="acceptAccountDeletionRequest()"
    [_defaultButtonText]="'admin-dashboard.accept_deletion_request'"
    [_responseText]="acceptAccountDeletionRequestResponse | async"
  ></app-button-feedback>
</div>
