<div class="container-fluid text-center">
  <div class="col-sm-8 text-start">
    <h1>{{ 'task.tasks' | translate }}</h1>
  </div>
  <hr />
  <h4>
    {{ 'task.overview_of_tasks' | translate }}
    <button [attr.id]="'btnToggleDescription'" (click)="helper.toggleSubject(showDescriptionSubject)" aria-label="Information" mat-button>
      <fa-icon [icon]="faInfoCircle"></fa-icon>
    </button>
  </h4>

  <div class="card my-3">
    <div class="card-body">
      <div *ngIf="showDescription$ | async" class="row justify-content-center">
        <div class="col-sm-9">
          <p class="text-justify">{{ 'task.description_task' | translate }}</p>
        </div>
      </div>

      <div class="row justify-content-center mb-3">
        <div class="col-12 col-sm-3 mb-2">
          <button
            [attr.id]="'btnToggleFilter'"
            (click)="helper.toggleSubject(isCollapseSubject)"
            class="mt-1"
            mat-raised-button
            type="submit"
          >
            <fa-icon [icon]="faList"></fa-icon>
            Filter
          </button>
        </div>

        <div class="col-12 col-sm-6 mb-2">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>{{'task.select' | translate}}</mat-label>
            <mat-select [attr.id]="'selectStudy'" (selectionChange)="showSelected(selectedStudy)" [(value)]="selectedStudy">
              <mat-option
                [attr.id]="'selection_' + selection?.study?.id"
                *ngFor="let selection of showStudyActivities$ | async"
                [value]="selection"
                >{{displaySelect(selection) | translate}}
                <span *ngIf="selection.study !== undefined">(ID {{selection.study.id}})</span></mat-option
              >
            </mat-select>
          </mat-form-field>
          <mat-form-field class="full-width">
            <mat-label>{{'activity.search_user' | translate}}</mat-label>
            <input (keyup)="keyUp.next($event)" [(ngModel)]="filter['searchFilter']" matInput />
          </mat-form-field>
        </div>

        <div class="col-sm-3 mb-1"></div>
      </div>

      <div *ngIf="isLoading$ | async; else elseTaskBlock" class="loadingSpinner">
        <app-loader></app-loader>
      </div>

      <ng-template #elseTaskBlock>
        <div [ngClass]="(isCollapse$ | async) ? 'justify-content-center' : 'row'" class="text-start">
          <div *ngIf="(isCollapse$ | async) === false" class="col-sm-3">
            <h3>{{ 'task.activity_status_filter' | translate }}</h3>
            <mat-checkbox [attr.id]="'checkboxPending'" (change)="applyFilter()" [(ngModel)]="filter['checkPending']"
              >{{ 'task.pending_task' | translate }}</mat-checkbox
            >
            <mat-checkbox [attr.id]="'checkboxDone'" (change)="applyFilter()" [(ngModel)]="filter['checkDone']"
              >{{ 'task.completed_task' | translate }}</mat-checkbox
            >
          </div>

          <div [ngClass]="(isCollapse$ | async) === false ? 'col-sm-9' : 'justify-content-center'">
            <div *ngIf="(tableContent$ | async)?.length === 0; else elseShowTasksBlock">
              <p class="centering">{{ 'task.no_tasks_found' | translate }}</p>
            </div>

            <!-- Table -->
            <ng-template #elseShowTasksBlock>
              <div class="mat-elevation-z0 text-start mb-3">
                <table (matSortChange)="onChangeSortTable($event)" [dataSource]="dataSource" mat-table matSort>
                  <!-- Activity_text Column -->
                  <ng-container matColumnDef="activity_text">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>{{'task.task' | translate}}</th>
                    <td *matCellDef="let activity" mat-cell>
                      {{helperActivity.translateActivityToTask(activity, activity.relationships?.questionnaires.data) | async}}
                      <span *ngIf="activity.attributes.action === 'ANSWERSHEET_SUBMITTED'"
                        >: {{helperActivity.getLessonNameOfActivity(activity, activity.relationships?.questionnaires.data)}}</span
                      >
                    </td>
                  </ng-container>

                  <!-- Performed_by Column -->
                  <ng-container matColumnDef="performed_by">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>{{'task.task_for' | translate}}</th>
                    <td *matCellDef="let activity" mat-cell>
                      {{helper.findArrObjById(activity.attributes.user, users) !== null ? helper.getCodeNameEmail(activity.attributes.user,
                      users) : ('task.system' | translate)}}
                    </td>
                  </ng-container>

                  <!-- Created_at Column -->
                  <ng-container matColumnDef="created_at">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>{{'task.created_at' | translate}}</th>
                    <td *matCellDef="let activity" mat-cell>{{helper.localizeDateString(activity.attributes.created_at)}}</td>
                  </ng-container>

                  <!-- Status Column -->
                  <ng-container matColumnDef="status">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>{{'task.status' | translate}}</th>
                    <td *matCellDef="let activity" mat-cell>
                      <!-- Answersheet status -->
                      <div *ngIf="activity.attributes.action === 'ANSWERSHEET_SUBMITTED'; then thenShowAnswersheetStatus"></div>
                      <ng-template #thenShowAnswersheetStatus>
                        <div class="my-3">
                          <div *ngIf="getStatusString(activity) === 'completed'">
                            <p>
                              {{'task.task_complete' | translate}}
                              <fa-icon [icon]="faCheck" class="fa-fw green-icon"></fa-icon>
                            </p>
                            <button
                              [attr.id]="'btnShowAnswersheet_' + activity.id"
                              (click)="showAnswersheet(activity)"
                              color="primary"
                              mat-button
                            >
                              {{'task.to_feedback' | translate}}
                              <fa-icon [icon]="faComments"></fa-icon>
                            </button>
                          </div>
                          <div *ngIf="getStatusString(activity) === 'pending'">
                            <p>
                              {{'task.task_pending' | translate}}
                              <fa-icon [icon]="faTimes" class="fa-fw red-icon"></fa-icon>
                            </p>
                            <button
                              [attr.id]="'btnShowAnswersheet_' + activity.id"
                              (click)="showAnswersheet(activity)"
                              color="primary"
                              mat-button
                            >
                              {{'task.provide_feedback' | translate}}
                              <fa-icon [icon]="faPlusCircle"></fa-icon>
                            </button>
                          </div>
                          <div *ngIf="getStatusString(activity) === 'canceled'">
                            <p class="text-danger">{{'task.task_canceled' | translate}}</p>
                            <button
                              [attr.id]="'btnShowAnswersheet_' + activity.id"
                              (click)="showAnswersheet(activity)"
                              color="primary"
                              mat-button
                            >
                              {{'task.to_answersheet' | translate}}
                            </button>
                          </div>
                          <!-- No answersheet status available -->
                          <ng-template #elseNoStatus>
                            <p>
                              {{'task.deleted' | translate}}
                              <fa-icon [icon]="faMinusCircle"></fa-icon>
                            </p>
                          </ng-template>
                        </div>
                      </ng-template>

                      <!-- Unlock questionnaire status -->
                      <div *ngIf="activity.attributes.action === 'INTERVENTION_INSTANCE_CAN_UNLOCK'; then thenShowUnlockStatus"></div>
                      <ng-template #thenShowUnlockStatus>
                        <div class="my-3">
                          <div *ngIf="getStatusString(activity) === 'completed'">
                            <p>
                              <fa-icon [icon]="faCheck" class="fa-fw green-icon"></fa-icon>
                            </p>
                            <button
                              [attr.id]="'btnOpenDialogInterventionInstanceDetails' + activity.id"
                              (click)="openDialogInterventionInstanceDetails(activity)"
                              color="primary"
                              mat-button
                            >
                              {{'task.task_show_instance_details' | translate}}
                              <fa-icon [icon]="faComments"></fa-icon>
                            </button>
                          </div>
                          <div *ngIf="getStatusString(activity) === 'pending'">
                            <p>
                              {{'task.task_pending' | translate}}
                              <fa-icon [icon]="faTimes" class="fa-fw red-icon"></fa-icon>
                            </p>
                            <button
                              [attr.id]="'btnOpenDialogInterventionInstanceDetails' + activity.id"
                              (click)="openDialogInterventionInstanceDetails(activity)"
                              color="primary"
                              mat-button
                            >
                              {{'task.task_unlock_lesson' | translate}}
                              <fa-icon [icon]="faPlusCircle"></fa-icon>
                            </button>
                          </div>
                          <div *ngIf="getStatusString(activity) === 'canceled'">
                            <p class="text-danger">{{'task.task_canceled' | translate}}</p>
                            <button
                              [attr.id]="'btnOpenDialogInterventionInstanceDetails' + activity.id"
                              (click)="openDialogInterventionInstanceDetails(activity)"
                              color="primary"
                              mat-button
                            >
                              {{'task.task_unlock_lesson' | translate}}
                              <fa-icon [icon]="faPlusCircle"></fa-icon>
                            </button>
                          </div>
                        </div>
                      </ng-template>
                    </td>
                  </ng-container>

                  <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                  <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
                </table>
                <mat-paginator
                  (page)="onPageChangeActivity($event)"
                  [length]="this.selectedStudy.activities.length"
                  [showFirstLastButtons]="true"
                  [pageSizeOptions]="[10, 20, 50]"
                  [pageSize]="20"
                ></mat-paginator>
              </div>
            </ng-template>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
