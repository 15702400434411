import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { skip, take } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { PayloadInterface } from '../../../models/interface/payload.interface';
import { RequestBodyData } from '../../../models/request-body.data';
import { UserInterface } from '../../../models/interface/user.interface';
import { OrganisationStore } from '../../../store/organisation/component-store/organisation.store';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-dialog-organisation-collaborator-remove',
  templateUrl: './dialog-organisation-collaborator-remove.component.html',
  styleUrls: ['./dialog-organisation-collaborator-remove.component.scss'],
  providers: [OrganisationStore]
})
export class DialogOrganisationCollaboratorRemoveComponent implements OnInit, OnDestroy {
  public collaborator: UserInterface;
  public param;
  public organisationId: number;

  public removeCollaboratorResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');

  private deleteOrganisationCollaboratorResponse$: Observable<any>;

  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private organisationStore: OrganisationStore,
    private dialogRef: MatDialogRef<DialogOrganisationCollaboratorRemoveComponent>
  ) {
    this.deleteOrganisationCollaboratorResponse$ = this.organisationStore.deleteOrganisationCollaboratorResponse$;
  }

  ngOnInit(): void {
    this.collaborator = this.data.collaborator;
    this.param = this.data.param;
    this.organisationId = this.data.organisationId;
  }

  // Removing collaborator from organisation
  public removeCollaborator(userId: number): void {
    if (this.removeCollaboratorResponse.value === 'DEFAULT') {
      const payload: PayloadInterface = new RequestBodyData('organisations', { collaborators: [{ id: userId }] });

      this.removeCollaboratorResponse.next('LOADING');
      this.organisationStore.deleteOrganisationCollaborator({
        organisationId: this.organisationId,
        payload
      });

      this.subscriptions.push(
        this.deleteOrganisationCollaboratorResponse$.pipe(skip(1), take(1)).subscribe((result: any) => {
          if (result instanceof HttpResponse) {
            this.removeCollaboratorResponse.next('SUCCESS');
            setTimeout(() => {
              this.removeCollaboratorResponse.next('DEFAULT');
              this.dialogRef.close('SUCCESS');
            }, 2500);
          } else {
            this.removeCollaboratorResponse.next('FAILURE');
            setTimeout(() => {
              this.removeCollaboratorResponse.next('DEFAULT');
            }, 2500);
          }
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
