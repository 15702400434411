<ion-card *ngIf="intervention?.attributes.gamification?.progressbars_enabled && !answers && !isDiary" class="progress-panel" slot="fixed">
  <ion-grid>
    <ion-row class="ion-justify-content-center">
      <ion-col size="11">
        <app-text-progress-bar
          [pxProgressBarHeight]="20"
          [pxFontSize]="17"
          [value]="getLessonProgress() / 100"
          [text]="getLessonProgress() + '%'"
        ></app-text-progress-bar>
      </ion-col>
      <ion-col size="auto">
        <ion-icon name="flag" class="flag"></ion-icon>
      </ion-col>
    </ion-row>
    <ion-row class="auto-height">
      <ion-col class="zero-padding">
        <ion-card-content class="progressbar-text ion-text-wrap" [class.showProgressbarText]="showProgressbarText">
          <div class="card-padding">{{progressbarText}}</div>
        </ion-card-content>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-card>
