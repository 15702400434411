<button [attr.id]="'btnDialogClose'" mat-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title>{{'group-configuration.study_media_gallery_title' | translate}}</h1>
<div mat-dialog-content class="mat-typography">
  <p>{{'group-configuration.study_media_gallery_select' | translate}}</p>

  <ng-template #selectMediaFromGallery> </ng-template>
</div>
<div mat-dialog-actions align="end">
  <button [attr.id]="'btnCancel'" mat-button mat-dialog-close>{{'group-configuration.cancel' | translate}}</button>
  <button [attr.id]="'btnSelectImage'" (click)="selectImage()" mat-button type="button">{{'group-configuration.apply' | translate}}</button>
</div>
