import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { download } from 'src/app/helpers/download/download';
import { environment } from '../../../../environments/environment';
import { PayloadInterface } from '../../../models/interface/payload.interface';
import { HelperService } from '../../helper/helper.service';

@Injectable({
  providedIn: 'root'
})
export class ReminderService {
  public header: HttpHeaders = new HttpHeaders();
  private backendUrl: string = environment.backendURL;

  constructor(private http: HttpClient, private helperService: HelperService) {
    this.header = this.header.set('Content-Type', 'application/json');
    this.header = this.helperService.setLocaleFromStorage(this.header);
  }

  /**
   * AAS2 API eCoach - Set intervention instance reminder
   * This function creates an intervention instance reminder.
   *
   * @params number instanceId - ID of the intervention instance.
   *         number receiverId - ID of the receipient.
   *         number maxTimes - Number of times the user should be reminded.
   *         number start - Timestamp when the reminder begins.
   *         number repeatTime - Time interval of the repetition.
   *         string repeatUnit - Time interval unit of the repetition.
   *         string contentText - Content of the reminder text.
   *         string contentEnding - Individual sender text
   * @return Observable<any> - An observable for any response.
   */
  public setInterventionInstanceReminder(
    instanceId: number,
    receiverId: number,
    maxTimes: number,
    start: number,
    repeatTime: number,
    repeatUnit: 'minute' | 'hour' | 'day',
    contentText?: string,
    contentEnding?: string,
    locale?: string
  ): Observable<any> {
    const payload: PayloadInterface = {
      data: {
        type: 'reminders',
        attributes: {
          receiver_id: receiverId,
          configuration: {
            max_times: maxTimes,
            start,
            pause: repeatTime,
            time_unit: repeatUnit
          }
        }
      }
    };
    const content = { locale: 'de' };
    if (locale) {
      content['locale'] = locale;
    }
    if (contentText) {
      content['text'] = contentText;
    }
    if (contentEnding) {
      content['ending'] = contentEnding;
    }
    payload.data.attributes['content'] = content;
    return this.http.post<any>(`${this.backendUrl}/api/v1/ecoach/reminders/interventions/instances/${instanceId}`, payload, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API eCoach - Update intervention instance reminder
   * This function updates an intervention instance reminder.
   *
   * @params number reminderId - ID of the reminder.
   *         number receiverId - ID of the receipient.
   *         number maxTimes - Number of times the user should be reminded.
   *         number start - Timestamp when the reminder begins.
   *         number repeatTime - Time interval of the repetition.
   *         string repeatUnit - Time interval unit of the repetition.
   *         string contentText - Content of the reminder text.
   *         string contentEnding - Individual sender text
   * @return Observable<any> - An observable for any response.
   */
  public updateInterventionInstanceReminder(
    reminderId: number,
    receiverId: number,
    maxTimes: number,
    start: number,
    repeatTime: number,
    repeatUnit: 'minute' | 'hour' | 'day',
    contentText: string,
    contentEnding: string,
    locale: string
  ): Observable<any> {
    const payload: PayloadInterface = {
      data: {
        type: 'reminders',
        attributes: {
          receiver_id: receiverId,
          max_times: maxTimes,
          start,
          configuration: {
            pause: repeatTime,
            time_unit: repeatUnit
          },
          content: {
            text: contentText,
            ending: contentEnding,
            locale
          }
        }
      }
    };
    return this.http.patch<any>(`${this.backendUrl}/api/v1/ecoach/reminders/${reminderId}`, payload, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API eCoach - Get an Intervention Instance Reminder
   * This function returns an intervention instance reminder.
   *
   * @params number reminderId - ID of the reminder.
   * @return Observable<any> - An observable for any response.
   */
  public getInterventionInstanceReminder(reminderId: number): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoach/reminders/${reminderId}`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API eCoach - Get all Intervention Instance Reminders of eCoach
   * This function returns all intervention instance reminders of the eCoach.
   *
   * @return Observable<any> - An observable for any response.
   */
  public getAllInterventionInstanceReminders(): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoach/reminders?limit=0`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API eCoach - Delete an Intervention Instance Reminder
   * This function deletes an intervention instance reminder.
   *
   * @params number reminderId - ID of the reminder.
   * @return Observable<any> - An observable for any response.
   */
  public deleteInterventionInstanceReminder(reminderId: number): Observable<any> {
    return this.http.delete<any>(`${this.backendUrl}/api/v1/ecoach/reminders/${reminderId}`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API eCoach - Get all Reminder logs of Study
   * This function returns all reminder logs of all intervention instance reminders of the study.
   *
   * @params number studyId - ID of the study.
   * @return Observable<any> - An observable for any response.
   */
  public getReminderLogsOfStudy(studyId: number, userId?: number): Observable<any> {
    let params = new HttpParams();
    if (userId !== undefined) {
      params = params.set('userID', userId);
    }
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoach/studies/${studyId}/reminders/logs?limit=0`, {
      headers: this.header,
      observe: 'response',
      params
    });
  }

  /**
   * AAS2 API eCoach - Export all Reminder logs of Study as CSV
   * This function returns all reminder logs of all intervention instance reminders of the study in csv.
   *
   * @params number studyId - ID of the study.
   * @return Observable<any> - An observable for any response.
   */
  public getReminderLogsOfStudyCSV(studyId: number, userId?: number, separator?: ';', deletedReminders = 1): Observable<any> {
    this.header = this.header.set('Content-Type', '*/*');
    this.header = this.header.set('Accept', '*/*');
    let params = new HttpParams();
    if (userId !== undefined) {
      params = params.set('userID', userId);
    }
    if (separator !== undefined) {
      params = params.set('separator', separator);
    }
    if (separator !== undefined) {
      params = params.set('separator', separator);
    }
    if (deletedReminders !== undefined) {
      params = params.set('deletedReminders', deletedReminders);
    }
    let downloaded = false;
    return this.http
      .get<any>(`${this.backendUrl}/api/v1/ecoach/studies/${studyId}/reminders/logs/csv/report`, {
        headers: this.header,
        reportProgress: true,
        observe: 'events',
        params,
        responseType: 'blob' as 'json'
      })
      .pipe(
        download(jsonResponse => {
          const getFileName = (response: HttpResponse<any>) => {
            let fileName: string;
            try {
              const contentDisposition: string = response.headers.get('content-disposition');
              const r = /filename="(.+)"/;
              fileName = r.exec(contentDisposition)[1];
            } catch (error) {
              fileName = `reminderLogs-studyId${studyId}-${new Date().toISOString()}.csv`;
            }
            return fileName;
          };

          const filename = `reminderLogs-studyId${studyId}-${new Date().toISOString()}.csv`;
          const binaryData = [];
          binaryData.push(jsonResponse);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/csv' }));
          downloadLink.setAttribute('download', filename);
          document.body.appendChild(downloadLink);
          if (!downloaded) {
            downloadLink.click();
            URL.revokeObjectURL(downloadLink.href);
            downloaded = true;
          }
        })
      );
  }
}
