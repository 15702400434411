import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { StudyInterface } from '../../../models/interface/study/study.interface';

@Injectable({
  providedIn: 'root'
})
export class GroupSharedService {
  public sourceGroup$: Observable<StudyInterface>;
  public activeChildroute$: Observable<string>;
  public isECoach$: Observable<boolean>;
  public isManager$: Observable<boolean>;
  public isPublisher$: Observable<boolean>;
  public isCollaborating$: Observable<boolean>;
  public isOrganisationManager$: Observable<boolean>;

  private sourceGroupSubject = new BehaviorSubject<StudyInterface>(null);
  private isECoachSubject = new BehaviorSubject<boolean>(false);
  private isManagerSubject = new BehaviorSubject<boolean>(false);
  private isPublisherSubject = new BehaviorSubject<boolean>(false);
  private isCollaboratingSubject = new BehaviorSubject<boolean>(true);
  private isOrganisationManagerSubject = new BehaviorSubject<boolean>(false);

  constructor() {
    this.sourceGroup$ = this.sourceGroupSubject.asObservable();
    this.isECoach$ = this.isECoachSubject.asObservable();
    this.isManager$ = this.isManagerSubject.asObservable();
    this.isPublisher$ = this.isPublisherSubject.asObservable();
    this.isCollaborating$ = this.isCollaboratingSubject.asObservable();
    this.isOrganisationManager$ = this.isOrganisationManagerSubject.asObservable();
  }

  pushIsECoach(value: boolean) {
    this.isECoachSubject.next(value);
  }

  pushGroup(group: StudyInterface) {
    this.sourceGroupSubject.next(group);
  }

  pushIsManager(value: boolean) {
    this.isManagerSubject.next(value);
  }

  pushIsPublisher(value: boolean) {
    this.isPublisherSubject.next(value);
  }

  pushIsCollaborating(value: boolean) {
    this.isCollaboratingSubject.next(value);
  }

  pushIsOrganisationManager(value: boolean) {
    this.isOrganisationManagerSubject.next(value);
  }
}
