import { CUSTOM_ELEMENTS_SCHEMA, inject, NgModule } from '@angular/core';

import { AppComponent } from './app.component';

import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';

import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { RefreshTokenInterceptorService } from './services/interceptors/refresh-token-interceptor/refresh-token-interceptor.service';

import { CookieService } from 'ngx-cookie-service';
import { StudyService } from './services/api/study/study.service';
import { CustomCookieService } from './services/customcookie/custom-cookie.service';

import { AlertService } from './services/alert/alert.service';
import { InterventionSharedService } from './services/shared/intervention-shared/intervention-shared.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UserService } from './services/api/user/user.service';
import { AsyncPipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from '../environments/environment';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WithCredentialsInterceptorService } from './services/interceptors/with-credentials-interceptor/with-credentials-interceptor.service';
import { OrganisationService } from './services/api/organisation/organisation.service';
import { ActionReducer, INIT, MetaReducer, META_REDUCERS, StoreModule, UPDATE, USER_PROVIDED_META_REDUCERS } from '@ngrx/store';
import { profileActivitiesReducer, profileReducer, profileRolesReducer } from './store/profile/profile.reducer';
import { NavigationModule } from './modules/navigation/navigation.module';
import { DialogModule } from './modules/dialog/dialog.module';
import { DirectiveModule } from './modules/directive/directive.module';
import { localStorageSync } from 'ngrx-store-localstorage';
import { EffectsModule } from '@ngrx/effects';
import { MediaModule } from './modules/media/media.module';
import { ProfileEffects } from './store/profile/profile.effect';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { OrganisationEffects } from './store/organisation/organisation.effect';
import { AuthenticationEffects } from './store/authentication/authentication.effect';
import { organisationsCollaboratorsReducer, organisationsReducer } from './store/organisation/organisation.reducer';
import { SkillEffects } from './store/skill/skill.effect';
import { dynamicAnswersReducer } from './store/dynamic-answers/dynamic-answers.reducer';
import { DynamicAnswersEffects } from './store/dynamic-answers/dynamic-answers.effects';
import { lessonLocaleReducer } from './store/lesson-locale/lesson-locale.reducer';
import { LessonLocaleEffects } from './store/lesson-locale/lesson-locale.effects';
import { AnswersheetEffects } from './store/answersheet/answersheet.effects';
import { DiaryEffects } from './store/diary/diary.effects';
import { isAuthenticatedReducer } from './store/authentication/authentication.reducer';
import { InterventionEffects } from './store/intervention/intervention.effects';
import { InterventionInstanceEffects } from './store/intervention-instance/intervention-instance.effects';
import { RootState } from './store';
import { LessonQuestionnaireEffects } from './store/lesson-questionnaire/lesson-questionnaire.effects';
import { MessageEffects } from './store/message/message.effects';
import { BuddyEffects } from './store/buddy/buddy.effects';
import { QueueEffects } from './store/queue/queue.effect';
import { StudyEffects } from './store/study/study.effect';
import { collabGroupsReducer, collaboratorsOfStudiesReducer } from './store/study/study.reducer';
import { nonCollabGroupsReducer } from './store/study/study.reducer';
import { collabInterventionsReducer, inactiveCollabInterventionsReducer } from './store/intervention/intervention.reducer';
import { nonCollabInterventionsReducer } from './store/intervention/intervention.reducer';
import { UserEffects } from './store/user/user.effect';
import { AnnouncementEffects } from './store/announcement/announcement.effects';
import { announcementReducer } from './store/announcement/announcement.reducer';
import { answersheetsOfInterventionInstancesReducer } from './store/answersheet/answersheet.reducer';
import { diariesReducer } from './store/diary/diary.reducer';
import { allInstancesForECoachReducer } from './store/intervention-instance/intervention-instance.reducer';
import { allQuestionnairesReducer } from './store/lesson-questionnaire/lesson-questionnaire.reducer';
import { allMessageThreadsReducer } from './store/message/message.reducer';
import { allJobResultsReducer } from './store/queue/queue.reducer';
import {
  usersReducer,
  myMembersReducer,
  allECoachesReducer,
  allInvitationsReducer,
  myRegisteredUsersReducer
} from './store/user/user.reducer';
import { allInterventionInstanceRemindersReducer } from './store/reminder/reminder.reducer';
import { ReminderEffects } from './store/reminder/reminder.effect';
import { CodebookModule } from './modules/codebook/codebook.module';

export const createTranslateLoader = (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json');

export const interceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: WithCredentialsInterceptorService, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptorService, multi: true }
];

export const localStorageSyncReducer = (reducer: ActionReducer<any>): ActionReducer<any> =>
  localStorageSync({
    keys: [
      {
        isAuthenticated: {
          encrypt: state => window.btoa(state),
          decrypt: state => {
            try {
              return window.atob(state);
            } catch (error) {
              return 'false';
            }
          }
        }
      },
      {
        myProfile: {
          encrypt: state => window.btoa(state),
          decrypt: state => {
            try {
              return window.atob(state);
            } catch (error) {
              return 'null';
            }
          }
        }
      },
      {
        myRoles: {
          encrypt: state => window.btoa(state),
          decrypt: state => {
            try {
              return window.atob(state);
            } catch (error) {
              return '[]';
            }
          }
        }
      }
    ],
    rehydrate: true
  })(reducer);

export const authenticationSyncReducer =
  (reducer: ActionReducer<RootState>): ActionReducer<RootState> =>
  (state, action) => {
    if (action.type === INIT || action.type === UPDATE) {
      const customCookieService = inject(CustomCookieService);
      const isAuthenticatedStorage: any = localStorage.getItem('isAuthenticated');
      const profileStorage: any = localStorage.getItem('myProfile');
      const rolesStorage: any = localStorage.getItem('myRoles');
      let isAuthenticated = false;
      let myProfile = null;
      let myRoles = [];
      if (isAuthenticatedStorage) {
        try {
          const base64 = window.atob(localStorage.getItem('isAuthenticated'));
          isAuthenticated = JSON.parse(base64);
        } catch (error) {
          localStorage.removeItem('isAuthenticated');
          isAuthenticated = false;
        }
      }
      if (profileStorage) {
        try {
          const base64 = window.atob(localStorage.getItem('myProfile'));
          myProfile = JSON.parse(base64);
        } catch (error) {
          localStorage.removeItem('myProfile');
          myProfile = null;
        }
      }
      if (rolesStorage) {
        try {
          const base64 = window.atob(localStorage.getItem('myRoles'));
          myRoles = JSON.parse(base64);
        } catch (error) {
          localStorage.removeItem('myRoles');
          myRoles = [];
        }
      }
      return {
        isAuthenticated: customCookieService.isCookieValid(),
        announcements: [],
        myProfile,
        myRoles,
        myActivities: [],
        organisations: [],
        organisationsCollaborators: [],
        lessonLocale: '',
        dynamicAnswers: [],
        collabGroups: [],
        nonCollabGroups: [],
        collaboratorsOfStudies: [],
        collabInterventions: [],
        inactiveCollabInterventions: [],
        nonCollabInterventions: [],
        answersheetsOfInterventionInstances: [],
        diaries: [],
        allInstancesForECoach: [],
        allQuestionnaires: [],
        allMessageThreads: [],
        allJobResults: [],
        users: [],
        myMembers: [],
        allECoaches: [],
        allInvitations: [],
        myRegisteredUsers: [],
        allInterventionInstanceReminders: []
      };
    }
    if (action.type === 'Perform forced logout') {
      return reducer(undefined, action);
    }
    return reducer(state, action);
  };

export const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer, authenticationSyncReducer];

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    RouterModule,
    BrowserModule,
    HttpClientModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    NavigationModule,
    DialogModule,
    CodebookModule,
    DirectiveModule,
    StoreModule.forRoot(
      {
        isAuthenticated: isAuthenticatedReducer,
        announcements: announcementReducer,
        myProfile: profileReducer,
        myRoles: profileRolesReducer,
        myActivities: profileActivitiesReducer,
        organisations: organisationsReducer,
        organisationsCollaborators: organisationsCollaboratorsReducer,
        lessonLocale: lessonLocaleReducer,
        dynamicAnswers: dynamicAnswersReducer,
        collabGroups: collabGroupsReducer,
        nonCollabGroups: nonCollabGroupsReducer,
        collaboratorsOfStudies: collaboratorsOfStudiesReducer,
        collabInterventions: collabInterventionsReducer,
        inactiveCollabInterventions: inactiveCollabInterventionsReducer,
        nonCollabInterventions: nonCollabInterventionsReducer,
        answersheetsOfInterventionInstances: answersheetsOfInterventionInstancesReducer,
        diaries: diariesReducer,
        allInstancesForECoach: allInstancesForECoachReducer,
        allQuestionnaires: allQuestionnairesReducer,
        allMessageThreads: allMessageThreadsReducer,
        allJobResults: allJobResultsReducer,
        users: usersReducer,
        myMembers: myMembersReducer,
        allECoaches: allECoachesReducer,
        allInvitations: allInvitationsReducer,
        myRegisteredUsers: myRegisteredUsersReducer,
        allInterventionInstanceReminders: allInterventionInstanceRemindersReducer
      },
      { metaReducers }
    ),
    // EffectsModule.forRoot([HydrationEffects, ProfileEffects]),
    EffectsModule.forRoot([
      AnnouncementEffects,
      AnswersheetEffects,
      AuthenticationEffects,
      DiaryEffects,
      InterventionEffects,
      InterventionInstanceEffects,
      LessonQuestionnaireEffects,
      MessageEffects,
      ProfileEffects,
      OrganisationEffects,
      SkillEffects,
      LessonLocaleEffects,
      DynamicAnswersEffects,
      BuddyEffects,
      QueueEffects,
      StudyEffects,
      UserEffects,
      ReminderEffects
    ]),
    MediaModule,
    StoreDevtoolsModule.instrument({
      logOnly: environment.production
    })
  ],
  providers: [
    AlertService,
    CookieService,
    CustomCookieService,
    StudyService,
    InterventionSharedService,
    UserService,
    OrganisationService,
    AsyncPipe,
    interceptorProviders
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
