/* eslint-disable @typescript-eslint/naming-convention */
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { faBell } from '@fortawesome/free-solid-svg-icons/faBell';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faHands } from '@fortawesome/free-solid-svg-icons/faHands';
import { faHandsHelping } from '@fortawesome/free-solid-svg-icons/faHandsHelping';
import { faCommentSlash } from '@fortawesome/free-solid-svg-icons/faCommentSlash';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock';
import { faLockOpen } from '@fortawesome/free-solid-svg-icons/faLockOpen';
import { faSquare } from '@fortawesome/free-solid-svg-icons/faSquare';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons/faCheckCircle';
import { faCircle } from '@fortawesome/free-regular-svg-icons/faCircle';
import { faComment } from '@fortawesome/free-regular-svg-icons/faComment';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter, mergeMap, skip, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { HelperActivityService } from '../../../services/helper/helper-activity/helper-activity.service';
import { InterventionInstanceInterface } from '../../../models/interface/intervention-instances/intervention-instance.interface';
import { ActivityInterface } from '../../../models/interface/activity.interface';
import { InterventionInterface } from '../../../models/interface/intervention.interface';
import { HelperService } from '../../../services/helper/helper.service';
import { UserInterface } from '../../../models/interface/user.interface';
import { MessageThreadsInterface } from '../../../models/interface/message_threads.interface';
import { LessonInterface } from '../../../models/interface/lesson.interface';
import { AnswersheetInterface } from '../../../models/interface/answersheet.interface';
import { HelperFeedbackService } from '../../../services/helper/helper-feedback/helper-feedback.service';
import { InterventionInstanceConfigurationInterface } from '../../../models/interface/intervention-instances/intervention-instance.configuration.interface';
import { ProfileInterface } from '../../../models/interface/profile.interface';
import { InterventionConfigurationInterface } from '../../../models/interface/intervention-configuration.interface';
import { SkillInterface } from '../../../models/interface/skill.interface';
import { HelperSkillService } from '../../../services/helper/helper-skill/helper-skill.service';
import { HelperDialogService } from '../../../services/helper/helper-dialog/helper-dialog.service';
import { StudyActionTypes } from '../../../store/study/study.action';
import { AnswersheetsStore } from '../../../store/answersheet/component-store/answersheet.store';
import { InterventionInstanceStore } from '../../../store/intervention-instance/component-store/intervention-instance.store';
import { MessageStore } from '../../../store/message/component-store/message.store';
import { SkillStore } from '../../../store/skill/component-store/skill.store';
import { getCollaboratorsByStudyId } from '../../../store/study/study.selector';
import { UserStore } from '../../../store/user/component-store/user.store';
import { Chart } from 'chart.js/auto';
import { InterventionInstanceLogInterface } from 'src/app/models/interface/intervention-instances/intervention-instance.log.interface';
import { faClock } from '@fortawesome/free-regular-svg-icons/faClock';
import { DiaryInterface } from '../../../models/interface/diary.interface';
import { DiaryStore } from '../../../store/diary/component-store/diary.store';
import { faBook } from '@fortawesome/free-solid-svg-icons/faBook';

/**
 * Component:
 * Instance details component displaying details including progress, submitted answers and activities;
 */

@Component({
  selector: 'app-instance-detail',
  templateUrl: './instance-detail.component.html',
  styleUrls: ['./instance-detail.component.scss'],
  providers: [AnswersheetsStore, DiaryStore, InterventionInstanceStore, MessageStore, SkillStore, UserStore]
})
export class InstanceDetailComponent implements OnInit, AfterViewInit, OnDestroy {
  public canvas: ElementRef;

  // Icon
  faArrowLeft = faArrowLeft;
  faArrowRight = faArrowRight;
  faLock = faLock;
  faLockOpen = faLockOpen;
  faCheck = faCheck;
  faComment = faComment;
  faCommentSlash = faCommentSlash;
  faCheckCircle = faCheckCircle;
  faCircle = faCircle;
  faHands = faHands;
  faHandsHelping = faHandsHelping;
  faEnvelope = faEnvelope;
  faTimes = faTimes;
  faBell = faBell;
  faEye = faEye;
  faSquare = faSquare;
  faClock = faClock;
  faBook = faBook;

  public intervention: InterventionInterface;
  public studyMembers: Array<UserInterface> = [];
  public instance: InterventionInstanceInterface;
  public instance$: Observable<InterventionInstanceInterface>;
  // public logsOfInterventionInstance$: Observable<Array<InterventionInstanceLogInterface>>;
  public questionnaires: Array<LessonInterface> = [];
  public answersheets: Array<AnswersheetInterface> = [];
  public answersheetsOfInterventionInstance$: Observable<Array<AnswersheetInterface>>;
  public activities: Array<ActivityInterface> = [];
  public threads: Array<MessageThreadsInterface> = [];
  public skills: Array<SkillInterface> = [];
  public hasSkills = false;

  public isLoading$: Observable<boolean>;

  // Lesson/Instance progress
  public progress = 0;
  public showChild$: Observable<string>;

  public profile: ProfileInterface;
  public profile$: Observable<ProfileInterface>;

  public activitiesSubject: BehaviorSubject<Array<ActivityInterface>> = new BehaviorSubject<Array<ActivityInterface>>([]);

  /*
  public instanceLogsSubject: BehaviorSubject<Array<InterventionInstanceLogInterface>> = new BehaviorSubject<
    Array<InterventionInstanceLogInterface>
  >([]);
  */
  public pagedActivitySubject: BehaviorSubject<Array<ActivityInterface>> = new BehaviorSubject<Array<ActivityInterface>>([]);
  public pagedActivity$: Observable<Array<ActivityInterface>> = this.pagedActivitySubject.asObservable();

  public activitiesOfInstance$: Observable<Array<ActivityInterface>>;
  public unlockQuestionnaireAsECoachResponse$: Observable<any>;

  public sendReminderResponseArray: Array<{ questionnaire_id: number; sendReminderResponse: BehaviorSubject<string> }> = [];
  public unlockManuallyResponseArray: Array<{ questionnaire_id: number; unlockManuallyResponse: BehaviorSubject<string> }> = [];

  public selectedIndexSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  public lessonSkills: Array<{ skillId: number; lessonIds: Array<number> }> = [];

  public studyCollaborator: Array<UserInterface> = [];

  // Translation
  public param;

  // New Chart
  public chart: any;
  public dataset: { labels: Array<string>; datasets: Array<{ label; data; backgroundColor }> } = { labels: [], datasets: [] };

  public diaries: Array<DiaryInterface>;
  public diariesOfStudy$: Observable<Array<DiaryInterface>>;

  private allThreadsOfInstance$: Observable<MessageThreadsInterface>;

  private skillsOfIntervention$: Observable<SkillInterface[]>;

  private collaboratorsOfStudy$: Observable<{ studyId: number; collaborators: UserInterface[] }>;

  private sendReminderEmailResponse$: Observable<any>;

  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private showChildSubject: BehaviorSubject<string> = new BehaviorSubject<string>('details');

  private subscriptions: Subscription[] = [];

  constructor(
    private helperService: HelperService,
    private helperFeedbackService: HelperFeedbackService,
    private helperActivityService: HelperActivityService,
    private helperSkillService: HelperSkillService,
    private helperDialogService: HelperDialogService,
    private answersheetsStore: AnswersheetsStore,
    private interventionInstanceStore: InterventionInstanceStore,
    private messageStore: MessageStore,
    private skillStore: SkillStore,
    private userStore: UserStore,
    private diaryStore: DiaryStore,
    private store: Store<{
      myProfile: ProfileInterface;
      skills: Array<SkillInterface>;
      getCollaboratorsByStudyId: { studyId: number; collaborators: UserInterface[] };
    }>
  ) {
    this.profile$ = store.select('myProfile');
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.showChild$ = this.showChildSubject.asObservable();
    this.answersheetsOfInterventionInstance$ = this.answersheetsStore.answersheetsOfInterventionInstance$;
    this.instance$ = this.interventionInstanceStore.instance$;
    this.activitiesOfInstance$ = this.interventionInstanceStore.activitiesOfInstance$;
    this.unlockQuestionnaireAsECoachResponse$ = this.interventionInstanceStore.unlockQuestionnaireAsECoachResponse$;
    this.allThreadsOfInstance$ = this.messageStore.allThreadsOfInstance$;
    this.skillsOfIntervention$ = this.skillStore.skillsOfIntervention$;
    this.sendReminderEmailResponse$ = this.userStore.sendReminderEmailResponse$;
    // this.logsOfInterventionInstance$ = this.interventionInstanceStore.logsOfInterventionInstance$;
    this.diariesOfStudy$ = this.diaryStore.diariesOfStudy$;
  }

  // Helper function
  public get helper() {
    return this.helperService;
  }

  public get helperFeedback() {
    return this.helperFeedbackService;
  }

  public get helperActivity() {
    return this.helperActivityService;
  }

  public get helperSkill() {
    return this.helperSkillService;
  }

  public get helperDialog() {
    return this.helperDialogService;
  }

  @ViewChild('canvas', { static: false }) set content(content: ElementRef) {
    if (content) {
      this.canvas = content;
      this.chart = new Chart(this.canvas.nativeElement.getContext('2d'), {
        type: 'polarArea',
        data: this.dataset,
        options: {
          responsive: true
        }
      });
    }
  }

  // Set study and its configuration from parent
  @Input()
  set _intervention(_intervention: InterventionInterface) {
    if (_intervention) {
      this.intervention = _intervention;
      /*
      if (this.intervention.attributes.gamification) {
        if (this.intervention.attributes.gamification.skills_order) {
          if (this.intervention.attributes.gamification.skills_order.length > 0) {
            this.hasSkills = true;
            this.lessonSkills = [];
            if (this.intervention.attributes.gamification.skills_order) {
              this.intervention.attributes.gamification.skills_order.forEach((skillId: number) => {
                this.lessonSkills.push({ skillId, lessonIds: [] });
              });
            }
          }
        }
      }
      */
    }
  }

  // Set members of study from parent
  @Input()
  set _members(_members: Array<UserInterface>) {
    this.studyMembers = _members;
  }

  // Set collaborators of study from parent
  @Input()
  set _collaborators(_collaborators: Array<UserInterface>) {
    if (_collaborators) {
      this.studyCollaborator = _collaborators;
    }
  }

  @Input()
  set _instance(_instance: InterventionInstanceInterface) {
    this.instance = _instance;
  }

  @Input()
  set _questionnaires(_questionnaires: Array<LessonInterface>) {
    if (_questionnaires) {
      this.questionnaires = _questionnaires;
    }
  }

  @Input()
  set _initialChild(_initialChild: string) {
    this.showChildSubject = new BehaviorSubject<string>(_initialChild);
  }

  @Input()
  set _selectedIndex(_selectedIndex: number) {
    if (_selectedIndex) {
      this.selectedIndexSubject = new BehaviorSubject<number>(_selectedIndex);
    }
  }

  ngOnInit(): void {
    if (this.intervention.attributes.gamification) {
      if (this.intervention.attributes.gamification.skills_order) {
        if (this.intervention.attributes.gamification.skills_order.length > 0) {
          this.hasSkills = true;
          this.lessonSkills = [];
          if (this.intervention.attributes.gamification.skills_order) {
            this.intervention.attributes.gamification.skills_order.forEach((skillId: number) => {
              this.lessonSkills.push({ skillId, lessonIds: [] });
            });
          }
        }
      }
    }

    this.interventionInstanceStore.getInstance(this.instance.id);
    this.subscriptions.push(
      this.instance$
        .pipe(
          filter(value => !!value),
          take(1)
        )
        .subscribe((result: InterventionInstanceInterface) => {
          this.instance = result;
          this.sendReminderResponseArray = [];
          this.instance.attributes.configuration.custom_order.forEach((questionnaireId: number) => {
            this.sendReminderResponseArray.push({
              questionnaire_id: questionnaireId,
              sendReminderResponse: new BehaviorSubject<string>('DEFAULT')
            });
            this.unlockManuallyResponseArray.push({
              questionnaire_id: questionnaireId,
              unlockManuallyResponse: new BehaviorSubject<string>('DEFAULT')
            });
          });
        })
    );

    /*
    this.interventionInstanceStore.getInterventionInstanceLogs({ instanceId: this.instance.id });
    this.subscriptions.push(
      this.logsOfInterventionInstance$.pipe(skip(1), take(1)).subscribe((result: Array<InterventionInstanceLogInterface>) => {
        this.instanceLogsSubject.next(result);
      })
    );

    this.diaryStore.getDiariesOfStudy({ studyId: this.intervention.attributes.study_id });
    this.subscriptions.push(
      this.diariesOfStudy$.pipe(skip(1), take(1)).subscribe((result: any) => {
        this.diaries = result;
      })
    );

    this.skillStore.getSkillsOfIntervention({ id: this.intervention.id });
    this.subscriptions.push(
      this.skillsOfIntervention$.subscribe(result => {
        this.skills = result;
        this.questionnaires.forEach((lesson: LessonInterface) => {
          if (lesson.attributes.skills) {
            lesson.attributes.skills.forEach((skillId: number) => {
              const index = this.lessonSkills.findIndex(
                (value: { skillId: number; lessonIds: Array<number> }) => value.skillId.toString() === skillId.toString()
              );
              if (index > -1) {
                this.lessonSkills[index].lessonIds.push(parseInt(lesson.id.toString(), 10));
              }
            });
          }
        });
        const skillDataSet = { label: 'In %:', data: [], backgroundColor: [] };
        this.lessonSkills.forEach((value: { skillId: number; lessonIds: Array<number> }) => {
          const found = this.skills.find((skill: SkillInterface) => skill.id.toString() === value.skillId.toString());
          this.dataset.labels.push(
            this.skills.find((skill: SkillInterface) => skill.id.toString() === value.skillId.toString()).attributes.title
          );
          skillDataSet.data.push(
            Math.round(
              (this.helper.getCommonIds(this.instance.attributes.progress.finished_questionnaires, value.lessonIds).length /
                value.lessonIds.length) *
                100
            )
          );
          skillDataSet.backgroundColor.push(
            this.skills.find((skill: SkillInterface) => skill.id.toString() === value.skillId.toString()).attributes.color
          );
        });
        this.dataset.datasets.push(skillDataSet);
      })
    );
    */

    this.subscriptions.push(
      this.profile$
        .pipe(
          filter(user => user !== null),
          mergeMap((value: ProfileInterface) => {
            this.profile = value;
            this.store.dispatch({
              type: StudyActionTypes.getCollaboratorsType,
              payload: { studyId: this.intervention.attributes.study_id, include: 'roles' }
            });

            this.collaboratorsOfStudy$ = this.store.select(getCollaboratorsByStudyId(this.intervention.attributes.study_id));
            return this.collaboratorsOfStudy$.pipe(
              filter(collaboratorsOfStudy => !!collaboratorsOfStudy),
              take(1)
            );
          }),
          mergeMap(result => {
            const collaborators = result.collaborators;

            // Determine my highest role
            const myself: UserInterface = collaborators.find(user => user.id.toString() === this.profile.id.toString());
            const myRoleSlug = myself ? this.helper.getHighestRoleOfCollaborator(myself) : '';
            const reqs: Array<Observable<any>> = [];

            if (myRoleSlug.match(/study\.(ecoachmanager|owner)$/)) {
              this.answersheetsStore.getAnswersheetsOfInterventionInstanceEM(this.instance.id);
            } else {
              this.answersheetsStore.getAnswersheetsOfInterventionInstance(this.instance.id);
            }

            this.answersheetsOfInterventionInstance$.subscribe((answersheets: AnswersheetInterface[]) => {
              this.answersheets = this.helperService.getAnswersheetsByNewest(answersheets);
            });

            if (myRoleSlug.match(/study\.(ecoachmanager|owner)$/)) {
              this.interventionInstanceStore.getActivitiesOfInterventionInstanceEM({
                instanceId: this.instance.id,
                include: 'questionnaires'
              });
              return this.activitiesOfInstance$.pipe(
                skip(1),
                take(1),
                switchMap((res: any) => {
                  this.activities = this.helper.getActivityByNewest(res);
                  this.activitiesSubject.next(this.activities);
                  this.pagedActivitySubject.next(this.activitiesSubject.value.slice(0, 20));
                  this.messageStore.getAllThreadsOfInstanceEM({
                    instanceId: this.instance.id
                  });
                  return this.allThreadsOfInstance$.pipe(skip(1), take(1));
                })
              );
            } else {
              this.interventionInstanceStore.getActivitiesOfInterventionInstance({
                instanceId: this.instance.id,
                include: 'questionnaires'
              });
              return this.activitiesOfInstance$.pipe(
                skip(1),
                take(1),
                switchMap((res: any) => {
                  this.activities = this.helper.getActivityByNewest(res);
                  this.activitiesSubject.next(this.activities);
                  this.pagedActivitySubject.next(this.activitiesSubject.value.slice(0, 20));
                  this.messageStore.getAllThreadsOfInstance({
                    instanceId: this.instance.id
                  });
                  return this.allThreadsOfInstance$.pipe(skip(1), take(1));
                })
              );
            }
          })
        )
        .subscribe(
          (result: any) => {
            this.threads = result;
            this.isLoadingSubject.next(false);
          },
          () => {
            this.answersheets = [];
            this.activities = [];
            this.pagedActivitySubject.next(this.activities);
            this.threads = [];
            this.isLoadingSubject.next(false);
          }
        )
    );
    const lessonOne =
      this.instance.attributes.progress.finished_questionnaires == null
        ? 0
        : this.instance.attributes.progress.finished_questionnaires.length;
    const lessonTwo = this.instance.attributes.configuration.custom_order.length;
    this.param = { lesson_one: lessonOne, lesson_two: lessonTwo };

    this.unlockAtDateQuestionnairesOfInstance(this.instance);
  }

  ngAfterViewInit(): void {}

  public getConfigurationByQuestionnaireId(questionnaireId: number): InterventionInstanceConfigurationInterface {
    const configs: Array<InterventionInstanceConfigurationInterface> = this.instance.attributes.configuration.questionnaire_configuration;
    return configs.find((config: InterventionInstanceConfigurationInterface) => config.id.toString() === questionnaireId.toString());
  }

  public getQuestionnaireById(questionnaireId: number): LessonInterface {
    const found = this.questionnaires.find((config: LessonInterface) => config.id.toString() === questionnaireId.toString());
    return found ? found : null;
  }

  public calculateProgress(): string {
    const customOrder = this.instance.attributes.configuration.custom_order.length;
    const finished =
      this.instance.attributes.progress.finished_questionnaires == null
        ? 0
        : this.instance.attributes.progress.finished_questionnaires.length;

    if (customOrder === 0 || finished === 0) {
      this.progress = 0;
      return '0%';
    }
    this.progress = (finished / customOrder) * 100;
    return ((finished / customOrder) * 100).toString() + '%';
  }

  public getLessonStatus(questionnaireId: number): string {
    const config = this.getConfigurationByQuestionnaireId(questionnaireId);
    const finished =
      this.instance.attributes.progress.finished_questionnaires == null ? [] : this.instance.attributes.progress.finished_questionnaires;
    const unlocked =
      this.instance.attributes.progress.unlocked_questionnaires == null ? [] : this.instance.attributes.progress.unlocked_questionnaires;
    const foundFinished = finished.find(finish => questionnaireId.toString() === finish.toString());
    const foundUnlocked = unlocked.find(unlock => questionnaireId.toString() === unlock.toString());

    if (foundFinished !== undefined) {
      return 'instance-detail.finished';
    } else if (foundUnlocked !== undefined) {
      return 'instance-detail.unlocked';
    } else {
      if (config.unlock_type === 'at_date') {
        const now = Math.floor(Date.now() / 1000);
        const timeToUnlock = config.unlock_date;
        return now - timeToUnlock < 0 ? 'instance-detail.locked' : 'instance-detail.unlocked';
      } else {
        return 'instance-detail.locked';
      }
    }
  }

  public isCurrentQuestionnaireId(instance: InterventionInstanceInterface, questionnaireId: number): boolean {
    const currentQuestionnaire = instance.attributes.progress.current_questionnaire_id;
    return currentQuestionnaire !== null ? currentQuestionnaire.toString() === questionnaireId.toString() : false;
  }

  public isActiveQuestionnaireId(instance: InterventionInstanceInterface, questionnaire_id: number): boolean {
    return !!instance.attributes.progress.active_questionnaires.find(id => id.toString() === questionnaire_id.toString());
  }

  public unlockManuallyQuestionnaire(instance: InterventionInstanceInterface, questionnaireId: number) {
    const unlock_tasks: Array<ActivityInterface> = this.activities.filter(
      (activity: ActivityInterface) =>
        activity.attributes.action === 'INTERVENTION_INSTANCE_CAN_UNLOCK' &&
        (activity.attributes.finished.toString() === '0' ||
          activity.attributes.finished.toString() === '' ||
          activity.attributes.finished.toString() === 'pending')
    );
    let activityId;
    if (unlock_tasks.length > 0) {
      activityId = unlock_tasks[0].id;
    }
    const foundIndex = this.unlockManuallyResponseArray.findIndex(
      (value: { questionnaire_id: number; unlockManuallyResponse: BehaviorSubject<string> }) =>
        value.questionnaire_id.toString() === questionnaireId.toString()
    );
    if (foundIndex > -1) {
      this.unlockManuallyResponseArray[foundIndex].unlockManuallyResponse.next('LOADING');
      this.interventionInstanceStore.unlockQuestionnaireAsECoach({ instance, unlockedQuestionnaires: [questionnaireId], activityId });
      this.subscriptions.push(
        this.unlockQuestionnaireAsECoachResponse$
          .pipe(
            filter(value => !!value),
            take(1),
            mergeMap((result: any) => {
              if (result instanceof HttpErrorResponse) {
                const error: HttpErrorResponse = result['response']['content'];
                if (error.error.errors[0].code === '89' && error.error.errors[0].status === '403') {
                  this.unlockManuallyResponseArray[foundIndex].unlockManuallyResponse.next('FAILURE1');
                } else {
                  this.unlockManuallyResponseArray[foundIndex].unlockManuallyResponse.next('FAILURE2');
                }
              }
              this.interventionInstanceStore.getInstance(this.instance.id);
              return this.instance$.pipe(skip(1), take(1));
            })
          )
          .subscribe(
            (result: InterventionInstanceInterface) => {
              this.unlockManuallyResponseArray[foundIndex].unlockManuallyResponse.next('SUCCESS');
              this.instance = result;
              setTimeout(() => {
                this.unlockManuallyResponseArray[foundIndex].unlockManuallyResponse.next('DEFAULT');
              }, 10000);
            },
            () => {
              this.unlockManuallyResponseArray[foundIndex].unlockManuallyResponse.next('FAILURE2');
              setTimeout(() => {
                this.unlockManuallyResponseArray[foundIndex].unlockManuallyResponse.next('DEFAULT');
              }, 10000);
            }
          )
      );
    }
  }

  // Display and navigate to child component relative to this path
  public showChild(child: string): void {
    this.showChildSubject.next(child);
  }

  public sendReminder(userId: number, questionnaireId: number): void {
    const foundIndex = this.sendReminderResponseArray.findIndex(
      (value: { questionnaire_id: number; sendReminderResponse: BehaviorSubject<string> }) =>
        value.questionnaire_id.toString() === questionnaireId.toString()
    );
    if (foundIndex > -1) {
      this.sendReminderResponseArray[foundIndex].sendReminderResponse.next('LOADING');
      this.userStore.sendReminderEmail({ userId, questionnaireId, interventionInstanceId: this.instance.id });
      this.subscriptions.push(
        this.sendReminderEmailResponse$.pipe(skip(1), take(1)).subscribe(
          result => {
            if (result instanceof HttpResponse) {
              this.sendReminderResponseArray[foundIndex].sendReminderResponse.next('SUCCESS');
            } else {
              this.sendReminderResponseArray[foundIndex].sendReminderResponse.next('FAILURE');
            }
            setTimeout(() => {
              this.sendReminderResponseArray[foundIndex].sendReminderResponse.next('DEFAULT');
            }, 10000);
          },
          () => {
            this.sendReminderResponseArray[foundIndex].sendReminderResponse.next('FAILURE');
            setTimeout(() => {
              this.sendReminderResponseArray[foundIndex].sendReminderResponse.next('DEFAULT');
            }, 10000);
          }
        )
      );
    }
  }

  public updatePagedActivities(event: any) {
    if (event) {
      this.pagedActivitySubject.next(event);
    }
  }

  unlockAtDateQuestionnairesOfInstance(instance: InterventionInstanceInterface) {
    const finished =
      this.instance.attributes.progress.finished_questionnaires == null ? [] : this.instance.attributes.progress.finished_questionnaires;
    const unlocked =
      this.instance.attributes.progress.unlocked_questionnaires == null ? [] : this.instance.attributes.progress.unlocked_questionnaires;

    const finishedAndUnlocked = finished.concat(unlocked);
    const instanceConfiguration: Array<InterventionInstanceConfigurationInterface> =
      instance.attributes.configuration.questionnaire_configuration;

    const questionnairesToUnlock = [];
    instanceConfiguration.forEach((config: InterventionInstanceConfigurationInterface) => {
      if (!finishedAndUnlocked.includes(config.id) && config.unlock_type === 'at_date') {
        const now = Math.floor(Date.now() / 1000);
        const timeToUnlock = config.unlock_date;
        if (now - timeToUnlock > 0) {
          questionnairesToUnlock.push(config.id);
        }
      }
    });
    if (questionnairesToUnlock.length > 0) {
      this.interventionInstanceStore.unlockQuestionnaireAsECoach({ instance, unlockedQuestionnaires: questionnairesToUnlock });
      this.unlockQuestionnaireAsECoachResponse$.pipe(
        filter(value => !!value),
        take(1)
      );
    }
  }

  public isCanceled(instance: InterventionInstanceInterface): boolean {
    return instance.attributes.progress.current_state === 'canceled';
  }

  public isPaused(instance: InterventionInstanceInterface): boolean {
    return instance.attributes.progress.current_state === 'paused';
  }

  public openDialogInterventionLesson(questionnaireId: number, studyId: number): void {
    this.helperDialog
      .openDialogInterventionLesson(questionnaireId, studyId)
      .afterClosed()
      .subscribe(result => {});
  }

  public getCondition(questionnaireId: number): string {
    const foundConfig: InterventionConfigurationInterface = this.getDefaultConfigurationOfQuestionnaire(questionnaireId);
    if (foundConfig !== null && foundConfig.condition) {
      if (Array.isArray(foundConfig.condition)) {
        if (foundConfig.condition.length > 0) {
          const condition = (foundConfig.condition as Array<any>).reduce((prev, curr) => prev + ' & ' + curr.description, '');
          return condition;
        }
        return null;
      }
      return foundConfig.condition.description ? foundConfig.condition.description : JSON.stringify(foundConfig.condition);
    } else {
      return null;
    }
  }

  // Returns default configuration of a questionnaire
  public getDefaultConfigurationOfQuestionnaire(questionnaireId: number): InterventionConfigurationInterface {
    const found = this.intervention.attributes.default_configuration.questionnaire_configuration.find(
      (questionnaire: InterventionConfigurationInterface) => questionnaire.id.toString() === questionnaireId.toString()
    );
    return found ? found : null;
  }

  /*
  public getQuestionnaireUnlockedSince(questionnaireId: number) {
    return this.instanceLogsSubject.value.find(
      (log: InterventionInstanceLogInterface) => log.attributes.questionnaire_id.toString() === questionnaireId.toString()
    )?.attributes.created_at;
  }
  */

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
