import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { RequestBodyData } from '../../../models/request-body.data';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { download } from '../../../helpers/download/download';
import { HelperService } from '../../helper/helper.service';

/**
 * Service:
 * Answersheet API service that handles answer sheets from patients
 */

@Injectable({
  providedIn: 'root'
})
export class AnswersheetService {
  private backendUrl: string = environment.backendURL;
  private header: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
  private payload: RequestBodyData;

  constructor(private http: HttpClient, private helperService: HelperService) {
    this.header = this.helperService.setLocaleFromStorage(this.header);
  }

  /**
   * AAS2 API User - Get Answersheet
   * This function retrieves an answer sheet by its identifier.
   *
   * @params number answersheetId - ID of the answersheet.
   * @return Observable<any> - An observable for any response.
   */
  public getAnswersheetUser(answersheetId: number): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/api/v1/answersheets/${answersheetId}`, { headers: this.header, observe: 'response' });
  }

  /**
   * AAS2 API ECoach - Get an Answersheet for an eCoach
   * This function retrieves an answer sheet by its identifier.
   *
   * @params number answersheetId - ID of the answersheet.
   * @return Observable<any> - An observable for any response.
   */
  public getAnswersheet(answersheetId: number): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoach/answersheets/${answersheetId}`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API ECoach Manager - Get an Answersheet as eCoach manager
   * This function retrieves an answer sheet by its identifier.
   *
   * @params number answersheetId - ID of the answersheet.
   * @return Observable<any> - An observable for any response.
   */
  public getAnswersheetEM(answersheetId: number): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoachmanager/answersheets/${answersheetId}`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API User - Evaluate Answersheet
   * This function get the evaluation of an answer sheet if submitted.
   *
   * @params number answersheetId - ID of the answersheet.
   * @return Observable<any> - An observable for any response.
   */
  public evaluateAnswersheet(answersheetId: number): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/api/v1/answersheets/${answersheetId}/evaluate`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API User - Get Answersheets for Study Member
   * This function get all the answer sheets that belongs to the study member.
   *
   * @params number studyId - ID of the study.
   *         number userId - ID of the user; preferably a study member of the user.
   * @return Observable<any> - An observable for any response.
   */
  public getAnswersheetsForStudyMember(studyId: number, userId: number): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/api/v1/studies/${studyId}/members/${userId}/answersheets?limit=0`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API User - Get Answersheets Details for Study Member
   * This function get all the answer sheet details that belongs to the study member.
   *
   * @params number studyId - ID of the study.
   *         number userId - ID of the user; preferably a study member of the user.
   *         number answersheetId - ID of the answersheet.
   * @return Observable<any> - An observable for any response.
   */
  public getAnswersheetsDetailsForStudyMember(studyId: number, userId: number, answersheetId: number): Observable<any> {
    return this.http.get<any>(
      `${this.backendUrl}/api/v1/studies/${studyId}/members/${userId}/answersheets/
    ${answersheetId}`,
      { headers: this.header, observe: 'response' }
    );
  }

  /**
   * AAS2 API User - Evaluate Answersheet of Member
   * This function gets the evaluation for an answer sheet of a member of the study.
   *
   * @params number studyId - ID of the study.
   *         number userId - ID of the user; preferably a study member of the user.
   *         number answersheetId - ID of the answersheet.
   * @return Observable<any> - An observable for any response.
   */
  public evaluateAnswersheetOfMember(studyId: number, userId: number, answersheetId: number): Observable<any> {
    return this.http.get<any>(
      `${this.backendUrl}/api/v1/studies/${studyId}/members/${userId}/answersheets/
    ${answersheetId}/evaluate`,
      { headers: this.header, observe: 'response' }
    );
  }

  /**
   * AAS2 API User - Remove Flags from Answersheet
   * This function removes flags for a given answer sheet.
   *
   * @params number studyId - ID of the study.
   *         number userId - ID of the user; preferably a study member of the user.
   *         number answersheetId  - ID of the answersheet.
   * @return Observable<any> - An observable for any response.
   */
  public removeFlagsOfAnswersheet(studyId: number, userId: number, answersheetId: number) {
    return this.http.request<any>(
      'delete',
      `${this.backendUrl}/api/v1/studies/${studyId}/members/
    ${userId}/answersheets/${answersheetId}/flags`,
      { body: this.payload, headers: this.header, observe: 'response' }
    );
  }

  /**
   * AAS2 API ECoach - Get Answersheets of an Intervention instance
   * This function returns a list of answersheets of an intervention instance.
   *
   * @params number interventionInstanceId - ID of the intervention instance.
   * @return Observable<any> - An observable for any response.
   */
  public getAnswersheetsOfAnInterventionInstance(interventionInstanceId: number) {
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoach/interventions/instances/${interventionInstanceId}/answersheets?limit=0`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API ECoach Manager - Get Answersheets of an Intervention instance
   * This function returns a list of answersheets of an intervention instance.
   *
   * @params number interventionInstanceId - ID of the intervention instance.
   * @return Observable<any> - An observable for any response.
   */
  public getAnswersheetsOfAnInterventionInstanceEM(interventionInstanceId: number) {
    return this.http.get<any>(
      `${this.backendUrl}/api/v1/ecoachmanager/interventions/instances/${interventionInstanceId}/answersheets?limit=0`,
      {
        headers: this.header,
        observe: 'response'
      }
    );
  }

  /**
   * AAS2 API ECoach - Get all answersheets of the ecoach's intervention instances
   * This function returns a list of all answersheets of the eCoachs' intervention instances.
   *
   * @params number instance_id - ID of the intervention instance.
   * @return Observable<any> - An observable for any response.
   */
  public getAllAnswersheetsOfAllEcoachInterventionInstances() {
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoach/interventions/instances/answersheets?limit=0`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API ECoach - Get answersheets of a mmember
   * This function returns a list of all answersheets of an eCoach's member.
   *
   * @params number user_id - ID of the user.
   * @return Observable<any> - An observable for any response.
   */
  public getAllAnswersheetsOfAnEcoachMember(userId: number) {
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoach/members/${userId}/answersheets?limit=0`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API User - Get Answersheet Report
   * This function returns a report of an answersheet in PDF.
   *
   * @params number answersheet_id - ID of the answersheet.
   * @return Observable<any> - An observable for any response.
   */
  public getAnswersheetPDFReportQueue(answersheetId: number): Observable<any> {
    this.header = this.header.set('Content-Type', '*/*');
    this.header = this.header.set('Accept', '*/*');
    let params = new HttpParams();
    const timezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (timezone !== undefined) {
      params = params.set('timezone', timezone);
    }
    this.header = this.helperService.setLocaleFromStorage(this.header);
    return this.http.get(`${this.backendUrl}/api/v1/queue/answersheets/${answersheetId}/pdf/report`, {
      headers: this.header,
      observe: 'response',
      params
    });
  }

  /**
   * AAS2 API User - Get Answersheet Report
   * This function returns a report of an answersheet in PDF.
   *
   * @params number answersheet_id - ID of the answersheet.
   * @return Observable<any> - An observable for any response.
   */
  public getAnswersheetPDFReport(answersheetId: number): Observable<any> {
    this.header = this.header.set('Content-Type', '*/*');
    this.header = this.header.set('Accept', '*/*');

    let downloaded = false;
    return this.http
      .get(`${this.backendUrl}/api/v1/answersheets/${answersheetId}/pdf/report`, {
        headers: this.header,
        reportProgress: true,
        observe: 'events',
        responseType: 'blob' as 'json'
      })
      .pipe(
        download(jsonResponse => {
          const filename = `answersheet_report_id-${answersheetId}-${new Date().toISOString()}.pdf`;
          const binaryData = [];
          binaryData.push(jsonResponse);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/pdf' }));
          downloadLink.setAttribute('download', filename);
          document.body.appendChild(downloadLink);
          if (!downloaded) {
            downloadLink.click();
            URL.revokeObjectURL(downloadLink.href);
            downloaded = true;
          }
        })
      );
  }
}
