/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { faBook } from '@fortawesome/free-solid-svg-icons/faBook';
import { faBookMedical } from '@fortawesome/free-solid-svg-icons/faBookMedical';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { mergeMap, skip, switchMap, take } from 'rxjs/operators';
import { DiaryInstanceInterface } from '../../../models/interface/diary-instance.interface';
import { HelperService } from '../../../services/helper/helper.service';
import { DiaryInterface } from '../../../models/interface/diary.interface';
import { PatientSharedService } from '../../../services/shared/patient-shared/patient-shared.service';
import { LessonInterface } from '../../../models/interface/lesson.interface';
import { DiaryActionTypes } from '../../../store/diary/diary.action';
import { Store } from '@ngrx/store';
import { DiaryStore } from '../../../store/diary/component-store/diary.store';

/**
 * Component:
 * Patient diary page displaying all assigned diaries to the patient;
 * Can be found: {uri}/patients/{{user_id}}/diaries
 */

@Component({
  selector: 'app-patient-diary',
  templateUrl: './patient-diary.component.html',
  styleUrls: ['./patient-diary.component.scss'],
  providers: [DiaryStore]
})
export class PatientDiaryComponent implements OnInit {
  @ViewChild('paginator') paginator;

  // Icons
  faBook = faBook;
  faInfoCircle = faInfoCircle;
  faBookMedical = faBookMedical;

  // Study ID and loading status for this child component
  public patientId: number;
  public isLoading$: Observable<boolean>;

  public questionnaires: Array<LessonInterface> = [];

  public diaryInstances: Array<DiaryInstanceInterface> = [];
  public diaryInstances$: Observable<Array<DiaryInstanceInterface>>;
  public diaries: Array<DiaryInterface> = [];
  public diaries$: Observable<Array<DiaryInterface>>;

  public pagedInstanceDiaryPair$: Observable<Array<{ diary_instance: DiaryInstanceInterface; diary: DiaryInterface }>>;
  public instanceDiaryPairs$: Observable<Array<{ diary_instance: DiaryInstanceInterface; diary: DiaryInterface }>>;

  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private instanceDiaryPair: Array<{ diary_instance: DiaryInstanceInterface; diary: DiaryInterface }> = [];

  // Diary instance & diary
  private instanceDiaryPairsSubject: BehaviorSubject<Array<{ diary_instance: DiaryInstanceInterface; diary: DiaryInterface }>> =
    new BehaviorSubject<Array<{ diary_instance: DiaryInstanceInterface; diary: DiaryInterface }>>([]);

  private pagedInstanceDiaryPairSubject: BehaviorSubject<Array<{ diary_instance: DiaryInstanceInterface; diary: DiaryInterface }>> =
    new BehaviorSubject<Array<{ diary_instance: DiaryInstanceInterface; diary: DiaryInterface }>>([]);

  private subscriptions: Subscription[] = [];

  constructor(
    private sharedService: PatientSharedService,
    private store: Store<{ diaries: Array<DiaryInterface> }>,
    private diaryStore: DiaryStore,
    private helperService: HelperService
  ) {
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.instanceDiaryPairs$ = this.instanceDiaryPairsSubject.asObservable();
    this.pagedInstanceDiaryPair$ = this.pagedInstanceDiaryPairSubject.asObservable();
    this.diaries$ = this.store.select('diaries');
    this.diaryInstances$ = this.diaryStore.allDiaryInstances$;
  }

  public get helper() {
    return this.helperService;
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.sharedService.sourceId$
        .pipe(
          switchMap((value: number) => {
            this.patientId = value;
            this.diaryStore.getAllInstancesOfDiaryECoach({ diaryId: undefined, ecoachId: undefined, patientId: value });
            return this.diaryInstances$.pipe(skip(1), take(1));
          }),
          mergeMap((result: any) => {
            this.diaryInstances = result;
            this.store.dispatch({
              type: DiaryActionTypes.getAllDiariesType,
              payload: {}
            });
            return this.diaries$.pipe(skip(1), take(1));
          })
        )
        .subscribe((result: any) => {
          this.diaries = result;
          this.diaryInstances.forEach((diaryInstance: DiaryInstanceInterface) => {
            const found = this.diaries.find(
              (value: DiaryInterface) => value.id.toString() === diaryInstance.attributes.diary_id.toString()
            );
            this.instanceDiaryPair.push({ diary_instance: diaryInstance, diary: found });
          });
          this.instanceDiaryPairsSubject.next(this.instanceDiaryPair);
          const currentPageSize = this.paginator?.paginator ? this.paginator?.paginator.pageSize : 20;
          this.pagedInstanceDiaryPairSubject.next(this.instanceDiaryPairsSubject.value.slice(0, currentPageSize));
          this.isLoadingSubject.next(false);
        })
    );

    this.sharedService.pushActiveChild('diaries');
  }

  public updatePagedDiaries(event: any) {
    if (event) {
      this.pagedInstanceDiaryPairSubject.next(event);
    }
  }
}
