import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { HelperService } from '../../../services/helper/helper.service';

/**
 * Component:
 * Group overview page displaying a list of groups - parent component of GroupComponent;
 * Can be found: {uri}/groups
 */

@Component({
  selector: 'app-group-overview',
  templateUrl: './group-overview.component.html',
  styleUrls: ['./group-overview.component.scss']
})
export class GroupOverviewComponent implements OnInit {
  // Icons
  faInfoCircle = faInfoCircle;

  public showDescription$: Observable<boolean>;
  public showDescriptionSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private helperService: HelperService) {
    this.showDescription$ = this.showDescriptionSubject.asObservable();
  }

  public get helper() {
    return this.helperService;
  }

  ngOnInit(): void {}
}
