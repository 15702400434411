import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { catchError, iif, Observable, of, skip, switchMap, take } from 'rxjs';
import { OrganisationInterface } from '../../models/interface/organisation/organisation.interface';
import { ProfileInterface } from '../../models/interface/profile.interface';
import { UserInterface } from '../../models/interface/user.interface';
import { OrganisationActionTypes } from '../../store/organisation/organisation.action';

@Injectable({
  providedIn: 'root'
})
export class OrganisationGuard implements CanActivate {
  public organisationId: string;
  private profile$: Observable<ProfileInterface> = this.store.select('myProfile');
  private profile: ProfileInterface;
  private organisations$: Observable<Array<OrganisationInterface>>;

  constructor(
    private router: Router,
    private store: Store<{
      myProfile: ProfileInterface;
      organisations: Array<OrganisationInterface>;
    }>
  ) {
    this.organisations$ = store.select('organisations');
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.organisationId = state.url.replace(/\D/g, '');
    return this.profile$.pipe(
      switchMap((user: ProfileInterface) => {
        this.profile = user;
        this.store.dispatch({ type: OrganisationActionTypes.getOrganisationsType, payload: { include: 'owners,collaborators' } });
        return this.organisations$.pipe(skip(1), take(1));
      }),
      switchMap((result: Array<OrganisationInterface>) => {
        const organisation = result.find((org: OrganisationInterface) => org.id.toString() === this.organisationId.toString());
        const isCollaborator = !!organisation?.relationships?.collaborators?.data.find(
          (user: UserInterface) => user.id.toString() === this.profile.id.toString()
        );
        return iif(() => isCollaborator, of(true), of(this.router.parseUrl('/groups')));
      }),
      catchError(() => of(this.router.parseUrl(`/groups`)))
    );
  }
}
