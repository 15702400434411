import { Action, createAction, props } from '@ngrx/store';
import { DiaryInterface } from 'src/app/models/interface/diary.interface';

export enum DiaryActionTypes {
  addAllDiariesStoreType = 'Add All Diaries Store',
  updateAllDiariesStoreType = 'Update All Diaries Store',
  deleteAllDiariesStoreType = 'Delete All Diaries Store',

  getAllDiariesType = '[Diary API] Get all diaries',
  getAllDiariesSuccessType = '[Success] Get all diaries',
  getAllDiariesErrorType = '[Error] Get all diaries'
}

export const addAllDiariesStore = createAction(DiaryActionTypes.addAllDiariesStoreType, props<{ diaries: Array<DiaryInterface> }>());
export const updateAllDiariesStore = createAction(DiaryActionTypes.updateAllDiariesStoreType, props<{ diaries: Array<DiaryInterface> }>());
export const removeAllDiariesStore = createAction(DiaryActionTypes.deleteAllDiariesStoreType);

export class GetAllDiaries implements Action {
  readonly type: string = DiaryActionTypes.getAllDiariesType;
  constructor(readonly payload: any) {}
}
