<!-- Text area component for lesson/question elements in ionic app -->
<div *ngIf="(evaluation$ | async)" [class.required]="required" [hidden]="hidden$ | async" class="question">
  <ion-label class="ion-text-wrap">{{questionSubject | async}}</ion-label>
  <ion-item #answerRequiredRef class="ion-text-wrap">
    <ion-textarea
      (change)="onChange($event)"
      [(ngModel)]="answer"
      [readonly]="isDisabled"
      placeholder="{{'question-text-area.textarea_placeholder' | translate}}"
    ></ion-textarea>
  </ion-item>
  <div *ngIf="noAnswerProvided$ | async" class="text-danger mb-2">
    <small>{{'question-text-area.no_answer_has_been_submitted' | translate}}</small>
  </div>
</div>
