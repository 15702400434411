import { HttpResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable, skip, Subscription, take } from 'rxjs';
import { StudyInterface } from '../../../models/interface/study/study.interface';
import { StudyStore } from '../../../store/study/component-store/study.store';

@Component({
  selector: 'app-dialog-admin-study-delete',
  templateUrl: './dialog-admin-study-delete.component.html',
  styleUrls: ['./dialog-admin-study-delete.component.scss'],
  providers: [StudyStore]
})
export class DialogAdminStudyDeleteComponent implements OnInit, OnDestroy {
  public study: StudyInterface;

  public deleteStudyConfirmation: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public deleteStudyResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');

  private deleteStudyAdminResponse$: Observable<any>;

  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private studystore: StudyStore,
    private dialogRef: MatDialogRef<DialogAdminStudyDeleteComponent>
  ) {
    this.deleteStudyAdminResponse$ = this.studystore.deleteStudyAdminResponse$;
  }

  ngOnInit(): void {
    this.study = this.data.group;
  }

  public deleteStudy(): void {
    if (this.deleteStudyResponse.value === 'DEFAULT') {
      this.deleteStudyResponse.next('LOADING');
      this.studystore.deleteStudyAdmin({ studyId: this.study.id });
      this.subscriptions.push(
        this.deleteStudyAdminResponse$.pipe(skip(1), take(1)).subscribe(
          (result: any) => {
            if (result instanceof HttpResponse) {
              this.deleteStudyResponse.next('SUCCESS');
              setTimeout(() => {
                this.deleteStudyResponse.next('DEFAULT');
                this.dialogRef.close();
              }, 2500);
            } else {
              this.deleteStudyResponse.next('FAILURE');
              setTimeout(() => {
                this.deleteStudyResponse.next('DEFAULT');
              }, 2500);
            }
          },
          (error: any) => {
            this.deleteStudyResponse.next('FAILURE');
            setTimeout(() => {
              this.deleteStudyResponse.next('DEFAULT');
            }, 2500);
          }
        )
      );
    }
  }

  public showConfirmation(): void {
    this.deleteStudyConfirmation.next(true);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
