/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged, mergeMap, skip, take } from 'rxjs/operators';
import { faList } from '@fortawesome/free-solid-svg-icons/faList';
import { InterventionInterface } from '../../../models/interface/intervention.interface';
import { HelperService } from '../../../services/helper/helper.service';
import { GroupSharedService } from '../../../services/shared/group-shared/group-shared.service';
import { HelperDialogService } from '../../../services/helper/helper-dialog/helper-dialog.service';
import { InterventionStore } from '../../../store/intervention/component-store/intervention.store';
import { StudyInterface } from 'src/app/models/interface/study/study.interface';

@Component({
  selector: 'app-group-intervention-review',
  templateUrl: './group-intervention-review.component.html',
  styleUrls: ['./group-intervention-review.component.scss'],
  providers: [InterventionStore]
})
export class GroupInterventionReviewComponent implements OnInit, OnDestroy {
  @ViewChild('paginator') paginator;

  // Icons
  faList = faList;

  // Study ID and loading status for this child component
  public studyId: number;
  public isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public isLoading$: Observable<boolean>;

  // Filter
  public filter = {
    searchFilter: '',
    typeOfIntervention: '3'
  };

  public isCollapse$: Observable<boolean>;
  public interventions$: Observable<Array<InterventionInterface>>;
  public pagedInterventions$: Observable<Array<InterventionInterface>>;

  public isManager$: Observable<boolean>;
  public isPublisher$: Observable<boolean>;
  public isECoach$: Observable<boolean>;

  // Translation for study name
  public param = { intervention_name: '...' };

  public isCollapseSubject = new BehaviorSubject<boolean>(true);

  private allInterventionsOfStudy$: Observable<Array<InterventionInterface>>;

  private searchText$ = new BehaviorSubject<string>('');
  private interventionsSubject: BehaviorSubject<Array<InterventionInterface>> = new BehaviorSubject([]);
  private pagedInterventionsSubject: BehaviorSubject<Array<InterventionInterface>> = new BehaviorSubject<Array<InterventionInterface>>([]);

  private isManagerSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isPublisherSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isECoachSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private initialInterventions: Array<InterventionInterface>;

  private subscriptions: Subscription[] = [];

  constructor(
    private sharedService: GroupSharedService,
    private helperService: HelperService,
    private helperDialogService: HelperDialogService,
    private router: Router,
    private translateService: TranslateService,
    private interventionStore: InterventionStore
  ) {
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.pagedInterventions$ = this.pagedInterventionsSubject.asObservable();
    this.isCollapse$ = this.isCollapseSubject.asObservable();
    this.interventions$ = this.interventionsSubject.asObservable();
    this.pagedInterventions$ = this.pagedInterventionsSubject.asObservable();

    this.isManager$ = this.isManagerSubject.asObservable();
    this.isPublisher$ = this.isPublisherSubject.asObservable();
    this.isECoach$ = this.isECoachSubject.asObservable();
    this.allInterventionsOfStudy$ = this.interventionStore.allInterventionsOfSpecificStudy$;
  }

  public get helper() {
    return this.helperService;
  }

  public get helperDialog() {
    return this.helperDialogService;
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.sharedService.isECoach$.subscribe((value: boolean) => {
        this.isECoachSubject.next(value);
      })
    );

    this.subscriptions.push(
      this.sharedService.isManager$.subscribe((value: boolean) => {
        this.isManagerSubject.next(value);
      })
    );

    this.subscriptions.push(
      this.sharedService.isPublisher$.subscribe((value: boolean) => {
        this.isPublisherSubject.next(value);
      })
    );

    this.subscriptions.push(
      this.sharedService.sourceGroup$.subscribe((value: StudyInterface) => {
        if (!value) {
          this.isLoadingSubject.next(true);
        } else {
          this.studyId = value.id;
          this.applyInterventionFilter();
          this.param = { intervention_name: value.attributes.name };
        }
      })
    );

    this.subscriptions.push(
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
        this.applyInterventionFilter();
      })
    );
  }

  public applyInterventionFilter(): void {
    this.isLoadingSubject.next(true);
    this.subscriptions.push(
      this.reloadInterventionList(true).subscribe((result: any) => {
        this.isLoadingSubject.next(false);
      })
    );
  }

  public reloadInterventionList(setFirst?: boolean): Observable<boolean> {
    const pageIndex = this.paginator?.paginator ? this.paginator?.paginator.pageIndex : 0;
    const pageSize = this.paginator?.paginator ? this.paginator?.paginator.pageSize : 20;
    const formGuidanceType = type =>
      ({
        3: undefined,
        2: 'accompanied',
        1: 'unaccompanied',
        0: 'accompanied_and_unaccompanied'
      }[type]);
    const paramGuidanceType: string = formGuidanceType(this.filter['typeOfIntervention']);
    this.interventionStore.getAllInterventionsOfSpecificStudy({ studyId: this.studyId, interventionType: paramGuidanceType, isActive: 0 });
    return this.allInterventionsOfStudy$.pipe(
      skip(1),
      take(1),
      mergeMap((interventions: Array<InterventionInterface>) => {
        this.initialInterventions = interventions;
        return this.search(this.filter['searchFilter']).pipe(
          mergeMap(() =>
            this.helper.setPagedContent(this.interventionsSubject, this.pagedInterventionsSubject, setFirst, pageIndex, pageSize)
          )
        );
      })
    );
  }

  public updateList(): void {
    this.subscriptions.push(this.reloadInterventionList().subscribe());
  }

  public getDetailedIntervention(id: number): void {
    this.router.navigateByUrl(`/interventions/${id}`);
  }

  public updatePagedInterventions(event: any) {
    if (event) {
      this.pagedInterventionsSubject.next(event);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  // Reads search value and runs searchFilter
  private search(name: string): Observable<boolean> {
    this.searchText$.next(name);
    return this.searchText$.pipe(
      distinctUntilChanged(),
      mergeMap(() => {
        this.interventionsSubject.next(
          this.helper.filterInterventionsBy(this.initialInterventions, this.filter['searchFilter'].toLowerCase().trim())
        );
        return of(true);
      })
    );
  }
}
