<div class="container-fluid">
  <div class="row text-start mt-3">
    <h1 class="col-12 col-sm-6">{{ 'imprint.imprint_title' | translate }}</h1>
    <a (click)="redirectToLogin()" *ngIf="(isAuthenticated$ | async) === false" mat-button class="col-12 col-sm-6 mb-2" color="primary"
      ><fa-icon [icon]="faArrowLeft"></fa-icon> {{ 'privacy.back_to_login' | translate }}</a
    >
    <hr />
  </div>
  <h3>Herausgeber</h3>
  <p>
    Universität Ulm<br />
    89069 Ulm<br />
    Telefon +49 (0)731/50-10<br />
    Telefax +49 (0)731/50-22038<br />
    Umsatzsteueridentifikationsnummer DE173703203
  </p>
  <h3>Vertretung</h3>
  <p>
    Die Universität Ulm ist eine Körperschaft des öffentlichen Rechts. Sie wird durch den Präsidenten Prof. Dr.-Ing. Michael Weber oder
    durch den Kanzler Dieter Kaufmann vertreten.
  </p>
  <h3>Adresse des Präsidenten</h3>
  <p>
    Universität Ulm<br />
    Präsident Prof. Dr.-Ing. Michael Weber<br />
    Helmholtzstr. 16<br />
    89081 Ulm
  </p>
  <h3>Adresse des Kanzlers</h3>
  <p>
    Universität Ulm<br />
    Kanzler Dieter Kaufmann<br />
    Helmholtzstr. 16<br />
    89081 Ulm
  </p>
  <h3>Bankverbindung</h3>
  <p>
    Kasse der Universität Ulm<br />
    Bundesbank Ulm<br />
    BIC (SWIFT-Code): MARKDEF1630<br />
    IBAN: DE72 6300 0000 0063 0015 05
  </p>
  <h3>Inhalt</h3>
  <p>
    Die inhaltliche Verantwortung und die Verantwortung im Sinne des Presserechts liegen bei der jeweiligen informationseinstellenden
    Einrichtung:<br /><br />
    Abteilung für Klinische Psychologie und Psychotherapie,<br />
    Prof. Dr. Harald Baumeister,<br />
    Lise-Meitner-Str. 16<br />
    89081 Ulm<br />
    Telefon: +49 731 50-32800<br />
    Fax: +49 731 50-32809
  </p>
  <h3>Zuständige Aufsichtsbehörde</h3>
  <p>
    Ministerium für Wissenschaft, Forschung und Kunst Baden-Württemberg<br />
    Königstraße 46<br />
    70173 Stuttgart
  </p>
  <h3>Copyright</h3>
  <p>
    ©&nbsp;Universität Ulm<br /><br />
    Alle auf dieser Website veröffentlichten Inhalte (Layout, Texte, Bilder, Grafiken usw.) unterliegen dem Urheberrecht. Jede vom
    Urheberrechtsgesetz nicht zugelassene Verwertung bedarf vorheriger ausdrücklicher, schriftlicher Zustimmung der Universität Ulm. Dies
    gilt insbesondere für Vervielfältigung, Bearbeitung, Übersetzung, Einspeicherung, Verarbeitung bzw. Wiedergabe von Inhalten in
    Datenbanken oder anderen elektronischen Medien und Systemen. Fotokopien und Downloads von Web-Seiten für den privaten,
    wissenschaftlichen und nicht kommerziellen Gebrauch dürfen hergestellt werden.<br /><br />
    Die Universität Ulm erlaubt ausdrücklich und begrüßt das Zitieren der Dokumente und Webseiten sowie das Setzen von Links auf ihre
    Website.<br /><br />
    Die Universität Ulm ist bestrebt, in allen Publikationen die Urheberrechte der verwendeten Grafiken, Tondokumente, Videosequenzen und
    Texte zu beachten, von ihr selbst erstellte Grafiken, Tondokumente, Videosequenzen und Texte zu nutzen oder auf lizenzfreie Grafiken,
    Tondokumente, Videosequenzen und Texte zurückzugreifen.<br /><br />
    Alle innerhalb des Internetangebotes genannten und ggf. durch Dritte geschützten Marken und Warenzeichen unterliegen uneingeschränkt den
    Bestimmungen des jeweils gültigen Kennzeichenrechts und den Besitzrechten der jeweiligen eingetragenen Eigentümer. Allein aufgrund der
    bloßen Nennung ist nicht der Schluss zu ziehen, dass Markenzeichen nicht durch Rechte Dritter geschützt sind!
  </p>
  <h3>Geschlechtergerechte Schreibweise</h3>
  <p>
    Bei der Erstellung der Seiten wurde versucht auf eine geschlechtergerechte Schreibweise zu achten. Trotzdem ist es möglich, dass manche
    Textstellen nicht geschlechtergerecht ausgedrückt wurden. Wir weisen an dieser Stelle ausdrücklich darauf hin, dass bei der Befragung
    alle Studienteilnehmende angesprochen werden.
  </p>
  <h3>Haftungsausschluss</h3>
  <h4>Haftungsausschluss bei eigenen Inhalten</h4>
  <p>
    Die Inhalte dieser Website werden mit größtmöglicher Sorgfalt recherchiert und implementiert. Fehler im Bearbeitungsvorgang sind dennoch
    nicht auszuschließen. Hinweise und Korrekturen senden Sie bitte an:<br /><br />
    Universität Ulm<br />
    Abteilung für Klinische Psychologie und Psychotherapie,<br />
    Prof. Dr. Harald Baumeister,<br />
    Lise-Meitner-Str. 16<br />
    89081 Ulm<br /><a href="mailto:support@esano-trainings.de"> support@esano-trainings.de </a><br /><br />
    Eine Haftung für die Richtigkeit, Vollständigkeit und Aktualität dieser Webseiten kann trotz sorgfältiger Prüfung nicht übernommen
    werden. Die Universität Ulm übernimmt insbesondere keinerlei Haftung für eventuelle Schäden oder Konsequenzen, die durch die direkte
    oder indirekte Nutzung der angebotenen Inhalte entstehen.<br /><br />
  </p>
  <h4>Haftungsausschluss bei Links zu externen Inhalten</h4>
  <p>
    Die Universität Ulm ist als Inhaltsanbieter für die eigenen Inhalte, die sie zur Nutzung bereithält, nach den allgemeinen Gesetzen
    verantwortlich. Von diesen eigenen Inhalten sind Querverweise („externe Links“) auf die von anderen Anbietern bereitgehaltenen Inhalte
    zu unterscheiden. Diese fremden Inhalte stammen weder von der Universität Ulm, noch hat die Universität Ulm die Möglichkeit, den Inhalt
    von Seiten Dritter zu beeinflussen.<br /><br />
    Die Inhalte fremder Seiten, auf die die Universität Ulm mittels Links hinweist, spiegeln nicht die Meinung der Universität Ulm wider,
    sondern dienen lediglich der Information und der Darstellung von Zusammenhängen. Die Universität macht sich diese weitervermittelten
    Inhalte nicht zu eigen und übernimmt auch keine Verantwortung für diese fremden Inhalte.<br /><br />
    Diese Feststellungen gelten für alle innerhalb des eigenen Internetangebotes gesetzten Links und Verweise sowie für Fremdeinträge in
    Gästebüchern, Diskussionsforen und Mailinglisten, die von der Universität Ulm eingerichtet wurden. Für illegale, fehlerhafte oder
    unvollständige Inhalte und insbesondere für Schäden, die aus der Nutzung oder Nichtnutzung solcherart dargebotener Informationen
    entstehen, haftet allein der Anbieter der Seite, auf welche verwiesen wurde.
  </p>
  <h3>Konzeption, Realisierung und Gestaltung</h3>
  <p>
    Universität Ulm<br />
    Abteilung für Klinische Psychologie und Psychotherapie,<br />
    Prof. Dr. Harald Baumeister,<br />
    Lise-Meitner-Str. 16<br />
    89081 Ulm<br />
    Prof. Dr. Harald Baumeister<br /><a href="mailto:support@esano-trainings.de"> support@esano-trainings.de </a>
  </p>
</div>
