/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { filter, take } from 'rxjs/operators';
import { InterventionInterface } from '../../../models/interface/intervention.interface';
import { InterventionStore } from '../../../store/intervention/component-store/intervention.store';
import { HttpResponse } from '@angular/common/http';
import { HelperService } from '../../../services/helper/helper.service';

@Component({
  selector: 'app-dialog-intervention-publish-official',
  templateUrl: './dialog-intervention-publish-official.component.html',
  styleUrls: ['./dialog-intervention-publish-official.component.scss'],
  providers: [InterventionStore]
})
export class DialogInterventionPublishOfficialComponent implements OnInit, OnDestroy {
  public firstTerm = false;
  public secondTerm = false;
  public thirdTerm = false;
  public fourthTerm = false;
  public fifthTerm = false;
  public sixthTerm = false;
  public intervention: InterventionInterface;
  public param = { intervention_name: '...' };

  public showRejectionDialogSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public rejectPendingInterventionResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');
  public acceptPendingInterventionResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');

  public setActiveInterventionAsPublisherResponse$: Observable<any>;

  public deactivationText = '';

  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private helperService: HelperService,
    private interventionStore: InterventionStore,
    private dialogRef: MatDialogRef<DialogInterventionPublishOfficialComponent>
  ) {
    this.setActiveInterventionAsPublisherResponse$ = interventionStore.setActiveInterventionAsPublisherResponse$;
  }

  public get helper() {
    return this.helperService;
  }

  ngOnInit(): void {
    this.intervention = this.data.intervention;
    this.param = { intervention_name: this.intervention.attributes.name };
  }

  public rejectPendingIntervention(): void {
    if (this.rejectPendingInterventionResponse.value === 'DEFAULT' && this.acceptPendingInterventionResponse.value === 'DEFAULT') {
      this.rejectPendingInterventionResponse.next('LOADING');
      this.interventionStore.setActiveInterventionAsPublisher({
        id: this.intervention.id,
        isActive: false,
        deactivationText: this.deactivationText !== '' ? this.deactivationText : undefined
      });
      this.subscriptions.push(
        this.setActiveInterventionAsPublisherResponse$
          .pipe(
            filter(value => !!value),
            take(1)
          )
          .subscribe((value: any) => {
            if (value instanceof HttpResponse) {
              this.rejectPendingInterventionResponse.next('SUCCESS');
              setTimeout(() => {
                this.rejectPendingInterventionResponse.next('DEFAULT');
                this.dialogRef.close('SUCCESS');
              }, 2500);
            } else {
              this.rejectPendingInterventionResponse.next('FAILURE');
              setTimeout(() => {
                this.rejectPendingInterventionResponse.next('DEFAULT');
              }, 2500);
            }
          })
      );
    }
  }

  public acceptPendingIntervention(): void {
    if (this.updateCondition()) {
      if (this.acceptPendingInterventionResponse.value === 'DEFAULT' && this.rejectPendingInterventionResponse.value === 'DEFAULT') {
        this.acceptPendingInterventionResponse.next('LOADING');
        this.interventionStore.setActiveInterventionAsPublisher({
          id: this.intervention.id,
          isActive: true
        });
        this.subscriptions.push(
          this.setActiveInterventionAsPublisherResponse$
            .pipe(
              filter(value => !!value),
              take(1)
            )
            .subscribe((value: any) => {
              if (value instanceof HttpResponse) {
                this.acceptPendingInterventionResponse.next('SUCCESS');
                setTimeout(() => {
                  this.acceptPendingInterventionResponse.next('DEFAULT');
                  this.dialogRef.close('SUCCESS');
                }, 2500);
              } else {
                this.acceptPendingInterventionResponse.next('FAILURE');
                setTimeout(() => {
                  this.acceptPendingInterventionResponse.next('DEFAULT');
                }, 2500);
              }
            })
        );
      }
    }
  }

  public updateCondition(): boolean {
    return this.firstTerm && this.secondTerm && this.thirdTerm && this.fourthTerm && this.fifthTerm && this.sixthTerm;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
