import { Injectable } from '@angular/core';
import { ConditionalblockInterface } from '../../models/interface/condition/conditionalblock.interface';
import { BehaviorSubject } from 'rxjs';
import { ConditionIfBlockInterface } from '../../models/interface/condition/condition_ifblock.interface';
import { HelperService } from '../helper/helper.service';
import { AnswersheetAnswerInterface } from '../../models/interface/answersheet-answer.interface';
import { ConditionElementInterface } from '../../models/interface/condition/condition-element.interface';
import { ParserService } from '../parser/parser.service';
const evaluationService = require('@aas2/aas2-evaluation-service');
/*
 * Evaluation is based on
 * Lenas null'sches System
 * x = true/false/null
 * answered(null) = false
 * or(x, null) = x
 * and(x, null) = false
 * not(null) = true
 * if condition is being evaluated and returns null, nothing happens
 */

@Injectable({
  providedIn: 'root'
})

/*
 * Condition service that handles conditional elements such as evaluating expression, traversal through tree etc.
 */
export class EvaluationService {
  leafNodes;

  public expressionSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public evaluationSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private helperService: HelperService, private parserService: ParserService) {
    this.leafNodes = [];
  }

  // Evaluate all conditional elements for this lesson with the given answers
  evaluate(condition: Array<ConditionalblockInterface>, position: number, answers: Array<AnswersheetAnswerInterface>) {
    // isShown = isShown && this.buildTree(answers, cond.condition.ifBlock);
    let isShown = true;
    if (condition.length > 0) {
      condition.forEach((cond: ConditionalblockInterface) => {
        const blockOpen = cond.blockOpen;
        const blockClose = cond.blockClose;
        if (blockOpen < position && position < blockClose) {
          // For each condition that applies for this element in this position
          if (cond.condition_type === 'conditional' || cond.condition_type === 'both') {
            isShown = this.evaluateCondition(cond.condition, answers);
          }
          if (cond.condition_type === 'none') {
            return true;
          }
          if (cond.condition_type === 'details') {
            // TODO
          }
        }
        return true;
      });
    }
    return isShown;
  }

  // Set up tree to determine nodes and leaves
  buildTree(dynamicAnswers: Array<AnswersheetAnswerInterface>, condition: ConditionIfBlockInterface): boolean {
    // Check if answers are available to apply the answer
    const myConditionLeaves = this.traverse(condition);
    if (!!dynamicAnswers && this.checkAnswersAvailable(dynamicAnswers, myConditionLeaves)) {
      if (condition.nodeType === 'and') {
        let conditionAnd = true;
        for (const conditionChild of condition.children) {
          conditionAnd = conditionAnd && this.buildTree(dynamicAnswers, conditionChild);
        }
        return conditionAnd;
      } else if (condition.nodeType === 'or') {
        let conditionOr = false;
        for (const conditionChild of condition.children) {
          conditionOr = conditionOr || this.buildTree(dynamicAnswers, conditionChild);
        }
        return conditionOr;
      } else if (condition.nodeType === 'not') {
        return !this.buildTree(dynamicAnswers, condition.children[0]);
      } else {
        // condition.notType === leaf apply expression parsing
        // Looking for answer
        return this.evaluateLeaf(dynamicAnswers, condition);
      }
    } else {
      return false;
    }
  }

  // Traverse through tree and returns all leaf elements
  traverse(condition: ConditionIfBlockInterface) {
    this.leafNodes = [];
    this.getLeafNodes(condition);
    return this.leafNodes;
  }

  // Recursive function that determines all leaf elements
  getLeafNodes(condition: ConditionIfBlockInterface) {
    if (condition.nodeType !== 'leaf') {
      if (condition.nodeType === 'and') {
        for (const child of condition.children) {
          this.getLeafNodes(child);
        }
      }
      if (condition.nodeType === 'or') {
        for (const child of condition.children) {
          this.getLeafNodes(child);
        }
      }
      if (condition.nodeType === 'not') {
        for (const child of condition.children) {
          this.getLeafNodes(child);
        }
      }
    } else {
      this.leafNodes.push(condition);
    }
  }

  /*
  TODO: Operation is needed e.g.;
   - 'equals' for Yes-No and SingleChoice
   - 'less', 'less than or equal', 'greater', 'greater than or equal', 'equals', 'not equals' or 'between' (secondvalue) for Slider and Date
   Evaluate leaf element
   Typechecking for expected answers required
   */
  evaluateLeaf(dynamicAnswers: Array<AnswersheetAnswerInterface>, condition: ConditionIfBlockInterface): boolean {
    const condLeafType = condition.leafType;
    const condOperation = condition.operation;
    const condValue = condition.value;
    const condSecondValue = condition.secondValue;

    // Perform operation for type
    const performOperation = (operation, value, secondvalue, answer) => {
      if (operation === 'less') {
        return parseInt(answer, 10) < parseInt(value, 10);
      }
      if (operation === 'less than or equal') {
        return parseInt(answer, 10) <= parseInt(value, 10);
      }
      if (operation === 'greater') {
        return parseInt(answer, 10) > parseInt(value, 10);
      }
      if (operation === 'greater than or equal') {
        return parseInt(answer, 10) >= parseInt(value, 10);
      }
      if (operation === 'equals') {
        return answer === value;
      }
      if (operation === 'not equals') {
        return answer !== value;
      }
      if (operation === 'between') {
        return parseInt(answer, 10) > parseInt(value, 10) && parseInt(answer, 10) < parseInt(secondvalue, 10);
      }
      if (operation === 'answered') {
        return answer !== null || true;
      }
      return null;
    };

    if (condition.leafType === 'date' || condition.leafType === 'slider') {
      let evaluation = false;
      const answers: Array<AnswersheetAnswerInterface> = dynamicAnswers.filter(
        x => x.position.toString() === condition.questionLabel.toString()
      );
      answers.forEach((answer: AnswersheetAnswerInterface) => {
        let myanswer = answer.value;
        let firstvalue = condValue;
        let secondvalue = condSecondValue;

        // Date
        if (condLeafType === 'date') {
          myanswer = this.helperService.convertStringToUnixTimestamp(this.helperService.formatDateToYYYY_MM_DD(answer.value));
          firstvalue = this.helperService.convertStringToUnixTimestamp(firstvalue);
          if (secondvalue) {
            secondvalue = this.helperService.convertStringToUnixTimestamp(secondvalue);
          }
        }
        if (performOperation(condOperation, firstvalue.toString(), secondvalue.toString(), myanswer.toString())) {
          evaluation = true;
        }
      });
      return evaluation;
    } else if (condition.leafType === 'table') {
      let evaluation = false;
      const answers: Array<AnswersheetAnswerInterface> = dynamicAnswers.filter(
        x => x.position.toString() === condition.questionLabel.toString()
      );
      let sum = null;
      answers.forEach((answer: AnswersheetAnswerInterface) => {
        const firstvalue = condValue;
        const secondvalue = condSecondValue;
        sum = answer.value.filter(val => val !== null).reduce((prev, curr) => prev + curr, 0);
        if (performOperation(condOperation, firstvalue.toString(), secondvalue.toString(), sum.toString())) {
          evaluation = true;
        }
      });
      return evaluation;
    } else {
      let evaluation = false;
      const answers: Array<AnswersheetAnswerInterface> = dynamicAnswers.filter(
        x => x.position.toString() === condition.questionLabel.toString()
      );
      answers.forEach((answer: AnswersheetAnswerInterface) => {
        const myanswer = answer.value;
        const firstvalue = condValue;
        const secondvalue = condSecondValue;

        // If answer is multi choice
        if (answer.value instanceof Array) {
          answer.value.forEach(value => {
            if (performOperation(condOperation, firstvalue.toString(), secondvalue.toString(), value.toString())) {
              evaluation = true;
            }
          });
          const found = answer.value.find(x => x.toString() === condition.value.toString());
          if (found) {
            evaluation = true;
          }
        } else if (answer.value instanceof Array) {
          answer.value.forEach(value => {
            if (performOperation(condOperation, firstvalue.toString(), secondvalue.toString(), value.toString())) {
              evaluation = true;
            }
          });
          const found = answer.value.find(x => x.toString() === condition.value.toString());
          if (found) {
            evaluation = true;
          }
        } else {
          if (answer.value) {
            if (answer.value.toString() === 'YES' && condition.value === true) {
              evaluation = true;
            }

            if (answer.value.toString() === 'true' && condition.value === 'YES') {
              evaluation = true;
            }

            if (answer.value.toString() === 'NO' && condition.value === false) {
              evaluation = false;
            }

            if (answer.value.toString() === 'false' && condition.value === 'NO') {
              evaluation = true;
            }

            if (answer.value.toString() === condition.value.toString()) {
              evaluation = true;
            }
          }
          const checkedAnswer = myanswer !== null ? myanswer.toString() : myanswer;
          if (performOperation(condOperation, firstvalue.toString(), secondvalue.toString(), checkedAnswer)) {
            evaluation = true;
          }
        }
      });
      return evaluation;
    }
  }

  // Check if answers are available and prevents undefined answers for conditions
  checkAnswersAvailable(dynamicAnswers: Array<AnswersheetAnswerInterface>, leaves) {
    let available = true;
    if (dynamicAnswers.length !== 0 && !!leaves) {
      // TODO Fix position and question label undefined issue
      leaves.forEach(arr => {
        if (dynamicAnswers.find(x => x.position.toString() === arr.questionLabel.toString())) {
          available = available && true;
        } else {
          available = available && false;
        }
      });
      return available;
    } else {
      return !available;
    }
  }

  displayEvaluation(evaluationSubject: BehaviorSubject<boolean>, value: boolean) {
    evaluationSubject.next(value);
  }

  // Get condition parsed in string
  getConditionalString(condition: Array<ConditionalblockInterface>, position: number) {
    // Check if answers are available to apply the answer
    let conditionString = '[';
    if (condition.length > 0) {
      condition.forEach(cond => {
        const blockOpen = cond.blockOpen;
        const blockClose = cond.blockClose;
        const blockType = cond.condition_type;
        if (blockType === 'conditional' || blockType === 'both') {
          if (blockOpen <= position && position < blockClose) {
            // For each condition that applies for this element in this position
            if (conditionString === '[') {
              conditionString = conditionString + this.buildConditionString(cond.condition.ifBlock);
            } else {
              conditionString = conditionString + ' conditionalAND ' + this.buildConditionString(cond.condition);
            }
          }
        }
        if (blockType === 'none') {
          // Loop
        }
        if (blockType === 'details') {
          // Loop
        }
        if (blockType === 'both') {
          if (blockOpen < position && position < blockClose) {
            // For each condition that applies for this element in this position
            if (conditionString === '[') {
              conditionString = conditionString + this.buildConditionString(cond.condition.ifBlock);
            } else {
              conditionString = conditionString + ' conditionalAND ' + this.buildConditionString(cond.condition);
            }
          }
        }
      });
    }
    conditionString = conditionString + ']';
    if (conditionString === '[]') {
      return '';
    } else {
      return conditionString;
    }
  }

  buildConditionString(condition): string {
    let conditionString = '';
    if (condition.nodeType !== 'leaf') {
      if (condition.nodeType === 'and') {
        for (let i = 0; i < condition.children.length; i++) {
          if (i === 0) {
            conditionString = '(' + this.buildConditionString(condition.children[i]) + ' AND ';
          }
          if (i === condition.children.length - 1) {
            conditionString = conditionString + this.buildConditionString(condition.children[i]) + ')';
          }
          if (i !== 0 && i !== condition.children.length - 1) {
            conditionString = conditionString + ' AND ' + this.buildConditionString(condition.children[i]);
          }
        }
        return conditionString;
      }
      if (condition.nodeType === 'or') {
        for (let i = 0; i < condition.children.length; i++) {
          if (i === 0) {
            conditionString = '(' + this.buildConditionString(condition.children[i]) + ' OR ';
          }
          if (i === condition.children.length - 1) {
            conditionString = conditionString + this.buildConditionString(condition.children[i]) + ')';
          }
          if (i !== 0 && i !== condition.children.length - 1) {
            conditionString = conditionString + ' OR ' + this.buildConditionString(condition.children[i]);
          }
        }
        return conditionString;
      }
      if (condition.nodeType === 'not') {
        return '( NOT ' + conditionString + this.buildConditionString(condition.children[0]) + ')';
      }
    } else {
      // leaf apply expression parsing
      // Looking for answer
      return this.buildConditionLeafString(condition);
    }
    return '';
  }

  buildConditionLeafString(condition: ConditionIfBlockInterface): string {
    return '"questionLabel(' + condition.questionLabel + ')' + ' === ' + condition.value + '"';
  }

  displayConditionString(expressionSubject: BehaviorSubject<string>, value: string) {
    expressionSubject.next(value);
  }

  evaluateCondition(condition: ConditionElementInterface, answers: Array<AnswersheetAnswerInterface>) {
    let tree = condition.ifBlock;
    return evaluationService.evaluateCondition(tree, this.parserService.parseAnswerToConditionEvaluation(answers));
  }

  public replaceReference(
    text: string,
    allElements,
    language,
    answers: Array<AnswersheetAnswerInterface>,
    dynamicAnswers: Array<AnswersheetAnswerInterface>
  ): string {
    const previewAnswers =
      answers.length > 0
        ? this.parserService.parseAnswerToConditionEvaluation(answers)
        : this.parserService.parseAnswerToConditionEvaluation(dynamicAnswers);
    const questionElements = this.parserService.parseElementsToReferenceEvaluation(allElements);
    try {
      return evaluationService.evaluateReferences(text, previewAnswers, questionElements, language);
    } catch (error) {
      return '';
    }
  }

  evaluateExpression(
    expressionSubject: BehaviorSubject<string>,
    evaluationSubject: BehaviorSubject<boolean>,
    condition: Array<ConditionalblockInterface>,
    position: number,
    answers: Array<AnswersheetAnswerInterface>,
    dynamicAnswers: Array<AnswersheetAnswerInterface>
  ): void {
    const _answers = answers.length > 0 ? answers : dynamicAnswers;
    this.displayConditionString(expressionSubject, this.getConditionalString(condition, position));
    this.displayEvaluation(evaluationSubject, this.evaluate(condition, position, _answers));
  }
}
