<div class="container-fluid mt-5" #container>
  <div class="row justify-content-center">
    <div class="col-sm-1 col-md-3 col-lg-4"></div>
    <div class="col-sm-10 col-md-6 col-lg-4 mx-auto mt-5">
      <ng-container *ngIf="(isPasswordResetInstruction$ | async) === false; then thenShowLogin else elseShowPWReset"></ng-container>

      <ng-template #thenShowLogin>
        <mat-card appearance="outlined">
          <img mat-card-image src="assets/esano-images/eSano_e-coach.png" alt="No image" />

          <mat-card-header>
            <mat-card-subtitle
              >{{'login.sign_in_information' | translate}}
              <span class="text-info">{{'login.sign_in_warning' | translate}}</span></mat-card-subtitle
            >
            <mat-card-subtitle>
              <app-alert></app-alert>
            </mat-card-subtitle>
          </mat-card-header>

          <mat-card-content>
            <!-- Login loginForm -->
            <div [formGroup]="loginForm">
              <!-- Email -->
              <mat-form-field [style.width.%]="100" appearance="outline">
                <mat-label>{{'login.email' | translate}}</mat-label>
                <input (keyup.enter)="login()" autocomplete="off" formControlName="email" id="email" matInput required type="text" />
                <mat-error
                  *ngIf="((isSubmitted$ | async) && f.get('email').invalid && f.get('email').errors?.required) || (f.get('email').touched && f.get('email').invalid)"
                  >{{'login.valid_email_required' | translate}}</mat-error
                >
              </mat-form-field>

              <!-- Password -->
              <mat-form-field [style.width.%]="100" appearance="outline">
                <mat-label>{{'login.password' | translate}}</mat-label>
                <input
                  (keyup.enter)="login()"
                  autocomplete="off"
                  formControlName="password"
                  id="password"
                  matInput
                  required
                  [type]="fieldTextType === 'text' ? 'text' : 'password'"
                />
                <button
                  [attr.id]="'btnTogglePasswordVisibility'"
                  matSuffix
                  mat-button
                  aria-label="visibility"
                  (click)="togglePasswordVisibility()"
                >
                  <mat-icon *ngIf="fieldTextType === 'text'">visibility_on</mat-icon>
                  <mat-icon *ngIf="fieldTextType === 'password'">visibility_off</mat-icon>
                </button>
                <mat-error
                  *ngIf="((isSubmitted$ | async) && f.get('password').invalid && f.get('password').errors?.required) || (f.get('password').touched && f.get('password').invalid)"
                  >{{'login.password_required' | translate}}</mat-error
                >
              </mat-form-field>

              <!-- Simple Sign In button -->

              <!-- Temporarily fixed autofill issue -->
              <ng-container *ngIf="loginForm.invalid && !loginForm.touched; then thenDisabled else elseEnabled"></ng-container>
              <ng-template #thenDisabled>
                <button disabled [style.width.%]="100" color="primary" mat-raised-button>{{'login.log_in' | translate}}</button>
              </ng-template>
              <ng-template #elseEnabled>
                <app-button-feedback
                  [_setMatButtonType]="'mat-stroked-button'"
                  [_setId]="'btnLogin'"
                  [_isDisabled]="(isLoading$ | async) || loginForm.invalid"
                  [_setWidth]="100"
                  (click)="login()"
                  [_defaultButtonText]="'login.log_in'"
                  [_responseText]="loginResponse | async"
                ></app-button-feedback>
              </ng-template>
            </div>
          </mat-card-content>
          <mat-card-actions align="end">
            <!-- Link to password reset -->
            <button id="btnTogglePasswordResetInstruction" (click)="toggleIsPasswordResetInstruction()" mat-button>
              {{'login.forgot_password' | translate}}
            </button>
          </mat-card-actions>
        </mat-card>
      </ng-template>
      <ng-template #elseShowPWReset>
        <mat-card appearance="outlined">
          <mat-card-header>
            <mat-card-title>{{'login.password_forgotten' | translate}}</mat-card-title>
            <mat-card-subtitle> {{'login.password_forgotten_description' | translate}} </mat-card-subtitle>
          </mat-card-header>

          <!-- Card body -->
          <mat-card-content>
            <!-- Login passwordResetForm -->
            <div [formGroup]="passwordResetForm">
              <!-- Email -->
              <mat-form-field [style.width.%]="100" appearance="outline">
                <mat-label>{{'login.email' | translate}}</mat-label>
                <input formControlName="email" id="instruction_email" matInput required type="text" />
                <mat-error
                  *ngIf="((isSubmittedReset$ | async) && g.get('email').invalid && g.get('email').errors?.required) || (g.get('email').touched && g.get('email').invalid)"
                  >{{'login.valid_email_required' | translate}}</mat-error
                >
              </mat-form-field>

              <!-- Simple Password Reset button -->
              <app-button-feedback
                [_setMatButtonType]="'mat-stroked-button'"
                [_setId]="'btnPasswordReset'"
                [_isDisabled]="passwordResetForm.invalid"
                [_setWidth]="100"
                (click)="requestPasswordReset()"
                [_defaultButtonText]="'login.password_forgotten_sent'"
                [_successButtonText]="'login.password_forgotten_sent_success'"
                [_errorButtonText]="'login.password_forgotten_sent_failed'"
                [_responseText]="passwordResetResponse | async"
              ></app-button-feedback>
            </div>
          </mat-card-content>
          <mat-card-actions align="end">
            <!-- Link to password reset -->
            <button (click)="toggleIsPasswordResetInstruction()" mat-button>{{'login.password_forgotten_login' | translate}}</button>
          </mat-card-actions>
        </mat-card>
      </ng-template>

      <div class="my-2">
        <mat-form-field appearance="outline">
          <mat-label>{{'login.language' | translate}}</mat-label>
          <mat-select (selectionChange)="changeLanguage()" [(value)]="selectedLanguage">
            <mat-option [value]="option" *ngFor="let option of languages">{{option}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="col-sm-1 col-md-3 col-lg-4"></div>
  </div>
</div>
