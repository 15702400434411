<div class="container-fluid text-center">
  <div *ngIf="(isLoading$ | async) else elseShowConfiguration">
    <app-loader></app-loader>
  </div>

  <ng-template #elseShowConfiguration>
    <div class="container text-start mb-3">
      <!-- Title and description -->
      <div class="form-group">
        <h2>{{'group-configuration.change_group_configuration' | translate}}</h2>
      </div>

      <form [formGroup]="groupFormType">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>{{'group-configuration.change_group_name' | translate}}</mat-label>
          <input [attr.id]="'name'" formControlName="name" matInput type="text" />
          <div *ngIf="submitted && f.name.invalid">
            <mat-error *ngIf="f.name.errors?.required">{{'group-configuration.error_is_required' | translate}}</mat-error>
            <mat-error *ngIf="f.name.errors?.maxlength">{{'group-configuration.error_max_255_characters' | translate}}</mat-error>
          </div>
        </mat-form-field>

        <hr />

        <!-- Set group picture -->
        <div *ngIf="(studyDetails.attributes.type === 'study')">
          <div class="form-group">
            <strong>{{'group-configuration.change_group_picture' | translate}}</strong>
          </div>

          <div class="form-group row justify-content-center">
            <div class="image-preview mb-3">
              <img [src]="(showImageSubject | async)" (error)="helper.getFallbackImage($event)" />
            </div>
            <div *ngIf="fileUploadProgress" class="progress form-group">
              <div [style.width.%]="fileUploadProgress" class="progress-bar progress-bar-striped bg-success" role="progressbar"></div>
            </div>
          </div>

          <!-- Upload group picture -->
          <div class="mt-2 mb-2 ml-2 mr-2">
            <div class="row">
              <div class="custom-file form-group col-12 col-lg-6 mb-2">
                <input
                  #fileInputPicture
                  [attr.id]="'image'"
                  (change)="processFile($event)"
                  accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml"
                  class="custom-file-input"
                  id="customFileStudyMedia"
                  name="image"
                  type="file"
                />
                <label
                  [attr.data-browse]="('group-configuration.browse' | translate)"
                  class="custom-file-label text-start"
                  for="customFileStudyMedia"
                  >{{uploadedFilePath}}</label
                >
              </div>

              <div class="form-group col-12 col-lg-2 mb-2">
                <button [attr.id]="'btnClearImage'" (click)="clearImage()" mat-button>{{'group-configuration.clear' | translate}}</button>
              </div>
              <div class="form-group col-12 col-lg-2 mb-2">
                <!-- Button trigger modal -->
                <button [attr.id]="'btnShowStudyMedia'" (click)="openDialogStudyMediaGallery()" color="primary" mat-button>
                  {{'group-configuration.display_study_pictures' | translate}}
                </button>
              </div>
            </div>
          </div>

          <hr />
        </div>

        <div *ngIf="studyDetails.attributes.type === 'study'" class="mt-3">
          <!-- Privacy settings for groups -->
          <div class="form-group">
            <strong>{{'group-configuration.change_group_private_type' | translate}}</strong>
          </div>

          <mat-radio-group [attr.id]="'radioIsPrivate'" aria-label="select an option" formControlName="isPrivate">
            <mat-radio-button [attr.id]="'radioItem0'" (change)="showIsPrivate($event.value)" value="0"
              >{{'group-configuration.no' | translate}}</mat-radio-button
            >
            <mat-radio-button [attr.id]="'radioItem1'" (change)="showIsPrivate($event.value)" value="1"
              >{{'group-configuration.yes' | translate}}</mat-radio-button
            >
          </mat-radio-group>

          <div *ngIf="(isPrivate$ | async) === '1'" class="form-container">
            <mat-form-field appearance="outline">
              <mat-label>{{'group-configuration.change_group_access_type' | translate}}</mat-label>
              <mat-select
                [attr.id]="'selectAccessType'"
                (selectionChange)="showAccesstype(groupFormType.value.accesstype)"
                formControlName="accesstype"
                required
              >
                <mat-option [attr.id]="'option_' + type" *ngFor="let type of accessType" [value]="type"
                  >{{('group-creation.' + type.toLowerCase()) | translate}}</mat-option
                >
              </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="((hasAccesstype$ | async) === 'PASSWORD')" appearance="outline" class="full-width">
              <mat-label>{{'group-configuration.password' | translate}}</mat-label>
              <input [attr.id]="'password'" formControlName="password" matInput type="text" />
              <div *ngIf="submitted && f.password.invalid">
                <mat-error *ngIf="f.password.errors?.required || f.password.errors?.minLength"
                  >{{'group-configuration.error_is_required' | translate}}</mat-error
                >
                <mat-error *ngIf="f.password.errors?.maxlength">{{'group-configuration.error_max_255_characters' | translate}}</mat-error>
              </div>
            </mat-form-field>
          </div>

          <hr />
        </div>

        <!-- Title and description -->
        <div class="mt-3">
          <div class="form-group">
            <strong>{{'group-configuration.change_group_translations_description' | translate}}</strong>
          </div>

          <div class="row">
            <!-- Filter button -->
            <div class="col-sm-6">
              <div>
                <mat-form-field appearance="outline" class="text-width">
                  <mat-label>{{'group-configuration.change_group_title_german' | translate}}</mat-label>
                  <input [attr.id]="'deTitle'" formControlName="deTitle" matInput type="text" />
                  <div *ngIf="submitted && f.deTitle.invalid">
                    <mat-error *ngIf="f.deTitle.errors?.required">{{'group-configuration.error_is_required' | translate}}</mat-error>
                    <mat-error *ngIf="f.deTitle.errors?.maxlength"
                      >{{'group-configuration.error_max_255_characters' | translate}}</mat-error
                    >
                  </div>
                </mat-form-field>
              </div>
              <div>
                <mat-form-field appearance="outline" class="text-width">
                  <mat-label>{{'group-configuration.change_group_description_german' | translate}}</mat-label>
                  <textarea [attr.id]="'deDescription'" formControlName="deDescription" matInput maxlength="255" rows="3"></textarea>
                  <div *ngIf="submitted && f.deDescription.invalid">
                    <mat-error *ngIf="f.deDescription.errors?.required">{{'group-configuration.error_is_required' | translate}}</mat-error>
                    <mat-error *ngIf="f.deDescription.errors?.maxlength"
                      >{{'group-configuration.error_max_255_characters' | translate}}</mat-error
                    >
                  </div>
                </mat-form-field>
              </div>
            </div>

            <div class="col-sm-6">
              <div>
                <mat-form-field appearance="outline" class="text-width">
                  <mat-label>{{'group-configuration.change_group_title_english' | translate}}</mat-label>
                  <input [attr.id]="'enTitle'" formControlName="enTitle" matInput type="text" />
                  <div *ngIf="submitted && f.enTitle.invalid">
                    <mat-error *ngIf="f.enTitle.errors?.required">{{'group-configuration.error_is_required' | translate}}</mat-error>
                    <mat-error *ngIf="f.enTitle.errors?.maxlength"
                      >{{'group-configuration.error_max_255_characters' | translate}}</mat-error
                    >
                  </div>
                </mat-form-field>
              </div>
              <div>
                <mat-form-field appearance="outline" class="text-width">
                  <mat-label>{{'group-configuration.change_group_description_english' | translate}}</mat-label>
                  <textarea [attr.id]="'enDescription'" formControlName="enDescription" matInput maxlength="255" rows="3"></textarea>
                  <div *ngIf="submitted && f.enDescription.invalid">
                    <mat-error *ngIf="f.enDescription.errors?.required">{{'group-configuration.error_is_required' | translate}}</mat-error>
                    <mat-error *ngIf="f.enDescription.errors?.maxlength"
                      >{{'group-configuration.error_max_255_characters' | translate}}</mat-error
                    >
                  </div>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>

        <div class="row text-end mb-1 justify-content-end">
          <div class="col align-self-end">
            <app-button-feedback
              [_setId]="'btnUpdateGroup'"
              [_isDisabled]="isGroupFormInvalid() || isFormNameOrPasswordInvalid()"
              (click)="updateGroup()"
              [_defaultButtonText]="'group-configuration.change_study_submit'"
              [_responseText]="updateGroupResponse | async"
            ></app-button-feedback>
          </div>
        </div>
      </form>
    </div>
  </ng-template>
</div>
