import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appCustomScroll]',
  exportAs: 'appCustomScroll'
})
export class CustomScrollDirective {
  disableBtn;
  top: number;
  offSetHeight: number;
  scrollHeight: number;

  constructor(private elementRef: ElementRef) {
    this.top = this.elementRef.nativeElement.scrollTop;
    this.offSetHeight = this.elementRef.nativeElement.offsetHeight;
    this.scrollHeight = this.elementRef.nativeElement.scrollHeight;
    if (this.top > this.scrollHeight - this.offSetHeight - 100) {
      this.disableBtn = false;
    } else {
      this.disableBtn = this.appCustomScroll;
    }
  }

  @Input() set appCustomScroll(condition: boolean) {
    this.disableBtn = condition;
  }

  private static isScrolledToBottom(top: number, offSetHeight: number, scrollHeight: number): boolean {
    return top > scrollHeight - offSetHeight - 100;
  }

  @HostListener('scroll') onScroll() {
    this.calculateButton();
  }

  @HostListener('resize') onResize() {
    this.calculateButton();
  }

  public isScrollbarVisible(): boolean {
    this.calculateButton();
    if (this.elementRef.nativeElement.scrollHeight > this.elementRef.nativeElement.clientHeight) {
      return this.disableBtn;
    } else {
      return false;
    }
  }

  private calculateButton() {
    this.top = this.elementRef.nativeElement.scrollTop;
    this.offSetHeight = this.elementRef.nativeElement.offsetHeight;
    this.scrollHeight = this.elementRef.nativeElement.scrollHeight;
    this.disableBtn = !CustomScrollDirective.isScrolledToBottom(this.top, this.offSetHeight, this.scrollHeight);
  }
}
