import { HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faEnvelope, faMessage } from '@fortawesome/free-regular-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faPen } from '@fortawesome/free-solid-svg-icons/faPen';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { BehaviorSubject, Observable, filter, take, map, mergeMap, skip, Subscription } from 'rxjs';
import { AccountDeletionRequestInterface } from '../../../models/interface/account-deletion-request.interface';
import { UserInterface } from '../../../models/interface/user.interface';
import { AlertService } from '../../../services/alert/alert.service';
import { HelperDialogService } from '../../../services/helper/helper-dialog/helper-dialog.service';
import { AuthenticationStore } from '../../../store/authentication/component-store/authentication.store';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { Store } from '@ngrx/store';
import { ProfileInterface } from '../../../models/interface/profile.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-card-user',
  templateUrl: './card-user.component.html',
  styleUrls: ['./card-user.component.css'],
  providers: [AuthenticationStore]
})
export class CardUserComponent implements OnInit {
  @Output()
  public emitDelete: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public emitUpdate: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public emitReloadInvitations: EventEmitter<any> = new EventEmitter<any>();

  // Icons
  faCheck = faCheck;
  faTimes = faTimes;
  faPen = faPen;
  faTrash = faTrash;
  faMessage = faMessage;
  faTimesCircle = faTimesCircle;
  faCheckCircle = faCheckCircle;
  faEnvelope = faEnvelope;

  public user: UserInterface;
  public deletionRequests: Array<AccountDeletionRequestInterface> = [];

  public isAdmin = false;
  public hasPendingInvitations = false;

  public userAsPatient = false;

  public resendVerificationResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');
  public resendVerificationResponse$: Observable<any>;

  public profile$: Observable<ProfileInterface>;

  private myUserId: number = null;

  private subscriptions: Subscription[] = [];

  private isModalLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    private router: Router,
    private helperDialogService: HelperDialogService,
    private alertService: AlertService,
    private authenticationStore: AuthenticationStore,
    private store: Store<{
      myProfile: ProfileInterface;
    }>
  ) {
    this.profile$ = store.select('myProfile');
    this.resendVerificationResponse$ = this.authenticationStore.resendVerificationResponse$;
  }

  public get helperDialog() {
    return this.helperDialogService;
  }

  @Input()
  set _user(_user: UserInterface) {
    if (_user) {
      this.user = _user;
    }
  }

  @Input()
  set _deletionRequests(_deletionRequests: Array<AccountDeletionRequestInterface>) {
    if (_deletionRequests) {
      this.deletionRequests = _deletionRequests;
    }
  }

  @Input()
  set _isAdmin(_isAdmin: boolean) {
    if (_isAdmin !== undefined) {
      this.isAdmin = _isAdmin;
    }
  }

  @Input()
  set _hasPendingInvitations(_hasPendingInvitations: boolean) {
    if (_hasPendingInvitations !== undefined) {
      this.hasPendingInvitations = _hasPendingInvitations;
    }
  }

  @Input()
  set _userAsPatient(_userAsPatient: boolean) {
    if (_userAsPatient !== undefined) {
      this.userAsPatient = _userAsPatient;
    }
  }

  ngOnInit(): void {
    // Get user's id
    this.subscriptions.push(
      this.profile$
        .pipe(
          filter(user => user !== null),
          take(1)
        )
        .subscribe((result: ProfileInterface) => {
          this.myUserId = result.id;
        })
    );
  }

  public isUserInRole(user: UserInterface, slug: string): boolean {
    const globalRoles: Array<{ slug: string; description: string }> = user.attributes.roles;
    return globalRoles.length > 0 ? !!globalRoles.find(role => role.slug.toString() === slug) : false;
  }

  public hasDeletionRequest(userId: number): boolean {
    return !!this.deletionRequests.find(
      (request: AccountDeletionRequestInterface) => request.attributes.user_id.toString() === userId.toString()
    );
  }

  public getDeletionRequest(userId: number): AccountDeletionRequestInterface {
    return this.deletionRequests.find(
      (request: AccountDeletionRequestInterface) => request.attributes.user_id.toString() === userId.toString()
    );
  }

  public resendVerificationEmail(email: string): void {
    if (this.resendVerificationResponse.value === 'DEFAULT') {
      this.isModalLoadingSubject.next(true);
      this.resendVerificationResponse.next('LOADING');

      this.authenticationStore.resendVerification(email);
      this.resendVerificationResponse$
        .pipe(
          filter(value => !!value),
          take(1)
        )
        .subscribe((value: any) => {
          if (value instanceof HttpResponse) {
            this.alertService.success('ACCOUNT_RESEND_SUCCEEDED');
            this.resendVerificationResponse.next('SUCCESS');
          } else {
            this.alertService.error('ACCOUNT_RESEND_FAILED');
            this.resendVerificationResponse.next('FAILURE');
          }
          this.isModalLoadingSubject.next(false);
          setTimeout(() => {
            this.resendVerificationResponse.next('DEFAULT');
          }, 2500);
          this.openDialogVerification();
        });
    }
  }

  public openDialogUserUpdate(): void {
    this.helperDialog
      .openDialogUserUpdate(this.user)
      .afterClosed()
      .subscribe(() => {
        this.emitUpdate.emit();
      });
  }

  public openDialogUserDelete(): void {
    this.helperDialog
      .openDialogUserDelete(this.user)
      .afterClosed()
      .subscribe(() => {
        this.emitDelete.emit();
      });
  }

  public openDialogVerification(): void {
    const dialogRef = this.helperDialog.openDialogVerification(this.isModalLoadingSubject);
    const loading = this.isModalLoadingSubject.asObservable().subscribe(value => {
      if (dialogRef && dialogRef.componentInstance) {
        dialogRef.componentInstance.data = { submitted: value };
      }
    });
    dialogRef.afterClosed().pipe(
      map((result: any) => {
        loading.unsubscribe();
      })
    );
  }

  public openDialogAccountRequests(): void {
    const foundDeletionRequest = this.deletionRequests.find(
      (request: AccountDeletionRequestInterface) => request.attributes.user_id.toString() === this.user.toString()
    );
    this.helperDialog
      .openDialogAccountRequests(this.user, foundDeletionRequest)
      .afterClosed()
      .subscribe(() => {});
  }

  public openDialogInvitation(user: UserInterface): void {
    this.helperDialog
      .openDialogPatientInvitationCheck(user, this.myUserId)
      .afterClosed()
      .subscribe(result => {
        this.emitReloadInvitations.emit();
      });
  }

  public openDialogStudyInvite(selectedUser: UserInterface): void {
    this.helperDialog
      .openDialogAccountInterventionInstanceAssignment('patientStudyInvitation', this.myUserId, undefined, selectedUser)
      .afterClosed()
      .subscribe(result => {
        this.emitReloadInvitations.emit();
      });
  }

  public openDialogInstanceCreate(selectedUser: UserInterface): void {
    this.helperDialog
      .openDialogAccountInterventionInstanceAssignment('patientInterventionAssignment', this.myUserId, undefined, selectedUser)
      .afterClosed()
      .subscribe(result => {});
  }

  public openDialogDeleteUnverified(user: UserInterface): void {
    this.helperDialog
      .openDialogUnverifiedAccountDelete(user, this.myUserId)
      .afterClosed()
      .subscribe(result => {
        this.emitDelete.emit();
      });
  }

  public getDetailedPatient(id?: number): void {
    this.router.navigateByUrl(`/patients/${id}`);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
