import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { mergeMap, of, map, tap, switchMap, catchError } from 'rxjs';
import {
  AddDynamicAnswersAction,
  addDynamicAnswersError,
  addDynamicAnswersStore,
  addDynamicAnswersSuccess,
  DynamicAnswersActionTypes,
  UpdateDynamicAnswersAction,
  updateDynamicAnswersStore
} from './dynamic-answers.action';

@Injectable()
export class DynamicAnswersEffects {
  loadDynamicAnswers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DynamicAnswersActionTypes.addDynamicAnswersType),
      map((action: AddDynamicAnswersAction) => action.payload),
      switchMap(payload => {
        const actions = [];
        actions.push(addDynamicAnswersStore({ dynamicAnswers: payload }));
        actions.push(addDynamicAnswersSuccess());
        return actions;
      }),
      catchError(() => of(addDynamicAnswersError()))
    )
  );

  updateDynamicAnswers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DynamicAnswersActionTypes.updateDynamicAnswersType),
      map((action: UpdateDynamicAnswersAction) => action.payload),
      switchMap(payload => of(updateDynamicAnswersStore({ dynamicAnswers: payload })))
    )
  );

  constructor(private actions$: Actions) {}
}
