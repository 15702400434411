/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Inject, Input, OnChanges, OnInit } from '@angular/core';
import { InterventionInterface } from '../../../models/interface/intervention.interface';
import { DiaryInterface } from '../../../models/interface/diary.interface';
import { InterventionConfigurationInterface } from '../../../models/interface/intervention-configuration.interface';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { HelperService } from '../../../services/helper/helper.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-instance-questionnaire-configuration-diary',
  templateUrl: './dialog-instance-questionnaire-configuration-diary.component.html',
  styleUrls: ['./dialog-instance-questionnaire-configuration-diary.component.scss']
})
export class DialogInstanceQuestionnaireConfigurationDiaryComponent implements OnInit, OnChanges {
  public intervention: InterventionInterface;

  public periods = ['week', 'month'];
  public weekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  public weekNum = Array.from(Array(7).keys());

  public numbersOfReminder: number[] = Array.from(Array(100).keys());

  public param;

  // Diaries of intervention
  public diaries: Array<DiaryInterface> = [];

  // Instance creation instanceForm
  public instanceFormType: UntypedFormGroup;

  // Ngx datetimepicker
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';

  arraySchedule: {
    id: number;
    weekday: number;
    hour: number;
    minute: number;
    unlock_date: Date;
  }[];

  private interventionReadOnly: InterventionInterface;
  private defaultConfiguration: {
    questionnaire_configuration: Array<InterventionConfigurationInterface>;
    custom_order: Array<number>;
  };
  private defaultConfigurationReadOnly: {
    questionnaire_configuration: Array<InterventionConfigurationInterface>;
    custom_order: Array<number>;
  };

  constructor(@Inject(MAT_DIALOG_DATA) public data, private formBuilder: UntypedFormBuilder, private helperService: HelperService) {
    // Form type contains searchFilter options
    this.instanceFormType = this.formBuilder.group({
      diary_id: ['', Validators.required],
      period: [0, Validators.required],
      every_schedule: this.formBuilder.array([])
    });
  }

  // Helper function
  public get f() {
    return this.instanceFormType.controls;
  }

  public get helper() {
    return this.helperService;
  }

  public get everySchedule() {
    return this.instanceFormType.get('every_schedule') as UntypedFormArray;
  }

  // Set study and its configuration from parent
  @Input()
  set _intervention(_intervention: InterventionInterface) {
    this.interventionReadOnly = JSON.parse(JSON.stringify(_intervention));
    this.defaultConfigurationReadOnly = JSON.parse(JSON.stringify(_intervention.attributes.default_configuration));

    this.intervention = JSON.parse(JSON.stringify(_intervention));
    this.defaultConfiguration = JSON.parse(JSON.stringify(_intervention.attributes.default_configuration));

    if (this.intervention.attributes.intervention_type === 'accompanied') {
      this.instanceFormType.controls['intervention_type'].setValue('GUIDED', { onlySelf: true });
    }
  }

  @Input()
  set _diaries(_diaries: Array<DiaryInterface>) {
    if (_diaries) {
      this.diaries = _diaries;
    }
  }

  ngOnInit(): void {
    this.arraySchedule = [];
  }

  addItem(item) {
    this.arraySchedule.push(item);
    this.everySchedule.push(
      this.formBuilder.group({
        id: item.id,
        weekday: item.weekday,
        hour: item.hour,
        minute: item.minute,
        unlock_date: item.date
      })
    );
  }

  removeItem() {
    this.arraySchedule.pop();
    this.everySchedule.removeAt(this.everySchedule.length - 1);
  }

  ngOnChanges(): void {}

  recalculateSelection(event) {
    if (event.value > this.arraySchedule.length) {
      while (event.value > this.arraySchedule.length) {
        this.addItem({
          id: 0,
          weekday: 1,
          hour: 15,
          minute: 45,
          unlock_date: new Date().setHours(15, 45)
        });
      }
    } else {
      while (event.value < this.arraySchedule.length) {
        this.removeItem();
      }
    }
  }
}
