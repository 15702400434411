/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { InterventionInterface } from '../../../models/interface/intervention.interface';
import { HelperService } from '../../../services/helper/helper.service';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged, mergeMap, skip, take } from 'rxjs/operators';
import { faList } from '@fortawesome/free-solid-svg-icons/faList';
import { ProfileInterface } from '../../../models/interface/profile.interface';
import { HelperDialogService } from '../../../services/helper/helper-dialog/helper-dialog.service';
import { InterventionStore } from '../../../store/intervention/component-store/intervention.store';

@Component({
  selector: 'app-intervention-review',
  templateUrl: './intervention-review.component.html',
  styleUrls: ['./intervention-review.component.scss'],
  providers: [InterventionStore]
})
export class InterventionReviewComponent implements OnInit, OnDestroy {
  @ViewChild('paginator') paginator;

  // Icons
  faList = faList;

  public isLoading$: Observable<boolean>;

  public initialInterventions: Array<InterventionInterface>;

  // Filter
  public filter = { searchFilter: '', typeOfIntervention: '0' };

  public isCollapse$: Observable<boolean>;

  public interventions$: Observable<Array<InterventionInterface>>;

  public searchText$ = new BehaviorSubject<string>('');

  public pagedInterventions$: Observable<Array<InterventionInterface>>;

  // Translation for study name
  public param = { intervention_name: '...' };
  public resultParam = { result_value: 0 };

  public profile$: Observable<ProfileInterface>;
  public isCollapseSubject = new BehaviorSubject<boolean>(true);

  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private interventionsSubject: BehaviorSubject<Array<InterventionInterface>> = new BehaviorSubject([]);

  private pendingReviewingInterventions$: Observable<Array<InterventionInterface>>;

  private pagedInterventionsSubject: BehaviorSubject<Array<InterventionInterface>> = new BehaviorSubject<Array<InterventionInterface>>([]);

  private subscriptions: Subscription[] = [];

  constructor(
    private helperService: HelperService,
    private helperDialogService: HelperDialogService,
    private router: Router,
    private translateService: TranslateService,
    private interventionStore: InterventionStore
  ) {
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.pagedInterventions$ = this.pagedInterventionsSubject.asObservable();
    this.isCollapse$ = this.isCollapseSubject.asObservable();
    this.interventions$ = this.interventionsSubject.asObservable();
    this.pendingReviewingInterventions$ = this.interventionStore.pendingReviewingInterventions$;
  }

  public get helper() {
    return this.helperService;
  }

  public get helperDialog() {
    return this.helperDialogService;
  }

  ngOnInit(): void {
    this.applyInterventionFilter();

    // Subscription to language change
    this.subscriptions.push(
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
        this.applyInterventionFilter();
      })
    );
  }

  public applyInterventionFilter(): void {
    this.isLoadingSubject.next(true);
    this.subscriptions.push(
      this.reloadInterventionList(true).subscribe((result: any) => {
        this.isLoadingSubject.next(false);
      })
    );
  }

  public reloadInterventionList(setFirst?: boolean): Observable<boolean> {
    const pageIndex = this.paginator?.paginator ? this.paginator?.paginator.pageIndex : 0;
    const pageSize = this.paginator?.paginator ? this.paginator?.paginator.pageSize : 20;
    const formGuidanceType = type =>
      ({
        2: 'accompanied',
        1: 'unaccompanied',
        0: undefined
      }[type]);
    const paramGuidanceType: string = formGuidanceType(this.filter['typeOfIntervention']);

    this.interventionStore.getAllPendingReviewingInterventions({ interventionType: paramGuidanceType });
    return this.pendingReviewingInterventions$.pipe(
      skip(1),
      take(1),
      mergeMap((interventions: Array<InterventionInterface>) => {
        this.initialInterventions = interventions;
        return this.search(this.filter['searchFilter']).pipe(
          mergeMap(() =>
            this.helper.setPagedContent(this.interventionsSubject, this.pagedInterventionsSubject, setFirst, pageIndex, pageSize)
          )
        );
      })
    );
  }

  public updateList(): void {
    this.subscriptions.push(this.reloadInterventionList().subscribe());
  }

  public getDetailedIntervention(id: number): void {
    this.router.navigateByUrl(`/interventions/${id}`);
  }

  public updatePagedInterventions(event: any) {
    if (event) {
      this.pagedInterventionsSubject.next(event);
    }
  }

  public openDialogInterventionTestPublish(intervention: InterventionInterface): void {
    this.helperDialog
      .openDialogInterventionTestPublish(intervention)
      .afterClosed()
      .pipe(mergeMap((value: any) => this.reloadInterventionList()))
      .subscribe(() => {});
  }

  public openDialogInterventionOfficialPublish(intervention: InterventionInterface): void {
    this.helperDialog
      .openDialogInterventionOfficialPublish(intervention)
      .afterClosed()
      .pipe(mergeMap((value: any) => this.reloadInterventionList()))
      .subscribe(() => {});
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  // Reads search value and runs searchFilter
  private search(name: string): Observable<boolean> {
    this.searchText$.next(name);
    return this.searchText$.pipe(
      distinctUntilChanged(),
      mergeMap(() => {
        this.interventionsSubject.next(
          this.helper.filterInterventionsBy(this.initialInterventions, this.filter['searchFilter'].toLowerCase().trim())
        );
        return of(true);
      })
    );
  }
}
