<div class="container-fluid text-center">
  <div *ngIf="(isLoading$ | async) === false; else elseLoading">
    <!-- Group jumbotron -->
    <div class="jumbotron text-center hoverable p-3">
      <div class="card mb-3">
        <div class="row justify-content-end px-3 py-2">
          <div class="col-12 col-sm-6 text-start">
            <button [attr.id]="'btnLinkGroups'" mat-button href="/groups">{{'group-detail.groups' | translate}}</button>
          </div>
          <div class="col-12 col-sm-6 text-end py-1 px-3">
            <div *ngIf="(group.attributes.is_private === 1); then privateBlock else openBlock" class="text-padding"></div>
            <ng-template #privateBlock>
              <span *ngIf="(group.attributes.accesstype === 'invite')">
                <fa-icon [icon]="faEnvelopeOpenText"></fa-icon>
                {{'group-detail.invitations_only' | translate}}
              </span>
              <span *ngIf="(group.attributes.accesstype === 'password')">
                <fa-icon [icon]="faKey"></fa-icon>
                {{'group-detail.password_only' | translate}}
              </span>
              <span *ngIf="(group.attributes.accesstype !== 'password') && (group.attributes.accesstype !== 'invite')">
                {{'group-detail.private' | translate}}
              </span>
            </ng-template>
            <ng-template #openBlock> {{'group-detail.public' | translate}} </ng-template>
            / {{'group-detail.joined' | translate}}
            <fa-icon [icon]="faCheckSquare"></fa-icon>
          </div>
        </div>

        <div class="card-body">
          <h2 class="mb-1">{{((group.attributes.title) ? group.attributes.title : 'group-detail.no_group_title') | translate}}</h2>
          <p>
            <small class="text-muted"
              >{{((group.attributes.name) ? group.attributes.name : 'group-detail.no_group_name') | translate}} (ID - {{group.id}})</small
            >
          </p>

          <div class="col-12 mt-2 mb-2">
            <img
              [src]="helper.getAllMediaSupportPath(group.attributes.picture, group.id)"
              (error)="helper.getFallbackImage($event)"
              class="img-thumbnail img-center"
            />
          </div>
          <p class="card-text text-center">
            {{((group.attributes.description) ? group.attributes.description : 'group-detail.no_group_description') | translate}}
          </p>

          <div class="justify-content-center">
            <mat-divider
              *ngIf="((isOrganisationCollaboratorSubject | async) && group.attributes.organisation_id !== null) || (isManager$ | async) || (isOwner$ | async)"
            ></mat-divider>
            <button
              [attr.id]="'btnLinkOrganisationDetails'"
              *ngIf="(isOrganisationCollaboratorSubject | async) && group.attributes.organisation_id !== null"
              (click)="getDetailedOrganisation(group.attributes.organisation_id)"
              class="spacing-right my-2"
              mat-button
            >
              {{'group-detail.to_organisation' | translate}}
            </button>

            <button
              *ngIf="(isManager$ | async) || (isOwner$ | async)"
              [attr.id]="'btnOpenDialogStudyReport'"
              class="my-2"
              (click)="openDialogStudyReport()"
              mat-button
            >
              <fa-icon [icon]="faFileCsv"></fa-icon>
              {{'study-report.download_study_report' | translate}}
            </button>

            <!--
            <button
              *ngIf="(isManager$ | async) || (isOwner$ | async)"
              [attr.id]="'btnOpenDialogCodebook'"
              class="spacing-right my-2"
              (click)="openDialogCodeBook()"
              mat-button
            >
              {{'codebook.open_codebook' | translate}}
            </button>

            <button
              [attr.id]="'btnGenerateReminderLogsReport'"
              *ngIf="(isManager$ | async) || (isOwner$ | async)"
              [disabled]="(isLoading$ | async)"
              (click)="generateCSVReminderLogsReport()"
              class="mb-1"
              mat-button
            >
              {{'group-detail.csv_reminder_logs_export' | translate}}
            </button>
            -->
          </div>
        </div>
      </div>

      <!-- Card Nav tab -->
      <div *ngIf="group.attributes.type === 'study'; then thenShowStudyNav else elseShowOrganisationNav"></div>
      <ng-template #thenShowStudyNav>
        <mat-tab-group
          (selectedTabChange)="onChangeTab($event)"
          animationDuration="0ms"
          backgroundColor="primary"
          color="accent"
          #matTabGroup
        >
          <mat-tab label="{{'group-detail.interventions' | translate}}"></mat-tab>
          <mat-tab *ngIf="(isECoach$ | async)" label="{{'group-detail.members' | translate}}"></mat-tab>
          <mat-tab label="{{'group-detail.collaborators' | translate}}"></mat-tab>
          <mat-tab *ngIf="(isECoach$ | async)" label="{{'group-detail.instances' | translate}}"></mat-tab>
          <mat-tab *ngIf="(isECoach$ | async)" label="{{'group-detail.tasks' | translate}}"></mat-tab>
          <mat-tab *ngIf="(isECoach$ | async)" label="{{'group-detail.activities' | translate}}"></mat-tab>
          <!-- <mat-tab *ngIf="(isECoach$ | async)" label="{{'group-detail.diaries' | translate}}"></mat-tab> -->
          <mat-tab *ngIf="(isOwner$ | async)" label="{{'group-detail.configurations' | translate}}"></mat-tab>
          <mat-tab *ngIf="(isPublisher$ | async) || (isManager$ | async)" label="{{'group-detail.review' | translate}}"></mat-tab>
          <!-- <mat-tab *ngIf="(isECoach$ | async) && (supportsBuddy$ | async)" label="{{'group-detail.buddies' | translate}}"></mat-tab>-->
        </mat-tab-group>
        <!-- Child components of study-detail -->
        <div class="card">
          <div class="card-body">
            <router-outlet></router-outlet>
          </div>
        </div>
      </ng-template>
      <ng-template #elseShowOrganisationNav>
        <mat-tab-group
          mat-stretch-tabs
          (selectedTabChange)="onChangeTab($event)"
          animationDuration="0ms"
          backgroundColor="primary"
          color="accent"
        >
          <mat-tab label="{{'group-detail.collaborators' | translate}}"></mat-tab>
          <mat-tab *ngIf="isOwner$ | async" label="{{'group-detail.configurations' | translate}}"></mat-tab>
        </mat-tab-group>
        <!-- Child components of study-detail -->
        <div class="card">
          <div class="card-body">
            <router-outlet></router-outlet>
          </div>
        </div>
      </ng-template>
    </div>
  </div>

  <ng-template #elseLoading>
    <app-loader></app-loader>
  </ng-template>
</div>
