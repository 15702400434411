import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { HelperService } from '../../../services/helper/helper.service';

/**
 * Component:
 * Patient page displaying general information on patients;
 * Can be found: {uri}/patients
 */

@Component({
  selector: 'app-patient-overview',
  templateUrl: './patient-overview.component.html',
  styleUrls: ['./patient-overview.component.scss']
})
export class PatientOverviewComponent implements OnInit {
  // Icons
  faInfoCircle = faInfoCircle;

  public showDescription$: Observable<boolean>;
  public showDescriptionSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private helperService: HelperService) {
    this.showDescription$ = this.showDescriptionSubject.asObservable();
  }

  public get helper() {
    return this.helperService;
  }

  ngOnInit() {}
}
