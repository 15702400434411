import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  FormGroupDirective,
  NgForm,
  Validators
} from '@angular/forms';
import { BehaviorSubject, iif, Observable, of, Subscription, throwError } from 'rxjs';
import { mergeMap, skip, take } from 'rxjs/operators';
import { ErrorStateMatcher } from '@angular/material/core';
import { InvitationInterface } from '../../../models/interface/invitation.interface';
import { HelperService } from '../../../services/helper/helper.service';
import { UserInterface } from '../../../models/interface/user.interface';
import { StudyInterface } from '../../../models/interface/study/study.interface';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { StudyActionTypes } from '../../../store/study/study.action';
import { UserActionTypes } from '../../../store/user/user.action';
import { StudyStore } from '../../../store/study/component-store/study.store';
import { HttpResponse } from '@angular/common/http';

/**
 * Component:
 * Patient account creation component displaying a form to insert email and a selection of studies;
 */

// eslint-disable-next-line @angular-eslint/component-class-suffix
class CodeUsedMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && (control.dirty || control.touched));
  }
}
@Component({
  selector: 'app-patient-creation-account',
  templateUrl: './patient-creation-account.component.html',
  styleUrls: ['./patient-creation-account.component.scss'],
  providers: [StudyStore]
})
export class PatientCreationAccountComponent implements OnInit, OnDestroy {
  public isLoading$: Observable<boolean>;

  // Data provided by StudyService
  public studies: Array<StudyInterface> = [];
  public studyList = [];

  // Patient creation studyForm
  public patientForm: UntypedFormGroup;

  // Patients and invitations
  public patients: Array<UserInterface> = [];
  public invitations: Array<InvitationInterface> = [];

  // Form submission
  submitted = false;
  disabled = false;

  public isHiddenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isHidden$: Observable<boolean>;

  public selectedStudiesSubject = new BehaviorSubject([]);
  public selectedStudies$: Observable<any[]>;

  public codeTakenSubject = new BehaviorSubject(false);
  public codeTaken$: Observable<boolean>;
  public studyCode: Array<{ id: number; codeTaken: boolean; code: string }> = [];

  public matcher = new CodeUsedMatcher();

  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private deleteMemberCodeResponse$: Observable<any>;

  private myMembers$: Observable<Array<UserInterface>>;
  private allInvitations$: Observable<Array<InvitationInterface>>;
  private myRegisteredUsers$: Observable<Array<UserInterface>>;

  private subscriptions: Subscription[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private studyStore: StudyStore,
    private helperService: HelperService,
    private actions$: Actions,
    private store: Store<{
      myMembers: Array<UserInterface>;
      allInvitations: Array<InvitationInterface>;
      myRegisteredUsers: Array<UserInterface>;
    }>
  ) {
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.isHidden$ = this.isHiddenSubject.asObservable();
    this.selectedStudies$ = this.selectedStudiesSubject.asObservable();
    this.codeTaken$ = this.codeTakenSubject.asObservable();
    this.patientForm = this.formBuilder.group({
      // name: ['', [Validators.required, Validators.maxLength(255), Validators.minLength(1)]],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(
            // eslint-disable-next-line max-len
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
        ]
      ],
      studyList: this.formBuilder.array([])
    });
    this.deleteMemberCodeResponse$ = this.studyStore.deleteMemberCodeResponse$;
    this.myMembers$ = this.store.select('myMembers');
    this.allInvitations$ = this.store.select('allInvitations');
    this.myRegisteredUsers$ = this.store.select('myRegisteredUsers');
  }

  public get f(): UntypedFormGroup {
    return this.patientForm;
  }

  public get fStudyList(): UntypedFormArray {
    return this.patientForm.get('studyList') as UntypedFormArray;
  }

  public get helper() {
    return this.helperService;
  }

  // Set study and its configuration from parent
  @Input() hide(_hide: boolean) {
    if (_hide !== undefined) {
      this.isHiddenSubject.next(_hide);
    }
  }

  ngOnInit(): void {
    this.store.dispatch({
      type: StudyActionTypes.getCollaboratingStudiesType,
      payload: { type: 'study', include: 'roles', role: 'study.owner,study.ecoachmanager,study.ecoach' }
    });
    this.subscriptions.push(
      this.actions$
        .pipe(
          ofType(StudyActionTypes.getCollaboratingStudiesSuccessType, StudyActionTypes.getCollaboratingStudiesErrorType),
          take(1),
          mergeMap((result: any) =>
            iif(() => result.type === StudyActionTypes.getCollaboratingStudiesSuccessType, of(result), throwError(result))
          )
        )
        .subscribe(
          result => {
            this.studies = result['response']['body']['data'];
            // Exclude studies if user is neither owner, ecoachmanager nor ecoach
            this.studies = this.studies.filter((study: StudyInterface) => study.relationships.roles.data.length > 0);
            this.studyList = this.studies;
            this.addCheckboxes();
            this.isLoadingSubject.next(false);
          },
          error => {
            console.error(error);
            this.isLoadingSubject.next(false);
          }
        )
    );

    // My patients and pending
    this.store.dispatch({
      type: UserActionTypes.getMyMembersType,
      payload: {}
    });
    this.subscriptions.push(
      this.myMembers$
        .pipe(
          mergeMap(result => {
            this.patients = result;
            this.store.dispatch({
              type: UserActionTypes.getUsersRegisteredByEcoachType,
              payload: {}
            });
            return this.myRegisteredUsers$.pipe(skip(1), take(1));
          }),
          mergeMap(result => {
            // Including non-verified patient accounts
            const registeredUsers = result;
            this.patients = this.patients.concat(registeredUsers);
            if (this.patients.length !== 0) {
              this.patients = this.patients.reduce(
                (unique: Array<UserInterface>, item: UserInterface) =>
                  unique.find(user => user.id.toString() === item.id.toString()) ? unique : [...unique, item],
                []
              );
            }
            this.store.dispatch({
              type: UserActionTypes.getAllInvitationsOfEcoachMembersType,
              payload: {}
            });
            return this.allInvitations$.pipe(skip(1), take(1));
          })
        )
        .subscribe(result => {
          this.invitations = result;
        })
    );
  }

  public checkIfOneSelected(): boolean {
    return (
      this.patientForm
        .get('studyList')
        .value.map(val => (val.checked === null ? false : val.checked))
        .reduce((val, curr) => val || curr) && this.patientForm.get('studyList').value.length > 0
    );
  }

  public codeUsed(studyId: number): boolean {
    const found = this.studyCode.find(
      (taken: { id: number; codeTaken: boolean; code: string }) => taken.id.toString() === studyId.toString() && taken.codeTaken
    );
    return found ? found.codeTaken : false;
  }

  public codeUsedString(code: string): boolean {
    const found = this.studyCode.find(
      (taken: { id: number; codeTaken: boolean; code: string }) => taken.id.toString() === code.toString() && taken.codeTaken
    );
    return found ? found.codeTaken : false;
  }

  public codeUsedInPendingInvitation(studyId: number): UserInterface {
    const found: { id: number; codeTaken: boolean; code: string } = this.studyCode.find(
      (taken: { id: number; codeTaken: boolean; code: string }) => taken.id.toString() === studyId.toString() && taken.codeTaken
    );
    const foundInvitation: InvitationInterface = this.invitations.find((invitation: InvitationInterface) => {
      if (invitation.attributes.code !== null) {
        return invitation.attributes.code.toString() === found.code.toString();
      } else {
        return false;
      }
    });
    if (foundInvitation) {
      const foundUser: UserInterface = this.patients.find(
        (user: UserInterface) => user.attributes.email === foundInvitation.attributes.email
      );
      return foundUser ? foundUser : null;
    } else {
      return null;
    }
  }

  public removeCodeFromPendingInvitation(studyId: number, user: UserInterface): void {
    this.studyStore.deleteMemberCodeOfPendingInvitation({ studyId, userId: user.id });
    this.deleteMemberCodeResponse$
      .pipe(
        skip(1),
        take(1),
        mergeMap((result: any) => iif(() => result instanceof HttpResponse, of(result), throwError(result)))
      )
      .subscribe(
        (result: any) => {
          this.studyCode = this.studyCode.map((taken: { id: number; codeTaken: boolean; code: string }) => {
            if (taken.id.toString() === studyId.toString() && taken.codeTaken) {
              return { id: taken.id, codeTaken: false, code: taken.code };
            } else {
              return taken;
            }
          });
        },
        () => {
          this.studyCode = this.studyCode.map((taken: { id: number; codeTaken: boolean; code: string }) => {
            if (taken.id.toString() === studyId.toString() && taken.codeTaken) {
              return { id: taken.id, codeTaken: false, code: taken.code };
            } else {
              return taken;
            }
          });
        }
      );
  }

  public getIncludedRolesOfStudy(studyId: number): any {
    const found: StudyInterface = this.helper.findArrObjById(studyId, this.studies);
    return found.relationships?.roles?.data;
  }

  ngOnDestroy(): void {}

  // Adding checkboxes on userForm control
  private addCheckboxes(): void {
    this.studyList.map((o: StudyInterface) => {
      (this.patientForm.controls.studyList as UntypedFormArray).push(
        this.formBuilder.group({
          checked: [false],
          code: [null]
        })
      );
      this.studyCode.push({ id: o.id, codeTaken: false, code: null });
    });
  }
}
