import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { catchError, EMPTY, forkJoin, Observable, switchMap, tap, throwError } from 'rxjs';
import { LessonInterface } from '../../../models/interface/lesson.interface';
import { LessonQuestionnaireService } from '../../../services/api/lesson-questionnaire/lesson-questionnaire.service';

export interface QuestionnairesState {
  questionnaire: LessonInterface | null;
  questionnairesOfStudy: Array<LessonInterface>;
  elementsOfQuestionnaire: Array<any>;
  questionnairesOfIntervention: Array<LessonInterface>;
  questionnairesOfMultipleInterventions: Array<LessonInterface[]>;
}

@Injectable()
export class QuestionnaireStore extends ComponentStore<QuestionnairesState> {
  readonly questionnaire$: Observable<LessonInterface> = this.select(state => state.questionnaire, {
    debounce: true
  });
  readonly questionnairesOfStudy$: Observable<Array<LessonInterface>> = this.select(state => state.questionnairesOfStudy, {
    debounce: true
  });
  readonly elementsOfQuestionnaire$: Observable<Array<any>> = this.select(state => state.elementsOfQuestionnaire, {
    debounce: true
  });
  readonly questionnairesOfIntervention$: Observable<Array<LessonInterface>> = this.select(state => state.questionnairesOfIntervention, {
    debounce: true
  });
  readonly questionnairesOfMultipleInterventions$: Observable<Array<LessonInterface[]>> = this.select(
    state => state.questionnairesOfMultipleInterventions,
    {
      debounce: true
    }
  );

  readonly updateLessonQuestionnaireState = this.updater(
    (
      state,
      payload: {
        questionnaire?: LessonInterface | null;
        questionnairesOfStudy?: Array<LessonInterface>;
        elementsOfQuestionnaire?: Array<any>;
        questionnairesOfIntervention?: Array<LessonInterface>;
        questionnairesOfMultipleInterventions?: Array<LessonInterface[]>;
      }
    ) => ({
      questionnaire: payload.questionnaire ? payload.questionnaire : state.questionnaire,
      questionnairesOfStudy: payload.questionnairesOfStudy ? payload.questionnairesOfStudy : state.questionnairesOfStudy,
      elementsOfQuestionnaire: payload.elementsOfQuestionnaire ? payload.elementsOfQuestionnaire : state.elementsOfQuestionnaire,
      questionnairesOfIntervention: payload.questionnairesOfIntervention
        ? payload.questionnairesOfIntervention
        : state.questionnairesOfIntervention,
      questionnairesOfMultipleInterventions: payload.questionnairesOfMultipleInterventions
        ? payload.questionnairesOfMultipleInterventions
        : state.questionnairesOfMultipleInterventions
    })
  );

  readonly getQuestionnaireDetails = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) => {
        this.updateLessonQuestionnaireState({ questionnaire: null });
        return this.lessonQuestionnaireService.getQuestionnaireDetails(payload.questionnaireId).pipe(
          tap({
            next: (result: any) => this.updateLessonQuestionnaireState({ questionnaire: result.body.data }),
            error: e => throwError(e)
          }),
          catchError(error => EMPTY)
        );
      })
    )
  );

  readonly getQuestionnairesStudy = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) =>
        this.lessonQuestionnaireService.getQuestionnairesStudy(payload.studyId).pipe(
          tap({
            next: (result: any) => this.updateLessonQuestionnaireState({ questionnairesOfStudy: result.body.data }),
            error: e => throwError(e)
          }),
          catchError(error => EMPTY)
        )
      )
    )
  );

  readonly getElementsOfQuestionnaire = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) =>
        this.lessonQuestionnaireService.getElementsOfQuestionnaire(payload.questionnaireId).pipe(
          tap({
            next: (result: any) => this.updateLessonQuestionnaireState({ elementsOfQuestionnaire: result.body.data.attributes.elements }),
            error: e => throwError(e)
          }),
          catchError(error => EMPTY)
        )
      )
    )
  );

  readonly getQuestionnairesIntervention = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) =>
        this.lessonQuestionnaireService.getAllQuestionnairesOfIntervention(payload.interventionId, payload.isActive).pipe(
          tap({
            next: (result: any) => this.updateLessonQuestionnaireState({ questionnairesOfIntervention: result.body.data }),
            error: e => throwError(e)
          }),
          catchError(error => EMPTY)
        )
      )
    )
  );

  readonly getQuestionnairesOfMultipleInterventions = this.effect((payloads$: Observable<Array<any>>) =>
    payloads$.pipe(
      switchMap((payloads: Array<any>) => {
        const reqs: Array<Observable<any>> = [];
        payloads.forEach(req => {
          reqs.push(this.lessonQuestionnaireService.getAllQuestionnairesOfIntervention(req.interventionId));
        });
        return forkJoin(reqs).pipe(
          tap({
            next: (results: any) => {
              const tempQuestionnaires = [];
              const responses: Array<HttpResponse<any>> = results;
              responses.forEach((response: HttpResponse<any>) => {
                tempQuestionnaires.push(response.body.data);
              });
              this.updateLessonQuestionnaireState({ questionnairesOfMultipleInterventions: tempQuestionnaires });
            },
            error: e => throwError(e)
          }),
          catchError(error => EMPTY)
        );
      })
    )
  );

  constructor(private readonly lessonQuestionnaireService: LessonQuestionnaireService) {
    super({
      questionnaire: null,
      questionnairesOfStudy: [],
      elementsOfQuestionnaire: [],
      questionnairesOfIntervention: [],
      questionnairesOfMultipleInterventions: []
    });
  }
}
