/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, OnDestroy, OnInit, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, mergeMap, Observable, skip, Subscription, take } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { DiaryAnswersheetsDetailComponent } from '../diary-answersheets-detail/diary-answersheets-detail.component';
import { AnswersheetInterface } from '../../../models/interface/answersheet.interface';
import { HelperService } from '../../../services/helper/helper.service';
import { DiaryInterface } from '../../../models/interface/diary.interface';
import { DiaryStore } from '../../../store/diary/component-store/diary.store';
import { ActivityInterface } from 'src/app/models/interface/activity.interface';
import { HelperActivityService } from 'src/app/services/helper/helper-activity/helper-activity.service';
import { PageEvent } from '@angular/material/paginator';

/**
 * Component:
 * Displays a diary and its answer sheets;
 */

@Component({
  selector: 'app-diary-answersheets',
  templateUrl: './diary-answersheets.component.html',
  styleUrls: ['./diary-answersheets.component.scss'],
  providers: [DiaryStore]
})
export class DiaryAnswersheetsComponent implements OnInit, OnDestroy {
  @ViewChild('answersheetsDetailsPreviewContainer', { read: ViewContainerRef }) answersheetsDetailsPreviewContainer: ViewContainerRef;

  public isLoading$: Observable<boolean>;
  public hasDiaryEntries$: Observable<boolean>;
  public hasDiaryActivities$: Observable<boolean>;

  public diaryAnswersheets: Array<AnswersheetInterface> = [];
  public answersheetsOfDiary$: Observable<Array<AnswersheetInterface>>;
  public diaryActivities$: Observable<Array<ActivityInterface>>;
  public selected: UntypedFormControl = new UntypedFormControl(0);

  public activitiesSubject: BehaviorSubject<Array<ActivityInterface>> = new BehaviorSubject<Array<ActivityInterface>>([]);
  public pagedActivitySubject: BehaviorSubject<Array<ActivityInterface>> = new BehaviorSubject<Array<ActivityInterface>>([]);
  public pagedActivity$: Observable<Array<ActivityInterface>> = this.pagedActivitySubject.asObservable();

  private diaryInstanceId: number;
  private diary: DiaryInterface;

  private isLoadingSubject = new BehaviorSubject<boolean>(true);
  private hasDiaryEntriesSubject = new BehaviorSubject<boolean>(false);
  private hasDiaryActivitiesSubject = new BehaviorSubject<boolean>(false);

  private subscriptions: Subscription[] = [];

  constructor(
    private diaryStore: DiaryStore,
    private translateService: TranslateService,
    private helperService: HelperService,
    private helperActivityService: HelperActivityService
  ) {
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.hasDiaryEntries$ = this.hasDiaryEntriesSubject.asObservable();
    this.hasDiaryActivities$ = this.hasDiaryActivitiesSubject.asObservable();
    this.answersheetsOfDiary$ = this.diaryStore.answersheetsOfDiary$;
    this.diaryActivities$ = this.diaryStore.diaryActivities$;
  }

  public get helper() {
    return this.helperService;
  }

  public get helperActivity() {
    return this.helperActivityService;
  }

  @Input()
  set _diary_instance_id(_diaryInstanceId: number) {
    this.diaryInstanceId = _diaryInstanceId;
  }

  @Input()
  set _diary(_diary: DiaryInterface) {
    this.diary = _diary;
  }

  ngOnInit(): void {
    this.diaryStore.getActivitiesOfDiaryInstance({
      diaryInstanceId: this.diaryInstanceId
    });
    this.subscriptions.push(
      this.diaryActivities$.pipe(skip(1), take(1)).subscribe((res: any) => {
        this.activitiesSubject.next(this.helper.getActivityByNewest(res));
        this.pagedActivitySubject.next(this.activitiesSubject.value.slice(0, 20));
      })
    );

    this.diaryStore.getAnswersheetsOfDiary({ diaryId: this.diary.id, diaryInstanceId: this.diaryInstanceId });
    this.subscriptions.push(
      this.answersheetsOfDiary$.subscribe((result: any) => {
        this.loadAnswersheetPreview(result);
      })
    );

    this.subscriptions.push(
      this.translateService.onLangChange
        .pipe(
          mergeMap(() => {
            this.diaryStore.getAnswersheetsOfDiary({ diaryId: this.diary.id, diaryInstanceId: this.diaryInstanceId });
            return this.answersheetsOfDiary$.pipe(skip(1), take(1));
          })
        )
        .subscribe((result: any) => {
          this.loadAnswersheetPreview(result);
        })
    );
  }

  public loadAnswersheetPreview(result) {
    this.diaryAnswersheets = this.helper.getAnswersheetsByNewest(result);
    if (this.diaryAnswersheets.length > 0) {
      this.hasDiaryEntriesSubject.next(true);
      this.initializeAnswersheetsPreviewComponent(DiaryAnswersheetsDetailComponent, this.diaryAnswersheets[0].id);
    } else {
      this.diaryAnswersheets = [];
    }
    this.isLoadingSubject.next(false);
    this.selected.setValue(0);
  }

  public initializeAnswersheetsPreviewComponent(componentClass: Type<any>, answersheetId: number): void {
    if (this.answersheetsDetailsPreviewContainer) {
      this.answersheetsDetailsPreviewContainer.clear();
      const component: DiaryAnswersheetsDetailComponent = this.answersheetsDetailsPreviewContainer.createComponent(componentClass).instance;
      component._answersheet_id = answersheetId;
      component._study_id = this.diary.attributes.study_id;
      component._diaryBackground = this.diary.attributes.page_color;
    }
  }

  public getSelectedIndexDiaries(event: any): void {
    if (this.diaryAnswersheets.length > 0 && event < this.diaryAnswersheets.length) {
      this.initializeAnswersheetsPreviewComponent(DiaryAnswersheetsDetailComponent, this.diaryAnswersheets[event].id);
    }
  }

  public onPageChangeActivity(event: PageEvent): void {
    const startIndex = event.pageIndex * event.pageSize;
    const endIndex =
      startIndex + event.pageSize > this.activitiesSubject.value.length ? this.activitiesSubject.value.length : startIndex + event.pageSize;
    this.pagedActivitySubject.next(this.activitiesSubject.value.slice(startIndex, endIndex));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
