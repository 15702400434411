<div class="row justify-content-center mt-1">
  <div class="col-12 mb-2">
    <div *ngIf="((items$ | async)?.length === 0) && (isLoading$ | async) === false; else elseShowPagination">
      <p *ngIf="showEmptySubject | async">{{'patient-study.no_search_results' | translate}}</p>
    </div>
    <ng-template #elseShowPagination>
      <mat-paginator
        #paginator
        (page)="onPageChange($event)"
        [showFirstLastButtons]="true"
        [length]="(items$ | async)?.length"
        [pageSizeOptions]="[10, 20, 50]"
        [pageSize]="20"
      >
      </mat-paginator>
    </ng-template>
  </div>
</div>
