import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { faPen } from '@fortawesome/free-solid-svg-icons/faPen';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { OrganisationInterface } from '../../../models/interface/organisation/organisation.interface';
import { UserInterface } from '../../../models/interface/user.interface';
import { HelperDialogService } from '../../../services/helper/helper-dialog/helper-dialog.service';
import { HelperService } from '../../../services/helper/helper.service';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons/faCheckSquare';
import { Router } from '@angular/router';

@Component({
  selector: 'app-card-organisation',
  templateUrl: './card-organisation.component.html',
  styleUrls: ['./card-organisation.component.css']
})
export class CardOrganisationComponent implements OnInit, OnDestroy {
  @Output()
  public emitDelete: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public emitUpdate: EventEmitter<any> = new EventEmitter<any>();

  // Icons
  faPen = faPen;
  faTrash = faTrash;
  faStar = faStar;
  faInfoCircle = faInfoCircle;
  faCheckSquare = faCheckSquare;

  public organisation: OrganisationInterface;
  public includedUsers: Array<UserInterface> = [];

  public isCollab = false;
  public isAdmin$: Observable<boolean>;

  private isAdminSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private subscriptions: Subscription[] = [];

  constructor(private router: Router, private helperService: HelperService, private helperDialogService: HelperDialogService) {
    this.isAdmin$ = this.isAdminSubject.asObservable();
  }

  public get helper() {
    return this.helperService;
  }

  public get helperDialog() {
    return this.helperDialogService;
  }

  @Input()
  set _organisation(_organisation: OrganisationInterface) {
    if (_organisation) {
      this.organisation = _organisation;
    }
  }

  @Input()
  set _includedUsers(_includedUsers: Array<UserInterface>) {
    if (_includedUsers) {
      this.includedUsers = _includedUsers;
    }
  }

  @Input()
  set _isAdmin(_isAdmin: boolean) {
    if (_isAdmin !== undefined) {
      this.isAdminSubject.next(_isAdmin);
    }
  }

  @Input()
  set _isCollab(_isCollab: boolean) {
    if (_isCollab !== undefined) {
      this.isCollab = _isCollab;
    }
  }

  ngOnInit(): void {}

  public openDialogOrganisationDetail(): void {
    this.helperDialog
      .openDialogOrganisationDetail(this.organisation)
      .afterClosed()
      .subscribe(() => {});
  }

  public openDialogOrganisationUpdate(): void {
    this.helperDialog
      .openDialogOrganisationUpdate(this.organisation)
      .afterClosed()
      .subscribe(() => {
        this.emitUpdate.emit();
      });
  }

  public openDialogOrganisationDelete(): void {
    this.helperDialog
      .openDialogOrganisationDelete(this.organisation)
      .afterClosed()
      .subscribe(() => {
        this.emitDelete.emit();
      });
  }

  public getDetailedOrganisation(id: number): void {
    this.router.navigateByUrl(`organisations/${id}/studies`);
  }

  public getOwnersOfOrganisation(organisation: OrganisationInterface): Array<UserInterface> {
    return organisation.relationships.owners.data;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
