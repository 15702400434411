/* eslint-disable @typescript-eslint/naming-convention */
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, Observable, Subscription } from 'rxjs';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { ElementBlockInterface } from '../../../../models/interface/elements/element-block.interface';
import { AnswersheetAnswerInterface } from '../../../../models/interface/answersheet-answer.interface';
import { ConditionalblockInterface } from '../../../../models/interface/condition/conditionalblock.interface';
import { ElementInterface } from '../../../../models/interface/elements/element.interface';
import { EvaluationService } from '../../../../services/evaluation/evaluation.service';
import { Store } from '@ngrx/store';

/**
 * Component:
 * Represents blockopens element
 */

@Component({
  selector: 'app-elements-page',
  templateUrl: './elements-page.component.html',
  styleUrls: ['./elements-page.component.scss']
})
export class ElementsPageComponent implements OnInit, OnDestroy {
  @Output()
  emitting = new EventEmitter<Array<AnswersheetAnswerInterface>>();

  // Icon
  faInfoCircle = faInfoCircle;

  public answers: Array<AnswersheetAnswerInterface> = [];

  public condition: Array<ConditionalblockInterface> = [];
  public evaluation$: Observable<boolean>;

  public pageNumber: number;

  private element: ElementInterface;
  private dynamicAnswers: Array<AnswersheetAnswerInterface> = [];
  private dynamicAnswers$: Observable<Array<AnswersheetAnswerInterface>>;

  private expressionSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private evaluationSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private iteration: number;
  private nested: number;

  private subscriptions: Subscription[] = [];

  constructor(
    private evaluationService: EvaluationService,
    private store: Store<{
      dynamicAnswers: Array<AnswersheetAnswerInterface>;
    }>
  ) {
    this.dynamicAnswers$ = store.select('dynamicAnswers');
    this.evaluation$ = this.evaluationSubject.asObservable();
  }

  @Input()
  set _element(_element: ElementBlockInterface | ElementInterface) {
    this.element = _element;
  }

  @Input()
  set _answers(_answers: Array<AnswersheetAnswerInterface>) {
    if (_answers) {
      this.answers = _answers;
    }
  }

  // Block condition
  @Input()
  set _condition(_condition: Array<ConditionalblockInterface>) {
    this.condition = _condition;
  }

  @Input()
  set _nested(_nested) {
    if (_nested !== undefined) {
      this.nested = _nested;
    }
  }

  @Input()
  set _iteration(_iteration) {
    if (_iteration !== undefined && this.nested !== undefined) {
      this.iteration = _iteration + this.nested;
    } else {
      if (this.nested !== undefined) {
        this.iteration = this.nested;
      }
    }
  }

  @Input()
  set _page(_page) {
    if (_page !== undefined && this.iteration !== undefined) {
      this.pageNumber = _page + this.iteration;
    } else {
      this.pageNumber = _page + 1;
    }
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.dynamicAnswers$.pipe(distinctUntilChanged()).subscribe(dynamicAnswers => {
        this.dynamicAnswers = dynamicAnswers;
        this.evaluationService.evaluateExpression(
          this.expressionSubject,
          this.evaluationSubject,
          this.condition,
          this.element.position,
          this.answers,
          this.dynamicAnswers
        );
      })
    );
    this.iteration = this.nested;
  }

  public translation(): { locale: string; progressbar_text: string } {
    return this.findTranslation();
  }

  public getPosition(): number {
    return this.element.position;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  private findTranslation(): any {
    if (this.element.translations) {
      const _content = this.element.translations.find(translation => translation.locale === localStorage.getItem('lessonLocale'));
      return _content ? _content : this.element.translations[0];
    }
  }
}
