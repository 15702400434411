<button [attr.id]="'btnDialogClose'" mat-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title>{{'downloads.all_reports' | translate}}</h1>

<div class="content-height" mat-dialog-content>
  <p>{{'instance-report.list_of_reports' | translate}}</p>
  <button [attr.id]="'btnLoadJobResults'" mat-button (click)="loadJobResults()">
    <fa-icon [icon]="faRedo"></fa-icon>
    {{'instance-report.reload_reports' | translate}}
  </button>

  <mat-tab-group mat-align-tabs="center">
    <mat-tab label="{{'downloads.instance_reports' | translate}}">
      <div class="container text-start">
        <br />

        <mat-list>
          <div *ngIf="(pagedJobInstances$ | async)?.length > 0; then thenShowInstanceReports else elseNoInstanceReports"></div>
          <ng-template #thenShowInstanceReports>
            <app-pagination-list
              #paginator
              (emitPageChange)="updatePagedJobInstances($event)"
              [_isLoading]="isLoading$ | async"
              [_items]="jobInstances$ | async"
            ></app-pagination-list>

            <mat-list-item [attr.id]="'jobResult_' + result.id" *ngFor="let result of (pagedJobInstances$ | async); trackBy: trackByJobId">
              <fa-icon matListItemIcon [icon]="faFilePdf"></fa-icon>
              <div matListItemTitle>{{result.attributes.result_id}}</div>
              <div matListItemLine><fa-icon [icon]="faClock"></fa-icon> {{helper.localizeDateTimestamp(result.attributes.created_at)}}</div>
              <div matListItemMeta>
                <div *ngIf="result.attributes.finished === 1 && result.attributes.successful === 0" mat-line>
                  <span class="text-danger">
                    <fa-icon [icon]="faTimes"></fa-icon>
                    {{'instance-report.failed' | translate}}
                  </span>
                </div>
                <div *ngIf="result.attributes.finished === 0" mat-line>
                  <span class="text-info">
                    <fa-icon [icon]="faEllipsisH"></fa-icon>
                    {{'instance-report.pending' | translate}}
                  </span>
                </div>
                <app-jobresult-download
                  [attr.id]="'jobResultDownload_' + result.id"
                  *ngIf="result.attributes.finished === 1 && result.attributes.successful === 1"
                  [_result]="result"
                ></app-jobresult-download>
              </div>
            </mat-list-item>
          </ng-template>
          <ng-template #elseNoInstanceReports>
            <p class="text-center">{{'instance-report.no_reports' | translate}}</p>
          </ng-template>
        </mat-list>
      </div>
    </mat-tab>
    <mat-tab label="{{'downloads.answersheet_reports' | translate}}">
      <div class="container text-start">
        <br />
        <mat-list>
          <div *ngIf="(pagedJobAnswersheets$ | async)?.length > 0; then thenShowAnswersheetReports else elseNoAnswersheetReports"></div>
          <ng-template #thenShowAnswersheetReports>
            <app-pagination-list
              #paginator
              (emitPageChange)="updatePagedJobAnswersheets($event)"
              [_isLoading]="isLoading$ | async"
              [_items]="jobAnswersheets$ | async"
            ></app-pagination-list>
            <mat-list-item
              [attr.id]="'jobResult_' + result.id"
              *ngFor="let result of (pagedJobAnswersheets$ | async); trackBy: trackByJobId"
            >
              <fa-icon matListItemIcon [icon]="faFilePdf"></fa-icon>
              <div matListItemTitle>{{result.attributes.result_id}}</div>
              <div matListItemLine><fa-icon [icon]="faClock"></fa-icon> {{helper.localizeDateTimestamp(result.attributes.created_at)}}</div>
              <div matListItemMeta>
                <div *ngIf="result.attributes.finished === 1 && result.attributes.successful === 0" mat-line>
                  <span class="text-danger">
                    <fa-icon [icon]="faTimes"></fa-icon>
                    {{'instance-report.failed' | translate}}
                  </span>
                </div>
                <div *ngIf="result.attributes.finished === 0" mat-line>
                  <span class="text-info">
                    <fa-icon [icon]="faEllipsisH"></fa-icon>
                    {{'instance-report.pending' | translate}}
                  </span>
                </div>
                <app-jobresult-download
                  [attr.id]="'jobResultDownload_' + result.id"
                  *ngIf="result.attributes.finished === 1 && result.attributes.successful === 1"
                  [_result]="result"
                ></app-jobresult-download>
              </div>
            </mat-list-item>
          </ng-template>
          <ng-template #elseNoAnswersheetReports>
            <p class="text-center">{{'instance-report.no_reports' | translate}}</p>
          </ng-template>
        </mat-list>
      </div>
    </mat-tab>
    <mat-tab label="{{'downloads.study_reports' | translate}}">
      <div class="container text-start">
        <br />
        <mat-list>
          <div *ngIf="(pagedJobStudies$ | async)?.length > 0; then thenShowStudyReports else elseNoStudyReports"></div>
          <ng-template #thenShowStudyReports>
            <app-pagination-list
              #paginator
              (emitPageChange)="updatePagedJobStudies($event)"
              [_isLoading]="isLoading$ | async"
              [_items]="jobStudies$ | async"
            ></app-pagination-list>

            <mat-list-item [attr.id]="'jobResult_' + result.id" *ngFor="let result of (pagedJobStudies$ | async); trackBy: trackByJobId">
              <fa-icon matListItemIcon [icon]="faFilePdf"></fa-icon>
              <div matListItemTitle>{{result.attributes.result_id}}</div>
              <div matListItemLine><fa-icon [icon]="faClock"></fa-icon> {{helper.localizeDateTimestamp(result.attributes.created_at)}}</div>
              <div matListItemMeta>
                <div *ngIf="result.attributes.finished === 1 && result.attributes.successful === 0" mat-line>
                  <span class="text-danger">
                    <fa-icon [icon]="faTimes"></fa-icon>
                    {{'instance-report.failed' | translate}}
                  </span>
                </div>
                <div *ngIf="result.attributes.finished === 0" mat-line>
                  <span class="text-info">
                    <fa-icon [icon]="faEllipsisH"></fa-icon>
                    {{'instance-report.pending' | translate}}
                  </span>
                </div>
                <app-jobresult-download
                  [attr.id]="'jobResultDownload_' + result.id"
                  *ngIf="result.attributes.finished === 1 && result.attributes.successful === 1"
                  [_result]="result"
                ></app-jobresult-download>
              </div>
            </mat-list-item>
          </ng-template>
          <ng-template #elseNoStudyReports>
            <p class="text-center">{{'instance-report.no_reports' | translate}}</p>
          </ng-template>
        </mat-list>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
