import { createReducer, on } from '@ngrx/store';
import { InterventionInstanceReminderInterface } from '../../models/interface/intervention-instance-reminder.interface';
import {
  addAllInterventionInstanceRemindersStore,
  removeAllInterventionInstanceRemindersStore,
  updateAllInterventionInstanceRemindersStore
} from './reminder.action';

export const initialState: Array<InterventionInstanceReminderInterface> = [];

export const allInterventionInstanceRemindersReducer = createReducer(
  initialState,
  on(addAllInterventionInstanceRemindersStore, (state, { allInterventionInstanceReminders }) => allInterventionInstanceReminders),
  on(updateAllInterventionInstanceRemindersStore, (state, { allInterventionInstanceReminders }) => allInterventionInstanceReminders),
  on(removeAllInterventionInstanceRemindersStore, (state, {}) => [])
);
