<div class="container-fluid">
  <!-- Update instance -->
  <div>
    <div class="container text-start">
      <br />

      <!-- InstanceFormType - Contains studyForm for instance creation -->
      <form [formGroup]="instanceFormType">
        <!-- Patient -->
        <div class="row my-2">
          <label class="col-12 col-md-6"><strong>{{'instance-update-form.form_update_instance_patient' | translate}}:</strong></label>
          <div class="col-12 col-md-6 text-start">
            {{helper.findArrObjById(instance.attributes.patient_id, studyMembers) !== null ?
            helper.getCodeNameEmail(instance.attributes.patient_id, studyMembers, instance.attributes.study_id) :
            ('instance-update-form.non_verified_patient' | translate)}}
          </div>
        </div>

        <!-- Collaborator -->
        <div *ngIf="(fCollaboratorList.length > 1) && (isManager$ | async)" class="my-3">
          <!-- Description -->
          <div class="form-group">
            <p class="text-info" [innerHTML]="'instance-update-form.form_update_instance_description' | translate"></p>
          </div>

          <div class="form-group row">
            <label [attr.for]="'collaboratorSelectUpdate' + instance.id" class="col-12 col-lg-6 mb-2"
              ><strong>{{'instance-update-form.form_update_instance_additional_ecoaches' | translate}}</strong></label
            >
            <div class="col-12 col-lg-6">
              <div class="card outline fixHeight overflowList">
                <div class="card-body">
                  <div *ngFor="let collaborator of fCollaboratorList.controls; let i = index" formArrayName="collaboratorList">
                    <div class="custom-control custom-checkbox mb-3">
                      <mat-checkbox
                        [attr.value]="selectableCollaborators[i]"
                        [attr.id]="'checkboxCollaborator_' + selectableCollaborators[i].id"
                        [formControlName]="i"
                      >
                        {{selectableCollaborators[i].attributes.email}} ({{selectableCollaborators[i].attributes.name}})
                        <fa-icon *ngIf="selectableCollaborators[i].id === myUserId" [icon]="faStar"></fa-icon>
                      </mat-checkbox>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col align-self-end">
                  <app-button-feedback
                    [attr.id]="'btnUpdateECoaches'"
                    (click)="updateECoaches()"
                    [_defaultButtonText]="'instance-update-form.form_update_instance_collaborator_save'"
                    [_responseText]="updateECoachResponse | async"
                  ></app-button-feedback>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Type and date settings for instance -->
        <div class="row">
          <!-- Guidance-->
          <div class="col-12">
            <div class="row">
              <p class="col-12 col-md-6">
                <strong>{{'instance-update-form.form_update_instance_guidance_support'| translate}}:</strong>
              </p>
              <div class="col-12 col-md-6 text-start">
                <mat-radio-group
                  [attr.id]="'radioGuidanceType'"
                  (change)="showInterventionType(instanceFormType.get('intervention_type').value)"
                  [(ngModel)]="selectedGuidanceType"
                  [ngModelOptions]="{standalone: true}"
                  aria-labelledby="radio-group-label"
                  class="roles-radio-group"
                  disabled
                >
                  <mat-radio-button
                    [attr.id]="'radioItem_' + type"
                    *ngFor="let type of guidanceType"
                    [value]="type"
                    class="types-radio-button"
                  >
                    {{'instance-update-form.' + type.toLowerCase() | translate}}
                  </mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
          </div>

          <!-- Starts at -->
          <div class="col-12">
            <div class="row">
              <div class="col-form-label col-12 col-md-6 pt-0">
                <strong>{{'instance-update-form.form_update_instance_starts_at' | translate}}:</strong>
              </div>
              <div class="col-12 col-md-6 text-center">
                <div *ngIf="lockStartsAt(); then thenShowLockedInput else elseShowUnlockedInput"></div>
                <ng-template #thenShowLockedInput>
                  <input
                    class="form-control"
                    value="{{helper.formatDateToDD_MM_YYYY_HH_MM(instance.attributes.configuration.starting_at * 1000)}}"
                    readonly
                  />
                </ng-template>
                <ng-template #elseShowUnlockedInput>
                  <mat-form-field class="full-width" appearance="outline">
                    <input
                      matInput
                      [attr.id]="'starts_at'"
                      [ngxMatDatetimePicker]="picker"
                      [placeholder]="helper.displayReadableDateTimeValue(instanceFormType.controls['starts_at'].value)"
                      formControlName="starts_at"
                      [min]="minDate"
                      [disabled]="false"
                      readonly
                    />
                    <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker
                      [attr.id]="'timepicker_starts_at'"
                      #picker
                      [showSpinners]="showSpinners"
                      [showSeconds]="showSeconds"
                      [stepHour]="stepHour"
                      [stepMinute]="stepMinute"
                      [stepSecond]="stepSecond"
                      [touchUi]="touchUi"
                      [color]="color"
                      [enableMeridian]="enableMeridian"
                    >
                    </ngx-mat-datetime-picker>
                  </mat-form-field>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <br />

        <!-- Description -->
        <div class="form-group">
          <strong>{{'instance-update-form.form_create_instance_customization_description' | translate}}</strong>
        </div>

        <div class="row">
          <div class="col align-self-end">
            <button [attr.id]="'btnResetQuestionnaire'" (click)="resetQuestionnaireConfig()" class="float-end" mat-button>
              {{'instance-update-form.form_update_instance_reset_config' | translate}}
            </button>
          </div>
        </div>

        <br />

        <!-- Lesson configuration -->
        <app-instance-questionnaire-configuration-update
          [_intervention]="intervention"
          [_questionnaires]="questionnaires"
          [_skills]="skills"
          [_diaries]="diaries"
          [_instance]="instance"
          [_locked_questionnaire_ids]="lockedQuestionnaireIds"
          [_form_custom_orderSubject]="formCustomOrderSubject"
        ></app-instance-questionnaire-configuration-update>
        <br />
      </form>
    </div>
  </div>
</div>
