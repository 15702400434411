<button [attr.id]="'btnDialogClose'" mat-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title>{{'group-buddy.export_buddy_activities' | translate}}</h1>
<div mat-dialog-content class="mat-typography">
  <div *ngIf="studyId; then thenShowBuddyActivityStudy else elseShowBuddyActivityBuddy"></div>
  <ng-template #thenShowBuddyActivityStudy>
    <p class="text-info">{{'group-buddy.export_buddy_activities_study_description' | translate}}</p>
  </ng-template>
  <ng-template #elseShowBuddyActivityBuddy>
    <p class="text-info">{{'group-buddy.export_buddy_activities_buddy_description' | translate}}</p>
    <p class="text-info">
      {{'group-buddy.export_buddy_activities_buddy' | translate}}:
      <strong>{{'group-buddy.buddy_instance_id' | translate }} #{{buddyInstance.id}}</strong>
    </p>
  </ng-template>

  <div [formGroup]="buddyActivityForm">
    <p>{{'group-buddy.select_start_and_end_time_activities' | translate}}</p>
    <div class="form-group row">
      <div class="col-12 col-sm-6">
        <mat-form-field class="full-width" appearance="outline">
          <input
            [attr.id]="'startTime'"
            matInput
            formControlName="startTime"
            [ngxMatDatetimePicker]="pickerStartTime"
            [attr.placeholder]="'group-buddy.select_start_time' | translate"
            [disabled]="false"
            readonly
          />
          <mat-datepicker-toggle matSuffix [for]="$any(pickerStartTime)"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker
            #pickerStartTime
            [attr.id]="'pickerStartTime'"
            [showSpinners]="showSpinners"
            [showSeconds]="showSeconds"
            [stepHour]="stepHour"
            [stepMinute]="stepMinute"
            [stepSecond]="stepSecond"
            [touchUi]="touchUi"
            [color]="color"
            [enableMeridian]="enableMeridian"
          >
          </ngx-mat-datetime-picker>
        </mat-form-field>
      </div>
      <div class="col-12 col-sm-6">
        <mat-form-field class="full-width" appearance="outline">
          <input
            [attr.id]="'endTime'"
            matInput
            formControlName="endTime"
            [ngxMatDatetimePicker]="pickerEndTime"
            [attr.placeholder]="'group-buddy.select_end_time' | translate"
            [disabled]="false"
            readonly
          />
          <mat-datepicker-toggle matSuffix [for]="$any(pickerEndTime)"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker
            #pickerEndTime
            [attr.id]="'pickerEndTime'"
            [showSpinners]="showSpinners"
            [showSeconds]="showSeconds"
            [stepHour]="stepHour"
            [stepMinute]="stepMinute"
            [stepSecond]="stepSecond"
            [touchUi]="touchUi"
            [color]="color"
            [enableMeridian]="enableMeridian"
          >
          </ngx-mat-datetime-picker>
        </mat-form-field>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-12 col-sm-4">{{'group-buddy.export_buddy_activities_by_user' | translate}}</div>
      <div class="col-12 col-sm-8">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>{{'group-buddy.select_buddy' | translate}}</mat-label>
          <mat-select [attr.id]="'selectUser'" (click)="resetFilter()" formControlName="userId">
            <mat-form-field class="full-width" appearance="fill">
              <mat-label>{{'group-creation.filter_selection' | translate}}</mat-label>
              <input
                [attr.id]="'userSelection'"
                autocomplete="off"
                matInput
                (keyup)="onKeyFilter()"
                [(ngModel)]="filter['userSelection']"
                [ngModelOptions]="{standalone: true}"
              />
            </mat-form-field>
            <mat-option [attr.id]="'option_' + owner.id" *ngFor="let owner of (members$ | async)" [value]="owner.id"
              >{{owner?.attributes.email | translate}}</mat-option
            >
            <mat-option [attr.id]="'option_none'" *ngIf="(members$ | async).length < 1"
              >{{'group-buddy.no_members_available' | translate}}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button [attr.id]="'btnCancel'" mat-button mat-dialog-close>{{'intervention-instance-reminder.cancel' | translate}}</button>
  <app-button-feedback
    *ngIf="buddyInstance"
    [_setId]="'btnGetCSVBuddyActivitiesByBuddyId'"
    (click)="generateCSVBuddyActivitiesReportByBuddyId()"
    [_defaultButtonText]="'group-buddy.export_activities_of_buddy'"
    [_responseText]="getCSVBuddyActivitiesByBuddyIdResponse | async"
  ></app-button-feedback>
  <app-button-feedback
    *ngIf="studyId"
    [_setId]="'btnGetCSVBuddyActivitiesByStudyId'"
    (click)="generateCSVBuddyActivitiesReportByStudyId()"
    [_defaultButtonText]="'group-buddy.export_activities_of_study'"
    [_responseText]="getCSVBuddyActivitiesByStudyIdResponse | async"
  ></app-button-feedback>
</div>
