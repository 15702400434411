import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { catchError, EMPTY, forkJoin, map, mergeMap, Observable, switchMap, tap, throwError } from 'rxjs';
import { OrganisationInterface } from '../../../models/interface/organisation/organisation.interface';
import { OrganisationService } from '../../../services/api/organisation/organisation.service';
import { UserInterface } from '../../../models/interface/user.interface';
import { StudyInterface } from '../../../models/interface/study/study.interface';

export interface OrganisationsState {
  organisation: OrganisationInterface | null;
  createOrganisationResponse: any;
  organisationCollaborators: UserInterface[];
  addOrganisationCollaboratorResponse: any;
  deleteOrganisationCollaboratorResponse: any;
  updateOrganisationCollaboratorResponse: any;
  organisationStudies: Array<StudyInterface>;
  deleteOrganisationResponse: any;
  createAccountOrganisationResponse: any;
  deleteUnverifiedOrganisationAccountResponse: any;
  addECoachAccountToOrganisationResponse: any;
}

@Injectable()
export class OrganisationStore extends ComponentStore<OrganisationsState> {
  readonly organisation$: Observable<OrganisationInterface> = this.select(state => state.organisation, {
    debounce: true
  });
  readonly createOrganisationResponse$: Observable<any> = this.select(state => state.createOrganisationResponse, {
    debounce: true
  });
  readonly addOrganisationCollaboratorResponse$: Observable<any> = this.select(state => state.addOrganisationCollaboratorResponse, {
    debounce: true
  });
  readonly deleteOrganisationCollaboratorResponse$: Observable<any> = this.select(state => state.deleteOrganisationCollaboratorResponse, {
    debounce: true
  });
  readonly updateOrganisationCollaboratorResponse$: Observable<any> = this.select(state => state.updateOrganisationCollaboratorResponse, {
    debounce: true
  });
  readonly organisationStudies$: Observable<StudyInterface[]> = this.select(state => state.organisationStudies, {
    debounce: true
  });
  readonly deleteOrganisationResponse$: Observable<any> = this.select(state => state.deleteOrganisationResponse, {
    debounce: true
  });
  readonly createAccountOrganisationResponse$: Observable<any> = this.select(state => state.createAccountOrganisationResponse, {
    debounce: true
  });
  readonly deleteUnverifiedOrganisationAccountResponse$: Observable<any> = this.select(
    state => state.deleteUnverifiedOrganisationAccountResponse,
    {
      debounce: true
    }
  );
  readonly addECoachAccountToOrganisationResponse$: Observable<any> = this.select(state => state.addECoachAccountToOrganisationResponse, {
    debounce: true
  });

  readonly updateOrganisationState = this.updater(
    (
      state,
      payload: {
        organisation?: OrganisationInterface | null;
        createOrganisationResponse?: any;
        organisationCollaborators?: UserInterface[];
        addOrganisationCollaboratorResponse?: any;
        deleteOrganisationCollaboratorResponse?: any;
        updateOrganisationCollaboratorResponse?: any;
        organisationStudies?: Array<StudyInterface>;
        deleteOrganisationResponse?: any;
        createAccountOrganisationResponse?: any;
        deleteUnverifiedOrganisationAccountResponse?: any;
        addECoachAccountToOrganisationResponse?: any;
      }
    ) => ({
      organisation: payload.organisation ? payload.organisation : state.organisation,
      createOrganisationResponse: payload.createOrganisationResponse
        ? payload.createOrganisationResponse
        : state.createOrganisationResponse,
      organisationCollaborators: payload.organisationCollaborators ? payload.organisationCollaborators : state.organisationCollaborators,
      addOrganisationCollaboratorResponse: payload.addOrganisationCollaboratorResponse
        ? payload.addOrganisationCollaboratorResponse
        : state.addOrganisationCollaboratorResponse,
      deleteOrganisationCollaboratorResponse: payload.deleteOrganisationCollaboratorResponse
        ? payload.deleteOrganisationCollaboratorResponse
        : state.deleteOrganisationCollaboratorResponse,
      updateOrganisationCollaboratorResponse: payload.updateOrganisationCollaboratorResponse
        ? payload.updateOrganisationCollaboratorResponse
        : state.updateOrganisationCollaboratorResponse,
      organisationStudies: payload.organisationStudies ? payload.organisationStudies : state.organisationStudies,
      deleteOrganisationResponse: payload.deleteOrganisationResponse
        ? payload.deleteOrganisationResponse
        : state.deleteOrganisationResponse,
      createAccountOrganisationResponse: payload.createAccountOrganisationResponse
        ? payload.createAccountOrganisationResponse
        : state.createAccountOrganisationResponse,
      deleteUnverifiedOrganisationAccountResponse: payload.deleteUnverifiedOrganisationAccountResponse
        ? payload.deleteUnverifiedOrganisationAccountResponse
        : state.deleteUnverifiedOrganisationAccountResponse,
      addECoachAccountToOrganisationResponse: payload.addECoachAccountToOrganisationResponse
        ? payload.addECoachAccountToOrganisationResponse
        : state.addECoachAccountToOrganisationResponse
    })
  );

  readonly getOrganisation = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) => {
        this.updateOrganisationState({ organisation: null });
        return this.organisationService.getOrganisation(payload.organisationId).pipe(
          tap({
            next: (result: any) => this.updateOrganisationState({ organisation: result.body.data }),
            error: e => throwError(e)
          }),
          catchError(error => EMPTY)
        );
      })
    )
  );

  readonly createOrganisationAdmin = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) => {
        this.updateOrganisationState({ createOrganisationResponse: null });
        return this.organisationService.createOrganisationAdmin(payload.name, payload.ownerId).pipe(
          tap({
            next: (result: any) => this.updateOrganisationState({ createOrganisationResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateOrganisationState({ createOrganisationResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly addOrganisationCollaborator = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) => {
        this.updateOrganisationState({ addOrganisationCollaboratorResponse: null });
        return this.organisationService.addCollaborators(payload.organisationId, payload.payload).pipe(
          tap({
            next: (result: any) => this.updateOrganisationState({ addOrganisationCollaboratorResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateOrganisationState({ addOrganisationCollaboratorResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly deleteOrganisationCollaborator = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) => {
        this.updateOrganisationState({ deleteOrganisationCollaboratorResponse: null });
        return this.organisationService.deleteCollaborators(payload.organisationId, payload.payload).pipe(
          tap({
            next: (result: any) => this.updateOrganisationState({ deleteOrganisationCollaboratorResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateOrganisationState({ deleteOrganisationCollaboratorResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly updateOrganisationCollaborator = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) => {
        this.updateOrganisationState({ updateOrganisationCollaboratorResponse: null });
        const reqs: any = [];
        reqs.push(
          this.organisationService.deleteCollaborators(payload.organisationId, payload.payloadRemove).pipe(
            map(result => result),
            catchError(error => throwError(error))
          )
        );
        reqs.push(
          this.organisationService.addCollaborators(payload.organisationId, payload.payloadAdd).pipe(
            map(result => result),
            catchError(error => throwError(error))
          )
        );
        return forkJoin(reqs).pipe(
          tap({
            next: (results: any) => this.updateOrganisationState({ updateOrganisationCollaboratorResponse: results }),
            error: e => throwError(e)
          }),
          catchError(errors => {
            this.updateOrganisationState({ updateOrganisationCollaboratorResponse: errors });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly getOrganisationStudies = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) =>
        this.organisationService.getStudiesOfOrganisation(payload.organisationId, payload.include).pipe(
          tap({
            next: (result: any) => {
              this.updateOrganisationState({ organisationStudies: result.body.data });
            },
            error: e => throwError(e)
          }),
          catchError(error => EMPTY)
        )
      )
    )
  );

  readonly deleteOrganisationAdmin = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) => {
        this.updateOrganisationState({ deleteOrganisationResponse: null });
        return this.organisationService.deleteOrganisationAdmin(payload.organisationId).pipe(
          tap({
            next: (result: any) => this.updateOrganisationState({ deleteOrganisationResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateOrganisationState({ deleteOrganisationResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly createAccountOrganisationManager = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) => {
        this.updateOrganisationState({ createAccountOrganisationResponse: null });
        return this.organisationService
          .createAccountAsOrganisationManager(payload.email, payload.organisations_ids, payload.name, payload.firstname, payload.lastname)
          .pipe(
            tap({
              next: (result: any) => this.updateOrganisationState({ createAccountOrganisationResponse: result }),
              error: e => throwError(e)
            }),
            catchError(error => {
              this.updateOrganisationState({ createAccountOrganisationResponse: error });
              return EMPTY;
            })
          );
      })
    )
  );

  readonly deleteUnverifiedOrganisationAccount = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) => {
        this.updateOrganisationState({ deleteUnverifiedOrganisationAccountResponse: null });
        return this.organisationService.deleteUnverifiedOrganisationAccount(payload.organisationId, payload.userIds).pipe(
          tap({
            next: (result: any) => this.updateOrganisationState({ deleteUnverifiedOrganisationAccountResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateOrganisationState({ deleteUnverifiedOrganisationAccountResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly addECoachAccountToOrganisation = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) => {
        this.updateOrganisationState({ addECoachAccountToOrganisationResponse: null });
        return this.organisationService.addECoachAccountToOrganisation(payload.emails, payload.organisations_ids).pipe(
          tap({
            next: (result: any) => this.updateOrganisationState({ addECoachAccountToOrganisationResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateOrganisationState({ addECoachAccountToOrganisationResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  constructor(private organisationService: OrganisationService) {
    super({
      organisation: null,
      createOrganisationResponse: null,
      organisationCollaborators: [],
      addOrganisationCollaboratorResponse: null,
      deleteOrganisationCollaboratorResponse: null,
      updateOrganisationCollaboratorResponse: null,
      organisationStudies: [],
      deleteOrganisationResponse: null,
      createAccountOrganisationResponse: null,
      deleteUnverifiedOrganisationAccountResponse: null,
      addECoachAccountToOrganisationResponse: null
    });
  }
}
