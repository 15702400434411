import { createFeatureSelector, createSelector } from '@ngrx/store';
import { InterventionInterface } from '../../models/interface/intervention.interface';

export const collabInterventionsState = createFeatureSelector('collabInterventions');
export const inactiveCollabInterventionsState = createFeatureSelector('inactiveCollabInterventions');
export const nonCollabInterventionsState = createFeatureSelector('nonCollabInterventions');

export const getCollabInterventions = createSelector(collabInterventionsState, collabInterventions => collabInterventions);
export const getInactiveCollabInterventions = createSelector(
  inactiveCollabInterventionsState,
  inactiveCollabInterventions => inactiveCollabInterventions
);
export const getNonCollabInterventions = createSelector(nonCollabInterventionsState, nonCollabInterventions => nonCollabInterventions);

export const getCollabInterventionById = (id: number) =>
  createSelector(collabInterventionsState, (collabInterventions: Array<InterventionInterface>) => {
    if (collabInterventions) {
      return collabInterventions.find((intervention: InterventionInterface) => intervention.id.toString() === id.toString());
    } else {
      return undefined;
    }
  });

export const getInactiveCollabInterventionById = (id: number) =>
  createSelector(inactiveCollabInterventionsState, (inactiveCollabInterventions: Array<InterventionInterface>) => {
    if (inactiveCollabInterventions) {
      return inactiveCollabInterventions.find((intervention: InterventionInterface) => intervention.id.toString() === id.toString());
    } else {
      return undefined;
    }
  });

export const getNonCollabInterventionpById = (id: number) =>
  createSelector(nonCollabInterventionsState, (nonCollabInterventions: Array<InterventionInterface>) => {
    if (nonCollabInterventions) {
      return nonCollabInterventions.find((intervention: InterventionInterface) => intervention.id.toString() === id.toString());
    } else {
      return undefined;
    }
  });

export const getCollabInterventionsByStudyId = (studyId: number) =>
  createSelector(collabInterventionsState, (collabInterventions: Array<InterventionInterface>) => {
    if (collabInterventions) {
      return collabInterventions.filter(
        (intervention: InterventionInterface) => intervention.attributes.study_id?.toString() === studyId.toString()
      );
    } else {
      return [];
    }
  });
