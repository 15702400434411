<div class="container-fluid text-center">
  <div class="col-sm-8 text-start mb-3">
    <h2>{{'intervention-instance.assignment' | translate}}</h2>
    <hr />
  </div>
  <div *ngIf="(isECoach$ | async) || (isManager$ | async)">
    <button [attr.id]="'btnToggleCollapse'" (click)="helper.toggleSubject(isCollapseSubject)" mat-button>
      <span *ngIf="(isCollapse$ | async); then thenCollapsed else elseCollapsed"></span>
      <ng-template #thenCollapsed>
        <span><fa-icon [icon]="faChevronDown"></fa-icon> {{'intervention-instance.my_assigned_participants_management' | translate}}</span>
      </ng-template>
      <ng-template #elseCollapsed>
        <span><fa-icon [icon]="faChevronUp"></fa-icon> {{'intervention-instance.my_assigned_participants_management' | translate}}</span>
      </ng-template>
    </button>
    <div *ngIf="(isCollapse$ | async) && ((isLoading$ | async ) === false)">
      <hr />

      <div class="col-12">
        <p>{{'intervention-instance.my_assigned_participants_add_description' | translate}}</p>
        <button
          [attr.id]="'btnOpenDialogInterventionInstanceCreate'"
          class="largeButton"
          (click)="openDialogInterventionInstanceCreate()"
          mat-button
          color="primary"
        >
          {{'intervention-instance.my_assigned_participants_add' | translate}}
        </button>
      </div>
    </div>
  </div>

  <hr />

  <div class="row justify-content-center">
    <div class="col-12 col-md-6 mb-2">
      <mat-form-field [style.width.%]="100" appearance="outline">
        <mat-label>{{'intervention-instance.search_instance' | translate}}</mat-label>
        <input
          [attr.id]="'search'"
          (keyup.enter)="applyInstanceFilter()"
          [(ngModel)]="searchFilter"
          [disabled]="(isLoading$ | async)"
          aria-describedby="collaboratorSearch"
          aria-label="Search"
          matInput
          type="text"
        />
        <button [attr.id]="'btnSearchInstance'" (keyup.enter)="applyInstanceFilter()" aria-label="Search" mat-button mat-button matSuffix>
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>

  <section class="checkbox-center">
    <mat-checkbox [attr.id]="'checkboxExcludeCanceledInstance'" [(ngModel)]="excludeCanceledInstance" (change)="applyInstanceFilter()"
      >{{'intervention-instance.exclude_canceled_instances' | translate}}</mat-checkbox
    >
  </section>

  <app-pagination-list
    #paginator
    (emitPageChange)="updatePagedInterventionInstances($event)"
    [_isLoading]="isLoading$ | async"
    [_items]="instances$ | async"
  ></app-pagination-list>

  <div class="row justify-content-center">
    <div *ngIf="(isLoading$ | async); else elseInstanceBlock" class="col-sm">
      <app-loader></app-loader>
    </div>
  </div>
  <ng-template #elseInstanceBlock>
    <div class="col-12">
      <div *ngIf="(isLoading$ | async) === false" class="row">
        <div *ngFor="let instance of (pagedInstances$ | async); trackBy: helper.trackByElement" class="col-12 col-md-6 mb-3">
          <app-card-intervention-instance
            [_interventionInstance]="instance"
            [_users]="studyMembers"
            [_studyCollaborators]="studyCollaborators"
            (emitUpdatePerformed)="updateList()"
          ></app-card-intervention-instance>
        </div>
      </div>
    </div>
  </ng-template>
</div>
