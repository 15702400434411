import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { map, switchMap, of, catchError } from 'rxjs';
import { QueueService } from '../../services/api/queue/queue.service';
import { addAllJobResultsStore, GetAllJobResultsOfUser, QueueActionTypes } from './queue.action';
import { HttpErrorResponseMessage } from 'src/app/enum/http-error-response-message';
import { ResponseError } from '../utils/response-error';
import { ResponseSuccess } from '../utils/response-success';

@Injectable()
export class QueueEffects {
  getAllJobResultsOfUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QueueActionTypes.getAllJobResultsOfUserType),
      map((action: GetAllJobResultsOfUser) => action.payload),
      switchMap((payload: any) =>
        this.queueService.getAllJobResultsOfUser().pipe(
          switchMap(result => {
            const actions = [];
            actions.push(addAllJobResultsStore({ allJobResults: result.body.data }));
            actions.push(new ResponseSuccess(QueueActionTypes.getAllJobResultsOfUserSuccessType, result));
            return actions;
          }),
          catchError(err =>
            of(
              new ResponseError(QueueActionTypes.getAllJobResultsOfUserErrorType, {
                message: HttpErrorResponseMessage.genericUnknownError,
                content: err
              })
            )
          )
        )
      )
    )
  );

  constructor(private actions$: Actions, private queueService: QueueService) {}
}
