<div class="my-3 mx-4" *ngIf="show && (announcements$ | async)?.length > 0">
  <ng-container class="col-12 col-md-6">
    <mat-card
      appearance="outlined"
      class="alert"
      [ngClass]="{'card-color-success': selectedAnnouncement.attributes.type === 'success', 'card-color-warning': selectedAnnouncement.attributes.type === 'warning', 'card-color-info': selectedAnnouncement.attributes.type === 'info', 'card-color-danger': selectedAnnouncement.attributes.type === 'error' }"
    >
      <button id="btnClose" mat-button class="close-button" (click)="hideBanner()">
        <mat-icon>close</mat-icon>
      </button>
      <mat-card-header class="center-content">
        <mat-card-title>{{selectedAnnouncement.attributes.title}}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p class="prewrap">{{selectedAnnouncement.attributes.message}}</p>
      </mat-card-content>

      <mat-card-actions class="center-content">
        <button id="btnPreviousAnnouncement" (click)="onClick('previous')" mat-button color="accent">
          <fa-icon [icon]="faArrowLeft"></fa-icon>
        </button>

        <div>{{displayIndexAnnouncement() + 1}} {{'announcement.of' | translate}} {{announcements.length}}</div>

        <button id="btnNextAnnouncement" (click)="onClick('next')" mat-button color="accent">
          <fa-icon [icon]="faArrowRight"></fa-icon>
        </button>
      </mat-card-actions>
    </mat-card>
  </ng-container>
</div>
