import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { HelperService } from '../../../services/helper/helper.service';

/**
 * Component:
 * Catalogue page displaying general information for interventions;
 * Can be found: {uri}/interventions
 */

@Component({
  selector: 'app-catalogue',
  templateUrl: './catalogue.component.html',
  styleUrls: ['./catalogue.component.scss']
})
export class CatalogueComponent implements OnInit {
  // Icons
  faInfoCircle = faInfoCircle;

  public showDescription$: Observable<boolean>;
  public showDescriptionSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private helperService: HelperService) {
    this.showDescription$ = this.showDescriptionSubject.asObservable();
  }

  public get helper() {
    return this.helperService;
  }

  ngOnInit(): void {}
}
