import { HttpResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { BehaviorSubject, forkJoin, iif, Observable, of, Subscription, throwError } from 'rxjs';
import { mergeMap, skip, take } from 'rxjs/operators';
import { UserInterface } from '../../../models/interface/user.interface';
import { UserActionTypes } from '../../../store/user/user.action';
import { UserStore } from '../../../store/user/component-store/user.store';

@Component({
  selector: 'app-dialog-admin-user-update',
  templateUrl: './dialog-admin-user-update.component.html',
  styleUrls: ['./dialog-admin-user-update.component.scss'],
  providers: [UserStore]
})
export class DialogAdminUserUpdateComponent implements OnInit, OnDestroy {
  public user: UserInterface;

  public updateAccountResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');

  public adminChecked = false;
  public ecoachChecked = false;
  public editorChecked = false;

  private users$: Observable<Array<UserInterface>>;

  private assignRolesToUserAdminResponse$: Observable<any>;
  private removeRolesFromUserAdminResponse$: Observable<any>;

  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userStore: UserStore,
    private store: Store<{ users: Array<UserInterface> }>,
    private dialogRef: MatDialogRef<DialogAdminUserUpdateComponent>
  ) {
    this.users$ = this.store.select('users');
    this.assignRolesToUserAdminResponse$ = this.userStore.assignRolesToUserAdminResponse$;
    this.removeRolesFromUserAdminResponse$ = this.userStore.removeRolesFromUserAdminResponse$;
  }

  ngOnInit(): void {
    this.user = this.data.user;
    this.adminChecked = this.isUserInRole(this.user, 'admin');
    this.ecoachChecked = this.isUserInRole(this.user, 'ecoach');
    this.editorChecked = this.isUserInRole(this.user, 'editor');
  }

  public isUserInRole(user: UserInterface, slug: string): boolean {
    const globalRoles: Array<{ slug: string; description: string }> = user.attributes.roles;
    return globalRoles.length > 0 ? !!globalRoles.find(role => role.slug.toString() === slug) : false;
  }

  public updateAccount(): void {
    if (this.updateAccountResponse.value !== 'LOADING') {
      this.updateAccountResponse.next('LOADING');

      const removingRoles: Array<string> = [];
      const addingRoles: Array<string> = [];

      if (!this.isUserInRole(this.user, 'admin') && this.adminChecked) {
        addingRoles.push('admin');
      }

      if (!this.isUserInRole(this.user, 'ecoach') && this.ecoachChecked) {
        addingRoles.push('ecoach');
      }

      if (!this.isUserInRole(this.user, 'editor') && this.editorChecked) {
        addingRoles.push('editor');
      }

      if (this.isUserInRole(this.user, 'admin') && !this.adminChecked) {
        removingRoles.push('admin');
      }

      if (this.isUserInRole(this.user, 'ecoach') && !this.ecoachChecked) {
        removingRoles.push('ecoach');
      }

      if (this.isUserInRole(this.user, 'editor') && !this.editorChecked) {
        removingRoles.push('editor');
      }

      const reqs: Array<any> = [];
      if (removingRoles.length > 0) {
        this.userStore.removeRolesFromUserAdmin({ userId: this.user.id, roleSlugs: removingRoles });
        reqs.push(
          this.removeRolesFromUserAdminResponse$.pipe(
            skip(1),
            take(1),
            mergeMap(result => iif(() => result instanceof HttpResponse, of(result), throwError(result)))
          )
        );
      }
      if (addingRoles.length > 0) {
        this.userStore.assignRolesToUserAdmin({ userId: this.user.id, roleSlugs: addingRoles });
        reqs.push(
          this.assignRolesToUserAdminResponse$.pipe(
            skip(1),
            take(1),
            mergeMap(result => iif(() => result instanceof HttpResponse, of(result), throwError(result)))
          )
        );
      }
      this.subscriptions.push(
        forkJoin(reqs)
          .pipe(
            mergeMap((result: any) => {
              this.store.dispatch({ type: UserActionTypes.getAllUsersAdminType, payload: { userId: this.user.id } });
              return this.users$.pipe(skip(1), take(1));
            })
          )
          .subscribe(
            result => {
              const userList = result;
              if (userList.length > 0) {
                this.user = userList[0];
              }
              this.updateAccountResponse.next('SUCCESS');
              setTimeout(() => {
                this.updateAccountResponse.next('DEFAULT');
              }, 2500);
            },
            (error: any) => {
              this.updateAccountResponse.next('FAILURE');
              setTimeout(() => {
                this.updateAccountResponse.next('DEFAULT');
              }, 2500);
            },
            () => {
              this.updateAccountResponse.next('SUCCESS');
              setTimeout(() => {
                this.updateAccountResponse.next('DEFAULT');
              }, 2500);
            }
          )
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
