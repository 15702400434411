<button [attr.id]="'btnDialogClose'" mat-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title>{{'intervention-instance-reminder.create_new_intervention_instance_reminder' | translate}}</h1>
<div mat-dialog-content class="mat-typography">
  <p>
    <strong>
      {{'intervention-instance-reminder.reminder_description_part1' | translate}}
      {{'intervention-instance-reminder.reminder_description_part2' | translate}}
    </strong>
  </p>

  <p class="text-info">{{'intervention-instance-reminder.reminder_setup' | translate}}</p>

  <div [formGroup]="reminderForm">
    <!-- Starts at -->
    <div class="col-12">
      <div class="col-form-label col-12 pt-0">
        <strong>{{'intervention-instance-reminder.form_create_intervention_instance_reminder_starts_at' | translate}}:</strong>
      </div>
      <div class="row">
        <div class="col-md-6 col-12">
          <mat-form-field class="full-width" appearance="outline">
            <input
              [attr.id]="'starts_at'"
              matInput
              [ngxMatDatetimePicker]="picker"
              placeholder="Choose a date"
              formControlName="starts_at"
              [min]="minDate"
              [disabled]="false"
              readonly
            />
            <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker
              #picker
              [attr.id]="'picker'"
              [showSpinners]="showSpinners"
              [showSeconds]="showSeconds"
              [stepHour]="stepHour"
              [stepMinute]="stepMinute"
              [stepSecond]="stepSecond"
              [touchUi]="touchUi"
              [color]="color"
              [enableMeridian]="enableMeridian"
            >
            </ngx-mat-datetime-picker>
          </mat-form-field>
        </div>
        <div class="col-md-6 col-12">
          <!-- Max_Times -->
          <mat-form-field [style.width.%]="100" appearance="outline">
            <mat-label>{{'intervention-instance-reminder.max_number_of_repetitions' | translate}}</mat-label>
            <input [attr.id]="'max_times'" autocomplete="off" formControlName="max_times" matInput required type="number" min="0" />
            <mat-error
              ><span
                *ngIf="((isSubmitted$ | async) && f.get('max_times').invalid && f.get('max_times').errors?.required) || (f.get('max_times').touched && f.get('max_times').invalid)"
                >{{'intervention-instance-reminder.valid_max_number_of_repetitions_required' | translate}}</span
              >
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 col-12">
        <!-- Time interval -->
        <mat-form-field [style.width.%]="100" appearance="outline">
          <mat-label>{{'intervention-instance-reminder.time_interval' | translate}}</mat-label>
          <input [attr.id]="'time_interval'" type="number" min="0" autocomplete="off" formControlName="time_interval" matInput />
          <mat-error
            *ngIf="((isSubmitted$ | async) && f.get('time_interval').invalid && f.get('time_interval').errors?.required) || (f.get('time_interval').touched && f.get('time_interval').invalid)"
            >{{'intervention-instance-reminder.time_interval_required' | translate}}</mat-error
          >
        </mat-form-field>
      </div>
      <div class="col-md-6 col-12">
        <!-- Time interval unit -->
        <mat-form-field appearance="outline">
          <mat-label>{{'intervention-instance-reminder.time_interval_unit' | translate }}</mat-label>
          <mat-select [attr.id]="'time_interval_unit'" formControlName="time_interval_unit" name="time_interval_unit">
            <mat-option [attr.id]="'option_' + unit" *ngFor="let unit of unitList" [value]="unit">
              {{('intervention-instance-reminder.' + unit) | translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <mat-divider class="mb-3"></mat-divider>

    <mat-form-field class="full-width" appearance="outline">
      <mat-label>{{'intervention-instance-reminder.select_language' | translate}}</mat-label>
      <mat-select [(value)]="selectedLanguageOnly">
        <mat-option [value]="option" *ngFor="let option of languages"
          >{{'intervention-instance-reminder.' + option | translate }}</mat-option
        >
      </mat-select>
    </mat-form-field>

    <p>{{getReminderGreeting()}}</p>

    <!-- Content text -->
    <mat-form-field [style.width.%]="100" appearance="outline" floatLabel="always">
      <mat-label>{{'intervention-instance-reminder.content_text' | translate}}</mat-label>
      <textarea
        [attr.id]="'content_text'"
        [attr.placeholder]="getReminderMessage()"
        autocomplete="off"
        formControlName="content_text"
        cdkTextareaAutosize
        cdkAutosizeMinRows="5"
        matInput
      ></textarea>
    </mat-form-field>

    <p>{{getReminderEndingHead()}}</p>

    <mat-form-field [style.width.%]="100" appearance="outline" floatLabel="always">
      <mat-label>{{'intervention-instance-reminder.content_ending' | translate}}</mat-label>
      <textarea
        [attr.id]="'content_ending'"
        [attr.placeholder]="getReminderEnding()"
        autocomplete="off"
        formControlName="content_ending"
        matInput
      ></textarea>
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button [attr.id]="'btnCancel'" mat-button mat-dialog-close>{{'intervention-instance-reminder.cancel' | translate}}</button>
  <app-button-feedback
    [_setId]="'btnSetInterventionInstanceReminder'"
    (click)="setInterventionInstanceReminder()"
    [_defaultButtonText]="'intervention-instance-reminder.reminder_confirm'"
    [_responseText]="createInterventionInstanceReminderResponse | async"
  ></app-button-feedback>
</div>
