import { createReducer, on } from '@ngrx/store';
import { removeLessonLocaleStore, updateLessonLocaleStore } from './lesson-locale.action';

export const initialLessonLocale = '';

export const lessonLocaleReducer = createReducer(
  initialLessonLocale,
  on(updateLessonLocaleStore, (state, { lessonLocale }) => lessonLocale),
  on(removeLessonLocaleStore, (state, {}) => '')
);
