import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HelperService } from '../../helper/helper.service';

@Injectable({
  providedIn: 'root'
})
export class SkillService {
  public header: HttpHeaders = new HttpHeaders();
  private backendUrl: string = environment.backendURL;

  constructor(private http: HttpClient, private helperService: HelperService) {
    this.header = this.header.set('Content-Type', 'application/json');
    this.header = this.helperService.setLocaleFromStorage(this.header);
  }

  /**
   * AAS2 API ECoach - Get all skills of an intervention
   * This function retrieves all skills of an intervention.
   *
   * @params number interventionId - An id of the intervention
   * @return Observable<any> - An observable for any response.
   */
  public getAllSkillsOfIntervention(interventionId: number): Observable<any> {
    this.header = this.helperService.setLocaleFromStorage(this.header);
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoach/interventions/${interventionId}/skills?limit=0`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API ECoach - Get a skill
   * This function retrieves a specific skill.
   *
   * @params number skillId - An id of the skill
   * @return Observable<any> - An observable for any response.
   */
  public getSkill(skillId: number): Observable<any> {
    this.header = this.helperService.setLocaleFromStorage(this.header);
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoach/skills/${skillId}`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API ECoach - Get elements of a skill
   * This function retrieves all elements of a specific skill.
   *
   * @params number skillId - An id of the skill
   * @return Observable<any> - An observable for any response.
   */
  public getSkillElements(skillId: number): Observable<any> {
    this.header = this.helperService.setLocaleFromStorage(this.header);
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoach/skills/${skillId}/elements`, {
      headers: this.header,
      observe: 'response'
    });
  }
}
