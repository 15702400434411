import { HttpResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, filter, Observable, Subscription, take } from 'rxjs';
import { UserInterface } from '../../../models/interface/user.interface';
import { AuthenticationStore } from '../../../store/authentication/component-store/authentication.store';

@Component({
  selector: 'app-dialog-admin-user-delete',
  templateUrl: './dialog-admin-user-delete.component.html',
  styleUrls: ['./dialog-admin-user-delete.component.scss'],
  providers: [AuthenticationStore]
})
export class DialogAdminUserDeleteComponent implements OnInit, OnDestroy {
  public user: UserInterface;

  public deleteAccountResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');
  public deactivateUserAdminResponse$: Observable<any>;

  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DialogAdminUserDeleteComponent>,
    private authenticationStore: AuthenticationStore
  ) {
    this.deactivateUserAdminResponse$ = this.authenticationStore.deactivateUserAdminResponse$;
  }

  ngOnInit(): void {
    this.user = this.data.user;
  }

  public deleteAccount(): void {
    if (this.deleteAccountResponse.value === 'DEFAULT') {
      this.deleteAccountResponse.next('LOADING');

      this.authenticationStore.deactivateUserAdmin(this.user.id);

      this.subscriptions.push(
        this.deactivateUserAdminResponse$
          .pipe(
            filter(value => !!value),
            take(1)
          )
          .subscribe((value: any) => {
            if (value instanceof HttpResponse) {
              this.deleteAccountResponse.next('SUCCESS');
              setTimeout(() => {
                this.deleteAccountResponse.next('DEFAULT');
                this.dialogRef.close();
              }, 2500);
            } else {
              this.deleteAccountResponse.next('FAILURE');
              setTimeout(() => {
                this.deleteAccountResponse.next('DEFAULT');
              }, 2500);
            }
          })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
