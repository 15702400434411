<mat-card appearance="outlined" [attr.id]="'diary_' + diary.id + '_' + diaryInstance?.id" class="max-width-card mb-3">
  <img
    mat-card-image
    [src]="helper.getAllMediaSupportPath(diary.attributes.picture, diary.attributes.study_id)"
    (error)="helper.getFallbackImage($event)"
    alt="No image"
    mat-card-image
  />
  <mat-card-header class="card-header-center">
    <div mat-card-avatar><fa-icon [icon]="faBook"></fa-icon></div>
    <mat-card-title
      >{{((diary.attributes.title) ? diary.attributes.title : 'intervention-diary.no_diary_title') | translate}}</mat-card-title
    >
    <mat-card-subtitle
      >{{((diary.attributes.name) ? diary.attributes.name : ('intervention-diary.no_diary_name' | translate))}} (ID -
      {{diary.id}})</mat-card-subtitle
    >
  </mat-card-header>
  <mat-card-content>
    <div *ngIf="users.length > 0" class="row justify-content-center">
      <p><strong>{{helper.getCodeNameEmail(diaryInstance.attributes.patient_id, users, diary.attributes.study_id)}}</strong></p>
    </div>

    <div class="row justify-content-center my-2">
      <div class="text-padding col-sm-6">
        <span *ngIf="(diary.attributes.is_active === 0); else activeBlock" class="text-danger"
          >{{'intervention-diary.inactive' | translate}}</span
        >
        <ng-template #activeBlock>
          <span class="text-success">{{'intervention-diary.active' | translate}}</span>
        </ng-template>
      </div>

      <div class="text-padding col-sm-6">
        <span *ngIf="(diary.attributes.is_running === 0); else runningBlock" class="text-danger">
          {{'intervention-diary.not_running' | translate}}
        </span>
        <ng-template #runningBlock>
          <span class="text-success">{{'intervention-diary.running' | translate}}</span>
        </ng-template>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-sm-6 text-end">{{'intervention-diary.available_languages' | translate}}:</div>
      <div class="col-12 col-sm-6 text-start">
        <div *ngFor="let code of diary.attributes.locales">{{helper.parseLanguage(code) | translate}}</div>
      </div>
    </div>

    <ng-template #elseNoLanguageBlock>
      <p>{{'intervention-diary.no_language_available' | translate}}</p>
    </ng-template>

    <p class="mt-2">
      {{((diary.attributes.description) ? diary.attributes.description : 'intervention-diary.no_diary_description') | translate}}
    </p>
  </mat-card-content>
  <mat-divider></mat-divider>
  <mat-card-actions>
    <button
      [attr.id]="'btnOpenDialogDiaryPreview_' + diary.id"
      (click)="openDialogDiaryPreview(diary.attributes.questionnaire_id, diary.attributes.study_id, diary.attributes.page_color)"
      mat-button
    >
      <fa-icon [icon]="faInfoCircle"></fa-icon>
      {{'intervention-diary.diary_show_diary_questionnaire' | translate}}
    </button>
    <button
      [attr.id]="'btnOpenDialogAnswersheetPreview_' + diaryInstance?.id"
      *ngIf="!!diaryInstance"
      (click)="openDialogAnswersheetPreview(diaryInstance.id, diary)"
      mat-button
    >
      <fa-icon [icon]="faBookMedical"></fa-icon>
      {{'patient-diary.diary_show_diary_answersheet' | translate}}
    </button>
  </mat-card-actions>
</mat-card>
