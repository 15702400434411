import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.scss']
})
export class ImprintComponent implements OnInit {
  // Icons
  faArrowLeft = faArrowLeft;

  // Authentication status
  public isAuthenticated$: Observable<boolean>;

  constructor(private router: Router, private store: Store<{ isAuthenticated: boolean }>) {
    this.isAuthenticated$ = this.store.select('isAuthenticated');
  }

  public redirectToLogin(): void {
    this.router.navigate([`/login`]);
  }

  ngOnInit(): void {}
}
