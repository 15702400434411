/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock';
import { faCommentDots } from '@fortawesome/free-solid-svg-icons/faCommentDots';
import { faComments } from '@fortawesome/free-solid-svg-icons/faComments';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faPen } from '@fortawesome/free-solid-svg-icons/faPen';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import { faTasks } from '@fortawesome/free-solid-svg-icons/faTasks';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { BehaviorSubject, fromEvent, Observable, of, Subscription } from 'rxjs';
import { distinctUntilKeyChanged, filter, mergeMap, skip, switchMap, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { HelperActivityService } from '../../../services/helper/helper-activity/helper-activity.service';
import { ActivityInterface } from '../../../models/interface/activity.interface';
import { HelperService } from '../../../services/helper/helper.service';
import { MessageThreadsInterface } from '../../../models/interface/message_threads.interface';
import { UserInterface } from '../../../models/interface/user.interface';
import { ProfileInterface } from '../../../models/interface/profile.interface';
import { HelperDialogService } from '../../../services/helper/helper-dialog/helper-dialog.service';
import { UserActionTypes } from '../../../store/user/user.action';
import { MessageStore } from '../../../store/message/component-store/message.store';
import { HttpResponse } from '@angular/common/http';
import { UserStore } from '../../../store/user/component-store/user.store';
import { CustomCookieService } from 'src/app/services/customcookie/custom-cookie.service';

/**
 * Home component displaying the starting page of eCoach platform
 */

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [MessageStore, UserStore]
})
export class HomeComponent implements OnInit, OnDestroy {
  @ViewChild('instanceDetailContainer', { read: ViewContainerRef }) instanceDetailContainer: ViewContainerRef;

  // Icons
  faTasks = faTasks;
  faComments = faComments;
  faPen = faPen;
  faCommentDots = faCommentDots;
  faClock = faClock;
  faPlusCircle = faPlusCircle;
  faInfoCircle = faInfoCircle;
  faArrowRight = faArrowRight;
  faUser = faUser;

  // First time welcome
  public isFirstTime = false;

  public isLoadingActivities$: Observable<boolean>;
  public isLoadingFeedback$: Observable<boolean>;
  public isLoadingConversations$: Observable<boolean>;

  public threadsParticipants: Array<MessageThreadsInterface> = [];
  public feedbackParticipants: Array<MessageThreadsInterface> = [];

  // Data provided by UserService
  public members: Array<UserInterface> = [];

  public tasksSubject: BehaviorSubject<Array<ActivityInterface>> = new BehaviorSubject([]);
  public memberActivitiesSubject: BehaviorSubject<Array<ActivityInterface>> = new BehaviorSubject([]);

  public threads$: Observable<Array<MessageThreadsInterface>>;
  public feedback$: Observable<Array<MessageThreadsInterface>>;

  public pagedActivitySubject: BehaviorSubject<Array<ActivityInterface>> = new BehaviorSubject<Array<ActivityInterface>>([]);
  public pagedActivity$: Observable<Array<ActivityInterface>> = this.pagedActivitySubject.asObservable();

  public pagedTaskSubject: BehaviorSubject<Array<ActivityInterface>> = new BehaviorSubject<Array<ActivityInterface>>([]);
  public pagedTask$: Observable<Array<ActivityInterface>> = this.pagedTaskSubject.asObservable();

  // Translation for intervention name
  public param = { intervention_name: '...' };

  public profile$: Observable<ProfileInterface>;
  public myActivities$: Observable<Array<ActivityInterface>>;

  private isLoadingActivitiesSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private isLoadingFeedbackSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private isLoadingConversationsSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private inboxFolder$: Observable<Array<MessageThreadsInterface>>;
  private inboxFolderAnswersheet$: Observable<Array<MessageThreadsInterface>>;

  private getThreadDetailsResponse$: Observable<any>;

  private myMembers$: Observable<Array<UserInterface>>;
  private studyActivities$: Observable<Array<ActivityInterface>>;

  private memberActivities: Array<ActivityInterface> = [];
  private myTasks: Array<ActivityInterface> = [];

  private feedbackSubject: BehaviorSubject<Array<MessageThreadsInterface>> = new BehaviorSubject([]);
  private threadsSubject: BehaviorSubject<Array<MessageThreadsInterface>> = new BehaviorSubject([]);

  private subscriptions: Subscription[] = [];

  constructor(
    private helperService: HelperService,
    private router: Router,
    private helperActivityService: HelperActivityService,
    private helperDialogService: HelperDialogService,
    private translateService: TranslateService,
    private customCookieService: CustomCookieService,
    private messageStore: MessageStore,
    private userStore: UserStore,
    private store: Store<{
      myProfile: ProfileInterface;
      myActivities: Array<ActivityInterface>;
      myMembers: Array<UserInterface>;
    }>
  ) {
    this.profile$ = this.store.select('myProfile');
    this.myActivities$ = this.store.select('myActivities');
    this.myMembers$ = this.store.select('myMembers');
    this.isLoadingActivities$ = this.isLoadingActivitiesSubject.asObservable();
    this.isLoadingFeedback$ = this.isLoadingFeedbackSubject.asObservable();
    this.isLoadingConversations$ = this.isLoadingConversationsSubject.asObservable();
    this.threads$ = this.threadsSubject.asObservable();
    this.feedback$ = this.feedbackSubject.asObservable();
    this.inboxFolder$ = this.messageStore.inboxFolder$;
    this.inboxFolderAnswersheet$ = this.messageStore.inboxFolderAnswersheet$;
    this.getThreadDetailsResponse$ = this.messageStore.getThreadDetailsResponse$;
    this.studyActivities$ = this.userStore.studyActivities$;
  }

  public get helper() {
    return this.helperService;
  }

  public get helperDialog() {
    return this.helperDialogService;
  }

  public get helperActivity() {
    return this.helperActivityService;
  }

  ngOnInit(): void {
    const source$ = fromEvent<StorageEvent>(window, 'storage');
    this.subscriptions.push(
      source$
        .pipe(
          filter(value => value.key === 'isAuthenticated'),
          distinctUntilKeyChanged('newValue'),
          switchMap((value: StorageEvent) => {
            if (this.customCookieService.isCookieValid()) {
              this.initialize();
            }
            return of(true);
          })
        )
        .subscribe({})
    );

    this.initialize();

    this.subscriptions.push(
      this.myActivities$.pipe(take(1)).subscribe(
        (result: Array<ActivityInterface>) => {
          this.isFirstTime = result.filter((activity: ActivityInterface) => activity.attributes.action === 'LOGIN').length <= 5;
        },
        () => {
          this.isFirstTime = false;
        }
      )
    );

    this.subscriptions.push(
      this.translateService.onLangChange
        .pipe(
          mergeMap((event: LangChangeEvent) => {
            this.isLoadingActivitiesSubject.next(true);
            this.isLoadingConversationsSubject.next(true);
            this.isLoadingFeedbackSubject.next(true);
            this.memberActivities = [];
            this.myTasks = [];

            this.userStore.getActivitiesOfMembers({ include: 'questionnaires' });
            return this.studyActivities$.pipe(skip(1), take(1));
          })
        )
        .subscribe(
          result => {
            this.memberActivities = result;

            // All my tasks that are incompleted
            this.myTasks = this.helperActivity.getTasks(this.memberActivities);

            // Sort activities and tasks by newest
            this.tasksSubject.next(
              this.helper.getActivityByNewest(this.helperActivity.filterPendingTasks(this.myTasks, this.feedbackParticipants))
            );
            this.memberActivitiesSubject.next(this.helper.getActivityByNewest(this.memberActivities));

            this.pagedTaskSubject.next(this.tasksSubject.value.slice(0, 20));
            this.pagedActivitySubject.next(this.memberActivitiesSubject.value.slice(0, 20));
          },
          error => {
            console.error(error);
          }
        )
    );

    this.subscriptions.push(
      this.myMembers$.subscribe((members: any) => {
        this.members = members;
      })
    );

    this.subscriptions.push(
      this.studyActivities$.subscribe((activities: any) => {
        this.memberActivities = activities;
        this.memberActivitiesSubject.next(this.helper.getActivityByNewest(this.memberActivities));
        this.pagedActivitySubject.next(this.memberActivitiesSubject.value.slice(0, 20));
        this.myTasks = this.helperActivity.getTasks(this.memberActivities);
        this.tasksSubject.next(
          this.helper.getActivityByNewest(this.helperActivity.filterPendingTasks(this.myTasks, this.feedbackParticipants))
        );
        this.pagedTaskSubject.next(this.tasksSubject.value.slice(0, 20));
        this.isLoadingActivitiesSubject.next(false);
      })
    );

    this.subscriptions.push(
      this.inboxFolder$.subscribe((threads: any) => {
        this.threadsParticipants = threads.filter((thread: MessageThreadsInterface) => thread.attributes?.answersheet_id === null);
        this.threadsSubject.next(this.helper.getInboxWithNewestUnreadMessage(this.threadsParticipants));
        this.isLoadingConversationsSubject.next(false);
      })
    );

    this.subscriptions.push(
      this.inboxFolderAnswersheet$.subscribe((feedback: any) => {
        this.feedbackParticipants = feedback.filter((thread: MessageThreadsInterface) => thread.attributes?.answersheet_id !== null);
        this.feedbackSubject.next(this.helper.getInboxWithNewestUnreadMessage(this.feedbackParticipants));
        this.isLoadingFeedbackSubject.next(false);
      })
    );
  }

  // Navigate to answersheet
  public showAnswersheet(activity: ActivityInterface): void {
    this.router.navigateByUrl(`/feedback-overview/answersheets/${this.helper.getAnswersheetIdFromActivity(activity)}`);
  }

  public getAnswersheetDetails(thread: MessageThreadsInterface): void {
    this.messageStore.getThreadDetails({ threadId: thread.id });
    this.subscriptions.push(
      this.getThreadDetailsResponse$.pipe(skip(1), take(1)).subscribe(result => {
        if (result instanceof HttpResponse) {
          this.router.navigateByUrl(`feedback-overview/answersheets/${thread.attributes.answersheet_id}`);
        }
      })
    );
  }

  public updatePagedActivities(event: any) {
    if (event) {
      this.pagedActivitySubject.next(event);
    }
  }

  public updatePagedTasks(event: any) {
    if (event) {
      this.pagedTaskSubject.next(event);
    }
  }

  public openDialogInterventionInstanceDetails(activity: ActivityInterface): void {
    const instanceId =
      activity.attributes.uri !== undefined ? this.helper.getLastIndexIDOfURI(activity.attributes.uri) : activity.attributes.entity_id;
    this.helperDialog
      .openDialogInterventionInstanceDetails(instanceId, this.param, 1)
      .afterClosed()
      .subscribe(() => {});
  }

  public trackByActivityId(index: number, element: ActivityInterface): number {
    return element.id;
  }

  public trackByActivityTaskId(index: number, element: ActivityInterface): number {
    return element.id;
  }

  public trackByThreadId(index: number, element: MessageThreadsInterface): number {
    return element.id;
  }

  public initialize() {
    this.store.dispatch({
      type: UserActionTypes.getMyMembersType,
      payload: {}
    });
    this.userStore.getActivitiesOfMembers({ include: 'questionnaires' });
    this.messageStore.getInboxFolder({ include: 'participants' });
    this.messageStore.getInboxFolder({ include: 'participants', answersheetIdFlag: true });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
