import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { download } from '../../../helpers/download/download';
import { HelperService } from '../../helper/helper.service';

@Injectable({
  providedIn: 'root'
})
export class QueueService {
  private backendUrl: string = environment.backendURL;
  private header: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(private http: HttpClient, private helperService: HelperService) {
    this.header = this.header.set('Content-Type', 'application/json');
    this.header = this.helperService.setLocaleFromStorage(this.header);
  }

  /**
   * AAS2 API User - Get job result
   * This function returns the job results of a specific jobresult id.
   *
   * @return Observable<any> - An observable for any response.
   */
  public getJobResult(jobResultId: number): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/api/v1/queue/job/results/${jobResultId}`, { headers: this.header, observe: 'response' });
  }

  /**
   * AAS2 API User - Get all job results of a user
   * This function returns the job results of a specific jobresult id.
   *
   * @return Observable<any> - An observable for any response.
   */
  public getAllJobResultsOfUser(): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/api/v1/queue/job/results?limit=0`, { headers: this.header, observe: 'response' });
  }

  public getReport(reportName: string): Observable<any> {
    this.header = this.header.set('Content-Type', '*/*');
    this.header = this.header.set('Accept', '*/*');
    let downloaded = false;
    return this.http
      .get<any>(`${this.backendUrl}/reports/${reportName}`, { headers: this.header, observe: 'response', responseType: 'blob' as 'json' })
      .pipe(
        download(jsonResponse => {
          const getFileName = (response: HttpResponse<any>) => {
            let fileName: string;
            try {
              const contentDisposition: string = response.headers.get('content-disposition');
              const r = /filename="(.+)"/;
              fileName = r.exec(contentDisposition)[1];
            } catch (error) {
              fileName = `report-${new Date().toISOString()}.pdf`;
            }
            return fileName;
          };

          const filename = `report-${new Date().toISOString()}.pdf`;
          const binaryData = [];
          binaryData.push(jsonResponse);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/pdf' }));
          downloadLink.setAttribute('download', filename);
          document.body.appendChild(downloadLink);
          if (!downloaded) {
            downloadLink.click();
            URL.revokeObjectURL(downloadLink.href);
            downloaded = true;
          }
        })
      );
  }

  public showReport(reportName: string): string {
    return `${this.backendUrl}/reports/${reportName}`;
  }

  public showSecureReport(studyId: number, reportName: string): Observable<any> {
    this.header = this.header.set('Content-Type', '*/*');
    this.header = this.header.set('Accept', '*/*');
    return this.http.get<any>(`${this.backendUrl}/reports/studies/${studyId}/${reportName}`, {
      headers: this.header,
      responseType: 'arrayBuffer' as 'json'
    });
  }

  public downloadReport(reportName: string): Observable<any> {
    this.header = this.header.set('Content-Type', '*/*');
    this.header = this.header.set('Accept', '*/*');
    return this.http.get(`${this.backendUrl}/api/v1/reports/${reportName}`, {
      headers: this.header,
      responseType: 'blob' as 'json'
    });
  }
}
