/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { faBell } from '@fortawesome/free-solid-svg-icons/faBell';
import { faBookOpen } from '@fortawesome/free-solid-svg-icons/faBookOpen';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons/faBullhorn';
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome';
import { faHospitalUser } from '@fortawesome/free-solid-svg-icons/faHospitalUser';
import { faLanguage } from '@fortawesome/free-solid-svg-icons/faLanguage';
import { faPen } from '@fortawesome/free-solid-svg-icons/faPen';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { faSortDown } from '@fortawesome/free-solid-svg-icons/faSortDown';
import { faTasks } from '@fortawesome/free-solid-svg-icons/faTasks';
import { faCommentDots } from '@fortawesome/free-solid-svg-icons/faCommentDots';
import { faUserAlt } from '@fortawesome/free-solid-svg-icons/faUserAlt';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons/faUserCircle';
import { faUsersCog } from '@fortawesome/free-solid-svg-icons/faUsersCog';
import {
  catchError,
  delay,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  mergeMap,
  repeat,
  skip,
  switchMap,
  take,
  tap
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { InterventionInstanceInterface } from '../../../models/interface/intervention-instances/intervention-instance.interface';
import { FcmMessageService } from '../../../services/fcm-message/fcm-message.service';
import { HelperService } from '../../../services/helper/helper.service';
import { UserInterface } from '../../../models/interface/user.interface';
import { MessageThreadsInterface } from '../../../models/interface/message_threads.interface';
import { NotificationInterface } from '../../../models/interface/notification.interface';
import { BehaviorSubject, forkJoin, fromEvent, Observable, of, Subscription, throwError } from 'rxjs';
import { ProfileActionTypes, removeProfileStore, removeProfileRolesStore } from '../../../store/profile/profile.action';
import { RoleInterface } from '../../../models/interface/role.interface';
import { Actions, ofType } from '@ngrx/effects';
import { ProfileInterface } from '../../../models/interface/profile.interface';
import { HelperDialogService } from '../../../services/helper/helper-dialog/helper-dialog.service';
import { AuthenticationActionTypes, forcedLogoutStore } from '../../../store/authentication/authentication.action';
import { InterventionInstanceStore } from '../../../store/intervention-instance/component-store/intervention-instance.store';
import { MessageStore } from '../../../store/message/component-store/message.store';
import { ProfileStore } from '../../../store/profile/component-store/profile.store';
import { CustomCookieService } from 'src/app/services/customcookie/custom-cookie.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  providers: [InterventionInstanceStore, MessageStore, ProfileStore]
})
export class NavigationComponent implements OnInit, OnDestroy {
  public title = 'eSano - eCoach';

  // Icons
  faSignOutAlt = faSignOutAlt;
  faUserAlt = faUserAlt;
  faUserCircle = faUserCircle;
  faHome = faHome;
  faTasks = faTasks;
  faLanguage = faLanguage;
  faClock = faClock;
  faBookOpen = faBookOpen;
  faUsers = faUsers;
  faPen = faPen;
  faCommentDots = faCommentDots;
  faBell = faBell;
  faHospitalUser = faHospitalUser;
  faQuestionCircle = faQuestionCircle;
  faBullhorn = faBullhorn;
  faSortDown = faSortDown;
  faDownload = faDownload;
  faUsersCog = faUsersCog;

  // Authentication status
  public isAuthenticated$: Observable<boolean>;

  public isLoginSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public profile$: Observable<UserInterface>;
  public profileRoles$: Observable<Array<RoleInterface>>;

  public isAdminSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // Contains notification for navbar
  public myNotifications: Array<NotificationInterface> = [];

  public unreadFeedback;
  public unreadConversation;

  public showBanner = null;

  public currentRouteSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private isAuthed = false;

  private inboxFolder$: Observable<Array<MessageThreadsInterface>>;
  private inboxFolderAnswersheet$: Observable<Array<MessageThreadsInterface>>;

  private threads: Array<MessageThreadsInterface> = [];

  private instance$: Observable<InterventionInstanceInterface>;

  private threadDetails$: Observable<MessageThreadsInterface>;

  private unlockMyInstancesAsEcoachResponse$: Observable<any>;

  private updateProfileResponse$: Observable<any>;

  private subscriptions: Subscription[] = [];

  constructor(
    private helperService: HelperService,
    private helperDialogService: HelperDialogService,
    private translateService: TranslateService,
    private fcmMessageService: FcmMessageService,
    private customCookieService: CustomCookieService,
    private router: Router,
    private interventionInstanceStore: InterventionInstanceStore,
    private messageStore: MessageStore,
    private profileStore: ProfileStore,
    private store: Store<{ myProfile: ProfileInterface; myRoles: Array<RoleInterface>; isAuthenticated: boolean }>,
    private actions$: Actions
  ) {
    this.router.events.pipe(filter((event: Event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.isLoginSubject.next(event.url === '/login');
    });
    this.isAuthenticated$ = this.store.select('isAuthenticated');
    this.profile$ = this.store.select('myProfile');
    this.profileRoles$ = this.store.select('myRoles');
    this.instance$ = this.interventionInstanceStore.instance$;
    this.unlockMyInstancesAsEcoachResponse$ = this.interventionInstanceStore.unlockMyInstancesAsEcoachResponse$;
    this.inboxFolder$ = this.messageStore.inboxFolder$;
    this.inboxFolderAnswersheet$ = this.messageStore.inboxFolderAnswersheet$;
    this.threadDetails$ = this.messageStore.threadDetails$;
    this.updateProfileResponse$ = this.profileStore.updateProfileResponse$;
  }

  public get helper() {
    return this.helperService;
  }

  public get helperDialog() {
    return this.helperDialogService;
  }

  ngOnInit(): void {
    const source$ = fromEvent<StorageEvent>(window, 'storage');
    this.subscriptions.push(
      source$
        .pipe(
          filter(value => value.key === 'isAuthenticated'),
          distinctUntilKeyChanged('newValue'),
          switchMap((value: StorageEvent) => {
            if (!this.customCookieService.isCookieValid()) {
              this.store.dispatch(forcedLogoutStore());
            } else {
              const base64 = window.atob(localStorage.getItem('myProfile'));
              const myProfile = JSON.parse(base64);
              return this.profile$.pipe(
                take(1),
                switchMap((profile: UserInterface) => {
                  if (JSON.stringify(profile) !== JSON.stringify(myProfile)) {
                    this.store.dispatch({ type: ProfileActionTypes.getProfileType });
                    this.store.dispatch({ type: ProfileActionTypes.getProfileRolesType });
                    this.router.navigate([`/`]);
                  }
                  return of(true);
                })
              );
            }
            return of(true);
          })
        )
        .subscribe({})
    );

    // Get authentication state and profile
    this.subscriptions.push(
      this.isAuthenticated$
        .pipe(
          mergeMap(value => {
            if (value) {
              this.isAuthed = value;
            }
            return of(value);
          })
        )
        .subscribe(value => {
          if (value) {
            this.store.dispatch({ type: ProfileActionTypes.getProfileType });
            this.store.dispatch({ type: ProfileActionTypes.getProfileRolesType });
            this.store.dispatch({ type: ProfileActionTypes.getProfileActivitiesType, payload: { action: undefined, unlimited: true } });
            this.updateMessageCount();
          } else {
            this.logout();
          }
        })
    );
    const unlockAllInstances = () => {
      this.interventionInstanceStore.unlockMyInstancesAsEcoach({});
      return this.unlockMyInstancesAsEcoachResponse$.pipe(filter(value => !!value, take(1)));
    };

    const poll = of({}).pipe(
      mergeMap(_ => unlockAllInstances()),
      catchError(e => of(null)),
      tap(() => console.log(new Date())),
      delay(1800000),
      repeat()
    );

    poll.subscribe();

    this.subscriptions.push(
      this.profileRoles$.pipe(filter((roles: Array<RoleInterface>) => roles.length > 0)).subscribe(
        (roles: Array<RoleInterface>) => {
          this.isAdminSubject.next(this.helperService.hasRoles(roles, /admin$/));
        },
        () => {
          this.isAdminSubject.next(false);
        }
      )
    );

    // Translation
    this.translateService.use(localStorage.getItem('language'));

    // Notification created when notification to thread is non-existent otherwise summarize multiple notifications with a counter
    this.subscriptions.push(
      this.fcmMessageService.currentMessage.subscribe((value: any) => {
        this.updateMessageCount();
        if (value) {
          const notificationJson = this.helperService.getJSONObject(value.notification.body);
          let notificationType: 'ANSWERSHEET' | 'INTERVENTION_INSTANCE' | 'THREAD' | 'FEEDBACK';
          // Set notification type
          if (notificationJson) {
            const reqs: any = [];
            this.messageStore.getInboxFolder({});
            reqs.push(this.inboxFolder$.pipe(skip(1), take(1)));
            this.messageStore.getInboxFolder({ answersheetIdFlag: true });
            reqs.push(forkJoin(this.inboxFolderAnswersheet$.pipe(skip(1), take(1))));
            forkJoin(reqs).subscribe((result: any) => {
              let filterThread: Array<MessageThreadsInterface> = [];
              filterThread = filterThread.concat(result[0]);
              filterThread = filterThread.concat(result[1]);
              const found: MessageThreadsInterface = filterThread.find(
                (thread: MessageThreadsInterface) => thread.id.toString() === notificationJson.thread_id.toString()
              );
              notificationType = found && found.attributes.answersheet_id !== null ? 'FEEDBACK' : 'THREAD';
              this.pushNotification(value, notificationType);
            });
          } else {
            if (value.notification.body.includes('ANSWERSHEET_SUBMITTED')) {
              notificationType = 'ANSWERSHEET';
            } else {
              notificationType = 'INTERVENTION_INSTANCE';
            }
            this.pushNotification(value, notificationType);
          }
        }
      })
    );

    this.updateMessageCount();
  }

  public setPlatformLanguage(language: string): void {
    this.profileStore.updateProfile({ settings: { locale: language } });
    this.subscriptions.push(
      this.updateProfileResponse$.pipe(skip(1), take(1)).subscribe(() => {
        localStorage.setItem('language', language);
        this.translateService.use(language);
      })
    );
  }

  /**
   * This function sends a logout request.
   */
  public logout(): void {
    this.store.dispatch({ type: AuthenticationActionTypes.logoutType });
    this.subscriptions.push(
      this.actions$
        .pipe(ofType(AuthenticationActionTypes.logoutSuccessType, AuthenticationActionTypes.logoutErrorType), take(1))
        .subscribe(() => {
          this.store.dispatch(forcedLogoutStore());
        })
    );
  }

  /**
   * This function returns translation to notification.
   *
   * @return string A string needed for ngx-translation
   */
  public getNotificationTranslation(notification: NotificationInterface): string {
    if (notification.data.type === 'THREAD') {
      if (notification.count === 1) {
        return 'navbar.notification_you_have_a_new_message';
      } else {
        return 'navbar.notification_you_have_new_messages';
      }
    }
    if (notification.data.type === 'FEEDBACK') {
      return notification.data.subject;
    }
    if (notification.data.type === 'ANSWERSHEET') {
      return 'navbar.answersheet_submitted';
    }
    if (notification.data.type === 'INTERVENTION_INSTANCE') {
      if (notification.count === 1) {
        return 'navbar.intervention_instance_updated';
      } else {
        return 'navbar.intervention_instance_updated_multiple';
      }
    }
    return '';
  }

  /**
   * This function returns an notification object.
   *
   * @return Object An object that defines how many notifications belong to a subject
   */
  public getNotificationParams(notification: NotificationInterface):
    | { thread_name: string }
    | {
        thread_name: string;
        count: number;
      } {
    if (notification.count === 1) {
      return { thread_name: notification.data.subject };
    } else {
      return {
        thread_name: notification.data.subject,
        count: notification.count
      };
    }
  }

  /**
   * This function redirects to the component
   * TODO Implement redirections to component depending on the content of the notification
   *
   * @return string A string needed for ngx-translation
   */
  public redirectToComponent(notification: NotificationInterface): void {
    switch (notification.data.type) {
      case 'ANSWERSHEET': {
        this.router.navigate([`/feedback-overview/answersheets/${notification.data.answersheet_id}`]);
        break;
      }
      case 'THREAD': {
        this.router.navigate(['/conversations'], { queryParams: { thread_id: notification.data.thread_id } });
        break;
      }
      case 'FEEDBACK': {
        this.messageStore.getThreadDetails({ threadId: notification.data.thread_id });
        this.subscriptions.push(
          this.threadDetails$.pipe(skip(1), take(1)).subscribe(result => {
            const message: MessageThreadsInterface = result;
            this.router.navigateByUrl(`feedback-overview/answersheets/${message.attributes.answersheet_id}`);
          })
        );

        break;
      }
      case 'INTERVENTION_INSTANCE': {
        this.interventionInstanceStore.getInstance(notification.data.intervention_instance_id);
        this.subscriptions.push(
          this.instance$
            .pipe(
              filter(value => !!value),
              take(1)
            )
            .subscribe((result: InterventionInstanceInterface) => {
              const instance: InterventionInstanceInterface = result;
              this.router.navigate([`/patients/${instance.attributes.patient_id}/instances`]);
            })
        );
        break;
      }
      default: {
        break;
      }
    }
  }

  public updateMessageCount(): void {
    if (this.isAuthed) {
      const reqs: any = [];
      this.messageStore.getInboxFolder({});
      reqs.push(this.inboxFolder$.pipe(skip(1), take(1)));
      this.messageStore.getInboxFolder({ answersheetIdFlag: true });
      reqs.push(forkJoin(this.inboxFolderAnswersheet$.pipe(skip(1), take(1))));
      forkJoin(reqs).subscribe((result: any) => {
        let filterThread: Array<MessageThreadsInterface> = [];
        filterThread = filterThread.concat(result[0]);
        filterThread = filterThread.concat(result[1]);
        this.threads = filterThread;
        this.unreadFeedback = this.threads
          .filter((thread: MessageThreadsInterface) => thread.attributes?.answersheet_id > 0 && thread.attributes.unread.is_unread)
          .map((thread: MessageThreadsInterface) => thread.attributes.unread.messages)
          .reduce((acc, cur) => acc + cur, 0);
        this.unreadConversation = this.threads
          .filter((thread: MessageThreadsInterface) => thread.attributes?.answersheet_id === null && thread.attributes.unread.is_unread)
          .map((thread: MessageThreadsInterface) => thread.attributes.unread.messages)
          .reduce((acc, cur) => acc + cur, 0);
      });
    }
  }

  public pushNotification(
    value: NotificationInterface,
    notificationType: 'ANSWERSHEET' | 'INTERVENTION_INSTANCE' | 'THREAD' | 'FEEDBACK'
  ): void {
    const jsonObject = this.helperService.getJSONObject(value.notification.body);
    if (jsonObject) {
      const info: { thread_id: string; subject: string; answersheet_id: number } = jsonObject;

      const findWithAttr = (array: Array<any>, anonValue: any): number => {
        for (let i = 0; i < array.length; i += 1) {
          if (!anonValue) {
            return -1;
          }
          if (array[i].data.thread_id.toString() === anonValue.toString()) {
            return i;
          }
        }
        return -1;
      };

      const notificationIndex: number = findWithAttr(this.myNotifications, info.thread_id);

      if (notificationIndex !== -1) {
        this.myNotifications[notificationIndex].count++;
      } else {
        let notificationInstance: NotificationInterface;

        const answersheetSubject = 'ANSWERSHEET_SUBMITTED';
        if (value.notification.body.includes('FEEDBACK_REQUIRED:TRUE') || value.notification.body.includes('ANSWERSHEET_SUBMITTED')) {
          const answersheetId = parseInt(
            value.notification.title.replace('ANSWERSHEET_SUBMITTED:', '').replace('FEEDBACK_REQUIRED:TRUE', ''),
            10
          );
          notificationInstance = {
            data: {
              thread_id: value.data.thread_id,
              subject: answersheetSubject,
              intervention_instance_id: value.data.intervention_instance_id,
              type: notificationType,
              answersheet_id: answersheetId
            },
            from: value.from,
            priority: value.priority,
            count: 1
          };
          this.myNotifications.push(notificationInstance);
        } else {
          notificationInstance = {
            data: {
              thread_id: jsonObject.thread_id,
              subject: jsonObject.subject,
              intervention_instance_id: -1,
              type: notificationType
            },
            from: value.from,
            priority: value.priority,
            count: 1
          };
          this.myNotifications.push(notificationInstance);
        }
      }
    } else {
      const id = parseInt(value.notification.title, 10);
      const findWithAttr = (array, anonValue): number => {
        for (let i = 0; i < array.length; i += 1) {
          if (!anonValue) {
            return -1;
          }
          if (
            array[i].data.intervention_instance_id.toString() === anonValue.id.toString() &&
            anonValue.body.includes('INTERVENTION_INSTANCE')
          ) {
            return i;
          }
        }
        return -1;
      };
      const notificationIndex = findWithAttr(this.myNotifications, { id, body: value.notification.body });
      if (notificationIndex !== -1) {
        this.myNotifications[notificationIndex].count++;
      } else {
        let notificationInstance: NotificationInterface;
        if (value.notification.body.includes('ANSWERSHEET_SUBMITTED')) {
          const answersheetId = parseInt(value.notification.title, 10);
          notificationInstance = {
            data: {
              thread_id: null,
              subject: 'navbar.answersheet_submitted',
              intervention_instance_id: null,
              type: notificationType,
              answersheet_id: answersheetId
            },
            from: value.from,
            priority: value.priority,
            count: 1
          };
          this.myNotifications.push(notificationInstance);
        } else if (value.notification.body.includes('INTERVENTION_INSTANCE')) {
          const instanceId = parseInt(value.notification.title, 10);
          notificationInstance = {
            data: {
              thread_id: jsonObject.thread_id,
              subject: 'navbar.intervention_instance_updated',
              intervention_instance_id: instanceId,
              type: notificationType
            },
            from: value.from,
            priority: value.priority,
            count: 1
          };
          this.myNotifications.push(notificationInstance);
        }
      }
    }
  }

  public redirectToHome(): void {
    this.router.navigate([`/`]);
  }

  public redirectToAdmin(): void {
    this.router.navigate([`/admin`]);
  }

  public displayAnnouncements(): void {
    this.showBanner = !this.showBanner;
  }

  public removeProfile(): void {
    this.store.dispatch(removeProfileStore());
    this.store.dispatch(removeProfileRolesStore());
  }

  public openDialogDownloadReport(): void {
    this.helperDialog
      .openDialogDownloads()
      .afterClosed()
      .subscribe(() => {});
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
