/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, of } from 'rxjs';
import { InterventionInterface } from 'src/app/models/interface/intervention.interface';
import { UserInterface } from 'src/app/models/interface/user.interface';
import { SkillInterface } from '../../../models/interface/skill.interface';
import { PatientCreationInterventionSelectionComponent } from '../../../modules/patient/patient-creation-intervention-selection/patient-creation-intervention-selection.component';
import { SkillService } from '../../api/skill/skill.service';

@Injectable({
  providedIn: 'root'
})
export class HelperSkillService {
  constructor(private skillService: SkillService) {}

  public getColorStyle(skillId: number, skills: Array<SkillInterface> = []): { color: string } {
    let color = '#C8191C';
    const found = skills.find((value: SkillInterface) => value.id.toString() === skillId.toString());
    if (found) {
      color = found.attributes.color ? found.attributes.color : color;
    }
    return {
      color: found ? found.attributes.color : color
    };
  }

  public getSkillsOfSelectedIntervention(
    foundInterventionSelect: PatientCreationInterventionSelectionComponent,
    interventionsSubject?: BehaviorSubject<Array<number>>
  ): Array<Observable<any>> {
    if (interventionsSubject) {
      const selectedInterventions = foundInterventionSelect.selectedInterventionSubject.value
        .map(
          (selection: { study_id: number; interventions: Array<InterventionInterface>; collaborators: Array<UserInterface> }) =>
            selection.interventions
        )
        .flatMap(interventions => interventions);
      interventionsSubject.next(selectedInterventions.map(value => value.id));
    }
    const reqs = [];
    foundInterventionSelect.selectedInterventionSubject.value.forEach(studyInterventions => {
      studyInterventions.interventions.forEach(intervention => {
        reqs.push(this.skillService.getAllSkillsOfIntervention(intervention.id).pipe(catchError(error => of(null))));
      });
    });
    return reqs;
  }
}
