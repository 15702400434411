/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RequestBodyData } from '../../../models/request-body.data';
import { PayloadInterface } from '../../../models/interface/payload.interface';
import { HelperService } from '../../helper/helper.service';

/**
 * Service:
 * Organisation API service that handles organisation-related requests
 */

@Injectable({
  providedIn: 'root'
})
export class OrganisationService {
  public header: HttpHeaders = new HttpHeaders();
  private backendUrl: string = environment.backendURL;

  constructor(private http: HttpClient, private helperService: HelperService) {
    this.header = this.header.set('Content-Type', 'application/json');
    this.header = this.helperService.setLocaleFromStorage(this.header);
  }

  /**
   * AAS2 API Admin - Create Organisation
   * This function creates a new organisation.
   *
   * @params string name - A string determining the name of the organisation.
   *         number owner_id - ID of the user
   * @return Observable<any> - An observable for any response.
   */
  public createOrganisationAdmin(name: string, ownerId?: number): Observable<any> {
    const organisation = {
      name,
      owner_id: ownerId
    };
    for (const prop in organisation) {
      if (Object.prototype.hasOwnProperty.call(organisation, prop)) {
        switch (prop) {
          case 'owner_id': {
            if (ownerId === undefined) {
              delete organisation.owner_id;
            }
            break;
          }
          default:
            break;
        }
      }
    }
    return this.http.post<any>(`${this.backendUrl}/api/v1/admin/organisations`, new RequestBodyData('organisations', organisation), {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API User - Get Organisation
   * This function returns organisation of a user.
   *
   * @params number organisationId - ID of the organisation.
   * @return Observable<any> - An observable for any response.
   */
  public getOrganisation(organisationId: number): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/api/v1/organisations/${organisationId}`, { observe: 'response' });
  }

  /**
   * AAS2 API User - Get all Studies of Organisation
   * This function returns all studies of an organisation.
   *
   * @params number organisationId - ID of the organisation.
   *         string include - A string including additional information.
   *           - typically 'collaborators'
   * @return Observable<any> - An observable for any response.
   */
  public getStudiesOfOrganisation(organisationId: number, include?: string): Observable<any> {
    let params = new HttpParams();
    if (include !== undefined) {
      params = params.set('include', include);
    }
    return this.http.get<any>(`${this.backendUrl}/api/v1/organisations/${organisationId}/studies?limit=0`, {
      headers: this.header,
      observe: 'response',
      params
    });
  }

  /**
   * AAS2 API User - Get all Collaborators of Organisation
   * This function returns all collaborators of an organisation.
   *
   * @params number organisationId - ID of the organisation.
   * @return Observable<any> - An observable for any response.
   */
  public getCollaboratorsOfOrganisation(organisationId: number): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/api/v1/organisations/${organisationId}/collaborators?limit=0`, { observe: 'response' });
  }

  /**
   * AAS2 API ECoach - Add Collaborators to organisation
   * This function adds collaborators to an organisation.
   *
   * @params number organisationId - ID of the organisation.
   *         Array<{id: number, role: number}> users - Contains a list of users and their roles.
   * @return Observable<any> - An observable for any response.
   */
  public addCollaborators(organisationId: number, payload: PayloadInterface): Observable<any> {
    return this.http.post<any>(
      `${this.backendUrl}/api/v1/organisationmanager/organisations/${organisationId}/relationships/collaborators`,
      payload,
      { headers: this.header, observe: 'response' }
    );
  }

  /**
   * AAS2 API ECoach - Remove Collaborators from organisation
   * This function deletes collaborators from an organisation or remove their roles.
   *
   * @params number organisationId - ID of the organisation.
   *         Array<{id: number, role: number}> users - Contains a list of users and their roles.
   * @return Observable<any> - An observable for any response.
   */
  public deleteCollaborators(organisationId: number, payload: PayloadInterface): Observable<any> {
    return this.http.request<any>(
      `delete`,
      `${this.backendUrl}/api/v1/organisationmanager/organisations/${organisationId}/relationships/collaborators`,
      { body: payload, headers: this.header, observe: 'response' }
    );
  }

  /**
   * AAS2 API Admin - Get Organisations as administrator
   * This function returns all organisations.
   *
   * @params string include  - Returns additional information 'collaborators' and 'owners'.
   * @return Observable<any> - An observable for any response.
   */
  public getOrganisationsAdmin(include?: string, id?: number): Observable<any> {
    let params = new HttpParams();
    if (include !== undefined) {
      params = params.set('include', include);
    }
    if (id !== undefined) {
      params = params.set('id', id);
    }
    return this.http.get<any>(`${this.backendUrl}/api/v1/admin/organisations?limit=0`, { observe: 'response', params });
  }

  /**
   * AAS2 API User - Get Organisations
   * This function returns all organisations.
   *
   * @params string include  - Returns additional information 'collaborators', 'roles' and 'owners'.
   * @return Observable<any> - An observable for any response.
   */
  public getOrganisations(include?: string, id?: number): Observable<any> {
    let params = new HttpParams();
    if (include !== undefined) {
      params = params.set('include', include);
    }
    if (id !== undefined) {
      params = params.set('id', id);
    }
    return this.http.get<any>(`${this.backendUrl}/api/v1/organisations?limit=0`, { observe: 'response', params });
  }

  /**
   * AAS2 API Admin - (Soft-)Delete an organisation
   * This function deletes an organisation.
   *
   * @params number organisationId - ID of the organisation.
   * @return Observable<any> - An observable for any response.
   */
  public deleteOrganisationAdmin(organisationId: number): Observable<any> {
    return this.http.request<any>(`delete`, `${this.backendUrl}/api/v1/admin/organisations/${organisationId}`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API Organisation manager/owner - Create Account as Organisation manager/owner
   * This function creates a new account as organisation manager/owner and assigns the account to the respective organisation.
   * The created account is automatically assigned as a global eCoach role and has the organisation.access role.
   *
   * @params string email - Email of the account.
   *         Array<number> organisations_ids - IDs of the assigning organisations
   *         string name - Unique username
   *         string firstname - First name of the account
   *         string lastname - Last name of the account
   * @return Observable<any> - An observable for any response.
   */
  public createAccountAsOrganisationManager(
    email: string,
    organisations_ids: Array<number>,
    name: string,
    firstname: string,
    lastname: string
  ): Observable<any> {
    this.header = this.helperService.setLocaleFromStorage(this.header);
    const reqBody: PayloadInterface = new RequestBodyData('users', {
      email,
      organisations_ids
    });
    if (name) {
      reqBody.data.attributes.name = name;
    }
    if (firstname) {
      reqBody.data.attributes.firstname = firstname;
    }
    if (lastname) {
      reqBody.data.attributes.lastname = lastname;
    }
    return this.http.post<any>(`${this.backendUrl}/api/v1/organisationmanager/auth/assign/register`, reqBody, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API Organisation manager/owner - Add Existing Account to Organisation as Organisation manager/owner
   * This function adds an existing account as organisation manager/owner to the organisation.
   * The created account is automatically assigned as a global eCoach role and has the organisation.access role.
   *
   * @params string email - Email of the account.
   *         Array<number> organisations_ids - IDs of the assigning organisations
   *         string name - Unique username
   *         string firstname - First name of the account
   *         string lastname - Last name of the account
   * @return Observable<any> - An observable for any response.
   */
  public addECoachAccountToOrganisation(emails: Array<string>, organisations_ids: Array<number>): Observable<any> {
    const reqBody: PayloadInterface = new RequestBodyData('organisations', {
      emails,
      organisations: organisations_ids
    });
    return this.http.post<any>(`${this.backendUrl}/api/v1/organisationmanager/organisations/collaborators`, reqBody, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API OrganisationManager/OrganisationOwner - Delete an unverified eCoach account of the organisation
   * This function removes a pending unverified account created in the organisation with everything related to the user's account.
   *
   * @params Array<number> user_ids - List of user ids.
   *         number organisationId - ID of the organisation.
   * @return Observable<any> - An observable for any response.
   */
  public deleteUnverifiedOrganisationAccount(organisationId: number, userIds: Array<number>): Observable<any> {
    const payload: PayloadInterface = {
      data: {
        type: 'users',
        attributes: {
          users: userIds
        }
      }
    };
    return this.http.request<any>(
      'delete',
      `${this.backendUrl}/api/v1/organisationmanager/organisations/${organisationId}/users/nonverified`,
      {
        body: payload,
        headers: this.header,
        observe: 'response'
      }
    );
  }
}
