import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HelperService } from '../../../services/helper/helper.service';
import { faHands } from '@fortawesome/free-solid-svg-icons/faHands';
import { faHandsHelping } from '@fortawesome/free-solid-svg-icons/faHandsHelping';
import { faBook } from '@fortawesome/free-solid-svg-icons/faBook';
import { BuddyInstanceInterface } from '../../../models/interface/buddy/buddy-instance.interface';
import { UserInterface } from '../../../models/interface/user.interface';
import { BehaviorSubject, filter, Observable, skip, Subscription, take } from 'rxjs';
import { BuddyStore } from '../../../store/buddy/component-store/buddy.store';
import { HttpResponse } from '@angular/common/http';
import { HelperActivityService } from '../../../services/helper/helper-activity/helper-activity.service';
import { BuddyActivityInterface } from '../../../models/interface/buddy/buddy-activity.interface';

@Component({
  selector: 'app-dialog-buddy-instance-delete',
  templateUrl: './dialog-buddy-instance-delete.component.html',
  styleUrls: ['./dialog-buddy-instance-delete.component.css'],
  providers: [BuddyStore]
})
export class DialogBuddyInstanceDeleteComponent implements OnInit, OnDestroy {
  // Icons
  faBook = faBook;
  faHands = faHands;
  faHandsHelping = faHandsHelping;

  public buddyInstance: BuddyInstanceInterface;
  public members: Array<UserInterface> = [];
  public studyCollaborators: Array<UserInterface> = [];

  public activities: Array<BuddyActivityInterface> = [];
  public pagedActivitySubject: BehaviorSubject<
    Array<{ pair1: BuddyActivityInterface; pair2: BuddyActivityInterface; localizedDate: string }>
  > = new BehaviorSubject<Array<{ pair1: BuddyActivityInterface; pair2: BuddyActivityInterface; localizedDate: string }>>([]);
  public pagedActivity$: Observable<Array<{ pair1: BuddyActivityInterface; pair2: BuddyActivityInterface; localizedDate: string }>> =
    this.pagedActivitySubject.asObservable();

  public buddyActivities$: Observable<Array<BuddyActivityInterface>>;

  public buddyActivitiesPair: Array<{ pair1: BuddyActivityInterface; pair2: BuddyActivityInterface; localizedDate: string }> = [];

  public isLoadingActivities$: Observable<boolean>;

  public deleteBuddyResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');

  public studyId: number;

  private isLoadingActivitiesSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private subscriptions: Subscription[] = [];

  private deleteBuddyResponse$: Observable<any>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<DialogBuddyInstanceDeleteComponent>,
    private buddyStore: BuddyStore,
    private helperService: HelperService,
    private helperActivityService: HelperActivityService
  ) {
    this.isLoadingActivities$ = this.isLoadingActivitiesSubject.asObservable();
    this.buddyActivities$ = this.buddyStore.allBuddyActivitiesOfBuddy$;
    this.deleteBuddyResponse$ = this.buddyStore.deleteBuddyResponse$;
  }

  public get helper() {
    return this.helperService;
  }

  public get helperActivity() {
    return this.helperActivityService;
  }

  ngOnInit(): void {
    this.buddyInstance = this.data.buddyInstance;
    this.members = this.data.members;
    this.studyCollaborators = this.data.studyCollaborators;
    this.studyId = this.data.studyId;

    this.buddyStore.getBuddyActivities(this.buddyInstance.id);
    this.subscriptions.push(
      this.buddyActivities$.pipe(skip(1), take(1)).subscribe(
        (result: any) => {
          this.activities = result;
          this.activities = this.helper.getBuddyActivityByNewest(this.activities);
          this.pairBuddy();
          this.pagedActivitySubject.next(this.buddyActivitiesPair.slice(0, 20));
          this.isLoadingActivitiesSubject.next(false);
        },
        () => {
          this.activities = [];
          this.pagedActivitySubject.next([]);
          this.isLoadingActivitiesSubject.next(false);
        }
      )
    );
  }

  public updatePagedActivities(event: any) {
    if (event) {
      this.pagedActivitySubject.next(event);
    }
  }

  public hasSentReminder(buddyActivityPair: {
    pair1: BuddyActivityInterface;
    pair2: BuddyActivityInterface;
    localizedDate: string;
  }): boolean {
    const reminder1 = buddyActivityPair?.pair1?.attributes.daily_reminder_timestamps;
    const reminder2 = buddyActivityPair?.pair2?.attributes.daily_reminder_timestamps;
    return (!!reminder1 || !!reminder2) && reminder1 !== 0 && reminder2 !== 0;
  }

  public getBuddyState(buddyActivityPair: { pair1: BuddyActivityInterface; pair2: BuddyActivityInterface; localizedDate: string }): string {
    const task1 = buddyActivityPair?.pair1?.attributes.daily_task_timestamps;
    const reminder1 = buddyActivityPair?.pair1?.attributes.daily_reminder_timestamps;
    const task2 = buddyActivityPair?.pair2?.attributes.daily_task_timestamps;
    const reminder2 = buddyActivityPair?.pair2?.attributes.daily_reminder_timestamps;
    if (!!task1 && !!task2) {
      return 'both_daily_task_completed';
    } else if (!!task1 || !!task2) {
      if (!!reminder1 || !!reminder2) {
        return 'one_daily_task_completed_with_reminder';
      }
      return 'one_daily_task_completed';
    } else {
      return 'no_daily_task_completed';
    }
  }

  public pairBuddy(): void {
    const buddyActivitiesPair1: Array<{ pair1: BuddyActivityInterface; pair2: BuddyActivityInterface; localizedDate: string }> = [];
    this.activities.forEach((activity: BuddyActivityInterface) => {
      const localizeDate = this.helper.localizeDate(activity.attributes.created_at);
      if (!buddyActivitiesPair1.find(pair => pair.localizedDate.toString() === localizeDate.toString())) {
        const buddyAct: BuddyActivityInterface = this.activities.find(
          (buddyActivity: BuddyActivityInterface) =>
            this.helper.localizeDate(buddyActivity.attributes.created_at) === localizeDate &&
            buddyActivity.attributes.user_id.toString() !== activity.attributes.user_id.toString()
        );
        buddyActivitiesPair1.push({ pair1: activity, pair2: buddyAct, localizedDate: localizeDate });
      }
    });
    this.buddyActivitiesPair = buddyActivitiesPair1;
  }

  public deleteBuddyInstance(buddyInstance: BuddyInstanceInterface): void {
    if (this.deleteBuddyResponse.value === 'DEFAULT') {
      this.deleteBuddyResponse.next('LOADING');
      this.buddyStore.deleteBuddy(buddyInstance.id);
      this.subscriptions.push(
        this.deleteBuddyResponse$
          .pipe(
            filter(value => !!value),
            take(1)
          )
          .subscribe((result: any) => {
            if (result instanceof HttpResponse) {
              this.deleteBuddyResponse.next('SUCCESS');
              setTimeout(() => {
                this.dialogRef.close(true);
                this.deleteBuddyResponse.next('DEFAULT');
                this.dialogRef.close();
              }, 2500);
            } else {
              this.deleteBuddyResponse.next('FAILURE');
              setTimeout(() => {
                this.deleteBuddyResponse.next('DEFAULT');
              }, 2500);
            }
          })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
