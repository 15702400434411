<div class="container-fluid text-center">
  <mat-tab-group class="my-3" animationDuration="0ms" mat-stretch-tabs>
    <mat-tab label="{{'intervention.all_interventions' | translate}}">
      <div class="card my-3">
        <div class="card-body">
          <div class="row mt-3">
            <div class="col-12 col-sm-3 mb-2">
              <button
                [attr.id]="'btnToggleFilter'"
                (click)="helper.toggleSubject(isCollapseSubject)"
                class="mt-1"
                mat-raised-button
                type="submit"
              >
                <fa-icon [icon]="faList"></fa-icon>
                Filter
              </button>
            </div>

            <div class="col-12 col-sm-6 mb-2">
              <mat-form-field [style.width.%]="100" appearance="outline">
                <mat-label>{{'intervention.search_intervention' | translate}}</mat-label>
                <input
                  [attr.id]="'search'"
                  (keyup.enter)="applyInterventionFilter()"
                  [(ngModel)]="filter['searchFilter']"
                  aria-describedby="interventionSearch"
                  aria-label="Search"
                  matInput
                  type="text"
                />
                <button
                  [attr.id]="'btnSearchIntervention'"
                  (click)="applyInterventionFilter()"
                  aria-label="Search"
                  mat-button
                  mat-button
                  matSuffix
                >
                  <mat-icon>search</mat-icon>
                </button>
              </mat-form-field>
            </div>
          </div>

          <app-pagination-list
            #paginatorStudy2
            (emitPageChange)="updatePagedInterventions($event)"
            [_isLoading]="isLoading$ | async"
            [_items]="interventions$ | async"
          ></app-pagination-list>

          <div class="row justify-content-center">
            <div
              *ngIf="(isCollapse$ | async) === false"
              [ngClass]="{'col-12': (isCollapse$ | async) === false, 'col-md-3': (isCollapse$ | async) === false, 'col-align-self-start': (isCollapse$ | async) === false}"
              class="text-start"
            >
              <div class="mb-2">
                <h3>{{'intervention.intervention_collab_header' | translate}}</h3>
                <mat-checkbox
                  [attr.id]="'checkboxIsCollaboratingOnly'"
                  (change)="applyInterventionFilter()"
                  [(ngModel)]="filter['isCollaboratingOnly']"
                  >{{'intervention.intervention_collaboration' | translate }}</mat-checkbox
                >
              </div>

              <mat-divider></mat-divider>

              <label class="mt-2" id="filter-radio-group-label">{{'intervention.type_intervention' | translate}}</label>
              <mat-radio-group
                [attr.id]="'radioTypeOfIntervention'"
                aria-label="select guidance type"
                aria-labelledby="filter-radio-group-label"
                class="filter-radio-group"
                [(ngModel)]="filter['typeOfIntervention']"
                (change)="applyInterventionFilter()"
              >
                <mat-radio-button [attr.id]="'radioItem0'" class="filter-radio-button" value="0"
                  >{{'intervention.no_selection' | translate}}</mat-radio-button
                >
                <mat-radio-button [attr.id]="'radioItem1'" class="filter-radio-button" value="1"
                  >{{'intervention.unguided' | translate}}</mat-radio-button
                >
                <mat-radio-button [attr.id]="'radioItem2'" class="filter-radio-button" value="2"
                  >{{'intervention.guided' | translate}}</mat-radio-button
                >
              </mat-radio-group>
            </div>

            <div class="col-12 col-md-9">
              <div *ngIf="isLoading$ | async; then thenLoadingBlock else elseInterventionBlock"></div>

              <ng-template #thenLoadingBlock>
                <div class="col-sm">
                  <app-loader></app-loader>
                </div>
              </ng-template>

              <ng-template #elseInterventionBlock>
                <div
                  *ngFor="let intervention of pagedInterventions$ | async; let i = index; trackBy: helper.trackByElement"
                  class="justify-content-center"
                >
                  <app-card-intervention [_intervention]="intervention" [_isCollab]="isCollabId(intervention.id)"> </app-card-intervention>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        {{'intervention.all_reviewing_interventions' | translate}}
        <fa-icon *ngIf="hasPendingReviewingInterventions" class="margin-tab-icon icon-color" [icon]="faExclamation"></fa-icon>
      </ng-template>
      <div class="card my-3">
        <div class="card-body">
          <app-intervention-review></app-intervention-review>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
