<div *ngIf="(isLoading$ | async) === false" class="container-fluid text-center">
  <div class="text-start">
    <h1>{{ 'profile.my_profile' | translate }}</h1>
    <hr />
  </div>
  <h4>
    {{ 'profile.overview_of_profile' | translate }}
    <button [attr.id]="'btnToggleDescription'" (click)="helper.toggleSubject(showDescriptionSubject)" aria-label="Information" mat-button>
      <fa-icon [icon]="faInfoCircle"></fa-icon>
    </button>
  </h4>
  <div *ngIf="showDescription$ | async" class="row justify-content-center">
    <div class="col-sm-9">
      <p class="text-justify">{{ 'profile.description_profile' | translate }}</p>
    </div>
  </div>

  <div class="row justify-content-center">
    <!-- Grid column -->
    <div class="col-sm-9 text-md-left">
      <section class="row mb-3">
        <div class="col-sm-4">{{'profile.email' | translate}}:</div>
        <div class="col-sm-8">{{userData.attributes.email}}</div>
      </section>

      <section class="mb-3">
        <form [formGroup]="profileEditForm" class="mb-3">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>{{'profile.my_profile_username' | translate}}</mat-label>
            <input
              [attr.id]="'name'"
              formControlName="name"
              matInput
              maxlength="255"
              type="text"
              value="{{userData.attributes.name}}"
              [readonly]="true"
            />
            <mat-hint *ngIf="!userData.attributes.name || (userData.attributes.name === '')" class="text-danger"
              >{{'profile.no_username_information' | translate}}</mat-hint
            >
            <mat-error *ngIf="f.name.errors?.required">{{'profile.error_is_required' | translate}}</mat-error>
            <mat-error *ngIf="f.name.errors?.maxlength">{{'profile.error_max_255_characters' | translate}}</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label>{{'profile.my_profile_firstname' | translate}}</mat-label>
            <input
              [attr.id]="'firstnam'"
              formControlName="firstname"
              matInput
              maxlength="255"
              type="text"
              value="{{userData.attributes.firstname}}"
            />
            <mat-error *ngIf="f.firstname.errors?.required">{{'profile.error_is_required' | translate}}</mat-error>
            <mat-error *ngIf="f.firstname.errors?.maxlength">{{'profile.error_max_255_characters' | translate}}</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label>{{'profile.my_profile_lastname' | translate}}</mat-label>
            <input
              [attr.id]="'lastname'"
              formControlName="lastname"
              matInput
              maxlength="255"
              type="text"
              value="{{userData.attributes.lastname}}"
            />
            <mat-error *ngIf="f.lastname.errors?.required">{{'profile.error_is_required' | translate}}</mat-error>
            <mat-error *ngIf="f.lastname.errors?.maxlength">{{'profile.error_max_255_characters' | translate}}</mat-error>
          </mat-form-field>

          <div class="row mb-3">
            <div class="col-sm-4">{{'profile.my_profile_roles' | translate}}:</div>
            <div class="col-sm-8">
              <mat-chip-listbox *ngIf="userRoles.length > 0, else elseNoRoleBlock">
                <mat-chip
                  [attr.id]="'role_' + role.toLowerCase()"
                  color="primary"
                  *ngFor="let role of userRoles"
                  matTooltip="{{getRoleTip(role) | translate}}"
                  selected
                >
                  {{('profile.' + role.toLowerCase()) | translate}}
                </mat-chip>
              </mat-chip-listbox>
              <ng-template #elseNoRoleBlock>
                <p class="text-sm-left">{{'profile.no_role_available' | translate}}</p>
              </ng-template>
            </div>
          </div>

          <div class="row">
            <label class="col-sm-4" id="radio-group-label">{{'profile.my_profile_gender' | translate}}:</label>

            <mat-radio-group
              [attr.id]="'radioGender'"
              aria-labelledby="radio-group-label"
              class="col-sm-8 gender-radio-group"
              formControlName="gender"
            >
              <mat-radio-button [attr.id]="'itemFemale'" [checked]="isGender('0')" class="gender-radio-button" value="0">
                {{'profile.my_profile_female' | translate}}
              </mat-radio-button>
              <mat-radio-button [attr.id]="'itemMale'" [checked]="isGender('1')" class="gender-radio-button" value="1">
                {{'profile.my_profile_male' | translate}}
              </mat-radio-button>
              <mat-radio-button [attr.id]="'itemDiverse'" [checked]="isGender('2')" class="gender-radio-button" value="2">
                {{'profile.my_profile_diverse' | translate}}
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="d-flex flex-row-reverse mb-3">
            <app-button-feedback
              [_setId]="'btnUpdateProfile'"
              [_isDisabled]="!userData.attributes.name || (userData.attributes.name === '')"
              (click)="updateProfile()"
              [_defaultButtonText]="'profile.save_changes'"
              [_successButtonText]="'profile.form_saved'"
              [_errorButtonText]="'profile.form_failed'"
              [_responseText]="updateProfileResponse | async"
            ></app-button-feedback>
          </div>
        </form>
      </section>

      <section class="mb-3">
        <h4>{{'profile.change_your_password' | translate}}</h4>
        <p>{{'profile.password_requirements' | translate}}</p>
        <p class="text-danger" *ngIf="showLeakPasswordInfo">{{'profile.error_password_leak' | translate}}</p>
        <form [formGroup]="passwordChangeForm">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>{{'profile.old_password' | translate}}</mat-label>
            <input
              [attr.id]="'old_password'"
              autocomplete="off"
              formControlName="old_password"
              matInput
              maxlength="255"
              required
              type="password"
              value=""
            />
            <mat-error *ngIf="g.old_password.errors?.required">{{'profile.error_is_required' | translate}}</mat-error>
            <mat-error *ngIf="g.old_password.errors?.maxlength">{{'profile.error_max_255_characters' | translate}}</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label>{{'profile.new_password' | translate}}</mat-label>
            <input
              [attr.id]="'new_password'"
              [type]="hideNewPassword ? 'password' : 'text'"
              autocomplete="off"
              formControlName="new_password"
              matInput
              maxlength="255"
              required
              type="password"
              value=""
            />
            <button
              (click)="hideNewPassword = !hideNewPassword"
              [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hideNewPassword"
              mat-button
              matSuffix
            >
              <mat-icon>{{hideNewPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-error *ngIf="g.new_password.errors?.required">{{'profile.error_is_required' | translate}}</mat-error>
            <mat-error *ngIf="g.new_password.errors?.maxlength">{{'profile.error_max_255_characters' | translate}}</mat-error>
            <mat-error *ngIf="g.new_password.errors?.pattern">{{'profile.error_password_requirements' | translate}}</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label>{{'profile.confirm_password' | translate}}</mat-label>
            <input
              [attr.id]="'confirm_password'"
              autocomplete="off"
              formControlName="confirm_password"
              matInput
              maxlength="255"
              required
              type="password"
              value=""
            />
            <mat-error *ngIf="g.confirm_password.errors?.required">{{'profile.error_is_required' | translate}}</mat-error>
            <mat-error *ngIf="g.confirm_password.errors?.maxlength">{{'profile.error_max_255_characters' | translate}}</mat-error>
            <mat-error *ngIf="g.confirm_password.errors?.pattern">{{'profile.error_password_requirements' | translate}}</mat-error>
            <mat-error *ngIf="g.confirm_password.errors?.mustMatch">{{'profile.error_password_matching' | translate}}</mat-error>
          </mat-form-field>

          <div class="d-flex flex-row-reverse mb-3">
            <app-button-feedback
              [_setId]="'btnChangePassword'"
              [_isDisabled]="passwordChangeForm.invalid"
              (click)="changePassword()"
              [_defaultButtonText]="'profile.update_password'"
              [_successButtonText]="'profile.form_saved'"
              [_errorButtonText]="'profile.form_failed'"
              [_responseText]="updatePasswordResponse | async"
            ></app-button-feedback>
          </div>
        </form>
      </section>
    </div>
  </div>
</div>
