/* eslint-disable @typescript-eslint/naming-convention */
import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, forkJoin, iif, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, skip, switchMap, take } from 'rxjs/operators';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { InstanceCreationFormComponent } from '../../intervention-instance/instance-creation-form/instance-creation-form.component';
import { PatientCreationInstanceCreationComponent } from '../../patient/patient-creation-instance-creation/patient-creation-instance-creation.component';
import { PatientCreationAccountComponent } from '../../patient/patient-creation-account/patient-creation-account.component';
import { PatientCreationInterventionSelectionComponent } from '../../patient/patient-creation-intervention-selection/patient-creation-intervention-selection.component';
import { RequestBodyData } from '../../../models/request-body.data';
import { InterventionInstanceInterface } from '../../../models/interface/intervention-instances/intervention-instance.interface';
import { InterventionInterface } from '../../../models/interface/intervention.interface';
import { UserInterface } from '../../../models/interface/user.interface';
import { LessonInterface } from '../../../models/interface/lesson.interface';
import { InstanceCreationConfirmationDialogComponent } from '../../intervention-instance/instance-creation-confirmation-dialog/instance-creation-confirmation-dialog.component';
import { PayloadInterface } from '../../../models/interface/payload.interface';
import { InvitationInterface } from '../../../models/interface/invitation.interface';
import { AlertService } from '../../../services/alert/alert.service';
import { environment } from '../../../../environments/environment';
import { DiaryInterface } from '../../../models/interface/diary.interface';
import { SkillInterface } from '../../../models/interface/skill.interface';
import { HelperSkillService } from '../../../services/helper/helper-skill/helper-skill.service';
import { Store } from '@ngrx/store';
import { StudyActionTypes } from '../../../store/study/study.action';
import { UserActionTypes } from '../../../store/user/user.action';
import { DiaryStore } from '../../../store/diary/component-store/diary.store';
import { InterventionStore } from '../../../store/intervention/component-store/intervention.store';
import { InterventionInstanceStore } from '../../../store/intervention-instance/component-store/intervention-instance.store';
import { QuestionnaireStore } from '../../../store/lesson-questionnaire/component-store/lesson-questionnaire.store';
import { getCollaboratorsByMultipleStudyIds, getCollaboratorsByStudyId } from '../../../store/study/study.selector';
import { StudyStore } from '../../../store/study/component-store/study.store';
import { UserStore } from '../../../store/user/component-store/user.store';
import { ReminderStore } from '../../../store/reminder/component-store/reminder.store';
import { HelperService } from '../../../services/helper/helper.service';
import { StudyInterface } from '../../../models/interface/study/study.interface';
import { Actions, ofType } from '@ngrx/effects';
import { PatientInvitationStudyComponent } from '../../patient/patient-invitation-study/patient-invitation-study.component';
import { ProfileInterface } from '../../../models/interface/profile.interface';
import { UntypedFormArray } from '@angular/forms';

@Component({
  selector: 'app-dialog-account-intervention-instance-assignment',
  templateUrl: './dialog-account-intervention-instance-assignment.component.html',
  styleUrls: ['./dialog-account-intervention-instance-assignment.component.scss'],
  providers: [DiaryStore, InterventionStore, InterventionInstanceStore, QuestionnaireStore, StudyStore, UserStore, ReminderStore]
})
export class DialogAccountInterventionInstanceAssignmentComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('scroll', { read: ElementRef }) public scrollRef: ElementRef;
  @ViewChild('accountAssignmentContainer', { read: ViewContainerRef }) accountAssignmentContainer: ViewContainerRef;
  @ViewChild('appScrollElement', { read: ElementRef }) public appScrollElement: ElementRef<any>;

  public updateScroll: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // Exposed class for template
  public patientCreationAccountComponentClass = PatientCreationAccountComponent;
  public patientCreationInstanceComponentClass = PatientCreationInstanceCreationComponent;
  public patientCreationInterventionSelectionComponentClass = PatientCreationInterventionSelectionComponent;
  public instanceCreationConfirmationDialogComponentClass = InstanceCreationConfirmationDialogComponent;
  public patientInvitationStudyComponentClass = PatientInvitationStudyComponent;

  public assignmentMode: 'accountCreation' | 'studyInvitation' | 'patientStudyInvitation' | 'patientInterventionAssignment';

  public dialogTitle:
    | 'patient-creation-overview.modal_create_patient_title'
    | 'patient.modal_assign_patient_title'
    | 'patient.modal_title_invite_patient_to_study'
    | 'group-instance.modal_create_instance_title';

  public showButtonAssignment$: Observable<any>;
  public profile$: Observable<UserInterface>;

  // Filter
  public filter = {
    userSelection: ''
  };

  public isLoadingButton$: Observable<boolean>;

  public myUserId: number;

  public diariesOfStudies$: Observable<Array<DiaryInterface[]>>;

  public assignInterventionsResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');
  public sendStudyInvitationOnlyResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');
  public sendInvitationAndCreateInstanceResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');
  public assignInterventionResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');

  public users: Array<UserInterface> = [];
  public usersSubject: BehaviorSubject<Array<UserInterface>> = new BehaviorSubject<Array<UserInterface>>([]);

  public submitted = false;

  public selectedUser: UserInterface;

  public selectedLanguage = localStorage.getItem('language');

  private instances: Array<InterventionInstanceInterface> = [];

  private newUserId: number;
  private emailExist: boolean;

  private allInterventionsOfStudies$: Observable<Array<InterventionInterface[]>>;
  private questionnairesOfMultipleInterventions$: Observable<Array<LessonInterface[]>>;
  private allInstancesOfInterventionOfPatient$: Observable<Array<InterventionInstanceInterface>>;
  private createMultipleInstancesAsECoachResponse$: Observable<Array<any>>;
  private addMembersMultipleStudiesResponse$: Observable<Array<any>>;
  private registerPatientAsECoachResponse$: Observable<any>;
  private allInvitations$: Observable<Array<InvitationInterface>>;
  private collaboratorsOfStudies$: Observable<Array<{ studyId: number; collaborators: UserInterface[] }>>;
  private myMembers$: Observable<Array<UserInterface>>;
  private myRegisteredUsers$: Observable<Array<UserInterface>>;

  private allInterventionsOfStudy$: Observable<Array<InterventionInterface>>;
  private collaboratorsOfStudy$: Observable<{ studyId: number; collaborators: UserInterface[] }>;

  private selectedInterventionsSubject: BehaviorSubject<Array<number>> = new BehaviorSubject<Array<number>>([]);

  private memberStudies$: Observable<Array<StudyInterface>>;

  // Tracks list of dynamically generated components
  private components: Array<any> = [];
  private studiesSubject: BehaviorSubject<Array<number>> = new BehaviorSubject<Array<number>>([]);
  private interventionsSubject: BehaviorSubject<Array<number>> = new BehaviorSubject<Array<number>>([]);
  private createdAccountSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private studiesDetail: Array<{ study_id: number; interventions: Array<InterventionInterface>; collaborators: Array<UserInterface> }> = [];

  private isLoadingButtonSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private showButtonAssignmentSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private study: StudyInterface;

  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DialogAccountInterventionInstanceAssignmentComponent>,
    private diaryStore: DiaryStore,
    private interventionStore: InterventionStore,
    private interventionInstanceStore: InterventionInstanceStore,
    private questionnaireStore: QuestionnaireStore,
    private studyStore: StudyStore,
    private userStore: UserStore,
    private reminderStore: ReminderStore,
    private alertService: AlertService,
    private actions$: Actions,
    private store: Store<{
      getCollaboratorsByMultipleStudyIds: Array<{
        study_id: number;
        interventions: Array<InterventionInterface>;
        collaborators: Array<UserInterface>;
      }>;
      myMembers: Array<UserInterface>;
      myRegisteredUsers: Array<UserInterface>;
      allInvitations: Array<InvitationInterface>;
      myProfile: ProfileInterface;
      getCollaboratorsByStudyId: { studyId: number; collaborators: UserInterface[] };
    }>,
    private helperSkillService: HelperSkillService,
    private helperService: HelperService
  ) {
    this.dialogRef.disableClose = true;
    this.isLoadingButton$ = this.isLoadingButtonSubject.asObservable();
    this.diariesOfStudies$ = this.diaryStore.diariesOfStudies$;
    this.allInterventionsOfStudies$ = this.interventionStore.allInterventionsOfStudies$;
    this.allInstancesOfInterventionOfPatient$ = this.interventionInstanceStore.allInstancesOfInterventionOfPatient$;
    this.createMultipleInstancesAsECoachResponse$ = this.interventionInstanceStore.createMultipleInstancesAsECoachResponse$;
    this.questionnairesOfMultipleInterventions$ = this.questionnaireStore.questionnairesOfMultipleInterventions$;
    this.addMembersMultipleStudiesResponse$ = this.studyStore.addMembersMultipleStudiesResponse$;
    this.myMembers$ = this.store.select('myMembers');
    this.myRegisteredUsers$ = this.store.select('myRegisteredUsers');
    this.registerPatientAsECoachResponse$ = this.userStore.registerPatientAsECoachResponse$;
    this.allInvitations$ = this.store.select('allInvitations');

    this.showButtonAssignment$ = this.showButtonAssignmentSubject.asObservable();
    this.memberStudies$ = this.userStore.memberStudies$;

    this.profile$ = store.select('myProfile');
    this.allInterventionsOfStudy$ = this.interventionStore.allInterventionsOfSpecificStudy$;
  }

  // Helper function
  public get helper() {
    return this.helperService;
  }

  ngOnInit(): void {
    this.assignmentMode = this.data.assignmentMode;

    if (this.assignmentMode === 'accountCreation') {
      this.dialogTitle = 'patient-creation-overview.modal_create_patient_title';
      this.myUserId = this.data.myUserId;
    } else if (this.assignmentMode === 'studyInvitation') {
      this.dialogTitle = 'group-instance.modal_create_instance_title';
      this.study = this.data.study;
      this.selectedUser = this.data.selectedUser;
      this.users = this.data.users;
      this.usersSubject.next(this.users);
      this.clearContainer();
      let interventions = [];
      let collaborators = [];

      let foundInterventionSelect: PatientCreationInterventionSelectionComponent;
      this.subscriptions.push(
        this.profile$
          .pipe(
            filter(user => user !== null),
            mergeMap((result: UserInterface) => {
              this.myUserId = result.id;
              this.interventionStore.getAllInterventionsOfSpecificStudy({ studyId: this.study.id, include: 'collaborators' });
              return this.allInterventionsOfStudy$.pipe(
                skip(1),
                take(1),
                mergeMap((interventionsResp: Array<InterventionInterface>) => {
                  interventions = interventionsResp;
                  this.store.dispatch({
                    type: StudyActionTypes.getCollaboratorsType,
                    payload: { studyId: this.study.id, include: 'roles' }
                  });
                  this.collaboratorsOfStudy$ = this.store.select(getCollaboratorsByStudyId(this.study.id));
                  return this.collaboratorsOfStudy$.pipe(
                    filter(collaboratorsOfStudy => !!collaboratorsOfStudy),
                    take(1)
                  );
                })
              );
            }),
            mergeMap((result: { studyId: number; collaborators: UserInterface[] }) => {
              collaborators = this.helper.excludeUsersWithOnlyStudyAccess(result.collaborators);
              const component = this.accountAssignmentContainer.createComponent(PatientCreationInterventionSelectionComponent).instance;
              this.components.push(component);
              foundInterventionSelect = this.setPatientCreationInterventionSelectionComponent(
                [{ study_id: this.study.id, interventions, collaborators }],
                [this.study]
              );
              return this.getAllInstancesOfInterventionOfPatient(this.selectedUser.id);
            })
          )
          .subscribe(
            (result: Array<InterventionInstanceInterface>) => {
              foundInterventionSelect = this.setPatientCreationInterventionSelectionComponent(
                undefined,
                undefined,
                undefined,
                undefined,
                result
              );
              if (foundInterventionSelect) {
                this.setComponentVisibilityICD(this.components, [], [foundInterventionSelect]);
              }
            },
            () => {
              foundInterventionSelect = this.setPatientCreationInterventionSelectionComponent(
                undefined,
                undefined,
                undefined,
                undefined,
                []
              );
              this.isLoadingButtonSubject.next(false);
              this.showButtonsICD(this.components, this.showButtonAssignmentSubject);
            }
          )
      );
    } else if (this.assignmentMode === 'patientInterventionAssignment') {
      this.dialogTitle = 'patient.modal_assign_patient_title';
      this.alertService.reset();
      this.selectedUser = this.data.selectedUser;
      this.myUserId = this.data.myUserId;
      this.clearContainer();
      let studies = [];
      this.studiesDetail = [];
      this.store.dispatch({
        type: StudyActionTypes.getCollaboratingStudiesType,
        payload: { type: 'study', role: `study.owner,study.ecoachmanager,study.ecoach` }
      });
      this.subscriptions.push(
        this.actions$
          .pipe(
            ofType(StudyActionTypes.getCollaboratingStudiesSuccessType, StudyActionTypes.getCollaboratingStudiesErrorType),
            take(1),
            mergeMap((result: any) =>
              iif(() => result.type === StudyActionTypes.getCollaboratingStudiesSuccessType, of(result), throwError(result))
            ),
            mergeMap((result: any) => {
              studies = result['response']['body']['data'];
              this.userStore.getMemberStudies({ userId: this.selectedUser.id });
              return this.memberStudies$.pipe(skip(1), take(1));
            }),
            catchError(error => of(error)),
            mergeMap((result: any) => {
              studies = studies.filter((study: StudyInterface) => result.map(val => val.id.toString()).includes(study.id.toString()));
              this.studiesDetail = [];
              const payload = studies.map((study: StudyInterface) => ({ studyId: study.id, include: 'collaborators' }));
              return iif(
                () => payload.length > 0,
                this.getAllInterventionsOfStudySelection(
                  payload,
                  studies.map((study: StudyInterface) => study.id)
                ),
                of([])
              );
            }),
            mergeMap((results: Array<any>) => {
              studies.forEach((study: StudyInterface, index) => {
                const resp = results.find(result => result.studyId.toString() === study.id.toString());
                this.studiesDetail[index].collaborators = resp ? resp.collaborators : [];
              });

              // Create the initial component dynamically inside the ng-template
              const component = this.accountAssignmentContainer.createComponent<PatientCreationInterventionSelectionComponent>(
                this.patientCreationInterventionSelectionComponentClass
              ).instance;

              this.components = [...this.components, component];

              const foundInterventionSelect = this.setPatientCreationInterventionSelectionComponent(this.studiesDetail, studies, true);
              this.setComponentVisibilityICD(this.components, [], [foundInterventionSelect]);
              return this.getAllInstancesOfInterventionOfPatient(this.selectedUser.id);
            })
          )
          .subscribe(
            (result: Array<InterventionInstanceInterface>) => {
              this.instances = result;
              const foundInterventionSelect = this.setPatientCreationInterventionSelectionComponent(
                undefined,
                undefined,
                undefined,
                undefined,
                this.instances
              );
            },
            () => {
              this.isLoadingButtonSubject.next(false);
              this.showButtonsICD(this.components, this.showButtonAssignmentSubject);
            }
          )
      );
    } else if (this.assignmentMode === 'patientStudyInvitation') {
      this.dialogTitle = 'patient.modal_title_invite_patient_to_study';
      this.selectedUser = this.data.selectedUser;
      this.myUserId = this.data.myUserId;
    }
  }

  ngAfterViewInit(): void {
    if (this.assignmentMode === 'accountCreation' || this.assignmentMode === 'patientStudyInvitation') {
      this.clearContainer();
      this.studiesSubject.next([]);
      this.createdAccountSubject.next(false);
    }

    if (this.assignmentMode === 'accountCreation') {
      this.interventionsSubject.next([]);

      const component = this.accountAssignmentContainer.createComponent<PatientCreationAccountComponent>(
        this.patientCreationAccountComponentClass
      ).instance;
      this.components = [...this.components, component];

      this.showButtonsICD(this.components, this.showButtonAssignmentSubject);
    } else if (this.assignmentMode === 'patientStudyInvitation') {
      this.selectedInterventionsSubject.next([]);
      this.alertService.reset();
      this.showButtonAssignmentSubject.next('');

      const component = this.accountAssignmentContainer.createComponent<PatientInvitationStudyComponent>(
        this.patientInvitationStudyComponentClass
      ).instance;
      this.components = [...this.components, component];

      const foundStudyInvitation: PatientInvitationStudyComponent = this.components.find(c => c instanceof PatientInvitationStudyComponent);
      foundStudyInvitation.patient = this.selectedUser;
      this.setComponentVisibilityICD(this.components, [], [foundStudyInvitation]);
    }
  }

  public showPreviousComponent(): void {
    const foundAccount: PatientCreationAccountComponent = this.components.find(c => c instanceof PatientCreationAccountComponent);
    const foundInterventionSelect: PatientCreationInterventionSelectionComponent = this.components.find(
      c => c instanceof PatientCreationInterventionSelectionComponent
    );
    const foundInstance: PatientCreationInstanceCreationComponent = this.components.find(
      c => c instanceof PatientCreationInstanceCreationComponent
    );
    const foundConfirmation: InstanceCreationConfirmationDialogComponent = this.components.find(
      c => c instanceof InstanceCreationConfirmationDialogComponent
    );
    const foundStudyInvitation: PatientInvitationStudyComponent = this.components.find(c => c instanceof PatientInvitationStudyComponent);
    if (this.assignmentMode === 'accountCreation') {
      if (!!foundInterventionSelect && !foundInterventionSelect.isHiddenSubject.value) {
        this.setComponentVisibilityICD(this.components, [foundInterventionSelect], [foundAccount]);
        return;
      }
      if (!!foundInstance && !foundInstance.isHiddenSubject.value) {
        this.setComponentVisibilityICD(this.components, [foundInstance], [foundInterventionSelect]);
        return;
      }
      if (!!foundConfirmation && !foundConfirmation.isHiddenSubject.value) {
        this.setComponentVisibilityICD(this.components, [foundInterventionSelect, foundConfirmation], [foundInstance]);
        return;
      }
      this.showButtonsICD(this.components, this.showButtonAssignmentSubject);
    }

    if (this.assignmentMode === 'patientStudyInvitation') {
      if (!!foundInterventionSelect && !foundInterventionSelect.isHiddenSubject.value) {
        this.setComponentVisibilityICD(
          this.components,
          [foundInterventionSelect, foundInstance, foundConfirmation],
          [foundStudyInvitation]
        );
        return;
      }
      if (!!foundInstance && !foundInstance.isHiddenSubject.value) {
        this.setComponentVisibilityICD(
          this.components,
          [foundInstance, foundConfirmation, foundStudyInvitation],
          [foundInterventionSelect]
        );
        return;
      }
      if (!!foundConfirmation && !foundConfirmation.isHiddenSubject.value) {
        this.setComponentVisibilityICD(
          this.components,
          [foundInterventionSelect, foundConfirmation, foundStudyInvitation],
          [foundInstance]
        );
        return;
      }
      this.showButtonsICD(this.components, this.showButtonAssignmentSubject);
    }

    if (this.assignmentMode === 'patientInterventionAssignment' || this.assignmentMode === 'studyInvitation') {
      if (!!foundInstance && !foundInstance.isHiddenSubject.value) {
        this.setComponentVisibilityICD(this.components, [foundInstance, foundConfirmation], [foundInterventionSelect]);
        return;
      }
      if (!!foundConfirmation && !foundConfirmation.isHiddenSubject.value) {
        this.setComponentVisibilityICD(this.components, [foundInterventionSelect, foundConfirmation], [foundInstance]);
        return;
      }
      this.showButtonsICD(this.components, this.showButtonAssignmentSubject);
    }
  }

  public showNextComponent(
    componentClass: Type<
      PatientCreationInterventionSelectionComponent | PatientCreationInstanceCreationComponent | InstanceCreationConfirmationDialogComponent
    >,
    className: string
  ) {
    this.isLoadingButtonSubject.next(true);
    const found = this.components.find(c => c instanceof componentClass);
    if (!found && className !== 'PatientCreationInterventionSelectionComponent') {
      const component = this.accountAssignmentContainer.createComponent<any>(componentClass).instance;
      component.hide(true);
      this.components = [...this.components, component];
    }

    const foundInterventionSelect: PatientCreationInterventionSelectionComponent = this.components.find(
      c => c instanceof PatientCreationInterventionSelectionComponent
    );
    const foundInstance: PatientCreationInstanceCreationComponent = this.components.find(
      c => c instanceof PatientCreationInstanceCreationComponent
    );
    const foundAccount: PatientCreationAccountComponent = this.components.find(c => c instanceof PatientCreationAccountComponent);
    const foundConfirmation: InstanceCreationConfirmationDialogComponent = this.components.find(
      c => c instanceof InstanceCreationConfirmationDialogComponent
    );
    const foundStudyInvitation: PatientInvitationStudyComponent = this.components.find(c => c instanceof PatientInvitationStudyComponent);

    if (
      (this.assignmentMode === 'accountCreation' || this.assignmentMode === 'patientStudyInvitation') &&
      className === 'PatientCreationInterventionSelectionComponent'
    ) {
      this.showNextPatientCreationInterventionSelectionComponent(
        componentClass,
        foundInterventionSelect,
        foundInstance,
        foundConfirmation,
        foundAccount,
        foundStudyInvitation
      );
    }

    if (
      this.assignmentMode === 'accountCreation' ||
      this.assignmentMode === 'patientStudyInvitation' ||
      this.assignmentMode === 'studyInvitation' ||
      this.assignmentMode === 'patientInterventionAssignment'
    ) {
      if (className === 'PatientCreationInstanceCreationComponent') {
        this.showNextPatientCreationInstanceCreationComponent(
          foundInterventionSelect,
          foundInstance,
          foundConfirmation,
          foundAccount,
          foundStudyInvitation
        );
      }
      if (className === 'InstanceCreationConfirmationDialogComponent') {
        this.showNextInstanceCreationConfirmationDialogComponent(
          foundInterventionSelect,
          foundInstance,
          foundConfirmation,
          foundAccount,
          foundStudyInvitation
        );
      }
    }
  }

  // accountCreation
  public createAccountSendInvitation(): void {
    // Set loading button - true
    this.isLoadingButtonSubject.next(true);
    this.newUserId = null;
    // Email exists
    this.emailExist = false;
    let codeTaken = false;
    this.alertService.reset();

    const foundInstance: PatientCreationInstanceCreationComponent = this.components.find(
      c => c instanceof PatientCreationInstanceCreationComponent
    );
    const foundInterventionSelect: PatientCreationInterventionSelectionComponent = this.components.find(
      c => c instanceof PatientCreationInterventionSelectionComponent
    );
    const foundAccount: PatientCreationAccountComponent = this.components.find(c => c instanceof PatientCreationAccountComponent);

    if (foundAccount) {
      if (foundAccount.patientForm.value.studyList) {
        this.registerAndStudyInvitation(foundAccount).subscribe(
          (results: any) => {
            let codeUsedArray: Array<{ id: number; codeTaken: boolean; code: string }> = [];
            if (Array.isArray(results)) {
              results.forEach((res: HttpResponse<any> | HttpErrorResponse) => {
                codeUsedArray = this.addStudyCodeTaken(res, codeUsedArray);
              });
            } else {
              codeUsedArray = this.addStudyCodeTaken(results, codeUsedArray);
            }

            if (codeUsedArray.length > 0) {
              foundAccount.studyCode = codeUsedArray;
              codeTaken = true;
              foundAccount.codeTakenSubject.next(true);
              this.setComponentVisibilityICD(this.components, [foundInterventionSelect, foundInstance], [foundAccount]);
              return;
            }
            this.setComponentVisibilityICD(this.components, [foundInterventionSelect, foundInstance, foundAccount], []);
            this.showButtonAssignmentSubject.next('confirm');
            if (this.emailExist) {
              this.alertService.info('FORM_CREATE_PATIENT_SUCCEEDED_EMAIL_EXISTS');
            } else {
              this.alertService.success('FORM_CREATE_PATIENT_SUCCEEDED');
            }
          },
          error => {
            console.error('Registration account error', error);
            // TODO Show which code has been used
            if (codeTaken) {
              foundAccount.codeTakenSubject.next(true);
              this.setComponentVisibilityICD(this.components, [foundInterventionSelect, foundInstance], [foundAccount]);
              this.isLoadingButtonSubject.next(false);
              this.showButtonsICD(this.components, this.showButtonAssignmentSubject);
              return;
            }
            this.setComponentVisibilityICD(this.components, [foundInterventionSelect, foundInstance, foundAccount], []);
            this.showButtonAssignmentSubject.next('confirm');
            this.alertService.error('FORM_CREATE_PATIENT_FAILED');
          }
        );
      }
    }
  }

  // patientStudyInvitation
  public hideButtonsOnInvitation(): boolean {
    const foundStudyInvitation: PatientInvitationStudyComponent = this.components.find(c => c instanceof PatientInvitationStudyComponent);
    return foundStudyInvitation ? foundStudyInvitation.isShowingInvitationsSubject.value : true;
  }

  // patientStudyInvitation
  public sendStudyInvitationOnly(): void {
    if (this.sendStudyInvitationOnlyResponse.value === 'DEFAULT') {
      this.sendStudyInvitationOnlyResponse.next('LOADING');
      const foundInterventionSelect: PatientCreationInterventionSelectionComponent = this.components.find(
        c => c instanceof PatientCreationInterventionSelectionComponent
      );
      const foundInstance: PatientCreationInstanceCreationComponent = this.components.find(
        c => c instanceof PatientCreationInstanceCreationComponent
      );
      const foundStudyInvitation: PatientInvitationStudyComponent = this.components.find(c => c instanceof PatientInvitationStudyComponent);
      const foundConfirmation: InstanceCreationConfirmationDialogComponent = this.components.find(
        c => c instanceof InstanceCreationConfirmationDialogComponent
      );
      if (foundStudyInvitation) {
        const patient = foundStudyInvitation.patient;
        foundStudyInvitation
          .sendInvitation(patient.id)
          .pipe(
            map(result => result),
            catchError(error => throwError(error))
          )
          .subscribe(
            () => {
              this.showButtonAssignmentSubject.next('confirm');
              this.setComponentVisibilityICD(
                this.components,
                [foundInterventionSelect, foundInstance, foundConfirmation, foundStudyInvitation],
                []
              );
              this.alertService.success('FORM_INVITE_PATIENT_SUCCEEDED');
              setTimeout(() => {
                this.sendStudyInvitationOnlyResponse.next('DEFAULT');
                this.alertService.reset();
                this.dialogRef.close();
              }, 5000);
            },
            error => {
              this.isLoadingButtonSubject.next(false);
              this.sendStudyInvitationOnlyResponse.next('DEFAULT');
              /*
              this.sendStudyInvitationOnlyResponse.next('FAILURE');
              this.setComponentVisibility(
                foundStudyInvitation,
                true,
                foundInterventionSelect,
                true,
                foundInstance,
                true,
                foundConfirmation,
                true
              );
              this.showButtonAssignmentSubject.next('confirm');
              this.alertService.error('FORM_INVITE_PATIENT_FAILED');
              setTimeout(() => {
                this.sendStudyInvitationOnlyResponse.next('DEFAULT');
                this.alertService.reset();
                this.dialogRef.close();
              }, 5000);
              */
            }
          );
      }
    }
  }

  // patientStudyInvitation
  public sendInvitationAndCreateInstance(): void {
    if (this.sendInvitationAndCreateInstanceResponse.value === 'DEFAULT') {
      this.sendInvitationAndCreateInstanceResponse.next('LOADING');

      const foundStudyInvitation: PatientInvitationStudyComponent = this.components.find(c => c instanceof PatientInvitationStudyComponent);
      const foundInterventionSelect: PatientCreationInterventionSelectionComponent = this.components.find(
        c => c instanceof PatientCreationInterventionSelectionComponent
      );
      const foundInstance: PatientCreationInstanceCreationComponent = this.components.find(
        c => c instanceof PatientCreationInstanceCreationComponent
      );
      const foundConfirmation: InstanceCreationConfirmationDialogComponent = this.components.find(
        c => c instanceof InstanceCreationConfirmationDialogComponent
      );
      if (foundStudyInvitation) {
        const patient = foundStudyInvitation.patient;
        foundStudyInvitation
          .sendInvitation(patient.id)
          .pipe(
            catchError(() => throwError('INVITATION_FAILED')),
            mergeMap(() => {
              if (!!foundInstance && !foundInstance.isHiddenSubject.value) {
                foundInstance.creationFormComponents.forEach((component: InstanceCreationFormComponent) => {
                  component.confirmInstanceSettings();
                });
              }
              const allInstances: Array<PayloadInterface> = foundInstance.myInstances;
              const payloads: Array<PayloadInterface> = allInstances.map((pay: PayloadInterface) => {
                pay.data.attributes.patient_id = this.selectedUser.id;
                pay.data.attributes.ecoach_ids = pay.data.attributes.ecoach_ids.filter(
                  (id: number) => id.toString() !== this.myUserId.toString()
                );
                return pay;
              });
              return this.createMultipleInstancesAsECoach(payloads);
            })
          )
          .subscribe(
            (res: any) => {
              this.sendInvitationAndCreateInstanceResponse.next('SUCCESS');
              this.setComponentVisibilityICD(
                this.components,
                [foundInterventionSelect, foundInstance, foundConfirmation, foundStudyInvitation],
                []
              );
              this.showButtonAssignmentSubject.next('confirm');
              this.alertService.success('FORM_INVITE_ASSIGN_PATIENT_SUCCEEDED');
              setTimeout(() => {
                this.sendInvitationAndCreateInstanceResponse.next('DEFAULT');
                this.alertService.reset();
                this.dialogRef.close();
              }, 5000);
            },
            error => {
              this.sendInvitationAndCreateInstanceResponse.next('FAILURE');
              this.showButtonAssignmentSubject.next('confirm');
              if (error === 'INVITATION_FAILED') {
                // this.alertService.error('FORM_INVITE_ASSIGN_PATIENT_FAILED_2');
                this.setComponentVisibilityICD(
                  this.components,
                  [foundInterventionSelect, foundInstance, foundConfirmation],
                  [foundStudyInvitation]
                );
                this.sendInvitationAndCreateInstanceResponse.next('DEFAULT');
                this.showButtonAssignmentSubject.next('selection');
                this.showButtonsICD(this.components, this.showButtonAssignmentSubject);
              } else {
                const errorMsg =
                  error === 'INSTANCE_FAILED' ? 'FORM_INVITE_ASSIGN_PATIENT_FAILED_1' : 'FORM_INVITE_ASSIGN_PATIENT_FAILED_2';
                this.alertService.error(errorMsg);
                this.setComponentVisibilityICD(
                  this.components,
                  [foundInterventionSelect, foundInstance, foundConfirmation, foundStudyInvitation],
                  []
                );
                setTimeout(() => {
                  this.sendInvitationAndCreateInstanceResponse.next('DEFAULT');
                  this.alertService.reset();
                  this.dialogRef.close();
                }, 5000);
              }
            }
          );
      }
    }
  }

  // accountCreation
  public registerAccount(): void {
    // Set loading button - true
    this.isLoadingButtonSubject.next(true);
    // Email exists
    this.newUserId = null;
    this.emailExist = false;
    let codeTaken = false;
    let alreadyAssigned = false;
    const instancePayload = [];
    const instanceNotCreated = false;
    this.alertService.reset();

    const foundInstance: PatientCreationInstanceCreationComponent = this.components.find(
      c => c instanceof PatientCreationInstanceCreationComponent
    );
    const foundInterventionSelect: PatientCreationInterventionSelectionComponent = this.components.find(
      c => c instanceof PatientCreationInterventionSelectionComponent
    );
    const foundAccount: PatientCreationAccountComponent = this.components.find(c => c instanceof PatientCreationAccountComponent);
    const foundConfirmation: InstanceCreationConfirmationDialogComponent = this.components.find(
      c => c instanceof InstanceCreationConfirmationDialogComponent
    );

    if (!!foundInstance && !foundInstance.isHiddenSubject.value) {
      foundInstance.creationFormComponents.forEach((component: InstanceCreationFormComponent) => {
        component.confirmInstanceSettings();
      });
    }
    if (foundAccount) {
      this.registerAndStudyInvitation(foundAccount)
        .pipe(
          mergeMap((results: any) => {
            let codeUsedArray: Array<{ id: number; codeTaken: boolean; code: string }> = [];
            if (Array.isArray(results)) {
              results.forEach((res: HttpResponse<any> | HttpErrorResponse) => {
                codeUsedArray = this.addStudyCodeTaken(res, codeUsedArray);
              });
            } else {
              codeUsedArray = this.addStudyCodeTaken(results, codeUsedArray);
            }

            if (codeUsedArray.length > 0) {
              foundAccount.studyCode = codeUsedArray;
              codeTaken = true;
              return throwError(results);
            } else {
              this.store.dispatch({
                type: UserActionTypes.getUsersRegisteredByEcoachType,
                payload: {}
              });
              return this.myRegisteredUsers$.pipe(
                skip(1),
                take(1),
                switchMap((result: any) => {
                  const responseMyRegisteredUsers: UserInterface = result.find(
                    (user: UserInterface) => user.attributes.email === foundAccount.patientForm.get('email').value
                  );
                  if (!responseMyRegisteredUsers) {
                    this.store.dispatch({
                      type: UserActionTypes.getMyMembersType,
                      payload: {}
                    });
                    return this.myMembers$.pipe(
                      skip(1),
                      take(1),
                      switchMap((resp: any) => {
                        const responseMyMembers: UserInterface = resp.find(
                          (user: UserInterface) =>
                            user.attributes.email.toLowerCase() === foundAccount.patientForm.get('email').value.toLowerCase()
                        );
                        this.newUserId = !!responseMyMembers ? responseMyMembers.id : this.newUserId;
                        return iif(
                          () => this.newUserId !== null,
                          this.getAllInstancesOfInterventionOfPatient(this.newUserId).pipe(
                            switchMap((interventionInstances: Array<InterventionInstanceInterface>) => of(interventionInstances)),
                            catchError(() => of([]))
                          ),
                          of(null)
                        );
                      })
                    );
                  } else {
                    this.newUserId = responseMyRegisteredUsers.id;
                    return iif(
                      () => !!responseMyRegisteredUsers,
                      this.getAllInstancesOfInterventionOfPatient(responseMyRegisteredUsers.id).pipe(
                        switchMap((interventionInstances: Array<InterventionInstanceInterface>) => of(interventionInstances)),
                        catchError(() => of([]))
                      ),
                      of(null)
                    );
                  }
                })
              );
            }
          }),
          mergeMap(result => {
            const allInstances: Array<PayloadInterface> = foundInstance.myInstances;
            if (result == null || result?.length === 0) {
              const payloads: Array<PayloadInterface> = allInstances.map((pay: PayloadInterface) =>
                this.setPayloadOfEMail(this.emailExist, pay, foundAccount, this.newUserId)
              );
              return this.createMultipleInstancesAsECoach(payloads);
            } else {
              const assignedInterventionInstances: Array<InterventionInstanceInterface> = result.filter(
                instance => instance.attributes.progress.current_state !== 'canceled'
              );
              const payloads: Array<PayloadInterface> = [];
              allInstances.forEach((pay: PayloadInterface) => {
                pay = this.setPayloadOfEMail(this.emailExist, pay, foundAccount, this.newUserId);
                if (
                  assignedInterventionInstances.find(
                    (value: InterventionInstanceInterface) =>
                      value.attributes.intervention_id.toString() === pay.data.attributes.intervention_id.toString()
                  )
                ) {
                  instancePayload.push(pay);
                  alreadyAssigned = true;
                } else {
                  payloads.push(pay);
                }
              });
              return iif(() => payloads.length > 0, this.createMultipleInstancesAsECoach(payloads), of(null));
            }
          })
        )
        .subscribe(
          () => {
            this.setComponentVisibilityICD(this.components, [foundInterventionSelect, foundInstance, foundConfirmation, foundAccount], []);
            this.showButtonAssignmentSubject.next('confirm');
            if (this.emailExist) {
              const infoMsg = alreadyAssigned
                ? 'FORM_CREATE_PATIENT_SUCCEEDED_EMAIL_EXISTS_INSTANCE_HAS_ALREADY_ASSIGNED'
                : 'FORM_CREATE_PATIENT_SUCCEEDED_EMAIL_EXISTS';
              if (alreadyAssigned) {
                const interventionIds: Array<string> = instancePayload.map((value: PayloadInterface) =>
                  value.data.attributes.intervention_id.toString()
                );
                const interventions: Array<InterventionInterface> = foundInterventionSelect.selectedInterventionSubject.value.flatMap(
                  (value: { study_id: number; interventions: Array<InterventionInterface>; collaborators: Array<UserInterface> }) =>
                    value.interventions
                );
                // Set intervention name of rejected intervention instances
                const intersection: Array<InterventionInterface> = interventions.filter(x => interventionIds.includes(x.id.toString()));
                const listOfInterventions = intersection
                  .map(
                    (value: InterventionInterface) =>
                      '<p>' + value.attributes.name + ' ' + value.attributes.title + ' (ID ' + value.id + ')' + '</p>'
                  )
                  .join('');

                this.alertService.setParam(listOfInterventions);
              }
              this.alertService.info(infoMsg);
            } else {
              this.alertService.success('FORM_CREATE_PATIENT_SUCCEEDED');
            }
          },
          error => {
            console.error('Registration account error', error);
            const hideComponents = [];
            const showComponents = [];
            if (!codeTaken) {
              hideComponents.push(foundAccount);
            } else {
              showComponents.push(foundAccount);
            }
            hideComponents.push([foundInterventionSelect, foundInstance, foundConfirmation]);
            this.setComponentVisibilityICD(this.components, hideComponents, showComponents);
            if (codeTaken) {
              foundInstance.myInstances = [];
              return;
            }
            this.showButtonAssignmentSubject.next('confirm');

            if (error === 'INSTANCE_FAILED') {
              this.alertService.error('FORM_INVITE_ASSIGN_PATIENT_FAILED_1');
            } else {
              const errorMsg = instanceNotCreated ? 'FORM_CREATE_INSTANCE_FAILED' : 'FORM_CREATE_PATIENT_FAILED';
              this.alertService.error(errorMsg);
            }
          }
        );
    }
  }

  // accountCreation
  public hasNoStudies(): boolean {
    const foundAccount: PatientCreationAccountComponent = this.components.find(c => c instanceof PatientCreationAccountComponent);
    return foundAccount ? foundAccount.fStudyList.length < 1 : false;
  }

  // accountCreation
  public noStudiesSelected(): boolean {
    const foundAccount: PatientCreationAccountComponent = this.components.find(c => c instanceof PatientCreationAccountComponent);
    if (foundAccount) {
      const checked = foundAccount.fStudyList.value.filter((value: { checked: boolean; code: string }) => value.checked);
      return foundAccount.fStudyList.value.length > 0 ? checked.length === 0 : false;
    }
    return false;
  }

  // accountCreation
  public hasNoEmail(): boolean {
    const foundAccount: PatientCreationAccountComponent = this.components.find(c => c instanceof PatientCreationAccountComponent);
    return foundAccount ? foundAccount.f.get('email').invalid : false;
  }

  // accountCreation
  public setPayloadOfEMail(
    emailExist: boolean,
    payload: PayloadInterface,
    foundAccount: PatientCreationAccountComponent,
    newUserId: number
  ): PayloadInterface {
    if (emailExist) {
      payload.data.attributes.patient_email = foundAccount.patientForm.get('email').value;
      delete payload.data.attributes.patient_id;
    } else {
      payload.data.attributes.patient_id = newUserId;
    }
    payload.data.attributes.ecoach_ids = payload.data.attributes.ecoach_ids.filter(
      (id: number) => id.toString() !== this.myUserId.toString()
    );
    return payload;
  }

  // patientInstanceAssignment
  public assignInterventions1(): void {
    // Set loading button - true
    this.isLoadingButtonSubject.next(true);
    const foundInstance: PatientCreationInstanceCreationComponent = this.components.find(
      c => c instanceof PatientCreationInstanceCreationComponent
    );
    if (!!foundInstance && !foundInstance.isHiddenSubject.value) {
      foundInstance.creationFormComponents.forEach((component: InstanceCreationFormComponent) => {
        component.confirmInstanceSettings();
      });
    }

    const allInstances: Array<PayloadInterface> = [];
    const payloads: Array<PayloadInterface> = [];
    foundInstance.myInstances.forEach((instance: PayloadInterface) => allInstances.push(instance));
    allInstances.forEach((pay: PayloadInterface) => {
      pay.data.attributes.patient_id = this.selectedUser.id;
      pay.data.attributes.ecoach_ids = pay.data.attributes.ecoach_ids.filter((id: number) => id.toString() !== this.myUserId.toString());
      payloads.push(pay);
    });

    if (this.assignInterventionsResponse.value === 'DEFAULT') {
      this.assignInterventionsResponse.next('LOADING');
      this.createMultipleInstancesAsECoach(payloads).subscribe(
        (res: any) => {
          this.assignInterventionsResponse.next('SUCCESS');
          setTimeout(() => {
            this.assignInterventionsResponse.next('DEFAULT');
            this.dialogRef.close('SUCCESS');
          }, 2500);
        },
        () => {
          this.assignInterventionsResponse.next('FAILURE');
          setTimeout(() => {
            this.assignInterventionsResponse.next('DEFAULT');
          }, 2500);
        }
      );
    }
  }

  // studyInvitation
  public showSelected(user: UserInterface): void {
    this.selectedUser = user;
    const foundInterventionSelect: PatientCreationInterventionSelectionComponent = this.components.find(
      c => c instanceof PatientCreationInterventionSelectionComponent
    );
    if (foundInterventionSelect) {
      this.subscriptions.push(
        this.getAllInstancesOfInterventionOfPatient(this.selectedUser.id).subscribe((result: any) => {
          foundInterventionSelect.setInstances(result);
          foundInterventionSelect.addInterventionSelectionPerStudy();
        })
      );
    }
  }

  // studyInvitation
  public assignInterventions(): void {
    if (!this.selectedUser?.id) {
      this.assignInterventionResponse.next('DEFAULT');
      return;
    } else if (!this.submitted) {
      this.submitted = true;
      this.assignInterventionResponse.next('LOADING');

      // Set loading button - true
      this.isLoadingButtonSubject.next(true);
      const foundInstance: PatientCreationInstanceCreationComponent = this.components.find(
        c => c instanceof PatientCreationInstanceCreationComponent
      );
      if (!!foundInstance && !foundInstance.isHiddenSubject.value) {
        foundInstance.creationFormComponents.forEach((component: InstanceCreationFormComponent) => {
          component.confirmInstanceSettings();
        });
      }

      const allInstances: Array<PayloadInterface> = foundInstance.myInstances;
      const payloads: Array<PayloadInterface> = allInstances.map((pay: PayloadInterface) => {
        pay.data.attributes.patient_id = this.selectedUser.id;
        pay.data.attributes.ecoach_ids = pay.data.attributes.ecoach_ids.filter((id: number) => id.toString() !== this.myUserId.toString());
        return pay;
      });

      this.subscriptions.push(
        this.createMultipleInstancesAsECoach(payloads).subscribe(
          results => {
            this.assignInterventionResponse.next('SUCCESS');
            setTimeout(() => {
              this.assignInterventionResponse.next('DEFAULT');
              this.submitted = false;
              this.dialogRef.close('SUCCESS');
            }, 2500);
          },
          () => {
            this.assignInterventionResponse.next('FAILURE');
            setTimeout(() => {
              this.assignInterventionResponse.next('DEFAULT');
              this.submitted = false;
            }, 2500);
          }
        )
      );
    }
  }

  // studyInvitation
  public resetFilter(): void {
    this.filter = {
      userSelection: ''
    };
    this.usersSubject.next(this.users);
  }

  public showButtonsICD(components: Array<any>, showButtonSubject: BehaviorSubject<string>): void {
    const foundAccount: PatientCreationAccountComponent = components.find(c => c instanceof PatientCreationAccountComponent);
    const foundStudyInvitation: PatientInvitationStudyComponent = components.find(c => c instanceof PatientInvitationStudyComponent);
    const foundInterventionSelect: PatientCreationInterventionSelectionComponent = components.find(
      c => c instanceof PatientCreationInterventionSelectionComponent
    );
    const foundInstance: PatientCreationInstanceCreationComponent = components.find(
      c => c instanceof PatientCreationInstanceCreationComponent
    );
    const foundConfirmation: InstanceCreationConfirmationDialogComponent = components.find(
      c => c instanceof InstanceCreationConfirmationDialogComponent
    );

    if (!!foundAccount && !foundAccount.isHiddenSubject.value) {
      showButtonSubject.next('account');
    }
    if (!!foundStudyInvitation && !foundStudyInvitation.isHiddenSubject.value) {
      showButtonSubject.next('selection');
    }
    if (!!foundInterventionSelect && !foundInterventionSelect.isHiddenSubject.value && foundInterventionSelect.studies.length > 0) {
      showButtonSubject.next('invite');
    }
    if (!!foundInstance && !foundInstance.isHiddenSubject.value) {
      showButtonSubject.next('instance');
    }
    if (!!foundConfirmation && !foundConfirmation.isHiddenSubject.value) {
      showButtonSubject.next('confirmation');
    }
  }

  public setComponentVisibilityICD(components: Array<any>, hideComponents: Array<any>, showComponents: Array<any>) {
    hideComponents.forEach(component => {
      this.setVisibilityICD(component, true, components);
    });
    showComponents.forEach(component => {
      this.setVisibilityICD(component, false, components);
    });
    this.isLoadingButtonSubject.next(false);
    this.showButtonsICD(this.components, this.showButtonAssignmentSubject);
  }

  public setVisibilityICD(
    found:
      | PatientCreationInterventionSelectionComponent
      | PatientCreationInstanceCreationComponent
      | InstanceCreationConfirmationDialogComponent
      | PatientCreationAccountComponent
      | PatientInvitationStudyComponent,
    hiding: boolean,
    components: Array<any>
  ): void {
    if (found) {
      const foundIndex = components.indexOf(found);
      if (foundIndex !== -1) {
        found.hide(hiding);
      }
    }
  }

  public setSelectedLanguage(event$): void {
    this.selectedLanguage = event$;
  }

  // accountCreation
  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  // accountCreation
  private checkAndSendStudyInvitations(
    result: Array<any>,
    foundAccount: PatientCreationAccountComponent,
    selectedStudyIdsAndCodes: Array<{ id: number; code: string }>
  ): Observable<any> {
    let payloadStudy: PayloadInterface = new RequestBodyData('users', {
      users: [{ email: foundAccount.patientForm.get('email').value }]
    });
    let invitations: Array<InvitationInterface> = result.filter(
      (invitation: InvitationInterface) =>
        invitation.attributes.email.toLowerCase() === foundAccount.patientForm.get('email').value.toLowerCase()
    );
    invitations = this.helperService.uniqueById(invitations);
    const payload: Array<{ studyId: number; code: string; payload: PayloadInterface }> = selectedStudyIdsAndCodes.map(
      (selected: { id: number; code: string }) => {
        const foundInvitation: InvitationInterface = invitations.find(
          (invitation: InvitationInterface) =>
            invitation.attributes.model_id.toString() === selected.id.toString() &&
            invitation.attributes.email.toString().toLowerCase() === foundAccount.patientForm.get('email').value.toLowerCase()
        );
        if (!(selected.code === null || selected.code === '') && !foundInvitation) {
          payloadStudy = new RequestBodyData('users', {
            users: [{ email: foundAccount.patientForm.get('email').value, code: selected.code }]
          });
        } else {
          payloadStudy = new RequestBodyData('users', { users: [{ email: foundAccount.patientForm.get('email').value }] });
        }
        return { studyId: selected.id, code: selected.code, payload: payloadStudy, language: this.selectedLanguage };
      }
    );
    this.studyStore.addMembersMultipleStudies(payload);
    return this.addMembersMultipleStudiesResponse$.pipe(skip(1), take(1));
  }

  // accountCreation
  private getInvitations(result: any): Observable<any> {
    if (!Array.isArray(result)) {
      this.newUserId = parseInt(result.headers.get('Location').substring(result.headers.get('Location').lastIndexOf('/') + 1), 10);
    }
    this.store.dispatch({
      type: UserActionTypes.getAllInvitationsOfEcoachMembersType,
      payload: {}
    });
    return this.allInvitations$.pipe(skip(1), take(1));
  }

  // accountCreation
  private catchAccountRegistrationError(result: any): Observable<any> {
    if (result instanceof HttpErrorResponse) {
      const errorData = result.error.errors[0];
      // Username is already taken
      if (errorData.status.toString() === '404' && errorData.code.toString() === '1400') {
        return throwError(result);
      }
      if ((errorData.status.toString() === '404' || errorData.status.toString() === '403') && errorData.code.toString() === '32') {
        this.emailExist = true;
        return of([]);
      }
    }
    return throwError(result);
  }

  // accountCreation
  private addStudyCodeTaken(
    result: HttpResponse<any> | HttpErrorResponse,
    codeUsedArray: Array<{ id: number; codeTaken: boolean; code: string }>
  ): Array<{ id: number; codeTaken: boolean; code: string }> {
    if (result instanceof HttpErrorResponse) {
      const isInvalid =
        result.status === 403 &&
        (result.statusText === 'Forbidden' ||
          result.statusText === 'OK' ||
          (result.error.errors[0] && result.error.errors[0].title === 'Invalid Codes'));
      const id = parseInt(result.url.replace(environment.backendURL.toString() + '/api/v1/ecoach/studies/', '').replace(/\D+/g, ''), 10);
      const code = isInvalid ? result.error.errors[0].detail.replace('The following codes are used already: ', '').trim() : '';
      codeUsedArray.push({ id, codeTaken: true, code });
    }
    return codeUsedArray;
  }

  // accountCreation
  private createMultipleInstancesAsECoach(payloads: Array<PayloadInterface>): Observable<any> {
    this.interventionInstanceStore.createMultipleInstancesAsECoach(payloads);
    return this.createMultipleInstancesAsECoachResponse$.pipe(
      skip(1),
      take(1),
      mergeMap((results: any) => {
        const responses = Array.isArray(results) ? results : [results];
        if (responses?.every(res => res instanceof HttpResponse)) {
          responses.forEach((val, index) => {
            const newInstanceId = parseInt(val.headers.get('Location').substring(val.headers.get('Location').lastIndexOf('/') + 1), 10);
            const foundConfirmations: InstanceCreationConfirmationDialogComponent = this.components.find(
              c => c instanceof InstanceCreationConfirmationDialogComponent
            );
            if (!!foundConfirmations.reminderConfigs[index]) {
              this.reminderStore.createInterventionInstanceReminder({
                instanceId: newInstanceId,
                receiverId: this.newUserId,
                maxTimes: foundConfirmations.reminderConfigs[index].payload.data.attributes.configuration.max_times,
                start: foundConfirmations.reminderConfigs[index].payload.data.attributes.configuration.start,
                repeatTime: foundConfirmations.reminderConfigs[index].payload.data.attributes.configuration.pause,
                repeatUnit: foundConfirmations.reminderConfigs[index].payload.data.attributes.configuration.time_unit,
                contentText: foundConfirmations.reminderConfigs[index].payload.data.attributes.content.text,
                contentEnding: foundConfirmations.reminderConfigs[index].payload.data.attributes.content.ending,
                locale: foundConfirmations.reminderConfigs[index].payload.data.attributes.content.locale
              });
            }
          });
          return of(null);
        } else {
          return throwError('INSTANCE_FAILED');
        }
      })
    );
  }

  // accountCreation & patientStudyInvitation
  private getQuestionnairesOfSelectedIntervention(
    foundInterventionSelect: PatientCreationInterventionSelectionComponent
  ): Observable<Array<LessonInterface[]>> {
    const selectedInterventions = foundInterventionSelect.selectedInterventionSubject.value
      .map(
        (selection: { study_id: number; interventions: Array<InterventionInterface>; collaborators: Array<UserInterface> }) =>
          selection.interventions
      )
      .flatMap(interventions => interventions);
    const payload = selectedInterventions.map((intervention: InterventionInterface) => ({ interventionId: intervention.id }));
    this.interventionsSubject.next(selectedInterventions.map(value => value.id));
    if (payload.length > 0) {
      this.questionnaireStore.getQuestionnairesOfMultipleInterventions(payload);
      return this.questionnairesOfMultipleInterventions$.pipe(skip(1), take(1));
    }
    return throwError(null);
  }

  // accountCreation & patientStudyInvitation
  private getDiariesOfSelectedIntervention(foundInterventionSelect: PatientCreationInterventionSelectionComponent): Observable<any> {
    const selectedInterventions = foundInterventionSelect.selectedInterventionSubject.value
      .map(
        (selection: { study_id: number; interventions: Array<InterventionInterface>; collaborators: Array<UserInterface> }) =>
          selection.interventions
      )
      .flatMap(interventions => interventions);
    const payload = selectedInterventions.map((intervention: InterventionInterface) => ({ studyId: intervention.attributes.study_id }));
    this.interventionsSubject.next(selectedInterventions.map(value => value.id));
    this.diaryStore.getDiariesOfStudies(payload);
    return this.diariesOfStudies$.pipe(skip(1), take(1));
  }

  private showNextInstanceCreationConfirmationDialogComponent(
    foundInterventionSelect?: PatientCreationInterventionSelectionComponent,
    foundInstance?: PatientCreationInstanceCreationComponent,
    foundConfirmation?: InstanceCreationConfirmationDialogComponent,
    foundAccount?: PatientCreationAccountComponent,
    foundStudyInvitation?: PatientInvitationStudyComponent
  ): void {
    if (!!foundInstance && !foundInstance.isHiddenSubject.value) {
      foundInstance.myInstances = [];
      foundInstance.creationFormComponents.forEach((component: InstanceCreationFormComponent) => {
        component.confirmInstanceSettings();
      });
    }

    const interventionSet: Array<{
      intervention_id: number;
      questionnaires: Array<LessonInterface>;
      diaries: Array<DiaryInterface>;
      skills: Array<SkillInterface>;
    }> = [];

    this.getQuestionnairesOfSelectedIntervention(foundInterventionSelect)
      .pipe(
        mergeMap((results: any) => {
          results.forEach((questionnaires: Array<LessonInterface>) => {
            interventionSet.push({
              intervention_id: questionnaires[0].attributes.intervention_id,
              questionnaires,
              diaries: [],
              skills: []
            });
          });
          return this.getDiariesOfSelectedIntervention(foundInterventionSelect);
        }),
        mergeMap((results: any) => {
          results.forEach((result: any, index: number) => {
            interventionSet[index].diaries = result;
          });
          return forkJoin(this.helperSkillService.getSkillsOfSelectedIntervention(foundInterventionSelect, this.interventionsSubject));
        })
      )
      .subscribe((results: Array<any>) => {
        results.forEach((result: any, index: number) => {
          interventionSet[index].skills = result instanceof HttpResponse ? result.body.data : [];
        });

        foundConfirmation._studiesAndIntervention(foundInterventionSelect.selectedInterventionSubject.value);
        foundConfirmation._questionnairesIntervention(interventionSet);
        foundConfirmation._payloads(foundInstance.myInstances);
        foundConfirmation._myUserId(this.myUserId);
        if (foundAccount) {
          foundConfirmation._email(foundAccount.patientForm.get('email').value.toLowerCase());
        } else if (this.selectedUser.attributes.email) {
          foundConfirmation._email(this.selectedUser.attributes.email);
        }

        const nestedCollaborators = foundInterventionSelect.selectedInterventionSubject.value.map(x => x.collaborators);
        foundConfirmation._collaborators = [].concat(...nestedCollaborators);
        this.setComponentVisibilityICD(
          this.components,
          [foundInterventionSelect, foundInstance, foundAccount, foundStudyInvitation],
          [foundConfirmation]
        );
        this.alertService.reset();
      });
  }

  private showNextPatientCreationInstanceCreationComponent(
    foundInterventionSelect?: PatientCreationInterventionSelectionComponent,
    foundInstance?: PatientCreationInstanceCreationComponent,
    foundConfirmation?: InstanceCreationConfirmationDialogComponent,
    foundAccount?: PatientCreationAccountComponent,
    foundStudyInvitation?: PatientInvitationStudyComponent
  ) {
    this.isLoadingButtonSubject.next(true);
    let isSelectionError = true;

    foundInterventionSelect.fInterventionList.value.forEach(entry => {
      // At least one intervention selected
      if (entry.study_interventions.length > 0) {
        const atLeastOneSelected =
          entry.study_interventions.map(val => (val === null ? false : val)).reduce((val, curr) => val || curr) &&
          entry.study_interventions.length > 0;
        isSelectionError = isSelectionError && !atLeastOneSelected;
      }
    });

    if (isSelectionError) {
      foundInterventionSelect.submitted = true;
      this.isLoadingButtonSubject.next(false);
      return;
    } else {
      const selectedInterventions = foundInterventionSelect.selectedInterventionSubject.value
        .map(
          (selection: { study_id: number; interventions: Array<InterventionInterface>; collaborators: Array<UserInterface> }) =>
            selection.interventions
        )
        .flatMap(interventions => interventions);
      const selectedInterventionsIds = selectedInterventions.map(value => value.id).sort();
      if (
        !(
          foundInterventionSelect.selectedInterventionSubject.value.length === this.selectedInterventionsSubject.value.length &&
          this.selectedInterventionsSubject.value.sort().every((value, index) => value === selectedInterventionsIds[index])
        )
      ) {
        this.selectedInterventionsSubject.next(selectedInterventionsIds);
        const questionnairesOfInterventions: Array<{ intervention_id: number; questionnaires: Array<LessonInterface> }> = [];

        this.getQuestionnairesOfSelectedIntervention(foundInterventionSelect).subscribe((results: any) => {
          results.forEach((questionnaires: Array<LessonInterface>) => {
            questionnairesOfInterventions.push({ intervention_id: questionnaires[0].attributes.intervention_id, questionnaires });
          });
          // Set Input() for PatientCreationInstanceCreationComponent
          foundInstance._myUserId(this.myUserId);
          foundInstance.selectedIntervention(foundInterventionSelect.selectedInterventionSubject.value);
          foundInstance.selectedQuestionnaires(questionnairesOfInterventions);
          this.setComponentVisibilityICD(
            this.components,
            [foundInterventionSelect, foundConfirmation, foundAccount, foundStudyInvitation],
            [foundInstance]
          );
          this.alertService.reset();
        });
      } else {
        this.setComponentVisibilityICD(
          this.components,
          [foundInterventionSelect, foundConfirmation, foundAccount, foundStudyInvitation],
          [foundInstance]
        );
        this.alertService.reset();
      }
    }
  }

  private showNextPatientCreationInterventionSelectionComponent(
    componentClass,
    foundInterventionSelect?: PatientCreationInterventionSelectionComponent,
    foundInstance?: PatientCreationInstanceCreationComponent,
    foundConfirmation?: InstanceCreationConfirmationDialogComponent,
    foundAccount?: PatientCreationAccountComponent,
    foundStudyInvitation?: PatientInvitationStudyComponent
  ) {
    this.isLoadingButtonSubject.next(true);
    // Get selected studies and get all interventions of each study
    let selectedStudyIds = [];
    let selectedStudies: Array<StudyInterface> = [];
    if (foundAccount) {
      foundAccount.patientForm.get('email').markAsTouched();
      foundAccount.submitted = true;
      // Check if patientForm is valid
      const noneIsSelected = !(
        foundAccount.patientForm
          .get('studyList')
          .value.map(val => (val.checked === null ? false : val.checked))
          .reduce((val, curr) => val || curr) && foundAccount.patientForm.get('studyList').value.length > 0
      );
      if (foundAccount.patientForm.invalid || foundAccount.patientForm.get('studyList').value === undefined || noneIsSelected) {
        this.isLoadingButtonSubject.next(false);
        return;
      }
      if (foundAccount.patientForm.get('studyList')?.value) {
        selectedStudyIds = foundAccount.patientForm
          .get('studyList')
          ?.value.map((v, i) => (v.checked ? parseInt(foundAccount.studyList[i].id.toString(), 10) : null))
          .filter(v => v !== null);
      }
      selectedStudies = foundAccount.studies;
    } else if (foundStudyInvitation) {
      selectedStudies = foundStudyInvitation.studyList.filter(
        (value, i: number) => (foundStudyInvitation.studyForm.controls.studyList as UntypedFormArray).controls[i].status !== 'DISABLED'
      );

      selectedStudyIds = foundStudyInvitation.studyForm.value.studyList
        .map((v, i) => {
          if (v.checked) {
            return v.code !== '' || v.code != null ? { id: selectedStudies[i].id, code: v.code } : { id: selectedStudies[i].id };
          } else {
            return null;
          }
        })
        .filter(v => v !== null)
        .map(list => list.id);
    }
    // eslint-disable-next-line max-len
    // !(selectedStudyIds.length === this.studiesSubject.value.length && this.studiesSubject.value.sort().every((value, index) => value === selectedStudyIds.sort()[index]))
    if (selectedStudyIds.length > 0) {
      this.studiesSubject.next(selectedStudyIds);
      const payload = selectedStudyIds.map((id: number) => ({ studyId: id, include: 'collaborators' }));
      if (payload.length > 0) {
        this.getAllInterventionsOfStudySelection(payload, selectedStudyIds).subscribe(
          (results: any) => {
            selectedStudyIds.forEach((id: number, index) => {
              const resp = results.find(result => result.studyId.toString() === id.toString());
              this.studiesDetail[index].collaborators = resp ? resp.collaborators : [];
            });

            if (!foundInterventionSelect) {
              const componentInner: any = this.accountAssignmentContainer.createComponent<
                | PatientCreationInterventionSelectionComponent
                | PatientCreationInstanceCreationComponent
                | InstanceCreationConfirmationDialogComponent
              >(componentClass).instance;

              componentInner.hide(true);
              this.components.push(componentInner);
            }

            foundInterventionSelect = this.setPatientCreationInterventionSelectionComponent(
              this.studiesDetail,
              selectedStudies,
              true,
              true
            );
            this.setComponentVisibilityICD(
              this.components,
              [foundInstance, foundConfirmation, foundAccount, foundStudyInvitation],
              [foundInterventionSelect]
            );
            this.alertService.reset();
          },
          () => {
            this.isLoadingButtonSubject.next(false);
            this.showButtonsICD(this.components, this.showButtonAssignmentSubject);
          }
        );
      }

      if (foundInterventionSelect) {
        foundInterventionSelect.studyIntervention([]);
      }
      this.setComponentVisibilityICD(
        this.components,
        [foundInstance, foundConfirmation, foundAccount, foundStudyInvitation],
        [foundInterventionSelect]
      );
      this.alertService.reset();
    } else {
      this.setComponentVisibilityICD(
        this.components,
        [foundInterventionSelect, foundInstance, foundConfirmation, foundStudyInvitation],
        [foundAccount]
      );
      this.alertService.reset();
      if (foundStudyInvitation) {
        foundStudyInvitation.showErrorMessage();
      }
    }
  }

  private getAllInstancesOfInterventionOfPatient(userId: number): Observable<any> {
    this.interventionInstanceStore.getAllInstancesOfInterventionOfPatient({ userId });
    return this.allInstancesOfInterventionOfPatient$.pipe(skip(1), take(1));
  }

  private setPatientCreationInterventionSelectionComponent(
    studyIntervention?: Array<{ study_id: number; interventions: Array<InterventionInterface>; collaborators: Array<UserInterface> }>,
    studySelection?: Array<StudyInterface>,
    initialized?: boolean,
    addInterventionSelectionPerStudy?: boolean,
    setInstances?: Array<InterventionInstanceInterface>
  ): PatientCreationInterventionSelectionComponent {
    const foundInterventionSelect = this.components.find(c => c instanceof PatientCreationInterventionSelectionComponent);
    if (foundInterventionSelect) {
      if (studyIntervention) {
        foundInterventionSelect.studyIntervention(studyIntervention);
      }
      if (studySelection) {
        foundInterventionSelect.studySelection(studySelection);
      }
      if (initialized !== undefined) {
        foundInterventionSelect.initialized(true);
      }
      if (addInterventionSelectionPerStudy !== undefined) {
        foundInterventionSelect.addInterventionSelectionPerStudy();
      }
      if (setInstances !== undefined) {
        foundInterventionSelect.setInstances(setInstances);
      }
    }
    return foundInterventionSelect;
  }

  private getSelectedIdsAndCodes(foundAccount: PatientCreationAccountComponent): Array<{ id: number; code: string }> {
    let selectedStudyIdsAndCodes: Array<{ id: number; code: string }> = [];
    if (foundAccount) {
      if (foundAccount.patientForm.value.studyList) {
        selectedStudyIdsAndCodes = foundAccount.patientForm
          .get('studyList')
          .value.map((v, i) => (v.checked ? { id: parseInt(foundAccount.studyList[i].id.toString(), 10), code: v.code } : null))
          .filter(v => v !== null);
      }
    }
    return selectedStudyIdsAndCodes;
  }

  private registerAndStudyInvitation(foundAccount: PatientCreationAccountComponent): Observable<any> {
    let selectedStudyIdsAndCodes: Array<{ id: number; code: string }> = [];
    if (foundAccount.patientForm.value.studyList) {
      selectedStudyIdsAndCodes = this.getSelectedIdsAndCodes(foundAccount);
    }
    const payloadAccount: PayloadInterface = {
      data: {
        type: 'users',
        attributes: {
          email: foundAccount.patientForm.get('email').value
        }
      }
    };

    this.userStore.registerPatientAsECoach({ payload: payloadAccount, language: this.selectedLanguage });
    return this.registerPatientAsECoachResponse$.pipe(
      skip(1),
      take(1),
      mergeMap((result: any) => iif(() => result instanceof HttpResponse, of(result), throwError(result))),
      mergeMap((result: any) => {
        this.newUserId = parseInt(result.headers.get('Location').substring(result.headers.get('Location').lastIndexOf('/') + 1), 10);
        return of(result);
      }),
      catchError(result => this.catchAccountRegistrationError(result)),
      mergeMap((result: any) => this.getInvitations(result)),
      mergeMap((result: any) => this.checkAndSendStudyInvitations(result, foundAccount, selectedStudyIdsAndCodes))
    );
  }

  private clearContainer() {
    this.components = [];
    if (this.accountAssignmentContainer) {
      this.accountAssignmentContainer.clear();
    }
  }

  private getAllInterventionsOfStudySelection(payload: any, selectedStudyIds: Array<number>): Observable<any> {
    this.interventionStore.getAllInterventionsOfStudies(payload);
    return this.allInterventionsOfStudies$.pipe(
      skip(1),
      take(1),
      switchMap((results: any) => {
        this.studiesDetail = selectedStudyIds.map((id: number, index: number) => ({
          study_id: id,
          interventions: results[index],
          collaborators: results[index].length > 0 ? results[index][0].relationships.collaborators : []
        }));
        const params: Array<{ studyId: number; include: string }> = this.studiesDetail.map(
          (value: { study_id: number; interventions: Array<InterventionInterface>; collaborators: Array<UserInterface> }) => ({
            studyId: value.study_id,
            include: 'roles'
          })
        );
        if (params.length > 0) {
          this.store.dispatch({ type: StudyActionTypes.getCollaboratorsOfMultipleStudiesType, payload: params });
          this.collaboratorsOfStudies$ = this.store.select(getCollaboratorsByMultipleStudyIds(params.map(value => value.studyId)));
          return this.collaboratorsOfStudies$.pipe(skip(1), take(1));
        }
        return throwError('No selection...');
      })
    );
  }
}
