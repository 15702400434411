import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons/faWindowClose';
import { faBriefcase } from '@fortawesome/free-solid-svg-icons/faBriefcase';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons/faCheckSquare';
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons/faEnvelopeOpenText';
import { faHands } from '@fortawesome/free-solid-svg-icons/faHands';
import { faHandsHelping } from '@fortawesome/free-solid-svg-icons/faHandsHelping';
import { faKey } from '@fortawesome/free-solid-svg-icons/faKey';
import { faList } from '@fortawesome/free-solid-svg-icons/faList';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faSitemap } from '@fortawesome/free-solid-svg-icons/faSitemap';
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons/faPlayCircle';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons/faFilePdf';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, filter, mergeMap, Observable, of, skip, Subscription, switchMap, take, tap } from 'rxjs';
import { InterventionInstanceReminderInterface } from '../../../models/interface/intervention-instance-reminder.interface';
import { InterventionInstanceInterface } from '../../../models/interface/intervention-instances/intervention-instance.interface';
import { InterventionInterface } from '../../../models/interface/intervention.interface';
import { LessonInterface } from '../../../models/interface/lesson.interface';
import { ProfileInterface } from '../../../models/interface/profile.interface';
import { StudyInterface } from '../../../models/interface/study/study.interface';
import { UserInterface } from '../../../models/interface/user.interface';
import { HelperDialogService } from '../../../services/helper/helper-dialog/helper-dialog.service';
import { HelperService } from '../../../services/helper/helper.service';
import { InterventionInstanceStore } from '../../../store/intervention-instance/component-store/intervention-instance.store';
import { InterventionStore } from '../../../store/intervention/component-store/intervention.store';
import { QuestionnaireStore } from '../../../store/lesson-questionnaire/component-store/lesson-questionnaire.store';
import { ReminderActionTypes } from '../../../store/reminder/reminder.action';

@Component({
  selector: 'app-card-intervention-instance',
  templateUrl: './card-intervention-instance.component.html',
  styleUrls: ['./card-intervention-instance.component.scss'],
  providers: [InterventionInstanceStore, QuestionnaireStore, InterventionStore]
})
export class CardInterventionInstanceComponent implements OnInit, OnDestroy {
  @Output()
  public emitUpdatePerformed: EventEmitter<any> = new EventEmitter<any>();

  // Icons
  faSearch = faSearch;
  faList = faList;
  faUsers = faUsers;
  faKey = faKey;
  faEnvelopeOpenText = faEnvelopeOpenText;
  faSitemap = faSitemap;
  faBriefcase = faBriefcase;
  faCheckSquare = faCheckSquare;
  faStar = faStar;
  faPlus = faPlus;
  faHands = faHands;
  faHandsHelping = faHandsHelping;
  faWindowClose = faWindowClose;
  faPlayCircle = faPlayCircle;
  faEdit = faEdit;
  faInfoCircle = faInfoCircle;
  faFilePdf = faFilePdf;
  faEnvelope = faEnvelope;

  public instance: InterventionInstanceInterface;

  public users: Array<UserInterface> = [];
  public studyCollaborators: Array<UserInterface> = [];

  public initialInterventions: Array<InterventionInterface> = [];

  public allInterventionInstanceReminders$: Observable<Array<InterventionInstanceReminderInterface>>;

  public interventionInstanceReminders: Array<InterventionInstanceReminderInterface>;

  public isManager$: Observable<boolean>;

  public profile$: Observable<ProfileInterface> = null;

  public profile: ProfileInterface;

  public instance$: Observable<InterventionInstanceInterface>;

  public questionnairesOfIntervention: Array<{
    id: number;
    lessons: Array<LessonInterface>;
    intervention: InterventionInterface;
  }> = [];

  public param = { intervention_name: '...' };
  private myRoleSlug: string;

  private allInterventionsOfStudy$: Observable<Array<InterventionInterface>>;

  private groups$: Observable<Array<StudyInterface>>;

  private unpauseInstanceResponse$: Observable<any>;

  private questionnairesOfStudy$: Observable<Array<LessonInterface>>;

  private isManagerSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private helperService: HelperService,
    private helperDialogService: HelperDialogService,
    private translateService: TranslateService,
    private interventionInstanceStore: InterventionInstanceStore,
    private questionnaireStore: QuestionnaireStore,
    private interventionStore: InterventionStore,
    private store: Store<{
      myProfile: ProfileInterface;
      getCollaboratorsByStudyId: { studyId: number; collaborators: UserInterface[] };
      myMembers: Array<UserInterface>;
      allInterventionInstanceReminders: Array<InterventionInstanceReminderInterface>;
      collabGroups: Array<StudyInterface>;
    }>
  ) {
    this.profile$ = store.select('myProfile');
    this.allInterventionInstanceReminders$ = this.store.select('allInterventionInstanceReminders');
    this.groups$ = this.store.select('collabGroups');
    this.instance$ = this.interventionInstanceStore.instance$;
    this.unpauseInstanceResponse$ = this.interventionInstanceStore.unpauseInstanceResponse$;
    this.allInterventionsOfStudy$ = this.interventionStore.allInterventionsOfSpecificStudy$;
    this.questionnairesOfStudy$ = this.questionnaireStore.questionnairesOfStudy$;
    this.isManager$ = this.isManagerSubject.asObservable();
  }

  public get helper() {
    return this.helperService;
  }

  public get helperDialog() {
    return this.helperDialogService;
  }

  @Input()
  set _interventionInstance(_interventionInstance: InterventionInstanceInterface) {
    if (_interventionInstance) {
      this.instance = _interventionInstance;
    }
  }

  @Input()
  set _users(_users: Array<UserInterface>) {
    if (_users) {
      this.users = _users;
    }
  }

  @Input()
  set _studyCollaborators(_studyCollaborators: Array<UserInterface>) {
    if (_studyCollaborators) {
      this.studyCollaborators = _studyCollaborators;
    }
  }

  ngOnInit(): void {
    this.profile$.subscribe(profile => {
      this.profile = profile;
    });

    this.allInterventionInstanceReminders$.subscribe((reminders: Array<InterventionInstanceReminderInterface>) => {
      this.interventionInstanceReminders = reminders;
    });

    this.subscriptions.push(
      this.groups$.subscribe((result: any) => {
        const studies: Array<StudyInterface> = result;
        const findStudy: StudyInterface = result.find(study => study.id.toString() === this.instance.attributes.study_id.toString());
        if (studies.length > 0) {
          this.myRoleSlug = this.helper.getHighestRoleOfStudy(findStudy);
          if (this.myRoleSlug === 'study.owner' || this.myRoleSlug === 'study.ecoachmanager') {
            this.isManagerSubject.next(true);
          }
        }
      })
    );
  }

  public openDialogInterventionInstanceDetails(): void {
    this.helperDialog
      .openDialogInterventionInstanceDetails(this.instance.id, this.param)
      .afterClosed()
      .pipe(
        switchMap(() => {
          this.interventionInstanceStore.getInstance(this.instance.id);
          return this.instance$.pipe(
            filter(value => !!value),
            take(1)
          );
        })
      )
      .subscribe((result: InterventionInstanceInterface) => {
        this.emitUpdatePerformed.emit(true);
      });
  }

  public openDialogInterventionInstanceUpdate(): void {
    let foundIntervention;
    this.interventionStore.getAllInterventionsOfSpecificStudy({ studyId: this.instance.attributes.study_id });
    this.allInterventionsOfStudy$
      .pipe(
        skip(1),
        take(1),
        mergeMap((interventions: Array<InterventionInterface>) => {
          this.initialInterventions = interventions;
          foundIntervention = this.initialInterventions.find(
            (intervention: InterventionInterface) => intervention.id.toString() === this.instance.attributes.intervention_id.toString()
          );
          if (foundIntervention) {
            this.param.intervention_name = foundIntervention.attributes.name;
          }
          this.questionnaireStore.getQuestionnairesStudy({ studyId: this.instance.attributes.study_id });
          return this.questionnairesOfStudy$.pipe(skip(1), take(1));
        }),
        mergeMap((questionnaires: Array<LessonInterface>) => {
          const lessons = questionnaires.filter(
            (questionnaire: LessonInterface) => questionnaire.attributes.intervention_id.toString() === foundIntervention.id.toString()
          );
          return this.helperDialog
            .openDialogInterventionInstanceUpdate(
              this.instance,
              this.param,
              this.helper.excludeUsersWithOnlyStudyAccess(this.studyCollaborators),
              foundIntervention,
              this.users,
              lessons,
              this.profile.id,
              this.isManagerSubject
            )
            .afterClosed()
            .pipe(
              mergeMap(value => {
                if (value !== 'SUCCESS') {
                  if (this.isManagerSubject.value) {
                    this.interventionInstanceStore.unpauseInstanceECoachManager({ instanceId: this.instance.id });
                  } else {
                    this.interventionInstanceStore.unpauseInstance({ instanceId: this.instance.id });
                  }
                  return this.unpauseInstanceResponse$.pipe(
                    filter(resp => !!resp),
                    take(1),
                    mergeMap(() => of(null))
                  );
                } else {
                  return of(null);
                }
              }),
              switchMap(() => {
                this.interventionInstanceStore.getInstance(this.instance.id);
                return this.instance$.pipe(
                  filter(value => !!value),
                  take(1)
                );
              })
            );
        })
      )
      .subscribe((result: InterventionInstanceInterface) => {
        this.emitUpdatePerformed.emit(true);
      });
  }

  public openDialogInterventionInstanceDelete(): void {
    let foundIntervention;
    this.interventionStore.getAllInterventionsOfSpecificStudy({ studyId: this.instance.attributes.study_id });
    this.allInterventionsOfStudy$
      .pipe(
        skip(1),
        take(1),
        mergeMap((interventions: Array<InterventionInterface>) => {
          this.initialInterventions = interventions;
          foundIntervention = this.initialInterventions.find(
            (intervention: InterventionInterface) => intervention.id.toString() === this.instance.attributes.intervention_id.toString()
          );
          if (foundIntervention) {
            this.param.intervention_name = foundIntervention.attributes.name;
          }
          return this.helperDialog
            .openDialogInterventionInstanceDelete(
              this.param,
              this.studyCollaborators,
              foundIntervention,
              this.users,
              this.isManagerSubject,
              this.instance
            )
            .afterClosed();
        })
      )
      .subscribe(value => {
        this.emitUpdatePerformed.emit(true);
      });
  }

  public openDialogInterventionInstanceReport(): void {
    let foundIntervention;
    this.interventionStore.getAllInterventionsOfSpecificStudy({ studyId: this.instance.attributes.study_id });
    this.allInterventionsOfStudy$
      .pipe(
        skip(1),
        take(1),
        mergeMap((interventions: Array<InterventionInterface>) => {
          this.initialInterventions = interventions;
          foundIntervention = this.initialInterventions.find(
            value => value.id.toString() === this.instance.attributes.intervention_id.toString()
          );
          this.param = { intervention_name: foundIntervention.attributes.title };
          this.questionnaireStore.getQuestionnairesStudy({ studyId: this.instance.attributes.study_id });
          return this.questionnairesOfStudy$.pipe(skip(1), take(1));
        }),
        mergeMap((questionnaires: Array<LessonInterface>) => {
          const lessons = questionnaires;
          return this.helperDialog
            .openDialogInterventionInstanceReport(
              this.param,
              this.studyCollaborators,
              foundIntervention,
              this.users,
              lessons,
              false,
              this.initialInterventions,
              this.users,
              this.instance
            )
            .afterClosed();
        })
      )
      .subscribe(result => {});
  }

  public openDialogInterventionInstanceReminderCreation(): void {
    this.helperDialog
      .openDialogCreateInterventionInstanceReminder(this.instance.id, this.instance.attributes.patient_id)
      .afterClosed()
      .subscribe(result => {
        this.store.dispatch({
          type: ReminderActionTypes.getAllInterventionInstanceRemindersOfUserType,
          payload: {}
        });
      });
  }

  public openDialogInterventionInstanceReminderDetails(reminder: InterventionInstanceReminderInterface): void {
    this.questionnaireStore.getQuestionnairesStudy({ studyId: this.instance.attributes.study_id });
    this.questionnairesOfStudy$
      .pipe(
        skip(1),
        take(1),
        mergeMap((questionnaires: Array<LessonInterface>) => {
          const lessons = questionnaires;
          return this.helperDialog
            .openDialogInterventionInstanceReminderDetails(reminder, this.instance.attributes.study_id, lessons)
            .afterClosed();
        })
      )
      .subscribe(result => {
        this.store.dispatch({
          type: ReminderActionTypes.getAllInterventionInstanceRemindersOfUserType,
          payload: {}
        });
      });
  }

  public hasReminder(): InterventionInstanceReminderInterface | undefined {
    const foundReminder = this.interventionInstanceReminders.find(
      (reminder: InterventionInstanceReminderInterface) => reminder.attributes.model_id.toString() === this.instance.id.toString()
    );
    return foundReminder;
  }

  public unpause(): void {
    if (this.isManagerSubject.value) {
      this.interventionInstanceStore.unpauseInstanceECoachManager({ instanceId: this.instance.id });
    } else {
      this.interventionInstanceStore.unpauseInstance({ instanceId: this.instance.id });
    }
    this.unpauseInstanceResponse$
      .pipe(
        filter(value => !!value),
        take(1)
      )
      .subscribe((result: any) => {
        this.emitUpdatePerformed.emit(true);
      });
  }

  public getDetailedIntervention(interventionId: number): void {
    this.router.navigateByUrl(`interventions/${interventionId}/instances`).then(() => {});
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
