import { Component, Input, OnInit } from '@angular/core';
import { DiaryInterface } from 'src/app/models/interface/diary.interface';
import { InterventionInterface } from 'src/app/models/interface/intervention.interface';
import { LessonInterface } from 'src/app/models/interface/lesson.interface';
import { StudyInterface } from 'src/app/models/interface/study/study.interface';
import { DiaryStore } from '../../../store/diary/component-store/diary.store';
import { InterventionStore } from '../../../store/intervention/component-store/intervention.store';
import { StudyStore } from '../../../store/study/component-store/study.store';
import { HelperService } from 'src/app/services/helper/helper.service';
import { BehaviorSubject, map, Observable, of } from 'rxjs';
import { QuestionnaireStore } from 'src/app/store/lesson-questionnaire/component-store/lesson-questionnaire.store';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-codebook-overview',
  templateUrl: './codebook-overview.component.html',
  styleUrls: ['./codebook-overview.component.scss'],
  providers: [InterventionStore, DiaryStore, StudyStore, QuestionnaireStore]
})
export class CodebookOverviewComponent implements OnInit {
  public displayedColumns: string[] = ['position', 'questionType', 'questionLabel', 'question', 'answer', 'raw'];
  public dataSource: MatTableDataSource<any>;

  // Filter
  public filter = {
    questionnaireSelection: ''
  };

  public study: StudyInterface;
  public interventions: Array<InterventionInterface> = [];
  public diaries: Array<DiaryInterface> = [];
  public questionnaires: Array<LessonInterface> = [];

  public questionnaireElements: Array<any> = [];

  public diariesOfStudy$: Observable<Array<DiaryInterface>>;

  public questionnairesOfIntervention$: Observable<Array<LessonInterface>>;
  public questionnaireOfDiary$: Observable<LessonInterface>;

  public selectedQuestionnaire: LessonInterface;

  public selectedLanguage: string;
  public languages: Array<string> = [];

  public exportInterventionCodebookResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');
  public exportDiaryCodebookResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');

  private exportInterventionCodebookResponse$: Observable<any>;
  private exportDiaryCodebookResponse$: Observable<any>;

  private elementsOfQuestionnaire$: Observable<Array<any>>;
  private allInterventionsOfStudy$: Observable<Array<InterventionInterface>>;

  constructor(
    private questionnaireStore: QuestionnaireStore,
    private interventionStore: InterventionStore,
    private diaryStore: DiaryStore,
    private helperService: HelperService,
    private translateService: TranslateService
  ) {
    this.allInterventionsOfStudy$ = this.interventionStore.allInterventionsOfSpecificStudy$;
    this.diariesOfStudy$ = this.diaryStore.diariesOfStudy$;
    this.questionnairesOfIntervention$ = this.questionnaireStore.questionnairesOfIntervention$;
    this.questionnaireOfDiary$ = this.diaryStore.questionnaireOfDiary$;
    this.elementsOfQuestionnaire$ = this.questionnaireStore.elementsOfQuestionnaire$;

    this.exportInterventionCodebookResponse$ = this.interventionStore.exportInterventionCodebookResponse$;
    this.exportDiaryCodebookResponse$ = this.diaryStore.exportDiaryCodebookResponse$;
  }

  public get helper() {
    return this.helperService;
  }

  @Input()
  set _study(_study: StudyInterface) {
    if (_study) {
      this.study = _study;
    }
  }

  ngOnInit(): void {
    this.interventionStore.getAllInterventionsOfSpecificStudy({ studyId: this.study.id, interventionType: undefined, isActive: 1 });

    this.allInterventionsOfStudy$.subscribe(interventions => {
      this.interventions = interventions;
    });

    this.diaryStore.getDiariesOfStudy({ studyId: this.study.id });
    this.diariesOfStudy$.subscribe(diaries => {
      this.diaries = diaries.filter((diary: DiaryInterface) => diary.attributes.study_id.toString() === this.study.id.toString());
    });

    this.questionnaireOfDiary$.subscribe((questionnaire: LessonInterface) => {
      this.selectedQuestionnaire = questionnaire;
    });
  }

  public getQuestionnairesOfIntervention(interventionId: number) {
    this.questionnaireStore.getQuestionnairesIntervention({ interventionId });
    this.questionnairesOfIntervention$.subscribe(result => {
      this.questionnaires = result;
      if (result.length > 0) {
        this.selectedQuestionnaire = result[0];
        this.languages = this.selectedQuestionnaire.attributes.locales ? this.selectedQuestionnaire.attributes.locales : [];
        this.selectedLanguage = this.languages.length > 0 ? this.languages[0] : null;
        this.changeSelection();
      }
    });
  }

  public getQuestionnaireOfDiary(diaryId: number) {
    this.diaryStore.getQuestionnaireOfDiary({ diaryId });
    this.questionnaireOfDiary$.subscribe(result => {
      this.questionnaires = [result];
      if (result !== null) {
        this.selectedQuestionnaire = result;
        this.languages = this.selectedQuestionnaire.attributes.locales ? this.selectedQuestionnaire.attributes.locales : [];
        this.selectedLanguage = this.languages.length > 0 ? this.languages[0] : null;
        this.changeSelection();
      }
    });
  }

  public getElementsOfQuestionnaire(questionnaireId: number) {
    this.questionnaireStore.getElementsOfQuestionnaire({ questionnaireId });
    this.elementsOfQuestionnaire$.subscribe((result: Array<any>) => {
      this.questionnaireElements = result.filter(element => !!element?.label);
      this.dataSource = new MatTableDataSource<any>(this.questionnaireElements);
    });
  }

  public resetFilter(): void {
    this.filter = {
      questionnaireSelection: ''
    };
  }

  public changeSelection() {
    this.getElementsOfQuestionnaire(this.selectedQuestionnaire.id);
  }

  public getCodebookQuestion(translations: Array<any>) {
    if (this.selectedLanguage) {
      return translations.find(element => element.locale.toString() === this.selectedLanguage).question;
    } else {
      return translations[0].question;
    }
  }

  public getCodebookAnswer(element: any): Observable<string> {
    switch (element.questiontype.toLowerCase()) {
      case 'yesnoswitch': {
        return this.translateService.get('codebook.yesno').pipe(map(translation => translation));
      }
      case 'singlechoice': {
        return of(element.translations.find(translation => translation.locale.toString() === this.selectedLanguage).answers);
      }
      case 'multiplechoice': {
        return of(element.translations.find(translation => translation.locale.toString() === this.selectedLanguage).answers);
      }
      case 'slider': {
        const translatedAnswers = element.translations.find(translation => translation.locale.toString() === this.selectedLanguage).answers;
        return of(
          translatedAnswers[0].label +
            '(' +
            translatedAnswers[0].value +
            ') - ' +
            translatedAnswers[1].label +
            '(' +
            translatedAnswers[1].value +
            ')'
        );
      }
      case 'textdate': {
        if (element.values[0] === 'date') {
          return this.translateService.get('codebook.date');
        } else if (element.values[0] === 'datetime') {
          return this.translateService.get('codebook.datetime');
        } else if (element.values[0] === 'time') {
          return this.translateService.get('codebook.time');
        } else {
          return of('');
        }
      }
      case 'textstring': {
        return this.translateService.get('codebook.textinput');
      }
      case 'textarea': {
        return this.translateService.get('codebook.textinput');
      }
      case 'questiontable': {
        return of(element.translations.find(translation => translation.locale.toString() === this.selectedLanguage).answers);
      }
      default:
        return of('');
    }
  }

  public getCodebookRawAnswer(element: any): string {
    switch (element.questiontype.toLowerCase()) {
      case 'yesnoswitch': {
        return element.values;
      }
      case 'singlechoice': {
        return element.values;
      }
      case 'multiplechoice': {
        return element.values;
      }
      case 'slider': {
        const translatedAnswers = element.translations.find(translation => translation.locale.toString() === this.selectedLanguage).answers;
        return this.helper.stringifyJSON(element.values);
      }
      case 'textdate': {
        if (element.values[0] === 'date') {
          return '(DD-MM-YYYY)';
        } else if (element.values[0] === 'datetime') {
          return '(DD-MM-YYYY HH:MM)';
        } else if (element.values[0] === 'time') {
          return '(HH:MM)';
        } else {
          return '';
        }
      }
      case 'textstring': {
        return '';
      }
      case 'textarea': {
        return '';
      }
      case 'questiontable': {
        return this.helper.stringifyJSON(element.values);
      }
      default:
        return '';
    }
  }

  public exportInterventionCodebook(interventionId: number): void {
    if (this.exportInterventionCodebookResponse.value === 'DEFAULT') {
      this.exportInterventionCodebookResponse.next('LOADING');

      this.interventionStore.exportCSVInterventionCodebook({ interventionId });
      this.exportInterventionCodebookResponse$.subscribe((value: any) => {
        if (value instanceof HttpErrorResponse) {
          this.exportInterventionCodebookResponse.next('FAILURE');
          setTimeout(() => {
            this.exportInterventionCodebookResponse.next('DEFAULT');
          }, 2500);
        } else {
          if (value.state && value.state === 'DONE') {
            this.exportInterventionCodebookResponse.next('SUCCESS');
            setTimeout(() => {
              this.exportInterventionCodebookResponse.next('DEFAULT');
            }, 2500);
          }
        }
      });
    }
  }

  public exportDiaryCodebook(interventionId: number): void {
    if (this.exportDiaryCodebookResponse.value === 'DEFAULT') {
      this.exportDiaryCodebookResponse.next('LOADING');

      this.diaryStore.exportCSVDiaryCodebook({ interventionId });
      this.exportDiaryCodebookResponse$.subscribe((value: any) => {
        if (value instanceof HttpErrorResponse) {
          this.exportDiaryCodebookResponse.next('FAILURE');
          setTimeout(() => {
            this.exportDiaryCodebookResponse.next('DEFAULT');
          }, 2500);
        } else {
          if (value.state && value.state === 'DONE') {
            this.exportDiaryCodebookResponse.next('SUCCESS');
            setTimeout(() => {
              this.exportDiaryCodebookResponse.next('DEFAULT');
            }, 2500);
          }
        }
      });
    }
  }
}
