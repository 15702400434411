import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { skip, take } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { OrganisationCollaboratorInterface } from '../../../models/interface/organisation/organisation-collaborator.interface';
import { PayloadInterface } from '../../../models/interface/payload.interface';
import { RequestBodyData } from '../../../models/request-body.data';
import { HelperService } from '../../../services/helper/helper.service';
import { UserInterface } from '../../../models/interface/user.interface';
import { PermissionInterface } from '../../../models/interface/permission.interface';
import { OrganisationStore } from '../../../store/organisation/component-store/organisation.store';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-dialog-organisation-collaborator-edit',
  templateUrl: './dialog-organisation-collaborator-edit.component.html',
  styleUrls: ['./dialog-organisation-collaborator-edit.component.scss'],
  providers: [OrganisationStore]
})
export class DialogOrganisationCollaboratorEditComponent implements OnInit, OnDestroy {
  public collaborator: UserInterface;
  public param;
  public organisationId: number;
  public collaborators: Array<UserInterface>;

  // Filter
  public filter = {
    userSelection: ''
  };

  public collaboratorsSubject: BehaviorSubject<Array<UserInterface>> = new BehaviorSubject<Array<UserInterface>>([]);
  public collaborators$: Observable<Array<UserInterface>> = this.collaboratorsSubject.asObservable();

  // List of roles
  public roles = ['organisation.manager', 'organisation.access'];
  public selectedRole: string;

  // Selected collaborator
  public selectedCollaborator: UserInterface;

  public updateCollaboratorResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');

  private updateOrganisationCollaboratorResponse$: Observable<any>;

  // Subscription Handler
  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private helperService: HelperService,
    private organisationStore: OrganisationStore,
    private dialogRef: MatDialogRef<DialogOrganisationCollaboratorEditComponent>
  ) {
    this.updateOrganisationCollaboratorResponse$ = this.organisationStore.updateOrganisationCollaboratorResponse$;
  }

  // Helper function
  public get helper() {
    return this.helperService;
  }

  ngOnInit(): void {
    this.collaborator = this.data.collaborator;
    this.param = this.data.param;
    this.organisationId = this.data.organisationId;
    this.collaborators = this.data.collaborators;
  }

  public getRolesOfCollaborator(user: OrganisationCollaboratorInterface): Array<PermissionInterface> {
    return user.attributes.roles;
  }

  public onChangeCollaborator(collaborator: OrganisationCollaboratorInterface): void {
    // Form to add an existing editor to an organisation with a role
    const allRoles = this.getRolesOfCollaborator(collaborator);

    const foundOrganisationmanager = allRoles.find(role => role.slug === 'organisation.manager');
    if (foundOrganisationmanager) {
      this.selectedRole = this.roles[0];
    }

    const foundOrganisationaccess = allRoles.find(role => role.slug === 'organisation.access');
    if (foundOrganisationaccess) {
      this.selectedRole = this.roles[1];
    }
  }

  public isNoneSelectedConfigureRoleForm(): boolean {
    return !this.selectedCollaborator && !this.selectedRole;
  }

  // Adding collaborator to organisation
  public updateCollaborator(): void {
    if (this.selectedCollaborator && this.selectedRole) {
      const removingRoles = this.roles.filter((role: string) => role !== this.selectedRole);
      const addRoles: Array<{ id: number; role: string }> = [];
      const removeRoles: Array<{ id: number; role: string }> = [];
      addRoles.push({ id: this.selectedCollaborator.id, role: this.selectedRole });
      removingRoles.forEach((role: string) => {
        removeRoles.push({ id: this.selectedCollaborator.id, role });
      });
      const payloadRemove: PayloadInterface = new RequestBodyData('organisations', { collaborators: removeRoles });
      const payloadAdd: PayloadInterface = new RequestBodyData('organisations', { collaborators: addRoles });

      this.updateCollaboratorResponse.next('LOADING');

      this.organisationStore.updateOrganisationCollaborator({
        organisationId: this.organisationId,
        payloadRemove,
        payloadAdd
      });

      this.subscriptions.push(
        this.updateOrganisationCollaboratorResponse$.pipe(skip(1), take(1)).subscribe((results: Array<any>) => {
          if (results.every(result => result instanceof HttpResponse)) {
            this.updateCollaboratorResponse.next('SUCCESS');
            setTimeout(() => {
              this.updateCollaboratorResponse.next('DEFAULT');
              this.dialogRef.close('SUCCESS');
            }, 2500);
          } else {
            this.updateCollaboratorResponse.next('FAILURE');
            setTimeout(() => {
              this.updateCollaboratorResponse.next('DEFAULT');
            }, 2500);
          }
        })
      );
    }
  }

  public resetFilter(): void {
    this.filter = {
      userSelection: ''
    };
    this.collaboratorsSubject.next(this.collaborators);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
