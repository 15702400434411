import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { HttpErrorResponseMessage } from 'src/app/enum/http-error-response-message';
import { InterventionService } from '../../services/api/intervention/intervention.service';
import {
  addCollabInterventionsStore,
  addInactiveCollabInterventionsStore,
  addNonCollabInterventionsStore,
  GetInterventionsOfCollabStudy,
  GetInterventionsOfNonCollabStudy,
  InterventionActionTypes
} from './intervention.action';
import { ResponseSuccess } from '../utils/response-success';
import { ResponseError } from '../utils/response-error';
import { InterventionInterface } from 'src/app/models/interface/intervention.interface';

@Injectable()
export class InterventionEffects {
  getInterventionsOfCollabStudy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InterventionActionTypes.getInterventionsOfCollabStudyType),
      map((action: GetInterventionsOfCollabStudy) => action.payload),
      switchMap((payload: any) => {
        const payloadParams = payload;
        return this.interventionService
          .getInterventionsOfCollabStudy(
            payload.type,
            payload.interventionType,
            payload.isShareable,
            payload.isSubscribable,
            payload.isPrivate,
            null,
            payload.studyId,
            payload.include,
            payload.typeOfParentStudy,
            payload.role
          )
          .pipe(
            switchMap(result => {
              const actions = [];
              if (
                payload.interventionType === undefined &&
                payload.isShareable === undefined &&
                payload.isSubscribable === undefined &&
                payload.isPrivate === undefined &&
                payload.study_id === undefined
              ) {
                actions.push(
                  addCollabInterventionsStore({
                    collabInterventions: result.body.data.filter(
                      (intervention: InterventionInterface) => intervention.attributes.is_active === 1
                    )
                  })
                );
                actions.push(
                  addInactiveCollabInterventionsStore({
                    inactiveCollabInterventions: result.body.data.filter(
                      (intervention: InterventionInterface) => intervention.attributes.is_active === 0
                    )
                  })
                );
              }

              actions.push(new ResponseSuccess(InterventionActionTypes.getInterventionsOfCollabStudySuccessType, result));
              return actions;
            }),
            catchError(err =>
              of(
                new ResponseError(InterventionActionTypes.getInterventionsOfCollabStudyErrorType, {
                  message: HttpErrorResponseMessage.genericUnknownError,
                  content: err
                })
              )
            )
          );
      })
    )
  );

  getInterventionsOfNonCollabStudy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InterventionActionTypes.getInterventionsOfNonCollabStudyType),
      map((action: GetInterventionsOfNonCollabStudy) => action.payload),
      switchMap((payload: any) => {
        const payloadParams = payload;
        return this.interventionService
          .getInterventionsOfNonCollabStudy(
            payload.type,
            payload.interventionType,
            payload.isShareable,
            payload.isSubscribable,
            payload.isPrivate,
            payload.isActive,
            payload.studyId,
            payload.include,
            payload.typeOfParentStudy
          )
          .pipe(
            switchMap(result => {
              const actions = [];
              if (
                payload.interventionType === undefined &&
                payload.isShareable === undefined &&
                payload.isSubscribable === undefined &&
                payload.isPrivate === undefined &&
                payload.study_id === undefined
              ) {
                actions.push(addNonCollabInterventionsStore({ nonCollabInterventions: result.body.data }));
              }
              actions.push(new ResponseSuccess(InterventionActionTypes.getInterventionsOfNonCollabStudySuccessType, result));
              return actions;
            }),
            catchError(err =>
              of(
                new ResponseError(InterventionActionTypes.getInterventionsOfNonCollabStudyErrorType, {
                  message: HttpErrorResponseMessage.genericUnknownError,
                  content: err
                })
              )
            )
          );
      })
    )
  );

  constructor(private actions$: Actions, private interventionService: InterventionService) {}
}
