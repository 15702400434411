import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { HttpErrorResponseMessage } from 'src/app/enum/http-error-response-message';
import { AnnouncementService } from '../../services/api/announcement/announcement.service';
import { ResponseSuccess } from '../utils/response-success';
import { ResponseError } from '../utils/response-error';
import { addAnnouncementsStore, AnnouncementActionTypes, GetAnnouncements } from './announcement.action';

@Injectable()
export class AnnouncementEffects {
  readonly getAnnouncements$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnnouncementActionTypes.getAnnouncementsType),
      map((action: GetAnnouncements) => action.payload),
      switchMap(
        (payload: { client?: string; operatorStart?: '>' | '<' | '='; start?: number; operatorEnd?: '>' | '<' | '='; end?: number }) =>
          this.announcementService
            .getAllAnnouncements(payload.client, payload.operatorStart, payload.start, payload.operatorEnd, payload.end)
            .pipe(
              switchMap(result => {
                const actions = [];
                actions.push(addAnnouncementsStore({ announcements: result.body.data }));
                actions.push(new ResponseSuccess(AnnouncementActionTypes.getAnnouncementsSuccessType, result));
                return actions;
              }),
              catchError(err =>
                of(
                  new ResponseError(AnnouncementActionTypes.getAnnouncementsErrorType, {
                    message: HttpErrorResponseMessage.genericUnknownError,
                    content: err
                  })
                )
              )
            )
      )
    )
  );

  constructor(private actions$: Actions, private announcementService: AnnouncementService) {}
}
