/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, throwError } from 'rxjs';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { skip, take } from 'rxjs/operators';
import { HelperService } from '../../../services/helper/helper.service';
import { LessonInterface } from '../../../models/interface/lesson.interface';
import { AnswersheetAnswerInterface } from '../../../models/interface/answersheet-answer.interface';
import { InterventionInterface } from '../../../models/interface/intervention.interface';
import { QuestionnaireStore } from '../../../store/lesson-questionnaire/component-store/lesson-questionnaire.store';

/**
 * Component:
 * Lesson preview component displaying all elements of a specific lesson/questionnaire;
 */

@Component({
  selector: 'app-lesson-preview',
  templateUrl: './lesson-preview.component.html',
  styleUrls: ['./lesson-preview.component.scss'],
  providers: [QuestionnaireStore]
})
export class LessonPreviewComponent implements OnInit, OnDestroy {
  // Is diary
  public isDiary = false;
  public diaryDefaultImageColor;
  public studyId: number;

  // Background color
  public backgroundColor;

  public isLoading$: Observable<boolean>;

  public intervention: InterventionInterface;

  // Questionnaire_id for preview
  public questionnaireId: number;
  public lesson: LessonInterface;

  public questionnaire$: Observable<LessonInterface>;

  // Answers distributed to child elements
  public childAnswers: Array<AnswersheetAnswerInterface>;

  // Answers from elements
  public dynamicAnswers: Array<AnswersheetAnswerInterface> = [];

  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private subscriptions: Subscription[] = [];

  constructor(
    private translateService: TranslateService,
    private helperService: HelperService,
    private questionnaireStore: QuestionnaireStore
  ) {
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.questionnaire$ = this.questionnaireStore.questionnaire$;
  }

  public get helper() {
    return this.helperService;
  }

  // Set study and its configuration from parent
  @Input()
  set _questionnaire_id(_questionnaireId: number) {
    this.questionnaireId = _questionnaireId;
  }

  @Input()
  set _isDiary(_isDiary: boolean) {
    if (_isDiary !== undefined) {
      this.isDiary = _isDiary;
    }
  }

  @Input()
  set _diaryDefault(_diaryDefault: string) {
    if (_diaryDefault !== undefined) {
      this.diaryDefaultImageColor = _diaryDefault;
    }
  }

  @Input()
  set _study_id(_studyId: number) {
    if (_studyId !== undefined) {
      this.studyId = _studyId;
    }
  }

  @Input()
  set _intervention(_intervention: InterventionInterface) {
    if (_intervention !== undefined) {
      this.intervention = _intervention;
    }
  }

  ngOnInit(): void {
    // Returns lesson details and elements
    this.getLessonDetails(this.questionnaireId);

    this.subscriptions.push(
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
        this.getLessonDetails(this.questionnaireId);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private getLessonDetails(questionnaireId: number): void {
    this.questionnaireStore.getQuestionnaireDetails({ questionnaireId });
    this.questionnaire$.pipe(skip(1), take(1)).subscribe(
      (result: any) => {
        // Questionnaire details
        this.lesson = result;
        this.backgroundColor = this.isDiary ? this.diaryDefaultImageColor : this.lesson.attributes.page_color;

        // Initialize answer values
        this.dynamicAnswers = [];
        this.childAnswers = [];
      },
      error => {
        throwError(error);
      },
      () => {
        this.isLoadingSubject.next(false);
      }
    );
  }
}
