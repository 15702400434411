<button [attr.id]="'btnDialogClose'" mat-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title>{{'organisation-collaborator.collaborators_remove_modal_title' | translate:param}}</h1>
<div mat-dialog-content class="mat-typography">
  {{'organisation-collaborator.collaborators_remove_modal_body' | translate}}
  <p>{{collaborator.attributes.firstname}} {{collaborator.attributes.lastname}}</p>
  <p>{{collaborator.attributes.email}}</p>
</div>
<div mat-dialog-actions align="end">
  <button [attr.id]="'btnCancel'" mat-button mat-dialog-close>{{'organisation-collaborator.cancel' | translate}}</button>
  <app-button-feedback
    [_setId]="'btnRemoveCollaborator'"
    (click)="removeCollaborator(collaborator.id)"
    [_defaultButtonText]="'organisation-collaborator.collaborators_remove'"
    [_responseText]="removeCollaboratorResponse | async"
  ></app-button-feedback>
</div>
