import { Injectable } from '@angular/core';
import { AnswersheetAnswerInterface } from '../../models/interface/answersheet-answer.interface';
import { ElementInterface } from '../../models/interface/elements/element.interface';

@Injectable({
  providedIn: 'root'
})
export class ParserService {
  constructor() {}

  public parseElementsToReferenceEvaluation(elements: Array<ElementInterface>) {
    let jsonElements = {};
    elements.forEach((element: ElementInterface) => {
      if (element.questiontype === 'TextDate') {
        switch (element.values[0]) {
          case 'date': {
            jsonElements[element.position] = { questionType: 'TextDate', values: element.values };
            break;
          }
          case 'datetime': {
            jsonElements[element.position] = { questionType: 'TextDateTime', values: element.values };
            break;
          }
          case 'time': {
            jsonElements[element.position] = { questionType: 'TextTime', values: element.values };
            break;
          }
        }
      } else {
        jsonElements[element.position] = { questionType: element.questiontype, values: element.values };
      }
      if (element.translations) {
        element.translations.forEach(translation => {
          jsonElements[element.position]['translations'] = { [translation.locale]: translation.answers };
        });
      }
    });
    return jsonElements;
  }

  public parseAnswerToConditionEvaluation(answers: Array<AnswersheetAnswerInterface>) {
    let jsonAnswers = {};
    answers.forEach((answer: AnswersheetAnswerInterface) => {
      const values = answer.value;
      if (jsonAnswers[answer.position] !== undefined) {
        jsonAnswers[answer.position][answer.parentLoopIndex] = values;
      } else {
        jsonAnswers[answer.position] = { [answer.parentLoopIndex]: values };
      }
    });
    return jsonAnswers;
  }

  public parseAnswersheetToAnswer(answers: Array<AnswersheetAnswerInterface>, elements): Array<AnswersheetAnswerInterface> {
    const dynamic: Array<AnswersheetAnswerInterface> = [];
    if (elements !== undefined || answers.length > 0) {
      if (Array.isArray(answers)) {
        answers.forEach((answer: AnswersheetAnswerInterface) => {
          const found = elements.find(element => element.position === answer.position);
          const filter = answers.filter(answerEntry => answerEntry.position === answer.position);

          // If multiple choice - nested array
          if (found) {
            if (found.questiontype === 'MultipleChoice') {
              if (Array.isArray(answer.value) && answer.value.every(value => Array.isArray(value))) {
                answer.value.forEach((value, index) => {
                  dynamic.push({
                    position: found.position,
                    loop: true,
                    parentLoopIndex: index,
                    value: value.map(element => element.toString().trim())
                  });
                });
              } else {
                dynamic.push({
                  position: found.position,
                  loop: false,
                  parentLoopIndex: answer.parentLoopIndex ? answer.parentLoopIndex : 0,
                  value: answer.value ? answer.value.map(element => (element !== null ? element.toString().trim() : null)) : null
                });
              }
            } else if (found.questiontype === 'YesNoSwitch') {
              if (filter.length > 1) {
                let concatAnswers = [];
                filter.forEach((singleAnswer: AnswersheetAnswerInterface) => {
                  concatAnswers = concatAnswers.concat(singleAnswer.value);
                });
                concatAnswers.forEach((element, index) => {
                  dynamic.push({
                    position: found.position,
                    loop: true,
                    parentLoopIndex: index,
                    value: element ? element === 'true' || element : element
                  });
                });
              } else {
                if (Array.isArray(filter[0].value)) {
                  filter[0].value.forEach((element, index) => {
                    dynamic.push({
                      position: found.position,
                      loop: true,
                      parentLoopIndex: index,
                      value: element ? element === 'true' || element : element
                    });
                  });
                } else {
                  dynamic.push({
                    position: found.position,
                    loop: false,
                    parentLoopIndex: 0,
                    value: answer.value ? answer.value === 'true' || answer.value : answer.value
                  });
                }
              }
            } else if (found.questiontype === 'QuestionTable') {
              const value = Array.isArray(answer.value) ? answer.value[0] : answer.value;
              dynamic.push({
                position: found.position,
                loop: answer.loop,
                parentLoopIndex: answer.parentLoopIndex ? answer.parentLoopIndex : 0,
                value: answer.value
              });
            } else {
              if (filter.length > 1) {
                let concatAnswers = [];
                filter.forEach((singleAnswer: AnswersheetAnswerInterface) => {
                  concatAnswers = concatAnswers.concat(singleAnswer.value);
                });
                concatAnswers.forEach((element, index) => {
                  dynamic.push({
                    position: found.position,
                    loop: true,
                    parentLoopIndex: index,
                    value: element ? element.toString().trim() : element
                  });
                });
              } else {
                if (Array.isArray(filter[0].value)) {
                  filter[0].value.forEach((element, index) => {
                    dynamic.push({
                      position: found.position,
                      loop: true,
                      parentLoopIndex: index,
                      value: element ? element.toString().trim() : element
                    });
                  });
                } else {
                  dynamic.push({
                    position: found.position,
                    loop: false,
                    parentLoopIndex: 0,
                    value: answer.value ? answer.value.toString().trim() : answer.value
                  });
                }
              }
            }
          }
        });
      }
    }
    return dynamic;
  }
}
