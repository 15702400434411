/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, skip, Subscription, take } from 'rxjs';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons/faCheckCircle';
import { faCircle } from '@fortawesome/free-regular-svg-icons/faCircle';
import { faComment } from '@fortawesome/free-regular-svg-icons/faComment';
import { faCommentSlash } from '@fortawesome/free-solid-svg-icons/faCommentSlash';
import { Router } from '@angular/router';
import { AnswersheetInterface } from '../../../models/interface/answersheet.interface';
import { HelperFeedbackService } from '../../../services/helper/helper-feedback/helper-feedback.service';
import { InterventionInstanceInterface } from '../../../models/interface/intervention-instances/intervention-instance.interface';
import { HelperService } from '../../../services/helper/helper.service';
import { MessageThreadsInterface } from '../../../models/interface/message_threads.interface';
import { UserInterface } from '../../../models/interface/user.interface';
import { LessonInterface } from '../../../models/interface/lesson.interface';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogAnswersheetReportComponent } from '../../dialog/dialog-answersheet-report/dialog-answersheet-report.component';
import { UserStore } from '../../../store/user/component-store/user.store';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-card-submitted-answersheet',
  templateUrl: './card-submitted-answersheet.component.html',
  styleUrls: ['./card-submitted-answersheet.component.css'],
  providers: [UserStore]
})
export class CardSubmittedAnswersheetComponent implements OnInit, OnDestroy {
  // Icons
  faCheckCircle = faCheckCircle;
  faCircle = faCircle;
  faCommentSlash = faCommentSlash;
  faComment = faComment;
  faEnvelope = faEnvelope;

  public answersheet: AnswersheetInterface;
  public instance: InterventionInstanceInterface;
  public questionnaires: Array<LessonInterface> = [];
  public threads: Array<MessageThreadsInterface> = [];
  public studyMembers: Array<UserInterface> = [];

  public isGuidingECoach = true;

  // Response
  public sendFeedbackReminderResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');
  public downloadPDFReportResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');

  // Dialog
  private dialogRefAnswersheetReportComponent: MatDialogRef<DialogAnswersheetReportComponent>;

  private sendReminderEmailResponse$: Observable<any>;

  // Subscriptions
  private subscriptions: Subscription[] = [];

  constructor(
    private helperService: HelperService,
    private router: Router,
    private helperFeedbackService: HelperFeedbackService,
    private userStore: UserStore,
    private dialog: MatDialog
  ) {
    this.sendReminderEmailResponse$ = this.userStore.sendReminderEmailResponse$;
  }

  // Helper function
  public get helper() {
    return this.helperService;
  }

  public get helperFeedback() {
    return this.helperFeedbackService;
  }

  @Input()
  set _answersheet(_answersheet: AnswersheetInterface) {
    if (_answersheet) {
      this.answersheet = _answersheet;
    }
  }

  @Input()
  set _instance(_instance: InterventionInstanceInterface) {
    if (_instance) {
      this.instance = _instance;
    }
  }

  @Input()
  set _questionnaires(_questionnaires: Array<LessonInterface>) {
    if (_questionnaires) {
      this.questionnaires = _questionnaires;
    }
  }

  @Input()
  set _threads(_threads: Array<MessageThreadsInterface>) {
    if (_threads) {
      this.threads = _threads;
    }
  }

  @Input()
  set _studyMembers(_studyMembers: Array<UserInterface>) {
    if (_studyMembers) {
      this.studyMembers = _studyMembers;
    }
  }

  @Input()
  set _isGuidingECoach(_isGuidingECoach: boolean) {
    if (_isGuidingECoach !== undefined) {
      this.isGuidingECoach = _isGuidingECoach;
    }
  }

  ngOnInit(): void {}

  // Navigate to Feedback Detail
  public getAnswersheetDetails(id: number) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([`feedback-overview/answersheets/${id}`]);
  }

  public getThreadByAnswersheet(answersheetId: number): MessageThreadsInterface {
    const feedbackFound: MessageThreadsInterface = this.threads.find(
      (value: MessageThreadsInterface) =>
        (value.attributes.answersheet_id !== null ? value.attributes.answersheet_id : '').toString() === answersheetId.toString()
    );
    return feedbackFound !== undefined ? feedbackFound : null;
  }

  public sendReminderUnreadFeedback(answersheet: AnswersheetInterface): void {
    if (this.sendFeedbackReminderResponse.value === 'DEFAULT') {
      this.sendFeedbackReminderResponse.next('LOADING');
      this.userStore.sendReminderToUnreadFeedback({ userId: answersheet.attributes.user_id, answersheetId: answersheet.id });
      this.subscriptions.push(
        this.sendReminderEmailResponse$.pipe(skip(1), take(1)).subscribe(
          result => {
            if (result instanceof HttpResponse) {
              this.sendFeedbackReminderResponse.next('SUCCESS');
            } else {
              this.sendFeedbackReminderResponse.next('FAILURE');
            }
            setTimeout(() => {
              this.sendFeedbackReminderResponse.next('DEFAULT');
            }, 10000);
          },
          error => {
            this.sendFeedbackReminderResponse.next('FAILURE');
            setTimeout(() => {
              this.sendFeedbackReminderResponse.next('DEFAULT');
            }, 10000);
          }
        )
      );
    }
  }

  openDialogAnswersheetReport(answersheet: AnswersheetInterface) {
    this.dialogRefAnswersheetReportComponent = this.dialog.open(DialogAnswersheetReportComponent, {
      data: {
        answersheet,
        param: { lesson_name: this.helper.findArrObjById(answersheet.attributes.questionnaire_id, this.questionnaires).attributes.name }
      },
      minWidth: '50vw'
    });

    this.dialogRefAnswersheetReportComponent.afterClosed().subscribe(() => {});
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
    this.dialog.closeAll();
  }
}
