import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';

/*
 * Alert service to parse message to a defined format
 */

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  public hasAlert$: Observable<{ text: string; type: string }>;
  public showLoading$: Observable<boolean>;
  public getParam$: Observable<any>;

  public param = { alertparam: '' };

  private alertSubject: BehaviorSubject<{ text: string; type: string }> = new BehaviorSubject<{ text: string; type: string }>(null);
  private showLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private keepAfterNavigationChange = false;

  private getParamSubject: BehaviorSubject<any> = new BehaviorSubject<any>(this.param);

  constructor(private router: Router) {
    this.hasAlert$ = this.alertSubject.asObservable();
    this.showLoading$ = this.showLoadingSubject.asObservable();
    this.getParam$ = this.getParamSubject.asObservable();
    // Clear alert message on route change
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterNavigationChange) {
          // Keep for a single location change
          this.keepAfterNavigationChange = false;
        } else {
          // Clear alert message
          this.alertSubject.next(null);
        }
      }
    });
  }

  public reset(keepAfterNavigationChange = false, showLoading: boolean = false): void {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.showLoadingSubject.next(showLoading);
    this.alertSubject.next(null);
  }

  public success(message: string, keepAfterNavigationChange = false, showLoading: boolean = false): void {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.showLoadingSubject.next(showLoading);
    this.alertSubject.next({ type: 'success', text: message });
  }

  public warning(message: string, keepAfterNavigationChange = false, showLoading: boolean = false): void {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.showLoadingSubject.next(showLoading);
    this.alertSubject.next({ type: 'warning', text: message });
  }

  public error(message: string, keepAfterNavigationChange = false, showLoading: boolean = false): void {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.showLoadingSubject.next(showLoading);
    this.alertSubject.next({ type: 'error', text: message });
  }

  public info(message: string, keepAfterNavigationChange = false, showLoading: boolean = false): void {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.showLoadingSubject.next(showLoading);
    this.alertSubject.next({ type: 'info', text: message });
  }

  public setParam(value: string): void {
    this.param = { alertparam: value };
    this.getParamSubject.next(this.param);
  }
}
