import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-text-progress-bar',
  templateUrl: './text-progress-bar.component.html',
  styleUrls: ['./text-progress-bar.component.scss']
})
export class TextProgressBarComponent implements OnInit, AfterViewInit {
  @Input() pxProgressBarHeight: number;
  @Input() pxFontSize: number;
  @Input() value: number;
  @Input() text: string;
  @Input() progressBackground: string = null;
  @Input() background: string = null;
  @ViewChild('progressBar', { read: ElementRef, static: false }) progressBar: ElementRef;

  constructor() {}

  ngOnInit() {
    // if no font size specified, use progress bar height
    if (this.text && !this.pxFontSize) {
      this.pxFontSize = this.pxProgressBarHeight;
    }
  }

  ngAfterViewInit() {
    if (this.progressBackground) {
      this.progressBar.nativeElement.style.setProperty('--progress-background', this.progressBackground);
    }

    if (this.background) {
      this.progressBar.nativeElement.style.setProperty('--background', this.background);
    }
  }
}
