<div class="container-fluid text-center">
  <div class="col-sm-8 text-start">
    <h2>{{ 'patient-instance.my_instances' | translate }}</h2>
    <hr />
  </div>

  <div class="row justify-content-center">
    <div class="col-12 col-md-6 mb-2">
      <mat-form-field [style.width.%]="100" appearance="outline">
        <mat-label>{{'patient-instance.search_instance' | translate}}</mat-label>
        <input
          [attr.id]="'search'"
          (keyup.enter)="applyInstanceFilter()"
          [(ngModel)]="this.filter['searchFilter']"
          [disabled]="(isLoading$ | async)"
          aria-describedby="instanceSearch"
          aria-label="Search"
          matInput
          type="text"
        />
        <button [attr.id]="'btnSearchInstance'" (keyup.enter)="applyInstanceFilter()" aria-label="Search" mat-button mat-button matSuffix>
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>

  <section class="checkbox-center">
    <mat-checkbox
      [attr.id]="'checkboxExcludeCanceledInstance'"
      [(ngModel)]="filter['excludeCanceledInstance']"
      (change)="applyInstanceFilter()"
      >{{'intervention-instance.exclude_canceled_instances' | translate}}</mat-checkbox
    >
  </section>

  <app-pagination-list
    #paginator
    (emitPageChange)="updatePagedInterventionInstances($event)"
    [_isLoading]="isLoading$ | async"
    [_items]="instances$ | async"
  ></app-pagination-list>

  <ng-template #elseShowGroupInstances>
    <div class="row">
      <div *ngFor="let instance of pagedInstances$ | async; trackBy: helper.trackByElement" class="col-12 col-md-6 mb-3">
        <app-card-intervention-instance
          [_interventionInstance]="instance"
          [_users]="users"
          [_studyCollaborators]="getStudyById(instance.attributes.study_id).relationships.collaborators.data"
          (emitUpdatePerformed)="updateList()"
        ></app-card-intervention-instance>
      </div>
    </div>
  </ng-template>

  <div *ngIf="(isLoading$ | async); else elseShowGroupInstances">
    <app-loader></app-loader>
  </div>
</div>
