/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { InstanceCreationFormComponent } from '../../intervention-instance/instance-creation-form/instance-creation-form.component';
import { PayloadInterface } from '../../../models/interface/payload.interface';
import { InterventionInterface } from '../../../models/interface/intervention.interface';
import { HelperService } from '../../../services/helper/helper.service';
import { UserInterface } from '../../../models/interface/user.interface';
import { LessonInterface } from '../../../models/interface/lesson.interface';

/**
 * Component:
 * Patient instance creation component displaying a form to configure all selected interventions;
 */

@Component({
  selector: 'app-patient-creation-instance-creation',
  templateUrl: './patient-creation-instance-creation.component.html',
  styleUrls: ['./patient-creation-instance-creation.component.scss']
})
export class PatientCreationInstanceCreationComponent implements OnInit {
  @ViewChildren(InstanceCreationFormComponent) creationFormComponents!: QueryList<InstanceCreationFormComponent>;

  faArrowLeft = faArrowLeft;
  faArrowRight = faArrowRight;

  public studiesAndIntervention: Array<{
    study_id: number;
    interventions: Array<InterventionInterface>;
    collaborators: Array<UserInterface>;
  }> = [];

  public questionnairesIntervention: Array<{
    intervention_id: number;
    questionnaires: Array<LessonInterface>;
    intervention: InterventionInterface;
    collaborators: Array<UserInterface>;
  }> = [];

  public myInstances: Array<PayloadInterface> = [];
  public myUserId: number;

  public numbersOfPages;
  public pageSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public page$ = this.pageSubject.asObservable();

  public isHiddenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isHidden$: Observable<boolean>;

  public isModalLoading$: Observable<boolean>;

  private isModalLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(private helperService: HelperService) {
    this.isModalLoading$ = this.isModalLoadingSubject.asObservable();
  }

  public get helper() {
    return this.helperService;
  }

  // Set myUserId from parent
  @Input() _myUserId(_myUserId: number) {
    if (_myUserId !== undefined) {
      this.myUserId = _myUserId;
    }
  }

  // Set study and its configuration from parent
  @Input() hide(_hide: boolean) {
    if (_hide !== undefined) {
      this.isHiddenSubject.next(_hide);
    }
  }

  @Input() selectedIntervention(
    _selectedIntervention: Array<{
      study_id: number;
      interventions: Array<InterventionInterface>;
      collaborators: Array<UserInterface>;
    }>
  ) {
    if (_selectedIntervention !== undefined) {
      this.studiesAndIntervention = _selectedIntervention;
    }
  }

  @Input() selectedQuestionnaires(_selectedQuestionnaires: Array<{ intervention_id: number; questionnaires: Array<LessonInterface> }>) {
    this.questionnairesIntervention = [];
    if (_selectedQuestionnaires !== undefined && this.studiesAndIntervention !== undefined && this.myUserId !== undefined) {
      _selectedQuestionnaires.forEach(content => {
        const foundIntervention = (interventionId: number, studiesIntervention) => {
          let result = null;
          studiesIntervention.forEach(
            (c: { study_id: number; interventions: Array<InterventionInterface>; collaborators: Array<UserInterface> }) => {
              const found = c.interventions.find(
                (intervention: InterventionInterface) => intervention.id.toString() === interventionId.toString()
              );
              if (found !== undefined) {
                result = found;
              }
            }
          );
          return result;
        };

        const foundCollaborators = (interventionId: number, studiesIntervention) => {
          let result = [];
          studiesIntervention.forEach(
            (c: { study_id: number; interventions: Array<InterventionInterface>; collaborators: Array<UserInterface> }) => {
              const found = c.interventions.find(
                (intervention: InterventionInterface) => intervention.id.toString() === interventionId.toString()
              );
              if (found !== undefined) {
                result = c.collaborators;
              }
            }
          );
          return result;
        };

        this.questionnairesIntervention.push({
          intervention_id: content.intervention_id,
          questionnaires: content.questionnaires,
          intervention: foundIntervention(content.intervention_id, this.studiesAndIntervention),
          collaborators: foundCollaborators(content.intervention_id, this.studiesAndIntervention)
        });

        this.numbersOfPages = this.questionnairesIntervention.length;
        this.pageSubject.next(0);

        this.isModalLoadingSubject.next(false);
      });
    }
  }

  ngOnInit(): void {
    // Get hidden
    this.isHidden$ = this.isHiddenSubject.asObservable();
  }

  public selectedInterventionByInterventionId(interventionId: number): InterventionInterface {
    let result = null;
    this.studiesAndIntervention.forEach(
      (content: { study_id: number; interventions: Array<InterventionInterface>; collaborators: Array<UserInterface> }) => {
        const found = content.interventions.find(
          (intervention: InterventionInterface) => intervention.id.toString() === interventionId.toString()
        );
        if (found !== undefined) {
          result = found;
        }
      }
    );
    return result;
  }

  // Event on creation confirmation
  public onConfirmCreation(payload: PayloadInterface): void {
    this.myInstances.push(payload);
  }

  // Display previous page
  public showPrevious(): void {
    const pageNumber = this.pageSubject.getValue();
    if (pageNumber > 0) {
      this.pageSubject.next(pageNumber - 1);
    }
  }

  // Display next page
  public showNext(): void {
    const pageNumber = this.pageSubject.getValue();
    if (pageNumber < this.numbersOfPages - 1) {
      this.pageSubject.next(pageNumber + 1);
    }
  }
}
