<button [attr.id]="'btnDialogClose'" mat-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title>{{'configuration-diary.configure_diary_title' | translate:param}}</h1>

<div class="content-height" mat-dialog-content>
  <ng-container [formGroup]="instanceFormType">
    <p class="text-center"><strong>{{'configuration-diary.numbers_of_reminders' | translate}}</strong></p>

    <div class="row">
      <mat-form-field class="col-12 col-md-5" appearance="outline">
        <mat-label>{{'configuration-diary.number_reminder' | translate}}</mat-label>
        <mat-select [attr.id]="'selectionPeriod'" formControlName="period" name="period" (selectionChange)="recalculateSelection($event)">
          <mat-option [attr.id]="'option_' + num" *ngFor="let num of numbersOfReminder" [value]="num"> {{num}} </mat-option>
        </mat-select>
      </mat-form-field>

      <span class="col-12 col-md-2">{{'configuration-diary.times_per' | translate}}</span>

      <mat-form-field class="col-12 col-md-5" appearance="outline">
        <mat-label>{{'configuration-diary.period' | translate}}</mat-label>
        <mat-select [attr.id]="'selectionPeriod'">
          <mat-option [attr.id]="'option_' + period" *ngFor="let period of periods" [value]="period">
            {{('configuration-diary.' + period) | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div formArrayName="every_schedule" *ngFor="let arrayItem of arraySchedule; let i = index; let isFirst = first">
      <mat-divider></mat-divider>

      <div [formGroupName]="i" class="mt-2">
        <div [attr.id]="'arrayItem_' + i" class="row justify-content-center">
          <p><strong>{{i+1}}.</strong></p>
          <mat-form-field class="col-12 col-md-6" appearance="outline">
            <mat-label>{{'configuration-diary.weekday' | translate}}</mat-label>
            <mat-select [attr.id]="'weekday_' + i" formControlName="weekday" name="weekday">
              <mat-option [attr.id]="'option_' + i + '_' + num" *ngFor="let num of weekNum" [value]="num">
                {{('configuration-diary.' + weekdays[num]) | translate}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <ngx-mat-timepicker
            class="col-12 col-md-5"
            [attr.id]="'unlock_date_' + i"
            formControlName="unlock_date"
            [showSpinners]="showSpinners"
            [stepHour]="stepHour"
            [stepMinute]="stepMinute"
            [stepSecond]="stepSecond"
            [showSeconds]="showSeconds"
          >
          </ngx-mat-timepicker>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<div mat-dialog-actions align="end">
  <button [attr.id]="'btnCancel'" mat-button mat-dialog-close>{{'instance-report.cancel' | translate}}</button>
</div>
