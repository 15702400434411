import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, iif, Observable, of, Subscription, throwError } from 'rxjs';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { map, mergeMap, skip, take } from 'rxjs/operators';
import { HelperService } from '../../../services/helper/helper.service';
import { UserInterface } from '../../../models/interface/user.interface';
import { MessageStore } from '../../../store/message/component-store/message.store';
import { HttpResponse } from '@angular/common/http';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { StudyInterface } from '../../../models/interface/study/study.interface';
import { StudyActionTypes } from '../../../store/study/study.action';
import { getCollaboratorsByStudyId } from '../../../store/study/study.selector';
import { ProfileInterface } from '../../../models/interface/profile.interface';

@Component({
  selector: 'app-dialog-conversation-create',
  templateUrl: './dialog-conversation-create.component.html',
  styleUrls: ['./dialog-conversation-create.component.scss'],
  providers: [MessageStore]
})
export class DialogConversationCreateComponent implements OnInit, OnDestroy {
  // Filter
  public filter = {
    studySelection: ''
  };
  public isLoading$: Observable<boolean>;
  public modalSelectedParticipants$: Observable<Array<any>>;

  // Data provided by UserService
  public members: Array<UserInterface> = [];
  public collaborators: Array<UserInterface> = [];
  public myUser: UserInterface;

  // Thread creation
  public submitted = false;
  public createThreadResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');

  public membersControl = new FormControl([]);
  public collaboratorsControl = new FormControl([]);

  public selectedStudy: StudyInterface;

  public groups: Array<StudyInterface> = [];

  private profile: ProfileInterface;

  private profile$: Observable<ProfileInterface>;

  private collaboratorsOfStudy$: Observable<{ studyId: number; collaborators: UserInterface[] }>;

  private newThreadId: number;

  private startThreadResponse$: Observable<any>;
  private addParticipantResponse$: Observable<any>;

  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private modalSelectedParticipantsSubject: BehaviorSubject<Array<any>> = new BehaviorSubject([]);

  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private helperService: HelperService,
    private dialogRef: MatDialogRef<DialogConversationCreateComponent>,
    private messageStore: MessageStore,
    private actions$: Actions,
    private store: Store<{
      myProfile: ProfileInterface;
      getCollaboratorsByStudyId: {
        studyId: number;
        collaborators: UserInterface[];
      };
    }>
  ) {
    this.profile$ = this.store.select('myProfile');
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.modalSelectedParticipants$ = this.modalSelectedParticipantsSubject.asObservable();
    this.startThreadResponse$ = this.messageStore.startThreadResponse$;
    this.addParticipantResponse$ = this.messageStore.addParticipantResponse$;
  }

  public get helper() {
    return this.helperService;
  }

  ngOnInit(): void {
    this.modalSelectedParticipantsSubject.next([]);
    this.myUser = this.data.myUser;
    this.members = this.data.members;

    this.profile$.subscribe((user: ProfileInterface) => {
      this.profile = user;
    });
    this.store.dispatch({
      type: StudyActionTypes.getCollaboratingStudiesType,
      payload: { include: 'owners,roles' }
    });

    this.actions$
      .pipe(
        ofType(StudyActionTypes.getCollaboratingStudiesSuccessType, StudyActionTypes.getCollaboratingStudiesErrorType),
        take(1),
        mergeMap((result: any) =>
          iif(() => result.type === StudyActionTypes.getCollaboratingStudiesSuccessType, of(result), throwError(result))
        ),
        mergeMap(result => {
          const groups = result['response']['body']['data'].filter((group: StudyInterface) => group.attributes.type === 'study');
          this.groups = groups;
          if (groups.length > 0) {
            this.selectedStudy = groups[0];
            this.store.dispatch({
              type: StudyActionTypes.getCollaboratorsType,
              payload: { studyId: this.selectedStudy.id, include: 'roles' }
            });
            this.collaboratorsOfStudy$ = this.store.select(getCollaboratorsByStudyId(this.selectedStudy.id));
            return this.collaboratorsOfStudy$.pipe(map(study => (study?.collaborators ? study.collaborators : [])));
          } else {
            return of([]);
          }
        })
      )
      .subscribe(result => {
        this.collaborators = this.helper
          .includeUsersWithOnlyECoachManagerOwner(result)
          .filter((user: UserInterface) => user.id.toString() !== this.profile?.id.toString());
      });
  }

  // Create thread, add participants, display new thread and close modal
  public createAndDisplayThread(): void {
    if (this.membersControl.value?.length === 0 && this.collaboratorsControl?.value?.length === 0) {
      return;
    }

    const selectedParticipants = [...this.membersControl.value, ...this.collaboratorsControl.value].map(user => user.id);
    if (selectedParticipants.length === 0) {
      return;
    }

    let threadSubject = '';
    selectedParticipants.forEach((userId: number) => {
      threadSubject =
        threadSubject === ''
          ? threadSubject.concat(this.getUserWithId(userId).attributes.name)
          : threadSubject.concat(', ' + this.getUserWithId(userId).attributes.name);
    });

    if (!this.submitted && selectedParticipants.length > 0) {
      this.submitted = true;
      this.createThreadResponse.next('LOADING');

      // Start thread and add participants
      this.messageStore.startThread({ subject: threadSubject });
      this.subscriptions.push(
        this.startThreadResponse$
          .pipe(
            skip(1),
            take(1),
            mergeMap((result: any) => {
              if (result instanceof HttpResponse) {
                const location: string = result.headers.get('Location');
                this.newThreadId = parseInt(location.substring(location.lastIndexOf('/') + 1), 10);
                this.messageStore.addParticipant({ threadId: this.newThreadId, participantsParam: selectedParticipants });
                return this.addParticipantResponse$.pipe(skip(1), take(1));
              } else {
                return throwError(result);
              }
            })
          )
          .subscribe(
            result => {
              if (result instanceof HttpResponse) {
                this.createThreadResponse.next('SUCCESS');
                setTimeout(() => {
                  this.createThreadResponse.next('DEFAULT');
                  this.submitted = false;
                  this.dialogRef.close(this.newThreadId);
                  this.removeSelectedParticipants();
                }, 2500);
              } else {
                this.createThreadResponse.next('FAILURE');
                setTimeout(() => {
                  this.createThreadResponse.next('DEFAULT');
                  this.submitted = false;
                }, 2500);
              }
            },
            error => {
              this.createThreadResponse.next('FAILURE');
              setTimeout(() => {
                this.createThreadResponse.next('DEFAULT');
                this.submitted = false;
              }, 2500);
            }
          )
      );
    }
  }

  // Display selected members in modal body
  public displaySelectedParticipants(): void {
    this.modalSelectedParticipantsSubject.next([...this.membersControl.value, ...this.collaboratorsControl.value]);
  }

  // Reset selected members in modal
  public removeSelectedParticipants(): void {
    this.modalSelectedParticipantsSubject.next([]);
  }

  // Returns user with a given user ID
  public getUserWithId(id: number): UserInterface {
    const foundMember = this.members.find(x => x.id.toString() === id.toString());
    return foundMember ? foundMember : this.collaborators.find(x => x.id.toString() === id.toString());
  }

  public changeSelection() {
    this.removeSelectedParticipants();
    this.store.dispatch({
      type: StudyActionTypes.getCollaboratorsType,
      payload: { studyId: this.selectedStudy.id, include: 'roles' }
    });
    this.collaboratorsOfStudy$ = this.store.select(getCollaboratorsByStudyId(this.selectedStudy.id));
    this.collaboratorsOfStudy$.pipe(map(study => (study?.collaborators ? study.collaborators : []))).subscribe(result => {
      this.collaborators = this.helper
        .includeUsersWithOnlyECoachManagerOwner(result)
        .filter((user: UserInterface) => user.id.toString() !== this.profile?.id.toString());
    });
    this.membersControl.reset();
    this.collaboratorsControl.reset();
  }

  public resetFilter(): void {
    this.filter = {
      studySelection: ''
    };
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
