<button [attr.id]="'btnDialogClose'" mat-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title>{{'group-intervention-review.publish-test-title' | translate}}</h1>
<div mat-dialog-content class="mat-typography">
  <p>{{'group-intervention-review.publish-test-modal_body' | translate:param}}</p>

  <section>
    <ul>
      <li>
        <mat-checkbox [attr.id]="'checkboxFirstTerm'" (ngModelChange)="updateCondition()" [(ngModel)]="firstTerm">
          {{'group-intervention-review.publish-test-terms_1' | translate}}
        </mat-checkbox>
      </li>
      <li>
        <mat-checkbox [attr.id]="'checkboxSecondTerm'" (ngModelChange)="updateCondition()" [(ngModel)]="secondTerm">
          {{'group-intervention-review.publish-test-terms_2' | translate}}
        </mat-checkbox>
      </li>
    </ul>
  </section>

  <section *ngIf="showRejectionDialogSubject | async">
    <p class="text-danger">{{'group-intervention-review.confirm_rejection' | translate}}</p>
    <mat-form-field class="full-width" appearance="outline">
      <mat-label>{{'group-intervention-review.optional_deactivation_text' | translate}}</mat-label>
      <textarea
        [attr.id]="'deactivationText'"
        matInput
        type="text"
        [(ngModel)]="deactivationText"
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="3"
      ></textarea>
      <button
        [attr.id]="'btnClearDeactivationText'"
        *ngIf="deactivationText"
        matSuffix
        mat-button
        aria-label="Clear"
        (click)="deactivationText=''"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </section>
  <p>{{'group-intervention-review.publish-test-note' | translate}}</p>
</div>
<div mat-dialog-actions align="end">
  <ng-template *ngIf="showRejectionDialogSubject | async; then thenRejectionConfirmation else elseDefaultConfirmation"></ng-template>
  <ng-template #thenRejectionConfirmation>
    <button [attr.id]="'btnCancel'" mat-button mat-dialog-close>{{'group-intervention-review.cancel' | translate}}</button>
    <button [attr.id]="'btnToggleRejectionDialog'" mat-button (click)="helper.toggleSubject(showRejectionDialogSubject)">
      {{'group-intervention-review.no' | translate}}
    </button>
    <app-button-feedback
      [_setId]="'btnRejectPendingIntervention'"
      [_isDisabled]="(acceptPendingInterventionResponse | async) !== 'DEFAULT'"
      (click)="rejectPendingIntervention()"
      [_defaultButtonText]="'group-intervention-review.yes'"
      [_responseText]="rejectPendingInterventionResponse | async"
    ></app-button-feedback>
  </ng-template>
  <ng-template #elseDefaultConfirmation>
    <button [attr.id]="'btnCancel'" mat-button mat-dialog-close>{{'group-intervention-review.cancel' | translate}}</button>
    <button [attr.id]="'btnToggleRejectionDialog'" mat-button (click)="helper.toggleSubject(showRejectionDialogSubject)">
      {{'group-intervention-review.reject' | translate}}
    </button>
    <app-button-feedback
      [_setId]="'btnAcceptPendingIntervention'"
      [_isDisabled]="!updateCondition() || (rejectPendingInterventionResponse | async) !== 'DEFAULT'"
      (click)="acceptPendingIntervention()"
      [_defaultButtonText]="'group-intervention-review.accept'"
      [_responseText]="acceptPendingInterventionResponse | async"
    ></app-button-feedback>
  </ng-template>
</div>
