import { Component, Input, OnInit } from '@angular/core';
import { faHands } from '@fortawesome/free-solid-svg-icons/faHands';
import { faHandsHelping } from '@fortawesome/free-solid-svg-icons/faHandsHelping';
import { InterventionInstanceInterface } from '../../../models/interface/intervention-instances/intervention-instance.interface';
import { InterventionInterface } from '../../../models/interface/intervention.interface';
import { HelperService } from '../../../services/helper/helper.service';
import { UserInterface } from '../../../models/interface/user.interface';

/**
 * Component:
 * Instance deletion form displaying instance details to cancel interventions;
 */

@Component({
  selector: 'app-instance-deletion',
  templateUrl: './instance-deletion.component.html',
  styleUrls: ['./instance-deletion.component.scss']
})
export class InstanceDeletionComponent implements OnInit {
  // Icons
  faHandsHelping = faHandsHelping;
  faHands = faHands;

  public intervention: InterventionInterface;
  public studyMembers: Array<UserInterface> = [];
  public studyCollaborator: Array<UserInterface> = [];
  public instance: InterventionInstanceInterface;

  constructor(private helperService: HelperService) {}

  public get helper() {
    return this.helperService;
  }

  @Input()
  set _intervention(_intervention: InterventionInterface) {
    this.intervention = _intervention;
  }

  @Input()
  set _members(_members: Array<UserInterface>) {
    this.studyMembers = _members;
  }

  @Input()
  set _collaborators(_collaborators: Array<UserInterface>) {
    this.studyCollaborator = _collaborators;
  }

  @Input()
  set _instance(_instance: InterventionInstanceInterface) {
    this.instance = _instance;
  }

  ngOnInit(): void {}
}
