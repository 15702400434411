import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnswersheetDetailComponent } from './answersheet-detail/answersheet-detail.component';
import { AnswersheetsComponent } from './answersheets/answersheets.component';
import { FeedbackOverviewComponent } from './feedback-overview/feedback-overview.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material/material.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { UtilsModule } from '../utils/utils.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';
import { QuestionnaireModule } from '../questionnaire/questionnaire.module';
import { DiaryAnswersheetsComponent } from './diary-answersheets/diary-answersheets.component';
import { DiaryAnswersheetsDetailComponent } from './diary-answersheets-detail/diary-answersheets-detail.component';
import { InterventionInstanceModule } from '../intervention-instance/intervention-instance.module';
import { DirectiveModule } from '../directive/directive.module';
import { AnswersheetFeedbackComponent } from './answersheet-feedback/answersheet-feedback.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export const createTranslateLoader = (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json');

@NgModule({
  declarations: [
    AnswersheetsComponent,
    AnswersheetDetailComponent,
    AnswersheetFeedbackComponent,
    FeedbackOverviewComponent,
    DiaryAnswersheetsComponent,
    DiaryAnswersheetsDetailComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    RouterModule,
    UtilsModule,
    QuestionnaireModule,
    InterventionInstanceModule,
    DirectiveModule,
    MaterialModule,
    FontAwesomeModule
  ],
  exports: [
    AnswersheetsComponent,
    AnswersheetDetailComponent,
    AnswersheetFeedbackComponent,
    FeedbackOverviewComponent,
    DiaryAnswersheetsComponent,
    DiaryAnswersheetsDetailComponent
  ]
})
export class AnswersheetModule {}
