/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged, mergeMap, skip, take } from 'rxjs/operators';
import { OrganisationSharedService } from '../../../services/shared/organisation-shared/organisation-shared.service';
import { HelperService } from '../../../services/helper/helper.service';
import { UserInterface } from '../../../models/interface/user.interface';
import { StudyInterface } from '../../../models/interface/study/study.interface';
import { Store } from '@ngrx/store';
import { RoleInterface } from '../../../models/interface/role.interface';
import { HelperDialogService } from '../../../services/helper/helper-dialog/helper-dialog.service';
import { OrganisationStore } from '../../../store/organisation/component-store/organisation.store';
import { StudyActionTypes } from '../../../store/study/study.action';
import { OrganisationInterface } from 'src/app/models/interface/organisation/organisation.interface';

@Component({
  selector: 'app-organisation-study',
  templateUrl: './organisation-study.component.html',
  styleUrls: ['./organisation-study.component.scss'],
  providers: [OrganisationStore]
})
export class OrganisationStudyComponent implements OnInit, OnDestroy {
  @ViewChild('paginator') paginator;

  public organisationId: number;
  public isLoading$: Observable<boolean>;

  // Filter
  public filter = { searchFilter: '' };

  public studies$: Observable<Array<StudyInterface>>;

  public searchTextSubject = new BehaviorSubject<string>('');

  public pagedStudies$: Observable<Array<StudyInterface>>;

  public isManager$: Observable<boolean>;

  public isOrganisationCollaborator$: Observable<boolean>;

  public profileRoles$: Observable<Array<RoleInterface>>;

  public organisations: Array<OrganisationInterface>;

  private initialStudies: Array<StudyInterface>;

  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private studiesSubject: BehaviorSubject<Array<StudyInterface>> = new BehaviorSubject([]);
  private pagedStudiesSubject: BehaviorSubject<Array<StudyInterface>> = new BehaviorSubject<Array<StudyInterface>>([]);

  private organisationStudies$: Observable<Array<StudyInterface>>;

  private isManagerSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isOrganisationCollaboratorSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private subscriptions: Subscription[] = [];

  constructor(
    private sharedService: OrganisationSharedService,
    private helperService: HelperService,
    private helperDialogService: HelperDialogService,
    private translateService: TranslateService,
    private organisationStore: OrganisationStore,
    private store: Store<{
      myRoles: Array<RoleInterface>;
      getCollaboratorsByStudyId: { studyId: number; collaborators: UserInterface[] };
      users: Array<UserInterface>;
    }>
  ) {
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.studies$ = this.studiesSubject.asObservable();
    this.pagedStudies$ = this.pagedStudiesSubject.asObservable();
    this.isManager$ = this.isManagerSubject.asObservable();
    this.isOrganisationCollaborator$ = this.isOrganisationCollaboratorSubject.asObservable();
    this.profileRoles$ = store.select('myRoles');
    this.organisationStudies$ = this.organisationStore.organisationStudies$;
  }

  public get helper() {
    return this.helperService;
  }

  public get helperDialog() {
    return this.helperDialogService;
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.sharedService.isCollaborator$.subscribe((value: boolean) => {
        this.isOrganisationCollaboratorSubject.next(value);
      })
    );

    this.subscriptions.push(
      this.sharedService.sourceOrganisation$.subscribe((value: OrganisationInterface) => {
        // Due to async service check whether value is null or valid id
        if (value === null || value === undefined) {
          this.isLoadingSubject.next(true);
        } else {
          this.organisations = [value];
          this.organisationId = value.id;
          this.applyStudyFilter();
        }
      })
    );

    this.subscriptions.push(
      this.sharedService.isCollaborator$.subscribe((value: boolean) => {
        this.isManagerSubject.next(value);
      })
    );

    this.subscriptions.push(
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
        this.applyStudyFilter();
      })
    );
  }

  public applyStudyFilter(): void {
    this.isLoadingSubject.next(true);
    this.subscriptions.push(
      this.reloadStudyList(true).subscribe((result: any) => {
        this.isLoadingSubject.next(false);
      })
    );
  }

  public reloadStudyList(setFirst?: boolean): Observable<boolean> {
    const pageIndex = this.paginator?.paginator ? this.paginator?.paginator.pageIndex : 0;
    const pageSize = this.paginator?.paginator ? this.paginator?.paginator.pageSize : 20;
    this.organisationStore.getOrganisationStudies({
      organisationId: this.organisationId,
      include: 'collaborators'
    });

    this.store.dispatch({
      type: StudyActionTypes.getCollaboratingStudiesType,
      payload: { include: 'owners,roles' }
    });

    return this.organisationStudies$.pipe(
      skip(1),
      take(1),
      mergeMap((results: any) => {
        this.initialStudies = results;
        return this.search(this.filter['searchFilter']).pipe(
          mergeMap(() => this.helper.setPagedContent(this.studiesSubject, this.pagedStudiesSubject, setFirst, pageIndex, pageSize))
        );
      })
    );
  }

  public updateList(): void {
    this.subscriptions.push(this.reloadStudyList().subscribe());
  }

  public updatePagedStudies(event: any) {
    if (event) {
      this.pagedStudiesSubject.next(event);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  // Reads search value and runs searchFilter
  private search(name: string): Observable<boolean> {
    this.searchTextSubject.next(name);
    return this.searchTextSubject.pipe(
      distinctUntilChanged(),
      mergeMap(() => {
        this.studiesSubject.next(this.helper.filterStudiesBy(this.initialStudies, this.filter['searchFilter'].toLowerCase().trim()));
        const currentPageSize = this.paginator?.paginator ? this.paginator?.paginator.pageSize : 20;
        this.pagedStudiesSubject.next(this.studiesSubject.value.slice(0, currentPageSize));
        return of(true);
      })
    );
  }
}
