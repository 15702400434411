import { HttpEvent, HttpEventType, HttpProgressEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { distinctUntilChanged, scan } from 'rxjs/operators';

const isHttpResponse = <T>(event: HttpEvent<T>): event is HttpResponse<T> => event.type === HttpEventType.Response;

const isHttpProgressEvent = (event: HttpEvent<unknown>): event is HttpProgressEvent =>
  event.type === HttpEventType.DownloadProgress || event.type === HttpEventType.UploadProgress;

export interface Download {
  content: JSON | null;
  progress: number;
  state: 'PENDING' | 'IN_PROGRESS' | 'DONE';
}

export const download =
  (saver?: (b: JSON) => void): ((source: Observable<HttpEvent<JSON>>) => Observable<Download>) =>
  (source: Observable<HttpEvent<JSON>>) =>
    source.pipe(
      scan(
        (dl: Download, event): Download => {
          if (isHttpProgressEvent(event)) {
            return {
              progress: event.total ? Math.round((100 * event.loaded) / event.total) : dl.progress,
              state: 'IN_PROGRESS',
              content: null
            };
          }
          if (isHttpResponse(event)) {
            if (saver) {
              saver(event.body);
            }
            return {
              progress: 100,
              state: 'DONE',
              content: event.body
            };
          }
          return dl;
        },
        { state: 'PENDING', progress: 0, content: null }
      ),
      distinctUntilChanged((a, b) => a.state === b.state && a.progress === b.progress && a.content === b.content)
    );
