<div class="container-fluid text-center">
  <div class="col-sm-8 text-start">
    <h2>{{ 'group-collaborator.collaborators_from' | translate:param }}</h2>
  </div>

  <!-- Management functions -->
  <div *ngIf="(isManager$ | async) || (isOrganisationManager$ | async)">
    <button [attr.id]="'btnToggleCollapse'" (click)="helper.toggleSubject(isCollapseSubject)" mat-button>
      <span *ngIf="(isCollapse$ | async)"
        ><fa-icon [icon]="faChevronDown"></fa-icon> {{'group-collaborator.collaborators_management' | translate}}</span
      >
      <span *ngIf="(isCollapse$ | async) === false"
        ><fa-icon [icon]="faChevronUp"></fa-icon> {{'group-collaborator.collaborators_management' | translate}}</span
      >
    </button>
    <div *ngIf="(isCollapse$ | async)">
      <hr />
      <div *ngIf="(isOrganisationManager$ | async) then thenIsOrganisationManager else elseIsNoOrganisationManager"></div>

      <ng-template #thenIsOrganisationManager>
        <div class="col-12">
          <p>{{'group-collaborator.collaborators_options_description' | translate}}</p>
          <button
            [attr.id]="'btnOpenDialogCollaboratorOrganisationManager'"
            class="largeButton"
            (click)="openDialogGroupCollaboratorOrganisationManager()"
            mat-button
            color="primary"
          >
            {{'group-collaborator.collaborators_options' | translate}}
          </button>
        </div>
      </ng-template>
      <ng-template #elseIsNoOrganisationManager>
        <div class="row">
          <div class="col-12 col-sm-6">
            <p>{{'group-collaborator.collaborators_add_description' | translate}}</p>
            <button
              [attr.id]="'btnOpenDialogCollaboratorAdd'"
              class="largeButton"
              (click)="openDialogGroupCollaboratorAdd()"
              mat-button
              color="primary"
            >
              {{'group-collaborator.collaborators_add' | translate}}
            </button>
          </div>
          <div class="col-12 col-sm-6">
            <p>{{'group-collaborator.collaborators_options_description' | translate}}</p>
            <button
              [attr.id]="'btnOpenDialogCollaboratorUpdate'"
              class="largeButton"
              (click)="openDialogGroupCollaboratorEdit()"
              mat-button
              color="primary"
            >
              {{'group-collaborator.collaborators_options' | translate}}
            </button>
          </div>
        </div>
      </ng-template>
    </div>
  </div>

  <hr />

  <div class="row justify-content-center">
    <div class="col-12 col-md-6 mb-2">
      <!-- Search bar -->
      <mat-form-field [style.width.%]="100" appearance="outline">
        <mat-label>{{'group-collaborator.search_user' | translate}}</mat-label>
        <input
          [attr.id]="'search'"
          (keyup.enter)="applyCollaboratorFilter()"
          [(ngModel)]="filter['searchFilter']"
          [disabled]="(isLoading$ | async)"
          aria-describedby="collaboratorSearch"
          aria-label="Search"
          matInput
          type="text"
        />
        <button
          [attr.id]="'btnSearchCollaborator'"
          (keyup.enter)="applyCollaboratorFilter()"
          aria-label="Search"
          mat-button
          mat-button
          matSuffix
        >
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>

  <app-pagination-list
    #paginator
    (emitPageChange)="updatePagedCollaborators($event)"
    [_isLoading]="isLoading$ | async"
    [_items]="collaborators$ | async"
  ></app-pagination-list>

  <div *ngIf="(isLoading$ | async) === false" class="row">
    <div *ngFor="let collaborator of pagedCollaborators$ | async; trackBy: helper.trackByElement" class="col-12 col-md-6 mb-3">
      <mat-card appearance="outlined" [attr.id]="'collaborator_' + collaborator.id">
        <mat-card-header class="card-header-center">
          <mat-card-title>
            <fa-icon [icon]="faEnvelope"></fa-icon>
            {{((collaborator.attributes.email) ? collaborator.attributes.email : ('intervention-collaborator.no_email' | translate))}}
          </mat-card-title>
          <mat-card-subtitle>
            <span *ngFor="let role of collaborator.relationships.roles.data" class="spacing-right">
              <fa-icon *ngIf="role.attributes.slug === 'study.owner'" [icon]="faStar"></fa-icon>
              {{helper.parseRoleSlug(role.attributes.slug) | translate}}
            </span>
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <p class="mb-1 text-center">
            {{((collaborator.attributes.firstname && collaborator.attributes.lastname) ? collaborator.attributes.firstname + ' ' +
            collaborator.attributes.lastname : ('intervention-collaborator.no_names' | translate))}}
          </p>
          <p class="mb-1 text-center">
            {{((collaborator.attributes.name) ? collaborator.attributes.name : ('intervention-collaborator.no_username' | translate))}}
          </p>
        </mat-card-content>
        <mat-card-actions [align]="'end'">
          <button
            [attr.id]="'btnOpenDialogCollaboratorRemove_' + collaborator.id"
            (click)="openDialogGroupCollaboratorRemove(collaborator)"
            *ngIf="(isManager$ | async)  && (profile.id !== collaborator.id) && (helper.getHighestRoleOfCollaborator(collaborator) !== 'study.owner')"
            mat-button
          >
            <fa-icon [icon]="faTrash"></fa-icon> {{'intervention-collaborator.collaborators_remove' | translate}}
          </button>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
  <div *ngIf="isLoading$ | async">
    <app-loader></app-loader>
  </div>
</div>
