import { createReducer, on } from '@ngrx/store';
import {
  addProfileStore,
  addProfileActivitiesStore,
  addProfileRolesStore,
  removeProfileStore,
  removeProfileActivitiesStore,
  removeProfileRolesStore,
  updateProfileStore,
  updateProfileActivitiesStore,
  updateProfileRolesStore
} from './profile.action';
import { RoleInterface } from '../../models/interface/role.interface';
import { ActivityInterface } from 'src/app/models/interface/activity.interface';
import { ProfileInterface } from 'src/app/models/interface/profile.interface';

export const initialState: ProfileInterface = null;
export const initialRolesState: Array<RoleInterface> = [];
export const initialActivitiesState: Array<ActivityInterface> = [];

export const profileReducer = createReducer(
  initialState,
  on(addProfileStore, (state, { myProfile }) => myProfile),
  on(updateProfileStore, (state, { myProfile }) => myProfile),
  on(removeProfileStore, (state, {}) => null)
);

export const profileRolesReducer = createReducer(
  initialRolesState,
  on(addProfileRolesStore, (state, { myRoles }) => myRoles),
  on(updateProfileRolesStore, (state, { myRoles }) => myRoles),
  on(removeProfileRolesStore, (state, {}) => [])
);

export const profileActivitiesReducer = createReducer(
  initialActivitiesState,
  on(addProfileActivitiesStore, (state, { myActivities }) => myActivities),
  on(updateProfileActivitiesStore, (state, { myActivities }) => myActivities),
  on(removeProfileActivitiesStore, (state, {}) => [])
);
