<div class="container-fluid text-center">
  <div *ngIf="(isLoading$ | async) === false; else elseLoading">
    <div class="jumbotron text-center hoverable p-3">
      <div class="card mb-3">
        <div class="card-body">
          <div class="row justify-content-end">
            <div class="col-12 col-sm-4 text-start">
              <a href="/groups"> {{'organisation-detail.groups' | translate}} </a>
            </div>
            <div class="col-12 col-sm-4">
              <h3>
                {{((organisation.attributes.name) ? organisation.attributes.name : 'organisation-detail.no_organisation_name') | translate}}
              </h3>
            </div>
            <div class="col-12 col-sm-4 text-end">
              {{'organisation-detail.joined' | translate}}
              <fa-icon [icon]="faCheckSquare"></fa-icon>
            </div>
          </div>

          <!-- TODO Add organisation description -->
          <section></section>
        </div>

        <!-- Card Nav tab -->
        <mat-tab-group
          (selectedTabChange)="onChangeTab($event)"
          animationDuration="0ms"
          color="accent"
          #matTabOrganisation
          mat-stretch-tabs
        >
          <mat-tab label="{{'organisation-detail.studies' | translate}}"></mat-tab>
          <mat-tab *ngIf="isManager$ | async" label="{{'organisation-detail.collaborators' | translate}}"></mat-tab>
          <mat-tab *ngIf="isManager$ | async" label="{{'organisation-detail.new_studies' | translate}}"></mat-tab>
        </mat-tab-group>
        <!-- Child components of organisation-detail -->
        <div class="card">
          <div class="card-body">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #elseLoading>
    <app-loader></app-loader>
  </ng-template>
</div>
