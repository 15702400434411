/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { faBook } from '@fortawesome/free-solid-svg-icons/faBook';
import { faHands } from '@fortawesome/free-solid-svg-icons/faHands';
import { faHandsHelping } from '@fortawesome/free-solid-svg-icons/faHandsHelping';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { faComment } from '@fortawesome/free-solid-svg-icons/faComment';
import { faCommentSlash } from '@fortawesome/free-solid-svg-icons/faCommentSlash';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faSquare } from '@fortawesome/free-solid-svg-icons/faSquare';
import { PayloadInterface } from '../../../models/interface/payload.interface';
import { InterventionInterface } from '../../../models/interface/intervention.interface';
import { AlertService } from '../../../services/alert/alert.service';
import { InterventionInstanceConfigurationInterface } from '../../../models/interface/intervention-instances/intervention-instance.configuration.interface';
import { HelperService } from '../../../services/helper/helper.service';
import { UserInterface } from '../../../models/interface/user.interface';
import { LessonInterface } from '../../../models/interface/lesson.interface';
import { DiaryInterface } from '../../../models/interface/diary.interface';
import { SkillInterface } from '../../../models/interface/skill.interface';
import { HelperDialogService } from '../../../services/helper/helper-dialog/helper-dialog.service';
import { HelperSkillService } from '../../../services/helper/helper-skill/helper-skill.service';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-instance-creation-confirmation-dialog',
  templateUrl: './instance-creation-confirmation-dialog.component.html',
  styleUrls: ['./instance-creation-confirmation-dialog.component.scss']
})
export class InstanceCreationConfirmationDialogComponent implements OnInit, OnDestroy {
  // Icon
  faArrowLeft = faArrowLeft;
  faArrowRight = faArrowRight;
  faComment = faComment;
  faCommentSlash = faCommentSlash;
  faHandsHelping = faHandsHelping;
  faHands = faHands;
  faBook = faBook;
  faEye = faEye;
  faSquare = faSquare;

  public studiesAndIntervention: Array<{
    study_id: number;
    interventions: Array<InterventionInterface>;
    collaborators: Array<UserInterface>;
  }> = [];

  public questionnairesIntervention: Array<{
    intervention_id: number;
    questionnaires: Array<LessonInterface>;
    diaries: Array<DiaryInterface>;
    skills: Array<SkillInterface>;
  }> = [];

  public payloads: Array<PayloadInterface> = [];
  public payloadAttributes: Array<{
    ecoach_ids: Array<number>;
    patient_id: number;
    intervention_id: number;
    intervention_type: string;
    configuration: {
      questionnaire_configuration: Array<InterventionInstanceConfigurationInterface>;
      custom_order: Array<number>;
      starting_at: number;
    };
  }> = [];

  public reminderConfigs: Array<{ payload: PayloadInterface; intervention: InterventionInterface }> = [];

  public myUserId: number;

  public emailSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public studyMembers: Array<UserInterface> = [];
  public studyCollaborator: Array<UserInterface> = [];

  public isHiddenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isHidden$: Observable<boolean>;

  public numbersOfPages;
  public pageSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public page$: Observable<number>;

  public interventions: Array<InterventionInterface> = [];

  public questionnaires: Array<LessonInterface> = [];

  public defaultReminderText = {
    de: {
      greeting: 'Guten Tag,',
      defaultText:
        'bislang wurde die Lektion noch nicht abgeschlossen. Wir möchten mit dieser E-Mail nur an die Bearbeitung erinnern. Wir wissen, dass so ein Training manchmal schwer im Alltag unterzubringen ist – gerade in schwierigen Zeiten kann es gar nicht so einfach sein, etwas für sich zu tun. Gerade dann kann es aber hilfreich sein, am Training dranzubleiben. Wir möchten daher gerne noch einmal dazu einladen, sich die Zeit für das Training zu nehmen.',
      ending: 'Mit besten Grüßen,',
      defaultEnding: 'das eSano Team'
    },
    en: {
      greeting: 'Hello,',
      defaultText:
        'So far the lessons have not yet been completed. With this e-mail we only want to remind you of the processing. We know that such a training is sometimes difficult to accommodate in everyday life - especially in difficult times it cannot be that easy to do something for yourself. But then it can be helpful to stick with the training. We would therefore like to invite you once again to make time for training.',
      ending: 'Best wishes,',
      defaultEnding: 'your eSano team'
    }
  };

  private subscriptions: Subscription[] = [];

  constructor(
    private alertService: AlertService,
    private helperService: HelperService,
    private helperSkillService: HelperSkillService,
    private helperDialogService: HelperDialogService
  ) {}

  public get helper() {
    return this.helperService;
  }

  public get helperSkill() {
    return this.helperSkillService;
  }

  public get helperDialog() {
    return this.helperDialogService;
  }

  public get alert() {
    return this.alertService;
  }

  // Set members of study from parent
  @Input()
  set _members(_members: Array<UserInterface>) {
    this.studyMembers = _members;
  }

  // Set collaborators of study from parent
  @Input()
  set _collaborators(_collaborators: Array<UserInterface>) {
    if (_collaborators && _collaborators.length > 0) {
      this.studyCollaborator = _collaborators;
    }
  }

  @Input() _myUserId(_myUserId: number) {
    if (_myUserId) {
      this.myUserId = _myUserId;
    }
  }

  @Input() _email(_email: string) {
    if (_email) {
      this.emailSubject.next(_email);
    }
  }

  // Set study and its configuration from parent
  @Input() hide(_hide: boolean) {
    if (_hide !== undefined) {
      this.isHiddenSubject.next(_hide);
    }
  }

  @Input() _payloads(_payloads: Array<PayloadInterface>) {
    if (_payloads) {
      this.payloads = [];
      this.payloadAttributes = [];

      this.payloads = _payloads;
      this.payloads.forEach((payload: PayloadInterface) => {
        this.payloadAttributes.push(payload.data.attributes);
      });
      this.numbersOfPages = this.payloads.length;
      this.pageSubject.next(0);

      this.reminderConfigs = Array(this.numbersOfPages).fill(null);
    }
  }

  @Input() _studiesAndIntervention(
    _studiesAndIntervention: Array<{
      study_id: number;
      interventions: Array<InterventionInterface>;
      collaborators: Array<UserInterface>;
    }>
  ) {
    if (_studiesAndIntervention) {
      this.studiesAndIntervention = _studiesAndIntervention;
      if (this.studiesAndIntervention.length > 0) {
        const flattenedCollaborators = [].concat(...this.studiesAndIntervention.map(x => x.collaborators));
        this.studyCollaborator = flattenedCollaborators;

        const flattenedInterventions = [].concat(...this.studiesAndIntervention.map(x => x.interventions));
        this.interventions = flattenedInterventions;
      }
    }
  }

  @Input() _questionnairesIntervention(
    _questionnairesIntervention: Array<{
      intervention_id: number;
      questionnaires: Array<LessonInterface>;
      diaries: Array<DiaryInterface>;
      skills: Array<SkillInterface>;
    }>
  ) {
    if (_questionnairesIntervention) {
      this.questionnairesIntervention = _questionnairesIntervention;
      const flattenedQuestionnaires = [].concat(...this.questionnairesIntervention.map(x => x.questionnaires));
      this.questionnaires = this.questionnaires.concat(flattenedQuestionnaires);
    }
  }

  ngOnInit(): void {
    // Get hidden
    this.isHidden$ = this.isHiddenSubject.asObservable();
    this.page$ = this.pageSubject.asObservable();
  }

  public getQuestionnaireById(questionnaireId: number): LessonInterface {
    const found = this.questionnaires.find((questionnaire: LessonInterface) => questionnaire.id.toString() === questionnaireId.toString());
    return found !== undefined ? found : null;
  }

  public getConfigurationByQuestionnaireId(questionnaireId: number, index: number): InterventionInstanceConfigurationInterface {
    let found = null;
    this.payloadAttributes[index].configuration.questionnaire_configuration.forEach(config => {
      if (config.id.toString() === questionnaireId.toString()) {
        found = config;
      }
    });
    return found;
  }

  public showUnlockHint(questionnaireId: number, index: number): boolean {
    const foundConfig = this.payloadAttributes[index].configuration.questionnaire_configuration.find(
      (config: InterventionInstanceConfigurationInterface) => config.id.toString() === questionnaireId.toString()
    );
    if (!foundConfig) {
      return false;
    }
    // Previous position based on form_custom_order
    const currentPos = this.payloadAttributes[index].configuration.custom_order.findIndex(
      value => value.toString() === questionnaireId.toString()
    );
    const previousPos = currentPos - 1;
    let foundPredecessor: InterventionInstanceConfigurationInterface;
    if (previousPos >= 0) {
      foundPredecessor = this.payloadAttributes[index].configuration.questionnaire_configuration.find(
        (config: InterventionInstanceConfigurationInterface) =>
          config.id.toString() === this.payloadAttributes[index].configuration.custom_order[previousPos].toString()
      );
    }
    if (foundPredecessor) {
      return foundConfig.unlock_type === 'after_previous' && foundPredecessor.feedback_required;
    }
    return false;
  }

  // Display previous page
  public showPrevious(): void {
    const pageNumber = this.pageSubject.getValue();
    if (pageNumber > 0) {
      this.pageSubject.next(pageNumber - 1);
    }
  }

  // Display next page
  public showNext(): void {
    const pageNumber = this.pageSubject.getValue();
    if (pageNumber < this.numbersOfPages - 1) {
      this.pageSubject.next(pageNumber + 1);
    }
  }

  public openDialogSkill(skillId: number, interventionId: number): void {
    const foundIntervention: {
      intervention_id: number;
      questionnaires: Array<LessonInterface>;
      diaries: Array<DiaryInterface>;
      skills: Array<SkillInterface>;
    } = this.findInterventionSet(interventionId);
    if (foundIntervention) {
      const dialogRef = this.helperDialog.openDialogSkill(
        skillId,
        foundIntervention.skills,
        this.helper.findArrObjById(interventionId, this.interventions)
      );
      if (dialogRef) {
        dialogRef.afterClosed().subscribe(result => {});
      }
    }
  }

  public findInterventionSet(interventionId: number): {
    intervention_id: number;
    questionnaires: Array<LessonInterface>;
    diaries: Array<DiaryInterface>;
    skills: Array<SkillInterface>;
  } {
    return this.questionnairesIntervention.find(
      (value: {
        intervention_id: number;
        questionnaires: Array<LessonInterface>;
        diaries: Array<DiaryInterface>;
        skills: Array<SkillInterface>;
      }) => value.intervention_id.toString() === interventionId.toString()
    );
  }

  public openDialogDiaryPreview(questionnaireId: number, studyId: number, diaryPageColor: string): void {
    this.helperDialog
      .openDialogDiaryPreview(questionnaireId, studyId, diaryPageColor)
      .afterClosed()
      .subscribe(result => {});
  }

  public findSkills(interventionId: number): Array<SkillInterface> {
    if (interventionId) {
      const found = this.findInterventionSet(interventionId);
      return found ? found.skills : [];
    }
    return [];
  }

  public findDiaries(interventionId: number): Array<DiaryInterface> {
    if (interventionId) {
      const found = this.findInterventionSet(interventionId);
      return found ? found.diaries : [];
    }
    return [];
  }

  public openDialogInterventionInstanceReminderCreation(
    index?: number,
    reminder?: {
      payload: PayloadInterface;
      intervention: InterventionInterface;
    },
    interventionId?: number
  ): void {
    this.helperDialog
      .openDialogCreateInterventionInstanceReminder(undefined, undefined, reminder?.payload)
      .afterClosed()
      .subscribe(result => {
        if (!(result instanceof HttpResponse) && !!result) {
          this.reminderConfigs[index] = {
            payload: result,
            intervention: this.helper.findArrObjById(this.payloadAttributes[index].intervention_id, this.interventions)
          };
        }
      });
  }

  public removeReminderConfig(index?: number): void {
    if (!!index || index === 0) {
      this.reminderConfigs[index] = null;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
