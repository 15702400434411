/* eslint-disable @typescript-eslint/naming-convention */
import { AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { BehaviorSubject, ConnectableObservable, forkJoin, iif, Observable, of, Subscription, throwError } from 'rxjs';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged, filter, map, mergeMap, skip, switchMap, take, tap } from 'rxjs/operators';
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons/faCheckSquare';
import { OrganisationCollaboratorInterface } from '../../../models/interface/organisation/organisation-collaborator.interface';
import { OrganisationSharedService } from '../../../services/shared/organisation-shared/organisation-shared.service';
import { HelperService } from '../../../services/helper/helper.service';
import { OrganisationInterface } from '../../../models/interface/organisation/organisation.interface';
import { PermissionInterface } from '../../../models/interface/permission.interface';
import { Store } from '@ngrx/store';
import { UserInterface } from '../../../models/interface/user.interface';
import { ProfileInterface } from '../../../models/interface/profile.interface';
import { Actions, ofType } from '@ngrx/effects';
import { OrganisationActionTypes } from '../../../store/organisation/organisation.action';
import { HttpResponse } from '@angular/common/http';
import { OrganisationStore } from '../../../store/organisation/component-store/organisation.store';

@Component({
  selector: 'app-organisation-detail',
  templateUrl: './organisation-detail.component.html',
  styleUrls: ['./organisation-detail.component.scss'],
  providers: [OrganisationStore]
})
export class OrganisationDetailComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren('matTabOrganisation') matTabGroup: QueryList<MatTabGroup>;

  // Icons
  faCheckSquare = faCheckSquare;

  public isLoading$: Observable<boolean>;

  // Data provided by OrganisationService
  public organisation: OrganisationInterface;
  public collaborators: Array<OrganisationCollaboratorInterface>;

  // Data provided by Profile Store
  public myUserId: number;

  public language: string;

  public isCollaborator$: Observable<boolean>;
  public isManager$: Observable<boolean>;
  public isOwner$: Observable<boolean>;

  public profile$: Observable<UserInterface>;

  private translationSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});

  private organisation$: Observable<OrganisationInterface>;

  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private isCollaboratorSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isManagerSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isOwnerSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private subscriptions: Subscription[] = [];

  constructor(
    private actRoute: ActivatedRoute,
    private router: Router,
    private sharedService: OrganisationSharedService,
    private helperService: HelperService,
    private translateService: TranslateService,
    private actions$: Actions,
    private store: Store<{ myProfile: ProfileInterface }>,
    private organisationStore: OrganisationStore
  ) {
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.isCollaborator$ = this.isCollaboratorSubject.asObservable();
    this.isManager$ = this.isManagerSubject.asObservable();
    this.isOwner$ = this.isOwnerSubject.asObservable();
    this.profile$ = store.select('myProfile');
    this.organisation$ = this.organisationStore.organisation$;

    this.subscriptions.push(
      this.translateService
        .getTranslation(localStorage.getItem('language') !== null ? localStorage.getItem('language') : 'de')
        .subscribe(translations => {
          this.translationSubject.next(translations);
        })
    );
  }

  public get helper() {
    return this.helperService;
  }

  public get translation() {
    return this.translateService;
  }

  ngOnInit(): void {
    this.getOrganisationContent();

    // Subscription to language change
    this.subscriptions.push(
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
        this.getOrganisationContent();
      })
    );

    // language
    if (localStorage.getItem('language') !== null) {
      this.language = localStorage.getItem('language');
    } else {
      this.language = 'de';
    }
    this.subscriptions.push(
      this.actRoute.params
        .pipe(
          map(v => v.id),
          switchMap(id => {
            this.organisationStore.getOrganisation({
              organisationId: id
            });
            return this.organisation$.pipe(skip(1), take(1));
          })
        )
        .subscribe(
          (result: any) => {
            this.organisation = result;
            this.sharedService.pushOrganisation(this.organisation);
          },
          error => {
            throwError(error);
            this.router.navigateByUrl('/groups');
          },
          () => {
            this.isLoadingSubject.next(false);
          }
        )
    );
  }

  ngAfterViewInit(): void {
    const url = this.router.url;
    this.matTabGroup.changes.subscribe((comps: QueryList<MatTabGroup>) => {
      this.helper.setMatTabActive(this.matTabGroup, this.router.url, this.translationSubject, this.translateService, 'organisation-detail');
    });
  }

  // Display and navigate to child component relative to this path
  public showChildComponent(child: string): void {
    if (child.match(/^(studies|collaborators|newStudy)$/)) {
      this.router.navigate([child], { relativeTo: this.actRoute });
    }
  }

  // Listens to tab change
  public onChangeTab(event): void {
    const tab: MatTab = event.tab;
    this.showChildComponent(this.evaluateTab(tab.textLabel, this.translationSubject.value));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private evaluateTab(label, translate: any): string {
    if (translate) {
      if (translate['organisation-detail']['studies'] === label) {
        return 'studies';
      }
      if (translate['organisation-detail']['collaborators'] === label) {
        return 'collaborators';
      }
      if (translate['organisation-detail']['new_studies'] === label) {
        return 'newStudy';
      }
    }
    return '';
  }

  // Get and display detailed organisation details
  private getOrganisationContent(): void {
    this.isLoadingSubject.next(true);

    this.subscriptions.push(
      this.profile$
        .pipe(
          filter(user => user !== null),
          mergeMap((value: ProfileInterface) => {
            // My current user ID
            this.myUserId = value.id;
            return this.actRoute.params;
          }),
          map(v => v.id),
          mergeMap(id => {
            this.organisationStore.getOrganisation({
              organisationId: id
            });
            const reqs: any = [];
            reqs.push(this.organisation$.pipe(skip(1), take(1)));
            this.store.dispatch({
              type: OrganisationActionTypes.getOrganisationCollaboratorsType,
              payload: {
                organisationId: id
              }
            });
            reqs.push(
              this.actions$.pipe(
                ofType(
                  OrganisationActionTypes.getOrganisationCollaboratorsSuccessType,
                  OrganisationActionTypes.getOrganisationCollaboratorsErrorType
                ),
                take(1)
              )
            );
            return forkJoin(reqs);
          })
        )
        .subscribe(
          (results: any) => {
            this.organisation = results[0];
            this.sharedService.pushOrganisation(this.organisation);

            if (results[1].type === OrganisationActionTypes.getOrganisationCollaboratorsSuccessType) {
              const result: HttpResponse<any> = results[1].response;
              this.collaborators = result['body']['data'];

              // Determine my highest role
              const myself: OrganisationCollaboratorInterface = this.collaborators.find(user => user.id === this.myUserId);
              if (myself !== undefined) {
                const myroleSlug = this.helperService.getHighestRoleOfOrganisationCollaborator(myself);
                if (myroleSlug.match(/organisation\.(manager|owner)$/)) {
                  this.isCollaboratorSubject.next(true);
                  this.sharedService.pushIsCollaborator(true);
                  this.isManagerSubject.next(true);
                  if (myroleSlug.match(/organisation\.owner$/)) {
                    this.isOwnerSubject.next(true);
                  }
                } else {
                  this.isCollaboratorSubject.next(false);
                  this.sharedService.pushIsCollaborator(false);
                }
              }
            }
            this.isLoadingSubject.next(false);
          },
          error => {
            throwError(error);
            this.router.navigateByUrl('/groups');
          },
          () => {
            this.isLoadingSubject.next(false);
          }
        )
    );
  }
}
