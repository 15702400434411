import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { UserInterface } from '../../../models/interface/user.interface';
import { HelperService } from '../helper.service';
import { TableSortInterface } from '../../../models/interface/table/table_sort.interface';
import { ActivityInterface } from '../../../models/interface/activity.interface';
import { TranslateService } from '@ngx-translate/core';
import { LessonInterface } from '../../../models/interface/lesson.interface';
import { mergeMap } from 'rxjs/operators';
import { MessageThreadsInterface } from '../../../models/interface/message_threads.interface';

@Injectable({
  providedIn: 'root'
})
export class HelperActivityService {
  constructor(private helperService: HelperService, private translateService: TranslateService) {}

  public get helper() {
    return this.helperService;
  }

  public toggleSortActivityId(
    selected: Array<ActivityInterface>,
    sortActivityId: boolean,
    contentSubject: BehaviorSubject<any>,
    tableSort: TableSortInterface
  ): void {
    if (sortActivityId === null || sortActivityId === true) {
      // Descending
      contentSubject.next(selected.sort((a, b) => parseInt(b.id.toString(), 10) - parseInt(a.id.toString(), 10)));
      this.setSortFilter(tableSort, false, null, null, null, null, null);
    } else {
      if (sortActivityId === false) {
        contentSubject.next(selected.sort((a, b) => parseInt(a.id.toString(), 10) - parseInt(b.id.toString(), 10)));
      }
      this.setSortFilter(tableSort, true, null, null, null, null, null);
    }
  }

  public toggleSortAction(
    selected: Array<ActivityInterface>,
    sortAction: boolean,
    contentSubject: BehaviorSubject<any>,
    tableSort: TableSortInterface
  ): void {
    if (sortAction === null || sortAction === true) {
      // Descending
      contentSubject.next(selected.sort((a, b) => b.attributes.action.localeCompare(a.attributes.action)));
      this.setSortFilter(tableSort, null, false, null, null, null, null);
    } else {
      if (sortAction === false) {
        contentSubject.next(selected.sort((a, b) => a.attributes.action.localeCompare(b.attributes.action)));
      }
      this.setSortFilter(tableSort, null, true, null, null, null, null);
    }
  }

  public toggleSortActionId(
    selected: Array<ActivityInterface>,
    sortActionId: boolean,
    contentSubject: BehaviorSubject<any>,
    tableSort
  ): void {
    if (sortActionId === null || sortActionId === true) {
      // Descending
      contentSubject.next(
        selected.sort((a, b) => parseInt(b.attributes.action_id.toString(), 10) - parseInt(a.attributes.action_id.toString(), 10))
      );
      this.setSortFilter(tableSort, null, null, false, null, null, null);
    } else {
      if (sortActionId === false) {
        contentSubject.next(
          selected.sort((a, b) => parseInt(a.attributes.action_id.toString(), 10) - parseInt(b.attributes.action_id.toString(), 10))
        );
      }
      this.setSortFilter(tableSort, null, null, true, null, null, null);
    }
  }

  public toggleSortText(
    selected: Array<ActivityInterface>,
    sortText: boolean,
    contentSubject: BehaviorSubject<any>,
    tableSort: TableSortInterface
  ): void {
    if (sortText === null || sortText === true) {
      // Descending
      contentSubject.next(selected.sort((a, b) => b.attributes.text.localeCompare(a.attributes.text)));
      this.setSortFilter(tableSort, null, null, null, false, null, null);
    } else {
      if (sortText === false) {
        contentSubject.next(selected.sort((a, b) => a.attributes.text.localeCompare(b.attributes.text)));
      }
      this.setSortFilter(tableSort, null, null, null, true, null, null);
    }
  }

  public toggleSortPerformedBy(
    selected: Array<ActivityInterface>,
    sortPerformedBy: boolean,
    contentSubject: BehaviorSubject<any>,
    users: Array<UserInterface>,
    tableSort: {
      sortActivityId: boolean;
      sortAction: boolean;
      sortActionId: boolean;
      sortText: boolean;
      sortPerformedBy: boolean;
      sortCreatedAt: boolean;
    }
  ): void {
    if (sortPerformedBy === null || sortPerformedBy === true) {
      // Descending
      contentSubject.next(
        selected.sort((activityA, activityB) => {
          const foundA =
            this.helper.findArrObjById(activityA.attributes.user, users) !== null
              ? this.helper.getCodeNameEmail(activityA.attributes.user, users)
              : 'SYSTEM';
          const foundB =
            this.helper.findArrObjById(activityB.attributes.user, users) !== null
              ? this.helper.getCodeNameEmail(activityB.attributes.user, users)
              : 'SYSTEM';
          return foundA.toString().localeCompare(foundB.toString());
        })
      );
      this.setSortFilter(tableSort, null, null, null, null, false, null);
    } else {
      if (sortPerformedBy === false) {
        contentSubject.next(
          selected.sort((activityA, activityB) => {
            const foundA =
              this.helper.findArrObjById(activityA.attributes.user, users) !== null
                ? this.helper.getCodeNameEmail(activityA.attributes.user, users)
                : 'SYSTEM';
            const foundB =
              this.helper.findArrObjById(activityB.attributes.user, users) !== null
                ? this.helper.getCodeNameEmail(activityB.attributes.user, users)
                : 'SYSTEM';
            return foundB.toString().localeCompare(foundA.toString());
          })
        );
      }
      this.setSortFilter(tableSort, null, null, null, null, true, null);
    }
  }

  public toggleSortCreated(
    selected: Array<ActivityInterface>,
    sortCreatedAt: boolean,
    contentSubject: BehaviorSubject<any>,
    tableSort: {
      sortActivityId: boolean;
      sortAction: boolean;
      sortActionId: boolean;
      sortText: boolean;
      sortPerformedBy: boolean;
      sortCreatedAt: boolean;
    }
  ): void {
    if (sortCreatedAt === null || sortCreatedAt === true) {
      // Descending
      contentSubject.next(
        selected.sort(
          (a, b) =>
            new Date(this.helper.getDateFromObjectString(b.attributes.created_at)).valueOf() -
            new Date(this.helper.getDateFromObjectString(a.attributes.created_at)).valueOf()
        )
      );
      this.setSortFilter(tableSort, null, null, null, null, null, false);
    } else {
      if (sortCreatedAt === false) {
        contentSubject.next(
          selected.sort(
            (a, b) =>
              new Date(this.helper.getDateFromObjectString(a.attributes.created_at)).valueOf() -
              new Date(this.helper.getDateFromObjectString(b.attributes.created_at)).valueOf()
          )
        );
      }
      this.setSortFilter(tableSort, null, null, null, null, null, true);
    }
  }

  public setSortFilter(tableSort: TableSortInterface, activityId, action, actionId, text, performedBy, createdAt): TableSortInterface {
    tableSort.sortActivityId = activityId;
    tableSort.sortAction = action;
    tableSort.sortActionId = actionId;
    tableSort.sortText = text;
    tableSort.sortPerformedBy = performedBy;
    tableSort.sortCreatedAt = createdAt;
    return tableSort;
  }

  public getActivityText(activity: ActivityInterface): Observable<string> {
    if (activity.attributes.text) {
      const replaceText = (act: ActivityInterface) => {
        // STUDY_SUBSCRIBED and ANSWERSHEET_SUBMITTED
        if (act.attributes.text.includes('{{name}}')) {
          return act.attributes.text.replace('{{name}}', '"' + act.attributes.name + '"');
        } else if (act.attributes.action === 'ANSWERSHEET_SUBMITTED') {
          return act.attributes.text.replace(/\./g, '');
        } else {
          return act.attributes.text;
        }
      };
      return of(replaceText(activity));
    } else {
      return this.translateService.get('activity.' + activity.attributes.action.toLowerCase());
    }
  }

  public getLessonNameOfActivity(activity: ActivityInterface, lessons: Array<LessonInterface> = []): string {
    if (activity.attributes.action === 'ANSWERSHEET_SUBMITTED') {
      const data: Array<{
        type: string;
        id: number;
      }> = activity.relationships.questionnaires.data;
      if (data) {
        if (data.length > 0) {
          const found: LessonInterface = lessons.find((lesson: LessonInterface) => lesson.id.toString() === data[0].id.toString());
          return found ? found.attributes.name : null;
        } else {
          return null;
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  public translateActivityToTask(activity: ActivityInterface, lessons: Array<LessonInterface> = []): Observable<string> {
    if (activity.attributes.action === 'ANSWERSHEET_SUBMITTED') {
      return this.translateService.get('helper-service.task_feedback_required').pipe(
        mergeMap((translation: string) => {
          const lessonName = this.getLessonNameOfActivity(activity, lessons);
          return of(translation.replace('{{lesson}}', '' + lessonName + ''));
        })
      );
    } else if (activity.attributes.action === 'INTERVENTION_INSTANCE_CAN_UNLOCK') {
      return this.translateService.get('helper-service.task_lesson_to_unlock_required');
    } else {
      return of('');
    }
  }

  public uniqueById(arr: Array<ActivityInterface>) {
    const sorted = arr.concat().sort();
    for (let i = 1; i < sorted.length; ) {
      if (sorted[i - 1].id === sorted[i].id) {
        sorted.splice(i, 1);
      } else {
        i++;
      }
    }
    return sorted;
  }

  public filterByActivities(arr: Array<ActivityInterface>, action: Array<string>): Array<ActivityInterface> {
    return arr.filter((activity: ActivityInterface) => action.includes(activity.attributes.action));
  }

  public getTasks(activities: Array<ActivityInterface>): Array<ActivityInterface> {
    return this.helper.getActivityByAction(activities, 'ANSWERSHEET_SUBMITTED', 'INTERVENTION_INSTANCE_CAN_UNLOCK');
  }

  public findActivityByAnswersheetId(array: Array<ActivityInterface>, answersheetId: number): ActivityInterface {
    return array
      .filter(act => act.attributes.action === 'ANSWERSHEET_SUBMITTED')
      .find((activity: ActivityInterface) => {
        const item = activity.attributes.info;
        if (item) {
          if (item.answersheet_id) {
            if (Array.isArray(item.answersheet_id)) {
              return item.answersheet_id[0].toString() === answersheetId.toString();
            } else {
              return item.answersheet_id.toString() === answersheetId.toString();
            }
          } else {
            return activity.attributes.entity_id.toString() === answersheetId.toString();
          }
        } else {
          return activity.attributes.entity_id.toString() === answersheetId.toString();
        }
      });
  }

  public getTasksFromActivities(activities: Array<ActivityInterface>) {
    return this.helper
      .getActivityByAction(activities, 'ANSWERSHEET_SUBMITTED', 'INTERVENTION_INSTANCE_CAN_UNLOCK')
      .filter((activity: ActivityInterface) => {
        if (activity.attributes.action === 'ANSWERSHEET_SUBMITTED') {
          if (activity.attributes.info) {
            if (activity.attributes.info.intervention_instance_id) {
              // return activity.attributes.info.includes('Intervention_Instance');
              return (
                activity.attributes.info.intervention_instance_id &&
                (activity.attributes.info.FEEDBACK_REQUIRED || activity.attributes.info.feedback_required)
              );
            }
            if (activity.attributes.info.diary_instance_id) {
              return false;
            }
            return false;
          } else {
            return true;
          }
        }
        return true;
      });
  }

  public isTaskCompleted(activity: ActivityInterface, threads: Array<MessageThreadsInterface>): boolean {
    const answersheetId = this.getAnswersheetIdFromActivity(activity);
    if (
      activity.attributes.finished.toString() === '1' ||
      activity.attributes.finished.toString() === '' ||
      activity.attributes.finished.toString() === 'completed'
    ) {
      return true;
    } else {
      if (answersheetId !== null) {
        const result = threads.find(x =>
          x.attributes.answersheet_id ? x.attributes.answersheet_id.toString() === answersheetId.toString() : false
        );
        return !!result;
      } else {
        return false;
      }
    }
  }

  public getAnswersheetIdFromActivity(activity: ActivityInterface): number {
    const path = activity.attributes.uri;
    return path != null ? parseInt(path.substring(path.lastIndexOf('/') + 1), 10) : null;
  }

  public filterPendingTasks(activities: Array<ActivityInterface>, threads: Array<MessageThreadsInterface>): Array<ActivityInterface> {
    return activities.filter((activity: ActivityInterface) => {
      if (activity.attributes.action === 'ANSWERSHEET_SUBMITTED') {
        const answersheetId = this.helper.getAnswersheetIdFromActivity(activity);
        if (answersheetId !== null) {
          const result = threads.find(x => x.attributes.answersheet_id.toString() === answersheetId.toString());
          // Added finished filter for 'ANSWERSHEET_SUBMITTED'
          if (activity.attributes.info) {
            const required = activity.attributes.info.FEEDBACK_REQUIRED || activity.attributes.info.feedback_required;
            return result === undefined && (activity.attributes.finished === 0 || activity.attributes.finished === 'pending') && required;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else if (activity.attributes.action === 'INTERVENTION_INSTANCE_CAN_UNLOCK') {
        return activity.attributes.finished === 0 || activity.attributes.finished === 'pending';
      } else {
        return false;
      }
    });
  }
}
