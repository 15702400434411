<button [attr.id]="'btnDialogClose'" mat-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title>{{'home.modal_detail_instance_title' | translate:param}}</h1>

<div mat-dialog-content>
  <!-- ng-template to ensure that the generated components end up in this place -->

  <div class="text-center" *ngIf="components.length === 0">
    <app-loader></app-loader>
  </div>

  <ng-template #instanceDetailContainer> </ng-template>
</div>
<div mat-dialog-actions align="end">
  <button [attr.id]="'btnCancel'" mat-button mat-dialog-close mat-dialog-close="true">
    {{'dialog.intervention-detail.dialog_close' | translate}}
  </button>
</div>
