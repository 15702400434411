/* eslint-disable @typescript-eslint/naming-convention */
import { HttpResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { faHands } from '@fortawesome/free-solid-svg-icons/faHands';
import { faHandsHelping } from '@fortawesome/free-solid-svg-icons/faHandsHelping';
import { Observable, Subscription, filter, take, BehaviorSubject } from 'rxjs';
import { BuddyInstanceInterface } from '../../../models/interface/buddy/buddy-instance.interface';
import { DiaryInstanceInterface } from '../../../models/interface/diary-instance.interface';
import { DiaryInterface } from '../../../models/interface/diary.interface';
import { InterventionInstanceInterface } from '../../../models/interface/intervention-instances/intervention-instance.interface';
import { InterventionInterface } from '../../../models/interface/intervention.interface';
import { UserInterface } from '../../../models/interface/user.interface';
import { HelperService } from '../../../services/helper/helper.service';
import { BuddyStore } from '../../../store/buddy/component-store/buddy.store';
import { DialogBuddyInstanceCreateComponent } from '../dialog-buddy-instance-create/dialog-buddy-instance-create.component';

@Component({
  selector: 'app-dialog-buddy-instance-swap',
  templateUrl: './dialog-buddy-instance-swap.component.html',
  styleUrls: ['./dialog-buddy-instance-swap.component.scss'],
  providers: [BuddyStore]
})
export class DialogBuddyInstanceSwapComponent implements OnInit, OnDestroy {
  // Icons
  faHands = faHands;
  faHandsHelping = faHandsHelping;

  public selectedBuddyInstance1: BuddyInstanceInterface;
  public buddySelection1: Array<BuddyInstanceInterface> = [];
  public selectedBuddyInstance2: BuddyInstanceInterface;
  public buddySelection2: Array<BuddyInstanceInterface> = [];

  public selectedBuddyToRemove: UserInterface;
  public buddyMembers1: Array<UserInterface> = [];
  public selectedBuddyToAdd: UserInterface;
  public buddyMembers2: Array<UserInterface> = [];

  public members: Array<UserInterface> = [];

  public interventionInstancesBuddy1: Array<InterventionInstanceInterface> = [];
  public interventionInstancesBuddy2: Array<InterventionInstanceInterface> = [];
  public diaryInstancesBuddy1: Array<DiaryInstanceInterface> = [];
  public diaryInstancesBuddy2: Array<DiaryInstanceInterface> = [];
  public param;
  public interventions: Array<InterventionInterface> = [];
  public buddyInstances: Array<BuddyInstanceInterface>;
  public diaryInstances: Array<DiaryInstanceInterface>;
  public interventionInstances: Array<InterventionInstanceInterface>;

  public diaries: Array<DiaryInterface>;

  public selectedInterventionInstance1: InterventionInstanceInterface;
  public selectedDiaryInstance1: DiaryInstanceInterface;

  public selectedInterventionInstance2: InterventionInstanceInterface;
  public selectedDiaryInstance2: DiaryInstanceInterface;

  // Form submission
  submitted = false;

  public swapBuddyResponse$: Observable<any>;
  public swapBuddyResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');

  // Translation
  public param1 = { buddyEmail: '', id1: '', id2: '' };
  public param2 = { buddyEmail: '', id1: '', id2: '' };

  public studyId: number;

  // Subscription Handler
  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private helperService: HelperService,
    private dialogRef: MatDialogRef<DialogBuddyInstanceCreateComponent>,
    private buddyStore: BuddyStore
  ) {
    this.swapBuddyResponse$ = this.buddyStore.swapBuddyResponse$;
  }

  // Helper function
  public get helper() {
    return this.helperService;
  }

  ngOnInit(): void {
    this.members = this.data.members;
    this.param = this.data.param;
    this.buddyInstances = this.data.buddyInstances;
    this.interventionInstances = this.data.interventionInstances;
    this.diaryInstances = this.data.diaryInstances;
    this.diaries = this.data.diaries;
    this.interventions = this.data.interventions;
    this.studyId = this.data.studyId;

    this.buddySelection1 = this.buddyInstances;
    this.buddySelection2 = this.buddyInstances;

    this.resetSelection1();
    this.resetSelection2();
  }

  public onChangeSelection1(event): void {
    this.buddyMembers1 = this.members.filter((user: UserInterface) => this.selectedBuddyInstance1.attributes.buddies_ids.includes(user.id));
    this.buddySelection2 = this.buddyInstances.filter(
      (buddyInstance: BuddyInstanceInterface) => buddyInstance.id.toString() !== this.selectedBuddyInstance1.id.toString()
    );
    this.resetSelection1();
  }

  public onChangeSelection2(event): void {
    this.buddyMembers2 = this.members.filter((user: UserInterface) => this.selectedBuddyInstance2.attributes.buddies_ids.includes(user.id));
    this.buddySelection1 = this.buddyInstances.filter(
      (buddyInstance: BuddyInstanceInterface) => buddyInstance.id.toString() !== this.selectedBuddyInstance2.id.toString()
    );
    this.resetSelection2();
  }

  public onChangeUserSelection(event): void {
    this.param1 = {
      buddyEmail: this.selectedBuddyToRemove?.attributes?.email,
      id1: this.selectedBuddyInstance1?.id.toString(),
      id2: this.selectedBuddyInstance2?.id.toString()
    };
    this.param2 = {
      buddyEmail: this.selectedBuddyToAdd?.attributes?.email,
      id1: this.selectedBuddyInstance2?.id.toString(),
      id2: this.selectedBuddyInstance1?.id.toString()
    };
  }

  public isAllSelected(): boolean {
    return !!this.selectedBuddyToRemove && !!this.selectedBuddyToAdd && !!this.selectedBuddyInstance1 && !!this.selectedBuddyInstance2;
  }

  public swapBuddyConnection(): void {
    this.submitted = true;
    if (this.isAllSelected() && this.swapBuddyResponse.value === 'DEFAULT') {
      this.swapBuddyResponse.next('LOADING');
      const activeUserId1 = this.selectedBuddyInstance1.attributes.buddies_ids.find(
        (id: number) => id.toString() !== this.selectedBuddyToRemove.id.toString()
      );
      const inactiveUserId2 = this.selectedBuddyInstance2.attributes.buddies_ids.find(
        (id: number) => id.toString() !== this.selectedBuddyToAdd.id.toString()
      );
      this.submitted = false;
      this.buddyStore.swapBuddies({
        buddyId1: this.selectedBuddyInstance1.id,
        active_user_id1: activeUserId1,
        inactive_user_id1: this.selectedBuddyToRemove.id,
        buddyId2: this.selectedBuddyInstance2.id,
        active_user_id2: this.selectedBuddyToAdd.id,
        inactive_user_id2: inactiveUserId2
      });
      this.subscriptions.push(
        this.swapBuddyResponse$
          .pipe(
            filter(value => !!value),
            take(1)
          )
          .subscribe((result: any) => {
            if (result instanceof HttpResponse) {
              this.swapBuddyResponse.next('SUCCESS');
              setTimeout(() => {
                this.submitted = false;
                this.swapBuddyResponse.next('DEFAULT');
                this.dialogRef.close(result);
              }, 2500);
            } else {
              this.swapBuddyResponse.next('FAILURE');
              setTimeout(() => {
                this.submitted = false;
                this.swapBuddyResponse.next('DEFAULT');
              }, 2500);
            }
          })
      );
    } else {
      this.submitted = false;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  private resetSelection1(): void {}

  private resetSelection2(): void {}
}
