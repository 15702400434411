import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AnswersheetAnswerInterface } from '../../../models/interface/answersheet-answer.interface';
import { InterventionInterface } from '../../../models/interface/intervention.interface';
import { PageIterationInterface } from '../../../models/interface/elements/page-iteration.interface';
import { ElementsPageComponent } from '../components/elements-page/elements-page.component';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {
  public intervention: InterventionInterface;
  public answers: Array<AnswersheetAnswerInterface>;

  public isDiary = true;

  public pages: Array<ElementsPageComponent>;
  public currentPage = 0;
  public showProgressbarText = false;
  public progressbarText;

  private allPages: Array<PageIterationInterface> = [];

  private currentPageSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private textShowingHandler;

  private lessonFinished = false;

  constructor() {}

  // Block condition
  @Input()
  set _intervention(_intervention: InterventionInterface) {
    if (_intervention) {
      this.intervention = _intervention;
      this.isDiary = false;
    }
  }

  @Input()
  set _answers(_answers) {
    if (_answers) {
      this.answers = _answers;
    }
  }

  @Input()
  set _pages(_pages: Array<ElementsPageComponent>) {
    this.pages = _pages;
  }

  @Input()
  set _allPages(_allPages: Array<PageIterationInterface>) {
    if (_allPages) {
      this.allPages = _allPages;
    }
  }

  @Input()
  set _currentPage(_currentPage: number) {
    if (_currentPage !== undefined || _currentPage !== null) {
      this.currentPage = _currentPage;
      this.currentPageSubject.next(_currentPage);
    }
  }

  ngOnInit(): void {
    this.currentPageSubject.asObservable().subscribe(value => {
      this.activateProgressbarText();
    });
  }

  getLessonProgress(): number {
    return this.lessonFinished ? 100 : Math.trunc((this.currentPage / (this.allPages.length + 1)) * 100);
  }

  activateProgressbarText() {
    if (this.allPages[this.currentPage - 1]) {
      const pageElementPos = this.allPages[this.currentPage - 1].position;
      const found = this.pages.find(element => element.getPosition().toString() === pageElementPos.toString());
      if (found) {
        const text = found.translation() ? found.translation().progressbar_text : null;
        if (text && text !== '') {
          // open text
          this.showProgressbarText = true;
          this.progressbarText = text;
          clearTimeout(this.textShowingHandler);
          this.textShowingHandler = setTimeout(() => {
            this.showProgressbarText = false;
          }, 6000);
        } else {
          // close text
          this.showProgressbarText = false;
        }
      } else {
        this.showProgressbarText = false;
      }
    }
  }

  getCurrentPageIndexAbsolute(): number {
    return this.allPages.length;
  }
}
