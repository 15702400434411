/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { BehaviorSubject, forkJoin, Observable, Subscription, throwError } from 'rxjs';
import { catchError, filter, mergeMap, skip, switchMap, take } from 'rxjs/operators';
import { faStar } from '@fortawesome/free-regular-svg-icons/faStar';
import { Store } from '@ngrx/store';
import { HelperService } from '../../../services/helper/helper.service';
import { UserInterface } from '../../../models/interface/user.interface';
import { StudyInterface } from '../../../models/interface/study/study.interface';
import { GroupSharedService } from '../../../services/shared/group-shared/group-shared.service';
import { ProfileInterface } from '../../../models/interface/profile.interface';
import { HelperDialogService } from '../../../services/helper/helper-dialog/helper-dialog.service';
import { StudyActionTypes } from '../../../store/study/study.action';
import { getCollaboratorsByStudyId } from '../../../store/study/study.selector';
import { UserActionTypes } from '../../../store/user/user.action';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';

/**
 * Component:
 * Collaborator page displaying a list of collaborators of the group;
 * Can be found: {uri}/groups/{{group_id}}/collaborators
 */

@Component({
  selector: 'app-group-collaborator',
  templateUrl: './group-collaborator.component.html',
  styleUrls: ['./group-collaborator.component.scss']
})
export class GroupCollaboratorComponent implements OnInit, OnDestroy {
  @ViewChild('paginator') paginator;

  // Icons
  faSearch = faSearch;
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;
  faStar = faStar;
  faEnvelope = faEnvelope;
  faTrash = faTrash;

  public profile: ProfileInterface;

  // Data provided by StudyService
  public collaborators: Array<UserInterface> = [];
  public eCoaches: Array<UserInterface>;

  // Filter
  public filter = {
    searchFilter: ''
  };

  // List of roles
  public roles = ['study.ecoachmanager', 'study.ecoach', 'study.access'];

  public isLoading$: Observable<boolean>;
  public isCollapse$: Observable<boolean>;

  public collaborators$: Observable<Array<UserInterface>>;
  public pagedCollaborators$: Observable<Array<UserInterface>>;

  public isManager$: Observable<boolean>;
  public isOrganisationManager$: Observable<boolean>;

  // Translation
  public param = { intervention_name: '...' };

  public profile$: Observable<ProfileInterface>;

  public isCollapseSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  // Study ID and loading status for this child component
  private study: StudyInterface;

  private searchTextSubject = new BehaviorSubject<string>('');
  private pagedCollaboratorsSubject: BehaviorSubject<Array<UserInterface>> = new BehaviorSubject<Array<UserInterface>>([]);

  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private collaboratorsSubject: BehaviorSubject<Array<UserInterface>> = new BehaviorSubject([]);
  private isManagerSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isOrganisationManagerSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private collaboratorsOfStudy$: Observable<{ studyId: number; collaborators: UserInterface[] }>;

  private allCollaborators$: Observable<Array<UserInterface>>;

  private subscriptions: Subscription[] = [];

  constructor(
    private sharedService: GroupSharedService,
    private helperService: HelperService,
    private helperDialogService: HelperDialogService,
    private store: Store<{
      myProfile: ProfileInterface;
      getCollaboratorsByStudyId: { studyId: number; collaborators: UserInterface[] };
      allECoaches: UserInterface[];
    }>
  ) {
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.pagedCollaborators$ = this.pagedCollaboratorsSubject.asObservable();
    this.isCollapse$ = this.isCollapseSubject.asObservable();
    this.collaborators$ = this.collaboratorsSubject.asObservable();
    this.isManager$ = this.isManagerSubject.asObservable();
    this.isOrganisationManager$ = this.isOrganisationManagerSubject.asObservable();
    this.profile$ = store.select('myProfile');
    this.allCollaborators$ = store.select('allECoaches');
  }

  public get helper() {
    return this.helperService;
  }

  public get helperDialog() {
    return this.helperDialogService;
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.sharedService.isOrganisationManager$.subscribe(value => {
        this.isOrganisationManagerSubject.next(value);
      })
    );

    // Sharing parent values: id and study name
    this.subscriptions.push(
      this.sharedService.sourceGroup$
        .pipe(
          filter((value: StudyInterface) => value !== null),
          switchMap((value: StudyInterface) => {
            this.study = value;
            this.param = { intervention_name: value.attributes.name };
            return this.profile$.pipe(
              filter(user => user !== null),
              take(1)
            );
          })
        )
        .subscribe(
          (result: ProfileInterface) => {
            this.profile = result;
            this.applyCollaboratorFilter();
          },
          error => {
            throwError('Error in setup:', error);
          }
        )
    );
  }

  public applyCollaboratorFilter(): void {
    this.isLoadingSubject.next(true);
    this.subscriptions.push(
      this.reloadCollaboratorList(true).subscribe((result: any) => {
        this.isLoadingSubject.next(false);
      })
    );
  }

  public updatePagedCollaborators(event: any) {
    if (event) {
      this.pagedCollaboratorsSubject.next(event);
    }
  }

  public reloadCollaboratorList(setFirst?: boolean): Observable<boolean> {
    const pageIndex = this.paginator?.paginator ? this.paginator?.paginator.pageIndex : 0;
    const pageSize = this.paginator?.paginator ? this.paginator?.paginator.pageSize : 20;
    const reqs: Array<Observable<any>> = [];
    this.store.dispatch({ type: StudyActionTypes.getCollaboratorsType, payload: { studyId: this.study.id, include: 'roles' } });
    this.collaboratorsOfStudy$ = this.store.select(getCollaboratorsByStudyId(this.study.id));
    reqs.push(this.collaboratorsOfStudy$.pipe(skip(1), take(1)));
    this.store.dispatch({ type: UserActionTypes.getAllEcoachesType, payload: {} });
    reqs.push(this.allCollaborators$.pipe(skip(1), take(1)));

    return forkJoin(reqs).pipe(
      mergeMap((results: Array<any>) => {
        this.collaborators = results[0].collaborators;
        this.eCoaches = this.getECoachNotInStudy(results[1], this.collaborators);
        const myself: UserInterface = this.collaborators.find(user => user.id.toString() === this.profile.id.toString());
        if (myself) {
          if (this.helper.getHighestRoleOfCollaborator(myself).match(/study\.(ecoachmanager|owner)$/)) {
            this.isManagerSubject.next(true);
          }
        }

        // If user is not manager - they can only see study.ecoachmanager, study.owner and themselves
        if (!(this.isManagerSubject.value || this.isOrganisationManagerSubject.value)) {
          this.collaborators = this.collaborators.filter((user: UserInterface) => {
            const foundManager =
              this.helperService.hasRoles(user.relationships?.roles?.data, /study\.(ecoachmanager|owner)$/) ||
              user.id.toString() === this.profile.id.toString();
            return foundManager;
          });
        }
        return this.performFilterAndPagination(setFirst, pageIndex, pageSize);
      }),
      catchError(error => {
        throwError('ForkJoin error', error);
        return this.performFilterAndPagination(setFirst, pageIndex, pageSize);
      })
    );
  }

  public updateList(): void {
    this.subscriptions.push(this.reloadCollaboratorList().subscribe());
  }

  public openDialogGroupCollaboratorAdd(user?: UserInterface): void {
    this.helperDialog
      .openDialogGroupCollaboratorAdd(this.param, this.study.id, this.collaborators, this.eCoaches)
      .afterClosed()
      .pipe(mergeMap((value: any) => this.reloadCollaboratorList()))
      .subscribe(() => {});
  }

  public openDialogGroupCollaboratorEdit(user?: UserInterface): void {
    this.helperDialog
      .openDialogGroupCollaboratorEdit(this.param, this.study.id, this.collaborators, this.eCoaches)
      .afterClosed()
      .pipe(mergeMap((value: any) => this.reloadCollaboratorList()))
      .subscribe(() => {});
  }

  public openDialogGroupCollaboratorRemove(user?: UserInterface): void {
    this.helperDialog
      .openDialogGroupCollaboratorRemove(this.param, this.study.id, this.collaborators, this.eCoaches, user)
      .afterClosed()
      .pipe(mergeMap((value: any) => this.reloadCollaboratorList()))
      .subscribe(() => {});
  }

  public openDialogGroupCollaboratorOrganisationManager(): void {
    this.helperDialog
      .openDialogGroupCollaboratorOrganisationManager(
        this.study,
        this.collaborators.filter(collaborator => collaborator.attributes.is_verified === 1),
        this.eCoaches.filter(collaborator => collaborator.attributes.is_verified === 1)
      )
      .afterClosed()
      .pipe(mergeMap((value: any) => this.reloadCollaboratorList()))
      .subscribe(() => {});
  }

  public trackByUser(index: number, element: UserInterface): string {
    return JSON.stringify(element);
  }

  public performFilterAndPagination(setFirst: boolean, pageIndex: number, pageSize: number): Observable<boolean> {
    return this.helper
      .searchUsersByInput(this.filter['searchFilter'], this.searchTextSubject, this.collaborators, this.collaboratorsSubject)
      .pipe(
        mergeMap(() =>
          this.helper.setPagedContent(this.collaboratorsSubject, this.pagedCollaboratorsSubject, setFirst, pageIndex, pageSize)
        )
      );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private getECoachNotInStudy(allEditors: Array<UserInterface>, myCollaborators: Array<UserInterface>): Array<UserInterface> {
    return allEditors.filter(
      (user: UserInterface) => !myCollaborators.map((collaborator: UserInterface) => collaborator.id).includes(user.id)
    );
  }
}
