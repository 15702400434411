<button [attr.id]="'btnDialogClose'" mat-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title>{{'admin-dashboard.delete_organisation' | translate}}</h1>
<div mat-dialog-content class="mat-typography">
  <p class="text-danger">{{'admin-dashboard.delete_organisation_warning' | translate}}</p>
  <p>{{'admin-dashboard.delete_organisation_description' | translate}}: <strong>{{organisation.attributes.name}}</strong></p>
</div>
<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{'admin-dashboard.cancel' | translate}}</button>
  <div *ngIf="(deleteOrganisationConfirmation | async); then thenShowConfirmation else elseShowNext"></div>
  <ng-template #thenShowConfirmation>
    <app-button-feedback
      [_setId]="'btnDeleteOrganisation'"
      (click)="deleteOrganisation()"
      [_defaultButtonText]="'admin-dashboard.delete_organisation_confirm'"
      [_responseText]="deleteOrganisationResponse | async"
    ></app-button-feedback>
  </ng-template>
  <ng-template #elseShowNext>
    <button [attr.id]="'btnShowConfirmation'" mat-button (click)="showConfirmation()">{{'admin-dashboard.next' | translate}}</button>
  </ng-template>
</div>
