import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, switchMap, take, takeWhile } from 'rxjs/operators';
import { ErrorInterface } from '../../../models/interface/error.interface';
import { CustomCookieService } from '../../customcookie/custom-cookie.service';
import { Action, Store } from '@ngrx/store';
import { ProfileInterface } from '../../../models/interface/profile.interface';
import { AuthenticationActionTypes, forcedLogoutStore, RefreshToken } from '../../../store/authentication/authentication.action';
import { Actions, ofType } from '@ngrx/effects';

/**
 * Service - Interceptor:
 * Refresh token interceptor that intercepts http requests, adds JWT to request query and automatically refreshes expired JWTs.
 */

@Injectable({
  providedIn: 'root'
})
export class RefreshTokenInterceptorService implements HttpInterceptor {
  private refreshTokenInProgressSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private hasTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private customCookieService: CustomCookieService,
    private actions$: Actions,
    private store: Store<{ myProfile: ProfileInterface }>
  ) {}

  refreshToken(req, error, next): Observable<any> {
    if (this.refreshTokenInProgressSubject.getValue()) {
      return this.hasTokenSubject.pipe(
        filter(value => value !== null),
        take(1),
        switchMap(() => next.handle(this.addAuthenticationToken(req)))
      );
    } else {
      this.refreshTokenInProgressSubject.next(true);
      this.hasTokenSubject.next(null);
      this.store.dispatch(new RefreshToken());
      return this.actions$.pipe(
        ofType(AuthenticationActionTypes.refreshTokenSuccessType, AuthenticationActionTypes.refreshTokenErrorType),
        take(1),
        switchMap((action: Action) => {
          if (action.type === AuthenticationActionTypes.refreshTokenSuccessType) {
            const responseToken = action['response']['body']['data']['attributes']['token'];
            if (responseToken) {
              this.customCookieService.deleteJWTCookie();
              this.customCookieService.setJWTCookie(responseToken);
              this.hasTokenSubject.next(responseToken);
              this.refreshTokenInProgressSubject.next(false);
              return of(responseToken);
            } else {
              console.error('New token from response not found...');
              return throwError(action['response']);
            }
          } else {
            this.refreshTokenInProgressSubject.next(false);
            console.error('Token refresh failed...');
            return throwError(action['response']);
          }
        }),
        catchError((errMessage: any) => {
          console.error('Refresh token failed...', errMessage);
          return throwError(errMessage);
        })
      );
    }
  }

  /**
   * This function adds JWT to the request.
   *
   * @params HttpRequest<any> req - Any incoming HttpRequest.
   * @return HttpRequest<any> - An HttpRequest with JWT.
   */
  addAuthenticationToken(req: HttpRequest<any>): HttpRequest<any> {
    if (
      !(
        req.url.includes('/auth/login') ||
        (req.url.includes('/auth/register') && !req.url.includes('/ecoach/auth/register')) ||
        req.url.includes('/auth/verify/resend') ||
        req.url.includes('/auth/password/reset/instructions') ||
        req.url.includes('/auth/password/reset') ||
        req.url.includes('/assets/i18n/')
      )
    ) {
      const token = this.customCookieService.getJWTCookie();
      if (token) {
        return req.clone({
          headers: req.headers.set('Authorization', `Bearer ${token}`)
        });
      }
    }
    return req;
  }

  /**
   * This function intercepts any incoming request and handles token refreshing and error.
   *
   * @params HttpRequest<any> req - Any incoming HttpRequest.
   *         HttpHandler next - HttpRequest transformation.
   * @return Observable<HttpEvent> - An observable for any HttpEvent.
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Fix assets null.json
    if (req.url.includes('/assets/i18n/')) {
      const language = localStorage.getItem('language') ? localStorage.getItem('language') : 'de';
      const uri = req.url.replace('null', language);
      if (req.url.includes('null') || req.url.includes('undefined')) {
        const replaceduri = uri.replace('null', language).replace('undefined', 'de');
        req = req.clone({
          url: replaceduri
        });
      }
    }

    return next.handle(this.addAuthenticationToken(req)).pipe(
      takeWhile(() => !this.refreshTokenInProgressSubject.getValue() || req.url.includes('/refresh') || req.url.includes('/assets/i18n/')),
      catchError((error: HttpErrorResponse | any) => {
        console.error('Error intercepted:', error);

        // Redirect to home page
        if (error.statusText && error.statusText.toString() === 'Unknown Error') {
          return throwError(error);
        }
        if (typeof error.error !== 'string' && error.error && error instanceof HttpErrorResponse && error.error.errors) {
          const errorData: ErrorInterface = error.error.errors[0];

          // Catching ngx-translate 404 error
          if (error.status.toString() === '404') {
            return throwError(error);
          }

          if (errorData.status.toString() === '401' && errorData.detail === 'Token has expired') {
            return this.refreshToken(req, error, next).pipe(switchMap(() => next.handle(this.addAuthenticationToken(req))));
          } else {
            // Blacklisted token or invalid authorization header
            console.log(errorData, req.url);
            if (
              errorData.status.toString() === '409' &&
              (errorData.detail === 'The token has been blacklisted' ||
                errorData.detail === 'The provided token has blacklisted. Re-login to get a new token.') &&
              req.url.includes('/refresh')
            ) {
              console.log('Refreshed token is blacklisted');
              this.store.dispatch(forcedLogoutStore());
              this.refreshTokenInProgressSubject.next(false);
              this.hasTokenSubject.next(null);
              return throwError(error);
            }

            if (
              errorData.status.toString() === '401' &&
              (errorData.detail === 'Token Signature could not be verified.' ||
                errorData.detail === 'Wrong number of segments' ||
                errorData.detail === 'Failed to authenticate because of bad credentials or an invalid authorization header.' ||
                errorData.detail === 'Malformed token')
            ) {
              this.store.dispatch(forcedLogoutStore());
              this.refreshTokenInProgressSubject.next(false);
              this.hasTokenSubject.next(null);
              return throwError(error);
            }

            if (errorData.status.toString() === '403' || errorData.detail === 'You are not allowed to access this route or entity.') {
              // this.router.navigate(['/error/403']);
              return throwError(error);
            }

            if (
              errorData.status.toString() === '400' &&
              errorData.detail === 'The provided token has expired. Re-login to get a new token.'
            ) {
              this.store.dispatch(forcedLogoutStore());
              this.refreshTokenInProgressSubject.next(false);
              this.hasTokenSubject.next(null);
              return throwError(error);
            }

            console.error(errorData.status + ' ' + errorData.detail);
            return throwError(error);
          }
        } else {
          return throwError(error);
        }
      }),
      catchError((error: any) => {
        console.error('intercept', error);
        return throwError(error);
      })
    );
  }
}
