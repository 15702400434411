<div class="container-fluid text-center">
  <div class="col-sm-8 text-start">
    <h2>{{ 'intervention-diary.diaries' | translate }}</h2>
    <hr />
  </div>

  <div class="row justify-content-center">
    <div class="col-12 col-md-6 mb-2">
      <mat-form-field [style.width.%]="100" appearance="outline">
        <mat-label>{{'intervention-diary.search_diary' | translate}}</mat-label>
        <input
          [attr.id]="'search'"
          (keyup.enter)="applyDiaryFilter()"
          [(ngModel)]="filter['searchFilter']"
          aria-describedby="interventionSearch"
          aria-label="Search"
          matInput
          type="text"
        />
        <button [attr.id]="'btnSearchDiary'" (click)="applyDiaryFilter()" aria-label="Search" mat-button mat-button matSuffix>
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>

  <app-pagination-list
    #paginator
    (emitPageChange)="updatePagedDiaries($event)"
    [_isLoading]="isLoading$ | async"
    [_items]="diaries$ | async"
  ></app-pagination-list>

  <div class="row justify-content-center">
    <div *ngIf="(isLoading$ | async); else elseInterventionBlock" class="col-sm">
      <app-loader></app-loader>
    </div>

    <ng-template #elseInterventionBlock>
      <div class="row col-12 col-sm-8">
        <div *ngFor="let diary of pagedDiaries$ | async; let i = index; trackBy: helper.trackByElement" class="col-12 col-lg-6">
          <app-card-diary [_diary]="diary"></app-card-diary>
        </div>
      </div>
    </ng-template>
  </div>
</div>
