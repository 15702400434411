import { Action, createAction, props } from '@ngrx/store';
import { AnswersheetAnswerInterface } from 'src/app/models/interface/answersheet-answer.interface';

export enum DynamicAnswersActionTypes {
  getDynamicAnswersType = 'Get Dynamic Answers Store',
  getDynamicAnswersSuccessType = '[Success] Get Dynamic Answers Store',
  getDynamicAnswersErrorType = '[Error] Get Dynamic Answers Store',
  addDynamicAnswersType = 'Add Dynamic Answers Store',
  addDynamicAnswersSuccessType = '[Success] Add Dynamic Answers Store',
  addDynamicAnswersErrorType = '[Error] Add Dynamic Answers Store',
  updateDynamicAnswersType = 'Update Dynamic Answers Store',
  updateDynamicAnswersSuccessType = '[Success] Update Dynamic Answers Store',
  updateDynamicAnswersErrorType = '[Error] Update Dynamic Answers Store',
  removeDynamicAnswersType = 'Delete Dynamic Answers Store',
  removeDynamicAnswersSuccessType = '[Success] Delete Dynamic Answers Store',
  removeDynamicAnswersErrorType = '[Error] Delete Dynamic Answers Store',
  setDynamicAnswersStore = 'Set Dynamic Answers Store'
}

export const addDynamicAnswersStore = createAction(
  DynamicAnswersActionTypes.setDynamicAnswersStore,
  props<{ dynamicAnswers: Array<AnswersheetAnswerInterface> }>()
);
export const addDynamicAnswersSuccess = createAction(DynamicAnswersActionTypes.addDynamicAnswersSuccessType);
export const addDynamicAnswersError = createAction(DynamicAnswersActionTypes.addDynamicAnswersErrorType);

export const updateDynamicAnswersStore = createAction(
  DynamicAnswersActionTypes.updateDynamicAnswersType,
  props<{ dynamicAnswers: Array<AnswersheetAnswerInterface> }>()
);
export const removeDynamicAnswersStore = createAction(DynamicAnswersActionTypes.updateDynamicAnswersType);

export class AddDynamicAnswersAction implements Action {
  readonly type: string = DynamicAnswersActionTypes.addDynamicAnswersType;
  constructor(readonly payload: any) {}
}

export class UpdateDynamicAnswersAction implements Action {
  readonly type: string = DynamicAnswersActionTypes.updateDynamicAnswersType;
  constructor(readonly payload: any) {}
}

export type All = AddDynamicAnswersAction | UpdateDynamicAnswersAction;
