import { AfterViewInit, Component, Inject, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SkillPreviewComponent } from '../../questionnaire/skill-preview/skill-preview.component';

@Component({
  selector: 'app-dialog-skill-elements',
  templateUrl: './dialog-skill-elements.component.html',
  styleUrls: ['./dialog-skill-elements.component.scss']
})
export class DialogSkillElementsComponent implements OnInit, AfterViewInit {
  @ViewChild('skillPreviewContainer', { read: ViewContainerRef }) skillPreviewContainer: ViewContainerRef;

  public skillPreviewComponentClass = SkillPreviewComponent;
  public components;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    this.components = [];
    if (this.skillPreviewContainer) {
      this.skillPreviewContainer.clear();
    }
  }

  ngAfterViewInit(): void {
    const component = this.skillPreviewContainer.createComponent<SkillPreviewComponent>(this.skillPreviewComponentClass).instance;
    component._skill = this.data.skill;
    component._intervention = this.data.intervention;
    this.components.push(component);
  }
}
