<button [attr.id]="'btnDialogClose'" mat-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title>{{'intervention-collaborator.collaborators_remove_modal_title' | translate:param}}</h1>
<div mat-dialog-content class="mat-typography">
  {{'intervention-collaborator.collaborators_remove_modal_body' | translate}}
  <p>
    <strong
      >{{collaborator.attributes.firstname}} {{collaborator.attributes.lastname}}
      <span *ngIf="collaborator.attributes.name">({{collaborator.attributes.name}})</span></strong
    >
  </p>
  <p><strong>{{collaborator.attributes.email}}</strong></p>
  <p *ngIf="isNotRemovableSubject | async" class="text-danger">
    {{'intervention-collaborator.cannot_remove_collaborator_instances' | translate }}
  </p>
</div>

<div mat-dialog-actions align="end">
  <button [attr.id]="'btnCancel'" mat-button mat-dialog-close>{{'intervention-collaborator.cancel' | translate}}</button>
  <app-button-feedback
    [_setId]="'btnRemoveCollaborator_' + collaborator.id"
    (click)="removeCollaborator(collaborator.id)"
    [_defaultButtonText]="'intervention-collaborator.collaborators_remove'"
    [_responseText]="removeCollaboratorResponse | async"
  ></app-button-feedback>
</div>
