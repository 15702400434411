<div *ngIf="(evaluation$ | async)" [class.required]="required" [hidden]="hidden$ | async" class="question">
  <form [formGroup]="questionTableForm">
    <div class="table-responsive">
      <table class="table table-borderless">
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col" *ngFor="let option of options">{{option}}</th>
          </tr>
        </thead>
        <tbody>
          <tr class="mb-3" *ngFor="let q of questionsSubject | async; index as i">
            <th scope="row">{{q}}</th>
            <ng-container formArrayName="selections">
              <td *ngFor="let val of range; index as j">
                <div class="form-check">
                  <input
                    type="radio"
                    [attr.id]="'radioId' + i + '_' + j"
                    [value]="val"
                    formControlName="{{i}}"
                    [attr.name]="i"
                    [attr.disabled]="isDisabled ? '' : null"
                    (change)="onChange()"
                  />
                </div>
              </td>
            </ng-container>
          </tr>
        </tbody>
      </table>
    </div>
  </form>
</div>
