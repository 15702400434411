<button [attr.id]="'btnDialogClose'" mat-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title>{{'intervention-collaborator.collaborators_add_modal_title' | translate:param}}</h1>
<div class="content-height" mat-dialog-content>
  <div>
    <mat-form-field class="full-width">
      <mat-label>{{'intervention-collaborator.my_collaborators' | translate}}</mat-label>
      <mat-select [attr.id]="'selectionCollaborator'" (click)="resetFilter()" [(ngModel)]="selectedCollaborator">
        <mat-form-field class="full-width" appearance="fill">
          <mat-label>{{'intervention-collaborator.filter_selection' | translate}}</mat-label>
          <input
            [attr.id]="'userSelection'"
            autocomplete="off"
            matInput
            (keyup)="helper.onKeyFilterUser(ecoaches, filter['userSelection'], ecoachesSubject, selectedCollaborator)"
            [(ngModel)]="filter['userSelection']"
          />
        </mat-form-field>
        <mat-option [attr.id]="'option_' + user.id" *ngFor="let user of (ecoaches$ | async)" [value]="user"
          >{{user?.attributes.email}}</mat-option
        >
      </mat-select>
    </mat-form-field>

    <p class="col">{{'intervention-collaborator.collaborators_select_role' | translate}}:</p>
    <div class="col text-start">
      <mat-radio-group
        [attr.id]="'radioSelectedRole'"
        [(ngModel)]="selectedRole"
        aria-labelledby="radio-group-label"
        class="roles-radio-group"
      >
        <mat-radio-button [attr.id]="'radioItem_' + role" *ngFor="let role of roles" [value]="role" class="roles-radio-button">
          {{helper.parseRoleSlug(role) | translate}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button [attr.id]="'btnCancel'" mat-button mat-dialog-close>{{'intervention-collaborator.cancel' | translate}}</button>
  <app-button-feedback
    [_setId]="'btnAddCollaborator'"
    (click)="addCollaborator()"
    [_defaultButtonText]="'intervention-collaborator.collaborators_add'"
    [_responseText]="addCollaboratorResponse | async"
    [_isDisabled]="isNoneSelectedAddCollaboratorForm()"
  ></app-button-feedback>
</div>
