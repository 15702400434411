<!-- Multiple choice component for lection/question elements in ionic app -->
<div *ngIf="(evaluation$ | async)" [class.required]="required" [hidden]="hidden$ | async" [ngClass]="{disabledItem:isDisabled}">
  <div class="row justify-content-center">
    <div class="col-12">
      <span>{{questionSubject | async}}</span>
    </div>
    <div class="col-12" *ngIf="answers.length === 0">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>{{'question-single-choice.select_option' | translate}}</mat-label>
        <mat-select [(ngModel)]="answer" (closed)="onChange($event)" multiple>
          <mat-option
            *ngFor="let v of element.values; index as i"
            [value]="v"
            [disabled]="(isDisabled && (answer?.toString() !== v.toString())) || (noAnswerProvided$ | async)"
            >{{setOptionTranslation(options[i])}}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-12" *ngIf="answers.length !== 0">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>{{'question-single-choice.select_option' | translate}}</mat-label>
        <mat-select [(ngModel)]="myAnswers" (selectionChange)="onChange($event)" multiple>
          <mat-option
            *ngFor="let v of element.values; index as i"
            [value]="v"
            [disabled]="(isDisabled && (answer?.toString() !== v.toString())) || (noAnswerProvided$ | async)"
            >{{setOptionTranslation(options[i])}}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div *ngIf="noAnswerProvided$ | async" class="text-danger mb-2">
    <small>{{'question-multiple-choice.no_answer_has_been_submitted' | translate}}</small>
  </div>
</div>
