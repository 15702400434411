import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons/faEllipsisH';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons/faFileCsv';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons/faFilePdf';
import { faRedo } from '@fortawesome/free-solid-svg-icons/faRedo';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, skip, Subscription, take } from 'rxjs';
import { HelperService } from '../../../services/helper/helper.service';
import { JobResultInterface } from '../../../models/interface/jobresult.interface';
import { QueueActionTypes } from '../../../store/queue/queue.action';

@Component({
  selector: 'app-dialog-downloads',
  templateUrl: './dialog-downloads.component.html',
  styleUrls: ['./dialog-downloads.component.scss']
})
export class DialogDownloadsComponent implements OnInit, OnDestroy {
  // Icons
  faRedo = faRedo;
  faTimes = faTimes;
  faEllipsisH = faEllipsisH;
  faFilePdf = faFilePdf;
  faFileCsv = faFileCsv;
  faClock = faClock;

  public isLoading$: Observable<boolean>;

  public submitted = false;

  public pagedJobAnswersheets$: Observable<Array<JobResultInterface>>;
  public pagedJobInstances$: Observable<Array<JobResultInterface>>;
  public pagedJobStudies$: Observable<Array<JobResultInterface>>;

  public jobresultsSubject: BehaviorSubject<Array<JobResultInterface>> = new BehaviorSubject<Array<JobResultInterface>>([]);
  public jobAnswersheetsSubject: BehaviorSubject<Array<JobResultInterface>> = new BehaviorSubject<Array<JobResultInterface>>([]);
  public jobInstancesSubject: BehaviorSubject<Array<JobResultInterface>> = new BehaviorSubject<Array<JobResultInterface>>([]);
  public jobStudiesSubject: BehaviorSubject<Array<JobResultInterface>> = new BehaviorSubject<Array<JobResultInterface>>([]);

  public jobAnswersheets$: Observable<Array<JobResultInterface>>;
  public jobInstances$: Observable<Array<JobResultInterface>>;
  public jobStudies$: Observable<Array<JobResultInterface>>;

  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private allJobResults$: Observable<Array<JobResultInterface>>;

  private pagedJobAnswersheetsSubject: BehaviorSubject<Array<JobResultInterface>> = new BehaviorSubject<Array<JobResultInterface>>([]);
  private pagedJobInstancesSubject: BehaviorSubject<Array<JobResultInterface>> = new BehaviorSubject<Array<JobResultInterface>>([]);
  private pagedJobStudiesSubject: BehaviorSubject<Array<JobResultInterface>> = new BehaviorSubject<Array<JobResultInterface>>([]);

  private subscriptions: Subscription[] = [];

  constructor(private store: Store<{ allJobResults: Array<JobResultInterface> }>, private helperService: HelperService) {
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.allJobResults$ = store.select('allJobResults');
    this.jobAnswersheets$ = this.jobAnswersheetsSubject.asObservable();
    this.jobInstances$ = this.jobInstancesSubject.asObservable();
    this.jobStudies$ = this.jobStudiesSubject.asObservable();
  }

  public get helper() {
    return this.helperService;
  }

  ngOnInit(): void {
    this.pagedJobAnswersheets$ = this.pagedJobAnswersheetsSubject.asObservable();
    this.pagedJobInstances$ = this.pagedJobInstancesSubject.asObservable();
    this.pagedJobStudies$ = this.pagedJobStudiesSubject.asObservable();
    this.loadJobResults();
  }

  public loadJobResults(): void {
    this.store.dispatch({ type: QueueActionTypes.getAllJobResultsOfUserType, payload: {} });
    this.allJobResults$.pipe(skip(1), take(1)).subscribe((result: any) => {
      const allJobs: Array<JobResultInterface> = result;
      this.jobAnswersheetsSubject.next(
        allJobs.filter((job: JobResultInterface) => job.attributes.type === 'Answersheet').sort((a, b) => b.id - a.id)
      );
      this.jobInstancesSubject.next(
        allJobs.filter((job: JobResultInterface) => job.attributes.type === 'Intervention_Instance').sort((a, b) => b.id - a.id)
      );
      this.jobStudiesSubject.next(allJobs.filter((job: JobResultInterface) => job.attributes.type === 'Study').sort((a, b) => b.id - a.id));
      this.jobresultsSubject.next(allJobs.sort((a, b) => b.id - a.id));

      this.pagedJobAnswersheetsSubject.next(this.jobAnswersheetsSubject.value.slice(0, 20));
      this.pagedJobInstancesSubject.next(this.jobInstancesSubject.value.slice(0, 20));
      this.pagedJobStudiesSubject.next(this.jobStudiesSubject.value.slice(0, 20));
      this.isLoadingSubject.next(false);
    });
  }

  public updatePagedJobAnswersheets(event: any) {
    if (event) {
      this.pagedJobAnswersheetsSubject.next(event);
    }
  }

  public updatePagedJobInstances(event: any) {
    if (event) {
      this.pagedJobInstancesSubject.next(event);
    }
  }

  public updatePagedJobStudies(event: any) {
    if (event) {
      this.pagedJobStudiesSubject.next(event);
    }
  }

  public trackByJobId(index: number, element: any): number {
    return element.id;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
