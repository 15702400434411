import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, Observable, skip, Subscription, take } from 'rxjs';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { InvitationInterface } from '../../../models/interface/invitation.interface';
import { HelperService } from '../../../services/helper/helper.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { StudyStore } from '../../../store/study/component-store/study.store';
import { StudyInterface } from '../../../models/interface/study/study.interface';

@Component({
  selector: 'app-dialog-group-member-invitation-check',
  templateUrl: './dialog-group-member-invitation-check.component.html',
  styleUrls: ['./dialog-group-member-invitation-check.component.scss'],
  providers: [StudyStore]
})
export class DialogGroupMemberInvitationCheckComponent implements OnInit, OnDestroy {
  @ViewChild('paginator') paginator;

  // Icons
  faSearch = faSearch;
  faInfoCircle = faInfoCircle;
  faEnvelope = faEnvelope;
  faCheckCircle = faCheckCircle;
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;
  faEdit = faEdit;

  public isManager: boolean;

  public study: StudyInterface;

  public pendingInvitations$: Observable<Array<InvitationInterface>>;
  public pendingInvitationsSubject: BehaviorSubject<Array<InvitationInterface>> = new BehaviorSubject<Array<InvitationInterface>>([]);
  public pagedInvitationsSubject: BehaviorSubject<Array<InvitationInterface>> = new BehaviorSubject<Array<InvitationInterface>>([]);
  public pendingInvitationCode: Array<string> = [];

  public isLoading$: Observable<boolean>;

  private studyInvitationsECoach$: Observable<any>;

  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private subscriptions: Subscription[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data, private studyStore: StudyStore, private helperService: HelperService) {
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.pendingInvitations$ = this.pendingInvitationsSubject.asObservable();
    this.studyInvitationsECoach$ = this.studyStore.studyInvitationsECoach$;
  }

  // Helper function
  public get helper() {
    return this.helperService;
  }

  ngOnInit(): void {
    this.isManager = this.data.isManager;
    this.study = this.data.study;

    if (this.isManager) {
      this.studyStore.getAllPendingStudyInvitations({ studyId: this.study.id });
    } else {
      this.studyStore.getEcoachStudyInvitations({ studyId: this.study.id });
    }

    this.subscriptions.push(
      this.studyInvitationsECoach$.pipe(skip(1), take(1)).subscribe(
        (result: any) => {
          this.pendingInvitationsSubject.next(result);
          const currentPageSize = this.paginator?.paginator ? this.paginator?.paginator.pageSize : 20;
          this.pagedInvitationsSubject.next(this.pendingInvitationsSubject.value.slice(0, currentPageSize));
          this.pendingInvitationCode = this.pagedInvitationsSubject.value.map(
            (invitation: InvitationInterface) => invitation.attributes.code
          );
          this.isLoadingSubject.next(false);
        },
        () => {
          this.isLoadingSubject.next(false);
        }
      )
    );
  }

  public removeInvitation(index: number): void {
    this.pendingInvitationsSubject.value.splice(index, 1);
    const currentPageSize = this.paginator.paginator ? this.paginator.paginator.pageSize : 20;
    this.pagedInvitationsSubject.next(this.pendingInvitationsSubject.value.slice(0, currentPageSize));
    this.pendingInvitationCode = this.pagedInvitationsSubject.value.map((invitation: InvitationInterface) => invitation.attributes.code);
    this.isLoadingSubject.next(false);
  }

  public updateInvitation(index: number, invitation: InvitationInterface): void {
    if (this.isManager) {
      this.studyStore.getAllPendingStudyInvitations({ studyId: this.study.id });
    } else {
      this.studyStore.getEcoachStudyInvitations({ studyId: this.study.id });
    }

    this.subscriptions.push(
      this.studyInvitationsECoach$.pipe(skip(1), take(1)).subscribe((result: any) => {
        const pendingInvitations = result;
        const found = pendingInvitations.find((value: InvitationInterface) => value.id.toString() === invitation.id.toString());
        if (found && this.paginator.paginator) {
          const startIndex = this.paginator.paginator.pageIndex * this.paginator.paginator.pageSize;
          const endIndex = this.paginator.paginator.pageIndex + 1 * this.paginator.paginator.pageSize;
          this.pagedInvitationsSubject.next(this.pendingInvitationsSubject.value.slice(startIndex, endIndex));
          this.pendingInvitationCode = this.pagedInvitationsSubject.value.map((inv: InvitationInterface) => inv.attributes.code);
        }
      })
    );
  }

  public updatePagedInvitations(event: any) {
    if (event) {
      this.pagedInvitationsSubject.next(event);
      this.pendingInvitationCode = this.pagedInvitationsSubject.value.map((invitation: InvitationInterface) => invitation.attributes.code);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
