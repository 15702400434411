/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faHands } from '@fortawesome/free-solid-svg-icons/faHands';
import { faHandsHelping } from '@fortawesome/free-solid-svg-icons/faHandsHelping';
import { InterventionInstanceInterface } from '../../../models/interface/intervention-instances/intervention-instance.interface';
import { InterventionInterface } from '../../../models/interface/intervention.interface';
import { HelperService } from '../../../services/helper/helper.service';
import { UserInterface } from '../../../models/interface/user.interface';
import { StudyInterface } from '../../../models/interface/study/study.interface';

@Component({
  selector: 'app-patient-creation-study-invite',
  templateUrl: './patient-creation-intervention-selection.component.html',
  styleUrls: ['./patient-creation-intervention-selection.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PatientCreationInterventionSelectionComponent implements OnInit {
  // Icons
  faExclamationCircle = faExclamationCircle;
  faHandsHelping = faHandsHelping;
  faHands = faHands;

  public isModalLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public isModalLoading$: Observable<boolean>;

  // Intervention selection interventionsForm
  public interventionsForm: UntypedFormGroup;

  public studies: Array<StudyInterface> = [];

  // Form submission
  public submitted = false;

  public isInitializing = true;

  public selectedInterventionSubject: BehaviorSubject<
    Array<{
      study_id: number;
      interventions: Array<InterventionInterface>;
      collaborators: Array<UserInterface>;
    }>
  > = new BehaviorSubject([]);

  public isHiddenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isHidden$: Observable<boolean>;

  public studyAndInterventionSubject: BehaviorSubject<
    Array<{
      study_id: number;
      interventions: Array<InterventionInterface>;
      collaborators: Array<UserInterface>;
    }>
  > = new BehaviorSubject<
    Array<{
      study_id: number;
      interventions: Array<InterventionInterface>;
      collaborators: Array<UserInterface>;
    }>
  >([]);

  public instances: Array<InterventionInstanceInterface> = [];

  constructor(private formBuilder: UntypedFormBuilder, private helperService: HelperService) {
    this.isModalLoading$ = this.isModalLoadingSubject.asObservable();
    this.isHidden$ = this.isHiddenSubject.asObservable();
    this.interventionsForm = this.formBuilder.group({
      interventionList: this.formBuilder.array([])
    });
    this.addInterventionSelectionPerStudy();
  }

  public get fInterventionList(): UntypedFormArray {
    return this.interventionsForm.get('interventionList') as UntypedFormArray;
  }

  public get helper() {
    return this.helperService;
  }

  // Set study and its configuration from parent
  @Input() hide(_hide: boolean) {
    if (_hide !== undefined) {
      this.isHiddenSubject.next(_hide);
    }
  }

  @Input() initialized(_initialized: boolean) {
    if (_initialized) {
      this.isInitializing = false;
    }
  }

  @Input() studyIntervention(
    _studyIntervention: Array<{
      study_id: number;
      interventions: Array<InterventionInterface>;
      collaborators: Array<UserInterface>;
    }>
  ) {
    if (_studyIntervention !== undefined) {
      this.studyAndInterventionSubject.next(_studyIntervention);
    }
  }

  @Input() studySelection(_studies: Array<StudyInterface>) {
    if (_studies !== undefined) {
      this.studies = _studies;
    }
  }

  @Input() setInstances(_instances: Array<InterventionInstanceInterface>) {
    if (_instances !== undefined) {
      this.instances = _instances;
      this.isInitializing = false;
    }
  }

  public fStudyId(i: number) {
    return this.fInterventionList.controls[i].get('study_id');
  }

  public fStudyInterventions(i: number): UntypedFormArray {
    return this.fInterventionList.controls[i].get('study_interventions') as UntypedFormArray;
  }

  public fGroup(i: number): { study_id: number; study_interventions: Array<boolean> } {
    return { study_id: this.fStudyId(i).value, study_interventions: this.fStudyInterventions(i).value };
  }

  ngOnInit(): void {
    // Get hidden
    this.studyAndInterventionSubject.asObservable().subscribe(() => {
      this.addInterventionSelectionPerStudy();
    });
    this.isModalLoadingSubject.next(false);
  }

  public addInterventionSelectionPerStudy(): void {
    const control = this.interventionsForm.controls.interventionList as UntypedFormArray;
    (this.interventionsForm.controls.interventionList as UntypedFormArray).clear();
    this.studyAndInterventionSubject.value.forEach(
      (group: { study_id: number; interventions: Array<InterventionInterface>; collaborators: Array<UserInterface> }) => {
        const interventionsAsFormControl = group.interventions.map(() => this.formBuilder.control(null));
        control.push(
          new UntypedFormGroup({
            study_id: this.formBuilder.control(group.study_id),
            study_interventions: this.formBuilder.array(interventionsAsFormControl)
          })
        );
      }
    );
  }

  // Display selected studies
  public onCheckboxChange(): void {
    const resultInterventions: Array<{
      study_id: number;
      interventions: Array<InterventionInterface>;
      collaborators: Array<UserInterface>;
    }> = [];
    this.fInterventionList.value.forEach(
      (
        entry: {
          study_id: number;
          study_interventions: Array<boolean>;
        },
        index
      ) => {
        let selectedIds = [];
        selectedIds = selectedIds.concat(
          entry.study_interventions
            .map((v, i) => (v ? this.studyAndInterventionSubject.value[index].interventions[i].id : null))
            .filter(v => v !== null)
            .map(id => parseInt(id.toString(), 10))
        );
        const selectedInterventions = this.studyAndInterventionSubject.value[index].interventions.filter((inter: InterventionInterface) =>
          selectedIds.includes(parseInt(inter.id.toString(), 10))
        );
        resultInterventions.push({
          study_id: entry.study_id,
          interventions: selectedInterventions,
          collaborators: this.studyAndInterventionSubject.value[index].collaborators
        });
      }
    );
    this.selectedInterventionSubject.next(resultInterventions);
  }

  public checkIfOneSelected(entry: { study_id: number; study_interventions: Array<boolean> }): boolean {
    if (entry.study_interventions.length > 0) {
      return (
        entry.study_interventions.map(val => (val === null ? false : val)).reduce((val, curr) => val || curr) &&
        entry.study_interventions.length > 0
      );
    } else {
      return false;
    }
  }

  public getSelectedIntervention(i: number, l: number): Observable<InterventionInterface> {
    return of(this.studyAndInterventionSubject.value[i].interventions[l]);
  }

  public getIDCheckbox(i: number, l: number): Observable<string> {
    return of('checkInterventionSelection' + i + '_' + l + '-' + this.studyAndInterventionSubject.value[i].interventions[l].id);
  }

  public hasPendingInstances(i: number, l: number): boolean {
    const intervention: InterventionInterface = this.studyAndInterventionSubject.value[i].interventions[l];
    const nonPendingStates = ['canceled'];
    const instanceResult = this.instances.filter(
      (instance: InterventionInstanceInterface) =>
        nonPendingStates.indexOf(instance.attributes.progress.current_state.toLowerCase()) === -1 &&
        instance.attributes.intervention_id.toString() === intervention.id.toString()
    );
    return instanceResult.length > 0;
  }
}
