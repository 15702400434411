import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { mergeMap, skip, take } from 'rxjs/operators';
import { BehaviorSubject, forkJoin, iif, Observable, of, Subscription, throwError } from 'rxjs';
import { PayloadInterface } from '../../../models/interface/payload.interface';
import { RequestBodyData } from '../../../models/request-body.data';
import { HelperService } from '../../../services/helper/helper.service';
import { UserInterface } from '../../../models/interface/user.interface';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { StudyStore } from '../../../store/study/component-store/study.store';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-dialog-intervention-collaborator-edit',
  templateUrl: './dialog-intervention-collaborator-edit.component.html',
  styleUrls: ['./dialog-intervention-collaborator-edit.component.scss'],
  providers: [StudyStore]
})
export class DialogInterventionCollaboratorEditComponent implements OnInit, OnDestroy {
  public collaborator: UserInterface;
  public param;
  public studyId: number;
  public collaborators: Array<UserInterface>;

  // Filter
  public filter = {
    userSelection: ''
  };

  public collaboratorsSubject: BehaviorSubject<Array<UserInterface>> = new BehaviorSubject<Array<UserInterface>>([]);
  public collaborators$: Observable<Array<UserInterface>> = this.collaboratorsSubject.asObservable();

  // List of roles
  public roles = ['study.ecoachmanager', 'study.ecoach', 'study.access'];
  public selectedRole: string;

  public selectedCollaborator: UserInterface;

  public updateCollaboratorResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');

  private addCollaboratorsResponse$: Observable<any>;
  private deleteCollaboratorsResponse$: Observable<any>;

  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private actions$: Actions,
    private store: Store,
    private helperService: HelperService,
    private dialogRef: MatDialogRef<DialogInterventionCollaboratorEditComponent>,
    private studyStore: StudyStore
  ) {
    this.addCollaboratorsResponse$ = this.studyStore.addCollaboratorsResponse$;
    this.deleteCollaboratorsResponse$ = this.studyStore.deleteCollaboratorsResponse$;
  }

  public get helper() {
    return this.helperService;
  }

  ngOnInit(): void {
    this.collaborator = this.data.collaborator;
    this.param = this.data.param;
    this.studyId = this.data.studyId;
    this.collaborators = this.data.collaborators;
    const ecoaches = this.data.ecoaches;
    this.resetFilter();
  }

  // Get all (multiple) roles of the user
  public getRolesOfCollaborator(user: UserInterface) {
    return user.relationships.roles.data;
  }

  public onChangeCollaborator(collaborator: UserInterface): void {
    // Form to add an existing editor to an study with a role
    const roles = this.getRolesOfCollaborator(collaborator);
    if (this.helper.hasRoles(roles, /study\.access$/)) {
      this.selectedRole = this.roles[2];
    }

    if (this.helper.hasRoles(roles, /study\.ecoach$/)) {
      this.selectedRole = this.roles[1];
    }

    if (this.helper.hasRoles(roles, /study\.ecoachmanager$/)) {
      this.selectedRole = this.roles[0];
    }
  }

  public isNoneSelectedConfigureRoleForm(): boolean {
    return !this.selectedCollaborator && !this.selectedRole;
  }

  // Adding collaborator to study
  public updateCollaborator(): void {
    if (this.selectedCollaborator && this.selectedRole) {
      if (this.updateCollaboratorResponse.value === 'DEFAULT') {
        const removingRoles = this.roles.filter((role: string) => role !== this.selectedRole);
        const addRoles: Array<{ id: number; role: string }> = [];
        const removeRoles: Array<{ id: number; role: string }> = [];
        addRoles.push({ id: this.selectedCollaborator.id, role: this.selectedRole });
        removingRoles.forEach((role: string) => {
          removeRoles.push({ id: this.selectedCollaborator.id, role });
        });
        const payloadRemove: PayloadInterface = new RequestBodyData('users', { users: removeRoles });
        const payloadAdd: PayloadInterface = new RequestBodyData('users', { users: addRoles });

        const reqs = [];

        this.studyStore.addCollaborators({ studyId: this.studyId, payload: payloadAdd });
        reqs.push(
          this.addCollaboratorsResponse$.pipe(
            skip(1),
            take(1),
            mergeMap((result: any) => iif(() => result instanceof HttpResponse, of(result), throwError(result)))
          )
        );

        this.studyStore.deleteCollaborators({ studyId: this.studyId, payload: payloadRemove });
        reqs.push(
          this.deleteCollaboratorsResponse$.pipe(
            skip(1),
            take(1),
            mergeMap((result: any) => iif(() => result instanceof HttpResponse, of(result), throwError(result)))
          )
        );

        this.updateCollaboratorResponse.next('LOADING');
        this.subscriptions.push(
          forkJoin(reqs).subscribe(
            () => {
              this.updateCollaboratorResponse.next('SUCCESS');
            },
            () => {
              this.updateCollaboratorResponse.next('FAILURE');
              setTimeout(() => {
                this.updateCollaboratorResponse.next('DEFAULT');
              }, 2500);
            },
            () => {
              setTimeout(() => {
                this.updateCollaboratorResponse.next('DEFAULT');
                this.dialogRef.close('SUCCESS');
              }, 2500);
            }
          )
        );
      }
    }
  }

  public resetFilter() {
    this.filter = {
      userSelection: ''
    };
    this.collaboratorsSubject.next(this.collaborators);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
