/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, mergeMap, skip, switchMap, take } from 'rxjs/operators';
import { faCommentSlash } from '@fortawesome/free-solid-svg-icons/faCommentSlash';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faList } from '@fortawesome/free-solid-svg-icons/faList';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons/faCheckCircle';
import { faCircle } from '@fortawesome/free-regular-svg-icons/faCircle';
import { faComment } from '@fortawesome/free-regular-svg-icons/faComment';
import { InterventionInstanceInterface } from '../../../models/interface/intervention-instances/intervention-instance.interface';
import { HelperService } from '../../../services/helper/helper.service';
import { UserInterface } from '../../../models/interface/user.interface';
import { MessageThreadsInterface } from '../../../models/interface/message_threads.interface';
import { LessonInterface } from '../../../models/interface/lesson.interface';
import { AnswersheetInterface } from '../../../models/interface/answersheet.interface';
import { HelperFeedbackService } from '../../../services/helper/helper-feedback/helper-feedback.service';
import { HelperDialogService } from '../../../services/helper/helper-dialog/helper-dialog.service';
import { AnswersheetActionTypes } from '../../../store/answersheet/answersheet.action';
import { Store } from '@ngrx/store';
import { InterventionInstanceActionTypes } from '../../../store/intervention-instance/intervention-instance.action';
import { LessonQuestionnaireActionTypes } from '../../../store/lesson-questionnaire/lesson-questionnaire.action';
import { UserActionTypes } from '../../../store/user/user.action';
import { MessageStore } from '../../../store/message/component-store/message.store';

/**
 * Component:
 * Answersheets page displaying a list of answer sheets;
 * Can be found: {uri}/feedback-overview/answersheets
 */

@Component({
  selector: 'app-answersheets',
  templateUrl: './answersheets.component.html',
  styleUrls: ['./answersheets.component.scss'],
  providers: [MessageStore]
})
export class AnswersheetsComponent implements OnInit, OnDestroy {
  @ViewChild('paginator') paginator;

  // Icons
  faList = faList;
  faInfoCircle = faInfoCircle;
  faComment = faComment;
  faCommentSlash = faCommentSlash;
  faCheckCircle = faCheckCircle;
  faCircle = faCircle;
  faEnvelope = faEnvelope;

  // Filter
  public sortCreation = ['answersheets.from_newest', 'answersheets.from_oldest'];
  public filter = {
    filterPendingActions: true,
    sortCreatedDate: this.sortCreation[0],
    isFBRequired: false,
    feedbackFilter: '2',
    searchFilter: ''
  };

  public isLoading$: Observable<boolean>;
  public pagedAnswersheets$: Observable<Array<AnswersheetInterface>>;
  public answersheets$: Observable<Array<AnswersheetInterface>>;
  public isCollapse$: Observable<boolean>;

  public myInstances: Array<InterventionInstanceInterface> = [];

  public users: Array<UserInterface> = [];
  public questionnaires: Array<LessonInterface> = [];
  public threads: Array<MessageThreadsInterface> = [];

  // Translation
  public param = { result_value: 0 };
  public interventionName = { intervention_name: '...' };

  public answersheetsContent$: Observable<Array<AnswersheetInterface>>;
  public answersheetsSubject: BehaviorSubject<Array<AnswersheetInterface>> = new BehaviorSubject<Array<AnswersheetInterface>>([]);

  public isCollapseSubject = new BehaviorSubject<boolean>(true);

  private answersheets: Array<AnswersheetInterface> = [];
  private initialAnswersheets: Array<AnswersheetInterface> = [];

  private allInstancesForECoach$: Observable<Array<InterventionInstanceInterface>>;
  private allQuestionnaires$: Observable<Array<LessonInterface>>;
  private inboxFolderAnswersheet$: Observable<Array<MessageThreadsInterface>>;
  private myMembers$: Observable<Array<UserInterface>>;

  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private pagedAnswersheetSubject: BehaviorSubject<Array<AnswersheetInterface>> = new BehaviorSubject<Array<AnswersheetInterface>>([]);
  private searchTextSubject = new BehaviorSubject<string>('');

  private subscriptions: Subscription[] = [];

  constructor(
    private helperService: HelperService,
    private helperDialogService: HelperDialogService,
    private messageStore: MessageStore,
    private router: Router,
    private store: Store<{
      answersheetsOfInterventionInstances: Array<AnswersheetInterface>;
      allInstancesForECoach: Array<InterventionInstanceInterface>;
      allQuestionnaires: Array<LessonInterface>;
      myMembers: Array<UserInterface>;
    }>,
    private helperFeedbackService: HelperFeedbackService
  ) {
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.pagedAnswersheets$ = this.pagedAnswersheetSubject.asObservable();
    this.isCollapse$ = this.isCollapseSubject.asObservable();
    this.answersheets$ = this.store.select('answersheetsOfInterventionInstances');
    this.allInstancesForECoach$ = this.store.select('allInstancesForECoach');
    this.allQuestionnaires$ = this.store.select('allQuestionnaires');
    this.inboxFolderAnswersheet$ = this.messageStore.inboxFolderAnswersheet$;
    this.myMembers$ = this.store.select('myMembers');
    this.answersheetsContent$ = this.answersheetsSubject.asObservable();
  }

  public get helper() {
    return this.helperService;
  }

  public get helperDialog() {
    return this.helperDialogService;
  }

  public get helperFeedback() {
    return this.helperFeedbackService;
  }

  ngOnInit(): void {
    this.messageStore.getInboxFolder({ operator: '>', answersheetId: 0, answersheetIdFlag: true });
    this.subscriptions.push(
      this.inboxFolderAnswersheet$.subscribe((result: any) => {
        this.threads = result;
      })
    );
    this.subscriptions.push(
      this.myMembers$.subscribe((result: any) => {
        this.users = result;
      })
    );
    this.subscriptions.push(
      this.allInstancesForECoach$.subscribe((result: any) => {
        this.myInstances = result;
      })
    );
    this.applyFilter();
  }

  public applyFilter(): void {
    this.isLoadingSubject.next(true);
    this.subscriptions.push(
      this.reloadAnswersheetList(true).subscribe((result: any) => {
        this.isLoadingSubject.next(false);
      })
    );
  }

  public updatePagedAnswersheets(event: any) {
    if (event) {
      this.pagedAnswersheetSubject.next(event);
    }
  }

  public reloadAnswersheetList(setFirst?: boolean): Observable<boolean> {
    this.store.dispatch({
      type: UserActionTypes.getMyMembersType,
      payload: {}
    });
    this.store.dispatch({ type: InterventionInstanceActionTypes.getAllInstancesForECoachType, payload: {} });
    this.store.dispatch({ type: AnswersheetActionTypes.getAnswersheetsOfAllInterventionInstancesType });
    return this.answersheets$.pipe(
      skip(1),
      take(1),
      mergeMap((answersheets: Array<AnswersheetInterface>) => {
        this.answersheets = answersheets;
        if (this.filter['isFBRequired']) {
          this.answersheets = this.answersheets.filter((answersheet: AnswersheetInterface) =>
            this.helperFeedbackService.isFeedbackRequired(
              answersheet.id,
              this.getInterventionInstance(answersheet.attributes.intervention_instance_id)
            )
          );
        }
        this.store.dispatch({ type: LessonQuestionnaireActionTypes.getAllQuestionnairesType, payload: {} });
        return this.allQuestionnaires$.pipe(
          skip(1),
          take(1),
          mergeMap((res: Array<LessonInterface>) => of(res))
        );
      }),
      mergeMap((result: Array<LessonInterface>) => {
        this.questionnaires = result;
        this.initialAnswersheets = this.answersheets;
        return this.performFilterAndPagination(setFirst);
      }),
      catchError(error => {
        this.questionnaires = [];
        this.answersheets = [];
        this.initialAnswersheets = this.answersheets;
        return this.performFilterAndPagination(setFirst);
      })
    );
  }

  public updateList(): void {
    this.subscriptions.push(this.reloadAnswersheetList().subscribe());
  }

  public getAnswersheetDetails(id: number): void {
    this.router.navigateByUrl(`feedback-overview/answersheets/${id}`).then(() => {});
  }

  public getInterventionInstance(id: number): InterventionInstanceInterface {
    return this.helperService.findArrObjById(id, this.myInstances);
  }

  public getThreadByAnswersheet(answersheetId: number): MessageThreadsInterface {
    const feedbackFound: MessageThreadsInterface = this.threads.find(
      (value: MessageThreadsInterface) =>
        (value.attributes.answersheet_id !== null ? value.attributes.answersheet_id : '').toString() === answersheetId.toString()
    );
    return feedbackFound ? feedbackFound : null;
  }

  public openDialogInterventionInstanceDetails(instanceId: number): void {
    this.helperDialog
      .openDialogInterventionInstanceDetails(instanceId, this.param)
      .afterClosed()
      .subscribe(() => {
        this.store.dispatch({ type: InterventionInstanceActionTypes.getAllInstancesForECoachType, payload: {} });
        this.messageStore.getInboxFolder({ operator: '>', answersheetId: 0, answersheetIdFlag: true });
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  public performFilterAndPagination(setFirst: boolean): Observable<boolean> {
    return this.helper
      .searchAnswersheetsByInput(
        this.filter['searchFilter'],
        this.searchTextSubject,
        this.filterWithValues(),
        this.questionnaires,
        this.answersheetsSubject,
        this.users
      )
      .pipe(
        mergeMap(() => {
          const currentPageSize = this.paginator.paginator.pagesize ? this.paginator.paginator.pageSize : 20;
          this.pagedAnswersheetSubject.next(this.answersheetsSubject.value.slice(0, currentPageSize));
          this.param = { result_value: this.answersheetsSubject.value.length };
          this.helper.setPagedContent(
            this.answersheetsSubject,
            this.pagedAnswersheetSubject,
            setFirst,
            this.paginator.paginator.pageIndex,
            this.paginator.paginator.pageSize
          );
          return of(true);
        })
      );
  }

  private filterWithValues(): Array<AnswersheetInterface> {
    switch (this.filter['feedbackFilter']) {
      case '1': {
        this.initialAnswersheets = this.initialAnswersheets.filter((answersheet: AnswersheetInterface) =>
          this.helperFeedback.isUnread(
            answersheet.id,
            this.getInterventionInstance(answersheet.attributes.intervention_instance_id),
            this.getThreadByAnswersheet(answersheet.id)
          )
        );
        break;
      }
      case '0': {
        this.initialAnswersheets = this.initialAnswersheets.filter((answersheet: AnswersheetInterface) =>
          this.helperFeedback.isRead(
            answersheet.id,
            this.getInterventionInstance(answersheet.attributes.intervention_instance_id),
            this.getThreadByAnswersheet(answersheet.id)
          )
        );
        break;
      }
      default: {
        break;
      }
    }

    switch (this.filter['sortCreatedDate']) {
      case 'answersheets.from_newest': {
        this.initialAnswersheets = this.helperService.getAnswersheetsByNewest(this.initialAnswersheets);
        break;
      }
      case 'answersheets.from_oldest': {
        this.initialAnswersheets = this.helperService.getAnswersheetsByOldest(this.initialAnswersheets);
        break;
      }
      default: {
        break;
      }
    }

    switch (this.filter['filterPendingActions']) {
      case true: {
        this.initialAnswersheets = this.initialAnswersheets.filter(
          (answersheet: AnswersheetInterface) =>
            (this.helperFeedback.isFeedbackRequired(
              answersheet.id,
              this.getInterventionInstance(answersheet.attributes.intervention_instance_id)
            ) &&
              !this.helperFeedback.hasFeedback(answersheet.id, this.threads)) ||
            (this.getThreadByAnswersheet(answersheet.id)?.attributes.unread.is_unread &&
              this.getThreadByAnswersheet(answersheet.id)?.attributes.unread.messages > 0)
        );
        break;
      }
      default: {
        break;
      }
    }
    return this.initialAnswersheets;
  }
}
