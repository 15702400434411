import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of, Subscription, throwError, Subject } from 'rxjs';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faList } from '@fortawesome/free-solid-svg-icons/faList';
import { catchError, mergeMap, distinctUntilChanged, map, debounceTime, switchMap, take, skip } from 'rxjs/operators';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { HelperActivityService } from '../../../services/helper/helper-activity/helper-activity.service';
import { ActivityInterface } from '../../../models/interface/activity.interface';
import { TableSortInterface } from '../../../models/interface/table/table_sort.interface';
import { HelperService } from '../../../services/helper/helper.service';
import { UserInterface } from '../../../models/interface/user.interface';
import { StudyInterface } from '../../../models/interface/study/study.interface';
import { LessonInterface } from '../../../models/interface/lesson.interface';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { LessonQuestionnaireActionTypes } from '../../../store/lesson-questionnaire/lesson-questionnaire.action';
import { StudyActionTypes } from '../../../store/study/study.action';
import { UserActionTypes } from '../../../store/user/user.action';
import { UserStore } from '../../../store/user/component-store/user.store';

/**
 * Component:
 * Activity page displaying a list of activities;
 * Can be found: {uri}/activities
 */

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss'],
  providers: [UserStore]
})
export class ActivityComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  // Table
  public displayedColumns: string[] = ['activity_text', 'performed_by', 'created_at'];
  public dataSource: MatTableDataSource<ActivityInterface>;
  public tableSort: TableSortInterface = {
    sortActivityId: null,
    sortAction: null,
    sortActionId: null,
    sortText: null,
    sortPerformedBy: null,
    sortCreatedAt: null
  };

  // Icons
  public faList = faList;
  public faInfoCircle = faInfoCircle;

  // Filter
  public filter = {
    checkBoxStudy: false,
    checkboxAnswersheet: false,
    searchFilter: '',
    studyFilter: ''
  };
  public keyUp = new Subject<any>();

  // Dropdown - Selected list
  public selectedStudy: StudyInterface;
  public alternative = 'activity.no_studies_available';

  public isLoading$: Observable<boolean>;
  public isCollapse$: Observable<boolean>;
  public studiesSelection$: Observable<Array<StudyInterface>>;
  public tableContent$: Observable<Array<ActivityInterface>>;
  public showDescription$: Observable<boolean>;

  public members: Array<UserInterface> = [];
  public lessons: Array<LessonInterface> = [];

  public isCollapseSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public showDescriptionSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private studies: Array<StudyInterface> = [];

  private allQuestionnaires$: Observable<Array<LessonInterface>>;
  private myMembers$: Observable<Array<UserInterface>>;
  private myRegisteredUsers$: Observable<Array<UserInterface>>;
  private studyActivities$: Observable<Array<ActivityInterface>>;

  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private studiesSelectionSubject: BehaviorSubject<Array<StudyInterface>> = new BehaviorSubject([]);
  private showStudiesSubject: BehaviorSubject<Array<StudyInterface>> = new BehaviorSubject([]);
  private tableContentSubject: BehaviorSubject<Array<ActivityInterface>> = new BehaviorSubject([]);

  private subscriptions: Subscription[] = [];

  constructor(
    private helperService: HelperService,
    private helperActivityService: HelperActivityService,
    private actions$: Actions,
    private userStore: UserStore,
    private store: Store<{
      allQuestionnaires: Array<LessonInterface>;
      myMembers: Array<UserInterface>;
      myRegisteredUsers: Array<UserInterface>;
    }>
  ) {
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.isCollapse$ = this.isCollapseSubject.asObservable();
    this.studiesSelection$ = this.studiesSelectionSubject.asObservable();
    this.tableContent$ = this.tableContentSubject.asObservable();
    this.showDescription$ = this.showDescriptionSubject.asObservable();

    this.subscriptions.push(
      this.keyUp
        .pipe(
          map((event: any) => event.target.value),
          debounceTime(1000),
          distinctUntilChanged(),
          mergeMap(search => {
            this.applyActivityFilter();
            return of(search);
          })
        )
        .subscribe()
    );

    this.allQuestionnaires$ = this.store.select('allQuestionnaires');
    this.myMembers$ = this.store.select('myMembers');
    this.myRegisteredUsers$ = this.store.select('myRegisteredUsers');
    this.studyActivities$ = this.userStore.studyActivities$;
  }

  public get helper() {
    return this.helperService;
  }

  public get helperActivity() {
    return this.helperActivityService;
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.isLoading$.subscribe(() => {
        if (this.paginator) {
          this.paginator.firstPage();
        }
      })
    );

    this.store.dispatch({ type: UserActionTypes.getMyMembersType, payload: {} });
    this.subscriptions.push(
      this.myMembers$.subscribe((result: any) => {
        this.members = this.helper.setUniqueMembers(result, this.members);
      })
    );

    this.store.dispatch({
      type: UserActionTypes.getUsersRegisteredByEcoachType,
      payload: {}
    });
    this.subscriptions.push(
      this.myRegisteredUsers$.subscribe((result: any) => {
        this.members = this.helper.setUniqueMembers(result, this.members);
      })
    );

    this.store.dispatch({ type: LessonQuestionnaireActionTypes.getAllQuestionnairesType, payload: {} });
    this.subscriptions.push(
      this.allQuestionnaires$.pipe(mergeMap((res: Array<LessonInterface>) => of(res))).subscribe((result: any) => {
        this.lessons = result;
      })
    );

    this.store.dispatch({
      type: StudyActionTypes.getCollaboratingStudiesType,
      payload: { type: 'study', role: `study.owner,study.ecoachmanager,study.ecoach` }
    });
    this.subscriptions.push(
      this.actions$
        .pipe(
          ofType(StudyActionTypes.getCollaboratingStudiesSuccessType, StudyActionTypes.getCollaboratingStudiesErrorType),
          take(1),
          switchMap((action: any) => {
            if (action.type === StudyActionTypes.getCollaboratingStudiesSuccessType) {
              this.studies = action['response']['body']['data'];
              this.studiesSelectionSubject.next(this.studies);
              this.selectedStudy = this.studies[0];
              return this.reloadActivityList();
            } else {
              return throwError(action);
            }
          })
        )
        .subscribe(
          result => {
            this.isLoadingSubject.next(false);
          },
          () => {
            this.isLoadingSubject.next(false);
          }
        )
    );
  }

  // Display Activities of Selected Intervention
  public showSelected(): void {
    this.isLoadingSubject.next(true);
    this.isCollapseSubject.next(true);
    this.filter['checkboxStudy'] = false;
    this.filter['checkboxAnswersheet'] = false;
    this.filter['searchFilter'] = '';
    this.applyActivityFilter();
  }

  public applyActivityFilter(): void {
    this.isLoadingSubject.next(true);
    this.subscriptions.push(
      this.reloadActivityList().subscribe((result: any) => {
        this.isLoadingSubject.next(false);
      })
    );
  }

  public updateList(): void {
    this.subscriptions.push(this.reloadActivityList().subscribe());
  }

  // Get study name in dropdown list
  public displaySelect(selected: StudyInterface): string {
    return selected !== undefined ? selected.attributes.name : this.alternative;
  }

  public reloadActivityList(sort?: Sort): Observable<any> {
    this.userStore.getActivitiesStudy({ studyId: this.selectedStudy.id, include: 'questionnaires' });
    return this.studyActivities$.pipe(
      skip(1),
      take(1),
      switchMap((result: any) => {
        const filterArray = this.filterAndSort(result);
        const currentPageSize = this.paginator ? this.paginator.pageSize : 20;
        if (sort) {
          this.dataSource = this.sortData(sort, filterArray, this.tableContentSubject, this.lessons, this.members, this.tableSort);
        } else {
          this.dataSource = new MatTableDataSource<ActivityInterface>(filterArray.slice(0, currentPageSize));
          this.tableContentSubject.next(filterArray);
        }

        if (this.paginator) {
          this.paginator.length = filterArray.length;
          this.paginator.firstPage();
        }
        return of(true);
      }),
      catchError((error: any) => {
        this.tableContentSubject.next([]);
        return of(true);
      })
    );
  }

  public onPageChangeActivity(event: PageEvent): void {
    const filterArray: Array<ActivityInterface> = this.filterAndSort(this.tableContentSubject.value);
    const startIndex = event.pageIndex * event.pageSize;
    const endIndex = startIndex + event.pageSize > filterArray.length ? filterArray.length : startIndex + event.pageSize;
    if (this.paginator) {
      this.paginator.length = filterArray.length;
    }
    this.dataSource = this.sortData(this.sort, filterArray.slice(), this.tableContentSubject, this.lessons, this.members, this.tableSort);
    this.dataSource = new MatTableDataSource<ActivityInterface>(filterArray.slice(startIndex, endIndex));
    this.dataSource.sort = this.sort;
  }

  public onChangeSortTable(sort: Sort): void {
    this.reloadActivityList(sort).subscribe();
  }

  public onKeyFilter(): void {
    const filterResults: Array<StudyInterface> = this.studies.filter((study: StudyInterface) =>
      study.attributes.name.toLowerCase().includes(this.filter['studyFilter'].toLowerCase())
    );
    this.studiesSelectionSubject.next(filterResults.length !== 0 ? filterResults : [this.selectedStudy]);
  }

  public resetFilter(): void {
    this.filter['studyFilter'] = '';
    this.studiesSelectionSubject.next(this.studies);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  private sortData(
    sort: Sort,
    data: Array<ActivityInterface>,
    tableContentSubject: BehaviorSubject<Array<ActivityInterface>>,
    lessons: Array<LessonInterface>,
    members: Array<UserInterface>,
    tableSort: TableSortInterface
  ): MatTableDataSource<ActivityInterface> {
    const isDesc = sort.direction === 'desc';
    switch (sort.active) {
      case 'activity_text':
        tableContentSubject.next(
          data.sort((a: ActivityInterface, b: ActivityInterface) => {
            const behavior = new BehaviorSubject<number>(null);
            forkJoin([this.helperActivity.getActivityText(a), this.helperActivity.getActivityText(b)]).subscribe((activities: string[]) => {
              const act1 =
                a.attributes.action === 'ANSWERSHEET_SUBMITTED'
                  ? activities[0] + this.helperActivity.getLessonNameOfActivity(a, lessons)
                  : activities[0];
              const act2 =
                b.attributes.action === 'ANSWERSHEET_SUBMITTED'
                  ? activities[1] + this.helperActivity.getLessonNameOfActivity(b, lessons)
                  : activities[1];
              behavior.next(isDesc ? act2.localeCompare(act1) : act1.localeCompare(act2));
            });
            return behavior.value;
          })
        );
        break;
      case 'performed_by':
        this.helperActivityService.toggleSortPerformedBy(data, isDesc, tableContentSubject, members, tableSort);
        break;
      case 'created_at':
        this.helperActivityService.toggleSortCreated(data, isDesc, tableContentSubject, tableSort);
        break;
    }
    const currentPageSize = this.paginator ? this.paginator.pageSize : 20;
    return new MatTableDataSource<ActivityInterface>(tableContentSubject.value.slice(0, currentPageSize));
  }

  private filterAndSort(arr: Array<ActivityInterface>): Array<ActivityInterface> {
    this.tableContentSubject.next(arr);
    let filterArray: any = [];
    if (this.filter['checkboxStudy']) {
      filterArray = [...filterArray, ...arr.filter(e => e.attributes.action === 'STUDY_SUBSCRIBED')];
    }
    if (this.filter['checkboxAnswersheet']) {
      filterArray = [...filterArray, ...arr.filter(e => e.attributes.action === 'ANSWERSHEET_SUBMITTED')];
    }
    if (!(this.filter['checkboxAnswersheet'] || this.filter['checkboxStudy'])) {
      filterArray = arr;
    }
    filterArray = this.helper.filterActivitiesByUser(filterArray, this.filter['searchFilter'].toLowerCase().trim(), this.members);
    return filterArray;
  }
}
