/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable, QueryList } from '@angular/core';
import { MessageThreadsInterface } from '../../models/interface/message_threads.interface';
import { ActivityInterface } from '../../models/interface/activity.interface';
import { TranslateService } from '@ngx-translate/core';
import { AnswersheetInterface } from '../../models/interface/answersheet.interface';
import { environment } from '../../../environments/environment';
import { LessonInterface } from '../../models/interface/lesson.interface';
import { UserInterface } from '../../models/interface/user.interface';
import { IncludedInterface } from '../../models/interface/included.interface';
import { TypeIdInterface } from '../../models/interface/study/typeId.interface';
import { BehaviorSubject, distinctUntilChanged, iif, mergeMap, Observable, of, switchMap } from 'rxjs';
import { InterventionInstanceInterface } from '../../models/interface/intervention-instances/intervention-instance.interface';
import { StudyInterface } from '../../models/interface/study/study.interface';
import { InterventionInterface } from '../../models/interface/intervention.interface';
import { DiaryInterface } from '../../models/interface/diary.interface';
import { OrganisationInterface } from '../../models/interface/organisation/organisation.interface';
import { OrganisationCollaboratorInterface } from '../../models/interface/organisation/organisation-collaborator.interface';
import { DiaryInstanceInterface } from '../../models/interface/diary-instance.interface';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faPause } from '@fortawesome/free-solid-svg-icons/faPause';
import { faPlay } from '@fortawesome/free-solid-svg-icons/faPlay';
import { faRunning } from '@fortawesome/free-solid-svg-icons/faRunning';
import { faStop } from '@fortawesome/free-solid-svg-icons/faStop';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { BuddyActivityInterface } from '../../models/interface/buddy/buddy-activity.interface';
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import { PermissionInterface } from 'src/app/models/interface/permission.interface';
import { MatPaginator } from '@angular/material/paginator';
import { BuddyInstanceInterface } from 'src/app/models/interface/buddy/buddy-instance.interface';
import { RoleInterface } from 'src/app/models/interface/role.interface';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  // Icons
  faPause = faPause;
  faPlay = faPlay;
  faStop = faStop;
  faRunning = faRunning;
  faEnvelope = faEnvelope;
  faTimes = faTimes;
  faCheck = faCheck;

  private backendUrl: string = environment.backendURL;

  constructor(private translateService: TranslateService) {}

  public parseLanguage(code: string) {
    return `helper-service.${code}`;
  }

  public parseRoleSlug(slug: string) {
    switch (slug) {
      case 'study.owner':
        return 'helper-service.role_study_owner';
      case 'study.collaborator':
        return 'helper-service.role_study_collaborator';
      case 'study.ecoachmanager':
        return 'helper-service.role_study_ecoachmanager';
      case 'study.ecoach':
        return 'helper-service.role_study_ecoach';
      case 'study.access':
        return 'helper-service.role_study_access';
      case 'study.publisher':
        return 'helper-service.role_study_publisher';
      case 'organisation.owner':
        return 'helper-service.role_organisation_owner';
      case 'organisation.manager':
        return 'helper-service.role_organisation_manager';
      case 'organisation.access':
        return 'helper-service.role_organisation_access';
      default:
        return slug;
    }
  }

  public convertUnixTimestampToString(timestamp: number) {
    if (timestamp !== null) {
      const date = new Date(timestamp * 1000);
      const isValid = d => d instanceof Date && !isNaN(d.getTime());

      if (isValid(date)) {
        // Valid date
        return date.toUTCString();
      } else {
        // Invalid date
        return 'INVALID_DATE';
      }
    } else {
      return 'NO_TIMESTAMP_FOUND';
    }
  }

  public convertStringToUnixTimestamp(date: string) {
    return Math.round(new Date(date).getTime() / 1000);
  }

  // Returns unix timestamp
  public convertUnixTimestampToDate(unixTimestamp: number) {
    return new Date(unixTimestamp * 1000).toUTCString();
  }

  public getDateFromObjectString(input: string | { date: string; timezone_type: number; timezone: string }): string {
    if (input instanceof Object) {
      return input.date;
    } else {
      return input;
    }
  }

  public localizeDateString(input: string | { date: string; timezone_type: number; timezone: string }) {
    const date = this.getDateFromObjectString(input);
    const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' } as const;
    const timeOptions = { hour: '2-digit', minute: '2-digit' } as const;
    const locale = this.translateService.currentLang;
    const getLocalLanguage = () => {
      if (locale == null) {
        return 'de-DE';
      } else {
        return locale.toString() === 'en' ? 'en-GB' : 'de-DE';
      }
    };
    const resultDate = new Date(date);
    return (
      new Date(date).toLocaleDateString(getLocalLanguage(), dateOptions) +
      ' ' +
      resultDate.toLocaleTimeString(getLocalLanguage(), timeOptions)
    );
  }

  public localizeDateTimestamp(date: number): string {
    const mydate = this.convertUnixTimestampToString(date);
    const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' } as const;
    const timeOptions = { hour: '2-digit', minute: '2-digit' } as const;
    const locale = this.translateService.currentLang;
    const getLocalLanguage = () => {
      if (locale == null) {
        return 'de-DE';
      } else {
        return locale.toString() === 'en' ? 'en-GB' : 'de-DE';
      }
    };
    const resultDate = new Date(mydate);
    return (
      new Date(mydate).toLocaleDateString(getLocalLanguage(), dateOptions) +
      ' ' +
      resultDate.toLocaleTimeString(getLocalLanguage(), timeOptions)
    );
  }

  public localizeDate(date: number): string {
    const mydate = this.convertUnixTimestampToString(date);
    const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' } as const;
    const locale = this.translateService.currentLang;
    const getLocalLanguage = () => {
      if (locale == null) {
        return 'de-DE';
      } else {
        return locale.toString() === 'en' ? 'en-GB' : 'de-DE';
      }
    };
    return new Date(mydate).toLocaleDateString(getLocalLanguage(), dateOptions);
  }

  public getInboxWithNewestUnreadMessage(inbox: Array<MessageThreadsInterface>) {
    if (inbox.length !== 0) {
      const unreadThreads = inbox.filter(
        (box: MessageThreadsInterface) => box.attributes.unread.is_unread === true && box.attributes.unread.messages !== 0
      );

      return unreadThreads.sort(
        (a, b) =>
          Number(new Date(this.getDateFromObjectString(b.attributes.updated_at))) -
          Number(new Date(this.getDateFromObjectString(a.attributes.updated_at)))
      );
    } else {
      return [];
    }
  }

  public getInboxWithRecentUpdatedThreads(inbox: Array<MessageThreadsInterface>) {
    if (inbox.length !== 0) {
      return inbox.sort(
        (a, b) =>
          Number(new Date(this.getDateFromObjectString(b.attributes.updated_at))) -
          Number(new Date(this.getDateFromObjectString(a.attributes.updated_at)))
      );
    } else {
      return [];
    }
  }

  public getActivityByNewest(activities: Array<ActivityInterface>) {
    if (activities.length !== 0) {
      return activities.sort(
        (a, b) =>
          Number(new Date(this.getDateFromObjectString(b.attributes.created_at))) -
          Number(new Date(this.getDateFromObjectString(a.attributes.created_at)))
      );
    } else {
      return [];
    }
  }

  // Get multiple actions of activities and return a list of filtered activities
  public getActivityByAction(activities: Array<ActivityInterface>, ...actions: string[]) {
    if (activities !== undefined && activities.length !== 0) {
      if (actions.length !== 0) {
        let filteredActivities = [];
        actions.forEach(action => {
          const activityByAction = activities.filter(activity => activity.attributes.action === action);
          filteredActivities = filteredActivities.concat(activityByAction);
        });
        return filteredActivities;
      } else {
        return activities;
      }
    } else {
      return [];
    }
  }

  public getThreadByNewest(threads: Array<MessageThreadsInterface>) {
    if (threads.length !== 0) {
      return threads.sort(
        (a, b) =>
          Number(new Date(this.getDateFromObjectString(b.attributes.created_at))) -
          Number(new Date(this.getDateFromObjectString(a.attributes.created_at)))
      );
    } else {
      return [];
    }
  }

  public getThreadByOldest(threads: Array<MessageThreadsInterface>) {
    if (threads.length !== 0) {
      return threads.sort(
        (a, b) =>
          Number(new Date(this.getDateFromObjectString(a.attributes.created_at))) -
          Number(new Date(this.getDateFromObjectString(b.attributes.created_at)))
      );
    } else {
      return [];
    }
  }

  public getThreadByRecent(threads: Array<MessageThreadsInterface>) {
    if (threads.length !== 0) {
      return threads.sort(
        (a, b) =>
          Number(new Date(this.getDateFromObjectString(b.attributes.updated_at))) -
          Number(new Date(this.getDateFromObjectString(a.attributes.updated_at)))
      );
    } else {
      return [];
    }
  }

  public getAnswersheetsByNewest(answersheets: Array<AnswersheetInterface>) {
    if (answersheets.length !== 0) {
      return answersheets.sort((a, b) => Number(new Date(b.attributes.collected_at)) - Number(new Date(a.attributes.collected_at)));
    } else {
      return [];
    }
  }

  public getAnswersheetsByOldest(answersheets: Array<AnswersheetInterface>) {
    if (answersheets.length !== 0) {
      return answersheets.sort((a, b) => Number(new Date(a.attributes.collected_at)) - Number(new Date(b.attributes.collected_at)));
    } else {
      return [];
    }
  }

  public getAnswersheetByRecent(answersheets: Array<AnswersheetInterface>) {
    if (answersheets.length !== 0) {
      return answersheets.sort((a, b) => Number(new Date(b.attributes.collected_at)) - Number(new Date(a.attributes.collected_at)));
    } else {
      return [];
    }
  }

  public formatDateToYYYY_MM_DD(date): string {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return [year, month, day].join('-');
  }

  public formatDateToYYYY_MM_DD_THH_MM(date): string {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    let hour = '' + d.getHours();
    let minute = '' + d.getMinutes();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    if (hour.length < 2) {
      hour = '0' + hour;
    }
    if (minute.length < 2) {
      minute = '0' + minute;
    }
    return [year, month, day].join('-') + 'T' + hour + ':' + minute;
  }

  public formatDateToDD_MM_YYYY_HH_MM(date): string {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    let hour = '' + d.getHours();
    let minute = '' + d.getMinutes();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    if (hour.length < 2) {
      hour = '0' + hour;
    }
    if (minute.length < 2) {
      minute = '0' + minute;
    }
    return [day, month, year].join('-') + ' ' + hour + ':' + minute;
  }

  // Returns property as undefined if declared but no assigned a value
  public getSafe(fn, defaultValue?) {
    try {
      return fn();
    } catch (e) {
      // Return undefined as default value
      return defaultValue;
    }
  }

  public getNullorEmpty(objCheck: any, objExpected: any) {
    if (typeof objCheck === 'undefined') {
      if (Array.isArray(objExpected)) {
        return [];
      } else {
        return null;
      }
    } else {
      return objCheck;
    }
  }

  // Returns object by id from array
  public findArrObjById(id: number, arrayOf: Array<any>) {
    if (!id || !arrayOf) {
      return null;
    }
    const found = arrayOf.find(element => element.id.toString() === id.toString());
    if (found === undefined) {
      return null;
    }
    return found;
  }

  public getMediaPath(path: string) {
    if (!path) {
      return 'assets/pictures/default_no_image_picture.svg';
    }
    if (path.includes('https://aas2api.klips-ulm.de')) {
      const editedPath = path.substring(path.indexOf('uploads/'));
      return this.backendUrl.concat(editedPath.replace('https://aas2api.klips-ulm.de', ''));
    }
    if (path.includes('/var/www/html/live/public')) {
      const editedPath = path.substring(path.indexOf('uploads/'));
      return this.backendUrl.concat(editedPath.replace('/var/www/html/live/public', ''));
    }
    if (path.includes('uploads/studies/') && !path.includes(this.backendUrl)) {
      const editedPath = path.substring(path.indexOf('uploads/'));
      return this.backendUrl.concat('/' + editedPath);
    } else {
      return path;
    }
  }

  public getStudyMedia(url: string, studyId: number): string {
    if (!url) {
      return 'assets/pictures/default_no_image_picture.svg';
    } else {
      return this.backendUrl.concat(`/uploads/studies/${studyId}/${url}`);
    }
  }

  public getFallbackImage(event) {
    event.target.src = 'assets/pictures/default_no_image_picture.svg';
  }

  public getAllMediaSupportPath(url: string, studyId?: number): string {
    if (Number.isNaN(parseInt(url, 10))) {
      if (url) {
        if (!url.includes('uploads/studies')) {
          return this.getStudyMedia(url, studyId);
        } else {
          return this.getMediaPath(url);
        }
      } else {
        return this.getMediaPath(url);
      }
    } else {
      if (studyId) {
        return this.getStudyMedia(url, studyId);
      } else {
        return url;
      }
    }
  }

  public sortLessonsAscending(lessons: Array<LessonInterface>) {
    if (lessons.length > 1) {
      return lessons.sort(
        (a: LessonInterface, b: LessonInterface) =>
          parseInt(a.attributes.position.toString(), 10) - parseInt(b.attributes.position.toString(), 10)
      );
    } else {
      return lessons;
    }
  }

  public uniqueBy(array: Array<any>, key = JSON.stringify): Array<any> {
    const alreadyExist = {};
    return array.filter(item => (alreadyExist.hasOwnProperty(key(item)) ? false : (alreadyExist[key(item)] = true)));
  }

  public uniqueById(arr: Array<any>) {
    const sorted = arr.concat().sort();
    for (let i = 1; i < sorted.length; ) {
      if (sorted[i - 1].id === sorted[i].id) {
        sorted.splice(i, 1);
      } else {
        i++;
      }
    }
    return sorted;
  }

  public getCommonObjectsById(array: Array<any>, includedIds: IncludedInterface) {
    if (array) {
      return array.filter(
        (obj: any) => includedIds.data.findIndex((value: TypeIdInterface) => obj.id.toString() === value.id.toString()) !== -1
      );
    } else {
      return [];
    }
  }

  public getCommonObjectsByObjectId(array: Array<any>, objects: Array<any>) {
    if (array) {
      return array.filter((obj: any) => objects.findIndex((value: TypeIdInterface) => obj.id.toString() === value.id.toString()) !== -1);
    } else {
      return [];
    }
  }

  public getCommonIds(array: Array<number>, ids: Array<number>) {
    if (array) {
      return array.filter((obj: any) => ids.findIndex((value: number) => obj.toString() === value.toString()) !== -1);
    } else {
      return [];
    }
  }

  public excludeUsersWithOnlyStudyAccess(users: Array<UserInterface>): Array<UserInterface> {
    return users.filter(
      (user: UserInterface) =>
        !(user.relationships?.roles.data.length === 1 && user.relationships?.roles.data[0].id.toString() === '8') &&
        !(
          user.relationships?.roles.data.length === 2 &&
          user.relationships?.roles.data.filter(value => value.id.toString() !== '8' && value.id.toString() !== '10').length === 0
        )
    );
  }

  public includeUsersWithOnlyECoachManagerOwner(users: Array<UserInterface>): Array<UserInterface> {
    return users.filter(
      (user: UserInterface) =>
        user.relationships.roles.data[0].id.toString() === '3' || user.relationships.roles.data[0].id.toString() === '5'
    );
  }

  public getLastIndexIDOfURI(path: string): number {
    if (path !== null) {
      return parseInt(path.substring(path.lastIndexOf('/') + 1), 10);
    } else {
      return null;
    }
  }

  public isHexColor(hex) {
    if (hex) {
      return hex.match(/^#[0-9A-F]{6}$/i);
    } else {
      return null;
    }
  }

  public getCodeOfUserByStudy(studyId: number, user: UserInterface): Observable<string> {
    const codes = user.attributes.study_code;
    if (codes && Array.isArray(codes) && codes.length > 0) {
      const foundCode = codes.find((code: { study_id: number; code: string }) => studyId.toString() === code.study_id.toString());
      if (foundCode) {
        return of(foundCode.code);
      } else {
        return of(null);
      }
    } else {
      return of(null);
    }
  }

  public getCodeNameEmail(userId: number, users: Array<UserInterface>, studyId?: number): string {
    const found: UserInterface = this.findArrObjById(userId, users);

    const getNameOrEmail = (user: UserInterface, translateService: TranslateService) => {
      if (user) {
        if (user.attributes.name) {
          return user.attributes.name;
        }
        if (user.attributes.email) {
          return user.attributes.email;
        }
      }
      return translateService.instant('NO_USERNAME');
    };

    const getCode = (studyIdParam: number, user: UserInterface) => {
      const codes = user.attributes.study_code;
      if (codes && Array.isArray(codes) && codes.length > 0) {
        const foundCode = codes.find((code: { study_id: number; code: string }) => studyIdParam.toString() === code.study_id.toString());
        return foundCode ? foundCode.code : null;
      } else {
        return null;
      }
    };

    if (found) {
      if (found.attributes.study_code !== undefined) {
        if (studyId) {
          const foundCode = getCode(studyId, found);
          return foundCode ? foundCode : getNameOrEmail(found, this.translateService);
        } else {
          return getNameOrEmail(found, this.translateService);
        }
      } else {
        return getNameOrEmail(found, this.translateService);
      }
    } else {
      return getNameOrEmail(found, this.translateService);
    }
  }

  public getStudyCode(studyIdParam: number, user: UserInterface): string {
    const codes = user?.attributes?.study_code;
    if (codes && Array.isArray(codes) && codes.length > 0) {
      const foundCode = codes.find((code: { study_id: number; code: string }) => studyIdParam.toString() === code.study_id.toString());
      return foundCode ? foundCode.code : null;
    } else {
      return null;
    }
  }

  public getAnswersheetIdFromActivity(activity: ActivityInterface) {
    const path = activity.attributes.uri;
    if (path !== null && path.includes('answersheets')) {
      return parseInt(path.substring(path.lastIndexOf('/') + 1), 10);
    } else {
      if (activity.attributes.info && activity.attributes.info.answersheet_id) {
        if (Array.isArray(activity.attributes.info.answersheet_id)) {
          return activity.attributes.info.answersheet_id[0];
        } else {
          return activity.attributes.info.answersheet_id;
        }
      } else {
        return null;
      }
    }
  }

  public isState(instance: InterventionInstanceInterface, state: string) {
    return instance.attributes.progress.current_state === state;
  }

  public setUniqueMembers(userResults: Array<UserInterface>, members: Array<UserInterface>): Array<UserInterface> {
    members = members.concat(userResults);
    if (members.length !== 0) {
      members = members.reduce(
        (unique: Array<UserInterface>, item: UserInterface) =>
          unique.find(user => user.id.toString() === item.id.toString()) ? unique : [...unique, item],
        []
      );
    }
    return members;
  }

  public filterUsersBy(list: Array<UserInterface>, value: string, studyId?: number): Array<UserInterface> {
    return list.filter((element, index, array) => {
      let ltName = '';
      let ltFirstname = '';
      let ltLastname = '';
      let ltEmail = '';
      let ltPatientStudyCode = '';
      if (element.attributes.name !== null) {
        ltName = element.attributes.name.toLowerCase().trim();
      }
      if (element.attributes.firstname !== null) {
        ltFirstname = element.attributes.firstname.toLowerCase().trim();
      }
      if (element.attributes.lastname !== null) {
        ltLastname = element.attributes.lastname.toLowerCase().trim();
      }
      if (element.attributes.email !== null) {
        ltEmail = element.attributes.email.toLowerCase().trim();
      }
      if (studyId) {
        if (element.attributes.study_code && element.attributes.study_code.length > 0) {
          const studyCode = element.attributes.study_code
            .filter((code: { study_id: number; code: string }) => code.study_id && code.study_id.toString() === studyId.toString())
            .map((result: { study_id: number; code: string }) => result.code);
          ltPatientStudyCode = studyCode ? studyCode.join('').toLowerCase().trim() : '';
        }
        return (
          ltName.includes(value) ||
          ltFirstname.includes(value) ||
          ltLastname.includes(value) ||
          ltEmail.includes(value) ||
          ltPatientStudyCode.includes(value)
        );
      }
      return ltName.includes(value) || ltFirstname.includes(value) || ltLastname.includes(value) || ltEmail.includes(value);
    });
  }

  public filterOrganisationCollaboratorsBy(
    list: Array<OrganisationCollaboratorInterface>,
    value: string
  ): Array<OrganisationCollaboratorInterface> {
    return list.filter((element, index, array) => {
      let ltName = '';
      let ltFirstname = '';
      let ltLastname = '';
      let ltEmail = '';
      if (element.attributes.name !== null) {
        ltName = element.attributes.name.toLowerCase().trim();
      }
      if (element.attributes.firstname !== null) {
        ltFirstname = element.attributes.firstname.toLowerCase().trim();
      }
      if (element.attributes.lastname !== null) {
        ltLastname = element.attributes.lastname.toLowerCase().trim();
      }
      if (element.attributes.email !== null) {
        ltEmail = element.attributes.email.toLowerCase().trim();
      }
      return ltName.includes(value) || ltFirstname.includes(value) || ltLastname.includes(value) || ltEmail.includes(value);
    });
  }

  public filterStudiesBy(list: Array<StudyInterface>, value: string): Array<StudyInterface> {
    return list.filter((element, index, array) => {
      let ltTitle = '';
      let ltName = '';
      let ltDescription = '';
      if (element.attributes.title !== null && element.attributes.title !== undefined) {
        ltTitle = element.attributes.title.toLowerCase().trim();
      }
      if (element.attributes.name !== null && element.attributes.name !== undefined) {
        ltName = element.attributes.name.toLowerCase().trim();
      }
      if (element.attributes.description !== null && element.attributes.description !== undefined) {
        ltDescription = element.attributes.description.toLowerCase().trim();
      }
      return ltTitle.includes(value) || ltName.includes(value) || ltDescription.includes(value);
    });
  }

  public filterOrganisationBy(list: Array<OrganisationInterface>, value: string): Array<OrganisationInterface> {
    return list.filter((element, index, array) => {
      let ltName = '';
      if (element.attributes.name !== null) {
        ltName = element.attributes.name.toLowerCase().trim();
      }
      return ltName.includes(value);
    });
  }

  public filterInterventionsBy(list: Array<InterventionInterface>, value: string): Array<InterventionInterface> {
    return list.filter((element, index, array) => {
      let ltTitle = '';
      let ltName = '';
      let ltDescription = '';
      if (element.attributes.title !== null) {
        ltTitle = element.attributes.title.toLowerCase().trim();
      }
      if (element.attributes.name !== null) {
        ltName = element.attributes.name.toLowerCase().trim();
      }
      if (element.attributes.description !== null) {
        ltDescription = element.attributes.description.toLowerCase().trim();
      }
      return ltTitle.includes(value) || ltName.includes(value) || ltDescription.includes(value);
    });
  }

  public filterInterventionInstancesBy(
    list: Array<InterventionInstanceInterface>,
    value: string,
    users: Array<UserInterface>,
    studyCollaborators: Array<UserInterface>,
    studyId: number
  ): Array<InterventionInstanceInterface> {
    return list.filter((element: InterventionInstanceInterface) => {
      let ltInstanceId = '';
      let ltInstanceState = '';
      let ltPatientStudyCode = '';
      let ltPatientName = '';
      let ltPatientEmail = '';
      let ltEcoachName = '';
      let ltEcoachEmail = '';

      if (element.attributes.patient_id) {
        const user: UserInterface = this.findArrObjById(element.attributes.patient_id, users);
        if (user) {
          if (user.attributes.study_code && user.attributes.study_code.length > 0) {
            const studyCode = user.attributes.study_code
              .filter((code: { study_id: number; code: string }) => code.study_id && code.study_id.toString() === studyId.toString())
              .map((result: { study_id: number; code: string }) => result.code);
            ltPatientStudyCode = studyCode ? studyCode.join('').toLowerCase().trim() : '';
          }
          if (user.attributes.name !== null) {
            ltPatientName = user.attributes.name.toLowerCase().trim();
          }
          if (user.attributes.email !== null) {
            ltPatientEmail = user.attributes.email.toLowerCase().trim();
          }
        }
      }

      if (element.attributes.ecoach_id.length > 0) {
        const collaborators: Array<UserInterface> = studyCollaborators.filter((collaborator: UserInterface) =>
          element.attributes.ecoach_id.map(userId => userId.toString()).includes(collaborator.id.toString())
        );
        if (collaborators.length > 0) {
          collaborators.forEach((collaborator: UserInterface) => {
            if (collaborator.attributes.name !== null) {
              ltEcoachName = ltEcoachName.concat(collaborator.attributes.name.toLowerCase().trim());
            }
            if (collaborator.attributes.email !== null) {
              ltEcoachEmail = ltEcoachEmail.concat(collaborator.attributes.email.toLowerCase().trim());
            }
          });
        }
      }

      if (element.attributes.progress.current_state) {
        const stateSubject = new BehaviorSubject<string>('');
        this.translateService
          .get('group-instance.state_' + element.attributes.progress.current_state.toLowerCase())
          .subscribe((translation: string) => {
            stateSubject.next(translation);
          });
        ltInstanceState = stateSubject.value.toLowerCase().trim();
      }
      if (element.id !== null) {
        ltInstanceId = element.id.toString().toLowerCase().trim();
      }
      return (
        ltInstanceId.includes(value) ||
        ltPatientName.includes(value) ||
        ltPatientEmail.includes(value) ||
        ltPatientStudyCode.includes(value) ||
        ltInstanceState.includes(value) ||
        ltEcoachName.includes(value) ||
        ltEcoachEmail.includes(value)
      );
    });
  }

  public filterDiariesBy(list: Array<DiaryInterface>, value: string): Array<DiaryInterface> {
    return list.filter((element, index, array) => {
      let ltTitle = '';
      let ltName = '';
      let ltDescription = '';
      if (element.attributes.title !== null) {
        ltTitle = element.attributes.title.toLowerCase().trim();
      }
      if (element.attributes.name !== null) {
        ltName = element.attributes.name.toLowerCase().trim();
      }
      if (element.attributes.description !== null) {
        ltDescription = element.attributes.description.toLowerCase().trim();
      }
      return ltTitle.includes(value) || ltName.includes(value) || ltDescription.includes(value);
    });
  }

  public filterInterventionInstancesByPatientId(
    list: Array<InterventionInstanceInterface>,
    value: number
  ): Array<InterventionInstanceInterface> {
    if (!value) {
      return [];
    }
    return list
      .filter((element: InterventionInstanceInterface) => element.attributes.patient_id !== null)
      .filter((element: InterventionInstanceInterface) => element.attributes.patient_id.toString() === value.toString());
  }

  public filterDiaryInstancesByPatientId(list: Array<DiaryInstanceInterface>, value: number): Array<DiaryInstanceInterface> {
    if (value) {
      return list.filter((element: DiaryInstanceInterface) => element.attributes.patient_id.toString() === value.toString());
    } else {
      return [];
    }
  }

  public filterActivitiesByUser(list: Array<ActivityInterface>, value: string, users: Array<UserInterface>): Array<ActivityInterface> {
    return list.filter(element => {
      const found: UserInterface = this.findArrObjById(element.attributes.user, users);
      const getNameOrEmail = (user: UserInterface) => {
        if (user) {
          return user.attributes.name ? user.attributes.name : user.attributes.email;
        } else {
          return 'System';
        }
      };
      if (found) {
        let ltName = '';
        let ltFirstname = '';
        let ltLastname = '';
        let ltEmail = '';
        let ltPatientStudyCode = '';

        if (found.attributes.name !== null) {
          ltName = found.attributes.name.toLowerCase().trim();
        }
        if (found.attributes.firstname !== null) {
          ltFirstname = found.attributes.firstname.toLowerCase().trim();
        }
        if (found.attributes.lastname !== null) {
          ltLastname = found.attributes.lastname.toLowerCase().trim();
        }
        if (found.attributes.email !== null) {
          ltEmail = found.attributes.email.toLowerCase().trim();
        }
        if (found.attributes.study_code && found.attributes.study_code.length > 0) {
          const studyCode = found.attributes.study_code.map((result: { study_id: number; code: string }) => result.code);
          ltPatientStudyCode = studyCode ? studyCode.join('').toLowerCase().trim() : '';
          return (
            ltName.includes(value) ||
            ltFirstname.includes(value) ||
            ltLastname.includes(value) ||
            ltEmail.includes(value) ||
            ltPatientStudyCode.includes(value)
          );
        } else {
          return ltName.includes(value) || ltFirstname.includes(value) || ltLastname.includes(value) || ltEmail.includes(value);
        }
      } else {
        return getNameOrEmail(found).includes(value);
      }
    });
  }

  public getRolesOfCollaborator(user: UserInterface, includedRoles: Array<RoleInterface>): Array<RoleInterface> {
    // const userRoles: Array<RoleInterface> = user.relationships.roles.data;
    const userRoles: Array<RoleInterface> = includedRoles;
    const userRoleIds: Array<number> = [];
    if (userRoles) {
      userRoles.forEach(role => userRoleIds.push(role.id));
      // Subarray of included roles based on array of ids
      const included: Array<RoleInterface> = [];
      userRoleIds.forEach(roleId => {
        if (includedRoles.find(global => global.id === roleId) !== undefined) {
          included.push(includedRoles.find(global => global.id === roleId));
        }
      });
      return included;
    } else {
      return [];
    }
  }

  public getHighestRoleOfCollaborator(user: UserInterface): string {
    const studyOwner = user.relationships?.roles.data.find(value => value.attributes.slug === 'study.owner');
    const studyManager = user.relationships?.roles.data.find(value => value.attributes.slug === 'study.ecoachmanager');
    const studyECoach = user.relationships?.roles.data.find(value => value.attributes.slug === 'study.ecoach');
    const studyPublisher = user.relationships?.roles.data.find(value => value.attributes.slug === 'study.publisher');
    const studyAccess = user.relationships?.roles.data.find(value => value.attributes.slug === 'study.access');
    if (!!studyOwner) {
      return studyOwner.attributes.slug;
    } else if (!!studyManager) {
      return studyManager.attributes.slug;
    } else if (!!studyECoach) {
      return studyECoach.attributes.slug;
    } else if (!!studyPublisher) {
      return studyPublisher.attributes.slug;
    } else if (!!studyAccess) {
      return studyAccess.attributes.slug;
    } else {
      return '';
    }
  }

  // Determine highest role of a user in a study
  public getHighestRoleOfStudy(study: StudyInterface): string {
    if (study.relationships?.roles) {
      const studyOwner = study.relationships.roles.data.find((value: any) => value.attributes.slug === 'study.owner');
      const studyManager = study.relationships.roles.data.find((value: any) => value.attributes.slug === 'study.ecoachmanager');
      const studyECoach = study.relationships.roles.data.find((value: any) => value.attributes.slug === 'study.ecoach');
      const studyPublisher = study.relationships.roles.data.find((value: any) => value.attributes.slug === 'study.publisher');
      const studyAccess = study.relationships.roles.data.find((value: any) => value.attributes.slug === 'study.access');
      if (!!studyOwner) {
        return studyOwner?.attributes.slug;
      } else if (!!studyManager) {
        return studyManager?.attributes.slug;
      } else if (!!studyECoach) {
        return studyECoach?.attributes.slug;
      } else if (!!studyPublisher) {
        return studyPublisher?.attributes.slug;
      } else if (!!studyAccess) {
        return studyAccess?.attributes.slug;
      } else {
        return '';
      }
    }
    return '';
  }

  // Determine highest role of a user
  public getHighestRoleOfIntervention(intervention?: InterventionInterface): string {
    if (!intervention?.relationships?.roles) {
      return '';
    } else {
      const studyOwner = intervention.relationships.roles.data.find(value => value.attributes.slug === 'study.owner');
      const studyManager = intervention.relationships.roles.data.find(value => value.attributes.slug === 'study.ecoachmanager');
      const studyECoach = intervention.relationships.roles.data.find(value => value.attributes.slug === 'study.ecoach');
      const studyPublisher = intervention.relationships.roles.data.find(value => value.attributes.slug === 'study.publisher');
      const studyAccess = intervention.relationships.roles.data.find(value => value.attributes.slug === 'study.access');
      if (!!studyOwner) {
        return studyOwner.attributes.slug;
      } else if (!!studyManager) {
        return studyManager.attributes.slug;
      } else if (!!studyECoach) {
        return studyECoach.attributes.slug;
      } else if (!!studyPublisher) {
        return studyPublisher.attributes.slug;
      } else if (!!studyAccess) {
        return studyAccess.attributes.slug;
      } else {
        return '';
      }
    }
  }

  // Determine highest role of a user
  public getHighestRoleOfOrganisationCollaborator(user: OrganisationCollaboratorInterface): string {
    const userRoles: Array<PermissionInterface> = user.attributes.roles;
    if (userRoles.find((value: PermissionInterface) => value.slug === 'organisation.owner')) {
      return 'organisation.owner';
    } else if (userRoles.find((value: PermissionInterface) => value.slug === 'organisation.manager')) {
      return 'organisation.manager';
    } else {
      return 'organisation.access';
    }
  }

  public getFaIconByInstanceState(stateParam: string) {
    const evaluateState = val =>
      ({
        initial: faStop,
        configuration: faPause,
        active: faRunning,
        awaiting_next_lesson: faRunning,
        awaiting_next_questionnaire: faRunning,
        feedback_required: faPause,
        feedback_provided: faPause,
        paused: faPause,
        canceled: faTimes,
        completed: faCheck,
        no_state: faStop
      }[val]);
    return evaluateState(stateParam.toLowerCase());
  }

  public getTextColorByInstanceState(stateParam: string) {
    const evaluateState = val =>
      ({
        initial: 'text-muted',
        configuration: 'text-warning',
        active: 'text-success',
        awaiting_next_lesson: 'text-success',
        awaiting_next_questionnaire: 'text-success',
        feedback_required: 'text-warning',
        feedback_provided: 'text-info',
        paused: 'text-warning',
        canceled: 'text-danger',
        completed: 'text-info',
        no_state: 'text-muted'
      }[val]);
    return evaluateState(stateParam.toLowerCase());
  }

  public getToolTipByInstanceState(stateParam: string) {
    const evaluateState = val =>
      ({
        feedback_required: 'intervention-instance.tooltip_feedback_required',
        feedback_provided: 'intervention-instance.tooltip_feedback_provided',
        paused: 'intervention-instance.tooltip_paused',
        canceled: 'intervention-instance.tooltip_canceled',
        completed: 'intervention-instance.tooltip_completed'
      }[val]);
    return evaluateState(stateParam.toLowerCase());
  }

  public getBuddyActivityByNewest(activities: Array<BuddyActivityInterface>) {
    if (activities.length !== 0) {
      return activities.sort((a, b) => b.attributes.created_at - a.attributes.created_at);
    } else {
      return [];
    }
  }

  public getJSONObject(jsonString) {
    try {
      const o = JSON.parse(jsonString);
      if (o && typeof o === 'object') {
        return o;
      }
    } catch (e) {}
    return false;
  }

  public getThreadByRecentUpdates(threads: Array<MessageThreadsInterface>) {
    if (threads.length !== 0) {
      return threads.sort(
        (a, b) =>
          Number(new Date(this.getDateFromObjectString(b.attributes.updated_at))) -
          Number(new Date(this.getDateFromObjectString(a.attributes.updated_at)))
      );
    } else {
      return [];
    }
  }

  public onKeyFilterUser(
    users: Array<UserInterface>,
    filterSelection: string,
    usersSubject: BehaviorSubject<Array<UserInterface>>,
    selectedUser: UserInterface,
    studyId?: number
  ) {
    const filterResults: Array<UserInterface> = users.filter(
      (user: UserInterface) =>
        user.attributes.name?.toLowerCase().includes(filterSelection.toLowerCase()) ||
        this.getCodeNameEmail(user.id, users, studyId).toLowerCase().includes(filterSelection.toLowerCase()) ||
        user.attributes.email.toLowerCase().includes(filterSelection.toLowerCase())
    );
    if (filterResults.length > 0) {
      usersSubject.next(filterResults);
    } else {
      usersSubject.next([selectedUser]);
    }
  }

  public displayReadableDateTimeValue(value: string) {
    const date = new Date(value);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours() < 10 ? '0'.concat(date.getHours().toString()) : date.getHours().toString();
    const minute = date.getMinutes() < 10 ? '0'.concat(date.getMinutes().toString()) : date.getMinutes().toString();
    return day + '.' + month + '.' + year + ', ' + hour + ':' + minute;
    // return month + '/' + day + '/' + year + ', ' + hour + ':' + minute;
  }

  public setMatTabActive(
    matTabGroup: QueryList<MatTabGroup>,
    url: string,
    translationSubject: BehaviorSubject<any>,
    translateService: TranslateService,
    componentTranslation: string
  ) {
    if (matTabGroup) {
      if (matTabGroup.first) {
        translationSubject
          .asObservable()
          .pipe(
            distinctUntilChanged(),
            switchMap(value => {
              return iif(
                () => !!translationSubject.value[componentTranslation],
                of(value),
                translateService.getTranslation(localStorage.getItem('language') !== null ? localStorage.getItem('language') : 'de')
              );
            })
          )
          .subscribe(value => {
            if (value) {
              translationSubject.next(value);
              const matTabGroupComponent: MatTabGroup = matTabGroup.first;
              const tabs: QueryList<MatTab> = matTabGroupComponent._allTabs;
              const urlString = url.substring(url.lastIndexOf('/') + 1);
              const label = value[componentTranslation][urlString];
              if (tabs.get(matTabGroupComponent.selectedIndex).textLabel !== label) {
                tabs.forEach((item: MatTab, index: number) => {
                  if (item.textLabel === label) {
                    matTabGroupComponent.selectedIndex = index;
                  }
                });
              }
            }
          });
      }
    }
  }

  public getECoachUsername(eCoachId: number, collaborators: Array<UserInterface>): string {
    if (eCoachId !== null) {
      const foundECoach: UserInterface = this.findArrObjById(eCoachId, collaborators);
      if (!!foundECoach) {
        if (foundECoach.attributes.name && foundECoach.attributes.name !== '') {
          return foundECoach.attributes.name;
        } else {
          return foundECoach.attributes.email;
        }
      } else {
        return eCoachId.toString();
      }
    } else {
      return 'patient-instance.my_instances_no_ecoach';
    }
  }

  public setPagination(
    paginator: MatPaginator,
    listSubject: BehaviorSubject<Array<any>>,
    pagedSubject: BehaviorSubject<Array<any>>,
    setFirst?: boolean,
    pageIndex?: number,
    pageSize?: number
  ): Observable<boolean> {
    if (setFirst) {
      pagedSubject.next(listSubject.value.slice(0, 20));
      if (paginator) {
        paginator.firstPage();
      }
    } else {
      const start = pageIndex * pageSize;
      const end = pageIndex * pageSize + pageSize <= listSubject.value.length ? start + pageSize : undefined;
      const pagedList = listSubject.value.slice(pageIndex * pageSize, end);
      if (pagedList.length > 0) {
        pagedSubject.next(pagedList);
        paginator.pageIndex = pageIndex;
      } else {
        pagedSubject.next(listSubject.value.slice((pageIndex - 1) * pageSize, end));
        paginator.pageIndex = pageIndex - 1 > 0 ? pageIndex - 1 : 0;
      }
    }
    return of(true);
  }

  public setPagedContent(
    listSubject: BehaviorSubject<Array<any>>,
    pagedSubject: BehaviorSubject<Array<any>>,
    setFirst?: boolean,
    pageIndex?: number,
    pageSize?: number
  ): Observable<boolean> {
    if (setFirst) {
      pagedSubject.next(listSubject.value.slice(0, 20));
    } else {
      const start = pageIndex * pageSize;
      const end = pageIndex * pageSize + pageSize <= listSubject.value.length ? start + pageSize : undefined;
      const pagedList = listSubject.value.slice(pageIndex * pageSize, end);
      if (pagedList.length > 0) {
        pagedSubject.next(pagedList);
      } else {
        pagedSubject.next(listSubject.value.slice((pageIndex - 1) * pageSize, end));
      }
    }
    return of(true);
  }

  public stringifyJSON(object: any) {
    return JSON.stringify(object);
  }

  public trackByElement(index: number, element: any): string {
    return JSON.stringify(element);
  }

  public hasUnlockDiaries(questionnaireId: number, questionnaires: Array<LessonInterface>) {
    return (
      this.findArrObjById(questionnaireId, questionnaires).attributes.unlock_diaries !== null &&
      this.findArrObjById(questionnaireId, questionnaires).attributes.unlock_diaries.length > 0
    );
  }

  // Search answersheets for intervention_instance_id and answersheet id while ignoring casing
  public filterAnswersheetsByInput(
    value: string,
    answersheets: Array<AnswersheetInterface>,
    questionnaires: Array<LessonInterface>,
    users: Array<UserInterface>
  ): Array<AnswersheetInterface> {
    return answersheets.filter((element: AnswersheetInterface) => {
      const ltAnswersheetId =
        element.attributes.intervention_instance_id !== null
          ? element.attributes.intervention_instance_id.toString().toLowerCase().trim()
          : '';
      const lesson: LessonInterface = this.findArrObjById(element.attributes.questionnaire_id, questionnaires);
      const ltLessonName = lesson ? lesson.attributes.name : '';
      const ltCodeEmailName = this.getCodeNameEmail(element.attributes.user_id, users);
      return ltAnswersheetId.includes(value) || ltLessonName.includes(value) || ltCodeEmailName.includes(value);
    });
  }

  public searchOrganisationsByInput(
    filterValue: string,
    searchTextSubject: BehaviorSubject<string>,
    initialOrganisations: Array<OrganisationInterface>,
    organisationsSubject: BehaviorSubject<Array<OrganisationInterface>>
  ): Observable<boolean> {
    searchTextSubject.next(filterValue);
    return searchTextSubject.pipe(
      distinctUntilChanged(),
      mergeMap(() => {
        organisationsSubject.next(this.filterOrganisationBy(initialOrganisations, filterValue));
        return of(true);
      })
    );
  }

  public searchStudiesByInput(
    filterValue: string,
    searchTextSubject: BehaviorSubject<string>,
    initialGroups: Array<StudyInterface>,
    studiesSubject: BehaviorSubject<Array<StudyInterface>>
  ): Observable<boolean> {
    searchTextSubject.next(filterValue);
    return searchTextSubject.pipe(
      distinctUntilChanged(),
      mergeMap(() => {
        studiesSubject.next(this.filterStudiesBy(initialGroups, filterValue));
        return of(true);
      })
    );
  }

  public searchUsersByInput(
    filterValue: string,
    searchTextSubject: BehaviorSubject<string>,
    allUsers: Array<UserInterface>,
    allUsersSubject: BehaviorSubject<Array<UserInterface>>
  ): Observable<boolean> {
    searchTextSubject.next(filterValue);
    return searchTextSubject.pipe(
      distinctUntilChanged(),
      mergeMap(() => {
        allUsersSubject.next(this.filterUsersBy(allUsers, filterValue));
        return of(true);
      })
    );
  }

  public searchAnswersheetsByInput(
    filterValue: string,
    searchTextSubject: BehaviorSubject<string>,
    answersheets: Array<AnswersheetInterface>,
    questionnaires: Array<LessonInterface>,
    answersheetsSubject: BehaviorSubject<Array<AnswersheetInterface>>,
    users: Array<UserInterface>
  ): Observable<boolean> {
    searchTextSubject.next(filterValue);
    return searchTextSubject.pipe(
      distinctUntilChanged(),
      mergeMap(() => {
        answersheetsSubject.next(this.filterAnswersheetsByInput(filterValue, answersheets, questionnaires, users));
        return of(true);
      })
    );
  }

  public searchBuddiesByInput(
    filterValue: string,
    searchTextSubject: BehaviorSubject<string>,
    buddies: Array<BuddyInstanceInterface>,
    buddiesSubject: BehaviorSubject<Array<BuddyInstanceInterface>>
  ): Observable<boolean> {
    searchTextSubject.next(filterValue);
    return searchTextSubject.pipe(
      distinctUntilChanged(),
      mergeMap(() => {
        buddiesSubject.next(buddies);
        return of(true);
      })
    );
  }

  public searchInterventionInstanceByInput(
    filterValue: string,
    searchTextSubject: BehaviorSubject<string>,
    instances: Array<InterventionInstanceInterface>,
    instancesSubject: BehaviorSubject<Array<InterventionInstanceInterface>>,
    studyId: number,
    studyCollaborators: Array<UserInterface>,
    users: Array<UserInterface>
  ): Observable<boolean> {
    searchTextSubject.next(filterValue);
    return searchTextSubject.pipe(
      distinctUntilChanged(),
      mergeMap(() => {
        instancesSubject.next(this.filterInterventionInstancesBy(instances, filterValue, users, studyCollaborators, studyId));
        return of(true);
      })
    );
  }

  public hasRoles(roles: Array<RoleInterface>, regex: RegExp): boolean {
    return !!roles.find((role: RoleInterface) => role.attributes.slug.match(regex));
  }

  public toggleSubject(subject: BehaviorSubject<boolean>) {
    subject.next(!subject.value);
  }

  public copiedInterventionBySameUser(intervention: InterventionInterface, userId: number): boolean {
    return !intervention.attributes.copied_by_user || !userId
      ? true
      : intervention.attributes.copied_by_user.toString() === userId.toString();
  }

  public isECoachOfInstance(instance: InterventionInstanceInterface, userId: number): boolean {
    return !!instance.attributes.ecoach_id.find((id: number) => id.toString() === userId.toString());
  }

  public isPatientOfInstance(instance: InterventionInstanceInterface, userId: number): boolean {
    return instance.attributes.patient_id?.toString() === userId.toString();
  }

  public setLocaleFromStorage(header: HttpHeaders): HttpHeaders {
    if (
      localStorage.getItem('language') !== null &&
      localStorage.getItem('language') !== undefined &&
      localStorage.getItem('language') !== 'undefined'
    ) {
      return header.set('Accept-Language', localStorage.getItem('language'));
    } else {
      localStorage.setItem('language', 'de');
      return header.set('Accept-Language', 'de');
    }
  }
}
