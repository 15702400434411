import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StudyInterface } from 'src/app/models/interface/study/study.interface';
import { UserInterface } from '../../models/interface/user.interface';

export const groupsState = createFeatureSelector('groups');
export const collabGroupsState = createFeatureSelector('collabGroups');
export const nonCollabGroupsState = createFeatureSelector('nonCollabGroups');
export const collaboratorsOfStudiesState = createFeatureSelector('collaboratorsOfStudies');

export const getCollabGroups = createSelector(collabGroupsState, collabGroups => collabGroups);
export const getNonCollabGroups = createSelector(nonCollabGroupsState, nonCollabGroups => nonCollabGroups);
export const getCollaboratorsOfStudies = createSelector(collaboratorsOfStudiesState, collaborators => collaborators);

export const getCollabGroupById = (id: number) =>
  createSelector(collabGroupsState, (collabGroups: Array<StudyInterface>) => {
    if (collabGroups) {
      return collabGroups.find((group: StudyInterface) => group.id.toString() === id.toString());
    } else {
      return undefined;
    }
  });

export const getNonCollabGroupById = (id: number) =>
  createSelector(nonCollabGroupsState, (nonCollabGroups: Array<StudyInterface>) => {
    if (nonCollabGroups) {
      return nonCollabGroups.find((group: StudyInterface) => group.id.toString() === id.toString());
    } else {
      return undefined;
    }
  });

export const getCollaboratorsByStudyId = (id: number) =>
  createSelector(collaboratorsOfStudiesState, (collaboratorsOfStudies: Array<{ studyId: number; collaborators: UserInterface[] }>) =>
    collaboratorsOfStudies.find(value => value.studyId.toString() === id.toString())
  );

export const getCollaboratorsByMultipleStudyIds = (ids: Array<number>) =>
  createSelector(collaboratorsOfStudiesState, (collaboratorsOfStudies: Array<{ studyId: number; collaborators: UserInterface[] }>) =>
    collaboratorsOfStudies.filter(value => ids.includes(value.studyId))
  );
