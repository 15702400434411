import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, iif, mergeMap, Observable, of, skip, Subscription, take, throwError } from 'rxjs';
import { PayloadInterface } from '../../../models/interface/payload.interface';
import { RequestBodyData } from '../../../models/request-body.data';
import { UserInterface } from '../../../models/interface/user.interface';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ErrorInterface } from '../../../models/interface/error.interface';
import { StudyStore } from '../../../store/study/component-store/study.store';

@Component({
  selector: 'app-dialog-intervention-collaborator-remove',
  templateUrl: './dialog-intervention-collaborator-remove.component.html',
  styleUrls: ['./dialog-intervention-collaborator-remove.component.scss'],
  providers: [StudyStore]
})
export class DialogInterventionCollaboratorRemoveComponent implements OnInit, OnDestroy {
  public collaborator: UserInterface;
  public param;
  public studyId: number;

  public removeCollaboratorResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');

  public isNotRemovableSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private deleteCollaboratorsResponse$: Observable<any>;

  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private studyStore: StudyStore,
    private dialogRef: MatDialogRef<DialogInterventionCollaboratorRemoveComponent>
  ) {
    this.deleteCollaboratorsResponse$ = this.studyStore.deleteCollaboratorsResponse$;
  }

  ngOnInit(): void {
    this.collaborator = this.data.collaborator;
    this.param = this.data.param;
    this.studyId = this.data.studyId;
  }

  // Removing collaborator from study
  public removeCollaborator(userId: number): void {
    if (this.removeCollaboratorResponse.value === 'DEFAULT') {
      const payload: PayloadInterface = new RequestBodyData('users', { users: [{ id: userId }] });
      this.removeCollaboratorResponse.next('LOADING');

      this.studyStore.deleteCollaborators({ studyId: this.studyId, payload });

      this.deleteCollaboratorsResponse$
        .pipe(
          skip(1),
          take(1),
          mergeMap((result: any) => iif(() => result instanceof HttpResponse, of(result), throwError(result)))
        )
        .subscribe(
          () => {
            this.removeCollaboratorResponse.next('SUCCESS');
            this.isNotRemovableSubject.next(false);
          },
          error => {
            this.removeCollaboratorResponse.next('FAILURE');
            if (typeof error.error !== 'string' && error.error && error instanceof HttpErrorResponse && error.error.errors) {
              const errorData: ErrorInterface = error.error.errors[0];
              if (errorData.status.toString() === '403' && errorData.detail.includes('is the only one guiding an intervention')) {
                this.isNotRemovableSubject.next(true);
              }
            }
            setTimeout(() => {
              this.removeCollaboratorResponse.next('DEFAULT');
            }, 2500);
          },
          () => {
            setTimeout(() => {
              this.removeCollaboratorResponse.next('DEFAULT');
              this.dialogRef.close('SUCCESS');
            }, 2500);
          }
        );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
