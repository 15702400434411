import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { catchError, EMPTY, forkJoin, map, mergeMap, Observable, tap, throwError } from 'rxjs';
import { SkillInterface } from '../../../models/interface/skill.interface';
import { SkillService } from '../../../services/api/skill/skill.service';

export interface SkillsState {
  skillsOfIntervention: SkillInterface[];
  elementsOfSkill: any;
}

@Injectable()
export class SkillStore extends ComponentStore<SkillsState> {
  readonly skillsOfIntervention$: Observable<SkillInterface[]> = this.select(state => state.skillsOfIntervention, {
    debounce: true
  });
  readonly elementsOfSkill$: Observable<any> = this.select(state => state.elementsOfSkill, {
    debounce: true
  });

  readonly updateSkillState = this.updater((state, payload: { skillsOfIntervention?: SkillInterface[]; elementsOfSkill?: any }) => ({
    skillsOfIntervention: payload.skillsOfIntervention ? payload.skillsOfIntervention : state.skillsOfIntervention,
    elementsOfSkill: payload.elementsOfSkill ? payload.elementsOfSkill : state.elementsOfSkill
  }));

  readonly getSkillsOfIntervention = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) =>
        this.skillService.getAllSkillsOfIntervention(payload.id).pipe(
          tap({
            next: (result: any) => this.updateSkillState({ skillsOfIntervention: result.body.data }),
            error: e => throwError(e)
          }),
          catchError(error => EMPTY)
        )
      )
    )
  );

  readonly getSkillElements = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) =>
        this.skillService.getSkillElements(payload.id).pipe(
          tap({
            next: (result: any) => this.updateSkillState({ elementsOfSkill: result.body.data['attributes']['elements'] }),
            error: e => throwError(e)
          }),
          catchError(error => EMPTY)
        )
      )
    )
  );

  constructor(private skillService: SkillService) {
    super({
      skillsOfIntervention: [],
      elementsOfSkill: []
    });
  }
}
