<div class="container-fluid">
  <div class="row">
    <div class="col-12 col-sm-8">
      <mat-tab-group
        *ngIf="(hasDiaryEntries$ | async) === true"
        (selectedIndexChange)="getSelectedIndexDiaries($event)"
        [selectedIndex]="selected.value"
      >
        <mat-tab
          [attr.id]="'tab_' + entry.id"
          *ngFor="let entry of diaryAnswersheets; let index = index"
          [label]="helper.localizeDateTimestamp(entry.attributes.collected_at)"
        >
        </mat-tab>
      </mat-tab-group>
      <!-- Diary answersheet content-->
      <ng-template #answersheetsDetailsPreviewContainer> </ng-template>
      <div *ngIf="(hasDiaryEntries$ | async) === false" class="mb-3">
        <p>{{('diary-answersheets.diary_entries_not_found' | translate)}}</p>
      </div>
    </div>
    <div class="col-12 col-sm-4">
      <div class="mb-4 text-start">
        <div class="card">
          <div *ngIf="(pagedActivity$ | async)?.length === 0" class="card-body">
            <p class="centering">{{'instance-detail.no_activities_found' | translate}}</p>
          </div>
          <div *ngIf="(pagedActivity$ | async).length !== 0" class="list-group">
            <div
              [attr.id]="'activity_' + activity.id"
              *ngFor="let activity of pagedActivity$ | async"
              class="list-group-item list-group-item-action flex-column align-items-start"
            >
              <div class="row justify-content-around mb-3">
                <div class="col-sm-8">
                  <p class="mb-1">
                    <strong> {{helperActivity.getActivityText(activity) | async}}</strong>
                  </p>
                </div>
                <div class="col-sm-4">
                  <p class="mb-1"><small>{{helper.localizeDateString(activity.attributes.created_at)}}</small></p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card-footer">
          <div class="mt-3">
            <mat-paginator
              (page)="onPageChangeActivity($event)"
              [length]="(activitiesSubject | async)?.length"
              [showFirstLastButtons]="true"
              [pageSizeOptions]="[10, 20, 50]"
              [pageSize]="20"
            >
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
