/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { HelperService } from '../../../services/helper/helper.service';
import { UserInterface } from '../../../models/interface/user.interface';
import { LessonInterface } from '../../../models/interface/lesson.interface';
import { AnswersheetInterface } from '../../../models/interface/answersheet.interface';
import { AnswersheetAnswerInterface } from '../../../models/interface/answersheet-answer.interface';
import { Store } from '@ngrx/store';
import { UserActionTypes } from '../../../store/user/user.action';
import { AnswersheetsStore } from '../../../store/answersheet/component-store/answersheet.store';
import { QuestionnaireStore } from '../../../store/lesson-questionnaire/component-store/lesson-questionnaire.store';

/**
 * Component:
 * Displays the answersheet details of a diary;
 */

@Component({
  selector: 'app-diary-answersheets-detail',
  templateUrl: './diary-answersheets-detail.component.html',
  styleUrls: ['./diary-answersheets-detail.component.scss'],
  providers: [AnswersheetsStore, QuestionnaireStore]
})
export class DiaryAnswersheetsDetailComponent implements OnInit, OnDestroy {
  // Icon
  faArrowLeft = faArrowLeft;
  faArrowRight = faArrowRight;
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;

  public isLoading$: Observable<boolean>;
  public isCollapse$: Observable<boolean>;

  public answersheet: AnswersheetInterface;
  public answersheet$: Observable<AnswersheetInterface | undefined>;
  public questionnaireId: number;
  public answers: Array<AnswersheetAnswerInterface> = [];
  public answersheetId: number;

  public lesson: LessonInterface;

  // Data provided by UserService
  public users: Array<UserInterface> = [];

  public backgroundColor;
  public diaryBackgroundImageColor;

  public param = { intervention_name: '' };

  public studyId: number;

  private questionnaire$: Observable<LessonInterface>;

  private myMembers$: Observable<Array<UserInterface>>;

  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private subscriptions: Subscription[] = [];

  constructor(
    private helperService: HelperService,
    private store: Store<{ myMembers: Array<UserInterface> }>,
    private questionnaireStore: QuestionnaireStore,
    private answersheetStore: AnswersheetsStore
  ) {
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.questionnaire$ = this.questionnaireStore.questionnaire$;
    this.myMembers$ = this.store.select('myMembers');
  }

  public get helper() {
    return this.helperService;
  }

  @Input()
  set _answersheet_id(_answersheetId: number) {
    if (_answersheetId) {
      this.answersheetId = _answersheetId;
      this.answersheetStore.getAnswersheetAsEcoach(this.answersheetId);
      this.answersheet$ = this.answersheetStore.selectAnswersheet(this.answersheetId);
    }
  }

  @Input()
  set _study_id(_studyId: number) {
    this.studyId = _studyId;
  }

  @Input()
  set _diaryBackground(_diaryBackground: string) {
    this.diaryBackgroundImageColor = _diaryBackground;
    this.backgroundColor = _diaryBackground;
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.answersheet$
        .pipe(
          filter(value => !!value),
          take(1)
        )
        .subscribe((result: any) => {
          this.answersheet = result;
          this.answers = this.answersheet.attributes.answers;
          this.questionnaireId = this.answersheet.attributes.questionnaire_id;
          this.questionnaireStore.getQuestionnaireDetails({ questionnaireId: this.questionnaireId });
          this.store.dispatch({
            type: UserActionTypes.getMyMembersType,
            payload: {}
          });
          this.isLoadingSubject.next(false);
        })
    );
    this.subscriptions.push(
      this.questionnaire$.subscribe((result: any) => {
        this.lesson = result;
      })
    );
    this.subscriptions.push(
      this.myMembers$.subscribe((result: any) => {
        this.users = [...this.users, ...result];
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
