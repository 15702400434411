import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, throwError } from 'rxjs';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons/faEllipsisH';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons/faFileDownload';
import { faHands } from '@fortawesome/free-solid-svg-icons/faHands';
import { faHandsHelping } from '@fortawesome/free-solid-svg-icons/faHandsHelping';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons/faFilePdf';
import { faRedo } from '@fortawesome/free-solid-svg-icons/faRedo';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { InterventionInstanceInterface } from '../../../models/interface/intervention-instances/intervention-instance.interface';
import { InterventionInterface } from '../../../models/interface/intervention.interface';
import { HelperService } from '../../../services/helper/helper.service';
import { UserInterface } from '../../../models/interface/user.interface';
import { Download } from '../../../helpers/download/download';
import { JobResultInterface } from '../../../models/interface/jobresult.interface';
import { catchError, delay, filter, mergeMap, repeat, take } from 'rxjs/operators';
import { of } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { QueueActionTypes } from '../../../store/queue/queue.action';
import { InterventionInstanceStore } from '../../../store/intervention-instance/component-store/intervention-instance.store';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-dialog-intervention-instance-report',
  templateUrl: './dialog-intervention-instance-report.component.html',
  styleUrls: ['./dialog-intervention-instance-report.component.scss'],
  providers: [InterventionInstanceStore]
})
export class DialogInterventionInstanceReportComponent implements OnInit, OnDestroy {
  @ViewChild('paginator') paginator;

  // Icons
  faFilePdf = faFilePdf;
  faFileDownload = faFileDownload;
  faHands = faHands;
  faHandsHelping = faHandsHelping;
  faTimes = faTimes;
  faEllipsisH = faEllipsisH;
  faRedo = faRedo;
  faClock = faClock;

  public isLoading$: Observable<boolean>;

  public param;
  public organisationId: number;
  public collaborators: Array<UserInterface>;
  public ecoaches: Array<UserInterface>;
  public users: Array<UserInterface>;

  public instance: InterventionInstanceInterface;

  public interventions: Array<InterventionInterface> = [];

  // List of roles
  public roles = ['study.owner', 'study.ecoachmanager', 'study.ecoach', 'study.access'];
  public selectedRole = 'study.access';

  public isReviewer = false;
  public isReviewerCollaborator = false;

  public includeUnfinishedQuestionnaires = false;
  public includeActivities = false;
  public includeDiaries = false;
  public includeFeedback = false;

  // Form submission
  public submitted = false;

  public downloadPDFReportResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');

  public download$: Observable<Download>;
  public jobresults$: Observable<Array<JobResultInterface>>;

  public selectedTabIndex = 0;

  public showDownloadHintSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public pagedJobResults$: Observable<Array<JobResultInterface>>;
  public pagedJobResultsSubject: BehaviorSubject<Array<JobResultInterface>> = new BehaviorSubject<Array<JobResultInterface>>([]);

  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private getInstancePDFReportQueueResponse$: Observable<any>;

  private jobresultsSubject: BehaviorSubject<Array<JobResultInterface>> = new BehaviorSubject<Array<JobResultInterface>>([]);

  // Subscription Handler
  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private helperService: HelperService,
    private interventionInstanceStore: InterventionInstanceStore,
    private actions$: Actions,
    private store: Store
  ) {
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.jobresults$ = this.jobresultsSubject.asObservable();
    this.getInstancePDFReportQueueResponse$ = this.interventionInstanceStore.getInstancePDFReportQueueResponse$;
  }

  public get helper() {
    return this.helperService;
  }

  ngOnInit(): void {
    this.collaborators = this.data.collaborators;
    this.param = this.data.param;
    this.organisationId = this.data.organisationId;
    this.ecoaches = this.data.ecoaches;
    this.instance = this.data.instance;
    this.interventions = this.data.interventions;
    this.users = this.data.users;

    const poll = of({}).pipe(
      mergeMap(_ => {
        if (this.jobresultsSubject.value.filter(job => job.attributes.finished === 0).length > 0) {
          this.loadJobResults();
        }
        return of({});
      }),
      catchError(e => of(null)),
      delay(30000),
      repeat()
    );

    this.subscriptions.push(poll.subscribe());
    this.pagedJobResults$ = this.pagedJobResultsSubject.asObservable();
    this.loadJobResults();
  }

  public getInstanceReportPDF(instance: InterventionInstanceInterface): void {
    if (this.downloadPDFReportResponse.value === 'FAILURE' || this.downloadPDFReportResponse.value === 'SUCCESS') {
      this.downloadPDFReportResponse.next('DEFAULT');
      return;
    }

    this.showDownloadHintSubject.next(false);

    const unfinishedQuestionnaires = this.includeUnfinishedQuestionnaires ? 1 : undefined;
    const activities = this.includeActivities ? 1 : undefined;
    const diaries = this.includeDiaries ? 1 : undefined;
    const feedback = this.includeFeedback ? 1 : undefined;

    this.submitted = true;
    if (this.downloadPDFReportResponse.value === 'DEFAULT') {
      this.downloadPDFReportResponse.next('LOADING');

      this.interventionInstanceStore.getInstancePDFReportQueue({
        instanceId: instance.id,
        unfinishedQuestionnaires,
        activities,
        diaries,
        feedback
      });

      this.subscriptions.push(
        this.getInstancePDFReportQueueResponse$
          .pipe(
            filter(value => !!value),
            mergeMap((result: any) => {
              if (result instanceof HttpErrorResponse) {
                return throwError(result);
              } else {
                this.store.dispatch({ type: QueueActionTypes.getAllJobResultsOfUserType, payload: {} });
                return this.actions$.pipe(
                  ofType(QueueActionTypes.getAllJobResultsOfUserSuccessType, QueueActionTypes.getAllJobResultsOfUserErrorType),
                  take(1)
                );
              }
            })
          )
          .subscribe(
            (result: any) => {
              if (result.type === QueueActionTypes.getAllJobResultsOfUserSuccessType) {
                const allJobs: Array<JobResultInterface> = result['response']['body']['data'];
                this.jobresultsSubject.next(
                  allJobs
                    .filter(
                      (job: JobResultInterface) =>
                        job.attributes.entity_id.toString() === this.instance.id.toString() &&
                        job.attributes.type === 'Intervention_Instance'
                    )
                    .sort((a, b) => b.id - a.id)
                );
                this.loadJobResults();
                this.downloadPDFReportResponse.next('SUCCESS');
                this.showDownloadHintSubject.next(true);
              } else {
                this.downloadPDFReportResponse.next('FAILURE');
              }
              setTimeout(() => {
                this.downloadPDFReportResponse.next('DEFAULT');
                this.submitted = false;
              }, 2500);
            },
            () => {
              this.downloadPDFReportResponse.next('FAILURE');
              setTimeout(() => {
                this.downloadPDFReportResponse.next('DEFAULT');
                this.submitted = false;
              }, 2500);
            }
          )
      );
    }
  }

  public updatePagedJobInstances(event: any) {
    if (event) {
      this.pagedJobResultsSubject.next(event);
    }
  }

  public loadJobResults(): void {
    this.store.dispatch({ type: QueueActionTypes.getAllJobResultsOfUserType, payload: {} });
    this.subscriptions.push(
      this.actions$
        .pipe(ofType(QueueActionTypes.getAllJobResultsOfUserSuccessType, QueueActionTypes.getAllJobResultsOfUserErrorType), take(1))
        .subscribe((result: any) => {
          if (result.type === QueueActionTypes.getAllJobResultsOfUserSuccessType) {
            const allJobs: Array<JobResultInterface> = result['response']['body']['data'];
            this.jobresultsSubject.next(
              allJobs
                .filter(
                  (job: JobResultInterface) =>
                    job.attributes.entity_id.toString() === this.instance.id.toString() && job.attributes.type === 'Intervention_Instance'
                )
                .sort((a, b) => b.id - a.id)
            );
            this.pagedJobResultsSubject.next(this.jobresultsSubject.value.slice(0, 20));
            this.isLoadingSubject.next(false);
            this.paginator?.paginator?.firstPage();
          }
        })
    );
  }

  public setSelectedTabIndex(event): void {
    this.selectedTabIndex = event;
    this.showDownloadHintSubject.next(false);
  }

  public trackByJobId(index: number, element: any): number {
    return element.id;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
