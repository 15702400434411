import { HttpResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { BehaviorSubject, mergeMap, Observable, skip, Subscription, take } from 'rxjs';
import { AccountDeletionRequestInterface } from '../../../models/interface/account-deletion-request.interface';
import { StudyInterface } from '../../../models/interface/study/study.interface';
import { UserInterface } from '../../../models/interface/user.interface';
import { HelperService } from '../../../services/helper/helper.service';
import { StudyStore } from '../../../store/study/component-store/study.store';
import { UserStore } from '../../../store/user/component-store/user.store';

@Component({
  selector: 'app-dialog-admin-account-deletion-requests',
  templateUrl: './dialog-admin-account-deletion-requests.component.html',
  styleUrls: ['./dialog-admin-account-deletion-requests.component.scss'],
  providers: [StudyStore, UserStore]
})
export class DialogAdminAccountDeletionRequestsComponent implements OnInit, OnDestroy {
  // Icons
  faEnvelope = faEnvelope;

  public allDeletionRequestsSubject: BehaviorSubject<Array<AccountDeletionRequestInterface>> = new BehaviorSubject<
    Array<AccountDeletionRequestInterface>
  >([]);

  public allStudiesSubject: BehaviorSubject<Array<StudyInterface>> = new BehaviorSubject<Array<StudyInterface>>([]);
  public selectedDeletionRequest: AccountDeletionRequestInterface;
  public selectedStudies: Array<StudyInterface> = [];

  public user: UserInterface;
  public param = { email: '...' };

  public rejectAccountDeletionRequestResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');
  public acceptAccountDeletionRequestResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');

  public rejectAccountDeletionRequestResponse$: Observable<any>;
  public acceptAccountDeletionRequestResponse$: Observable<any>;

  private allStudies$: Observable<StudyInterface[]>;
  private accountDeletionRequests$: Observable<Array<AccountDeletionRequestInterface>>;
  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DialogAdminAccountDeletionRequestsComponent>,
    private helperService: HelperService,
    private studyStore: StudyStore,
    private userStore: UserStore
  ) {
    this.user = this.data.user;
    this.selectedDeletionRequest = this.data.deletionRequest;
    this.param.email = this.data.user.attributes.email;
    this.allStudies$ = this.studyStore.allStudies$;
    this.accountDeletionRequests$ = this.userStore.accountDeletionRequests$;
    this.rejectAccountDeletionRequestResponse$ = this.userStore.rejectAccountDeletionRequestResponse$;
    this.acceptAccountDeletionRequestResponse$ = this.userStore.acceptAccountDeletionRequestResponse$;
  }

  public get helper() {
    return this.helperService;
  }

  ngOnInit(): void {
    this.studyStore.getAllStudies({ type: 'study' });
    this.subscriptions.push(
      this.allStudies$
        .pipe(
          skip(1),
          take(1),
          mergeMap((result: any) => {
            this.allStudiesSubject.next(result);
            this.userStore.getDeletionRequests({});
            return this.accountDeletionRequests$.pipe(skip(1), take(1));
          })
        )
        .subscribe((result: any) => {
          this.allDeletionRequestsSubject.next(result);
          this.selectedDeletionRequest = this.getDeletionRequest(this.user.id);
          this.selectedStudies = this.getSelectedStudies();
        })
    );
  }

  public getDeletionRequest(userId: number): AccountDeletionRequestInterface {
    return this.allDeletionRequestsSubject.value.find(
      (request: AccountDeletionRequestInterface) => request.attributes.user_id.toString() === userId.toString()
    );
  }

  public getSelectedStudies(): Array<StudyInterface> {
    if (this.selectedDeletionRequest) {
      if (this.selectedDeletionRequest.attributes.studies.length > 0) {
        const studyIds: Array<number> = this.selectedDeletionRequest.attributes.studies.map(entry => entry.study_id);
        return this.allStudiesSubject.value.filter(
          (obj: any) => studyIds.findIndex((value: number) => obj.id.toString() === value.toString()) !== -1
        );
      }
    }
    return [];
  }

  public getECoachManager(studyId: number): Array<string> {
    const entry = this.selectedDeletionRequest.attributes.studies.find(item => item.study_id.toString() === studyId.toString());
    return entry ? entry.ecoach_managers : [];
  }

  public rejectAccountDeletionRequest(): void {
    if (this.rejectAccountDeletionRequestResponse.value === 'DEFAULT') {
      this.rejectAccountDeletionRequestResponse.next('LOADING');
      this.userStore.rejectDeletionRequest({
        requestId: this.selectedDeletionRequest.id
      });
      this.subscriptions.push(
        this.rejectAccountDeletionRequestResponse$.pipe(skip(1), take(1)).subscribe((result: any) => {
          if (result instanceof HttpResponse) {
            this.rejectAccountDeletionRequestResponse.next('SUCCESS');
            setTimeout(() => {
              this.rejectAccountDeletionRequestResponse.next('DEFAULT');
              this.dialogRef.close();
            }, 2500);
          } else {
            this.rejectAccountDeletionRequestResponse.next('FAILURE');
            setTimeout(() => {
              this.rejectAccountDeletionRequestResponse.next('DEFAULT');
            }, 2500);
          }
        })
      );
    }
  }

  public acceptAccountDeletionRequest(): void {
    if (this.acceptAccountDeletionRequestResponse.value === 'DEFAULT') {
      this.acceptAccountDeletionRequestResponse.next('LOADING');
      if (this.selectedDeletionRequest.attributes.pseudonymized === 1) {
        this.userStore.acceptDeletionRequestPseudonymise({
          userId: this.selectedDeletionRequest.attributes.user_id
        });
      } else {
        this.userStore.acceptDeletionRequestDelete({
          userId: this.selectedDeletionRequest.attributes.user_id
        });
      }
      this.subscriptions.push(
        this.acceptAccountDeletionRequestResponse$.pipe(skip(1), take(1)).subscribe((result: any) => {
          if (result instanceof HttpResponse) {
            this.acceptAccountDeletionRequestResponse.next('SUCCESS');
            setTimeout(() => {
              this.acceptAccountDeletionRequestResponse.next('DEFAULT');
              this.dialogRef.close();
            }, 2500);
          } else {
            this.acceptAccountDeletionRequestResponse.next('FAILURE');
            setTimeout(() => {
              this.acceptAccountDeletionRequestResponse.next('DEFAULT');
            }, 2500);
          }
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
