import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of, map, switchMap, catchError } from 'rxjs';
import { LessonLocaleActionTypes, SetLessonLocaleAction, updateLessonLocaleStore } from './lesson-locale.action';

@Injectable()
export class LessonLocaleEffects {
  updateLessonLocale$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LessonLocaleActionTypes.setLessonLocaleType),
      map((action: SetLessonLocaleAction) => action.payload),
      switchMap(payload => of(updateLessonLocaleStore({ lessonLocale: payload })))
    )
  );

  constructor(private actions$: Actions) {}
}
