import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { MyProfileService } from 'src/app/services/api/my-profile/my-profile.service';
import {
  addProfileStore,
  addProfileActivitiesStore,
  addProfileRolesStore,
  GetProfileActivities,
  ProfileActionTypes
} from './profile.action';
import { HttpErrorResponseMessage } from 'src/app/enum/http-error-response-message';
import { ResponseSuccess } from '../utils/response-success';
import { ResponseError } from '../utils/response-error';

@Injectable()
export class ProfileEffects {
  loadProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActionTypes.getProfileType),
      switchMap(() =>
        this.myProfileService.getMyProfile().pipe(
          switchMap(result => {
            const actions = [];
            actions.push(addProfileStore({ myProfile: result.body.data }));
            actions.push(new ResponseSuccess(ProfileActionTypes.getProfileSuccessType, result));
            return actions;
          }),
          catchError(err =>
            of(
              new ResponseError(ProfileActionTypes.getProfileErrorType, {
                message: HttpErrorResponseMessage.genericUnknownError,
                content: err
              })
            )
          )
        )
      )
    )
  );

  loadProfileRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActionTypes.getProfileRolesType),
      switchMap(() =>
        this.myProfileService.getMyRoles().pipe(
          switchMap(result => {
            const actions = [];
            actions.push(addProfileRolesStore({ myRoles: result.body.data }));
            actions.push(new ResponseSuccess(ProfileActionTypes.getProfileRolesSuccessType, result));
            return actions;
          }),
          catchError(err =>
            of(
              new ResponseError(ProfileActionTypes.getProfileRolesErrorType, {
                message: HttpErrorResponseMessage.genericUnknownError,
                content: err
              })
            )
          )
        )
      )
    )
  );

  loadProfileActivities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActionTypes.getProfileActivitiesType),
      map((action: GetProfileActivities) => action.payload),
      switchMap(payload =>
        this.myProfileService.getMyActivities(payload.action, payload.unlimited).pipe(
          switchMap(result => {
            const actions = [];
            actions.push(addProfileActivitiesStore({ myActivities: result.body.data }));
            actions.push(new ResponseSuccess(ProfileActionTypes.getProfileActivitiesSuccessType, result));
            return actions;
          }),
          catchError(err =>
            of(
              new ResponseError(ProfileActionTypes.getProfileActivitiesErrorType, {
                message: HttpErrorResponseMessage.genericUnknownError,
                content: err
              })
            )
          )
        )
      )
    )
  );

  constructor(private actions$: Actions, private myProfileService: MyProfileService) {}
}
