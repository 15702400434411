import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, distinctUntilChanged, Observable, Subscription } from 'rxjs';
import { AnswersheetAnswerInterface } from '../../../../models/interface/answersheet-answer.interface';
import { ConditionalblockInterface } from '../../../../models/interface/condition/conditionalblock.interface';
import { QuestionTableInterface } from '../../../../models/interface/elements-question/question-table/question-table.interface';
import { TableInterface } from '../../../../models/interface/elements-question/question-table/table.interface';
import { ElementInterface } from '../../../../models/interface/elements/element.interface';
import { EvaluationService } from '../../../../services/evaluation/evaluation.service';
import { DynamicAnswersActionTypes } from '../../../../store/dynamic-answers/dynamic-answers.action';

@Component({
  selector: 'app-question-table',
  templateUrl: './question-table.component.html',
  styleUrls: ['./question-table.component.scss']
})
export class QuestionTableComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('answerRequired', { read: ElementRef, static: false }) answerRequiredRef: ElementRef;

  public questionTableForm = this.formBuilder.group({
    selections: this.formBuilder.array([])
  });

  public answers: Array<AnswersheetAnswerInterface> = [];
  public myAnswers: Array<string> = [];
  public values: { min: number; max: number; question: number } = { min: 0, max: 0, question: 0 };
  public range: Array<number> = [];
  public options: Array<string> = [];
  public question;
  public answer;
  public required = false;
  public isDisabled = false;

  public allElements = [];
  public element: QuestionTableInterface | ElementInterface;
  public questionSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public questionsSubject: BehaviorSubject<Array<string>> = new BehaviorSubject<Array<string>>([]);

  public condition: Array<ConditionalblockInterface> = [];

  public evaluation$: Observable<boolean>;
  public hidden$: Observable<boolean>;
  public noAnswerProvided$: Observable<boolean>;

  private dynamicAnswers: Array<AnswersheetAnswerInterface> = [];
  private dynamicAnswers$: Observable<Array<AnswersheetAnswerInterface>>;

  private lessonLocale$: Observable<any>;

  private expressionSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private evaluationSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private hiddenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private noAnswerProvidedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private iteration = 0;
  private nested = 0;

  private content: TableInterface;

  private pageNumber: number;
  private currentPage: number;

  private subscriptions: Subscription[] = [];

  constructor(
    private translateService: TranslateService,
    private evaluationService: EvaluationService,
    private renderer: Renderer2,
    private formBuilder: UntypedFormBuilder,
    private store: Store<{ dynamicAnswers: Array<AnswersheetAnswerInterface>; lessonLocale: any }>
  ) {
    this.dynamicAnswers$ = store.select('dynamicAnswers');
    this.lessonLocale$ = store.select('lessonLocale');
    this.evaluation$ = this.evaluationSubject.asObservable();
    this.hidden$ = this.hiddenSubject.asObservable();
    this.noAnswerProvided$ = this.noAnswerProvidedSubject.asObservable();
  }

  public get fSelectionList(): UntypedFormArray {
    return this.questionTableForm.get('selections') as UntypedFormArray;
  }

  @Input()
  set _element(_element: QuestionTableInterface | ElementInterface) {
    this.element = _element;
    this.values = _element.values;
  }

  @Input()
  set _iteration(_iteration: number) {
    this.iteration = _iteration;
  }

  @Input()
  set _answers(_answers: Array<AnswersheetAnswerInterface>) {
    if (_answers !== undefined) {
      this.answers = _answers;
      this.isDisabled = true;
    }
  }

  // Block condition
  @Input()
  set _condition(_condition: Array<ConditionalblockInterface>) {
    this.condition = _condition;
  }

  @Input()
  set _page(_page) {
    if (_page !== undefined) {
      this.pageNumber = _page;
      if (this.currentPage !== undefined) {
        this.hiddenSubject.next(this.currentPage.toString() !== this.pageNumber.toString());
      }
    }
  }

  @Input()
  set _currentPage(_currentPage) {
    if (_currentPage !== undefined) {
      this.currentPage = _currentPage;
      if (this.pageNumber !== undefined) {
        this.hiddenSubject.next(this.currentPage.toString() !== this.pageNumber.toString());
      }
    }
  }

  @Input()
  set _allElements(_allElements) {
    if (_allElements !== undefined) {
      this.allElements = _allElements;
    }
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.dynamicAnswers$.pipe(distinctUntilChanged()).subscribe(dynamicAnswers => {
        this.dynamicAnswers = dynamicAnswers;
        if (this.question) {
          const questions = [];
          this.question.forEach(element => {
            questions.push(
              this.evaluationService.replaceReference(
                element,
                this.allElements,
                localStorage.getItem('lessonLocale'),
                this.answers,
                this.dynamicAnswers
              )
            );
          });
          this.questionsSubject.next(questions);
        }
        if (this.element.position) {
          this.setAnswersFromDynamicAnswers(dynamicAnswers);
        }
        this.evaluationService.evaluateExpression(
          this.expressionSubject,
          this.evaluationSubject,
          this.condition,
          this.element.position,
          this.answers,
          this.dynamicAnswers
        );
      })
    );

    // Assign translation
    this.content = this.findTranslation();
    this.setQuestionTableChoice(this.content);
    // If answers available
    if (this.answers.length > 0) {
      this.setAnswersFromAnswersheet(this.answers);
    } else {
      this.setAnswersFromDynamicAnswers(this.dynamicAnswers);
    }
    this.subscriptions.push(
      this.translateService.onLangChange.subscribe(
        (event: LangChangeEvent) => {
          this.content = this.findTranslation();
          this.setQuestionTableChoice(this.content);
          this.setAnswersFromAnswersheet(this.answers);
        },
        error => {
          console.error(error);
        }
      )
    );
    this.subscriptions.push(
      this.lessonLocale$.subscribe(() => {
        this.content = this.findTranslation();
        this.setQuestionTableChoice(this.content);
        this.setAnswersFromAnswersheet(this.answers);
      })
    );
    this.evaluationService.evaluateExpression(
      this.expressionSubject,
      this.evaluationSubject,
      this.condition,
      this.element.position,
      this.answers,
      this.dynamicAnswers
    );
  }

  ngAfterViewInit(): void {
    this.injectStyle();
  }

  public injectStyle(): void {
    try {
      this.renderer.setStyle(this.answerRequiredRef.nativeElement.shadowRoot.childNodes[0], 'background', 'transparent');
    } catch (TypeError) {
      setTimeout(() => {
        this.injectStyle();
      }, 100);
    }
  }

  public setAnswersFromAnswersheet(answers: Array<AnswersheetAnswerInterface>): void {
    this.myAnswers = [];
    if (Array.isArray(answers) && answers.length !== 0) {
      answers.forEach((element: AnswersheetAnswerInterface) => {
        if (element.position.toString() === this.element.position.toString()) {
          // Find indexes of values and convert it to value array of option;
          // Values of answersheet
          const values = element.value;
          if (Array.isArray(values) && values.every(value => Array.isArray(value))) {
            const answerValues: Array<any> = values[this.iteration];
            this.myAnswers = answerValues;
            if (this.myAnswers) {
              this.myAnswers = this.myAnswers.map(value => value.toString().trim());
            } else {
              this.myAnswers = [];
              this.noAnswerProvidedSubject.next(true);
            }

            this.questionTableForm.controls['selections'].setValue(answerValues);
          } else {
            this.myAnswers = values;
            if (this.myAnswers) {
              this.myAnswers = this.myAnswers.map(value => value.toString().trim());
            } else {
              this.myAnswers = [];
              this.noAnswerProvidedSubject.next(true);
            }
          }
        }
      });
    }
  }

  public setAnswersFromDynamicAnswers(answers: Array<AnswersheetAnswerInterface>): void {
    const element = answers.find(
      (answer: AnswersheetAnswerInterface) =>
        answer.position.toString() === this.element.position.toString() && answer.parentLoopIndex.toString() === this.iteration.toString()
    );
    if (element) {
      this.questionTableForm.controls['selections'].setValue(element.value);
      if (Array.isArray(element.value) && element.value.every(value => Array.isArray(value))) {
        const answerValues: Array<any> = element.value[this.iteration];
        this.myAnswers = answerValues;
        if (this.myAnswers) {
          this.myAnswers = this.myAnswers.map(value => value.toString().trim());
        } else {
          this.myAnswers = [];
          this.noAnswerProvidedSubject.next(true);
        }
      } else {
        this.myAnswers = element.value;
        if (this.myAnswers) {
          this.myAnswers = this.myAnswers.map(value => value?.toString().trim());
        } else {
          this.myAnswers = [];
          this.noAnswerProvidedSubject.next(true);
        }
      }
      if (!this.answer) {
        this.noAnswerProvidedSubject.next(true);
      }
    }
  }

  public onChange(): void {
    const answerArray: Array<number> = (this.questionTableForm.get('selections') as UntypedFormArray).controls.map(
      (control: AbstractControl) => control.value
    );
    const submittedAnswer: AnswersheetAnswerInterface = {
      value: answerArray,
      position: this.element.position,
      loop: false,
      parentLoopIndex: this.iteration + this.nested
    };
    const findElementIndex = this.dynamicAnswers.findIndex(
      (value: AnswersheetAnswerInterface) =>
        value.position.toString() === submittedAnswer.position.toString() &&
        value.parentLoopIndex.toString() === submittedAnswer.parentLoopIndex.toString()
    );
    if (findElementIndex > -1) {
      const temp = [...this.dynamicAnswers];
      temp[findElementIndex] = submittedAnswer;
      this.dynamicAnswers = temp;
    } else {
      this.dynamicAnswers = [...this.dynamicAnswers, submittedAnswer];
    }
    this.store.dispatch({
      type: DynamicAnswersActionTypes.addDynamicAnswersType,
      payload: this.dynamicAnswers
    });
  }

  public isAnswerFromAnswersheet(values): boolean {
    if (this.myAnswers !== null) {
      return this.myAnswers.includes(values);
    } else {
      return false;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  private findTranslation() {
    const _content = this.element.translations.find(translation => translation.locale === localStorage.getItem('lessonLocale'));
    return _content ? _content : this.element.translations[0];
  }

  private setQuestionTableChoice(content: TableInterface): void {
    this.options = content.answers;
    this.question = content.question;
    this.question.map(() => {
      const control = new UntypedFormControl();
      (this.questionTableForm.get('selections') as UntypedFormArray).push(control);
    });
    this.range = this.fillRange(this.values.min, this.values.max);
    const questions = [];
    this.question.forEach(element => {
      questions.push(
        this.evaluationService.replaceReference(
          element,
          this.allElements,
          localStorage.getItem('lessonLocale'),
          this.answers,
          this.dynamicAnswers
        )
      );
    });
    this.questionsSubject.next(questions);
  }

  private fillRange(start: number, end: number) {
    const isAscending = start < end;
    const targetLength = isAscending ? end - start + 1 : start - end + 1;
    return isAscending
      ? Array(targetLength)
          .fill(0)
          .map((_, index) => start + index)
      : Array(targetLength)
          .fill(0)
          .map((_, index) => start - index);
  }
}
