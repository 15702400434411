import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { skip, take } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserInterface } from '../../../models/interface/user.interface';
import { UserStore } from '../../../store/user/component-store/user.store';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-dialog-patient-account-delete',
  templateUrl: './dialog-patient-account-delete.component.html',
  styleUrls: ['./dialog-patient-account-delete.component.scss'],
  providers: [UserStore]
})
export class DialogPatientAccountDeleteComponent implements OnInit, OnDestroy {
  public patient: UserInterface;
  public myUserId: number;

  public removeUnverifiedAccountResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');

  private deleteUnverifiedPatientAccountResponse$: Observable<any>;

  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private userStore: UserStore,
    private dialogRef: MatDialogRef<DialogPatientAccountDeleteComponent>
  ) {
    this.deleteUnverifiedPatientAccountResponse$ = this.userStore.deleteUnverifiedPatientAccountResponse$;
  }

  ngOnInit(): void {
    this.patient = this.data.user;
    this.myUserId = this.data.myUserId;
  }

  // Remove unverified account as eCoach
  public removeUnverifiedAccount(): void {
    if (this.removeUnverifiedAccountResponse.value === 'DEFAULT') {
      this.removeUnverifiedAccountResponse.next('LOADING');
      this.userStore.deleteUnverifiedPatientAccount({ userIds: [this.patient.id] });
      this.subscriptions.push(
        this.deleteUnverifiedPatientAccountResponse$.pipe(skip(1), take(1)).subscribe(
          result => {
            if (result instanceof HttpResponse) {
              this.removeUnverifiedAccountResponse.next('SUCCESS');
              setTimeout(() => {
                this.removeUnverifiedAccountResponse.next('DEFAULT');
                this.dialogRef.close('SUCCESS');
              }, 2500);
            } else {
              this.removeUnverifiedAccountResponse.next('FAILURE');
              setTimeout(() => {
                this.removeUnverifiedAccountResponse.next('DEFAULT');
              }, 2500);
            }
          },
          () => {
            this.removeUnverifiedAccountResponse.next('FAILURE');
            setTimeout(() => {
              this.removeUnverifiedAccountResponse.next('DEFAULT');
            }, 2500);
          }
        )
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
