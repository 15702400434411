<button [attr.id]="'btnDialogClose'" mat-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title>{{'admin-dashboard.create_new_account' | translate}}</h1>
<div mat-dialog-content class="mat-typography">
  <div [formGroup]="accountForm">
    <mat-form-field [style.width.%]="100" appearance="outline">
      <mat-label>{{'admin-dashboard.email' | translate}}</mat-label>
      <input
        [attr.id]="'email'"
        autocomplete="off"
        formControlName="email"
        matInput
        required
        type="text"
        [errorStateMatcher]="matcher"
        (change)="resetError()"
      />
      <mat-error
        ><span
          *ngIf="((isSubmitted$ | async) && f.get('email').invalid && f.get('email').errors?.required) || (f.get('email').touched && f.get('email').invalid)"
          >{{'admin-dashboard.valid_email_required' | translate}}</span
        >
        <span *ngIf="isEmailAlreadyInUseSubject | async">{{'admin-dashboard.email_already_exists' | translate}}</span>
      </mat-error>
    </mat-form-field>

    <mat-form-field [style.width.%]="100" appearance="outline">
      <mat-label>{{'admin-dashboard.name' | translate}}</mat-label>
      <input [attr.id]="'name'" autocomplete="off" formControlName="name" matInput [errorStateMatcher]="matcher" (change)="resetError()" />
      <mat-error>
        <span
          *ngIf="((isSubmitted$ | async) && f.get('name').invalid && f.get('name').errors?.required) || (f.get('name').touched && f.get('name').invalid)"
          >{{'admin-dashboard.name_required' | translate}}</span
        >
        <span *ngIf="isNameAlreadyInUseSubject | async">{{'admin-dashboard.name_already_used' | translate}}</span>
      </mat-error>
      <mat-error></mat-error>
    </mat-form-field>

    <mat-form-field [style.width.%]="100" appearance="outline">
      <mat-label>{{'admin-dashboard.firstname' | translate}}</mat-label>
      <input [attr.id]="'firstname'" autocomplete="off" formControlName="firstname" matInput />
      <mat-error
        *ngIf="((isSubmitted$ | async) && f.get('firstname').invalid && f.get('firstname').errors?.required) || (f.get('firstname').touched && f.get('firstname').invalid)"
        >{{'admin-dashboard.firstname_required' | translate}}</mat-error
      >
    </mat-form-field>

    <mat-form-field [style.width.%]="100" appearance="outline">
      <mat-label>{{'admin-dashboard.lastname' | translate}}</mat-label>
      <input [attr.id]="'lastname'" autocomplete="off" formControlName="lastname" matInput />
      <mat-error
        *ngIf="((isSubmitted$ | async) && f.get('lastname').invalid && f.get('lastname').errors?.required) || (f.get('lastname').touched && f.get('lastname').invalid)"
        >{{'admin-dashboard.lastname_required' | translate}}</mat-error
      >
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button [attr.id]="'btnCancel'" mat-button mat-dialog-close>{{'admin-dashboard.cancel' | translate}}</button>
  <app-button-feedback
    [_setId]="'btnRegisterNewAccount'"
    (click)="registerNewAccount()"
    [_defaultButtonText]="'admin-dashboard.create_new_account_confirm'"
    [_responseText]="createNewAccountResponse | async"
  ></app-button-feedback>
</div>
