import { createReducer, on } from '@ngrx/store';
import { JobResultInterface } from '../../models/interface/jobresult.interface';
import { addAllJobResultsStore, removeAllJobResultsStore, updateAllJobResultsStore } from './queue.action';

export const initialState: Array<JobResultInterface> = [];

export const allJobResultsReducer = createReducer(
  initialState,
  on(addAllJobResultsStore, (state, { allJobResults }) => allJobResults),
  on(updateAllJobResultsStore, (state, { allJobResults }) => allJobResults),
  on(removeAllJobResultsStore, (state, {}) => [])
);
