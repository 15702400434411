import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { iif, Observable, of } from 'rxjs';
import { catchError, filter, skip, switchMap, take } from 'rxjs/operators';
import { InterventionInterface } from '../../models/interface/intervention.interface';
import { Store } from '@ngrx/store';
import { InterventionActionTypes } from '../../../app/store/intervention/intervention.action';
import { StudyActionTypes } from '../../../app/store/study/study.action';
import { getCollabInterventionById } from '../../store/intervention/intervention.selector';
import { HelperService } from '../../services/helper/helper.service';
import { StudyInterface } from '../../models/interface/study/study.interface';
import { getCollabGroupById } from '../../store/study/study.selector';
import { RoleInterface } from 'src/app/models/interface/role.interface';

@Injectable({
  providedIn: 'root'
})
export class InterventionRoleGuard implements CanActivateChild {
  private intervention$: Observable<InterventionInterface | undefined>;
  private inactiveCollabInterventions$: Observable<Array<InterventionInterface>>;
  private group$: Observable<StudyInterface>;

  constructor(
    private router: Router,
    private store: Store<{
      getCollabInterventionById: InterventionInterface;
      inactiveCollabInterventions: Array<InterventionInterface>;
      getCollabGroupById: StudyInterface;
    }>,
    private helperService: HelperService
  ) {}

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const interventionId = parseInt(state.url.replace(/\D/g, ''), 10);
    this.inactiveCollabInterventions$ = this.store.select('inactiveCollabInterventions');
    this.store.dispatch({
      type: InterventionActionTypes.getInterventionsOfCollabStudyType,
      payload: { typeOfParentStudy: 'study' }
    });
    return this.inactiveCollabInterventions$.pipe(
      skip(1),
      take(1),
      switchMap((inactiveInterventions: Array<InterventionInterface>) => {
        const foundInactiveIntervention = inactiveInterventions.find(
          (intervention: InterventionInterface) => intervention.id.toString() === interventionId.toString()
        );
        if (!!foundInactiveIntervention) {
          // if (state.url.includes('lessons') || state.url.includes('skills') || state.url.includes('diaries')) {
          if (state.url.includes('lessons') || state.url.includes('diaries')) {
            this.store.dispatch({
              type: StudyActionTypes.getCollaboratingStudiesType,
              payload: { include: `owners,roles` }
            });
            this.group$ = this.store.select(getCollabGroupById(foundInactiveIntervention.attributes.study_id));
            return this.group$.pipe(
              skip(1),
              take(1),
              switchMap((group: StudyInterface) => {
                if (group.attributes.type !== 'study') {
                  return of(this.router.parseUrl(`/groups`));
                } else {
                  if (group.relationships?.roles?.data) {
                    const includedRoles: Array<RoleInterface> = group.relationships.roles.data;
                    return iif(
                      () => this.helperService.hasRoles(includedRoles, /study\.(publisher|ecoachmanager|owner)$/),
                      of(true),
                      of(this.router.parseUrl('/interventions'))
                    );
                  }
                  return of(this.router.parseUrl(`/interventions`));
                }
              })
            );
          } else {
            return of(true);
          }
        } else {
          this.intervention$ = this.store.select(getCollabInterventionById(interventionId));
          this.store.dispatch({ type: InterventionActionTypes.getInterventionsOfCollabStudyType, payload: { typeOfParentStudy: 'study' } });
          return this.intervention$.pipe(
            filter((intervention: InterventionInterface | undefined) => !!intervention),
            take(1),
            switchMap((result: any) => {
              const intervention: InterventionInterface = result;
              this.store.dispatch({
                type: StudyActionTypes.getCollaboratingStudiesType,
                payload: { include: `owners,roles` }
              });
              this.group$ = this.store.select(getCollabGroupById(intervention.attributes.study_id));
              return this.group$.pipe(
                skip(1),
                take(1),
                switchMap((group: StudyInterface) => {
                  if (group.attributes.type !== 'study') {
                    return of(this.router.parseUrl(`/groups`));
                  } else {
                    if (group.relationships?.roles?.data) {
                      const includedRoles: Array<RoleInterface> = group.relationships.roles.data;
                      const myRoleSlug = this.helperService.getHighestRoleOfStudy(group);
                      if (!this.helperService.hasRoles(includedRoles, /study\.(publisher|ecoachmanager|owner|access|ecoach)$/)) {
                        return of(this.router.parseUrl(`/interventions`));
                      }
                      if (myRoleSlug.match(/study\.(ecoach|ecoachmanager|owner)$/)) {
                        return of(true);
                      }
                      if (myRoleSlug.match(/study\.(publisher|access)$/)) {
                        return iif(
                          () => state.url.includes('lessons') || state.url.includes('diaries'),
                          of(true),
                          of(this.router.parseUrl(`/interventions/${interventionId}`))
                        );
                      }
                    }
                    return of(this.router.parseUrl(`/interventions`));
                  }
                })
              );
            }),
            catchError(() => of(this.router.parseUrl(`/interventions/${interventionId}`)))
          );
        }
      })
    );
  }
}
