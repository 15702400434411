import { AfterViewInit, Component, Inject, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DiaryAnswersheetsComponent } from '../../answersheet/diary-answersheets/diary-answersheets.component';
import { DiaryInterface } from '../../../models/interface/diary.interface';

@Component({
  selector: 'app-dialog-patient-diary-answersheet-preview',
  templateUrl: './dialog-patient-diary-answersheet-preview.component.html',
  styleUrls: ['./dialog-patient-diary-answersheet-preview.component.scss']
})
export class DialogPatientDiaryAnswersheetPreviewComponent implements OnInit, AfterViewInit {
  @ViewChild('answersheetsPreviewContainer', { read: ViewContainerRef }) answersheetsPreviewContainer: ViewContainerRef;

  public components;

  public diaryInstanceId: number;
  public diary: DiaryInterface;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    this.diaryInstanceId = this.data.diary_instance_id;
    this.diary = this.data.diary;
  }

  ngAfterViewInit(): void {
    this.components = [];
    if (this.answersheetsPreviewContainer) {
      this.answersheetsPreviewContainer.clear();
    }
    const component = this.answersheetsPreviewContainer.createComponent<DiaryAnswersheetsComponent>(DiaryAnswersheetsComponent).instance;
    component._diary_instance_id = this.diaryInstanceId;
    component._diary = this.diary;
    this.components = [...this.components, component];
  }
}
