import { createReducer, on } from '@ngrx/store';
import { InterventionInstanceInterface } from 'src/app/models/interface/intervention-instances/intervention-instance.interface';
import {
  addAllInstancesForECoachStore,
  removeAllInstancesForECoachStore,
  updateAllInstancesForECoachStore
} from './intervention-instance.action';

export const initialAllInstancesForECoachState: Array<InterventionInstanceInterface> = [];
export const allInstancesForECoachReducer = createReducer(
  initialAllInstancesForECoachState,
  on(addAllInstancesForECoachStore, (state, { allInstancesForECoach }) => allInstancesForECoach),
  on(updateAllInstancesForECoachStore, (state, { allInstancesForECoach }) => allInstancesForECoach),
  on(removeAllInstancesForECoachStore, (state, {}) => [])
);
