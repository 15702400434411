/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of, Subscription, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { filter, mergeMap, skip, switchMap, take, tap } from 'rxjs/operators';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faComments } from '@fortawesome/free-solid-svg-icons/faComments';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons/faMinusCircle';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { HelperActivityService } from '../../../services/helper/helper-activity/helper-activity.service';
import { InterventionSharedService } from '../../../services/shared/intervention-shared/intervention-shared.service';
import { InterventionInstanceInterface } from '../../../models/interface/intervention-instances/intervention-instance.interface';
import { ActivityInterface } from '../../../models/interface/activity.interface';
import { TableSortInterface } from '../../../models/interface/table/table_sort.interface';
import { InterventionInterface } from '../../../models/interface/intervention.interface';
import { HelperService } from '../../../services/helper/helper.service';
import { UserInterface } from '../../../models/interface/user.interface';
import { MessageThreadsInterface } from '../../../models/interface/message_threads.interface';
import { LessonInterface } from '../../../models/interface/lesson.interface';
import { HelperDialogService } from '../../../services/helper/helper-dialog/helper-dialog.service';
import { Store } from '@ngrx/store';
import { MessageActionTypes } from '../../../store/message/message.action';
import { UserActionTypes } from '../../../store/user/user.action';
import { InterventionInstanceStore } from '../../../store/intervention-instance/component-store/intervention-instance.store';
import { MessageStore } from '../../../store/message/component-store/message.store';

/**
 * Component:
 * Intervention tasks page displaying a list of all tasks regarding the intervention;
 * Can be found: {uri}/intervention/{{intervention_id}}/tasks
 */

@Component({
  selector: 'app-intervention-task',
  templateUrl: './intervention-task.component.html',
  styleUrls: ['./intervention-task.component.scss'],
  providers: [InterventionInstanceStore, MessageStore]
})
export class InterventionTaskComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild('instanceDetailContainer', { read: ViewContainerRef }) instanceDetailContainer: ViewContainerRef;

  public displayedColumns: string[] = ['activity_text', 'performed_by', 'created_at', 'status'];
  public dataSource;

  // Icons
  faMinusCircle = faMinusCircle;
  faPlusCircle = faPlusCircle;
  faComments = faComments;
  faCheck = faCheck;
  faTimes = faTimes;

  // Study ID and loading status for this child component
  public studyId: number;
  public isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public isLoading$: Observable<boolean>;

  public isManager$: Observable<boolean>;

  // Data provided by UserService
  public users: Array<UserInterface> = [];
  public lessons: Array<LessonInterface> = [];

  public feedbackPartipants: Array<MessageThreadsInterface> = [];

  public tasksSubject: BehaviorSubject<Array<ActivityInterface>> = new BehaviorSubject([]);
  public myTasks$ = this.tasksSubject.asObservable();

  // Table sort
  public tableSort: TableSortInterface = {
    sortActivityId: null,
    sortAction: null,
    sortActionId: null,
    sortText: null,
    sortPerformedBy: null,
    sortCreatedAt: null
  };

  // Translation for study name
  public param = { intervention_name: '...' };

  private intervention: InterventionInterface;
  private allInstancesOfIntervention: Array<InterventionInstanceInterface> = [];

  // Intervention user role
  private isManagerSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private allInstancesOfInterventionForECoach$: Observable<Array<InterventionInstanceInterface>>;
  private activitiesOfMultipleInstances$: Observable<{ activities: Array<ActivityInterface>; questionnaires: Array<LessonInterface> }>;
  private allMessageThreads$: Observable<Array<MessageThreadsInterface>>;

  private inboxFolderAnswersheet$: Observable<Array<MessageThreadsInterface>>;

  private studyActivities$: Observable<Array<ActivityInterface>>;

  private myActivities: Array<ActivityInterface> = [];
  private myTasks: Array<ActivityInterface> = [];

  private threads: Array<MessageThreadsInterface> = [];

  private tableContentSubject: BehaviorSubject<Array<ActivityInterface>> = new BehaviorSubject([]);

  private myMembers$: Observable<Array<UserInterface>>;

  private subscriptions: Subscription[] = [];

  constructor(
    private sharedService: InterventionSharedService,
    private helperService: HelperService,
    private router: Router,
    private interventionInstanceStore: InterventionInstanceStore,
    private messageStore: MessageStore,
    private store: Store<{ allMessageThreads: Array<MessageThreadsInterface>; myMembers: Array<UserInterface> }>,
    private helperActivityService: HelperActivityService,
    private helperDialogService: HelperDialogService,
    private dialog: MatDialog
  ) {
    router.events.subscribe(() => {
      this.dialog.closeAll();
    });

    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.isManager$ = this.isManagerSubject.asObservable();
    this.allInstancesOfInterventionForECoach$ = this.interventionInstanceStore.allInstancesOfInterventionForECoach$;
    this.activitiesOfMultipleInstances$ = this.interventionInstanceStore.activitiesOfMultipleInstances$;
    this.allMessageThreads$ = this.store.select('allMessageThreads');
    this.inboxFolderAnswersheet$ = this.messageStore.inboxFolderAnswersheet$;
    this.myMembers$ = this.store.select('myMembers');
  }

  public get helper() {
    return this.helperService;
  }

  public get helperDialog() {
    return this.helperDialogService;
  }

  public get helperActivity() {
    return this.helperActivityService;
  }

  ngOnInit(): void {
    this.store.dispatch({
      type: UserActionTypes.getMyMembersType,
      payload: {}
    });
    this.subscriptions.push(
      this.myMembers$.subscribe(
        result => {
          this.users = result;
        },
        () => {
          this.users = [];
        }
      )
    );

    this.subscriptions.push(
      this.sharedService.intervention$
        .pipe(
          filter(intervention => !!intervention),
          mergeMap((intervention: InterventionInterface) => {
            this.intervention = intervention;
            this.studyId = intervention.attributes.study_id;
            this.param = { intervention_name: intervention.attributes.name };
            this.interventionInstanceStore.getAllInstancesOfInterventionForECoach({ interventionId: this.intervention.id });
            return this.allInstancesOfInterventionForECoach$.pipe(skip(1), take(1));
          }),
          mergeMap((result: Array<InterventionInstanceInterface>) => {
            this.allInstancesOfIntervention = result;
            const payload: Array<{ instanceId: number; include: string }> = this.allInstancesOfIntervention.map(
              (value: InterventionInstanceInterface) => ({
                instanceId: parseInt(value.id.toString(), 10),
                include: 'questionnaires'
              })
            );
            if (payload.length > 0) {
              this.interventionInstanceStore.getActivitiesOfMultipleInstances(payload);
              return this.activitiesOfMultipleInstances$.pipe(
                skip(1),
                take(1),
                switchMap((res: { activities: Array<ActivityInterface>; questionnaires: Array<LessonInterface> }) => {
                  this.lessons = res.questionnaires;
                  return of(res.activities);
                })
              );
            }
            return throwError(result);
          }),
          mergeMap((results: Array<any>) => {
            this.myActivities = results;
            this.myTasks = this.helperService.getActivityByAction(
              this.myActivities,
              'ANSWERSHEET_SUBMITTED',
              'INTERVENTION_INSTANCE_CAN_UNLOCK'
            );

            this.tasksSubject.next(
              this.helperService.getActivityByNewest(this.helperActivity.filterPendingTasks(this.myTasks, this.feedbackPartipants))
            );
            this.tableContentSubject.next(this.tasksSubject.value);
            this.dataSource = this.tableContentSubject.value;
            this.dataSource.sort = this.sort;
            const currentPageSize = this.paginator ? this.paginator.pageSize : 20;
            this.dataSource = this.tableContentSubject.value.slice(0, currentPageSize);
            if (this.paginator) {
              this.paginator.firstPage();
            }
            const reqs: Array<Observable<any>> = [];
            this.messageStore.getInboxFolder({ include: 'participants', answersheetIdFlag: true });
            reqs.push(this.inboxFolderAnswersheet$.pipe(skip(1), take(1)));
            this.store.dispatch({
              type: MessageActionTypes.getAllMessagesThreadsType,
              payload: { answersheetFlag: true, include: 'messages,participants' }
            });
            reqs.push(this.allMessageThreads$.pipe(skip(1), take(1)));
            return forkJoin(reqs);
          })
        )
        .subscribe(
          (results: any) => {
            this.feedbackPartipants = results[0].filter((thread: MessageThreadsInterface) => thread.attributes.answersheet_id !== null);
            this.threads = results[1];
            this.isLoadingSubject.next(false);
          },
          () => {
            this.isLoadingSubject.next(false);
          }
        )
    );
  }

  public getStatus(activity): boolean {
    return this.helperActivity.isTaskCompleted(activity, this.threads);
  }

  public getAnswersheetId(activity: ActivityInterface): number {
    const path = activity.attributes.uri;
    if (path !== null) {
      return parseInt(path.substring(path.lastIndexOf('/') + 1), 10);
    }
    return null;
  }

  public showAnswersheet(activity: ActivityInterface): void {
    this.router.navigateByUrl(`/feedback-overview/answersheets/${this.getAnswersheetId(activity)}`);
  }

  public onPageChangeActivity(event: PageEvent): void {
    const startIndex = event.pageIndex * event.pageSize;
    const endIndex =
      startIndex + event.pageSize > this.tasksSubject.value.length ? this.tasksSubject.value.length : startIndex + event.pageSize;
    if (this.sort.direction !== '') {
      this.sortData(this.sort, this.tasksSubject.value.slice());
      this.tableContentSubject.next(this.tableContentSubject.value.slice(startIndex, endIndex));
    } else {
      this.tableContentSubject.next(this.tasksSubject.value.slice(startIndex, endIndex));
    }
    this.dataSource = this.tableContentSubject.value;
    this.dataSource.sort = this.sort;
  }

  public sortData(sort: Sort, data: Array<ActivityInterface>): void {
    const isDesc = sort.direction === 'desc';
    switch (sort.active) {
      case 'activity_text':
        this.tableContentSubject.next(
          data.sort((a, b) => {
            const behavior = new BehaviorSubject<number>(null);
            this.subscriptions.push(
              forkJoin([this.helperActivity.getActivityText(a), this.helperActivity.getActivityText(b)]).subscribe(
                (activities: string[]) => {
                  const act1 =
                    a.attributes.action === 'ANSWERSHEET_SUBMITTED'
                      ? activities[0] + this.helperActivity.getLessonNameOfActivity(a, this.lessons)
                      : activities[0];
                  const act2 =
                    b.attributes.action === 'ANSWERSHEET_SUBMITTED'
                      ? activities[1] + this.helperActivity.getLessonNameOfActivity(b, this.lessons)
                      : activities[1];
                  if (isDesc) {
                    behavior.next(act2.localeCompare(act1));
                  } else {
                    behavior.next(act1.localeCompare(act2));
                  }
                }
              )
            );
            return behavior.value;
          })
        );
        this.dataSource = this.tableContentSubject.value;
        break;
      case 'performed_by':
        this.helperActivityService.toggleSortPerformedBy(data, isDesc, this.tableContentSubject, this.users, this.tableSort);
        this.dataSource = this.tableContentSubject.value;
        break;
      case 'created_at':
        this.helperActivityService.toggleSortCreated(data, isDesc, this.tableContentSubject, this.tableSort);
        this.dataSource = this.tableContentSubject.value;
        break;
    }
  }

  public onChangeSortTable(sort: Sort): void {
    const data: Array<ActivityInterface> = this.tableContentSubject.value.slice();
    const isDefault = sort.direction === '';
    const currentPageSize = this.paginator ? this.paginator.pageSize : 20;
    if (isDefault) {
      this.dataSource = data.slice(0, currentPageSize);
    } else {
      this.sortData(sort, data);
      this.dataSource = this.tableContentSubject.value.slice(0, currentPageSize);
    }
    this.paginator.firstPage();
  }

  public openDialogInterventionInstanceDetails(activity: ActivityInterface): void {
    const instanceId =
      activity.attributes.uri !== undefined ? this.helper.getLastIndexIDOfURI(activity.attributes.uri) : activity.attributes.entity_id;
    this.helperDialog
      .openDialogInterventionInstanceDetails(instanceId, this.param, 1)
      .afterClosed()
      .subscribe(result => {});
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
