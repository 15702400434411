<!-- Detail -->
<button
  [attr.id]="'btnToggleShowing_' + element.id + '_' + pageIteration + '_' + nested"
  (click)="toggleShowing()"
  *ngIf="((element?.type === 'details' || (element?.type === 'both')) && (evaluation$ | async) && (pageNumber - 1 === currentPage))"
  [hidden]="hidden$ | async"
  mat-button
>
  <b>
    <fa-icon *ngIf="(toggle$ | async); else elseDetailsBlock" [icon]="faChevronDown"></fa-icon>
    <ng-template #elseDetailsBlock>
      <fa-icon [icon]="faChevronUp"></fa-icon>
    </ng-template>
    {{content.text}}</b
  >
</button>
<div [hidden]="(toggle$ | async) && (element?.type === 'details' || (element?.type === 'both')) && (pageNumber - 1 === currentPage)">
  <ng-template #elementsBlockContainer> </ng-template>
</div>
