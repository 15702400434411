<mat-card appearance="outlined">
  <mat-card-content>
    <div class="row">
      <div class="col-12 col-sm-4 center">
        <p *ngIf="isAdmin">{{user.attributes.firstname}} {{user.attributes.lastname}} ({{user.attributes.name}})</p>
        <p>{{user.attributes.email}}</p>
      </div>
      <div class="col-12 col-sm-4 center">
        <ng-container *ngIf="user.attributes.is_verified === 1; then thenShowRoles else elseShowResendVerification"></ng-container>
        <ng-template #thenShowRoles>
          <div class="row align-items-center text-center">
            <span class="col-12 text-success" [ngClass]="{'col-md-3': isAdmin, 'col-md-9': !isAdmin}">
              <fa-icon [icon]="faCheckCircle"></fa-icon>
              {{'patient.is_verified' | translate}}
            </span>
            <span *ngIf="isAdmin; then thenShowAdmin else elseShowDefault"> </span>
            <ng-template #thenShowAdmin
              ><span
                class="col-12 col-md-3"
                [ngClass]="{'font-weight-bold': isUserInRole(user, 'admin'), 'text-muted' : !isUserInRole(user, 'admin') }"
              >
                {{'admin-dashboard.admin' | translate}} <fa-icon [icon]="isUserInRole(user, 'admin') ? faCheck : faTimes"></fa-icon>
              </span>
              <span
                class="col-12 col-md-3"
                [ngClass]="{'font-weight-bold': isUserInRole(user, 'ecoach'), 'text-muted' : !isUserInRole(user, 'ecoach') }"
              >
                {{'admin-dashboard.ecoach' | translate}} <fa-icon [icon]="isUserInRole(user, 'ecoach') ? faCheck : faTimes"></fa-icon>
              </span>
              <span
                class="col-12 col-md-3"
                [ngClass]="{'font-weight-bold': isUserInRole(user, 'editor'), 'text-muted' : !isUserInRole(user, 'editor') }"
              >
                {{'admin-dashboard.editor' | translate}}
                <fa-icon [icon]="isUserInRole(user, 'editor') ? faCheck : faTimes"></fa-icon> </span
            ></ng-template>
            <ng-template #elseShowDefault>
              <div class="col-12 col-md-3">
                <button
                  [attr.id]="'btnOpenDialogInvitation_' + user.id"
                  *ngIf="(user.attributes.is_verified === 1) && hasPendingInvitations"
                  class="center"
                  class="my-1"
                  (click)="openDialogInvitation(user)"
                  mat-button
                  matBadge="!"
                  matBadgeSize="small"
                  type="button"
                >
                  <fa-icon [icon]="faEnvelope"></fa-icon>
                  {{ 'patient.pending_invitations' | translate }}
                </button>
              </div>
            </ng-template>
          </div>
        </ng-template>
        <ng-template #elseShowResendVerification>
          <div class="row align-items-center text-center">
            <span class="col-12 col-md-6 text-danger">
              <fa-icon [icon]="faTimesCircle"></fa-icon>
              {{'patient.is_not_verified' | translate}}
            </span>
            <div class="col-12 col-md-6">
              <app-button-feedback
                (click)="resendVerificationEmail(user.attributes.email)"
                [_defaultButtonText]="'admin-dashboard.resend_verification'"
                [_responseText]="resendVerificationResponse | async"
              ></app-button-feedback>
            </div>
          </div>
        </ng-template>
      </div>
      <div class="col-12 col-sm-4 center">
        <div *ngIf="isAdmin">
          <button mat-button color="primary" (click)="openDialogUserUpdate()"><fa-icon [icon]="faPen"></fa-icon></button>
          <button mat-button color="primary" (click)="openDialogUserDelete()"><fa-icon [icon]="faTrash"></fa-icon></button>
          <button mat-button color="primary" *ngIf="hasDeletionRequest(user.id)" (click)="openDialogAccountRequests()">
            <fa-icon [icon]="faMessage"></fa-icon> {{'admin-dashboard.deletion_request' | translate}}
          </button>
        </div>

        <div *ngIf="userAsPatient">
          <div *ngIf="(user.attributes.is_verified === 1); then showVerifiedButtons else showUnverifiedButtons"></div>
          <ng-template #showVerifiedButtons>
            <button
              [attr.id]="'btnGetDetailedPatient' + user.id"
              class="mb-2"
              (click)="getDetailedPatient(user.id)"
              color="primary"
              mat-button
            >
              {{ 'patient.show_patient_details' | translate }}
            </button>
            <button
              [attr.id]="'btnOpenDialogStudyInvite' + user.id"
              class="mb-2"
              (click)="openDialogStudyInvite(user)"
              color="primary"
              mat-button
              type="button"
            >
              {{ 'patient.invite_patient_to_study' | translate }}
            </button>

            <button
              [attr.id]="'btnOpenDialogInstanceCreate' + user.id"
              class="mb-2"
              (click)="openDialogInstanceCreate(user)"
              color="primary"
              mat-button
              type="button"
            >
              {{ 'patient.assign_patient_to_intervention' | translate }}
            </button>
          </ng-template>
          <ng-template #showUnverifiedButtons>
            <button
              class="mb-2"
              [attr.id]="'btnOpenDialogDeleteUnverified_' + user.id"
              (click)="openDialogDeleteUnverified(user)"
              mat-button
              type="button"
            >
              <fa-icon [icon]="faTrash"></fa-icon>
              {{ 'patient.delete_my_unverified_patient' | translate}}
            </button>
          </ng-template>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
