import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { UserInterface } from '../../../models/interface/user.interface';
import { AlertService } from '../../../services/alert/alert.service';
import { filter, take } from 'rxjs/operators';
import { ProfileInterface } from '../../../models/interface/profile.interface';
import { HelperDialogService } from '../../../services/helper/helper-dialog/helper-dialog.service';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons/faUserPlus';

/**
 * Component:
 * Patient account creation overview component - parent component to create patient accounts and assign them to interventions;
 */

@Component({
  selector: 'app-patient-creation-overview',
  templateUrl: './patient-creation-overview.component.html',
  styleUrls: ['./patient-creation-overview.component.scss'],
  providers: [AlertService]
})
export class PatientCreationOverviewComponent implements OnInit, OnDestroy {
  @Output()
  patientCreationEvent = new EventEmitter<string>();

  @ViewChild('createPatientAccountContainer', { read: ViewContainerRef }) createPatientAccountContainer: ViewContainerRef;

  // Icons
  public faUserPlus = faUserPlus;

  public isLoadingButton$: Observable<boolean>;

  public profile$: Observable<UserInterface>;

  private myUserId: number = null;

  private isLoadingButtonSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private subscriptions: Subscription[] = [];

  constructor(private helperDialogService: HelperDialogService, private store: Store<{ myProfile: ProfileInterface }>) {
    this.isLoadingButton$ = this.isLoadingButtonSubject.asObservable();
    this.profile$ = store.select('myProfile');
  }

  public get helperDialog() {
    return this.helperDialogService;
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.profile$.pipe(filter(user => user !== null)).subscribe((result: ProfileInterface) => {
        this.myUserId = result.id;
      })
    );
  }

  public openDialogPatientAccountCreate(): void {
    this.helperDialog
      .openDialogAccountInterventionInstanceAssignment('accountCreation', this.myUserId)
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        this.patientCreationEvent.emit(result);
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
