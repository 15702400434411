<div [hidden]="isHidden$ | async">
  <div *ngIf="(isModalLoading$ | async); else elseShowInstances">
    <app-loader></app-loader>
  </div>

  <ng-template #elseShowInstances>
    <div *ngFor="let form of questionnairesIntervention; let i = index">
      <div [attr.id]="'page_' + i" [hidden]="(page$ | async) !== i">
        <app-instance-creation-form
          (confirmedForm)="onConfirmCreation($event)"
          [_collaborators]="helper.excludeUsersWithOnlyStudyAccess(form.collaborators)"
          [_intervention]="form.intervention"
          [_members]="[]"
          [_myUserId]="myUserId"
          [_questionnaires]="form.questionnaires"
        ></app-instance-creation-form>
      </div>
    </div>

    <div class="container row">
      <div class="col-sm-1 vertical-center">
        <button
          [attr.id]="'btnShowPrevious'"
          (click)="showPrevious()"
          *ngIf="((page$ | async) !== 0) && (numbersOfPages > 1)"
          class="button-design"
        >
          <fa-icon [icon]="faArrowLeft"></fa-icon>
        </button>
      </div>
      <div class="col-sm-10 vertical-center">
        <div>
          {{'patient-creation-instance-creation.modal_patient_configure_instance_selected_intervention' | translate}} -
          <strong
            >{{selectedInterventionByInterventionId(questionnairesIntervention[pageSubject.value].intervention_id).attributes.title}}</strong
          >
          - {{'patient-creation-instance-creation.modal_patient_configure_instance_page' | translate}} {{pageSubject.value + 1}}
          {{'patient-creation-instance-creation.modal_patient_configure_instance_page_of' | translate}} {{numbersOfPages}}
        </div>
      </div>
      <div class="col-sm-1 vertical-center">
        <button
          [attr.id]="'btnShowNext'"
          (click)="showNext()"
          *ngIf="((page$ | async) !== (numbersOfPages - 1)) && (numbersOfPages > 1)"
          class="button-design"
        >
          <fa-icon [icon]="faArrowRight"></fa-icon>
        </button>
      </div>
    </div>
  </ng-template>
</div>
