import { Action, createAction, props } from '@ngrx/store';

export enum AuthenticationActionTypes {
  updateIsAuthenticatedStoreType = 'Update IsAuthenticated Store',
  deleteIsAuthenticatedStoreType = 'Delete IsAuthenticated Store',

  forcedLogoutStoreType = 'Perform forced logout',
  checkCookieAuthStoreType = 'Check available valid JWT cookie',
  checkCookieAuthStoreSuccessType = '[Success] Check available valid JWT cookie',

  loginType = '[Authentication API] Login',
  loginSuccessType = '[Success] Login',
  loginErrorType = '[Error] Login',

  refreshTokenType = '[Authentication API] Refresh Token',
  refreshTokenSuccessType = '[Success] Refresh Token',
  refreshTokenErrorType = '[Error] Refresh Token',

  logoutType = '[Authentication API] Logout',
  logoutSuccessType = '[Success] Logout',
  logoutErrorType = '[Error] Logout'
}

export const updateIsAuthenticatedStore = createAction(
  AuthenticationActionTypes.updateIsAuthenticatedStoreType,
  props<{ isAuthenticated: boolean }>()
);
export const removeIsAuthenticatedStore = createAction(AuthenticationActionTypes.deleteIsAuthenticatedStoreType);

export const forcedLogoutStore = createAction(AuthenticationActionTypes.forcedLogoutStoreType);

export class Login implements Action {
  readonly type: string = AuthenticationActionTypes.loginType;
  constructor(readonly payload: any) {}
}
export class RefreshToken implements Action {
  readonly type: string = AuthenticationActionTypes.refreshTokenType;
  constructor() {}
}

export class Logout implements Action {
  readonly type: string = AuthenticationActionTypes.logoutType;
  constructor() {}
}
