/* eslint-disable max-len */
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subscription, filter, take, mergeMap, of, switchMap, distinctUntilChanged } from 'rxjs';
import { PayloadInterface } from 'src/app/models/interface/payload.interface';
import { HelperService } from '../../../services/helper/helper.service';
import { ReminderStore } from '../../../store/reminder/component-store/reminder.store';

@Component({
  selector: 'app-dialog-intervention-instance-reminder-create',
  templateUrl: './dialog-intervention-instance-reminder-create.component.html',
  styleUrls: ['./dialog-intervention-instance-reminder-create.component.scss'],
  providers: [ReminderStore]
})
export class DialogInterventionInstanceReminderCreateComponent implements OnInit, OnDestroy {
  // Reminder form submission
  public reminderForm: UntypedFormGroup;

  public createInterventionInstanceReminderResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');

  public isSubmitted$: Observable<boolean>;

  public createInterventionInstanceReminderResponse$: Observable<any>;

  public existingReminderConfig: PayloadInterface;

  public userId: number;
  public instanceId: number;

  public languages = ['de', 'en'];
  public selectedLanguage;
  public selectedLanguageSubject: BehaviorSubject<any> = new BehaviorSubject<any>('');

  public defaultReminderText;

  public unitList = ['minute', 'hour', 'day'];

  // Ngx datetimepicker
  public showSpinners = false;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate = new Date();
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';

  private isSubmittedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<DialogInterventionInstanceReminderCreateComponent>,
    private helperService: HelperService,
    private reminderStore: ReminderStore,
    private httpClient: HttpClient
  ) {
    this.selectedLanguageSubject.next(this.languages[0]);
    this.selectedLanguageSubject
      .pipe(switchMap(language => this.httpClient.get(`../../../../assets/i18n/${language}.json`)))
      .subscribe(translation => {
        this.defaultReminderText = translation;
      });
    this.isSubmitted$ = this.isSubmittedSubject.asObservable();
    this.reminderForm = new UntypedFormGroup({
      starts_at: new UntypedFormControl('', [Validators.required]),
      max_times: new UntypedFormControl('', [Validators.required]),
      time_interval: new UntypedFormControl('', [Validators.required]),
      time_interval_unit: new UntypedFormControl(this.unitList[0]),
      content_text: new UntypedFormControl(''),
      content_ending: new UntypedFormControl('')
    });
    this.reminderForm.controls['starts_at'].setValue(new Date());

    this.createInterventionInstanceReminderResponse$ = this.reminderStore.createInterventionInstanceReminderResponse$;
  }

  public get f() {
    return this.reminderForm;
  }

  public get helper() {
    return this.helperService;
  }

  get selectedLanguageOnly(): string {
    return this.selectedLanguageSubject.value;
  }

  set selectedLanguageOnly(value: string) {
    this.selectedLanguageSubject.next(value);
  }

  ngOnInit(): void {
    this.instanceId = this.data.instanceId;
    this.userId = this.data.userId;
    this.existingReminderConfig = this.data.reminderConfig;
    const startsAt = this.helperService.convertStringToUnixTimestamp(
      this.f.get('starts_at').value || this.f.get('starts_at').value === '' ? this.f.get('starts_at').value : undefined
    );
    if (this.existingReminderConfig) {
      const reminderConfig = this.existingReminderConfig.data.attributes;
      this.f.get('starts_at').patchValue(new Date(this.helper.convertUnixTimestampToDate(reminderConfig.configuration.start)));
      this.f.get('max_times').patchValue(reminderConfig.configuration.max_times ? reminderConfig.configuration.max_times : '');
      this.f.get('time_interval').patchValue(reminderConfig.configuration.pause ? reminderConfig.configuration.pause : '');
      this.f.get('time_interval_unit').patchValue(reminderConfig.configuration.time_unit ? reminderConfig.configuration.time_unit : '');
      this.f.get('content_text').patchValue(reminderConfig.content.text ? reminderConfig.content.text : '');
      this.f.get('content_ending').patchValue(reminderConfig.content.ending ? reminderConfig.content.ending : '');
    }
  }

  public getReminderGreeting(): string {
    if (this.defaultReminderText) {
      return this.defaultReminderText['intervention-instance-reminder']['default_reminder_message_head'];
    } else {
      return '';
    }
  }

  public getReminderMessage(): string {
    if (this.defaultReminderText) {
      return this.defaultReminderText['intervention-instance-reminder']['default_reminder_message'];
    } else {
      return '';
    }
  }

  public getReminderEndingHead(): string {
    if (this.defaultReminderText) {
      return this.defaultReminderText['intervention-instance-reminder']['default_reminder_ending_head'];
    } else {
      return '';
    }
  }

  public getReminderEnding(): string {
    if (this.defaultReminderText) {
      return this.defaultReminderText['intervention-instance-reminder']['default_reminder_ending'];
    } else {
      return '';
    }
  }

  public setInterventionInstanceReminder(): void {
    this.isSubmittedSubject.next(true);
    if (this.reminderForm.invalid) {
      return;
    }

    const startsAt = this.helperService.convertStringToUnixTimestamp(
      this.f.get('starts_at').value || this.f.get('starts_at').value === '' ? this.f.get('starts_at').value : undefined
    );
    const maxTimes = this.f.get('max_times').value || this.f.get('max_times').value === '' ? this.f.get('max_times').value : undefined;
    const timeInterval =
      this.f.get('time_interval').value || this.f.get('time_interval').value === '' ? this.f.get('time_interval').value : undefined;
    const timeIntervalUnit =
      this.f.get('time_interval_unit').value || this.f.get('time_interval_unit').value === ''
        ? this.f.get('time_interval_unit').value
        : undefined;
    const contentText =
      this.f.get('content_text').value || this.f.get('content_text').value === '' ? this.f.get('content_text').value : undefined;
    const contentEnding =
      this.f.get('content_ending').value || this.f.get('content_ending').value === '' ? this.f.get('content_ending').value : undefined;
    if (!!this.userId && !!this.instanceId) {
      if (this.createInterventionInstanceReminderResponse.value === 'DEFAULT') {
        this.createInterventionInstanceReminderResponse.next('LOADING');

        this.reminderStore.createInterventionInstanceReminder({
          instanceId: this.instanceId,
          receiverId: this.userId,
          maxTimes,
          start: startsAt,
          repeatTime: timeInterval,
          repeatUnit: timeIntervalUnit,
          contentText,
          contentEnding,
          locale: this.selectedLanguageSubject.value
        });

        this.subscriptions.push(
          this.createInterventionInstanceReminderResponse$
            .pipe(
              filter(value => !!value),
              take(1)
            )
            .subscribe((value: any) => {
              if (value instanceof HttpResponse) {
                this.createInterventionInstanceReminderResponse.next('SUCCESS');
                setTimeout(() => {
                  this.createInterventionInstanceReminderResponse.next('DEFAULT');
                  this.dialogRef.close(value);
                }, 2500);
              } else {
                this.createInterventionInstanceReminderResponse.next('FAILURE');
                setTimeout(() => {
                  this.createInterventionInstanceReminderResponse.next('DEFAULT');
                }, 2500);
              }
            })
        );
      }
    } else {
      const payload: PayloadInterface = {
        data: {
          type: 'reminders',
          attributes: {
            receiver_id: this.userId ? this.userId : null,
            configuration: {
              max_times: maxTimes,
              start: startsAt,
              pause: timeInterval,
              time_unit: timeIntervalUnit
            },
            content: {
              text: contentText,
              ending: contentEnding,
              locale: this.selectedLanguageSubject.value
            }
          }
        }
      };
      this.dialogRef.close(payload);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
