import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, Observable, Subscription } from 'rxjs';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { AnswersheetAnswerInterface } from '../../../../models/interface/answersheet-answer.interface';
import { QuestionYesNoInterface } from '../../../../models/interface/elements-question/question-yes-no/question-yes-no.interface';
import { ConditionalblockInterface } from '../../../../models/interface/condition/conditionalblock.interface';
import { ElementInterface } from '../../../../models/interface/elements/element.interface';
import { EvaluationService } from '../../../../services/evaluation/evaluation.service';
import { YesNoInterface } from '../../../../models/interface/elements-question/question-yes-no/yes-no.interface';
import { Store } from '@ngrx/store';
import { DynamicAnswersActionTypes } from '../../../../store/dynamic-answers/dynamic-answers.action';

/**
 * Component:
 * Represents yes/no element
 */

@Component({
  selector: 'app-question-yes-no',
  templateUrl: './question-yes-no.component.html',
  styleUrls: ['./question-yes-no.component.scss', '../../../../../assets/styles/aas2_app.scss']
})
export class QuestionYesNoComponent implements OnInit, OnDestroy {
  @ViewChild('yesButton', { read: ElementRef }) yesButton: ElementRef;
  @ViewChild('noButton', { read: ElementRef }) noButton: ElementRef;

  // Icon
  faInfoCircle = faInfoCircle;

  public question = '';
  public answer: boolean;
  public required = false;
  public isDisabled = false;

  public allElements = [];
  public element: QuestionYesNoInterface | ElementInterface;
  public questionSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

  // Values to evaluate condition
  public condition: Array<ConditionalblockInterface> = [];

  // Page related
  public pageNumber;
  public currentPage;

  public evaluation$: Observable<boolean>;
  public hidden$: Observable<boolean>;
  public noAnswerProvided$: Observable<boolean>;

  private expressionSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private evaluationSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private hiddenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private noAnswerProvidedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private content: YesNoInterface;
  private answers: Array<AnswersheetAnswerInterface> = [];

  private dynamicAnswers: Array<AnswersheetAnswerInterface> = [];
  private dynamicAnswers$: Observable<Array<AnswersheetAnswerInterface>>;

  private lessonLocale$: Observable<any>;

  private iteration = 0;
  private nested = 0;

  // Subscription Handler
  private subscriptions: Subscription[] = [];

  constructor(
    private translateService: TranslateService,
    private evaluationService: EvaluationService,
    private store: Store<{ dynamicAnswers: Array<AnswersheetAnswerInterface>; lessonLocale: any }>
  ) {
    this.dynamicAnswers$ = store.select('dynamicAnswers');
    this.lessonLocale$ = store.select('lessonLocale');
    this.evaluation$ = this.evaluationSubject.asObservable();
    this.hidden$ = this.hiddenSubject.asObservable();
    this.noAnswerProvided$ = this.noAnswerProvidedSubject.asObservable();
  }

  @Input()
  set _element(_element: QuestionYesNoInterface | ElementInterface) {
    this.element = _element;
  }

  @Input()
  set _iteration(_iteration: number) {
    this.iteration = _iteration;
  }

  @Input()
  set _nested(_nested: number) {
    this.nested = _nested;
  }

  @Input()
  set _answers(_answers: Array<AnswersheetAnswerInterface>) {
    if (_answers) {
      this.answers = _answers;
      this.isDisabled = true;
    }
  }

  // Block condition
  @Input()
  set _condition(_condition: Array<ConditionalblockInterface>) {
    this.condition = _condition;
  }

  @Input()
  set _page(_page) {
    if (_page !== undefined) {
      this.pageNumber = _page;
      if (this.currentPage !== undefined) {
        this.hiddenSubject.next(this.currentPage.toString() !== this.pageNumber.toString());
      }
    }
  }

  @Input()
  set _currentPage(_currentPage) {
    if (_currentPage !== undefined) {
      this.currentPage = _currentPage;
      if (this.pageNumber !== undefined) {
        this.hiddenSubject.next(this.currentPage.toString() !== this.pageNumber.toString());
      }
    }
  }

  @Input()
  set _allElements(_allElements) {
    if (_allElements !== undefined) {
      this.allElements = _allElements;
    }
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.dynamicAnswers$.pipe(distinctUntilChanged()).subscribe(dynamicAnswers => {
        this.dynamicAnswers = dynamicAnswers;
        this.questionSubject.next(
          this.evaluationService.replaceReference(
            this.question,
            this.allElements,
            localStorage.getItem('lessonLocale'),
            this.answers,
            this.dynamicAnswers
          )
        );
        if (this.element.position) {
          this.setAnswersFromDynamicAnswers(dynamicAnswers);
        }
        this.evaluationService.evaluateExpression(
          this.expressionSubject,
          this.evaluationSubject,
          this.condition,
          this.element.position,
          this.answers,
          this.dynamicAnswers
        );
      })
    );

    // Assign translation
    this.content = this.findTranslation();
    this.setTextString(this.content);
    this.subscriptions.push(
      this.translateService.onLangChange.subscribe(
        (event: LangChangeEvent) => {
          this.content = this.findTranslation();
          this.setTextString(this.content);
        },
        error => {
          console.error(error);
        }
      )
    );
    this.subscriptions.push(
      this.lessonLocale$.subscribe(() => {
        this.content = this.findTranslation();
        this.setTextString(this.content);
      })
    );

    // Set answersheet answers
    if (this.answers.length > 0) {
      this.setAnswersFromAnswersheet(this.answers);
    } else {
      this.setAnswersFromDynamicAnswers(this.dynamicAnswers);
    }
    this.evaluationService.evaluateExpression(
      this.expressionSubject,
      this.evaluationSubject,
      this.condition,
      this.element.position,
      this.answers,
      this.dynamicAnswers
    );
  }

  public answerQuestion(answer: boolean): void {
    const submittedAnswer: AnswersheetAnswerInterface = {
      value: answer,
      position: this.element.position,
      loop: false,
      parentLoopIndex: this.iteration + this.nested
    };
    const findElementIndex = this.dynamicAnswers.findIndex(
      (value: AnswersheetAnswerInterface) =>
        value.position.toString() === submittedAnswer.position.toString() &&
        value.parentLoopIndex.toString() === submittedAnswer.parentLoopIndex.toString()
    );
    if (findElementIndex > -1) {
      const temp = [...this.dynamicAnswers];
      temp[findElementIndex] = submittedAnswer;
      this.dynamicAnswers = temp;
    } else {
      this.dynamicAnswers = [...this.dynamicAnswers, submittedAnswer];
    }
    this.store.dispatch({
      type: DynamicAnswersActionTypes.addDynamicAnswersType,
      payload: this.dynamicAnswers
    });
    this.answer = answer;
  }

  public setAnswerYes(): void {
    if (this.yesButton != null && this.noButton != null) {
      this.yesButton.nativeElement.classList.add('selectedButton');
      this.noButton.nativeElement.classList.remove('selectedButton');
    }
  }

  public setAnswerNo(): void {
    if (this.yesButton != null && this.noButton != null) {
      this.noButton.nativeElement.classList.add('selectedButton');
      this.yesButton.nativeElement.classList.remove('selectedButton');
    }
  }

  public setAnswersFromAnswersheet(answers: Array<AnswersheetAnswerInterface>): void {
    const element = answers.find(
      (answer: AnswersheetAnswerInterface) =>
        answer.position.toString() === this.element.position.toString() && answer.parentLoopIndex.toString() === this.iteration.toString()
    );
    if (element) {
      const value = element.value !== null ? element.value.toString() : null;
      switch (value) {
        case 'true':
        case 'YES':
          this.answer = true;
          this.setAnswerYes();
          break;
        case 'false':
        case 'NO':
          this.answer = false;
          this.setAnswerNo();
          break;
        case null: {
          this.noAnswerProvidedSubject.next(true);
          break;
        }
      }
    } else {
      this.noAnswerProvidedSubject.next(true);
    }
  }

  public setAnswersFromDynamicAnswers(answers: Array<AnswersheetAnswerInterface>): void {
    const element = answers.find(
      (answer: AnswersheetAnswerInterface) =>
        answer.position.toString() === this.element.position.toString() && answer.parentLoopIndex.toString() === this.iteration.toString()
    );
    if (element) {
      switch (element.value) {
        case true:
        case 'true':
        case 'YES':
          this.answer = true;
          this.setAnswerYes();
          break;
        case false:
        case 'false':
        case 'NO':
          this.answer = false;
          this.setAnswerNo();
          break;
        case null:
          this.noAnswerProvidedSubject.next(true);
          break;
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  private findTranslation() {
    const _content = this.element.translations.find(translation => translation.locale === localStorage.getItem('lessonLocale'));
    return _content ? _content : this.element.translations[0];
  }

  private setTextString(content: YesNoInterface): void {
    this.question = content.question;
    this.questionSubject.next(
      this.evaluationService.replaceReference(
        this.question,
        this.allElements,
        localStorage.getItem('lessonLocale'),
        this.answers,
        this.dynamicAnswers
      )
    );
  }
}
