/* eslint-disable @typescript-eslint/naming-convention */
import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, filter, mergeMap, skip, take, takeLast, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogGroupCreationComponent } from '../../dialog/dialog-group-creation/dialog-group-creation.component';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { StudyMediaInterface } from '../../../models/interface/study/study-media.interface';
import { AlertService } from '../../../services/alert/alert.service';
import { HelperService } from '../../../services/helper/helper.service';
import { RoleInterface } from '../../../models/interface/role.interface';
import { UserInterface } from '../../../models/interface/user.interface';
import { OrganisationInterface } from '../../../models/interface/organisation/organisation.interface';
import { Store } from '@ngrx/store';
import { ProfileInterface } from '../../../models/interface/profile.interface';
import { OrganisationActionTypes } from '../../../store/organisation/organisation.action';
import { HelperDialogService } from '../../../services/helper/helper-dialog/helper-dialog.service';
import { UserActionTypes } from '../../../store/user/user.action';
import { OrganisationStore } from '../../../store/organisation/component-store/organisation.store';
import { StudyStore } from '../../../store/study/component-store/study.store';

/**
 * Component:
 * Group creation page - child component of GroupOverviewComponent;
 */

@Component({
  selector: 'app-group-creation',
  templateUrl: './group-creation.component.html',
  styleUrls: ['./group-creation.component.scss'],
  providers: [OrganisationStore, StudyStore]
})
export class GroupCreationComponent implements OnInit, OnDestroy {
  @Output() newGroupEvent = new EventEmitter<string>();

  @ViewChild('fileInputPicture') fileInputPicture: ElementRef;

  // Icons
  faInfoCircle = faInfoCircle;

  // Group creation studyForm
  public groupFormType: UntypedFormGroup;
  public groupType = ['STUDY'];
  public accessType = ['PASSWORD', 'INVITATION'];

  // Calculate dates
  public today = new Date();
  public tomorrow = this.today.setDate(this.today.getDate() + 1);

  // Image upload
  public language: string;
  public url = '';
  public fileData: File = null;
  public previewUrl: any = null;
  public fileUploadProgress: number = null;
  public uploadedFilePath: string = null;
  public files: Array<any> = null;

  // Form submission
  public submitted = false;
  public disabled = false;

  public organisations: Array<OrganisationInterface> = [];

  public selectedOrganisation: OrganisationInterface;

  public organisationsSubject: BehaviorSubject<Array<OrganisationInterface>> = new BehaviorSubject<Array<OrganisationInterface>>([]);
  public organisations$: Observable<Array<OrganisationInterface>> = this.organisationsSubject.asObservable();

  public allECoachesAsAdmin$: Observable<Array<UserInterface>>;
  public selectedMinDate$: Observable<string>;
  public selectedCurrentDate$: Observable<string>;
  public selectedIsPrivate$: Observable<string>;
  public selectedAccesstype$: Observable<string>;
  public isECoach$: Observable<boolean>;
  public showDescription$: Observable<boolean>;
  public globalRole$: Observable<string>;

  public profile$: Observable<ProfileInterface> = this.store.select('myProfile');
  public profileRoles$: Observable<Array<RoleInterface>> = this.store.select('myRoles');

  public organisationStore$: Observable<Array<OrganisationInterface>> = this.store.select('organisations');

  // Data from UserService
  public allECoachesAsAdmin: Array<UserInterface> = [];

  public filter = {
    userSelection: '',
    organisationSelection: ''
  };

  public showDescriptionSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private submittedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isUploadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private createStudyResponse$: Observable<any>;
  private updateStudyResponse$: Observable<any>;

  private profile: ProfileInterface;

  private locationUrl;

  private allECoachesAsAdminSubject: BehaviorSubject<Array<UserInterface>> = new BehaviorSubject<Array<UserInterface>>([]);

  private selectedMinDateSubject = new BehaviorSubject<string>(this.helperService.formatDateToYYYY_MM_DD(this.tomorrow));
  private selectedCurrentDateSubject = new BehaviorSubject<string>(this.helperService.formatDateToYYYY_MM_DD(this.tomorrow));
  private selectedIsPrivateSubject = new BehaviorSubject<string>('0');
  private selectedAccesstypeSubject = new BehaviorSubject<string>('PASSWORD');

  private globalRoleSubject: BehaviorSubject<string> = new BehaviorSubject<string>('ecoach');
  private isECoachSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private users$: Observable<Array<UserInterface>>;

  private createOrganisationResponse$: Observable<any>;

  private uploadStudyMediaResponse$: Observable<any>;

  private subscriptions: Subscription[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private helperService: HelperService,
    private helperDialogService: HelperDialogService,
    private router: Router,
    private alertService: AlertService,
    private organisationStore: OrganisationStore,
    private studyStore: StudyStore,
    private store: Store<{
      myProfile: ProfileInterface;
      myRoles: Array<RoleInterface>;
      organisations: Array<OrganisationInterface>;
      users: Array<UserInterface>;
    }>
  ) {
    this.profile$ = this.store.select('myProfile');
    this.users$ = this.store.select('users');
    this.allECoachesAsAdmin$ = this.allECoachesAsAdminSubject.asObservable();
    this.isECoach$ = this.isECoachSubject.asObservable();
    this.showDescription$ = this.showDescriptionSubject.asObservable();
    this.globalRole$ = this.globalRoleSubject.asObservable();

    // Form type contains searchFilter options
    this.groupFormType = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(255), Validators.minLength(1)]],
      grouptype: ['', Validators.required],
      is_private: ['0', Validators.required],
      accesstype: ['', Validators.required],
      password: ['', Validators.maxLength(255)],
      subscribable: ['2'],
      starts_at: [''],
      ends_at: [''],
      deTitle: ['', [Validators.required, Validators.maxLength(255)]],
      enTitle: ['', [Validators.required, Validators.maxLength(255)]],
      deDescription: ['', [Validators.required, Validators.maxLength(255)]],
      enDescription: ['', [Validators.required, Validators.maxLength(255)]],
      owner_id: [-1]
    });
    this.groupFormType.controls['grouptype'].setValue(this.groupType[0], { onlySelf: true });
    this.groupFormType.controls['accesstype'].setValue(this.accessType[0], { onlySelf: true });
    this.groupFormType.controls['owner_id'].setValue(-1, { onlySelf: true });

    // Set values and date for group creation studyForm
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    this.groupFormType.controls['starts_at'].setValue(this.helperService.formatDateToYYYY_MM_DD(Date.now()));
    this.groupFormType.controls['ends_at'].setValue(this.helperService.formatDateToYYYY_MM_DD(this.tomorrow));
    this.selectedIsPrivate$ = this.selectedIsPrivateSubject.asObservable();
    this.selectedAccesstype$ = this.selectedAccesstypeSubject.asObservable();
    this.selectedMinDate$ = this.selectedMinDateSubject.asObservable();
    this.selectedCurrentDate$ = this.selectedCurrentDateSubject.asObservable();
    this.createOrganisationResponse$ = this.organisationStore.createOrganisationResponse$;
    this.createStudyResponse$ = this.studyStore.createStudyResponse$;
    this.updateStudyResponse$ = this.studyStore.updateStudyResponse$;
    this.uploadStudyMediaResponse$ = this.studyStore.uploadStudyMediaResponse$;
  }

  get f() {
    return this.groupFormType.controls;
  }

  public get helper() {
    return this.helperService;
  }

  public get helperDialog() {
    return this.helperDialogService;
  }

  ngOnInit(): void {
    if (this.globalRoleSubject.value === 'admin') {
      this.store.dispatch({ type: OrganisationActionTypes.getOrganisationsAdminType, payload: { include: 'owners,collaborators' } });
    }
    this.subscriptions.push(
      this.organisationStore$.pipe(filter(organisations => organisations.length > 0)).subscribe((result: Array<OrganisationInterface>) => {
        this.organisations = result;
        this.organisationsSubject.next(this.organisations);
      })
    );

    this.subscriptions.push(
      this.profile$
        .pipe(
          filter(user => user !== null),
          mergeMap((result: ProfileInterface) => {
            this.profile = result;
            return this.profileRoles$.pipe(
              filter(user => user !== null),
              take(1)
            );
          }),
          mergeMap((resp: Array<RoleInterface>) => {
            // Check whether the user is eCoach or admin
            const roles: Array<RoleInterface> = resp;
            if (this.helperService.hasRoles(roles, /(ecoachmanager|ecoach)$/)) {
              this.isECoachSubject.next(true);
            }
            if (this.helperService.hasRoles(roles, /admin$/)) {
              this.globalRoleSubject.next('admin');
              this.groupType = ['STUDY', 'ORGANISATION'];
              this.store.dispatch({ type: UserActionTypes.getAllUsersAdminType, payload: {} });
              return this.users$.pipe(skip(1), take(1));
            }
            return of(null);
          })
        )
        .subscribe((result: any) => {
          // Get all users in role eCoach as admin
          this.allECoachesAsAdminSubject.next(result);
          this.allECoachesAsAdmin = result.filter((user: UserInterface) =>
            user.attributes.roles.find((role: { slug: string; description: string }) => role.slug.toString() === 'ecoach')
          );
          if (this.allECoachesAsAdmin.length > 0) {
            this.groupFormType.controls['owner_id'].setValue(this.allECoachesAsAdmin[0].id, { onlySelf: true });
          }
        })
    );

    this.groupFormType.get('is_private').valueChanges.subscribe(val => {
      if (this.groupFormType.get('accesstype').value === 'PASSWORD' && val.toString() === '1') {
        this.groupFormType.controls['password'].setValidators([Validators.required, Validators.minLength(1), Validators.maxLength(255)]);
      } else {
        this.groupFormType.controls['password'].clearValidators();
      }
      this.groupFormType.controls['password'].updateValueAndValidity();
    });

    this.groupFormType.get('accesstype').valueChanges.subscribe(val => {
      if (val === 'PASSWORD') {
        this.groupFormType.controls['password'].setValidators([Validators.required, Validators.minLength(1), Validators.maxLength(255)]);
      } else {
        this.groupFormType.controls['password'].clearValidators();
      }
      this.groupFormType.controls['password'].updateValueAndValidity();
    });
  }

  // Group creation studyForm check is_private
  public showIsPrivate(event): void {
    this.selectedIsPrivateSubject.next(event.value.toString());
  }

  // Group creation studyForm check access type
  public showAccesstype(value: string): void {
    this.selectedAccesstypeSubject.next(value);
  }

  public isCollabId(organisation: OrganisationInterface): boolean {
    return !!organisation.relationships.collaborators.data.find((user: UserInterface) => user.id.toString() === this.profile.id.toString());
  }

  // Submit parameters required to create a group
  public createGroup(): void {
    this.submitted = true;
    this.disabled = true;
    let newStudyId = null;

    if (
      (!this.isGroupFormInvalid() && !this.isNameOrPasswordInvalid() && this.f.grouptype.value === 'STUDY') ||
      (!this.isNameOrPasswordInvalid() && this.f.grouptype.value === 'ORGANISATION') ||
      (!this.isNameOrPasswordInvalid() && this.f.grouptype.value === 'STUDY' && !this.isOwnerAndECoach())
    ) {
      let grouptypeParam = 'study';
      let accesstypeParam = null;
      let isPrivateParam = null;
      const translations = [];

      const translationGerman = {
        locale: 'de',
        title: this.f.deTitle.value,
        description: this.f.deDescription.value
      };

      const translationEnglish = {
        locale: 'en',
        title: this.f.enTitle.value,
        description: this.f.enDescription.value
      };

      translations.push(translationGerman, translationEnglish);

      const formGrouptype = grouptype =>
        ({
          STUDY: 'study',
          ORGANISATION: 'organisation'
        }[grouptype]);
      grouptypeParam = formGrouptype(this.f.grouptype.value);

      const formIsPrivate = isPrivate =>
        ({
          1: true,
          0: false
        }[isPrivate]);
      isPrivateParam = formIsPrivate(this.f.is_private.value);

      const formAccesstype = accessType =>
        ({
          PASSWORD: 'password',
          INVITATION: 'invite'
        }[accessType]);
      accesstypeParam = formAccesstype(this.f.accesstype.value);

      const startsAt = grouptypeParam === 'study' ? this.helperService.convertStringToUnixTimestamp(this.f.starts_at.value) : null;
      const endsAt = grouptypeParam === 'study' ? this.helperService.convertStringToUnixTimestamp(this.f.ends_at.value) : null;

      // User is eCoach and owner
      if (this.globalRoleSubject.value === 'ecoach' || this.isOwnerAndECoach()) {
        this.studyStore.createStudy({
          name: this.f.name.value,
          type: grouptypeParam,
          organisationId: this.selectedOrganisation ? parseInt(this.selectedOrganisation.id.toString(), 10) : undefined
        });
        this.subscriptions.push(
          this.createStudyResponse$
            .pipe(
              skip(1),
              take(1),
              mergeMap((result: any) => {
                // Get location_url
                if (result instanceof HttpResponse) {
                  this.locationUrl = result['headers'].get('Location');
                  newStudyId = this.locationUrl.substring(this.locationUrl.lastIndexOf('/') + 1);
                  return of(newStudyId);
                } else {
                  return throwError(result);
                }
              }),
              mergeMap(studyId => {
                // If image is provided
                if (this.fileInputPicture.nativeElement.files.length > 0) {
                  this.studyStore.uploadStudyMedia({ studyId: parseInt(studyId, 10), fileToUpload: this.files[0] });
                  return this.uploadStudyMediaResponse$.pipe(
                    skip(1),
                    takeWhile(result => !(result.type === HttpEventType.Response), true),
                    mergeMap((result: any) => {
                      this.fileUploadProgress = Math.round((result['loaded'] / result['total']) * 100);
                      return of(result);
                    }),
                    takeLast(1),
                    mergeMap((result: any) => {
                      if (result instanceof HttpResponse) {
                        this.fileUploadProgress = 0;
                        this.isUploadingSubject.next(false);
                        return of(result);
                      } else {
                        return throwError(result);
                      }
                    }),
                    mergeMap((result: any) => {
                      const studyMediaList: Array<StudyMediaInterface> = result.body.data;
                      this.studyStore.updateStudy({
                        studyId: parseInt(studyId, 10),
                        translationContent: translations,
                        accesstypeParam: isPrivateParam ? accesstypeParam : undefined,
                        password: isPrivateParam && accesstypeParam === 'password' ? this.f.password.value : undefined,
                        isPrivate: isPrivateParam,
                        startsAt,
                        endsAt,
                        picture: `uploads/studies/${parseInt(studyId, 10)}/${studyMediaList[0].attributes.url}`
                      });
                      return this.updateStudyResponse$.pipe(
                        skip(1),
                        take(1),
                        mergeMap((res: any) => {
                          if (res instanceof HttpResponse) {
                            return of(res);
                          } else {
                            return throwError(res);
                          }
                        })
                      );
                    })
                  );
                } else {
                  this.studyStore.updateStudy({
                    studyId: parseInt(studyId, 10),
                    translationContent: translations,
                    accesstypeParam: isPrivateParam ? accesstypeParam : undefined,
                    password: isPrivateParam && accesstypeParam === 'password' ? this.f.password.value : undefined,
                    isPrivate: isPrivateParam,
                    startsAt,
                    endsAt
                  });
                  return this.updateStudyResponse$.pipe(
                    skip(1),
                    take(1),
                    mergeMap((res: any) => {
                      if (res instanceof HttpResponse) {
                        return of(res);
                      } else {
                        return throwError(res);
                      }
                    })
                  );
                }
              }),
              catchError(error => throwError(error))
            )
            .subscribe(
              () => {
                this.showCreationSuccess(newStudyId.toString(), 'group-creation.form_create_group_succeeded_redirection', true);
              },
              () => {
                this.showCreationError('group-creation.form_create_group_failed');
              }
            )
        );
      } else {
        // If user is admin
        if (this.globalRoleSubject.value === 'admin') {
          // If user create a group for another user
          if (this.f.owner_id.value.toString() !== '-1') {
            const selectedOrganisationId = this.selectedOrganisation ? parseInt(this.selectedOrganisation.id.toString(), 10) : undefined;
            this.studyStore.createStudyAdmin({
              name: this.f.name.value,
              type: grouptypeParam,
              ownerId: parseInt(this.f.owner_id.value.toString(), 10),
              organisationId: selectedOrganisationId
            });
            this.subscriptions.push(
              this.createStudyResponse$
                .pipe(
                  skip(1),
                  take(1),
                  mergeMap((result: any) => {
                    if (result instanceof HttpResponse) {
                      this.locationUrl = result['headers'].get('Location');
                      newStudyId = this.locationUrl.substring(this.locationUrl.lastIndexOf('/') + 1);
                      return of(newStudyId);
                    } else {
                      return throwError(result);
                    }
                  })
                )
                .subscribe(
                  () => {
                    this.showCreationSuccess(newStudyId, 'group-creation.form_create_group_succeeded');
                  },
                  error => {
                    this.showCreationError('group-creation.form_create_failed');
                  }
                )
            );
          }
        } else {
          this.disabled = false;
        }
      }
    } else {
      this.disabled = false;
    }
  }

  public isOwnerAndECoach(): boolean {
    if (this.f.owner_id.value.toString() !== '-1') {
      return (
        this.f.owner_id.value.toString() === this.profile.id.toString() &&
        this.isECoachSubject.value &&
        this.globalRoleSubject.value === 'admin'
      );
    }
    return false;
  }

  public processFile(fileInput: any): void {
    this.fileData = fileInput.target.files[0] as File;
    this.uploadedFilePath = this.fileData.name;
    this.files = fileInput.target.files;
    this.displayImagePreview();
  }

  public displayImagePreview(): string {
    if (this.fileData) {
      if (this.fileData.type.match(/image\/*/) == null) {
        return 'assets/pictures/default_no_image_picture.svg';
      }
      const reader = new FileReader();
      reader.readAsDataURL(this.fileData);
      reader.onload = _event => {
        this.previewUrl = reader.result;
      };
      return this.previewUrl;
    } else {
      return 'assets/pictures/default_no_image_picture.svg';
    }
  }

  public clearImage(): void {
    this.fileData = null;
    this.previewUrl = null;
    this.fileUploadProgress = null;
    this.uploadedFilePath = null;
    this.files = null;
    this.fileInputPicture.nativeElement.value = '';
  }

  public openDialogGroupCreate(): MatDialogRef<DialogGroupCreationComponent> {
    const dialogRef = this.helperDialog.openDialogGroupCreate(this.submitted);
    this.submittedSubject.asObservable().subscribe(value => {
      if (dialogRef && dialogRef.componentInstance) {
        dialogRef.componentInstance.data = { submitted: value };
      }
    });
    dialogRef.afterClosed().subscribe(() => {
      if (this.globalRoleSubject.value === 'admin') {
        this.store.dispatch({ type: OrganisationActionTypes.getOrganisationsAdminType, payload: { include: 'owners,collaborators' } });
      }
      this.subscriptions.push(
        this.organisationStore$
          .pipe(filter(organisations => organisations.length > 0))
          .subscribe((result: Array<OrganisationInterface>) => {
            this.organisations = result;
            this.organisationsSubject.next(this.organisations);
          })
      );
    });
    return dialogRef;
  }

  public updateOrganisationSelection(event: any) {
    if (this.isOwnerAndECoach()) {
      this.organisationsSubject.next(this.organisations.filter((organisation: OrganisationInterface) => this.isCollabId(organisation)));
      if (this.selectedOrganisation) {
        if (!this.isCollabId(this.selectedOrganisation)) {
          this.selectedOrganisation = undefined;
        }
      }
    }
  }

  // Submit parameters required to create an organisation
  public createOrganisation(): void {
    this.submitted = true;
    this.disabled = true;

    if (!this.f.name.invalid && this.f.grouptype.value === 'ORGANISATION' && this.globalRoleSubject.value === 'admin') {
      // If user create a group for another user
      if (this.f.owner_id.value.toString() !== '-1') {
        this.organisationStore.createOrganisationAdmin({
          name: this.f.name.value,
          ownerId: parseInt(this.f.owner_id.value.toString(), 10)
        });

        this.createOrganisationResponse$.pipe(skip(1), take(1)).subscribe((result: any) => {
          if (result instanceof HttpResponse) {
            this.showCreationSuccess(this.locationUrl, 'group-creation.form_create_organisation_succeeded');
          } else {
            this.showCreationError('group-creation.form_create_organisation_failed');
          }
        });
      }
    } else {
      this.disabled = false;
    }
  }

  public onKeyFilter(): void {
    const filterResults: Array<UserInterface> = this.allECoachesAsAdmin.filter(
      (user: UserInterface) =>
        user.attributes.name?.toLowerCase().includes(this.filter['userSelection'].toLowerCase()) ||
        user.attributes.email.toLowerCase().includes(this.filter['userSelection'].toLowerCase())
    );
    const eCoaches =
      filterResults.length > 0
        ? filterResults
        : [this.helper.findArrObjById(parseInt(this.f.owner_id.value.toString(), 10), this.allECoachesAsAdmin)];
    this.allECoachesAsAdminSubject.next(eCoaches);
  }

  public onKeyFilterOrganisation(): void {
    const filterResults: Array<OrganisationInterface> = this.organisations.filter((organisation: OrganisationInterface) =>
      organisation.attributes?.name.toLowerCase().includes(this.filter['organisationSelection'].toLowerCase())
    );
    const organisations = filterResults.length > 0 ? filterResults : [this.selectedOrganisation];
    this.organisationsSubject.next(organisations);
  }

  public resetFilter(): void {
    this.filter = {
      userSelection: '',
      organisationSelection: ''
    };

    this.allECoachesAsAdminSubject.next(this.allECoachesAsAdmin);
    if (this.isOwnerAndECoach()) {
      this.organisationsSubject.next(this.organisations.filter((organisation: OrganisationInterface) => this.isCollabId(organisation)));
      if (this.selectedOrganisation) {
        if (!this.isCollabId(this.selectedOrganisation)) {
          this.selectedOrganisation = undefined;
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  private isGroupFormInvalid(): boolean {
    const translationInvalid =
      this.f.deTitle.invalid || this.f.deDescription.invalid || this.f.enTitle.invalid || this.f.enDescription.invalid;
    if (!(this.f.name.invalid || this.f.grouptype.invalid)) {
      if (this.f.is_private.value === '0') {
        return translationInvalid;
      } else {
        if (this.f.accesstype.value === 'PASSWORD') {
          if (this.f.password.invalid) {
            return true;
          }
        }
        return translationInvalid;
      }
    }
    return true;
  }

  private isNameOrPasswordInvalid(): boolean {
    return this.f.name.invalid || this.f.password.invalid;
  }

  private showCreationSuccess(locationUrl: string, message?: string, redirect?: boolean): void {
    if (message) {
      this.alertService.success(message);
    }
    this.submitted = false;
    this.submittedSubject.next(false);
    const dialogRef = this.openDialogGroupCreate();
    dialogRef.componentInstance.data = { submitted: false };
    this.disabled = false;
    this.newGroupEvent.emit(locationUrl);
    setTimeout(() => {
      dialogRef.close();
    }, 5000);
    dialogRef.afterClosed().subscribe(() => {
      if (redirect) {
        this.router.navigateByUrl(`/groups/${locationUrl}`);
      }
    });
  }

  private showCreationError(message?: string): void {
    if (message) {
      this.alertService.error(message);
    }
    this.submitted = false;
    this.submittedSubject.next(false);
    const dialogRef = this.openDialogGroupCreate();
    setTimeout(() => {
      this.disabled = false;
      dialogRef.close();
    }, 5000);
  }
}
