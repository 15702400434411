import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, distinctUntilChanged, Observable, Subscription } from 'rxjs';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons/faLightbulb';
import { ElementInterface } from '../../../../models/interface/elements/element.interface';
import { EvaluationService } from '../../../../services/evaluation/evaluation.service';
import { HelperService } from '../../../../services/helper/helper.service';
import { AnswersheetAnswerInterface } from '../../../../models/interface/answersheet-answer.interface';
import { QuestionTextInterface } from '../../../../models/interface/elements-question/question-text/question-text.interface';
import { ConditionalblockInterface } from '../../../../models/interface/condition/conditionalblock.interface';
import { TextInterface } from '../../../../models/interface/elements-question/question-text/text.interface';
import { Store } from '@ngrx/store';

/**
 * Component:
 * Represents text element
 */

@Component({
  selector: 'app-question-text',
  templateUrl: './question-text.component.html',
  styleUrls: ['./question-text.component.scss', '../../../../../assets/styles/aas2_app.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QuestionTextComponent implements OnInit, OnDestroy {
  // Icons
  faExclamationCircle = faExclamationCircle;
  faLightBulb = faLightbulb;
  faInfoCircle = faInfoCircle;
  faCheck = faCheck;

  public text = '';
  public headline = '';

  public allElements = [];
  public element: QuestionTextInterface | ElementInterface;
  public answers: Array<AnswersheetAnswerInterface> = [];
  public showHeadline: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public showText: BehaviorSubject<string> = new BehaviorSubject<string>('');

  // Values to evaluate condition
  public condition: Array<ConditionalblockInterface> = [];

  public evaluation$: Observable<boolean>;
  public hidden$: Observable<boolean>;

  private expressionSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private evaluationSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private hiddenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private content: TextInterface;
  private dynamicAnswers: Array<AnswersheetAnswerInterface> = [];
  private dynamicAnswers$: Observable<Array<AnswersheetAnswerInterface>>;

  private lessonLocale$: Observable<any>;

  private pageNumber: number;
  private currentPage: number;

  // Subscription Handler
  private subscriptions: Subscription[] = [];

  constructor(
    private translateService: TranslateService,
    private evaluationService: EvaluationService,
    private helperService: HelperService,
    private store: Store<{ dynamicAnswers: Array<AnswersheetAnswerInterface>; lessonLocale: any }>
  ) {
    this.dynamicAnswers$ = store.select('dynamicAnswers');
    this.lessonLocale$ = store.select('lessonLocale');
    this.evaluation$ = this.evaluationSubject.asObservable();
    this.hidden$ = this.hiddenSubject.asObservable();
  }

  // Helper function
  get helper() {
    return this.helperService;
  }

  @Input()
  set _element(_element: QuestionTextInterface | ElementInterface) {
    this.element = _element;
  }

  // Block condition
  @Input()
  set _condition(_condition: Array<ConditionalblockInterface>) {
    this.condition = _condition;
  }

  @Input()
  set _page(_page) {
    if (_page !== undefined) {
      this.pageNumber = _page;
      if (this.currentPage !== undefined) {
        this.hiddenSubject.next(this.currentPage.toString() !== this.pageNumber.toString());
      }
    }
  }

  @Input()
  set _currentPage(_currentPage) {
    if (_currentPage !== undefined) {
      this.currentPage = _currentPage;
      if (this.pageNumber !== undefined) {
        this.hiddenSubject.next(this.currentPage.toString() !== this.pageNumber.toString());
      }
    }
  }

  @Input()
  set _allElements(_allElements) {
    if (_allElements !== undefined) {
      this.allElements = _allElements;
    }
  }

  @Input()
  set _answers(_answers: Array<AnswersheetAnswerInterface>) {
    if (_answers !== undefined) {
      this.answers = _answers;
    }
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.dynamicAnswers$.pipe(distinctUntilChanged()).subscribe(dynamicAnswers => {
        this.dynamicAnswers = dynamicAnswers;
        this.showHeadline.next(
          this.evaluationService.replaceReference(
            this.headline,
            this.allElements,
            localStorage.getItem('lessonLocale'),
            this.answers,
            this.dynamicAnswers
          )
        );
        this.showText.next(
          this.evaluationService.replaceReference(
            this.text,
            this.allElements,
            localStorage.getItem('lessonLocale'),
            this.answers,
            this.dynamicAnswers
          )
        );
        this.evaluationService.evaluateExpression(
          this.expressionSubject,
          this.evaluationSubject,
          this.condition,
          this.element.position,
          this.answers,
          this.dynamicAnswers
        );
      })
    );

    // Assign translation
    this.content = this.findTranslation();
    if (this.content) {
      this.setText(this.content);
    }
    this.subscriptions.push(
      this.translateService.onLangChange.subscribe(
        (event: LangChangeEvent) => {
          this.content = this.findTranslation();
          if (this.content) {
            this.setText(this.content);
          }
        },
        error => {
          console.error(error);
        }
      )
    );
    this.subscriptions.push(
      this.lessonLocale$.subscribe(() => {
        this.content = this.findTranslation();
        if (this.content) {
          this.setText(this.content);
        }
      })
    );
    this.evaluationService.evaluateExpression(
      this.expressionSubject,
      this.evaluationSubject,
      this.condition,
      this.element.position,
      this.answers,
      this.dynamicAnswers
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  private findTranslation() {
    if (this.element.translations) {
      const _content = this.element.translations.find(translation => translation.locale === localStorage.getItem('lessonLocale'));
      return _content ? _content : this.element.translations[0];
    } else {
      return null;
    }
  }

  private setText(content: TextInterface): void {
    // Replace URI of media files embedded in text
    this.helper.getMediaPath(content.text);
    if (this.element.type === 'table') {
      this.text = content.text.slice(0, 6) + ' class="table table-striped fullWidth"' + content.text.slice(6);
    } else {
      this.text = content.text;
      this.headline = content.headline;
    }
    this.showHeadline.next(
      this.evaluationService.replaceReference(
        this.headline,
        this.allElements,
        localStorage.getItem('lessonLocale'),
        this.answers,
        this.dynamicAnswers
      )
    );
    this.showText.next(
      this.evaluationService.replaceReference(
        this.text,
        this.allElements,
        localStorage.getItem('lessonLocale'),
        this.answers,
        this.dynamicAnswers
      )
    );
  }
}
