import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StudyInterface } from 'src/app/models/interface/study/study.interface';

@Component({
  selector: 'app-dialog-codebook',
  templateUrl: './dialog-codebook.component.html',
  styleUrls: ['./dialog-codebook.component.scss']
})
export class DialogCodebookComponent implements OnInit {
  public study: StudyInterface;

  constructor(@Inject(MAT_DIALOG_DATA) public data) {}

  ngOnInit(): void {
    this.study = this.data.group;
  }
}
