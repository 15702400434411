import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateParser, TranslateService } from '@ngx-translate/core';

export class CustomMatPaginatorIntl extends MatPaginatorIntl {
  private rangeLabelIntl: string;

  constructor(private translateService: TranslateService, private translateParser: TranslateParser) {
    super();
    this.getTranslations();
    this.translateService.onLangChange.subscribe(() => {
      this.getTranslations();
    });
  }

  getTranslations() {
    this.translateService
      .get([
        'paginator.items_per_page',
        'paginator.next_page',
        'paginator.previous_page',
        'paginator.first_page',
        'paginator.last_page',
        'paginator.range'
      ])
      .subscribe(translation => {
        this.itemsPerPageLabel = translation['paginator.items_per_page'];
        this.nextPageLabel = translation['paginator.next_page'];
        this.previousPageLabel = translation['paginator.previous_page'];
        this.firstPageLabel = translation['paginator.first_page'];
        this.lastPageLabel = translation['paginator.last_page'];
        this.rangeLabelIntl = translation['paginator.range'];
        this.changes.next();
      });
  }

  getRangeLabel = (page, pageSize, length) => {
    length = Math.max(length, 0);
    const displayStartIndex = page * pageSize;
    const endIndex = displayStartIndex < length ? Math.min(displayStartIndex + pageSize, length) : displayStartIndex + pageSize;
    const startIndex = displayStartIndex + 1;
    return this.translateParser.interpolate(this.rangeLabelIntl, { startIndex, endIndex, length });
  };
}
