<!-- Simple alert message -->
<div
  *ngIf="message"
  [ngClass]="{ 'alert': message, 'alert-success': message.type === 'success', 'alert-warning': message.type === 'warning', 'alert-info': message.type === 'info', 'alert-danger': message.type === 'error' }"
  [innerHTML]="(message.text | translate:param) | sanitizeHtml"
>
  <div *ngIf="(showLoading$ | async)" class="d-flex justify-content-center">
    <app-loader></app-loader>
  </div>
</div>
