<!-- Single choice component for lection/question elements in ionic app -->
<div
  *ngIf="(evaluation$ | async)"
  [class.required]="required"
  [hidden]="hidden$ | async"
  [ngClass]="{disabledItem:isDisabled}"
  class="align-items-center"
>
  <div class="row justify-content-center">
    <div class="col-12">
      <span>{{questionSubject | async}}</span>
    </div>
    <div class="col-12">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>{{'question-single-choice.select_option' | translate}}</mat-label>
        <mat-select [(value)]="answer" (closed)="onChange($event)">
          <mat-option
            *ngFor="let v of element.values; index as i"
            [value]="v"
            [disabled]="(isDisabled && (answer?.toString() !== v.toString())) || (noAnswerProvided$ | async)"
            >{{setOptionTranslation(options[i])}}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div *ngIf="noAnswerProvided$ | async" class="text-danger mb-2">
    <small>{{'question-single-choice.no_answer_has_been_submitted' | translate}}</small>
  </div>
</div>
