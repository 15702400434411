import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UserService } from '../../services/api/user/user.service';
import {
  addAllECoachesStore,
  addAllInvitationsStore,
  addMyMembersStore,
  addMyRegisteredUsersStore,
  addUsersStore,
  GetAllEcoaches,
  GetAllEcoachesError,
  GetAllEcoachesSuccess,
  GetAllInvitationsOfEcoachMembers,
  GetAllInvitationsOfEcoachMembersError,
  GetAllInvitationsOfEcoachMembersSuccess,
  GetAllUsersAdmin,
  GetAllUsersAdminError,
  GetAllUsersAdminSuccess,
  GetMyMembers,
  GetMyMembersError,
  GetMyMembersSuccess,
  GetUsersRegisteredByEcoach,
  GetUsersRegisteredByEcoachError,
  GetUsersRegisteredByEcoachSuccess,
  UserActionTypes
} from './user.action';
import { HttpErrorResponseMessage } from 'src/app/enum/http-error-response-message';
import { map, switchMap, of, catchError } from 'rxjs';

@Injectable()
export class UserEffects {
  getAllUsersAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.getAllUsersAdminType),
      map((action: GetAllUsersAdmin) => action.payload),
      switchMap((payload: any) =>
        this.userService.getAllUsersAdmin(payload.userId, payload.searchterm).pipe(
          switchMap(result => {
            const actions = [];
            actions.push(addUsersStore({ users: result.body.data }));
            actions.push(new GetAllUsersAdminSuccess(result));
            return actions;
          }),
          catchError(err => of(new GetAllUsersAdminError({ message: HttpErrorResponseMessage.genericUnknownError, content: err })))
        )
      )
    )
  );

  getMyMembers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.getMyMembersType),
      map((action: GetMyMembers) => action.payload),
      switchMap((payload: any) =>
        this.userService.getMyMembers(payload.userId, payload.email).pipe(
          switchMap(result => {
            const actions = [];
            actions.push(addMyMembersStore({ myMembers: result.body.data }));
            actions.push(new GetMyMembersSuccess(result));
            return actions;
          }),
          catchError(err => of(new GetMyMembersError({ message: HttpErrorResponseMessage.genericUnknownError, content: err })))
        )
      )
    )
  );

  getAllEcoaches$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.getAllEcoachesType),
      map((action: GetAllEcoaches) => action.payload),
      switchMap((payload: any) =>
        this.userService.getAllEcoaches().pipe(
          switchMap(result => {
            const actions = [];
            actions.push(addAllECoachesStore({ allECoaches: result.body.data }));
            actions.push(new GetAllEcoachesSuccess(result));
            return actions;
          }),
          catchError(err => of(new GetAllEcoachesError({ message: HttpErrorResponseMessage.genericUnknownError, content: err })))
        )
      )
    )
  );

  getAllInvitationsOfEcoachMembers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.getAllInvitationsOfEcoachMembersType),
      map((action: GetAllInvitationsOfEcoachMembers) => action.payload),
      switchMap((payload: any) =>
        this.userService.getAllInvitationsOfEcoachMembers(payload.email).pipe(
          switchMap(result => {
            const actions = [];
            actions.push(addAllInvitationsStore({ allInvitations: result.body.data }));
            actions.push(new GetAllInvitationsOfEcoachMembersSuccess(result));
            return actions;
          }),
          catchError(err =>
            of(new GetAllInvitationsOfEcoachMembersError({ message: HttpErrorResponseMessage.genericUnknownError, content: err }))
          )
        )
      )
    )
  );

  getUsersRegisteredByEcoach$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.getUsersRegisteredByEcoachType),
      map((action: GetUsersRegisteredByEcoach) => action.payload),
      switchMap((payload: any) =>
        this.userService.getUsersRegisteredByEcoach(payload.userId).pipe(
          switchMap(result => {
            const actions = [];
            actions.push(addMyRegisteredUsersStore({ myRegisteredUsers: result.body.data }));
            actions.push(new GetUsersRegisteredByEcoachSuccess(result));
            return actions;
          }),
          catchError(err =>
            of(new GetUsersRegisteredByEcoachError({ message: HttpErrorResponseMessage.genericUnknownError, content: err }))
          )
        )
      )
    )
  );

  constructor(private actions$: Actions, private userService: UserService) {}
}
