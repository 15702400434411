import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, Observable, Subscription } from 'rxjs';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons/faFilePdf';
import { faFilePowerpoint } from '@fortawesome/free-solid-svg-icons/faFilePowerpoint';
import { faFileWord } from '@fortawesome/free-solid-svg-icons/faFileWord';
import { MediaInterface } from '../../../../models/interface/elements-question/question-media/media.interface';
import { AnswersheetAnswerInterface } from '../../../../models/interface/answersheet-answer.interface';
import { QuestionMediaInterface } from '../../../../models/interface/elements-question/question-media/question-media.interface';
import { ConditionalblockInterface } from '../../../../models/interface/condition/conditionalblock.interface';
import { ElementInterface } from '../../../../models/interface/elements/element.interface';
import { EvaluationService } from '../../../../services/evaluation/evaluation.service';
import { HelperService } from '../../../../services/helper/helper.service';
import { Store } from '@ngrx/store';

/**
 * Component:
 * Represents media element
 */

@Component({
  selector: 'app-question-media',
  templateUrl: './question-media.component.html',
  styleUrls: ['./question-media.component.scss', '../../../../../assets/styles/aas2_app.scss']
})
export class QuestionMediaComponent implements OnInit, OnDestroy {
  // Icons
  faFileWord = faFileWord;
  faFilePowerPoint = faFilePowerpoint;
  faFilePdf = faFilePdf;

  public type;
  public localUri;
  public description = '';
  public subtitle = '';
  public filePath: string;
  public fileName: string;
  public platformIsAndroid = false;
  public banner = 0;
  public width;

  public allElements = [];
  public element: QuestionMediaInterface | ElementInterface;

  public answers: Array<AnswersheetAnswerInterface> = [];
  public subtitleSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public descriptionSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public condition: Array<ConditionalblockInterface> = [];

  // Only for mp4 files - audio or video
  public isAudio = 0;

  public expression = null;

  public evaluation$: Observable<boolean>;
  public hidden$: Observable<boolean>;

  // Page related
  public pageNumber: number;
  public currentPage: number;

  private expressionSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private evaluationSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private hiddenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private dynamicAnswers: Array<AnswersheetAnswerInterface> = [];
  private dynamicAnswers$: Observable<Array<AnswersheetAnswerInterface>>;

  private lessonLocale$: Observable<any>;

  private content: MediaInterface;

  private subscriptions: Subscription[] = [];

  constructor(
    private translateService: TranslateService,
    private evaluationService: EvaluationService,
    private helperService: HelperService,
    private store: Store<{
      dynamicAnswers: Array<AnswersheetAnswerInterface>;
      lessonLocale: any;
    }>
  ) {
    this.dynamicAnswers$ = store.select('dynamicAnswers');
    this.lessonLocale$ = store.select('lessonLocale');
    this.evaluation$ = this.evaluationSubject.asObservable();
    this.hidden$ = this.hiddenSubject.asObservable();
  }

  // Helper function
  public get helper() {
    return this.helperService;
  }

  @Input()
  set _element(_element: QuestionMediaInterface | ElementInterface) {
    this.element = _element;
    this.type = _element.mimetype;
    if (_element.is_audio) {
      this.isAudio = _element.is_audio;
    }
    if (_element.banner) {
      this.banner = _element.banner;
    }
    if (_element.width) {
      this.width = _element.width;
    }
    if (_element.description) {
      this.description = _element.description;
    }
  }

  // Block condition
  @Input()
  set _condition(_condition: Array<ConditionalblockInterface>) {
    this.condition = _condition;
  }

  @Input()
  set _page(_page) {
    if (_page !== undefined) {
      this.pageNumber = _page;
      if (this.currentPage !== undefined) {
        this.hiddenSubject.next(this.currentPage.toString() !== this.pageNumber.toString());
      }
    }
  }

  @Input()
  set _currentPage(_currentPage) {
    if (_currentPage !== undefined) {
      this.currentPage = _currentPage;
      if (this.pageNumber !== undefined) {
        this.hiddenSubject.next(this.currentPage.toString() !== this.pageNumber.toString());
      }
    }
  }

  @Input()
  set _allElements(_allElements) {
    if (_allElements !== undefined) {
      this.allElements = _allElements;
    }
  }

  @Input()
  set _answers(_answers: Array<AnswersheetAnswerInterface>) {
    if (_answers !== undefined) {
      this.answers = _answers;
    }
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.dynamicAnswers$.pipe(distinctUntilChanged()).subscribe(dynamicAnswers => {
        this.dynamicAnswers = dynamicAnswers;
        this.subtitleSubject.next(
          this.evaluationService.replaceReference(
            this.subtitle,
            this.allElements,
            localStorage.getItem('lessonLocale'),
            this.answers,
            this.dynamicAnswers
          )
        );
        this.descriptionSubject.next(
          this.evaluationService.replaceReference(
            this.description,
            this.allElements,
            localStorage.getItem('lessonLocale'),
            this.answers,
            this.dynamicAnswers
          )
        );
        this.evaluationService.evaluateExpression(
          this.expressionSubject,
          this.evaluationSubject,
          this.condition,
          this.element.position,
          this.answers,
          this.dynamicAnswers
        );
      })
    );

    // Assign translation
    this.content = this.findTranslation();
    this.setMedia(this.content);
    this.subscriptions.push(
      this.translateService.onLangChange.subscribe(
        (event: LangChangeEvent) => {
          this.content = this.findTranslation();
          this.setMedia(this.content);
        },
        error => {
          console.error(error);
        }
      )
    );
    this.subscriptions.push(
      this.lessonLocale$.subscribe(() => {
        this.content = this.findTranslation();
        this.setMedia(this.content);
      })
    );
    this.evaluationService.evaluateExpression(
      this.expressionSubject,
      this.evaluationSubject,
      this.condition,
      this.element.position,
      this.answers,
      this.dynamicAnswers
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  private findTranslation() {
    const _content = this.element.translations.find(translation => translation.locale === localStorage.getItem('lessonLocale'));
    return _content ? _content : this.element.translations[0];
  }

  private setMedia(content: MediaInterface): void {
    this.localUri = this.helper.getMediaPath(content.uri);
    this.description = content.description;
    this.subtitle = content.subtitle;
    this.fileName = content.filename;
    this.subtitleSubject.next(
      this.evaluationService.replaceReference(
        this.subtitle,
        this.allElements,
        localStorage.getItem('lessonLocale'),
        this.answers,
        this.dynamicAnswers
      )
    );
    this.descriptionSubject.next(
      this.evaluationService.replaceReference(
        this.description,
        this.allElements,
        localStorage.getItem('lessonLocale'),
        this.answers,
        this.dynamicAnswers
      )
    );
  }
}
