<button [attr.id]="'btnDialogClose'" mat-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title>
  <span *ngIf="isTest; then thenTestTitle else elseOfficialTitle"></span>
  <ng-template #thenTestTitle>{{'group-intervention-review.review-test-modal_title' | translate:param}} </ng-template>
  <ng-template #elseOfficialTitle>{{'group-intervention-review.review-official-modal_title' | translate:param}}</ng-template>
</h1>
<div mat-dialog-content class="mat-typography">
  <p>
    <span *ngIf="isTest; then thenTestBody else elseOfficialBody"></span>
    <ng-template #thenTestBody>{{'group-intervention-review.review-test-modal_body' | translate:param}}</ng-template>
    <ng-template #elseOfficialBody>{{'group-intervention-review.review-official-modal_body' | translate:param}}</ng-template>
  </p>

  <section>
    <ul>
      <li>
        <mat-checkbox [attr.id]="'checkboxFirstTerm'" [(ngModel)]="firstTerm" (ngModelChange)="updateCondition()">
          <ng-template *ngIf="isTest; then thenTest1 else elseOfficial1"></ng-template>
          <ng-template #thenTest1>{{'group-intervention-review.publish-test-terms_1' | translate}}</ng-template>
          <ng-template #elseOfficial1>{{'group-intervention-review.publish-official-terms_1' | translate}}</ng-template>
        </mat-checkbox>
      </li>
      <li>
        <mat-checkbox [attr.id]="'checkboxSecondTerm'" [(ngModel)]="secondTerm" (ngModelChange)="updateCondition()">
          <span *ngIf="isTest; then thenTest2 else elseOfficial2"></span>
          <ng-template #thenTest2>{{'group-intervention-review.publish-test-terms_2' | translate}}</ng-template>
          <ng-template #elseOfficial2>{{'group-intervention-review.publish-official-terms_2' | translate}}</ng-template>
        </mat-checkbox>
      </li>
      <li *ngIf="!isTest">
        <mat-checkbox [attr.id]="'checkboxThirdTerm'" [(ngModel)]="thirdTerm" (ngModelChange)="updateCondition()">
          {{'group-intervention-review.publish-official-terms_3' | translate}}
        </mat-checkbox>
      </li>
      <li *ngIf="!isTest">
        <mat-checkbox *ngIf="!isTest" [attr.id]="'checkboxFourthTerm'" [(ngModel)]="fourthTerm" (ngModelChange)="updateCondition()">
          {{'group-intervention-review.publish-official-terms_4' | translate}}
        </mat-checkbox>
      </li>
      <li *ngIf="!isTest">
        <mat-checkbox *ngIf="!isTest" [attr.id]="'checkboxFifthTerm'" [(ngModel)]="fifthTerm" (ngModelChange)="updateCondition()">
          {{'group-intervention-review.publish-official-terms_5' | translate}}
        </mat-checkbox>
      </li>
      <li *ngIf="!isTest">
        <mat-checkbox *ngIf="!isTest" [attr.id]="'checkboxSixthTerm'" [(ngModel)]="sixthTerm" (ngModelChange)="updateCondition()">
          {{'group-intervention-review.publish-official-terms_6' | translate}}
        </mat-checkbox>
      </li>
    </ul>
  </section>
</div>
<div mat-dialog-actions align="end">
  <button [attr.id]="'btnCancel'" mat-button mat-dialog-close>{{'group-intervention-review.cancel' | translate}}</button>
  <app-button-feedback
    [_setId]="'btnRejectPendingIntervention'"
    [_isDisabled]="(confirmInterventionReviewAsECoachManagerResponse | async) !== 'DEFAULT' || !updateCondition()"
    (click)="confirmReviewedIntervention()"
    [_defaultButtonText]="'group-intervention-review.button_review_intervention'"
    [_responseText]="confirmInterventionReviewAsECoachManagerResponse | async"
  ></app-button-feedback>
</div>
