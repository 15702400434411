<div *ngIf="(isLoading$ | async); else elsePatientStudyInvitationBlock">
  <app-loader></app-loader>
</div>

<ng-template #elsePatientStudyInvitationBlock>
  <div [hidden]="isHidden$ | async" class="container text-start">
    <!-- Common studies -->
    <div class="mt-3">
      <div *ngIf="memberInStudy.length > 0; then thenShowMemberInStudy else elseShowNoMemberInStudy"></div>
      <ng-template #thenShowMemberInStudy>
        <p><strong>{{'patient-invitation-study.modal_list_already_in_study' | translate}}:</strong></p>
        <ul [attr.id]="'study_' + collab.id" *ngFor="let collab of memberInStudy" class="list-group">
          <li class="list-group-item mb-1">
            <div class="row mx-2 mb-2">
              <img
                class="col-4"
                [src]="helper.getAllMediaSupportPath(collab.attributes.picture, collab.id)"
                (error)="helper.getFallbackImage($event)"
                alt="No image"
                class="image-parent img-fluid"
              />
              <div class="col-8">
                <p>
                  <strong>
                    {{(collab.attributes.title) ? collab.attributes.title : 'patient-invitation-study.no_group_title' |translate}}
                  </strong>
                </p>
                <p><small class="text-muted">({{collab.attributes.name}}) (ID - {{collab.id}})</small></p>
              </div>
            </div>
          </li>
        </ul>
      </ng-template>
      <ng-template #elseShowNoMemberInStudy>
        <p><strong>{{'patient-invitation-study.modal_list_no_common_study' | translate}}</strong></p>
      </ng-template>
    </div>
    <!-- Select studies -->
    <div class="mt-3">
      <div *ngIf="fStudyList.controls.length > 0; then thenShowStudyList else elseShowNoStudyList"></div>

      <ng-template #thenShowStudyList>
        <div>
          <p><strong>{{'patient-invitation-study.modal_list_of_study' | translate}}:</strong></p>
        </div>
        <form (ngSubmit)="sendInvitation(patient.id)" [formGroup]="studyForm">
          <!-- Alert -->
          <div *ngIf="submitted">
            <app-alert></app-alert>
          </div>

          <div class="card outline">
            <div class="card-body text-start">
              <div *ngFor="let study of fStudyList.controls; let i = index; last as isLastStudy" formArrayName="studyList">
                <div [attr.id]="'study_' + studyList[i].id" [formGroupName]="i" class="form-group row">
                  <div class="row mx-2 mb-2">
                    <img
                      class="col-4"
                      [src]="helper.getAllMediaSupportPath(studyList[i].attributes.picture, studyList[i].id)"
                      (error)="helper.getFallbackImage($event)"
                      alt="No image"
                      class="image-parent img-fluid"
                    />
                    <div class="col-8">
                      <p>
                        <strong>
                          {{(studyList[i].attributes.title) ? studyList[i].attributes.title : 'patient-invitation-study.no_group_title'
                          |translate}}
                        </strong>
                      </p>
                      <p><small class="text-muted">({{studyList[i].attributes.name}}) (ID - {{studyList[i].id}})</small></p>
                      <p class="text-muted">
                        {{'patient-invitation-study.collaborator_roles' | translate}}:
                        <span *ngFor="let role of getIncludedRolesOfStudy(studyList[i].id); last as isLast"
                          >{{helper.parseRoleSlug(role.attributes.slug) | translate}}<span *ngIf="!isLast"> | </span>
                        </span>
                      </p>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <mat-checkbox [attr.id]="'checkboxStudy' + studyList[i].id" [attr.value]="studyList[i]" [formControlName]="'checked'">
                      <div *ngIf="hasPendingInvitation(studyList[i])" class="invalid-feedback d-block">
                        {{'patient-invitation-study.pending_invitation_exists' | translate}}
                      </div>
                    </mat-checkbox>
                  </div>
                  <div class="col-md-6">
                    <mat-form-field [style.width.%]="100" appearance="outline">
                      <mat-label>{{'patient-invitation-study.form_create_patient_study_code' | translate}}</mat-label>
                      <input
                        [attr.id]="'study_' + studyList[i]"
                        [formControlName]="'code'"
                        matInput
                        type="text"
                        [errorStateMatcher]="matcher"
                      />
                      <mat-error *ngIf="(codeTaken$ | async) && codeUsed(studyList[i].id) && submitted" class="mb-5">
                        {{'patient-invitation-study.code_has_been_used' | translate}}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <mat-divider class="my-3" *ngIf="!isLastStudy"></mat-divider>
              </div>
            </div>
          </div>

          <app-email-language-selection
            (emitLanguageSelection)="setSelectedLanguage($event)"
            [_initialLanguage]="selectedLanguage"
          ></app-email-language-selection>
        </form>
      </ng-template>
      <ng-template #elseShowNoStudyList>
        <p><strong>{{'patient-invitation-study.modal_list_no_other_study' | translate}}</strong></p>
      </ng-template>
    </div>
  </div>
</ng-template>
