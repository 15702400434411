import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { faKey } from '@fortawesome/free-solid-svg-icons/faKey';
import { faList } from '@fortawesome/free-solid-svg-icons/faList';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faSitemap } from '@fortawesome/free-solid-svg-icons/faSitemap';
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons/faEnvelopeOpenText';
import { faBriefcase } from '@fortawesome/free-solid-svg-icons/faBriefcase';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons/faCheckSquare';
import { Actions, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Subscription, take, mergeMap, of, throwError, BehaviorSubject, filter, Observable, skip, forkJoin, distinct } from 'rxjs';
import { OrganisationCollaboratorInterface } from '../../../models/interface/organisation/organisation-collaborator.interface';
import { ProfileInterface } from '../../../models/interface/profile.interface';
import { RoleInterface } from '../../../models/interface/role.interface';
import { StudyInterface } from '../../../models/interface/study/study.interface';
import { UserInterface } from '../../../models/interface/user.interface';
import { HelperDialogService } from '../../../services/helper/helper-dialog/helper-dialog.service';
import { HelperService } from '../../../services/helper/helper.service';
import { OrganisationActionTypes, GetOrganisationCollaboratorsSuccess } from '../../../store/organisation/organisation.action';
import { StudyActionTypes } from '../../../store/study/study.action';
import { getCollaboratorsByStudyId } from '../../../store/study/study.selector';
import { UserActionTypes } from '../../../store/user/user.action';
import { faPen } from '@fortawesome/free-solid-svg-icons/faPen';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { OrganisationInterface } from '../../../models/interface/organisation/organisation.interface';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-card-study',
  templateUrl: './card-study.component.html',
  styleUrls: ['./card-study.component.scss']
})
export class CardStudyComponent implements OnInit {
  @Output()
  public emitUpdatePerformed: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public emitDelete: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public emitUpdate: EventEmitter<any> = new EventEmitter<any>();

  // Icons
  faSearch = faSearch;
  faList = faList;
  faUsers = faUsers;
  faKey = faKey;
  faEnvelopeOpenText = faEnvelopeOpenText;
  faSitemap = faSitemap;
  faBriefcase = faBriefcase;
  faCheckSquare = faCheckSquare;
  faStar = faStar;
  faPlus = faPlus;
  faPen = faPen;
  faTrash = faTrash;

  public study: StudyInterface;

  public profile$: Observable<ProfileInterface>;
  public profileRoles$: Observable<Array<RoleInterface>>;

  public isOrganisationCollaborator$: Observable<boolean>;

  public isAdmin$: Observable<boolean>;

  public showAdmin = false;

  public patient: UserInterface;

  public organisations: Array<OrganisationInterface> = [];

  private collaboratorsOfStudy$: Observable<{ studyId: number; collaborators: UserInterface[] }>;

  private users$: Observable<Array<UserInterface>>;

  private groups$: Observable<Array<StudyInterface>>;

  private groupsSubject: BehaviorSubject<Array<StudyInterface>> = new BehaviorSubject<Array<StudyInterface>>([]);

  private isOrganisationCollaboratorSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private isAdminSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private collaboratingIds: Array<number>;

  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private helperService: HelperService,
    private helperDialogService: HelperDialogService,
    private actions$: Actions,
    private store: Store<{
      myProfile: ProfileInterface;
      myRoles: Array<RoleInterface>;
      users: Array<UserInterface>;
      collabGroups: Array<StudyInterface>;
    }>
  ) {
    this.profile$ = store.select('myProfile');
    this.profileRoles$ = store.select('myRoles');
    this.users$ = this.store.select('users');
    this.groups$ = this.store.select('collabGroups');
    this.isOrganisationCollaborator$ = this.isOrganisationCollaboratorSubject.asObservable();
    this.isAdmin$ = this.isAdminSubject.asObservable();
  }

  public get helper() {
    return this.helperService;
  }

  public get helperDialog() {
    return this.helperDialogService;
  }

  @Input()
  set _study(_study: StudyInterface) {
    if (_study) {
      this.study = _study;
    }
  }

  @Input()
  set _patient(_patient: UserInterface) {
    if (_patient) {
      this.patient = _patient;
    }
  }

  @Input()
  set _isAdmin(_isAdmin: boolean) {
    if (_isAdmin !== undefined) {
      this.isAdminSubject.next(_isAdmin);
      this.showAdmin = _isAdmin;
    }
  }

  @Input()
  set _organisations(_organisations: Array<OrganisationInterface>) {
    if (_organisations) {
      this.organisations = _organisations;
    }
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.groups$.subscribe(
        result => {
          const groups = result.filter(
            (group: StudyInterface) => group.attributes.type === 'study' || group.attributes.type === 'organisation'
          );
          this.groupsSubject.next(groups);
          this.collaboratingIds = groups.map(obj => obj.id);
          const foundStudy = groups.find((group: StudyInterface) => group.id.toString() === this.study.id.toString());
          if (foundStudy) {
            this.study = foundStudy;
          }
        },
        error => {
          console.error(error);
          this.groupsSubject.next([]);
          this.collaboratingIds = [];
        }
      )
    );

    let myProfile;
    if (this.study.attributes.organisation_id) {
      this.profile$
        .pipe(
          filter(profile => !!profile),
          take(1),
          mergeMap(profile => {
            myProfile = profile;
            this.store.dispatch({
              type: OrganisationActionTypes.getOrganisationCollaboratorsType,
              payload: {
                organisationId: this.study.attributes.organisation_id
              }
            });
            return this.actions$.pipe(
              ofType(
                OrganisationActionTypes.getOrganisationCollaboratorsSuccessType,
                OrganisationActionTypes.getOrganisationCollaboratorsErrorType
              ),
              take(1)
            );
          })
        )
        .subscribe((result: any) => {
          if (result.type === OrganisationActionTypes.getOrganisationCollaboratorsSuccessType) {
            const collaborators = result['response']['body']['data'];

            // Determine my highest role
            const myself: OrganisationCollaboratorInterface = collaborators.find(user => user.id.toString() === myProfile.id.toString());
            if (myself !== undefined) {
              const myroleSlug = this.helperService.getHighestRoleOfOrganisationCollaborator(myself);
              if (myroleSlug.match(/organisation\.(manager|owner)$/)) {
                this.isOrganisationCollaboratorSubject.next(true);
              } else {
                this.isOrganisationCollaboratorSubject.next(false);
              }
            }
          }
        });
    }
  }

  public isCollabId(id: number): boolean {
    return this.collaboratingIds !== undefined ? this.collaboratingIds.includes(id) : false;
  }

  public getDetailedStudy(id: number): void {
    this.router.navigateByUrl(`/groups/${id}`);
  }

  public getPatientOverview(): void {
    this.router.navigate(['/patients']);
  }

  public getDetailedOrganisation(id: number): void {
    this.router.navigateByUrl(`organisations/${id}/studies`);
  }

  public openDialogGroupCollaboratorOrganisationManager(study: StudyInterface): void {
    this.subscriptions.push(
      this.profileRoles$
        .pipe(
          filter(user => user !== null),
          take(1),
          mergeMap((resp: Array<RoleInterface>) => {
            const roles: Array<RoleInterface> = resp;
            this.isAdminSubject.next(this.helperService.hasRoles(roles, /admin$/));
            const reqs: Array<Observable<any>> = [];

            this.store.dispatch({
              type: StudyActionTypes.getCollaboratorsType,
              payload: { studyId: study.id, include: 'roles' }
            });

            this.collaboratorsOfStudy$ = this.store.select(getCollaboratorsByStudyId(study.id));
            reqs.push(this.collaboratorsOfStudy$.pipe(skip(1), take(1)));

            if (this.isAdminSubject.value) {
              this.store.dispatch({ type: UserActionTypes.getAllUsersAdminType, payload: {} });
              reqs.push(this.users$.pipe(skip(1), take(1)));
            } else {
              this.store.dispatch({
                type: OrganisationActionTypes.getOrganisationCollaboratorsType,
                payload: {
                  organisationId: study.attributes.organisation_id
                }
              });
              reqs.push(
                this.actions$.pipe(
                  ofType(
                    OrganisationActionTypes.getOrganisationCollaboratorsSuccessType,
                    OrganisationActionTypes.getOrganisationCollaboratorsErrorType
                  ),
                  take(1),
                  mergeMap((action: Action) => {
                    if (action.type === OrganisationActionTypes.getOrganisationCollaboratorsSuccessType) {
                      return of((action as GetOrganisationCollaboratorsSuccess).response);
                    }
                    if (action.type === OrganisationActionTypes.getOrganisationCollaboratorsErrorType) {
                      return throwError('Error while returning organisation collaborators');
                    }
                    return of((action as GetOrganisationCollaboratorsSuccess).response);
                  })
                )
              );
            }
            return forkJoin(reqs);
          }),
          mergeMap((results: Array<any>) => {
            const collaborators = results[0].collaborators;

            const eCoaches =
              results[1] instanceof HttpResponse
                ? this.getEditorsNotInStudy(results[1].body.data, collaborators)
                : this.getEditorsNotInStudy(results[1], collaborators);

            return this.helperDialog
              .openDialogGroupCollaboratorOrganisationManager(
                study,
                collaborators.filter(collaborator => collaborator.attributes.is_verified === 1),
                eCoaches.filter(collaborator => collaborator.attributes.is_verified === 1)
              )
              .afterClosed();
          })
        )
        .subscribe(
          result => {
            this.emitUpdatePerformed.emit(result);
          },
          error => {
            throwError('Loading error', error);
          }
        )
    );
  }

  public openDialogAssignStudy(study: StudyInterface): void {
    this.helperDialog
      .openDialogAssignStudy(study)
      .afterClosed()
      .subscribe(result => {
        this.emitUpdatePerformed.emit(result);
      });
  }

  public openDialogStudyUpdateAdmin(study: StudyInterface): void {
    this.helperDialog
      .openDialogStudyUpdate(study)
      .afterClosed()
      .subscribe(() => {
        this.emitUpdate.emit();
      });
  }

  public openDialogStudyDeleteAdmin(study: StudyInterface): void {
    this.helperDialog
      .openDialogStudyDelete(study)
      .afterClosed()
      .subscribe(() => {
        this.emitDelete.emit();
      });
  }

  public getStudyCodeFromStudy(): string {
    const patientCodes: Array<{ study_id: number; code: string }> = this.patient.attributes.study_code;
    const found: { study_id: number; code: string } = patientCodes.find(
      (value: { study_id: number; code: string }) => value.study_id.toString() === this.study.id.toString()
    );
    if (found) {
      return found.code;
    } else {
      return '';
    }
  }

  // Get all eCoaches who are not part of the study/study
  private getEditorsNotInStudy(allEditors: Array<UserInterface>, myCollaborators: Array<UserInterface>): Array<UserInterface> {
    let notCollab: Array<UserInterface> = allEditors;
    myCollaborators.forEach(myCollab => {
      notCollab = notCollab.filter(editor => editor.id !== myCollab.id);
    });
    return notCollab;
  }
}
