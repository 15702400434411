<button [attr.id]="'btnDialogClose'" mat-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title>{{'group-buddy.buddy_instance_swap_modal_title' | translate}}</h1>
<div class="content-height" mat-dialog-content>
  <!-- First buddy -->
  <div>
    <mat-form-field class="full-width mb-2">
      <mat-label>{{'group-buddy.select_active_buddy_instance' | translate}}</mat-label>
      <mat-select [attr.id]="'selectedBuddyInstance1'" (selectionChange)="onChangeSelection1($event)" [(value)]="selectedBuddyInstance1">
        <mat-option [attr.id]="'option_' + buddy.id" *ngFor="let buddy of buddySelection1" [value]="buddy"
          >{{'group-buddy.buddy_instance_id' | translate}} #{{buddy.id}}</mat-option
        >
      </mat-select>
    </mat-form-field>

    <mat-form-field class="full-width mb-2">
      <mat-label>{{'group-buddy.select_buddy_to_remove' | translate}}</mat-label>
      <mat-select [attr.id]="'selectedBuddyToRemove'" (selectionChange)="onChangeUserSelection($event)" [(ngModel)]="selectedBuddyToRemove">
        <mat-option [attr.id]="'option_' + user.id" *ngFor="let user of buddyMembers1" [value]="user"
          >{{user.attributes.email}}
          <span *ngIf="helper.getStudyCode(studyId, user)">({{helper.getStudyCode(studyId, user)}})</span></mat-option
        >
      </mat-select>
      <mat-hint class="text-info" *ngIf="buddyMembers1?.length === 0">{{'group-buddy.buddy_user_required' | translate}}</mat-hint>
    </mat-form-field>
  </div>

  <hr />

  <!-- Second buddy -->
  <div>
    <mat-form-field class="full-width mb-2">
      <mat-label>{{'group-buddy.select_inactive_buddy_instance' | translate}}</mat-label>
      <mat-select [attr.id]="'selectedBuddyInstance2'" (selectionChange)="onChangeSelection2($event)" [(value)]="selectedBuddyInstance2">
        <mat-option [attr.id]="'option_' + buddy.id" *ngFor="let buddy of buddySelection2" [value]="buddy"
          >{{'group-buddy.buddy_instance_id' | translate}} #{{buddy.id}}</mat-option
        >
      </mat-select>
    </mat-form-field>

    <mat-form-field class="full-width mb-2">
      <mat-label>{{'group-buddy.select_buddy_to_add' | translate}}</mat-label>
      <mat-select [attr.id]="'selectedBuddyToAdd'" (selectionChange)="onChangeUserSelection($event)" [(ngModel)]="selectedBuddyToAdd">
        <mat-option [attr.id]="'option_' + user.id" *ngFor="let user of buddyMembers2" [value]="user"
          >{{user.attributes.email}}
          <span *ngIf="helper.getStudyCode(studyId, user)">({{helper.getStudyCode(studyId, user)}})</span></mat-option
        >
      </mat-select>
      <mat-hint class="text-info" *ngIf="buddyMembers2?.length === 0">{{'group-buddy.buddy_user_required' | translate}}</mat-hint>
    </mat-form-field>
  </div>

  <p *ngIf="isAllSelected()" class="text-info">{{'group-buddy.inactive_buddy' | translate:param1}}</p>
  <p *ngIf="isAllSelected()" class="text-info">{{'group-buddy.active_buddy' | translate:param2}}</p>
</div>
<div mat-dialog-actions align="end">
  <button [attr.id]="'btnCancel'" mat-button mat-dialog-close>{{'group-buddy.cancel' | translate}}</button>
  <button [attr.id]="'btnSwapBuddyConnection'" (click)="swapBuddyConnection()" [disabled]="!isAllSelected()" mat-button>
    {{'group-buddy.update_buddy' | translate}}
  </button>
</div>
