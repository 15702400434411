import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { map, switchMap, of, catchError } from 'rxjs';
import {
  addAllInterventionInstanceRemindersStore,
  GetAllInterventionInstanceRemindersOfUser,
  ReminderActionTypes
} from './reminder.action';
import { HttpErrorResponseMessage } from 'src/app/enum/http-error-response-message';
import { ResponseError } from '../utils/response-error';
import { ResponseSuccess } from '../utils/response-success';
import { ReminderService } from '../../services/api/reminder/reminder.service';

@Injectable()
export class ReminderEffects {
  getAllInterventionInstanceRemindersOfUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReminderActionTypes.getAllInterventionInstanceRemindersOfUserType),
      map((action: GetAllInterventionInstanceRemindersOfUser) => action.payload),
      switchMap((payload: any) =>
        this.reminderService.getAllInterventionInstanceReminders().pipe(
          switchMap(result => {
            const actions = [];
            actions.push(addAllInterventionInstanceRemindersStore({ allInterventionInstanceReminders: result.body.data }));
            actions.push(new ResponseSuccess(ReminderActionTypes.getAllInterventionInstanceRemindersOfUserSuccessType, result));
            return actions;
          }),
          catchError(err =>
            of(
              new ResponseError(ReminderActionTypes.getAllInterventionInstanceRemindersOfUserErrorType, {
                message: HttpErrorResponseMessage.genericUnknownError,
                content: err
              })
            )
          )
        )
      )
    )
  );

  constructor(private actions$: Actions, private reminderService: ReminderService) {}
}
