import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { iif, Observable, of, switchMap } from 'rxjs';

/**
 * Guard:
 * Authentication guard that protects from unauthorized access to components.
 */

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  private isAuthenticated$: Observable<boolean>;

  constructor(private router: Router, private store: Store<{ isAuthenticated: boolean }>) {
    this.isAuthenticated$ = this.store.select('isAuthenticated');
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isAuthenticated$.pipe(switchMap(value => iif(() => value, of(true), of(this.router.parseUrl('/login')))));
  }
}
