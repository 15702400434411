import { AfterViewInit, Component, Inject, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { LessonPreviewComponent } from '../../questionnaire/lesson-preview/lesson-preview.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-dialog-intervention-lesson-preview',
  templateUrl: './dialog-intervention-lesson-preview.component.html',
  styleUrls: ['./dialog-intervention-lesson-preview.component.scss']
})
export class DialogInterventionLessonPreviewComponent implements OnInit, AfterViewInit {
  @ViewChild('lessonPreviewContainer', { read: ViewContainerRef }) lessonPreviewContainer: ViewContainerRef;

  public components;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    this.components = [];
    if (this.lessonPreviewContainer) {
      this.lessonPreviewContainer.clear();
    }
  }

  ngAfterViewInit(): void {
    const component = this.lessonPreviewContainer.createComponent<LessonPreviewComponent>(LessonPreviewComponent).instance;
    component._questionnaire_id = this.data.questionnaire_id;
    component._isDiary = false;
    component._study_id = this.data.study_id;
    this.components.push(component);
  }
}
