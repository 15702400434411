import { createReducer, on } from '@ngrx/store';
import {
  addOrganisationsStore,
  addOrganisationsAdminStore,
  addOrganisationsCollaboratorsStore,
  removeOrganisationsStore,
  removeOrganisationsCollaboratorsStore,
  updateOrganisationsStore,
  updateOrganisationsCollaboratorsStore
} from './organisation.action';
import { OrganisationInterface } from 'src/app/models/interface/organisation/organisation.interface';
import { UserInterface } from 'src/app/models/interface/user.interface';

export const initialState: Array<OrganisationInterface> = [];
export const initialOrganisationCollaboratorsState: Array<UserInterface> = [];

export const organisationsReducer = createReducer(
  initialState,
  on(addOrganisationsStore, (state, { organisations }) => organisations),
  on(addOrganisationsAdminStore, (state, { organisations }) => organisations),
  on(updateOrganisationsStore, (state, { organisations }) => organisations),
  on(removeOrganisationsStore, (state, {}) => [])
);

export const organisationsCollaboratorsReducer = createReducer(
  initialOrganisationCollaboratorsState,
  on(addOrganisationsCollaboratorsStore, (state, { collaborators }) => collaborators),
  on(updateOrganisationsCollaboratorsStore, (state, { collaborators }) => collaborators),
  on(removeOrganisationsCollaboratorsStore, (state, {}) => [])
);
