import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-instance-update-information',
  templateUrl: './instance-update-information.component.html',
  styleUrls: ['./instance-update-information.component.scss']
})
export class InstanceUpdateInformationComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
