import { createReducer, on } from '@ngrx/store';
import { updateIsAuthenticatedStore, removeIsAuthenticatedStore } from './authentication.action';

export const initialIsAuthenticated = false;

export const isAuthenticatedReducer = createReducer(
  initialIsAuthenticated,
  on(updateIsAuthenticatedStore, (state, { isAuthenticated }) => isAuthenticated),
  on(removeIsAuthenticatedStore, (state, {}) => false)
);
