import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, Observable, Subscription } from 'rxjs';
import { ConditionalblockInterface } from '../../../../models/interface/condition/conditionalblock.interface';
import { ElementInterface } from '../../../../models/interface/elements/element.interface';
import { EvaluationService } from '../../../../services/evaluation/evaluation.service';
import { QuestionSpaceInterface } from '../../../../models/interface/elements-question/question-space/question-space.interface';
import { AnswersheetAnswerInterface } from '../../../../models/interface/answersheet-answer.interface';
import { Store } from '@ngrx/store';

/**
 * Component:
 * Represents space element
 */

@Component({
  selector: 'app-elements-spaces',
  templateUrl: './elements-spaces.component.html',
  styleUrls: ['./elements-spaces.component.scss']
})
export class ElementsSpacesComponent implements OnInit, OnDestroy {
  public size = 1;
  public spacing: Array<string> = ['15px', '25px', '35px', '45px', '55px'];

  public condition: Array<ConditionalblockInterface> = [];

  public evaluation$: Observable<boolean>;
  public hidden$: Observable<boolean>;

  public pageNumber: number;
  public currentPage: number;

  private element: ElementInterface;
  private dynamicAnswers: Array<AnswersheetAnswerInterface> = [];
  private dynamicAnswers$: Observable<Array<AnswersheetAnswerInterface>>;

  private expressionSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private evaluationSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private hiddenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private subscriptions: Subscription[] = [];

  constructor(
    private evaluationService: EvaluationService,
    private store: Store<{
      dynamicAnswers: Array<AnswersheetAnswerInterface>;
    }>
  ) {
    this.dynamicAnswers$ = store.select('dynamicAnswers');
    this.evaluation$ = this.evaluationSubject.asObservable();
    this.hidden$ = this.hiddenSubject.asObservable();
  }

  @Input()
  set _element(_element: QuestionSpaceInterface | ElementInterface) {
    this.element = _element;
  }

  @Input()
  set _condition(_condition: Array<ConditionalblockInterface>) {
    this.condition = _condition;
  }

  @Input()
  set _page(_page) {
    if (_page !== undefined) {
      this.pageNumber = _page;
      if (this.currentPage !== undefined) {
        this.hiddenSubject.next(this.currentPage.toString() !== this.pageNumber.toString());
      }
    }
  }

  @Input()
  set _currentPage(_currentPage) {
    if (_currentPage !== undefined) {
      this.currentPage = _currentPage;
      if (this.pageNumber !== undefined) {
        this.hiddenSubject.next(this.currentPage.toString() !== this.pageNumber.toString());
      }
    }
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.dynamicAnswers$.pipe(distinctUntilChanged()).subscribe(dynamicAnswers => {
        this.dynamicAnswers = dynamicAnswers;
        this.evaluationService.evaluateExpression(
          this.expressionSubject,
          this.evaluationSubject,
          this.condition,
          this.element.position,
          this.dynamicAnswers,
          this.dynamicAnswers
        );
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
