import { Action, createAction, props } from '@ngrx/store';
import { UserInterface } from '../../models/interface/user.interface';
import { InvitationInterface } from '../../models/interface/invitation.interface';

export enum UserActionTypes {
  addUsersStoreType = 'Add Users Store',
  updateUsersStoreType = 'Update Users Store',
  deleteUsersStoreType = 'Delete Users Store',

  addMyMembersStoreType = 'Add My Members Store',
  updateMyMembersStoreType = 'Update My Members Store',
  deleteMyMembersStoreType = 'Delete My Members Store',

  addAllECoachesStoreType = 'Add All eCoaches Store',
  updateAllECoachesStoreType = 'Updat All eCoaches Store',
  deleteAllECoachesStoreType = 'Delete All eCoaches Store',

  addAllInvitationsStoreType = 'Add All Invitations Store',
  updateAllInvitationsStoreType = 'Update All Invitations Store',
  deleteAllInvitationsStoreType = 'Delete All Invitations Store',

  addMyRegisteredUsersStoreType = 'Add My Registered Users Store',
  updateMyRegisteredUsersStoreType = 'Update My Registered Users Store',
  deleteMyRegisteredUsersStoreType = 'Delete My Registered Users Store',

  getAllUsersAdminType = '[Study API] Get All Users as Administrator',
  getAllUsersAdminSuccessType = '[Success] Get All Users as Administrator',
  getAllUsersAdminErrorType = '[Error] Get All Users as Administrator',

  getMyMembersType = '[Study API] Get My Members',
  getMyMembersSuccessType = '[Success] Get My Members',
  getMyMembersErrorType = '[Error] Get My Members',

  getAllEcoachesType = '[Study API] Get All eCoaches',
  getAllEcoachesSuccessType = '[Success] Get All eCoaches',
  getAllEcoachesErrorType = '[Error] Get All eCoaches',

  getAllInvitationsOfEcoachMembersType = '[Study API] Get All Invitations of All Members',
  getAllInvitationsOfEcoachMembersSuccessType = '[Success] Get All Invitations of All Members',
  getAllInvitationsOfEcoachMembersErrorType = '[Error] Get All Invitations of All Members',

  getUsersRegisteredByEcoachType = '[Study API] Get All Users Registered by eCoach',
  getUsersRegisteredByEcoachSuccessType = '[Success] Get All Users Registered by eCoach',
  getUsersRegisteredByEcoachErrorType = '[Error] Get All Users Registered by eCoach'
}

export const addUsersStore = createAction(UserActionTypes.addUsersStoreType, props<{ users: Array<UserInterface> }>());
export const updateUsersStore = createAction(UserActionTypes.updateUsersStoreType, props<{ users: Array<UserInterface> }>());
export const removeUsersStore = createAction(UserActionTypes.deleteUsersStoreType);

export const addMyMembersStore = createAction(UserActionTypes.addMyMembersStoreType, props<{ myMembers: Array<UserInterface> }>());
export const updateMyMembersStore = createAction(UserActionTypes.updateMyMembersStoreType, props<{ myMembers: Array<UserInterface> }>());
export const removeMyMembersStore = createAction(UserActionTypes.deleteMyMembersStoreType);

export const addAllECoachesStore = createAction(UserActionTypes.addAllECoachesStoreType, props<{ allECoaches: Array<UserInterface> }>());
export const updateAllECoachesStore = createAction(
  UserActionTypes.updateAllECoachesStoreType,
  props<{ allECoaches: Array<UserInterface> }>()
);
export const removeAllECoachesStore = createAction(UserActionTypes.deleteAllECoachesStoreType);

export const addAllInvitationsStore = createAction(
  UserActionTypes.addAllInvitationsStoreType,
  props<{ allInvitations: Array<InvitationInterface> }>()
);
export const updateAllInvitationsStore = createAction(
  UserActionTypes.updateAllInvitationsStoreType,
  props<{ allInvitations: Array<InvitationInterface> }>()
);
export const removeAllInvitationsStore = createAction(UserActionTypes.deleteAllInvitationsStoreType);

export const addMyRegisteredUsersStore = createAction(
  UserActionTypes.addMyRegisteredUsersStoreType,
  props<{ myRegisteredUsers: Array<UserInterface> }>()
);
export const updateMyRegisteredUsersStore = createAction(
  UserActionTypes.updateMyRegisteredUsersStoreType,
  props<{ myRegisteredUsers: Array<UserInterface> }>()
);
export const removeMyRegisteredUsersStore = createAction(UserActionTypes.deleteMyRegisteredUsersStoreType);

export class GetAllUsersAdmin implements Action {
  readonly type: string = UserActionTypes.getAllUsersAdminType;
  constructor(readonly payload: any) {}
}

export class GetAllUsersAdminSuccess implements Action {
  readonly type: string = UserActionTypes.getAllUsersAdminSuccessType;
  constructor(readonly response: any) {}
}

export class GetAllUsersAdminError implements Action {
  readonly type: string = UserActionTypes.getAllUsersAdminErrorType;
  constructor(readonly response: any) {}
}

export class GetMyMembers implements Action {
  readonly type: string = UserActionTypes.getMyMembersType;
  constructor(readonly payload: any) {}
}

export class GetMyMembersSuccess implements Action {
  readonly type: string = UserActionTypes.getMyMembersSuccessType;
  constructor(readonly response: any) {}
}

export class GetMyMembersError implements Action {
  readonly type: string = UserActionTypes.getMyMembersErrorType;
  constructor(readonly response: any) {}
}

export class GetAllEcoaches implements Action {
  readonly type: string = UserActionTypes.getAllEcoachesType;
  constructor(readonly payload: any) {}
}

export class GetAllEcoachesSuccess implements Action {
  readonly type: string = UserActionTypes.getAllEcoachesSuccessType;
  constructor(readonly response: any) {}
}

export class GetAllEcoachesError implements Action {
  readonly type: string = UserActionTypes.getAllEcoachesErrorType;
  constructor(readonly response: any) {}
}

export class GetAllInvitationsOfEcoachMembers implements Action {
  readonly type: string = UserActionTypes.getAllInvitationsOfEcoachMembersType;
  constructor(readonly payload: any) {}
}

export class GetAllInvitationsOfEcoachMembersSuccess implements Action {
  readonly type: string = UserActionTypes.getAllInvitationsOfEcoachMembersSuccessType;
  constructor(readonly response: any) {}
}

export class GetAllInvitationsOfEcoachMembersError implements Action {
  readonly type: string = UserActionTypes.getAllInvitationsOfEcoachMembersErrorType;
  constructor(readonly response: any) {}
}

export class GetUsersRegisteredByEcoach implements Action {
  readonly type: string = UserActionTypes.getUsersRegisteredByEcoachType;
  constructor(readonly payload: any) {}
}

export class GetUsersRegisteredByEcoachSuccess implements Action {
  readonly type: string = UserActionTypes.getUsersRegisteredByEcoachSuccessType;
  constructor(readonly response: any) {}
}

export class GetUsersRegisteredByEcoachError implements Action {
  readonly type: string = UserActionTypes.getUsersRegisteredByEcoachErrorType;
  constructor(readonly response: any) {}
}
