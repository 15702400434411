<div class="container-fluid text-center">
  <div class="col-sm-8 text-start mb-3">
    <h2>{{'lesson.lesson_from' | translate:param}}</h2>
    <hr />
  </div>

  <div *ngIf="(isLoading$ | async) === false; else elseLoadingBlock" class="container">
    <div *ngIf="questionnaires.length === 0; else elseLessonListBlock" class="mb-3">
      <p>{{'lesson.no_lesson_found' | translate}}</p>
    </div>

    <ng-template #elseLessonListBlock>
      <div class="container-fluid sticky pb-2">
        <div class="card">
          <div class="my-3">
            <button [attr.id]="'btnCollapseOverview'" mat-button (click)="helper.toggleSubject(isCollapseSubject)">
              <span *ngIf="(isCollapse$ | async)"
                ><fa-icon [icon]="faChevronDown"></fa-icon> {{'lesson.overview_all_lessons' | translate}}</span
              >
              <span *ngIf="(isCollapse$ | async) === false"
                ><fa-icon [icon]="faChevronUp"></fa-icon> {{'lesson.overview_all_lessons' | translate}}</span
              >
            </button>
          </div>
          <div *ngIf="(isCollapse$ | async)" class="d-flex flex-row flex-nowrap overflowList">
            <div [attr.id]="'lesson_' + lesson.id" *ngFor="let lesson of questionnaires; let i = index" class="card card-body card-fix m-2">
              <button
                [attr.id]="'btnInitializeLessonPreviewComponent_' + lesson.id"
                (click)="initializeLessonPreviewComponent(lesson.id, intervention.attributes.study_id)"
                class="btn btn-link"
              >
                {{lesson.attributes.position}} . {{((lesson.attributes.name) ? lesson.attributes.name : ('lesson.no_lesson_name' |
                translate))}}
              </button>
              <p class="strong">{{lesson.attributes.title}}</p>
              <p class="mb-1">{{lesson.attributes.description}}</p>

              <div
                *ngIf="lesson.attributes.unlock_diaries !== null && lesson.attributes.unlock_diaries.length > 0; then thenShowDiaries"
              ></div>
              <ng-template #thenShowDiaries>
                <hr />
                <div *ngFor="let diary of lesson.attributes.unlock_diaries">
                  <fa-icon [icon]="faBook"></fa-icon>
                  {{helper.findArrObjById(diary, diaries) !== null ? helper.findArrObjById(diary, diaries).attributes.name :
                  ('lesson.diary_not_found' | translate)}}
                </div>
              </ng-template>
              <!--
              <div *ngIf="lesson.attributes?.skills && lesson.attributes?.skills.length > 0; then thenShowSkills"></div>
              <ng-template #thenShowSkills>
                <div class="button-row">
                  <button
                    [attr.id]="'btnOpenDialogSkill_' +  skillId"
                    *ngFor="let skillId of lesson.attributes.skills; last as isLast"
                    [ngClass]="{'spacing-right': !isLast}"
                    class="mb-2"
                    (click)="helperDialog.openDialogSkill(skillId, skills, intervention)"
                    mat-button
                  >
                    <span [ngStyle]="helperSkill.getColorStyle(skillId, skills)"
                      ><fa-icon [icon]="faSquare"></fa-icon> {{helper.findArrObjById(skillId, skills) !== null ?
                      helper.findArrObjById(skillId, skills).attributes.title : ('lesson.skill_not_found' | translate)}}</span
                    >
                  </button>
                </div>
              </ng-template>
            --></div>
          </div>
        </div>
      </div>

      <ng-template #lessonPreviewContainer> </ng-template>

      <div *ngIf="isLoadingLesson$ | async">
        <app-loader></app-loader>
      </div>

      <div *ngIf="components.length === 0" class="container">
        <div class="jumbotron text-center hoverable p-4 noPreviewSize">
          <div class="center">
            <p class="lead"><small>{{'lesson.no_lesson_preview_available' | translate}}</small></p>
          </div>
        </div>
      </div>
    </ng-template>
  </div>

  <ng-template #elseLoadingBlock>
    <div>
      <app-loader></app-loader>
    </div>
  </ng-template>
</div>
