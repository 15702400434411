import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { InterventionInterface } from '../../../models/interface/intervention.interface';

@Injectable({
  providedIn: 'root'
})
export class InterventionSharedService {
  public intervention$: Observable<InterventionInterface>;
  public isEcoach$: Observable<boolean>;
  public isManager$: Observable<boolean>;
  public isPublisher$: Observable<boolean>;
  public sourceMyUserId$: Observable<number>;

  private interventionSubject = new BehaviorSubject<InterventionInterface>(null);
  private isECoachSubject = new BehaviorSubject<boolean>(false);
  private isManagerSubject = new BehaviorSubject<boolean>(false);
  private isPublisherSubject = new BehaviorSubject<boolean>(false);
  private myUserIdSubject = new BehaviorSubject<number>(null);

  constructor() {
    this.intervention$ = this.interventionSubject.asObservable();
    this.isEcoach$ = this.isECoachSubject.asObservable();
    this.isManager$ = this.isManagerSubject.asObservable();
    this.isPublisher$ = this.isPublisherSubject.asObservable();
    this.sourceMyUserId$ = this.myUserIdSubject.asObservable();
  }

  pushIntervention(intervention: InterventionInterface) {
    this.interventionSubject.next(intervention);
  }

  pushIsECoach(value: boolean) {
    this.isECoachSubject.next(value);
  }

  pushMyUserId(id: number) {
    this.myUserIdSubject.next(id);
  }

  pushIsManager(value: boolean) {
    this.isManagerSubject.next(value);
  }

  pushIsPublisher(value: boolean) {
    this.isPublisherSubject.next(value);
  }
}
