import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { RoleInterface } from 'src/app/models/interface/role.interface';
import { AuthenticationService } from 'src/app/services/api/authentication/authentication.service';
import { MessageService } from 'src/app/services/api/message/message.service';
import { MyProfileService } from 'src/app/services/api/my-profile/my-profile.service';
import { CustomCookieService } from 'src/app/services/customcookie/custom-cookie.service';
import { FcmMessageService } from 'src/app/services/fcm-message/fcm-message.service';
import {
  AuthenticationActionTypes,
  forcedLogoutStore,
  Login,
  removeIsAuthenticatedStore,
  updateIsAuthenticatedStore
} from './authentication.action';
import { HttpErrorResponseMessage } from 'src/app/enum/http-error-response-message';
import { ErrorInterface } from 'src/app/models/interface/error.interface';
import { MessageDeviceTokensInterface } from 'src/app/models/interface/message_devicetokens.interface';
import { ProfileActionTypes } from '../profile/profile.action';
import { ResponseSuccess } from '../utils/response-success';
import { ResponseError } from '../utils/response-error';
import { HelperService } from '../../services/helper/helper.service';

@Injectable()
export class AuthenticationEffects {
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActionTypes.loginType),
      map((action: Login) => action.payload),
      mergeMap((payload: any) =>
        this.authenticationService.login(payload.email, payload.password).pipe(
          catchError((error: HttpErrorResponse) => {
            if (error.status.toString() === '401' && (error.statusText === 'Unauthorized' || error.statusText === 'OK')) {
              return throwError({ message: HttpErrorResponseMessage.loginWrongCredentials, content: error });
            } else if (error.status.toString() === '422' && error.statusText === 'Unprocessable Entity') {
              return throwError({ message: HttpErrorResponseMessage.genericErrorUnprocessableEntity, content: error });
            } else if (error.status.toString() === '429' && error.statusText === 'Too Many Requests') {
              return throwError({ message: HttpErrorResponseMessage.loginTooManyAttempts, content: error });
            } else {
              return throwError({ message: HttpErrorResponseMessage.genericUnknownError, content: error });
            }
          }),
          mergeMap((resp: HttpResponse<any>) => {
            const token = resp.body['data']['attributes']['token'];
            localStorage.setItem('showBanner', 'true');
            if (token) {
              this.customCookieService.setJWTCookie(token);
              return this.myProfileService.getMyRoles();
            } else {
              return throwError('No token returned');
            }
          }),
          mergeMap((result: HttpResponse<any>) => {
            const roles: Array<RoleInterface> = result.body.data;
            if (this.helperService.hasRoles(roles, /(ecoach|ecoachmanager|admin)$/)) {
              this.router.navigate(['/home']);

              const reqs = [];

              // Set translation of user profile
              reqs.push(
                this.myProfileService.getMyProfile().pipe(
                  map((res: HttpResponse<any>) => {
                    let languageProfile: string;
                    const settings: Array<any> | { locale: string } = res.body.data.attributes.settings;
                    let foundSetting;
                    if (Array.isArray(settings)) {
                      foundSetting = settings.find(obj => obj.hasOwnProperty('locale'));
                    } else {
                      if (settings instanceof Object) {
                        foundSetting = settings.hasOwnProperty('locale');
                      }
                    }
                    if (foundSetting === undefined) {
                      return this.myProfileService.updateProfile({ locale: 'de' }).subscribe(() => {
                        localStorage.setItem('language', 'de');
                        this.translateService.use('de');
                      });
                    } else {
                      languageProfile = foundSetting.locale;
                      localStorage.setItem('language', languageProfile);
                      this.translateService.use(languageProfile);
                      return of([]);
                    }
                  })
                )
              );

              const isSupported = () => 'Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window;

              // Ask permission for notifications
              if (isSupported()) {
                Notification.requestPermission().then((permission: NotificationPermission) => {
                  console.log('Okay lets try it again - Permission ', permission);
                  if (permission === 'granted') {
                    console.log('Notification permission granted.');
                    // Register device token for notification
                    reqs.push(
                      this.messageService.registerDeviceToken(this.fcmMessagingService.tokenInstance).pipe(
                        map((resp: HttpResponse<any>) => resp),
                        catchError((error: HttpErrorResponse) => {
                          console.error('Register device token failed.');
                          return of(error);
                        })
                      )
                    );
                  } else {
                    console.warn('Unable to get permission to notify.');
                  }
                });
              }
              return forkJoin(reqs);
            } else {
              // Deny access to platform for user not in role eCoach or admin
              this.authenticationService.logout();
              return throwError({ message: HttpErrorResponseMessage.loginAccessDenied, content: null });
            }
          }),
          mergeMap(result => {
            const actions = [];
            actions.push(updateIsAuthenticatedStore({ isAuthenticated: true }));
            actions.push(new ResponseSuccess(AuthenticationActionTypes.loginSuccessType, result));
            return actions;
          }),
          catchError(err => {
            const actions = [];
            actions.push(forcedLogoutStore());
            actions.push(new ResponseError(AuthenticationActionTypes.loginErrorType, err));
            return actions;
          })
        )
      )
    )
  );

  refreshToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActionTypes.refreshTokenType),
      switchMap(() =>
        this.authenticationService.refreshToken().pipe(
          switchMap(result => {
            const actions = [];
            actions.push(updateIsAuthenticatedStore({ isAuthenticated: true }));
            actions.push(new ResponseSuccess(AuthenticationActionTypes.refreshTokenSuccessType, result));
            return actions;
          }),
          catchError(err => {
            if (err.statusText && err.statusText.toString() === 'Unknown Error') {
              return of(
                new ResponseError(AuthenticationActionTypes.refreshTokenErrorType, {
                  message: HttpErrorResponseMessage.genericUnknownError,
                  content: err
                })
              );
            }
            if (typeof err.error !== 'string' && err.error && err instanceof HttpErrorResponse && err.error.errors) {
              const errorData: ErrorInterface = err.error.errors[0];
              if (err.status.toString() === '404') {
                return of(
                  new ResponseError(AuthenticationActionTypes.refreshTokenErrorType, {
                    message: HttpErrorResponseMessage.genericNotFoundError,
                    content: err
                  })
                );
              }
              if (errorData.status.toString() === '401' && errorData.detail === 'Token has expired') {
                return of(
                  new ResponseError(AuthenticationActionTypes.refreshTokenErrorType, {
                    message: HttpErrorResponseMessage.refreshTokenExpired,
                    content: err
                  })
                );
              } else {
                if (errorData.status.toString() === '409' || errorData.detail === 'The token has been blacklisted') {
                  return of(
                    new ResponseError(AuthenticationActionTypes.refreshTokenErrorType, {
                      message: HttpErrorResponseMessage.refreshTokenBlacklisted,
                      content: err
                    })
                  );
                }
                if (
                  errorData.status.toString() === '401' &&
                  (errorData.detail === 'Token Signature could not be verified.' || errorData.detail === 'Wrong number of segments')
                ) {
                  return of(
                    new ResponseError(AuthenticationActionTypes.refreshTokenErrorType, {
                      message: HttpErrorResponseMessage.refreshTokenUnknownSignature,
                      content: err
                    })
                  );
                }
                if (
                  errorData.status.toString() === '401' &&
                  errorData.detail === 'Failed to authenticate because of bad credentials or an invalid authorization header.'
                ) {
                  return of(
                    new ResponseError(AuthenticationActionTypes.refreshTokenErrorType, {
                      message: HttpErrorResponseMessage.genericBadCredentialsOrInvalidHeadersError,
                      content: err
                    })
                  );
                }

                if (errorData.status.toString() === '403' || errorData.detail === 'You are not allowed to access this route or entity.') {
                  return of(
                    new ResponseError(AuthenticationActionTypes.refreshTokenErrorType, {
                      message: HttpErrorResponseMessage.genericNotAllowedError,
                      content: err
                    })
                  );
                }

                if (errorData.status.toString() === '401' && errorData.detail === 'Malformed token') {
                  return of(
                    new ResponseError(AuthenticationActionTypes.refreshTokenErrorType, {
                      message: HttpErrorResponseMessage.refreshTokenMalformed,
                      content: err
                    })
                  );
                }
              }
            }
            return of(
              new ResponseError(AuthenticationActionTypes.refreshTokenErrorType, {
                message: HttpErrorResponseMessage.genericUnknownError,
                content: err
              })
            );
          })
        )
      )
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActionTypes.logoutType),
      switchMap(() =>
        this.messageService.getAllDeviceTokens().pipe(
          map((result: HttpResponse<any>) => {
            localStorage.setItem('showBanner', 'false');
            const allDeviceTokens: Array<MessageDeviceTokensInterface> = result.body.data;
            return allDeviceTokens;
          }),
          map(allTokens => {
            // User's current device token
            let foundToken: MessageDeviceTokensInterface = null;
            let tokenId = null;
            if (this.fcmMessagingService.tokenInstance !== null) {
              foundToken = allTokens.find(
                (device: MessageDeviceTokensInterface) => device.attributes.firebase_token === this.fcmMessagingService.tokenInstance
              );
              if (foundToken !== undefined) {
                tokenId = foundToken.id;
              }
            }
            return of(tokenId);
          }),
          switchMap((tokenId$: Observable<any>) =>
            tokenId$.pipe(
              switchMap(id => {
                if (id !== null) {
                  return this.messageService.deleteDeviceToken(id).pipe(
                    switchMap(() => this.authenticationService.logout()),
                    catchError(() => this.authenticationService.logout())
                  );
                } else {
                  return this.authenticationService.logout();
                }
              })
            )
          ),
          switchMap(result => {
            this.customCookieService.deleteJWTCookie();
            const actions = [];
            actions.push(forcedLogoutStore());
            actions.push(new ResponseSuccess(AuthenticationActionTypes.logoutSuccessType, result));
            return actions;
          }),
          catchError(err => {
            const actions = [];
            actions.push(forcedLogoutStore());
            actions.push(
              new ResponseError(AuthenticationActionTypes.logoutErrorType, {
                message: HttpErrorResponseMessage.genericUnknownError,
                content: err
              })
            );
            return actions;
          })
        )
      )
    )
  );

  forcedLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActionTypes.forcedLogoutStoreType),
      switchMap(() => {
        this.customCookieService.deleteJWTCookie();
        this.router.navigate(['/login']);
        return of(removeIsAuthenticatedStore());
      })
    )
  );

  checkCookieAuth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActionTypes.checkCookieAuthStoreType),
      switchMap(() => {
        if (this.customCookieService.isCookieValid()) {
          const actions = [];
          actions.push(updateIsAuthenticatedStore({ isAuthenticated: true }));
          actions.push({ type: ProfileActionTypes.getProfileType });
          actions.push({ type: ProfileActionTypes.getProfileRolesType });
          actions.push({ type: ProfileActionTypes.getProfileActivitiesType, payload: { action: undefined, unlimited: true } });
          actions.push({ type: AuthenticationActionTypes.checkCookieAuthStoreSuccessType });
          return actions;
        } else {
          return of(forcedLogoutStore());
        }
      })
    )
  );

  constructor(
    private actions$: Actions,
    private authenticationService: AuthenticationService,
    private myProfileService: MyProfileService,
    private customCookieService: CustomCookieService,
    private messageService: MessageService,
    private fcmMessagingService: FcmMessageService,
    private translateService: TranslateService,
    private helperService: HelperService,
    private router: Router
  ) {}
}
