<button [attr.id]="'btnDialogClose'" mat-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title>{{'admin-dashboard.delete_study' | translate}}</h1>
<div mat-dialog-content class="mat-typography">
  <p class="text-danger">{{'admin-dashboard.delete_study_warning' | translate}}</p>
  <p>{{'admin-dashboard.delete_study_description' | translate}}: <strong>{{study.attributes.name}}</strong></p>
</div>
<div mat-dialog-actions align="end">
  <button [attr.id]="'btnCancel'" mat-button mat-dialog-close>{{'admin-dashboard.cancel' | translate}}</button>
  <div *ngIf="(deleteStudyConfirmation | async); then thenShowConfirmation else elseShowNext"></div>
  <ng-template #thenShowConfirmation>
    <app-button-feedback
      [_setId]="'btnDeleteStudy'"
      (click)="deleteStudy()"
      [_defaultButtonText]="'admin-dashboard.delete_study_confirm'"
      [_responseText]="deleteStudyResponse | async"
    ></app-button-feedback>
  </ng-template>
  <ng-template #elseShowNext>
    <button [attr.id]="'btnShowConfirmation'" mat-button (click)="showConfirmation()">{{'admin-dashboard.next' | translate}}</button>
  </ng-template>
</div>
