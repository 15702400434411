import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { OrganisationService } from 'src/app/services/api/organisation/organisation.service';
import {
  addOrganisationsStore,
  addOrganisationsAdminStore,
  addOrganisationsCollaboratorsStore,
  getOrganisationsCollaboratorsSuccessStore,
  GetOrganisationsAdmin,
  OrganisationActionTypes,
  GetOrganisationCollaborators,
  GetOrganisationCollaboratorsSuccess,
  GetOrganisationCollaboratorsError,
  GetOrganisationsAdminError,
  GetOrganisationsAdminSuccess,
  GetOrganisations
} from './organisation.action';
import { HttpErrorResponseMessage } from 'src/app/enum/http-error-response-message';
import { ResponseSuccess } from '../utils/response-success';
import { ResponseError } from '../utils/response-error';

@Injectable()
export class OrganisationEffects {
  loadOrganisations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationActionTypes.getOrganisationsType),
      map((action: GetOrganisations) => action.payload),
      switchMap(payload =>
        this.organisationService.getOrganisations(payload.include).pipe(
          mergeMap(result => {
            const actions = [];
            actions.push(addOrganisationsStore({ organisations: result.body.data }));
            actions.push(new ResponseSuccess(OrganisationActionTypes.getOrganisationsSuccessType, result));
            return actions;
          }),
          catchError(err =>
            of(
              new ResponseError(OrganisationActionTypes.getOrganisationsErrorType, {
                message: HttpErrorResponseMessage.genericUnknownError,
                content: err
              })
            )
          )
        )
      )
    )
  );

  loadOrganisationsAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(...[OrganisationActionTypes.getOrganisationsAdminType]),
      map((action: GetOrganisationsAdmin) => action.payload),
      switchMap(payload =>
        this.organisationService.getOrganisationsAdmin(payload.include, payload.id).pipe(
          mergeMap(result => {
            const actions = [];
            actions.push(addOrganisationsAdminStore({ organisations: result.body.data }));
            actions.push(new GetOrganisationsAdminSuccess(result));
            return actions;
          }),
          catchError(err => of(new GetOrganisationsAdminError(err)))
        )
      )
    )
  );

  loadOrganisationCollaborators$ = createEffect(() =>
    this.actions$.pipe(
      ofType(...[OrganisationActionTypes.getOrganisationCollaboratorsType]),
      map((action: GetOrganisationCollaborators) => action.payload),
      switchMap(payload =>
        this.organisationService.getCollaboratorsOfOrganisation(payload.organisationId).pipe(
          mergeMap(result => of(new GetOrganisationCollaboratorsSuccess(result))),
          catchError(err => of(new GetOrganisationCollaboratorsError(err)))
        )
      )
    )
  );

  constructor(private actions$: Actions, private organisationService: OrganisationService) {}
}
