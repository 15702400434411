/* eslint-disable @typescript-eslint/naming-convention */
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { mergeMap, skip, take } from 'rxjs/operators';
import { ThemePalette } from '@angular/material/core';
import { RequestBodyData } from '../../../models/request-body.data';
import { InterventionInterface } from '../../../models/interface/intervention.interface';
import { InterventionConfigurationInterface } from '../../../models/interface/intervention-configuration.interface';
import { HelperService } from '../../../services/helper/helper.service';
import { UserInterface } from '../../../models/interface/user.interface';
import { LessonInterface } from '../../../models/interface/lesson.interface';
import { PayloadInterface } from '../../../models/interface/payload.interface';
import { InterventionInstanceConfigurationInterface } from '../../../models/interface/intervention-instances/intervention-instance.configuration.interface';
import { DiaryInterface } from '../../../models/interface/diary.interface';
import { SkillInterface } from '../../../models/interface/skill.interface';
import { Store } from '@ngrx/store';
import { StudyActionTypes } from '../../../store/study/study.action';
import { DiaryStore } from '../../../store/diary/component-store/diary.store';
import { QuestionnaireStore } from '../../../store/lesson-questionnaire/component-store/lesson-questionnaire.store';
import { SkillStore } from '../../../store/skill/component-store/skill.store';
import { getCollaboratorsByStudyId } from '../../../store/study/study.selector';

/**
 * Component:
 * Instance creation form displaying configurations to assign interventions;
 */

@Component({
  selector: 'app-instance-creation-form',
  templateUrl: './instance-creation-form.component.html',
  styleUrls: ['./instance-creation-form.component.scss'],
  providers: [DiaryStore, QuestionnaireStore, SkillStore]
})
export class InstanceCreationFormComponent implements OnInit, OnDestroy {
  @Input() id!: string;

  // Emit payload of instance to parent component
  @Output()
  confirmedForm = new EventEmitter<PayloadInterface>();

  public isLoading$: Observable<boolean>;

  // Intervention and its default configurations
  public intervention: InterventionInterface;
  public myUserId: number;

  // Questionnaires of study
  public questionnaires: Array<LessonInterface> = [];

  public skills: Array<SkillInterface> = [];
  public diaries: Array<DiaryInterface> = [];

  public diariesOfStudy$: Observable<Array<DiaryInterface>>;
  public questionnairesOfIntervention$: Observable<Array<LessonInterface>>;

  // Form select options
  studyMembers: Array<UserInterface> = [];
  studyCollaborator: Array<UserInterface> = [];
  positionType: Array<string> = ['-1'];

  // Filter selection
  public filter = { userSelection: '' };

  public membersSubject: BehaviorSubject<Array<UserInterface>> = new BehaviorSubject<Array<UserInterface>>([]);
  public members$: Observable<Array<UserInterface>> = this.membersSubject.asObservable();

  // Instance creation instanceForm
  public instanceFormType: UntypedFormGroup;
  public currentDate = this.helperService.formatDateToYYYY_MM_DD_THH_MM(Date.now());

  public formCustomOrderSubject = new BehaviorSubject<Array<{ questionnaire: number; enabled: boolean }>>([]);

  public guidanceType = ['UNGUIDED', 'GUIDED'];

  public updateView: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  // Selected guiding type
  public selectedGuidanceType = 'UNGUIDED';

  // Data from StudyService
  public collaborators: Array<UserInterface> = [];
  public collaboratorsWithoutUser: Array<UserInterface> = [];

  public isManager$: Observable<boolean>;

  public showMoveShortCutSubject: BehaviorSubject<Array<boolean>> = new BehaviorSubject<Array<boolean>>([]);

  public skillsOfIntervention$: Observable<SkillInterface[]>;

  // Ngx datetimepicker
  public showSpinners = false;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate = new Date();
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';

  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private isManagerSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private interventionReadOnly: InterventionInterface;
  private defaultConfiguration: {
    questionnaire_configuration: Array<InterventionConfigurationInterface>;
    custom_order: Array<number>;
  };
  private defaultConfigurationReadOnly: {
    questionnaire_configuration: Array<InterventionConfigurationInterface>;
    custom_order: Array<number>;
  };

  private collaboratorsOfStudy$: Observable<{ studyId: number; collaborators: UserInterface[] }>;

  // Subscription handler
  private subscriptions: Subscription[] = [];

  constructor(
    private diaryStore: DiaryStore,
    private questionnaireStore: QuestionnaireStore,
    private skillStore: SkillStore,
    private formBuilder: UntypedFormBuilder,
    private translateService: TranslateService,
    private helperService: HelperService,
    private store: Store<{
      skills: Array<SkillInterface>;
      getCollaboratorsByStudyId: { studyId: number; collaborators: UserInterface[] };
    }>
  ) {
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.isManager$ = this.isManagerSubject.asObservable();

    // Form type contains searchFilter options
    this.instanceFormType = this.formBuilder.group({
      patient: ['', Validators.required],
      intervention_type: ['UNGUIDED', Validators.required],
      starts_at: ['', Validators.required],
      questionnaire_configuration: this.formBuilder.array([]),
      collaboratorList: new UntypedFormArray([])
    });

    if (this.studyMembers.length !== 0) {
      this.instanceFormType.controls['patient'].setValue(this.studyMembers[0], { onlySelf: true });
    }

    // Set values and date for group creation studyForm
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    this.instanceFormType.controls['starts_at'].setValue(new Date());

    this.diariesOfStudy$ = this.diaryStore.diariesOfStudy$;
    this.questionnairesOfIntervention$ = this.questionnaireStore.questionnairesOfIntervention$;
    this.skillsOfIntervention$ = this.skillStore.skillsOfIntervention$;
  }

  // Helper function
  public get f() {
    return this.instanceFormType.controls;
  }

  public get fCollaboratorList(): UntypedFormArray {
    return this.instanceFormType.get('collaboratorList') as UntypedFormArray;
  }

  public get fQuestionnaireConfiguration() {
    return this.instanceFormType.get('questionnaire_configuration') as UntypedFormArray;
  }

  public get helper() {
    return this.helperService;
  }

  // Set study and its configuration from parent
  @Input()
  set _intervention(_intervention: InterventionInterface) {
    this.interventionReadOnly = JSON.parse(JSON.stringify(_intervention));
    this.defaultConfigurationReadOnly = JSON.parse(JSON.stringify(_intervention.attributes.default_configuration));

    this.intervention = JSON.parse(JSON.stringify(_intervention));
    this.defaultConfiguration = JSON.parse(JSON.stringify(_intervention.attributes.default_configuration));

    if (this.intervention.attributes.intervention_type === 'accompanied') {
      this.instanceFormType.controls['intervention_type'].setValue('GUIDED', { onlySelf: true });
      this.selectedGuidanceType = 'GUIDED';
    }
  }

  // Set members of study from parent
  @Input()
  set _members(_members: Array<UserInterface>) {
    this.studyMembers = _members;
    this.membersSubject.next(this.studyMembers);
    this.instanceFormType.controls['patient'].setValue(this.studyMembers[0], { onlySelf: true });
  }

  // Set collaborators of study from parent
  @Input()
  set _collaborators(_collaborators: Array<UserInterface>) {
    if (_collaborators) {
      this.studyCollaborator = _collaborators;
    }
  }

  // Set questionnaires of study from parent
  @Input()
  set _questionnaires(_questionnaires: Array<LessonInterface>) {
    this.questionnaires = _questionnaires;
    this.questionnaires.forEach((questionnaire, index) => {
      this.positionType.push(index.toString());
    });
    this.setDefaultQuestionnaireConfigControl(this.questionnaires);
  }

  @Input()
  set _myUserId(_myUserId: number) {
    this.myUserId = _myUserId;
  }

  ngOnInit(): void {
    // Set guidance type
    if (this.intervention.attributes.intervention_type === 'accompanied') {
      this.instanceFormType.controls['intervention_type'].setValue('GUIDED', { onlySelf: true });
      this.selectedGuidanceType = 'GUIDED';
    }

    this.store.dispatch({
      type: StudyActionTypes.getCollaboratorsType,
      payload: { studyId: this.intervention.attributes.study_id, include: 'roles' }
    });

    this.collaboratorsOfStudy$ = this.store.select(getCollaboratorsByStudyId(this.intervention.attributes.study_id));
    this.subscriptions.push(
      this.collaboratorsOfStudy$
        .pipe(
          take(1),
          mergeMap(result => {
            this.collaborators = result.collaborators;
            // Determine my highest role
            const myself: UserInterface = this.collaborators.find(user => user.id.toString() === this.myUserId.toString());
            if (myself !== undefined) {
              this.isManagerSubject.next(!!this.helperService.getHighestRoleOfCollaborator(myself).match(/study\.(ecoachmanager|owner)$/));
            }
            this.addCheckboxes();
            this.diaryStore.getDiariesOfStudy({ studyId: this.intervention.attributes.study_id });
            return this.diariesOfStudy$.pipe(skip(1), take(1));
          })
          /*
          , switchMap((result: any) => {
            this.diaries = result;
            this.skillStore.getSkillsOfIntervention({ id: this.intervention.id });
            return this.skillsOfIntervention$;
          })
        )
        .subscribe(
          result => {
            this.skills = result;
        */
        )
        .subscribe(
          (result: any) => {
            this.diaries = result;
            this.isLoadingSubject.next(false);
          },
          error => {
            this.skills = [];
            this.isLoadingSubject.next(false);
          }
        )
    );

    // Subscription to language change
    this.subscriptions.push(
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
        this.setQuestionnaireConfiguration(this.intervention.id);
      })
    );
  }

  public isGuided(): boolean {
    return this.intervention.attributes.intervention_type === 'accompanied';
  }

  // Reset to default configuration of intervention
  public resetDefaultQuestionnaireConfig(): void {
    this.getInterventionDefaultConfiguration();
    const mapping = this.defaultConfiguration.custom_order.map(x => ({
      questionnaire: x,
      enabled: true
    }));
    this.formCustomOrderSubject.next(mapping);
    this.questionnaires.forEach((questionnaire: LessonInterface, i: number) => {
      const unlockDate = this.getRecalculatedUnlockDate(questionnaire.id);
      const foundIndex = this.formCustomOrderSubject.value.map(x => x.questionnaire).indexOf(parseInt(questionnaire.id.toString(), 10));
      const found = foundIndex > -1;
      const foundDefaultConfig: InterventionConfigurationInterface = this.getDefaultConfigurationOfQuestionnaire(questionnaire.id);
      const currentPosition = found ? foundIndex : this.formCustomOrderSubject.value.length;
      this.fQuestionnaireConfiguration.controls[i].get('questionnaire_id').patchValue(questionnaire.id, { onlySelf: true });
      this.fQuestionnaireConfiguration.controls[i].get('current_position').patchValue(currentPosition, { onlySelf: true });
      this.fQuestionnaireConfiguration.controls[i].get('current_enabled').patchValue(found, { onlySelf: true });
      this.fQuestionnaireConfiguration.controls[i].get('unlock_type').patchValue(foundDefaultConfig.unlock_type.toUpperCase(), {
        onlySelf: true
      });
      this.fQuestionnaireConfiguration.controls[i]
        .get('unlock_days_after_start')
        .patchValue(this.getUnlockDaysAfterStart(questionnaire.id), {
          onlySelf: true
        });
      this.fQuestionnaireConfiguration.controls[i].get('unlock_date').patchValue(unlockDate, { onlySelf: true });
      this.fQuestionnaireConfiguration.controls[i]
        .get('feedback_required')
        .patchValue(foundDefaultConfig.feedback_required, { onlySelf: true });
      this.fQuestionnaireConfiguration.controls[i].get('condition').patchValue(foundDefaultConfig.condition, { onlySelf: true });
      if (!found) {
        const added = this.formCustomOrderSubject.value;
        added.push({
          questionnaire: parseInt(questionnaire.id.toString(), 10),
          enabled: found
        });
        this.formCustomOrderSubject.next(added);
      }
    });
    this.updateView.next('resetDefaultQuestionnaireConfig');
    this.sortByDefaultCustomOrderPosition();
  }

  // Group creation studyForm check intervention_type
  public showInterventionType(value: string): void {
    if (this.selectedGuidanceType === 'GUIDED' && value === 'UNGUIDED') {
      this.fQuestionnaireConfiguration.controls.forEach(control => {
        if (control.get('unlock_type').value === 'MANUALLY') {
          control.get('unlock_type').patchValue('AFTER_PREVIOUS', { onlySelf: true });
        }
        control.get('feedback_required').patchValue(false, { onlySelf: true });
      });
    }
    this.instanceFormType.controls['intervention_type'].patchValue(this.selectedGuidanceType, { onlySelf: true });
    this.updateView.next('show_interventionType');
  }

  // Submit parameters required to create an instance
  public confirmInstanceSettings(): boolean {
    if (this.isInvalidInstanceForm()) {
      const collabIds: Array<number> = this.instanceFormType
        .get('collaboratorList')
        .value.map((v, i) => (v ? this.collaboratorsWithoutUser[i].id : null))
        .filter(v => v !== null)
        .map(id => parseInt(id, 10));

      const patient: UserInterface = this.f.patient.value;
      const userId = patient ? patient.id : null;
      let interventionType = '';

      const startsAt = this.helperService.convertStringToUnixTimestamp(this.f.starts_at.value);

      switch (this.f.intervention_type.value) {
        case 'UNGUIDED': {
          interventionType = 'Unguided';
          break;
        }
        case 'GUIDED': {
          interventionType = 'Guided';
          break;
        }
        default:
          break;
      }

      const customConfig: Array<InterventionInstanceConfigurationInterface> = [];
      const customOrder: Array<number> = this.formCustomOrderSubject.value.filter(x => x.enabled).map(x => x.questionnaire);
      // Configuration
      this.fQuestionnaireConfiguration.controls.forEach(formcontrol => {
        const questionnaireUnlockDate = this.helperService.convertStringToUnixTimestamp(
          new Date(formcontrol.get('unlock_date').value).toUTCString()
        );
        const checkUnlockDate = questionnaireUnlockDate < startsAt ? startsAt : questionnaireUnlockDate;
        const configBody = {
          id: parseInt(formcontrol.get('questionnaire_id').value, 10),
          unlock_type: formcontrol.get('unlock_type').value.toLowerCase(),
          unlock_date: checkUnlockDate,
          feedback_required: formcontrol.get('feedback_required').value,
          condition: this.getDefaultConditional(parseInt(formcontrol.get('questionnaire_id').value, 10)),
          bonus_lesson: this.getDefaultBonusLesson(parseInt(formcontrol.get('questionnaire_id').value, 10))
        };

        if (formcontrol.get('unlock_type').value.toLowerCase() !== 'conditional') {
          delete configBody.condition;
        }

        if (
          formcontrol.get('unlock_type').value.toLowerCase() !== 'conditional' ||
          this.getDefaultBonusLesson(parseInt(formcontrol.get('questionnaire_id').value, 10)) === undefined ||
          this.getDefaultBonusLesson(parseInt(formcontrol.get('questionnaire_id').value, 10)) === null
        ) {
          delete configBody.bonus_lesson;
        }

        // Only add the configuration are needed for the custom_order to custom_config
        if (customOrder.includes(parseInt(formcontrol.get('questionnaire_id').value, 10))) {
          customConfig.push(configBody);
        }
      });

      const payload: PayloadInterface = new RequestBodyData('intervention_instance', {
        ecoach_ids: collabIds,
        patient_id: userId,
        intervention_id: this.intervention.id,
        intervention_type: interventionType,
        configuration: {
          questionnaire_configuration: customConfig,
          custom_order: customOrder,
          starting_at: startsAt
        }
      });
      this.confirmedForm.emit(payload);
      return true;
    }
    return false;
  }

  // TODO Check instance studyForm for inconsistency
  public isInvalidInstanceForm(): boolean {
    return true;
  }

  public sortByDefaultCustomOrderPosition(): void {
    this.fQuestionnaireConfiguration.controls.sort((a, b) => {
      if (a.get('current_enabled').value && b.get('current_enabled').value) {
        const indexA = a.get('current_position').value.toString();
        const indexB = b.get('current_position').value.toString();
        return indexA - indexB;
      } else if (!a.get('current_enabled').value && b.get('current_enabled').value) {
        return 1;
      } else if (a.get('current_enabled').value && !b.get('current_enabled').value) {
        return -1;
      } else {
        const questionnaireA = a.get('questionnaire_id').value.toString();
        const questionnaireB = b.get('questionnaire_id').value.toString();
        return (
          this.questionnaires.find(value => value.id.toString() === questionnaireA.toString()).attributes.position -
          this.questionnaires.find(value => value.id.toString() === questionnaireB.toString()).attributes.position
        );
      }
    });
    this.fQuestionnaireConfiguration.controls.forEach((formControl, index) => {
      formControl.get('current_position').patchValue(index.toString(), { onlySelf: true });
    });
    const controls: AbstractControl[] = this.fQuestionnaireConfiguration.controls;
    const selectedQuestionnnaire: Array<{ questionnaire: number; enabled: boolean }> = [];
    controls.forEach((abc: AbstractControl) => {
      selectedQuestionnnaire.push({
        questionnaire: parseInt(abc.get('questionnaire_id').value, 10),
        enabled: abc.get('current_enabled').value
      });
    });
    this.formCustomOrderSubject.next(selectedQuestionnnaire);
  }

  public getRecalculatedUnlockDate(questionnaireId: number): Date {
    return new Date(new Date(this.f.starts_at.value).getTime() + this.getUnlockDaysAfterStart(questionnaireId) * 24 * 60 * 60 * 1000);
  }

  public onKeyFilter(): void {
    const filterResults: Array<UserInterface> = this.studyMembers.filter(
      (user: UserInterface) =>
        this.helper.getCodeNameEmail(user.id, this.studyMembers).toLowerCase().includes(this.filter['userSelection'].toLowerCase()) ||
        user.attributes.email.toLowerCase().includes(this.filter['userSelection'].toLowerCase())
    );
    this.membersSubject.next(filterResults.length !== 0 ? filterResults : [this.f.patient.value]);
  }

  public resetFilter(): void {
    this.filter = { userSelection: '' };
    this.membersSubject.next(this.studyMembers);
  }

  public getIndexOfElement(arr: Array<any>): Array<number> {
    return arr.map((value, index) => index);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  public hasDefaultConditional(questionnaireId: number): boolean {
    const config: InterventionConfigurationInterface = this.getDefaultConfigurationOfQuestionnaire(questionnaireId);
    return config ? config.unlock_type.toLowerCase() === 'conditional' : false;
  }

  public getDefaultConditional(questionnaireId: number) {
    return (this.getDefaultConfigurationOfQuestionnaire(questionnaireId) as InterventionConfigurationInterface).condition;
  }

  public getDefaultBonusLesson(questionnaireId: number) {
    return (this.getDefaultConfigurationOfQuestionnaire(questionnaireId) as InterventionConfigurationInterface).bonus_lesson;
  }

  // Returns default unlock days of a questionnaire
  private getUnlockDaysAfterStart(questionnaireId: number): number {
    const foundDefaultConfig: InterventionConfigurationInterface = this.getDefaultConfigurationOfQuestionnaire(questionnaireId);
    return foundDefaultConfig && foundDefaultConfig.unlock_days_after_start
      ? parseInt(foundDefaultConfig.unlock_days_after_start.toString(), 10)
      : 0;
  }

  // Returns default configuration of a questionnaire
  private getDefaultConfigurationOfQuestionnaire(questionnaireId: number): InterventionConfigurationInterface {
    this.getInterventionDefaultConfiguration();
    const found = this.defaultConfiguration.questionnaire_configuration.find(
      (questionnaire: InterventionConfigurationInterface) => questionnaire.id.toString() === questionnaireId.toString()
    );
    return found ? found : null;
  }

  // Set default configuration
  private getInterventionDefaultConfiguration(): void {
    this.intervention = JSON.parse(JSON.stringify(this.interventionReadOnly));
    this.defaultConfiguration = JSON.parse(JSON.stringify(this.defaultConfigurationReadOnly));
  }

  // Adding checkboxes on userForm control
  private addCheckboxes(): void {
    this.collaboratorsWithoutUser = this.studyCollaborator.filter(
      (user: UserInterface) =>
        user.id.toString() !== this.myUserId.toString() &&
        !!this.helperService.getHighestRoleOfCollaborator(user).match(/study\.(ecoach|ecoachmanager|owner)$/)
    );
    this.collaboratorsWithoutUser.map(() => {
      const control = new UntypedFormControl();
      (this.instanceFormType.controls.collaboratorList as UntypedFormArray).push(control);
    });
  }

  // Set questionnaire configuration
  private setQuestionnaireConfiguration(interventionId: number) {
    this.questionnaireStore.getQuestionnairesIntervention({ interventionId });
    return this.questionnairesOfIntervention$.pipe(skip(1), take(1)).subscribe((result: any) => {
      this.questionnaires = this.helper.sortLessonsAscending(result);
      this.questionnaires.forEach((questionnaire, index) => {
        this.positionType.push(index.toString());
      });
      this.setDefaultQuestionnaireConfigControl(this.questionnaires);
    });
  }

  // Set default configuration in studyForm controls
  private setDefaultQuestionnaireConfigControl(questionnaires: Array<LessonInterface>): void {
    this.fQuestionnaireConfiguration.setValue([]);
    // this.form_custom_order = this.default_configuration.custom_order;
    const mapping = this.defaultConfiguration.custom_order.map(x => ({
      questionnaire: x,
      enabled: true
    }));
    this.formCustomOrderSubject.next(mapping);
    questionnaires.forEach((questionnaire: LessonInterface) => {
      const foundDefaultConfig: InterventionConfigurationInterface = this.getDefaultConfigurationOfQuestionnaire(questionnaire.id);
      const unlockDaysAfterStart = this.getUnlockDaysAfterStart(questionnaire.id);
      const unlockDate = this.getRecalculatedUnlockDate(questionnaire.id);

      // Is enabled?
      const foundIndex = this.formCustomOrderSubject.value.findIndex(
        index => index.questionnaire.toString() === questionnaire.id.toString()
      );
      const found = foundIndex !== -1;
      const currentPosition = found ? foundIndex : this.formCustomOrderSubject.value.length;
      this.fQuestionnaireConfiguration.push(
        this.formBuilder.group({
          questionnaire_id: [questionnaire.id],
          current_position: [currentPosition.toString()],
          current_enabled: [found, Validators.required],
          unlock_type: [foundDefaultConfig.unlock_type.toUpperCase()],
          unlock_days_after_start: [unlockDaysAfterStart],
          unlock_date: [unlockDate],
          feedback_required: [foundDefaultConfig.feedback_required],
          condition: null
        })
      );

      if (!found) {
        const added = this.formCustomOrderSubject.value;
        added.push({
          questionnaire: parseInt(questionnaire.id.toString(), 10),
          enabled: found
        });
        this.formCustomOrderSubject.next(added);
      }
    });
    this.showMoveShortCutSubject = new BehaviorSubject<Array<boolean>>(new Array(this.fQuestionnaireConfiguration.length).fill(false));
    this.sortByDefaultCustomOrderPosition();
    this.updateView.next('setDefaultQuestionnaireConfig');
  }
}
