/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faComments } from '@fortawesome/free-solid-svg-icons/faComments';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faList } from '@fortawesome/free-solid-svg-icons/faList';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons/faMinusCircle';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { BehaviorSubject, forkJoin, iif, Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { debounceTime, delay, distinctUntilChanged, map, mergeMap, skip, take } from 'rxjs/operators';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { HelperActivityService } from '../../../services/helper/helper-activity/helper-activity.service';
import { ActivityInterface } from '../../../models/interface/activity.interface';
import { TableSortInterface } from '../../../models/interface/table/table_sort.interface';
import { HelperService } from '../../../services/helper/helper.service';
import { UserInterface } from '../../../models/interface/user.interface';
import { MessageThreadsInterface } from '../../../models/interface/message_threads.interface';
import { StudyActivityInterface } from '../../../models/interface/study-activity.interface';
import { StudyInterface } from '../../../models/interface/study/study.interface';
import { HelperDialogService } from '../../../services/helper/helper-dialog/helper-dialog.service';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { MessageActionTypes } from '../../../store/message/message.action';
import { StudyActionTypes } from '../../../store/study/study.action';
import { UserActionTypes } from '../../../store/user/user.action';
import { UserStore } from '../../../store/user/component-store/user.store';

/**
 * Component:
 * Tasks page displaying all tasks of the eCoach;
 * Can be found: {uri}/tasks
 */

@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss'],
  providers: [UserStore]
})
export class TaskComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('instanceDetailContainer', { read: ViewContainerRef }) instanceDetailContainer: ViewContainerRef;

  // Exposed class for template
  public components;

  // Icons
  faMinusCircle = faMinusCircle;
  faPlusCircle = faPlusCircle;
  faComments = faComments;
  faList = faList;
  faCheck = faCheck;
  faTimes = faTimes;
  faInfoCircle = faInfoCircle;

  public displayedColumns: string[] = ['activity_text', 'performed_by', 'created_at', 'status'];
  public dataSource: MatTableDataSource<ActivityInterface>;

  // Filter
  public filter = { searchFilter: '', checkPending: false, checkDone: false };

  // Table sort
  public tableSort: TableSortInterface = {
    sortActivityId: null,
    sortAction: null,
    sortActionId: null,
    sortText: null,
    sortPerformedBy: null,
    sortCreatedAt: null
  };

  public selectedStudy: StudyActivityInterface;
  public studyActivities: Array<StudyActivityInterface> = [];

  // Data provided by UserService
  public users: Array<UserInterface> = [];

  public isLoading$: Observable<boolean>;
  public isCollapse$: Observable<boolean>;

  public showStudyActivities$;
  public tableContent$: Observable<Array<ActivityInterface>>;
  public showDescription$: Observable<boolean>;

  // Translation for intervention name
  public param = { intervention_name: '...' };

  public keyUp = new Subject<any>();

  public isCollapseSubject = new BehaviorSubject<boolean>(true);

  public showDescriptionSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // Data provided by StudyService
  private studies: Array<StudyInterface> = [];
  private allTasks: Array<ActivityInterface> = [];

  private allMessageThreads$: Observable<Array<MessageThreadsInterface>>;

  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private tableContentSubject: BehaviorSubject<Array<ActivityInterface>> = new BehaviorSubject([]);

  private threads: Array<MessageThreadsInterface> = [];

  private showStudyActivitiesSubject: BehaviorSubject<Array<StudyActivityInterface>> = new BehaviorSubject([]);
  private myMembers$: Observable<Array<UserInterface>>;

  private studyActivities$: Observable<Array<ActivityInterface>>;

  // Subscription Handler
  private subscriptions: Subscription[] = [];

  constructor(
    private helperService: HelperService,
    private router: Router,
    private helperActivityService: HelperActivityService,
    private helperDialogService: HelperDialogService,
    private userStore: UserStore,
    private actions$: Actions,
    private store: Store<{ allMessageThreads: Array<MessageThreadsInterface>; myMembers: Array<UserInterface> }>
  ) {
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.isCollapse$ = this.isCollapseSubject.asObservable();
    this.showStudyActivities$ = this.showStudyActivitiesSubject.asObservable();
    this.tableContent$ = this.tableContentSubject.asObservable();
    this.showDescription$ = this.showDescriptionSubject.asObservable();

    this.subscriptions.push(
      this.keyUp
        .pipe(
          map((event: any) => event.target.value),
          debounceTime(1000),
          distinctUntilChanged(),
          mergeMap(search => {
            this.applyFilter();
            return of(search);
          }),
          delay(500)
        )
        .subscribe()
    );
    this.allMessageThreads$ = this.store.select('allMessageThreads');
    this.myMembers$ = this.store.select('myMembers');
    this.studyActivities$ = this.userStore.studyActivities$;
  }

  public get helper() {
    return this.helperService;
  }

  public get helperDialog() {
    return this.helperDialogService;
  }

  public get helperActivity() {
    return this.helperActivityService;
  }

  ngOnInit(): void {
    // Activities and metadata on task page
    const reqs: Array<Observable<any>> = [];
    /*
      1. Get all all isCollaboratingSubject interventions of therapist
      forkjoin():
      2. Get all activities based on study id
      3. Get all members of the ecoach
      4. Get all activities of my members
      5. Get all threads with answersheetId
     */
    // Request all isCollaboratingSubject interventions and retrieve their activities based on the given ID
    this.store.dispatch({
      type: StudyActionTypes.getCollaboratingStudiesType,
      payload: { role: 'study.owner,study.ecoachmanager,study.ecoach' }
    });
    this.subscriptions.push(
      this.actions$
        .pipe(
          ofType(StudyActionTypes.getCollaboratingStudiesSuccessType, StudyActionTypes.getCollaboratingStudiesErrorType),
          take(1),
          mergeMap((result: any) =>
            iif(() => result.type === StudyActionTypes.getCollaboratingStudiesSuccessType, of(result), throwError(result))
          ),
          mergeMap((result: any) => {
            this.studies = result['response']['body']['data'];

            this.studies.forEach(study => {
              reqs.push(of(null));
            });

            // Queue last 3 requests to get members, all activities of my members and all feedback
            this.store.dispatch({
              type: UserActionTypes.getMyMembersType,
              payload: {}
            });
            reqs.push(this.myMembers$.pipe(skip(1), take(1)));
            this.userStore.getActivitiesOfMembers({ include: 'questionnaires' });
            reqs.push(this.studyActivities$.pipe(skip(1), take(1)));
            this.store.dispatch({
              type: MessageActionTypes.getAllMessagesThreadsType,
              payload: { answersheetFlag: true, include: 'messages,participants' }
            });
            reqs.push(this.allMessageThreads$.pipe(skip(1), take(1)));
            return forkJoin(reqs);
          })
        )
        .subscribe(
          (results: Array<any>) => {
            for (let counting = 0; counting < results.length; counting++) {
              if (counting < this.studies.length) {
                // Pair study with corresponding list of activities
                this.studyActivities.push({
                  activities: [],
                  study: this.studies[counting]
                });
              } else {
                switch (counting) {
                  case this.studies.length:
                    this.users = results[counting];
                    break;
                  case this.studies.length + 1:
                    this.allTasks = this.helperActivity.getTasksFromActivities(results[counting]);
                    break;
                  case this.studies.length + 2:
                    this.threads = results[counting];
                    break;
                  default:
                    break;
                }
              }
            }

            this.studyActivities.unshift({
              activities: this.allTasks,
              alternative: 'task.all_studies_tasks'
            });

            this.showStudyActivitiesSubject.next(this.studyActivities);
            // Select default study and set default table content
            this.selectedStudy = this.studyActivities[0];
            this.tableContentSubject.next(this.sortActivitiesByCreationDesc(this.studyActivities[0].activities));
            this.dataSource = new MatTableDataSource<ActivityInterface>(this.tableContentSubject.value);
            this.dataSource.sort = this.sort;
            const currentPageSize = this.paginator ? this.paginator.pageSize : 20;
            this.dataSource = new MatTableDataSource<ActivityInterface>(this.tableContentSubject.value.slice(0, currentPageSize));
            if (this.paginator) {
              this.setPaginatorLength(this.tableContentSubject.value.length);
              this.paginator.firstPage();
            }
            this.isLoadingSubject.next(false);
          },
          error => {
            console.error('Tasks could not be loaded due to', error);
            this.initializeEmptyList();
            this.isLoadingSubject.next(false);
          },
          () => {
            // If study-activities pair is empty
            if (reqs.length === 0) {
              this.initializeEmptyList();
            }
            this.isLoadingSubject.next(false);
          }
        )
    );
  }

  // Show selected activities in table content
  public showSelected(selected: StudyActivityInterface): void {
    this.isLoadingSubject.next(true);
    if (selected.study?.id) {
      this.userStore.getActivitiesStudy({ studyId: selected.study.id, include: 'questionnaires' });
      this.subscriptions.push(
        this.studyActivities$.pipe(skip(1), take(1)).subscribe((result: any) => {
          const activities: Array<ActivityInterface> = this.helperActivity.getTasksFromActivities(result);
          const index = this.studyActivities.findIndex(
            (value: StudyActivityInterface) => value.study?.id.toString() === selected.study?.id.toString()
          );
          if (index > -1) {
            this.studyActivities[index].activities = activities;
          }
          // Close and reset searchFilter options
          this.filter = {
            searchFilter: '',
            checkPending: false,
            checkDone: false
          };
          this.isCollapseSubject.next(true);
          this.tableContentSubject.next(this.sortActivitiesByCreationDesc(selected.activities));
          const currentPageSize = this.paginator ? this.paginator.pageSize : 20;
          this.dataSource = new MatTableDataSource<ActivityInterface>(this.tableContentSubject.value.slice(0, currentPageSize));
          this.dataSource.sort = this.sort;
          if (this.paginator) {
            this.dataSource = new MatTableDataSource<ActivityInterface>(this.tableContentSubject.value.slice(0, currentPageSize));
            this.paginator.firstPage();
          }
          this.isLoadingSubject.next(false);
        })
      );
    } else {
      this.userStore.getActivitiesOfMembers({ include: 'questionnaires' });
      this.studyActivities$.pipe(skip(1), take(1)).subscribe((result: any) => {
        this.allTasks = this.helperActivity.getTasksFromActivities(result);
        const index = this.studyActivities.findIndex((value: StudyActivityInterface) => !value.study);
        this.studyActivities[index].activities = this.allTasks;
        // Close and reset searchFilter options
        this.filter = {
          searchFilter: '',
          checkPending: false,
          checkDone: false
        };
        this.isCollapseSubject.next(true);
        this.tableContentSubject.next(this.sortActivitiesByCreationDesc(selected.activities));
        const currentPageSize = this.paginator ? this.paginator.pageSize : 20;
        this.dataSource = new MatTableDataSource<ActivityInterface>(this.tableContentSubject.value.slice(0, currentPageSize));
        this.dataSource.sort = this.sort;
        if (this.paginator) {
          this.dataSource = new MatTableDataSource<ActivityInterface>(this.tableContentSubject.value.slice(0, currentPageSize));
          this.paginator.firstPage();
        }
        this.isLoadingSubject.next(false);
      });
    }
  }

  // Apply searchFilter onto table content activities
  public applyFilter(): void {
    const filterArray = this.filterAndSort();

    if (this.paginator) {
      this.setPaginatorLength(filterArray.length);
      this.paginator.firstPage();
    }
  }

  public displaySelect(selected: StudyActivityInterface): string {
    return selected.study ? selected.study.attributes.name : selected.alternative;
  }

  public initializeEmptyList(): void {
    this.studyActivities = [];
    this.allTasks = [];
    const pair: StudyActivityInterface = {
      activities: this.allTasks,
      alternative: 'ALL_STUDIES_TASKS'
    };
    this.studyActivities.unshift(pair);
    this.showStudyActivitiesSubject.next(this.studyActivities);
    // Select default study and set default table content
    this.selectedStudy = this.studyActivities[0];
    this.tableContentSubject.next(this.sortActivitiesByCreationDesc(this.studyActivities[0].activities));
    if (this.dataSource) {
      this.dataSource.sort = this.sort;
    }
    const currentPageSize = this.paginator ? this.paginator.pageSize : 20;
    this.dataSource = new MatTableDataSource<ActivityInterface>(this.tableContentSubject.value.slice(0, currentPageSize));
    if (this.paginator) {
      this.setPaginatorLength(this.tableContentSubject.value.length);
      this.paginator.firstPage();
    }
  }

  public getStatus(activity): boolean {
    // Check for task status
    return this.helperActivity.isTaskCompleted(activity, this.threads);
  }

  public getStatusString(activity): string {
    const answersheetId = this.helperActivity.getAnswersheetIdFromActivity(activity);
    // Check for task status
    if (
      activity.attributes.finished.toString() === '1' ||
      activity.attributes.finished.toString() === '' ||
      activity.attributes.finished.toString() === 'completed'
    ) {
      return 'completed';
    } else if (activity.attributes.finished.toString() === '0' || activity.attributes.finished.toString() === 'pending') {
      return 'pending';
    } else if (activity.attributes.finished.toString() === 'canceled') {
      return 'canceled';
    } else {
      if (answersheetId !== null) {
        const result = this.threads.find(x =>
          x.attributes.answersheet_id ? x.attributes.answersheet_id.toString() === answersheetId.toString() : false
        );
        return result ? 'completed' : 'pending';
      }
      return 'pending';
    }
  }

  // Navigate to answersheet
  public showAnswersheet(activity: ActivityInterface): void {
    this.router.navigateByUrl(`/feedback-overview/answersheets/${this.helperActivity.getAnswersheetIdFromActivity(activity)}`);
  }

  onPageChangeActivity(event: PageEvent) {
    const filterArray: Array<ActivityInterface> = this.filterAndSort();
    const startIndex = event.pageIndex * event.pageSize;
    const endIndex = startIndex + event.pageSize > filterArray.length ? filterArray.length : startIndex + event.pageSize;

    if (this.paginator) {
      this.setPaginatorLength(filterArray.length);
    }

    if (this.sort.direction !== '') {
      this.sortData(this.sort, filterArray.slice());
    }
    this.tableContentSubject.next(filterArray.slice(startIndex, endIndex));
    this.dataSource = new MatTableDataSource<ActivityInterface>(filterArray.slice(startIndex, endIndex));
    this.dataSource.sort = this.sort;
  }

  public onChangeSortTable(sort: Sort): void {
    const data: Array<ActivityInterface> = this.selectedStudy.activities.slice();
    if (!(sort.direction === '')) {
      this.sortData(sort, data);
    }
    this.applyFilter();
  }

  public openDialogInterventionInstanceDetails(activity: ActivityInterface): void {
    const instanceId =
      activity.attributes.uri !== undefined ? this.helper.getLastIndexIDOfURI(activity.attributes.uri) : activity.attributes.entity_id;
    this.helperDialog
      .openDialogInterventionInstanceDetails(instanceId, this.param, 1)
      .afterClosed()
      .subscribe(result => {});
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  private sortData(sort: Sort, data: Array<ActivityInterface>): void {
    const isDesc = sort.direction === 'desc';
    switch (sort.active) {
      case 'activity_text':
        this.tableContentSubject.next(
          data.sort((a, b) => {
            const behavior = new BehaviorSubject<number>(null);
            forkJoin([this.helperActivity.getActivityText(a), this.helperActivity.getActivityText(b)]).subscribe((activities: string[]) => {
              const act1 =
                a.attributes.action === 'ANSWERSHEET_SUBMITTED'
                  ? activities[0] + this.helperActivity.getLessonNameOfActivity(a, a.relationships.questionnaires.data)
                  : activities[0];
              const act2 =
                b.attributes.action === 'ANSWERSHEET_SUBMITTED'
                  ? activities[1] + this.helperActivity.getLessonNameOfActivity(b, b.relationships.questionnaires.data)
                  : activities[1];
              if (isDesc) {
                behavior.next(act2.localeCompare(act1));
              } else {
                behavior.next(act1.localeCompare(act2));
              }
            });
            return behavior.value;
          })
        );
        break;
      case 'performed_by':
        this.helperActivityService.toggleSortPerformedBy(data, isDesc, this.tableContentSubject, this.users, this.tableSort);
        break;
      case 'created_at':
        this.helperActivityService.toggleSortCreated(data, isDesc, this.tableContentSubject, this.tableSort);
        break;
    }
    this.dataSource = new MatTableDataSource<ActivityInterface>(this.tableContentSubject.value);
  }

  private filterAndSort(): Array<ActivityInterface> {
    let filterArray: Array<ActivityInterface> = [];
    if (this.filter['checkPending']) {
      this.selectedStudy.activities.forEach(activity => {
        if (this.getStatusString(activity) === 'pending') {
          filterArray.push(activity);
        }
      });
    }

    if (this.filter['checkDone']) {
      this.selectedStudy.activities.forEach(activity => {
        if (this.getStatusString(activity) === 'completed') {
          filterArray.push(activity);
        }
      });
    }

    if (!(this.filter['checkPending'] || this.filter['checkDone'])) {
      filterArray = this.selectedStudy.activities;
    }
    filterArray = this.helper.filterActivitiesByUser(filterArray, this.filter['searchFilter'].toLowerCase().trim(), this.users);
    if (!(this.sort.direction === '')) {
      this.sortData(this.sort, filterArray);
    }
    const currentPageSize = this.paginator ? this.paginator.pageSize : 20;
    this.dataSource = new MatTableDataSource<ActivityInterface>(filterArray.slice(0, currentPageSize));
    return filterArray;
  }

  private setPaginatorLength(length: number): void {
    this.paginator.length = length;
  }

  private sortActivitiesByCreationDesc(activities: Array<ActivityInterface>): Array<ActivityInterface> {
    return activities.sort(
      (a, b) =>
        new Date(this.helper.getDateFromObjectString(b.attributes.created_at)).valueOf() -
        new Date(this.helper.getDateFromObjectString(a.attributes.created_at)).valueOf()
    );
  }
}
