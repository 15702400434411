import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { catchError, EMPTY, Observable, switchMap, tap, throwError } from 'rxjs';
import { AnswersheetInterface } from '../../../models/interface/answersheet.interface';
import { AnswersheetService } from '../../../services/api/answersheet/answersheet.service';

export interface AnswersheetsState {
  answersheets: AnswersheetInterface[];
  answersheetsOfInterventionInstance: AnswersheetInterface[];
  answersheetPDFReportResponse: any;
}

@Injectable()
export class AnswersheetsStore extends ComponentStore<AnswersheetsState> {
  readonly answersheets$: Observable<AnswersheetInterface[]> = this.select(state => state.answersheets, { debounce: true });
  readonly answersheetsOfInterventionInstance$: Observable<AnswersheetInterface[]> = this.select(
    state => state.answersheetsOfInterventionInstance,
    { debounce: true }
  );
  readonly answersheetPDFReportResponse$: Observable<any> = this.select(state => state.answersheetPDFReportResponse, { debounce: true });

  readonly updateAnswersheetState = this.updater(
    (
      state,
      payload: {
        answersheet?: AnswersheetInterface;
        answersheetsOfInterventionInstance?: AnswersheetInterface[];
        answersheetPDFReportResponse?: any;
      }
    ) => {
      let tempAnswersheetsState = state.answersheets;
      if (payload.answersheet) {
        const index = state.answersheets.findIndex(
          (value: AnswersheetInterface) => value.id.toString() === payload.answersheet.id.toString()
        );
        if (index > -1) {
          tempAnswersheetsState[index] = payload.answersheet;
        } else {
          tempAnswersheetsState = [...state.answersheets, payload.answersheet].sort(
            (a, b) => parseInt(a.id.toString(), 10) - parseInt(b.id.toString(), 10)
          );
        }
      }
      return {
        answersheets: tempAnswersheetsState,
        answersheetsOfInterventionInstance: payload.answersheetsOfInterventionInstance
          ? payload.answersheetsOfInterventionInstance
          : state.answersheetsOfInterventionInstance,
        answersheetPDFReportResponse: payload.answersheetPDFReportResponse
          ? payload.answersheetPDFReportResponse
          : state.answersheetPDFReportResponse
      };
    }
  );

  // Answersheet APIs
  readonly getAnswersheetAsEcoach = this.effect((answersheetId$: Observable<number>) =>
    answersheetId$.pipe(
      switchMap((id: number) =>
        this.answersheetService.getAnswersheet(id).pipe(
          tap({
            next: (result: any) => this.updateAnswersheetState({ answersheet: result.body.data }),
            error: e => throwError(e)
          }),
          catchError(() => EMPTY)
        )
      )
    )
  );

  readonly getAnswersheetAsEM = this.effect((answersheetId$: Observable<number>) =>
    answersheetId$.pipe(
      switchMap((id: number) =>
        this.answersheetService.getAnswersheetEM(id).pipe(
          tap({
            next: (result: any) => this.updateAnswersheetState({ answersheet: result.body.data }),
            error: e => throwError(e)
          }),
          catchError(() => EMPTY)
        )
      )
    )
  );

  readonly getAnswersheetsOfInterventionInstance = this.effect((interventionInstanceId$: Observable<number>) =>
    interventionInstanceId$.pipe(
      switchMap((id: number) =>
        this.answersheetService.getAnswersheetsOfAnInterventionInstance(id).pipe(
          tap({
            next: (result: any) => this.updateAnswersheetState({ answersheetsOfInterventionInstance: result.body.data }),
            error: e => throwError(e)
          }),
          catchError(() => EMPTY)
        )
      )
    )
  );

  readonly getAnswersheetsOfInterventionInstanceEM = this.effect((interventionInstanceId$: Observable<number>) =>
    interventionInstanceId$.pipe(
      switchMap((id: number) =>
        this.answersheetService.getAnswersheetsOfAnInterventionInstanceEM(id).pipe(
          tap({
            next: (result: any) => this.updateAnswersheetState({ answersheetsOfInterventionInstance: result.body.data }),
            error: e => throwError(e)
          }),
          catchError(() => EMPTY)
        )
      )
    )
  );

  readonly getAnswersheetPDFReport = this.effect((answersheetId$: Observable<number>) =>
    answersheetId$.pipe(
      switchMap((id: number) => {
        this.updateAnswersheetState({ answersheetPDFReportResponse: null });
        return this.answersheetService.getAnswersheetPDFReport(id).pipe(
          tap({
            next: (result: any) => this.updateAnswersheetState({ answersheetPDFReportResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateAnswersheetState({ answersheetPDFReportResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly getAnswersheetPDFReportQueue = this.effect((answersheetId$: Observable<number>) =>
    answersheetId$.pipe(
      switchMap((id: number) => {
        this.updateAnswersheetState({ answersheetPDFReportResponse: null });
        return this.answersheetService.getAnswersheetPDFReportQueue(id).pipe(
          tap({
            next: (result: any) => this.updateAnswersheetState({ answersheetPDFReportResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateAnswersheetState({ answersheetPDFReportResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  constructor(private readonly answersheetService: AnswersheetService) {
    super({ answersheets: [], answersheetsOfInterventionInstance: [], answersheetPDFReportResponse: null });
  }

  selectAnswersheet(answersheetId: number) {
    return this.select(state => state.answersheets.find(a => a.id.toString() === answersheetId.toString()));
  }
}
