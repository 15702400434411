import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { catchError, EMPTY, forkJoin, Observable, of, switchMap, tap, throwError } from 'rxjs';
import { ActivityInterface } from 'src/app/models/interface/activity.interface';
import { LessonInterface } from 'src/app/models/interface/lesson.interface';
import { AnswersheetInterface } from '../../../models/interface/answersheet.interface';
import { DiaryInstanceInterface } from '../../../models/interface/diary-instance.interface';
import { DiaryInterface } from '../../../models/interface/diary.interface';
import { DiaryService } from '../../../services/api/diary/diary.service';

export interface DiariesState {
  diariesOfStudy: DiaryInterface[];
  allDiaryInstances: DiaryInstanceInterface[];
  answersheetsOfDiary: AnswersheetInterface[];
  diariesOfStudies: Array<DiaryInterface[]>;
  diaryActivities: ActivityInterface[];
  questionnaireOfDiary: LessonInterface;
  exportDiaryCodebookResponse: any;
}

@Injectable()
export class DiaryStore extends ComponentStore<DiariesState> {
  readonly diariesOfStudy$: Observable<DiaryInterface[]> = this.select(state => state.diariesOfStudy, { debounce: true });
  readonly allDiaryInstances$: Observable<DiaryInstanceInterface[]> = this.select(state => state.allDiaryInstances, { debounce: true });
  readonly answersheetsOfDiary$: Observable<AnswersheetInterface[]> = this.select(state => state.answersheetsOfDiary, { debounce: true });
  readonly diariesOfStudies$: Observable<Array<DiaryInterface[]>> = this.select(state => state.diariesOfStudies, { debounce: true });
  readonly diaryActivities$: Observable<ActivityInterface[]> = this.select(state => state.diaryActivities, { debounce: true });
  readonly questionnaireOfDiary$: Observable<LessonInterface> = this.select(state => state.questionnaireOfDiary, { debounce: true });
  readonly exportDiaryCodebookResponse$: Observable<any> = this.select(state => state.exportDiaryCodebookResponse, { debounce: true });

  readonly updateDiaryState = this.updater(
    (
      state,
      payload: {
        diariesOfStudy?: DiaryInterface[];
        allDiaryInstances?: DiaryInstanceInterface[];
        answersheetsOfDiary?: AnswersheetInterface[];
        diariesOfStudies?: Array<DiaryInterface[]>;
        diaryActivities?: Array<ActivityInterface>;
        questionnaireOfDiary?: LessonInterface;
        exportDiaryCodebookResponse?: any;
      }
    ) => ({
      diariesOfStudy: payload.diariesOfStudy ? payload.diariesOfStudy : state.diariesOfStudy,
      allDiaryInstances: payload.allDiaryInstances ? payload.allDiaryInstances : state.allDiaryInstances,
      answersheetsOfDiary: payload.answersheetsOfDiary ? payload.answersheetsOfDiary : state.answersheetsOfDiary,
      diariesOfStudies: payload.diariesOfStudies ? payload.diariesOfStudies : state.diariesOfStudies,
      diaryActivities: payload.diaryActivities ? payload.diaryActivities : state.diaryActivities,
      questionnaireOfDiary: payload.questionnaireOfDiary ? payload.questionnaireOfDiary : state.questionnaireOfDiary,
      exportDiaryCodebookResponse: payload.exportDiaryCodebookResponse
        ? payload.exportDiaryCodebookResponse
        : state.exportDiaryCodebookResponse
    })
  );

  // Answersheet APIs
  readonly getAllInstancesOfDiaryECoach = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) =>
        this.diaryService.getAllInstancesOfDiaryECoach(payload.diaryId, payload.ecoachId, payload.patientId).pipe(
          tap({
            next: (result: any) => this.updateDiaryState({ allDiaryInstances: result.body.data }),
            error: e => throwError(e)
          }),
          catchError(() => EMPTY)
        )
      )
    )
  );

  readonly getDiariesOfStudy = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) =>
        this.diaryService
          .getDiariesOfStudy(
            payload.studyId,
            payload.isShareable,
            payload.isSubscribable,
            payload.isActive,
            payload.isPrivate,
            payload.questionnaireId
          )
          .pipe(
            tap({
              next: (result: any) => this.updateDiaryState({ diariesOfStudy: result.body.data }),
              error: e => throwError(e)
            }),
            catchError(() => EMPTY)
          )
      )
    )
  );

  readonly getAnswersheetsOfDiary = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) =>
        this.diaryService
          .getAllAnswersheetsOfDiaryECoach(payload.diaryId, payload.diaryInstanceId, payload.ecoachId, payload.patientId)
          .pipe(
            tap({
              next: (result: any) => this.updateDiaryState({ answersheetsOfDiary: result.body.data }),
              error: e => throwError(e)
            }),
            catchError(() => EMPTY)
          )
      )
    )
  );

  readonly getDiariesOfStudies = this.effect((payload$: Observable<Array<any>>) =>
    payload$.pipe(
      switchMap((payload: Array<any>) => {
        const reqs: Array<Observable<any>> = [];
        payload.forEach(req => {
          reqs.push(
            this.diaryService.getDiariesOfStudy(
              req.studyId,
              req.isShareable,
              req.isSubscribable,
              req.isActive,
              req.isPrivate,
              req.questionnaireId
            )
          );
        });
        return forkJoin(reqs).pipe(
          tap({
            next: (results: Array<any>) => {
              const diariesOfStudies = [];
              results.forEach((result: any) => {
                diariesOfStudies.push(result.body.data);
              });
              return of(this.updateDiaryState({ diariesOfStudies }));
            },
            error: e => throwError(e)
          }),
          catchError(() => EMPTY)
        );
      })
    )
  );

  readonly getActivitiesOfDiaryInstance = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) =>
        this.diaryService.getActivitiesOfDiaryInstance(payload.diaryInstanceId).pipe(
          tap({
            next: (result: any) => this.updateDiaryState({ diaryActivities: result.body.data }),
            error: e => throwError(e)
          }),
          catchError(() => EMPTY)
        )
      )
    )
  );

  readonly getActivitiesOfDiaryInstanceEM = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) =>
        this.diaryService.getActivitiesOfDiaryInstanceEM(payload.diaryInstanceId).pipe(
          tap({
            next: (result: any) => this.updateDiaryState({ diaryActivities: result.body.data }),
            error: e => throwError(e)
          }),
          catchError(() => EMPTY)
        )
      )
    )
  );

  readonly getQuestionnaireOfDiary = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) => {
        this.updateDiaryState({ questionnaireOfDiary: null });
        return this.diaryService.getQuestionnaireOfDiary(payload.diaryId).pipe(
          tap({
            next: (result: any) => this.updateDiaryState({ questionnaireOfDiary: result.body.data }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateDiaryState({ questionnaireOfDiary: null });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly exportCSVDiaryCodebook = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) => {
        this.updateDiaryState({ exportDiaryCodebookResponse: null });
        return this.diaryService.exportCSVDiaryCodebook(payload.interventionId).pipe(
          tap({
            next: (result: any) => this.updateDiaryState({ exportDiaryCodebookResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateDiaryState({ exportDiaryCodebookResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  constructor(private readonly diaryService: DiaryService) {
    super({
      diariesOfStudy: [],
      allDiaryInstances: [],
      answersheetsOfDiary: [],
      diariesOfStudies: [],
      diaryActivities: [],
      questionnaireOfDiary: null,
      exportDiaryCodebookResponse: null
    });
  }
}
