<!-- Media component for lection/question elements in ionic app -->
<div *ngIf="(evaluation$ | async)" [hidden]="hidden$ | async" class="question-media question" [class.banner]="banner">
  <div [class.banner-headline]="banner">
    <h4 class="ion-text-start">{{(subtitleSubject | async)}}</h4>
  </div>
  <div ngSwitch="{{type}}">
    <img *ngSwitchCase="'image/jpeg'" [alt]="('question-media.content_not_found' | translate)" [src]="localUri" [style.width.%]="width" />
    <img *ngSwitchCase="'image/jpg'" [alt]="('question-media.content_not_found' | translate)" [src]="localUri" [style.width.%]="width" />
    <img *ngSwitchCase="'image/gif'" [alt]="('question-media.content_not_found' | translate)" [src]="localUri" [style.width.%]="width" />
    <img *ngSwitchCase="'image/png'" [alt]="('question-media.content_not_found' | translate)" [src]="localUri" [style.width.%]="width" />
    <img
      *ngSwitchCase="'image/svg+xml'"
      [alt]="('question-media.content_not_found' | translate)"
      [src]="localUri"
      [style.width.%]="width"
    />

    <!-- Use video player instead of html video if platform is android -->
    <div *ngSwitchCase="'video/webm'" [style.width.%]="width">
      <video class="mediaplayer" *ngIf="platformIsAndroid === false; else isAndroidWebm" [src]="localUri" controls></video>
      <ng-template #isAndroidWebm>
        <button>Play Video</button>
      </ng-template>
    </div>
    <div *ngSwitchCase="'video/ogg'" [style.width.%]="width">
      <video class="mediaplayer" *ngIf="platformIsAndroid === false; else isAndroidOgg" [src]="localUri" controls></video>
      <ng-template #isAndroidOgg>
        <button>Play Video</button>
      </ng-template>
    </div>

    <!-- Video and audio mp4 -->
    <div *ngSwitchCase="'video/mp4'">
      <div *ngIf="(isAudio === 1); else elseShowVideo">
        <audio class="mediaplayer" controls>
          <source [src]="localUri" [type]="type" />
        </audio>
      </div>
      <ng-template #elseShowVideo>
        <video
          class="mediaplayer"
          *ngIf="platformIsAndroid === false; else isAndroidOgg"
          [src]="localUri"
          controls
          [style.width.%]="width"
        ></video>
        <ng-template #isAndroidOgg>
          <button>Play Video</button>
        </ng-template>
      </ng-template>
    </div>

    <audio class="mediaplayer" *ngSwitchCase="'audio/webm'" controls>
      <source [src]="localUri" [type]="type" />
    </audio>
    <audio class="mediaplayer" *ngSwitchCase="'audio/ogg'" controls>
      <source [src]="localUri" [type]="type" />
    </audio>
    <audio class="mediaplayer" *ngSwitchCase="'audio/mpeg'" controls>
      <source [src]="localUri" [type]="type" />
    </audio>
    <audio class="mediaplayer" *ngSwitchCase="'audio/wav'" controls>
      <source [src]="localUri" [type]="type" />
    </audio>
    <audio class="mediaplayer" *ngSwitchCase="'audio/x-wav'" controls>
      <source [src]="localUri" [type]="type" />
    </audio>
    <audio class="mediaplayer" *ngSwitchCase="'audio/m4a'" controls>
      <source [src]="localUri" [type]="type" />
    </audio>
    <audio class="mediaplayer" *ngSwitchCase="'audio/x-m4a'" controls>
      <source [src]="localUri" [type]="type" />
    </audio>
    <audio class="mediaplayer" *ngSwitchCase="'audio/mp4'" controls>
      <source [src]="localUri" [type]="type" />
    </audio>
    <audio class="mediaplayer" *ngSwitchCase="'audio/mpga'" controls>
      <source [src]="localUri" [type]="type" />
    </audio>

    <a *ngSwitchCase="'application/pdf'" [download]="fileName" [href]="localUri" target="_blank">
      <fa-icon [icon]="faFilePdf" class="fa-3x"></fa-icon>
      {{fileName}}
    </a>
    <a *ngSwitchCase="'application/msword'" [download]="fileName" [href]="localUri">
      <fa-icon [icon]="faFileWord" class="fa-3x"></fa-icon>
      {{fileName}}
    </a>
    <a *ngSwitchCase="'application/vnd.openxmlformats-officedocument.wordprocessingml.document'" [download]="fileName" [href]="localUri">
      <fa-icon [icon]="faFileWord" class="fa-3x"></fa-icon>
      {{fileName}}
    </a>
    <a *ngSwitchCase="'application/vnd.ms-powerpoint'" [download]="fileName" [href]="localUri">
      <fa-icon [icon]="faFilePowerPoint" class="fa-3x"></fa-icon>
      {{fileName}}
    </a>
    <a *ngSwitchCase="'application/vnd.openxmlformats-officedocument.presentationml.presentation'" [download]="fileName" [href]="localUri">
      <fa-icon [icon]="faFilePowerPoint" class="fa-3x"></fa-icon>
      {{fileName}}
    </a>
    <a *ngSwitchCase="'application/vnd.oasis.opendocument.text'" [download]="fileName" [href]="localUri">
      <fa-icon [icon]="faFileWord" class="fa-3x"></fa-icon>
      {{fileName}}
    </a>
  </div>
  <div *ngIf="description !== ''" [class.banner-headline]="banner">
    <p class="ion-text-start">{{descriptionSubject | async}}</p>
  </div>
</div>
