import { HttpResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable, Subscription, take, skip } from 'rxjs';
import { OrganisationInterface } from '../../../models/interface/organisation/organisation.interface';
import { OrganisationStore } from '../../../store/organisation/component-store/organisation.store';

@Component({
  selector: 'app-dialog-admin-organisation-delete',
  templateUrl: './dialog-admin-organisation-delete.component.html',
  styleUrls: ['./dialog-admin-organisation-delete.component.scss'],
  providers: [OrganisationStore]
})
export class DialogAdminOrganisationDeleteComponent implements OnInit, OnDestroy {
  public organisation: OrganisationInterface;

  public deleteOrganisationConfirmation: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public deleteOrganisationResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');

  private deleteOrganisationResponse$: Observable<any>;

  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DialogAdminOrganisationDeleteComponent>,
    private organisationStore: OrganisationStore
  ) {
    this.deleteOrganisationResponse$ = this.organisationStore.deleteOrganisationResponse$;
  }

  ngOnInit(): void {
    this.organisation = this.data.organisation;
  }

  public deleteOrganisation(): void {
    if (this.deleteOrganisationResponse.value === 'DEFAULT') {
      this.deleteOrganisationResponse.next('LOADING');
      this.organisationStore.deleteOrganisationAdmin({
        organisationId: this.organisation.id
      });
      this.subscriptions.push(
        this.deleteOrganisationResponse$.pipe(skip(1), take(1)).subscribe((result: any) => {
          if (result instanceof HttpResponse) {
            this.deleteOrganisationResponse.next('SUCCESS');
            setTimeout(() => {
              this.deleteOrganisationResponse.next('DEFAULT');
              this.dialogRef.close();
            }, 2500);
          } else {
            this.deleteOrganisationResponse.next('FAILURE');
            setTimeout(() => {
              this.deleteOrganisationResponse.next('DEFAULT');
            }, 2500);
          }
        })
      );
    }
  }

  public showConfirmation(): void {
    this.deleteOrganisationConfirmation.next(true);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
