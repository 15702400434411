<div class="row my-2">
  <div class="col-12 col-sm-6 my-2">
    <p>{{'group-instance.select_email_language' | translate}}</p>
  </div>
  <div class="col-12 col-sm-6 my-2">
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>{{'group-instance.select_language' | translate}}</mat-label>
      <mat-select (selectionChange)="changeSelection()" [(value)]="selectedLanguage">
        <mat-option [value]="option" *ngFor="let option of languages">{{option}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
