/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, throwError } from 'rxjs';
import { JobResultInterface } from '../../../models/interface/jobresult.interface';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons/faEllipsisH';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons/faFileDownload';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons/faFilePdf';
import { faRedo } from '@fortawesome/free-solid-svg-icons/faRedo';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { AnswersheetInterface } from '../../../models/interface/answersheet.interface';
import { Download } from '../../../helpers/download/download';
import { catchError, delay, mergeMap, repeat, skip, take } from 'rxjs/operators';
import { of } from 'rxjs';
import { HelperService } from '../../../services/helper/helper.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { QueueActionTypes } from '../../../store/queue/queue.action';
import { AnswersheetsStore } from '../../../store/answersheet/component-store/answersheet.store';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-dialog-answersheet-report',
  templateUrl: './dialog-answersheet-report.component.html',
  styleUrls: ['./dialog-answersheet-report.component.scss'],
  providers: [AnswersheetsStore]
})
export class DialogAnswersheetReportComponent implements OnInit, OnDestroy {
  @ViewChild('paginator') paginator: MatPaginator;

  // Icons
  faFilePdf = faFilePdf;
  faFileDownload = faFileDownload;
  faTimes = faTimes;
  faEllipsisH = faEllipsisH;
  faRedo = faRedo;
  faClock = faClock;

  public param = { lesson_name: '...' };
  public answersheet: AnswersheetInterface;

  public isLoading$: Observable<boolean>;

  public downloadPDFReportResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');
  public download$: Observable<Download>;

  public jobresults$: Observable<Array<JobResultInterface>>;
  public pagedJobResults$: Observable<Array<JobResultInterface>>;
  public pagedJobResultsSubject: BehaviorSubject<Array<JobResultInterface>> = new BehaviorSubject<Array<JobResultInterface>>([]);

  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private jobresultsSubject: BehaviorSubject<Array<JobResultInterface>> = new BehaviorSubject<Array<JobResultInterface>>([]);

  private subscriptions: Subscription[] = [];

  private answersheetPDFReportResponse$: Observable<any>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private answersheetsStore: AnswersheetsStore,
    private actions$: Actions,
    private store: Store,
    private helperService: HelperService
  ) {
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.jobresults$ = this.jobresultsSubject.asObservable();
    this.answersheetPDFReportResponse$ = this.answersheetsStore.answersheetPDFReportResponse$;
  }

  public get helper() {
    return this.helperService;
  }

  ngOnInit(): void {
    this.param = this.data.param;
    this.answersheet = this.data.answersheet;

    const poll = of({}).pipe(
      mergeMap(_ => {
        if (this.jobresultsSubject.value.filter(job => job.attributes.finished === 0).length > 0) {
          this.loadJobResults();
        }
        return of({});
      }),
      catchError(e => of(null)),
      delay(30000),
      repeat()
    );

    this.subscriptions.push(poll.subscribe());
    this.pagedJobResults$ = this.pagedJobResultsSubject.asObservable();
    this.loadJobResults();
  }

  public getAnswersheetReportPDF(answersheet: AnswersheetInterface) {
    if (this.downloadPDFReportResponse.value === 'FAILURE' || this.downloadPDFReportResponse.value === 'SUCCESS') {
      this.downloadPDFReportResponse.next('DEFAULT');
      return;
    }

    if (this.downloadPDFReportResponse.value === 'DEFAULT') {
      this.downloadPDFReportResponse.next('LOADING');

      this.answersheetsStore.getAnswersheetPDFReportQueue(answersheet.id);
      this.answersheetPDFReportResponse$
        .pipe(
          skip(1),
          take(1),
          mergeMap((result: any) => {
            if (result instanceof HttpErrorResponse) {
              this.store.dispatch({ type: QueueActionTypes.getAllJobResultsOfUserType, payload: {} });
              return throwError(result);
            } else {
              return of(result);
            }
          })
        )
        .subscribe(
          (result: any) => {
            this.loadJobResults();
            this.downloadPDFReportResponse.next('SUCCESS');
            setTimeout(() => {
              this.downloadPDFReportResponse.next('DEFAULT');
            }, 2500);
          },
          () => {
            this.downloadPDFReportResponse.next('FAILURE');
            setTimeout(() => {
              this.downloadPDFReportResponse.next('DEFAULT');
            }, 2500);
          }
        );
    }
  }

  public updatePagedJobResults(event: any) {
    if (event) {
      this.pagedJobResultsSubject.next(event);
    }
  }

  public loadJobResults(): void {
    this.store.dispatch({ type: QueueActionTypes.getAllJobResultsOfUserType, payload: {} });
    this.subscriptions.push(
      this.actions$
        .pipe(ofType(QueueActionTypes.getAllJobResultsOfUserSuccessType, QueueActionTypes.getAllJobResultsOfUserErrorType), take(1))
        .subscribe((result: any) => {
          if (result.type === QueueActionTypes.getAllJobResultsOfUserSuccessType) {
            const allJobs: Array<JobResultInterface> = result['response']['body']['data'];
            this.jobresultsSubject.next(
              allJobs
                .filter(
                  (job: JobResultInterface) =>
                    job.attributes.entity_id.toString() === this.answersheet.id.toString() && job.attributes.type === 'Answersheet'
                )
                .sort((a, b) => b.id - a.id)
            );
            this.pagedJobResultsSubject.next(this.jobresultsSubject.value.slice(0, 20));
            if (this.paginator) {
              this.paginator.firstPage();
            }
            this.isLoadingSubject.next(false);
          }
        })
    );
  }

  public trackByJobId(index: number, element: any): number {
    return element.id;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
