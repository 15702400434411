import { createReducer, on } from '@ngrx/store';
import { addAllDiariesStore, updateAllDiariesStore, removeAllDiariesStore } from './diary.action';

import { DiaryInterface } from 'src/app/models/interface/diary.interface';

export const initialAllDiariesState: Array<DiaryInterface> = [];

export const diariesReducer = createReducer(
  initialAllDiariesState,
  on(addAllDiariesStore, (state, { diaries }) => diaries),
  on(updateAllDiariesStore, (state, { diaries }) => diaries),
  on(removeAllDiariesStore, (state, {}) => [])
);
