import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { faBook } from '@fortawesome/free-solid-svg-icons/faBook';
import { faBriefcase } from '@fortawesome/free-solid-svg-icons/faBriefcase';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons/faCheckSquare';
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons/faEnvelopeOpenText';
import { faHands } from '@fortawesome/free-solid-svg-icons/faHands';
import { faHandsHelping } from '@fortawesome/free-solid-svg-icons/faHandsHelping';
import { faKey } from '@fortawesome/free-solid-svg-icons/faKey';
import { faList } from '@fortawesome/free-solid-svg-icons/faList';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faSitemap } from '@fortawesome/free-solid-svg-icons/faSitemap';
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { HelperService } from '../../../services/helper/helper.service';
import { StudyInterface } from '../../../models/interface/study/study.interface';
import { OrganisationInterface } from '../../../models/interface/organisation/organisation.interface';
import { Store } from '@ngrx/store';
import { OrganisationActionTypes } from '../../../store/organisation/organisation.action';
import { filter, skip, take } from 'rxjs/operators';
import { StudyStore } from '../../../store/study/component-store/study.store';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-dialog-admin-study-organisation',
  templateUrl: './dialog-admin-study-organisation.component.html',
  styleUrls: ['./dialog-admin-study-organisation.component.scss'],
  providers: [StudyStore]
})
export class DialogAdminStudyOrganisationComponent implements OnInit, OnDestroy {
  // Icons
  faBook = faBook;
  faHands = faHands;
  faHandsHelping = faHandsHelping;
  faSearch = faSearch;
  faPlus = faPlus;
  faList = faList;
  faUsers = faUsers;
  faSitemap = faSitemap;
  faBriefcase = faBriefcase;
  faKey = faKey;
  faEnvelopeOpenText = faEnvelopeOpenText;
  faCheckSquare = faCheckSquare;
  faStar = faStar;

  // Filter selection
  public filter = {
    organisationSelection: ''
  };

  public study: StudyInterface;
  public organisations: Array<OrganisationInterface> = [];
  public selectedOrganisation: OrganisationInterface;
  public assignStudyToOrganisationResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');

  public organisations$: Observable<Array<OrganisationInterface>>;

  private assignStudyToOrganisationResponse$: Observable<any>;

  private organisationsSubject: BehaviorSubject<Array<OrganisationInterface>> = new BehaviorSubject<Array<OrganisationInterface>>([]);

  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<DialogAdminStudyOrganisationComponent>,
    private helperService: HelperService,
    private studyStore: StudyStore,
    private store: Store<{ organisations: Array<OrganisationInterface> }>
  ) {
    this.organisations$ = store.select('organisations');
    this.assignStudyToOrganisationResponse$ = this.studyStore.assignStudyToOrganisationResponse$;
  }

  public get helper() {
    return this.helperService;
  }

  ngOnInit(): void {
    this.study = this.data.study;
    this.store.dispatch({ type: OrganisationActionTypes.getOrganisationsAdminType, payload: { include: 'owners,collaborators' } });

    this.subscriptions.push(
      this.organisations$
        .pipe(
          filter(result => result.length > 0),
          take(1)
        )
        .subscribe(
          result => {
            this.organisations = result;
            this.organisationsSubject.next(this.organisations);
            if (this.organisations.length > 0) {
              this.selectedOrganisation = this.organisations[0];
            }
          },
          () => {
            this.organisations = [];
          }
        )
    );
  }

  public assignStudyToOrganisation(): void {
    if (this.selectedOrganisation) {
      if (this.assignStudyToOrganisationResponse.value === 'DEFAULT') {
        this.assignStudyToOrganisationResponse.next('LOADING');
        this.studyStore.assignStudyToOrganisation({
          studyId: parseInt(this.study.id.toString(), 10),
          organisationId: this.selectedOrganisation.id
        });
        this.assignStudyToOrganisationResponse$.pipe(skip(1), take(1)).subscribe(
          result => {
            if (result instanceof HttpResponse) {
              this.assignStudyToOrganisationResponse.next('SUCCESS');
              setTimeout(() => {
                this.assignStudyToOrganisationResponse.next('DEFAULT');
                this.dialogRef.close('SUCCESS');
              }, 2500);
            } else {
              this.assignStudyToOrganisationResponse.next('FAILURE');
              setTimeout(() => {
                this.assignStudyToOrganisationResponse.next('DEFAULT');
              }, 2500);
            }
          },
          () => {
            this.assignStudyToOrganisationResponse.next('FAILURE');
            setTimeout(() => {
              this.assignStudyToOrganisationResponse.next('DEFAULT');
            }, 2500);
          }
        );
      }
    }
  }

  public onKeyFilter(): void {
    const filterResults: Array<OrganisationInterface> = this.organisations.filter((organisation: OrganisationInterface) =>
      organisation.attributes.name.toLowerCase().includes(this.filter['organisationSelection'].toLowerCase())
    );
    this.organisationsSubject.next(filterResults.length !== 0 ? filterResults : [this.selectedOrganisation]);
  }

  public resetFilter(): void {
    this.filter = {
      organisationSelection: ''
    };
    this.organisationsSubject.next(this.organisations);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
