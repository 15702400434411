<!-- *ngIf="isAuthenticated$ | async" -->
<footer class="page-footer font-small pt-4">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-6 text-center text-lg-left mb-2">
        <a href="https://www.uni-ulm.de" mat-button
          ><img class="p-1 img-size" src="assets/uni-ulm_logo/Logo_uulm_Vorlage_100mm_schwarz.svg"
        /></a>
      </div>
      <div class="col-12 col-lg-6 text-center text-lg-right mb-2">
        <div class="row">
          <div class="col-12 col-md-6">
            <a mat-button (click)="redirectToImprint()">Impressum</a>
          </div>

          <div class="col-12 col-md-6">
            <a mat-button (click)="redirectToPrivacyECoach()">Datenschutzerklärung eCoach Plattform</a>
          </div>

          <a href="mailto:support@esano-trainings.de" mat-button>
            <span><fa-icon [icon]="faTools"></fa-icon></span> {{'footer.contact_technical_support' | translate}}</a
          >
        </div>
      </div>
      <section class="mat-typography">
        <div class="caption footer-copyright text-center p-2">© 2020 Universität Ulm | Ulm University</div>
      </section>
    </div>
  </div>
</footer>
