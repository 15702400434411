import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { AuthenticationService } from '../../../services/api/authentication/authentication.service';
import { catchError, EMPTY, Observable, switchMap, tap, throwError } from 'rxjs';

export interface AuthenticationState {
  resendVerificationResponse: any;
  deactivateUserAdminResponse: any;
  requestPasswordResetResponse: any;
  createAccountAdminResponse: any;
}

@Injectable()
export class AuthenticationStore extends ComponentStore<AuthenticationState> {
  readonly resendVerificationResponse$: Observable<any> = this.select(state => state.resendVerificationResponse, { debounce: true });
  readonly deactivateUserAdminResponse$: Observable<any> = this.select(state => state.deactivateUserAdminResponse, { debounce: true });
  readonly requestPasswordResetResponse$: Observable<any> = this.select(state => state.requestPasswordResetResponse, { debounce: true });
  readonly createAccountAdminResponse$: Observable<any> = this.select(state => state.createAccountAdminResponse, { debounce: true });

  readonly updateAuthenticationState = this.updater(
    (
      state,
      payload: {
        resendVerificationResponse?: any;
        deactivateUserAdminResponse?: any;
        requestPasswordResetResponse?: any;
        createAccountAdminResponse?: any;
      }
    ) => ({
      resendVerificationResponse: payload.resendVerificationResponse
        ? payload.resendVerificationResponse
        : state.resendVerificationResponse,
      deactivateUserAdminResponse: payload.deactivateUserAdminResponse
        ? payload.deactivateUserAdminResponse
        : state.deactivateUserAdminResponse,
      requestPasswordResetResponse: payload.requestPasswordResetResponse
        ? payload.requestPasswordResetResponse
        : state.requestPasswordResetResponse,
      createAccountAdminResponse: payload.createAccountAdminResponse ? payload.createAccountAdminResponse : state.createAccountAdminResponse
    })
  );

  // API
  readonly resendVerification = this.effect((email$: Observable<string>) =>
    email$.pipe(
      switchMap((email: string) => {
        this.updateAuthenticationState({ resendVerificationResponse: null });
        return this.authenticationService.resendVerification(email).pipe(
          tap({
            next: (result: any) => this.updateAuthenticationState({ resendVerificationResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateAuthenticationState({ resendVerificationResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly deactivateUserAdmin = this.effect((userId$: Observable<number>) =>
    userId$.pipe(
      switchMap((userId: number) => {
        this.updateAuthenticationState({ deactivateUserAdminResponse: null });
        return this.authenticationService.deactivateUserAdmin(userId).pipe(
          tap({
            next: (result: any) => this.updateAuthenticationState({ deactivateUserAdminResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateAuthenticationState({ deactivateUserAdminResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly requestPasswordReset = this.effect((email$: Observable<string>) =>
    email$.pipe(
      switchMap((email: string) => {
        this.updateAuthenticationState({ requestPasswordResetResponse: null });
        return this.authenticationService.requestPasswordReset(email).pipe(
          tap({
            next: (result: any) => this.updateAuthenticationState({ requestPasswordResetResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateAuthenticationState({ requestPasswordResetResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly createAccountAdmin = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) => {
        this.updateAuthenticationState({ createAccountAdminResponse: null });
        return this.authenticationService
          .createAccountAdmin(payload.email, payload.roleSlugs, payload.name, payload.firstname, payload.lastname)
          .pipe(
            tap({
              next: (result: any) => this.updateAuthenticationState({ createAccountAdminResponse: result }),
              error: e => throwError(e)
            }),
            catchError(error => {
              this.updateAuthenticationState({ createAccountAdminResponse: error });
              return EMPTY;
            })
          );
      })
    )
  );

  constructor(private readonly authenticationService: AuthenticationService) {
    super({
      resendVerificationResponse: null,
      deactivateUserAdminResponse: null,
      requestPasswordResetResponse: null,
      createAccountAdminResponse: null
    });
  }
}
