import { Component, Inject, OnInit } from '@angular/core';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { BehaviorSubject, iif, mergeMap, Observable, of, skip, take, throwError } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PayloadInterface } from '../../../models/interface/payload.interface';
import { RequestBodyData } from '../../../models/request-body.data';
import { HelperService } from '../../../services/helper/helper.service';
import { UserInterface } from '../../../models/interface/user.interface';
import { StudyStore } from '../../../store/study/component-store/study.store';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-dialog-group-member-code-update',
  templateUrl: './dialog-group-member-code-update.component.html',
  styleUrls: ['./dialog-group-member-code-update.component.scss'],
  providers: [StudyStore]
})
export class DialogGroupMemberCodeUpdateComponent implements OnInit {
  // Icons
  faEnvelope = faEnvelope;

  public studyCodeControl;
  public user: UserInterface;
  public studyId: number;

  public invalidUpdatedCodeSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public updateStudyCodeResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');

  private updateMemberCodeResponse$: Observable<any>;

  private submitted = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private studyStore: StudyStore,
    private helperService: HelperService,
    private dialogRef: MatDialogRef<DialogGroupMemberCodeUpdateComponent>
  ) {
    this.studyCodeControl = new UntypedFormControl('', [this.checkUpdatedStudyCodeValidator.bind(this)]);
    this.updateMemberCodeResponse$ = this.studyStore.updateMemberCodeResponse$;
  }

  public get helper() {
    return this.helperService;
  }

  ngOnInit(): void {
    this.user = this.data.user;
    this.studyId = this.data.studyId;
    this.setInputStudyCode(this.user);
  }

  public checkUpdatedStudyCodeValidator(): { studyCodeAccepted: boolean } {
    return !!this.invalidUpdatedCodeSubject ? { studyCodeAccepted: !this.invalidUpdatedCodeSubject.value } : null;
  }

  public setInputStudyCode(user: UserInterface): void {
    this.invalidUpdatedCodeSubject.next(false);
    this.helper.getCodeOfUserByStudy(this.studyId, user).subscribe((next: string) => {
      this.studyCodeControl.patchValue(next);
    });
  }

  public updateStudyCode(user: UserInterface): void {
    this.invalidUpdatedCodeSubject.next(false);
    if (this.studyCodeControl.value && this.studyCodeControl.value !== '') {
      if (!this.submitted) {
        this.updateStudyCodeResponse.next('LOADING');
        this.submitted = true;
        const payload: PayloadInterface = new RequestBodyData('studies', {
          users: [{ id: parseInt(user.id.toString(), 10), code: this.studyCodeControl.value }]
        });
        this.studyStore.updateMemberCode({ studyId: this.studyId, payload });
        this.updateMemberCodeResponse$
          .pipe(
            skip(1),
            take(1),
            mergeMap((result: any) => iif(() => result instanceof HttpResponse, of(result), throwError(result)))
          )
          .subscribe(
            () => {
              this.updateStudyCodeResponse.next('SUCCESS');
            },
            () => {
              this.updateStudyCodeResponse.next('FAILURE');
              this.invalidUpdatedCodeSubject.next(true);
              setTimeout(() => {
                this.updateStudyCodeResponse.next('DEFAULT');
                this.submitted = false;
              }, 2500);
            },
            () => {
              setTimeout(() => {
                this.updateStudyCodeResponse.next('DEFAULT');
                this.submitted = false;
                this.dialogRef.close('SUCCESS');
              }, 2500);
            }
          );
      }
    }
  }
}
