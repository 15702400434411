/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { skip, take } from 'rxjs/operators';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { ProfileInterface } from '../../../models/interface/profile.interface';
import { passwordMatchingValidator } from '../../../helpers/validators/password-matching.validator';
import { HelperService } from '../../../services/helper/helper.service';
import { Store } from '@ngrx/store';
import { RoleInterface } from '../../../models/interface/role.interface';
import { ProfileActionTypes } from '../../../store/profile/profile.action';
import { ProfileStore } from '../../../store/profile/component-store/profile.store';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';

/**
 * Component:
 * Profile page displaying general profile information and password change;
 * Can be found: {uri}/myprofile
 */

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  providers: [ProfileStore]
})
export class ProfileComponent implements OnInit, OnDestroy {
  // Icons
  faCheck = faCheck;
  faTimes = faTimes;
  faInfoCircle = faInfoCircle;

  // Loading
  public isLoading$: Observable<boolean>;

  public userRoles: string[] = [];
  public userData: ProfileInterface;

  public profile$: Observable<ProfileInterface> = this.store.select('myProfile');
  public profileRoles$: Observable<Array<RoleInterface>> = this.store.select('myRoles');

  public updateProfileResponse$: Observable<any>;
  public updatePasswordResponse$: Observable<any>;

  // Form to edit profile and change password
  public profileEditForm: UntypedFormGroup;
  public passwordChangeForm: UntypedFormGroup;

  // Hide new password
  public hideNewPassword = true;

  public updateProfileResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');
  public updatePasswordResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');

  public showDescription$: Observable<boolean>;

  public showLeakPasswordInfo = false;

  public showDescriptionSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private subscriptions: Subscription[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private helperService: HelperService,
    private profileStore: ProfileStore,
    private store: Store<{ myProfile: ProfileInterface; myRoles: Array<RoleInterface> }>
  ) {
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.showDescription$ = this.showDescriptionSubject.asObservable();
    this.profileEditForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(255)]],
      firstname: ['', [Validators.required, Validators.maxLength(255)]],
      lastname: ['', [Validators.required, Validators.maxLength(255)]],
      gender: ['', Validators.required]
    });

    this.passwordChangeForm = this.formBuilder.group(
      {
        old_password: ['', [Validators.required, Validators.maxLength(255)]],
        new_password: [
          '',
          [
            Validators.required,
            Validators.maxLength(255),
            Validators.pattern('^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\\d]){1,})(?=(.*[\\W]){1,})(?!.*\\s).{8,}$')
          ]
        ],
        confirm_password: [
          '',
          [
            Validators.required,
            Validators.maxLength(255),
            Validators.pattern('^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\\d]){1,})(?=(.*[\\W]){1,})(?!.*\\s).{8,}$')
          ]
        ]
      },
      {
        validators: passwordMatchingValidator('new_password', 'confirm_password')
      }
    );
    this.updateProfileResponse$ = this.profileStore.updateProfileResponse$;
    this.updatePasswordResponse$ = this.profileStore.updatePasswordResponse$;
  }

  // Helper function
  public get helper() {
    return this.helperService;
  }

  get f() {
    return this.profileEditForm.controls;
  }

  get g() {
    return this.passwordChangeForm.controls;
  }

  ngOnInit(): void {
    this.isLoadingSubject.next(true);
    this.store.dispatch({
      type: ProfileActionTypes.getProfileType
    });
    this.store.dispatch({
      type: ProfileActionTypes.getProfileRolesType
    });
    this.subscriptions.push(
      this.profile$.subscribe(profile => {
        if (profile) {
          this.userData = profile;
          this.profileEditForm.setValue({
            name: this.userData.attributes.name,
            firstname: this.userData.attributes.firstname,
            lastname: this.userData.attributes.lastname,
            gender: this.userData.attributes.sex.toString()
          });
          this.isLoadingSubject.next(false);
        }
      })
    );
    this.subscriptions.push(
      this.profileRoles$.subscribe(roles => {
        this.userRoles = this.extractRoles(roles);
      })
    );
  }

  /*
    Extract and parse roles to Array<string>
   */
  public extractRoles(respRoles: any[]): string[] {
    const arr: Array<string> = [];
    respRoles.forEach(value => {
      arr.push(value['attributes']['name']);
    });
    return arr;
  }

  public updateProfile(): void {
    if (this.profileEditForm.invalid) {
      return;
    } else {
      this.updateProfileResponse.next('LOADING');
    }

    this.profileStore.updateProfile({
      settings: undefined,
      name: undefined,
      firstname: this.f.firstname.value,
      lastname: this.f.lastname.value,
      sex: this.f.gender.value
    });

    this.subscriptions.push(
      this.updateProfileResponse$.pipe(skip(1), take(1)).subscribe((result: any) => {
        if (result instanceof HttpResponse) {
          this.updateProfileResponse.next('SUCCESS');
        } else {
          this.updateProfileResponse.next('FAILURE');
        }
        setTimeout(() => {
          this.updateProfileResponse.next('DEFAULT');
        }, 2500);
      })
    );
  }

  public changePassword(): void {
    if (this.passwordChangeForm.invalid && this.updatePasswordResponse.value !== 'DEFAULT') {
      return;
    } else {
      this.updatePasswordResponse.next('LOADING');
      this.showLeakPasswordInfo = false;
      this.profileStore.updatePassword({
        currentPassword: this.g.old_password.value,
        password: this.g.new_password.value,
        passwordConfirmation: this.g.confirm_password.value
      });
      this.subscriptions.push(
        this.updatePasswordResponse$.pipe(skip(1), take(1)).subscribe((result: any) => {
          if (result instanceof HttpResponse) {
            this.updatePasswordResponse.next('SUCCESS');
          } else {
            if (result instanceof HttpErrorResponse) {
              const errorMessage = JSON.stringify(result);
              this.showLeakPasswordInfo = errorMessage.includes('has appeared in a data leak');
            }
            this.updatePasswordResponse.next('FAILURE');
          }
          setTimeout(() => {
            this.updatePasswordResponse.next('DEFAULT');
          }, 2500);
        })
      );
    }
  }

  public isGender(value: string): boolean {
    return this.f.gender.value.toString() === value;
  }

  public getRoleTip(role: string): string {
    if (role.toLowerCase() === 'admin' || role.toLowerCase() === 'administrator') {
      return 'profile.tip_admin';
    }
    if (role.toLowerCase() === 'editor') {
      return 'profile.tip_editor';
    }
    if (role.toLowerCase() === 'ecoach') {
      return 'profile.tip_ecoach';
    }
    return '';
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
