import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, iif, mergeMap, Observable, of, skip, Subscription, take, throwError } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PayloadInterface } from '../../../models/interface/payload.interface';
import { RequestBodyData } from '../../../models/request-body.data';
import { HelperService } from '../../../services/helper/helper.service';
import { UserInterface } from '../../../models/interface/user.interface';
import { StudyStore } from '../../../store/study/component-store/study.store';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-dialog-intervention-collaborator-add',
  templateUrl: './dialog-intervention-collaborator-add.component.html',
  styleUrls: ['./dialog-intervention-collaborator-add.component.scss'],
  providers: [StudyStore]
})
export class DialogInterventionCollaboratorAddComponent implements OnInit, OnDestroy {
  public collaborator: UserInterface;
  public param;
  public studyId: number;
  public collaborators: Array<UserInterface>;

  public ecoaches: Array<UserInterface>;

  // Filter
  public filter = {
    userSelection: ''
  };

  public ecoachesSubject: BehaviorSubject<Array<UserInterface>> = new BehaviorSubject<Array<UserInterface>>([]);
  public ecoaches$: Observable<Array<UserInterface>> = this.ecoachesSubject.asObservable();

  // List of roles
  public roles = ['study.ecoachmanager', 'study.ecoach', 'study.access'];
  public selectedRole: string;

  // Selected collaborator
  public selectedCollaborator: UserInterface;

  // Form submission
  submitted = false;

  public addCollaboratorResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');

  private addCollaboratorsResponse$: Observable<any>;

  // Subscription Handler
  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private helperService: HelperService,
    private dialogRef: MatDialogRef<DialogInterventionCollaboratorAddComponent>,
    private studyStore: StudyStore
  ) {
    this.addCollaboratorsResponse$ = this.studyStore.addCollaboratorsResponse$;
  }

  // Helper function
  public get helper() {
    return this.helperService;
  }

  ngOnInit(): void {
    this.collaborators = this.data.collaborators;
    this.param = this.data.param;
    this.studyId = this.data.studyId;
    this.ecoaches = this.data.ecoaches;
    this.ecoachesSubject.next(this.ecoaches);
    this.collaborator = this.data.collaborator;

    if (this.ecoaches.length > 0) {
      this.selectedCollaborator = this.ecoaches[0];
    }
  }

  public isNoneSelectedAddCollaboratorForm(): boolean {
    return !this.selectedCollaborator && !this.selectedRole;
  }

  // Adding collaborator to study
  public addCollaborator(): void {
    if (this.selectedCollaborator && this.selectedRole) {
      if (this.addCollaboratorResponse.value === 'DEFAULT') {
        const addRoles: Array<{ id: number; role: string }> = [];
        addRoles.push({ id: this.selectedCollaborator.id, role: this.selectedRole });
        const payload: PayloadInterface = new RequestBodyData('users', { users: addRoles });
        this.addCollaboratorResponse.next('LOADING');
        this.studyStore.addCollaborators({ studyId: this.studyId, payload });
        this.subscriptions.push(
          this.addCollaboratorsResponse$
            .pipe(
              skip(1),
              take(1),
              mergeMap((result: any) => iif(() => result instanceof HttpResponse, of(result), throwError(result)))
            )
            .subscribe(
              () => {
                this.addCollaboratorResponse.next('SUCCESS');
              },
              () => {
                this.addCollaboratorResponse.next('FAILURE');
                setTimeout(() => {
                  this.addCollaboratorResponse.next('DEFAULT');
                }, 2500);
              },
              () => {
                setTimeout(() => {
                  this.addCollaboratorResponse.next('DEFAULT');
                  this.dialogRef.close('SUCCESS');
                }, 2500);
              }
            )
        );
      }
    }
  }

  public resetFilter(): void {
    this.filter['userSelection'] = '';
    this.ecoachesSubject.next(this.ecoaches);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
