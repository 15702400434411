import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, of, catchError } from 'rxjs';
import { MessageService } from '../../services/api/message/message.service';
import { addAllMessageThreadsStore, GetAllMessagesThreads, MessageActionTypes } from './message.action';
import { HttpErrorResponseMessage } from 'src/app/enum/http-error-response-message';
import { ResponseSuccess } from '../utils/response-success';
import { ResponseError } from '../utils/response-error';

@Injectable()
export class MessageEffects {
  getAllMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MessageActionTypes.getAllMessagesThreadsType),
      map((action: GetAllMessagesThreads) => action.payload),
      switchMap((payload: any) =>
        this.messageService.getAllMessagesThreads(payload.answersheetFlag, payload.answersheetId, payload.include).pipe(
          switchMap(result => {
            const actions = [];
            actions.push(addAllMessageThreadsStore({ allMessageThreads: result.body.data }));
            actions.push(new ResponseSuccess(MessageActionTypes.getAllMessagesThreadsSuccessType, result));
            return actions;
          }),
          catchError(err =>
            of(
              new ResponseError(MessageActionTypes.getAllMessagesThreadsErrorType, {
                message: HttpErrorResponseMessage.genericUnknownError,
                content: err
              })
            )
          )
        )
      )
    )
  );

  constructor(private actions$: Actions, private messageService: MessageService) {}
}
