<!-- Text string component for lesson/question elements in ionic app -->
<div *ngIf="(evaluation$ | async)" [class.required]="required" [hidden]="hidden$ | async" class="question">
  <ion-label class="ion-text-wrap">{{questionSubject | async}}</ion-label>
  <ion-item #answerRequired class="ion-text-wrap">
    <ion-input
      (change)="onChange($event)"
      [(ngModel)]="answer"
      [readonly]="isDisabled"
      placeholder="{{'question-text-string.textarea_placeholder' | translate}}"
      type="text"
    ></ion-input>
  </ion-item>
  <div *ngIf="noAnswerProvided$ | async" class="text-danger mb-2">
    <small>{{'question-text-string.no_answer_has_been_submitted' | translate}}</small>
  </div>
</div>
