import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { map, switchMap, of, catchError, forkJoin, Observable } from 'rxjs';
import { HttpErrorResponseMessage } from 'src/app/enum/http-error-response-message';
import { StudyService } from '../../services/api/study/study.service';
import {
  addCollabGroupsStore,
  addCollaboratorsOfStudiesStore,
  addNonCollabGroupsStore,
  GetCollaboratingStudies,
  GetCollaboratingStudiesError,
  GetCollaboratingStudiesSuccess,
  GetCollaborators,
  GetCollaboratorsError,
  GetCollaboratorsOfMultipleStudies,
  GetCollaboratorsOfMultipleStudiesError,
  GetCollaboratorsOfMultipleStudiesSuccess,
  GetCollaboratorsSuccess,
  GetNonCollaboratingStudies,
  GetNonCollaboratingStudiesError,
  GetNonCollaboratingStudiesSuccess,
  StudyActionTypes
} from './study.action';

@Injectable()
export class StudyEffects {
  getCollaboratingStudies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StudyActionTypes.getCollaboratingStudiesType),
      map((action: GetCollaboratingStudies) => action.payload),
      switchMap((payload: any) =>
        this.studyService
          .getCollaboratingStudies(
            payload.type,
            payload.interventionType,
            payload.isShareable,
            payload.isSubscribable,
            payload.isPrivate,
            payload.isActive,
            payload.studyId,
            payload.include,
            payload.role
          )
          .pipe(
            switchMap(result => {
              const actions = [];
              if (!payload.role) {
                actions.push(addCollabGroupsStore({ collabGroups: result.body.data }));
              }
              actions.push(new GetCollaboratingStudiesSuccess(result));
              return actions;
            }),
            catchError(err => of(new GetCollaboratingStudiesError({ message: HttpErrorResponseMessage.genericUnknownError, content: err })))
          )
      )
    )
  );

  getNonCollaboratingStudies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StudyActionTypes.getNonCollaboratingStudiesType),
      map((action: GetNonCollaboratingStudies) => action.payload),
      switchMap((payload: any) =>
        this.studyService
          .getNonCollaboratingStudies(
            payload.type,
            payload.interventionType,
            payload.isShareable,
            payload.isSubscribable,
            payload.isRunning,
            payload.isPrivate,
            payload.isActive,
            payload.include
          )
          .pipe(
            switchMap(result => {
              const actions = [];
              actions.push(addNonCollabGroupsStore({ nonCollabGroups: result.body.data }));
              actions.push(new GetNonCollaboratingStudiesSuccess(result));
              return actions;
            }),
            catchError(err =>
              of(new GetNonCollaboratingStudiesError({ message: HttpErrorResponseMessage.genericUnknownError, content: err }))
            )
          )
      )
    )
  );

  getCollaborators$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StudyActionTypes.getCollaboratorsType),
      map((action: GetCollaborators) => action.payload),
      switchMap((payload: any) =>
        this.studyService.getCollaborators(payload.studyId, payload.include).pipe(
          switchMap(result => {
            const actions = [];
            actions.push(
              addCollaboratorsOfStudiesStore({
                collaboratorsOfStudy: {
                  studyId: payload.studyId,
                  collaborators: result.body.data
                }
              })
            );
            actions.push(new GetCollaboratorsSuccess(result));
            return actions;
          }),
          catchError(err => {
            const actions = [];
            actions.push(
              addCollaboratorsOfStudiesStore({
                collaboratorsOfStudy: {
                  studyId: payload.studyId,
                  collaborators: []
                }
              })
            );
            actions.push(new GetCollaboratorsError({ message: HttpErrorResponseMessage.genericUnknownError, content: err }));
            return actions;
          })
        )
      )
    )
  );

  getCollaboratorsOfMultipleStudies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StudyActionTypes.getCollaboratorsOfMultipleStudiesType),
      map((action: GetCollaboratorsOfMultipleStudies) => action.payload),
      switchMap((payload: Array<any>) => {
        const reqs: Array<Observable<any>> = [];
        payload.forEach(req => {
          reqs.push(this.studyService.getCollaborators(req.studyId, req.include));
        });
        return forkJoin(reqs).pipe(
          switchMap(results => {
            const actions = [];
            results.forEach((result, index) => {
              actions.push(
                addCollaboratorsOfStudiesStore({
                  collaboratorsOfStudy: {
                    studyId: payload[index].studyId,
                    collaborators: result.body.data
                  }
                })
              );
            });
            actions.push(new GetCollaboratorsOfMultipleStudiesSuccess(results));
            return actions;
          }),
          catchError(err =>
            of(new GetCollaboratorsOfMultipleStudiesError({ message: HttpErrorResponseMessage.genericUnknownError, content: err }))
          )
        );
      })
    )
  );

  constructor(private actions$: Actions, private studyService: StudyService) {}
}
