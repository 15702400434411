import { Action, createAction, props } from '@ngrx/store';
import { OrganisationInterface } from 'src/app/models/interface/organisation/organisation.interface';
import { UserInterface } from 'src/app/models/interface/user.interface';

export enum OrganisationActionTypes {
  addOrganisationsStoreType = 'Add Organisations Store',
  addOrganisationsAdminStoreType = 'Add Organisations Admin Store',
  updateOrganisationsStoreType = 'Update Organisations Store',
  deleteOrganisationsStoreType = 'Remove Organisations Store',

  addOrganisationsCollaboratorsStoreType = 'Add Organisations Collaborators Store',
  updateOrganisationsCollaboratorsStoreType = 'Update Organisations Collaborators Store',
  deleteOrganisationsCollaboratorsStoreType = 'Delete Organisations Collaborators Store',

  getOrganisationsCollaboratorsStoreSuccessType = '[Success] Add Organisations Collaborators Store',
  getOrganisationsCollaboratorsStoreErrorType = '[Error] Add Organisations Collaborators Store',

  getOrganisationsType = '[Organisation API] Get Organisations',
  getOrganisationsSuccessType = '[Organisation API - Success] Get Organisations',
  getOrganisationsErrorType = '[Organisation API - Error] Get Organisations',

  getOrganisationsAdminType = '[Organisation API] Get Organisations as admin',
  getOrganisationsAdminSuccessType = '[Organisation API - Success] Get Organisations as admin',
  getOrganisationsAdminErrorType = '[Organisation API - Error] Get Organisations as admin',

  getOrganisationCollaboratorsType = '[Organisation API] Get Organisation Collaborators',
  getOrganisationCollaboratorsSuccessType = '[Organisation API - Success] Get Organisation Collaborators',
  getOrganisationCollaboratorsErrorType = '[Organisation API - Error] Get Organisation Collaborators'
}

export const addOrganisationsStore = createAction(
  OrganisationActionTypes.addOrganisationsStoreType,
  props<{ organisations: Array<OrganisationInterface> }>()
);

export const addOrganisationsAdminStore = createAction(
  OrganisationActionTypes.addOrganisationsAdminStoreType,
  props<{ organisations: Array<OrganisationInterface> }>()
);

export const updateOrganisationsStore = createAction(
  OrganisationActionTypes.updateOrganisationsStoreType,
  props<{ organisations: Array<OrganisationInterface> }>()
);

export const removeOrganisationsStore = createAction(OrganisationActionTypes.deleteOrganisationsStoreType);

export const addOrganisationsCollaboratorsStore = createAction(
  OrganisationActionTypes.addOrganisationsCollaboratorsStoreType,
  props<{ collaborators: Array<UserInterface> }>()
);

export const updateOrganisationsCollaboratorsStore = createAction(
  OrganisationActionTypes.updateOrganisationsCollaboratorsStoreType,
  props<{ collaborators: Array<UserInterface> }>()
);

export const removeOrganisationsCollaboratorsStore = createAction(OrganisationActionTypes.deleteOrganisationsCollaboratorsStoreType);

export const getOrganisationsCollaboratorsSuccessStore = createAction(
  OrganisationActionTypes.getOrganisationsCollaboratorsStoreSuccessType
);
export const getOrganisationsCollaboratorsErrorStore = createAction(OrganisationActionTypes.getOrganisationsCollaboratorsStoreErrorType);

export class GetOrganisationsAdminSuccess implements Action {
  readonly type: string = OrganisationActionTypes.getOrganisationsAdminSuccessType;
  constructor(readonly response: any) {}
}

export class GetOrganisationsAdminError implements Action {
  readonly type: string = OrganisationActionTypes.getOrganisationsAdminErrorType;
  constructor(readonly response: any) {}
}

export class GetOrganisationCollaboratorsError implements Action {
  readonly type: string = OrganisationActionTypes.getOrganisationCollaboratorsErrorType;
  constructor(readonly response: any) {}
}
export class GetOrganisations implements Action {
  readonly type: string = OrganisationActionTypes.getOrganisationsType;
  constructor(readonly payload: any) {}
}

export class GetOrganisationsAdmin implements Action {
  readonly type: string = OrganisationActionTypes.getOrganisationsAdminType;
  constructor(readonly payload: any) {}
}

export class GetOrganisationCollaborators implements Action {
  readonly type: string = OrganisationActionTypes.getOrganisationCollaboratorsType;
  constructor(readonly payload: any) {}
}

export class GetOrganisationCollaboratorsSuccess implements Action {
  readonly type: string = OrganisationActionTypes.getOrganisationCollaboratorsSuccessType;
  constructor(readonly response: any) {}
}
