import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationComponent } from './navigation/navigation.component';
import { MaterialModule } from '../material/material.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AnnouncementModule } from '../announcement/announcement.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LayoutModule } from '@angular/cdk/layout';
import { FaqComponent } from './faq/faq.component';
import { FooterComponent } from './footer/footer.component';
import { ProfileComponent } from './profile/profile.component';
import { AppRoutingModule } from '../../app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { profileReducer, profileRolesReducer } from '../../store/profile/profile.reducer';
import { EffectsModule } from '@ngrx/effects';
import { HydrationEffects } from '../../store/hydration/hydration.effects';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UtilsModule } from '../utils/utils.module';
import { ImprintComponent } from './imprint/imprint.component';
import { PrivacyPatientComponent } from './privacy-patient/privacy-patient.component';
import { PrivacyEcoachComponent } from './privacy-ecoach/privacy-ecoach.component';

export const createTranslateLoader = (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json');

@NgModule({
  declarations: [
    NavigationComponent,
    FaqComponent,
    FooterComponent,
    ProfileComponent,
    ImprintComponent,
    PrivacyPatientComponent,
    PrivacyEcoachComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FontAwesomeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    FlexLayoutModule,
    LayoutModule,
    RouterModule,
    AppRoutingModule,
    AnnouncementModule,
    EffectsModule.forFeature([HydrationEffects]),
    UtilsModule
  ],
  exports: [
    NavigationComponent,
    FaqComponent,
    FooterComponent,
    ProfileComponent,
    ImprintComponent,
    PrivacyPatientComponent,
    PrivacyEcoachComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class NavigationModule {}
