<button [attr.id]="'btnDialogClose'" mat-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title>{{'intervention-instance.modal_update_instance_title' | translate:param}}</h1>

<div mat-dialog-content>
  <div [hidden]="!((progressSubject | async) === 'INFORMATION')">
    <ng-template #instanceUpdateInformationContainer> </ng-template>
  </div>

  <div [hidden]="!((progressSubject | async) === 'CONFIGURATION')">
    <ng-template #instanceUpdateContainer> </ng-template>
  </div>

  <div [hidden]="!((progressSubject | async) === 'CONFIRMATION')">
    <ng-template #instanceUpdateConfirmationContainer> </ng-template>
  </div>
</div>
<div mat-dialog-actions align="end">
  <!-- Information -->
  <button [attr.id]="'btnCancel'" *ngIf="(progressSubject | async) === 'INFORMATION'" mat-button mat-dialog-close>
    {{'intervention-instance.modal_cancel' | translate}}
  </button>
  <button [attr.id]="'btnPauseAndContinue'" (click)="pauseAndContinue()" *ngIf="(progressSubject | async) === 'INFORMATION'" mat-button>
    {{'intervention-instance.modal_continue' | translate}}
  </button>

  <!-- Configuration -->
  <button
    [attr.id]="'btnGoBackUpdate'"
    (click)="goBackUpdate('INFORMATION')"
    *ngIf="(progressSubject | async) === 'CONFIGURATION'"
    mat-button
  >
    {{'intervention-instance.modal_back' | translate}}
  </button>

  <button
    [attr.id]="'btnConfirmUpdateSettings'"
    (click)="confirmUpdateSettings()"
    *ngIf="(progressSubject | async) === 'CONFIGURATION'"
    mat-button
  >
    {{'intervention-instance.modal_next' | translate}}
  </button>
  <!-- Confirmation -->
  <button
    [attr.id]="'btnGoBackUpdate'"
    (click)="goBackUpdate('CONFIGURATION')"
    *ngIf="(progressSubject | async) === 'CONFIRMATION'"
    mat-button
  >
    {{'intervention-instance.modal_back' | translate}}
  </button>

  <app-button-feedback
    [_setId]="'btnUpdateInterventionInstance'"
    (click)="updateInterventionInstance()"
    *ngIf="(progressSubject | async) === 'CONFIRMATION'"
    [_defaultButtonText]="'intervention-instance.modal_update_instance'"
    [_responseText]="updateInstanceResponse | async"
  ></app-button-feedback>
</div>
