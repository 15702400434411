import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { TranslateService } from '@ngx-translate/core';
import { filter, take } from 'rxjs/operators';
import { AlertService } from '../../../services/alert/alert.service';
import { Actions, ofType } from '@ngrx/effects';
import { AuthenticationActionTypes } from '../../../store/authentication/authentication.action';
import { Store, Action } from '@ngrx/store';
import { HttpErrorResponseMessage } from '../../../enum/http-error-response-message';
import { AuthenticationStore } from '../../../store/authentication/component-store/authentication.store';
import { HttpResponse } from '@angular/common/http';

/**
 * Component:
 * Login page displaying a studyForm to log into the eCoach platform;
 * Can be found: {uri}/login
 */

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [AuthenticationStore]
})
export class LoginComponent implements OnInit, OnDestroy {
  readonly emailRegex =
    // eslint-disable-next-line max-len
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  // Icons
  faCheck = faCheck;
  faTimes = faTimes;

  languages = ['en', 'de'];

  public selectedLanguage = 'de';

  public isLoading$: Observable<boolean>;
  public isLoadingReset$: Observable<boolean>;
  public isSubmitted$: Observable<boolean>;
  public isSubmittedReset$: Observable<boolean>;
  public isWaiting$: Observable<boolean>;
  public isPasswordResetInstruction$: Observable<boolean>;

  public passwordResetResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');
  public loginResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');

  public requestPasswordResetResponse$: Observable<any>;

  // LoginForm
  public loginForm: UntypedFormGroup;

  // PasswordResetForm
  public passwordResetForm: UntypedFormGroup;

  public fieldTextType = 'password';

  private isAuthenticated$: Observable<boolean>;

  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private isLoadingResetSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private isSubmittedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isSubmittedResetSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private isWaitingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private isPasswordResetInstructionSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private alertService: AlertService,
    private translateService: TranslateService,
    private authenticationStore: AuthenticationStore,
    private actions$: Actions,
    private store: Store<{ isAuthenticated: boolean }>
  ) {
    this.isAuthenticated$ = this.store.select('isAuthenticated');
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.isLoadingReset$ = this.isLoadingResetSubject.asObservable();
    this.isSubmitted$ = this.isSubmittedSubject.asObservable();
    this.isSubmittedReset$ = this.isSubmittedResetSubject.asObservable();
    this.isWaiting$ = this.isWaitingSubject.asObservable();
    this.isPasswordResetInstruction$ = this.isPasswordResetInstructionSubject.asObservable();

    this.loginForm = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required, Validators.pattern(this.emailRegex)]),
      password: new UntypedFormControl('', Validators.required)
    });

    this.passwordResetForm = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required, Validators.pattern(this.emailRegex)])
    });
    // Set default language
    if (localStorage.getItem('language')) {
      const selected = this.languages.includes(localStorage.getItem('language')) ? localStorage.getItem('language') : this.languages[1];
      localStorage.setItem('language', selected);
      this.translateService.use(selected);
      this.selectedLanguage = selected;
    } else {
      this.translateService.use('de');
      this.selectedLanguage = this.languages[0];
      localStorage.setItem('language', this.languages[0]);
    }
    this.requestPasswordResetResponse$ = this.authenticationStore.requestPasswordResetResponse$;
  }

  public get f() {
    return this.loginForm;
  }

  public get g() {
    return this.passwordResetForm;
  }

  ngOnInit(): void {
    // Check if user is already authenticated
    this.subscriptions.push(
      this.isAuthenticated$.subscribe((value: boolean) => {
        if (value) {
          this.router.navigate(['/home']);
        }
      })
    );
    console.log('selectedLanguage', this.selectedLanguage);
  }

  /**
   * This function validates studyForm input and sends a login request.
   */
  public login(): void {
    this.isSubmittedSubject.next(true);
    if (this.loginForm.invalid || this.isLoadingSubject.value) {
      return;
    }
    if (this.loginResponse.value === 'DEFAULT') {
      this.loginResponse.next('LOADING');
      this.isLoadingSubject.next(true);
      this.store.dispatch({
        type: AuthenticationActionTypes.loginType,
        payload: { email: this.f.get('email').value, password: this.f.get('password').value }
      });
      this.actions$.pipe(ofType(AuthenticationActionTypes.loginSuccessType, AuthenticationActionTypes.loginErrorType), take(1)).subscribe(
        (action: Action) => {
          if (action.type === AuthenticationActionTypes.loginErrorType) {
            this.loginResponse.next('FAILURE');
            setTimeout(() => {
              this.loginResponse.next('DEFAULT');
            }, 2500);
            switch (action['response']['message']) {
              case HttpErrorResponseMessage.loginWrongCredentials:
                this.alertService.error(action['response']['message']);
                break;
              case HttpErrorResponseMessage.loginAccessDenied:
                this.alertService.warning('ALERT_ACCESS_DENIED');
                break;
              case HttpErrorResponseMessage.loginTooManyAttempts:
                this.alertService.error(action['response']['message']);
                this.isWaitingSubject.next(true);
                break;
              default:
                this.alertService.error(action['response']['message']);
                break;
            }
          } else {
            this.loginResponse.next('SUCCESS');
            setTimeout(() => {
              this.loginResponse.next('DEFAULT');
            }, 2500);
            this.router.navigate(['/home']);
          }
          this.isLoadingSubject.next(false);
        },
        error => {
          console.error(error);
          this.isLoadingSubject.next(false);
          this.loginResponse.next('FAILURE');
          setTimeout(() => {
            this.loginResponse.next('DEFAULT');
          }, 2500);
        },
        () => {
          this.isLoadingSubject.next(false);
          setTimeout(() => {
            this.loginResponse.next('DEFAULT');
          }, 2500);
        }
      );
    }
  }

  public requestPasswordReset(): void {
    this.isSubmittedResetSubject.next(true);
    if (this.passwordResetForm.invalid) {
      return;
    }
    this.passwordResetResponse.next('LOADING');
    this.isLoadingResetSubject.next(true);

    this.authenticationStore.requestPasswordReset(this.g.get('email').value);
    this.requestPasswordResetResponse$
      .pipe(
        filter(value => !!value),
        take(1)
      )
      .subscribe((value: any) => {
        if (value instanceof HttpResponse) {
          this.passwordResetResponse.next('SUCCESS');
        } else {
          this.passwordResetResponse.next('FAILURE');
        }
        setTimeout(() => {
          this.passwordResetResponse.next('DEFAULT');
          this.isLoadingResetSubject.next(false);
        }, 2500);
      });
  }

  public toggleIsPasswordResetInstruction(): void {
    this.isPasswordResetInstructionSubject.next(!this.isPasswordResetInstructionSubject.value);
    this.passwordResetResponse.next('DEFAULT');
  }

  public togglePasswordVisibility(): void {
    this.fieldTextType = this.fieldTextType === 'password' ? 'text' : 'password';
  }

  public changeLanguage(): void {
    this.translateService.use(this.selectedLanguage);
    localStorage.setItem('language', this.selectedLanguage);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
