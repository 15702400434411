<button [attr.id]="'btnDialogClose'" mat-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title>{{'admin-dashboard.create_study' | translate}}</h1>
<div mat-dialog-content class="mat-typography">
  <div class="container text-start mt-3">
    <!-- Form -->
    <div class="text-center mb-3">
      <p><strong>{{'admin-dashboard.form_create_study' | translate}}</strong></p>
    </div>

    <!-- Title and description -->
    <div class="form-group">
      <strong>{{'admin-dashboard.form_create_study_name_description' | translate}}</strong>
    </div>

    <form [formGroup]="groupFormType">
      <div class="form-container">
        <mat-form-field appearance="outline" class="half-width">
          <mat-label>{{'group-creation.form_create_group_name' | translate}}</mat-label>
          <input [attr.id]="'name'" formControlName="name" matInput type="text" required />
          <div *ngIf="f.name.invalid">
            <mat-error *ngIf="f.name.errors?.required">{{'group-creation.error_is_required' | translate}}</mat-error>
            <mat-error *ngIf="f.name.errors?.maxlength">{{'group-creation.error_max_255_characters' | translate}}</mat-error>
          </div>
        </mat-form-field>
      </div>

      <hr />

      <div>
        <!-- Privacy settings for groups -->
        <div class="mb-3">
          <div class="form-group">
            <strong>{{'admin-dashboard.form_create_study_privacy_description' | translate}}</strong>
          </div>
          <div class="my-3">
            <mat-radio-group [attr.id]="'radioIsPrivate'" aria-label="select an option" formControlName="is_private">
              <mat-radio-button [attr.id]="'radioItem0'" (change)="showIsPrivate($event)" value="0"
                >{{'group-creation.no' | translate}}</mat-radio-button
              >
              <mat-radio-button [attr.id]="'radioItem1'" (change)="showIsPrivate($event)" value="1"
                >{{'group-creation.yes' | translate}}</mat-radio-button
              >
            </mat-radio-group>
          </div>
          <div *ngIf="(selectedIsPrivate$ | async) === '1'" class="row">
            <div class="col-sm-12 col-4">
              <mat-form-field appearance="outline">
                <mat-label>{{'group-creation.form_create_group_access_type' | translate}}</mat-label>
                <mat-select
                  [attr.id]="'selectionAccessType'"
                  (selectionChange)="showAccesstype(groupFormType.value.accesstype)"
                  formControlName="accesstype"
                  required
                >
                  <mat-option [attr.id]="'option_' + type" *ngFor="let type of accessType" [value]="type"
                    >{{('group-creation.' + type.toLowerCase()) | translate}}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-sm-12 col-8">
              <mat-form-field *ngIf="((selectedAccesstype$ | async) === 'PASSWORD')" appearance="outline" class="full-width">
                <mat-label>{{'group-creation.password' | translate}}</mat-label>
                <input [attr.id]="'password'" formControlName="password" matInput type="text" />
                <div *ngIf="f.password.invalid">
                  <mat-error *ngIf="f.password.errors?.required || f.password.errors?.minLength"
                    >{{'group-creation.error_is_required' | translate}}</mat-error
                  >
                  <mat-error *ngIf="f.password.errors?.maxlength">{{'group-creation.error_max_255_characters' | translate}}</mat-error>
                </div>
              </mat-form-field>
            </div>
          </div>

          <hr />
        </div>

        <div *ngIf="(globalRole$ | async) === 'admin'">
          <div class="mb-3">
            <div class="form-group">
              <div></div>
              <ng-template #thenShowStudy>
                <strong>{{'group-creation.form_create_study_owner_description' | translate}}</strong>
              </ng-template>
            </div>
            <div class="form-group row">
              <div class="col-sm-8">
                <mat-form-field appearance="outline">
                  <mat-label>{{'group-creation.form_select_owner' | translate}}</mat-label>
                  <mat-select [attr.id]="'selectionOwnerId'" (click)="resetFilter()" formControlName="owner_id" required>
                    <mat-form-field class="full-width" appearance="fill">
                      <mat-label>{{'group-creation.filter_selection' | translate}}</mat-label>
                      <input
                        [attr.id]="'userSelection'"
                        autocomplete="off"
                        matInput
                        (keyup)="onKeyFilter()"
                        [(ngModel)]="filter['userSelection']"
                        [ngModelOptions]="{standalone: true}"
                      />
                    </mat-form-field>
                    <mat-option [attr.id]="'option_' + owner.id" *ngFor="let owner of (allECoachesAsAdmin$ | async)" [value]="owner.id"
                      >{{owner?.attributes.email | translate}}</mat-option
                    >
                    <mat-option *ngIf="(allECoachesAsAdmin$ | async).length < 1"
                      >{{'group-creation.no_ecoach_available' | translate}}</mat-option
                    >
                  </mat-select>
                  <mat-error *ngIf="(f.owner_id.value < 0)">{{'group-creation.error_selection_needed' | translate}}</mat-error>
                </mat-form-field>

                <div class="form-group">
                  <strong>{{'group-creation.select_organisation_description' | translate}}</strong>
                </div>
                <div class="form-group row">
                  <div class="col-sm-8">
                    <mat-form-field appearance="outline">
                      <mat-label>{{'group-creation.select_organisation_label' | translate}}</mat-label>
                      <mat-select [attr.id]="'selectionOrganisation'" (click)="resetFilter()" [(value)]="selectedOrganisation">
                        <mat-form-field class="full-width" appearance="fill">
                          <mat-label>{{'group-creation.filter_selection_organisation' | translate}}</mat-label>
                          <input
                            [attr.id]="'organisationSelection'"
                            autocomplete="off"
                            matInput
                            (keyup)="onKeyFilterOrganisation()"
                            [(ngModel)]="filter['organisationSelection']"
                            [ngModelOptions]="{standalone: true}"
                          />
                        </mat-form-field>
                        <mat-option [attr.id]="'option_' + org.id" *ngFor="let org of (organisations$ | async)" [value]="org"
                          >{{org?.attributes.name | translate}}</mat-option
                        >
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </div>
        </div>
      </div>

      <!-- Set group picture -->
      <div
        *ngIf="((((isECoach$ | async) && ((globalRole$ | async) === 'ecoach')) || ((isECoach$ | async) && ((globalRole$ | async) === 'admin') && isOwnerAndECoach())))"
      >
        <div class="form-group">
          <strong>{{'group-creation.form_create_set_group_picture' | translate}}</strong>
        </div>

        <div class="form-group row justify-content-center">
          <div class="image-preview mb-3">
            <img [src]="displayImagePreview()" (error)="helper.getFallbackImage($event)" />
          </div>
          <div *ngIf="fileUploadProgress" class="progress form-group">
            <div [style.width.%]="fileUploadProgress" class="progress-bar progress-bar-striped bg-success" role="progressbar"></div>
          </div>
        </div>

        <!-- Upload group picture -->
        <div class="mt-2 mb-2 ml-2 mr-2">
          <div class="row">
            <div class="custom-file form-group col-12 col-sm-8 mb-2">
              <input
                [attr.id]="'image'"
                #fileInputPicture
                (change)="processFile($event)"
                accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml"
                class="custom-file-input"
                id="customFileStudyMedia"
                name="image"
                type="file"
              />
              <label
                [attr.data-browse]="('group-creation.browse' | translate)"
                class="custom-file-label text-start"
                for="customFileStudyMedia"
                >{{uploadedFilePath}}</label
              >
            </div>
            <div class="form-group col-12 col-sm-4 mb-2">
              <button [attr.id]="'btnClearImage'" (click)="clearImage()" class="spacing-right" mat-button>
                {{'group-creation.clear' | translate}}
              </button>
            </div>
          </div>
        </div>

        <hr />
      </div>

      <!-- Title and description -->
      <div
        *ngIf="((((isECoach$ | async) && ((globalRole$ | async) === 'ecoach')) || ((isECoach$ | async) && ((globalRole$ | async) === 'admin') && isOwnerAndECoach())))"
      >
        <div class="form-group">
          <strong>{{'group-creation.form_create_group_translations_description' | translate}}</strong>
        </div>
        <div class="row">
          <!-- Filter button -->
          <div class="col-sm-6">
            <div>
              <mat-form-field appearance="outline" class="text-width">
                <mat-label>{{'group-creation.form_create_group_title_german' | translate}}</mat-label>
                <input [attr.id]="'deTitle'" formControlName="deTitle" matInput type="text" />
                <div *ngIf="f.deTitle.invalid">
                  <mat-error *ngIf="f.deTitle.errors?.required">{{'group-creation.error_is_required' | translate}}</mat-error>
                  <mat-error *ngIf="f.deTitle.errors?.maxlength">{{'group-creation.error_max_255_characters' | translate}}</mat-error>
                </div>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="outline" class="text-width">
                <mat-label>{{'group-creation.form_create_group_description_german' | translate}}</mat-label>
                <textarea [attr.id]="'deDescription'" formControlName="deDescription" matInput maxlength="255" rows="3"></textarea>
                <div *ngIf="f.deDescription.invalid">
                  <mat-error *ngIf="f.deDescription.errors?.required">{{'group-creation.error_is_required' | translate}}</mat-error>
                  <mat-error *ngIf="f.deDescription.errors?.maxlength">{{'group-creation.error_max_255_characters' | translate}}</mat-error>
                </div>
              </mat-form-field>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <mat-form-field appearance="outline" class="text-width">
                <mat-label>{{'group-creation.form_create_group_title_english' | translate}}</mat-label>
                <input [attr.id]="'enTitle'" formControlName="enTitle" matInput type="text" />
                <div *ngIf="f.enTitle.invalid">
                  <mat-error *ngIf="f.enTitle.errors?.required">{{'group-creation.error_is_required' | translate}}</mat-error>
                  <mat-error *ngIf="f.enTitle.errors?.maxlength">{{'group-creation.error_max_255_characters' | translate}}</mat-error>
                </div>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="outline" class="text-width">
                <mat-label>{{'group-creation.form_create_group_description_english' | translate}}</mat-label>
                <textarea [attr.id]="'enDescription'" formControlName="enDescription" matInput maxlength="255" rows="3"></textarea>
                <div *ngIf="f.enDescription.invalid">
                  <mat-error *ngIf="f.enDescription.errors?.required">{{'group-creation.error_is_required' | translate}}</mat-error>
                  <mat-error *ngIf="f.enDescription.errors?.maxlength">{{'group-creation.error_max_255_characters' | translate}}</mat-error>
                </div>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button [attr.id]="'btnCancel'" mat-button mat-dialog-close>{{'admin-dashboard.cancel' | translate}}</button>

  <app-button-feedback
    [_setId]="'btnCreateGroup'"
    (click)="createGroup()"
    [_isDisabled]="(f.owner_id.value === -1) || f.name.invalid"
    [_defaultButtonText]="'admin-dashboard.create_new_study'"
    [_responseText]="createStudyResponse | async"
  ></app-button-feedback>
</div>
