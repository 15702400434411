<div *ngIf="(evaluation$ | async)" [class.required]="required" [hidden]="hidden$ | async">
  <!-- New date, datetime & time -->
  <div class="row justify-content-center">
    <div class="col-12 question">
      <span>{{questionSubject | async}}</span>
    </div>
    <div class="col-12 centered">
      <mat-form-field class="full-width" appearance="outline" *ngIf="type === 'datetime'">
        <input
          matInput
          [ngxMatDatetimePicker]="picker"
          [(ngModel)]="answer"
          (ngModelChange)="onChange($event)"
          [min]="minDate"
          [disabled]="isDisabled"
          readonly
        />
        <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker
          #picker
          [showSpinners]="showSpinners"
          [showSeconds]="showSeconds"
          [stepHour]="stepHour"
          [stepMinute]="stepMinute"
          [stepSecond]="stepSecond"
          [touchUi]="touchUi"
          [color]="color"
          [enableMeridian]="enableMeridian"
        >
        </ngx-mat-datetime-picker>
      </mat-form-field>
      <mat-form-field class="full-width" appearance="outline" *ngIf="type === 'date'">
        <input matInput [disabled]="isDisabled" [matDatepicker]="picker" [(ngModel)]="answer" (ngModelChange)="onChange($event)" readonly />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker [color]="color"> </mat-datepicker>
      </mat-form-field>
      <mat-form-field class="full-width" appearance="outline" *ngIf="type === 'time'">
        <input
          matInput
          [disabled]="isDisabled"
          name="selected_time"
          [format]="24"
          [(ngModel)]="answer"
          (ngModelChange)="onChange($event)"
          [ngxMatTimepicker]="picker"
          placeholder="12:00"
          readonly
        />
        <ngx-mat-timepicker [cancelBtnTmpl]="cancelBtnTmpl" [confirmBtnTmpl]="confirmBtnTmpl" #picker></ngx-mat-timepicker>
        <mat-icon *ngIf="!isDisabled" matSuffix (click)="picker.open()"> watch_later </mat-icon>
      </mat-form-field>
    </div>
  </div>

  <div *ngIf="noAnswerProvided$ | async" class="text-danger mb-2">
    <small>{{'question-text-date.no_answer_has_been_submitted' | translate}}</small>
  </div>
</div>

<ng-template #confirmBtnTmpl>
  <button mat-button color="primary">{{'question-text-date.confirm' | translate}}</button>
</ng-template>

<ng-template #cancelBtnTmpl>
  <button mat-button>{{'question-text-date.cancel' | translate}}</button>
</ng-template>
