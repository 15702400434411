import { Action, createAction, props } from '@ngrx/store';
import { MessageThreadsInterface } from 'src/app/models/interface/message_threads.interface';

export enum MessageActionTypes {
  addAllMessageThreadsStoreType = 'Add All Message Threads Store',
  updateAllMessageThreadssStoreType = 'Update All Message Threads Store',
  deleteAllMessageThreadsStoreType = 'Delete All Message Threads Store',

  getAllMessagesThreadsType = '[Message API] Get All Messages Threads',
  getAllMessagesThreadsSuccessType = '[Success] Get All Messages Threads',
  getAllMessagesThreadsErrorType = '[Error] Get All Messages Threads'
}

export const addAllMessageThreadsStore = createAction(
  MessageActionTypes.addAllMessageThreadsStoreType,
  props<{ allMessageThreads: Array<MessageThreadsInterface> }>()
);
export const updateAllMessageThreadsStore = createAction(
  MessageActionTypes.updateAllMessageThreadssStoreType,
  props<{ allMessageThreads: Array<MessageThreadsInterface> }>()
);
export const removeAllMessageThreadsStore = createAction(MessageActionTypes.deleteAllMessageThreadsStoreType);

export class GetAllMessagesThreads implements Action {
  readonly type: string = MessageActionTypes.getAllMessagesThreadsType;
  constructor(readonly payload: any) {}
}
