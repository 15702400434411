<!-- Text component for lection/question elements in ionic app -->
<div
  *ngIf="(evaluation$ | async)"
  [hidden]="hidden$ | async"
  [ngClass]="{'textImportant': element.type === 'important', 'textInfo': element.type === 'info', 'textTip': element.type === 'tip', 'textSuccess': element.type === 'success', 'textHighlight': element.type === 'highlight'}"
  class="question-text question-border"
>
  <div ngSwitch="{{element.type}}">
    <div *ngSwitchCase="'important'" class="row">
      <div class="col-2">
        <fa-icon [icon]="faExclamationCircle" class="fa-2x"></fa-icon>
      </div>
      <div class="col-10">
        <div *ngIf="headline !== ''" [innerHTML]="(showHeadline | async) | sanitizeHtml" class="headline"></div>
        <div [innerHTML]="(showText | async) | sanitizeHtml" class="text text-start"></div>
      </div>
    </div>

    <div *ngSwitchCase="'info'" class="row">
      <div class="col-2">
        <fa-icon [icon]="faInfoCircle" class="fa-2x"></fa-icon>
      </div>
      <div class="col-10">
        <div *ngIf="headline !== ''" [innerHTML]="(showHeadline | async) | sanitizeHtml" class="headline"></div>
        <div [innerHTML]="(showText | async) | sanitizeHtml" class="text text-start"></div>
      </div>
    </div>

    <div *ngSwitchCase="'tip'" class="row">
      <div class="col-2">
        <fa-icon [icon]="faLightBulb" class="fa-2x"></fa-icon>
      </div>
      <div class="col-10">
        <div *ngIf="headline !== ''" [innerHTML]="(showHeadline | async) | sanitizeHtml" class="headline"></div>
        <div [innerHTML]="(showText | async) | sanitizeHtml" class="text text-start"></div>
      </div>
    </div>

    <div *ngSwitchCase="'success'" class="row">
      <div class="col-2">
        <fa-icon [icon]="faCheck" class="fa-2x"></fa-icon>
      </div>
      <div class="col-10">
        <div *ngIf="headline !== ''" [innerHTML]="(showHeadline | async) | sanitizeHtml" class="headline"></div>
        <div [innerHTML]="(showText | async) | sanitizeHtml" class="text text-start"></div>
      </div>
    </div>

    <div *ngSwitchCase="'none'">
      <div *ngIf="headline !== ''" [innerHTML]="(showHeadline | async) | sanitizeHtml" class="headline"></div>
      <div [innerHTML]="(showText | async) | sanitizeHtml" class="text text-start"></div>
    </div>

    <div *ngSwitchCase="''">
      <div *ngIf="headline !== ''" [innerHTML]="(showHeadline | async) | sanitizeHtml" class="headline"></div>
      <div [innerHTML]="(showText | async) | sanitizeHtml" class="text text-start"></div>
    </div>

    <div *ngSwitchCase="'highlight'">
      <div *ngIf="headline !== ''" [innerHTML]="(showHeadline | async) | sanitizeHtml" class="headline"></div>
      <div [innerHTML]="(showText | async) | sanitizeHtml" class="text text-start"></div>
    </div>

    <div *ngSwitchCase="'table'">
      <div *ngIf="headline !== ''" [innerHTML]="(showHeadline | async) | sanitizeHtml" class="headline"></div>
      <div [innerHTML]="(showText | async) | sanitizeHtml" class="table-responsive"></div>
    </div>
  </div>
</div>
