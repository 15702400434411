<button [attr.id]="'btnDialogClose'" mat-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title>{{'admin-study-organisation.assign_study_modal_title' | translate}}</h1>
<div mat-dialog-content class="mat-typography">
  <p>{{'admin-study-organisation.assign_study_modal_body' | translate}}</p>

  <mat-form-field class="full-width">
    <mat-label>{{'admin-study-organisation.select_organisation' | translate}}</mat-label>
    <mat-select [attr.id]="'selectionOrganisation'" (click)="resetFilter()" [(ngModel)]="selectedOrganisation">
      <mat-form-field class="full-width" appearance="fill">
        <mat-label>{{'admin-study-organisation.filter_selection' | translate}}</mat-label>
        <input
          [attr.id]="'organisationSelection'"
          autocomplete="off"
          matInput
          (keyup)="onKeyFilter()"
          [(ngModel)]="filter['organisationSelection']"
        />
      </mat-form-field>
      <mat-option [attr.id]="'option_' + organisation.id" *ngFor="let organisation of (organisations$ | async)" [value]="organisation"
        >{{organisation?.attributes.name}} - (ID {{organisation?.id}})
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-card appearance="outlined" class="card mx-auto my-3">
    <mat-card-header class="card-header-center">
      <mat-card-title>
        <div>{{((study.attributes.name) ? study.attributes.name : 'group.no_group_name') | translate}}</div>
      </mat-card-title>
      <mat-card-subtitle>
        {{((study.attributes.title) ? study.attributes.title : 'group.no_group_title') | translate}} (ID - {{study.id}})
      </mat-card-subtitle>
    </mat-card-header>
    <div class="row justify-content-center">
      <img
        [src]="helper.getAllMediaSupportPath(study.attributes.picture, study.id)"
        (error)="helper.getFallbackImage($event)"
        alt="No image"
        class="img-fluid card-img-top col-6"
        mat-card-image
      />
    </div>
    <mat-card-content class="text-center">
      <p>{{((study.attributes.description) ? study.attributes.description : 'group.no_group_description') | translate}}</p>

      <p>
        <span *ngIf="(study.attributes.type === 'study')"><fa-icon [icon]="faUsers"></fa-icon> {{'group.study' | translate}}</span>
        <span *ngIf="(study.attributes.type === 'organisation')"
          ><fa-icon [icon]="faSitemap"></fa-icon> {{'group.organisation' | translate}}</span
        >
        <span *ngIf="(study.attributes.type === 'workgroup')"
          ><fa-icon [icon]="faBriefcase"></fa-icon> {{'group.workgroup' | translate}}</span
        >
        /
        <span *ngIf="(study.attributes.is_private === 1); else openBlock" class="text-padding">
          <span *ngIf="(study.attributes.accesstype === 'invite')">
            <fa-icon [icon]="faEnvelopeOpenText"></fa-icon>
            {{'group.invitations_only' | translate}}
          </span>
          <span *ngIf="(study.attributes.accesstype === 'password')">
            <fa-icon [icon]="faKey"></fa-icon>
            {{'group.password_only' | translate}}
          </span>
          <span *ngIf="(study.attributes.accesstype !== 'password') && (study.attributes.accesstype !== 'invite')"
            >{{'group.private' | translate}}
          </span>
        </span>
        <ng-template #openBlock> {{'group.public' | translate}} </ng-template>
      </p>
    </mat-card-content>
  </mat-card>
</div>
<div mat-dialog-actions align="end">
  <button [attr.id]="'btnCancel'" mat-button mat-dialog-close>{{'admin-study-organisation.cancel' | translate}}</button>
  <app-button-feedback
    [_setId]="'btnAssignStudyToOrganisation'"
    (click)="assignStudyToOrganisation()"
    [_defaultButtonText]="'admin-study-organisation.assign_study_to_organisation'"
    [_responseText]="assignStudyToOrganisationResponse | async"
  ></app-button-feedback>
</div>
