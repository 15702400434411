<button [attr.id]="'btnDialogClose'" mat-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title>{{'admin-dashboard.delete_account' | translate}}</h1>
<div mat-dialog-content class="mat-typography">
  <p>{{'admin-dashboard.delete_account_description' | translate}}: <strong>{{user.attributes.email}}</strong></p>
</div>
<div mat-dialog-actions align="end">
  <button [attr.id]="'btnCancel'" mat-button mat-dialog-close>{{'admin-dashboard.cancel' | translate}}</button>
  <app-button-feedback
    [_setId]="'btnDeleteAccount'"
    (click)="deleteAccount()"
    [_defaultButtonText]="'admin-dashboard.delete_account_confirm'"
    [_responseText]="deleteAccountResponse | async"
  ></app-button-feedback>
</div>
