import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { catchError, filter, mergeMap, skip, take } from 'rxjs/operators';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons/faCheckSquare';
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar';
import { Store } from '@ngrx/store';
import { HelperService } from '../../../services/helper/helper.service';
import { RoleInterface } from '../../../models/interface/role.interface';
import { UserInterface } from '../../../models/interface/user.interface';
import { OrganisationInterface } from '../../../models/interface/organisation/organisation.interface';
import { OrganisationActionTypes } from '../../../store/organisation/organisation.action';
import { ProfileInterface } from '../../../models/interface/profile.interface';

@Component({
  selector: 'app-organisation',
  templateUrl: './organisation.component.html',
  styleUrls: ['./organisation.component.scss']
})
export class OrganisationComponent implements OnInit, OnDestroy, OnChanges {
  @Input() reloadEvent = '';

  @ViewChild('paginator') paginator;

  // Icons
  faCheckSquare = faCheckSquare;
  faStar = faStar;

  // Loading status
  public isLoading$: Observable<boolean>;

  // Filter
  public filter = { searchFilter: '' };

  public pagedOrganisations: Observable<Array<OrganisationInterface>>;
  public role$: Observable<string>;
  public isCollapsed$: Observable<boolean>;

  public profile$: Observable<ProfileInterface>;
  public profileRoles$: Observable<Array<RoleInterface>>;
  public organisations$: Observable<Array<OrganisationInterface>>;
  public organisationsSubject: BehaviorSubject<Array<OrganisationInterface>> = new BehaviorSubject([]);
  public organisationsCollaborators$: Observable<Array<UserInterface>>;

  // Data from Profile Store
  private myProfile: ProfileInterface;

  // Data provided by OrganisationService
  private initialOrganisations: Array<OrganisationInterface>;

  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private searchTextSubject = new BehaviorSubject<string>('');

  private pagedOrganisationsSubject: BehaviorSubject<Array<OrganisationInterface>> = new BehaviorSubject<Array<OrganisationInterface>>([]);
  private roleSubject: BehaviorSubject<string> = new BehaviorSubject<string>('ecoach');

  // Filter toggle
  private isCollapsedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  // Subscription Handler
  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private helperService: HelperService,
    private store: Store<{
      myProfile: ProfileInterface;
      myRoles: Array<RoleInterface>;
      organisations: Array<OrganisationInterface>;
      organisationsCollaborators: Array<UserInterface>;
    }>
  ) {
    this.organisations$ = store.select('organisations');
    this.organisationsCollaborators$ = store.select('organisationsCollaborators');
    this.profile$ = store.select('myProfile');
    this.profileRoles$ = store.select('myRoles');
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.isCollapsed$ = this.isCollapsedSubject.asObservable();
    this.pagedOrganisations = this.pagedOrganisationsSubject.asObservable();
    this.role$ = this.roleSubject.asObservable();
  }

  // Helper function
  public get helper() {
    return this.helperService;
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.profile$
        .pipe(
          filter(user => user !== null),
          mergeMap((result: ProfileInterface) => {
            this.myProfile = result;
            return this.profileRoles$.pipe(
              filter(roles => roles !== null),
              take(1)
            );
          })
        )
        .subscribe((resp: Array<RoleInterface>) => {
          if (this.helperService.hasRoles(resp, /admin$/)) {
            this.roleSubject.next('admin');
          } else {
            if (this.helperService.hasRoles(resp, /(ecoachmanager|ecoach)$/)) {
              this.roleSubject.next('ecoach');
            }
          }
          this.applyOrganisationFilter();
        })
    );

    // Subscription to language change
    this.subscriptions.push(
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
        this.applyOrganisationFilter();
      })
    );
  }

  public applyOrganisationFilter(): void {
    this.isLoadingSubject.next(true);
    this.subscriptions.push(
      this.reloadOrganisationList(true).subscribe((result: any) => {
        this.isLoadingSubject.next(false);
      })
    );
  }

  public reloadOrganisationList(setFirst?: boolean): Observable<boolean> {
    const pageIndex = this.paginator?.paginator ? this.paginator?.paginator.pageIndex : 0;
    const pageSize = this.paginator?.paginator ? this.paginator?.paginator.pageSize : 20;
    if (this.roleSubject.value === 'admin') {
      this.store.dispatch({ type: OrganisationActionTypes.getOrganisationsAdminType, payload: { include: 'owners,collaborators' } });
    } else {
      this.store.dispatch({ type: OrganisationActionTypes.getOrganisationsType, payload: { include: 'owners,collaborators' } });
    }

    return this.organisations$.pipe(
      skip(1),
      take(1),
      mergeMap(result => {
        this.initialOrganisations = result;
        return this.helper
          .searchOrganisationsByInput(
            this.filter['searchFilter'],
            this.searchTextSubject,
            this.initialOrganisations,
            this.organisationsSubject
          )
          .pipe(
            mergeMap(() =>
              this.helper.setPagedContent(this.organisationsSubject, this.pagedOrganisationsSubject, setFirst, pageIndex, pageSize)
            )
          );
      }),
      catchError(error => {
        console.error(error);
        this.initialOrganisations = [];
        return this.helper
          .searchOrganisationsByInput(
            this.filter['searchFilter'],
            this.searchTextSubject,
            this.initialOrganisations,
            this.organisationsSubject
          )
          .pipe(
            mergeMap(() =>
              this.helper.setPagedContent(this.organisationsSubject, this.pagedOrganisationsSubject, setFirst, pageIndex, pageSize)
            )
          );
      })
    );
  }

  public updateList(): void {
    this.subscriptions.push(this.reloadOrganisationList().subscribe());
  }

  public isCollabId(organisation: OrganisationInterface): boolean {
    return !!organisation.relationships.collaborators.data.find(
      (user: UserInterface) => user.id.toString() === this.myProfile.id.toString()
    );
  }

  /*
    Navigates to detailed interventions
   */
  public getDetailedStudy(id: number): void {
    this.router.navigateByUrl(`/groups/${id}`);
  }

  public getDetailedOrganisation(id: number): void {
    this.router.navigateByUrl(`organisations/${id}/studies`);
  }

  public getOwnersOfOrganisation(organisation: OrganisationInterface): Array<UserInterface> {
    return organisation.relationships.owners.data;
  }

  public updatePagedOrganisations(event: any) {
    if (event) {
      this.pagedOrganisationsSubject.next(event);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.reloadEvent.currentValue) {
      this.applyOrganisationFilter();
    }
  }
}
