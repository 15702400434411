import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { BuddyInstanceInterface } from 'src/app/models/interface/buddy/buddy-instance.interface';
import { UserInterface } from 'src/app/models/interface/user.interface';
import { HelperDialogService } from 'src/app/services/helper/helper-dialog/helper-dialog.service';
import { HelperService } from 'src/app/services/helper/helper.service';
import { BuddyStore } from 'src/app/store/buddy/component-store/buddy.store';
import { DialogBuddyInstanceCreateComponent } from '../dialog-buddy-instance-create/dialog-buddy-instance-create.component';

@Component({
  selector: 'app-dialog-buddy-activity-report',
  templateUrl: './dialog-buddy-activity-report.component.html',
  styleUrls: ['./dialog-buddy-activity-report.component.scss'],
  providers: [BuddyStore]
})
export class DialogBuddyActivityReportComponent implements OnInit, OnDestroy {
  // Buddy activity form submission
  public buddyActivityForm: UntypedFormGroup;

  public getCSVBuddyActivitiesByBuddyIdResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');
  public getCSVBuddyActivitiesByStudyIdResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');

  public members$: Observable<Array<UserInterface>>;

  // Ngx datetimepicker
  public showSpinners = false;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';

  public studyId: number;
  public members: Array<UserInterface> = [];
  public buddyInstance: BuddyInstanceInterface;

  public filter = {
    userSelection: ''
  };

  private getCSVBuddyActivitiesByBuddyIdResponse$: Observable<any>;

  private getCSVBuddyActivitiesByStudyIdResponse$: Observable<any>;

  private membersSubject: BehaviorSubject<Array<UserInterface>> = new BehaviorSubject<Array<UserInterface>>([]);

  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private helperService: HelperService,
    private dialogRef: MatDialogRef<DialogBuddyInstanceCreateComponent>,
    private buddyStore: BuddyStore
  ) {
    if (this.data.studyId) {
      this.studyId = this.data.studyId;
    }
    if (this.data.members) {
      this.members = this.data.members;
    }
    if (this.data.buddyInstance) {
      this.buddyInstance = this.data.buddyInstance;
    }
    this.buddyActivityForm = new UntypedFormGroup({
      startTime: new UntypedFormControl(''),
      endTime: new UntypedFormControl(''),
      userId: new UntypedFormControl(-1)
    });

    this.getCSVBuddyActivitiesByBuddyIdResponse$ = this.buddyStore.getCSVBuddyActivitiesByBuddyIdResponse$;
    this.getCSVBuddyActivitiesByStudyIdResponse$ = this.buddyStore.getCSVBuddyActivitiesByStudyIdResponse$;
    this.members$ = this.membersSubject.asObservable();
  }

  public get f() {
    return this.buddyActivityForm;
  }

  ngOnInit(): void {
    if (this.studyId) {
      this.membersSubject.next(this.members);
    }
    if (this.buddyInstance) {
      this.membersSubject.next(
        this.members.filter((user: UserInterface) =>
          this.buddyInstance.attributes.buddies_ids.map(id => parseInt(id.toString(), 10)).includes(user.id)
        )
      );
    }
  }

  public resetAllFilter(): void {
    this.f.get('startTime').patchValue('');
    this.f.get('endTime').patchValue('');
    this.f.get('userId').patchValue(-1);
  }

  public resetFilter(): void {
    this.filter = {
      userSelection: ''
    };
  }

  public onKeyFilter(): void {
    const filterResults: Array<UserInterface> = this.members.filter(
      (user: UserInterface) =>
        user.attributes.name?.toLowerCase().includes(this.filter['userSelection'].toLowerCase()) ||
        user.attributes.email.toLowerCase().includes(this.filter['userSelection'].toLowerCase())
    );
    this.membersSubject.next(filterResults);
  }

  public generateCSVBuddyActivitiesReportByBuddyId(): void {
    const startTime =
      this.f.get('startTime').value && this.f.get('startTime').value !== ''
        ? this.helperService.convertStringToUnixTimestamp(this.f.get('startTime').value)
        : undefined;
    const endTime =
      this.f.get('endTime').value && this.f.get('endTime').value !== ''
        ? this.helperService.convertStringToUnixTimestamp(this.f.get('endTime').value)
        : undefined;
    const userId = this.f.get('userId').value && this.f.get('userId').value !== -1 ? this.f.get('userId').value : undefined;

    if (this.getCSVBuddyActivitiesByBuddyIdResponse.value === 'DEFAULT') {
      this.getCSVBuddyActivitiesByBuddyIdResponse.next('LOADING');

      this.buddyStore.exportCSVBuddyActivitiesByBuddyId({ buddyId: this.buddyInstance.id, userId, startTime, endTime });
      this.subscriptions.push(
        this.getCSVBuddyActivitiesByBuddyIdResponse$.subscribe((value: any) => {
          if (value instanceof HttpErrorResponse) {
            this.getCSVBuddyActivitiesByBuddyIdResponse.next('FAILURE');
            setTimeout(() => {
              this.getCSVBuddyActivitiesByBuddyIdResponse.next('DEFAULT');
            }, 2500);
          } else {
            if (value.state && value.state === 'DONE') {
              this.getCSVBuddyActivitiesByBuddyIdResponse.next('SUCCESS');
              setTimeout(() => {
                this.getCSVBuddyActivitiesByBuddyIdResponse.next('DEFAULT');
              }, 2500);
            }
          }
        })
      );
    }
  }

  public generateCSVBuddyActivitiesReportByStudyId(): void {
    const startTime =
      this.f.get('startTime').value && this.f.get('startTime').value !== ''
        ? this.helperService.convertStringToUnixTimestamp(this.f.get('startTime').value)
        : undefined;
    const endTime =
      this.f.get('endTime').value && this.f.get('endTime').value !== ''
        ? this.helperService.convertStringToUnixTimestamp(this.f.get('endTime').value)
        : undefined;
    const userId = this.f.get('userId').value && this.f.get('userId').value !== -1 ? this.f.get('userId').value : undefined;

    if (this.getCSVBuddyActivitiesByStudyIdResponse.value === 'DEFAULT') {
      this.getCSVBuddyActivitiesByStudyIdResponse.next('LOADING');

      this.buddyStore.exportCSVBuddyActivitiesByStudyId({ studyId: this.studyId, userId, startTime, endTime });
      this.subscriptions.push(
        this.getCSVBuddyActivitiesByStudyIdResponse$.subscribe((value: any) => {
          if (value instanceof HttpErrorResponse) {
            this.getCSVBuddyActivitiesByStudyIdResponse.next('FAILURE');
            setTimeout(() => {
              this.getCSVBuddyActivitiesByStudyIdResponse.next('DEFAULT');
            }, 2500);
          } else {
            if (value.state && value.state === 'DONE') {
              this.getCSVBuddyActivitiesByStudyIdResponse.next('SUCCESS');
              setTimeout(() => {
                this.getCSVBuddyActivitiesByStudyIdResponse.next('DEFAULT');
              }, 2500);
            }
          }
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
