<mat-card [attr.id]="'organisation_' + organisation.id" appearance="outlined" class="mx-auto mb-3">
  <mat-card-header>
    <mat-card-title-group>
      <mat-card-title>
        {{((organisation.attributes.name) ? organisation.attributes.name : 'organisation.no_organisation_name') | translate}}
      </mat-card-title>
      <mat-card-subtitle> (Organisation-ID - {{organisation.id}}) </mat-card-subtitle>
    </mat-card-title-group>
  </mat-card-header>
  <mat-card-content>
    <div class="my-2">
      <p *ngIf="isCollab">
        <span>
          {{'organisation.joined' | translate}}
          <fa-icon [icon]="faCheckSquare"></fa-icon>
        </span>
      </p>

      <p class="text-start">
        <fa-icon [icon]="faStar"></fa-icon>
        <strong>{{'organisation.owner' | translate}}:</strong> {{getOwnersOfOrganisation(organisation)[0]?.attributes.email}}
      </p>
    </div>
    <mat-divider></mat-divider>
  </mat-card-content>
  <mat-card-actions [align]="'end'">
    <div *ngIf="(isAdmin$ | async); then thenShowAdmin else elseShowDefault"></div>
    <ng-template #elseShowDefault></ng-template>
    <div *ngIf="isCollab">
      <button
        [attr.id]="'btnGetDetailedOrganisation_' + organisation.id"
        (click)="getDetailedOrganisation(organisation.id)"
        class="mb-2"
        mat-button
      >
        <mat-label>{{'organisation.to_organisation' | translate}}</mat-label>
      </button>
    </div>

    <ng-template #thenShowAdmin>
      <button (click)="openDialogOrganisationDetail()" mat-button>
        <fa-icon [icon]="faInfoCircle"></fa-icon> {{'organisation.details' | translate}}
      </button>

      <button (click)="openDialogOrganisationUpdate()" mat-button>
        <fa-icon [icon]="faPen"></fa-icon> {{'organisation.edit' | translate}}
      </button>

      <button (click)="openDialogOrganisationDelete()" mat-button>
        <fa-icon [icon]="faTrash"></fa-icon> {{'organisation.delete' | translate}}
      </button>
    </ng-template>
  </mat-card-actions>
</mat-card>
