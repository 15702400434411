<!-- Yes-No question component for lection/question elements in ionic app -->
<div *ngIf="(evaluation$ | async)" [class.required]="required" [hidden]="hidden$ | async" class="question">
  <ion-label class="ion-text-wrap">{{questionSubject | async}}</ion-label>
  <div>
    <ion-button
      #yesButton
      [class.selectedButton]="answer === true"
      (click)="answerQuestion(true)"
      [disabled]="isDisabled || (noAnswerProvided$ | async)"
      >{{'question-yes-no.yes' | translate}}</ion-button
    >
    <ion-button
      #noButton
      (click)="answerQuestion(false)"
      [class.selectedButton]="answer === false"
      [disabled]="isDisabled || (noAnswerProvided$ | async)"
      >{{'question-yes-no.no' | translate}}</ion-button
    >
  </div>
  <div *ngIf="noAnswerProvided$ | async" class="text-danger mb-2">
    <small>{{'question-yes-no.no_answer_has_been_submitted' | translate}}</small>
  </div>
</div>
