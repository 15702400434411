import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

/*
  Show/remove default preview content from parent component
 */
export class LessonSharedService {
  public sourceId$: Observable<number>;
  public isPreviewSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private sourceIdSubject = new BehaviorSubject<number>(null);

  constructor() {
    this.sourceId$ = this.sourceIdSubject.asObservable();
  }

  pushIsPreview(value: boolean) {
    this.isPreviewSubject.next(value);
  }

  pushId(id: number) {
    this.sourceIdSubject.next(id);
  }
}
