/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { RequestBodyData } from '../../../models/request-body.data';
import { download } from '../../../helpers/download/download';
import { HelperService } from '../../helper/helper.service';

@Injectable({
  providedIn: 'root'
})
export class BuddyService {
  public header: HttpHeaders = new HttpHeaders();
  private backendUrl: string = environment.backendURL;

  constructor(private http: HttpClient, private helperService: HelperService) {
    this.header = this.header.set('Content-Type', 'application/json');
    this.header = this.helperService.setLocaleFromStorage(this.header);
  }

  /**
   * AAS2 API ECoach - Connect two users
   * This function connects two users and creates a buddy pair.
   * @params number buddyId1 - A number of the user id.
   *         number interventionInstanceId_1 - A number of the intervention instance id that belongs to buddyId1.
   *         number diaryInstanceId1 - A number of the diary instance id that belongs to buddyId1.
   *         number buddyId2 - A number of the user id.
   *         number interventionInstanceId2 - A number of the intervention instance id that belongs to buddyId2.
   *         number diaryInstanceId-2 - A number of the diary instance id that belongs to buddyId2.
   *         number studyId - A number of the study id.
   * @return Observable<any> - An observable for any response.
   */
  public connectBuddies(
    buddyId1: number,
    interventionInstanceId1: number,
    diaryInstanceId1: number,
    buddyId2: number,
    interventionInstanceId2: number,
    diaryInstanceId2: number,
    studyId: number,
    language?: string
  ): Observable<any> {
    this.header = language ? this.header.set('Accept-Language', language) : this.helperService.setLocaleFromStorage(this.header);
    const requestBody = new RequestBodyData('intervention_instance', {
      buddies: [
        { buddy_id: buddyId1, intervention_instance_id: interventionInstanceId1, diary_instance_id: diaryInstanceId1 },
        { buddy_id: buddyId2, intervention_instance_id: interventionInstanceId2, diary_instance_id: diaryInstanceId2 }
      ]
    });
    return this.http.post<any>(`${this.backendUrl}/api/v1/ecoach/studies/${studyId}/buddies`, requestBody, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API ECoach - Show buddies of an intervention
   * This function returns buddies connections of an intervention.
   * @params number studyId - A number of the study id.
   * @return Observable<any> - An observable for any response.
   */
  public getAllBuddiesOfStudy(studyId: number): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoach/studies/${studyId}/buddies?limit=0`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API ECoach Manager - Show buddies of an intervention
   * This function returns buddies connections of an intervention.
   * @params number studyId - A number of the study id.
   * @return Observable<any> - An observable for any response.
   */
  public getAllBuddiesOfStudyEM(studyId: number): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoachmanager/studies/${studyId}/buddies?limit=0`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API ECoach - Delete a buddy connection
   * This function deletes a specific buddy connection.
   * @params number buddyConnectionId - A number of the buddy connection id.
   * @return Observable<any> - An observable for any response.
   */
  public deleteBuddy(buddyConnectionId: number): Observable<any> {
    return this.http.delete<any>(`${this.backendUrl}/api/v1/ecoach/studies/buddies/${buddyConnectionId}`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API ECoach - Show buddy instance/connection
   * This function returns a specific buddy connection.
   * @params number buddyConnectionId - A number of the buddy connection id.
   * @return Observable<any> - An observable for any response.
   */
  public getBuddy(buddyConnectionId: number): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoach/studies/buddies/${buddyConnectionId}`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API ECoach - Show eCoach buddy instances
   * This function returns all buddies connections of the eCoach.
   * @return Observable<any> - An observable for any response.
   */
  public getAllBuddiesOfECoach(): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoach/buddies?limit=0`, { headers: this.header, observe: 'response' });
  }

  /**
   * AAS2 API ECoach - Get the activities of a buddy instance
   * This function returns all activities of a specific buddy connection.
   * @params number buddyConnectionId - A number of the buddy connection id.
   * @return Observable<any> - An observable for any response.
   */
  public getBuddyActivities(buddyConnectionId: number): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoach/buddies/${buddyConnectionId}/activities?limit=0`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API ECoach Manager - Get the activities of a buddy instance
   * This function returns all activities of a specific buddy connection.
   * @params number buddyConnectionId - A number of the buddy connection id.
   * @return Observable<any> - An observable for any response.
   */
  public getBuddyActivitiesEM(buddyConnectionId: number): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoachmanager/buddies/${buddyConnectionId}/activities?limit=0`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API ECoach - Update a buddy instance
   * This function updates a buddy pair.
   * @params number buddyId1 - A number of the user id.
   *         number interventionInstanceId1 - A number of the intervention instance id that belongs to buddyId1.
   *         number diaryInstanceId1 - A number of the diary instance id that belongs to buddyId1.
   *         number buddyId2 - A number of the user id.
   *         number interventionInstanceId2 - A number of the intervention instance id that belongs to buddyId2.
   *         number diaryInstanceId2 - A number of the diary instance id that belongs to buddyId2.
   *         number buddyConnectionId - A number of the buddy connection id.
   * @return Observable<any> - An observable for any response.
   */
  public updateBuddy(
    buddyId1: number,
    interventionInstanceId1: number,
    diaryInstanceId1: number,
    buddyId2: number,
    interventionInstanceId2: number,
    diaryInstanceId2: number,
    buddyConnectionId: number
  ): Observable<any> {
    const requestBody = new RequestBodyData('intervention_instance', {
      buddies: [
        { buddy_id: buddyId1, intervention_instance_id: interventionInstanceId1, diary_instance_id: diaryInstanceId1 },
        { buddy_id: buddyId2, intervention_instance_id: interventionInstanceId2, diary_instance_id: diaryInstanceId2 }
      ]
    });
    return this.http.patch<any>(`${this.backendUrl}/api/v1/ecoach/studies/buddies/${buddyConnectionId}`, requestBody, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API ECoach - Swap users in buddy instances
   * This function swaps users in buddy instances.
   * @params number buddyId1 - A number of the target buddyId with active users.
   *         number active_user_id1 - ID of the user who remains in the active buddy instance.
   *         number inactive_user_id1 - ID of the user who gets swapped to the inactive buddy instance.
   *         number buddyId2 - A number of the target buddyId with inactive users.
   *         number active_user_id2 - ID of the user who gets swapped to the active buddy instance.
   *         number inactive_user_id2 - ID of the user who gets remains in the inactive buddy instance.
   * @return Observable<any> - An observable for any response.
   */
  public swapBuddy(
    buddyId1: number,
    activeUserId1: number,
    inactiveUserId1: number,
    buddyId2: number,
    activeUserId2: number,
    inactiveUserId2: number
  ): Observable<any> {
    const requestBody = new RequestBodyData('intervention_instance', {
      buddies: [
        { buddy_id: buddyId1, active_user_id: activeUserId1, inactive_user_id: inactiveUserId1, active: true },
        { buddy_id: buddyId2, active_user_id: activeUserId2, inactive_user_id: inactiveUserId2 }
      ]
    });
    return this.http.post<any>(`${this.backendUrl}/api/v1/ecoach/buddies/swap`, requestBody, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API eCoach - Get inactive (and active) buddy feature users in the past x days
   * This function returns all users who were active/inactive in the past x days.
   * @params number studyId - A number of the study id.
   *         boolean isActive - A boolean flag if collection is active/inactive
   *         number days - A number of days.
   * @return Observable<any> - An observable for any response.
   */
  public getBuddyByLastActivity(studyId: number, isActive: boolean, days: number): Observable<any> {
    let params = new HttpParams();
    params = params.set('active_users', isActive);
    params = params.set('last_activity_since', days);
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoach/studies/${studyId}/buddies/usage?limit=0`, {
      headers: this.header,
      observe: 'response',
      params
    });
  }

  /**
   * AAS2 API eCoach - Generate csv report of inactive (and active) buddy feature users in the past x days
   * This function returns a csv report of all users who were active/inactive in the past x days.
   * @params number studyId - A number of the study id.
   *         boolean isActive - A boolean flag if collection is active/inactive
   *         number days - A number of days.
   * @return Observable<any> - An observable for any response.
   */
  public getCSVBuddyByLastActivity(studyId: number, isActive: boolean, days: number): Observable<any> {
    this.header = this.header.set('Content-Type', '*/*');
    this.header = this.header.set('Accept', '*/*');
    let params = new HttpParams();
    params = params.set('active_users', isActive);
    params = params.set('last_activity_since', days);
    let downloaded = false;
    return this.http
      .get<any>(`${this.backendUrl}/api/v1/ecoach/studies/${studyId}/buddies/usage/csv/report`, {
        headers: this.header,
        reportProgress: true,
        observe: 'events',
        params,
        responseType: 'blob' as 'json'
      })
      .pipe(
        download(jsonResponse => {
          const getFileName = (response: HttpResponse<any>) => {
            let fileName: string;
            try {
              const contentDisposition: string = response.headers.get('content-disposition');
              const r = /filename="(.+)"/;
              fileName = r.exec(contentDisposition)[1];
            } catch (error) {
              fileName = `inactive-buddyreport-${days}days-${new Date().toISOString()}.csv`;
            }
            return fileName;
          };

          const filename = `inactive-buddyreport-${days}days-${new Date().toISOString()}.csv`;
          const binaryData = [];
          binaryData.push(jsonResponse);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/csv' }));
          downloadLink.setAttribute('download', filename);
          document.body.appendChild(downloadLink);
          if (!downloaded) {
            downloadLink.click();
            URL.revokeObjectURL(downloadLink.href);
            downloaded = true;
          }
        })
      );
  }

  /**
   * AAS2 API eCoach - Generate csv report of all buddy activities of a buddy instance
   * This function returns a csv report of all buddy activities of a buddy instance.
   * @params number buddyId - A number of the buddy instance id.
   *         number userId - A number of the user id to filter the buddy activities for this specific user.
   *         number startTime - A unix timetamp to help filter the buddy activities by created_at for a specific range with start time.
   *         number endTime - A unix timestamp to help filter the buddy activities by created_at for a specific range with end time.
   * @return Observable<any> - An observable for any response.
   */
  public getCSVBuddyActivitiesByBuddyId(buddyId: number, userId?: number, startTime?: number, endTime?: number): Observable<any> {
    this.header = this.header.set('Content-Type', '*/*');
    this.header = this.header.set('Accept', '*/*');
    let params = new HttpParams();
    if (userId !== undefined) {
      params = params.set('userID', userId);
    }
    if (startTime !== undefined) {
      params = params.set('startTime', startTime);
    }
    if (endTime !== undefined) {
      params = params.set('endTime', endTime);
    }
    let downloaded = false;
    return this.http
      .get<any>(`${this.backendUrl}/api/v1/ecoach/buddies/${buddyId}/activities/csv/report`, {
        headers: this.header,
        reportProgress: true,
        observe: 'events',
        params,
        responseType: 'blob' as 'json'
      })
      .pipe(
        download(jsonResponse => {
          const getFileName = (response: HttpResponse<any>) => {
            let fileName: string;
            try {
              const contentDisposition: string = response.headers.get('content-disposition');
              const r = /filename="(.+)"/;
              fileName = r.exec(contentDisposition)[1];
            } catch (error) {
              fileName = `buddyActivities-buddyId${buddyId}-${new Date().toISOString()}.csv`;
            }
            return fileName;
          };

          const filename = `buddyActivities-buddyId${buddyId}-${new Date().toISOString()}.csv`;
          const binaryData = [];
          binaryData.push(jsonResponse);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/csv' }));
          downloadLink.setAttribute('download', filename);
          document.body.appendChild(downloadLink);
          if (!downloaded) {
            downloadLink.click();
            URL.revokeObjectURL(downloadLink.href);
            downloaded = true;
          }
        })
      );
  }

  /**
   * AAS2 API eCoach - Generate csv report of all buddy activities of a study
   * This function returns a csv report of all buddy activities of a study.
   * @params number study - A number of the study id.
   *         number userId - A number of the user id to filter the buddy activities for this specific user.
   *         number startTime - A unix timetamp to help filter the buddy activities by created_at for a specific range with start time.
   *         number endTime - A unix timestamp to help filter the buddy activities by created_at for a specific range with end time.
   * @return Observable<any> - An observable for any response.
   */
  public getCSVBuddyActivitiesByStudyId(studyId: number, userId?: number, startTime?: number, endTime?: number): Observable<any> {
    this.header = this.header.set('Content-Type', '*/*');
    this.header = this.header.set('Accept', '*/*');
    let params = new HttpParams();
    if (userId !== undefined) {
      params = params.set('userID', userId);
    }
    if (startTime !== undefined) {
      params = params.set('startTime', startTime);
    }
    if (endTime !== undefined) {
      params = params.set('endTime', endTime);
    }
    params = params.set('deletedBuddies', 'true');
    let downloaded = false;
    return this.http
      .get<any>(`${this.backendUrl}/api/v1/ecoach/studies/${studyId}/buddies/activities/csv/report`, {
        headers: this.header,
        reportProgress: true,
        observe: 'events',
        params,
        responseType: 'blob' as 'json'
      })
      .pipe(
        download(jsonResponse => {
          const getFileName = (response: HttpResponse<any>) => {
            let fileName: string;
            try {
              const contentDisposition: string = response.headers.get('content-disposition');
              const r = /filename="(.+)"/;
              fileName = r.exec(contentDisposition)[1];
            } catch (error) {
              fileName = `buddyActivities-studyId${studyId}-${new Date().toISOString()}.csv`;
            }
            return fileName;
          };

          const filename = `buddyActivities-studyId${studyId}-${new Date().toISOString()}.csv`;
          const binaryData = [];
          binaryData.push(jsonResponse);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/csv' }));
          downloadLink.setAttribute('download', filename);
          document.body.appendChild(downloadLink);
          if (!downloaded) {
            downloadLink.click();
            URL.revokeObjectURL(downloadLink.href);
            downloaded = true;
          }
        })
      );
  }

  /**
   * AAS2 API eCoach manager - Get all buddy swapping/update activities of study
   * This function returns all buddy activities from the study.
   * @params number studyId - A number of the study id.
   * @return Observable<any> - An observable for any response.
   */
  public getAllBuddyActivitiesStudyEM(studyId: number): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoachmanager/studies/${studyId}/buddies/logs?limit=0`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API eCoach - Get all buddy swapping/update activities of assigned buddy instances in study
   * This function returns all buddy activities of assigned buddy instances in study.
   * @params number studyId - A number of the study id.
   * @return Observable<any> - An observable for any response.
   */
  public getAllBuddyActivitiesStudy(studyId: number): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoach/studies/${studyId}/buddies/logs?limit=0`, {
      headers: this.header,
      observe: 'response'
    });
  }
}
