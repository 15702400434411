import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { iif, Observable, of } from 'rxjs';
import { RoleInterface } from '../../models/interface/role.interface';
import { catchError, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ProfileActionTypes } from '../../../app/store/profile/profile.action';
import { HelperService } from 'src/app/services/helper/helper.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalRoleGuard implements CanActivate {
  private profileRoles$: Observable<Array<RoleInterface>>;

  constructor(private router: Router, private store: Store<{ myRoles: Array<RoleInterface> }>, private helperService: HelperService) {
    this.profileRoles$ = this.store.select('myRoles');
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.store.dispatch({ type: ProfileActionTypes.getProfileRolesType });
    return this.profileRoles$.pipe(
      switchMap((roles: Array<RoleInterface>) =>
        iif(() => this.helperService.hasRoles(roles, /(ecoach|ecoachmanager|admin)$/), of(true), of(this.router.parseUrl('/home')))
      ),
      catchError(() => of(this.router.parseUrl('/home')))
    );
  }
}
