import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { skip, take } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { PayloadInterface } from '../../../models/interface/payload.interface';
import { RequestBodyData } from '../../../models/request-body.data';
import { HelperService } from '../../../services/helper/helper.service';
import { UserInterface } from '../../../models/interface/user.interface';
import { OrganisationStore } from '../../../store/organisation/component-store/organisation.store';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-dialog-organisation-collaborator-add',
  templateUrl: './dialog-organisation-collaborator-add.component.html',
  styleUrls: ['./dialog-organisation-collaborator-add.component.scss'],
  providers: [OrganisationStore]
})
export class DialogOrganisationCollaboratorAddComponent implements OnInit, OnDestroy {
  public collaborator: UserInterface;
  public param;
  public organisationId: number;
  public collaborators: Array<UserInterface>;
  public ecoaches: Array<UserInterface>;

  // Filter
  public filter = {
    userSelection: ''
  };

  public ecoachesSubject: BehaviorSubject<Array<UserInterface>> = new BehaviorSubject<Array<UserInterface>>([]);
  public ecoaches$: Observable<Array<UserInterface>> = this.ecoachesSubject.asObservable();

  // List of roles
  public roles = ['organisation.manager', 'organisation.access'];
  public selectedRole: string;

  // Selected collaborator
  public selectedCollaborator: UserInterface;

  public addCollaboratorResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');

  private addOrganisationCollaboratorResponse$: Observable<any>;

  // Subscription Handler
  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private helperService: HelperService,
    private organisationStore: OrganisationStore,
    private dialogRef: MatDialogRef<DialogOrganisationCollaboratorAddComponent>
  ) {
    this.addOrganisationCollaboratorResponse$ = this.organisationStore.addOrganisationCollaboratorResponse$;
  }

  // Helper function
  public get helper() {
    return this.helperService;
  }

  ngOnInit(): void {
    this.collaborator = this.data.collaborator;
    this.param = this.data.param;
    this.organisationId = this.data.organisationId;
    this.ecoaches = this.data.ecoaches;

    if (this.ecoaches.length > 0) {
      this.selectedCollaborator = this.ecoaches[0];
    }
  }

  public isNoneSelectedAddCollaboratorForm(): boolean {
    return !this.selectedCollaborator && !this.selectedRole;
  }

  // Adding collaborator to organisation
  public addCollaborator(): void {
    if (this.addCollaboratorResponse.value === 'DEFAULT') {
      if (this.selectedCollaborator && this.selectedRole) {
        const addRoles: Array<{ id: number; role: string }> = [];
        addRoles.push({ id: this.selectedCollaborator.id, role: this.selectedRole });
        const payload: PayloadInterface = new RequestBodyData('organisations', { collaborators: addRoles });

        this.addCollaboratorResponse.next('LOADING');
        this.organisationStore.addOrganisationCollaborator({
          organisationId: this.organisationId,
          payload
        });

        this.subscriptions.push(
          this.addOrganisationCollaboratorResponse$.pipe(skip(1), take(1)).subscribe((result: any) => {
            if (result instanceof HttpResponse) {
              this.addCollaboratorResponse.next('SUCCESS');
              setTimeout(() => {
                this.addCollaboratorResponse.next('DEFAULT');
                this.dialogRef.close('SUCCESS');
              }, 2500);
            } else {
              this.addCollaboratorResponse.next('FAILURE');
              setTimeout(() => {
                this.addCollaboratorResponse.next('DEFAULT');
              }, 2500);
            }
          })
        );
      }
    }
  }

  public resetFilter(): void {
    this.filter = {
      userSelection: ''
    };
    this.ecoachesSubject.next(this.ecoaches);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
