import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { HttpErrorResponseMessage } from 'src/app/enum/http-error-response-message';
import { InterventionInstanceService } from '../../services/api/intervention-instance/intervention-instance.service';
import { ResponseError } from '../utils/response-error';
import { ResponseSuccess } from '../utils/response-success';
import { addAllInstancesForECoachStore, GetAllInstancesForECoach, InterventionInstanceActionTypes } from './intervention-instance.action';

@Injectable()
export class InterventionInstanceEffects {
  getAllInstancesForECoach$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InterventionInstanceActionTypes.getAllInstancesForECoachType),
      map((action: GetAllInstancesForECoach) => action.payload),
      switchMap((payload: any) =>
        this.interventionInstanceService
          .getAllInstancesForECoach(payload.patientId, payload.interventionId, payload.interventionType, payload.pendingInvitation)
          .pipe(
            switchMap(result => {
              const actions = [];
              actions.push(addAllInstancesForECoachStore({ allInstancesForECoach: result.body.data }));
              actions.push(new ResponseSuccess(InterventionInstanceActionTypes.getAllInstancesForECoachSuccessType, result));
              return actions;
            }),
            catchError(err =>
              of(
                new ResponseError(InterventionInstanceActionTypes.getAllInstancesForECoachErrorType, {
                  message: HttpErrorResponseMessage.genericUnknownError,
                  content: err
                })
              )
            )
          )
      )
    )
  );

  constructor(private actions$: Actions, private interventionInstanceService: InterventionInstanceService) {}
}
