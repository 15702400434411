import { Action, createAction, props } from '@ngrx/store';
import { JobResultInterface } from '../../models/interface/jobresult.interface';

export enum QueueActionTypes {
  addAllJobResultsStoreType = 'Add All Job Results Store',
  updateAllJobResultsStoreType = 'Update All Job Results Store',
  deleteAllJobResultsStoreType = 'Remove All Job Results Store',

  getAllJobResultsOfUserType = '[Queue API] Get All Job Results of a User',
  getAllJobResultsOfUserSuccessType = '[Success] Get All Job Results of a User',
  getAllJobResultsOfUserErrorType = '[Error] Get All Job Results of a User'
}

export const addAllJobResultsStore = createAction(
  QueueActionTypes.addAllJobResultsStoreType,
  props<{ allJobResults: Array<JobResultInterface> }>()
);

export const updateAllJobResultsStore = createAction(
  QueueActionTypes.updateAllJobResultsStoreType,
  props<{ allJobResults: Array<JobResultInterface> }>()
);

export const removeAllJobResultsStore = createAction(QueueActionTypes.deleteAllJobResultsStoreType);

export class GetAllJobResultsOfUser implements Action {
  readonly type: string = QueueActionTypes.getAllJobResultsOfUserType;
  constructor(readonly payload: any) {}
}

export class GetAllJobResultsOfUserSuccess implements Action {
  readonly type: string = QueueActionTypes.getAllJobResultsOfUserSuccessType;
  constructor(readonly response: any) {}
}

export class GetAllJobResultsOfUserError implements Action {
  readonly type: string = QueueActionTypes.getAllJobResultsOfUserErrorType;
  constructor(readonly response: any) {}
}
