import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-email-language-selection',
  templateUrl: './email-language-selection.component.html',
  styleUrls: ['./email-language-selection.component.scss']
})
export class EmailLanguageSelectionComponent {
  @Output()
  public emitLanguageSelection: EventEmitter<any> = new EventEmitter<any>();

  languages = ['en', 'de', 'ca', 'es', 'fr', 'ro'];

  public selectedLanguage = 'de';

  constructor() {}

  @Input()
  set _initialLanguage(_initialLanguage: string) {
    if (_initialLanguage !== 'undefined' && _initialLanguage !== undefined) {
      this.selectedLanguage = _initialLanguage;
    }
  }

  public changeSelection(): void {
    this.emitLanguageSelection.emit(this.selectedLanguage);
  }
}
