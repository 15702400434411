<div class="container-fluid text-center">
  <div class="col-sm-8 text-start">
    <h2>{{ 'group-intervention-review.unpublished_interventions' | translate }}</h2>
    <hr />
  </div>

  <div class="row">
    <div class="col-12 col-sm-3 mb-2">
      <button [attr.id]="'btnToggleFilter'" (click)="helper.toggleSubject(isCollapseSubject)" class="mt-1" mat-raised-button type="submit">
        <fa-icon [icon]="faList"></fa-icon>
        Filter
      </button>
    </div>

    <div class="col-12 col-sm-6 mb-2">
      <mat-form-field [style.width.%]="100" appearance="outline">
        <mat-label>{{'group-intervention-review.search_intervention' | translate}}</mat-label>
        <input
          [attr.id]="'search'"
          (keyup.enter)="applyInterventionFilter()"
          [(ngModel)]="this.filter['searchFilter']"
          aria-describedby="interventionSearch"
          aria-label="Search"
          matInput
          type="text"
        />
        <button [attr.id]="'btnSearchIntervention'" (click)="applyInterventionFilter()" aria-label="Search" mat-button mat-button matSuffix>
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>

  <app-pagination-list
    #paginator
    (emitPageChange)="updatePagedInterventions($event)"
    [_isLoading]="isLoading$ | async"
    [_items]="interventions$ | async"
  ></app-pagination-list>

  <div class="row justify-content-center">
    <div
      *ngIf="(isCollapse$ | async) === false"
      [ngClass]="{'col-12': (isCollapse$ | async) === false, 'col-md-3': (isCollapse$ | async) === false, 'col-align-self-start': (isCollapse$ | async) === false}"
      class="text-start"
    >
      <div>
        <label id="filter-radio-group-label">{{'group-intervention-review.type_intervention' | translate}}</label>
        <mat-radio-group
          [attr.id]="'radioTypeOfIntervention'"
          aria-label="select guidance type"
          aria-labelledby="filter-radio-group-label"
          class="filter-radio-group"
          [(ngModel)]="filter['typeOfIntervention']"
          (change)="applyInterventionFilter()"
        >
          <mat-radio-button [attr.id]="'radioItem3'" class="filter-radio-button" value="3"
            >{{'group-intervention-review.no_selection' | translate}}</mat-radio-button
          >
          <mat-radio-button [attr.id]="'radioItem2'" class="filter-radio-button" value="2"
            >{{'group-intervention-review.guided' | translate}}</mat-radio-button
          >
          <mat-radio-button [attr.id]="'radioItem1'" class="filter-radio-button" value="1"
            >{{'group-intervention-review.unguided' | translate}}</mat-radio-button
          >
        </mat-radio-group>
      </div>
    </div>

    <div *ngIf="(isLoading$ | async); else elseInterventionBlock" class="col-sm">
      <app-loader></app-loader>
    </div>

    <ng-template #elseInterventionBlock>
      <div class="col-12 col-md-9">
        <div *ngFor="let intervention of pagedInterventions$ | async; let i = index; trackBy: helper.trackByElement">
          <app-card-intervention
            (emitPublishingPerformed)="updateList()"
            (emitDeletionPerformed)="updateList()"
            [_intervention]="intervention"
            [_isCollab]="true"
            [_isPublisher]="isPublisher$ | async"
            [_isManager]="isManager$ | async"
            [_showStudyButton]="false"
          ></app-card-intervention>
        </div>
      </div>
    </ng-template>
  </div>
</div>
