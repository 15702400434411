import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faRedo } from '@fortawesome/free-solid-svg-icons/faRedo';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt';
import { catchError, distinctUntilChanged, filter, mergeMap, skip, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { PatientCreationOverviewComponent } from '../patient-creation-overview/patient-creation-overview.component';
import { PatientCreationInstanceCreationComponent } from '../patient-creation-instance-creation/patient-creation-instance-creation.component';
import { InvitationInterface } from '../../../models/interface/invitation.interface';
import { HelperService } from '../../../services/helper/helper.service';
import { UserInterface } from '../../../models/interface/user.interface';
import { ProfileInterface } from '../../../models/interface/profile.interface';
import { HelperDialogService } from '../../../services/helper/helper-dialog/helper-dialog.service';
import { UserActionTypes } from '../../../store/user/user.action';
import { AuthenticationStore } from '../../../store/authentication/component-store/authentication.store';
import { faList } from '@fortawesome/free-solid-svg-icons/faList';

/**
 * Component:
 * Patient page displaying all patients of the eCoach;
 * Can be found: {uri}/patients
 */

@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.scss'],
  providers: [AuthenticationStore]
})
export class PatientComponent implements OnInit, OnDestroy {
  @ViewChild('paginator') paginator;

  @ViewChild('patientCreationOverview') patientCreationOverview: PatientCreationOverviewComponent;
  @ViewChild('assignInterventionContainer', { read: ViewContainerRef }) assignInterventionContainer: ViewContainerRef;

  @ViewChild('inviteStudyContainer', { read: ViewContainerRef }) inviteStudyContainer: ViewContainerRef;

  public patientCreationInstanceComponentClass = PatientCreationInstanceCreationComponent;

  // Icons
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;
  faEnvelope = faEnvelope;
  faCheckCircle = faCheckCircle;
  faTimesCircle = faTimesCircle;
  faInfoCircle = faInfoCircle;
  faTrashAlt = faTrashAlt;
  faRedo = faRedo;
  faList = faList;

  // Filter
  public filter = { searchFilter: '', selectedSort: '', selectedVerified: 'all_verification_states' };

  public isLoading$: Observable<boolean>;
  public isCollapsed$: Observable<boolean>;

  // StudyForm - study invitation
  public studyForm: UntypedFormGroup;
  public searchTextSubject = new BehaviorSubject<string>('');

  public patients$: Observable<Array<UserInterface>>;
  public pagedPatients$: Observable<Array<UserInterface>>;

  public pendingInvitations: Array<InvitationInterface> = [];
  public verifiedSubject: BehaviorSubject<Array<boolean>> = new BehaviorSubject<Array<boolean>>(new Array(20).fill(false));

  public profile$: Observable<ProfileInterface>;

  public resendVerificationResponse$: Observable<any>;
  public isCollapsedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  public sortSelection: Array<string> = ['email_ascending', 'email_descending'];

  public filterVerified: Array<string> = ['all_verification_states', 'is_verified', 'is_unverified'];

  private myPatients: Array<UserInterface> = [];
  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private patientsSubject: BehaviorSubject<Array<UserInterface>> = new BehaviorSubject([]);
  private pagedPatientsSubject: BehaviorSubject<Array<UserInterface>> = new BehaviorSubject<Array<UserInterface>>([]);

  private myMembers$: Observable<Array<UserInterface>>;
  private allInvitations$: Observable<Array<InvitationInterface>>;
  private myRegisteredUsers$: Observable<Array<UserInterface>>;

  private subscriptions: Subscription[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private helperService: HelperService,
    private helperDialogService: HelperDialogService,
    private authenticationStore: AuthenticationStore,
    private store: Store<{
      myProfile: ProfileInterface;
      myMembers: Array<UserInterface>;
      allInvitations: Array<InvitationInterface>;
      myRegisteredUsers: Array<UserInterface>;
    }>
  ) {
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.isCollapsed$ = this.isCollapsedSubject.asObservable();
    this.patients$ = this.patientsSubject.asObservable();
    this.pagedPatients$ = this.pagedPatientsSubject.asObservable();
    this.profile$ = store.select('myProfile');

    this.studyForm = this.formBuilder.group({
      studyList: new UntypedFormArray([])
    });

    this.resendVerificationResponse$ = this.authenticationStore.resendVerificationResponse$;
    this.myMembers$ = this.store.select('myMembers');
    this.allInvitations$ = this.store.select('allInvitations');
    this.myRegisteredUsers$ = this.store.select('myRegisteredUsers');
  }

  public get helper() {
    return this.helperService;
  }

  public get helperDialog() {
    return this.helperDialogService;
  }

  ngOnInit(): void {
    // Get user's id
    this.subscriptions.push(
      this.profile$
        .pipe(
          filter(user => user !== null),
          take(1)
        )
        .subscribe((result: ProfileInterface) => {
          this.applyUserFilter();
        })
    );
  }

  public applyUserFilter(): void {
    this.isLoadingSubject.next(true);
    this.subscriptions.push(
      this.reloadUserList(true).subscribe((result: any) => {
        this.isLoadingSubject.next(false);
      })
    );
  }

  public reloadUserList(setFirst?: boolean): Observable<boolean> {
    const pageIndex = this.paginator?.paginator ? this.paginator?.paginator.pageIndex : 0;
    const pageSize = this.paginator?.paginator ? this.paginator?.paginator.pageSize : 20;
    this.store.dispatch({
      type: UserActionTypes.getAllInvitationsOfEcoachMembersType,
      payload: {}
    });
    return this.allInvitations$.pipe(
      skip(1),
      take(1),
      mergeMap(result => {
        this.pendingInvitations = result;
        this.store.dispatch({
          type: UserActionTypes.getUsersRegisteredByEcoachType,
          payload: {}
        });
        return this.myRegisteredUsers$.pipe(skip(1), take(1));
      }),
      mergeMap(result => {
        this.myPatients = result;
        this.store.dispatch({
          type: UserActionTypes.getMyMembersType,
          payload: {}
        });
        return this.myMembers$.pipe(skip(1), take(1));
      }),
      mergeMap(result => {
        this.myPatients = this.filterUsersBy(this.helper.setUniqueMembers(this.myPatients, result));
        return this.search(this.filter['searchFilter']).pipe(
          mergeMap(() => this.helper.setPagedContent(this.patientsSubject, this.pagedPatientsSubject, setFirst, pageIndex, pageSize))
        );
      }),
      catchError(() => {
        this.pendingInvitations = [];
        this.myPatients = [];
        return this.search(this.filter['searchFilter']).pipe(
          mergeMap(() => this.helper.setPagedContent(this.patientsSubject, this.pagedPatientsSubject, setFirst, pageIndex, pageSize))
        );
      })
    );
  }

  public updateList(): void {
    this.subscriptions.push(this.reloadUserList().subscribe());
  }

  public updateInvitations(): void {
    this.store.dispatch({
      type: UserActionTypes.getAllInvitationsOfEcoachMembersType,
      payload: {}
    });
    this.allInvitations$.pipe(skip(1), take(1)).subscribe(result => {
      this.pendingInvitations = result;
    });
  }

  public updatePagedPatients(event: any) {
    if (event) {
      this.pagedPatientsSubject.next(event);
      const currentPageSize = this.paginator?.paginator ? this.paginator?.paginator.pageSize : 20;
      this.verifiedSubject = new BehaviorSubject<Array<boolean>>(new Array(currentPageSize).fill(false));
    }
  }

  public reloadPage(event: string) {
    this.applyUserFilter();
  }

  public resetFilter(): void {
    this.filter = {
      selectedSort: '',
      selectedVerified: 'all_verification_states',
      searchFilter: ''
    };
    this.applyUserFilter();
  }

  public filterUsersBy(list: Array<UserInterface>): Array<UserInterface> {
    let users = list;

    const mapNullToString = attr => (attr !== null ? attr : '');

    if (this.filter['selectedSort'] !== null || this.filter['selectedSort'] !== '' || this.filter['selectedSort'] !== undefined) {
      switch (this.filter['selectedSort']) {
        case 'email_ascending': {
          users = users.sort((a, b) => mapNullToString(a.attributes.email).localeCompare(mapNullToString(b.attributes.email)));
          break;
        }
        case 'email_descending': {
          users = users.sort((a, b) => mapNullToString(b.attributes.email).localeCompare(mapNullToString(a.attributes.email)));
          break;
        }
        default: {
          break;
        }
      }

      if (this.filter['selectedVerified'] === 'is_verified') {
        users = users.filter((user: UserInterface) => user.attributes.is_verified.toString() === '1');
      }

      if (this.filter['selectedVerified'] === 'is_unverified') {
        users = users.filter((user: UserInterface) => user.attributes.is_verified.toString() === '0');
      }
    }

    return users;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  // Reads search value and runs searchFilter
  private search(name: string): Observable<boolean> {
    this.searchTextSubject.next(name);
    return this.searchTextSubject.pipe(
      distinctUntilChanged(),
      mergeMap(() => {
        this.patientsSubject.next(this.helper.filterUsersBy(this.myPatients, this.filter['searchFilter'].toLowerCase().trim()));
        this.verifiedSubject = new BehaviorSubject<Array<boolean>>(new Array(this.paginator ? this.paginator.pageSize : 20).fill(false));
        return of(true);
      })
    );
  }
}
