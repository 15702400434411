import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, filter, Observable, Subscription, take } from 'rxjs';
import { UserInterface } from '../../../models/interface/user.interface';
import { HelperService } from '../../../services/helper/helper.service';
import { HttpResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { StudyActionTypes } from '../../../store/study/study.action';
import { InterventionInstanceStore } from '../../../store/intervention-instance/component-store/intervention-instance.store';
import { getCollaboratorsByStudyId } from '../../../store/study/study.selector';

@Component({
  selector: 'app-dialog-instance-collaborator-add',
  templateUrl: './dialog-instance-collaborator-add.component.html',
  styleUrls: ['./dialog-instance-collaborator-add.component.scss'],
  providers: [InterventionInstanceStore]
})
export class DialogInstanceCollaboratorAddComponent implements OnInit, OnDestroy {
  // Filter
  public filter = {
    userCurrentSelection: '',
    userNewSelection: ''
  };

  public studyId: number;

  public studyCollaborators: Array<UserInterface> = [];
  public currentECoachesSubject: BehaviorSubject<Array<UserInterface>> = new BehaviorSubject<Array<UserInterface>>([]);
  public newECoachesSubject: BehaviorSubject<Array<UserInterface>> = new BehaviorSubject<Array<UserInterface>>([]);

  public currentECoach: UserInterface;
  public newECoach: UserInterface;

  public insertECoachResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');

  private addECoachToECoachInterventionInstancesOfStudyResponse$: Observable<any>;

  private collaboratorsOfStudy$: Observable<{ studyId: number; collaborators: UserInterface[] }>;

  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private helperService: HelperService,
    private dialogRef: MatDialogRef<DialogInstanceCollaboratorAddComponent>,
    private interventionInstanceStore: InterventionInstanceStore,
    private store: Store<{
      getCollaboratorsByStudyId: { studyId: number; collaborators: UserInterface[] };
    }>
  ) {
    this.addECoachToECoachInterventionInstancesOfStudyResponse$ =
      this.interventionInstanceStore.addECoachToECoachInterventionInstancesOfStudyResponse$;
  }

  public get helper() {
    return this.helperService;
  }

  ngOnInit(): void {
    this.studyId = this.data.studyId;
    this.store.dispatch({ type: StudyActionTypes.getCollaboratorsType, payload: { studyId: this.studyId, include: 'roles' } });
    this.collaboratorsOfStudy$ = this.store.select(getCollaboratorsByStudyId(this.studyId));
    this.subscriptions.push(
      this.collaboratorsOfStudy$
        .pipe(
          filter(collaboratorsOfStudy => !!collaboratorsOfStudy),
          take(1)
        )
        .subscribe((result: { studyId: number; collaborators: UserInterface[] }) => {
          const collaborators: Array<UserInterface> = result.collaborators;
          this.studyCollaborators = collaborators.filter((user: UserInterface) =>
            this.helperService.hasRoles(user.relationships.roles.data, /study\.(ecoach|ecoachmanager|owner)$/)
          );
          this.currentECoachesSubject.next(this.studyCollaborators);
          this.newECoachesSubject.next(this.studyCollaborators);
          this.currentECoach = this.studyCollaborators[0];
          this.newECoach = this.studyCollaborators[0];
        })
    );
  }

  // Add eCoach to eCoaches intervention instances of study
  public addECoachToECoachesInstanceStudy(): void {
    if (this.insertECoachResponse.value === 'DEFAULT') {
      this.insertECoachResponse.next('LOADING');
      this.interventionInstanceStore.addECoachToECoachInterventionInstancesOfStudy({
        studyId: this.studyId,
        currentECoach: this.currentECoach.id,
        newECoach: this.newECoach.id
      });
      this.subscriptions.push(
        this.addECoachToECoachInterventionInstancesOfStudyResponse$
          .pipe(
            filter(value => !!value),
            take(1)
          )
          .subscribe(
            (result: any) => {
              if (result instanceof HttpResponse) {
                this.insertECoachResponse.next('SUCCESS');
              } else {
                this.insertECoachResponse.next('FAILURE');
              }
              setTimeout(() => {
                this.dialogRef.close('SUCCESS');
                this.insertECoachResponse.next('DEFAULT');
              }, 2500);
            },
            () => {
              this.insertECoachResponse.next('FAILURE');
              setTimeout(() => {
                this.insertECoachResponse.next('DEFAULT');
              }, 2500);
            }
          )
      );
    }
  }

  public onKeyFilterECoaches(
    eCoachesSubject: BehaviorSubject<Array<UserInterface>>,
    selectedCollaborator: UserInterface,
    filtering: string
  ): void {
    const filterResults: Array<UserInterface> = this.studyCollaborators.filter(
      (user: UserInterface) =>
        this.helper.getCodeNameEmail(user.id, this.studyCollaborators).toLowerCase().includes(filtering.toLowerCase()) ||
        user.attributes.email.toLowerCase().includes(filtering.toLowerCase())
    );
    const altResult: Array<UserInterface> = selectedCollaborator ? [selectedCollaborator] : [];
    eCoachesSubject.next(filterResults.length !== 0 ? filterResults : altResult);
  }

  public resetFilter(): void {
    this.filter = {
      userCurrentSelection: '',
      userNewSelection: ''
    };
    this.currentECoachesSubject.next(this.studyCollaborators);
    this.newECoachesSubject.next(this.studyCollaborators);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
