import { createReducer, on } from '@ngrx/store';
import { InvitationInterface } from 'src/app/models/interface/invitation.interface';
import { UserInterface } from '../../models/interface/user.interface';
import {
  addUsersStore,
  updateUsersStore,
  removeUsersStore,
  addMyMembersStore,
  updateMyMembersStore,
  removeMyMembersStore,
  addAllECoachesStore,
  removeAllECoachesStore,
  updateAllECoachesStore,
  addAllInvitationsStore,
  removeAllInvitationsStore,
  updateAllInvitationsStore,
  addMyRegisteredUsersStore,
  updateMyRegisteredUsersStore,
  removeMyRegisteredUsersStore
} from '../../store/user/user.action';

export const initialUsersState: Array<UserInterface> = [];
export const usersReducer = createReducer(
  initialUsersState,
  on(addUsersStore, (state, { users }) => users),
  on(updateUsersStore, (state, { users }) => users),
  on(removeUsersStore, (state, {}) => [])
);

export const initialMyMembersState: Array<UserInterface> = [];
export const myMembersReducer = createReducer(
  initialMyMembersState,
  on(addMyMembersStore, (state, { myMembers }) => myMembers),
  on(updateMyMembersStore, (state, { myMembers }) => myMembers),
  on(removeMyMembersStore, (state, {}) => [])
);

export const initialAllEcoachesState: Array<UserInterface> = [];
export const allECoachesReducer = createReducer(
  initialAllEcoachesState,
  on(addAllECoachesStore, (state, { allECoaches }) => allECoaches),
  on(updateAllECoachesStore, (state, { allECoaches }) => allECoaches),
  on(removeAllECoachesStore, (state, {}) => [])
);

export const initialAllInvitationsState: Array<InvitationInterface> = [];
export const allInvitationsReducer = createReducer(
  initialAllInvitationsState,
  on(addAllInvitationsStore, (state, { allInvitations }) => allInvitations),
  on(updateAllInvitationsStore, (state, { allInvitations }) => allInvitations),
  on(removeAllInvitationsStore, (state, {}) => [])
);

export const initialMyRegisteredUsersState: Array<UserInterface> = [];
export const myRegisteredUsersReducer = createReducer(
  initialMyRegisteredUsersState,
  on(addMyRegisteredUsersStore, (state, { myRegisteredUsers }) => myRegisteredUsers),
  on(updateMyRegisteredUsersStore, (state, { myRegisteredUsers }) => myRegisteredUsers),
  on(removeMyRegisteredUsersStore, (state, {}) => [])
);
