<div class="container">
  <div *ngIf="studyMedia.length > 0, else elseNoPicturesFound">
    <ul [formGroup]="selectMediaForm">
      <li *ngFor="let media of studyMedia; let i = index">
        <input type="radio" [value]="media" formControlName="selected" [attr.id]="'cb' + i" />
        <label [attr.for]="'cb' + i">
          <img [src]="helper.getStudyMedia(media.attributes.url, studyId)" (error)="helper.getFallbackImage($event)" />
        </label>
      </li>
    </ul>
  </div>

  <ng-template #elseNoPicturesFound>
    <p>{{'group-configuration.no_pictures_found' | translate}}</p>
  </ng-template>
</div>
