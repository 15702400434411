import { Action, createAction, props } from '@ngrx/store';
import { InterventionInstanceInterface } from 'src/app/models/interface/intervention-instances/intervention-instance.interface';

export enum InterventionInstanceActionTypes {
  addAllInstancesForECoachStoreType = 'Add All Instances For ECoach Store',
  updateAllInstancesForECoachStoreType = 'Update All Instances For ECoach Store',
  deleteAllInstancesForECoachStoreType = 'Delete All Instances For ECoach Store',

  getAllInstancesForECoachType = '[Intervention Instance API] All Intervention Instances for a specific ecoach',
  getAllInstancesForECoachSuccessType = '[Success] All Intervention Instances for a specific ecoach',
  getAllInstancesForECoachErrorType = '[Error] All Intervention Instances for a specific ecoach'
}

export const addAllInstancesForECoachStore = createAction(
  InterventionInstanceActionTypes.addAllInstancesForECoachStoreType,
  props<{ allInstancesForECoach: Array<InterventionInstanceInterface> }>()
);
export const updateAllInstancesForECoachStore = createAction(
  InterventionInstanceActionTypes.updateAllInstancesForECoachStoreType,
  props<{ allInstancesForECoach: Array<InterventionInstanceInterface> }>()
);
export const removeAllInstancesForECoachStore = createAction(InterventionInstanceActionTypes.deleteAllInstancesForECoachStoreType);

export class GetAllInstancesForECoach implements Action {
  readonly type: string = InterventionInstanceActionTypes.getAllInstancesForECoachType;
  constructor(readonly payload: any) {}
}
