import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, of, catchError } from 'rxjs';
import { HttpErrorResponseMessage } from 'src/app/enum/http-error-response-message';
import { LessonQuestionnaireActionTypes, GetAllQuestionnaires, addAllQuestionnairesStore } from './lesson-questionnaire.action';
import { LessonQuestionnaireService } from '../../services/api/lesson-questionnaire/lesson-questionnaire.service';
import { ResponseSuccess } from '../utils/response-success';
import { ResponseError } from '../utils/response-error';

@Injectable()
export class LessonQuestionnaireEffects {
  getAllQuestionnaires$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LessonQuestionnaireActionTypes.getAllQuestionnairesType),
      map((action: GetAllQuestionnaires) => action.payload),
      switchMap((payload: any) =>
        this.lessonQuestionnaireService.getAllQuestionnaires(payload.isActive).pipe(
          switchMap(result => {
            const actions = [];
            actions.push(addAllQuestionnairesStore({ allQuestionnaires: result.body.data }));
            actions.push(new ResponseSuccess(LessonQuestionnaireActionTypes.getAllQuestionnairesSuccessType, result));
            return actions;
          }),
          catchError(err =>
            of(
              new ResponseError(LessonQuestionnaireActionTypes.getAllQuestionnairesErrorType, {
                message: HttpErrorResponseMessage.genericUnknownError,
                content: err
              })
            )
          )
        )
      )
    )
  );

  constructor(private actions$: Actions, private lessonQuestionnaireService: LessonQuestionnaireService) {}
}
