/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AccountDeletionRequestInterface } from 'src/app/models/interface/account-deletion-request.interface';
import { PayloadInterface } from 'src/app/models/interface/payload.interface';
import { DialogAccountInterventionInstanceAssignmentComponent } from 'src/app/modules/dialog/dialog-account-intervention-instance-assignment/dialog-account-intervention-instance-assignment.component';
import { DialogBuddyActivityReportComponent } from 'src/app/modules/dialog/dialog-buddy-activity-report/dialog-buddy-activity-report.component';
import { DialogInterventionPublishReviewComponent } from 'src/app/modules/dialog/dialog-intervention-publish-review/dialog-intervention-publish-review.component';
import { DialogOrganisationAccountCreationComponent } from 'src/app/modules/dialog/dialog-organisation-account-creation/dialog-organisation-account-creation.component';
import { BuddyInstanceInterface } from '../../../models/interface/buddy/buddy-instance.interface';
import { DiaryInstanceInterface } from '../../../models/interface/diary-instance.interface';
import { DiaryInterface } from '../../../models/interface/diary.interface';
import { InterventionInstanceReminderInterface } from '../../../models/interface/intervention-instance-reminder.interface';
import { InterventionInstanceInterface } from '../../../models/interface/intervention-instances/intervention-instance.interface';
import { InterventionInterface } from '../../../models/interface/intervention.interface';
import { LessonInterface } from '../../../models/interface/lesson.interface';
import { OrganisationInterface } from '../../../models/interface/organisation/organisation.interface';
import { ProfileInterface } from '../../../models/interface/profile.interface';
import { SkillInterface } from '../../../models/interface/skill.interface';
import { StudyMediaInterface } from '../../../models/interface/study/study-media.interface';
import { StudyInterface } from '../../../models/interface/study/study.interface';
import { UserInterface } from '../../../models/interface/user.interface';
import { DialogAdminAccountDeletionRequestsComponent } from '../../../modules/dialog/dialog-admin-account-deletion-requests/dialog-admin-account-deletion-requests.component';
import { DialogAdminOrganisationCreateComponent } from '../../../modules/dialog/dialog-admin-organisation-create/dialog-admin-organisation-create.component';
import { DialogAdminOrganisationDeleteComponent } from '../../../modules/dialog/dialog-admin-organisation-delete/dialog-admin-organisation-delete.component';
import { DialogAdminOrganisationDetailComponent } from '../../../modules/dialog/dialog-admin-organisation-detail/dialog-admin-organisation-detail.component';
import { DialogAdminOrganisationUpdateComponent } from '../../../modules/dialog/dialog-admin-organisation-update/dialog-admin-organisation-update.component';
import { DialogAdminStudyCreateComponent } from '../../../modules/dialog/dialog-admin-study-create/dialog-admin-study-create.component';
import { DialogAdminStudyDeleteComponent } from '../../../modules/dialog/dialog-admin-study-delete/dialog-admin-study-delete.component';
import { DialogAdminStudyOrganisationComponent } from '../../../modules/dialog/dialog-admin-study-organisation/dialog-admin-study-organisation.component';
import { DialogAdminStudyUpdateComponent } from '../../../modules/dialog/dialog-admin-study-update/dialog-admin-study-update.component';
import { DialogAdminUserCreateComponent } from '../../../modules/dialog/dialog-admin-user-create/dialog-admin-user-create.component';
import { DialogAdminUserDeleteComponent } from '../../../modules/dialog/dialog-admin-user-delete/dialog-admin-user-delete.component';
import { DialogAdminUserUpdateComponent } from '../../../modules/dialog/dialog-admin-user-update/dialog-admin-user-update.component';
import { DialogBuddyInstanceCreateComponent } from '../../../modules/dialog/dialog-buddy-instance-create/dialog-buddy-instance-create.component';
import { DialogBuddyInstanceDeleteComponent } from '../../../modules/dialog/dialog-buddy-instance-delete/dialog-buddy-instance-delete.component';
import { DialogBuddyInstanceDetailComponent } from '../../../modules/dialog/dialog-buddy-instance-detail/dialog-buddy-instance-detail.component';
import { DialogBuddyInstanceSwapComponent } from '../../../modules/dialog/dialog-buddy-instance-swap/dialog-buddy-instance-swap.component';
import { DialogBuddyInstanceUpdateComponent } from '../../../modules/dialog/dialog-buddy-instance-update/dialog-buddy-instance-update.component';
import { DialogConversationCreateComponent } from '../../../modules/dialog/dialog-conversation-create/dialog-conversation-create.component';
import { DialogDownloadsComponent } from '../../../modules/dialog/dialog-downloads/dialog-downloads.component';
import { DialogGroupCreationComponent } from '../../../modules/dialog/dialog-group-creation/dialog-group-creation.component';
import { DialogGroupMemberCodeUpdateComponent } from '../../../modules/dialog/dialog-group-member-code-update/dialog-group-member-code-update.component';
import { DialogGroupMemberInvitationCheckComponent } from '../../../modules/dialog/dialog-group-member-invitation-check/dialog-group-member-invitation-check.component';
import { DialogGroupMemberRemoveComponent } from '../../../modules/dialog/dialog-group-member-remove/dialog-group-member-remove.component';
import { DialogInstanceCollaboratorAddComponent } from '../../../modules/dialog/dialog-instance-collaborator-add/dialog-instance-collaborator-add.component';
import { DialogInstanceQuestionnaireConfigurationDiaryComponent } from '../../../modules/dialog/dialog-instance-questionnaire-configuration-diary/dialog-instance-questionnaire-configuration-diary.component';
import { DialogInterventionCollaboratorAddComponent } from '../../../modules/dialog/dialog-intervention-collaborator-add/dialog-intervention-collaborator-add.component';
import { DialogInterventionCollaboratorEditComponent } from '../../../modules/dialog/dialog-intervention-collaborator-edit/dialog-intervention-collaborator-edit.component';
import { DialogInterventionCollaboratorRemoveComponent } from '../../../modules/dialog/dialog-intervention-collaborator-remove/dialog-intervention-collaborator-remove.component';
import { DialogInterventionDeleteComponent } from '../../../modules/dialog/dialog-intervention-delete/dialog-intervention-delete.component';
import { DialogInterventionDiaryPreviewComponent } from '../../../modules/dialog/dialog-intervention-diary-preview/dialog-intervention-diary-preview.component';
import { DialogInterventionInstanceCreateComponent } from '../../../modules/dialog/dialog-intervention-instance-create/dialog-intervention-instance-create.component';
import { DialogInterventionInstanceDeleteComponent } from '../../../modules/dialog/dialog-intervention-instance-delete/dialog-intervention-instance-delete.component';
import { DialogInterventionInstanceDetailComponent } from '../../../modules/dialog/dialog-intervention-instance-detail/dialog-intervention-instance-detail.component';
import { DialogInterventionInstanceReminderCreateComponent } from '../../../modules/dialog/dialog-intervention-instance-reminder-create/dialog-intervention-instance-reminder-create.component';
import { DialogInterventionInstanceReminderDetailComponent } from '../../../modules/dialog/dialog-intervention-instance-reminder-detail/dialog-intervention-instance-reminder-detail.component';
import { DialogInterventionInstanceReportComponent } from '../../../modules/dialog/dialog-intervention-instance-report/dialog-intervention-instance-report.component';
import { DialogInterventionInstanceUpdateComponent } from '../../../modules/dialog/dialog-intervention-instance-update/dialog-intervention-instance-update.component';
import { DialogInterventionLessonPreviewComponent } from '../../../modules/dialog/dialog-intervention-lesson-preview/dialog-intervention-lesson-preview.component';
import { DialogInterventionPublishOfficialComponent } from '../../../modules/dialog/dialog-intervention-publish-official/dialog-intervention-publish-official.component';
import { DialogInterventionPublishTestComponent } from '../../../modules/dialog/dialog-intervention-publish-test/dialog-intervention-publish-test.component';
import { DialogOrganisationAccountDeleteComponent } from '../../../modules/dialog/dialog-organisation-account-delete/dialog-organisation-account-delete.component';
import { DialogOrganisationCollaboratorAddComponent } from '../../../modules/dialog/dialog-organisation-collaborator-add/dialog-organisation-collaborator-add.component';
import { DialogOrganisationCollaboratorEditComponent } from '../../../modules/dialog/dialog-organisation-collaborator-edit/dialog-organisation-collaborator-edit.component';
import { DialogOrganisationCollaboratorRemoveComponent } from '../../../modules/dialog/dialog-organisation-collaborator-remove/dialog-organisation-collaborator-remove.component';
import { DialogOrganisationStudyCollaboratorManagementComponent } from '../../../modules/dialog/dialog-organisation-study-collaborator-management/dialog-organisation-study-collaborator-management.component';
import { DialogPatientAccountDeleteComponent } from '../../../modules/dialog/dialog-patient-account-delete/dialog-patient-account-delete.component';
import { DialogPatientDiaryAnswersheetPreviewComponent } from '../../../modules/dialog/dialog-patient-diary-answersheet-preview/dialog-patient-diary-answersheet-preview.component';
import { DialogPatientInvitationsComponent } from '../../../modules/dialog/dialog-patient-invitations/dialog-patient-invitations.component';
import { DialogPatientVerificationResendComponent } from '../../../modules/dialog/dialog-patient-verification-resend/dialog-patient-verification-resend.component';
import { DialogSkillElementsComponent } from '../../../modules/dialog/dialog-skill-elements/dialog-skill-elements.component';
import { DialogStudyMediaGalleryComponent } from '../../../modules/dialog/dialog-study-media-gallery/dialog-study-media-gallery.component';
import { DialogStudyReportComponent } from '../../../modules/dialog/dialog-study-report/dialog-study-report.component';
import { DialogCodebookComponent } from '../../../modules/dialog/dialog-codebook/dialog-codebook.component';

@Injectable({
  providedIn: 'root'
})
export class HelperDialogService {
  constructor(public dialog: MatDialog, private router: Router) {
    router.events.subscribe(() => {
      this.dialog.closeAll();
    });
  }

  public openDialogOrganisationCreate(): MatDialogRef<DialogAdminOrganisationCreateComponent> {
    return this.dialog.open(DialogAdminOrganisationCreateComponent, {
      minWidth: '50vw'
    });
  }

  public openDialogOrganisationDetail(organisation: OrganisationInterface): MatDialogRef<DialogAdminOrganisationDetailComponent> {
    return this.dialog.open(DialogAdminOrganisationDetailComponent, {
      data: {
        organisation
      },
      minWidth: '50vw'
    });
  }

  public openDialogOrganisationUpdate(organisation: OrganisationInterface): MatDialogRef<DialogAdminOrganisationUpdateComponent> {
    return this.dialog.open(DialogAdminOrganisationUpdateComponent, {
      data: {
        organisation
      },
      minWidth: '50vw'
    });
  }

  public openDialogOrganisationDelete(organisation: OrganisationInterface): MatDialogRef<DialogAdminOrganisationDeleteComponent> {
    return this.dialog.open(DialogAdminOrganisationDeleteComponent, {
      data: {
        organisation
      },
      minWidth: '50vw'
    });
  }

  public openDialogOrganisationCollaboratorAdd(
    param: any,
    organisationId: number,
    collaborators: Array<UserInterface>,
    eCoaches: Array<UserInterface>,
    user?: UserInterface
  ): MatDialogRef<DialogOrganisationCollaboratorAddComponent> {
    return this.dialog.open(DialogOrganisationCollaboratorAddComponent, {
      data: {
        collaborator: user ? user : null,
        param,
        organisationId,
        collaborators,
        ecoaches: eCoaches
      },
      minWidth: '50vw'
    });
  }

  public openDialogOrganisationCollaboratorEdit(
    param: any,
    organisationId: number,
    collaborators: Array<UserInterface>,
    eCoaches: Array<UserInterface>,
    user?: UserInterface
  ): MatDialogRef<DialogOrganisationCollaboratorEditComponent> {
    return this.dialog.open(DialogOrganisationCollaboratorEditComponent, {
      data: {
        collaborator: user ? user : null,
        param,
        organisationId,
        collaborators,
        ecoaches: eCoaches
      },
      minWidth: '50vw'
    });
  }

  public openDialogOrganisationCollaboratorRemove(
    param: any,
    organisationId: number,
    collaborators: Array<UserInterface>,
    eCoaches: Array<UserInterface>,
    user?: UserInterface
  ): MatDialogRef<DialogOrganisationCollaboratorRemoveComponent> {
    return this.dialog.open(DialogOrganisationCollaboratorRemoveComponent, {
      data: {
        collaborator: user ? user : null,
        param,
        organisationId,
        collaborators,
        ecoaches: eCoaches
      },
      minWidth: '50vw'
    });
  }

  public openDialogStudyCreate(): MatDialogRef<DialogAdminStudyCreateComponent> {
    return this.dialog.open(DialogAdminStudyCreateComponent, {
      minWidth: '50vw'
    });
  }

  public openDialogStudyUpdate(group: StudyInterface): MatDialogRef<DialogAdminStudyUpdateComponent> {
    return this.dialog.open(DialogAdminStudyUpdateComponent, {
      data: {
        group
      },
      minWidth: '50vw'
    });
  }

  public openDialogStudyDelete(group: StudyInterface): MatDialogRef<DialogAdminStudyDeleteComponent> {
    return this.dialog.open(DialogAdminStudyDeleteComponent, {
      data: {
        group
      },
      minWidth: '50vw'
    });
  }

  public openDialogAssignStudy(study: StudyInterface): MatDialogRef<DialogAdminStudyOrganisationComponent> {
    return this.dialog.open(DialogAdminStudyOrganisationComponent, {
      data: {
        study
      },
      minWidth: '50vw'
    });
  }

  public openDialogUserCreate(): MatDialogRef<DialogAdminUserCreateComponent> {
    return this.dialog.open(DialogAdminUserCreateComponent, {
      minWidth: '50vw'
    });
  }

  public openDialogUserUpdate(user: UserInterface): MatDialogRef<DialogAdminUserUpdateComponent> {
    return this.dialog.open(DialogAdminUserUpdateComponent, {
      data: {
        user
      },
      minWidth: '50vw'
    });
  }

  public openDialogUserDelete(user: UserInterface): MatDialogRef<DialogAdminUserDeleteComponent> {
    return this.dialog.open(DialogAdminUserDeleteComponent, {
      data: {
        user
      },
      minWidth: '50vw'
    });
  }

  public openDialogVerification(isModalLoadingSubject: BehaviorSubject<boolean>): MatDialogRef<DialogPatientVerificationResendComponent> {
    return this.dialog.open(DialogPatientVerificationResendComponent, {
      data: {
        submitted: isModalLoadingSubject.value
      },
      minWidth: '50vw'
    });
  }

  public openDialogAccountRequests(
    user: UserInterface,
    deletionRequest: AccountDeletionRequestInterface
  ): MatDialogRef<DialogAdminAccountDeletionRequestsComponent> {
    return this.dialog.open(DialogAdminAccountDeletionRequestsComponent, {
      data: {
        user,
        deletionRequest
      },
      minWidth: '50vw'
    });
  }

  public openDialogInterventionTestPublish(intervention: InterventionInterface): MatDialogRef<DialogInterventionPublishTestComponent> {
    return this.dialog.open(DialogInterventionPublishTestComponent, {
      data: {
        intervention
      },
      minWidth: '50vw'
    });
  }

  public openDialogInterventionReview(intervention: InterventionInterface): MatDialogRef<DialogInterventionPublishReviewComponent> {
    return this.dialog.open(DialogInterventionPublishReviewComponent, {
      data: {
        intervention
      },
      minWidth: '50vw'
    });
  }

  public openDialogInterventionOfficialPublish(
    intervention: InterventionInterface
  ): MatDialogRef<DialogInterventionPublishOfficialComponent> {
    return this.dialog.open(DialogInterventionPublishOfficialComponent, {
      data: {
        intervention
      },
      minWidth: '50vw'
    });
  }

  public openDialogInterventionDelete(intervention: InterventionInterface): MatDialogRef<DialogInterventionDeleteComponent> {
    return this.dialog.open(DialogInterventionDeleteComponent, {
      data: {
        intervention
      },
      minWidth: '50vw'
    });
  }

  public openDialogInterventionInstanceDetails(
    instanceId: number,
    param?: any,
    index?: number
  ): MatDialogRef<DialogInterventionInstanceDetailComponent> {
    return this.dialog.open(DialogInterventionInstanceDetailComponent, {
      data: {
        param,
        instanceId,
        selectedIndex: index ? index : 0
      },
      minWidth: '50vw'
    });
  }

  public openDialogInterventionInstanceCreate(
    param: any,
    intervention: InterventionInterface,
    members: Array<UserInterface>,
    collaborators: Array<UserInterface>,
    myUserId: number,
    questionnaires: Array<LessonInterface>
  ): MatDialogRef<DialogInterventionInstanceCreateComponent> {
    return this.dialog.open(DialogInterventionInstanceCreateComponent, {
      data: {
        param,
        intervention,
        members,
        collaborators,
        myUserId,
        questionnaires
      },
      minWidth: '50vw'
    });
  }

  public openDialogInterventionInstanceUpdate(
    instance: InterventionInstanceInterface,
    param: any,
    collaborators: Array<UserInterface>,
    intervention: InterventionInterface,
    members: Array<UserInterface>,
    questionnaires: Array<LessonInterface>,
    myUserId: number,
    isManagerSubject: BehaviorSubject<boolean>
  ): MatDialogRef<DialogInterventionInstanceUpdateComponent> {
    return this.dialog.open(DialogInterventionInstanceUpdateComponent, {
      data: {
        param,
        instance_id: instance ? instance.id : null,
        selectedIndex: 0,
        collaborators,
        instance,
        intervention,
        members,
        questionnaires,
        id: 'updateModal',
        myUserId,
        isManager: isManagerSubject.value
      },
      minWidth: '50vw'
    });
  }

  public openDialogInterventionInstanceDelete(
    param: any,
    collaborators: Array<UserInterface>,
    intervention: InterventionInterface,
    members: Array<UserInterface>,
    isManagerSubject: BehaviorSubject<boolean>,
    instance?: InterventionInstanceInterface
  ): MatDialogRef<DialogInterventionInstanceDeleteComponent> {
    return this.dialog.open(DialogInterventionInstanceDeleteComponent, {
      data: {
        param,
        collaborators,
        instance,
        intervention,
        members,
        isManager: isManagerSubject.value
      },
      minWidth: '50vw'
    });
  }

  public openDialogInterventionInstanceReport(
    param: any,
    collaborators: Array<UserInterface>,
    intervention: InterventionInterface,
    members: Array<UserInterface>,
    questionnaires: Array<LessonInterface>,
    isManager: boolean,
    interventions: Array<InterventionInterface>,
    users: Array<UserInterface>,
    instance?: InterventionInstanceInterface
  ): MatDialogRef<DialogInterventionInstanceReportComponent> {
    return this.dialog.open(DialogInterventionInstanceReportComponent, {
      data: {
        param,
        collaborators,
        instance,
        intervention,
        members,
        questionnaires,
        isManager,
        interventions,
        users
      },
      minWidth: '50vw'
    });
  }

  public openDialogGroupCreate(submitted: boolean): MatDialogRef<DialogGroupCreationComponent> {
    return this.dialog.open(DialogGroupCreationComponent, {
      data: {
        submitted
      },
      minWidth: '50vw'
    });
  }

  public openDialogGroupCollaboratorAdd(
    param: any,
    studyId: number,
    collaborators: Array<UserInterface>,
    ecoaches: Array<UserInterface>,
    user?: UserInterface
  ): MatDialogRef<DialogInterventionCollaboratorAddComponent> {
    return this.dialog.open(DialogInterventionCollaboratorAddComponent, {
      data: {
        collaborator: user ? user : null,
        param,
        studyId,
        collaborators,
        ecoaches
      },
      minWidth: '50vw'
    });
  }

  public openDialogGroupCollaboratorEdit(
    param: any,
    studyId: number,
    collaborators: Array<UserInterface>,
    eCoaches: Array<UserInterface>,
    user?: UserInterface
  ): MatDialogRef<DialogInterventionCollaboratorEditComponent> {
    return this.dialog.open(DialogInterventionCollaboratorEditComponent, {
      data: {
        collaborator: user ? user : null,
        param,
        studyId,
        collaborators,
        ecoaches: eCoaches
      },
      minWidth: '50vw'
    });
  }

  public openDialogGroupCollaboratorRemove(
    param: any,
    studyId: number,
    collaborators: Array<UserInterface>,
    eCoaches: Array<UserInterface>,
    user?: UserInterface
  ): MatDialogRef<DialogInterventionCollaboratorRemoveComponent> {
    return this.dialog.open(DialogInterventionCollaboratorRemoveComponent, {
      data: {
        collaborator: user ? user : null,
        param,
        studyId,
        collaborators,
        ecoaches: eCoaches
      },
      minWidth: '50vw'
    });
  }

  public openDialogGroupCollaboratorOrganisationManager(
    study: StudyInterface,
    collaborators: Array<UserInterface>,
    eCoaches: Array<UserInterface>
  ): MatDialogRef<DialogOrganisationStudyCollaboratorManagementComponent> {
    const param = { intervention_name: study.attributes.name };
    return this.dialog.open(DialogOrganisationStudyCollaboratorManagementComponent, {
      data: {
        collaborator: null,
        param,
        studyId: study.id,
        organisationId: study.attributes.organisation_id,
        collaborators,
        ecoaches: eCoaches
      },
      minWidth: '50vw'
    });
  }

  public openDialogOrganisationAccountCreation(organisations_ids: Array<number>): MatDialogRef<DialogOrganisationAccountCreationComponent> {
    return this.dialog.open(DialogOrganisationAccountCreationComponent, {
      data: {
        organisations_ids
      },
      minWidth: '50vw'
    });
  }

  public openDialogGroupInvitationCheck(
    isManagerSubject: BehaviorSubject<boolean>,
    study: StudyInterface
  ): MatDialogRef<DialogGroupMemberInvitationCheckComponent> {
    return this.dialog.open(DialogGroupMemberInvitationCheckComponent, {
      data: {
        isManager: isManagerSubject.value,
        study
      },
      minWidth: '50vw'
    });
  }

  public openDialogGroupStudyCodeUpdate(user: UserInterface, studyId: number): MatDialogRef<DialogGroupMemberCodeUpdateComponent> {
    return this.dialog.open(DialogGroupMemberCodeUpdateComponent, {
      data: {
        user,
        studyId
      },
      minWidth: '50vw'
    });
  }

  public openDialogGroupMemberRemove(
    user: UserInterface,
    isManagerSubject: BehaviorSubject<boolean>,
    studyId: number,
    code: string,
    param: any
  ): MatDialogRef<DialogGroupMemberRemoveComponent> {
    return this.dialog.open(DialogGroupMemberRemoveComponent, {
      data: {
        isManager: isManagerSubject.value,
        studyId,
        member: user,
        code,
        param
      },
      minWidth: '50vw'
    });
  }

  public openDialogStudyMediaGallery(
    studyMedia: Array<StudyMediaInterface>,
    studyId: number
  ): MatDialogRef<DialogStudyMediaGalleryComponent> {
    return this.dialog.open(DialogStudyMediaGalleryComponent, {
      data: {
        studyMedia,
        studyId
      },
      minWidth: '50vw'
    });
  }

  public openDialogStudyReport(group: StudyInterface): MatDialogRef<DialogStudyReportComponent> {
    return this.dialog.open(DialogStudyReportComponent, {
      data: {
        group
      },
      minWidth: '50vw'
    });
  }

  public openDialogCodeBook(group: StudyInterface): MatDialogRef<DialogCodebookComponent> {
    return this.dialog.open(DialogCodebookComponent, {
      data: {
        group
      },
      minWidth: '50vw'
    });
  }

  public openDialogPatientResendVerification(
    isModalLoadingSubject: BehaviorSubject<boolean>
  ): MatDialogRef<DialogPatientVerificationResendComponent> {
    return this.dialog.open(DialogPatientVerificationResendComponent, {
      data: {
        submitted: isModalLoadingSubject.value
      },
      minWidth: '50vw'
    });
  }

  public openDialogPatientInvitationCheck(user: UserInterface, myUserId: number): MatDialogRef<DialogPatientInvitationsComponent> {
    return this.dialog.open(DialogPatientInvitationsComponent, {
      data: {
        user,
        myUserId
      },
      minWidth: '50vw'
    });
  }

  public openDialogUnverifiedAccountDelete(user: UserInterface, myUserId: number): MatDialogRef<DialogPatientAccountDeleteComponent> {
    return this.dialog.open(DialogPatientAccountDeleteComponent, {
      data: {
        user,
        myUserId
      },
      minWidth: '50vw'
    });
  }

  public openDialogUnverifiedOrganisationAccountDelete(
    organisationId: number,
    user: UserInterface,
    myUserId: number
  ): MatDialogRef<DialogOrganisationAccountDeleteComponent> {
    return this.dialog.open(DialogOrganisationAccountDeleteComponent, {
      data: {
        organisationId,
        user,
        myUserId
      },
      minWidth: '50vw'
    });
  }

  public openDialogOrganisationUnverifiedAccountDelete(
    user: UserInterface,
    myUserId: number
  ): MatDialogRef<DialogPatientAccountDeleteComponent> {
    return this.dialog.open(DialogPatientAccountDeleteComponent, {
      data: {
        user,
        myUserId
      },
      minWidth: '50vw'
    });
  }

  public openDialogInterventionLesson(questionnaireId: number, studyId: number): MatDialogRef<DialogInterventionLessonPreviewComponent> {
    return this.dialog.open(DialogInterventionLessonPreviewComponent, {
      data: {
        questionnaire_id: questionnaireId,
        study_id: studyId
      },
      minWidth: '50vw'
    });
  }

  public openDialogDiaryPreview(
    questionnaireId: number,
    studyId: number,
    diaryPageColor: string
  ): MatDialogRef<DialogInterventionDiaryPreviewComponent> {
    return this.dialog.open(DialogInterventionDiaryPreviewComponent, {
      data: {
        questionnaire_id: questionnaireId,
        study_id: studyId,
        diaryPageColor
      },
      minWidth: '50vw'
    });
  }

  public openDialogDiaryAnswersheetPreview(
    diary_instance_id: number,
    diary: DiaryInterface
  ): MatDialogRef<DialogPatientDiaryAnswersheetPreviewComponent> {
    return this.dialog.open(DialogPatientDiaryAnswersheetPreviewComponent, {
      data: {
        diary_instance_id,
        diary
      },
      minWidth: '50vw'
    });
  }

  public openDialogInterventionInstanceDiaryConfiguration(): MatDialogRef<DialogInstanceQuestionnaireConfigurationDiaryComponent> {
    return this.dialog.open(DialogInstanceQuestionnaireConfigurationDiaryComponent, {
      data: {}
    });
  }

  public openDialogConversationCreate(
    includedParticipants: Array<UserInterface>,
    myUser: ProfileInterface,
    members: Array<UserInterface>,
    allECoaches: Array<UserInterface>,
    collaborators: Array<UserInterface>
  ): MatDialogRef<DialogConversationCreateComponent> {
    return this.dialog.open(DialogConversationCreateComponent, {
      data: {
        includedParticipants,
        myUser,
        members,
        allECoaches,
        collaborators
      },
      minWidth: '50vw'
    });
  }

  public openDialogDownloads(): MatDialogRef<DialogDownloadsComponent> {
    return this.dialog.open(DialogDownloadsComponent, { minWidth: '50vw' });
  }

  public openDialogBuddyInstanceDetails(
    buddyInstance: BuddyInstanceInterface,
    members: Array<UserInterface>,
    studyCollaborators: Array<UserInterface>,
    interventions: Array<InterventionInterface>,
    interventionInstances: Array<InterventionInstanceInterface>,
    diaryInstances: Array<DiaryInstanceInterface>,
    diaries: Array<DiaryInterface>,
    isManager: boolean,
    studyId: number
  ): MatDialogRef<DialogBuddyInstanceDetailComponent> {
    return this.dialog.open(DialogBuddyInstanceDetailComponent, {
      data: {
        buddyInstance,
        members,
        studyCollaborators,
        interventions,
        interventionInstances,
        diaryInstances,
        diaries,
        isManager,
        studyId
      },
      minWidth: '50vw'
    });
  }

  public openDialogBuddyInstanceCreate(
    members: Array<UserInterface>,
    param: any,
    interventions: Array<InterventionInterface>,
    interventionInstances: Array<InterventionInstanceInterface>,
    diaryInstances: Array<DiaryInstanceInterface>,
    diaries: Array<DiaryInterface>,
    studyId: number
  ): MatDialogRef<DialogBuddyInstanceCreateComponent> {
    return this.dialog.open(DialogBuddyInstanceCreateComponent, {
      data: {
        members,
        param,
        interventions,
        interventionInstances,
        diaryInstances,
        diaries,
        studyId
      },
      minWidth: '50vw'
    });
  }

  public openDialogBuddyInstanceDelete(
    buddyInstance: BuddyInstanceInterface,
    members: Array<UserInterface>,
    param: any,
    interventions: Array<InterventionInterface>,
    studyCollaborators: Array<UserInterface>,
    studyId: number
  ): MatDialogRef<DialogBuddyInstanceDeleteComponent> {
    return this.dialog.open(DialogBuddyInstanceDeleteComponent, {
      data: {
        members,
        param,
        interventions,
        studyCollaborators,
        buddyInstance,
        studyId
      },
      minWidth: '50vw'
    });
  }

  public openDialogBuddyInstanceUpdate(
    buddyInstance: BuddyInstanceInterface,
    members: Array<UserInterface>,
    param: any,
    interventions: Array<InterventionInterface>,
    interventionInstances: Array<InterventionInstanceInterface>,
    diaryInstances: Array<DiaryInstanceInterface>,
    diaries: Array<DiaryInterface>,
    studyCollaborators: Array<UserInterface>,
    studyId: number
  ): MatDialogRef<DialogBuddyInstanceUpdateComponent> {
    return this.dialog.open(DialogBuddyInstanceUpdateComponent, {
      data: {
        members,
        param,
        interventions,
        buddyInstance,
        interventionInstances,
        diaryInstances,
        diaries,
        studyCollaborators,
        studyId
      },
      minWidth: '50vw'
    });
  }

  public openDialogBuddyInstanceSwap(
    buddyInstances: Array<BuddyInstanceInterface>,
    members: Array<UserInterface>,
    param: any,
    interventions: Array<InterventionInterface>,
    interventionInstances: Array<InterventionInstanceInterface>,
    diaryInstances: Array<DiaryInstanceInterface>,
    diaries: Array<DiaryInterface>,
    studyCollaborators: Array<UserInterface>,
    studyId: number
  ): MatDialogRef<DialogBuddyInstanceSwapComponent> {
    return this.dialog.open(DialogBuddyInstanceSwapComponent, {
      data: {
        members,
        param,
        interventions,
        buddyInstances,
        interventionInstances,
        diaryInstances,
        diaries,
        studyCollaborators,
        studyId
      },
      minWidth: '50vw'
    });
  }

  public openDialogSkill(
    skillId: number,
    skills: Array<SkillInterface>,
    intervention: InterventionInterface
  ): MatDialogRef<DialogSkillElementsComponent> {
    const found = skills.find((value: SkillInterface) => value.id.toString() === skillId.toString());
    if (found) {
      const dialogRef = this.dialog.open(DialogSkillElementsComponent, {
        data: {
          skill: found,
          intervention
        },
        minWidth: '50vw'
      });
      return dialogRef;
    }
    return null;
  }

  public openDialogSkillByName(
    skillId?: number,
    skills?: Array<SkillInterface>,
    name?: number,
    intervention?: InterventionInterface
  ): MatDialogRef<DialogSkillElementsComponent> {
    const found = skillId
      ? skills.find((value: SkillInterface) => value.id.toString() === skillId.toString())
      : skills.find((value: SkillInterface) => value.attributes.title.toString() === name.toString());
    if (found) {
      const dialogRef = this.dialog.open(DialogSkillElementsComponent, {
        data: {
          skill: found,
          intervention
        },
        minWidth: '50vw'
      });
      return dialogRef;
    }
    return null;
  }

  public openDialogAddCollaboratorToECoachInstancesStudy(studyId: number): MatDialogRef<DialogInstanceCollaboratorAddComponent> {
    const dialogRef = this.dialog.open(DialogInstanceCollaboratorAddComponent, {
      data: {
        studyId
      },
      minWidth: '50vw'
    });
    return dialogRef;
  }

  public openDialogCreateInterventionInstanceReminder(
    instanceId?: number,
    userId?: number,
    reminderConfig?: PayloadInterface
  ): MatDialogRef<DialogInterventionInstanceReminderCreateComponent> {
    const dialogRef = this.dialog.open(DialogInterventionInstanceReminderCreateComponent, {
      data: {
        instanceId,
        userId,
        reminderConfig
      },
      minWidth: '50vw'
    });
    return dialogRef;
  }

  public openDialogInterventionInstanceReminderDetails(
    reminder: InterventionInstanceReminderInterface,
    studyId?: number,
    questionnaires?: Array<LessonInterface>
  ): MatDialogRef<DialogInterventionInstanceReminderDetailComponent> {
    const dialogRef = this.dialog.open(DialogInterventionInstanceReminderDetailComponent, {
      data: {
        reminder,
        studyId,
        questionnaires
      },
      minWidth: '50vw'
    });
    return dialogRef;
  }

  public openDialogBuddyActivityReport(
    studyId?: number,
    members?: Array<UserInterface>,
    buddyInstance?: BuddyInstanceInterface
  ): MatDialogRef<DialogBuddyActivityReportComponent> {
    const dialogRef = this.dialog.open(DialogBuddyActivityReportComponent, {
      data: {
        studyId,
        members,
        buddyInstance
      },
      minWidth: '50vw'
    });
    return dialogRef;
  }

  public openDialogAccountInterventionInstanceAssignment(
    assignmentMode: 'accountCreation' | 'studyInvitation' | 'patientStudyInvitation' | 'patientInterventionAssignment',
    myUserId?: number,
    study?: StudyInterface,
    selectedUser?: UserInterface,
    users?: Array<UserInterface>
  ): MatDialogRef<DialogAccountInterventionInstanceAssignmentComponent> {
    return this.dialog.open(DialogAccountInterventionInstanceAssignmentComponent, {
      data: {
        assignmentMode,
        study,
        myUserId,
        selectedUser,
        users
      },
      minWidth: '50vw'
    });
  }
}
