import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-privacy-ecoach',
  templateUrl: './privacy-ecoach.component.html',
  styleUrls: ['./privacy-ecoach.component.scss']
})
export class PrivacyEcoachComponent implements OnInit {
  // Icons
  faArrowLeft = faArrowLeft;

  // Authentication status
  public isAuthenticated$: Observable<boolean>;

  public isMasterResearch = false;

  constructor(private router: Router, private store: Store<{ isAuthenticated: boolean }>) {
    this.isAuthenticated$ = this.store.select('isAuthenticated');
  }

  public redirectToLogin(): void {
    this.router.navigate([`/login`]);
  }

  ngOnInit(): void {
    this.isMasterResearch = environment.backendURL.includes('research.klips');
  }
}
