import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { iif, Observable, of } from 'rxjs';
import { UserInterface } from '../../models/interface/user.interface';
import { catchError, skip, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ProfileInterface } from '../../../app/models/interface/profile.interface';
import { StudyActionTypes } from '../../../app/store/study/study.action';
import { getCollaboratorsByStudyId } from '../../store/study/study.selector';

// Access to the child group routes if user has permission to access as organisation.manager
@Injectable({
  providedIn: 'root'
})
export class GroupOrganisationManagerGuard implements CanActivateChild {
  private profile$: Observable<ProfileInterface> = this.store.select('myProfile');
  private profile: ProfileInterface;
  private groupId: number;
  private collaboratorsOfStudy$: Observable<{ studyId: number; collaborators: UserInterface[] }>;

  constructor(
    private router: Router,
    private store: Store<{
      myProfile: ProfileInterface;
      getCollaboratorsByStudyId: { studyId: number; collaborators: UserInterface[] };
    }>
  ) {}

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.groupId = parseInt(state.url.replace(/\D/g, ''), 10);
    return this.profile$.pipe(
      switchMap((user: ProfileInterface) => {
        this.profile = user;
        this.store.dispatch({ type: StudyActionTypes.getCollaboratorsType, payload: { studyId: this.groupId, include: 'roles' } });
        this.collaboratorsOfStudy$ = this.store.select(getCollaboratorsByStudyId(this.groupId));
        return this.collaboratorsOfStudy$.pipe(skip(1), take(1));
      }),
      switchMap((result: { studyId: number; collaborators: UserInterface[] }) => {
        const collaborators = result.collaborators;
        const isCollaborator = !!collaborators.find(user => user.id.toString() === this.profile.id.toString());
        if (isCollaborator) {
          return of(true);
        }
        return iif(
          () => state.url.includes('interventions') || state.url.includes('collaborators'),
          of(true),
          of(this.router.parseUrl(`/groups/${this.groupId}/collaborators`))
        );
      }),
      catchError(() => of(this.router.parseUrl('/groups')))
    );
  }
}
