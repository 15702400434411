import { createReducer, on } from '@ngrx/store';
import { StudyInterface } from 'src/app/models/interface/study/study.interface';
import { UserInterface } from '../../models/interface/user.interface';
import {
  addCollabGroupsStore,
  addCollaboratorsOfStudiesStore,
  addNonCollabGroupsStore,
  removeCollabGroupsStore,
  removeCollaboratorsOfStudiesStore,
  removeNonCollabGroupsStore,
  updateCollabGroupsStore,
  updateCollaboratorsOfStudiesStore,
  updateNonCollabGroupsStore
} from './study.action';

export const initialCollabGroupsState: Array<StudyInterface> = [];
export const collabGroupsReducer = createReducer(
  initialCollabGroupsState,
  on(addCollabGroupsStore, (state, { collabGroups }) => collabGroups),
  on(updateCollabGroupsStore, (state, { collabGroups }) => collabGroups),
  on(removeCollabGroupsStore, (state, {}) => [])
);

export const initialNonCollabGroupsState: Array<StudyInterface> = [];
export const nonCollabGroupsReducer = createReducer(
  initialNonCollabGroupsState,
  on(addNonCollabGroupsStore, (state, { nonCollabGroups }) => nonCollabGroups),
  on(updateNonCollabGroupsStore, (state, { nonCollabGroups }) => nonCollabGroups),
  on(removeNonCollabGroupsStore, (state, {}) => [])
);

export const initialCollaboratorsOfStudies: Array<{ studyId: number; collaborators: UserInterface[] }> = [];
export const collaboratorsOfStudiesReducer = createReducer(
  initialCollaboratorsOfStudies,
  on(addCollaboratorsOfStudiesStore, (state, { collaboratorsOfStudy }) => {
    const currentState = [...state];
    const foundIndex = currentState.findIndex(entry => entry.studyId.toString() === collaboratorsOfStudy.studyId.toString());
    if (foundIndex > -1) {
      currentState[foundIndex] = collaboratorsOfStudy;
    } else {
      currentState.push(collaboratorsOfStudy);
    }
    return currentState;
  }),
  on(updateCollaboratorsOfStudiesStore, (state, { collaboratorsOfStudy }) => {
    const currentState = [...state];
    const foundIndex = currentState.findIndex(entry => entry.studyId.toString() === collaboratorsOfStudy.studyId.toString());
    if (foundIndex > -1) {
      currentState[foundIndex] = collaboratorsOfStudy;
    } else {
      currentState.push(collaboratorsOfStudy);
    }
    return currentState;
  }),
  on(removeCollaboratorsOfStudiesStore, (state, { studyId }) => {
    const currentState = [...state];
    const foundIndex = currentState.findIndex(entry => entry.studyId.toString() === studyId.toString());
    if (foundIndex > -1) {
      currentState.splice(foundIndex, 1);
    }
    return state;
  })
);
