import { Action, createAction, props } from '@ngrx/store';
import { AnnouncementInterface } from 'src/app/models/interface/announcement.interface';

export enum AnnouncementActionTypes {
  addAnnouncementsStoreType = 'Add Announcements Store',
  updateAnnouncementsStoreType = 'Update Announcements Store',
  deleteAnnouncementsStoreType = 'Delete Announcements Store',

  getAnnouncementsType = '[Announcement API] Get All Annoucements',
  getAnnouncementsSuccessType = '[Success] Get All Annoucements',
  getAnnouncementsErrorType = '[Error] Get All Annoucements'
}
export const addAnnouncementsStore = createAction(
  AnnouncementActionTypes.addAnnouncementsStoreType,
  props<{ announcements: Array<AnnouncementInterface> }>()
);
export const updateAnnouncementsStore = createAction(
  AnnouncementActionTypes.updateAnnouncementsStoreType,
  props<{ announcements: Array<AnnouncementInterface> }>()
);
export const removeAnnouncementsStore = createAction(AnnouncementActionTypes.deleteAnnouncementsStoreType);

export class GetAnnouncements implements Action {
  readonly type: string = AnnouncementActionTypes.getAnnouncementsType;
  constructor(
    readonly payload: { client?: string; operatorStart?: '>' | '<' | '='; start?: number; operatorEnd?: '>' | '<' | '='; end?: number }
  ) {}
}
