/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, forkJoin, iif, Observable, of, Subscription, throwError } from 'rxjs';
import { faBook } from '@fortawesome/free-solid-svg-icons/faBook';
import { faHands } from '@fortawesome/free-solid-svg-icons/faHands';
import { faHandsHelping } from '@fortawesome/free-solid-svg-icons/faHandsHelping';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { faComment } from '@fortawesome/free-solid-svg-icons/faComment';
import { faCommentSlash } from '@fortawesome/free-solid-svg-icons/faCommentSlash';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faSquare } from '@fortawesome/free-solid-svg-icons/faSquare';
import { mergeMap, skip, switchMap, take, tap } from 'rxjs/operators';
import { RequestBodyData } from '../../../models/request-body.data';
import { InterventionInstanceInterface } from '../../../models/interface/intervention-instances/intervention-instance.interface';
import { InterventionInterface } from '../../../models/interface/intervention.interface';
import { HelperService } from '../../../services/helper/helper.service';
import { UserInterface } from '../../../models/interface/user.interface';
import { LessonInterface } from '../../../models/interface/lesson.interface';
import { PayloadInterface } from '../../../models/interface/payload.interface';
import { AlertService } from '../../../services/alert/alert.service';
import { InterventionInstanceConfigurationInterface } from '../../../models/interface/intervention-instances/intervention-instance.configuration.interface';
import { SkillInterface } from '../../../models/interface/skill.interface';
import { DiaryInterface } from '../../../models/interface/diary.interface';
import { HelperDialogService } from '../../../services/helper/helper-dialog/helper-dialog.service';
import { HelperSkillService } from '../../../services/helper/helper-skill/helper-skill.service';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { StudyActionTypes } from '../../../store/study/study.action';
import { DiaryStore } from '../../../store/diary/component-store/diary.store';
import { InterventionInstanceStore } from '../../../store/intervention-instance/component-store/intervention-instance.store';
import { SkillStore } from '../../../store/skill/component-store/skill.store';
import { UserStore } from '../../../store/user/component-store/user.store';
import { StudyInterface } from '../../../models/interface/study/study.interface';

/**
 * Component:
 * Instance update confirmation displaying instance details to confirm the settings of the intervention;
 */

@Component({
  selector: 'app-instance-update-confirmation',
  templateUrl: './instance-update-confirmation.component.html',
  styleUrls: ['./instance-update-confirmation.component.scss'],
  providers: [DiaryStore, InterventionInstanceStore, SkillStore, UserStore]
})
export class InstanceUpdateConfirmationComponent implements OnInit, OnDestroy {
  // Icon
  faArrowLeft = faArrowLeft;
  faArrowRight = faArrowRight;
  faComment = faComment;
  faCommentSlash = faCommentSlash;
  faHandsHelping = faHandsHelping;
  faHands = faHands;
  faBook = faBook;
  faEye = faEye;
  faSquare = faSquare;

  public instancePayload: PayloadInterface = null;
  public instanceAttributes: {
    ecoach_ids: number | Array<number>;
    patient_id: number;
    intervention_id: number;
    intervention_type: string;
    configuration: {
      questionnaire_configuration: Array<InterventionInstanceConfigurationInterface>;
      custom_order: Array<number>;
      starting_at: number;
    };
  } = null;

  public myUserId: number;

  public intervention: InterventionInterface;
  public studyMembers: Array<UserInterface> = [];
  public studyCollaborator: Array<UserInterface> = [];
  public instance: InterventionInstanceInterface;
  public formCustomOrder: Array<number> = [];
  public unlockType: Array<string> = ['ALWAYS', 'MANUALLY', 'AFTER_PREVIOUS', 'AT_DATE'];

  // Pages
  public numbersOfPages = [];
  public page = 0;
  public pageSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public page$ = this.pageSubject.asObservable();
  public unconfirmedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  public isManager$: Observable<boolean>;

  public questionnaires: Array<LessonInterface> = [];
  public skills: Array<SkillInterface> = [];

  public diaries: Array<DiaryInterface> = [];
  public diariesOfStudy$: Observable<Array<DiaryInterface>>;

  public updateInstanceResponse$: Observable<any>;

  public skillsOfIntervention$: Observable<SkillInterface[]>;

  private addEcoachesToInterventionInstanceResponse$: Observable<any>;
  private deleteEcoachesToInterventionInstanceResponse$: Observable<any>;

  // Element id
  private elementId: string;
  private positionType: Array<string> = ['-1'];
  private isManagerSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private subscriptions: Subscription[] = [];

  constructor(
    private actions$: Actions,
    private alertService: AlertService,
    private helperService: HelperService,
    private helperSkillService: HelperSkillService,
    private helperDialogService: HelperDialogService,
    private diaryStore: DiaryStore,
    private interventionInstanceStore: InterventionInstanceStore,
    private skillStore: SkillStore,
    private userStore: UserStore,
    private store: Store<{ skills: Array<SkillInterface> }>
  ) {
    this.isManager$ = this.isManagerSubject.asObservable();
    this.diariesOfStudy$ = this.diaryStore.diariesOfStudy$;
    this.updateInstanceResponse$ = this.interventionInstanceStore.updateInstanceResponse$;
    this.skillsOfIntervention$ = this.skillStore.skillsOfIntervention$;
    this.addEcoachesToInterventionInstanceResponse$ = this.userStore.addEcoachesToInterventionInstanceResponse$;
    this.deleteEcoachesToInterventionInstanceResponse$ = this.userStore.deleteEcoachesToInterventionInstanceResponse$;
  }

  // Helper function
  public get helper() {
    return this.helperService;
  }

  public get helperSkill() {
    return this.helperSkillService;
  }

  public get helperDialog() {
    return this.helperDialogService;
  }

  public get alert() {
    return this.alertService;
  }

  @Input()
  set _id(_id) {
    this.elementId = _id;
  }

  // Set study and its configuration from parent
  @Input()
  set _intervention(_intervention: InterventionInterface) {
    this.intervention = _intervention;
  }

  // Set members of study from parent
  @Input()
  set _members(_members: Array<UserInterface>) {
    this.studyMembers = _members;
  }

  // Set collaborators of study from parent
  @Input()
  set _collaborators(_collaborators: Array<UserInterface>) {
    this.studyCollaborator = _collaborators;
  }

  // Set instance from parent
  @Input()
  set _instance(_instance: InterventionInstanceInterface) {
    this.instance = _instance;
  }

  @Input()
  set _payload(_payload: PayloadInterface) {
    this.instancePayload = _payload;
    this.instanceAttributes = _payload.data.attributes;
    console.log(this.instancePayload);
  }

  @Input()
  set _questionnaires(_questionnaires: Array<LessonInterface>) {
    this.questionnaires = _questionnaires;
    // Indexing position of questionnaire
    this.questionnaires.forEach((questionnaire, index) => {
      this.positionType.push(index.toString());
    });
    // Set page number for page element
    this.numbersOfPages = Array(this.questionnaires.length)
      .fill(0)
      .map((x, i) => i);
  }

  @Input()
  set _myUserId(_myUserId: number) {
    this.myUserId = _myUserId;
  }

  ngOnInit(): void {
    this.store.dispatch({
      type: StudyActionTypes.getCollaboratingStudiesType,
      payload: { studyId: this.intervention.attributes.study_id, include: 'roles' }
    });
    this.subscriptions.push(
      this.actions$
        .pipe(
          ofType(StudyActionTypes.getCollaboratingStudiesSuccessType, StudyActionTypes.getCollaboratingStudiesErrorType),
          take(1),
          mergeMap((result: any) =>
            iif(() => result.type === StudyActionTypes.getCollaboratingStudiesSuccessType, of(result), throwError(result))
          ),
          switchMap((result: any) => {
            const studies: Array<StudyInterface> = result['response']['body']['data'];
            const myRoleSlug = this.helper.getHighestRoleOfStudy(studies[0]);
            if (myRoleSlug === 'study.owner' || myRoleSlug === 'study.ecoachmanager') {
              this.isManagerSubject.next(true);
            }
            /*
            this.isManagerSubject.next(!!myRoleSlug.match(/study\.(ecoachmanager|owner)$/));
            this.skillStore.getSkillsOfIntervention({ id: this.intervention.id });
            return this.skillsOfIntervention$;
          }),
          switchMap(result => {
            this.skills = result;
            */
            this.diaryStore.getDiariesOfStudy({ studyId: this.intervention.attributes.study_id });
            return this.diariesOfStudy$.pipe(skip(1), take(1));
          })
        )
        .subscribe(
          (result: any) => {
            this.diaries = result;
          },
          () => {
            this.diaries = [];
          }
        )
    );

    this.formCustomOrder = this.instanceAttributes.configuration.custom_order;
  }

  public getQuestionnaireById(questionnaireId: number): LessonInterface {
    if (questionnaireId !== undefined) {
      const found = this.questionnaires.find(
        (questionnaire: LessonInterface) => questionnaire.id.toString() === questionnaireId.toString()
      );
      return found !== undefined ? found : null;
    }
    return null;
  }

  public getConfigurationByQuestionnaireId(questionnaireId: number): InterventionInstanceConfigurationInterface {
    let found = null;
    this.instanceAttributes.configuration.questionnaire_configuration.forEach(config => {
      if (config.id.toString() === questionnaireId.toString()) {
        found = config;
      }
    });
    return found;
  }

  // Display next page
  public showNextPage(): void {
    const pageNumber = this.pageSubject.getValue();
    if (pageNumber > 0) {
      this.pageSubject.next(pageNumber - 1);
    }
  }

  // Display previous page
  public showPreviousPage(): void {
    const pageNumber = this.pageSubject.getValue();
    if (pageNumber < this.numbersOfPages.length - 1) {
      this.pageSubject.next(pageNumber + 1);
    }
  }

  public updateInterventionInstance(instanceId: number): Observable<any> {
    const removeIds = [];
    const addIds = [];
    const previousIds = this.instance.attributes.ecoach_id.filter(id => id.toString() !== this.myUserId.toString());
    const newIds = this.instancePayload.data.attributes.ecoach_ids.filter(id => id.toString() !== this.myUserId.toString());
    previousIds.forEach(id => {
      const found = newIds.find(foundid => foundid.toString() === id.toString());
      if (found === undefined) {
        removeIds.push(parseInt(id.toString(), 10));
      }
    });
    newIds.forEach(id => {
      const found = previousIds.find(foundid => foundid.toString() === id.toString());
      if (found === undefined) {
        addIds.push(parseInt(id.toString(), 10));
      }
    });
    const reqs: Array<Observable<any>> = [];

    if (removeIds.length !== 0) {
      const payloadRemove: PayloadInterface = new RequestBodyData('intervention_instance', { ecoach_ids: removeIds });
      this.userStore.deleteEcoachesToInterventionInstance({ instanceId: this.instance.id, payload: payloadRemove });
      reqs.push(this.deleteEcoachesToInterventionInstanceResponse$.pipe(skip(1), take(1)));
    }
    if (addIds.length !== 0) {
      const payloadAdd: PayloadInterface = new RequestBodyData('intervention_instance', { ecoach_ids: addIds });
      this.userStore.addEcoachesToInterventionInstance({ instanceId: this.instance.id, payload: payloadAdd });
      reqs.push(this.addEcoachesToInterventionInstanceResponse$.pipe(skip(1), take(1)));
    }
    const res: Observable<any> = reqs.length > 0 ? forkJoin(reqs) : of(true);
    return res.pipe(
      take(1),
      mergeMap(() => {
        if (this.isManagerSubject.value) {
          this.interventionInstanceStore.updateInstanceAsECoachManager({ payload: this.instancePayload, instanceId });
        } else {
          this.interventionInstanceStore.updateInstanceAsECoach({ payload: this.instancePayload, instanceId });
        }
        return this.updateInstanceResponse$.pipe(skip(1), take(1));
      })
    );
  }

  public showUnlockHint(questionnaireId: number): boolean {
    const foundConfig = this.instanceAttributes.configuration.questionnaire_configuration.find(
      (config: InterventionInstanceConfigurationInterface) => config.id.toString() === questionnaireId.toString()
    );
    if (!foundConfig) {
      return false;
    }
    // Previous position based on form_custom_order
    const currentPost = this.instanceAttributes.configuration.custom_order.findIndex(
      value => value.toString() === questionnaireId.toString()
    );
    // const previous_pos = current_pos - 1;
    const previousPos = currentPost - 1;
    let foundPredecessor: InterventionInstanceConfigurationInterface;
    if (previousPos >= 0) {
      foundPredecessor = this.instanceAttributes.configuration.questionnaire_configuration.find(
        (config: InterventionInstanceConfigurationInterface) =>
          config.id.toString() === this.instanceAttributes.configuration.custom_order[previousPos].toString()
      );
    }
    if (foundPredecessor) {
      return foundConfig.unlock_type === 'after_previous' && foundPredecessor.feedback_required;
    }
    return false;
  }

  public getAlreadyStarted(questionnaireId: number): number {
    if (this.instance.attributes.progress.active_questionnaires) {
      return this.instance.attributes.progress.active_questionnaires.find(
        (value: number) => value.toString() === questionnaireId.toString()
      );
    }
    return null;
  }

  public openDialogDiaryPreview(questionnaireId: number, studyId: number, diaryPageColor: string): void {
    this.helperDialog
      .openDialogDiaryPreview(questionnaireId, studyId, diaryPageColor)
      .afterClosed()
      .subscribe(result => {});
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
