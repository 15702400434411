<div class="container-fluid text-center">
  <div class="col-sm-8 text-start">
    <h1>{{ 'feedback-overview.feedback' | translate }}</h1>
  </div>
  <hr />
  <h4>
    {{ 'feedback-overview.overview_of_feedback_answersheet_pending' | translate }}
    <button [attr.id]="'btnToggleDescription'" (click)="helper.toggleSubject(showDescriptionSubject)" aria-label="Information" mat-button>
      <fa-icon [icon]="faInfoCircle"></fa-icon>
    </button>
  </h4>
  <div *ngIf="showDescription$ | async" class="row justify-content-center mb-3">
    <div class="col-sm-9">
      <p class="text-justify">{{ 'feedback-overview.description_feedback' | translate }}</p>
    </div>
  </div>

  <div>
    <router-outlet></router-outlet>
  </div>
</div>
