/* eslint-disable @typescript-eslint/naming-convention */
import { HttpResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { skip, take } from 'rxjs/operators';
import { OrganisationInterface } from '../../../models/interface/organisation/organisation.interface';
import { ProfileInterface } from '../../../models/interface/profile.interface';
import { RoleInterface } from '../../../models/interface/role.interface';
import { UserInterface } from '../../../models/interface/user.interface';
import { HelperService } from '../../../services/helper/helper.service';
import { UserActionTypes } from '../../../store/user/user.action';
import { OrganisationStore } from '../../../store/organisation/component-store/organisation.store';

@Component({
  selector: 'app-dialog-admin-organisation-create',
  templateUrl: './dialog-admin-organisation-create.component.html',
  styleUrls: ['./dialog-admin-organisation-create.component.scss'],
  providers: [OrganisationStore]
})
export class DialogAdminOrganisationCreateComponent implements OnInit, OnDestroy {
  public createOrganisationResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');

  // Form submission
  public groupFormType: UntypedFormGroup;
  public submitted = false;
  public disabled = false;

  // Filter selection
  public filter = {
    userSelection: ''
  };

  public organisations: Array<OrganisationInterface> = [];
  public organisationsSubject: BehaviorSubject<Array<OrganisationInterface>> = new BehaviorSubject<Array<OrganisationInterface>>([]);

  // Data from UserService
  public allECoachesAsAdmin$: Observable<Array<UserInterface>>;
  public allECoachesAsAdmin: Array<UserInterface> = [];

  private users$: Observable<Array<UserInterface>>;

  private allECoachesAsAdminSubject: BehaviorSubject<Array<UserInterface>> = new BehaviorSubject<Array<UserInterface>>([]);

  private createOrganisationResponse$: Observable<any>;

  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private helperService: HelperService,
    private organisationStore: OrganisationStore,
    private store: Store<{
      myProfile: ProfileInterface;
      myRoles: Array<RoleInterface>;
      organisations: Array<OrganisationInterface>;
      users: Array<UserInterface>;
    }>,
    private dialogRef: MatDialogRef<DialogAdminOrganisationCreateComponent>
  ) {
    this.allECoachesAsAdmin$ = this.allECoachesAsAdminSubject.asObservable();
    this.groupFormType = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(255), Validators.minLength(1)]],
      owner_id: [-1]
    });
    this.groupFormType.controls['owner_id'].setValue(-1, { onlySelf: true });
    this.createOrganisationResponse$ = this.organisationStore.createOrganisationResponse$;
    this.users$ = store.select('users');
  }

  get f() {
    return this.groupFormType.controls;
  }

  public get helper() {
    return this.helperService;
  }

  ngOnInit(): void {
    this.store.dispatch({ type: UserActionTypes.getAllUsersAdminType, payload: {} });
    this.subscriptions.push(
      this.users$.subscribe((result: any) => {
        this.allECoachesAsAdmin = result.filter((user: UserInterface) =>
          user.attributes.roles.find((role: { slug: string; description: string }) => role.slug.toString() === 'ecoach')
        );
        this.allECoachesAsAdminSubject.next(this.allECoachesAsAdmin);
        if (this.allECoachesAsAdmin.length > 0) {
          this.groupFormType.controls['owner_id'].setValue(this.allECoachesAsAdmin[0].id, { onlySelf: true });
        }
      })
    );
  }

  public createOrganisation(): void {
    this.submitted = true;
    if (!this.f.name.invalid && this.f.owner_id.value.toString() !== '-1' && this.createOrganisationResponse.value === 'DEFAULT') {
      this.createOrganisationResponse.next('LOADING');
      this.organisationStore.createOrganisationAdmin({
        name: this.f.name.value,
        ownerId: parseInt(this.f.owner_id.value.toString(), 10)
      });
      this.subscriptions.push(
        this.createOrganisationResponse$.pipe(skip(1), take(1)).subscribe((result: any) => {
          if (result instanceof HttpResponse) {
            this.createOrganisationResponse.next('SUCCESS');
            setTimeout(() => {
              this.createOrganisationResponse.next('DEFAULT');
              this.dialogRef.close();
            }, 2500);
          } else {
            this.createOrganisationResponse.next('FAILURE');
            setTimeout(() => {
              this.createOrganisationResponse.next('DEFAULT');
            }, 2500);
          }
        })
      );
    }
  }

  public resetFilter(): void {
    this.filter = {
      userSelection: ''
    };
    this.allECoachesAsAdminSubject.next(this.allECoachesAsAdmin);
    this.organisationsSubject.next(this.organisations);
  }

  public onKeyFilter(): void {
    const filterResults: Array<UserInterface> = this.allECoachesAsAdmin.filter(
      (user: UserInterface) =>
        user.attributes.name?.toLowerCase().includes(this.filter['userSelection'].toLowerCase()) ||
        user.attributes.email.toLowerCase().includes(this.filter['userSelection'].toLowerCase())
    );
    const results =
      filterResults.length !== 0
        ? filterResults
        : [this.helper.findArrObjById(parseInt(this.f.owner_id.value.toString(), 10), this.allECoachesAsAdmin)];
    this.allECoachesAsAdminSubject.next(results);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
