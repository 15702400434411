<div *ngIf="(role$ | async) === 'admin'; else elseShowDefaultGroupView" class="container-fluid text-center">
  <mat-tab-group class="my-3" animationDuration="0ms" mat-stretch-tabs>
    <mat-tab label="{{'group.all_studies' | translate}}">
      <div class="card my-3">
        <div class="card-body">
          <div class="hide_overflow">
            <div class="row mt-3">
              <!-- Filter button -->
              <div class="col-12 col-sm-3 mb-2">
                <button
                  [attr.id]="'btnToggleFilter'"
                  (click)="helper.toggleSubject(isCollapsedSubject)"
                  class="mt-1"
                  mat-raised-button
                  type="submit"
                >
                  <fa-icon [icon]="faList"></fa-icon>
                  Filter
                </button>
              </div>

              <div class="col-12 col-sm-6 mb-2">
                <!-- Search bar -->
                <mat-form-field [style.width.%]="100" appearance="outline">
                  <mat-label>{{'group.group_search' | translate}}</mat-label>
                  <input
                    [attr.id]="'search'"
                    (keyup.enter)="applyGroupFilter()"
                    [(ngModel)]="filter['searchFilter']"
                    aria-describedby="groupSearch"
                    aria-label="Search"
                    matInput
                    type="text"
                  />
                  <button [attr.id]="'btnSearchGroup'" (click)="applyGroupFilter()" aria-label="Search" mat-button mat-button matSuffix>
                    <mat-icon>search</mat-icon>
                  </button>
                </mat-form-field>
              </div>
            </div>

            <app-pagination-list
              #paginatorStudy1
              (emitPageChange)="updatePagedGroups($event)"
              [_isLoading]="isLoading$ | async"
              [_items]="groups$ | async"
            ></app-pagination-list>

            <div class="row justify-content-center">
              <!-- Content of searchFilter - Selections, radio buttons -->
              <div
                *ngIf="(isCollapsed$ | async) === false"
                [ngClass]="{'col-12': (isCollapsed$ | async) === false, 'col-md-3': (isCollapsed$ | async) === false, 'col-align-self-start': (isCollapsed$ | async) === false}"
                class="text-start"
              >
                <div class="mb-2">
                  <p><strong>{{'group.group_collab_filter' | translate}}</strong></p>
                  <mat-checkbox [attr.id]="'checkboxIsCollab'" (change)="applyGroupFilter()" [(ngModel)]="filter['isCollab']"
                    >{{'group.group_collaboration' | translate }}</mat-checkbox
                  >
                </div>
                <mat-divider></mat-divider>
                <div class="my-2">
                  <div class="mt-2">
                    <label id="filter-radio-group-label">{{'group.is_group_private' | translate}}</label>
                    <mat-radio-group
                      [attr.id]="'radioIsPrivate'"
                      aria-label="select private type"
                      aria-labelledby="filter-radio-group-label"
                      class="filter-radio-group"
                      [(ngModel)]="filter['private']"
                      (change)="applyGroupFilter()"
                    >
                      <mat-radio-button [attr.id]="'radioItem2'" class="filter-radio-button" value="2"
                        >{{'group.no_selection' | translate}}</mat-radio-button
                      >
                      <mat-radio-button [attr.id]="'radioItem1'" class="filter-radio-button" value="1"
                        >{{'group.yes' | translate}}</mat-radio-button
                      >
                      <mat-radio-button [attr.id]="'radioItem0'" class="filter-radio-button" value="0"
                        >{{'group.no' | translate}}</mat-radio-button
                      >
                    </mat-radio-group>
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-9">
                <!-- Loader -->
                <div *ngIf="isLoading$ | async; then thenLoadingBlock else elseGroupBlock"></div>

                <ng-template #thenLoadingBlock>
                  <div class="col-sm">
                    <app-loader></app-loader>
                  </div>
                </ng-template>

                <ng-template #elseGroupBlock>
                  <!-- List of groups -->
                  <div
                    *ngFor="let group of pagedGroups$ | async; let i = index; trackBy: helper.trackByElement"
                    class="justify-content-center"
                  >
                    <app-card-study (emitUpdatePerformed)="updateList()" [_study]="group" [_organisations]="organisations"></app-card-study>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="{{'group.all_organisations' | translate}}">
      <div class="card my-3">
        <div class="card-body">
          <app-organisation [reloadEvent]="(reloadEventSubject | async)"></app-organisation>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="{{'group.create_new_group' | translate}}">
      <div class="card my-3">
        <div class="card-body">
          <app-group-creation (newGroupEvent)="reloadGroups($event)"></app-group-creation>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<ng-template #elseShowDefaultGroupView>
  <mat-tab-group class="my-3" animationDuration="0ms" mat-stretch-tabs>
    <mat-tab label="{{'group.all_studies' | translate}}">
      <div class="card my-3">
        <div class="card-body">
          <div class="hide_overflow">
            <div class="row mt-3">
              <!-- Filter button -->
              <div class="col-12 col-sm-3 mb-2">
                <button
                  [attr.id]="'btnToggleFilter'"
                  (click)="helper.toggleSubject(isCollapsedSubject)"
                  class="mt-1"
                  mat-raised-button
                  type="submit"
                >
                  <fa-icon [icon]="faList"></fa-icon>
                  Filter
                </button>
              </div>

              <div class="col-12 col-sm-6 mb-2">
                <!-- Search bar -->
                <mat-form-field [style.width.%]="100" appearance="outline">
                  <mat-label>{{'group.group_search' | translate}}</mat-label>
                  <input
                    [attr.id]="'search'"
                    (keyup.enter)="applyGroupFilter()"
                    [(ngModel)]="filter['searchFilter']"
                    aria-describedby="groupSearch"
                    aria-label="Search"
                    matInput
                    type="text"
                  />
                  <button [attr.id]="'btnSearchGroup'" (click)="applyGroupFilter()" aria-label="Search" mat-button mat-button matSuffix>
                    <mat-icon>search</mat-icon>
                  </button>
                </mat-form-field>
              </div>
            </div>

            <app-pagination-list
              #paginatorStudy2
              (emitPageChange)="updatePagedGroups($event)"
              [_isLoading]="isLoading$ | async"
              [_items]="groups$ | async"
            ></app-pagination-list>

            <div class="row justify-content-center">
              <!-- Content of searchFilter - Selections, radio buttons -->
              <div
                *ngIf="(isCollapsed$ | async) === false"
                [ngClass]="{'col-12': (isCollapsed$ | async) === false, 'col-md-3': (isCollapsed$ | async) === false}"
                class="text-start"
              >
                <div class="mb-2">
                  <h4>{{'group.group_collab_filter' | translate}}</h4>
                  <mat-checkbox [attr.id]="'checkboxIsCollab'" (change)="applyGroupFilter()" [(ngModel)]="filter['isCollab']"
                    >{{'group.group_collaboration' | translate }}</mat-checkbox
                  >
                </div>
                <mat-divider></mat-divider>
                <div class="my-2">
                  <div class="mt-2">
                    <label id="filter-radio-group-label_2">{{'group.is_group_private' | translate}}</label>
                    <mat-radio-group
                      [attr.id]="'radioIsPrivate'"
                      aria-label="select private type"
                      aria-labelledby="filter-radio-group-label_2"
                      class="filter-radio-group"
                      formControlName="private"
                    >
                      <mat-radio-button [attr.id]="'radioItem2'" (change)="applyGroupFilter()" class="filter-radio-button" value="2"
                        >{{'group.no_selection' | translate}}</mat-radio-button
                      >
                      <mat-radio-button [attr.id]="'radioItem1'" (change)="applyGroupFilter()" class="filter-radio-button" value="1"
                        >{{'group.yes' | translate}}</mat-radio-button
                      >
                      <mat-radio-button [attr.id]="'radioItem0'" (change)="applyGroupFilter()" class="filter-radio-button" value="0"
                        >{{'group.no' | translate}}</mat-radio-button
                      >
                    </mat-radio-group>
                  </div>
                </div>
              </div>

              <!-- Loader -->

              <div class="col-12 col-md-9">
                <!-- Loader -->
                <div *ngIf="isLoading$ | async; then thenLoadingBlock else elseGroupBlock"></div>

                <ng-template #thenLoadingBlock>
                  <div class="col-sm">
                    <app-loader></app-loader>
                  </div>
                </ng-template>

                <ng-template #elseGroupBlock>
                  <!-- List of groups -->
                  <div *ngFor="let group of pagedGroups$ | async; let i = index; trackBy: trackByGroupId" class="justify-content-center">
                    <app-card-study
                      (emitUpdatePerformed)="applyGroupFilter()"
                      [_study]="group"
                      [_organisations]="organisations"
                    ></app-card-study>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="{{'group.all_organisations' | translate}}">
      <div class="card my-3">
        <div class="card-body">
          <app-organisation [reloadEvent]="(reloadEventSubject | async)"></app-organisation>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</ng-template>
