import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { catchError, map, mergeMap, skip, take } from 'rxjs/operators';
import { BehaviorSubject, forkJoin, iif, Observable, of, Subscription, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { PayloadInterface } from '../../../models/interface/payload.interface';
import { RequestBodyData } from '../../../models/request-body.data';
import { HelperService } from '../../../services/helper/helper.service';
import { UserInterface } from '../../../models/interface/user.interface';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ErrorInterface } from '../../../models/interface/error.interface';
import { StudyStore } from '../../../store/study/component-store/study.store';
import { StudyActionTypes } from 'src/app/store/study/study.action';
import { getCollaboratorsByStudyId } from 'src/app/store/study/study.selector';

@Component({
  selector: 'app-dialog-organisation-study-collaborator-management',
  templateUrl: './dialog-organisation-study-collaborator-management.component.html',
  styleUrls: ['./dialog-organisation-study-collaborator-management.component.scss'],
  providers: [StudyStore]
})
export class DialogOrganisationStudyCollaboratorManagementComponent implements OnInit, OnDestroy {
  public collaborator: UserInterface;
  public param;
  public studyId: number;
  public organisationId: number;

  public collaborators: Array<UserInterface>;
  public ecoaches: Array<UserInterface>;

  // Filter
  public filter = {
    userSelection: ''
  };

  public ecoachesSubject: BehaviorSubject<Array<UserInterface>> = new BehaviorSubject<Array<UserInterface>>([]);
  public ecoaches$: Observable<Array<UserInterface>> = this.ecoachesSubject.asObservable();

  public collaboratorsSubject: BehaviorSubject<Array<UserInterface>> = new BehaviorSubject<Array<UserInterface>>([]);
  public collaborators$: Observable<Array<UserInterface>> = this.ecoachesSubject.asObservable();

  // List of roles
  public roles = ['study.ecoachmanager', 'study.ecoach', 'study.access'];
  public selectedRole = 'study.access';

  public isOwner = false;
  public isOwnerCollaborator = false;

  public isReviewer = false;
  public isReviewerCollaborator = false;

  // Selected collaborator
  public selectedCollaborator: UserInterface;

  // Form submission
  submitted = false;

  public selectedTabIndex = 0;

  public addCollaboratorResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');
  public removeCollaboratorResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');
  public updateCollaboratorResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');

  public isNotRemovableSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private collaboratorsOfStudy$: Observable<{ studyId: number; collaborators: UserInterface[] }>;

  private addCollaboratorsResponse$: Observable<any>;
  private deleteCollaboratorsResponse$: Observable<any>;

  // Subscription Handler
  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private helperService: HelperService,
    private dialogRef: MatDialogRef<DialogOrganisationStudyCollaboratorManagementComponent>,
    private studyStore: StudyStore,
    private store: Store
  ) {
    this.addCollaboratorsResponse$ = this.studyStore.addCollaboratorsResponse$;
    this.deleteCollaboratorsResponse$ = this.studyStore.deleteCollaboratorsResponse$;
  }

  // Helper function
  public get helper() {
    return this.helperService;
  }

  ngOnInit(): void {
    this.collaborator = this.data.collaborator;
    this.collaborators = this.data.collaborators;
    this.param = this.data.param;
    this.organisationId = this.data.organisationId;
    this.studyId = this.data.studyId;
    this.ecoaches = this.data.ecoaches;

    this.store.dispatch({
      type: StudyActionTypes.getCollaboratorsType,
      payload: { studyId: this.studyId, include: 'roles' }
    });

    this.collaboratorsOfStudy$ = this.store.select(getCollaboratorsByStudyId(this.studyId));
    this.collaboratorsOfStudy$.subscribe((collaboratorsOfStudy: { studyId: number; collaborators: UserInterface[] }) => {
      this.collaborators = collaboratorsOfStudy.collaborators;
    });

    if (this.ecoaches.length > 0) {
      this.selectedCollaborator = this.ecoaches[0];
    }
  }

  // Get all (multiple) roles of the user
  public getRolesOfCollaborator(user: UserInterface) {
    return user.relationships.roles.data;
  }

  public isNoneSelectedAddCollaboratorForm(): boolean {
    return !this.selectedCollaborator && !this.selectedRole;
  }

  // Adding collaborator to study
  public addCollaborator(): void {
    this.submitted = true;
    if (this.addCollaboratorResponse.value === 'DEFAULT') {
      this.addCollaboratorResponse.next('LOADING');
      if (this.selectedCollaborator && this.selectedRole) {
        const addRoles: Array<{ id: number; role: string }> = [];
        addRoles.push({ id: this.selectedCollaborator.id, role: this.selectedRole });

        // If reviewer feature is checked
        if (this.isReviewer) {
          addRoles.push({ id: this.selectedCollaborator.id, role: 'study.publisher' });
        }

        // If owner feature is checked
        if (this.isOwner) {
          addRoles.push({ id: this.selectedCollaborator.id, role: 'study.owner' });
        }
        const payload: PayloadInterface = new RequestBodyData('users', { users: addRoles });

        this.studyStore.addCollaborators({ studyId: this.studyId, payload });
        this.subscriptions.push(
          this.addCollaboratorsResponse$
            .pipe(
              skip(1),
              take(1),
              mergeMap((result: any) => iif(() => result instanceof HttpResponse, of(result), throwError(result)))
            )
            .subscribe(
              () => {
                this.addCollaboratorResponse.next('SUCCESS');
              },
              () => {
                this.addCollaboratorResponse.next('FAILURE');
                setTimeout(() => {
                  this.submitted = false;
                  this.addCollaboratorResponse.next('DEFAULT');
                }, 2500);
              },
              () => {
                setTimeout(() => {
                  this.submitted = false;
                  this.addCollaboratorResponse.next('DEFAULT');
                  this.dialogRef.close('SUCCESS');
                }, 2500);
              }
            )
        );
      }
    }
  }

  public onChangeCollaborator(collaborator: UserInterface): void {
    // Form to add an existing editor to an study with a role
    const roles = this.getRolesOfCollaborator(collaborator);

    if (this.helper.hasRoles(roles, /study\.ecoachmanager$/)) {
      this.selectedRole = this.roles[0];
    }

    if (this.helper.hasRoles(roles, /study\.ecoach$/)) {
      this.selectedRole = this.roles[1];
    }

    if (this.helper.hasRoles(roles, /study\.access$/)) {
      this.selectedRole = this.roles[2];
    }

    this.isReviewerCollaborator = this.helper.hasRoles(roles, /study\.publisher$/);

    this.isOwnerCollaborator = this.helper.hasRoles(roles, /study\.owner$/);
  }

  public isNoneSelectedConfigureRoleForm(): boolean {
    return !this.selectedCollaborator && !this.selectedRole;
  }

  // Adding collaborator to study
  public updateCollaborator(): void {
    this.submitted = true;
    if (this.updateCollaboratorResponse.value === 'DEFAULT') {
      this.updateCollaboratorResponse.next('LOADING');
      if (this.selectedCollaborator && this.selectedRole) {
        const removingRoles = this.roles.filter((role: string) => role !== this.selectedRole);
        const addRoles: Array<{ id: number; role: string }> = [];
        const removeRoles: Array<{ id: number; role: string }> = [];
        addRoles.push({ id: this.selectedCollaborator.id, role: this.selectedRole });
        if (this.isReviewerCollaborator) {
          addRoles.push({ id: this.selectedCollaborator.id, role: 'study.publisher' });
        } else {
          removeRoles.push({ id: this.selectedCollaborator.id, role: 'study.publisher' });
        }
        if (this.isOwnerCollaborator) {
          addRoles.push({ id: this.selectedCollaborator.id, role: 'study.owner' });
        } else {
          removeRoles.push({ id: this.selectedCollaborator.id, role: 'study.owner' });
        }
        removingRoles.forEach((role: string) => {
          removeRoles.push({ id: this.selectedCollaborator.id, role });
        });
        const payloadRemove: PayloadInterface = new RequestBodyData('users', { users: removeRoles });
        const payloadAdd: PayloadInterface = new RequestBodyData('users', { users: addRoles });

        const reqs = [];

        this.studyStore.addCollaborators({ studyId: this.studyId, payload: payloadAdd });
        reqs.push(
          this.addCollaboratorsResponse$.pipe(
            skip(1),
            take(1),
            mergeMap((result: any) => iif(() => result instanceof HttpResponse, of(result), throwError(result)))
          )
        );

        this.studyStore.deleteCollaborators({ studyId: this.studyId, payload: payloadRemove });
        reqs.push(
          this.deleteCollaboratorsResponse$.pipe(
            skip(1),
            take(1),
            mergeMap((result: any) => iif(() => result instanceof HttpResponse, of(result), throwError(result)))
          )
        );

        this.subscriptions.push(
          forkJoin(reqs)
            .pipe(
              map(result => result),
              catchError(error => throwError(error))
            )
            .subscribe(
              () => {
                this.updateCollaboratorResponse.next('SUCCESS');
                this.isNotRemovableSubject.next(false);
              },
              error => {
                this.updateCollaboratorResponse.next('FAILURE');
                if (typeof error.error !== 'string' && error.error && error instanceof HttpErrorResponse && error.error.errors) {
                  const errorData: ErrorInterface = error.error.errors[0];
                  if (errorData.status.toString() === '403' && errorData.detail.includes('is the only one guiding an intervention')) {
                    this.isNotRemovableSubject.next(true);
                  }
                }
                setTimeout(() => {
                  this.submitted = false;
                  this.updateCollaboratorResponse.next('DEFAULT');
                }, 2500);
              },
              () => {
                setTimeout(() => {
                  this.submitted = false;
                  this.updateCollaboratorResponse.next('DEFAULT');
                  this.dialogRef.close('SUCCESS');
                }, 2500);
              }
            )
        );
      }
    }
  }

  // Removing collaborator from study
  public removeCollaborator(): void {
    this.submitted = true;
    if (this.removeCollaboratorResponse.value === 'DEFAULT' && this.selectedCollaborator) {
      this.removeCollaboratorResponse.next('LOADING');
      const payload: PayloadInterface = new RequestBodyData('users', { users: [{ id: this.selectedCollaborator.id }] });

      this.studyStore.deleteCollaborators({ studyId: this.studyId, payload });
      this.subscriptions.push(
        this.deleteCollaboratorsResponse$
          .pipe(
            skip(1),
            take(1),
            mergeMap((result: any) => iif(() => result instanceof HttpResponse, of(result), throwError(result)))
          )
          .subscribe(
            () => {
              this.removeCollaboratorResponse.next('SUCCESS');
              this.isNotRemovableSubject.next(false);
            },
            error => {
              this.removeCollaboratorResponse.next('FAILURE');
              if (typeof error.error !== 'string' && error.error && error instanceof HttpErrorResponse && error.error.errors) {
                const errorData: ErrorInterface = error.error.errors[0];
                if (errorData.status.toString() === '403' && errorData.detail.includes('is the only one guiding an intervention')) {
                  this.isNotRemovableSubject.next(true);
                }
              }
              setTimeout(() => {
                this.submitted = false;
                this.removeCollaboratorResponse.next('DEFAULT');
              }, 2500);
            },
            () => {
              setTimeout(() => {
                this.submitted = false;
                this.removeCollaboratorResponse.next('DEFAULT');
                this.dialogRef.close('SUCCESS');
              }, 2500);
            }
          )
      );
    }
  }

  public setSelectedTabIndex(event): void {
    this.selectedTabIndex = event;
    this.isNotRemovableSubject.next(false);
  }

  public onKeyFilterECoaches(): void {
    const filterResults: Array<UserInterface> = this.ecoaches.filter(
      (user: UserInterface) =>
        this.helper.getCodeNameEmail(user.id, this.ecoaches).toLowerCase().includes(this.filter['userSelection'].toLowerCase()) ||
        user.attributes.email.toLowerCase().includes(this.filter['userSelection'].toLowerCase())
    );
    this.ecoachesSubject.next(filterResults.length !== 0 ? filterResults : [this.selectedCollaborator]);
  }

  public onKeyFilterCollaborators(): void {
    const filterResults: Array<UserInterface> = this.collaborators.filter(
      (user: UserInterface) =>
        user.attributes.name?.toLowerCase().includes(this.filter['userSelection'].toLowerCase()) ||
        user.attributes.email.toLowerCase().includes(this.filter['userSelection'].toLowerCase())
    );
    this.collaboratorsSubject.next(filterResults.length !== 0 ? filterResults : [this.selectedCollaborator]);
  }

  public resetFilter(): void {
    this.filter = {
      userSelection: ''
    };
    this.ecoachesSubject.next(this.ecoaches);
    this.collaboratorsSubject.next(this.collaborators);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
