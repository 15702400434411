<div class="container text-start mt-3">
  <div class="text-center mb-3">
    <h4>
      {{'organisation-study-creation.form_create_group' | translate}}
      <button (click)="helper.toggleSubject(showDescriptionSubject)" aria-label="Information" mat-button>
        <fa-icon [icon]="faInfoCircle"></fa-icon>
      </button>
    </h4>
    <div *ngIf="showDescription$ | async" class="row justify-content-center">
      <div class="col-sm-9">
        <p class="text-justify">{{ 'organisation-study-creation.form_create_group_information' | translate }}</p>
      </div>
    </div>
  </div>

  <!-- Title and description -->
  <div class="form-group">
    <strong>{{'organisation-study-creation.form_create_group_name_grouptype_description' | translate}}</strong>
  </div>

  <form [formGroup]="groupFormType">
    <div class="form-container">
      <mat-form-field appearance="outline" class="half-width">
        <mat-label>{{'organisation-study-creation.form_create_group_name' | translate}}</mat-label>
        <input [attr.id]="'name'" formControlName="name" matInput type="text" />
        <div *ngIf="submitted && f.name.invalid">
          <mat-error *ngIf="f.name.errors?.required">{{'organisation-study-creation.error_is_required' | translate}}</mat-error>
          <mat-error *ngIf="f.name.errors?.maxlength">{{'organisation-study-creation.error_max_255_characters' | translate}}</mat-error>
        </div>
      </mat-form-field>
    </div>

    <hr />

    <div>
      <div class="mb-3">
        <div class="form-group">
          <div></div>
          <strong>{{'organisation-study-creation.form_create_study_owner_description' | translate}}</strong>
        </div>
        <div class="form-group row">
          <div class="col-sm-8">
            <mat-form-field appearance="outline">
              <mat-label>{{'organisation-study-creation.form_select_owner' | translate}}</mat-label>
              <mat-select [attr.id]="'selectOwner'" (click)="resetFilter()" formControlName="owner_id" required>
                <mat-form-field class="full-width" appearance="fill">
                  <mat-label>{{'intervention-collaborator.filter_selection' | translate}}</mat-label>
                  <input
                    [attr.id]="'userSelection'"
                    autocomplete="off"
                    matInput
                    (keyup)="onKeyFilter()"
                    [(ngModel)]="filter['userSelection']"
                    [ngModelOptions]="{standalone: true}"
                  />
                </mat-form-field>
                <mat-option [attr.id]="'owner_' + owner.id" *ngFor="let owner of (ecoaches$ | async)" [value]="owner.id"
                  >{{owner?.attributes.email | translate}}</mat-option
                >
                <mat-option *ngIf="allECoaches.length < 1">{{'organisation-study-creation.no_ecoach_available' | translate}}</mat-option>
              </mat-select>
              <mat-error *ngIf="submitted && (f.owner_id.value < 0)"
                >{{'organisation-study-creation.error_selection_needed' | translate}}</mat-error
              >
            </mat-form-field>
          </div>
        </div>
        <hr />
      </div>
    </div>

    <!-- Set group picture -->
    <div *ngIf="isOwnerAndECoach()">
      <!-- Privacy settings for groups -->
      <div class="mb-3">
        <div class="form-group">
          <strong>{{'organisation-study-creation.form_create_group_privacy_description' | translate}}</strong>
        </div>
        <mat-radio-group
          [attr.id]="'radioIsPrivate'"
          (change)="showIsPrivate($event)"
          aria-label="select an option"
          formControlName="is_private"
        >
          <mat-radio-button [attr.id]="'radioItem0'" value="0">{{'organisation-study-creation.no' | translate}}</mat-radio-button>
          <mat-radio-button [attr.id]="'radioItem1'" value="1">{{'organisation-study-creation.yes' | translate}}</mat-radio-button>
        </mat-radio-group>

        <div *ngIf="(selectedIsPrivate$ | async) === '1'" class="form-container">
          <mat-form-field appearance="outline">
            <mat-label>{{'organisation-study-creation.form_create_group_access_type' | translate}}</mat-label>
            <mat-select
              [attr.id]="'radioAccessType'"
              (selectionChange)="showAccessType(groupFormType.value.accesstype)"
              formControlName="accesstype"
              required
            >
              <mat-option [attr.id]="'option_' + type" *ngFor="let type of accessType" [value]="type"
                >{{('organisation-study-creation.' + type.toLowerCase()) | translate}}</mat-option
              >
            </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="((selectedAccessType$ | async) === 'PASSWORD')" appearance="outline" class="half-width">
            <mat-label>{{'organisation-study-creation.password' | translate}}</mat-label>
            <input [attr.id]="'password'" formControlName="password" matInput type="text" />
            <div *ngIf="submitted && f.password.invalid">
              <mat-error *ngIf="f.password.errors?.required">{{'organisation-study-creation.error_is_required' | translate}}</mat-error>
              <mat-error *ngIf="f.password.errors?.maxlength"
                >{{'organisation-study-creation.error_max_255_characters' | translate}}</mat-error
              >
            </div>
          </mat-form-field>
        </div>

        <hr />
      </div>

      <div class="form-group">
        <strong>{{'organisation-study-creation.form_create_set_group_picture' | translate}}</strong>
      </div>

      <div class="form-group row justify-content-center">
        <div class="image-preview mb-3">
          <img [src]="displayImagePreview()" (error)="helper.getFallbackImage($event)" />
        </div>
        <div *ngIf="fileUploadProgress" class="progress form-group">
          <div [style.width.%]="fileUploadProgress" class="progress-bar progress-bar-striped bg-success" role="progressbar"></div>
        </div>
      </div>

      <!-- Upload group picture -->
      <div class="mt-2 mb-2 ml-2 mr-2">
        <div class="row">
          <div class="custom-file form-group col-12 col-sm-8 mb-2">
            <input
              [attr.id]="'image'"
              #fileInputPicture
              (change)="processFile($event)"
              accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml"
              class="custom-file-input"
              id="customFileStudyMedia"
              name="image"
              type="file"
            />
            <label
              [attr.data-browse]="('organisation-study-creation.browse' | translate)"
              class="custom-file-label text-start"
              for="customFileStudyMedia"
              >{{uploadedFilePath}}</label
            >
          </div>
          <div class="form-group col-12 col-sm-4 mb-2">
            <button [attr.id]="'btnClearImage'" (click)="clearImage()" class="spacing-right" mat-button>
              {{'organisation-study-creation.clear' | translate}}
            </button>
          </div>
        </div>
      </div>

      <hr />
    </div>

    <!-- Title and description -->
    <div *ngIf="isOwnerAndECoach()">
      <div class="form-group">
        <strong>{{'organisation-study-creation.form_create_group_translations_description' | translate}}</strong>
      </div>
      <div class="row">
        <!-- Filter button -->
        <div class="col-sm-6">
          <div>
            <mat-form-field appearance="outline" class="text-width">
              <mat-label>{{'organisation-study-creation.form_create_group_title_german' | translate}}</mat-label>
              <input [attr.id]="'deTitle'" formControlName="deTitle" matInput type="text" />
              <div *ngIf="submitted && f.deTitle.invalid">
                <mat-error *ngIf="f.deTitle.errors?.required">{{'organisation-study-creation.error_is_required' | translate}}</mat-error>
                <mat-error *ngIf="f.deTitle.errors?.maxlength"
                  >{{'organisation-study-creation.error_max_255_characters' | translate}}</mat-error
                >
              </div>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field appearance="outline" class="text-width">
              <mat-label>{{'organisation-study-creation.form_create_group_description_german' | translate}}</mat-label>
              <textarea [attr.id]="'deDescription'" formControlName="deDescription" matInput maxlength="255" rows="3"></textarea>
              <div *ngIf="submitted && f.deDescription.invalid">
                <mat-error *ngIf="f.deDescription.errors?.required"
                  >{{'organisation-study-creation.error_is_required' | translate}}</mat-error
                >
                <mat-error *ngIf="f.deDescription.errors?.maxlength"
                  >{{'organisation-study-creation.error_max_255_characters' | translate}}</mat-error
                >
              </div>
            </mat-form-field>
          </div>
        </div>

        <div class="col-sm-6">
          <div>
            <mat-form-field appearance="outline" class="text-width">
              <mat-label>{{'organisation-study-creation.form_create_group_title_english' | translate}}</mat-label>
              <input [attr.id]="'enTitle'" formControlName="enTitle" matInput type="text" />
              <div *ngIf="submitted && f.enTitle.invalid">
                <mat-error *ngIf="f.enTitle.errors?.required">{{'organisation-study-creation.error_is_required' | translate}}</mat-error>
                <mat-error *ngIf="f.enTitle.errors?.maxlength"
                  >{{'organisation-study-creation.error_max_255_characters' | translate}}</mat-error
                >
              </div>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field appearance="outline" class="text-width">
              <mat-label>{{'organisation-study-creation.form_create_group_description_english' | translate}}</mat-label>
              <textarea [attr.id]="'enDescription'" formControlName="enDescription" matInput maxlength="255" rows="3"></textarea>
              <div *ngIf="submitted && f.enDescription.invalid">
                <mat-error *ngIf="f.enDescription.errors?.required"
                  >{{'organisation-study-creation.error_is_required' | translate}}</mat-error
                >
                <mat-error *ngIf="f.enDescription.errors?.maxlength"
                  >{{'organisation-study-creation.error_max_255_characters' | translate}}</mat-error
                >
              </div>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <div class="row text-end mb-1 justify-content-end">
      <div class="col align-self-end">
        <button [attr.id]="'btnCreateGroup'" (click)="createGroup()" [disabled]="disabled" mat-button type="submit">
          {{'organisation-study-creation.form_create_study_submit' | translate}}
        </button>
      </div>
    </div>
  </form>
</div>
