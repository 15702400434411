<mat-card appearance="outlined" [attr.id]="'answersheet_' + answersheet.id" class="card mx-auto mb-3">
  <mat-card-header class="card-header-center">
    <mat-card-title
      >{{'instance-detail.answersheet_of_lesson' | translate}} '{{helper.findArrObjById(answersheet.attributes.questionnaire_id,
      questionnaires)?.attributes.name}} '
    </mat-card-title>
    <mat-card-subtitle>
      <!-- Feedback required -->
      <div *ngIf="helperFeedback.isFeedbackRequired(answersheet.id, instance); then thenShowIsRequired else elseShowNotRequired"></div>
      <ng-template #thenShowIsRequired>
        <div *ngIf="helperFeedback.hasFeedback(answersheet.id, threads); then thenShowFeedback else elseShowFeedbackRequired"></div>
        <ng-template #thenShowFeedback>
          <div
            *ngIf="helperFeedback.isRead(answersheet.id, instance, getThreadByAnswersheet(answersheet.id)); then thenIsRead else elseNotRead"
          ></div>
          <ng-template #thenIsRead>
            <p>
              <span class="text-success font-weight-bold">
                <fa-icon [icon]="faCheckCircle"></fa-icon>
                {{'instance-detail.feedback_read' | translate}}
              </span>
            </p>
          </ng-template>
          <ng-template #elseNotRead>
            <p>
              <span class="text-info font-weight-bold">
                <fa-icon [icon]="faCircle"></fa-icon>
                {{'instance-detail.feedback_not_read' | translate}}
              </span>
            </p>
          </ng-template>
        </ng-template>
        <ng-template #elseShowFeedbackRequired>
          <p>
            <span class="text-danger font-weight-bold">
              <fa-icon [icon]="faComment"></fa-icon>
              {{'instance-detail.answersheet_feedback_mandatory' | translate}}
            </span>
          </p>
        </ng-template>
      </ng-template>
      <ng-template #elseShowNotRequired>
        <small>
          <span class="font-weight-light">
            <fa-icon [icon]="faCommentSlash"></fa-icon>
            {{'instance-detail.answersheet_feedback_optional' | translate}}
          </span>
        </small>
      </ng-template>

      <!-- Read or Unread -->
      <p>
        <span
          *ngIf="(getThreadByAnswersheet(answersheet.id)?.attributes.unread.is_unread) && (getThreadByAnswersheet(answersheet.id)?.attributes.unread.messages > 0)"
        >
          <fa-icon [icon]="faEnvelope"></fa-icon>
          {{'instance-detail.new_messages' | translate}} : {{getThreadByAnswersheet(answersheet.id)?.attributes.unread.messages}}</span
        >
      </p>
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <p
      *ngIf="helper.findArrObjById(instance.attributes.patient_id, studyMembers) !== null && answersheet.attributes.intervention_instance_id !== null && instance !== null; else elseNoUserFound"
      class="mb-1"
    >
      {{'instance-detail.answersheet_submitted_by' | translate}} {{helper.getCodeNameEmail(instance.attributes.patient_id, studyMembers,
      instance.attributes.study_id)}}
    </p>
    <ng-template #elseNoUserFound> {{('instance-detail.answersheet_no_user_found' | translate)}} </ng-template>
    <p class="mb-1">
      {{'instance-detail.answersheet_collected_at' | translate}} {{helper.localizeDateTimestamp(answersheet.attributes.collected_at)}}
    </p>

    <!-- Feedback information only -->
    <div *ngIf="(getThreadByAnswersheet(answersheet.id) !== null)" class="card border-info mt-3">
      <div *appVariable="getThreadByAnswersheet(answersheet.id) as thread" class="card-body text-info text-start">
        <p class="card-title mb-1">
          <strong
            >{{((thread.attributes.subject) ? thread.attributes.subject : 'instance-detail.no_intervention_name') | translate}}</strong
          >
        </p>
        <p class="card-text mb-1">
          {{'instance-detail.feedback_created_at' | translate}} {{helper.localizeDateString(thread.attributes.created_at)}}
        </p>
        <p class="card-text mb-1">
          {{'instance-detail.feedback_updated_at' | translate}} {{helper.localizeDateString(thread.attributes.updated_at)}}
        </p>
      </div>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <div *ngIf="!helperFeedback.hasFeedback(answersheet.id, threads); then thenShowAnswersheetDetails else elseShowFeedback"></div>

    <ng-template #thenShowAnswersheetDetails>
      <button
        [attr.id]="'btnGetAnswersheetDetails_' + answersheet.id"
        (click)="getAnswersheetDetails(answersheet.id)"
        color="primary"
        mat-button
      >
        {{'instance-detail.answersheet_see_answers' | translate}}
      </button>
    </ng-template>
    <ng-template #elseShowFeedback>
      <button
        [attr.id]="'btnGetAnswersheetDetails_' + answersheet.id"
        (click)="getAnswersheetDetails(answersheet.id)"
        color="primary"
        mat-button
      >
        {{'instance-detail.show_feedback' | translate}}
      </button>
    </ng-template>

    <!-- Send reminder to unread feedback -->
    <app-button-feedback
      [_setId]="'btnSendReminderUnreadFeedback_' + answersheet.id"
      *ngIf="helperFeedback.isFeedbackRequired(answersheet.id, instance) && helperFeedback.hasFeedback(answersheet.id, threads) && !helperFeedback.isRead(answersheet.id, instance, getThreadByAnswersheet(answersheet.id))"
      (click)="sendReminderUnreadFeedback(answersheet)"
      [_defaultButtonText]="'instance-detail.send_reminder_unread_feedback'"
      [_responseText]="sendFeedbackReminderResponse | async"
    ></app-button-feedback>

    <button
      [attr.id]="'btnOpenDialogAnswersheetReport_' + answersheet.id"
      *ngIf="isGuidingECoach"
      (click)="openDialogAnswersheetReport(answersheet)"
      color="primary"
      mat-button
    >
      {{'answersheet-report.download_pdf' | translate}}
    </button>
  </mat-card-actions>
</mat-card>
