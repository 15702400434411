import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { skip, take } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserInterface } from '../../../models/interface/user.interface';
import { HttpResponse } from '@angular/common/http';
import { OrganisationStore } from '../../../store/organisation/component-store/organisation.store';

@Component({
  selector: 'app-dialog-organisation-account-delete',
  templateUrl: './dialog-organisation-account-delete.component.html',
  styleUrls: ['./dialog-organisation-account-delete.component.scss'],
  providers: [OrganisationStore]
})
export class DialogOrganisationAccountDeleteComponent implements OnInit, OnDestroy {
  public patient: UserInterface;
  public myUserId: number;
  public organisationId: number;

  public removeUnverifiedAccountResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');

  private deleteUnverifiedOrganisationAccountResponse$: Observable<any>;

  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private organisationStore: OrganisationStore,
    private dialogRef: MatDialogRef<DialogOrganisationAccountDeleteComponent>
  ) {
    this.deleteUnverifiedOrganisationAccountResponse$ = this.organisationStore.deleteUnverifiedOrganisationAccountResponse$;
  }

  ngOnInit(): void {
    this.organisationId = this.data.organisationId;
    this.patient = this.data.user;
    this.myUserId = this.data.myUserId;
  }

  // Remove unverified account as eCoach
  public removeUnverifiedAccount(): void {
    if (this.removeUnverifiedAccountResponse.value === 'DEFAULT') {
      this.removeUnverifiedAccountResponse.next('LOADING');
      this.organisationStore.deleteUnverifiedOrganisationAccount({ organisationId: this.organisationId, userIds: [this.patient.id] });
      this.subscriptions.push(
        this.deleteUnverifiedOrganisationAccountResponse$.pipe(skip(1), take(1)).subscribe(
          result => {
            if (result instanceof HttpResponse) {
              this.removeUnverifiedAccountResponse.next('SUCCESS');
              setTimeout(() => {
                this.removeUnverifiedAccountResponse.next('DEFAULT');
                this.dialogRef.close('SUCCESS');
              }, 2500);
            } else {
              this.removeUnverifiedAccountResponse.next('FAILURE');
              setTimeout(() => {
                this.removeUnverifiedAccountResponse.next('DEFAULT');
              }, 2500);
            }
          },
          () => {
            this.removeUnverifiedAccountResponse.next('FAILURE');
            setTimeout(() => {
              this.removeUnverifiedAccountResponse.next('DEFAULT');
            }, 2500);
          }
        )
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
