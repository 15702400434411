<div class="container-fluid text-center">
  <div class="text-start">
    <h1>{{ 'group-overview.groups' | translate }}</h1>
    <hr />
  </div>
  <h4>
    {{ 'group-overview.overview_of_groups' | translate }}
    <button [attr.id]="'btnToggleDescription'" mat-button aria-label="Information" (click)="helper.toggleSubject(showDescriptionSubject)">
      <fa-icon [icon]="faInfoCircle"></fa-icon>
    </button>
  </h4>
  <div *ngIf="showDescription$ | async" class="row justify-content-center">
    <div class="col-sm-9">
      <p class="text-justify">{{ 'group-overview.description_group_overview' | translate }}</p>
    </div>
  </div>

  <div>
    <app-group></app-group>
  </div>
</div>
