import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { Observable, BehaviorSubject, take, Subscription, skip } from 'rxjs';
import { OrganisationInterface } from '../../../models/interface/organisation/organisation.interface';
import { StudyInterface } from '../../../models/interface/study/study.interface';
import { UserInterface } from '../../../models/interface/user.interface';
import { HelperService } from '../../../services/helper/helper.service';
import { StudyStore } from '../../../store/study/component-store/study.store';

@Component({
  selector: 'app-dialog-admin-organisation-detail',
  templateUrl: './dialog-admin-organisation-detail.component.html',
  providers: [StudyStore]
})
export class DialogAdminOrganisationDetailComponent implements OnInit, OnDestroy {
  @ViewChild('paginatorStudies') paginatorStudies;
  @ViewChild('paginatorOrganisationCollaborators') paginatorOrganisationCollaborators;

  // Icons
  faEnvelope = faEnvelope;

  public isLoading$: Observable<boolean>;

  public organisation: OrganisationInterface;

  public studiesContent$: Observable<Array<StudyInterface>>;
  public studiesSubject: BehaviorSubject<Array<StudyInterface>> = new BehaviorSubject<Array<StudyInterface>>([]);
  public organisationCollaboratorsContent$: Observable<Array<UserInterface>>;
  public organisationCollaboratorsSubject: BehaviorSubject<Array<UserInterface>> = new BehaviorSubject<Array<UserInterface>>([]);

  public pagedStudies$: Observable<Array<StudyInterface>>;
  public pagedOrganisationCollaborators$: Observable<Array<UserInterface>>;

  public pagedStudiesSubject: BehaviorSubject<Array<StudyInterface>> = new BehaviorSubject<Array<StudyInterface>>([]);
  public pagedOrganisationCollaboratorsSubject: BehaviorSubject<Array<UserInterface>> = new BehaviorSubject<Array<UserInterface>>([]);

  public allStudies$: Observable<StudyInterface[]>;

  public param = { name: '...' };

  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private subscriptions: Subscription[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private helperService: HelperService, private studyStore: StudyStore) {
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.studiesContent$ = this.studiesSubject.asObservable();
    this.pagedStudies$ = this.pagedStudiesSubject.asObservable();
    this.pagedOrganisationCollaborators$ = this.pagedOrganisationCollaboratorsSubject.asObservable();
    this.allStudies$ = this.studyStore.allStudies$;
  }

  public get helper() {
    return this.helperService;
  }

  ngOnInit(): void {
    this.organisation = this.data.organisation;
    this.param.name = this.organisation.attributes.name;
    this.studyStore.getAllStudies({ type: 'study', organisation_id: this.organisation.id });
    this.subscriptions.push(
      this.allStudies$.pipe(skip(1), take(1)).subscribe((result: any) => {
        this.studiesSubject.next(result);
        this.pagedStudiesSubject.next(this.studiesSubject.value.slice(0, 20));
        this.isLoadingSubject.next(false);
      })
    );
  }

  public updatePagedStudies(event: any) {
    if (event) {
      this.pagedStudiesSubject.next(event);
    }
  }

  public updatePagedOrganisationCollaborators(event: any) {
    if (event) {
      this.pagedOrganisationCollaboratorsSubject.next(event);
    }
  }

  public trackByJobId(index: number, element: any): number {
    return element.id;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
