import { createReducer, on } from '@ngrx/store';
import { addAnnouncementsStore, updateAnnouncementsStore, removeAnnouncementsStore } from './announcement.action';

import { AnnouncementInterface } from '../../models/interface/announcement.interface';

export const initialAnnouncementsState: Array<AnnouncementInterface> = [];

export const announcementReducer = createReducer(
  initialAnnouncementsState,
  on(addAnnouncementsStore, (state, { announcements }) => announcements),
  on(updateAnnouncementsStore, (state, { announcements }) => announcements),
  on(removeAnnouncementsStore, (state, {}) => [])
);
