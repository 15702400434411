<button [attr.id]="'btnDialogClose'" mat-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title>{{'group-member.modal_title_add_update_study_code' | translate}}</h1>
<div class="content-height" mat-dialog-content>
  <p>{{'group-member.modal_body_add_update_study_code' | translate}}:</p>
  <p>
    <fa-icon [icon]="faEnvelope"></fa-icon>
    {{user.attributes.email}}
  </p>

  <form class="mb-3">
    <mat-form-field appearance="outline" class="code_input-width">
      <mat-label [attr.for]="'insertStudyCode' + user.id">{{'group-member.modal_current_study_code' | translate}}: </mat-label>
      <input [attr.id]="'insertStudyCode' + user.id" [formControl]="studyCodeControl" matInput type="text" />
      <mat-error *ngIf="(invalidUpdatedCodeSubject | async)"> {{'group-member.modal_study_code_error' | translate}} </mat-error>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{'group-member.cancel' | translate}}</button>
  <app-button-feedback
    [attr.id]="'btnUpdateStudyCode'"
    (click)="updateStudyCode(user)"
    [_isDisabled]="!(studyCodeControl.value && studyCodeControl.value !== '')"
    [_defaultButtonText]="'group-member.modal_study_code_save'"
    [_responseText]="updateStudyCodeResponse | async"
  ></app-button-feedback>
</div>
