<div *ngIf="(isLoading$ | async) === false" class="container">
  <div class="jumbotron text-center hoverable p-3">
    <div class="center-content mb-3">
      <mat-card appearance="outlined" class="card mx-auto mb-3">
        <mat-card-header class="text-center">
          <mat-card-title>{{'patient-detail.my_patients_details' | translate}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row">
            <div class="col-sm-4"><strong>{{'patient-detail.my_patients_email' | translate}}:</strong></div>
            <div class="col-sm-8"><p>{{user.attributes.email}}</p></div>

            <div class="col-sm-4"><strong>{{'patient-detail.my_patients_username' | translate}}:</strong></div>
            <div class="col-sm-8">
              <p>{{(user.attributes.name) ? user.attributes.name : ('patient-detail.my_patients_no_username' | translate)}}</p>
            </div>

            <div class="col-sm-4"><strong>{{'patient-detail.my_patients_account_status' | translate}}:</strong></div>
            <div class="col-sm-8">
              <div *ngIf="(user.attributes.is_verified === 1); then thenShowVerified else elseShowNotVerified"></div>
              <ng-template #thenShowVerified>
                <p class="text-center text-success">
                  <fa-icon [icon]="faCheckCircle"></fa-icon>
                  {{'patient-detail.is_verified' | translate}}
                </p>
              </ng-template>
              <ng-template #elseShowNotVerified>
                <p class="text-center text-danger">
                  <fa-icon [icon]="faTimesCircle"></fa-icon>
                  {{'patient-detail.is_not_verified' | translate}}
                </p>
              </ng-template>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <mat-tab-group
      (selectedTabChange)="onChangeTab($event)"
      animationDuration="0ms"
      backgroundColor="primary"
      color="accent"
      #matTabGroup
      mat-stretch-tabs
    >
      <mat-tab label="{{'patient-detail.studies' | translate}}"></mat-tab>
      <mat-tab label="{{'patient-detail.instances' | translate}}"></mat-tab>
      <mat-tab label="{{'patient-detail.diaries' | translate}}"></mat-tab>
    </mat-tab-group>

    <!-- Child components of study-detail -->
    <div class="card">
      <div class="card-body">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
