import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { InvitationInterface } from '../../../models/interface/invitation.interface';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HelperService } from '../../../services/helper/helper.service';
import { BehaviorSubject, forkJoin, iif, mergeMap, Observable, of, skip, Subscription, take, throwError } from 'rxjs';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { UserInterface } from '../../../models/interface/user.interface';
import { StudyInterface } from '../../../models/interface/study/study.interface';
import { StudyActionTypes } from '../../../store/study/study.action';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { UserActionTypes } from '../../../store/user/user.action';

@Component({
  selector: 'app-dialog-patient-invitations',
  templateUrl: './dialog-patient-invitations.component.html',
  styleUrls: ['./dialog-patient-invitations.component.scss']
})
export class DialogPatientInvitationsComponent implements OnInit, OnDestroy {
  @ViewChild('paginator') paginator;

  // Icons
  faSearch = faSearch;
  faInfoCircle = faInfoCircle;
  faEnvelope = faEnvelope;
  faCheckCircle = faCheckCircle;
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;
  faEdit = faEdit;

  public user: UserInterface;

  public pendingInvitations$: Observable<Array<InvitationInterface>>;
  public pendingInvitationsSubject: BehaviorSubject<Array<InvitationInterface>> = new BehaviorSubject<Array<InvitationInterface>>([]);
  public pagedInvitationsSubject: BehaviorSubject<Array<InvitationInterface>> = new BehaviorSubject<Array<InvitationInterface>>([]);
  public pendingInvitationCode: Array<string> = [];

  public studies: Array<StudyInterface> = [];

  public isLoading$: Observable<boolean>;

  private myUserId: number;

  private allInvitations$: Observable<Array<InvitationInterface>>;

  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private helperService: HelperService,
    private actions$: Actions,
    private store: Store<{ allInvitations: Array<InvitationInterface> }>
  ) {
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.pendingInvitations$ = this.pendingInvitationsSubject.asObservable();
    this.allInvitations$ = this.store.select('allInvitations');
  }

  public get helper() {
    return this.helperService;
  }

  ngOnInit(): void {
    this.user = this.data.user;
    this.myUserId = this.data.myUserId;
    const reqs: Array<Observable<any>> = [];
    this.store.dispatch({ type: StudyActionTypes.getCollaboratingStudiesType, payload: { type: 'study', include: 'roles' } });
    reqs.push(
      this.actions$.pipe(
        ofType(StudyActionTypes.getCollaboratingStudiesSuccessType, StudyActionTypes.getCollaboratingStudiesErrorType),
        take(1),
        mergeMap((result: any) =>
          iif(() => result.type === StudyActionTypes.getCollaboratingStudiesSuccessType, of(result), throwError(result))
        )
      )
    );
    this.store.dispatch({
      type: UserActionTypes.getAllInvitationsOfEcoachMembersType,
      payload: { email: this.user.attributes.email }
    });
    reqs.push(this.allInvitations$.pipe(skip(1), take(1)));
    this.subscriptions.push(
      forkJoin(reqs).subscribe(
        (results: Array<any>) => {
          this.studies = results[0]['response']['body']['data'];

          this.pendingInvitationsSubject.next(results[1]);
          const currentPageSize = this.paginator?.paginator ? this.paginator.paginator.pageSize : 20;
          this.pagedInvitationsSubject.next(this.pendingInvitationsSubject.value.slice(0, currentPageSize));
          this.pendingInvitationCode = this.pagedInvitationsSubject.value.map(
            (invitation: InvitationInterface) => invitation.attributes.code
          );
          this.isLoadingSubject.next(false);
        },
        () => {
          this.studies = [];
          this.pendingInvitationsSubject.next([]);
          this.isLoadingSubject.next(false);
        }
      )
    );
  }

  public removeInvitation(index: number) {
    this.pendingInvitationsSubject.value.splice(index, 1);
    const currentPageSize = this.paginator?.paginator ? this.paginator.paginator.pageSize : 20;
    this.pagedInvitationsSubject.next(this.pendingInvitationsSubject.value.slice(0, currentPageSize));
    this.pendingInvitationCode = this.pagedInvitationsSubject.value.map((invitation: InvitationInterface) => invitation.attributes.code);
    this.isLoadingSubject.next(false);
  }

  public getIsManager(studyId: number): boolean {
    const found = this.helper.findArrObjById(studyId, this.studies);
    return found ? !!this.helper.getHighestRoleOfStudy(found).match(/study\.(ecoachmanager|owner)$/) : false;
  }

  public getIsECoachOfInvitation(studyId: number, invitation: InvitationInterface): boolean {
    const found = this.helper.findArrObjById(studyId, this.studies);
    const isECoach = found ? !!this.helper.getHighestRoleOfStudy(found).match(/study\.ecoach$/) : false;
    const isSender = invitation.attributes.sender_id.toString() === this.myUserId.toString();
    return isECoach && isSender;
  }

  public updateInvitation(index: number, invitation: InvitationInterface) {
    this.store.dispatch({
      type: UserActionTypes.getAllInvitationsOfEcoachMembersType,
      payload: { email: this.user.attributes.email }
    });
    this.subscriptions.push(
      this.allInvitations$.subscribe(result => {
        const pendingInvitations = result;
        const found = pendingInvitations.find((value: InvitationInterface) => value.id.toString() === invitation.id.toString());
        if (found) {
          if (this.paginator?.paginator) {
            const startIndex = this.paginator.paginator.pageIndex * this.paginator.paginator.pageSize;
            const endIndex = this.paginator.paginator.pageIndex + 1 * this.paginator.paginator.pageSize;
            this.pagedInvitationsSubject.next(this.pendingInvitationsSubject.value.slice(startIndex, endIndex));
            this.pendingInvitationCode = this.pagedInvitationsSubject.value.map((inv: InvitationInterface) => inv.attributes.code);
          }
        }
      })
    );
  }

  public updatePagedInvitations(event: any) {
    if (event) {
      this.pagedInvitationsSubject.next(event);
      this.pendingInvitationCode = this.pagedInvitationsSubject.value.map((invitation: InvitationInterface) => invitation.attributes.code);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
