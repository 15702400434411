import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { catchError, EMPTY, mergeMap, Observable, switchMap, tap, throwError } from 'rxjs';
import { InterventionInstanceReminderLogsInterface } from 'src/app/models/interface/intervention-instance-reminder-logs.interface';
import { ReminderService } from '../../../services/api/reminder/reminder.service';

export interface ReminderState {
  createInterventionInstanceReminderResponse: any;
  updateInterventionInstanceReminderResponse: any;
  deleteInterventionInstanceReminderResponse: any;
  allReminderLogsOfStudy: Array<InterventionInstanceReminderLogsInterface>;
  getCSVReminderLogsOfStudyResponse: any;
}

@Injectable()
export class ReminderStore extends ComponentStore<ReminderState> {
  readonly createInterventionInstanceReminderResponse$: Observable<any> = this.select(
    state => state.createInterventionInstanceReminderResponse,
    {
      debounce: true
    }
  );

  readonly updateInterventionInstanceReminderResponse$: Observable<any> = this.select(
    state => state.updateInterventionInstanceReminderResponse,
    {
      debounce: true
    }
  );

  readonly deleteInterventionInstanceReminderResponse$: Observable<any> = this.select(
    state => state.deleteInterventionInstanceReminderResponse,
    {
      debounce: true
    }
  );

  readonly allReminderLogsOfStudy$: Observable<any> = this.select(state => state.allReminderLogsOfStudy, {
    debounce: true
  });

  readonly getCSVReminderLogsOfStudyResponse$: Observable<any> = this.select(state => state.getCSVReminderLogsOfStudyResponse, {
    debounce: true
  });

  readonly updateReminderState = this.updater(
    (
      state,
      payload: {
        createInterventionInstanceReminderResponse?: any;
        updateInterventionInstanceReminderResponse?: any;
        deleteInterventionInstanceReminderResponse?: any;
        allReminderLogsOfStudy?: Array<InterventionInstanceReminderLogsInterface>;
        getCSVReminderLogsOfStudyResponse?: any;
      }
    ) => ({
      createInterventionInstanceReminderResponse: payload.createInterventionInstanceReminderResponse
        ? payload.createInterventionInstanceReminderResponse
        : state.createInterventionInstanceReminderResponse,
      updateInterventionInstanceReminderResponse: payload.updateInterventionInstanceReminderResponse
        ? payload.updateInterventionInstanceReminderResponse
        : state.updateInterventionInstanceReminderResponse,
      deleteInterventionInstanceReminderResponse: payload.deleteInterventionInstanceReminderResponse
        ? payload.deleteInterventionInstanceReminderResponse
        : state.deleteInterventionInstanceReminderResponse,
      allReminderLogsOfStudy: payload.allReminderLogsOfStudy ? payload.allReminderLogsOfStudy : state.allReminderLogsOfStudy,
      getCSVReminderLogsOfStudyResponse: payload.getCSVReminderLogsOfStudyResponse
        ? payload.getCSVReminderLogsOfStudyResponse
        : state.getCSVReminderLogsOfStudyResponse
    })
  );

  readonly createInterventionInstanceReminder = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) => {
        this.updateReminderState({ createInterventionInstanceReminderResponse: null });
        return this.reminderService
          .setInterventionInstanceReminder(
            payload.instanceId,
            payload.receiverId,
            payload.maxTimes,
            payload.start,
            payload.repeatTime,
            payload.repeatUnit,
            payload.contentText,
            payload.contentEnding,
            payload.locale
          )
          .pipe(
            tap({
              next: (result: any) => this.updateReminderState({ createInterventionInstanceReminderResponse: result }),
              error: e => throwError(e)
            }),
            catchError(error => {
              this.updateReminderState({ createInterventionInstanceReminderResponse: error });
              return EMPTY;
            })
          );
      })
    )
  );

  readonly updateInterventionInstanceReminder = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) => {
        this.updateReminderState({ updateInterventionInstanceReminderResponse: null });
        return this.reminderService
          .updateInterventionInstanceReminder(
            payload.reminderId,
            payload.receiverId,
            payload.maxTimes,
            payload.start,
            payload.repeatTime,
            payload.repeatUnit,
            payload.contentText,
            payload.contentEnding,
            payload.locale
          )
          .pipe(
            tap({
              next: (result: any) => this.updateReminderState({ updateInterventionInstanceReminderResponse: result }),
              error: e => throwError(e)
            }),
            catchError(error => {
              this.updateReminderState({ updateInterventionInstanceReminderResponse: error });
              return EMPTY;
            })
          );
      })
    )
  );

  readonly deleteInterventionInstanceReminder = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      mergeMap((payload: any) => {
        this.updateReminderState({ deleteInterventionInstanceReminderResponse: null });
        return this.reminderService.deleteInterventionInstanceReminder(payload.reminderId).pipe(
          tap({
            next: (result: any) => this.updateReminderState({ deleteInterventionInstanceReminderResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateReminderState({ deleteInterventionInstanceReminderResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly getallReminderLogsOfStudy = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) =>
        this.reminderService.getReminderLogsOfStudy(payload.studyId, payload.userId).pipe(
          tap({
            next: (result: any) => this.updateReminderState({ allReminderLogsOfStudy: result.body.data }),
            error: e => throwError(e)
          }),
          catchError(error => EMPTY)
        )
      )
    )
  );

  readonly getReminderLogsOfStudyCSV = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) => {
        this.updateReminderState({ getCSVReminderLogsOfStudyResponse: null });
        return this.reminderService.getReminderLogsOfStudyCSV(payload.studyId, payload.userId).pipe(
          tap({
            next: (result: any) => this.updateReminderState({ getCSVReminderLogsOfStudyResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateReminderState({ getCSVReminderLogsOfStudyResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  constructor(private reminderService: ReminderService) {
    super({
      createInterventionInstanceReminderResponse: null,
      updateInterventionInstanceReminderResponse: null,
      deleteInterventionInstanceReminderResponse: null,
      allReminderLogsOfStudy: [],
      getCSVReminderLogsOfStudyResponse: null
    });
  }
}
