import { NgModule } from '@angular/core';

import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './guards/authentication/auth.guard';
import { GroupGuard } from './guards/group/group.guard';
import { GlobalRoleGuard } from './guards/global-role/global-role.guard';
import { GroupOwnerGuard } from './guards/group-owner/group-owner.guard';
import { InterventionRoleGuard } from './guards/intervention-role/intervention-role.guard';
import { GroupPublisherGuard } from './guards/group-publisher/group-publisher.guard';
import { OrganisationManagerGuard } from './guards/organisation-manager/organisation-manager.guard';
import { OrganisationGuard } from './guards/organisation/organisation.guard';
import { GroupOrganisationManagerGuard } from './guards/group-organisation-manager/group-organisation-manager.guard';
import { InterventionBuddyGuard } from './guards/intervention-buddy/intervention-buddy.guard';
import { ActivityComponent } from './modules/activity/activity/activity.component';
import { FeedbackOverviewComponent } from './modules/answersheet/feedback-overview/feedback-overview.component';
import { AnswersheetsComponent } from './modules/answersheet/answersheets/answersheets.component';
import { AnswersheetDetailComponent } from './modules/answersheet/answersheet-detail/answersheet-detail.component';
import { CatalogueComponent } from './modules/intervention/catalogue/catalogue.component';
import { InterventionDetailComponent } from './modules/intervention/intervention-detail/intervention-detail.component';
import { InterventionCollaboratorComponent } from './modules/intervention/intervention-collaborator/intervention-collaborator.component';
import { InterventionLessonComponent } from './modules/intervention/intervention-lesson/intervention-lesson.component';
import { InterventionInstanceComponent } from './modules/intervention/intervention-instance/intervention-instance.component';
import { InterventionActivityComponent } from './modules/intervention/intervention-activity/intervention-activity.component';
import { InterventionTaskComponent } from './modules/intervention/intervention-task/intervention-task.component';
import { InterventionDiaryComponent } from './modules/intervention/intervention-diary/intervention-diary.component';
import { GroupOverviewComponent } from './modules/group/group-overview/group-overview.component';
import { GroupDetailComponent } from './modules/group/group-detail/group-detail.component';
import { GroupMemberComponent } from './modules/group/group-member/group-member.component';
import { GroupCollaboratorComponent } from './modules/group/group-collaborator/group-collaborator.component';
import { GroupInterventionComponent } from './modules/group/group-intervention/group-intervention.component';
import { GroupActivityComponent } from './modules/group/group-activity/group-activity.component';
import { GroupInstanceComponent } from './modules/group/group-instance/group-instance.component';
import { GroupTaskComponent } from './modules/group/group-task/group-task.component';
import { GroupConfigurationComponent } from './modules/group/group-configuration/group-configuration.component';
import { GroupInterventionReviewComponent } from './modules/group/group-intervention-review/group-intervention-review.component';
import { OrganisationDetailComponent } from './modules/organisation/organisation-detail/organisation-detail.component';
import { OrganisationStudyComponent } from './modules/organisation/organisation-study/organisation-study.component';
import { OrganisationCollaboratorComponent } from './modules/organisation/organisation-collaborator/organisation-collaborator.component';
import { OrganisationStudyCreationComponent } from './modules/organisation/organisation-study-creation/organisation-study-creation.component';
import { TaskComponent } from './modules/task/task/task.component';
import { PatientDiaryComponent } from './modules/patient/patient-diary/patient-diary.component';
import { PatientStudyComponent } from './modules/patient/patient-study/patient-study.component';
import { PatientInstanceComponent } from './modules/patient/patient-instance/patient-instance.component';
import { PatientOverviewComponent } from './modules/patient/patient-overview/patient-overview.component';
import { PatientDetailComponent } from './modules/patient/patient-detail/patient-detail.component';
import { ConversationComponent } from './modules/conversation/conversation/conversation.component';
import { FaqComponent } from './modules/navigation/faq/faq.component';
import { HomeComponent } from './modules/home/home/home.component';
import { ProfileComponent } from './modules/navigation/profile/profile.component';
import { GroupBuddyComponent } from './modules/group/group-buddy/group-buddy.component';
import { LoginComponent } from './modules/login/login/login.component';
import { AdminDashboardComponent } from './modules/admin/admin-dashboard/admin-dashboard.component';
import { AdminRoleGuard } from './guards/admin-role/admin-role.guard';
import { AdminDashboardUserComponent } from './modules/admin/admin-dashboard-user/admin-dashboard-user.component';
import { AdminDashboardOrganisationComponent } from './modules/admin/admin-dashboard-organisation/admin-dashboard-organisation.component';
import { AdminDashboardStudyComponent } from './modules/admin/admin-dashboard-study/admin-dashboard-study.component';
import { InterventionSkillComponent } from './modules/intervention/intervention-skill/intervention-skill.component';
import { GroupDiaryComponent } from './modules/group/group-diary/group-diary.component';
import { ImprintComponent } from './modules/navigation/imprint/imprint.component';
import { PrivacyPatientComponent } from './modules/navigation/privacy-patient/privacy-patient.component';
import { PrivacyEcoachComponent } from './modules/navigation/privacy-ecoach/privacy-ecoach.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent, loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule) },
  { path: 'imprint', component: ImprintComponent },
  { path: 'privacy-ecoach', component: PrivacyEcoachComponent },
  {
    path: 'home',
    component: HomeComponent,
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
    canActivate: [AuthGuard]
  },
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  { path: 'myprofile', component: ProfileComponent, canActivate: [AuthGuard, GlobalRoleGuard] },
  { path: 'faq', component: FaqComponent, canActivate: [AuthGuard, GlobalRoleGuard] },
  {
    path: 'activities',
    component: ActivityComponent,
    loadChildren: () => import('./modules/activity/activity.module').then(m => m.ActivityModule),
    canActivate: [AuthGuard, GlobalRoleGuard]
  },
  {
    path: 'tasks',
    component: TaskComponent,
    loadChildren: () => import('./modules/task/task.module').then(m => m.TaskModule),
    canActivate: [AuthGuard, GlobalRoleGuard]
  },
  {
    path: 'conversations',
    component: ConversationComponent,
    loadChildren: () => import('./modules/conversation/conversation.module').then(m => m.ConversationModule),
    canActivate: [AuthGuard, GlobalRoleGuard]
  },

  {
    path: 'interventions',
    component: CatalogueComponent,
    loadChildren: () => import('./modules/intervention/intervention.module').then(m => m.InterventionModule),
    canActivate: [AuthGuard, GlobalRoleGuard]
  },
  {
    path: 'interventions/:id',
    component: InterventionDetailComponent,
    loadChildren: () => import('./modules/intervention/intervention.module').then(m => m.InterventionModule),
    canActivate: [AuthGuard, GlobalRoleGuard],
    canActivateChild: [InterventionRoleGuard],
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'lessons' },
      { path: 'lessons', component: InterventionLessonComponent },
      // { path: 'skills', component: InterventionSkillComponent },
      { path: 'collaborators', component: InterventionCollaboratorComponent },
      { path: 'activities', component: InterventionActivityComponent },
      { path: 'instances', component: InterventionInstanceComponent },
      { path: 'tasks', component: InterventionTaskComponent },
      { path: 'diaries', component: InterventionDiaryComponent }
    ]
  },

  {
    path: 'groups',
    component: GroupOverviewComponent,
    loadChildren: () => import('./modules/group/group.module').then(m => m.GroupModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'groups/:id',
    component: GroupDetailComponent,
    loadChildren: () => import('./modules/group/group.module').then(m => m.GroupModule),
    canActivate: [AuthGuard, GlobalRoleGuard],
    // canActivateChild: [GroupOrganisationManagerGuard, InterventionBuddyGuard],
    canActivateChild: [GroupOrganisationManagerGuard],
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'interventions' },
      { path: 'members', component: GroupMemberComponent, canActivate: [GroupGuard] },
      { path: 'collaborators', component: GroupCollaboratorComponent },
      { path: 'interventions', component: GroupInterventionComponent, canActivate: [GroupGuard] },
      { path: 'activities', component: GroupActivityComponent, canActivate: [GroupGuard] },
      { path: 'instances', component: GroupInstanceComponent, canActivate: [GroupGuard] },
      { path: 'tasks', component: GroupTaskComponent, canActivate: [GroupGuard] },
      { path: 'configuration', component: GroupConfigurationComponent, canActivate: [GroupOwnerGuard] },
      { path: 'review', component: GroupInterventionReviewComponent, canActivate: [GroupPublisherGuard] }
      // { path: 'diaryInstances', component: GroupDiaryComponent, canActivate: [GroupGuard] },
      // {path: 'requests', component: GroupRequestComponent, canActivateChild: [AuthGuard, GlobalRoleGuard]}
      // { path: 'buddies', component: GroupBuddyComponent, canActivate: [GroupGuard] }
    ]
  },
  {
    path: 'organisations/:id',
    component: OrganisationDetailComponent,
    loadChildren: () => import('./modules/organisation/organisation.module').then(m => m.OrganisationModule),
    canActivate: [AuthGuard, GlobalRoleGuard, OrganisationGuard],
    canActivateChild: [OrganisationManagerGuard],
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'studies' },
      { path: 'studies', component: OrganisationStudyComponent },
      { path: 'collaborators', component: OrganisationCollaboratorComponent },
      { path: 'newStudy', component: OrganisationStudyCreationComponent }
    ]
  },

  {
    path: 'patients',
    component: PatientOverviewComponent,
    loadChildren: () => import('./modules/patient/patient.module').then(m => m.PatientModule),
    canActivate: [AuthGuard, GlobalRoleGuard]
  },
  {
    path: 'patients/:id',
    component: PatientDetailComponent,
    loadChildren: () => import('./modules/patient/patient.module').then(m => m.PatientModule),
    canActivate: [AuthGuard, GlobalRoleGuard],
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'studies' },
      { path: 'studies', component: PatientStudyComponent },
      { path: 'instances', component: PatientInstanceComponent },
      { path: 'diaries', component: PatientDiaryComponent }
    ]
  },
  {
    path: 'feedback-overview',
    component: FeedbackOverviewComponent,
    canActivate: [AuthGuard, GlobalRoleGuard],
    loadChildren: () => import('./modules/answersheet/answersheet.module').then(m => m.AnswersheetModule),
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'answersheets' },
      { path: 'answersheets', component: AnswersheetsComponent, canActivateChild: [AuthGuard, GlobalRoleGuard] },
      { path: 'answersheets/:id', component: AnswersheetDetailComponent, canActivate: [AuthGuard, GlobalRoleGuard] }
    ]
  },
  /*
  {
    path: 'admin',
    component: AdminDashboardComponent,
    canActivate: [AuthGuard, AdminRoleGuard],
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'users' },
      {
        path: 'users',
        component: AdminDashboardUserComponent,
        canActivateChild: [AuthGuard, AdminRoleGuard],
        loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule)
      },
      {
        path: 'organisations',
        component: AdminDashboardOrganisationComponent,
        canActivateChild: [AuthGuard, AdminRoleGuard],
        loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule)
      },
      {
        path: 'studies',
        component: AdminDashboardStudyComponent,
        canActivate: [AuthGuard, AdminRoleGuard],
        loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule)
      }
    ]
  },
  */
  { path: '**', redirectTo: 'home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'ignore' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
