/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, forkJoin, iif, Observable, of, Subscription, throwError } from 'rxjs';
import { distinctUntilChanged, filter, mergeMap, skip, take } from 'rxjs/operators';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { InterventionSharedService } from '../../../services/shared/intervention-shared/intervention-shared.service';
import { InterventionInstanceInterface } from '../../../models/interface/intervention-instances/intervention-instance.interface';
import { InterventionInterface } from '../../../models/interface/intervention.interface';
import { HelperService } from '../../../services/helper/helper.service';
import { UserInterface } from '../../../models/interface/user.interface';
import { LessonInterface } from '../../../models/interface/lesson.interface';
import { ProfileInterface } from '../../../models/interface/profile.interface';
import { HelperDialogService } from '../../../services/helper/helper-dialog/helper-dialog.service';
import { StudyActionTypes } from '../../../store/study/study.action';
import { UserActionTypes } from '../../../store/user/user.action';
import { InterventionInstanceStore } from '../../../store/intervention-instance/component-store/intervention-instance.store';
import { QuestionnaireStore } from '../../../store/lesson-questionnaire/component-store/lesson-questionnaire.store';
import { getCollaboratorsByStudyId } from '../../../store/study/study.selector';
import { StudyStore } from '../../../store/study/component-store/study.store';
import { InterventionInstanceReminderInterface } from '../../../models/interface/intervention-instance-reminder.interface';
import { ReminderActionTypes } from '../../../store/reminder/reminder.action';

/**
 * Component:
 * Intervention instance page displaying a list of assigned interventions;
 * Can be found: {uri}/intervention/{{intervention_id}}/instances
 */

@Component({
  selector: 'app-intervention-instance',
  templateUrl: './intervention-instance.component.html',
  styleUrls: ['./intervention-instance.component.scss'],
  providers: [InterventionInstanceStore, QuestionnaireStore, StudyStore]
})
export class InterventionInstanceComponent implements OnInit, OnDestroy {
  @ViewChild('paginator') paginator;

  // Icons
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;

  // Filter
  public searchFilter = '';
  public isLoading$: Observable<boolean>;
  public intervention: InterventionInterface;
  public excludeCanceledInstance = true;

  public profile: ProfileInterface;

  // Data provided by StudyService
  public studyMembers: Array<UserInterface> = [];
  public studyCollaborators: Array<UserInterface> = [];

  public questionnaires: Array<LessonInterface> = [];
  public selectedUserSubject: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public selectedUser: UserInterface;

  public pagedInstances$: Observable<Array<InterventionInstanceInterface>>;

  public instances$: Observable<Array<InterventionInstanceInterface>>;
  public isCollapse$: Observable<boolean>;
  public isManager$: Observable<boolean>;
  public isECoach$: Observable<boolean>;
  public profile$: Observable<ProfileInterface>;
  public instance$: Observable<InterventionInstanceInterface | null>;
  public instancesOfIntervention$: Observable<Array<InterventionInstanceInterface>>;

  public questionnairesOfIntervention$: Observable<Array<LessonInterface>>;

  public allInterventionInstanceReminders$: Observable<Array<InterventionInstanceReminderInterface>>;

  // Translation for study name
  public param = { intervention_name: '...' };

  public isCollapseSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private searchTextSubject = new BehaviorSubject<string>('');

  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private myRoleSlug: string;
  private myInstances: Array<InterventionInstanceInterface> = [];

  private allInstancesOfInterventionForECoach$: Observable<Array<InterventionInstanceInterface>>;
  private collaboratorsOfStudy$: Observable<{ studyId: number; collaborators: UserInterface[] }>;

  private studyMembers$: Observable<any>;

  private instancesSubject: BehaviorSubject<Array<InterventionInstanceInterface>> = new BehaviorSubject<
    Array<InterventionInstanceInterface>
  >([]);
  private pagedInstancesSubject: BehaviorSubject<Array<InterventionInstanceInterface>> = new BehaviorSubject<
    Array<InterventionInstanceInterface>
  >([]);

  private isManagerSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isECoachSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private myMembers$: Observable<Array<UserInterface>>;

  private subscriptions: Subscription[] = [];

  constructor(
    private sharedService: InterventionSharedService,
    private helperService: HelperService,
    private helperDialogService: HelperDialogService,
    private translateService: TranslateService,
    private questionnaireStore: QuestionnaireStore,
    private studyStore: StudyStore,
    private interventionInstanceStore: InterventionInstanceStore,
    private store: Store<{
      myProfile: ProfileInterface;
      getCollaboratorsByStudyId: { studyId: number; collaborators: UserInterface[] };
      myMembers: Array<UserInterface>;
      allInterventionInstanceReminders: Array<InterventionInstanceReminderInterface>;
    }>
  ) {
    this.profile$ = store.select('myProfile');
    this.myMembers$ = this.store.select('myMembers');
    this.allInterventionInstanceReminders$ = this.store.select('allInterventionInstanceReminders');
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.isCollapse$ = this.isCollapseSubject.asObservable();
    this.isECoach$ = this.isECoachSubject.asObservable();
    this.isManager$ = this.isManagerSubject.asObservable();
    this.instances$ = this.instancesSubject.asObservable();
    this.pagedInstances$ = this.pagedInstancesSubject.asObservable();
    this.instancesOfIntervention$ = this.interventionInstanceStore.instancesOfIntervention$;
    this.instance$ = this.interventionInstanceStore.instance$;
    this.allInstancesOfInterventionForECoach$ = this.interventionInstanceStore.allInstancesOfInterventionForECoach$;
    this.questionnairesOfIntervention$ = this.questionnaireStore.questionnairesOfIntervention$;
    this.studyMembers$ = this.studyStore.studyMembers$;
  }

  public get helper() {
    return this.helperService;
  }

  public get helperDialog() {
    return this.helperDialogService;
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.sharedService.intervention$
        .pipe(
          filter((value: InterventionInterface) => value !== null),
          mergeMap((value: InterventionInterface) => {
            this.intervention = value;
            this.param = { intervention_name: this.intervention.attributes.name };

            this.store.dispatch({
              type: StudyActionTypes.getCollaboratorsType,
              payload: { studyId: this.intervention.attributes.study_id, include: 'roles' }
            });

            this.collaboratorsOfStudy$ = this.store.select(getCollaboratorsByStudyId(this.intervention.attributes.study_id));
            return this.collaboratorsOfStudy$.pipe(
              filter(collaboratorsOfStudy => !!collaboratorsOfStudy),
              take(1)
            );
          }),
          mergeMap((result: any) => {
            this.studyCollaborators = result.collaborators;
            return this.profile$.pipe(filter(user => user !== null));
          }),
          mergeMap((result: any) => {
            this.profile = result;

            // Determine my highest role
            const myself: UserInterface = this.studyCollaborators.find(user => user.id === this.profile.id);
            if (myself !== undefined) {
              this.myRoleSlug = this.helper.getHighestRoleOfCollaborator(myself);
              this.isECoachSubject.next(!!this.myRoleSlug.match(/study\.(ecoach|collaborator|ecoachmanager|owner)$/));
              this.isManagerSubject.next(!!this.myRoleSlug.match(/study\.(ecoachmanager|owner)$/));
            }

            const reqs: Array<Observable<any>> = [];
            if (this.isManagerSubject.value) {
              this.studyStore.getMembers({ studyId: this.intervention.attributes.study_id });
            } else {
              this.studyStore.getECoachMembers({ studyId: this.intervention.attributes.study_id });
            }
            reqs.push(this.studyMembers$.pipe(skip(1), take(1)));
            this.store.dispatch({
              type: UserActionTypes.getMyMembersType,
              payload: {}
            });
            reqs.push(this.myMembers$.pipe(skip(1), take(1)));
            return forkJoin(reqs);
          }),
          mergeMap((results: Array<any>) => {
            this.studyMembers = results[0];
            if (this.studyMembers.length > 0) {
              if (!this.isManagerSubject.value) {
                const myMemberIds = results[1].map((user: UserInterface) => user.id);
                this.studyMembers = this.studyMembers.filter((user: UserInterface) => myMemberIds.includes(user.id));
              }
              this.selectedUser = this.studyMembers[0];
              this.selectedUserSubject.next(this.studyMembers[0].id);
            } else {
              this.selectedUserSubject.next(null);
            }
            this.questionnaireStore.getQuestionnairesIntervention({ interventionId: this.intervention.id });
            return this.questionnairesOfIntervention$.pipe(skip(1), take(1));
          })
        )
        .subscribe(
          (result: any) => {
            this.questionnaires = result;
            this.questionnaires = this.helper.sortLessonsAscending(this.questionnaires);
            this.applyInstanceFilter();
          },
          error => {
            throwError(error);
          }
        )
    );

    this.subscriptions.push(
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
        this.applyInstanceFilter();
      })
    );
  }

  public applyInstanceFilter(): void {
    this.isLoadingSubject.next(true);
    this.subscriptions.push(
      this.reloadInstanceList(true).subscribe((result: any) => {
        this.isLoadingSubject.next(false);
      })
    );
  }

  public reloadInstanceList(setFirst?: boolean): Observable<boolean> {
    const pageIndex = this.paginator?.paginator ? this.paginator?.paginator.pageIndex : 0;
    const pageSize = this.paginator?.paginator ? this.paginator?.paginator.pageSize : 20;
    this.store.dispatch({
      type: ReminderActionTypes.getAllInterventionInstanceRemindersOfUserType,
      payload: {}
    });
    this.store.dispatch({
      type: StudyActionTypes.getCollaboratingStudiesType,
      payload: { include: 'owners,roles' }
    });

    if (this.myRoleSlug.match(/study\.(ecoachmanager|owner)$/)) {
      this.interventionInstanceStore.getAllInstancesOfIntervention({ interventionId: this.intervention.id });
    } else {
      this.interventionInstanceStore.getAllInstancesOfInterventionForECoach({ interventionId: this.intervention.id });
    }

    return iif(
      () => !!this.myRoleSlug.match(/study\.(ecoachmanager|owner)$/),
      this.instancesOfIntervention$,
      this.allInstancesOfInterventionForECoach$
    ).pipe(
      skip(1),
      take(1),
      mergeMap((result: Array<InterventionInstanceInterface>) => {
        this.myInstances = result;
        if (this.excludeCanceledInstance) {
          this.myInstances = this.myInstances.filter(
            (instance: InterventionInstanceInterface) => instance.attributes.progress.current_state !== 'canceled'
          );
        }
        return this.search(this.searchFilter).pipe(
          mergeMap(() => this.helper.setPagedContent(this.instancesSubject, this.pagedInstancesSubject, setFirst, pageIndex, pageSize))
        );
      })
    );
  }

  public updateList(): void {
    this.subscriptions.push(this.reloadInstanceList().subscribe());
  }

  public updatePagedInterventionInstances(event: any) {
    if (event) {
      this.pagedInstancesSubject.next(event);
    }
  }

  public openDialogInterventionInstanceCreate(): void {
    this.helperDialog
      .openDialogInterventionInstanceCreate(
        this.param,
        this.intervention,
        this.studyMembers,
        this.helper.excludeUsersWithOnlyStudyAccess(this.studyCollaborators),
        this.profile.id,
        this.questionnaires
      )
      .afterClosed()
      .pipe(mergeMap((value: any) => this.reloadInstanceList()))
      .subscribe(() => {});
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  // Reads search value and runs searchFilter
  private search(name: string): Observable<boolean> {
    this.searchTextSubject.next(name);
    return this.searchTextSubject.pipe(
      distinctUntilChanged(),
      mergeMap(() => {
        this.instancesSubject.next(
          this.helper.filterInterventionInstancesBy(
            this.myInstances,
            this.searchFilter.toLowerCase().trim(),
            this.studyMembers,
            this.studyCollaborators,
            this.intervention.attributes.study_id
          )
        );
        return of(true);
      })
    );
  }
}
