<div [hidden]="isHidden$ | async" class="container text-start mb-3">
  <!-- Form -->
  <div class="text-left mb-3">
    <p>{{'patient-creation-account.form_create_patient_account' | translate}}</p>
  </div>

  <!-- Title and description -->
  <div class="form-group mb-3">
    <strong>{{'patient-creation-account.form_create_patient_account_description' | translate}}</strong>
  </div>

  <!-- Patient studyForm -->
  <form [formGroup]="patientForm">
    <mat-form-field [style.width.%]="100" appearance="outline">
      <mat-label>{{'patient-creation-account.form_create_patient_email' | translate}}</mat-label>
      <input [attr.id]="'email'" [formControlName]="'email'" matInput required type="text" />
      <mat-error *ngIf="submitted && f.get('email').errors?.required">{{'patient-creation-account.email_required' | translate}}</mat-error>
      <mat-error *ngIf="f.get('email').invalid && f.get('email').touched"
        >{{'patient-creation-account.valid_email_required' | translate}}</mat-error
      >
    </mat-form-field>

    <!-- Select study for patient -->
    <div class="form-group my-3">
      <strong>{{'patient-creation-account.form_create_patient_studies_description' | translate}}</strong>
    </div>
    <div *ngIf="(selectedStudies$ | async).length !== 0" class="outline">
      <button
        [attr.id]="'btnSelectStudy_' + selected"
        *ngFor="let selected of (selectedStudies$ | async)"
        class="btn btn-outline-secondary btn-sm spacing-left spacing-right"
      >
        {{helper.findArrObjById(selected, studies).attributes.name}}
      </button>
    </div>
    <div *ngIf="submitted && !checkIfOneSelected()">
      <div class="alert alert-danger" role="alert">{{'patient-creation-account.selection_required_study' | translate}}</div>
    </div>
    <div class="card outline mb-3">
      <div *ngIf="(isLoading$ | async); else elseStudies">
        <app-loader></app-loader>
      </div>
      <ng-template #elseStudies>
        <div *ngIf="fStudyList.length > 0; then thenListOfStudies else elseShowNoStudies"></div>
        <ng-template #thenListOfStudies>
          <div class="card-body">
            <div
              [attr.id]="'study_' + studyList[i].id"
              *ngFor="let study of fStudyList.controls; let i = index; let isLast = last"
              formArrayName="studyList"
            >
              <div [formGroupName]="i" class="row mb-2">
                <div class="row mx-2 mb-2">
                  <img
                    class="col-4"
                    [src]="helper.getAllMediaSupportPath(studyList[i].attributes.picture, studyList[i].id)"
                    (error)="helper.getFallbackImage($event)"
                    alt="No image"
                    class="image-parent img-fluid"
                  />
                  <div class="col-8">
                    <p>
                      <strong>
                        {{(studyList[i].attributes.title) ? studyList[i].attributes.title : 'patient-invitation-study.no_group_title'
                        |translate}}
                      </strong>
                    </p>
                    <p><small class="text-muted">({{studyList[i].attributes.name}}) (ID - {{studyList[i].id}})</small></p>
                    <p class="text-muted">
                      {{'patient-invitation-study.collaborator_roles' | translate}}:
                      <span *ngFor="let role of getIncludedRolesOfStudy(studyList[i].id); last as isLast"
                        >{{helper.parseRoleSlug(role.attributes.slug) | translate}}<span *ngIf="!isLast"> | </span>
                      </span>
                    </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <mat-checkbox [attr.id]="'checkboxStudy' + studyList[i].id" [attr.value]="studyList[i]" [formControlName]="'checked'">
                  </mat-checkbox>
                  <div class="row mb-2">
                    <button
                      [attr.id]="'btnRemoveCodeFromPendingInvitation_' + studyList[i].id"
                      class="mx-auto"
                      *ngIf="codeUsed(studyList[i].id)"
                      mat-button
                      (click)="removeCodeFromPendingInvitation(studyList[i].id, codeUsedInPendingInvitation(studyList[i].id))"
                    >
                      {{'patient-creation-account.code_remove' | translate}}
                    </button>
                  </div>
                </div>
                <div class="col-md-6">
                  <mat-form-field [style.width.%]="100" appearance="outline" class="mb-2">
                    <mat-label>{{'patient-creation-account.form_create_patient_study_code' | translate}}</mat-label>
                    <input
                      [attr.id]="'code_' + studyList[i].id"
                      [formControlName]="'code'"
                      matInput
                      type="text"
                      [errorStateMatcher]="matcher"
                    />
                    <mat-error *ngIf="codeUsed(studyList[i].id)" class="mb-5">
                      {{'patient-creation-account.code_has_been_used' | translate}}
                      <span *ngIf="(codeUsedInPendingInvitation(studyList[i].id) !== null)">
                        {{'patient-creation-account.code_can_be_reassigned' | translate}} :
                        {{codeUsedInPendingInvitation(studyList[i].id).attributes.email}}.
                        {{'patient-creation-account.code_remove_code_description' | translate}}
                      </span>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <mat-divider class="my-3" *ngIf="!isLast"></mat-divider>
            </div>
          </div>
        </ng-template>
        <ng-template #elseShowNoStudies>
          <p>{{'patient-creation-account.form_create_patient_no_study' | translate}}</p>
        </ng-template>
      </ng-template>
    </div>
  </form>
</div>
