/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { faList } from '@fortawesome/free-solid-svg-icons/faList';
import { BehaviorSubject, iif, Observable, of, Subscription } from 'rxjs';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { catchError, filter, mergeMap, skip, switchMap, take } from 'rxjs/operators';
import { HelperService } from '../../../services/helper/helper.service';
import { RoleInterface } from '../../../models/interface/role.interface';
import { StudyInterface } from '../../../models/interface/study/study.interface';
import { HelperDialogService } from '../../../services/helper/helper-dialog/helper-dialog.service';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { StudyActionTypes } from '../../../store/study/study.action';
import { StudyStore } from '../../../store/study/component-store/study.store';
import { OrganisationInterface } from '../../../models/interface/organisation/organisation.interface';
import { OrganisationActionTypes } from '../../../store/organisation/organisation.action';

/**
 * Component:
 * Group page displaying a list of groups - child component of GroupOverviewComponent;
 */

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss'],
  providers: [StudyStore]
})
export class GroupComponent implements OnInit, OnDestroy {
  @ViewChild('paginatorStudy1') paginatorStudy1;
  @ViewChild('paginatorStudy2') paginatorStudy2;

  // Icons
  faList = faList;

  public isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public isLoading$: Observable<boolean> = this.isLoadingSubject.asObservable();

  // Filter
  public filter = {
    searchFilter: '',
    isCollab: true,
    private: '2'
  };

  public profileRoles$: Observable<Array<RoleInterface>>;
  public role$: Observable<string>;
  public isCollapsed$: Observable<boolean>;
  public groups$: Observable<Array<StudyInterface>>;
  public pagedGroups$: Observable<Array<StudyInterface>>;

  public param = { result_value: 0 };

  public reloadEventSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public organisations$: Observable<Array<OrganisationInterface>>;
  public organisations: Array<OrganisationInterface> = [];

  public isCollapsedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  public isAdminSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private searchTextSubject = new BehaviorSubject<string>('');

  private groupsSubject: BehaviorSubject<Array<StudyInterface>> = new BehaviorSubject([]);
  private pagedGroupsSubject: BehaviorSubject<Array<StudyInterface>> = new BehaviorSubject<Array<StudyInterface>>([]);
  private roleSubject: BehaviorSubject<string> = new BehaviorSubject<string>('ecoach');

  private allStudies$: Observable<StudyInterface[]>;

  private subscriptions: Subscription[] = [];

  constructor(
    private translateService: TranslateService,
    private helperService: HelperService,
    private helperDialogService: HelperDialogService,
    private studyStore: StudyStore,
    private actions$: Actions,
    private store: Store<{ myRoles: Array<RoleInterface>; organisations: Array<OrganisationInterface> }>
  ) {
    this.profileRoles$ = store.select('myRoles');
    this.organisations$ = store.select('organisations');
    this.groups$ = this.groupsSubject.asObservable();

    this.pagedGroups$ = this.pagedGroupsSubject.asObservable();
    this.isCollapsed$ = this.isCollapsedSubject.asObservable();
    this.role$ = this.roleSubject.asObservable();

    this.allStudies$ = this.studyStore.allStudies$;
  }

  public get helper() {
    return this.helperService;
  }

  public get helperDialog() {
    return this.helperDialogService;
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.profileRoles$
        .pipe(
          filter(roles => roles !== null),
          take(1)
        )
        .subscribe((roles: Array<RoleInterface>) => {
          if (this.helper.hasRoles(roles, /admin$/)) {
            this.roleSubject.next('admin');
            this.isAdminSubject.next(true);
          }
          this.applyGroupFilter();
        })
    );

    // Subscription to language change
    this.subscriptions.push(
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
        this.applyGroupFilter();
      })
    );
  }

  public applyGroupFilter(): void {
    this.isLoadingSubject.next(true);
    this.subscriptions.push(
      this.reloadOrganisationList()
        .pipe(switchMap(() => this.reloadGroupList(true)))
        .subscribe((result: any) => {
          this.isLoadingSubject.next(false);
        })
    );
  }

  public reloadOrganisationList(): Observable<boolean> {
    if (this.roleSubject.value === 'admin') {
      this.store.dispatch({ type: OrganisationActionTypes.getOrganisationsAdminType, payload: { include: 'owners,collaborators' } });
    } else {
      this.store.dispatch({ type: OrganisationActionTypes.getOrganisationsType, payload: { include: 'owners,collaborators' } });
    }
    return this.organisations$.pipe(
      skip(1),
      take(1),
      mergeMap(result => {
        this.organisations = result;
        return of(true);
      }),
      catchError(error => {
        this.organisations = [];
        return of(false);
      })
    );
  }

  public reloadGroupList(setFirst?: boolean): Observable<boolean> {
    const paginator = this.roleSubject.value === 'admin' ? this.paginatorStudy1 : this.paginatorStudy2;
    const pageIndex = paginator?.paginator ? paginator.paginator.pageIndex : 0;
    const pageSize = paginator?.paginator ? paginator.paginator.pageSize : 20;
    const getPrivate = isPrivate =>
      ({
        2: undefined,
        1: 1,
        0: 0
      }[isPrivate]);
    const paramPrivate: number = getPrivate(this.filter['private']);

    this.store.dispatch({
      type: StudyActionTypes.getCollaboratingStudiesType,
      payload: { isPrivate: paramPrivate, include: 'owners,roles' }
    });
    return this.actions$.pipe(
      ofType(StudyActionTypes.getCollaboratingStudiesSuccessType, StudyActionTypes.getCollaboratingStudiesErrorType),
      take(1),
      mergeMap((result: any) =>
        iif(() => result.type === StudyActionTypes.getCollaboratingStudiesSuccessType, of(result['response']['body']['data']), of([]))
      ),
      mergeMap((groups: any) =>
        iif(
          () => this.filter['isCollab'],
          this.setStudiesCollabOnly(groups, setFirst, pageIndex, pageSize),
          this.setStudiesNonCollabOnly(groups, paramPrivate, setFirst, pageIndex, pageSize)
        )
      )
    );
  }

  public setStudiesCollabOnly(groups: Array<StudyInterface>, setFirst, pageIndex, pageSize) {
    const studies = groups.filter((group: StudyInterface) => group.attributes.type === 'study' || group.attributes.type === 'organisation');
    return this.performFilterAndPagination(setFirst, pageIndex, pageSize, studies);
  }

  public setStudiesNonCollabOnly(groups: Array<StudyInterface>, paramPrivate, setFirst, pageIndex, pageSize) {
    const studies = groups.filter((group: StudyInterface) => group.attributes.type === 'study' || group.attributes.type === 'organisation');
    this.studyStore.getAllStudies({ isPrivate: paramPrivate, isActive: 1, include: 'owners' });
    return this.allStudies$.pipe(
      skip(1),
      take(1),
      mergeMap((result: any) => {
        const collaboratingGroups = result.filter((group: StudyInterface) => group.attributes.type === 'study');
        const temp = collaboratingGroups.map(obj => studies.find(o => o.id.toString() === obj.id.toString()) || obj);
        return this.performFilterAndPagination(setFirst, pageIndex, pageSize, temp);
      })
    );
  }

  public updateList(): void {
    this.subscriptions.push(this.reloadGroupList().subscribe());
  }

  public updatePagedGroups(event: any) {
    if (event) {
      this.pagedGroupsSubject.next(event);
    }
  }

  public reloadGroups(event: string) {
    if (event) {
      this.applyGroupFilter();
      this.reloadEventSubject.next(event);
    }
  }

  public trackByGroupId(index: number, element: StudyInterface): number {
    return element.id;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  public performFilterAndPagination(
    setFirst: boolean,
    pageIndex: number,
    pageSize: number,
    groups: Array<StudyInterface>
  ): Observable<boolean> {
    return this.helper
      .searchStudiesByInput(this.filter['searchFilter'], this.searchTextSubject, groups, this.groupsSubject)
      .pipe(mergeMap(() => this.helper.setPagedContent(this.groupsSubject, this.pagedGroupsSubject, setFirst, pageIndex, pageSize)));
  }
}
